/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  CreateTransactionFilterResponseDto,
  CreateTransactionFiltersDto,
  GetTransactionFilterDto,
  PaginatedTransactionFilterResultDto,
  UpdateTransactionFiltersDto,
} from "../models"

export interface CreateFilterRequest {
  workspaceId: string
  createTransactionFiltersDto: CreateTransactionFiltersDto
}

export interface DeleteFilterRequest {
  workspaceId: string
  filterId: string
}

export interface GetFiltersRequest {
  workspaceId: string
  page: number
  limit: number
  sortDirection?: GetFiltersSortDirectionEnum
  query?: string
  excludedIds?: Array<string>
}

export interface GetOneFilterRequest {
  workspaceId: string
  filterId: string
}

export interface UpdateFilterRequest {
  workspaceId: string
  filterId: string
  updateTransactionFiltersDto: UpdateTransactionFiltersDto
}

/**
 *
 */
export class FiltersApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static createFilter(params: CreateFilterRequest) {
    return localFiltersApi.createFilter(params)
  }

  /**
   * Create a new filter
   */
  createFilter({
    createTransactionFiltersDto,
    workspaceId,
  }: CreateFilterRequest): Promise<CreateTransactionFilterResponseDto> {
    return this.restInstance.post(`/workspaces/${encodeURIComponent(workspaceId)}/filters`, createTransactionFiltersDto)
  }

  static deleteFilter(params: DeleteFilterRequest) {
    return localFiltersApi.deleteFilter(params)
  }

  /**
   * Delete a filter
   */
  deleteFilter({ workspaceId, filterId }: DeleteFilterRequest): Promise<void> {
    return this.restInstance.delete(
      `/workspaces/${encodeURIComponent(workspaceId)}/filters/${encodeURIComponent(filterId)}`,
      undefined,
    )
  }

  static getFilters(params: GetFiltersRequest) {
    return localFiltersApi.getFilters(params)
  }

  /**
   * Get filters
   */
  getFilters({ workspaceId, ...queryParameters }: GetFiltersRequest): Promise<PaginatedTransactionFilterResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/filters?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
    )
  }

  static getOneFilter(params: GetOneFilterRequest) {
    return localFiltersApi.getOneFilter(params)
  }

  /**
   * Get one filter
   */
  getOneFilter({ workspaceId, filterId }: GetOneFilterRequest): Promise<GetTransactionFilterDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/filters/${encodeURIComponent(filterId)}`,
    )
  }

  static updateFilter(params: UpdateFilterRequest) {
    return localFiltersApi.updateFilter(params)
  }

  /**
   * Update a filter
   */
  updateFilter({ updateTransactionFiltersDto, workspaceId, filterId }: UpdateFilterRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/filters/${encodeURIComponent(filterId)}`,
      updateTransactionFiltersDto,
      true,
    )
  }
}

const localFiltersApi = new FiltersApi()

/**
 * @export
 * @enum {string}
 */
export type GetFiltersSortDirectionEnum = "ascending" | "descending"
