import { useMutation, useQuery } from "react-query"
import { queryClient } from "../../.."
import { UrlShortenerApi } from "../openapi"

export const GET_SHORTENED_URL = "getShortenedUrl"

export const urlShortenerActions = {
  useShortenedUrl: (id: string) => {
    return useQuery([GET_SHORTENED_URL, id], () => UrlShortenerApi.get({ urlId: id }), {
      retry: (retryCount, err: { message?: string }) => {
        return err?.message?.includes("Validation failed") ? false : retryCount < 3
      },
    })
  },
  useCreateShortened: () => {
    return useMutation(UrlShortenerApi.create, {
      onSuccess: () => {
        // More reports
        queryClient.invalidateQueries(GET_SHORTENED_URL)
      },
    })
  },
}
