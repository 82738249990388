import {
  Box,
  createStyles,
  IconButton,
  InputAdornment,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Theme,
} from "@material-ui/core"
import { CloseRounded, SearchRounded } from "@material-ui/icons"
import Fuse from "fuse.js"
import React, { useState } from "react"

import { GetWalletTypeDto } from "services/api/openapi"
import SourceElement from "../SourceElement"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sourceContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridGap: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },
  }),
)

interface Props {
  sources: GetWalletTypeDto[]
  value?: GetWalletTypeDto
  onChange?: (newValue?: GetWalletTypeDto) => void
}

interface SourcesListProps {
  filterFn: (sourceType: string) => boolean
  index: number
  value: number
}

const SourceSelector = (props: Props): JSX.Element => {
  const customSource = props.sources.filter((s) => s.name === "Custom")
  const SourcesList = ({ value, index, filterFn, ...other }: SourcesListProps): JSX.Element => {
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        <Box className={classes.sourceContainer}>
          {(elementsToDisplay.length > 0 ? elementsToDisplay : customSource).map((x) => {
            if (filterFn(x.type))
              return (
                <SourceElement
                  isSelected={props.value === x}
                  onSelected={() => onSourceSelected(x)}
                  key={x.name}
                  source={x}
                />
              )
            return null
          })}
        </Box>
      </Box>
    )
  }

  const [tabIndex, setTabIndex] = useState(0)
  const [searchQuery, setSearchQuery] = useState<string>("")
  const classes = useStyles()

  const onSourceSelected = (source: GetWalletTypeDto) => {
    if (props.onChange) props.onChange(source === props.value ? undefined : source)
  }

  const inSearchMode = searchQuery.length > 1
  const elementsToDisplay = inSearchMode
    ? new Fuse(props.sources, {
        shouldSort: true,
        findAllMatches: false,
        threshold: 0.3,
        location: 0,
        distance: 500,
        minMatchCharLength: 1,
        keys: ["name"],
      })
        .search(searchQuery)
        .map((x) => x.item)
    : props.sources.sort((a, b) => a.name.localeCompare(b.name))

  return (
    <>
      <Box pr={3} mb={2}>
        <TextField
          fullWidth
          placeholder="Search..."
          value={searchQuery}
          onChange={(x) => setSearchQuery(x.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearchQuery("")}>
                  <CloseRounded />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box pt={2} />
        <Box pl={5}>
          <Tabs value={tabIndex} onChange={(_, index) => setTabIndex(index)}>
            <Tab disableRipple label={"All"} />
            <Tab disableRipple label={"Exchanges"} />
            <Tab disableRipple label={"Chains"} />
            {/* <Tab disableRipple label={"Payments"} /> Maybe one day we will have this category*/}
            <Tab disableRipple label={"Custody"} />
          </Tabs>
        </Box>
        <Box pt={3} />

        <SourcesList value={tabIndex} index={0} filterFn={(_) => true} />
        <SourcesList value={tabIndex} index={1} filterFn={(type: string) => type === "exchange" || type === "custom"} />
        <SourcesList value={tabIndex} index={2} filterFn={(type: string) => type === "chain" || type === "custom"} />
        {/* <SourcesList
          value={tabIndex}
          index={3}
          filterFn={(type: string) => type === "payment" || type === "custom"}
        /> */}
        <SourcesList value={tabIndex} index={3} filterFn={(type: string) => type === "custody" || type === "custom"} />
      </Box>
    </>
  )
}

export default SourceSelector
