import { Box, Theme } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { WarningRounded } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import React from "react"

import ExternalLink from "components/ExternalLink"
import { GetWalletTypeDtoNameEnum } from "services/api/openapi/models"

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  warningIcon: {
    marginRight: theme.spacing(1),
  },
}))

const KrakenHint = (): JSX.Element => {
  const classes = useStyles()
  return (
    <Box mb={2} component={Paper} className={classes.paper} display="flex">
      <WarningRounded className={classes.warningIcon} />
      <Typography>
        We require the <i>Export Data</i> permission to be set. Please refer to{" "}
        <ExternalLink href="https://support.kraken.com/hc/en-us/articles/360025484891-How-to-export-your-account-history-via-API">
          the Kraken documentation
        </ExternalLink>{" "}
        for more information.
      </Typography>
    </Box>
  )
}

export const AdditionalInfoBanners = (props: { sourceName: GetWalletTypeDtoNameEnum }): JSX.Element => {
  if (props.sourceName === "Kraken") return <KrakenHint />
  return <></>
}
