import React from "react"
import BackOfficeView from "components/misc/BackOfficeView"
import DrawerElement from "CryptioUI/DrawerElement"
import { BoldWeight, TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import CopyableText from "components/misc/copyableText"

interface TechAdministratorInformationProps {
  positionId: string
}

const TechAdministratorInformation = (props: TechAdministratorInformationProps): JSX.Element => {
  const { positionId } = props

  return (
    <BackOfficeView>
      <div className="py-8">
        <TypographyUI variant={TypographyVariant.H3} boldness={BoldWeight.MEDIUM}>
          Tech Administrator information
        </TypographyUI>
      </div>
      <DrawerElement nameElement="Position ID">
        <CopyableText text={positionId}>
          <TypographyUI variant={TypographyVariant.BODY} boldness={BoldWeight.MEDIUM} className="text-grey-400">
            {positionId}
          </TypographyUI>
        </CopyableText>
      </DrawerElement>
    </BackOfficeView>
  )
}

export default TechAdministratorInformation
