import React, { useMemo, useState } from "react"

import { DrawerCategory, DrawerFormSection } from "../../../components/Drawer/DrawerItems"
import useFilterDrawer from "../../../components/Hooks/useFilterDrawer"
import DrawerUI from "../../../CryptioUI/Drawer"
import { DeFiParameters, labelHasAnyFilter, defaultDefiParams } from ".."
import CustomSelector from "components/selector/CustomSelector"
import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import {
  DefiBlockchainDto,
  DefiPositionNameDto,
  DefiPositionTypeDto,
  DefiProtocolDto,
  GetDefiNetworkRequest,
  GetDefiProtocolsRequest,
  GetLivePositionsStateEnum,
  GetPositionNamesRequest,
  GetPositionTypesRequest,
  GetWalletDto,
  GetWalletsRequest,
} from "services/api/openapi"
import WalletOptionInAutocomplete from "components/AutoCompleteOptions/WalletOptionInAutocomplete"
import SimpleAutocompleteFactory from "components/Autocomplete/SimpleAutocompleteFactory"

interface Props {
  filter: DeFiParameters
  setFilter: (newValue: DeFiParameters) => void
}

const DeFiFilter = (props: Props): JSX.Element => {
  const { filter } = props
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const { OpenButton, SaveButton, tempFilter, updateFilter } = useFilterDrawer({
    ...props,
    defaultParams: defaultDefiParams,
    setIsDrawerOpen,
  })

  const filterCount = useMemo(() => {
    let res = 0
    if (filter.state !== undefined) res++
    if (filter.applications !== undefined && filter.applications.length) res++
    if (filter.blockchains !== undefined && filter.blockchains.length) res++
    if (filter.positionsNames !== undefined && filter.positionsNames.length) res++
    if (filter.positionsTypes !== undefined && filter.positionsTypes.length) res++
    if (filter.walletIds !== undefined && filter.walletIds.length) res++
    return res
  }, [filter])

  const canClear = useMemo(() => {
    return filterCount > 0 || labelHasAnyFilter(tempFilter)
  }, [filterCount, tempFilter])

  const StatusFilter = SimpleAutocompleteFactory<GetLivePositionsStateEnum>({
    open: "Open",
    close: "Close",
  })

  return (
    <>
      <OpenButton filterCount={filterCount} />
      <DrawerUI title="Label Filter" onClose={() => setIsDrawerOpen(false)} isOpen={isDrawerOpen}>
        <DrawerCategory title="Filter">
          <DrawerFormSection htmlFor="status-select" name="Search by status">
            <StatusFilter
              id="status-type-select"
              value={tempFilter.state ?? null}
              size="small"
              onChange={(value) => {
                updateFilter({ state: value ?? undefined })
              }}
              placeholder={tempFilter.state ? "" : "Status"}
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="autocomplete-application" name="Search by application">
            <CustomSelector<WithoutWorkspaceId<GetDefiProtocolsRequest>, DefiProtocolDto, true>
              id="application"
              value={tempFilter.applications ?? []}
              fullWidth
              multiple
              disableCloseOnSelect
              filterSelectedOptions
              onChange={(_, newValue) => {
                updateFilter({ applications: newValue })
              }}
              getOptionLabel={(option) => option.name}
              defaultPaginatedQueryProps={{
                onlyInWorkspace: true,
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              usePaginatedQuery={api.workspace.useDefiProtocol}
              size="small"
              placeholder={
                tempFilter.applications === undefined || tempFilter.applications.length === 0 ? "Application" : ""
              }
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="autocomplete-chain" name="Search by chain">
            <CustomSelector<WithoutWorkspaceId<GetDefiNetworkRequest>, DefiBlockchainDto, true>
              id="chain"
              value={tempFilter.blockchains ?? []}
              fullWidth
              multiple
              disableCloseOnSelect
              filterSelectedOptions
              onChange={(_, newValue) => {
                updateFilter({ blockchains: newValue })
              }}
              getOptionLabel={(option) => option.name}
              defaultPaginatedQueryProps={{
                onlyInWorkspace: true,
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              usePaginatedQuery={api.workspace.useDefiNetwork}
              size="small"
              placeholder={tempFilter.blockchains === undefined || tempFilter.blockchains.length === 0 ? "Chain" : ""}
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="autocomplete-name" name="Search by name">
            <CustomSelector<WithoutWorkspaceId<GetPositionNamesRequest>, DefiPositionNameDto, true>
              id="name"
              value={tempFilter.positionsNames ?? []}
              fullWidth
              multiple
              filterSelectedOptions
              onChange={(_, newValue) => updateFilter({ positionsNames: newValue })}
              getOptionLabel={(option) => option.name}
              defaultPaginatedQueryProps={{
                onlyInWorkspace: true,
              }}
              usePaginatedQuery={api.workspace.usePositionName}
              size="small"
              placeholder={
                tempFilter.positionsNames === undefined || tempFilter.positionsNames.length === 0 ? "Name" : ""
              }
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="autocomplete-position-type" name="Search by position type">
            <CustomSelector<WithoutWorkspaceId<GetPositionTypesRequest>, DefiPositionTypeDto, true>
              id="position-type"
              value={tempFilter.positionsTypes ?? []}
              fullWidth
              multiple
              onChange={(_, newValue) => updateFilter({ positionsTypes: newValue })}
              getOptionLabel={(option) => option.name}
              defaultPaginatedQueryProps={{
                onlyInWorkspace: true,
              }}
              getOptionSelected={(option, value) => option.name === value.name}
              usePaginatedQuery={api.workspace.usePositionType}
              size="small"
              placeholder={
                tempFilter.positionsTypes === undefined || tempFilter.positionsTypes.length === 0 ? "Position type" : ""
              }
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="autocomplete-chain" name="Search by source">
            <CustomSelector<WithoutWorkspaceId<GetWalletsRequest>, GetWalletDto, true>
              id="wallets-select"
              value={tempFilter.walletIds ?? []}
              multiple
              placeholder={tempFilter.walletIds === undefined || tempFilter.walletIds.length === 0 ? "Source" : ""}
              defaultPaginatedQueryProps={{
                sortBy: "name",
                sortDirection: "ascending",
              }}
              size="small"
              filterSelectedOptions
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onChange={(_, newValue) => {
                updateFilter({ walletIds: newValue })
              }}
              usePaginatedQuery={api.wallet.useWallets}
              renderOption={(option) => <WalletOptionInAutocomplete wallet={option} />}
            />
          </DrawerFormSection>
          <SaveButton filterCount={filterCount} canClear={canClear} />
        </DrawerCategory>
      </DrawerUI>
    </>
  )
}

export default DeFiFilter
