import React from "react"

import LabelIcon from "../LabelIcon"

export interface LabelCircleColorProps {
  color: string
  type?: "automatic" | "manual" | undefined
  size?: number
}

export const LabelCircleColor = ({ color, type, size = 20 }: LabelCircleColorProps): JSX.Element => {
  return (
    <div
      data-tag="allowRowEvents"
      style={{
        height: `${size}px`,
        width: `${size}px`,
        borderRadius: "9999px",
        opacity: "1",
        backgroundColor: color,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {type && <LabelIcon type={type} color={color} size={14} />}
    </div>
  )
}
