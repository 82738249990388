/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import { PaginatedHistoryResultDto } from "../models"

export interface GetWorkspaceHistoryRequest {
  page: number
  limit: number
  workspaceId: string
  excludedIds?: Array<string>
}

/**
 *
 */
export class HistoryApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static getWorkspaceHistory(params: GetWorkspaceHistoryRequest) {
    return localHistoryApi.getWorkspaceHistory(params)
  }

  /**
   * Get workspace history
   */
  getWorkspaceHistory({
    workspaceId,
    ...queryParameters
  }: GetWorkspaceHistoryRequest): Promise<PaginatedHistoryResultDto> {
    return this.restInstance.get(
      `/history/workspace/${encodeURIComponent(workspaceId)}?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
    )
  }
}

const localHistoryApi = new HistoryApi()
