import { TypographyProps } from "@material-ui/core"
import React from "react"
import { OtherPartyAliasDto } from "services/api/openapi"
import { EllipsisText } from "services/utils/textUtils"
import { WithComponentProp } from "services/utils/types"
import AddressTitleTooltip from "./AddressTitleTooltip"
import TooltipUI from "CryptioUI/Tooltip"
import { Mode, TypographyVariant } from "CryptioUI/types"
import ButtonUI from "CryptioUI/Button"
import TypographyAllowEvent from "components/TypographyAllowEvent"
import TypographyUI from "CryptioUI/Typography"

type Props = {
  children: string
  otherParties: OtherPartyAliasDto[]
  subtitle: string
  onClick: () => void
} & WithComponentProp<Omit<TypographyProps, "children">>

const AddressTypography = ({ children: address, otherParties, subtitle, onClick }: Props) => {
  const text = otherParties.length > 1 ? `${otherParties.length} other parties` : EllipsisText(address, 8)

  return (
    <TooltipUI content={<AddressTitleTooltip otherParties={otherParties} />} noMaxWidth>
      <p className="flex flex-row justify-start items-center">
        <TypographyAllowEvent
          variant={subtitle === "from:" ? TypographyVariant.BUTTON : TypographyVariant.CAPTION}
          className={`pr-1 ${subtitle === "from:" ? "text-grey-500" : "text-grey-400"}`}
        >
          {subtitle}
        </TypographyAllowEvent>
        {otherParties.length > 1 ? (
          <TypographyAllowEvent
            variant={subtitle === "from:" ? TypographyVariant.BUTTON : TypographyVariant.CAPTION}
            className={`${
              subtitle === "from:" ? "" : "underline"
            } overflow-hidden whitespace-nowrap overflow-ellipsis w-[110px] max-w-[110px] text-left ${
              subtitle === "from:" ? "text-grey-500" : "text-grey-400"
            }`}
          >
            {text ?? "-"}
          </TypographyAllowEvent>
        ) : (
          <ButtonUI
            mode={Mode.TEXTONLY}
            className={`p-0 h-fit ${subtitle === "from:" ? "text-grey-500 hover:underline" : "text-grey-400"}`}
            onClick={onClick}
          >
            <div className="flex justify-center h-fit">
              <TypographyUI
                ellipsisPosition="end"
                variant={subtitle === "from:" ? TypographyVariant.BUTTON : TypographyVariant.CAPTION}
                className={`${
                  subtitle === "from:"
                    ? "text-grey-500 hover:text-grey-400"
                    : "underline text-grey-400 hover:text-grey-500 "
                } overflow-hidden whitespace-nowrap overflow-ellipsis w-[110px] max-w-[110px] text-left`}
              >
                {text ?? "-"}
              </TypographyUI>
            </div>
          </ButtonUI>
        )}
        {/* {otherParties.length === 1 && !burned && <AiOutlineBlock className={classes.logo} />}
        {burned && otherParties.length === 1 && <FaBurn className={classes.burned} />} */}
      </p>
    </TooltipUI>
  )
}

export default AddressTypography
