import { Box, createStyles, makeStyles, Typography } from "@material-ui/core"
import BigNumber from "bignumber.js"
import React from "react"

import ProrationDetails from "scenes/Billing/Checkout/prorationDetails"
import { GetFeatureAddonDto, SubscriptionPayingDetailsPeriodTypeEnum } from "services/api/openapi"
import { ExtendedLimitAddon } from "./PricingCalculator"

interface Props {
  totalUsdPricePerMonth: BigNumber
  totalUsdPricePerYear: BigNumber
  period: SubscriptionPayingDetailsPeriodTypeEnum
  featureAddons: GetFeatureAddonDto[]
  limitAddons: ExtendedLimitAddon[]
  planId: string
  isError?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    checkoutBox: {
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
  }),
)

const DisplayPricing = ({
  totalUsdPricePerMonth,
  totalUsdPricePerYear,
  period,
  planId,
  limitAddons,
  isError,
}: Props): JSX.Element => {
  const classes = useStyles()

  if (isError) {
    return (
      <Box height="100%" p={2}>
        <Box className={classes.checkoutBox}>
          <Typography variant="h4" color="error">
            $- USD / {period === "monthly" ? "Month" : "Year"}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box height="100%" p={2}>
      <Box className={classes.checkoutBox}>
        {period === "monthly" && (
          <>
            <Typography variant="h4">${totalUsdPricePerMonth.toFixed(2)} USD / Month</Typography>
            <ProrationDetails
              planId={planId}
              limitAddons={limitAddons?.map((x) => ({
                id: x.id,
                additionalCount: x.additionalRequired,
              }))}
              totalUsdPrice={totalUsdPricePerMonth}
            />
          </>
        )}
        {period === "yearly" && (
          <>
            <Typography variant="h4">${totalUsdPricePerYear.toFixed(2)} USD / Year</Typography>
            <ProrationDetails
              planId={planId}
              limitAddons={limitAddons?.map((x) => ({
                id: x.id,
                additionalCount: x.additionalRequired,
              }))}
              totalUsdPrice={totalUsdPricePerYear}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

export default DisplayPricing
