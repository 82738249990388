import { Box, Grid } from "@material-ui/core"
import React from "react"

import BaseContainer from "components/Container"
import MainTitleView from "components/MainTitleView"
import LimitationAlmostReachedTable from "./LimitationAlmostReachedTable"
import LimitationFreeTrialTable from "./LimitationFreeTrialTable"

const BackOfficeLimitationAlmostReachedScene = (): JSX.Element => {
  return (
    <BaseContainer>
      <Box mb={2}>
        <MainTitleView title="Contractual limitations almost reached" />
      </Box>

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <LimitationFreeTrialTable />
              </Grid>
              <Box mt={3}>
                <Grid item xs={12}>
                  <LimitationAlmostReachedTable
                    limitationType="csv_wallet"
                    limitationIdentifier="CSV wallet"
                    limitationColumn="CSV"
                    defaultMaxRemainingUsage={2}
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <LimitationAlmostReachedTable
                  limitationType="transaction"
                  limitationIdentifier="Transaction count"
                  limitationColumn="transactions"
                  defaultMaxRemainingUsage={100}
                />
              </Grid>
              <Box mt={3}>
                <Grid item xs={12}>
                  <LimitationAlmostReachedTable
                    limitationType="user_per_workspace"
                    limitationIdentifier="Number of users"
                    limitationColumn="user spot"
                    defaultMaxRemainingUsage={1}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </BaseContainer>
  )
}

export default BackOfficeLimitationAlmostReachedScene
