import { useTypedController } from "@hookform/strictly-typed"
import { Box, DialogActions, FormLabel, TextField, Typography } from "@material-ui/core"
import { checkImportFormat } from "pure-shared"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import DateRangePicker, { DateRange, PlainDate } from "components/DateRangePicker"

import { useLoadingButton } from "components/LoadingButton"
import useDialog from "components/misc/useDialog"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { GetWalletTypeDto } from "services/api/openapi"
import { Mixpanel } from "services/mixpanel"
import { AdditionalInfoBanners } from "../AdditionalInfoBanners"
import BasicString from "./Fields/BasicString"
import FileCustom from "./Fields/FileCustom"
import HiddenString from "./Fields/HiddenString"
import dayjs from "dayjs"
import { WorkspaceContext } from "services/context/workspaceContext"
import WarningTypography from "../../../../components/WarningTypography"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface Props {
  selectedSource: GetWalletTypeDto
  setSelectedSource: (source: GetWalletTypeDto | undefined) => void
  onClose?: () => void
}

type GenericFieldSourceForm = {
  name: string
  dateRange: DateRange
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

export default function GenericFieldSource({ selectedSource, setSelectedSource, onClose }: Props) {
  const { handleSubmit, control, formState, watch } = useForm({ mode: "onChange" })
  const TypedController = useTypedController<GenericFieldSourceForm>({ control })
  const basicDialog = useDialog()
  const { mutateAsync: createBatchWallets } = api.wallet.useCreateBatchWallets()
  const { mutateAsync: replaceCsv } = api.wallet.useReplaceCSVWithError()
  const [csvFile, setCsvFile] = useState<File | null>(null)
  const { workspace } = useContext(WorkspaceContext)
  const toast = useToast()
  const [ImportButton, handleButtonCallback] = useLoadingButton()
  const watchAllFields = watch()

  const submitImport = async ({ dateRange, name, ...credentials }: GenericFieldSourceForm) => {
    if (!selectedSource) return
    try {
      const startDate = dateRange?.startDate?.toBackendDate() ?? null
      const endDate = dateRange?.endDate?.toBackendDate() ?? null
      const typeId = selectedSource.id

      const result = await createBatchWallets({
        createBatchWalletsDto: {
          wallets: [{ name, typeId, startDate, endDate, credentials }],
        },
      })

      if (csvFile) {
        await replaceCsv({
          walletId: result.ids[0],
          file: csvFile,
        })
      }

      if (selectedSource.type == "custom" && !csvFile) {
        toast.open(`No file has been uploaded`, {
          variant: "warning",
        })
      }

      toast.open(`Source ${name} created`, {
        variant: "success",
      })

      if (onClose) onClose()
    } catch (e) {
      toastCatch(e, toast)
    }
    Mixpanel.track("WalletCreated", {
      sourceType: selectedSource.type,
      sourceName: selectedSource.name,
    })
  }

  const showConfirmation = (form: any) => {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography style={{ fontWeight: "bold" }}>
          Your input seems to be incorrect. The import will probably fail. Are you sure you want to continue?
        </Typography>
      ),
      yesText: "Yes",
      noText: "No",
      onAccept: () => {
        submitImport(form)
      },
    })
  }

  const isLockingConflicting =
    workspace.lock.isEnabled &&
    dayjs.utc(watchAllFields?.dateRange?.startDate?.toBackendDate().valueOf()) < dayjs(workspace.lock.exclusiveEndDate)
  const defaultValue = workspace.lock.isEnabled
    ? { startDate: PlainDate.fromBackendDate(workspace?.lock.exclusivePlainEndDate ?? ""), endDate: undefined }
    : {}

  const importWallet = async (form: any) => {
    if (!checkImportFormat(selectedSource.name, form)) {
      showConfirmation(form)
    } else {
      submitImport(form)
    }
  }

  const importFormId = "wallet-import-form"
  if (!selectedSource.credentialFields) return <></>
  const formFields = selectedSource.credentialFields.map((field) => {
    switch (field.type) {
      case "string":
        return <BasicString key={field.name} isRequired={field.isRequired} field={field} control={control} />
      case "hidden-string":
        return <HiddenString isRequired={field.isRequired} key={field.name} field={field} control={control} />
      default:
        return (
          <FileCustom
            isRequired={field.isRequired}
            key={field.name}
            selectedSource={selectedSource}
            setCsvFile={setCsvFile}
            csvFile={csvFile}
          />
        )
    }
  })

  return (
    <>
      {basicDialog.dialog}
      <form
        // Use react hook form controller
        id={importFormId}
        onSubmit={handleButtonCallback(handleSubmit(importWallet))}
      >
        <Box mt={2}>
          <AdditionalInfoBanners sourceName={selectedSource.name} />
        </Box>
        <Box mt={4}>
          <FormLabel htmlFor="name-textfield">Name</FormLabel>
          <TypedController
            name="name"
            defaultValue=""
            rules={{ required: true, minLength: 1 }}
            render={(props) => <TextField {...props} id="name-textfield" fullWidth margin="normal" />}
          />
          {formFields}
        </Box>
        <TypedController
          name="dateRange"
          defaultValue={defaultValue}
          rules={{
            required: false,
            validate: (dateRange) =>
              (!dateRange.startDate || dateRange.startDate.isValid) &&
              (!dateRange.endDate || dateRange.endDate.isValid) &&
              !isLockingConflicting,
          }}
          render={({ value, onChange }) => (
            <DateRangePicker
              value={value}
              onChange={onChange}
              fullHistoryButtonText="Fetch complete history"
              disabledSwitch={!!(workspace.lock.isEnabled && workspace.lock.exclusivePlainEndDate)}
            />
          )}
        />
        {isLockingConflicting && (
          <div className="mt-4">
            <WarningTypography>
              Some transactions occurred during a period that is currently locked in your workspace. If you want to
              include them, please unlock your period first. Please note that this action will update your cost basis.
            </WarningTypography>
          </div>
        )}
      </form>
      <DialogActions>
        <ButtonUI mode={Mode.CONTAINED} onClick={() => setSelectedSource(undefined)}>
          Previous
        </ButtonUI>
        <PermissionDisabled permission="can_create_wallet" action="import sources">
          <ImportButton disabled={!formState.isValid} type="submit" form={importFormId}>
            Create
          </ImportButton>
        </PermissionDisabled>
      </DialogActions>
    </>
  )
}
