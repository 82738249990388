import React from "react"

import TagUI from "../../../Tag"

interface DatePickerTagProps {
  active: boolean
  label: string
  onClick: () => void
}

const DatePickerTag = ({ active, label, onClick }: DatePickerTagProps): JSX.Element => (
  <TagUI
    label={label}
    onClick={onClick}
    className={
      active ? " !bg-primary-300 !text-white !border-primary-300 hover:!bg-primary-400 hover:!border-primary-400 " : ""
    }
  />
)

export default DatePickerTag
