import React from "react"
import { TextField, Box, Typography } from "@material-ui/core"
import { HelpRounded } from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import api from "services/api"
import NetworkErrorMessage from "../misc/NetworkErrorMessage"
import LoadingSpinner from "../misc/LoadingSpinner"
import { GetCostBasisAlgorithmDtoNameEnum } from "services/api/openapi"
import { WarningAdminFeature } from "../Feature/WarningAdminFeature"
import ConditionalTooltip from "../ConditionalTooltip"
import TooltipUI from "CryptioUI/Tooltip"

interface Props {
  id?: string
  workspaceId?: string
  isWorkspaceLockEnabled?: boolean
  value: GetCostBasisAlgorithmDtoNameEnum
  onChange: (newValue: GetCostBasisAlgorithmDtoNameEnum) => void
}

const WorkspaceAlgorithmSelect = ({
  workspaceId,
  isWorkspaceLockEnabled = false,
  onChange,
  value,
  ...props
}: Props) => {
  const algorithms = api.workspace.useCostBasisAlgorithm(workspaceId)

  if (algorithms.isError) return <NetworkErrorMessage additionalData={algorithms} />

  if (algorithms.isLoading || algorithms.data === undefined || algorithms.data === null) return <LoadingSpinner />

  const algorithm = algorithms.data.find((alg) => alg.name === value)

  return (
    <WarningAdminFeature isAdminFeature={algorithm?.isFeatureEnabledOnlyForAdmin ?? false}>
      <ConditionalTooltip
        tooltipMessage={"The cost basis methodology of a locked workspace can't be edited"}
        disabled={isWorkspaceLockEnabled}
      >
        <Autocomplete
          {...props}
          value={algorithm}
          disabled={isWorkspaceLockEnabled}
          getOptionLabel={(opt) => opt.displayName}
          options={algorithms.data}
          autoComplete
          disableClearable
          includeInputInList
          getOptionSelected={(option, value) => option.name === value.name}
          onChange={(_, newValue) => onChange(newValue.name)}
          renderInput={(params) => <TextField {...params} />}
          renderOption={(option) => (
            <WarningAdminFeature isAdminFeature={option.isFeatureEnabledOnlyForAdmin}>
              <Box display="flex" alignItems="center">
                {option.displayName}
                {!option.name.startsWith("wac") && (
                  <TooltipUI
                    classNameTrigger="ml-4"
                    side="right"
                    content={
                      <Typography variant="body2">
                        Cost basis can either be computed across all wallets (Universal), or separately for each
                        wallet/exchange (Per wallet).
                        <br />
                        Changing this setting may significantly impact your capital gains.
                        {/* TODO: add link */}
                        {/*
                    <br />
                    <ExternalLink href="" underline="always">
                      Learn more
                    </ExternalLink> */}
                      </Typography>
                    }
                  >
                    <HelpRounded />
                  </TooltipUI>
                )}
              </Box>
            </WarningAdminFeature>
          )}
        />
      </ConditionalTooltip>
    </WarningAdminFeature>
  )
}

export default WorkspaceAlgorithmSelect
