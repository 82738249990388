import React from "react"
import { twMerge } from "tailwind-merge"

import { ButtonGroupUIProps, Mode } from "CryptioUI/types"

const ButtonGroupUI = (props: ButtonGroupUIProps): JSX.Element => {
  const { children, mode, disabled, size } = props
  const textOnlyStyle = mode === Mode.TEXTONLY ? "border-r-2 border-grey-200 last:border-none" : ""

  return (
    <div className="flex items-center">
      {/* eslint-disable-next-line complexity */}
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          mode: child.props?.mode ?? mode,
          size: child.props?.size ?? size,
          disabled: child.props?.disabled ?? disabled,
          classNameContainer: twMerge(
            `${
              child.props?.classNameContainer ?? ""
            } child:first:rounded-l-xl child:last:rounded-r-xl ${textOnlyStyle}`,
          ),
          className: twMerge(`${child.props?.className ?? ""} rounded-none focus:!outline-none active:outline-none`),
        }),
      )}
    </div>
  )
}

export default ButtonGroupUI
