import { Grid, TextField, Typography } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import Fuse from "fuse.js"
import { prettyEmptyOrNull } from "pure-shared"
import React, { useMemo } from "react"
import { UseQueryResult } from "react-query"

import api from "services/api"
import { GetAvailableCurrencyDto } from "services/api/openapi"
import NetworkErrorMessage from "./misc/NetworkErrorMessage"

interface Props {
  id?: string
  value: string | null
  onChange: (newCurrencyId: string) => void
  onNetworkError?: (query: UseQueryResult<unknown, unknown>) => void
  disableClearable?: boolean
  disabled?: boolean
}

export const CurrencyField = React.forwardRef((props: Props, ref) => {
  const { onChange, onNetworkError, value, disabled = false, ...rest } = props

  const availableCurrencies = api.user.useAvailableCurrencies()

  const currentCurrency = useMemo(() => {
    return availableCurrencies.data?.find((c) => c.id === value) ?? null
  }, [availableCurrencies, value])

  if (availableCurrencies.isError) {
    if (onNetworkError) {
      onNetworkError(availableCurrencies)
    }
    return <NetworkErrorMessage additionalData={availableCurrencies} />
  }
  if (availableCurrencies.isLoading || availableCurrencies.data === undefined) {
    return <p>Loading...</p>
  }

  const fuse = new Fuse<GetAvailableCurrencyDto>(availableCurrencies.data, {
    keys: ["symbol", "name"],
    shouldSort: true,
    findAllMatches: false,
    threshold: 0.2,
  })

  const filterOptions = (e: { inputValue: string }) => {
    const tmp = fuse.search(e.inputValue)
    if (tmp.length === 0) {
      return availableCurrencies.data ?? []
    }
    return tmp.map((x) => x.item)
  }

  return (
    <Autocomplete
      {...rest}
      value={currentCurrency}
      disabled={disabled}
      openOnFocus
      filterOptions={(_, e) => filterOptions(e)}
      onChange={(_, newValue) => newValue && onChange(newValue.id)}
      getOptionLabel={(label: GetAvailableCurrencyDto) => label.name}
      options={availableCurrencies.data ?? []}
      renderOption={(option) => (
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography variant="body1">
              <span>{option.name}</span>
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {prettyEmptyOrNull(option.symbol)}
            </Typography>
          </Grid>
        </Grid>
      )}
      renderInput={(params) => <TextField {...params} placeholder="Currency" />}
      size="small"
      innerRef={ref}
    />
  )
})

CurrencyField.displayName = "CurrencyField"
