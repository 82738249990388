import { Box } from "@material-ui/core"
import React from "react"
import useDialog from "components/misc/useDialog"
import { DrawerProp } from "components/misc/useDrawer"

import { FullMovementDto } from "services/api/openapi/models"
import MovementForm from "./MovementForm"
import MovementSummary from "./MovementSummary"

const MovementDrawer = (props: DrawerProp<FullMovementDto, false>) => {
  const { item: movement } = props
  const basicDialog = useDialog()

  return (
    <Box mb={3}>
      {basicDialog.dialog}

      <MovementForm {...props} />

      <MovementSummary movement={movement} />
    </Box>
  )
}

export default MovementDrawer
