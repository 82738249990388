import React, { useCallback, useMemo, useState } from "react"

import BasicDialog from "./BasicDialog"

interface DialogData {
  explicit?: boolean
  title: string
  content: React.ReactNode
  noText?: string
  yesText: string
  onCancel?: () => void
  onRefuse?: () => void
  onAccept: () => Promise<void> | void
  className?: string
}

const useDialog = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [data, setData] = useState<DialogData | undefined>(undefined)

  const showDialog = useCallback(
    (data: DialogData) => {
      setData(data)
      setIsOpen(true)
    },
    [setIsOpen],
  )

  const onDialogRefused = useCallback(() => {
    setIsOpen(false)
    data?.onRefuse?.()
  }, [data, setIsOpen])

  const onDialogCancelled = useCallback(() => {
    if (data?.explicit) return
    setIsOpen(false)
    if (data?.onCancel) {
      data?.onCancel?.()
    } else {
      data?.onRefuse?.()
    }
  }, [data, setIsOpen])

  const onDialogAccepted = useCallback(async () => {
    if (data !== undefined) await data.onAccept()
    setIsOpen(false)
  }, [data, setIsOpen])

  const dialog = useMemo(
    () => (
      <BasicDialog
        open={isOpen}
        onAccept={onDialogAccepted}
        onRefuse={onDialogRefused}
        onCancel={onDialogCancelled}
        title={data?.title ?? "Modal"}
        content={data?.content}
        noText={data?.noText}
        yesText={data?.yesText ?? "OK"}
        className={data?.className ?? ""}
      />
    ),
    [data, isOpen, onDialogAccepted, onDialogRefused, onDialogCancelled],
  )

  return {
    dialog,
    showDialog,
    closeDialog: onDialogCancelled,
  }
}

export default useDialog
