import { IconButton, InputAdornment, TextField, TextFieldProps } from "@material-ui/core"
import { ClearRounded } from "@material-ui/icons"
import React, { useRef } from "react"

import isNumberStringValid from "services/utils/isNumberStringValid"

type Props = Omit<TextFieldProps, "onChange" | "value" | "id"> & {
  id?: string
  value: string
  emptyValue?: string | undefined
  onChange: (x: string | undefined) => void
  isValueValid?: (value: string) => boolean
}

const InputNumber = ({ value, isValueValid, emptyValue = undefined, onChange, ...rest }: Props): JSX.Element => {
  const ref = useRef(null)
  return (
    <TextField
      {...rest}
      error={isValueValid ? !isValueValid(value) : Boolean(value && !isNumberStringValid(value))}
      ref={ref}
      value={value}
      onChange={(x) => onChange(x.target.value || emptyValue)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => onChange(emptyValue)} style={{ padding: 0 }}>
              <ClearRounded color="disabled" fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder="0"
      fullWidth
    />
  )
}

export default InputNumber
