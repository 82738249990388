import React, { useContext } from "react"
import { Chip, Grid, TextField, Typography } from "@material-ui/core"
import { isWacMap, REGEX_UUID_V4 } from "pure-shared"
import _ from "lodash"

import {
  FilterTypeWithDate,
  FilterTypeWithoutDate,
  FilterTypeWithoutDateState,
  SpecificFilter,
  TypeMap,
  TypeMapDate,
} from "."

import ContactOptionInAutocomplete from "components/AutoCompleteOptions/ContactOptionInAutocomplete"
import LabelOptionInAutocomplete from "components/AutoCompleteOptions/LabelOptionInAutocomplete"
import WalletOptionInAutocomplete from "components/AutoCompleteOptions/WalletOptionInAutocomplete"
import InputClearable from "components/Input/InputClearable"
import CustomSelector from "components/selector/CustomSelector"
import { White } from "../../../../materialTheme"
import api from "services/api"
import { BasicElement, WithoutWorkspaceId } from "services/api/aliases"
import {
  FilterElement,
  GetContactDto,
  GetContactsRequest,
  GetLabelDto,
  GetLabelsRequest,
  GetUnknownContactDto,
  GetUnknownContactsRequest,
  GetWalletDto,
  GetWalletsRequest,
} from "services/api/openapi"
import AssetFilter from "./forms/AssetFilter"
import ComplexityFilter from "./forms/ComplexityFilter"
import FeeTypeFilter from "./forms/FeeTypeFilter"
import { HasInvoiceFilter } from "./forms/HasInvoiceFilter"
import { OrderTypeFilter, transactionFilterOrderPrettyMap } from "./forms/OrderTypeFilter"
import TransactionCostBasisErrorStatus from "./forms/TransactionCostBasisErrorStatus"
import { TransactionMappingStatusFilter } from "./forms/TransactionMappingStatusFilter"
import TransactionSynchronizationFilter from "./forms/TransactionSynchronizationFilter"
import ValuationStatusFilter from "./forms/ValuationStatusFilter"
import TransactionMappingsFilter from "./forms/TransactionMappingsFilter"
import {
  TransactionFilterInternalTransferStatusValueType,
  UsageTypeAutocomplete,
} from "./forms/InternalTransferStatusFilter"
import { WorkspaceContext } from "services/context/workspaceContext"
import LabelsApplicationFilter from "./forms/LabelsApplicationFilter"
import { Autocomplete } from "@material-ui/lab"
import SynchronizationModeFilter from "./forms/SynchronizationModeFilter"
import ImportTypeFilter, { transactionFilterImportPrettyMap } from "./forms/ImportTypeFilter"
import TooltipUI from "CryptioUI/Tooltip"
import isNumberStringValid from "../../../../services/utils/isNumberStringValid"
import BigNumber from "bignumber.js"
import NftFilter from "./forms/NftFilter"

export type PrettyFilterType = {
  [K in FilterTypeWithoutDate]: {
    key: FilterTypeWithoutDate
    name: string
    render: (props: { value: TypeMap[K]; index: number; disabled?: boolean }) => JSX.Element
    defaultValue: SpecificFilter<K>
    validation?: (value: TypeMap[K]) => boolean
  }
}

export type PrettyFilterDateType = {
  [K in FilterTypeWithDate]: {
    key: FilterTypeWithDate
    name: string
    render: (props: { value: TypeMapDate[K] }) => JSX.Element
    defaultValue: SpecificFilter<K>
    validation?: (value: TypeMapDate[K]) => boolean
  }
}
interface UseFilterMapParams {
  filters: FilterTypeWithoutDateState[]
  setFilters: (newFilter: FilterTypeWithoutDateState[]) => void
}

const isUuid4 = (uuid: string) => REGEX_UUID_V4.test(uuid)

export const useFilterMap = (params: UseFilterMapParams) => {
  const { filters, setFilters } = params
  const { workspace } = useContext(WorkspaceContext)

  const mapFactoryDate: PrettyFilterDateType = {
    start_date: {
      key: "start_date",
      name: "Start Date",
      render: () => <></>,
      defaultValue: { type: "start_date", value: "", isNot: false },
    },
    end_date: {
      key: "end_date",
      name: "End Date",
      render: () => <></>,
      defaultValue: { type: "end_date", value: "", isNot: false },
    },
  }

  const mapFactory: PrettyFilterType = {
    order_type: {
      key: "order_type",
      name: "Order Type",
      render: ({ value, index, disabled }) => {
        return (
          <OrderTypeFilter
            id="order-type-select"
            multiple
            disabled={disabled}
            disableCloseOnSelect
            filterSelectedOptions
            getOptionDisabled={() => value && value?.length >= Object.keys(transactionFilterOrderPrettyMap).length - 1}
            value={value}
            size="small"
            placeholder={value && value.length > 0 ? "" : "No order type selected"}
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "order_type", value: [], isNot: false },
    },
    import_type: {
      key: "import_type",
      name: "Import Type",
      render: ({ value, index, disabled }) => {
        return (
          <ImportTypeFilter
            id="import-type-select"
            multiple
            disabled={disabled}
            disableCloseOnSelect
            filterSelectedOptions
            getOptionDisabled={() => value && value?.length >= Object.keys(transactionFilterImportPrettyMap).length - 1}
            value={value}
            size="small"
            placeholder={value && value.length > 0 ? "" : "No import type selected"}
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "import_type", value: [], isNot: false },
    },
    wallets: {
      key: "wallets",
      name: "Wallets",
      render: ({ value, index, disabled }) => {
        return (
          <CustomSelector<WithoutWorkspaceId<GetWalletsRequest>, BasicElement, true, false, true>
            id="wallets-select"
            value={value}
            multiple
            disabled={disabled}
            placeholder={value && value.length > 0 ? "" : "No wallet selected"}
            defaultPaginatedQueryProps={{
              sortBy: "name",
              sortDirection: "ascending",
            }}
            size="small"
            disableCloseOnSelect
            filterSelectedOptions
            getOptionDisabled={value.length >= 10 ? () => true : () => false}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            onChange={(_e, newValue) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = newValue.map((v) => _.pick(v, ["id", "name"]))
              setFilters(cpy)
            }}
            usePaginatedQuery={api.wallet.useWallets}
            renderOption={(option) => <WalletOptionInAutocomplete wallet={option as GetWalletDto} />}
          />
        )
      },
      defaultValue: { type: "wallets", value: [], isNot: false },
    },
    assets: {
      key: "assets",
      name: "Assets",
      render: ({ value, index, disabled }) => {
        return (
          <AssetFilter
            size="small"
            id="asset-select"
            disabled={disabled}
            placeholder={value && value.length > 0 ? "" : "No asset selected"}
            // @ts-ignore: TODO: add symbol in type
            assets={value}
            onAssetsChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value.map((v) => _.pick(v, ["id", "name"]))
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "assets", value: [], isNot: false },
    },
    labels: {
      key: "labels",
      name: "Labels",
      render: ({ value, index, disabled }) => {
        return (
          <CustomSelector<WithoutWorkspaceId<GetLabelsRequest>["getLabelQuery"], FilterElement, true>
            id="labels-select"
            multiple
            value={value ?? []}
            disabled={disabled}
            defaultPaginatedQueryProps={{
              sortBy: "movement_count",
              sortDirection: "descending",
            }}
            size="small"
            placeholder={value && value.length > 0 ? "" : "All labels"}
            filterSelectedOptions
            getOptionSelected={(option, value) => option.id === value.id}
            disableCloseOnSelect
            getOptionDisabled={value && value.length >= 10 ? () => true : () => false}
            getOptionLabel={(option) => option.name}
            onChange={(_e, newValue) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = newValue.length === 0 ? null : newValue.map((v) => _.pick(v, ["id", "name"]))
              setFilters(cpy)
            }}
            usePaginatedQuery={api.label.useLabels}
            renderOption={(option) => <LabelOptionInAutocomplete label={option as GetLabelDto} />}
          />
        )
      },
      defaultValue: { type: "labels", value: null, isNot: false },
    },
    labels_application: {
      key: "labels_application",
      name: "Labels Application",
      render: ({ value, index, disabled }) => {
        return (
          <LabelsApplicationFilter
            id="labels-application-select"
            value={value}
            disabled={disabled}
            disableClearable={true}
            size="small"
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "labels_application", value: "manualLabel", isNot: false },
    },
    contacts: {
      key: "contacts",
      name: "Contacts",
      render: ({ value, index, disabled }) => {
        return (
          <CustomSelector<WithoutWorkspaceId<GetContactsRequest>, BasicElement, true>
            id="contacts-select"
            multiple
            defaultPaginatedQueryProps={{
              sortBy: "movement_count",
              sortDirection: "descending",
            }}
            size="small"
            disabled={disabled}
            disableCloseOnSelect
            filterSelectedOptions
            placeholder={value && value.length > 0 ? "" : "All contacts"}
            getOptionDisabled={value && value.length >= 10 ? () => true : () => false}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            onChange={(_e, newValue) => {
              const cpy = JSON.parse(JSON.stringify(filters))
              cpy[index].value = newValue.length === 0 ? null : newValue.map((v) => _.pick(v, ["id", "name"]))
              setFilters(cpy)
            }}
            value={value ?? []}
            usePaginatedQuery={api.contact.useContacts}
            renderOption={(option) => <ContactOptionInAutocomplete contact={option as GetContactDto} />}
          />
        )
      },
      defaultValue: { type: "contacts", value: null, isNot: false },
    },
    addresses: {
      key: "addresses",
      name: "Addresses",
      render: ({ value, index, disabled }) => {
        return (
          <CustomSelector<GetUnknownContactsRequest["getUnknownContactQuery"], GetUnknownContactDto | string, true>
            value={value ?? []}
            id="address-select"
            disabled={disabled}
            getOptionLabel={(option) => {
              if (typeof option === "string") return option as string
              return (option as GetUnknownContactDto).address
            }}
            getOptionSelected={(option, value) => {
              const optionAddress = typeof option === "string" ? option : option.address
              const valueAddress = typeof value === "string" ? value : value.address
              return optionAddress === valueAddress
            }}
            disableCloseOnSelect
            onChange={(_, newValue) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value =
                newValue.length === 0 ? null : newValue.map((n) => (typeof n === "string" ? n : n.address))
              setFilters(cpy)
            }}
            filterSelectedOptions
            placeholder={value && value.length > 0 ? "" : "All addresses"}
            defaultPaginatedQueryProps={{}}
            usePaginatedQuery={api.contact.useUnknownContacts}
            size="small"
            multiple
            renderOption={(option) => {
              if (typeof option === "string") return `Add ${option}`
              return (
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography component="span" variant="body1">
                      {option.address}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {option.movementCount} occurrence
                    </Typography>
                  </Grid>
                </Grid>
              )
            }}
          />
        )
      },
      defaultValue: { type: "addresses", value: null, isNot: false },
    },
    transaction_mapping_status: {
      key: "transaction_mapping_status",
      name: "Transactions Mapping",
      render: ({ value, index, disabled }) => {
        return (
          <TransactionMappingsFilter
            id="accounting-status-select"
            value={value}
            disabled={disabled}
            disableClearable={true}
            size="small"
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "transaction_mapping_status", value: "onlyManuallyMapped", isNot: false },
    },
    transaction_hash: {
      key: "transaction_hash",
      name: "Transaction Hash",
      render: ({ value, index, disabled }) => {
        return (
          <Autocomplete
            multiple
            id="tags-filled"
            disabled={disabled}
            defaultValue={value ?? undefined}
            freeSolo
            size="small"
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip size="small" variant="default" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TooltipUI content="Input a transaction hash and press Enter. Multiple transaction hashes can be filtered at once.">
                <TextField {...params} placeholder={value && value.length > 0 ? "" : "No transaction hash"} />
              </TooltipUI>
            )}
            onChange={(_, value: string[]) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value.length === 0 ? null : value
              setFilters(cpy)
            }}
            options={[]}
          />
        )
      },
      defaultValue: { type: "transaction_hash", value: null, isNot: false },
    },
    note: {
      key: "note",
      name: "Note",
      render: ({ value, index, disabled }) => {
        return (
          <InputClearable
            size="small"
            id="notes-textfield"
            disabled={disabled}
            value={value ?? ""}
            placeholder={value && value.length > 0 ? "" : "No note"}
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value.length === 0 ? null : value
              setFilters(cpy)
            }}
            fullWidth
          />
        )
      },
      defaultValue: { type: "note", value: null, isNot: false },
    },
    valuation_status: {
      key: "valuation_status",
      name: "Valuation Status",
      render: ({ value, index, disabled }) => {
        return (
          <ValuationStatusFilter
            id="valuation-status-select"
            value={value}
            disabled={disabled}
            size="small"
            disableClearable={true}
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "valuation_status", value: "noValuation", isNot: false },
    },
    cost_basis_error_statuses: {
      key: "cost_basis_error_statuses",
      name: "Cost Basis Error",
      render: ({ value, index, disabled }) => {
        return (
          <TransactionCostBasisErrorStatus
            id="cost-basis-error-select"
            multiple
            disableCloseOnSelect
            placeholder={value && value.length > 0 ? "" : "Any error"}
            filterSelectedOptions
            disabled={disabled}
            filterOptions={
              isWacMap[workspace.costBasisAlgorithmName]
                ? undefined
                : (options) => options.filter((opt) => opt !== "impacted_by_missing_price")
            }
            // @ts-ignore: todo: remove missing_fiat_rate from enum type
            value={value ?? []}
            size="small"
            onChange={(newValue) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = newValue === null || newValue.length === 0 ? null : newValue
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "cost_basis_error_statuses", value: null, isNot: false },
    },
    internal_transfer_status: {
      key: "internal_transfer_status",
      name: "Internal Transfer Status",
      render: ({ value, index, disabled }) => {
        return (
          <UsageTypeAutocomplete
            id={"viewed-not-viewed"}
            style={{ backgroundColor: White }}
            value={value as TransactionFilterInternalTransferStatusValueType}
            size="small"
            disabled={disabled}
            renderProps={{
              variant: "standard",
            }}
            disableClearable={true}
            onChange={(newValue) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = newValue
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "internal_transfer_status", value: "internalTransfer", isNot: false },
    },
    nft: {
      key: "nft",
      name: "NFT",
      render: ({ value, index, disabled }) => {
        return (
          <NftFilter
            id="nft-select"
            value={value}
            disabled={disabled}
            size="small"
            disableClearable={true}
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "nft", value: "allNfts", isNot: false },
    },
    accounting_mapping_status: {
      key: "accounting_mapping_status",
      name: "Chart Of Accounts",
      render: ({ value, index, disabled }) => {
        return (
          <TransactionMappingStatusFilter
            id="mapping-status-select"
            value={value}
            size="small"
            disabled={disabled}
            disableClearable={true}
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "accounting_mapping_status", value: "completeMapping", isNot: false },
    },
    fee_type: {
      key: "fee_type",
      name: "Fees",
      render: ({ value, index, disabled }) => {
        return (
          <FeeTypeFilter
            id="fee-type-select"
            value={value}
            disabled={disabled}
            disableClearable={true}
            size="small"
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "fee_type", value: "transactionWithFee", isNot: false },
    },
    relative_fiat_value: {
      key: "relative_fiat_value",
      name: "Relative Fiat Value",
      render: ({ value, index, disabled }) => {
        return (
          <InputClearable
            error={value === null || !isNumberStringValid(value)}
            size="small"
            disabled={disabled}
            type="number"
            id="relative-fiat-value"
            helperText={value === null ? "The value cannot be empty" : ""}
            value={value ?? ""}
            placeholder={value ?? ""}
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
            fullWidth
          />
        )
      },
      defaultValue: { type: "relative_fiat_value", value: "0", isNot: false },
      validation: (value) => value !== null && isNumberStringValid(value),
    },
    range_fiat_value: {
      key: "range_fiat_value",
      name: "Range Fiat Value",
      render: ({ value, index, disabled }) => {
        return (
          <>
            <InputClearable
              error={value.min === null || !isNumberStringValid(value.min)}
              size="small"
              disabled={disabled}
              type="number"
              id="range-fiat-value-min"
              helperText={value.min === null ? "The value cannot be empty" : ""}
              value={value.min ?? ""}
              placeholder={value.min ?? ""}
              onChange={(value) => {
                const cpy = JSON.parse(JSON.stringify(filters))

                cpy[index].value.min = value
                setFilters(cpy)
              }}
              fullWidth
            />
            <InputClearable
              error={value.max === null || !isNumberStringValid(value.max)}
              size="small"
              disabled={disabled}
              type="number"
              id="range-fiat-value-max"
              helperText={value.max === null ? "The value cannot be empty" : ""}
              value={value.max ?? ""}
              placeholder={value.max ?? ""}
              onChange={(value) => {
                const cpy = JSON.parse(JSON.stringify(filters))

                cpy[index].value.max = value
                setFilters(cpy)
              }}
              fullWidth
            />
          </>
        )
      },
      defaultValue: { type: "range_fiat_value", value: { min: "0", max: "0" }, isNot: false },
      validation: (value) =>
        isNumberStringValid(value.min) &&
        isNumberStringValid(value.max) &&
        new BigNumber(value.min).isLessThanOrEqualTo(value.max),
    },
    complexity: {
      key: "complexity",
      name: "Transaction Type",
      render: ({ value, index, disabled }) => {
        return (
          <ComplexityFilter
            id="complexity-select"
            value={value}
            disabled={disabled}
            disableClearable={true}
            size="small"
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "complexity", value: "simple", isNot: false },
    },
    transaction_volume: {
      key: "transaction_volume",
      name: "Transaction Volume",
      render: ({ value, index, disabled }) => {
        return (
          <InputClearable
            error={value === null}
            size="small"
            type="number"
            id="transaction-volume"
            disabled={disabled}
            helperText={value === null ? "The value cannot be empty" : ""}
            value={value ?? ""}
            placeholder={value ?? ""}
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
            fullWidth
          />
        )
      },
      defaultValue: { type: "transaction_volume", value: "0", isNot: false },
    },
    synchronization_status: {
      key: "synchronization_status",
      name: "Synchronization Status",
      render: ({ value, index, disabled }) => {
        return (
          <TransactionSynchronizationFilter
            id="transaction-synchronization-status-select"
            value={value}
            size="small"
            disabled={disabled}
            disableClearable={true}
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "synchronization_status", value: "synced", isNot: false },
    },
    synchronization_mode: {
      key: "synchronization_mode",
      name: "Synchronization Mode",
      render: ({ value, index, disabled }) => {
        return (
          <SynchronizationModeFilter
            id="synchronization-mode-select"
            value={value}
            disableClearable={true}
            size="small"
            disabled={disabled}
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "synchronization_mode", value: "manual", isNot: false },
    },
    has_invoice: {
      key: "has_invoice",
      name: "Has Invoice",
      render: ({ value, index, disabled }) => {
        return (
          <HasInvoiceFilter
            id="has-filter-select"
            value={value}
            disabled={disabled}
            placeholder={value && value.length > 0 ? "" : "No invoice selected"}
            size="small"
            disableClearable={true}
            onChange={(value) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value
              setFilters(cpy)
            }}
          />
        )
      },
      defaultValue: { type: "has_invoice", value: "no", isNot: false },
    },
    transaction_id: {
      key: "transaction_id",
      name: "Transaction ID",
      render: ({ value, index, disabled }) => {
        return (
          <Autocomplete
            multiple
            id="txid-textfield"
            disabled={disabled}
            defaultValue={value}
            freeSolo
            size="small"
            renderTags={(value: string[], getTagProps) =>
              value.map((option, index) => (
                <Chip size="small" variant="default" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TooltipUI content="Input a transaction ID and press Enter. Multiple transaction ids can be filtered at once.">
                <TextField {...params} placeholder={value?.length > 0 ? "" : "The value cannot be empty"} />
              </TooltipUI>
            )}
            onChange={(_, value: string[]) => {
              const cpy = JSON.parse(JSON.stringify(filters))

              cpy[index].value = value.length === 0 ? null : value
              setFilters(cpy)
            }}
            options={[]}
          />
        )
      },
      defaultValue: { type: "transaction_id", value: [], isNot: false },
      validation: (value) => value?.length > 0 && value.every(isUuid4),
    },
  }

  return { mapFactory, mapFactoryDate }
}
