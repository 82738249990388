import { Box, makeStyles, MenuItem, Select } from "@material-ui/core"
import { newColorHex, newColorNames } from "CryptioUI/types/tag"
import React from "react"

import { LabelCircleColor } from "./LabelCircleColor"

interface Props {
  value: string
  onChange: (color: string) => void
}

const useStyles = makeStyles((/* theme: Theme */) => ({
  selectColorItem: {
    display: "flex",
    justifyContent: "center",
  },
  displayColorItem: {
    height: "1em",
  },
}))

const LabelColorSelector = ({ value, onChange }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Select
      value={value}
      onChange={(x) => onChange(x.target.value as string)}
      labelId="demo-simple-select-filled-label"
      id="demo-simple-select-filled"
      renderValue={(value) => (
        <Box display="flex" className={classes.displayColorItem} justifyContent="center">
          <LabelCircleColor color={value as string} />
        </Box>
      )}
    >
      {newColorNames.map((option) => (
        <MenuItem value={newColorHex[option]} key={newColorHex[option]} className={classes.selectColorItem}>
          <LabelCircleColor color={newColorHex[option]} />
        </MenuItem>
      ))}
    </Select>
  )
}

export default LabelColorSelector
