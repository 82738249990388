import { useTypedController } from "@hookform/strictly-typed"
import { ReportFormComponentProps } from "."
import WalletOptionInAutocomplete from "components/AutoCompleteOptions/WalletOptionInAutocomplete"
import { DrawerFormSection } from "components/Drawer/DrawerItems"
import CustomSelector from "components/selector/CustomSelector"
import api from "services/api"
import { DrawerReportForm } from "../ReportForm"

const ReportSingleSelectWallet = ({ control }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })

  return (
    <DrawerFormSection htmlFor="source-select" name="Wallet">
      <TypedController
        name="wallets"
        defaultValue={[]}
        rules={{ required: true, validate: (t) => Boolean(t && t.length === 1) }}
        render={({ ref, onChange, value, ...rest }) => (
          <CustomSelector
            id="source-select"
            {...rest}
            value={value?.[0] ?? null}
            inputRef={ref}
            onChange={(_, newValue) => onChange(newValue ? [newValue] : null)}
            getOptionLabel={(option) => option.name}
            defaultPaginatedQueryProps={{
              sortBy: "name",
              sortDirection: "ascending",
              excludedIds: value?.[0] ? [value[0].id] : undefined,
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            usePaginatedQuery={api.wallet.useWallets}
            size="small"
            filterSelectedOptions
            renderOption={(option) => <WalletOptionInAutocomplete wallet={option} />}
          />
        )}
      />
    </DrawerFormSection>
  )
}

export default ReportSingleSelectWallet
