import React from "react"
import { css } from "@emotion/css"

import emotionTheme from "../../../emotionTheme"
import { Dates } from "../../types"

export interface DatePickerTriggerProps {
  dates: Dates
}

const DatePickerTrigger = ({ dates }: DatePickerTriggerProps): JSX.Element => {
  const generateInputText = (): string => {
    const generateStart = () => {
      return `${
        dates.startDate && dates.startDate !== dates.endDate ? `Start: ${dates.startDate.format("MM/DD/YYYY")}` : ""
      }`
    }
    const generateEnd = () => {
      return `${dates.endDate && dates.endDate !== dates.startDate ? `End: ${dates.endDate.format("MM/DD/YYYY")}` : ""}`
    }
    const generateArrow = () => {
      return `${dates.startDate && dates.endDate && dates.endDate !== dates.startDate ? " -> " : ""}`
    }
    if (!dates.startDate && !dates.endDate) return "Select a period"
    return `${generateStart()} ${generateArrow()} ${generateEnd()}`
  }

  return (
    <div
      data-test-id="openDatePicker"
      className={css`
        font-family: "Inter", sans-serif;
        color: ${emotionTheme.colors.grey[400]};
        background: ${emotionTheme.colors.white};
        box-shadow: 0px 3px 6px -2px rgba(59, 59, 95, 0.08);
        border-radius: 8px;
        border: 1px solid #e9eaf0;
        font-weight: 500;
        font-size: 14px;
        padding: 8px 12px;
        display: inline-block;
      `}
    >
      {generateInputText()}
    </div>
  )
}

export default DatePickerTrigger
