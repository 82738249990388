import { Typography } from "@material-ui/core"
import dayjs from "dayjs"
import React from "react"

import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import CopyableText from "components/misc/copyableText"
import { BackOfficeGetFullUserDto } from "services/api/openapi/models"
import { pluralize } from "services/utils/textUtils"

const BackOfficeUserSummary = ({ user }: { user: BackOfficeGetFullUserDto }) => {
  const unknownText = "<Unknown>"

  return (
    <DrawerCategory title="User Summary">
      <DrawerSection name="Name">
        <Typography variant="body2" color="textSecondary">
          {user.firstName ?? unknownText} {user.lastName ?? unknownText}
        </Typography>
      </DrawerSection>

      <DrawerSection name="Company">
        <Typography variant="body2" color="textSecondary">
          {user.company ?? unknownText}
        </Typography>
      </DrawerSection>

      <DrawerSection name={`Email (${user.emailConfirmed ? "" : "not "}confirmed)`}>
        <Typography variant="body2" color="textSecondary">
          <CopyableText>{user.email}</CopyableText>
        </Typography>
      </DrawerSection>

      <DrawerSection name="User ID">
        <Typography variant="body2" color="textSecondary">
          <CopyableText>{user.id}</CopyableText>
        </Typography>
      </DrawerSection>

      <DrawerSection name="Inferred Country">
        <Typography variant="body2" color="textSecondary">
          {user.inferredCountry}
        </Typography>
      </DrawerSection>

      <DrawerSection name="2FA">
        <Typography variant="body2" color="textSecondary">
          {user.is2Fa ? "Enabled" : "Disabled"}
        </Typography>
      </DrawerSection>

      <DrawerSection name="Role">
        <Typography variant="body2" color="textSecondary">
          User role is {user.role}
        </Typography>
      </DrawerSection>

      <DrawerSection name="Registration Date">
        <Typography variant="body2" color="textSecondary">
          {dayjs(user.createdAt).fromNow()} ({dayjs(user.createdAt).tz().format("YYYY/MM/DD hh:mm A")})
        </Typography>
      </DrawerSection>

      <DrawerSection name="Last Session">
        <Typography variant="body2" color="textSecondary">
          {dayjs(user.lastLoggedInAt).tz().format("YYYY/MM/DD hh:mm A")}
        </Typography>
      </DrawerSection>

      <DrawerSection name="Sessions Count">
        <Typography variant="body2" color="textSecondary">
          This user logged {user.loginCount} {pluralize(user.loginCount > 1, "time")} into Cryptio
        </Typography>
      </DrawerSection>
    </DrawerCategory>
  )
}

export default BackOfficeUserSummary
