import React, { useContext } from "react"

import { GetWorkspaceDtoFeaturesEnum } from "services/api/openapi/models"
import { WorkspaceContext } from "services/context/workspaceContext"
import ChildrenProps from "services/misc/childrenProps"
import { WarningAdminFeature } from "./WarningAdminFeature"
import { UserContext } from "../../services/context/userContext"
import BackOfficeView from "../misc/BackOfficeView"

interface FeatureGateProps extends ChildrenProps {
  feature?: GetWorkspaceDtoFeaturesEnum
  fallback?: React.ReactNode
  fullWidth?: boolean
}

export function FeatureGate({ feature, children, fallback, fullWidth = true }: FeatureGateProps): JSX.Element {
  const workspaceCtx = useContext(WorkspaceContext)
  const userCtx = useContext(UserContext)

  if (!feature) {
    return <>{children}</>
  }

  const isFeatureEnabled = workspaceCtx.workspace.features.includes(feature)
  const isAdmin =
    userCtx.user.role === "tech_administrator" ||
    userCtx.user.role === "business_administrator" ||
    userCtx.user.role === "reporter"

  if (isFeatureEnabled) return <>{children}</>
  if (isAdmin) {
    return (
      <BackOfficeView>
        <WarningAdminFeature fullWidth={fullWidth} isAdminFeature>
          {children}
        </WarningAdminFeature>
      </BackOfficeView>
    )
  }

  return <>{fallback}</>
}
