import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import { ExitToAppRounded } from "@material-ui/icons"
import React from "react"

import ExternalLink from "components/ExternalLink"
import { Mode } from "CryptioUI/types"
import { GetWalletTypeDto } from "services/api/openapi"
import InputButtonUI from "CryptioUI/InputButton"
import { useToast } from "CryptioUI/Toaster"

const useStyles = makeStyles((theme: Theme) => ({
  fileUploadField: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  attachCsvFileText: {
    marginTop: theme.spacing(2),
  },
  externalLink: {
    padding: 0,
    marginTop: theme.spacing(3),
    textAlign: "end",
  },
  csvExampleMenuItem: {
    padding: 0,
    marginTop: theme.spacing(3),
    textAlign: "end",
  },
  linkIcon: {
    display: "inline-flex",
    verticalAlign: "top",
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  selectedSource: GetWalletTypeDto
  setCsvFile: (file: File) => void
  isRequired: boolean
  csvFile: File | null
}

export default function FileCustom({ selectedSource, setCsvFile, csvFile, isRequired }: Props) {
  const classes = useStyles()
  const toast = useToast()
  const isRequiredName = () => (!isRequired ? " (optional)" : "")

  // const [ImportButton, handleButtonCallback] = useLoadingButton()
  // const { handleSubmit, control, watch } = useForm<WalletImportForm>({ mode: "onChange" })
  // const TypedController = useTypedController<WalletImportForm>({ control })
  // const { mutateAsync: createCustomWalletMutation } = api.wallet.useCreateCustomWallet()

  // const submitImport = async ({ name }: WalletImportForm) => {
  //   try {
  //     if (!csvFile) {
  //       return
  //     }
  //     await createCustomWalletMutation({
  //       name,
  //       sourceId: selectedSource.id,
  //       file: csvFile,
  //     })
  //     if (onClose) onClose()
  //   } catch (e) {
  //     toastCatch(e, toast)
  //   }
  //   Mixpanel.track("WalletCreated", { sourceType: selectedSource.kind, sourceName: selectedSource.name })
  // }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFile = event.target.files.item(0) as File
      // 500mb
      if (newFile.size > 500e6) {
        toast.open("File exceeds 500MB", { variant: "danger" })
      } else {
        setCsvFile(newFile)
      }
    }
  }

  return (
    <Box mt={2}>
      <Typography variant="subtitle1" className={classes.attachCsvFileText}>
        (format csv, 500MB max) {isRequiredName()}
      </Typography>
      <InputButtonUI
        htmlFor="customCsv"
        mode={Mode.CONTAINED}
        fullWidth={true}
        type="file"
        accept=".csv"
        name="customCsv"
        id="customCsv"
        onChange={handleFileChange}
      >
        {csvFile ? `${csvFile.name} (${(csvFile.size / 1e6).toFixed(2)}MB)` : "Choose csv file"}
      </InputButtonUI>
      {selectedSource.name === "Custom" && (
        <Box display="flex" justifyContent="space-around">
          <ExternalLink
            className={classes.csvExampleMenuItem}
            href={"https://docs.google.com/spreadsheets/d/1ve3usOD7gpnF6Q4nm8_RWzTHlgpCKyURpkeNdxW_I6k"}
          >
            See CSV example
            <ExitToAppRounded className={classes.linkIcon} />
          </ExternalLink>

          <ExternalLink className={classes.externalLink} href={`/assets`}>
            See asset list
          </ExternalLink>
        </Box>
      )}
    </Box>
  )
}
