import { BoldWeight, TypographyProps, TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import { iconStyleBlack } from "CryptioUI/Utilities/config"
import { ReactComponent as Help } from "CryptioUI/assets/icons/help-circle.svg"
import TooltipUI from "CryptioUI/Tooltip"

interface AddendsType {
  addendsTitle: string
  addendsValue: string | null
  tooltipInfo?: string | undefined
  isNegative?: boolean
}

interface SumType {
  sumTitle: string
  sumValue: string | null
  tooltipInfo?: string | undefined
}

interface SumViewProps {
  addends: AddendsType[]
  sum: SumType
}

interface TypoWithOptionalTooltip extends TypographyProps {
  children: string
  tooltipInfo?: string
}

const SumView = (props: SumViewProps) => {
  const { addends, sum } = props

  const TypoWithOptionalTooltip = ({ children, tooltipInfo, ...rest }: TypoWithOptionalTooltip) => {
    return (
      <div className="flex flex-row justify-start items-center gap-2">
        <TypographyUI {...rest}>{children}</TypographyUI>
        {tooltipInfo && (
          <TooltipUI side="right" content={tooltipInfo}>
            <Help className={iconStyleBlack} />
          </TooltipUI>
        )}
      </div>
    )
  }

  return (
    <div className="w-1/2 flex flex-col gap-4">
      {addends.map((addend) => {
        const isNegative = addend.addendsValue !== "0" && addend.isNegative ? true : false

        return (
          <div className="flex flex-row justify-between">
            <TypoWithOptionalTooltip
              tooltipInfo={addend.tooltipInfo}
              boldness={BoldWeight.REGULAR}
              variant={TypographyVariant.BODY}
            >
              {addend.addendsTitle}
            </TypoWithOptionalTooltip>
            <TypographyUI
              boldness={BoldWeight.REGULAR}
              variant={TypographyVariant.BODY}
              className={isNegative ? "text-danger-300" : ""}
            >
              {addend.addendsValue ? `$ ${addend.addendsValue}` : "- -"}
            </TypographyUI>
          </div>
        )
      })}
      <div className="h-px bg-grey-300 w-full opacity-50" />
      <div className="flex flex-row justify-between">
        <TypoWithOptionalTooltip
          tooltipInfo={sum.tooltipInfo}
          boldness={BoldWeight.MEDIUM}
          variant={TypographyVariant.BODY}
        >
          {sum.sumTitle}
        </TypoWithOptionalTooltip>
        <TypographyUI boldness={BoldWeight.MEDIUM} variant={TypographyVariant.BODY}>
          {sum.sumValue ? `$ ${sum.sumValue}` : "- -"}
        </TypographyUI>
      </div>
    </div>
  )
}

export default SumView
