import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import { HelpRounded } from "@material-ui/icons"
import TooltipUI from "CryptioUI/Tooltip"
import React from "react"

import { openInNewTab } from "services/utils/openInNewTab"
import { pluralize } from "services/utils/textUtils"

interface Props {
  title: React.ReactNode
  totalCount: number
  selectedCount?: number
  qualifier: string
  qualifierSuffix?: string
  helpTooltipContent?: string
  helpTooltipUrl?: string
  onClick?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tooltipIcon: {
    marginLeft: theme.spacing(1.5),
  },
  helpButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  title: {
    fontWeight: 400,
    fontSize: "2.5rem",
  },
}))

const TableTitleWithTotalCount = (props: Props) => {
  const classes = useStyles()
  const countId = props.qualifier.replace(" ", "-") + "-count"

  return (
    <Box>
      <Box className={classes.outerContainer}>
        <Typography variant="h1" className={classes.title}>
          {props.title}
        </Typography>
        {props.helpTooltipContent !== undefined && (
          <TooltipUI
            classNameTrigger={classes.tooltipIcon}
            content={<Typography>{props.helpTooltipContent}</Typography>}
          >
            <HelpRounded
              className={props.helpTooltipUrl ? classes.helpButton : ""}
              onClick={() => {
                props.helpTooltipUrl && openInNewTab(props.helpTooltipUrl)
                props.onClick && props.onClick()
              }}
            />
          </TooltipUI>
        )}
      </Box>
      <Typography id={countId} variant="body1">
        {props.totalCount} {pluralize(props.totalCount > 1, props.qualifier, props.qualifierSuffix)}
        {props.selectedCount ? <>{` - ${props.selectedCount} selected`}</> : null}
      </Typography>
    </Box>
  )
}

export default TableTitleWithTotalCount
