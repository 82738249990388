import { Box, DialogActions, makeStyles, Theme, Typography } from "@material-ui/core"
import { WarningRounded } from "@material-ui/icons"
import React, { useContext, useRef, useState } from "react"
import { IDataTableColumn } from "react-data-table-component"
import { useForm } from "react-hook-form"

import { useLoadingButton } from "components/LoadingButton"
import BaseTable from "components/Table"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from "services/constants"
import { ThemeContext } from "services/context/themeContext"
import parseCsvFile, { CsvModalType, CsvParseOptions, WithCsvInfos } from "services/csvParse"
import { widgetTableStyle } from "../../Dashboard/widgets/widgetTableStyle"
import InputButtonUI from "CryptioUI/InputButton"
import { useToast } from "../../../CryptioUI/Toaster/useToast"
import { toastCatch } from "components/ReactHookForm/utils"
import { ReactComponent as Free } from "../../../CryptioUI/assets/icons/check.svg"
// Special for this csv because of addresses

type UpdateLabelDto = { transationsId: string; movementsId: string }

type LabelWithCsvInfos = WithCsvInfos<UpdateLabelDto>
interface LabelUpdateModalCsvForm {
  labels: LabelWithCsvInfos[]
}

const useStyles = makeStyles((theme: Theme) => ({
  validateButton: {
    marginRight: theme.spacing(2),
  },
}))

const columns: IDataTableColumn<LabelWithCsvInfos>[] = [
  {
    name: "Transations Id",
    selector: "transationsId",
    sortable: true,
  },
  {
    name: "Movements Id",
    selector: "movementsId",
    sortable: true,
  },
  {
    name: "Status",
    selector: "errorString",
    sortable: true,
    format: function formatAddress(row) {
      return row.errorString ? (
        <Typography color="error" variant="body1">
          <Box display="flex">
            <Box pr={1}>
              <WarningRounded />
            </Box>
            {row.errorString}
          </Box>
        </Typography>
      ) : (
        <Free width={21} color="inherit" />
      )
    },
  },
]

export interface LabelUpdateProp {
  labelId: string
}
const LabelUpdateModalCsv = ({ labelId }: LabelUpdateProp): JSX.Element => {
  const classes = useStyles()
  const [modalData, setModalData] = useState<CsvModalType<UpdateLabelDto>>({
    isOpen: false,
    data: [],
  })
  const muiThemeContext = useContext(ThemeContext)
  const { handleSubmit } = useForm<LabelUpdateModalCsvForm>()
  const toast = useToast()

  const [selectedRows, setSelectedRows] = useState<{
    allSelected: boolean
    selectedCount: number
    selectedRows: LabelWithCsvInfos[]
  }>({ allSelected: false, selectedCount: 0, selectedRows: [] })

  const fileField = useRef<HTMLInputElement>(null)

  const handleFileChange = () => {
    if (!fileField.current || !fileField.current.files || !fileField.current.files.length) {
      return
    }

    const options: CsvParseOptions<UpdateLabelDto> = {
      file: fileField.current.files[0],
      requiredColumns: ["transationsId", "movementsId"],
      setModalData,
      toast,
      transformData: (data) =>
        data.map(({ transationsId, movementsId }, index): WithCsvInfos<UpdateLabelDto> => {
          return {
            transationsId,
            movementsId,
            errorString: null,
            rowId: index,
          }
        }),
    }

    parseCsvFile<UpdateLabelDto>(options)
  }

  const [ImportSelectionButton, handleButtonCallback] = useLoadingButton()
  const { mutateAsync: bulkUpdateMovementsMutation } = api.transaction.useBulkUpdateMovements()
  const onSubmit = async () => {
    try {
      const { movementIds, transactionIds } = selectedRows.selectedRows.reduce<{
        movementIds: string[]
        transactionIds: string[]
      }>(
        (concatIds, row) => {
          if (row.movementsId.length) concatIds.movementIds.push(row.movementsId)
          if (row.transationsId.length) concatIds.transactionIds.push(row.transationsId)
          return concatIds
        },
        { movementIds: [], transactionIds: [] },
      )
      await bulkUpdateMovementsMutation({
        movementBulkUpdateDto: {
          labelIdsToAdd: [labelId],
          individualMovements: { movementIds },
          individualTransactions: { transactionIds },
        },
      })
    } catch (e) {
      toastCatch(e, toast)
    }
    setModalData({
      isOpen: false,
      data: [],
    })
  }

  return (
    <>
      <InputButtonUI
        mode={Mode.CONTAINED}
        type="file"
        htmlFor="labels"
        accept=".csv"
        ref={fileField}
        id="labels"
        onChange={handleFileChange}
        onClick={() => {
          if (fileField.current) {
            fileField.current.value = ""
            fileField.current.files = null
          }
        }}
      >
        Import from CSV
      </InputButtonUI>
      <ModalUI
        isOpen={modalData.isOpen}
        onClose={() => setModalData({ isOpen: false, data: [] })}
        className="w-[1000px] h-auto"
        title="Update labels"
      >
        <BaseTable<LabelWithCsvInfos, "errorString">
          columns={columns}
          items={modalData.data}
          fixedScroll="400px"
          onSelectedChange={setSelectedRows}
          paginationRowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          paginationTotalRows={modalData.data.length}
          paginationPerPage={50}
          pagination={modalData.data.length > DEFAULT_ROWS_PER_PAGE}
          customStyle={widgetTableStyle(muiThemeContext.type)}
          defaultParams={{
            sortBy: "errorString",
            sortDirection: "ascending",
          }}
          sortServer={false}
        />
        <Box component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
          <DialogActions className="flex justify-between items-center">
            <Typography variant="body1">
              {modalData.data.length} element
              {modalData.data.length > 1 ? "s" : ""} found
            </Typography>
            <div className="flex flex-row">
              <ImportSelectionButton
                disabled={selectedRows.selectedCount === 0}
                className={classes.validateButton}
                type="submit"
              >
                Import selection
              </ImportSelectionButton>
              <ButtonUI onClick={() => setModalData({ isOpen: false, data: [] })} mode={Mode.CONTAINED}>
                Close
              </ButtonUI>
            </div>
          </DialogActions>
        </Box>
      </ModalUI>
    </>
  )
}

export default LabelUpdateModalCsv
