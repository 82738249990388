import { Box, BoxProps } from "@material-ui/core"
import { AccountCreatedViaType, prettyAccountCreatedViaMap } from "pure-shared"
import React from "react"
import ButtonUI from "CryptioUI/Button"

type Props = {
  onClick: (createdVia: AccountCreatedViaType) => void
  action: string
} & Omit<BoxProps, "onClick">

type ButtonProps = {
  createdVia: AccountCreatedViaType
  color?: string
}

const SelectSignInUp = ({ onClick, action, ...rest }: Props) => {
  const CreatedViaButton = ({ createdVia }: ButtonProps) => (
    <div className="mt-3">
      <ButtonUI fullWidth onClick={() => onClick(createdVia)}>
        I want to {action} with {prettyAccountCreatedViaMap[createdVia]}
      </ButtonUI>
    </div>
  )

  return (
    <Box display="flex" flexDirection="column" {...rest}>
      <CreatedViaButton createdVia="cryptio" />
      <CreatedViaButton createdVia="xero" color="#13B5EA" />
    </Box>
  )
}

export const SelectSignIn = (props: Omit<Props, "action">) => <SelectSignInUp action="sign in" {...props} />
export const SelectSignUp = (props: Omit<Props, "action">) => <SelectSignInUp action="sign up" {...props} />
