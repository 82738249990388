import { queryClient } from "../../.."
import { useWorkspaceMutation, useWorkspaceQuery } from "../../context/workspaceContext"
import { IntegrationsApi } from "../openapi"

export const GET_BUSINESS_INTEGRATIONS = "GET_BUSINESS_INTEGRATIONS"
// TODO: rename
export const GET_REQUEST_FINANCE = "GET_REQUEST_FINANCE"
export const GET_FIREBLOCKS = "GET_FIREBLOCKS"

export const integrationsActions = {
  // List integrations
  useBusinessIntegrations: () => {
    return useWorkspaceQuery([GET_BUSINESS_INTEGRATIONS], IntegrationsApi.getBusinessIntegrations, {})
  },

  // REQUEST FINANCE
  useAddRequestFinanceIntegration: () => {
    return useWorkspaceMutation(IntegrationsApi.addRequestFinanceIntegration, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_REQUEST_FINANCE)
      },
    })
  },

  useDeleteRequestFinanceIntegration: () => {
    return useWorkspaceMutation(IntegrationsApi.deleteRequestFinanceIntegration, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_REQUEST_FINANCE)
      },
    })
  },

  useSynchronizeRequestFinance: () => {
    return useWorkspaceMutation(IntegrationsApi.synchronizeAllRequestFinanceIntegrations, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_REQUEST_FINANCE)
      },
    })
  },

  useSynchronizeOneRequestFinanceIntegration: () => {
    return useWorkspaceMutation(IntegrationsApi.synchronizeOneRequestFinanceIntegrations, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_REQUEST_FINANCE)
      },
    })
  },

  useRequestFinance: () => {
    return useWorkspaceQuery([GET_REQUEST_FINANCE], IntegrationsApi.getRequestFinanceIntegrations, {})
  },

  // FIREBLOCKS
  useConnectToFireblocks: () => {
    return useWorkspaceMutation(IntegrationsApi.connectToFireblocks, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_FIREBLOCKS)
      },
    })
  },

  useDisconnectFromFireblocks: () => {
    return useWorkspaceMutation(IntegrationsApi.disconnectFromFireblocks, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_FIREBLOCKS)
      },
    })
  },

  useUpdateFireblocks: () => {
    return useWorkspaceMutation(IntegrationsApi.updateFireblocks, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_FIREBLOCKS)
      },
    })
  },

  useFireblocks: () => {
    return useWorkspaceQuery(
      [GET_FIREBLOCKS],
      IntegrationsApi.getFireblocks,
      {},
      {
        refetchInterval: 5000,
      },
    )
  },

  useUpdateFireblocksSettings: () => {
    return useWorkspaceMutation(IntegrationsApi.updateFireblocksSettings, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_FIREBLOCKS)
      },
    })
  },

  useUpdateFireblocksCredentials: () => {
    return useWorkspaceMutation(IntegrationsApi.updateFireblocksCredentials, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_FIREBLOCKS)
      },
    })
  },
}
