import { IconButton, Tooltip, Typography } from "@material-ui/core"
import { CachedRounded, DeleteRounded, DoneRounded, MoreHorizRounded, WarningRounded } from "@material-ui/icons"
import BigNumber from "bignumber.js"
import dayjs from "dayjs"
import React, { useContext } from "react"
import numbro from "numbro"

import { UncontrolledLoadingButton } from "../../../components/LoadingButton"
import useDialog from "../../../components/misc/useDialog"
import PermissionDisabled from "../../../components/Permission/PermissionDisabled"
import { toastCatch } from "../../../components/ReactHookForm/utils"
import { IExtendedDataTableColumn } from "../../../components/Table/interface"
import api from "../../../services/api"
import { GenericJobStatusEnum, GetImpairmentDto, GetImpairmentsSortByEnum } from "../../../services/api/openapi"
import { WorkspaceContext } from "../../../services/context/workspaceContext"
import { plainDateToUtcDayJs } from "pure-shared"
import { useToast } from "CryptioUI/Toaster"

const simpleStatusToIcon = (status: GenericJobStatusEnum) => {
  switch (status) {
    case "active":
      return (
        <Tooltip title="Calculating">
          <CachedRounded />
        </Tooltip>
      )
    case "pending":
      return (
        <Tooltip title="Pending">
          <MoreHorizRounded />
        </Tooltip>
      )
    case "error":
      return (
        <Tooltip title="Error">
          <Typography>An unknown error occured</Typography>
        </Tooltip>
      )
    case "inactive":
    default:
      return (
        <Tooltip title="Done">
          <DoneRounded />
        </Tooltip>
      )
  }
}

export const useImpairmentColumns = () => {
  const toast = useToast()
  const { workspace } = useContext(WorkspaceContext)
  const basicDialog = useDialog()

  const isImpairmentCostBasisRunning = workspace.costBasisImpairment.status !== "inactive"

  const { mutateAsync: applyImpairmentMutation } = api.impairment.useApplyImpairment()
  const { mutateAsync: unapplyImpairmentMutation } = api.impairment.useUnapplyImpairment()
  const { mutateAsync: updateImpairmentValuationMutation } = api.impairment.useUpdateImpairmentValuation()
  const { mutateAsync: deleteImpairmentMutation } = api.impairment.useDeleteImpairment()

  const applyImpairment = async (impairmentId: string) => {
    try {
      await applyImpairmentMutation({
        impairmentId,
      })
      toast.open("Applying impairment...", { variant: "default" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const askApplyImpairment = (impairmentId: string) => {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to apply this impairment? You will need to reapply all impairments.
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: () => applyImpairment(impairmentId),
    })
  }

  const updateImpairmentValution = async (impairmentId: string) => {
    try {
      await updateImpairmentValuationMutation({
        impairmentId: impairmentId,
        updateImpairmentValuationDto: {
          overridePrice: false,
        },
      })
      toast.open("Updating impairment valuation ", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const unapplyImpairment = async (impairmentId: string) => {
    try {
      await unapplyImpairmentMutation({
        impairmentId,
      })
      toast.open("Unapplying impairment...", { variant: "default" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const handleDelete = async (impairmentId: string) => {
    try {
      await deleteImpairmentMutation({ impairmentId })
      toast.open("Impairment deleted", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const askDeleteImpairment = (impairment: GetImpairmentDto) => {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">Do you really want to delete this impairment? This action is irreversible.</Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: () => handleDelete(impairment.id),
    })
  }

  const isInLockPeriod = (row: GetImpairmentDto) =>
    !!(
      workspace.lock.isEnabled &&
      workspace.lock.inclusivePlainEndDate &&
      !plainDateToUtcDayJs(row.endDate).isAfter(plainDateToUtcDayJs(workspace.lock.inclusivePlainEndDate))
    )

  const columns: IExtendedDataTableColumn<GetImpairmentDto, GetImpairmentsSortByEnum>[] = [
    {
      name: "Created at",
      selector: "createdAt",
      sortable: true,
      sortName: "created_at",
      maxWidth: "150px",
      format: (row) => dayjs(row.createdAt).fromNow(),
    },
    // {
    //   name: "Start date",
    //   selector: "startDate",
    //   maxWidth: "50px",
    // },
    {
      name: "Date",
      selector: "endDate",
      sortable: true,
      sortName: "end_date",
      maxWidth: "50px",
    },
    {
      name: "Valuation",
      selector: "status",
      format: function format(row) {
        if (!isInLockPeriod(row)) {
          return (
            <Tooltip title="Some more recent impairments are already snapshotted">
              <Typography variant="body2">Not in locked period</Typography>
            </Tooltip>
          )
        }
        if (row.valuationStatus !== "inactive") {
          return simpleStatusToIcon(row.valuationStatus)
        }
        if (row.isValuated) {
          return <DoneRounded />
        }
        return (
          <UncontrolledLoadingButton onClick={() => updateImpairmentValution(row.id)}>Update</UncontrolledLoadingButton>
        )
      },
    },
    {
      name: "Apply",
      selector: "status",
      format: function format(row) {
        if (row.applicationStatus !== "inactive") {
          return simpleStatusToIcon(row.applicationStatus)
        }
        if (!isInLockPeriod(row)) {
          return "-"
        }
        if (row.appliedAt) {
          return <DoneRounded />
        }
        if (row.valuationStatus !== "inactive") {
          return <Typography variant="body2">Waiting for valuation</Typography>
        }
        if (!row.isValuated) {
          return <Typography variant="body2">Valuation Needed</Typography>
        }
        if (isImpairmentCostBasisRunning) {
          return <Typography variant="body2">cost basis is running</Typography>
        }
        return (
          <UncontrolledLoadingButton
            onClick={() => (row.isBeforeLastApplicationDate ? askApplyImpairment : applyImpairment)(row.id)}
          >
            Apply
          </UncontrolledLoadingButton>
        )
      },
    },
    {
      name: "Total impairments to date",
      selector: "generationStatus",
      format: function format(row) {
        if (!isInLockPeriod(row) || isImpairmentCostBasisRunning) {
          return "-"
        }
        if (row.valuationStatus !== "inactive") {
          return <Typography variant="body2">Waiting for valuation</Typography>
        }
        if (!row.isValuated) {
          return <Typography variant="body2">Valuation Needed</Typography>
        }
        if (workspace.isCostBasisImpairmentDirty) {
          return <WarningRounded />
        }
        if (row.estimatedTotalImpairmentValueFiat) {
          return numbro(new BigNumber(row.estimatedTotalImpairmentValueFiat).decimalPlaces(2)).format({
            thousandSeparated: true,
          })
        }
        return "-"
      },
    },
    {
      name: "Total impairments for current assets",
      selector: "generationStatus",
      format: function format(row) {
        if (!isInLockPeriod(row) || isImpairmentCostBasisRunning) {
          return "-"
        }
        if (row.valuationStatus !== "inactive") {
          return "-"
        }
        if (!row.isValuated) {
          return "-"
        }
        if (workspace.isCostBasisImpairmentDirty) {
          return "-"
        }
        if (row.unsoldImpairmentValueFiat) {
          return numbro(new BigNumber(row.unsoldImpairmentValueFiat).decimalPlaces(2)).format({
            thousandSeparated: true,
          })
        }
        return "-"
      },
    },
    {
      name: "Unapply",
      selector: "status",
      format: function format(row) {
        if (!isInLockPeriod(row)) {
          return "-"
        }
        if (row.appliedAt === null) {
          return "-"
        }
        if (isImpairmentCostBasisRunning) {
          return <Typography variant="body2">cost basis is running</Typography>
        }
        if (row.valuationStatus !== "inactive") {
          return <Typography variant="body2">Waiting for valuation</Typography>
        }
        return <UncontrolledLoadingButton onClick={() => unapplyImpairment(row.id)}>Unapply</UncontrolledLoadingButton>
      },
    },
    {
      name: "Delete",
      selector: "del",
      format: function format(row) {
        if (isImpairmentCostBasisRunning) {
          return <Typography variant="body2">cost basis is running</Typography>
        }
        return (
          <PermissionDisabled
            permission="can_remove_impairment"
            action="delete an impairment"
            titlePermissionEnable="Delete"
          >
            <IconButton onClick={() => askDeleteImpairment(row)}>
              <DeleteRounded />
            </IconButton>
          </PermissionDisabled>
        )
      },
    },
  ]

  return {
    columns,
    basicDialog,
  }
}
