import { useTypedController } from "@hookform/strictly-typed"
import { TextField } from "@material-ui/core"
import {
  ReportSummarizedLedgerEntriesTimeframePrettyMap,
  ReportSummarizedLedgerEntriesTimeframeTypesArray,
} from "pure-shared"
import React from "react"
import { ReportFormComponentProps } from "."
import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { DrawerReportForm } from "../ReportForm"
import { Autocomplete } from "@material-ui/lab"

const SummarizedLedgerTimeframe = ({ control }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })

  return (
    <DrawerFormSection htmlFor="summarized-ledger-select" name="Aggregation granularity">
      <TypedController
        name="summarizedLedgerEntriesTimeframe"
        defaultValue={undefined}
        rules={{ required: true }}
        render={({ onChange, ...rest }) => (
          <Autocomplete
            id="ledger-select"
            {...rest}
            disableClearable
            onChange={(_, newValue) => onChange(newValue)}
            getOptionLabel={(option) => ReportSummarizedLedgerEntriesTimeframePrettyMap[option]}
            options={[...ReportSummarizedLedgerEntriesTimeframeTypesArray]}
            renderInput={(params) => <TextField {...params} />}
            size="small"
          />
        )}
      />
    </DrawerFormSection>
  )
}

export default SummarizedLedgerTimeframe
