import { Avatar, Divider, IconButton, MenuItem, Popover, Typography } from "@material-ui/core"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import {
  AppsRounded,
  AssignmentReturnedRounded,
  BusinessCenterRounded,
  DashboardRounded,
  DescriptionRounded,
  EventRounded,
  ExitToAppRounded,
  HelpRounded,
  PermContactCalendarRounded,
  SettingsRounded,
  ShareRounded,
  SupervisorAccountRounded,
} from "@material-ui/icons"
import LocalAtmIcon from "@material-ui/icons/LocalAtm"
import clsx from "clsx"
import React, { useContext } from "react"
import { NavLink } from "react-router-dom"
import { useHistory } from "react-router"

import Backdrop from "components/Backdrop/Backdrop"
import { LightGrey, White } from "../../materialTheme"
import { URLS } from "../../routes"
import api from "services/api"
import { GetUserDto } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { Mixpanel } from "services/mixpanel"
import { BASE_FRONT_URL, BillingEnabled } from "services/variables"
import { BillingInfoInMenu } from "./BillingInfoInMenu"
import { LinkIcon, MultiLinkIcon } from "./LinkIcons"
import { WorkspaceMenu } from "./WorkspaceMenu"
import copy from "copy-to-clipboard"
import { camelCaseToTitleCase } from "services/utils/textUtils"
import BackOfficeView from "../misc/BackOfficeView"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"
import { FeatureGate } from "components/Feature/FeatureGate"

// TODO Refactor the profile menu
// TODO Refactor the workspace switcher

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      "color": theme.palette.getContrastText(LightGrey),
      "backgroundColor": LightGrey,
      "width": theme.spacing(4),
      "height": theme.spacing(4),
      "&:hover": {
        color: theme.palette.getContrastText(White),
        backgroundColor: White,
      },
    },
    grow: {
      flexGrow: 1,
    },
    unclickableLink: {
      cursor: "default",
      color: `${theme.palette.text.disabled}`,
    },
    linkTypographyWithIcon: {
      marginLeft: "0.5em",
      fontWeight: 400,
    },
    linkTypography: {
      marginLeft: theme.spacing(0.5),
      fontWeight: 400,
    },
    activeSubLink: {
      color: `${theme.palette.action.selected} !important`,
    },
    inactiveSubLink: {
      "color": LightGrey,
      "&:hover": {
        color: White,
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      height: "62px",
    },
    menuPaper: {
      marginTop: theme.spacing(1),
      border: `1px solid #7676a3`,
      backgroundColor: theme.palette.primary.main,
      color: LightGrey,
    },
    activeIcon: {
      color: theme.palette.action.selected,
      padding: 0,
      margin: 0,
    },
    notActiveIcon: {
      color: "inherit",
      padding: 0,
      margin: 0,
    },
    dividerSubLink: {
      backgroundColor: "#555",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(1),
    },
  }),
)

const PrivateLayout = ({ user }: { user: GetUserDto }): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [showBackDrop, setShowBackdrop] = React.useState(false)
  const workspaceCtx = useContext(WorkspaceContext)
  const toast = useToast()

  const isMenuOpen = Boolean(anchorEl)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  React.useEffect(() => {
    return () => {
      if (showBackDrop) setShowBackdrop(false)
    }
  }, [showBackDrop])

  const { mutateAsync: logoutMutation } = api.user.useLogout()
  const { mutateAsync: createShortenedMutation } = api.urlShortener.useCreateShortened()

  const handleLogOut = async (): Promise<void> => {
    Mixpanel.track("Logout")
    setShowBackdrop(true)
    await logoutMutation()
    handleMenuClose()
  }

  const shareView = async () => {
    try {
      const res = await createShortenedMutation({
        createUrlShortenerDto: {
          url: `/redirect/workspace/${workspaceCtx.workspace.id}?workspaceRedirect=${encodeURI(
            `${history.location.pathname}${history.location.search}`,
          )}`,
        },
      })

      copy(`${BASE_FRONT_URL}${res.url}`)
      toast.open("Url copied to clipboard", { variant: "default" })
      handleMenuClose()
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const copyWorkspaceId = () => {
    copy(workspaceCtx.workspace.id)
    toast.open("Workspace ID copied to clipboard", { variant: "default" })
    handleMenuClose()
  }

  const menuId = "primary-search-account-menu"
  const profileMenu = (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id={menuId}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      classes={{ paper: classes.menuPaper }}
    >
      <MenuItem className={clsx(classes.linkTypography, classes.unclickableLink)}>{user.email}</MenuItem>
      {user.role !== "normal" && (
        <MenuItem className={clsx(classes.linkTypography, classes.unclickableLink)}>
          Role: {camelCaseToTitleCase(user.role)}
        </MenuItem>
      )}
      <Divider className={classes.dividerSubLink} />
      <MenuItem onClick={handleMenuClose}>
        <>
          <HelpRounded />
          <a
            className={classes.inactiveSubLink}
            rel="noopener noreferrer"
            href="https://support.cryptio.co/hc/en-gb"
            target="_blank"
          >
            <Typography className={classes.linkTypographyWithIcon}>Help center</Typography>
          </a>
        </>
      </MenuItem>
      <Divider className={classes.dividerSubLink} />
      <MenuItem
        component={NavLink}
        to={URLS.Settings}
        className={classes.inactiveSubLink}
        activeClassName={classes.activeSubLink}
        onClick={handleMenuClose}
      >
        <SettingsRounded />
        <Typography className={classes.linkTypographyWithIcon}>Settings</Typography>
      </MenuItem>
      {BillingEnabled && (
        <>
          <Divider className={classes.dividerSubLink} />
          <MenuItem
            component={NavLink}
            to={URLS.Billing.Status}
            className={classes.inactiveSubLink}
            activeClassName={classes.activeSubLink}
            onClick={handleMenuClose}
          >
            <LocalAtmIcon />
            <Typography className={classes.linkTypographyWithIcon}>Subscription</Typography>
          </MenuItem>
        </>
      )}
      <Divider className={classes.dividerSubLink} />
      <BackOfficeView persistent>
        <>
          <MenuItem
            component={NavLink}
            to={URLS.BackOffice.Home}
            className={classes.inactiveSubLink}
            activeClassName={classes.activeSubLink}
            onClick={handleMenuClose}
          >
            <SupervisorAccountRounded />
            <Typography className={classes.linkTypographyWithIcon}>Back office</Typography>
          </MenuItem>
          <Divider className={classes.dividerSubLink} />
          <MenuItem onClick={copyWorkspaceId}>
            <AppsRounded fontSize="medium" />
            <Typography className={classes.linkTypographyWithIcon}>Copy workspace ID</Typography>
          </MenuItem>
          <Divider className={classes.dividerSubLink} />
        </>
      </BackOfficeView>
      <MenuItem onClick={shareView}>
        <ShareRounded />
        <Typography className={classes.linkTypographyWithIcon}>Share view</Typography>
      </MenuItem>
      <Divider className={classes.dividerSubLink} />
      <MenuItem onClick={handleLogOut}>
        <ExitToAppRounded />
        <Typography className={classes.linkTypographyWithIcon}>Log out</Typography>
      </MenuItem>
    </Popover>
  )

  return (
    <div className="flex flex-row justify-between overflow-x-visible items-center w-full min-w-[990px]">
      {showBackDrop && <Backdrop style={{ backgroundColor: "black", opacity: 0.8 }} />}
      <WorkspaceMenu />
      {BillingEnabled && <BillingInfoInMenu />}
      <div className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <LinkIcon
          onClick={() => Mixpanel.track("OpenPageDashboard")}
          url={URLS.Portfolio}
          title="Dashboard"
          Icon={DashboardRounded}
        />
        <FeatureGate
          fullWidth={false}
          feature="defi_position"
          fallback={<LinkIcon url={URLS.Transactions.basePath} title="Transactions" Icon={EventRounded} />}
        >
          <MultiLinkIcon
            options={[
              {
                url: URLS.Transactions.basePath,
                name: "Transactions",
              },
              {
                url: URLS.DeFi.basePath,
                name: "DeFi (Beta)",
              },
            ]}
            title="Transactions"
            Icon={EventRounded}
            active={[URLS.Transactions.basePath, URLS.DeFi.basePath].includes(location.pathname)}
          />
        </FeatureGate>
        <MultiLinkIcon
          options={[
            {
              url: URLS.Business.Integrations,
              name: "Integrations",
            },
            {
              url: URLS.Business.UnidentifiedAddresses,
              name: "Unidentified addresses",
            },
            {
              url: URLS.Business.Contacts.getUrl(),
              name: "Contacts",
              onClick: () => Mixpanel.track("OpenPageContacts"),
            },
            {
              url: URLS.Business.Labels.getUrl(),
              name: "Labels",
              onClick: () => Mixpanel.track("OpenPageLabels"),
            },
          ]}
          title="Business"
          Icon={BusinessCenterRounded}
          active={[
            URLS.Business.UnidentifiedAddresses,
            URLS.Business.Integrations,
            URLS.Business.Contacts.basePath,
            URLS.Business.Labels.basePath,
          ].includes(location.pathname)}
        />
        <MultiLinkIcon
          options={[
            {
              url: URLS.Accounting.Integrations,
              name: "Integrations",
            },
            {
              url: URLS.Accounting.AutomatedMapping,
              name: "Chart of accounts",
              onClick: () => Mixpanel.track("OpenPageCOA"),
            },
            {
              url: URLS.Accounting.Impairment,
              name: "Impairment",
              feature: "impairment",
            },
          ]}
          title="Accounting"
          Icon={PermContactCalendarRounded}
          active={[URLS.Accounting.Integrations, URLS.Accounting.AutomatedMapping, URLS.Accounting.Impairment].includes(
            location.pathname,
          )}
        />
        <LinkIcon url={URLS.Reports.getUrl()} title="Reports" Icon={DescriptionRounded} />
        <LinkIcon url={URLS.Import.getUrl()} title="Imports" Icon={AssignmentReturnedRounded} />
        <div className="ml-4 flex justify-center items-center">
          <IconButton
            edge="end"
            aria-label="Account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            className={[URLS.Settings].includes(location.pathname) ? classes.activeIcon : classes.notActiveIcon}
          >
            <Avatar className={classes.avatar}>{user.email.charAt(0).toUpperCase()}</Avatar>
          </IconButton>
        </div>
      </div>
      {profileMenu}
    </div>
  )
}

export default PrivateLayout
