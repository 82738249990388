import { Divider, Link, Typography } from "@material-ui/core"
import { prettyEmptyOrNull } from "pure-shared"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

import AddressTypography from "components/AddressTypography"
import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import CopyableText from "components/misc/copyableText"
import { URLS } from "../../../../routes"
import { GetPublicAssetDto } from "services/api/openapi/models"
import { prettyAssetTypeMap } from ".."
import AssetSummaryBackOffice from "./AssetSummaryBackOffice"
import AdministratorInformation from "components/misc/AdministratorInformation"
import toFilterElement from "services/utils/toFilterElement"
import NftLink from "../../../../components/CoreTransaction/Cells/NftLink"
import BackOfficeView from "../../../../components/misc/BackOfficeView"

const AssetSummary = ({
  asset,
  onClose,
  isAdminView,
}: {
  asset: GetPublicAssetDto
  onClose: () => void
  isAdminView: boolean
}) => {
  return (
    <DrawerCategory title="Asset Summary">
      <AdministratorInformation infos={[{ id: asset.id, extended: "Asset" }]} />
      <DrawerSection name="Name">
        <Typography variant="body2" color="textSecondary">
          {prettyEmptyOrNull(asset.name)}
        </Typography>
      </DrawerSection>

      <DrawerSection name="Symbol">
        <Typography variant="body2" color="textSecondary">
          {prettyEmptyOrNull(asset.symbol)}
        </Typography>
      </DrawerSection>

      <DrawerSection name="Unique symbol">
        <Typography variant="body2" color="textSecondary">
          <CopyableText>{asset.uniqueSymbol}</CopyableText>
        </Typography>
      </DrawerSection>

      <DrawerSection name="Type">
        <Typography variant="body2" color="textSecondary">
          {prettyAssetTypeMap[asset.type]}
        </Typography>
      </DrawerSection>

      <DrawerSection name="Token addresses">
        {asset.blockchainLinks.map((link, idx) => (
          <React.Fragment key={`${link.blockchain}-${link.tokenAddress}`}>
            {idx > 0 && <Divider style={{ marginTop: 8, marginBottom: 8 }} />}
            <CopyableText text={link.tokenAddress} allowEvent>
              <AddressTypography component="span">{link.tokenAddress}</AddressTypography>
            </CopyableText>
            <Typography>{link.blockchain}</Typography>
          </React.Fragment>
        ))}
        {asset.blockchainLinks.length === 0 && <Typography>-</Typography>}
      </DrawerSection>

      <DrawerSection name="NFT Information">
        {asset.blockchainLinks.map((link, idx) => (
          <>
            {link.tokenId !== null && (
              <>
                <CopyableText text={link.tokenId} allowEvent>
                  Token Id: {link.tokenId}
                </CopyableText>
                <NftLink
                  key={`asset-nft-${idx}`}
                  address={link.tokenAddress}
                  tokenId={link.tokenId}
                  sourceName={link.blockchain}
                />
              </>
            )}
          </>
        ))}
      </DrawerSection>

      <BackOfficeView>
        <AssetSummaryBackOffice asset={asset} />
      </BackOfficeView>

      {isAdminView && (
        <DrawerSection name="See movements">
          <Link
            color="inherit"
            component={RouterLink}
            to={URLS.Transactions.getUrl({
              filters: [{ type: "assets", value: [toFilterElement(asset)], isNot: false }],
            })}
            underline="always"
            onClick={onClose}
          >
            Find movements with this asset
          </Link>
        </DrawerSection>
      )}
    </DrawerCategory>
  )
}

export default AssetSummary
