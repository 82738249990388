import { Mixpanel } from "../../../services/mixpanel"
import api from "../../../services/api"
import { Box, Typography } from "@material-ui/core"
import { LoadingButton } from "components/LoadingButton"
import { Mode } from "CryptioUI/types"
import { iconStyleBlack } from "CryptioUI/Utilities/config"
import { ReactComponent as Dollar } from "CryptioUI/assets/icons/menu/billing.svg"
import TooltipUI from "CryptioUI/Tooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface MarkLedgerEntriesProps {
  show: boolean
}

const MarkLedgerEntries = ({ show }: MarkLedgerEntriesProps): JSX.Element => {
  const toast = useToast()
  const canMarkLegderEntriesAsSynchronized = api.report.useCanMarkLegderEntriesAsSynchronized()
  const { mutateAsync: markLedgerEntriesAsSynchronizedMutation } = api.report.useMarkLegderEntriesAsSynchronized()

  const markLedgerEntriesAsSynchronized = async () => {
    try {
      await markLedgerEntriesAsSynchronizedMutation({})
      toast.open("Reconciling with ledger entries...", { variant: "default" })
    } catch (e) {
      toastCatch(e, toast)
    }
    Mixpanel.track("ClickReconcileLedgerEntries")
  }

  if (!show || !canMarkLegderEntriesAsSynchronized.isSuccess) {
    return <></>
  }

  const { reportExist, isReportRunning } = canMarkLegderEntriesAsSynchronized.data

  const tooltipText = (() => {
    if (isReportRunning) {
      return "A ledger entry report is currently being generated. Please wait until the generation is done."
    }
    if (!reportExist) {
      return "No ledger entry report exist. Please generate a ledger entry report in the report page."
    }
    return "Mark the error-free transactions of your latest ledger entries report as synchronized"
  })()

  return (
    <>
      <Box mr={2}>
        <TooltipUI content={<Typography>{tooltipText}</Typography>}>
          <div>
            <LoadingButton
              mode={Mode.CONTAINED}
              disabled={!reportExist || isReportRunning}
              Icon={<Dollar className={iconStyleBlack} />}
              onClick={markLedgerEntriesAsSynchronized}
              pending={isReportRunning}
            >
              Reconcile with ledger entries
            </LoadingButton>
          </div>
        </TooltipUI>
      </Box>
    </>
  )
}

export default MarkLedgerEntries
