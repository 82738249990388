import { useEffect, useMemo, useState } from "react"
import { UseQueryResult } from "react-query"
import { SelfPaginatedQuery } from "services/utils/types"

export type DeveloppedRowsType =
  | {
      type: "positiveAdd"
      itemIds: string[]
    }
  | {
      type: "negativeAdd"
      excludedIds: string[]
    }

function useDeveloppedTransactionPage<T>(
  rows: UseQueryResult<SelfPaginatedQuery<T>, unknown>["data"] | undefined,
  nonMemoizedGetRowId: (row: T) => string,
) {
  const [developpedRows, setDeveloppedRows] = useState<DeveloppedRowsType | undefined>(undefined)

  // Without this Memo, the useDeveloppedTransactionPage rerenders the component everytime,
  // as `getRowId` is usually specified as an arrow function which is
  // different with every call
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getRowId = useMemo(() => nonMemoizedGetRowId, [])

  const { isPageDevelopped, onPageDevelopped, DeveloppedRowCount } = useMemo(() => {
    const isPageDevelopped = (() => {
      if (rows === undefined) return false
      if (developpedRows?.type === "positiveAdd") {
        // all transaction on this page are in the Developped transaction list
        return rows.data.every((x) => developpedRows.itemIds.includes(getRowId(x)))
      } else if (developpedRows?.type === "negativeAdd") {
        // all transaction on this page are NOT in the excluded transaction list
        return rows.data.every((x) => !developpedRows.excludedIds.includes(getRowId(x)))
      }
      return false
    })()

    const DeveloppedRowCount = (() => {
      if (rows === undefined) return 0
      if (developpedRows?.type === "positiveAdd") return developpedRows.itemIds.length
      else if (developpedRows?.type === "negativeAdd") return rows.totalCount - developpedRows.excludedIds.length

      return 0
    })()
    // rows, developpedRows
    const onPageDevelopped = () => {
      if (rows === undefined) return

      if (developpedRows?.type === "positiveAdd") {
        if (isPageDevelopped) {
          setDeveloppedRows({
            // remove all transaction ids from this page
            ...developpedRows,
            itemIds: developpedRows.itemIds.filter((x) => !rows.data.some((tx) => getRowId(tx) === x)),
          })
        } else {
          setDeveloppedRows({
            // add all transaction ids from this page
            ...developpedRows,
            itemIds: Array.from(new Set(developpedRows.itemIds.concat(rows.data.map((x) => getRowId(x))))),
          })
        }
      } else if (developpedRows?.type === "negativeAdd") {
        if (isPageDevelopped) {
          setDeveloppedRows({
            // add transaction to excluded list
            ...developpedRows,
            excludedIds: Array.from(new Set(developpedRows.excludedIds.concat(rows.data.map((x) => getRowId(x))))),
          })
        } else {
          setDeveloppedRows({
            // remove transaction from excluded list
            ...developpedRows,
            excludedIds: developpedRows.excludedIds.filter((x) => !rows.data.some((tx) => getRowId(tx) === x)),
          })
        }
      } else {
        setDeveloppedRows({
          // create a new selection list
          type: "positiveAdd",
          itemIds: rows.data.map((x) => getRowId(x)),
        })
      }
    }

    return {
      isPageDevelopped,
      DeveloppedRowCount,
      onPageDevelopped,
    }
  }, [developpedRows, getRowId, rows])

  useEffect(() => {
    if (developpedRows === undefined || !rows) return
    if (developpedRows.type === "positiveAdd" && developpedRows.itemIds.length === 0) setDeveloppedRows(undefined) // if the user deDevelopped all rows in individual mode, remove selection
    if (developpedRows.type === "negativeAdd" && developpedRows.excludedIds.length === rows.totalCount) {
      // if the user excluded all rows in filtered mode, remove selection
      setDeveloppedRows(undefined)
    }
    if (developpedRows.type === "positiveAdd" && developpedRows.itemIds.length === rows.totalCount) {
      // If the user manually Developped all pages like a dumb ass, move to filtered
      setDeveloppedRows({
        type: "negativeAdd",
        excludedIds: [],
      })
    }
  }, [developpedRows, rows])

  return {
    developpedRows,
    setDeveloppedRows,
    isPageDevelopped,
    DeveloppedRowCount,
    onPageDevelopped,
  }
}

export default useDeveloppedTransactionPage
