import { Box, DialogActions } from "@material-ui/core"
import React, { useContext } from "react"

import BaseTable from "components/Table"
import { IExtendedDataTableColumn } from "components/Table/interface"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { BackOfficeWorkspaceInfo } from "services/api/openapi/models"
import { ThemeContext } from "services/context/themeContext"
import { widgetTableStyle } from "../../../Dashboard/widgets/widgetTableStyle"

export type UserSourceModal = BackOfficeWorkspaceInfo["sources"][0] & {
  workspaceName: string
}

interface BackOfficeUserSourceModalProps {
  sources: UserSourceModal[]
  isOpen: boolean
  onClose: () => void
}

const columns: IExtendedDataTableColumn<UserSourceModal, undefined>[] = [
  {
    name: "Workspace",
    selector: "workspaceName",
  },
  {
    name: "Source",
    selector: "name",
  },
  {
    name: "Type",
    selector: "sourceName",
  },
]

const BackOfficeUserSourceModal = ({ isOpen, onClose, sources }: BackOfficeUserSourceModalProps) => {
  const muiThemeContext = useContext(ThemeContext)

  return (
    <ModalUI isOpen={isOpen} onClose={onClose} className="W-[700px] h-auto" title="Sources">
      <BaseTable<UserSourceModal, undefined>
        customStyle={widgetTableStyle(muiThemeContext.type)}
        columns={columns}
        items={sources}
        fixedScroll="500px"
      />
      <Box>
        <DialogActions>
          <ButtonUI onClick={onClose}>Close</ButtonUI>
        </DialogActions>
      </Box>
    </ModalUI>
  )
}

export default BackOfficeUserSourceModal
