import { useTypedController } from "@hookform/strictly-typed"
import { Box, TextField } from "@material-ui/core"
import { KeyboardDatePicker } from "@material-ui/pickers"
import dayjs, { Dayjs } from "dayjs"
import { PlainDateFormat } from "pure-shared"
import React, { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"

import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import { DrawerProp } from "components/misc/useDrawer"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import PermissionText from "components/Permission/PermissionText"
import WarningTypography from "components/WarningTypography"
import api from "services/api"
import { GetWalletDto } from "services/api/openapi"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

type WalletUpdateForm = {
  name: string
  endDate: Dayjs | undefined
}

const WalletForm = (props: DrawerProp<GetWalletDto, false>) => {
  const { item: wallet, isOpen, onClose } = props
  const walletEndDate = wallet.endDate ? dayjs(wallet.endDate) : undefined

  const toast = useToast()
  const { handleSubmit, control, formState, reset, watch } = useForm<WalletUpdateForm>({
    mode: "onChange",
  })
  const TypedController = useTypedController<WalletUpdateForm>({ control })

  const { mutateAsync: updateWalletMutation } = api.wallet.useUpdateWallet()

  const watchAllFields = watch()
  const isFormDirty =
    wallet &&
    formState.isValid &&
    formState.isDirty &&
    (watchAllFields.name !== wallet.name || (!!watchAllFields.endDate && !watchAllFields.endDate.isSame(walletEndDate)))

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen, wallet, reset])

  const [SaveWalletButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = useCallback(
    async ({ ...form }: WalletUpdateForm) => {
      const prepareEndDate = () => {
        if (form.endDate && form.endDate.isSame(walletEndDate)) {
          return null
        }
        return form.endDate && form.endDate.isValid() ? form.endDate.format(PlainDateFormat) : null
      }

      try {
        await updateWalletMutation({
          walletId: wallet.id,
          updateWalletDto: {
            name: form.name,
            endDate: prepareEndDate(),
          },
        })
        toast.open("Changes saved", { variant: "success" })
      } catch (e) {
        toastCatch(e, toast)
      }
      onClose()
    },
    [wallet, updateWalletMutation, onClose, toast, walletEndDate],
  )
  const isWalletDeleting = wallet.stepName === "delete"

  return (
    <DrawerCategory component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))} title="Source edition">
      {isWalletDeleting && (
        <WarningTypography mt={3}>Cannot edit a wallet that is currently being deleted.</WarningTypography>
      )}
      <Box mt={3}>
        <PermissionText permission={"can_modify_wallet"} action="edit sources" />
      </Box>
      <DrawerFormSection htmlFor="wallet-name-textfield" name="Name">
        <TypedController
          id="wallet-name-textfield"
          name="name"
          defaultValue={wallet.name}
          render={(props) => (
            <PermissionDisabled permission="can_modify_wallet" action="modify a source">
              <TextField {...props} name="name" placeholder="Name of the source" fullWidth={true} />
            </PermissionDisabled>
          )}
        />
      </DrawerFormSection>
      {wallet.endDate && (
        <DrawerFormSection htmlFor="wallet-end-date-textfield" name="Increase end date">
          <TypedController
            id="wallet-end-date-textfield"
            name="endDate"
            defaultValue={walletEndDate}
            rules={{
              validate: (date) => {
                return date && date.isValid() && (date.isAfter(walletEndDate, "day") || date.isSame(walletEndDate))
              },
            }}
            render={(props) => (
              <PermissionDisabled permission="can_modify_wallet" action="modify a source">
                <KeyboardDatePicker
                  {...props}
                  name="endDate"
                  placeholder="End date"
                  fullWidth={true}
                  minDate={walletEndDate}
                  format="YYYY/MM/DD"
                />
              </PermissionDisabled>
            )}
          />
        </DrawerFormSection>
      )}
      <Box mt={2}>
        <PermissionDisabled permission="can_modify_wallet" action="edit sources">
          <SaveWalletButton disabled={!isFormDirty || isWalletDeleting} type="submit">
            Save changes
          </SaveWalletButton>
        </PermissionDisabled>
      </Box>
    </DrawerCategory>
  )
}

export default WalletForm
