import { Box, Link, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import {
  BusinessCenterRounded,
  CachedRounded,
  PublicRounded,
  MoreHorizRounded,
  WarningRounded,
} from "@material-ui/icons"
import { Link as RouterLink } from "react-router-dom"
import React, { useContext, useMemo } from "react"

import { ReactComponent as AddIcon } from "CryptioUI/assets/icons/add.svg"
import { URLS } from "../../routes"
import BaseContainer from "components/Container"
import { LabelCircleColor } from "components/Labels/LabelCircleColor"
import MainTitleView from "components/MainTitleView"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDrawer from "components/misc/useDrawer"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import PermissionReadView from "components/Permission/PermissionReadView"
import { GenericSorting, IExtendedDataTableColumn } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import TableTitleWithTotalCount from "components/Table/TableTitleWithTotalCount"
import api from "services/api"
import { GetLabelDto, GetLabelQuery, GetLabelQuerySortByEnum } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import { Mixpanel } from "services/mixpanel"
import GlobalLabelsExplanation from "./GlobalLabelsExplanation"
import toFilterElement from "services/utils/toFilterElement"
import { newColorHex, correspondanceHex } from "CryptioUI/types/tag"
import ButtonUI from "CryptioUI/Button"
import { iconStyleWhite } from "CryptioUI/Utilities/config"
import LabelFilter from "./filters/LabelFilter"
import { PaginationParameters } from "services/urlParse"
import { WithoutWorkspaceId } from "services/api/aliases"
import { pluralize } from "../../services/utils/textUtils"
import TooltipUI from "CryptioUI/Tooltip"

const useStyles = makeStyles((theme: Theme) => ({
  noLabelText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  selectMenuItem: {
    "&.Mui-focusVisible": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))

export type LabelParameters = Partial<GetLabelQuery>

export const defaultLabelParams: LabelParameters = {
  labelType: "all_label",
  query: undefined,
  individualId: undefined,
}

export const defaultLabelSorting: GenericSorting<GetLabelQuerySortByEnum> = {
  sortDirection: "descending",
  sortBy: "created_at",
}

export const labelHasAnyFilter = (filters: LabelParameters) =>
  Boolean(filters.labelType || filters.query || filters.individualId)

const paramsToFilter = (params: PaginationParameters & Partial<LabelParameters>): WithoutWorkspaceId<GetLabelQuery> => {
  return {
    page: params.page,
    limit: params.limit,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
    labelType: params.labelType,
    query: params.query,
    individualId: params.individualId,
  }
}

const LabelsScene = (): JSX.Element => {
  const classes = useStyles()
  const { params, setLimit, setPage, setSorting, setParams } = usePaginatedParams<
    LabelParameters,
    GetLabelQuerySortByEnum
  >(defaultLabelParams, defaultLabelSorting)

  const viewTitle = "Labels"
  const viewDescription = "Labels help adding context to transactions. Click to know more!"
  const viewHelpURL = "https://support.cryptio.co/hc/en-gb/articles/7049569936913-Label-Categorisation"

  const { workspace } = useContext(WorkspaceContext)

  const [labelDrawer, openLabelDrawer] = useDrawer("label")
  const [walletDrawer, openWalletDrawer] = useDrawer("wallet")
  const [contactDrawer, openContactDrawer] = useDrawer("contact")

  const capitalizeContent = (content: string) => {
    return <span className="capitalize">{content}</span>
  }

  const columns = useMemo<IExtendedDataTableColumn<GetLabelDto, GetLabelQuerySortByEnum>[]>(
    () => [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        sortName: "name",
      },
      {
        name: "Color",
        selector: "color",
        sortable: true,
        sortName: "color",
        format: function formatColor(row) {
          return row.color && <LabelCircleColor color={newColorHex[correspondanceHex[row.color]]} />
        },
      },
      {
        name: "Transactions",
        selector: "movementCount",
        sortable: true,
        sortName: "movement_count",
        format: function formatContactAddresses(row) {
          switch (row.status) {
            case "active":
              return (
                <TooltipUI content="Calculating">
                  <CachedRounded />
                </TooltipUI>
              )
            case "pending":
              return (
                <TooltipUI content="Pending">
                  <MoreHorizRounded />
                </TooltipUI>
              )
            case "error":
              return (
                <TooltipUI content="Error">
                  <Typography>
                    <WarningRounded />
                  </Typography>
                </TooltipUI>
              )
            case "inactive":
            default:
              if (row.movementCount === 0) return "0"
              return (
                <Link
                  component={RouterLink}
                  to={URLS.Transactions.getUrl({
                    filters: [{ type: "labels", value: [toFilterElement(row)], isNot: false }],
                  })}
                  underline="always"
                >
                  {row.movementCount}
                </Link>
              )
          }
        },
      },
      {
        name: "From",
        selector: "from",
        format: function formatFrom(row) {
          if (!row.from) return "-"

          const onClick =
            row.from.type === "contact"
              ? () => row.from && openContactDrawer(row.from.items[0].id)
              : () => row.from && openWalletDrawer(row.from.items[0].id)

          return (
            <TooltipUI content={capitalizeContent(row.from.type)}>
              {row.from.items.length === 1 ? (
                <Link underline="always" onClick={onClick}>
                  <Typography>{row.from.items[0].name}</Typography>
                </Link>
              ) : (
                <Typography>
                  {row.from.items.length} {pluralize(true, row.from.type)}
                </Typography>
              )}
            </TooltipUI>
          )
        },
      },
      {
        name: "To",
        selector: "to",
        format: function formatTo(row) {
          if (!row.to) return "-"

          const onClick =
            row.to.type === "contact"
              ? () => row.to && openContactDrawer(row.to.items[0].id)
              : () => row.to && openWalletDrawer(row.to.items[0].id)

          return (
            <TooltipUI content={capitalizeContent(row.to.type)}>
              {row.to.items.length === 1 ? (
                <Link underline="always" onClick={onClick}>
                  <Typography>{row.to.items[0].name}</Typography>
                </Link>
              ) : (
                <Typography>
                  {row.to.items.length} {pluralize(true, row.to.type)}
                </Typography>
              )}
            </TooltipUI>
          )
        },
      },
      {
        name: "Global",
        selector: "isGlobal",
        format: function formatColor(row) {
          return row.isGlobal ? (
            <TooltipUI content="This label is global">
              <PublicRounded data-tag="allowRowEvents" />
            </TooltipUI>
          ) : (
            "-"
          )
        },
      },
    ],
    [openContactDrawer, openWalletDrawer],
  )

  const filterCount = useMemo(() => {
    const filter = params
    let res = 0
    if (filter.labelType !== "all_label") res++
    if (filter.individualId !== undefined) res++
    if (filter.query !== undefined) res++
    return res
  }, [params])

  const labels = api.label.useLabels(paramsToFilter(params), {
    refetchInterval: 2000,
  })
  if (!workspace.userRights.can_read_label) return <PermissionReadView viewTitle={viewTitle} itemName="labels" />

  if (labels.isError) return <NetworkErrorMessage small={false} additionalData={labels} />

  if (labels.isLoading || labels.data === undefined) return <LoadingSpinner />

  const addLabelButton = (
    <PermissionDisabled permission="can_create_label" action="create labels">
      <ButtonUI onClick={() => openLabelDrawer(null)} Icon={<AddIcon className={iconStyleWhite} />}>
        Label
      </ButtonUI>
    </PermissionDisabled>
  )

  const hasAnyFilter = labelHasAnyFilter(params)

  if (labels.data.totalCount === 0 && (params.labelType !== "all_label" || hasAnyFilter)) {
    if (params.page > 1) {
      // I'm doing a set a setTimeout to avoid a warning to be triggered
      setTimeout(() => setPage(1), 0)
      return <LoadingSpinner />
    }

    return (
      <BaseContainer>
        {labelDrawer}
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <MainTitleView
            onClick={() => Mixpanel.track("LabelsInfo")}
            title={viewTitle}
            helpTooltipContent={viewDescription}
            helpTooltipUrl={viewHelpURL}
          />
        </Box>
        <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          {hasAnyFilter ? (
            <>
              <Typography className={classes.noLabelText} variant="h4">
                There are no label that match the selected {pluralize(filterCount > 1, "filter")}.
                <br />
                Click the following buttons to reset or update the filters.
              </Typography>
              <Box display="flex" flexDirection="row">
                <LabelFilter setFilter={setParams} filter={params} />
              </Box>
            </>
          ) : (
            <>
              <BusinessCenterRounded fontSize="large" />
              <Typography className={classes.noLabelText} variant="h4">
                This label list is empty.
                <br />
                Click below to add labels.
              </Typography>
              <Box display="flex" flexDirection="row">
                {addLabelButton}
              </Box>
            </>
          )}
        </Box>
      </BaseContainer>
    )
  }
  return (
    <Box>
      <GlobalLabelsExplanation />
      <BaseContainer>
        {labelDrawer}
        {walletDrawer}
        {contactDrawer}
        <Box mb={2} display="flex" justifyContent="space-between" alignItems="flex-end">
          <TableTitleWithTotalCount
            onClick={() => Mixpanel.track("LabelsInfo")}
            title={viewTitle}
            qualifier="label"
            totalCount={labels.data.totalCount}
            helpTooltipContent={viewDescription}
            helpTooltipUrl={viewHelpURL}
          />
          <Box justifyContent="center" display="flex" flexDirection="row" alignItems="center">
            <Box mr={2}>
              <LabelFilter setFilter={setParams} filter={params} />
            </Box>
            {addLabelButton}
          </Box>
        </Box>
        <Paper>
          <ServerSideTable<GetLabelDto, GetLabelQuerySortByEnum>
            columns={columns}
            items={labels.data.data}
            totalCount={labels.data.totalCount}
            defaultParams={params}
            limit={labels.data.limit}
            onPageChange={setPage}
            onLimitChange={setLimit}
            onSort={setSorting}
            onRowClicked={(row) => openLabelDrawer(row.id)}
          />
        </Paper>
      </BaseContainer>
    </Box>
  )
}

export default LabelsScene
