import React from "react"
import { twMerge } from "tailwind-merge"

import { CardProps } from "CryptioUI/types/card"

const CardUI = (props: CardProps) => {
  const { onClick, disabled = false, className = "", children, fullWidth = false } = props
  const fullWidthStyle = fullWidth ? "w-full" : ""
  const cursorStyle = onClick
    ? "hover:cursor-pointer hover:bg-grey-100 focus:outline focus:outline-4 focus:outline-primary-100 active:bg-grey-300 active:enabled:text-white active:enabled:outline active:enabled:outline-4 active:enabled:outline-offset-0"
    : "hover:cursor-auto"
  const commonStyle =
    "bg-white shadow-md border border-grey-200 rounded-xl text-grey-500 text-md h-fit pointer-events-auto"
  const genericDisabledStyle =
    "disabled:active:text-grey-500 disabled:active:outline-none disabled:bg-grey-200 border border-grey-200 disabled:text-inherit striped disabled:grayscale"
  const finalStyle = twMerge([cursorStyle, fullWidthStyle, commonStyle, genericDisabledStyle, className].join(" "))

  return (
    <button className={finalStyle} onClick={onClick} disabled={disabled} role="button">
      {children}
    </button>
  )
}

export default CardUI
