import React, { useMemo, useState } from "react"

import { DrawerCategory, DrawerFormSection } from "../../../components/Drawer/DrawerItems"
import useFilterDrawer from "../../../components/Hooks/useFilterDrawer"
import InputClearable from "../../../components/Input/InputClearable"
import DrawerUI from "../../../CryptioUI/Drawer"
import { defaultReportParams, reportHasAnyFilter, ReportParameters } from "../index"
import BackOfficeView from "../../../components/misc/BackOfficeView"
import { REGEX_UUID_V4 } from "pure-shared"
import ReportModuleFilter from "./ReportModuleFilter"

interface Props {
  filter: ReportParameters
  setFilter: (newValue: ReportParameters) => void
}

const ReportFilter = (props: Props): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const { OpenButton, SaveButton, tempFilter, updateFilter, setIsErrorValidation } = useFilterDrawer({
    ...props,
    defaultParams: defaultReportParams,
    setIsDrawerOpen,
  })

  const filterCount = useMemo(() => {
    const filter = props.filter
    let res = 0
    if (filter.reportModule !== undefined) res++
    if (filter.reportType !== undefined) res++
    if (filter.individualId !== undefined) res++
    if (filter.walletIds?.length) res++
    if (filter.assetIds?.length) res++
    if (filter.startDate !== undefined) res++
    if (filter.endDate !== undefined) res++
    return res
  }, [props.filter])

  const canClear = useMemo(() => {
    return filterCount > 0 || reportHasAnyFilter(tempFilter)
  }, [filterCount, tempFilter])
  const isUuid4 = (uuid: string) => REGEX_UUID_V4.test(uuid)

  useMemo(() => {
    setIsErrorValidation(tempFilter.individualId !== undefined && !isUuid4(tempFilter.individualId))
  }, [setIsErrorValidation, tempFilter])

  return (
    <>
      <OpenButton filterCount={filterCount} />
      <DrawerUI title="Report Filter" onClose={() => setIsDrawerOpen(false)} isOpen={isDrawerOpen}>
        <DrawerCategory title="Filter">
          <ReportModuleFilter tempFilter={tempFilter} updateFilter={updateFilter} />
          <BackOfficeView>
            <DrawerFormSection htmlFor="report-id-textfield" name="Filter by report ID">
              <InputClearable
                id="report-id-textfield"
                onChange={(newValue) => updateFilter({ individualId: newValue ?? undefined })}
                value={tempFilter.individualId}
                fullWidth
                error={tempFilter.individualId !== undefined && !isUuid4(tempFilter.individualId)}
              />
            </DrawerFormSection>
          </BackOfficeView>
          <SaveButton filterCount={filterCount} canClear={canClear} />
        </DrawerCategory>
      </DrawerUI>
    </>
  )
}

export default ReportFilter
