import { Box } from "@material-ui/core"
import { createFilterOptions } from "@material-ui/lab"
import React, { useState } from "react"

import ContactOptionInAutocomplete from "components/AutoCompleteOptions/ContactOptionInAutocomplete"
import { useLoadingButton } from "components/LoadingButton"
import CustomSelector from "components/selector/CustomSelector"
import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import { GetContactDto, GetContactsRequest, GetUnknownContactDto } from "services/api/openapi"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const CreateContact = ({ address }: GetUnknownContactDto) => {
  const [contact, setContact] = useState<string | GetContactDto | null>(null)
  const toast = useToast()
  const { mutateAsync: createContactMutation } = api.contact.useCreateContacts()
  const { mutateAsync: updateContactMutation } = api.contact.useBatchUpdateContacts()
  const [CreateContactButton, handleButtonCallback] = useLoadingButton()

  return (
    <Box
      component="form"
      onSubmit={handleButtonCallback(async (e) => {
        e.preventDefault()

        if (!contact) {
          return
        }
        try {
          if (typeof contact === "string") {
            await createContactMutation({ createContactsDto: { contacts: [{ name: contact, addresses: [address] }] } })
            toast.open(`Contact ${contact} created`, { variant: "success" })
          } else {
            await updateContactMutation({
              batchUpdateContactDto: {
                id: contact.id,
                addresses: [address],
              },
            })
            toast.open(`Contact ${contact.name} updated`, { variant: "success" })
          }
        } catch (e) {
          toastCatch(e, toast)
        }
        setContact(null)
      })}
      width="100%"
      display="flex"
    >
      <CustomSelector<WithoutWorkspaceId<GetContactsRequest>, string | GetContactDto, false, false, true>
        fullWidth
        value={contact}
        onChange={(_, newValue) => setContact(newValue)}
        filterOptions={(options, params) => {
          const filter = createFilterOptions<GetContactDto>({
            stringify: (opt) => opt.name,
          })

          const filtered: (GetContactDto | string)[] = filter(options as GetContactDto[], params)

          if (
            (params.inputValue !== "" && !options.find((opt) => (opt as GetContactDto).name === params.inputValue)) ||
            filtered.length === 0
          ) {
            filtered.push(params.inputValue)
          }
          return filtered
        }}
        getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
        defaultPaginatedQueryProps={{
          sortBy: "movement_count",
          sortDirection: "descending",
        }}
        getOptionSelected={(opt, other) => {
          if (typeof opt !== typeof other) {
            return false
          }
          if (typeof opt === "string") {
            return opt === other
          }
          return opt.id === (other as GetContactDto).id
        }}
        usePaginatedQuery={api.contact.useContacts}
        freeSolo
        size="small"
        placeholder="Type to search..."
        getOptionDisabled={(option) => option === ""}
        renderOption={(option) => {
          if (option === "") return "No options"
          if (typeof option === "string") return `Create contact "${option}"`
          return <ContactOptionInAutocomplete contact={option as GetContactDto} />
        }}
        onInputBlur={(typingInput) => {
          if (
            typingInput &&
            (!contact || (typeof contact === "string" ? typingInput !== contact : contact.name !== typingInput))
          ) {
            setContact(typingInput)
          }
        }}
      />
      <Box mr={2} />
      <CreateContactButton className="whitespace-nowrap" disabled={!contact} type="submit">
        Ok
      </CreateContactButton>
    </Box>
  )
}

export default CreateContact
