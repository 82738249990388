import { Box, Grid, IconButton, makeStyles, TextField, Theme, Typography } from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import { toastCatch } from "components/ReactHookForm/utils"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"
import { useToast } from "CryptioUI/Toaster"
import React from "react"

import api from "services/api"
import { BasicElement } from "services/api/aliases"
import {
  GetMinimalWorkspaceRoleDto,
  GetWorkspaceDto,
  GetWorkspaceUserDto,
  PaginatedWorkspaceUserResultDto,
} from "services/api/openapi"
import BackOfficeView from "components/misc/BackOfficeView"

interface Props {
  askDeleteUser: (user: GetWorkspaceUserDto) => void
  workspace: GetWorkspaceDto
  roles: GetMinimalWorkspaceRoleDto[]
  workspaceUsers: PaginatedWorkspaceUserResultDto
  askOwnershipUser: (user: GetWorkspaceUserDto) => void
}
const useStyles = makeStyles((theme: Theme) => ({
  autocompleteRole: {
    minWidth: theme.spacing(25),
  },
}))

const WorkspaceMembers = ({ askDeleteUser, workspace, roles, workspaceUsers, askOwnershipUser }: Props) => {
  const classes = useStyles()
  const toast = useToast()
  const { mutateAsync: updateWorkspaceUserMutation } = api.workspace.useUpdateWorkspaceUser()

  const handleRoleOnChange = async (role: GetMinimalWorkspaceRoleDto | BasicElement, userId: string) => {
    try {
      if (!workspace) {
        return
      }
      await updateWorkspaceUserMutation({
        updateWorkspaceUserDto: { roleId: role.id },
        workspaceId: workspace.id,
        userId,
      })
      toast.open("User role updated", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }
  return (
    <>
      {workspaceUsers.data.map((wUser) => (
        <Box key={wUser.userId}>
          <Grid container spacing={3} alignItems="center">
            {workspace.userRights.can_remove_workspace_user && (
              <Grid item xs={1}>
                <IconButton
                  style={{ padding: 0 }}
                  disabled={wUser.userId === workspace.ownerUserId}
                  onClick={() => askDeleteUser(wUser)}
                >
                  <Delete />
                </IconButton>
              </Grid>
            )}
            <Grid item xs={5}>
              <Box>
                <Typography noWrap data-test-id="workspace-member">
                  {wUser.userName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              {workspace.userRights.can_modify_workspace_user && wUser.userId !== workspace.ownerUserId ? (
                <Autocomplete
                  value={{ id: wUser.roleId, name: wUser.roleName }}
                  getOptionSelected={(option, selected) => option.id === selected.id}
                  onChange={(_, role) => handleRoleOnChange(role, wUser.userId)}
                  getOptionLabel={(role) => role.name}
                  options={(roles as BasicElement[]) ?? []}
                  disableClearable
                  className={classes.autocompleteRole}
                  renderInput={(params) => <TextField {...params} />}
                  size="small"
                />
              ) : (
                <Box>
                  <Typography>{wUser.userId !== workspace.ownerUserId ? wUser.roleName : "Owner"}</Typography>
                </Box>
              )}
            </Grid>
            <BackOfficeView adminOnly>
              <PermissionDisabled permission="can_modify_workspace_user" action="change workspace owner">
                <Grid item xs={1}>
                  <IconButton
                    className="p-0"
                    disabled={wUser.userId === workspace.ownerUserId}
                    onClick={() => askOwnershipUser(wUser)}
                  >
                    <VerifiedUserIcon />
                  </IconButton>
                </Grid>
              </PermissionDisabled>
            </BackOfficeView>
          </Grid>
        </Box>
      ))}
    </>
  )
}

export default WorkspaceMembers
