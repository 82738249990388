import React, { useContext } from "react"
import WarningTypography from "components/WarningTypography"
import { WorkspaceContext } from "services/context/workspaceContext"

const WarningForm = () => {
  const workspaceCtx = useContext(WorkspaceContext)

  return (
    <>
      {workspaceCtx.workspace.isCoaMappingDirty && (
        <WarningTypography alignItems="start" mt={3}>
          You have done some modifications on the chart of accounts mappings. Please apply the mappings before
          generating a ledger entries report.
        </WarningTypography>
      )}

      {workspaceCtx.workspace.isCostBasisDirty && (
        <WarningTypography alignItems="start" mt={3}>
          Some modifications on pricing have been made. Please update the cost basis before generating a ledger entries
          report or a cost basis report.
        </WarningTypography>
      )}

      {!workspaceCtx.workspace.accountingIntegration && (
        <WarningTypography alignItems="start" mt={3}>
          There are no accounting integration linked with this workspace. Hence you will not be able to generate ledger
          entries and journal sequential numbering reports.
        </WarningTypography>
      )}
    </>
  )
}

export default WarningForm
