import { Box, Divider, Typography } from "@material-ui/core"
import React from "react"

import { DrawerSection } from "components/Drawer/DrawerItems"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import api from "services/api"
import { GetPublicAssetDto } from "services/api/openapi/models"

const AssetSummaryBackOffice = ({ asset }: { asset: GetPublicAssetDto }) => {
  const assetLinks = api.backOffice.asset.useAssetLinks(asset.id)

  if (assetLinks.isError) return <NetworkErrorMessage additionalData={assetLinks} />
  if (assetLinks.isLoading || assetLinks.data === undefined) return <LoadingSpinner />

  return (
    <Box>
      <DrawerSection name="Asset exchange links">
        {assetLinks.data.map((link, idx) => (
          <React.Fragment key={link.walletTypeId + link.exchangeSymbol}>
            {idx > 0 && <Divider style={{ marginTop: 8, marginBottom: 8 }} />}
            <Typography variant="body1">Exchange: {link.walletTypeName}</Typography>
            <Typography variant="body2">Symbol: {link.exchangeSymbol}</Typography>
          </React.Fragment>
        ))}
        {assetLinks.data.length === 0 && <Typography>-</Typography>}
      </DrawerSection>
    </Box>
  )
}

export default AssetSummaryBackOffice
