import { Typography } from "@material-ui/core"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import BaseContainer from "components/Container"

import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import useDrawer from "components/misc/useDrawer"
import PermissionReadView from "components/Permission/PermissionReadView"
import api from "services/api"
import {
  NewFullMovementDto,
  NewInListMovementDto,
  NewTransactionPaginationQueryDto,
  NewTransactionPaginationQueryDtoSortDirectionEnum,
} from "services/api/openapi"
import { FilterContext } from "services/context/filterContext"
import { SelectTransactionPageContext } from "services/context/selectTransactionPageContext"
import { WorkspaceContext } from "services/context/workspaceContext"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import BulkMovementDrawer from "./BulkMovementDrawer"
import CostBasisModal from "./CostBasisModal"
import FilterController from "./FilterController"
import GeneratedTable from "./GeneratedTable"
import { ColumnsName } from "./GeneratedTable/ContainerColumn"
import useBulkSelectorTransactionPage from "./GeneratedTable/useBulkSelectorTransactionPage"
import { useConfigTable } from "./GeneratedTable/useConfigTable"
import useDeveloppedTransactionPage from "./GeneratedTable/useDeveloppedTransactionPage"
import NoTransaction from "./NoTransaction"
import { useHistory } from "react-router"
import { URLS } from "../../routes"
import { reviewFilters } from "../../services/utils/toFilterElement"

export type TransactionParams = Pick<NewTransactionPaginationQueryDto, "filters">

export const newTransactionHasAnyFilter = (filters: TransactionParams) => filters.filters.length > 0

export type Trade<TMovement extends NewInListMovementDto | NewFullMovementDto = NewInListMovementDto> = {
  in: TMovement
  out: TMovement
}

export const defaultTransactionParams: TransactionParams = { filters: [] }

export const defaultTransactionSorting: {
  sortDirection: NewTransactionPaginationQueryDtoSortDirectionEnum
  sortBy: "transaction_date"
} = {
  sortDirection: "descending",
  sortBy: "transaction_date",
}

export type CostBasisIds = {
  incomingMovementId: string | undefined
  feeMovementId: string | undefined
  outgoingMovementId: string | undefined
}

const CoreTransaction = (): JSX.Element => {
  const workspaceCtx = useContext(WorkspaceContext)
  const mvtSelected = useContext(SelectTransactionPageContext)
  const history = useHistory()

  const [walletDrawer, openWalletMovementDrawer] = useDrawer("wallet")
  const [movementLabelDrawer, openMovementLabelDrawer] = useDrawer("movement-label")
  const [transactionLabelDrawer, openTransactionLabelDrawer] = useDrawer("transaction-label")
  const [tradeDrawer, openTradeDrawer] = useDrawer("trade")
  const [costBasisDrawerTransactionId, setCostBasisDrawerTransactionId] = useState<CostBasisIds | null>(null)
  const [transactionDrawer, openTransactionDrawer] = useDrawer("transaction")
  const [movementDrawer, openMovementDrawer] = useDrawer("movement")
  const [hiddenColumns, setHiddenColumns] = useState<ColumnsName[]>([])
  const [isBulkEditDrawerOpen, setIsBulkEditDrawerOpen] = useState<boolean>(false)
  const { isNeedReview, isTab } = useContext(FilterContext)
  const columnsNameArray = useMemo(() => {
    return Object.values(ColumnsName)
  }, [])
  const columnsConfig = useConfigTable()

  const filteredColumnsNameArray = useMemo(() => {
    return columnsNameArray.filter((column) => {
      const columnFound = columnsConfig.find((col) => col.name === column)
      if (columnFound === undefined || !columnFound?.omit) return column
    })
  }, [columnsNameArray, columnsConfig])

  const desabledColumnsNameArray = useMemo(
    () =>
      filteredColumnsNameArray.filter((column) => {
        const columnFound = hiddenColumns.find((col) => col === column)
        if (columnFound === undefined) return column
      }),
    [hiddenColumns, filteredColumnsNameArray],
  )

  const {
    params,
    setLimit,
    setPage,
    setSorting,
    setParams: _setParams,
  } = usePaginatedParams<TransactionParams, "transaction_date">(defaultTransactionParams, defaultTransactionSorting)

  if (isNeedReview && params.filters.length === 0) {
    if (isTab("missingLabel")) {
      history.replace(URLS.TransactionsReviewMissingLabel.getUrl(reviewFilters.missingLabel))
    } else if (isTab("internalTransfer")) {
      history.replace(URLS.TransactionsReviewInternalTransfer.getUrl(reviewFilters.internalTransfer))
    } else if (isTab("incompleteChartOfAccounts")) {
      history.replace(URLS.TransactionsReviewIncompleteCOA.getUrl(reviewFilters.incompleteChartOfAccounts))
    } else if (isTab("readyToBeSynchronized")) {
      history.replace(URLS.TransactionsReviewReadyToBeSynced.getUrl(reviewFilters.readyToBeSynchronized))
    }
  }

  const { search } = useLocation()

  const transactionId = new URLSearchParams(search).get("transactionId")

  useEffect(() => {
    if (transactionId) {
      openTransactionDrawer(transactionId)
    }
  }, [openTransactionDrawer, transactionId])

  const transactions = api.transaction.useTransactions(
    {
      newTransactionPaginationQueryDto: {
        page: params.page,
        limit: params.limit,
        sortDirection: params.sortDirection,
        filters: params.filters,
      },
    },
    {
      refetchInterval: 5000,
      notifyOnChangeProps: ["data", "error", "isError", "isLoading", "isFetched"],
    },
  )

  const basicDialog = useDialog()

  const setParamsWithDialog = (newParams: TransactionParams) => {
    if (selectedRows === undefined) _setParams(newParams)
    else {
      basicDialog.showDialog({
        title: "Update filter?",
        content: <Typography variant="h5">If you update the filter you will loose your selection.</Typography>,
        yesText: "Yes",
        noText: "Cancel",
        onAccept: () => {
          setSelectedRows(undefined)
          _setParams(newParams)
        },
      })
    }
  }

  const { selectedRows, setSelectedRows, isPageSelected, onPageSelected, selectedRowCount } =
    useBulkSelectorTransactionPage(transactions.data, (t) => t.id)

  const { developpedRows, setDeveloppedRows } = useDeveloppedTransactionPage(
    //TO DO develop everything
    transactions.data,
    (t) => t.id,
  )

  const isRefetching = transactions.isRefetching && !transactions.isFetched

  if (!workspaceCtx.workspace.userRights.can_read_transaction)
    return <PermissionReadView viewTitle={"Transactions"} itemName="transactions" />
  // A network error occured. Too bad!
  if (transactions.isError) return <NetworkErrorMessage small={false} additionalData={transactions} />
  // We are still talking with the server. Wait a moment!
  if (transactions.isLoading || transactions.data === undefined || isRefetching) {
    return <LoadingSpinner />
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setLimit(parseInt(event.target.value, 10))
  }

  if (transactions.data?.totalCount === 0 && !isNeedReview) {
    if (params.page > 1) {
      // I'm doing a set a setTimeout to avoid a warning to be triggered
      setTimeout(() => setPage(1), 0)
      return <LoadingSpinner />
    }

    const hasAnyFilter = newTransactionHasAnyFilter(params)

    return (
      <NoTransaction
        hasAnyFilter={hasAnyFilter}
        walletDrawer={walletDrawer}
        setParamsWithDialog={setParamsWithDialog}
        params={params}
      />
    )
  }

  return (
    <BaseContainer>
      {basicDialog.dialog}
      {walletDrawer}
      {movementLabelDrawer}
      {transactionLabelDrawer}
      {transactionDrawer}
      {tradeDrawer}
      {movementDrawer}
      <FilterController
        isRefetching={isRefetching}
        totalCount={transactions.data.totalCount}
        maxTotalCount={transactions.data.maxTotalCount}
        selectedTransactionCount={selectedRowCount}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setParamsWithDialog={setParamsWithDialog}
        params={params}
        columns={filteredColumnsNameArray}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={setHiddenColumns}
        setIsBulkEditDrawerOpen={setIsBulkEditDrawerOpen}
        mvtSelected={mvtSelected}
        transactions={transactions.data.data}
      />
      <GeneratedTable
        totalCount={transactions.data.totalCount}
        transactions={transactions.data.data}
        openWalletMovementDrawer={openWalletMovementDrawer}
        openMovementLabelDrawer={openMovementLabelDrawer}
        setCostBasisDrawerTransactionId={setCostBasisDrawerTransactionId}
        openTransactionDrawer={openTransactionDrawer}
        openTradeDrawer={openTradeDrawer}
        openMovementDrawer={openMovementDrawer}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        developpedRows={developpedRows}
        setDeveloppedRows={setDeveloppedRows}
        onPageSelected={onPageSelected}
        isPageSelected={isPageSelected}
        filteredColumnsNameArray={desabledColumnsNameArray}
        openTransactionLabelDrawer={openTransactionLabelDrawer}
        mvtSelected={mvtSelected}
        desabledColumnsNameArray={desabledColumnsNameArray}
        hiddenColumns={hiddenColumns}
        params={params}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        setSorting={setSorting}
        sortDirection={params.sortDirection}
      />
      <BulkMovementDrawer
        isOpen={isBulkEditDrawerOpen}
        onClose={() => setIsBulkEditDrawerOpen(false)}
        params={params}
        selectedRows={selectedRows}
        mvtSelected={mvtSelected}
        selectedCount={selectedRowCount}
      />
      <CostBasisModal
        movementIds={costBasisDrawerTransactionId ?? null}
        onClose={() => setCostBasisDrawerTransactionId(null)}
      />
    </BaseContainer>
  )
}

export default CoreTransaction
