import { TextField, TextFieldProps } from "@material-ui/core"
import React from "react"

interface Props extends Omit<TextFieldProps, "type" | "onError" | "variant" | "value"> {
  onError: (error: { message: string; value: string }) => void
  value: string
}

export const InputEmail = React.forwardRef((props: Props, ref) => {
  const { onChange, onError, onBlur, value, error, ...rest } = props

  return (
    <TextField
      type="email"
      onChange={async (event) => {
        const value = event.target.value
        if (event.currentTarget.reportValidity() === false) {
          onError({ message: "Invalid email address", value })
        } else if (onChange) {
          onChange(event)
        }
      }}
      onBlur={onBlur}
      value={value}
      error={error}
      innerRef={ref}
      {...rest}
    />
  )
})
InputEmail.displayName = "InputEmail"
