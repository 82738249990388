export enum RadioMode {
  OUTLINED = "Outlined",
  TEXTONLY = "TextOnly",
}

export interface InputRadioUiProps {
  handleChange: (newValue: boolean) => void
  label: string
  name: string
  checked: boolean
  disabled?: boolean
  className?: string
  variant?: RadioMode
}
