import { useTypedController } from "@hookform/strictly-typed"
import { ReportFormComponentProps } from "."
import WalletOptionInAutocomplete from "components/AutoCompleteOptions/WalletOptionInAutocomplete"
import { DrawerFormSection } from "components/Drawer/DrawerItems"
import CustomSelector from "components/selector/CustomSelector"
import WarningTypography from "components/WarningTypography"
import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import { GetWalletsRequest, GetWalletDto, GetWalletsTypeNamesEnum } from "services/api/openapi"
import { DrawerReportForm } from "../ReportForm"
import { matchReportExportFormatIdentifier } from "./interface"
import { CustomSourceNamesArray } from "pure-shared"

export const chainlinkNodeOPeratorValidSourceNames: GetWalletsTypeNamesEnum[] = ["Ethereum"]

const ReportMultiSelectWallet = ({ control, reportType, reportFormat }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })

  const isChainlinkReport = matchReportExportFormatIdentifier([{ type: "chainlink_node_operator" }], {
    reportFormat,
    reportType,
  })
  const isTransactionsCustomReport = matchReportExportFormatIdentifier([{ type: "transactions_custom_export" }], {
    reportFormat,
    reportType,
  })

  return (
    <>
      {isChainlinkReport && (
        <WarningTypography alignItems="start" mt={3}>
          Only ethereum wallets can be selected
        </WarningTypography>
      )}
      <DrawerFormSection htmlFor="source-select" name="Wallet">
        <TypedController
          name="wallets"
          defaultValue={[]}
          render={({ ref, onChange, ...rest }) => (
            <CustomSelector<WithoutWorkspaceId<GetWalletsRequest>, GetWalletDto, true>
              id="source-select"
              {...rest}
              inputRef={ref}
              onChange={(_, newValue) => onChange(newValue)}
              getOptionLabel={(option) => option.name}
              defaultPaginatedQueryProps={{
                sortBy: "name",
                sortDirection: "ascending",
                excludedIds: rest.value?.map((v) => v.id),
                walletSourceType: isTransactionsCustomReport ? [...CustomSourceNamesArray] : undefined,
                typeNames: isChainlinkReport ? chainlinkNodeOPeratorValidSourceNames : undefined,
              }}
              getOptionDisabled={rest.value && rest.value.length >= 50 ? () => true : () => false}
              getOptionSelected={(option, value) => option.id === value.id}
              multiple
              disableCloseOnSelect
              usePaginatedQuery={api.wallet.useWallets}
              size="small"
              placeholder={rest.value?.length ? undefined : "All"}
              filterSelectedOptions
              renderOption={(option) => <WalletOptionInAutocomplete wallet={option} />}
            />
          )}
        />
      </DrawerFormSection>
    </>
  )
}

export default ReportMultiSelectWallet
