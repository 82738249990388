/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  DefiLivePositionDto,
  DefiPositionTotalDto,
  PaginatedDefiBlockchainResultDto,
  PaginatedDefiLivePositionResult,
  PaginatedDefiPositionNameResultDto,
  PaginatedDefiPositionTypeResultDto,
  PaginatedDefiProtocolResultDto,
} from "../models"

export interface GetDefiNetworkRequest {
  page: number
  limit: number
  workspaceId: string
  onlyInWorkspace?: boolean
  excludedIds?: Array<string>
}

export interface GetDefiProtocolsRequest {
  page: number
  limit: number
  workspaceId: string
  onlyInWorkspace?: boolean
  excludedIds?: Array<string>
}

export interface GetLivePositionRequest {
  workspaceId: string
  positionId: string
}

export interface GetLivePositionsRequest {
  page: number
  limit: number
  workspaceId: string
  state?: GetLivePositionsStateEnum
  applicationsIds?: Array<string>
  blockchainIds?: Array<string>
  positionsNames?: Array<string>
  positionsTypes?: Array<string>
  walletIds?: Array<string>
  excludedIds?: Array<string>
}

export interface GetPositionNamesRequest {
  page: number
  limit: number
  workspaceId: string
  onlyInWorkspace?: boolean
  excludedIds?: Array<string>
}

export interface GetPositionTypesRequest {
  page: number
  limit: number
  workspaceId: string
  onlyInWorkspace?: boolean
  excludedIds?: Array<string>
}

export interface TotalDefiPositionsRequest {
  workspaceId: string
}

/**
 *
 */
export class DefiApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static getDefiNetwork(params: GetDefiNetworkRequest) {
    return localDefiApi.getDefiNetwork(params)
  }

  /**
   * Get user\'s defi networks
   */
  getDefiNetwork({
    workspaceId,
    ...queryParameters
  }: GetDefiNetworkRequest): Promise<PaginatedDefiBlockchainResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/defi-position/networks?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }

  static getDefiProtocols(params: GetDefiProtocolsRequest) {
    return localDefiApi.getDefiProtocols(params)
  }

  /**
   * Get user\'s defi protocols
   */
  getDefiProtocols({
    workspaceId,
    ...queryParameters
  }: GetDefiProtocolsRequest): Promise<PaginatedDefiProtocolResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/defi-position/protocols?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }

  static getLivePosition(params: GetLivePositionRequest) {
    return localDefiApi.getLivePosition(params)
  }

  /**
   * Get a position by id
   */
  getLivePosition({ workspaceId, positionId }: GetLivePositionRequest): Promise<DefiLivePositionDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/defi-position/${encodeURIComponent(positionId)}`,
    )
  }

  static getLivePositions(params: GetLivePositionsRequest) {
    return localDefiApi.getLivePositions(params)
  }

  /**
   * Get all positions
   */
  getLivePositions({
    workspaceId,
    ...queryParameters
  }: GetLivePositionsRequest): Promise<PaginatedDefiLivePositionResult> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/defi-position?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }

  static getPositionNames(params: GetPositionNamesRequest) {
    return localDefiApi.getPositionNames(params)
  }

  /**
   * Get user\'s defi position name
   */
  getPositionNames({
    workspaceId,
    ...queryParameters
  }: GetPositionNamesRequest): Promise<PaginatedDefiPositionNameResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(
        workspaceId,
      )}/defi-position/position-names?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
    )
  }

  static getPositionTypes(params: GetPositionTypesRequest) {
    return localDefiApi.getPositionTypes(params)
  }

  /**
   * Get user\'s defi position type
   */
  getPositionTypes({
    workspaceId,
    ...queryParameters
  }: GetPositionTypesRequest): Promise<PaginatedDefiPositionTypeResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(
        workspaceId,
      )}/defi-position/position-types?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
    )
  }

  static totalDefiPositions(params: TotalDefiPositionsRequest) {
    return localDefiApi.totalDefiPositions(params)
  }

  /**
   * Get total positions
   */
  totalDefiPositions({ workspaceId }: TotalDefiPositionsRequest): Promise<DefiPositionTotalDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/defi-position/total`)
  }
}

const localDefiApi = new DefiApi()

/**
 * @export
 * @enum {string}
 */
export type GetLivePositionsStateEnum = "open" | "close"
