import { useTypedController } from "@hookform/strictly-typed"
import { Box, Divider, makeStyles, TextField, Theme, Typography } from "@material-ui/core"
import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import { useLoadingButton } from "components/LoadingButton"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { Mode } from "CryptioUI/types"

import api from "services/api"
import InputButtonUI from "CryptioUI/InputButton"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  formInputField: {
    marginBottom: theme.spacing(3),
  },
  validateButton: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  attachInvoiceText: {
    marginBottom: theme.spacing(0.5),
  },
}))

interface AddInvoiceForm {
  invoiceRef: string
}

interface Props {
  isOpen: boolean
  setIsOpen: (newValue: boolean) => void
  transactionId: string
}

const AddInvoiceModal = ({ transactionId, setIsOpen, isOpen }: Props): JSX.Element => {
  const classes = useStyles()
  const { handleSubmit, control, reset, formState } = useForm<AddInvoiceForm>({
    mode: "onChange",
  })
  const TypedController = useTypedController<AddInvoiceForm>({ control })
  const toast = useToast()
  const { mutateAsync: updateTransactionInvoiceMutation } = api.transaction.useUpdateTransactionInvoice()

  // TODO: upload file via generated
  const { mutateAsync: createInvoiceMutation } = api.invoices.useCreateInvoices()
  const [invoiceFile, setInvoiceFile] = useState<File | null>(null)

  const [ImportInvoiceButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = useCallback(
    async ({ invoiceRef }: AddInvoiceForm) => {
      try {
        const invoice = await createInvoiceMutation({
          invoiceRef,
          source: "internal_pdf",
          file: invoiceFile,
        })

        if (invoice) {
          await updateTransactionInvoiceMutation({
            updateTransactionInvoiceDto: {
              transactionId: transactionId,
              invoiceId: invoice.id,
            },
          })
          setIsOpen(false)
          toast.open("Invoice created", { variant: "success" })
          reset()
        }
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [setIsOpen, reset, invoiceFile, createInvoiceMutation, transactionId, updateTransactionInvoiceMutation, toast],
  )

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFile = event.target.files.item(0) as File
      // 5.5MO
      if (newFile?.size > 5.5e6) {
        toast.open("File exceeds 5MB", { variant: "danger" })
      } else {
        setInvoiceFile(newFile)
      }
    }
  }

  return (
    <>
      <ModalUI isOpen={isOpen} onClose={() => setIsOpen(false)} className="w-[38em]" title="Add invoice">
        <Box
          component="form"
          onSubmit={handleButtonCallback(handleSubmit(onSubmit))}
          p={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <TypedController
            name="invoiceRef"
            defaultValue={""}
            rules={{ required: true }}
            render={(props) => (
              <TextField {...props} className={classes.formInputField} placeholder="Invoice number" fullWidth={true} />
            )}
          />

          <Box width="100%" display="flex" justifyItems="flex-start" flexDirection="column">
            <Typography variant="subtitle1" className={classes.attachInvoiceText}>
              (format pdf,csv,xlsx, 5MB max)
            </Typography>
            <ButtonUI mode={Mode.CONTAINED} className={classes.formInputField} fullWidth={true}>
              <label htmlFor="invoice"></label>
            </ButtonUI>
            <InputButtonUI
              mode={Mode.CONTAINED}
              className={classes.formInputField}
              fullWidth={true}
              type="file"
              accept=".pdf"
              name="invoice"
              id="invoice"
              onChange={handleFileChange}
            >
              <Typography variant="body1">{invoiceFile ? invoiceFile.name : "Attach invoice (optional)"}</Typography>
            </InputButtonUI>
          </Box>

          <Divider />
          <Box mt={1}>
            <ImportInvoiceButton className={classes.validateButton} type="submit" disabled={!formState.isValid}>
              Import
            </ImportInvoiceButton>
          </Box>
        </Box>
      </ModalUI>
    </>
  )
}

export default AddInvoiceModal
