import { PaletteType } from "@material-ui/core"
import { createTheme } from "react-data-table-component"

import getTheme from "../../../materialTheme"

const DataTableThemeName = "cryptio_widgets"

const createDataTableTheme = (paletteType: PaletteType) => {
  const { palette } = getTheme(paletteType)

  return createTheme(DataTableThemeName, {
    text: {
      primary: palette.text.primary,
      secondary: palette.text.secondary,
      disabled: palette.text.disabled,
    },
    background: {
      default: palette.background.paper,
    },
    context: {
      background: "#FF00FF",
      text: "#FF00FF",
    },
    divider: {
      default: palette.divider,
    },
    action: {
      button: "#FF00FF",
      hover: "#FF00FF",
      focus: "#FF00FF",
      disabled: "#FF00FF",
    },
    sortFocus: {
      default: palette.primary.light,
    },
    selected: {
      default: palette.background.default,
      text: palette.getContrastText(palette.background.default),
    },
    highlightOnHover: {
      default: palette.action.hover,
      text: palette.primary.main,
    },
    striped: {
      default: "#FF00FF",
      text: "#FF00FF",
    },
  })
}

export const widgetTableStyle = (paletteType: PaletteType) => {
  const { typography } = getTheme(paletteType)
  const theme = createDataTableTheme(paletteType)

  return {
    headRow: {
      style: {
        minHeight: "56px",
        borderBottomWidth: "1px",
        borderBottomColor: "rgba(100, 100, 100, 0.1)",
        borderBottomStyle: "solid",
      },
      denseStyle: {
        minHeight: "32px",
      },
    },
    headCells: {
      style: {
        fontSize: typography.body1.fontSize,
        fontWeight: "bold",
        borderBottomColor: "rgba(100, 100, 100, 0.1)",
      },
    },
    cells: {
      style: {
        cursor: "default",
      },
    },
    rows: {
      style: {
        "fontSize": "13px",
        "color": theme.text.primary,
        "backgroundColor": theme.background.default,
        "minHeight": "65px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "rgba(100, 100, 100, 0.1)",
        },
        "cursor": "pointer",
      },
      denseStyle: {
        minHeight: "32px",
      },
      highlightOnHoverStyle: {
        color: theme.highlightOnHover.text,
        backgroundColor: theme.highlightOnHover.default,
        transitionDuration: "0.15s",
        transitionProperty: "background-color",
        outlineStyle: "solid",
        outlineWidth: "1px",
        outlineColor: theme.background.default,
      },
      stripedStyle: {
        color: theme.striped.text,
        backgroundColor: theme.striped.default,
      },
    },
    noData: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.text.primary,
        backgroundColor: theme.background.default,
      },
    },
    progress: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.text.primary,
        backgroundColor: theme.background.default,
      },
    },
  }
}
