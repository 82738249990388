import { useTypedController } from "@hookform/strictly-typed"
import {
  Box,
  Checkbox,
  DialogActions,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import { checkIntegrationFormat } from "pure-shared"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { DrawerCategory } from "components/Drawer/DrawerItems"
import FieldHelper from "components/FieldHelper"
import useDialog from "components/misc/useDialog"
import { DrawerProp } from "components/misc/useDrawer"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { FireblocksResultDto } from "services/api/openapi"
import { showConfirmation } from "../../utils"
import { IntegrationForm } from "./index"
import { toastCatch } from "components/ReactHookForm/utils"
import { useToast } from "CryptioUI/Toaster"

type UpdateCredentialsForm = Omit<IntegrationForm, "importHiddenVaults">

interface ModalProps {
  isDialogOpen: boolean
  setDialogOpen: (isOpen: boolean) => void
  formId: string
}

function FireblocksUpdateCredentialsModal({ isDialogOpen, setDialogOpen, formId }: ModalProps) {
  const { control, handleSubmit, formState } = useForm<IntegrationForm>({
    mode: "onChange",
  })
  const TypedController = useTypedController<IntegrationForm>({ control })
  const dialog = useDialog()
  const toast = useToast()
  const { mutateAsync: updateFireblocksMutation } = api.integrations.useUpdateFireblocks()
  const { mutateAsync: updateFireblocksCredentials } = api.integrations.useUpdateFireblocksCredentials()

  async function submitImport({ apiKey, apiSecret }: UpdateCredentialsForm) {
    try {
      await updateFireblocksCredentials({
        fireblocksUpdateCredentialsDto: {
          apiKey,
          apiSecret: apiSecret.length > 0 ? apiSecret : undefined,
        },
      })
      await updateFireblocksMutation({})
      setDialogOpen(false)
      toast.open("Fireblocks credentials updated", {
        variant: "success",
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  async function updateCredentials(form: UpdateCredentialsForm) {
    if (!checkIntegrationFormat("fireblocks", form.apiKey)) {
      showConfirmation(dialog, () => submitImport(form))
    } else {
      submitImport(form)
    }
  }

  return (
    <ModalUI
      isOpen={isDialogOpen}
      onClose={() => setDialogOpen(false)}
      className="w-[700px]"
      title="Update credentials"
    >
      <Divider />
      {dialog.dialog}
      <form id={formId} onSubmit={handleSubmit(updateCredentials)}>
        <Box mt={2} padding="8px 24px">
          <Grid container alignItems="center">
            <FormLabel htmlFor="apiKey-textfield">API key</FormLabel>
            <Box ml={1}>
              <FieldHelper helpUrl={"https://docs.fireblocks.com/api/#issuing-api-credentials"} />
            </Box>
          </Grid>
          <TypedController
            name="apiKey"
            defaultValue=""
            rules={{ required: true, minLength: 1 }}
            render={(props) => (
              <TextField {...props} id="apiKey-textfield" fullWidth variant="standard" margin="normal" />
            )}
          />
        </Box>
        <Box mt={2} padding="8px 24px">
          <Grid container alignItems="center">
            <FormLabel htmlFor="apiSecret-textfield">API secret (optional)</FormLabel>
          </Grid>
          <TypedController
            name="apiSecret"
            defaultValue=""
            rules={{ required: false }}
            render={(props) => (
              <TextField
                {...props}
                id="apiSecret-textfield"
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                margin="dense"
              />
            )}
          />
        </Box>
        <DialogActions>
          <ButtonUI
            mode={Mode.CONTAINED}
            onClick={() => {
              setDialogOpen(false)
            }}
          >
            Cancel
          </ButtonUI>
          <ButtonUI disabled={!formState.isValid} type="submit" form={formId}>
            Update
          </ButtonUI>
        </DialogActions>
      </form>
    </ModalUI>
  )
}

function FireblocksIntegrationEditionDrawer(props: DrawerProp<FireblocksResultDto, true>) {
  const { item: integration, onClose } = props
  const toast = useToast()
  const { mutateAsync: updateFireblocksSettings } = api.integrations.useUpdateFireblocksSettings()
  const [importHiddenVaults, setImportHiddenVaults] = useState<boolean>(integration?.importHiddenVaults ?? false)

  const onSubmit = async () => {
    try {
      await updateFireblocksSettings({
        fireblocksUpdateSettingsDto: {
          importHiddenVaults,
        },
      })
      toast.open("Changes saved", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
    onClose()
  }

  const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
  const isFormDirty = importHiddenVaults !== integration?.importHiddenVaults

  return (
    <>
      <FireblocksUpdateCredentialsModal
        isDialogOpen={isDialogOpen}
        setDialogOpen={setDialogOpen}
        formId="business-integrations-credentials-fireblocks-form"
      />
      <DrawerCategory title="Integration Edition">
        <Box mt={3}>
          <Typography variant="body2">
            If the API keys associated with Fireblocks expired, you can update them so that you don&apos;t have to
            disconnect and reconnect again.
          </Typography>
          <Box mt={2} />
          <Typography variant="body2">
            Use the button below to update credentials. Once the credentials are updated, you will be able to update
            Fireblocks.
          </Typography>
          <Box mt={2} />
          <ButtonUI onClick={() => setDialogOpen(true)}>Update credentials</ButtonUI>
        </Box>
      </DrawerCategory>
      <DrawerCategory title="Hidden Vaults">
        <Box mt={2}>
          <Box mt={2}>
            <FormControlLabel
              control={<Checkbox color="primary" onChange={() => setImportHiddenVaults(!importHiddenVaults)} />}
              label="Import hidden vaults"
              checked={importHiddenVaults}
            />
            <Box mt={1} />
            <ButtonUI disabled={!isFormDirty} onClick={onSubmit}>
              Save changes
            </ButtonUI>
          </Box>
        </Box>
      </DrawerCategory>
    </>
  )
}

export default FireblocksIntegrationEditionDrawer
