import { Box, makeStyles, Paper, PaperProps, Typography } from "@material-ui/core"
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined"
import RecordVoiceOverOutlinedIcon from "@material-ui/icons/RecordVoiceOverOutlined"
import React, { useState } from "react"

import AppLayout from "components/layout"
import LoadingSpinner from "components/misc/LoadingSpinner"
import TitleWithDivider from "components/TitleWithDivider"
import { DarkGrey } from "../../materialTheme"
import api from "services/api"
import ChildrenProps from "services/misc/childrenProps"
import { pluralize } from "services/utils/textUtils"
import NoWorkspaceCreateWorkspaceForm from "./CreateWorkspace"
import NoWorkspaceInvitationForm from "./JoinWorkspace"

const useStyles = makeStyles(() => ({
  container: {
    margin: "0 auto",
    maxWidth: "38em",
  },
  card: {
    cursor: "pointer",
  },
  selectedCard: {
    border: "solid",
  },
  noInvitationCard: {
    cursor: "not-allowed",
  },
  subtitle: {
    fontWeight: 900,
  },
  text: {
    textAlign: "center",
    color: DarkGrey,
    fontSize: "1em",
  },
  icon: {
    width: "1.8em",
    height: "1.8em",
    margin: "16px",
  },
  paperButton: {
    "&:hover": {
      color: "unset",
    },
  },
}))

type NoWorkspaceInvitationCardProps = {
  invitationCount: number
  onClick?: () => void
  selected?: boolean
}

const PaperButton = ({ className, ...props }: PaperProps) => {
  const classes = useStyles()
  return <Paper {...props} className={`${className} ${classes.paperButton}`} component="a" />
}

export const NoWorkspaceInvitationCard = ({ invitationCount, onClick, selected }: NoWorkspaceInvitationCardProps) => {
  const classes = useStyles()
  return (
    <Box
      width="45%"
      height="12rem"
      component={PaperButton}
      p={1}
      display="flex"
      alignItems="center"
      flexDirection="column"
      onClick={onClick}
      className={invitationCount === 0 ? classes.noInvitationCard : selected ? classes.selectedCard : classes.card}
    >
      <RecordVoiceOverOutlinedIcon className={classes.icon} />
      <Typography variant="h5" className={classes.subtitle}>
        Join workspace
      </Typography>
      <Typography variant="body1" className={classes.text}>
        You have <b>{invitationCount}</b> pending {pluralize(invitationCount > 1, "invitation")}
      </Typography>
    </Box>
  )
}

type Props = {
  onCreateOrJoin: (workspaceId: string) => void
}

const NoWorkspaceView = ({ onCreateOrJoin }: Props) => {
  const classes = useStyles()
  const [flow, setFlow] = useState<"create" | "invite" | null>(null)

  const Container = ({ children }: ChildrenProps) => (
    <Box pt={15}>
      <Paper className={classes.container}>
        <Box display="flex" flexDirection="column" padding={3}>
          {children}
        </Box>
      </Paper>
    </Box>
  )

  const goBack = () => setFlow(null)

  const user = api.user.useUser()
  if (user.isLoading || user.data === undefined) {
    return <LoadingSpinner />
  }

  if (flow === "create") {
    return (
      <Container>
        <NoWorkspaceCreateWorkspaceForm onCreateOrJoin={onCreateOrJoin} goBack={goBack} />
      </Container>
    )
  }

  if (flow === "invite") {
    return (
      <Container>
        <NoWorkspaceInvitationForm
          onCreateOrJoin={onCreateOrJoin}
          goBack={goBack}
          invitationCount={user.data.workspaceInvitationCount}
        />
      </Container>
    )
  }

  return (
    <AppLayout user={undefined} noButton>
      <Container>
        <TitleWithDivider title="Create or join a workspace" mb={3} />
        <Box p={5} display="flex" justifyContent="space-between">
          <NoWorkspaceInvitationCard
            invitationCount={user.data.workspaceInvitationCount}
            onClick={user.data.workspaceInvitationCount > 0 ? () => setFlow("invite") : undefined}
          />
          <Box
            width="45%"
            height="12rem"
            component={PaperButton}
            p={1}
            display="flex"
            alignItems="center"
            flexDirection="column"
            onClick={() => setFlow("create")}
            className={classes.card}
          >
            <AddCircleOutlinedIcon className={classes.icon} />
            <Typography variant="h5" className={classes.subtitle}>
              Create workspace
            </Typography>
            <Typography variant="body1" className={classes.text}>
              You can create your own brand new workspace
            </Typography>
          </Box>
        </Box>
      </Container>
    </AppLayout>
  )
}

export default NoWorkspaceView
