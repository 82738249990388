import { Typography } from "@material-ui/core"
import { AccountCreatedViaType } from "pure-shared"
import React from "react"

import { SelectSignUp } from "components/SelectSignInUp"
import { RegistrationStepProps } from "./state"

export const Step0 = (props: RegistrationStepProps<0>) => {
  const { onNext } = props

  const onClick = (createdVia: AccountCreatedViaType) => {
    onNext({ accountCreationVia: createdVia })
  }

  return (
    <>
      <Typography>Please chose a registration method below.</Typography>
      <SelectSignUp onClick={onClick} mb={1} />
    </>
  )
}
