import { Grid, Typography } from "@material-ui/core"
import React from "react"

import { GetContactDto } from "services/api/openapi"
import { pluralize } from "services/utils/textUtils"

interface Props {
  contact: GetContactDto
}

const ContactOptionInAutocomplete = ({ contact }: Props) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Typography variant="body1">{contact.name}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {contact.addressCount === 1 ? (
            <span>{contact.addresses[0]}</span>
          ) : (
            <span>{contact.addressCount} addresses</span>
          )}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {contact.movementCount} {pluralize(contact.movementCount > 0, "movement")}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ContactOptionInAutocomplete
