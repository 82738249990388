import { Box } from "@material-ui/core"
import React from "react"
import { useLocation } from "react-router"

import { DrawerProp } from "components/misc/useDrawer"
import { URLS } from "../../../../routes"
import { GetPublicAssetDto } from "services/api/openapi/models"
import AssetDrawerAdminCategories from "./AssetDrawerAdminCategories"
import AssetSummary from "./AssetSummary"
import BackOfficeView from "../../../../components/misc/BackOfficeView"

const PublicAssetDrawer = (props: DrawerProp<GetPublicAssetDto, false>) => {
  const { pathname } = useLocation()
  const { item: asset, onClose } = props
  const isAdminView = pathname !== URLS.BackOffice.Asset

  return (
    <Box mb={3}>
      <BackOfficeView>
        <AssetDrawerAdminCategories {...props} />
      </BackOfficeView>

      <AssetSummary asset={asset} onClose={onClose} isAdminView={isAdminView} />
    </Box>
  )
}

export default PublicAssetDrawer
