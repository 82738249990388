import React, { useMemo, useState } from "react"
import { useLocation } from "react-router"
import { GetCOAMappingsTypeEnum } from "services/api/openapi"
import { TransactionParams } from "../../components/CoreTransaction"

import ChildrenProps from "../misc/childrenProps"

export type Tabs = GetCOAMappingsTypeEnum

interface ContextValue {
  tab: Tabs
  isNeedReview: boolean
  changeTab: (newTab: Tabs) => void
  isTab: (tabToCheck: Tabs) => boolean
  countFilters: (filterCount: number, filter: TransactionParams) => number
}

export const AccountingFilterContext = React.createContext<ContextValue>(undefined as unknown as ContextValue)

type AllProps = ChildrenProps

export const AccountingFilterContextProvider = (props: AllProps): JSX.Element => {
  const [tab, setTab] = useState<Tabs>("label_mapping")
  const isTab = (tabToCheck: Tabs): boolean => tab === tabToCheck
  const { pathname } = useLocation()
  const isNeedReview = useMemo((): boolean => pathname.includes("needReview"), [pathname])

  const countFilters = (
    filterCount: number,
    // , filter: TransactionParams
  ) => {
    if (isTab("label_mapping") || isTab("wallet_mapping") || isTab("exchange_mapping")) {
      return filterCount - 1
    }
    return filterCount
  }

  return (
    <AccountingFilterContext.Provider
      value={{
        tab,
        changeTab: setTab,
        isTab,
        countFilters,
        isNeedReview,
      }}
    >
      {props.children}
    </AccountingFilterContext.Provider>
  )
}
