import React, { useMemo, useState } from "react"
import { createPortal } from "react-dom"

import TypographyUI from "CryptioUI/Typography"

import { TypographyVariant } from "../types"
import { Options } from "../types/toaster"

import { ToastContext } from "./Context"
import { Toast } from "./Toast"

// Create a random ID
function generateUEID() {
  let first = ((Math.random() * 46656) | 0).toString(36)
  let second = ((Math.random() * 46656) | 0).toString(36)
  first = ("000" + first).slice(-3)
  second = ("000" + second).slice(-3)

  return first + second
}
interface ToastProps {
  id: string
  content: React.ReactNode
  options?: Options
}

export const ToastProvider = ({ children }: { children: JSX.Element }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([])
  const open = (content: React.ReactNode, options?: Options) =>
    setToasts((currentToasts: ToastProps[]) => [...currentToasts, { id: generateUEID(), content, options }])
  const close = (id: string) => setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id))
  const contextValue = useMemo(() => ({ open }), [])

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      {createPortal(
        <React.Fragment>
          {toasts.map(({ id, content, options }, index) => (
            <Toast key={id} close={() => close(id)} options={options} index={index}>
              <TypographyUI variant={TypographyVariant.BODY}>{content}</TypographyUI>
            </Toast>
          ))}
        </React.Fragment>,
        document.body,
      )}
    </ToastContext.Provider>
  )
}
