import React from "react"
import WarningTypography from "components/WarningTypography"
import { Box, Typography, makeStyles, Theme } from "@material-ui/core"
import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { WarningAdminFeature } from "components/Feature/WarningAdminFeature"
import ButtonUI from "CryptioUI/Button"
import { ButtonSize, Mode } from "CryptioUI/types"
import { GetReportTypeDto, ReportTypeFormatDto } from "services/api/openapi"
import TooltipUI from "CryptioUI/Tooltip"

interface FieldFormProps {
  reportType: GetReportTypeDto | null
  reportFormat: ReportTypeFormatDto | null
  setReportFormat: (newReportFormat: ReportTypeFormatDto | null) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  formatContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: theme.spacing(1),
  },
  noMaxWidth: {
    maxWidth: "none",
  },
}))

const FieldForm = (props: FieldFormProps) => {
  const classes = useStyles()
  const { reportType, reportFormat, setReportFormat } = props

  return (
    <>
      {reportType && (
        <Box alignItems="start" mt={3}>
          <Typography variant="body1" style={{ fontWeight: "800" }}>
            {reportType.description}
          </Typography>
        </Box>
      )}
      {reportType && reportType.coming && (
        <WarningTypography alignItems="start" mt={3}>
          This report is still under development. If you need this report soon, please contact the team.
        </WarningTypography>
      )}
      {reportType && reportType.formats.length > 1 && (
        <DrawerFormSection htmlFor="export-format-select" name="CSV format">
          <Box className={classes.formatContainer}>
            {reportType.formats.map((format) => {
              const card = (
                <WarningAdminFeature isAdminFeature={format.isFeatureEnabledOnlyForAdmin} key={format.id}>
                  <ButtonUI
                    mode={format === reportFormat ? Mode.DEFAULT : Mode.CONTAINED}
                    onClick={() => setReportFormat(format)}
                    fullWidth
                    size={ButtonSize.MD}
                  >
                    <Typography variant="body1" style={{ textAlign: "center" }}>
                      {format.displayName}
                    </Typography>
                  </ButtonUI>
                </WarningAdminFeature>
              )

              if (format.previewUrl && format.previewTitle) {
                return (
                  <TooltipUI
                    key={format.id}
                    content={
                      <Box p={0.5}>
                        <Typography variant="h4">{format.previewTitle}</Typography>
                        <Box mt={0.5} />
                        {format.previewSubtitle && <Typography variant="body1">{format.previewSubtitle}</Typography>}
                        <Box mt={1.5} display="grid" justifyItems="center">
                          <img alt={`${format.name} preview`} src={format.previewUrl} width={600} />
                        </Box>
                      </Box>
                    }
                    noMaxWidth
                  >
                    {card}
                  </TooltipUI>
                )
              }
              return card
            })}
          </Box>
        </DrawerFormSection>
      )}
    </>
  )
}

export default FieldForm
