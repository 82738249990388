import { makeStyles, Typography } from "@material-ui/core"
import { Autocomplete, AutocompleteProps, AutocompleteRenderOptionState } from "@material-ui/lab"
import React, { useMemo, useState } from "react"

interface Props<T>
  extends Omit<AutocompleteProps<T, false, false, false>, "inputValue" | "freeSolo" | "onInputChange"> {
  createCustomOption: (value: string) => T
  customOptionLabel: string
}

const useStyles = makeStyles({
  customLabel: {
    fontWeight: "bold",
  },
})

const CustomOptionLabel = ({ message }: { message: string }) => {
  const classes = useStyles()

  return <Typography className={classes.customLabel}>{message}:&nbsp;</Typography>
}

export function AutocompleteCustomOption<T>(props: Props<T>) {
  const { options, createCustomOption, getOptionLabel, renderOption, customOptionLabel, ...rest } = props

  const [value, setValue] = useState<string>("")

  const [optionsWithCustom, custom] = useMemo(() => {
    const results = Array.from(options)
    if (value !== "") {
      const newOption = createCustomOption(value)
      if (options.find((v) => v === newOption) === undefined) {
        results.push(newOption)
        return [results, newOption]
      }
    }
    return [results, null]
  }, [value, options, createCustomOption])

  const renderOptionInner = (option: T, state: AutocompleteRenderOptionState) => {
    const optionRendered = renderOption
      ? renderOption(option, state)
      : getOptionLabel
      ? getOptionLabel(option)
      : `${option}`
    return (
      <>
        {option === custom && <CustomOptionLabel message={customOptionLabel} />}
        {optionRendered}
      </>
    )
  }

  return (
    <Autocomplete
      inputValue={value}
      options={optionsWithCustom}
      getOptionLabel={getOptionLabel}
      renderOption={renderOptionInner}
      onInputChange={(_, newValue) => setValue(newValue)}
      {...rest}
    />
  )
}
