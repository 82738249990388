import { Avatar, Box, Paper, Typography } from "@material-ui/core"
import { ImageOutlined } from "@material-ui/icons"
import { prettyEmptyOrNull } from "pure-shared"
import React, { useContext, useMemo } from "react"
import { IDataTableColumn } from "react-data-table-component"

import AddressTypography from "components/AddressTypography"
import BaseContainer from "components/Container"
import CopyableText from "components/misc/copyableText"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDrawer from "components/misc/useDrawer"
import { GenericSorting } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import TableTitleWithTotalCount from "components/Table/TableTitleWithTotalCount"
import api from "services/api"
import { BasicElement, WithoutWorkspaceId } from "services/api/aliases"
import {
  BackOfficeGetAssetDto,
  BackOfficeGetAssetsRequest,
  GetPublicAssetDto,
  GetPublicAssetDtoTypeEnum,
  GetPublicAssetsRequest,
  GetPublicAssetsSortByEnum,
} from "services/api/openapi"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import BackOfficeAssetFilter from "./filters/BackOfficeAssetFilter"
import download from "services/utils/download"
import { UserContext } from "services/context/userContext"
import { WorkspaceInAutocomplete } from "components/AutoCompleteOptions/WorkspaceOptionInAutocomplete"
import ButtonUI from "CryptioUI/Button"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

type PrettyAssetType = Record<GetPublicAssetDtoTypeEnum, string>

export const prettyAssetTypeMap: PrettyAssetType = {
  fiat: "Fiat",
  /* eslint-disable camelcase */
  token: "Token",
  exchange_unknown: "Exchange unknown",
  main_chain: "Main chain",
}
export const assetTypeArray: GetPublicAssetDtoTypeEnum[] = Object.keys(
  prettyAssetTypeMap,
) as GetPublicAssetDtoTypeEnum[]

const defaultBackOfficeAssetSorting: GenericSorting<GetPublicAssetsSortByEnum> = {
  sortDirection: "ascending",
  sortBy: "market_cap_rank",
}

export type AssetPaginatedParams = Omit<
  WithoutWorkspaceId<GetPublicAssetsRequest & BackOfficeGetAssetsRequest>,
  "queryWorkspaceId"
> & { queryWorkspace: (BasicElement & WorkspaceInAutocomplete) | undefined }
export const defaultBackOfficeGetAssetsParams: AssetPaginatedParams = {
  page: 0,
  limit: 10,
  query: undefined,
  type: undefined,
  address: undefined,
  queryWorkspace: undefined,
}

export const backOfficeAssetHasAnyFilter = (filters: Partial<GetPublicAssetsRequest>) =>
  Boolean(filters.query || filters.type || filters.address || filters.workspaceId)

const BackOfficeAssetScene = (): JSX.Element => {
  const { params, setLimit, setPage, setSorting, setParams } = usePaginatedParams<
    AssetPaginatedParams,
    GetPublicAssetsSortByEnum
  >(defaultBackOfficeGetAssetsParams, defaultBackOfficeAssetSorting)
  const toast = useToast()

  const { user } = useContext(UserContext)
  const isAdmin = user.role !== "normal"
  const [assetDrawer, openAssetDrawer] = useDrawer("asset")

  const useAssets = isAdmin ? api.backOffice.asset.useAssets : api.asset.usePublicAssets

  const assets = useAssets({
    page: params.page,
    limit: params.limit,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
    query: params.query,
    type: params.type,
    queryWorkspaceId: params.queryWorkspace?.id,
    address: params.address,
  })

  const columns = useMemo<IDataTableColumn<GetPublicAssetDto | BackOfficeGetAssetDto>[]>(
    () => [
      {
        name: "Icon",
        selector: "icon",
        cell: function FormatIcon(row) {
          return row.logoUrl ? (
            <Avatar alt={prettyEmptyOrNull(row.symbol)} src={row.logoUrl} />
          ) : (
            <Avatar alt={prettyEmptyOrNull(row.symbol)}>
              <ImageOutlined />
            </Avatar>
          )
        },
      },
      {
        name: "Name",
        selector: "name",
        sortName: "name",
        sortable: true,
      },
      {
        name: "Symbol",
        selector: "symbol",
        sortName: "symbol",
        sortable: true,
      },
      {
        name: "Unique symbol",
        selector: "uniqueSymbol",
        format: function formatUniqueId(row): JSX.Element {
          return <CopyableText allowEvent>{row.uniqueSymbol}</CopyableText>
        },
      },
      {
        name: "Type",
        selector: "type",
        format: (row) => prettyAssetTypeMap[row.type],
      },
      {
        name: "Estimated market rank",
        selector: "marketCapRank",
        sortName: "market_cap_rank",
        sortable: true,
      },
      {
        name: "Token addresses",
        selector: "tokenAddress",
        format: function formatTokenAddress(row): React.ReactNode {
          if (row.blockchainLinks.length === 1) {
            const [link] = row.blockchainLinks
            return (
              <>
                <CopyableText text={link.tokenAddress} allowEvent>
                  <AddressTypography component="span">{link.tokenAddress}</AddressTypography>
                </CopyableText>
                <Typography>{link.blockchain}</Typography>
              </>
            )
          } else if (row.blockchainLinks.length > 1) {
            return `${row.blockchainLinks.length} token addresses`
          } else {
            return "-"
          }
        },
      },
    ],
    [],
  )

  const handleCsvDownload = async () => {
    try {
      const url = await api.asset.getAssetCsvSignedUrl()
      download(url.signedUrl)
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  if (assets.isError) return <NetworkErrorMessage small={false} additionalData={assets} />
  if (assets.isLoading || assets.data === undefined) return <LoadingSpinner />

  return (
    <BaseContainer>
      {assetDrawer}

      <Box mb={2} display="flex" justifyContent="space-between" alignItems="flex-end">
        <TableTitleWithTotalCount title="Assets" qualifier="Asset" totalCount={assets.data.totalCount} />
        <Box display="flex" alignItems="flex-end">
          <Box ml={1} />
          <ButtonUI onClick={handleCsvDownload}>Download assets as CSV</ButtonUI>
          <Box ml={1} />
          <BackOfficeAssetFilter setFilter={setParams} filter={params} isAdmin={isAdmin} />
        </Box>
      </Box>
      <Paper>
        <ServerSideTable<GetPublicAssetDto, GetPublicAssetsSortByEnum>
          columns={columns}
          items={assets.data.data}
          defaultParams={params}
          limit={assets.data.limit}
          totalCount={assets.data.totalCount}
          onPageChange={setPage}
          onLimitChange={setLimit}
          onSort={setSorting}
          onRowClicked={(row) => openAssetDrawer(row.id)}
        />
      </Paper>
    </BaseContainer>
  )
}

export default BackOfficeAssetScene
