import { useContext } from "react"
import { GetReportModuleDto, GetReportTypeDto } from "services/api/openapi"
import { UserContext } from "services/context/userContext"
import { WorkspaceContext } from "services/context/workspaceContext"

export const useJoinFavoriteReports = (data: GetReportModuleDto[] | undefined): GetReportModuleDto[] => {
  const userCtx = useContext(UserContext)
  const isFeatureEnabled = useContext(WorkspaceContext).workspace.features.includes("favorite_report")
  const isAdmin =
    userCtx.user.role === "tech_administrator" ||
    userCtx.user.role === "business_administrator" ||
    userCtx.user.role === "reporter"

  if (data === undefined) return []
  if (!isFeatureEnabled && !isAdmin) {
    return data
  }

  const favoriteReports: Array<GetReportTypeDto> = data.reduce<Array<GetReportTypeDto>>((r, reportModule) => {
    return r.concat(reportModule.types.filter((report) => report.isFavorite))
  }, [])
  return data.concat([
    {
      displayName: "Favorite",
      isFeatureEnabledOnlyForAdmin: !isFeatureEnabled,
      name: "favorite",
      types: favoriteReports,
    },
  ])
}
