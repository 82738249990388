import { Box, Link, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import { BusinessCenterRounded } from "@material-ui/icons"
import React, { useMemo } from "react"
import { Link as RouterLink, useHistory } from "react-router-dom"

import BaseContainer from "components/Container"
import MainTitleView from "components/MainTitleView"
import CopyableText from "components/misc/copyableText"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import DebouncedTextField from "components/selector/DebouncedTextField"
import { GenericSorting, IExtendedDataTableColumn } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import TableTitleWithTotalCount from "components/Table/TableTitleWithTotalCount"
import ButtonUI from "CryptioUI/Button"
import { URLS } from "../../routes"
import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import {
  // GetContactsRequest,
  GetUnknownContactDto,
  GetUnknownContactQuerySortByEnum,
  GetUnknownContactsRequest,
} from "services/api/openapi"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import CreateContact from "./UnidentifiedAddressesCreateContact"
import { useToast } from "CryptioUI/Toaster"

const defaultUnknownContactSorting: GenericSorting<GetUnknownContactQuerySortByEnum> = {
  sortDirection: "descending",
  sortBy: "movement_count",
}

const defaultUnknownContactParams: WithoutWorkspaceId<GetUnknownContactsRequest["getUnknownContactQuery"]> = {
  page: 0,
  limit: 10,
  address: "",
}

const useStyles = makeStyles((theme: Theme) => ({
  noContactText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  searchTextField: {
    backgroundColor: "white",
    width: "28rem",
  },
  createReport: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

// const contactHasAnyFilter = (filters: Partial<GetContactsRequest>) => Boolean(filters.query?.length)

const UnidentifiedAddressesScene = (): JSX.Element => {
  const classes = useStyles()

  const { params, setLimit, setPage, setSorting, setParams } = usePaginatedParams<
    Partial<GetUnknownContactsRequest["getUnknownContactQuery"]>,
    GetUnknownContactQuerySortByEnum
  >(defaultUnknownContactParams, defaultUnknownContactSorting)
  const addresses = api.contact.useUnknownContacts({
    page: params.page,
    limit: params.limit,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
    query: params.address,
  })
  const { mutateAsync: createReportMutation } = api.report.useCreateReports()
  const reportModules = api.report.useReportTypes()
  const history = useHistory()
  const toast = useToast()

  const createAddressesReport = async () => {
    const reportModule = reportModules.data?.find((m) => m.name === "accounting")
    if (!reportModule) return
    const unidentifiedType = reportModule.types?.find((t) => t.name === "unidentified_addresses")
    if (!unidentifiedType || unidentifiedType.formats.length < 1) return

    const [unidentifiedFormat] = unidentifiedType.formats
    await createReportMutation({
      createReportDto: {
        reportModule: reportModule.name,
        exportFormatId: unidentifiedFormat.id,
        startDate: null,
        endDate: null,
        assetIds: [],
        walletIds: [],
        allColumns: true,
        fileType: "csv",
        impairmentId: null,
        includeNft: true,
        inputDate: null,
      },
    })
    toast.open("Unidentified addresses report created", { variant: "success" })
    history.push(URLS.Reports.basePath)
  }

  const columns = useMemo<IExtendedDataTableColumn<GetUnknownContactDto, GetUnknownContactQuerySortByEnum>[]>(
    () => [
      {
        name: "Name",
        selector: "name",
        cell: CreateContact,
      },
      {
        name: "Address",
        selector: "address",
        format: function formatContactAddresses(row) {
          return <CopyableText allowEvent>{row.address}</CopyableText>
        },
      },
      {
        name: "Movements",
        selector: "movementCount",
        sortable: true,
        sortName: "movement_count",
        format: function formatContactAddresses(row) {
          if (row.movementCount === 0) return "0"
          return (
            <Link
              component={RouterLink}
              to={URLS.Transactions.getUrl({
                filters: [{ type: "addresses", value: [row.address], isNot: false }],
              })}
              underline="always"
            >
              {row.movementCount}
            </Link>
          )
        },
        width: "150px",
      },
    ],
    [],
  )

  if (reportModules.isError) return <NetworkErrorMessage small={false} additionalData={reportModules} />
  if (reportModules.isLoading || reportModules.data === undefined) return <LoadingSpinner />

  if (addresses.isError) return <NetworkErrorMessage small={false} additionalData={addresses} />
  if (addresses.isLoading || addresses.data === undefined) return <LoadingSpinner />

  const hasAnyFilter = !!params.address

  if (addresses.data.totalCount === 0 && params.page > 1) {
    // I'm doing a set a setTimeout to avoid a warning to be triggered
    setTimeout(() => setPage(1), 0)
    return <LoadingSpinner />
  }

  const Header = () => {
    return (
      <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {hasAnyFilter ? (
          <>
            <Typography className={classes.noContactText} variant="h4">
              There are no unidentified addresses that match the filter.
              <br />
              Edit or clear the text field.
            </Typography>
            <Box display="flex" flexDirection="row"></Box>
          </>
        ) : (
          <>
            <BusinessCenterRounded fontSize="large" />
            <Typography className={classes.noContactText} variant="h4">
              All your addresses are identified.
            </Typography>
          </>
        )}
      </Box>
    )
  }

  const viewTitle = "Unidentified addresses"
  const viewDescription =
    "Unidentified addresses are addresses present in the transaction history which are not associated to a contact."
  const viewHelpURL =
    "https://support.cryptio.co/hc/en-gb/articles/7037996189969-Identifying-managing-and-creating-Contacts"

  const Tools = () => (
    <>
      <div className="flex flex-col items-end">
        <ButtonUI onClick={createAddressesReport}>Generate report</ButtonUI>
        <div className="mt-4">
          <DebouncedTextField
            autoFocus
            placeholder="Search an address"
            value={params.address}
            setValue={(q) => setParams({ address: q })}
            className={classes.searchTextField}
          />
        </div>
      </div>
    </>
  )

  return (
    <BaseContainer>
      <div className="flex mb-4 justify-between items-start">
        {addresses.data.totalCount > 0 ? (
          <>
            <TableTitleWithTotalCount
              title={viewTitle}
              qualifier="unidentified address"
              qualifierSuffix="es"
              totalCount={addresses.data.totalCount}
              helpTooltipContent={viewDescription}
              helpTooltipUrl={viewHelpURL}
            />
            <Tools />
          </>
        ) : (
          <>
            <MainTitleView title={viewTitle} helpTooltipContent={viewDescription} helpTooltipUrl={viewHelpURL} />
            {hasAnyFilter && <Tools />}
          </>
        )}
      </div>

      {addresses.data.totalCount === 0 ? (
        <Header />
      ) : (
        <Paper>
          <ServerSideTable<GetUnknownContactDto, GetUnknownContactQuerySortByEnum>
            columns={columns}
            items={addresses.data.data}
            limit={addresses.data.limit}
            totalCount={addresses.data.totalCount}
            defaultParams={params}
            onPageChange={setPage}
            onLimitChange={setLimit}
            onSort={setSorting}
            paginationRowsPerPageOptions={[5, 10, 20, 30]}
          />
        </Paper>
      )}
    </BaseContainer>
  )
}

export default UnidentifiedAddressesScene
