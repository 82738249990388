import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import React from "react"
import { useLocation } from "react-router-dom"

import { URLS } from "../../routes"
import { LinkIcon } from "./LinkIcons"

// TODO Refactor the profile menu
// TODO Refactor the workspace switcher

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      height: "62px",
    },
  }),
)

interface PublicLayoutParams {
  noButton: boolean
}

const PublicLayout = ({ noButton }: PublicLayoutParams): JSX.Element => {
  const classes = useStyles()
  const { search } = useLocation()

  return (
    <>
      <div className={classes.grow} />
      <div className={classes.sectionDesktop}>
        {!noButton && (
          <>
            <LinkIcon url={`${URLS.Account.Login}${search}`} title="Login" />
            <LinkIcon url={`${URLS.Account.Register}${search}`} title="Register" />
          </>
        )}
      </div>
    </>
  )
}

export default PublicLayout
