import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React, { useState } from "react"
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons"
import { GenericSorting, SortDirectionEnum } from "components/Table/interface"
import TypographyUI from "CryptioUI/Typography"
import { BoldWeight, TypographyVariant } from "CryptioUI/types"

interface HeaderProps {
  columnName: string
  setSorting: (sorting: GenericSorting<"transaction_date">) => void
  sortDirection: SortDirectionEnum
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    maxHeight: "42px",
    minHeight: "42px",
    height: "42px",
    width: "100%",
    backgroundColor: "#F2F4F8",
  },
  headerText: {
    fontWeight: "bold",
  },
  containerArrow: {
    "display": "flex",
    "justifyContent": "center",
    "alignItems": "center",
    "marginLeft": theme.spacing(0.3),
    "marginBottom": theme.spacing(0.2),
    "&:hover": {
      cursor: "pointer",
    },
  },
}))

const Header = (props: HeaderProps) => {
  const { columnName, setSorting, sortDirection } = props
  const classes = useStyles()
  const [isDescending, setIsDescending] = useState(sortDirection === "descending" ? true : false)

  const onClick = () => {
    setIsDescending(!isDescending)
    setSorting({
      sortBy: "transaction_date",
      sortDirection: isDescending ? "ascending" : "descending",
    })
  }

  return (
    <div className={classes.container}>
      <TypographyUI className="uppercase text-grey-400" variant={TypographyVariant.CAPTION} boldness={BoldWeight.BOLD}>
        {columnName}
      </TypographyUI>
      {columnName === "Date" && (
        <div className={classes.containerArrow} onClick={onClick}>
          {isDescending ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </div>
      )}
    </div>
  )
}

export default Header
