import { Box, Card, createStyles, makeStyles, Typography } from "@material-ui/core"
import React, { useContext } from "react"
import { ReactComponent as Delete } from "CryptioUI/assets/icons/delete.svg"
import dayjs from "dayjs"
import { GetTransactionFilterDto, TransactionFilterEndDate, TransactionFilterStartDate } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { FilterType, FilterTypeWithoutDateState } from ".."
import { AlternateBackgroundColor } from "../../../../../materialTheme"
import { PlainDate } from "../../../../DateRangePicker"
import { filterTypeFilter } from ".."

const useStyles = makeStyles(() =>
  createStyles({
    deleteIcon: {
      "&:hover": {
        cursor: "pointer",
      },
      "height": "1.1em",
      "width": "1.1em",
    },
    containerCard: {
      "display": "flex",
      "justifyContent": "center",
      "alignItems": "center",
      "width": "100%",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: AlternateBackgroundColor,
      },
    },
    subtitle: {
      weight: "bold",
    },
  }),
)

export interface FavoriteCardProps {
  deleteTransactionFilter: (savedFilter: GetTransactionFilterDto, actionAfterConfirm?: () => void) => void
  filter: GetTransactionFilterDto
  setValue: (value: number) => void
  setEndDateTemp: (value: PlainDate | undefined) => void
  setStartDateTemp: (value: PlainDate | undefined) => void
  setTempFilterWithoutDate: (value: FilterTypeWithoutDateState[]) => void
  setFilterLoaded: (filterLoaded: GetTransactionFilterDto | undefined) => void
}

const FavoriteCard = (props: FavoriteCardProps): JSX.Element => {
  const {
    deleteTransactionFilter,
    filter,
    setValue,
    setStartDateTemp,
    setEndDateTemp,
    setTempFilterWithoutDate,
    setFilterLoaded,
  } = props
  const classes = useStyles()
  const { workspace } = useContext(WorkspaceContext)

  const loadFavoriteFilter = (savedFilter: GetTransactionFilterDto) => {
    const filtersFiltered = savedFilter.filters.map((filter) => filter)

    const dateFilterNames: FilterType[] = ["start_date", "end_date"]
    const tempFilterWithoutDate = filtersFiltered.filter((filter) => !dateFilterNames.includes(filter.type))
    const startDateFilter = filtersFiltered.find(filterTypeFilter<TransactionFilterStartDate>("start_date"))
    const endDateFilter = filtersFiltered.find(filterTypeFilter<TransactionFilterEndDate>("end_date"))

    setTempFilterWithoutDate(tempFilterWithoutDate as FilterTypeWithoutDateState[])
    setFilterLoaded(savedFilter)
    setValue(0)

    setStartDateTemp(startDateFilter ? PlainDate.fromBackendDate(startDateFilter.value) : undefined)
    setEndDateTemp(endDateFilter ? PlainDate.fromBackendDate(endDateFilter.value) : undefined)
  }

  return (
    <Box mb={2} justifyContent="center" alignItems="center" width="100%" display="flex" flexDirection="row">
      <Card className={classes.containerCard} onClick={() => loadFavoriteFilter(filter)}>
        <Box m={2} justifyContent="center" width="100%" alignItems="flex-start" display="flex" flexDirection="column">
          <Typography variant={"h4"} className={classes.subtitle}>
            {filter.name}
          </Typography>
          <Typography variant={"body1"}>
            {`Created on ${dayjs(filter.createdAt).tz(workspace.timezone).format("LLL")}`}
          </Typography>
        </Box>
      </Card>
      <Box pl={2}>
        <div onClick={() => deleteTransactionFilter(filter)}>
          <Delete className={classes.deleteIcon} />
        </div>
      </Box>
    </Box>
  )
}

export default FavoriteCard
