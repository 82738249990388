import { Typography } from "@material-ui/core"
import React from "react"

const DialogDataConfirmQuit = (onClose: () => void) => ({
  title: "Are you sure?",
  content: (
    <Typography variant="h5">Are you sure you want to quit without saving? All changes will be lost.</Typography>
  ),
  yesText: "Yes",
  noText: "Cancel",
  onAccept: onClose,
})

export default DialogDataConfirmQuit
