import JsonCrush from "jsoncrush"

import { GenericSorting } from "components/Table/interface"

export const paramUrlName = "params"

export const formatUrl = <T>(pathName: string, params?: T, defaultParams?: T): string => {
  let nonDefaultParams = {}

  // get all fields that are different from the default value
  if (params && defaultParams) {
    Object.keys(params).forEach((k) => {
      // @ts-ignore
      if (JSON.stringify(params[k]) !== JSON.stringify(defaultParams[k])) {
        // @ts-ignore
        nonDefaultParams[k] = params[k]
      }
    })
  } else if (params) nonDefaultParams = params

  const stringifiedParams = JSON.stringify(nonDefaultParams)

  // Empty object
  if (stringifiedParams === "{}") {
    return pathName
  }

  // Let's encode the "&" to avoid front crash
  return `${pathName}?${paramUrlName}=${encodeURIComponent(JsonCrush.crush(stringifiedParams))}`
}

export const parametrizableRoute = <T>(pathName: string, defaultParams?: T): ParametrizableRouteReturnType<T> => ({
  basePath: pathName,
  getUrl: (params) => formatUrl(pathName, params, defaultParams),
})

export interface PaginationParameters {
  page: number
  limit: number
}

export type BasicParameters<TSortEnum> = PaginationParameters & GenericSorting<TSortEnum>

export interface PaginatedParamsReturnType<TSortEnum, TParam> {
  params: BasicParameters<TSortEnum> & TParam
  setLimit: (limit: number) => void
  setPage: (page: number) => void
  setSorting: (sorting: GenericSorting<TSortEnum>) => void
  setParams: (newParams: TParam) => void
}

export const parametrizableTableRoute = <TParam, TSortEnum>(
  pathName: string,
  defaultParams: TParam,
  defaultSorting: GenericSorting<TSortEnum>,
) =>
  parametrizableRoute<TParam & GenericSorting<TSortEnum>>(pathName, {
    ...defaultParams,
    ...defaultSorting,
  })

interface ParametrizableRouteReturnType<T> {
  // used only for react router
  basePath: string

  // used to build url
  getUrl: (params?: Partial<T>) => string
}
