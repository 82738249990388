import React from "react"
import { twMerge } from "tailwind-merge"
import { css } from "@emotion/css"

import { ReactComponent as CloseStroke } from "CryptioUI/assets/icons/close-stroke.svg"
import { ToastProps } from "CryptioUI/types"

import { useTimeout } from "./useTimeout"
import Loader from "./Loader"

const variantStyle = {
  default: "bg-grey-500",
  success: "bg-primary-300",
  danger: "bg-danger-300",
  warning: "bg-warning-400",
}

// eslint-disable-next-line complexity
export function Toast({ close, children, options, index }: ToastProps) {
  const position = {
    horizontal: options?.position?.horizontal ?? "left",
    vertical: options?.position?.vertical ?? "bottom",
  }
  const variant = options?.variant ?? "default"
  const duration = options?.loading || options?.noAutohide ? undefined : options?.duration ?? 5000
  const commonStyle =
    "flex flex-col z-[999999] absolute overflow-hidden min-w-[350px] w-fit-content h-[45px] rounded-lg text-white"
  const horizontalPosition =
    position.horizontal === "center"
      ? css`
          left: 50%;
          transform: translateX(-50%);
        `
      : css`
          ${position.horizontal}: 16px;
        `
  const withLoaderStyle = "justify-center child:pb-0"
  const animation = css`
    animation: fadeIn 0.5s ${duration ? `, fadeIn 0.2s linear ${duration ? duration - 200 : 0}ms 1 reverse` : ""};
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `
  useTimeout(close, duration)
  React.useEffect(() => {
    if (options?.loading === false) {
      close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.loading])

  return (
    <div
      className={twMerge("toast", commonStyle, horizontalPosition, variantStyle[variant], withLoaderStyle, animation)}
      style={{ [position.vertical]: `${index === 0 ? 10 : index * 50 + 10}px` }}
    >
      <div className="toast__content flex justify-between items-center w-full h-[21px] flex-row px-3 pb-2">
        <div className="flex justify-start items-center" role="alert">
          {options?.loading ? <Loader className="mr-2" /> : null}
          {children}
        </div>
        {options?.actionButton ? (
          <>{options.actionButton}</>
        ) : (
          <button onClick={close} className="toast__close-btn ml-3">
            <CloseStroke width={12} color="white" />
          </button>
        )}
      </div>
      {options?.loading || options?.noAutohide ? null : (
        <div
          className={css`
            position: relative;
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 4px;
            background: white;
            opacity: 0.5;
            animation: timer ${duration}ms linear 0s 1;
            @keyframes timer {
              0% {
                width: 100%;
              }
              100% {
                width: 0;
              }
            }
          `}
        />
      )}
    </div>
  )
}
