import { Box, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

import { DrawerCategory } from "components/Drawer/DrawerItems"
import { DrawerProp } from "components/misc/useDrawer"
import { limitTypes } from "components/Stripe/constants"
import { URLS } from "../../../../routes"
import { BackOfficeGetFullPlanDto } from "services/api/openapi/models"
import BackOfficeBillingSummary from "./BackOfficeBillingSummary"

const BackOfficeBillingDrawer = (props: DrawerProp<BackOfficeGetFullPlanDto, false>) => {
  const { item: plan } = props

  return (
    <Box mb={3}>
      <BackOfficeBillingSummary plan={plan} />

      {plan.hardLimits.length > 0 && (
        <DrawerCategory title="Plan limits">
          <Box mt={2} maxWidth="700px">
            <TableContainer component={Paper}>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Limit</TableCell>
                    <TableCell align="right">Maximum</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {limitTypes.map((limitType, idx) => {
                    const hardLimit = plan.hardLimits.find((x) => x.limitType === limitType.type)
                    if (!hardLimit) return null
                    return (
                      <TableRow key={idx}>
                        <TableCell>{limitType.name}</TableCell>
                        <TableCell align="right">{hardLimit.maximum}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DrawerCategory>
      )}

      <DrawerCategory title="View workspaces using this plan">
        <Box mt={3} />
        <Link component={RouterLink} to={URLS.BackOffice.Workspace.getUrl({ plan })}>
          Click to filter
        </Link>
      </DrawerCategory>
    </Box>
  )
}

export default BackOfficeBillingDrawer
