import React from "react"
import { DrawerCategory, DrawerSection } from "../../Drawer/DrawerItems"
import { Typography } from "@material-ui/core"
import api from "../../../services/api"
import LoadingSpinner from "../../misc/LoadingSpinner"
import WarningTypography from "../../WarningTypography"
import ReactJson from "react-json-view"
import copy from "copy-to-clipboard"
import CopyableText from "../../misc/copyableText"

interface TransactionMetadataProps {
  transactionId: string
}

const TransactionMetadata = (props: TransactionMetadataProps): JSX.Element => {
  const metadata = api.backOffice.wallet.useTransactionMetadata(props.transactionId)

  if (metadata.isLoading || !metadata.data) {
    return (
      <DrawerCategory title="Tech administrator information">
        <LoadingSpinner />
      </DrawerCategory>
    )
  }

  if (metadata.isError) {
    return (
      <DrawerCategory title="Tech administrator information">
        <WarningTypography>Failed to load tech administrator information</WarningTypography>
      </DrawerCategory>
    )
  }

  return (
    <DrawerCategory title="Tech administrator information">
      <DrawerSection name="Metadata">
        <ReactJson
          displayDataTypes={false}
          displayObjectSize={false}
          name={"transaction"}
          src={metadata.data.movements.map((mvt) => mvt.metadata)}
          enableClipboard={(props) => copy(JSON.stringify(props.src, null, 2))}
          collapsed={true}
        />
      </DrawerSection>
      {metadata.data.movements.length > 0 && (
        <DrawerSection name="Created at">
          <Typography variant="body2" color="textSecondary">
            <CopyableText>{metadata.data.movements[0].createdAt}</CopyableText>
          </Typography>
        </DrawerSection>
      )}
    </DrawerCategory>
  )
}

export default TransactionMetadata
