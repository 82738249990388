import { Fade } from "@material-ui/core"
import { ComputedDatum, ResponsivePie } from "@nivo/pie"
import BigNumber from "bignumber.js"
import numbro from "numbro"
import { prettyEmptyOrNull } from "pure-shared"
import React, { useContext, useEffect, useState } from "react"

import LoadingSpinner from "components/misc/LoadingSpinner"
import api from "services/api"
import { GlobalBalanceDto, OverviewBalanceDto } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { getSymbol } from "services/utils/AssetSymbols"
import { prettyNumber } from "services/utils/processNumber"
import noDuplicateAssets from "./noDuplicateAssets"
import { cropName } from "services/utils/textUtils"
import { TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"

interface Props {
  assets: OverviewBalanceDto[]
}

interface PieProps extends Props {
  assets: OverviewBalanceDto[]
  symbol: string
  balance: GlobalBalanceDto | undefined
}

interface AssetBalance {
  value: BigNumber
  id: string | null
  label: string
}

const WidgetPie = ({ assets, symbol, balance }: PieProps) => {
  const [newAssets, setNewAssets] = useState<OverviewBalanceDto[]>(assets)

  useEffect(() => {
    setNewAssets(noDuplicateAssets(assets))
  }, [assets])

  if (balance === undefined) return <LoadingSpinner />

  const topAssetsBalances = new Array<AssetBalance>()
  for (const asset of newAssets) {
    if (asset.amountInUsd && asset.usdRateToDefaultCurrency) {
      topAssetsBalances.push({
        value: new BigNumber(asset.amountInUsd).multipliedBy(asset.usdRateToDefaultCurrency),
        id: asset.assetName,
        label: prettyEmptyOrNull(cropName(asset.assetSymbol, 8)),
      })
    }
  }

  if (balance.amountInUsd && balance.usdRateToDefaultCurrency) {
    const remainingAmount = topAssetsBalances.reduce(
      (remainingAmount, topAssetBalance) => remainingAmount.minus(topAssetBalance.value),
      new BigNumber(balance.amountInUsd).multipliedBy(balance.usdRateToDefaultCurrency),
    )
    remainingAmount.gt(0) &&
      topAssetsBalances.push({
        value: remainingAmount,
        label: "Others",
        id: "Others",
      })
  }

  const CenteredMetric = ({
    dataWithArc,
    centerX,
    centerY,
  }: {
    dataWithArc: ComputedDatum<AssetBalance>[]
    centerX: number
    centerY: number
  }) => {
    const total = dataWithArc
      .reduce((total, datum) => total.plus(datum.data.value), new BigNumber(0))
      .decimalPlaces(2)
      .toNumber()

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "26",
        }}
      >
        {!total ? "No valuation" : `${symbol} ${numbro(total).format(prettyNumber(total))}`}
      </text>
    )
  }

  return (
    <ResponsivePie
      theme={{
        legends: { hidden: { symbol: { fill: "#d9d9d9" } } },
      }}
      data={topAssetsBalances}
      margin={{ top: 40, right: 80, bottom: 40, left: 40 }}
      colors={{ scheme: "spectral" }}
      innerRadius={0.6}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      enableArcLinkLabels={false}
      enableArcLabels={false}
      layers={["arcs", "legends", CenteredMetric]}
      value={(topBalance) => topBalance.value.decimalPlaces(2).toNumber()}
      valueFormat={(total) => {
        return `${symbol} ${numbro(total).format(prettyNumber(total))}`
      }}
      legends={[
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 50,
          translateY: 0,
          itemsSpacing: 13,
          itemWidth: 100,
          itemHeight: 18,
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          toggleSerie: true,
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#999",
              },
            },
          ],
        },
      ]}
    />
  )
}

const DashboardPie = ({ assets }: Props): JSX.Element => {
  const { workspace } = useContext(WorkspaceContext)
  const userFiatSymbol = getSymbol(workspace.defaultCurrencySymbol)
  const balance = api.balance.useGlobalBalance()

  if (!assets.length) {
    return (
      <div className="flex w-full justify-center items-center p-6">
        <TypographyUI variant={TypographyVariant.BODY}>Missing valuation.</TypographyUI>
      </div>
    )
  }

  return (
    <Fade in={true} timeout={500}>
      <div className="h-[400px] w-full pb-8">
        <WidgetPie balance={balance.data} symbol={userFiatSymbol || ""} assets={assets} />
      </div>
    </Fade>
  )
}
export default DashboardPie
