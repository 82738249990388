import { UseQueryOptions } from "react-query"

import { queryClient } from "../../.."
import {
  usePaginatedWorkspaceQuery,
  useWorkspaceMutation,
  useWorkspaceQuery,
  WorkspaceContextValue,
} from "../../context/workspaceContext"
import { WithoutWorkspaceId } from "../aliases"
import {
  GetBulkSignedUrlRequest,
  GetReportSignedUrlDto,
  GetReportBulkSignedUrlDto,
  GetReportDto,
  GetReportsRequest,
  PaginatedReportResultDto,
  ReportsApi,
  GetFavoriteReportFiltersRequest,
  PaginatedFavoriteReportFilterResultDto,
} from "../openapi"

export const GET_REPORTS_KEY = "getReports"
export const GET_REPORT_KEY = "getReport"
export const GET_REPORT_TYPES_KEY = "getReportTypes"
export const GET_CAN_SYNCHRONIZE_USING_LEDGER_ENTRY = "canMarkLegderEntriesAsSynchronized"
export const GET_FAVORITE_REPORT_FILTERS_KEY = "getFavoriteReportFilters"

export const reportActions = {
  useReports: (
    variables: WithoutWorkspaceId<GetReportsRequest>,
    config?: UseQueryOptions<PaginatedReportResultDto>,
  ) => {
    return usePaginatedWorkspaceQuery([GET_REPORTS_KEY, variables], ReportsApi.getReports, variables, config)
  },
  useUpdateHiddenColumns: () => {
    return useWorkspaceMutation(ReportsApi.updateExportFormatHiddenColumns, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_REPORT_TYPES_KEY)
      },
    })
  },
  useReportTypes: () => {
    return useWorkspaceQuery([GET_REPORT_TYPES_KEY], ReportsApi.getReportTypes, {}, {})
  },
  useCreateReports: () => {
    return useWorkspaceMutation(ReportsApi.createReport, {
      onSuccess: () => {
        // More reports
        queryClient.invalidateQueries(GET_REPORTS_KEY)
      },
    })
  },
  useDeleteReport: () => {
    return useWorkspaceMutation(ReportsApi.deleteReport, {
      onSuccess: () => {
        // Less reports
        queryClient.invalidateQueries(GET_REPORTS_KEY)
      },
    })
  },
  useDeleteBulkReports: () => {
    return useWorkspaceMutation(ReportsApi.deleteBulkReports, {
      onSuccess: () => {
        // Less reports
        queryClient.invalidateQueries(GET_REPORTS_KEY)
      },
    })
  },
  getSignedUrl: (workspaceCtx: WorkspaceContextValue, reportId: string): Promise<GetReportSignedUrlDto> => {
    return ReportsApi.getDownloadSignedUrl({ workspaceId: workspaceCtx.workspace.id, reportId })
  },
  getBulkSignedUrl: (params: GetBulkSignedUrlRequest): Promise<GetReportBulkSignedUrlDto> => {
    return ReportsApi.getBulkSignedUrl(params)
  },
  useReport: (reportId: string | undefined | null, option?: UseQueryOptions<GetReportDto>) => {
    return useWorkspaceQuery(
      [GET_REPORT_KEY, reportId],
      ReportsApi.getReport,
      { reportId: reportId ?? "" },
      {
        enabled: !!reportId,
        ...option,
      },
    )
  },
  useMarkLegderEntriesAsSynchronized: () => {
    return useWorkspaceMutation(ReportsApi.markLedgerEntriesAsSynchronized)
  },
  useCanMarkLegderEntriesAsSynchronized: () => {
    return useWorkspaceQuery(
      [GET_CAN_SYNCHRONIZE_USING_LEDGER_ENTRY],
      ReportsApi.canMarkLedgerEntriesAsSynchronized,
      {},
    )
  },
  useAddFavoriteReport: () => {
    return useWorkspaceMutation(ReportsApi.addFavoriteReport, {
      onSuccess: () => {
        // More favorite reports
        queryClient.invalidateQueries(GET_REPORT_TYPES_KEY)
      },
    })
  },
  useDeleteFavoriteReport: () => {
    return useWorkspaceMutation(ReportsApi.deleteFavoriteReport, {
      onSuccess: () => {
        // Less favorite reports
        queryClient.invalidateQueries(GET_REPORT_TYPES_KEY)
      },
    })
  },
  useFavoriteReportFilters: (
    variables: WithoutWorkspaceId<GetFavoriteReportFiltersRequest>,
    config?: UseQueryOptions<PaginatedFavoriteReportFilterResultDto>,
  ) => {
    return usePaginatedWorkspaceQuery(
      [GET_FAVORITE_REPORT_FILTERS_KEY, variables],
      ReportsApi.getFavoriteReportFilters,
      variables,
      config,
    )
  },
  useAddFavoriteReportFilter: () => {
    return useWorkspaceMutation(ReportsApi.addFavoriteReportFilter, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_FAVORITE_REPORT_FILTERS_KEY)
      },
    })
  },
  useDeleteFavoriteReportFilter: () => {
    return useWorkspaceMutation(ReportsApi.deleteFavoriteReportFilter, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_FAVORITE_REPORT_FILTERS_KEY)
      },
    })
  },
  useEditFavoriteReportFilter: () => {
    return useWorkspaceMutation(ReportsApi.editFavoriteReportFilter, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_FAVORITE_REPORT_FILTERS_KEY)
      },
    })
  },
}
