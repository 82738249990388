import { Typography } from "@material-ui/core"
import React from "react"

import useDialog from "components/misc/useDialog"

export function showConfirmation(dialog: ReturnType<typeof useDialog>, onAccept: () => void) {
  dialog.showDialog({
    title: "Are you sure?",
    content: (
      <Typography style={{ fontWeight: "bold" }}>
        Your input seems to be incorrect. The integration will probably fail. Are you sure you want to continue?
      </Typography>
    ),
    yesText: "Yes",
    noText: "No",
    onAccept,
  })
}
