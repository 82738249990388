import { Box, Typography } from "@material-ui/core"
import dayjs from "dayjs"
import { isInteger } from "pure-shared"
import React, { useState } from "react"
import { IDataTableColumn } from "react-data-table-component"

import CopyableText from "components/misc/copyableText"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDrawer from "components/misc/useDrawer"
import { GenericSorting } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import api from "services/api"
import { BackOfficeGetMinimalWorkspaceDto, BackOfficeGetWorkspacesSortByEnum } from "services/api/openapi"
import LimitationTable from "./LimitationTable"

const columns: IDataTableColumn<BackOfficeGetMinimalWorkspaceDto>[] = [
  {
    name: "Days left",
    selector: "subscriptionPeriodTo",
    format: (row) => dayjs(row.subscriptionPeriodTo).fromNow(true),
  },
  {
    name: "Workspace name",
    selector: "name",
  },
  {
    name: "Workspace ID",
    selector: "id",
    format: function format(row) {
      return <CopyableText>{row.id}</CopyableText>
    },
  },
]

const LimitationFreeTrialTable = (): JSX.Element => {
  const [workspaceDrawer, openWorkspaceDrawer] = useDrawer("backoffice-workspace")
  const [maxRemainingUsage, setMaxRemainingUsage] = useState("2")

  const [page, setPage] = useState(1)
  const [sorting, setSorting] = useState<GenericSorting<BackOfficeGetWorkspacesSortByEnum>>({
    sortBy: "created_at",
    sortDirection: "descending",
  })

  const isQueryValid = isInteger(maxRemainingUsage)

  const workspaces = api.backOffice.workspace.useWorkspaces(
    {
      page,
      limit: 5,
      subscriptionType: "trial",
      freeTrialMaxDaysRemaining: Number(maxRemainingUsage),
    },
    { enabled: isQueryValid },
  )

  if (workspaces.isError) {
    return (
      <LimitationTable title="Free trial expiring">
        <NetworkErrorMessage additionalData={workspaces} />
      </LimitationTable>
    )
  }

  if (workspaces.isLoading || workspaces.data === undefined) {
    return (
      <LimitationTable title="Free trial expiring">
        <LoadingSpinner />
      </LimitationTable>
    )
  }

  if (!isQueryValid) {
    return (
      <LimitationTable
        title="Free trial expiring"
        filterName="days"
        maxRemainingUsage={maxRemainingUsage}
        setMaxRemainingUsage={setMaxRemainingUsage}
      >
        <Box display="flex" alignItems="center" justifyContent="center" p={3}>
          <Typography>Invalid query</Typography>
        </Box>
      </LimitationTable>
    )
  }

  return (
    <LimitationTable
      title="Free trial expiring"
      filterName="days"
      maxRemainingUsage={maxRemainingUsage}
      setMaxRemainingUsage={setMaxRemainingUsage}
    >
      {workspaceDrawer}
      <ServerSideTable<BackOfficeGetMinimalWorkspaceDto, BackOfficeGetWorkspacesSortByEnum>
        columns={columns}
        items={workspaces.data.data}
        limit={5}
        paginationRowsPerPageOptions={[]}
        totalCount={workspaces.data.totalCount}
        defaultParams={{
          ...sorting,
          page,
        }}
        onPageChange={setPage}
        onSort={setSorting}
        onRowClicked={(row) => openWorkspaceDrawer(row.id)}
        dense
      />
    </LimitationTable>
  )
}

export default LimitationFreeTrialTable
