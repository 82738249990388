import { Box, Container, makeStyles, Theme } from "@material-ui/core"
import React from "react"

import MainTitleView from "components/MainTitleView"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import api from "services/api"
// import DeleteUserAccount from "./DeleteAccount"
import ResetPassword from "./ResetPassword"
import TwoFactorAuthSettings from "./TwoFactorAuth"
import BackOfficeView from "../../components/misc/BackOfficeView"
import AdminFeatures from "./AdminFeatures"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    paddingRight: theme.spacing(30),
    paddingLeft: theme.spacing(30),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    height: "100%",
  },
}))

const SettingsScene = (): JSX.Element => {
  const classes = useStyles()

  const user = api.user.useUser()

  if (user.isError) return <NetworkErrorMessage small={false} additionalData={user} />
  if (user.isLoading || user.data === undefined) return <LoadingSpinner />

  return (
    <Container maxWidth="xl" className={classes.root}>
      <MainTitleView title="Settings" />
      <Box mt={3}>
        {user.data.accountCreatedVia === "cryptio" && (
          <>
            <TwoFactorAuthSettings user={user.data} />
            <ResetPassword user={user.data} />

            <BackOfficeView persistent>
              <AdminFeatures />
            </BackOfficeView>
          </>
        )}
        {/* We won't allow users to delete their accounts, so let's hide this part */}
        {/* <DeleteUserAccount user={user.data} /> */}
      </Box>
    </Container>
  )
}

export default SettingsScene
