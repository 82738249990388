import { Typography } from "@material-ui/core"
import BigNumber from "bignumber.js"
import dayjs from "dayjs"
import React from "react"

import api from "services/api"
import LoadingSpinner from "../misc/LoadingSpinner"
import NetworkErrorMessage from "../misc/NetworkErrorMessage"

const UpcomingInvoice = (): JSX.Element => {
  const invoice = api.billing.useRetrieveUpcomingInvoiceData({
    refetchInterval: 10000,
  })

  if (invoice.isError) return <NetworkErrorMessage additionalData={invoice} />

  if (invoice.isLoading || invoice.data === undefined) return <LoadingSpinner />

  return (
    <>
      <Typography variant="body2" gutterBottom>
        Your next payment of ${new BigNumber(invoice.data.total / 100).toFixed(2)} will be automatically debited
        on&nbsp;
        {dayjs(invoice.data.nextPaymentAttempt).tz().format("LLL")}.
      </Typography>
    </>
  )
}

export default UpcomingInvoice
