import { useTypedController } from "@hookform/strictly-typed"
import dayjs from "dayjs"
import React from "react"
import { ReportFormComponentProps } from "."
import PlainDatePicker from "components/DatePickers/DatePicker"
import { PlainDate } from "components/DateRangePicker"
import { DrawerSection } from "components/Drawer/DrawerItems"
import { DrawerReportForm } from "../ReportForm"

const ReportInputDate = ({ control }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })

  return (
    <DrawerSection name="Date" labelGap={0}>
      <TypedController
        name="inputDate"
        defaultValue={new PlainDate(dayjs())}
        rules={{
          required: true,
          validate: (date) => Boolean(date && date.isValid && !date.date.isAfter(dayjs())),
        }}
        render={({ onChange, value }) => (
          <PlainDatePicker
            disableFuture={true}
            maxDateMessage="Can only choose a past date"
            value={value || undefined}
            onChange={(date) => onChange(date)}
            fullWidth
          />
        )}
      />
    </DrawerSection>
  )
}

export default ReportInputDate
