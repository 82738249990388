import { useMemo, useState } from "react"

import { GenericSorting } from "components/Table/interface"
import { DEFAULT_ROWS_PER_PAGE } from "services/constants"
import { BasicParameters, PaginatedParamsReturnType, PaginationParameters } from "services/urlParse"

export const usePaginatedParamsWithoutURL = <TParam, TSortEnum>(
  defaultValue: TParam,
  defaultSorting: GenericSorting<TSortEnum>,
  defaultPagination?: PaginationParameters,
): PaginatedParamsReturnType<TSortEnum, TParam> => {
  type CompleteParameters = BasicParameters<TSortEnum> & TParam
  const [parameters, setParameters] = useState<CompleteParameters>()

  const setters = useMemo<PaginatedParamsReturnType<TSortEnum, TParam>>(() => {
    const defaultParams: CompleteParameters = {
      ...defaultValue,
      ...defaultSorting,
      ...(defaultPagination ?? { page: 1, limit: DEFAULT_ROWS_PER_PAGE }),
    }

    const params: CompleteParameters = {
      ...defaultParams,
      ...parameters,
    }

    const setValue = (setter: (oldValue: CompleteParameters) => CompleteParameters) => {
      const newValue = setter(params)
      setParameters(newValue)
    }

    return {
      params,
      setLimit: (limit) => setValue((p) => ({ ...p, page: 1, limit })),
      setPage: (page) => setValue((p) => ({ ...p, page })),
      setSorting: (sorting) =>
        setValue((previous) => ({
          ...previous,
          page: 1,
          sortBy: sorting.sortBy,
          sortDirection: sorting.sortDirection,
        })),
      setParams: (params) =>
        setValue((p) => {
          return { ...p, ...params, page: 1 }
        }),
    }
  }, [parameters, defaultValue, defaultSorting, defaultPagination])

  return setters
}
