import { Box, Checkbox, FormLabel } from "@material-ui/core"
import dayjs from "dayjs"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { DateRange, PlainDate } from "components/DateRangePicker"
import { useLoadingButton } from "components/LoadingButton"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import { DrawerProp } from "components/misc/useDrawer"

import api from "services/api"
import {
  CreateReportDtoFileTypeEnum,
  GetAssetDto,
  GetImpairmentDto,
  GetReportDto,
  GetReportModuleDto,
  GetReportTypeDto,
  GetWalletDto,
  ReportTypeFormatColumnDto,
  ReportTypeFormatDto,
} from "services/api/openapi"
import { Mixpanel } from "services/mixpanel"
import { ReportPortfolioType } from "../form/PortfolioRange"
import { chainlinkNodeOPeratorValidSourceNames } from "../form/MultiSelectWallet"

import ReportFormatForm from "../form"
import { matchReportExportFormatIdentifier } from "../form/interface"
import { ReportLedgerEntriesTransactionType, ReportSummarizedLedgerEntriesTimeframeType } from "pure-shared"
import ModulesForm from "./ModulesForm"
import ReportTypesForm from "./ReportTypesForm"
import WarningForm from "./WarningForm"
import FieldForm from "./FieldForm"
import BackOfficeView from "components/misc/BackOfficeView"
import { useJoinFavoriteReports } from "./useJoinFavoriteReports"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

export type DrawerReportForm = {
  date: DateRange
  inputDate?: PlainDate | null
  hiddenColumns: {
    columns: ReportTypeFormatColumnDto[]
    allColumns: boolean
  }
  assets: GetAssetDto[] | undefined
  wallets: GetWalletDto[] | undefined
  consensysDate?: {
    year: number | null
    month: number | null
  }
  portfolioType?: ReportPortfolioType
  fileType?: CreateReportDtoFileTypeEnum
  ledgerEntriesTransactionType?: ReportLedgerEntriesTransactionType
  impairment?: GetImpairmentDto | null
  summarizedLedgerEntriesTimeframe?: ReportSummarizedLedgerEntriesTimeframeType
  includeNft: boolean
}

const ReportForm = ({
  isOpen,
  item: report,
  onClose,
  setFormDirty,
  setFormDirtyData,
  returnAction,
  title,
  setTitle,
  setReturnAction,
}: DrawerProp<GetReportDto, true>) => {
  const { handleSubmit, control, formState, reset, watch, setValue } = useForm<DrawerReportForm>({
    mode: "onChange",
  })
  const toast = useToast()

  const [keepOpen, setKeepOpen] = useState<boolean>(false)
  const [savedModule, setSavedModule] = useState<GetReportModuleDto | null>(null)
  const [reportType, setReportType] = useState<GetReportTypeDto | null>(null)
  const [reportFormat, setReportFormat] = useState<ReportTypeFormatDto | null>(null)

  const { mutateAsync: updateHiddenColumnsMutation } = api.report.useUpdateHiddenColumns()
  const { mutateAsync: createReportMutation } = api.report.useCreateReports()

  useEffect(() => {
    if (!report && !savedModule && setTitle && title !== "New report") {
      setTitle("New report")
    }
  }, [setTitle, report, title, savedModule])

  const isFormDirty = formState.isValid && reportType !== null && reportFormat !== null

  useEffect(() => setFormDirty(isFormDirty), [isFormDirty, setFormDirty])
  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen, report, reset])
  useEffect(() => {
    setFormDirtyData({ reportType, reportFormat })
  }, [reportType, reportFormat, setFormDirtyData])

  const watchAllFields = watch()
  const moduleReportTypes = api.report.useReportTypes()

  const [CreateReportButton, handleButtonCallback] = useLoadingButton()

  useEffect(() => {
    if (!savedModule && returnAction && setReturnAction) {
      setReturnAction(undefined)
      if (setTitle !== undefined) setTitle("New report")
    }
  }, [savedModule, setReturnAction, returnAction, setTitle])

  useEffect(() => {
    if (moduleReportTypes.data !== undefined && moduleReportTypes.data.length === 1) {
      setSavedModule(moduleReportTypes.data[0])
    }
  }, [moduleReportTypes, setSavedModule])

  useEffect(() => {
    if (savedModule === null) setReportType(null)
  }, [setReportType, savedModule])

  const onSubmit = useCallback(
    async (form: DrawerReportForm) => {
      if (!savedModule || !reportType || !reportFormat) return
      try {
        const previousHiddenColumn = reportFormat.columns.filter((c) => !c.isVisible)
        if (
          form.hiddenColumns &&
          !form.hiddenColumns.allColumns &&
          (form.hiddenColumns.columns.length !== previousHiddenColumn.length ||
            JSON.stringify(form.hiddenColumns.columns.map((h) => h.name).sort()) !==
              JSON.stringify(previousHiddenColumn.map((c) => c.name).sort()))
        ) {
          await updateHiddenColumnsMutation({
            exportFormatId: reportFormat.id,
            updateExportFormatHiddenColumnsDto: {
              hiddenColumns: form.hiddenColumns.allColumns
                ? []
                : form.hiddenColumns.columns.map((column) => column.name),
            },
          })
        }

        const isConsensysReport = matchReportExportFormatIdentifier(
          [
            // { type: "consensys_asset_roll_forward", exportFormatName: "consensys_asset_roll_forward" },
            // { type: "consensys_rgl_asset_roll_forward", exportFormatName: "consensys_rgl_asset_roll_forward" },
            // { type: "consensys_asset_roll_forward_tax", exportFormatName: "consensys_asset_roll_forward_tax" },
            { type: "new_consensys_asset_roll_forward" },
            // { type: "consensys_asset_breakdown_recap" },
            { type: "consensys_ledger_entries" },
          ],
          {
            reportFormat,
            reportType,
          },
        )

        const isPorfolioReport = matchReportExportFormatIdentifier(
          [{ type: "portfolio_asset_breakdown" }, { type: "portfolio_wallet_breakdown" }],
          {
            reportFormat,
            reportType,
          },
        )

        let rangeDate: DateRange

        if (isConsensysReport) {
          if (form.consensysDate === undefined || !form.consensysDate.year || !form.consensysDate.month) {
            throw new Error("Missing date")
          }
          // if (form.wallets?.length !== 1) {
          //   throw new Error("Missing wallet")
          // }
          rangeDate = {
            startDate: new PlainDate(dayjs.utc(`${form.consensysDate?.year}-${form.consensysDate?.month}-01`)),
            endDate: undefined,
          }
        } else if (isPorfolioReport) {
          if (!form.portfolioType) {
            throw new Error("Missing date")
          }
          rangeDate = form.portfolioType.date
        } else {
          // if (isArf && (
          //   !form.date || !form.date.startDate || !form.date.endDate
          // )) {
          //   throw new Error("Start and end date should be set")
          // }
          rangeDate = {
            startDate: form.date?.startDate,
            endDate: form.date?.endDate,
          }
        }

        let fileType: CreateReportDtoFileTypeEnum
        if (form.fileType) {
          fileType = form.fileType
        } else if (reportFormat.availableAsXlsx) {
          fileType = "xlsx"
        } else if (reportFormat.availableAsLegacyExcel) {
          fileType = "legacy_excel"
        } else {
          fileType = "csv"
        }
        await createReportMutation({
          createReportDto: {
            reportModule: reportType.module,
            startDate: rangeDate.startDate?.toBackendDate() ?? null,
            endDate: rangeDate.endDate?.toBackendDate() ?? null,
            assetIds: (reportType.name !== "ledger" && form.assets?.map((w) => w.id)) || [],
            walletIds: (reportType.name !== "ledger" && form.wallets?.map((w) => w.id)) || [],
            exportFormatId: reportFormat.id,
            allColumns: form.hiddenColumns?.allColumns ?? true,
            fileType,
            ledgerEntriesTransactionType: form.ledgerEntriesTransactionType,
            impairmentId: form.impairment?.id ?? null,
            summarizedLedgerEntriesTimeframe: form.summarizedLedgerEntriesTimeframe,
            includeNft: form?.includeNft,
            inputDate: form?.inputDate?.toBackendDate() ?? null,
          },
        })

        if (!keepOpen) onClose()
      } catch (e) {
        toastCatch(e, toast)
      }
      Mixpanel.track("ReportCreated", {
        reportName: reportType.name,
        reportFormat: reportFormat.name,
      })
    },
    [
      onClose,
      createReportMutation,
      toast,
      reportType,
      reportFormat,
      updateHiddenColumnsMutation,
      savedModule,
      keepOpen,
    ],
  )

  const moduleReportTypesWithFavorite = useJoinFavoriteReports(moduleReportTypes.data)
  if (moduleReportTypes.isError) return <NetworkErrorMessage additionalData={moduleReportTypes} />
  if (moduleReportTypes.isLoading || moduleReportTypes.data === undefined) return <LoadingSpinner />

  const allColumnsHidden = Boolean(
    watchAllFields.hiddenColumns &&
      reportFormat &&
      !watchAllFields.hiddenColumns.allColumns &&
      watchAllFields.hiddenColumns.columns.length === reportFormat.columns.filter((c) => c.canBeRemoved).length,
  )

  return (
    <Box component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))} title="New report">
      <WarningForm />
      <ModulesForm
        moduleReportTypes={moduleReportTypesWithFavorite}
        savedModule={savedModule}
        setSavedModule={setSavedModule}
        setReportType={setReportType}
        setTitle={setTitle}
        setReturnAction={setReturnAction}
      />
      <ReportTypesForm
        savedModule={savedModule}
        reportType={reportType}
        setReportType={setReportType}
        setReportFormat={setReportFormat}
        setReturnAction={setReturnAction}
        setSavedModule={setSavedModule}
        setTitle={setTitle}
        setValue={() =>
          setValue(
            "wallets",
            watchAllFields.wallets?.filter((w) => chainlinkNodeOPeratorValidSourceNames.includes(w.sourceName)),
          )
        }
      />
      <FieldForm reportType={reportType} reportFormat={reportFormat} setReportFormat={setReportFormat} />
      {savedModule && reportType && reportFormat && (
        <ReportFormatForm
          control={control}
          reportModule={savedModule}
          reportType={reportType}
          reportFormat={reportFormat}
          allColumnsHidden={allColumnsHidden}
        />
      )}
      {savedModule && reportType && (
        <Box mt={3} display="flex" justifyContent="space-between">
          <CreateReportButton disabled={!isFormDirty || allColumnsHidden} type="submit">
            Create
          </CreateReportButton>
          <BackOfficeView>
            <Box display="flex" alignItems="center">
              <Checkbox
                color="primary"
                id="manual-mapping-checkbox"
                style={{ paddingLeft: 0 }}
                onChange={(_, check) => setKeepOpen(check)}
                checked={keepOpen}
              />
              <FormLabel htmlFor="manual-mapping-checkbox">Keep open</FormLabel>
            </Box>
          </BackOfficeView>
        </Box>
      )}
    </Box>
  )
}

export default ReportForm
