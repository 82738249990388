import React from "react"
import { CustomCellDirection } from "../../GeneratedTable/useGenerateCell"
import dayjs from "dayjs"
import TypographyAllowEvent from "components/TypographyAllowEvent"
import { ReactComponent as AlignArrowRight } from "CryptioUI/assets/icons/align-arrow-right.svg"
import { ReactComponent as AlignArrowLeft } from "CryptioUI/assets/icons/align-arrow-left.svg"
import { ReactComponent as Gas } from "CryptioUI/assets/icons/gas.svg"
import { ReactComponent as Trade } from "CryptioUI/assets/icons/menu/transactions.svg"
import { BoldWeight, TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"

interface DateProps {
  transactionDate: string
  isDevelopped: boolean
  direction: CustomCellDirection
}

const Date = (props: DateProps) => {
  const { isDevelopped, transactionDate, direction } = props

  if (isDevelopped) {
    switch (direction) {
      case CustomCellDirection.IN:
        return (
          <div className="flex flex-row justify-center items-center gap-2">
            <AlignArrowLeft className="w-5 text-grey-400" />
            <TypographyUI boldness={BoldWeight.MEDIUM} variant={TypographyVariant.BODY} className="text-grey-400">
              In
            </TypographyUI>
          </div>
        )
      case CustomCellDirection.OUT:
        return (
          <div className="flex flex-row justify-center items-center gap-2">
            <AlignArrowRight className="w-5 text-grey-400" />
            <TypographyUI boldness={BoldWeight.MEDIUM} variant={TypographyVariant.BODY} className="text-grey-400">
              Out
            </TypographyUI>
          </div>
        )
      case CustomCellDirection.FEE:
        return (
          <div className="flex flex-row justify-center items-center gap-2">
            <Gas className="w-5 text-grey-400" />
            <TypographyUI boldness={BoldWeight.MEDIUM} variant={TypographyVariant.BODY} className="text-grey-400">
              Fee
            </TypographyUI>
          </div>
        )
      case CustomCellDirection.TRADE:
        return (
          <div className="flex flex-row justify-center items-center gap-2">
            <Trade className="w-5 text-grey-400" />
            <TypographyUI boldness={BoldWeight.MEDIUM} variant={TypographyVariant.BODY} className="text-grey-400">
              Trade
            </TypographyUI>
          </div>
        )
      default:
        return <></>
    }
  }
  return (
    <div className="flex flex-col justify-start items-start gap-1" data-tag="allowRowEvents">
      <TypographyAllowEvent variant={TypographyVariant.BODY} boldness={BoldWeight.REGULAR} className="text-grey-500">
        {dayjs(transactionDate).tz().format("L")}
      </TypographyAllowEvent>
      <TypographyAllowEvent className="text-grey-400" variant={TypographyVariant.CAPTION} boldness={BoldWeight.REGULAR}>
        {dayjs(transactionDate).tz().format("LTS")}
      </TypographyAllowEvent>
    </div>
  )
}

export default Date
