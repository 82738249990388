import { Box, DialogActions, Typography } from "@material-ui/core"
import { CheckRounded, WarningRounded } from "@material-ui/icons"
import React, { useContext, useRef, useState } from "react"
import { IDataTableColumn } from "react-data-table-component"
import { useForm } from "react-hook-form"

import { useLoadingButton } from "components/LoadingButton"
import BaseTable from "components/Table"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { ReplaceChartAccountDto } from "services/api/openapi"
import { CHART_ACCOUNT_TYPES, DEFAULT_ROWS_PER_PAGE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from "services/constants"
import { ThemeContext } from "services/context/themeContext"
import parseCsvFile, {
  CsvModalType,
  CsvParseOptions,
  findRowByRowIdFilterByErrorString,
  WithCsvInfos,
} from "services/csvParse"
import { DataTableSelectedRowState } from "services/utils/types"
import { widgetTableStyle } from "../../../Dashboard/widgets/widgetTableStyle"
import InputButtonUI from "CryptioUI/InputButton"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

type ChartAccountWithCsvInfos = WithCsvInfos<ReplaceChartAccountDto>
interface ChartAccountModalCsvForm {
  chartAccounts: ChartAccountWithCsvInfos[]
}

const columns: IDataTableColumn<ChartAccountWithCsvInfos>[] = [
  {
    name: "Code",
    selector: "code",
    sortable: true,
  },
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Type",
    selector: "type",
    sortable: true,
  },
  {
    name: "Status",
    selector: "errorString",
    sortable: true,
    format: function formatAddress(row) {
      return row.errorString ? (
        <Typography color="error" variant="body1">
          <Box display="flex">
            <Box pr={1}>
              <WarningRounded />
            </Box>
            {row.errorString}
          </Box>
        </Typography>
      ) : (
        <CheckRounded color="action" />
      )
    },
  },
]

const ChartAccountModalCsv = ({
  buttonText,
  buttonDisabled,
  askConnect,
}: {
  buttonText: string
  buttonDisabled: boolean
  askConnect: (onConnect: () => void) => void
}): JSX.Element => {
  const [modalData, setModalData] = useState<CsvModalType<ReplaceChartAccountDto>>({
    isOpen: false,
    data: [],
  })
  const { handleSubmit } = useForm<ChartAccountModalCsvForm>()
  const toast = useToast()
  const muiThemeContext = useContext(ThemeContext)

  const [selectedRows, setSelectedRows] = useState<DataTableSelectedRowState<ChartAccountWithCsvInfos>>({
    allSelected: false,
    selectedCount: 0,
    selectedRows: [],
  })

  const fileField = useRef<HTMLInputElement>(null)

  const handleFileChange = () => {
    if (!fileField.current || !fileField.current.files || !fileField.current.files.length) {
      return
    }

    const options: CsvParseOptions<ReplaceChartAccountDto> = {
      file: fileField.current.files[0],
      requiredColumns: ["name", "type", "code", "description"],
      setModalData,
      toast,
      transformData: (data) =>
        data.map(({ ...row }, index) => {
          let errorString: string | null = null
          if (!CHART_ACCOUNT_TYPES.includes(row.type)) {
            errorString = "Invalid account type"
          } else if (!row.name.length) {
            errorString = "Missing name"
          }
          return {
            ...row,
            externalIdentifier: null,
            errorString,
            rowId: index,
          }
        }),
    }

    parseCsvFile<ReplaceChartAccountDto>(options)
  }

  const [ImportSelectionButton, handleButtonCallback] = useLoadingButton()
  const { mutateAsync: replaceChartAccountMutation } = api.chartAccount.useReplaceChartAccounts()
  const onSubmit = async () => {
    try {
      // Need filter, empty item in array
      await replaceChartAccountMutation({
        replaceChartAccountsDto: {
          accounts: findRowByRowIdFilterByErrorString(selectedRows),
        },
      })

      toast.open("COA successfully imported", { variant: "success" })
    } catch (e) {
      toastCatch(e as Error, toast)
    }

    setModalData({
      isOpen: false,
      data: [],
    })
  }

  return (
    <>
      <InputButtonUI
        htmlFor="chart-of-account"
        type="file"
        accept=".csv"
        ref={fileField}
        name="chart-of-account"
        id="chart-of-account"
        disabled={buttonDisabled}
        onClick={() => {
          if (fileField.current) {
            fileField.current.value = ""
            fileField.current.files = null
          }
        }}
        onChange={() => askConnect(handleFileChange)}
      >
        {buttonText}
      </InputButtonUI>
      <ModalUI
        isOpen={modalData.isOpen}
        onClose={() => setModalData({ isOpen: false, data: [] })}
        className="w-[1000px] h-auto"
        title="Import Chart of accounts"
      >
        <BaseTable<ChartAccountWithCsvInfos, "errorString">
          columns={columns}
          items={modalData.data}
          fixedScroll="400px"
          defaultParams={{
            sortDirection: "ascending",
            sortBy: "errorString",
          }}
          onSelectedChange={setSelectedRows}
          paginationRowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          paginationTotalRows={modalData.data.length}
          paginationPerPage={50}
          pagination={modalData.data.length > DEFAULT_ROWS_PER_PAGE}
          customStyle={widgetTableStyle(muiThemeContext.type)}
          sortServer={false}
        />
        <Box component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              {modalData.data.length} element
              {modalData.data.length > 1 ? "s" : ""} found
            </Typography>
            <div className="mt-4 flex flex-row space-x-4">
              <ButtonUI onClick={() => setModalData({ isOpen: false, data: [] })} mode={Mode.CONTAINED}>
                Close
              </ButtonUI>
              <ImportSelectionButton disabled={selectedRows.selectedCount === 0} type="submit">
                Import selection
              </ImportSelectionButton>
            </div>
          </DialogActions>
        </Box>
      </ModalUI>
    </>
  )
}

export default ChartAccountModalCsv
