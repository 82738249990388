import { Box, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import WarningIcon from "@material-ui/icons/Warning"
import React, { useMemo } from "react"
import { UseQueryResult } from "react-query"

import useCounterPartyTableColumn from "../../useCounterPartyTableColumn"
import { FullMovementDto, MovementCounterPartyDto, MovementCounterPartyDtoDirectionEnum } from "services/api/openapi"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import LoadingSpinner from "components/misc/LoadingSpinner"
import ServerSideTable from "components/Table/ServerSideTable"
import useDrawer from "../../../../misc/useDrawer"

const useStyles = makeStyles((theme: Theme) => ({
  containerSection: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  typography: {
    fontWeight: 500,
  },
  container: {
    display: "flex",
    overflowX: "scroll",
    width: "100%",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.12)",
    borderRadius: "2px",
    minHeight: "fit-content",
    height: "fit-content",
  },
  typographyWarning: {
    fontWeight: 800,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  iconWarning: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.4),
  },
}))

interface SelectCounterPartyProps {
  counterParties: UseQueryResult<MovementCounterPartyDto[], unknown>
  confirmedInternalTransfer: boolean
  setSelectedGuessedOtherParty: (newCounterParty: MovementCounterPartyDto | null) => void
  selectedGuessedOtherParty: MovementCounterPartyDto | null
  isInternal: boolean
  movement?: FullMovementDto
}

const SelectCounterParty = (props: SelectCounterPartyProps) => {
  const {
    counterParties,
    confirmedInternalTransfer,
    isInternal,
    setSelectedGuessedOtherParty,
    selectedGuessedOtherParty,
    // movement,
  } = props
  const classes = useStyles()

  // const { isNeedReview } = useContext(FilterContext)
  // const { mutateAsync: confirmReviewInternalTransfer } = api.transaction.useConfirmReviewInternalTransfer()

  const [walletDrawer, openWalletDrawer] = useDrawer("wallet")
  const columns = useCounterPartyTableColumn({
    setSelectedGuessedOtherParty,
    selectedGuessedOtherParty,
    openWalletDrawer,
  })
  const [movementDrawer, openMovementDrawer] = useDrawer("movement")

  const serverSideTable = useMemo(() => {
    return (
      <Paper>
        {movementDrawer}
        <ServerSideTable<MovementCounterPartyDto, MovementCounterPartyDtoDirectionEnum>
          columns={columns}
          items={counterParties.data || []}
          totalCount={counterParties.data?.length || 0}
          pagination={false}
          onRowClicked={(row) => {
            const movementId = counterParties.data?.find((m) => m.id === row.id)?.id

            if (movementId) {
              openMovementDrawer({ movementId })
            }
          }}
        />
      </Paper>
    )
  }, [counterParties, columns, movementDrawer, openMovementDrawer])
  // useEffect(() => {
  //   const isMovementViewed = () =>
  //     isNeedReview && movement && !counterParties.isLoading && !(counterParties.data === undefined)

  //   if (isMovementViewed()) {
  //     confirmReviewInternalTransfer({ transactionId: movement?.id ?? "" })
  //   }
  // }, [isNeedReview, movement, confirmReviewInternalTransfer, counterParties.data, counterParties.isLoading])

  if (counterParties.isError) {
    return <NetworkErrorMessage additionalData={counterParties} />
  }
  if (counterParties.isLoading || counterParties.data === undefined) {
    return <LoadingSpinner />
  }

  return (
    <>
      {walletDrawer}
      {confirmedInternalTransfer && isInternal && (
        <Box>
          {counterParties.data.length > 0 ? (
            <Box display="flex" flexDirection="column">
              <div className={classes.containerSection}>
                <Typography className={classes.typography}>Is the transaction below the counterparty ?</Typography>
              </div>
              <div className={classes.container}>{serverSideTable}</div>
            </Box>
          ) : (
            <div className={classes.containerSection}>
              <Typography className={classes.typographyWarning}>
                <WarningIcon className={classes.iconWarning} /> No counter party found
              </Typography>
            </div>
          )}
        </Box>
      )}
    </>
  )
}

export default SelectCounterParty
