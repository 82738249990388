import React, { useEffect, useState } from "react"

import { DrawerProps } from "CryptioUI/types"

import WrapperDrawer from "./WrapperDrawer"
import HeaderDrawerUI from "./HeaderDrawer"

const DrawerUI = (props: DrawerProps): JSX.Element => {
  const {
    children,
    title,
    side = "right",
    delayDurationIn = "duration-300",
    delayDurationOut = "duration-200",
    isOpen,
    onClose,
    returnAction,
    RightHeaderButton,
  } = props
  const [isRemoved, setIsRemoved] = useState(false)

  useEffect(() => {
    if (!isOpen && isRemoved) setTimeout(() => setIsRemoved(false), 200)
    else {
      if (isRemoved !== isOpen) setIsRemoved(isOpen)
    }
  }, [isOpen, isRemoved])

  return (
    <>
      {isRemoved && (
        <WrapperDrawer
          side={side}
          isOpen={isOpen}
          onClose={onClose}
          delayDurationIn={delayDurationIn}
          delayDurationOut={delayDurationOut}
        >
          <HeaderDrawerUI title={title} returnAction={returnAction} RightButton={RightHeaderButton} />
          <div className="px-10 pt-20 py-6 overflow-y-auto h-full">{children}</div>
        </WrapperDrawer>
      )}
    </>
  )
}

export default DrawerUI
