import { Typography } from "@material-ui/core"
import React, { useMemo, useState } from "react"

import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import { BackOfficeGetFullUserDto } from "services/api/openapi/models"
import { pluralize } from "services/utils/textUtils"
import BackOfficeUserMetricModal, { WorkspaceInfoKey } from "./BackOfficeUserMetricModal"
import BackOfficeUserSourceModal, { UserSourceModal } from "./BackOfficeUserSourceModal"

const BackOfficeUserMetrics = ({ user }: { user: BackOfficeGetFullUserDto }) => {
  const [metricModalData, setMetricModalData] = useState<{
    isOpen: boolean
    workspaceInfoKey: WorkspaceInfoKey
  }>({
    isOpen: false,
    workspaceInfoKey: "transactionCount",
  })
  const [isUserSourceModalOpen, setIsUserSourceModalOpen] = useState<boolean>(false)

  const openModal = (workspaceInfoKey: WorkspaceInfoKey) => setMetricModalData({ isOpen: true, workspaceInfoKey })
  const closeModal = () =>
    setMetricModalData((old) => ({
      isOpen: false,
      workspaceInfoKey: old.workspaceInfoKey,
    }))

  const totalCount = useMemo(
    () => ({
      transaction: user.workspaces.reduce((p, v) => p + v.transactionCount, 0),
      contact: user.workspaces.reduce((p, v) => p + v.contactCount, 0),
      label: user.workspaces.reduce((p, v) => p + v.labelCount, 0),
      accounting: user.workspaces.reduce((p, v) => p + (v.accountingIntegrationName ? 1 : 0), 0),
      mapping: user.workspaces.reduce((p, v) => p + v.mappingCount, 0),
      source: user.workspaces.reduce((p, v) => p + v.sources.length, 0),
    }),
    [user],
  )

  const sourceList = useMemo(
    () =>
      user.workspaces.reduce(
        (sources, workspace) =>
          sources.concat(
            workspace.sources.map((source) => ({
              ...source,
              workspaceName: workspace.name,
            })),
          ),
        [] as UserSourceModal[],
      ),
    [user],
  )

  return (
    <>
      <BackOfficeUserSourceModal
        sources={sourceList}
        isOpen={isUserSourceModalOpen}
        onClose={() => setIsUserSourceModalOpen(false)}
      />

      <BackOfficeUserMetricModal
        workspaces={user.workspaces}
        isOpen={metricModalData.isOpen}
        workspaceInfoKey={metricModalData.workspaceInfoKey}
        onClose={closeModal}
      />

      <DrawerCategory title="User metrics">
        <DrawerSection name="Workspaces">
          <Typography variant="body2" color="textSecondary" style={{ padding: "6px 8px" }}>
            {user.workspaces.length} {pluralize(user.workspaces.length > 1, "workspace")}
          </Typography>
        </DrawerSection>

        <DrawerSection name="Sources">
          <ButtonUI mode={Mode.TEXTONLY} onClick={() => setIsUserSourceModalOpen(true)}>
            <Typography variant="body2" color="textSecondary">
              {totalCount.source} {pluralize(totalCount.source > 1, "source")}
            </Typography>
          </ButtonUI>
        </DrawerSection>

        <DrawerSection name="Transactions">
          <ButtonUI mode={Mode.TEXTONLY} onClick={() => openModal("transactionCount")}>
            <Typography variant="body2" color="textSecondary">
              {totalCount.transaction} {pluralize(totalCount.transaction > 1, "transaction")}
            </Typography>
          </ButtonUI>
        </DrawerSection>

        <DrawerSection name="Contact">
          <ButtonUI mode={Mode.TEXTONLY} onClick={() => openModal("contactCount")}>
            <Typography variant="body2" color="textSecondary">
              {totalCount.contact} {pluralize(totalCount.contact > 1, "contact")}
            </Typography>
          </ButtonUI>
        </DrawerSection>

        <DrawerSection name="Labels">
          <ButtonUI mode={Mode.TEXTONLY} onClick={() => openModal("labelCount")}>
            <Typography variant="body2" color="textSecondary">
              {totalCount.label} {pluralize(totalCount.label > 1, "label")}
            </Typography>
          </ButtonUI>
        </DrawerSection>

        <DrawerSection name="Accounting integrations">
          <ButtonUI mode={Mode.TEXTONLY} onClick={() => openModal("accountingIntegrationName")}>
            <Typography variant="body2" color="textSecondary">
              {totalCount.accounting} {pluralize(totalCount.accounting > 1, "accounting integration")}
            </Typography>
          </ButtonUI>
        </DrawerSection>

        <DrawerSection name="Mappings">
          <ButtonUI mode={Mode.TEXTONLY} onClick={() => openModal("mappingCount")}>
            <Typography variant="body2" color="textSecondary">
              {totalCount.mapping} {pluralize(totalCount.mapping > 1, "mapping")}
            </Typography>
          </ButtonUI>
        </DrawerSection>
      </DrawerCategory>
    </>
  )
}

export default BackOfficeUserMetrics
