/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import { GetNftDto, NftSummaryDto, PaginatedGetNftDtoResult, UpdateNftQuery } from "../models"

export interface GetNftRequest {
  workspaceId: string
  nftId: string
}

export interface GetNftsRequest {
  page: number
  limit: number
  workspaceId: string
  blockchain?: GetNftsBlockchainEnum
  standard?: GetNftsStandardEnum
  collectionName?: string
  fiatValue?: string
  walletSourceIds?: Array<string>
  excludedIds?: Array<string>
}

export interface GetSummaryRequest {
  workspaceId: string
}

export interface UpdateNftRequest {
  workspaceId: string
  updateNftQuery: UpdateNftQuery
}

/**
 *
 */
export class NftApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static getNft(params: GetNftRequest) {
    return localNftApi.getNft(params)
  }

  /**
   * Get a nft by id
   */
  getNft({ workspaceId, nftId }: GetNftRequest): Promise<GetNftDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/nft/${encodeURIComponent(nftId)}`)
  }

  static getNfts(params: GetNftsRequest) {
    return localNftApi.getNfts(params)
  }

  /**
   * Get all nfts
   */
  getNfts({ workspaceId, ...queryParameters }: GetNftsRequest): Promise<PaginatedGetNftDtoResult> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/nft?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
    )
  }

  static getSummary(params: GetSummaryRequest) {
    return localNftApi.getSummary(params)
  }

  /**
   * Get NFT summary
   */
  getSummary({ workspaceId }: GetSummaryRequest): Promise<NftSummaryDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/nft/summary`)
  }

  static updateNft(params: UpdateNftRequest) {
    return localNftApi.updateNft(params)
  }

  /**
   * Update a NFT valuation
   */
  updateNft({ updateNftQuery, workspaceId }: UpdateNftRequest): Promise<void> {
    return this.restInstance.put(`/workspaces/${encodeURIComponent(workspaceId)}/nft`, updateNftQuery, true)
  }
}

const localNftApi = new NftApi()

/**
 * @export
 * @enum {string}
 */
export type GetNftsBlockchainEnum = "Ethereum"
/**
 * @export
 * @enum {string}
 */
export type GetNftsStandardEnum = "erc-721" | "erc-1155"
