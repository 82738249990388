import { Control } from "react-hook-form"
import WarningTypography from "components/WarningTypography"
import { GetReportModuleDto, GetReportTypeDto, ReportTypeFormatDto } from "services/api/openapi"
import { DrawerReportForm } from "../ReportForm"
import ReportConsensysDate from "./ConsensysDate"
import ReportHideColumns from "./HideColumns"
import ReportMultiSelectAsset from "./MultiSelectAsset"
import ReportMultiSelectWallet from "./MultiSelectWallet"
import ReportPortfolioRange from "./PortfolioRange"
import ReportDateRangePicker from "./ReportDateRangePicker"
import ReportEndDate from "./ReportEndDate"
import ReportFormFileType from "./ReportFileType"
import ReportSingleSelectWallet from "./SingleSelectWallet"
import { matchReportExportFormatIdentifier, ReportExportFormatIdentifier } from "./interface"
import ReportLedgerEntriesTransactionTypeOption from "./LedgerEntriesTransactionType"
import ReportSelectImpairment from "./Impairment"
import SummarizedLedgerTimeframe from "./SummarizedLedgerTimeframe"
import { IncludeNftToggle, IncludeNftByDefault } from "./IncludeNft"
import ReportInputDate from "./ReportInputDate"
import { ReportFavoriteFilters } from "./ReportFavoriteFilters"

export type ReportFormComponentProps = {
  reportModule: GetReportModuleDto
  reportType: GetReportTypeDto
  reportFormat: ReportTypeFormatDto
  control: Control<DrawerReportForm>
  allColumnsHidden: boolean
}

type FormComponent = {
  Component: (props: ReportFormComponentProps) => JSX.Element
} & (
  | {
      include?: never
      exclude: ReportExportFormatIdentifier[]
    }
  | {
      include: ReportExportFormatIdentifier[]
      exclude?: never
    }
)
const formComponents: FormComponent[] = [
  {
    Component: ReportSelectImpairment,
    include: [{ type: "impairment_balance" }, { type: "impairment_transaction_detail" }],
  },
  {
    Component: () => (
      <WarningTypography alignItems="start" mt={3}>
        Please note that this report is not suited if you have more than 10.000 transactions to export. The Historical
        balances report is more adapted for this type of volume.
      </WarningTypography>
    ),
    include: [{ type: "per_day_breakdown" }],
  },
  {
    Component: ReportMultiSelectAsset,
    exclude: [
      { type: "new_consensys_asset_roll_forward" },
      { type: "consensys_ledger_entries" },
      { type: "administrator", exportFormatName: "sanity_check" },
      { type: "filecoin_reward_tracking" },

      { type: "fireblocks_missing_addresses" },
      { type: "contacts" },
      { type: "unidentified_addresses" },
      { type: "ledger" },
      { type: "summarized_ledger" },
      { type: "sources" },
      { type: "impairment", exportFormatName: "recap" },
      { type: "assets" },
      { type: "audit_trail" },
      { type: "trial_balances" },
      { type: "transactions_custom_export" },
    ],
  },
  {
    Component: ReportMultiSelectWallet,
    exclude: [
      { type: "new_consensys_asset_roll_forward" },
      { type: "administrator", exportFormatName: "sanity_check" },
      { type: "filecoin_reward_tracking" },

      { type: "fireblocks_missing_addresses" },
      { type: "contacts" },
      { type: "unidentified_addresses" },
      { type: "ledger" },
      { type: "impairment", exportFormatName: "recap" },
      { type: "summarized_ledger" },
      { type: "audit_trail" },
      { type: "trial_balances" },
    ],
  },
  {
    Component: ReportFavoriteFilters,
    exclude: [
      { type: "new_consensys_asset_roll_forward" },
      { type: "administrator", exportFormatName: "sanity_check" },
      { type: "filecoin_reward_tracking" },

      { type: "fireblocks_missing_addresses" },
      { type: "contacts" },
      { type: "unidentified_addresses" },
      { type: "ledger" },
      { type: "impairment", exportFormatName: "recap" },
      { type: "summarized_ledger" },
      { type: "audit_trail" },
      { type: "trial_balances" },
    ],
  },
  {
    Component: ReportEndDate,
    include: [{ type: "end_of_day_balances" }, { type: "consensys_asset_breakdown_recap" }],
  },
  {
    Component: () => (
      <WarningTypography alignItems="start" mt={3}>
        Note that we use the balances directly provided by the remote sources in this report, except for the
        transactions coming from a CSV source, a BTC xPub wallet, or Coinbase Commerce. In that case, balances are
        computed from Cryptio&apos;s transactions.
      </WarningTypography>
    ),
    include: [{ type: "current_balances" }],
  },
  {
    Component: () => (
      <WarningTypography alignItems="start" mt={3}>
        Don&apos;t forget to update the selected wallets to get the latest transactions.
      </WarningTypography>
    ),
    exclude: [
      { type: "consensys_asset_roll_forward", exportFormatName: "consensys_asset_roll_forward" },
      { type: "consensys_rgl_asset_roll_forward", exportFormatName: "consensys_rgl_asset_roll_forward" },
      { type: "consensys_asset_roll_forward_tax", exportFormatName: "consensys_asset_roll_forward_tax" },
      { type: "new_consensys_asset_roll_forward" },
      { type: "consensys_ledger_entries" },
      { type: "consensys_asset_breakdown_recap" },
      { type: "administrator", exportFormatName: "sanity_check" },

      { type: "fireblocks_missing_addresses" },
      { type: "contacts" },
      { type: "unidentified_addresses" },
      { type: "ledger" },
      { type: "summarized_ledger" },
      { type: "sources" },
      { type: "impairment", exportFormatName: "recap" },
      { type: "audit_trail" },
      { type: "transactions_custom_export" },
    ],
  },
  {
    Component: IncludeNftToggle,
    exclude: [
      { type: "contacts" },
      { type: "sources" },
      { type: "fees_expenditures" },
      { type: "assets" },
      { type: "unidentified_addresses" },
      { type: "audit_trail" },
      { type: "ledger" },
      { type: "summarized_ledger" },
      { type: "transactions" },
      { type: "transactions_custom_export" },

      { type: "filecoin_reward_tracking" },

      { type: "consensys_asset_roll_forward", exportFormatName: "consensys_asset_roll_forward" },
      { type: "consensys_rgl_asset_roll_forward", exportFormatName: "consensys_rgl_asset_roll_forward" },
      { type: "consensys_asset_roll_forward_tax", exportFormatName: "consensys_asset_roll_forward_tax" },
      { type: "new_consensys_asset_roll_forward" },
      { type: "consensys_ledger_entries" },
      { type: "consensys_asset_breakdown_recap" },
      { type: "consensys_transactions" },
      { type: "consensys_provider_fee_historical_balances" },
    ],
  },
  {
    Component: IncludeNftByDefault,
    include: [
      { type: "transactions" },
      { type: "contacts" },
      { type: "sources" },
      { type: "fees_expenditures" },
      { type: "assets" },
      { type: "unidentified_addresses" },
      { type: "audit_trail" },
      { type: "ledger" },
    ],
  },
  {
    Component: ReportDateRangePicker,
    exclude: [
      // { type: "consensys_asset_roll_forward", exportFormatName: "consensys_asset_roll_forward" },
      // { type: "consensys_rgl_asset_roll_forward", exportFormatName: "consensys_rgl_asset_roll_forward" },
      // { type: "consensys_asset_roll_forward_tax", exportFormatName: "consensys_asset_roll_forward_tax" },
      { type: "new_consensys_asset_roll_forward" },
      { type: "consensys_ledger_entries" },
      { type: "consensys_asset_breakdown_recap" },
      { type: "administrator", exportFormatName: "sanity_check" },

      { type: "fireblocks_missing_addresses" },
      { type: "contacts" },
      { type: "unidentified_addresses" },
      { type: "sources" },
      { type: "impairment", exportFormatName: "recap" },
      { type: "assets" },
      { type: "current_balances" },
      { type: "end_of_day_balances" },

      { type: "portfolio_asset_breakdown" },
      { type: "portfolio_wallet_breakdown" },

      { type: "impairment_balance" },
    ],
  },
  {
    Component: ReportLedgerEntriesTransactionTypeOption,
    include: [{ type: "ledger" }],
  },
  {
    Component: SummarizedLedgerTimeframe,
    include: [{ type: "summarized_ledger" }],
  },
  {
    Component: ReportPortfolioRange,
    include: [{ type: "portfolio_asset_breakdown" }, { type: "portfolio_wallet_breakdown" }],
  },
  {
    Component: ReportSingleSelectWallet,
    include: [{ type: "new_consensys_asset_roll_forward" }, { type: "filecoin_reward_tracking" }],
  },
  {
    Component: ReportConsensysDate,
    include: [
      // { type: "consensys_asset_roll_forward", exportFormatName: "consensys_asset_roll_forward" },
      // { type: "consensys_rgl_asset_roll_forward", exportFormatName: "consensys_rgl_asset_roll_forward" },
      // { type: "consensys_asset_roll_forward_tax", exportFormatName: "consensys_asset_roll_forward_tax" },
      { type: "new_consensys_asset_roll_forward" },
      { type: "consensys_ledger_entries" },
    ],
  },
  {
    Component: ReportInputDate,
    include: [{ type: "filecoin_reward_tracking" }],
  },
  {
    Component: ReportHideColumns,
    exclude: [
      { type: "fireblocks_missing_addresses" },
      { type: "contacts" },
      { type: "assets" },
      { type: "unidentified_addresses" },
      { type: "ledger" },
      { type: "summarized_ledger" },
      { type: "audit_trail" },
      { type: "trial_balances" },
      { type: "transactions_custom_export" },
      { type: "filecoin_reward_tracking" },
    ],
  },
]

const ReportFormatForm = (props: ReportFormComponentProps) => {
  return (
    <>
      {formComponents
        .filter((formComponent) => {
          if (formComponent.exclude) {
            return !matchReportExportFormatIdentifier(formComponent.exclude, props)
          }
          return matchReportExportFormatIdentifier(formComponent.include, props)
        })
        .map(({ Component }, idx) => (
          <Component key={Component.name + idx} {...props} />
        ))}
      {props.reportFormat.availableAsCsv &&
        (props.reportFormat.availableAsXlsx || props.reportFormat.availableAsLegacyExcel) && (
          <ReportFormFileType {...props} />
        )}
    </>
  )
}

export default ReportFormatForm
