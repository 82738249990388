import React, { createContext, useContext, useEffect, useState } from "react"
import api from "../../api"
import { getScript } from "../../misc/getScript"
import { DEFAULT_FORM_ID, WORKSPACE_ID_FIELD_ID } from "./ids"

interface ZendeskManagerContextInterface {
  workspaceId: string | undefined
  updateZendeskWorkspaceId: (zendeskWorkspaceId: string | undefined) => void
}

const ZendeskManagerContext = createContext<ZendeskManagerContextInterface>(undefined!)

// I could not find an "easy" way to retrieve the workspace ID from within this
// component, so I exposed a hook so that we can inject the workspace ID here
// via `PrivateApp`.
export const useZendeskManager = () => useContext(ZendeskManagerContext)

interface Props {
  enabled?: boolean
  zendeskKey: string
  children: React.ReactNode
}

export const ZendeskProvider = ({ enabled = true, zendeskKey, children }: Props): JSX.Element => {
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [workspaceId, setWorkspaceId] = useState<string | undefined>(undefined)

  const user = api.user.useUser()

  if (user.isSuccess && user.data.email !== email) {
    setEmail(user.data.email)
  }

  useEffect(() => {
    if (!enabled) return

    // This is asynchronous. Be careful if you use
    // `window.zE` as it may not be defined.
    getScript(`https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`, "zE", true, "ze-snippet").catch(
      console.error,
    )

    // We don't care if this object is declared before the script above is done
    // loading. Feel free to expose settings as part of this component's
    // interface.
    window.zESettings = {
      webWidget: {
        contactForm: {
          ticketForms: [{ id: DEFAULT_FORM_ID, title: false }],
        },
      },
    }

    // Maybe we should expose a cleanup function to avoid loading the script
    // multiple times. But I don't see us doing this right now.
  }, [enabled, zendeskKey])

  // We track workspace ID and email changes.
  useEffect(() => {
    if (window.zE !== undefined) {
      window.zE("webWidget", "updateSettings", {
        webWidget: {
          contactForm: {
            fields: [
              { id: WORKSPACE_ID_FIELD_ID, prefill: { "*": workspaceId } },
              { id: "email", prefill: { "*": email } },
            ],
          },
        },
      })
    }
  }, [workspaceId, email])

  const updateZendeskWorkspaceId = (zendeskWorkspaceId: string | undefined) => setWorkspaceId(zendeskWorkspaceId)

  return (
    <ZendeskManagerContext.Provider value={{ workspaceId: undefined, updateZendeskWorkspaceId }}>
      {children}
    </ZendeskManagerContext.Provider>
  )
}
