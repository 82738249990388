import { Box } from "@material-ui/core"
import React, { useState } from "react"
import InputClearable from "components/Input/InputClearable"
import ModalUI from "CryptioUI/Modal"

interface Props {
  isOpen: boolean
  onClose: () => void
  onSave: (name: string) => void
}

const FavoriteModale = (props: Props): JSX.Element => {
  const { isOpen, onClose, onSave } = props
  const [name, setName] = useState("")

  return (
    <ModalUI
      isOpen={isOpen}
      onClose={onClose}
      className="w-[600px] h-auto"
      title="New Favorite"
      actionTitle="Save"
      onAction={() => onSave(name)}
      disabled={name === "" ? true : false}
    >
      <Box display="flex" flexDirection="column" justifyContent="flex-end">
        <InputClearable
          label="Name :"
          id="name-favorite-filter-textfield"
          value={name}
          onChange={(value) => setName(value)}
          placeholder="Enter name"
          fullWidth
        />

        {/* <Box mt={2}>
          <DialogActions>
            <ButtonUI onClick={onClose} color="default">
              Cancel
            </ButtonUI>
            <UncontrolledLoadingButton disabled={name === "" ? true : false} onClick={() => onSave(name)}>
              Save
            </UncontrolledLoadingButton>
          </DialogActions>
        </Box> */}
      </Box>
    </ModalUI>
  )
}

export default FavoriteModale
