import dayjs, { Dayjs } from "dayjs"
import { useMutation, useQuery, UseQueryOptions } from "react-query"
import { useHistory } from "react-router"

import { queryClient } from "../../.."
import { GetUserDto, UserApi } from "../openapi"

export const GET_USER_KEY = "getUser"
export const GET_AVAILABLE_CURRENCIES_KEY = "getAvailableCurrencies"
const GET_PRE_LOGIN_STATUS = "getPreLoginStatus"

export const userActions = {
  useUser: (config?: UseQueryOptions<GetUserDto>) => {
    return useQuery({
      queryKey: GET_USER_KEY,
      queryFn: () => UserApi.getUser(),
      useErrorBoundary: false,
      notifyOnChangeProps: ["data", "error"],
      retry: false,
      ...config,
    })
  },
  useAvailableCurrencies: () => {
    return useQuery([GET_AVAILABLE_CURRENCIES_KEY], () => UserApi.getAllAvailableFiats())
  },
  useGetPreLoginStatus: (email: string) => {
    return useQuery([GET_PRE_LOGIN_STATUS, email], () => UserApi.getPreLoginStatus({ email }), {})
  },
  useLazyGetPreLoginStatus: () => {
    return useMutation(UserApi.getPreLoginStatus)
  },
  useResendConfirmationEmail: () => {
    return useMutation(UserApi.resendConfirmationEmail)
  },
  useRegister: () => {
    return useMutation(UserApi.register)
  },
  useLogin: () => {
    return useMutation(UserApi.login, {
      onSuccess: () => {
        // localStorage.clear()
        queryClient.invalidateQueries(GET_USER_KEY)
      },
    })
  },
  useXeroPostRegister: () => {
    return useMutation(UserApi.xeroPostRegister, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_USER_KEY)
      },
    })
  },
  useLogout: () => {
    const history = useHistory()
    let fetchDate: Dayjs | undefined = undefined

    return useMutation(UserApi.logout, {
      onMutate: () => {
        fetchDate = dayjs()
      },
      onSuccess: () => {
        setTimeout(
          () => {
            localStorage.removeItem("workspaceId")
            queryClient.invalidateQueries(GET_USER_KEY).then(() => queryClient.clear())
            history.go(0)
          },
          fetchDate && fetchDate?.diff(dayjs(), "s", true) >= -1 ? 1000 : 0,
        )
      },
    })
  },
  useForgotPassword: () => {
    return useMutation(UserApi.forgotPassword)
  },
  useResetPassword: () => {
    return useMutation(UserApi.resetPassword)
  },
  useResetForgottenPassword: () => {
    return useMutation(UserApi.resetForgottenPassword)
  },
  useGenerate2fa: () => {
    return useMutation(UserApi.generate2faQRCode)
  },
  useConfirmActivation2fa: () => {
    return useMutation(UserApi.confirm2faActivation, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_USER_KEY)
      },
    })
  },
  useDelete2fa: () => {
    return useMutation(UserApi.delete2fa, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_USER_KEY)
      },
    })
  },
  useConfirmEmail: () => {
    return useMutation(UserApi.confirmEmail, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_USER_KEY)
      },
    })
  },
  useDeleteUser: () => {
    return useMutation(UserApi.deleteUser, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_USER_KEY)
      },
    })
  },
}
