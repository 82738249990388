import { Checkbox, Grid, IconButton, makeStyles, MenuItem, Popover, Theme, Typography } from "@material-ui/core"
import { ArrowDropDownRounded } from "@material-ui/icons"
import React, { useEffect, useMemo, useState } from "react"
import { IDataTableColumn } from "react-data-table-component"
import { UseQueryResult } from "react-query"

import { SelfPaginatedQuery } from "services/utils/types"

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    "minWidth": "0px",
    "padding": "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  mainCheckBox: {
    "color": `${theme.palette.primary.main} !important`,
    "paddingTop": "0px",
    "paddingBottom": "0px",
    "paddingRight": "0px",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  dropDown: {
    zIndex: 1,
  },
}))

export type SelectedRowsType =
  | {
      type: "individuals"
      itemIds: string[]
    }
  | {
      type: "filtered"
      excludedIds: string[]
    }

function useBulkSelector<T>(
  rows: UseQueryResult<SelfPaginatedQuery<T>, unknown>["data"] | undefined,
  nonMemoizedGetRowId: (row: T) => string,
) {
  const [selectedRows, setSelectedRows] = useState<SelectedRowsType | undefined>(undefined)

  // Without this Memo, the useBulkSelector rerenders the component everytime,
  // as `getRowId` is usually specified as an arrow function which is
  // different with every call
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getRowId = useMemo(() => nonMemoizedGetRowId, [])

  const { DropDownCheckBox, isPageSelected, onPageSelected, selectedRowCount } = useMemo(() => {
    const isPageSelected = (() => {
      if (rows === undefined) return false
      if (selectedRows?.type === "individuals") {
        // all transaction on this page are in the selected transaction list
        return rows.data.every((x) => selectedRows.itemIds.includes(getRowId(x)))
      } else if (selectedRows?.type === "filtered") {
        // all transaction on this page are NOT in the excluded transaction list
        return rows.data.every((x) => !selectedRows.excludedIds.includes(getRowId(x)))
      }
      return false
    })()

    const selectedRowCount = (() => {
      if (rows === undefined) return 0

      if (selectedRows?.type === "individuals") return selectedRows.itemIds.length
      else if (selectedRows?.type === "filtered") return rows.totalCount - selectedRows.excludedIds.length

      return 0
    })()
    // rows, selectedRows
    const onPageSelected = () => {
      if (rows === undefined) return

      if (selectedRows?.type === "individuals") {
        if (isPageSelected) {
          // remove all transaction ids from this page
          setSelectedRows({
            ...selectedRows,
            itemIds: selectedRows.itemIds.filter((x) => !rows.data.some((tx) => getRowId(tx) === x)),
          })
        } else {
          // add all transaction ids from this page
          setSelectedRows({
            ...selectedRows,
            itemIds: Array.from(new Set(selectedRows.itemIds.concat(rows.data.map((x) => getRowId(x))))),
          })
        }
      } else if (selectedRows?.type === "filtered") {
        if (isPageSelected) {
          // add transaction to excluded list
          setSelectedRows({
            ...selectedRows,
            excludedIds: Array.from(new Set(selectedRows.excludedIds.concat(rows.data.map((x) => getRowId(x))))),
          })
        } else {
          // remove transaction from excluded list
          setSelectedRows({
            ...selectedRows,
            excludedIds: selectedRows.excludedIds.filter((x) => !rows.data.some((tx) => getRowId(tx) === x)),
          })
        }
      } else {
        // create a new selection list
        setSelectedRows({
          type: "individuals",
          itemIds: rows.data.map((x) => getRowId(x)),
        })
      }
    }

    // selectedRows, rows
    const DropDownCheckBox = () => {
      const classes = useStyles()
      const options = [
        {
          name: "Select all",
          action: () =>
            setSelectedRows({
              type: "filtered",
              excludedIds: [],
            }),
        },
        {
          name: "Unselect all",
          action: () => setSelectedRows(undefined),
        },
      ]
      const [open, setOpen] = React.useState(false)
      const anchorRef = React.useRef<HTMLDivElement>(null)

      const handleMenuItemClick = (_event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        options[index].action()
        setOpen(false)
      }

      const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
      }

      const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current?.contains(event.target as HTMLDivElement)) {
          return
        }

        setOpen(false)
      }

      return (
        <Grid item>
          <Grid ref={anchorRef} container spacing={3}>
            <Grid item xs={6}>
              <IconButton className={classes.iconButton}>
                <Checkbox
                  color="primary"
                  checked={isPageSelected}
                  onChange={onPageSelected}
                  className={classes.mainCheckBox}
                />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton onClick={handleToggle} className={classes.iconButton}>
                <ArrowDropDownRounded />
              </IconButton>
            </Grid>
          </Grid>
          <Popover
            anchorEl={anchorRef.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={open}
            onClose={handleClose}
            className={classes.dropDown}
          >
            {options.map((option, index) => (
              <MenuItem key={index} onClick={(event) => handleMenuItemClick(event, index)}>
                <Typography>{option.name}</Typography>
              </MenuItem>
            ))}
          </Popover>
        </Grid>
      )
    }

    return {
      isPageSelected,
      selectedRowCount,
      onPageSelected,
      DropDownCheckBox,
    }
  }, [selectedRows, getRowId, rows])

  const bulkSelectorColumn: IDataTableColumn<T> = useMemo(() => {
    const isRowSelected = (id: string): boolean => {
      if (selectedRows?.type === "individuals") return selectedRows.itemIds.includes(id)
      else if (selectedRows?.type === "filtered") return !selectedRows.excludedIds.includes(id)
      return false
    }

    const onRowSelected = (selectedTransaction: T) => {
      const isSelected = isRowSelected(getRowId(selectedTransaction))

      if (selectedRows?.type === "individuals") {
        if (isSelected) {
          // remove row from selected list
          setSelectedRows({
            ...selectedRows,
            itemIds: selectedRows.itemIds.filter((x) => x !== getRowId(selectedTransaction)),
          })
        } else {
          // add row to selected list
          setSelectedRows({
            ...selectedRows,
            itemIds: Array.from(new Set(selectedRows.itemIds.concat([getRowId(selectedTransaction)]))),
          })
        }
      } else if (selectedRows?.type === "filtered") {
        if (isSelected) {
          // add transaction to excluded list
          setSelectedRows({
            ...selectedRows,
            excludedIds: Array.from(new Set(selectedRows.excludedIds.concat([getRowId(selectedTransaction)]))),
          })
        } else {
          // remove transaction from excluded list
          setSelectedRows({
            ...selectedRows,
            excludedIds: selectedRows.excludedIds.filter((x) => x !== getRowId(selectedTransaction)),
          })
        }
      } else {
        // create a new selection list
        setSelectedRows({
          type: "individuals",
          itemIds: [getRowId(selectedTransaction)],
        })
      }
    }

    return {
      name: <DropDownCheckBox />,
      selector: "checkbox",
      width: "64px",
      cell: function formatX(row) {
        return <Checkbox checked={isRowSelected(getRowId(row))} onChange={() => onRowSelected(row)} color="primary" />
      },
    }
  }, [selectedRows, DropDownCheckBox, getRowId])

  useEffect(() => {
    if (selectedRows === undefined || !rows) return

    // if the user deselected all rows in individual mode, remove selection
    if (selectedRows.type === "individuals" && selectedRows.itemIds.length === 0) setSelectedRows(undefined)

    // if the user excluded all rows in filtered mode, remove selection
    if (selectedRows.type === "filtered" && selectedRows.excludedIds.length === rows.totalCount)
      setSelectedRows(undefined)

    // If the user manually selected all pages like a dumb ass, move to filtered
    if (selectedRows.type === "individuals" && selectedRows.itemIds.length === rows.totalCount) {
      setSelectedRows({
        type: "filtered",
        excludedIds: [],
      })
    }
  }, [selectedRows, rows])

  return {
    selectedRows,
    setSelectedRows,
    isPageSelected,
    selectedRowCount,
    onPageSelected,
    bulkSelectorColumn,
  }
}

export default useBulkSelector
