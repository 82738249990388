import { useTypedController } from "@hookform/strictly-typed"
import { Box, makeStyles, TextField, Theme, Typography } from "@material-ui/core"
import { CheckBoxRounded, CloseRounded } from "@material-ui/icons"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { UncontrolledLoadingButton, useLoadingButton } from "components/LoadingButton"
import { GainColor, LossColor } from "../../materialTheme"
import api from "services/api"
import { GetUserDto } from "services/api/openapi/models"
import SettingsCategory from "./SettingsCategory"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface Activate2FaForm {
  code2Fa: string
}

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontSize: "1.2em",
  },
  enabled2faIcon: {
    color: GainColor,
    marginRight: theme.spacing(1),
  },
  disabled2faIcon: {
    color: LossColor,
    marginRight: theme.spacing(1),
  },
}))

const TwoFactorAuthSettings = ({ user }: { user: GetUserDto }): JSX.Element => {
  const classes = useStyles()
  const { control, handleSubmit, formState } = useForm<Activate2FaForm>({ mode: "onChange" })
  const [urlCode, setUrlCode] = useState<string | null>(null)
  const TypedController = useTypedController<Activate2FaForm>({ control })
  const toast = useToast()

  const { mutateAsync: generate2faMutation } = api.user.useGenerate2fa()
  const { mutateAsync: delete2faMutation } = api.user.useDelete2fa()
  const { mutateAsync: confirmActivation2fa } = api.user.useConfirmActivation2fa()

  const generate2faQRCode = async () => {
    try {
      const res = await generate2faMutation()

      setUrlCode(res?.url || null)
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const [Toggle2faButton, handleButtonCallback] = useLoadingButton()
  const submit2faActivation = async (form: Activate2FaForm) => {
    try {
      if (user.is2Fa) {
        await delete2faMutation({ confirm2faActivationDto: form })
      } else {
        await confirmActivation2fa({ confirm2faActivationDto: form })
        setUrlCode(null)
      }
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  // TODO There is some code duplication that could be avoided

  if (!user.is2Fa && !urlCode) {
    return (
      <SettingsCategory title="2FA" description="Enable or disable 2FA on your account.">
        <Box display="flex" mb={2}>
          <CloseRounded className={classes.disabled2faIcon} />
          <Typography>2FA is not enabled for this account.</Typography>
        </Box>
        <UncontrolledLoadingButton className={classes.submitButton} onClick={generate2faQRCode}>
          Activate Two-Auth Authentication
        </UncontrolledLoadingButton>
      </SettingsCategory>
    )
  }

  const description = user.is2Fa
    ? "Please input the code provided by the Authenticator application to disable 2FA."
    : "Please use the Authenticator application to scan the QR Code. Then input the code to enable 2FA."

  return (
    <SettingsCategory title="2FA" description={description}>
      {user.is2Fa ? (
        <Box display="flex" alignItems="center" mb={2}>
          <CheckBoxRounded className={classes.enabled2faIcon} />
          <Typography>2FA is enabled for this account.</Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" mb={2}>
          <CloseRounded className={classes.disabled2faIcon} />
          <Typography>2FA is not enabled for this account.</Typography>
        </Box>
      )}

      <Box
        component="form"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        onSubmit={handleButtonCallback(handleSubmit(submit2faActivation))}
      >
        {urlCode && <img id="img_qrCode" src={urlCode} alt="img_qrCode" width="250px" height="250px" />}
        <DrawerFormSection htmlFor="code2fa-textfield" name="Code">
          <TypedController
            name="code2Fa"
            defaultValue={""}
            rules={{
              required: true,
              minLength: 6,
              maxLength: 6,
            }}
            render={(props) => (
              <TextField
                id="code2fa-textfield"
                type="code2Fa"
                {...props}
                error={!!formState.errors.code2Fa}
                placeholder="Input the code here"
              />
            )}
          />
        </DrawerFormSection>

        <Box mt={3} />

        <Toggle2faButton className={classes.submitButton} type="submit" disabled={!formState.isValid}>
          {user.is2Fa ? "Disable 2FA" : "Enable 2FA"}
        </Toggle2faButton>
      </Box>
    </SettingsCategory>
  )
}

export default TwoFactorAuthSettings
