import React from "react"
import { DefiAssetDto } from "services/api/openapi"

import BigNumber from "bignumber.js"
import numbro from "numbro"
import { BoldWeight, TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import TooltipUI from "CryptioUI/Tooltip"

interface AssetDeFiProps {
  assets: DefiAssetDto[]
  totalValueUsd: string
  left?: boolean
  color?: string
}

const AssetDeFi = (props: AssetDeFiProps) => {
  const { assets, totalValueUsd, left = false, color = "text-grey-500" } = props
  const totalValue = new BigNumber(totalValueUsd)
  const totalValueRounded = totalValue.decimalPlaces(2, BigNumber.ROUND_HALF_UP)
  const prettyNumber = numbro(totalValueRounded?.toString()).format({
    thousandSeparated: true,
  })
  const position = left ? "start" : "end"
  const size = position === "end" ? "w-[400px] max-w-[400px]" : "w-[180px] max-w-[180px]"
  const wrappredSize = position === "end" ? "w-[380px] max-w-[380px]" : "w-[160px] max-w-[160px]"
  const assetsParsed = assets
    .map((asset, idx) => {
      const assetVolume = new BigNumber(asset.volume).decimalPlaces(2, BigNumber.ROUND_HALF_UP)
      const prettVolume = numbro(assetVolume?.toString()).format({
        thousandSeparated: true,
      })

      if (idx !== 0) return ` / ${prettVolume} ${asset.assetSymbol}`
      return `${prettVolume} ${asset.assetSymbol}`
    })
    .join("")

  const TotalValueUSD = () => {
    if (totalValue.toString() === "0") {
      return (
        <TypographyUI className="text-grey-400" variant={TypographyVariant.BODY}>
          - -
        </TypographyUI>
      )
    }
    if (totalValue.absoluteValue().isLessThan(0.1)) {
      return (
        <TooltipUI
          side="bottom"
          alignment="end"
          content={`$ ${numbro(totalValue?.toString()).format({
            thousandSeparated: true,
          })}`}
        >
          <div>
            <TypographyUI variant={TypographyVariant.BODY} boldness={BoldWeight.REGULAR} className={`${color}`}>
              {"< $ 0.1"}
            </TypographyUI>
          </div>
        </TooltipUI>
      )
    }
    return (
      <div
        className={`${wrappredSize} overflow-hidden whitespace-nowrap text-ellipsis flex justify-${position}`}
        data-tag="allowRowEvents"
      >
        <TooltipUI side="bottom" alignment="end" content={`$ ${prettyNumber}`}>
          <div>
            <TypographyUI
              variant={TypographyVariant.BUTTON}
              boldness={BoldWeight.REGULAR}
              className={`${color}`}
            >{`$ ${prettyNumber}`}</TypographyUI>
          </div>
        </TooltipUI>
      </div>
    )
  }

  return (
    <div className={`flex flex-col ${size} items-${position}`} data-tag="allowRowEvents">
      <TotalValueUSD />
      <div
        className={`${wrappredSize} overflow-hidden whitespace-nowrap text-ellipsis flex justify-${position}`}
        data-tag="allowRowEvents"
      >
        <TooltipUI side="bottom" alignment="end" content={assetsParsed}>
          <div>
            <TypographyUI className="text-grey-400" variant={TypographyVariant.CAPTION}>
              {totalValue.toString() !== "0" && assetsParsed}
            </TypographyUI>
          </div>
        </TooltipUI>
      </div>
    </div>
  )
}

export default AssetDeFi
