import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import BigNumber from "bignumber.js"
import React from "react"

import { SubscriptionPayingDetailsPeriodTypeEnum } from "services/api/openapi"
import { limitTypes } from "./constants"
import { ExtendedLimitAddon } from "./PricingCalculator"

interface Props {
  addon: ExtendedLimitAddon
  showOnlyPeriodPrice?: SubscriptionPayingDetailsPeriodTypeEnum
}

const LimitAddonDetailedViewer = ({ addon, showOnlyPeriodPrice }: Props): JSX.Element => {
  const limitType = limitTypes.find((x) => x.type === addon.limitType)
  if (!limitType) throw new Error("should not happend")

  return (
    <Box>
      <Typography variant="h5">{limitType.name}</Typography>
      {addon.pricing.pricingType === "standard" && (
        <>
          <Typography>Standard pricing</Typography>
          {showOnlyPeriodPrice !== "yearly" && (
            <Typography variant="subtitle1">
              ${new BigNumber(addon.pricing.usdPricePerMonth).toFixed(2)} USD / Month per unit
            </Typography>
          )}
          {showOnlyPeriodPrice !== "monthly" && (
            <Typography variant="subtitle1">
              ${new BigNumber(addon.pricing.usdPricePerYear).toFixed(2)} USD / Year per unit
            </Typography>
          )}
        </>
      )}
      {addon.pricing.pricingType === "package" && (
        <>
          <Typography>Package pricing</Typography>
          {showOnlyPeriodPrice !== "yearly" && (
            <Typography variant="subtitle1">
              ${new BigNumber(addon.pricing.usdPricePerMonth).toFixed(2)} USD / Month per {addon.pricing.perUnit} unit
            </Typography>
          )}
          {showOnlyPeriodPrice !== "monthly" && (
            <Typography variant="subtitle1">
              ${new BigNumber(addon.pricing.usdPricePerYear).toFixed(2)} USD / Year per {addon.pricing.perUnit} unit
            </Typography>
          )}
        </>
      )}
      {addon.pricing.pricingType === "volume" && (
        <>
          <Typography>Volume pricing</Typography>
          <Box maxWidth="600px">
            <TableContainer component={Paper}>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Quantity</TableCell>
                    {showOnlyPeriodPrice !== "yearly" && <TableCell align="right">USD / Month per unit</TableCell>}
                    {showOnlyPeriodPrice !== "monthly" && <TableCell align="right">USD / Year per unit</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addon.pricing.prices.map((price, idx) => (
                    <TableRow key={idx}>
                      {price.min === 1 && (
                        <TableCell align="left">
                          For the first {price.min} to {price.max === null ? "Infinity" : price.max}
                        </TableCell>
                      )}
                      {price.min > 1 && price.max !== null && (
                        <TableCell align="left">
                          For the next {price.min} to {price.max}
                        </TableCell>
                      )}
                      {price.min > 1 && price.max === null && <TableCell align="left">For the rest</TableCell>}
                      {showOnlyPeriodPrice !== "yearly" && (
                        <TableCell align="right">${new BigNumber(price.usdPricePerMonth).toFixed(2)}</TableCell>
                      )}
                      {showOnlyPeriodPrice !== "monthly" && (
                        <TableCell align="right">${new BigNumber(price.usdPricePerYear).toFixed(2)}</TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
      {addon.pricing.pricingType === "graduated" && (
        <>
          <Typography>Graduated Pricing</Typography>
          <Box maxWidth="600px">
            <TableContainer component={Paper}>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Quantity</TableCell>
                    {showOnlyPeriodPrice !== "yearly" && <TableCell align="right">USD / Month per unit</TableCell>}
                    {showOnlyPeriodPrice !== "monthly" && <TableCell align="right">USD / Year per unit</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addon.pricing.prices.map((price, idx) => (
                    <TableRow key={idx}>
                      {price.min === 1 && (
                        <TableCell align="left">
                          For the first {price.min} to {price.max === null ? "Infinity" : price.max}
                        </TableCell>
                      )}
                      {price.min > 1 && price.max !== null && (
                        <TableCell align="left">
                          For the next {price.min + 1} to {price.max}
                        </TableCell>
                      )}
                      {price.min > 1 && price.max === null && <TableCell align="left">For the rest</TableCell>}
                      {showOnlyPeriodPrice !== "yearly" && (
                        <TableCell align="right">${new BigNumber(price.usdPricePerMonth).toFixed(2)}</TableCell>
                      )}
                      {showOnlyPeriodPrice !== "monthly" && (
                        <TableCell align="right">${new BigNumber(price.usdPricePerYear).toFixed(2)}</TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
      <Box mt={1}>
        <Typography variant="body2">For {addon.additionalRequired} additionals</Typography>
        {showOnlyPeriodPrice !== "yearly" && (
          <Typography variant="subtitle1">${addon.usdPricePerMonth.toFixed(2)} USD / Month</Typography>
        )}
        {showOnlyPeriodPrice !== "monthly" && (
          <Typography variant="subtitle1">${addon.usdPricePerYear.toFixed(2)} USD / Year</Typography>
        )}
      </Box>
    </Box>
  )
}

export default LimitAddonDetailedViewer
