import { queryClient } from "../../.."
import {
  usePaginatedWorkspaceQuery,
  useWorkspaceMutation,
  WorkspaceContextValue,
  WorkspacePrefix,
} from "../../context/workspaceContext"
import { WithoutWorkspaceId } from "../aliases"
import rest from "../http"
import { CreateInvoiceDto, GetInvoiceDto, GetInvoiceSignedUrlDto, GetInvoicesRequest, InvoicesApi } from "../openapi"
import { PaginationQuery } from "../pagination"
import { GET_TRANSACTION_KEY } from "./transactions"

export const GET_INVOICES_KEY = "getInvoices"

type GetInvoicesVariables = GetInvoicesRequest & PaginationQuery

type InvoiceWithFile = CreateInvoiceDto & { file: File | null }
const internalCreateInvoice = async ({
  file,
  ...variables
}: InvoiceWithFile & WorkspacePrefix): Promise<GetInvoiceDto> => {
  const invoice = await rest.post<GetInvoiceDto>(`/workspaces/${variables.workspaceId}/invoices`, variables)

  if (file) {
    const formData = new FormData()
    formData.append("file", file, file.name)

    await rest.uploadFile<void>(
      `/workspaces/${variables.workspaceId}/invoices/${invoice.id}/upload`,
      "POST",
      formData,
      true,
    )
  }

  return invoice
}

// const internalUpdateInvoices = async (
//   variables: UpdateInvoice & WorkspacePrefixer,
// ) => {
//   await rest.put<void>(`${variables.workspaceUrlPrefix}/invoices`, variables, true)
// }

export const invoicesActions = {
  useInvoices: (variables: WithoutWorkspaceId<GetInvoicesVariables>) => {
    return usePaginatedWorkspaceQuery([GET_INVOICES_KEY, variables], InvoicesApi.getInvoices, variables)
  },
  useCreateInvoices: () => {
    return useWorkspaceMutation(internalCreateInvoice, {
      onSuccess: () => {
        // More invoice
        queryClient.invalidateQueries(GET_INVOICES_KEY)
      },
    })
  },
  useDeleteInvoices: () => {
    return useWorkspaceMutation(InvoicesApi.deleteInvoice, {
      onSuccess: () => {
        // Unmap invoice from transaction(s)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)

        // Less invoice
        queryClient.invalidateQueries(GET_INVOICES_KEY)
      },
    })
  },
  getSignedUrl: (workspaceCtx: WorkspaceContextValue, invoiceId: string): Promise<GetInvoiceSignedUrlDto> => {
    return InvoicesApi.downloadInvoice({
      invoiceId,
      workspaceId: workspaceCtx.workspace.id,
    })
  },

  // useUpdateInvoices: () => {
  //   const workspaceCtx = useContext(WorkspaceContext)
  //   return useMutation(
  //     insertWorkspacePrefixer(workspaceCtx, internalUpdateInvoices),
  //     {
  //       onSuccess: () => {
  //         queryClient.invalidateQueries(GET_INVOICES_KEY)
  //       },
  //     },
  //   )
  // },
}
