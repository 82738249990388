import { Box, Typography } from "@material-ui/core"
import { toastCatch } from "components/ReactHookForm/utils"
import { useToast } from "CryptioUI/Toaster"
import React, { useContext } from "react"

import { DrawerCategory } from "../../../../components/Drawer/DrawerItems"
import { UncontrolledLoadingButton } from "../../../../components/LoadingButton"
import useDialog from "../../../../components/misc/useDialog"
import { DrawerProp } from "../../../../components/misc/useDrawer"
import api from "../../../../services/api"
import { WorkspaceAccountingIntegrationDto } from "../../../../services/api/aliases"
import { NewFullTransactionDto } from "../../../../services/api/openapi/models"
import { WorkspaceContext } from "../../../../services/context/workspaceContext"
import ConditionalTooltip from "../../../ConditionalTooltip"
import ContactUsInfo from "./ContactUsInfo"
import SynchronizationDate from "./SynchronizationDate"
import SynchronizationStatus from "./SynchronizationStatus"
import ToggleManuallySyncedButton from "./ToggleManuallySyncedButton"

export interface TransactionSynchronizationSubcomponentsProps {
  accountingIntegration: WorkspaceAccountingIntegrationDto
  transaction: NewFullTransactionDto
  isBookable: boolean
  isConsolidated: boolean
  isCustomIntegration: boolean
}

const TransactionSynchronization = (props: DrawerProp<NewFullTransactionDto, false>) => {
  const { item: transaction } = props
  const workspaceCtx = useContext(WorkspaceContext)
  const basicDialog = useDialog()
  const toast = useToast()

  const { mutateAsync: bulkUpdateMovementsMutation } = api.transaction.useBulkUpdateMovements()

  const syncTransactionsToAccountingSystem = async () => {
    try {
      await bulkUpdateMovementsMutation({
        movementBulkUpdateDto: {
          individualTransactions: { transactionIds: [transaction.id] },
          synchronizationAction: "syncToAccountingSystem",
        },
      })

      toast.open("Syncing one transaction...", { variant: "default" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const askSyncTransactionToAccountingSystem = () => {
    if (props.isFormDirty) {
      basicDialog.showDialog({
        title: "This transaction has changed. Are you sure?",
        content: (
          <Typography variant="h5">
            Are you sure you want to synchronize this transaction without saving your modifications first? You will lose
            any modification made to the transaction.
          </Typography>
        ),
        yesText: "Synchronize",
        noText: "Cancel",
        onAccept: () => syncTransactionsToAccountingSystem(),
      })
    } else syncTransactionsToAccountingSystem()
  }

  if (workspaceCtx.workspace.accountingIntegration === null) return <></>

  const isBookable = transaction.isBookable
  const isConsolidated = transaction.accountingJournal?.isConsolidated ?? false
  const isCustomIntegration = workspaceCtx.workspace.accountingIntegration.name === "custom"

  const subcomponentsProps: TransactionSynchronizationSubcomponentsProps = {
    accountingIntegration: workspaceCtx.workspace.accountingIntegration,
    transaction,
    isBookable,
    isConsolidated,
    isCustomIntegration,
  }

  return (
    <DrawerCategory title="Accounting system">
      <Box mt={2} mb={2}>
        <SynchronizationDate {...subcomponentsProps} />
        <SynchronizationStatus {...subcomponentsProps} />
      </Box>

      {isConsolidated && (
        <ContactUsInfo
          helpElement={
            <Typography>
              Some parts of the transaction have been booked independently in the past. Since then, the transaction has
              been consolidated so the entire transaction can&apos;t be automatically synchronized.
            </Typography>
          }
        />
      )}

      {!isConsolidated &&
        !isCustomIntegration &&
        (isBookable ? (
          <Box>
            <ConditionalTooltip
              tooltipMessage="There is an error with your accounting integration, you must reconnect the integration to be able to synchronize a transaction"
              disabled={workspaceCtx.workspace.accountingIntegration.connectionErrorAt !== null}
            >
              <UncontrolledLoadingButton
                disabled={workspaceCtx.workspace.accountingIntegration.connectionErrorAt !== null}
                onClick={() => askSyncTransactionToAccountingSystem()}
              >
                Synchronize transaction
              </UncontrolledLoadingButton>
            </ConditionalTooltip>
          </Box>
        ) : (
          <Box>
            <ContactUsInfo
              helpElement={
                <Typography>
                  This transaction is <i>complex</i> and can not be currently booked into an accounting system and will
                  be ignored. Please reach out to us if you need to book this transaction.
                </Typography>
              }
            />
          </Box>
        ))}

      {!isConsolidated && (!isBookable || isCustomIntegration) && (
        <ToggleManuallySyncedButton {...subcomponentsProps} />
      )}

      {/* TODO: we don't keep this information right now */}
      {/* {transaction.accountingJournal.status === "synced" &&
            transaction.accountingJournal.synchronizedAt &&
            dayjs(transaction.accountingJournal.mappingUpdatedAt).isAfter(transaction.accountingJournal.synchronizedAt) && (
              <Box display="flex" mt={2}>
                <WarningRounded />
                <Box mr={1} />
                <Typography variant="body1">
                  Transaction mappings changed since the last synchronization with{" "}
                  {prettyAccountingIntegrationNameMap[workspaceCtx.workspace.accountingIntegration.name]}.
                </Typography>
              </Box>
            )} */}
    </DrawerCategory>
  )
}

export default TransactionSynchronization
