const scriptStatusKey = Symbol("scriptMapKey")

type ScriptLoadingStatus = undefined | Promise<void> | "loaded" | "error"
type ScriptLoadingStatusMap = Record<string, ScriptLoadingStatus>

declare global {
  interface Window {
    // The interface of the global window object is modified to contain a list
    // of all scripts loaded and their loading status.
    // As the key is a symbol, no conflict with existing global objects can occur.
    [scriptStatusKey]: ScriptLoadingStatusMap | undefined
  }
}

// This function loads a script in the page by its source (URI).
//
// The `onLoad` callback is called once after the script finished loading.
//
// It handles the loading errors, and return a promise which completes when the
// script is available.
//
// The same script cannot be loaded multiple times by accident as the function
// maintains a list of every script loaded. Therefore this function can be
// called anywhere in the code as many times as needed each time the
// availability of a script is needed.
const loadDynamicScript = (src: string, defer: boolean, id?: string, onLoad?: () => void): Promise<void> => {
  const scriptStatusMap = (window[scriptStatusKey] = window[scriptStatusKey] || {})
  const scriptStatus = scriptStatusMap[src]

  if (scriptStatus === undefined) {
    const result = new Promise<void>((res, err) => {
      const script = document.createElement("script")
      script.onload = () => {
        if (onLoad !== undefined) onLoad()
        scriptStatusMap[src] = "loaded"
        res()
      }

      script.onerror = () => {
        scriptStatusMap[src] = "error"
        err(new Error(`Could not load script ${src}`))
      }

      script.async = true
      script.defer = defer

      document.head.appendChild(script)
      script.src = src

      if (id !== undefined) {
        script.id = id
      }
    })

    scriptStatusMap[src] = result
    return result
  } else if (scriptStatus === "loaded") {
    return Promise.resolve()
  } else if (scriptStatus === "error") {
    return Promise.reject(new Error(`Could not load script ${src}`))
  } else {
    return scriptStatus
  }
}

// When a third party provide a script, their documentation usually provide a
// premade code. For example this Google Tag one :
//
// ```html
// <!-- Global site tag (gtag.js) -->
// <script async src="https://www.googletagmanager.com/gtag/js?id=XXXXXX"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());
//   gtag('config', 'XXXXXX');
// </script>
// ```
//
// This code is separated in two parts :
// - Loading the script whose source is
//   "https://www.googletagmanager.com/gtag/js?id=XXXXXX"
// - Initializing the script, and preparing it for further use.
//
// After executing this script, the user can interact with the third-party
// script by accessing a global variable, in this case `gtag`.
//
// This function encapsulates this use case and allows loading third-party
// script and accessing them on the fly.
//
// The `src` argument is the source of the script,
// for example "https://www.googletagmanager.com/gtag/js?id=XXXXXX"
//
// The `globalName` is the name of the global variable used to access the
// script, for example "gtag".
//
// The type parameter T is the type of this global variable. You can often find
// these types already made on npm.
//
// The `onLoad` callback is executed only once, just after the script was loaded
// for the first time. It is used to put the initialization code provided by the
// third party. For example :
//
// ```js
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());
//   gtag('config', 'XXXXXX');
// ```
export async function getScript<T>(
  src: string,
  globalName: string,
  defer: boolean = false,
  id?: string,
  onLoad?: () => void,
): Promise<T> {
  await loadDynamicScript(src, defer, id, onLoad)
  if (globalName in window) {
    return (window as unknown as Record<string, T>)[globalName]
  } else {
    throw new Error(`The global ${globalName} does not exist in script ${src}`)
  }
}
