import { Theme } from "@material-ui/core"
import { CachedRounded } from "@material-ui/icons"
import { createStyles, makeStyles } from "@material-ui/styles"
import React from "react"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@keyframes rotation": {
      from: {
        transform: "rotate(359deg)",
      },
      to: {
        transform: "rotate(0deg)",
      },
    },
    "rotate": {
      animation: "$rotation 2s infinite linear",
      marginRight: theme.spacing(0.5),
      color: "white",
    },
  }),
)

const Spinner = (): JSX.Element => {
  const classes = useStyles()
  return <CachedRounded className={classes.rotate} />
}

export default Spinner
