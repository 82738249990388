import React, { useEffect } from "react"
import { useHistory, useParams } from "react-router"

import LoadingSpinner from "components/misc/LoadingSpinner"
import { URLS } from "../../routes"
import api from "services/api"

const UrlShortenerRedirectScene = () => {
  const { urlId } = useParams<{ urlId: string }>()
  const history = useHistory()

  const url = api.urlShortener.useShortenedUrl(urlId)

  useEffect(() => {
    if (!url.data) return
    history.replace(url.data.url)
  }, [url.data, history])

  useEffect(() => {
    if (!url.isError) return
    history.replace(URLS.Portfolio)
  }, [url.isError, history])

  return <LoadingSpinner />
}

export default UrlShortenerRedirectScene
