import { Checkbox, makeStyles } from "@material-ui/core"
import CircleChecked from "@material-ui/icons/RadioButtonChecked"
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked"
import React from "react"
import { MovementCounterPartyDto } from "services/api/openapi"

const useStyles = makeStyles(() => ({
  containerCellCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "5em",
    width: "5em",
    minWidth: "5em",
  },
}))

interface CheckboxCellInternalTransferProps {
  counterParty: MovementCounterPartyDto
  setSelectedGuessedOtherParty: (newCounterParty: MovementCounterPartyDto | null) => void
  selectedGuessedOtherParty: MovementCounterPartyDto | null
}

const CheckboxCellInternalTransfer = (props: CheckboxCellInternalTransferProps) => {
  const classes = useStyles()
  const { counterParty, setSelectedGuessedOtherParty, selectedGuessedOtherParty } = props

  const isChecked = (): boolean => {
    if (!selectedGuessedOtherParty) return false
    if (selectedGuessedOtherParty.id === counterParty.id) return true
    return false
  }

  const onRowSelected = () => {
    if (isChecked()) {
      setSelectedGuessedOtherParty(null)
    } else {
      setSelectedGuessedOtherParty(counterParty)
    }
  }

  return (
    <div className={classes.containerCellCheckbox}>
      <Checkbox
        color="primary"
        checked={isChecked()}
        icon={<CircleUnchecked />}
        checkedIcon={<CircleChecked color={"primary"} />}
        onChange={onRowSelected}
      />
    </div>
  )
}

export default CheckboxCellInternalTransfer
