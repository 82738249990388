import { Box, IconButton, Paper, Typography } from "@material-ui/core"
import { AddRounded, FileCopyRounded, VisibilityOffRounded, VisibilityRounded } from "@material-ui/icons"
import React, { useCallback, useMemo, useState } from "react"

import BaseContainer from "components/Container"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import useDrawer from "components/misc/useDrawer"
import { GenericSorting, IExtendedDataTableColumn } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import TableTitleWithTotalCount from "components/Table/TableTitleWithTotalCount"
import ButtonUI from "CryptioUI/Button"
import api from "services/api"
import {
  BackOfficeGetFullPlanDto,
  BackOfficeGetMinimalPlanDto,
  BackOfficeGetPlansRequest,
  BackOfficeGetPlansSortByEnum,
} from "services/api/openapi"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import BackOfficeBillingDialog, { BackOfficeBillingPlanForm } from "./BackOfficeBillingDialog"
import BackOfficeBillingFilter from "./filters/BackOfficeBillingFilter"
import TooltipUI from "CryptioUI/Tooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const defaultBackOfficeBillingSorting: GenericSorting<BackOfficeGetPlansSortByEnum> = {
  sortDirection: "descending",
  sortBy: "created_at",
}

export type BackOfficeBillingParameters = Omit<
  BackOfficeGetPlansRequest,
  "minimumActiveSubscription" | "minimumAllTimeSubscription"
> & {
  minimumActiveSubscription: string | undefined
  minimumAllTimeSubscription: string | undefined
}

export const defaultBackOfficeGetPlanParams: BackOfficeBillingParameters = {
  page: 0,
  limit: 10,
  planId: "",
  query: "",
  minimumActiveSubscription: undefined,
  minimumAllTimeSubscription: undefined,
}

export const backOfficeBillingHasAnyFilter = (filters: Partial<BackOfficeBillingParameters>) =>
  Boolean(filters.planId || filters.query || filters.minimumActiveSubscription || filters.minimumAllTimeSubscription)

const BackOfficeBillingScene = (): JSX.Element => {
  const [dialogData, setDialogData] = useState<{ data: BackOfficeBillingPlanForm | null } | null>(null)
  const [planDrawer, openPlanDrawer] = useDrawer("backoffice-plan")
  const { params, setLimit, setPage, setSorting, setParams } = usePaginatedParams<
    Partial<BackOfficeBillingParameters>,
    BackOfficeGetPlansSortByEnum
  >(defaultBackOfficeGetPlanParams, defaultBackOfficeBillingSorting)
  const basicDialog = useDialog()

  const toast = useToast()
  const workspaces = api.backOffice.billing.usePlans({
    ...params,
    planId: params.planId || undefined,
    minimumActiveSubscription: params.minimumActiveSubscription ? Number(params.minimumActiveSubscription) : undefined,
    minimumAllTimeSubscription: params.minimumAllTimeSubscription
      ? Number(params.minimumAllTimeSubscription)
      : undefined,
  })
  const { mutateAsync: togglePlanVisibilityMutation } = api.backOffice.billing.useTogglePlanVisibility()

  const askToggleVisibility = useCallback(
    (plan: BackOfficeGetFullPlanDto) => {
      const doToggleVisibility = async (plan: BackOfficeGetFullPlanDto) => {
        try {
          await togglePlanVisibilityMutation({ planId: plan.id })
          toast.open(`Plan is now ${plan.isPublic ? "hidden" : "visible"}`, { variant: "success" })
        } catch (e) {
          toastCatch(e, toast)
        }
      }

      basicDialog.showDialog({
        title: "Are you sure?",
        content: (
          <Typography variant="h5">
            Do you really want to {plan.isPublic ? "hide the plan from" : "show the plan to"} the users?
          </Typography>
        ),
        yesText: "Yes",
        noText: "Cancel",
        onAccept: () => doToggleVisibility(plan),
      })
    },
    [basicDialog, toast, togglePlanVisibilityMutation],
  )

  const openDialog = (data?: BackOfficeBillingPlanForm) => {
    setDialogData({ data: data ?? null })
  }
  const closeDialog = () => {
    setDialogData(null)
  }

  const columns = useMemo<IExtendedDataTableColumn<BackOfficeGetMinimalPlanDto, BackOfficeGetPlansSortByEnum>[]>(
    () => [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        sortName: "name",
      },
      {
        name: "Per month ($)",
        selector: "usdPricePerMonth",
        sortable: true,
        sortName: "usd_price_per_month",
      },
      {
        name: "Per year ($)",
        selector: "usdPricePerYear",
        sortable: true,
        sortName: "usd_price_per_year",
      },
      {
        name: "Visibility",
        selector: "isPublic",
        cell: function Format(row) {
          return (
            <TooltipUI
              content={
                row.isPublic
                  ? "This subscription is public. Users can subscribe to this subscription."
                  : "This subscription is not public. Users can not subscribe to this subscription on their own."
              }
            >
              <IconButton onClick={() => askToggleVisibility(row)}>
                {row.isPublic ? <VisibilityRounded /> : <VisibilityOffRounded />}
              </IconButton>
            </TooltipUI>
          )
        },
      },
      {
        name: "Active workspaces",
        selector: "activeSubscriptionCount",
        sortable: true,
        sortName: "active_subscription_count",
      },
      {
        name: "All workspaces",
        selector: "allTimeSubscriptionCount",
        sortable: true,
        sortName: "all_time_subscription_count",
      },
      {
        name: "Clone",
        cell: function Format(row) {
          return (
            <TooltipUI content="Click to create a new subscription based on this one.">
              <IconButton
                onClick={() =>
                  openDialog({
                    name: row.name,
                    description: row.description,
                    usdPricePer: {
                      month: row.usdPricePerMonth.toString(),
                      year: row.usdPricePerYear.toString(),
                    },
                    hardLimits: row.hardLimits.reduce((limits, limit) => {
                      limits[limit.limitType] = limit.maximum.toString()
                      return limits
                    }, {} as BackOfficeBillingPlanForm["hardLimits"]),
                  })
                }
              >
                <FileCopyRounded />
              </IconButton>
            </TooltipUI>
          )
        },
      },
    ],
    [askToggleVisibility],
  )

  if (workspaces.isError) return <NetworkErrorMessage additionalData={workspaces} />

  if (workspaces.isLoading || workspaces.data === undefined) return <LoadingSpinner />

  const addPlanButton = (
    // has an optional parameter
    <ButtonUI onClick={() => openDialog()} Icon={<AddRounded />}>
      Plan
    </ButtonUI>
  )
  return (
    <BaseContainer>
      {basicDialog.dialog}
      {planDrawer}
      <BackOfficeBillingDialog
        isOpen={dialogData !== null}
        onClose={closeDialog}
        formData={dialogData?.data ?? undefined}
        setFormDirty={() => {}}
      />
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="flex-end">
        <TableTitleWithTotalCount
          title="Plans"
          qualifier="Plan"
          totalCount={workspaces.data.totalCount}
          helpTooltipContent="You are about to create a new subscription. You should only do so if an user is completely enable to subscribe on its own. Please do not abuse the subscription creation."
        />
        <Box display="flex" alignItems="flex-end">
          <Box mr={2}>
            <BackOfficeBillingFilter setFilter={setParams} filter={params} />
          </Box>
          {addPlanButton}
        </Box>
      </Box>
      <Paper>
        <ServerSideTable<BackOfficeGetMinimalPlanDto, BackOfficeGetPlansSortByEnum>
          columns={columns}
          items={workspaces.data.data}
          limit={workspaces.data.limit}
          totalCount={workspaces.data.totalCount}
          defaultParams={params}
          onPageChange={setPage}
          onLimitChange={setLimit}
          onSort={setSorting}
          onRowClicked={(row) => openPlanDrawer(row.id)}
        />
      </Paper>
    </BaseContainer>
  )
}

export default BackOfficeBillingScene
