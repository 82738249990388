import { Link, Paper, Typography } from "@material-ui/core"
import React, { useMemo } from "react"

import useDrawer from "components/misc/useDrawer"
import { IExtendedDataTableColumn } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import { GetCOAMappingsRequest, GetLabelDto, GetLabelQuerySortByEnum } from "services/api/openapi"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import { defaultLabelParams, defaultLabelSorting, LabelParameters } from "scenes/Labels"
import { URLS } from "routes"
import { Link as RouterLink } from "react-router-dom"
import toFilterElement from "services/utils/toFilterElement"
import { CachedRounded, MoreHorizRounded, WarningRounded } from "@material-ui/icons"
import { pluralize } from "services/utils/textUtils"
import TooltipUI from "CryptioUI/Tooltip"

export const defaultMappingParams: WithoutWorkspaceId<GetCOAMappingsRequest> = {
  type: undefined,
  excludedIds: undefined,
  accountType: undefined,
  page: 0,
  limit: 10,
}

export const mappingHasAnyFilter = (filters: Partial<GetCOAMappingsRequest>) =>
  Boolean(filters.type || filters.accountType)

interface ExchangeTabProps {
  openMappingDrawer: <PassedItemId extends string | null>(
    data: PassedItemId,
    formData?: (null extends PassedItemId ? Partial<Record<string, unknown>> | undefined : undefined) | undefined,
  ) => void
}

const LabelTab = (props: ExchangeTabProps): JSX.Element => {
  const { openMappingDrawer } = props
  const [contactDrawer, openContactDrawer] = useDrawer("contact")

  const {
    params: paramsLabel,
    setSorting: setSortingLabels,
    setLimit,
    setPage,
  } = usePaginatedParams<LabelParameters, GetLabelQuerySortByEnum>(defaultLabelParams, defaultLabelSorting)

  const labels = api.label.useLabels(
    {
      page: paramsLabel.page,
      limit: paramsLabel.limit,
      sortBy: paramsLabel.sortBy,
      sortDirection: paramsLabel.sortDirection,
      labelType: paramsLabel.labelType,
      withNoMappingOnly: true,
      withTransactionsOnly: true,
    },
    { refetchInterval: 2000 },
  )

  const [walletDrawer, openWalletDrawer] = useDrawer("wallet")

  const columns = useMemo<IExtendedDataTableColumn<GetLabelDto, GetLabelQuerySortByEnum>[]>(
    () => [
      {
        minWidth: "50px",
        width: "50px",
        maxWidth: "50px",
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        sortName: "name",
      },
      {
        name: "Transactions",
        selector: "movementCount",
        sortable: true,
        sortName: "movement_count",
        format: function formatContactAddresses(row) {
          switch (row.status) {
            case "active":
              return (
                <TooltipUI content="Calculating">
                  <CachedRounded />
                </TooltipUI>
              )
            case "pending":
              return (
                <TooltipUI content="Pending">
                  <MoreHorizRounded />
                </TooltipUI>
              )
            case "error":
              return (
                <TooltipUI content="Error">
                  <Typography>
                    <WarningRounded />
                  </Typography>
                </TooltipUI>
              )
            case "inactive":
            default:
              if (row.movementCount === 0) return "0"
              return (
                <Link
                  component={RouterLink}
                  to={URLS.Transactions.getUrl({
                    filters: [{ type: "labels", value: [toFilterElement(row)], isNot: false }],
                  })}
                  underline="always"
                >
                  {row.movementCount}
                </Link>
              )
          }
        },
      },
      {
        name: "From",
        selector: "from",
        format: function formatFrom(row) {
          if (!row.from) return "-"

          const onClick =
            row.from.type === "contact"
              ? () => row.from && openContactDrawer(row.from.items[0].id)
              : () => row.from && openWalletDrawer(row.from.items[0].id)

          return (
            <TooltipUI content={row.from.type} classNameTrigger="capitalize">
              {row.from.items.length === 1 ? (
                <Link underline="always" onClick={onClick}>
                  <Typography>{row.from.items[0].name}</Typography>
                </Link>
              ) : (
                <Typography>
                  {row.from.items.length} {pluralize(true, row.from.type)}
                </Typography>
              )}
            </TooltipUI>
          )
        },
      },
      {
        name: "To",
        selector: "to",
        format: function formatTo(row) {
          if (!row.to) return "-"

          const onClick =
            row.to.type === "contact"
              ? () => row.to && openContactDrawer(row.to.items[0].id)
              : () => row.to && openWalletDrawer(row.to.items[0].id)

          return (
            <TooltipUI content={row.to.type} classNameTrigger="capitalize">
              {row.to.items.length === 1 ? (
                <Link underline="always" onClick={onClick}>
                  <Typography>{row.to.items[0].name}</Typography>
                </Link>
              ) : (
                <Typography>
                  {row.to.items.length} {pluralize(true, row.to.type)}
                </Typography>
              )}
            </TooltipUI>
          )
        },
      },
      {
        minWidth: "50px",
        width: "50px",
        maxWidth: "50px",
      },
    ],
    [openContactDrawer, openWalletDrawer],
  )

  return (
    <>
      {walletDrawer}
      {contactDrawer}
      {labels && labels.data && (
        <Paper>
          <ServerSideTable<GetLabelDto, GetLabelQuerySortByEnum>
            columns={columns}
            items={labels.data.data}
            totalCount={labels.data.totalCount}
            defaultParams={paramsLabel}
            limit={labels.data.limit}
            onPageChange={setPage}
            onLimitChange={setLimit}
            onSort={setSortingLabels}
            onRowClicked={(row) => openMappingDrawer(null, { row })}
          />
        </Paper>
      )}
    </>
  )
}

export default LabelTab
