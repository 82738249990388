import BigNumber from "bignumber.js"

import { CostBasisErrorStatusType, NFTExplorer } from "."
import { TransactionExplorer, UserRoleEnum } from "./types"
import { prettyEmptyOrNull } from "./utils"

export const explorers: TransactionExplorer[] = [
  {
    displayableName: "SnowTrace",
    sourceName: "Avalanche",
    getLink: (txHash: string) => `https://snowtrace.io/tx/${txHash}`,
  },
  {
    displayableName: "Blockchain.com",
    sourceName: "Bitcoin",
    getLink: (txHash: string) => `https://blockchain.com/btc/tx/${txHash}`,
  },
  {
    displayableName: "Blockcypher",
    sourceName: "Litecoin",
    getLink: (txHash: string) => `https://live.blockcypher.com/ltc/tx/${txHash}`,
  },
  {
    displayableName: "BscScan",
    sourceName: "Binance Smart Chain",
    getLink: (txHash: string) => `https://bscscan.com/tx/${txHash}`,
  },
  {
    displayableName: "AdaStat",
    sourceName: "Cardano",
    getLink: (txHash: string) => `https://adastat.net/transactions/${txHash}`,
  },
  {
    displayableName: "Etherscan",
    sourceName: "Ethereum",
    getLink: (txHash: string) => `https://etherscan.io/tx/${txHash}`,
  },
  {
    displayableName: "Filfox",
    sourceName: "Filecoin",
    getLink: (message: string) => `https://filfox.info/en/message/${message}`,
  },
  {
    displayableName: "Near Explorer",
    sourceName: "Near",
    getLink: (txHash: string) => `https://explorer.near.org/transactions/${txHash}`,
  },
  {
    displayableName: "PolygonScan",
    sourceName: "Polygon",
    getLink: (txHash: string) => `https://polygonscan.com/tx/${txHash}`,
  },
  // {
  //   displayableName: "Solana Beach",
  //   sourceName: "Solana",
  //   getLink: (txHash: string) => `https://solana.surf/transaction/${txHash}`,
  // },
  // {
  //   displayableName: "Subscan",
  //   sourceName: "Polkadot",
  //   getLink: (txHash: string) => `https://polkadot.subscan.io/extrinsic/${txHash}`,
  // },
  {
    displayableName: "TzStats",
    sourceName: "Tezos",
    getLink: (txHash: string) => `https://tzstats.com/${txHash}`,
  },
  {
    displayableName: "Nexo",
    sourceName: "Nexo",
    getLink: (txhash: string) => `https://platform.nexo.io/transactions/${txhash}`,
  },
  {
    displayableName: "ViewBlock",
    sourceName: "Zilliqa",
    getLink: (txhash: string) => `https://viewblock.io/zilliqa/tx/${txhash}`,
  },
  {
    displayableName: "OptimismScan",
    sourceName: "Optimism",
    getLink: (txhash: string) => `https://optimistic.etherscan.io/tx/${txhash}`,
  },
  {
    displayableName: "Arbiscan",
    sourceName: "Arbitrum",
    getLink: (txhash: string) => `https://arbiscan.io/tx/${txhash}`,
  },
  {
    displayableName: "LineaScan",
    sourceName: "Linea",
    getLink: (txHash: string) => `https://lineascan.build/tx/${txHash}`,
  },
]

export const nftExplorers: NFTExplorer[] = [
  {
    displayableName: "Etherscan",
    sourceName: "Ethereum",
    getLink: (address: string, tokenId: string) => `https://etherscan.io/nft/${address}/${tokenId}`,
  },
]

export const costBasisErrorStatusToString = (
  errorStatuses: CostBasisErrorStatusType[] | null | undefined,
  assetSymbol: string | null,
  _volume: string | BigNumber,
): string | null => {
  if (!errorStatuses || errorStatuses.length === 0) {
    return null
  }

  const asset = prettyEmptyOrNull(assetSymbol)

  if (errorStatuses.includes("negative_balance")) {
    // TODO: we can't compute negative volume because of per wallet / workspace
    // const negativeAmount = new BigNumber(volume).negated().isLessThan(balance)
    //   ? balance
    //   : volume.toString()

    return `Negative balance for asset ${asset}` // . Missing ${negativeAmount} ${asset}.
  }
  if (errorStatuses.includes("missing_usd_rate")) {
    return `Missing rate for asset ${asset}. This may skew cost basis.`
  }
  if (errorStatuses.includes("impacted_by_missing_price")) {
    return "Previous transaction with missing price"
  }
  if (errorStatuses.includes("missing_fiat_rate")) {
    return "Missing fiat rate."
  }
  return null
}

export const isCryptioStaff = (role: UserRoleEnum) =>
  role === "business_administrator" || role === "reporter" || role === "tech_administrator"
