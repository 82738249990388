import TooltipUI from "CryptioUI/Tooltip"
import React from "react"

interface ConditionalTooltipProps {
  tooltipMessage: string
  disabled: boolean
  children: React.ReactElement
}

const ConditionalTooltip = (props: ConditionalTooltipProps) => {
  const { tooltipMessage, disabled, children } = props

  return (
    <>
      {disabled ? (
        <TooltipUI content={tooltipMessage}>
          <span>{children}</span>
        </TooltipUI>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default ConditionalTooltip
