/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import { GetVersionDto } from "../models"

/**
 *
 */
export class DefaultApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static check() {
    return localDefaultApi.check()
  }

  /**
   */
  check(): Promise<void> {
    return this.restInstance.get(`/health`)
  }

  static version() {
    return localDefaultApi.version()
  }

  /**
   * Returns the current version of the application
   */
  version(): Promise<GetVersionDto> {
    return this.restInstance.get(`/version`)
  }
}

const localDefaultApi = new DefaultApi()
