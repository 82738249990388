import TooltipUI from "CryptioUI/Tooltip"
import WarningTypography from "../../../WarningTypography"

interface Props {
  helpElement: JSX.Element
}

function ContactUsInfo({ helpElement }: Props) {
  return (
    <TooltipUI content={helpElement}>
      <WarningTypography>Book this transaction? Contact us</WarningTypography>
    </TooltipUI>
  )
}

export default ContactUsInfo
