import { Box, Button, makeStyles, Theme, Typography } from "@material-ui/core"
import dayjs from "dayjs"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

import { URLS } from "../../../routes"
import { GetFullSubscriptionDto } from "services/api/openapi"

interface Props {
  subscription: GetFullSubscriptionDto
}

const useStyles = makeStyles((theme: Theme) => ({
  subscribeButton: {
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
}))

const TrialSubscriptionView = ({ subscription }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        You have not subscribed to Cryptio
      </Typography>
      {subscription.isExpired ? (
        <>
          <Typography variant="h4" color="error">
            Trial expired! Please subscribe using the button below to continue using Cryptio.
          </Typography>
          <Box mt={1} />
          <Button
            variant="contained"
            color="primary"
            className={classes.subscribeButton}
            component={RouterLink}
            to={URLS.Billing.Simulator}
          >
            Subscribe
          </Button>
        </>
      ) : (
        <>
          {subscription.periodTo && (
            <Typography variant="body1" gutterBottom>
              There are {dayjs(subscription.periodTo).fromNow(true)} remaining in your free trial.
              <br />
              Subscribe now to avoid any interruption of service.
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            className={classes.subscribeButton}
            component={RouterLink}
            to={URLS.Billing.Simulator}
          >
            Subscribe
          </Button>
        </>
      )}
    </Box>
  )
}

export default TrialSubscriptionView
