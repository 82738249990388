import { Dialog } from "@headlessui/react"
import React from "react"
import { css } from "@emotion/css"

import { BoldWeight, ButtonSize, HeaderDrawerProps, Mode, TypographyVariant } from "CryptioUI/types"
import ButtonUI from "CryptioUI/Button"
import Typography from "CryptioUI/Typography"
import { ReactComponent as ArrowLeft } from "CryptioUI/assets/icons/arrow-left.svg"

const HeaderDrawerUI = (props: HeaderDrawerProps): JSX.Element => {
  const { title, returnAction, RightButton } = props
  const iconStyle = css`
    height: 18px;
    width: 18px;
    filter: invert(41%) sepia(9%) saturate(913%) hue-rotate(191deg) brightness(93%) contrast(89%);
  `

  return (
    <div className="fixed top-0 w-full z-[10002]">
      <div className="h-20 bg-grey-100 opacity-100 px-10 flex items-center w-full justify-between">
        <div className="flex flex-row items-center">
          {returnAction !== undefined && (
            <div className="flex justify-center items-center mr-4">
              <ButtonUI
                className="!p-0"
                mode={Mode.TEXTONLY}
                size={ButtonSize.MD}
                Icon={<ArrowLeft className={iconStyle} />}
                onClick={returnAction}
              />
            </div>
          )}
          <Dialog.Title as={Typography} variant={TypographyVariant.H2} boldness={BoldWeight.MEDIUM} className="text-xl">
            {title}
          </Dialog.Title>
        </div>
        {RightButton}
      </div>
    </div>
  )
}

export default HeaderDrawerUI
