import { useTypedController } from "@hookform/strictly-typed"
import { Box, makeStyles, Theme } from "@material-ui/core"
import React, { useCallback } from "react"
import { useForm } from "react-hook-form"
import { useLoadingButton } from "components/LoadingButton"
import CustomSelector from "components/selector/CustomSelector"
import ModalUI from "CryptioUI/Modal"

import api from "services/api"
import { GetInvoiceDto } from "services/api/openapi/models"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  validateButton: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}))

interface LinkWithInvoiceForm {
  invoice: GetInvoiceDto | null
}

interface Props {
  isOpen: boolean
  setIsOpen: (newValue: boolean) => void
  transactionId: string
}

const LinkWithInvoiceModal = ({ isOpen, setIsOpen, transactionId }: Props): JSX.Element => {
  const classes = useStyles()
  const toast = useToast()
  const { handleSubmit, control, formState, reset } = useForm<LinkWithInvoiceForm>({ mode: "onChange" })
  const TypedController = useTypedController<LinkWithInvoiceForm>({ control })
  const { mutateAsync: updateTransactionInvoiceMutation } = api.transaction.useUpdateTransactionInvoice()

  const [SaveInvoiceButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = useCallback(
    async (form: LinkWithInvoiceForm) => {
      if (form.invoice) {
        try {
          await updateTransactionInvoiceMutation({
            updateTransactionInvoiceDto: {
              transactionId: transactionId,
              invoiceId: form.invoice.id,
            },
          })
          toast.open("Successfully linked the invoice with the transaction", { variant: "success" })
        } catch (e) {
          toastCatch(e, toast)
        }
      }
      reset()
      setIsOpen(false)
    },
    [setIsOpen, reset, transactionId, toast, updateTransactionInvoiceMutation],
  )

  return (
    <>
      <ModalUI isOpen={isOpen} onClose={() => setIsOpen(false)} className="w-[39em]" title="Link with existing file">
        <Box
          component="form"
          onSubmit={handleButtonCallback(handleSubmit(onSubmit))}
          p={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <TypedController
            name="invoice"
            defaultValue={null}
            rules={{ required: true }}
            render={({ ref, onChange, ...rest }) => (
              <CustomSelector
                {...rest}
                inputRef={ref}
                fullWidth
                onChange={(_, newValue) => onChange(newValue)}
                getOptionLabel={(option) => option.invoiceRef}
                defaultPaginatedQueryProps={{
                  sortBy: "invoice_ref",
                }}
                error={!!formState.errors.invoice}
                usePaginatedQuery={api.invoices.useInvoices}
                size="small"
                placeholder="Type to search..."
              />
            )}
          />
          <SaveInvoiceButton className={classes.validateButton} disabled={!formState.isValid} type="submit">
            Save
          </SaveInvoiceButton>
        </Box>
      </ModalUI>
    </>
  )
}

export default LinkWithInvoiceModal
