import { Box, Divider, Link, List, ListItem, makeStyles, Theme, Typography } from "@material-ui/core"
import { CachedRounded, ExitToAppRounded } from "@material-ui/icons"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

import AddressTypography from "components/AddressTypography"
import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import ExternalLink from "components/ExternalLink"
import { UncontrolledLoadingButton } from "components/LoadingButton"
import AdministratorInformation from "components/misc/AdministratorInformation"
import CopyableText from "components/misc/copyableText"
import { DrawerProp } from "components/misc/useDrawer"
import { URLS } from "../../../routes"
import api from "services/api"
import { GetContactDto } from "services/api/openapi"
import BackOfficeView from "components/misc/BackOfficeView"
import { pluralize } from "services/utils/textUtils"
import toFilterElement from "services/utils/toFilterElement"
import ContactModalCsv from "../ContactModalCsv"
import ContactForm from "./ContactForm"
import { ReactComponent as Reset } from "CryptioUI/assets/icons/reset.svg"
import ButtonUI from "CryptioUI/Button"
import { iconStyleWhite } from "CryptioUI/Utilities/config"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  linkIcon: {
    display: "inline-flex",
    verticalAlign: "top",
    marginLeft: theme.spacing(1),
  },
  csvExampleMenuItem: {
    padding: 0,
    marginTop: theme.spacing(3),
    textAlign: "end",
  },
  validateButton: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginRight: theme.spacing(5),
  },
}))

const ContactDrawer = (props: DrawerProp<GetContactDto, true>) => {
  const { item: contact, onClose } = props
  const classes = useStyles()
  const toast = useToast()

  const { mutateAsync: updateMovementCountMutation } = api.backOffice.contact.useUpdateMovementCounter()
  const updateMovementCount = async () => {
    if (!contact) return
    try {
      await updateMovementCountMutation({ contactId: contact.id })
      toast.open("Updating movement count...", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  return (
    <>
      {contact && !contact.isEditable ? (
        <Box mt={4.5}>
          <Box mb={2}>
            <Typography variant="h5">
              Contact <strong>{contact.name}</strong> cannot be edited.
              {contact.isGlobal && (
                <>
                  <br />
                  This contact is global
                </>
              )}
              {contact.isFireblocks && (
                <>
                  <br />
                  This contact comes from Fireblocks
                </>
              )}
            </Typography>
          </Box>

          <ButtonUI onClick={() => onClose()}>
            <Typography variant="body1">Close</Typography>
          </ButtonUI>
        </Box>
      ) : (
        <>
          <ContactForm {...props} />

          <DrawerCategory title="Batch import">
            <Box mt={3} />
            <ContactModalCsv />
            <Box mt={2} />
            <ExternalLink
              className={classes.csvExampleMenuItem}
              href={"https://docs.google.com/spreadsheets/d/1-e89xDkNiERs-BsNnRelGFdWZovMl5-1ixQ7t3W6nR8"}
            >
              See CSV example
              <ExitToAppRounded className={classes.linkIcon} />
            </ExternalLink>
          </DrawerCategory>
        </>
      )}

      {contact && (
        <Box>
          <AdministratorInformation infos={[{ id: contact.id, extended: "Contact" }]} />
          {contact && (
            <BackOfficeView adminOnly>
              <Box mt={2}>
                <UncontrolledLoadingButton
                  // TODO: if custom csv => open modal, upload file and submit
                  onClick={() => updateMovementCount()}
                  Icon={<Reset className={iconStyleWhite} />}
                >
                  Update movement count
                </UncontrolledLoadingButton>
              </Box>
            </BackOfficeView>
          )}

          <DrawerCategory title="Contact Summary">
            {contact.addressCount > 0 && (
              <DrawerSection name="Addresses">
                <List dense>
                  {contact.addresses.map((addr, idx) => (
                    <Box key={idx}>
                      <ListItem key={addr}>
                        <CopyableText text={addr}>
                          <AddressTypography component="span">{addr}</AddressTypography>
                        </CopyableText>
                      </ListItem>
                      {contact.addressCount > 1 && idx !== contact.addressCount - 1 && <Divider />}
                    </Box>
                  ))}
                </List>
              </DrawerSection>
            )}
            <DrawerSection name="Movements">
              {contact.status !== "inactive" ? (
                <CachedRounded data-test-id="movement-count-loading" />
              ) : (
                <Link
                  onClick={onClose}
                  component={RouterLink}
                  to={URLS.Transactions.getUrl({
                    filters: [{ type: "contacts", value: [toFilterElement(contact)], isNot: false }],
                  })}
                  underline="always"
                >
                  <Typography variant="body1">
                    {contact.isGlobal
                      ? "See transactions with this contact"
                      : `${contact.movementCount} ${pluralize(contact.movementCount > 1, "movement")}`}
                  </Typography>
                </Link>
              )}
            </DrawerSection>
          </DrawerCategory>
        </Box>
      )}
    </>
  )
}

export default ContactDrawer
