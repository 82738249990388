import { Grid, IconButton, makeStyles, MenuItem, Popover, Theme, Typography } from "@material-ui/core"
import { ArrowDropDownRounded } from "@material-ui/icons"
import InputCheckbox from "CryptioUI/InputCheckbox"
import React, { useMemo, useRef, useState } from "react"
import { SelectTransactionPageContextValue } from "services/context/selectTransactionPageContext"
import { SelectedRowsType } from "../GeneratedTable/useBulkSelectorTransactionPage"

interface DropDownCheckBoxProps {
  setSelectedRows: (rows: SelectedRowsType | undefined) => void
  isPageSelected: boolean
  onPageSelected: () => void
  mvtSelected: SelectTransactionPageContextValue
  selectedRows: SelectedRowsType | undefined
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "#F5F5F5",
    maxHeight: "42px",
    minHeight: "42px",
    height: "42px",
    paddingLeft: "15px",
  },
  iconButton: {
    padding: 0,
  },
  mainCheckBox: {
    color: `${theme.palette.primary.main} !important`,
    padding: 0,
  },
  dropDown: {
    zIndex: 1,
  },
}))

const DropDownCheckBox = (props: DropDownCheckBoxProps) => {
  const { setSelectedRows, isPageSelected, mvtSelected, onPageSelected, selectedRows } = props
  const classes = useStyles()
  const anchorRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const isAllRowsSelected = useMemo(() => {
    if (selectedRows && selectedRows.type === "negativeAdd" && selectedRows.excludedIds.length === 0) return true
    return false
  }, [selectedRows])

  const options = [
    {
      name: `${isPageSelected ? "Unselect" : "Select"} only the rows displayed`,
      action: () => {
        onPageSelected()
        mvtSelected.onPageSelected()
      },
    },
  ]

  const handleMenuItemClick = (_event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    options[index].action()
    setOpen(false)
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current?.contains(event.target as HTMLDivElement)) {
      return
    }
    setOpen(false)
  }

  return (
    <>
      <Grid ref={anchorRef} className={classes.container}>
        <IconButton className={classes.iconButton} data-test-id="select-all">
          <InputCheckbox
            disabled={false}
            value={"checkbox-transaction"}
            handleChange={() => {
              if (isAllRowsSelected) {
                setSelectedRows(undefined)
                mvtSelected.setSelectedRows(undefined)
              } else {
                setSelectedRows({
                  type: "negativeAdd",
                  excludedIds: [],
                })
                mvtSelected.setSelectedRows({
                  type: "negativeAdd",
                  excludedIds: [],
                })
              }
            }}
            checked={isAllRowsSelected}
            name={"drop-down-checkbox-transaction"}
          />
          {/* <Checkbox
            color="primary"
            checked={isAllRowsSelected}
            onChange={() => {
              if (isAllRowsSelected) {
                setSelectedRows(undefined)
                mvtSelected.setSelectedRows(undefined)
              } else {
                setSelectedRows({
                  type: "negativeAdd",
                  excludedIds: [],
                })
                mvtSelected.setSelectedRows({
                  type: "negativeAdd",
                  excludedIds: [],
                })
              }
            }}
            className={classes.mainCheckBox}
            data-test-id="select-all"
          /> */}
        </IconButton>
        <IconButton onClick={handleToggle} className={classes.iconButton}>
          <ArrowDropDownRounded />
        </IconButton>
      </Grid>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        onClose={handleClose}
        className={classes.dropDown}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={(event) => handleMenuItemClick(event, index)}>
            <Typography>{option.name}</Typography>
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}

export default DropDownCheckBox
