import SimpleAutocompleteFactory from "components/Autocomplete/SimpleAutocompleteFactory"
import { TransactionFilterImportTypeValueEnum } from "../../../../../services/api/openapi"

export const transactionFilterImportPrettyMap = {
  wallet: "Wallet",
  exchange: "Exchange",
  custom: "Custom CSV",
}

export const ImportTypeFilter = SimpleAutocompleteFactory<TransactionFilterImportTypeValueEnum>(
  transactionFilterImportPrettyMap,
)

export default ImportTypeFilter
