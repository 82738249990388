import { createStyles, makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import ChildrenProps from "services/misc/childrenProps"

interface WarningAdminFeatureProps extends ChildrenProps {
  isAdminFeature: boolean
  fullWidth?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningBorder: {
      border: `${theme.spacing(1)}px #ba000d solid`,
      padding: `${theme.spacing(1)}px`,
    },
  }),
)

export function WarningAdminFeature({ isAdminFeature, children, fullWidth = true }: WarningAdminFeatureProps) {
  const classes = useStyles()

  if (isAdminFeature) {
    return <div className={clsx(classes.warningBorder, fullWidth ? "w-full" : "")}>{children}</div>
  }
  return <>{children}</>
}
