import React from "react"
import Develop from "./Develop"
import { NewInListMovementDto } from "services/api/openapi"
import { SelectTransactionPageContextValue } from "services/context/selectTransactionPageContext"
import { DeveloppedRowsType } from "../../GeneratedTable/useDeveloppedTransactionPage"
import { SelectedRowsType } from "../../GeneratedTable/useBulkSelectorTransactionPage"

interface CheckBoxProps {
  selectedRows: SelectedRowsType | undefined
  setSelectedRows?: (row: SelectedRowsType | undefined) => void
  developpedRows: DeveloppedRowsType | undefined
  setDeveloppedRows: (row: DeveloppedRowsType | undefined) => void
  mvtSelected?: SelectTransactionPageContextValue
  mainMovement: NewInListMovementDto | undefined
  id: string
  isDevelopped: boolean
  isComplex: boolean
}

const CheckBox = (props: CheckBoxProps) => {
  const {
    selectedRows,
    setSelectedRows,
    developpedRows,
    setDeveloppedRows,
    mvtSelected,
    mainMovement,
    id,
    isDevelopped,
    isComplex,
  } = props

  if (isDevelopped && mvtSelected) {
    return (
      <Develop
        id={mainMovement?.id ?? ""}
        selectedRows={mvtSelected.selectedRows}
        setSelectedRows={mvtSelected.setSelectedRows}
        developpedRows={undefined}
        setDeveloppedRows={undefined}
        isComplex={isComplex}
      />
    )
  }
  if (setSelectedRows) {
    return (
      <Develop
        id={id}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        developpedRows={developpedRows}
        setDeveloppedRows={setDeveloppedRows}
        isComplex={isComplex}
      />
    )
  }
  return <></>
}

export default CheckBox
