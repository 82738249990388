import React from "react"
import { SelectedRowsType } from "../../GeneratedTable/useBulkSelectorTransactionPage"
import { DeveloppedRowsType } from "../../GeneratedTable/useDeveloppedTransactionPage"
import InputCheckbox from "CryptioUI/InputCheckbox"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import { ReactComponent as ArrowRight } from "CryptioUI/assets/icons/chevron-right.svg"
import { ReactComponent as ArrowDown } from "CryptioUI/assets/icons/chevron-down.svg"

interface DevelopProps {
  developpedRows: DeveloppedRowsType | undefined
  setDeveloppedRows: ((rows: DeveloppedRowsType) => void) | undefined
  selectedRows: SelectedRowsType | undefined
  setSelectedRows: (row: SelectedRowsType | undefined) => void
  id: string
  isComplex: boolean
}

const Develop = (props: DevelopProps) => {
  const { developpedRows, setDeveloppedRows, selectedRows, setSelectedRows, id, isComplex } = props

  const isRowSelected = (id: string): boolean => {
    if (selectedRows?.type === "positiveAdd") return selectedRows.itemIds.includes(id)
    else if (selectedRows?.type === "negativeAdd") return !selectedRows.excludedIds.includes(id)
    return false
  }

  const onRowSelected = () => {
    const isSelected = isRowSelected(id)

    if (selectedRows?.type === "positiveAdd") {
      if (isSelected) {
        // remove row from selected list
        setSelectedRows({
          ...selectedRows,
          itemIds: selectedRows.itemIds.filter((x) => x !== id),
        })
      } else {
        // add row to selected list
        setSelectedRows({
          ...selectedRows,
          itemIds: Array.from(new Set(selectedRows.itemIds.concat([id]))),
        })
      }
    } else if (selectedRows?.type === "negativeAdd") {
      if (isSelected) {
        // add transaction to excluded list
        setSelectedRows({
          ...selectedRows,
          excludedIds: Array.from(new Set(selectedRows.excludedIds.concat([id]))),
        })
      } else {
        // remove transaction from excluded list
        setSelectedRows({
          ...selectedRows,
          excludedIds: selectedRows.excludedIds.filter((x) => x !== id),
        })
      }
    } else {
      // create a new selection list
      setSelectedRows({
        type: "positiveAdd",
        itemIds: [id],
      })
    }
  }

  const isRowDevelopped = (id: string): boolean => {
    if (developpedRows?.type === "positiveAdd") return !developpedRows.itemIds.includes(id)
    else if (developpedRows?.type === "negativeAdd") return developpedRows.excludedIds.includes(id)
    return true
  }

  const onRowDevelopped = () => {
    const isSelected = isRowDevelopped(id)

    if (!setDeveloppedRows) return

    if (developpedRows?.type === "positiveAdd") {
      if (!isSelected) {
        // remove row from selected list
        setDeveloppedRows({
          ...developpedRows,
          itemIds: developpedRows.itemIds.filter((x) => x !== id),
        })
      } else {
        // add row to selected list
        setDeveloppedRows({
          ...developpedRows,
          itemIds: Array.from(new Set(developpedRows.itemIds.concat([id]))),
        })
      }
    } else if (developpedRows?.type === "negativeAdd") {
      if (!isSelected) {
        // add transaction to excluded list
        setDeveloppedRows({
          ...developpedRows,
          excludedIds: Array.from(new Set(developpedRows.excludedIds.concat([id]))),
        })
      } else {
        // remove transaction from excluded list
        setDeveloppedRows({
          ...developpedRows,
          excludedIds: developpedRows.excludedIds.filter((x) => x !== id),
        })
      }
    } else {
      // create a new selection list
      setDeveloppedRows({
        type: "positiveAdd",
        itemIds: [id],
      })
    }
  }

  // FIXME: isRowDevelopped(id) is inverted.
  //  true => movements hidden
  //  false => movements shown

  return (
    <div className="flex flex-col justify-center items-center w-full h-full gap-2">
      <InputCheckbox
        disabled={false}
        value={"checkbox-transaction"}
        handleChange={onRowSelected}
        checked={isRowSelected(id)}
        name={"checkbox-transaction"}
      />
      {isComplex && (
        <ButtonUI
          data-test-id="develop"
          mode={Mode.TEXTONLY}
          Icon={
            !isRowDevelopped(id) ? (
              <ArrowDown className="text-grey-400 flex w-[14px] h-[14px]" />
            ) : (
              <ArrowRight className="text-grey-400 flex w-[14px] h-[14px]" />
            )
          }
          className="p-0 h-fit"
          onClick={onRowDevelopped}
        />
      )}
    </div>
  )
}

export default Develop
