import { Badge, Box, MenuItem, Popover, Typography, Divider, IconButton } from "@material-ui/core"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { AccountBoxRounded, AppsRounded, ArrowDropDownRounded } from "@material-ui/icons"
import React, { useContext } from "react"
import { NavLink, useHistory, useLocation } from "react-router-dom"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"

import { LightGrey, White } from "../../materialTheme"
import { URLS } from "../../routes"
import { WorkspaceStatusDisplay } from "./WorkspaceStatusDisplay"
import api from "services/api"
import { WorkspaceContext } from "services/context/workspaceContext"
import { GetWorkspaceInListDto } from "../../services/api/openapi"
import BackOfficeView from "components/misc/BackOfficeView"
import TooltipUI from "CryptioUI/Tooltip"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkTypography: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(1),
      fontWeight: 400,
    },
    typographyEllipsis: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(1),
      fontWeight: 400,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    inactiveLink: {
      "color": LightGrey,
      "&:hover": {
        color: White,
      },
    },
    inactiveSubLink: {
      "color": LightGrey,
      "&:hover": {
        color: White,
      },
    },
    activeSubLink: {
      color: `${theme.palette.action.selected} !important`,
    },
    menuPaper: {
      marginTop: theme.spacing(3),
      border: `1px solid $#7676a3`,
      backgroundColor: theme.palette.primary.main,
      color: LightGrey,
    },
    dividerSubLink: {
      backgroundColor: "#555",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    adminTooltip: {
      marginLeft: "auto",
      marginRight: 0,
    },
    adminButton: {
      "color": White,
      "&:hover": {
        color: LightGrey,
      },
    },
  }),
)

export const WorkspaceMenu = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { pathname } = useLocation()

  const workspaceCtx = useContext(WorkspaceContext)
  const user = api.user.useUser()

  const workspaces = api.workspace.useWorkspacesList({
    page: 0,
    limit: 5,
    excludedIds: [workspaceCtx.workspace.id],
  })

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getHighlightClass = (menuUrl: string): string => {
    return pathname.replace(/\/$/, "") === menuUrl ? classes.activeSubLink : ""
  }

  const handleChangeWorkspace = (newWorkspaceId: string) => {
    // Avoid full rerender
    if (workspaceCtx.workspace.id !== newWorkspaceId) {
      workspaceCtx.changeWorkspace(newWorkspaceId)
      history.push(pathname)
    }
    handleClose()
  }

  const getWorkspaceMenuItem = (workspace: GetWorkspaceInListDto) => {
    return (
      <MenuItem className={classes.inactiveSubLink}>
        <div className={workspace.isAdmin ? "w-5/6" : "w-full"} onClick={() => handleChangeWorkspace(workspace.id)}>
          <Typography className={classes.linkTypography}>{workspace.name}</Typography>
        </div>
        <BackOfficeView>
          <div className="w-1/6" onClick={handleClose}>
            <TooltipUI content={"Back office view"} classNameTrigger={classes.adminTooltip}>
              <IconButton
                size="small"
                component={NavLink}
                to={URLS.BackOffice.Workspace.getUrl({ workspaceId: workspace.id })}
              >
                <AccountBoxRounded className={classes.adminButton} />
              </IconButton>
            </TooltipUI>
          </div>
        </BackOfficeView>
      </MenuItem>
    )
  }

  const menuId = "manage-workspace-menu"
  return (
    <Box display="flex" alignItems="center">
      <MenuItem className={classes.inactiveLink}>
        <ButtonUI
          aria-haspopup="true"
          onClick={handleClickListItem}
          mode={Mode.TEXTONLY}
          className="!text-white"
          aria-controls={menuId}
        >
          <Badge
            badgeContent={user.data?.workspaceInvitationCount}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            color="secondary"
          >
            <AppsRounded fontSize="medium" />
            <div className="max-w-[180px]">
              <TooltipUI content={<Typography>{workspaceCtx.workspace.name}</Typography>}>
                <Typography data-test-id="workspaceNameButton" className={classes.typographyEllipsis}>
                  {workspaceCtx.workspace.name}
                </Typography>
              </TooltipUI>
            </div>
            <ArrowDropDownRounded data-test-id="workspaceMenuIcon" />
          </Badge>
        </ButtonUI>
        <Popover
          id={menuId}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={{ paper: classes.menuPaper }}
        >
          {!workspaces.isLoading &&
            workspaces.data?.data.map((option) => (
              <React.Fragment key={option.id}>
                {getWorkspaceMenuItem(option)}
                <Divider className={classes.dividerSubLink} />
              </React.Fragment>
            ))}
          <MenuItem
            component={NavLink}
            to={URLS.Workspaces}
            className={classes.inactiveSubLink}
            activeClassName={getHighlightClass(URLS.Workspaces)}
            onClick={handleClose}
          >
            <Typography className={classes.linkTypography}>Manage workspaces</Typography>
          </MenuItem>
          <Divider className={classes.dividerSubLink} />
          <MenuItem
            component={NavLink}
            to={URLS.WorkspaceInvitation}
            className={classes.inactiveSubLink}
            activeClassName={getHighlightClass(URLS.WorkspaceInvitation)}
            onClick={handleClose}
          >
            <Badge
              badgeContent={user.data?.workspaceInvitationCount}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              color="secondary"
            >
              <Typography className={classes.linkTypography}>Manage invitations</Typography>
            </Badge>
          </MenuItem>
        </Popover>
      </MenuItem>
      <WorkspaceStatusDisplay workspace={workspaceCtx.workspace} />
    </Box>
  )
}
