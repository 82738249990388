import { WalletImportErrorType } from "pure-shared"

export const errorExplanations: Record<WalletImportErrorType, string> = {
  invalidCredentials:
    "The credentials are invalid. Please use the button Update Credentials above to change the credentials.",
  krakenAPIKeyAlreadyUsed:
    "The API key you provided was already used by another service. Due to the way the Kraken API works, Cryptio requires a fresh Kraken API key.",
  permissionDenied:
    "The API key you provided did not have enough permissions to import your transactions. Add the permissions to the key or provide another API key.",
  otherError: "An unknown error has occured. Please contact us; we will resolve the issue as soon as possible.",
  csvEmpty: "The CSV you sent did not contain any data.",
  csvWrongFormat: "The CSV was not in the correct format. Please see the documentation of the source.",
  csvWrongFormatWithReport: "The CSV was not in the correct format. See the attached error report for more details.",
  csvErrorWithReport: "The CSV contained some errors. See the attached error report for more details.",
  notImported: "No transactions were uploaded to this source.",
}

export const errorsNeedToChangeCredentials: Record<WalletImportErrorType, boolean> = {
  krakenAPIKeyAlreadyUsed: true,
  invalidCredentials: true,
  permissionDenied: true,
  otherError: false,
  csvEmpty: true,
  csvWrongFormat: true,
  csvWrongFormatWithReport: true,
  csvErrorWithReport: true,
  notImported: false,
}
