import { useTypedController } from "@hookform/strictly-typed"
import dayjs from "dayjs"
import React from "react"
import { ReportFormComponentProps } from "."
import PlainDatePicker from "components/DatePickers/DatePicker"
import { PlainDate } from "components/DateRangePicker"
import { DrawerSection } from "components/Drawer/DrawerItems"
import { DrawerReportForm } from "../ReportForm"

const ReportEndDate = ({ control }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })

  return (
    <DrawerSection name="Date" labelGap={0}>
      <TypedController
        name="date"
        defaultValue={{
          startDate: undefined,
          // TODO: bruh .subtract(1, "day")
          endDate: new PlainDate(dayjs()),
        }}
        rules={{
          required: true,
          validate: (dateRange) =>
            dateRange.startDate === undefined &&
            dateRange.endDate &&
            dateRange.endDate.isValid &&
            !dateRange.endDate.date.isAfter(dayjs()),
        }}
        render={({ onChange, value }) => (
          <PlainDatePicker
            disableFuture={true}
            maxDateMessage="Can only generate the report for a past date"
            value={value.endDate}
            onChange={(endDate) => onChange({ startDate: undefined, endDate })}
            fullWidth
          />
        )}
      />
    </DrawerSection>
  )
}

export default ReportEndDate
