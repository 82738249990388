import {
  Checkbox,
  ClickAwayListener,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Popper,
} from "@material-ui/core"
import { MoreVertRounded } from "@material-ui/icons"
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state"
import React from "react"
import { ColumnsName } from "./ContainerColumn"

const useStyles = makeStyles(() =>
  createStyles({
    iconButton: {
      padding: "6px",
    },
  }),
)

interface Props {
  columns: ColumnsName[]
  hiddenColumns: ColumnsName[]
  setHiddenColumns: (columns: ColumnsName[]) => void
}

const OmitColumnSelector = (props: Props): JSX.Element => {
  const classes = useStyles()
  const { columns, hiddenColumns, setHiddenColumns } = props

  const handleToggle = (column: ColumnsName) => {
    if (hiddenColumns.includes(column)) {
      //show element
      setHiddenColumns(Array.from(hiddenColumns.filter((c) => c !== column)))
    } else {
      //hide element
      setHiddenColumns(Array.from(hiddenColumns.concat([column])))
    }
  }

  return (
    <PopupState variant="popper" popupId="demo-popup-popper">
      {(popupState) => (
        <div>
          <IconButton className={classes.iconButton} {...bindToggle(popupState)} aria-label="open">
            <MoreVertRounded />
          </IconButton>
          <Popper {...bindPopper(popupState)}>
            <ClickAwayListener onClickAway={popupState.close}>
              <Paper>
                <List>
                  {columns.map((column, idx) => (
                    <ListItem key={idx} dense button onClick={() => handleToggle(column)}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={!hiddenColumns.includes(column)}
                          tabIndex={-1}
                          color="primary"
                        />
                      </ListItemIcon>
                      <ListItemText primary={column} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  )
}

export default OmitColumnSelector
