import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import React from "react"
import { useHistory } from "react-router-dom"
import { TransactionParams } from "."
import BaseContainer from "components/Container"
import MainTitleView from "components/MainTitleView"
import useDialog from "components/misc/useDialog"
import ButtonUI from "CryptioUI/Button"
import { EventRounded } from "@material-ui/icons"

import { URLS } from "../../routes"
import TransactionFilter from "./filters/TransactionFilter"

interface NoTransactionParameters {
  hasAnyFilter: boolean
  walletDrawer: JSX.Element
  setParamsWithDialog: (newParams: TransactionParams) => void
  params: TransactionParams
}

const useStyles = makeStyles((theme: Theme) => ({
  noTransactionText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}))

const NoTransaction = (props: NoTransactionParameters): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  const { hasAnyFilter, walletDrawer, setParamsWithDialog, params } = props

  const basicDialog = useDialog()

  return (
    <BaseContainer>
      {basicDialog.dialog}
      {walletDrawer}
      <MainTitleView
        title="Transactions"
        helpTooltipContent="Browse your transactions and adjust labels, USD rates, notes, invoices..."
      />

      <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <EventRounded fontSize="large" />
        {hasAnyFilter ? (
          <>
            <Typography className={classes.noTransactionText} variant="h4">
              There are no transaction that match the selected filters.
              <br />
              Click the following buttons to reset or update the filters.
            </Typography>
            <Box display="flex" flexDirection="row">
              <TransactionFilter setFilter={setParamsWithDialog} filter={params} />
            </Box>
          </>
        ) : (
          <>
            <Typography className={classes.noTransactionText} variant="h4">
              You don&apos;t have any transactions.
              <br />
              Click the button below to go to the import view.
            </Typography>
            <ButtonUI
              onClick={() =>
                history.push({
                  pathname: URLS.Import.basePath,
                  state: { openSourceSelector: true },
                })
              }
            >
              Go to Import
            </ButtonUI>
          </>
        )}
      </Box>
    </BaseContainer>
  )
}

export default NoTransaction
