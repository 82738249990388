import { Container, ContainerProps, makeStyles, Theme } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    paddingRight: theme.spacing(10),
    paddingLeft: theme.spacing(10),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    height: "100%",
  },
}))

const BaseContainer = ({ children, ...props }: ContainerProps) => {
  const classes = useStyles()
  return (
    <Container maxWidth="xl" {...props} className={classes.root}>
      {children}
    </Container>
  )
}

export default BaseContainer
