import { AccountCreatedViaType } from "pure-shared"

// export const stepsNames = ["Account information", "Personal informations", "Workspace creatpion"]
export const positionNames = [
  "CEO",
  "COO",
  "CFO",
  "Treasury Manager",
  "Accounting & Finance Manager",
  "Accounting & Finance Officer",
  "Internal Controller",
]

export type RegistrationSteps = [
  {
    accountCreationVia: AccountCreatedViaType
  },
  {
    email: string
    password: string
    confirmPassword: string
  },
  {
    firstName: string
    lastName: string
    companyName: string
    companyPosition: string
    acceptTermsOfUse: boolean
  },
]

/* eslint-disable @typescript-eslint/no-explicit-any */
type Drop1<T extends any[]> = ((...args: T) => void) extends (a1: any, ...rest: infer R) => void ? R : never

type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any ? R : never
/* eslint-enable @typescript-eslint/no-explicit-any */

export type RegistrationStepsIndexes = Partial<Drop1<RegistrationSteps>>["length"]

export type RegistrationData = UnionToIntersection<RegistrationSteps[number]>

export type RegistrationState = Partial<RegistrationSteps>

export type RegistrationStepProps<T extends RegistrationStepsIndexes> = {
  onNext: (data: RegistrationSteps[T]) => void
  onValid: (valid: boolean) => void
}

// export function isRegistrationStepIndex(index: number): index is RegistrationStepsIndexes {
//   return Number.isInteger(index) && index >= 0 && index <= 3
// }

export const registrationFormId = (step: RegistrationStepsIndexes) => `registration-form-step-${step}`
