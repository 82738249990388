import { Include, ObjectTypedKeys } from ".."

export type ReportKeyType = string
export type ReportSheetKeyType = Record<string, ReportKeyType>

export type ReportPrettyColumnMap<TReportKeys extends ReportKeyType> = Record<TReportKeys, string>
export type ReportPrettySheetMap<TReportSheetKeys extends ReportSheetKeyType> = {
  [S in keyof TReportSheetKeys]: {
    defaultDisplayName: string
    columns: ReportPrettyColumnMap<TReportSheetKeys[S]>
  }
}

type ReportPrettySheetMapToSheetKeyType<T extends ReportPrettySheetMap<ReportSheetKeyType>> = {
  [S in keyof T]: keyof T[S]["columns"]
}

type BaseWorkspace = {
  timezone: string
  defaultCurrencySymbol: string
}

type GetReportColumnMap<T extends ReportKeyType = ReportKeyType> = (
  workspace: BaseWorkspace,
) => ReportPrettyColumnMap<T>

export const defaultSheetName = "default"

export type GetReportSheetMap<T extends ReportSheetKeyType = ReportSheetKeyType> = (
  workspace: BaseWorkspace,
) => ReportPrettySheetMap<T> | Promise<ReportPrettySheetMap<T>>

// Helper to catpure the return type (instead of Record<string, string> we have list of key)
const makeColumnFunction =
  <T extends ReportKeyType>(input: GetReportColumnMap<T>): GetReportSheetMap<Record<typeof defaultSheetName, T>> =>
  (...args) => ({
    [defaultSheetName]: {
      defaultDisplayName: "Default",
      columns: input(...args),
    },
  })

// Helper to catpure the return type
const makeSheets = <T extends GetReportSheetMap>(map: T): T => map

const getCryptioBankStatementColumns = makeColumnFunction(({ timezone, defaultCurrencySymbol }) => ({
  date: `Date (${timezone})`,
  amountUsd: "Amount (USD)",
  amountFiat: `Amount (${defaultCurrencySymbol})`,
  payee: "Payee",
  description: "Description",
  reference: "Reference",
  chequeNumber: "Cheque number",
  transactionHash: "Transaction hash",
  assetToUsdRate: "Asset to USD rate",
  usdToFiatRate: `USD to ${defaultCurrencySymbol} rate`,
}))

const getCryptioBalanceBreakdownColumns = makeColumnFunction(({ timezone, defaultCurrencySymbol }) => ({
  date: `Date (${timezone})`,
  sourceName: "Source name",
  asset: "Asset",
  assetUniqueSymbol: "Asset unique symbol",

  in: "In",
  out: "Out",
  balance: "Balance",
  borrowBalance: "Borrow balance",

  inUsd: "In (USD)",
  outUsd: "Out (USD)",
  inventoryUsd: `inventory (USD)`,
  avgDailyPriceUsd: "Average Daily Price (USD)",
  marketValueUsd: "Assets market value (USD)",
  unrealizedGainLossUsd: "Unrealized gain/loss (USD)",

  borrowInventoryUsd: "Borrow inventory (USD)",
  borrowMarketValueUsd: "Borrow market value (USD)",
  borrowUnrealizedGainLossUsd: "Borrow unrealized gain/loss (USD)",

  inFiat: `In (${defaultCurrencySymbol})`,
  outFiat: `Out (${defaultCurrencySymbol})`,
  inventoryFiat: `inventory (${defaultCurrencySymbol})`,
  avgDailyPriceFiat: `Average Daily Price (${defaultCurrencySymbol})`,
  marketValueFiat: `Assets market value (${defaultCurrencySymbol})`,
  unrealizedGainLossFiat: `Unrealized gain/loss (${defaultCurrencySymbol})`,

  borrowInventoryFiat: `Borrow inventory (${defaultCurrencySymbol})`,
  borrowMarketValueFiat: `Borrow market value (${defaultCurrencySymbol})`,
  borrowUnrealizedGainLossFiat: `Borrow unrealized gain/loss (${defaultCurrencySymbol})`,
}))

const getCryptioBalanceTotalPerDayColumns = makeColumnFunction(({ timezone, defaultCurrencySymbol }) => ({
  date: `Date (${timezone})`,
  sourceName: "Source name",
  asset: "Asset",
  assetUniqueSymbol: "Asset unique symbol",

  totalIn: "Total In",
  totalOut: "Total Out",
  balanceAtEndOfDay: "Balance",

  totalInUsd: "Total In (USD)",
  totalOutUsd: "Total Out (USD)",
  inventoryAtEndOfDayUsd: `inventory (USD)`,
  avgDailyPriceUsd: "Average Daily Price (USD)",
  marketValueAtEndOfDayUsd: "Assets market value (USD)",
  unrealizedGainLossAtEndOfDayUsd: "Unrealized gain/loss (USD)",

  totalInFiat: `Total In (${defaultCurrencySymbol})`,
  totalOutFiat: `Total Out (${defaultCurrencySymbol})`,
  inventoryAtEndOfDayFiat: `inventory (${defaultCurrencySymbol})`,
  avgDailyPriceFiat: `Average Daily Price (${defaultCurrencySymbol})`,
  marketValueAtEndOfDayFiat: `Assets market value (${defaultCurrencySymbol})`,
  unrealizedGainLossAtEndOfDayFiat: `Unrealized gain/loss (${defaultCurrencySymbol})`,
}))

const getCryptioPortfolioColumns = makeColumnFunction(({ timezone, defaultCurrencySymbol }) => ({
  date: `Date (${timezone})`,
  sourceName: "Source",
  asset: "Asset",
  assetUniqueSymbol: "Asset unique symbol",

  balance: "Balance",

  inventoryUsd: `inventory (USD)`,
  avgDailyPriceUsd: "Average Daily Price (USD)",
  marketValueUsd: "Assets market value (USD)",
  unrealizedGainLossUsd: "Unrealized gain/loss (USD)",

  inventoryFiat: `inventory (${defaultCurrencySymbol})`,
  avgDailyPriceFiat: `Average Daily Price (${defaultCurrencySymbol})`,
  marketValueFiat: `Assets market value (${defaultCurrencySymbol})`,
  unrealizedGainLossFiat: `Unrealized gain/loss (${defaultCurrencySymbol})`,
}))

const getCryptioCurrentBalancesColumns = makeColumnFunction(({ defaultCurrencySymbol }) => ({
  asset: "Asset",
  assetUniqueSymbol: "Asset unique symbol",

  currentBalance: "Current balance",

  avgDailyPriceUsd: "Average Daily Price (USD)",
  marketValueUsd: "Assets market value (USD)",

  avgDailyPriceFiat: `Average Daily Price (${defaultCurrencySymbol})`,
  marketValueFiat: `Assets market value (${defaultCurrencySymbol})`,
}))

const getCryptioEndOfDayBalances = makeColumnFunction(({ defaultCurrencySymbol }) => ({
  asset: "Asset",
  assetUniqueSymbol: "Asset unique symbol",

  endOfDayBalance: "End of day balance",

  avgDailyPriceUsd: "Average Daily Price (USD)",
  marketValueUsd: "Assets market value (USD)",

  avgDailyPriceFiat: `Average Daily Price (${defaultCurrencySymbol})`,
  marketValueFiat: `Assets market value (${defaultCurrencySymbol})`,
}))

const getCryptioMovementColumns = makeColumnFunction(({ timezone, defaultCurrencySymbol }) => ({
  date: `Date (${timezone})`,
  sourceName: "Source name",

  txhash: "Hash / unique id",

  type: "Movement type",

  assetSymbol: "Asset symbol",
  assetUniqueSymbol: "Asset unique symbol",
  volume: "Volume",

  movementValueUsd: `Movement value (USD)`,
  costBasisUsd: `Cost basis (USD)`,
  gainUsd: `Gain (USD)`,

  assetToUsd: `Asset to USD`,

  movementValueFiat: `Movement value (${defaultCurrencySymbol})`,
  costBasisFiat: `Cost basis (${defaultCurrencySymbol})`,
  gainFiat: `Gain (${defaultCurrencySymbol})`,

  usdToFiat: `USD to ${defaultCurrencySymbol}`,
  assetToFiat: `Asset to ${defaultCurrencySymbol}`,

  labels: "Labels",
  otherParty: "Other party",
  otherPartyAlias: "Other party alias (contacts)",

  invoiceRef: "Invoice reference",

  isInternalTransfer: "Is internal transfer",
  internalTransferCostBasisUsd: `Internal transfer cost basis (USD)`,
  internalTransferCostBasisFiat: `Internal transfer cost basis (${defaultCurrencySymbol})`,

  notes: "Notes",

  cryptioTransactionId: "Cryptio transaction ID",
  cryptioMovementId: "Cryptio movement ID",
}))

const getChainlinkNodeOperatorTransactionColumns = makeColumnFunction(({ timezone, defaultCurrencySymbol }) => ({
  date: `Date (${timezone})`,
  sourceName: "Source name",

  txhash: "Hash / unique id",

  orderType: "Order type",

  incomingAsset: "Incoming asset",
  incomingAssetUniqueSymbol: "Incoming asset unique symbol",
  incomingVolume: "Incoming volume",

  outgoingAsset: "Outgoing asset",
  outgoingAssetUniqueSymbol: "Outgoing asset unique symbol",
  outgoingVolume: "Outgoing volume",

  feeAsset: "Fee asset",
  feeAssetUniqueSymbol: "Fee asset unique symbol",
  feeVolume: "Fee volume",

  transactionValueUsd: `Transaction value (USD)`,
  costBasisUsd: `Cost basis (USD)`,
  gainUsd: `Gain (USD)`,

  feeValueUsd: `Fee value (USD)`,
  feeCostBasisUsd: `Fee cost basis (USD)`,
  feeGainUsd: `Fee gain (USD)`,

  incomingToUsd: `Incoming asset to USD`,
  outgoingToUsd: `Outgoing asset to USD`,
  feeToUsd: `Fee asset to USD`,

  transactionValueFiat: `Transaction value (${defaultCurrencySymbol})`,
  costBasisFiat: `Cost basis (${defaultCurrencySymbol})`,
  gainFiat: `Gain (${defaultCurrencySymbol})`,

  feeValueFiat: `Fee value (${defaultCurrencySymbol})`,
  feeCostBasisFiat: `Fee cost basis (${defaultCurrencySymbol})`,
  feeGainFiat: `Fee gain (${defaultCurrencySymbol})`,

  usdToFiat: `USD to ${defaultCurrencySymbol}`,
  incomingToFiat: `Incoming asset to ${defaultCurrencySymbol}`,
  outgoingToFiat: `Outgoing asset to ${defaultCurrencySymbol}`,
  feeToFiat: `Fee asset to ${defaultCurrencySymbol}`,

  labels: "Labels",
  otherParties: "Other parties",
  otherPartiesAliases: "Other parties aliases (contacts)",

  invoiceRef: "Invoice reference",

  internalTransfer: "Internal transfer",
  internalTransferCostBasisUsd: `Internal transfer cost basis (USD)`,
  internalTransferCostBasisFiat: `Internal transfer cost basis (${defaultCurrencySymbol})`,

  success: "Success",
  notes: "Notes",

  cryptioId: "Cryptio ID",

  ethLink: "ETH/LINK",
}))

const getCryptioUnidentifiedAddressesColumns = makeColumnFunction(() => ({
  name: "name",
  address: "address",
}))

const getCryptioFireblocksMissingAddressesColumns = makeColumnFunction(() => ({
  name: "name",
  address: "address",
}))

const getCryptioLedgerColumns = makeColumnFunction(({ timezone, defaultCurrencySymbol }) => ({
  id: "Id",
  label: "Label",
  date: `Date (${timezone})`,
  accountNumber: "Account_number",
  accountName: "Account_name",
  debitUsd: "Debit (USD)",
  creditUsd: "Credit (USD)",
  debitFiat: `Debit (${defaultCurrencySymbol})`,
  creditFiat: `Credit (${defaultCurrencySymbol})`,
  orderType: "Order type",
  sourceName: "Source name",
  otherParties: "Other parties",
  otherPartiesAliases: "Other parties aliases (contacts)",
  notes: "Notes",
  transactionHash: "Transaction hash",
  transactionId: "Transaction ID",
  error: "Error",
}))

const getQuickBooksLedgerColumns = makeColumnFunction(() => ({
  JournalNo: "JournalNo",
  JournalDate: "JournalDate",
  Memo: "Memo",
  AccountName: "AccountName",
  Debits: "Debits",
  Credits: "Credits",
  Description: "Description",
  Name: "Name",
  Location: "Location",
  Class: "Class",
  Error: "Error",
}))

const getXeroLedgerColumns = makeColumnFunction(() => ({
  Number: "Number",
  Narration: "*Narration",
  Date: "*Date",
  Description: "Description",
  AccountCode: "*AccountCode",
  TaxRate: "*TaxRate",
  Amount: "*Amount",
  Error: "Error",
}))

const getCryptioCostBasisColumns = makeColumnFunction(({ timezone, defaultCurrencySymbol }) => ({
  dateAcquired: `Date Acquired (${timezone})`,
  dateSold: `Date Sold (${timezone})`,
  term: "Term",

  type: "Transaction type",

  asset: "Asset",
  assetUniqueSymbol: "Asset unique symbol",
  volume: "Volume",
  balance: "Balance",

  inventoryUsd: "Inventory (USD)",
  costBasisUsd: "Cost Basis (USD)",
  proceedsUsd: "Proceeds (USD)",
  gainOrLossUsd: "Gain or Loss (USD)",

  inventoryFiat: `Inventory (${defaultCurrencySymbol})`,
  costBasisFiat: `Cost Basis (${defaultCurrencySymbol})`,
  proceedsFiat: `Proceeds (${defaultCurrencySymbol})`,
  gainOrLossFiat: `Gain or Loss (${defaultCurrencySymbol})`,

  otherParty: "Other party",
  labels: "Labels",
  sourceName: "Source name",
  sourceType: "Source type",
  txhash: "Hash",
  explorerLink: "Explorer link",
  error: "Error",
}))

const getUsGouvCostBasisColumns = makeColumnFunction(() => ({
  description: "Description (a)",
  dateAcquired: "Date Acquired(b)",
  dateSold: "Date Sold (c)",
  proceeds: "Proceeds (d)",
  costBasis: "Cost Basis(e)",
  adjustementCode: "Adjustment Code (f)",
  adjustementAmount: "Adjustment amount(g)",
  gainOrLoss: "Gain or loss(h)",
  labels: "Labels",
  error: "Error",
}))

const getSummarizedLedgerColumns = makeColumnFunction(({ timezone, defaultCurrencySymbol }) => ({
  date: `Date (${timezone})`,
  accountName: "Account name",
  debitUsd: "Debit (USD)",
  creditUsd: "Credit (USD)",
  debitFiat: `Debit (${defaultCurrencySymbol})`,
  creditFiat: `Credit (${defaultCurrencySymbol})`,
}))

const getCryptioContactsColumns = makeColumnFunction(() => ({
  name: "Name",
  address: "Address",
}))

const getCryptioAssetsColumns = makeColumnFunction(() => ({
  name: "Name",
  uniqueSymbol: "Unique symbol",
}))

const getCryptioTransactionColumns = makeColumnFunction(({ timezone, defaultCurrencySymbol }) => ({
  date: `Date (${timezone})`,
  sourceName: "Source name",

  txhash: "Hash / unique id",

  orderType: "Order type",

  incomingAsset: "Incoming asset",
  incomingAssetUniqueSymbol: "Incoming asset unique symbol",
  incomingVolume: "Incoming volume",

  outgoingAsset: "Outgoing asset",
  outgoingAssetUniqueSymbol: "Outgoing asset unique symbol",
  outgoingVolume: "Outgoing volume",

  feeAsset: "Fee asset",
  feeAssetUniqueSymbol: "Fee asset unique symbol",
  feeVolume: "Fee volume",

  transactionValueUsd: `Transaction value (USD)`,
  costBasisUsd: `Cost basis (USD)`,
  gainUsd: `Gain (USD)`,

  feeValueUsd: `Fee value (USD)`,
  feeCostBasisUsd: `Fee cost basis (USD)`,
  feeGainUsd: `Fee gain (USD)`,

  incomingToUsd: `Incoming asset to USD`,
  outgoingToUsd: `Outgoing asset to USD`,
  feeToUsd: `Fee asset to USD`,

  transactionValueFiat: `Transaction value (${defaultCurrencySymbol})`,
  costBasisFiat: `Cost basis (${defaultCurrencySymbol})`,
  gainFiat: `Gain (${defaultCurrencySymbol})`,

  feeValueFiat: `Fee value (${defaultCurrencySymbol})`,
  feeCostBasisFiat: `Fee cost basis (${defaultCurrencySymbol})`,
  feeGainFiat: `Fee gain (${defaultCurrencySymbol})`,

  usdToFiat: `USD to ${defaultCurrencySymbol}`,
  incomingToFiat: `Incoming asset to ${defaultCurrencySymbol}`,
  outgoingToFiat: `Outgoing asset to ${defaultCurrencySymbol}`,
  feeToFiat: `Fee asset to ${defaultCurrencySymbol}`,

  labels: "Labels",
  otherParties: "Other parties",
  otherPartiesAliases: "Other parties aliases (contacts)",

  invoiceRef: "Invoice reference",

  internalTransfer: "Internal transfer",
  internalTransferCostBasisUsd: `Internal transfer cost basis (USD)`,
  internalTransferCostBasisFiat: `Internal transfer cost basis (${defaultCurrencySymbol})`,

  success: "Success",
  notes: "Notes",

  cryptioId: "Cryptio ID",
}))

const getConsensysTransactionColumns = makeColumnFunction(({ timezone }) => ({
  date: `Date (${timezone})`,
  sourceName: "Source name",
  txhash: "Hash / unique id",
  orderType: "Order type",
  incomingAsset: "Incoming asset",
  incomingAssetUniqueSymbol: "Incoming asset unique symbol",
  incomingVolume: "Incoming volume",
  incomingAssetPrice: "Incoming asset price",
  outgoingAsset: "Outgoing asset",
  outgoingAssetUniqueSymbol: "Outgoing asset unique symbol",
  outgoingVolume: "Outgoing volume",
  outgoingAssetPrice: "Outgoing asset price",
  feeAsset: "Fee asset",
  feeAssetUniqueSymbol: "Fee asset unique symbol",
  feeVolume: "Fee volume",
  feeAssetPrice: "Fee asset price",
  feeBookValue: "Fee book value",
  feeValue: "Fee value",
  feeGainLoss: "Fee gain/loss",
  feeRealizedGainLoss: "Fee gain/loss (Tax basis)",
  transactionValue: "Transaction value",
  transactionBookValue: "Transaction book value",
  transactionGainLoss: "Transaction gain/loss",
  transactionRealizedGainLoss: "Transaction gain/loss (Tax basis)",
  bookValueImpact: "Book value impact",
  taxBasisImpact: "Book value impact (Tax basis)",
  labels: "Labels",
  otherParties: "Other parties",
  otherPartiesAliases: "Other parties aliases (contacts)",
  invoiceRef: "Invoice reference",
  internalTransfer: "Internal transfer",
  internalTransferBookValue: "Internal transfer book value",
  success: "Success",
  notes: "Notes",
  cryptioId: "Cryptio ID",
}))

const getCryptioTransfersStatementColumns = makeColumnFunction(({ timezone, defaultCurrencySymbol }) => ({
  date: `Date (${timezone})`,
  sourceName: "Source name",

  txhash: "Hash / unique id",

  orderType: "Order type",

  incomingAsset: "Incoming asset",
  incomingAssetUniqueSymbol: "Incoming asset unique symbol",
  incomingVolume: "Incoming volume",

  outgoingAsset: "Outgoing asset",
  outgoingAssetUniqueSymbol: "Outgoing asset unique symbol",
  outgoingVolume: "Outgoing volume",

  transactionValueUsd: `Transaction value (USD)`,

  incomingToUsd: `Incoming asset to USD`,
  outgoingToUsd: `Outgoing asset to USD`,

  transactionValueFiat: `Transaction value (${defaultCurrencySymbol})`,

  usdToFiat: `USD to ${defaultCurrencySymbol}`,
  incomingToFiat: `Incoming asset to ${defaultCurrencySymbol}`,
  outgoingToFiat: `Outgoing asset to ${defaultCurrencySymbol}`,

  labels: "Labels",
  otherParties: "Other parties",
  otherPartiesAliases: "Other parties aliases (contacts)",

  internalTransfer: "Internal transfer",
  internalTransferCostBasisUsd: `Internal transfer cost basis (USD)`,
  internalTransferCostBasisFiat: `Internal transfer cost basis (${defaultCurrencySymbol})`,

  success: "Success",
  notes: "Notes",

  cryptioId: "Cryptio ID",
}))

const getNewTransactionColumns = makeColumnFunction(({ timezone, defaultCurrencySymbol }) => ({
  date: `Date (${timezone})`,
  sourceName: "Source name",
  txhash: "Hash / unique id",
  orderType: "Order type",

  incomingAsset: "Incoming asset",
  incomingAssetUniqueSymbol: "Incoming asset unique symbol",
  incomingVolume: "Incoming volume",
  incomingAssetPrice: `Incoming asset price (${defaultCurrencySymbol})`,

  outgoingAsset: "Outgoing asset",
  outgoingAssetUniqueSymbol: "Outgoing asset unique symbol",
  outgoingVolume: "Outgoing volume",
  outgoingAssetPrice: `Outgoing asset price (${defaultCurrencySymbol})`,

  feeAsset: "Fee asset",
  feeAssetUniqueSymbol: "Fee asset unique symbol",
  feeVolume: "Fee volume",
  feeAssetPrice: `Fee asset price ${defaultCurrencySymbol}`,
  feeBookValue: `Fee book value ${defaultCurrencySymbol}`,
  feeValue: `Fee value ${defaultCurrencySymbol}`,
  feeGainLoss: `Fee gain/loss ${defaultCurrencySymbol}`,

  transactionValue: `Transaction value (${defaultCurrencySymbol})`,
  transactionBookValue: `Transaction book value (${defaultCurrencySymbol})`,
  transactionGainLoss: `Transaction gain/loss (${defaultCurrencySymbol})`,
  bookValueImpact: `Book value impact (${defaultCurrencySymbol})`,

  labels: "Labels",
  otherParties: "Other parties",
  otherPartiesAliases: "Other parties aliases (contacts)",
  invoiceRef: "Invoice reference",

  internalTransfer: "Internal transfer",
  internalTransferBookValue: `Internal transfer book value (${defaultCurrencySymbol})`,

  success: "Success",
  notes: "Notes",
  cryptioId: "Cryptio ID",
}))

const getCustomTransactionColumns = makeColumnFunction(() => ({
  transactionDate: `transactionDate`,
  orderType: "orderType",
  txhash: "txhash",

  incomingAsset: "incomingAsset",
  incomingVolume: "incomingVolume",

  outgoingAsset: "outgoingAsset",
  outgoingVolume: "outgoingVolume",

  feeAsset: "feeAsset",
  feeVolume: "feeVolume",

  otherParties: "otherParties",

  note: "note",
  success: "success",

  internalTransfer: "internalTransfer",
  walletName: "walletName",
}))

const getFireblocksMonthEndInvoicingColumns = makeColumnFunction(({ timezone }) => ({
  date: `Date (${timezone})`,
  assetSymbol: "Asset symbol",
  openingPrice: "Opening price",
  openingBalance: "Opening balance",
  assetMarketValue: "Asset market value",
  dailyBpsPercent: "Daily bps%",
  dailyBpsEarned: "Daily bps earned",
}))

const getConsensysAssetRollForwardColumns = makeColumnFunction(() => ({
  sourceName: "Source Name",
  assetSymbol: "Token",
  assetUniqueSymbol: "Token unique symbol",
  hasAtLeastOneMissingPrice: "Missing price / Cost basis",
}))

const getNewConsensysAssetRollForwardColumns = makeColumnFunction(() => ({
  assetSymbol: "Token",
  assetUniqueSymbol: "Token unique symbol",
  hasAtLeastOneMissingPrice: "Missing price / Cost basis",
}))

const getConsensysProviderFeeHistoricalBalancesColumns = makeColumnFunction(({ timezone }) => ({
  date: `Date (${timezone})`,
  sourceName: "Source name",
  asset: "Asset",
  assetUniqueSymbol: "Asset unique symbol",

  in: "In",
  in85percent: "In - 85%",
  inProvider: "In - Provider",

  out85percent: "Out - 85%",
  outProvider: "Out - Provider",
  out: "Out",

  balance: "Balance",
  balance85percent: "Balance - 85%",
  providerBalance: "Provider balance",

  inUsd: "In (USD)",
  in85percentUsd: "In (USD) - 85%",
  inProviderUsd: "In (USD) - Provider",

  outUsd: "Out (USD)",
  out85percentUsd: "Out (USD) - 85%",
  outProviderUsd: "Out (USD) - Provider",

  inventoryInUsd: "In (USD) - Inventory",
  inventoryIn85percentUsd: "In (USD) - Inventory - 85%",
  inventoryInProviderUsd: "In (USD) - Inventory - Provider",
  inventoryOutUsd: "Out (USD) - Inventory",
  inventoryOut85percentUsd: "Out (USD) - Inventory - 85%",
  inventoryOutProviderUsd: "Out (USD) - Inventory - Provider",
  inventoryInImpairedUsd: "In (USD) - Inventory - Impaired",
  inventoryIn85percentImpairedUsd: "In (USD) - Inventory - 85% - Impaired",
  inventoryInProviderImpairedUsd: "In (USD) - Inventory - Provider - Impaired",
  inventoryOutImpairedUsd: "Out (USD) - Inventory - Impaired",
  inventoryOut85percentImpairedUsd: "Out (USD) - Inventory - 85% - Impaired",
  inventoryOutProviderImpairedUsd: "Out (USD) - Inventory - Provider - Impaired",

  inventoryUsd: `inventory (USD)`,
  inventoryImpairedUsd: `inventory (USD) - Impaired`,
  inventory85percentUsd: `inventory (USD) - 85%`,
  inventory85percentImpairedUsd: `inventory (USD) - 85% - Impaired`,
  inventoryProviderUsd: `inventory (USD) - Provider`,
  inventoryProviderImpairedUsd: `inventory (USD) - Provider - Impaired`,
  avgDailyPriceUsd: "Average Daily Price (USD)",
  marketValueUsd: "Assets market value (USD)",
  marketValue85percentUsd: "Assets market value (USD) - 85%",
  marketValueProviderUsd: "Assets market value (USD) - Provider",
  unrealizedGainLossUsd: "Unrealized gain/loss (USD)",
  unrealizedGainLossImpairedUsd: "Unrealized gain/loss (USD) - Impaired",
  unrealizedGainLoss85percentUsd: "Unrealized gain/loss (USD) - 85%",
  unrealizedGainLoss85percentImpairedUsd: "Unrealized gain/loss (USD) - 85% - Impaired",
  unrealizedGainLossProviderUsd: "Unrealized gain/loss (USD) - Provider",
  unrealizedGainLossProviderImpairedUsd: "Unrealized gain/loss (USD) - Provider - Impaired",
}))

const getConsensysAssetBreakdownRecap = makeColumnFunction(({ timezone }) => ({
  date: `Date (${timezone})`,
  sourceName: "Source name",
  tokenName: "Token Name",
  balance: "Balance",
  inventoryCostUsd: "Inventory Cost (USD)",
  fiarMarketValueUsd: "Fair Market Value (USD)",
  inventoryCostImpairedUsd: "Inventory Cost Impaired (USD)",
  impairedBalanceAssetUsd: "Impaired Asset Balance (USD)",
}))

const getImpairmentUniversalTransactionDetailColumns = makeColumnFunction(({ timezone, defaultCurrencySymbol }) => ({
  date: `Date (${timezone})`,
  assetSymbol: "Asset symbol",
  assetUniqueSymbol: "Asset unique symbol",
  assetVolume: "Asset volume",

  dailyPriceUsd: "Daily price (USD)",
  periodLastPriceUsd: "Period last price (USD)",
  transactionValueUsd: "Transaction value (USD)",
  endPeriodValueUsd: "End period value (USD)",
  gapsCostBasisUsd: "Gaps cost basis (USD)",

  dailyPriceFiat: `Daily price (${defaultCurrencySymbol})`,
  periodLastPriceFiat: `Period last price (${defaultCurrencySymbol})`,
  transactionValueFiat: `Transaction value (${defaultCurrencySymbol})`,
  endPeriodValueFiat: `End period value (${defaultCurrencySymbol})`,
  gapsCostBasisFiat: `Gaps cost basis (${defaultCurrencySymbol})`,

  transactionHash: "Transaction hash",
}))

const getImpairmentPerWalletTransactionDetailColumns = makeSheets(({ timezone, defaultCurrencySymbol }) => ({
  transaction_detail: {
    defaultDisplayName: "Transaction Detail",
    columns: {
      date: `Date (${timezone})`,
      sourceName: "Source name",
      assetSymbol: "Asset symbol",
      assetUniqueSymbol: "Asset unique symbol",
      assetVolume: "Asset volume",

      dailyPriceUsd: "Daily price (USD)",
      periodLastPriceUsd: "Period last price (USD)",
      transactionValueUsd: "Transaction value (USD)",
      endPeriodValueUsd: "End period value (USD)",
      gapsCostBasisUsd: "Gaps cost basis (USD)",

      dailyPriceFiat: `Daily price (${defaultCurrencySymbol})`,
      periodLastPriceFiat: `Period last price (${defaultCurrencySymbol})`,
      transactionValueFiat: `Transaction value (${defaultCurrencySymbol})`,
      endPeriodValueFiat: `End period value (${defaultCurrencySymbol})`,
      gapsCostBasisFiat: `Gaps cost basis (${defaultCurrencySymbol})`,

      transactionHash: "Transaction hash",
    },
  },
  recap: {
    defaultDisplayName: "Impairments",
    columns: {
      startDate: "Start date",
      endDate: "End date",
      estimatedTotalValue: `Total impairments to date (${defaultCurrencySymbol})`,
      estimatedUnsoldValue: `Total impairments of current assets (${defaultCurrencySymbol})`,
      appliedAt: "Applied at",
    },
  },
}))

const getWacUniversalImpairmentColumns = makeColumnFunction(() => ({
  assetSymbol: "Asset symbol",
  assetUniqueSymbol: "Asset unique symbol",
  holdingVolume: "Holding (volume)",

  lastBalanceUsd: "Last Balance (USD)",
  wacPerUnitUsd: "WAC per unit (USD)",
  wacUsd: "WAC (USD)",
  lastUnitPriceUsd: "Last unit price of the period (USD)",
  lastValueUsd: "Last value of the period (USD)",
  impairmentUsd: "Impairment (USD)",
}))

const getWacPerWalletImpairmentColumns = makeColumnFunction(() => ({
  sourceName: "Source name",
  assetSymbol: "Asset symbol",
  assetUniqueSymbol: "Asset unique symbol",
  holdingVolume: "Holding (volume)",

  lastBalanceUsd: "Last Balance (USD)",
  wacPerUnitUsd: "WAC per unit (USD)",
  wacUsd: "WAC (USD)",
  lastUnitPriceUsd: "Last unit price of the period (USD)",
  lastValueUsd: "Last value of the period (USD)",
  impairmentUsd: "Impairment (USD)",
}))

const getImpairmentPerWalletBalanceColumns = makeSheets(({ defaultCurrencySymbol }) => ({
  balances: {
    defaultDisplayName: "Balances",
    columns: {
      sourceName: "Source name",
      currentAssets: "Current assets:",
      assetUniqueSymbol: "Asset unique symbol",

      impairedUnits: "Impaired units",
      impairments: "Impairments",

      emptyColumn: "",
      previousImpairmentValue: "Previous Impairment Value",
      totalImpairmentValue: "Total Impairment Value",
    },
  },
  recap: {
    defaultDisplayName: "Impairments",
    columns: {
      startDate: "Start date",
      endDate: "End date",
      estimatedTotalValue: `Total impairments to date (${defaultCurrencySymbol})`,
      estimatedUnsoldValue: `Total impairments of current assets (${defaultCurrencySymbol})`,
      appliedAt: "Applied at",
    },
  },
}))

const getImpairmentUniversalBalanceColumns = makeSheets(({ defaultCurrencySymbol }) => ({
  balances: {
    defaultDisplayName: "Balances",
    columns: {
      currentAssets: "Current assets:",
      assetUniqueSymbol: "Asset unique symbol",

      beginningUnits: "Beginning Units",
      beginningBalance: "Beg. Balance",
      unitDeposits: "Unit Deposits",
      unitWithdrawals: "Unit Withdrawals",
      deposits: "Deposits",
      withdrawals: "Withdrawals",
      impairedUnits: "Impaired units",
      impairments: "Impairments",
      endingBalance: "Ending Balance",
      endingUnits: "Ending Units",

      emptyColumn: "",
      previousImpairmentValue: "Previous Impairment Value",
      totalImpairmentValue: "Total Impairment Value",
    },
  },
  recap: {
    defaultDisplayName: "Impairments",
    columns: {
      startDate: "Start date",
      endDate: "End date",
      estimatedTotalValue: `Total impairments to date (${defaultCurrencySymbol})`,
      estimatedUnsoldValue: `Total impairments of current assets (${defaultCurrencySymbol})`,
      appliedAt: "Applied at",
    },
  },
}))

const getImpairmentRecapColumns = makeColumnFunction(({ defaultCurrencySymbol }) => ({
  startDate: "Start date",
  endDate: "End date",
  estimatedTotalValue: `Total impairments to date (${defaultCurrencySymbol})`,
  estimatedUnsoldValue: `Total impairments of current assets (${defaultCurrencySymbol})`,
  isApplied: "Is applied",
}))

const getCryptioAssetRollForwardColumns = makeColumnFunction(({ defaultCurrencySymbol }) => ({
  sourceName: "Wallet",
  assetSymbol: "Asset symbol",
  assetUniqueSymbol: "Asset unique symbol",
  beginningUnits: "Beginning Units",
  beginningBalance: `Beginning Balance (${defaultCurrencySymbol})`,
  unitDeposits: "Unit Deposits",
  unitWithdrawals: "Unit Withdrawals",
  fiatDeposits: `Deposits (${defaultCurrencySymbol})`,
  fiatWithdrawals: `Withdrawals (${defaultCurrencySymbol})`,
  endingUnits: "Ending Units",
  endingBalance: `Ending Balance (${defaultCurrencySymbol})`,
}))

const feesExpendituresSheets = makeSheets(({ defaultCurrencySymbol }) => ({
  feesList: {
    defaultDisplayName: "Fees list",
    columns: {
      date: "Date",
      assetSymbol: "Asset",
      source: "Source",
      volume: "Volume",
      assetPriceUsd: "Asset Price (USD)",
      assetPriceFiat: `Asset Price (${defaultCurrencySymbol})`,
      spentUsd: "Spent (USD)",
      spentFiat: `Spent (${defaultCurrencySymbol})`,
      txhash: "Transaction hash",
      cryptioId: "Cryptio ID",
    },
  },
  totalFees: {
    defaultDisplayName: "Total fees spent",
    columns: {
      assetSymbol: "Asset",
      volume: "Volume",
      spentUsd: "Spent (USD)",
      spentFiat: `Spent (${defaultCurrencySymbol})`,
      count: "Number of transactions",
    },
  },
}))

const sanityCheckSheets = makeSheets(() => ({
  rawData: {
    defaultDisplayName: "Raw Data",
    columns: {
      walletName: "Wallet Name",
      walletType: "Wallet Type",
      walletAddress: "Wallet Address",
      assetUniqueSymbol: "Asset Unique Symbol",
      assetAddress: "Asset Address",
      remoteBalance: "Remote Balance",
      cryptioBalance: "Cryptio Balance",
      difference: "Difference",
      differenceUsdValuation: "Difference USD Valuation",
      remoteBalanceUsdValuation: "Remote Balance USD Valuation",
    },
  },
  readMe: {
    defaultDisplayName: "README",
    columns: {
      tutorial: "Tutorial",
    },
  },
}))

const highestMissingVolumeColumns = makeColumnFunction(() => ({
  walletName: "Wallet Name",
  walletType: "Wallet Type",
  assetName: "Asset Name",
  assetSymbol: "Asset Symbol",
  assetUniqueSymbol: "Asset Unique Symbol",
  volume: "Volume",
}))

const getSourcesReportColumns = makeColumnFunction(() => ({
  name: "Source name",
  type: "Type",
  address: "Address",
  importDate: "Import date",
  transactionsCount: "Transactions count",
}))

const getConsensysLedgerEntriesColumns = makeSheets(({ defaultCurrencySymbol }) => ({
  ledger_entries: {
    defaultDisplayName: "Ledger entries",
    columns: {
      date: "Date",
      sourceName: "Wallet Name",
      type: "Type",
      label: "Label",
      accountNumber: "GL #",
      accountName: "GL Name",
      debitUsd: "DR",
      creditUsd: "CR",
      memo: "Memo",
      name: "Name",
      nonImpairedDebitUsd: "DR (Non impaired)",
      nonImpairedCreditUsd: "CR (Non impaired)",
    },
  },
  recap: {
    defaultDisplayName: "Impairments",
    columns: {
      startDate: "Start date",
      endDate: "End date",
      estimatedTotalValue: `Total impairments to date (${defaultCurrencySymbol})`,
      estimatedUnsoldValue: `Total impairments of current assets (${defaultCurrencySymbol})`,
      appliedAt: "Applied at",
    },
  },
}))

const getCryptioAuditTrailColumns = makeColumnFunction(() => ({
  date: "Name",
  stakeholder: "Stakeholder",
  action: "Action",
  id: "Id",
}))

const getCryptioJournalSequentialNumberingColumns = makeColumnFunction(({ timezone }) => ({
  date: `Date ${timezone}`,
  labels: "Labels",
  transactionHash: "Transaction hash",
  debitAccountName: "Debit account name",
  creditAccountName: "Credit account name",
  debit: "Debit",
  credit: "Credit",
  synchronizationDate: "Synchronization Date",
  sourceName: "Source name",
  otherParties: "Other parties",
  otherPartiesAliases: "Other parties aliases",
  notes: "Notes",
  transactionId: "Transaction ID",
  journalId: "Journal ID",
}))

const trialBalancesReportSheets = makeSheets(() => ({
  summary: {
    defaultDisplayName: "Trial Balances Total Sum",
    columns: {
      accountType: "Accounts",
      debit: "Debit",
      credit: "Credit",
    },
  },
  breakdown: {
    defaultDisplayName: "Breakdown",
    columns: {
      accountCode: "Code",
      account: "Accounts",
      debit: "Debit",
      credit: "Credit",
    },
  },
  missingData: {
    defaultDisplayName: "Missing data",
    columns: {},
  },
}))

const FilecoinRewardTrackingReportSheets = makeSheets(async ({ timezone }) => ({
  tx_history: {
    defaultDisplayName: "Cryptio Tx History Export",
    columns: {
      date: `Date (${timezone})`,
      txhash: "Unique ID",
      assetUniqueSymbol: "Asset Unique Symbol",
      volume: "Volume",
      transactionValue: "Transaction Value",
    },
  },
}))

export type ReportKeysTypeFromColumnMap<TFunc extends GetReportColumnMap> = keyof ReturnType<TFunc>
export type ReportSheetKeysTypeFromSheetMap<TFunc extends GetReportSheetMap> = ReportPrettySheetMapToSheetKeyType<
  Awaited<ReturnType<TFunc>>
>

// This defines the layout of the reports (4 depths: report types, report formats, sheets, columns)
const getReportColumnMapper = <T extends Record<string, Record<string, GetReportSheetMap>>>(map: T) => map
export const getReportColumnMap = getReportColumnMapper({
  total_per_day: { cryptio: getCryptioBalanceTotalPerDayColumns },
  asset_breakdown: { cryptio: getCryptioBalanceBreakdownColumns },
  wallet_breakdown: { cryptio: getCryptioBalanceBreakdownColumns },
  per_day_breakdown: { cryptio: getCryptioBalanceBreakdownColumns },
  portfolio_asset_breakdown: { cryptio: getCryptioPortfolioColumns },
  portfolio_wallet_breakdown: { cryptio: getCryptioPortfolioColumns },
  current_balances: { cryptio: getCryptioCurrentBalancesColumns },
  end_of_day_balances: { cryptio: getCryptioEndOfDayBalances },
  cost_basis: {
    cryptio_universal: getCryptioCostBasisColumns,
    cryptio_per_wallet: getCryptioCostBasisColumns,
    us_gouv: getUsGouvCostBasisColumns,
  },
  ledger: {
    cryptio: getCryptioLedgerColumns,
    xero_us: getXeroLedgerColumns,
    xero_uk: getXeroLedgerColumns,
    xero_fr: getXeroLedgerColumns,
    quickbooks: getQuickBooksLedgerColumns,
  },
  summarized_ledger: {
    cryptio: getSummarizedLedgerColumns,
  },
  transactions: {
    cryptio: getCryptioTransactionColumns,
  },
  new_transaction_history: {
    cryptio: getNewTransactionColumns,
  },
  transactions_custom_export: {
    cryptio: getCustomTransactionColumns,
  },
  movements: {
    cryptio: getCryptioMovementColumns,
  },
  assets: {
    cryptio: getCryptioAssetsColumns,
  },
  chainlink_node_operator: {
    cryptio: getChainlinkNodeOperatorTransactionColumns,
  },
  bank_statement: {
    cryptio: getCryptioBankStatementColumns,
  },
  unidentified_addresses: {
    cryptio: getCryptioUnidentifiedAddressesColumns,
  },
  fireblocks_missing_addresses: {
    cryptio: getCryptioFireblocksMissingAddressesColumns,
  },
  custom: {
    fireblocks_month_end_invoicing: getFireblocksMonthEndInvoicingColumns,
  },
  impairment: {
    wac_universal_impairment: getWacUniversalImpairmentColumns,
    wac_per_wallet_impairment: getWacPerWalletImpairmentColumns,

    recap: getImpairmentRecapColumns,
  },
  impairment_balance: {
    cryptio_per_wallet: getImpairmentPerWalletBalanceColumns,
    cryptio_universal: getImpairmentUniversalBalanceColumns,
  },
  impairment_transaction_detail: {
    cryptio_per_wallet: getImpairmentPerWalletTransactionDetailColumns,
    cryptio_universal: getImpairmentUniversalTransactionDetailColumns,
  },
  contacts: {
    cryptio: getCryptioContactsColumns,
  },
  asset_roll_forward: {
    cryptio: getCryptioAssetRollForwardColumns,
  },
  detailed_asset_roll_forward: {
    cryptio: getCryptioAssetRollForwardColumns,
  },
  fees_expenditures: {
    cryptio: feesExpendituresSheets,
  },
  transfers_statement: {
    cryptio: getCryptioTransfersStatementColumns,
  },
  sources: {
    cryptio: getSourcesReportColumns,
  },
  highest_missing_volume: {
    cryptio: highestMissingVolumeColumns,
  },
  audit_trail: {
    cryptio: getCryptioAuditTrailColumns,
  },
  journal_sequential_numbering: {
    cryptio: getCryptioJournalSequentialNumberingColumns,
  },
  administrator: {
    sanity_check: sanityCheckSheets,
  },
  trial_balances: {
    cryptio: trialBalancesReportSheets,
  },

  filecoin_reward_tracking: {
    cryptio: FilecoinRewardTrackingReportSheets,
  },

  // Consensys
  consensys_transactions: {
    cryptio: getConsensysTransactionColumns,
  },
  consensys_asset_roll_forward: {
    consensys_asset_roll_forward: getConsensysAssetRollForwardColumns,
  },
  consensys_rgl_asset_roll_forward: {
    consensys_rgl_asset_roll_forward: getConsensysAssetRollForwardColumns,
  },
  consensys_asset_roll_forward_tax: {
    consensys_asset_roll_forward_tax: getConsensysAssetRollForwardColumns,
  },
  new_consensys_asset_roll_forward: {
    new_consensys_asset_roll_forward: getNewConsensysAssetRollForwardColumns,
  },
  consensys_provider_fee_historical_balances: {
    consensys_provider_fee_historical_balances: getConsensysProviderFeeHistoricalBalancesColumns,
  },
  consensys_asset_breakdown_recap: {
    consensys_asset_breakdown_recap: getConsensysAssetBreakdownRecap,
  },
  consensys_ledger_entries: {
    consensys_ledger_entries: getConsensysLedgerEntriesColumns,
  },
})

export const ReportTypeNamesArray = ObjectTypedKeys(getReportColumnMap)
export type ReportTypeNamesType = keyof typeof getReportColumnMap

export const ReportTypeNamesAPIArray = ["asset_breakdown", "end_of_day_balances", "transactions"] as const
export type ReportTypeNamesAPIType = Include<typeof ReportTypeNamesAPIArray[number], ReportTypeNamesType>

export type ReportColumnType = typeof getReportColumnMap
export type ReportFormatNamesType = { [K in keyof ReportColumnType]: keyof ReportColumnType[K] }[keyof ReportColumnType]

export type ReportExportFormatType = {
  [K in keyof ReportColumnType]: { type: K; exportFormatName: keyof ReportColumnType[K] & string }
}[keyof ReportColumnType]

export const reportTypeFormatsArray = <T extends ReportTypeNamesType>(type: T) =>
  ObjectTypedKeys(getReportColumnMap[type])

export const ReportModuleNamesArray = [
  "accounting",
  "financial",
  "audit",
  "consensys",
  "filecoin",
  "favorite",
  "admin",
] as const
export type ReportModuleNameType = typeof ReportModuleNamesArray[number]

export const ReportLedgerEntriesTransactionTypesArray = ["all", "bookable", "unbookable"] as const
export type ReportLedgerEntriesTransactionType = typeof ReportLedgerEntriesTransactionTypesArray[number]
export const ReportLedgerEntriesTransactionPrettyMap: Record<ReportLedgerEntriesTransactionType, string> = {
  all: "All transactions type",
  bookable: "Synchronizable transactions only",
  unbookable: "Un-synchronizable Complex transactions only",
}

export const ReportSummarizedLedgerEntriesTimeframeTypesArray = ["day", "week", "month"] as const
export type ReportSummarizedLedgerEntriesTimeframeType = typeof ReportSummarizedLedgerEntriesTimeframeTypesArray[number]
export const ReportSummarizedLedgerEntriesTimeframePrettyMap: Record<
  ReportSummarizedLedgerEntriesTimeframeType,
  string
> = {
  day: "Day",
  week: "Week",
  month: "Month",
}
