import React, { useCallback, useContext, useMemo, useState } from "react"

import { Mixpanel } from "services/mixpanel"

import { DrawerCategory, DrawerTabs } from "components/Drawer/DrawerItems"

import {
  GetTransactionFilterDto,
  NewTransactionPaginationQueryDto,
  TransactionFilterEndDate,
  TransactionFilterStartDate,
} from "services/api/openapi"

import { Box, Tab, Tabs, Typography } from "@material-ui/core"
import useFilterTransaction from "./useFilterTransaction"
import api from "services/api"
import FavoriteTab from "./FavoriteTab"
import { PlainDate } from "components/DateRangePicker"
import FavoriteModale from "./FavoriteTab/FavoriteModal"
import NewFilterTab from "./NewFilterTab"
import TabPanel from "./TabPannel"
import { FilterContext } from "services/context/filterContext"
import DrawerUI from "CryptioUI/Drawer"
import useDialog from "components/misc/useDialog"
import { defaultTransactionParams, newTransactionHasAnyFilter, TransactionParams } from "components/CoreTransaction"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface Props {
  filter: TransactionParams
  setFilter: (newValue: TransactionParams) => void
}

export type FilterType = TransactionParams["filters"][number]["type"]

export type FilterTypeWithDateState = TransactionFilterStartDate | TransactionFilterEndDate

export type FilterTypeWithoutDateState = Exclude<TransactionParams["filters"][number], FilterTypeWithDateState>

export type SpecificFilter<T extends FilterType> = NewTransactionPaginationQueryDto["filters"][number] & { type: T }

export function findFilter<T extends FilterType>({ filters }: TransactionParams, key: T) {
  return filters.find((f) => f.type === key) as SpecificFilter<T> | undefined
}

export function isFilterType<F extends TransactionParams["filters"][number]>(
  filter: TransactionParams["filters"][number],
  type: F["type"],
): filter is F {
  return filter.type === type
}

export function filterTypeFilter<F extends TransactionParams["filters"][number]>(type: F["type"]) {
  return (filter: TransactionParams["filters"][number]): filter is F => filter.type === type
}

export const defaulFilterParams: TransactionParams = { filters: [] }

export type FilterTypeValue = TransactionParams["filters"][number]["value"]
export type FilterTypeIsNot = TransactionParams["filters"][number]["isNot"]

// FIXME: cleanup type
export type TypeMapDate = {
  start_date: SpecificFilter<"start_date">["value"]
  end_date: SpecificFilter<"end_date">["value"]
}

export type TypeMap = {
  wallets: SpecificFilter<"wallets">["value"]
  assets: SpecificFilter<"assets">["value"]
  labels: SpecificFilter<"labels">["value"]
  labels_application: SpecificFilter<"labels_application">["value"]
  contacts: SpecificFilter<"contacts">["value"]
  addresses: SpecificFilter<"addresses">["value"]
  transaction_mapping_status: SpecificFilter<"transaction_mapping_status">["value"]
  transaction_hash: SpecificFilter<"transaction_hash">["value"]
  note: SpecificFilter<"note">["value"]
  valuation_status: SpecificFilter<"valuation_status">["value"]
  cost_basis_error_statuses: SpecificFilter<"cost_basis_error_statuses">["value"]
  internal_transfer_status: SpecificFilter<"internal_transfer_status">["value"]
  nft: SpecificFilter<"nft">["value"]
  accounting_mapping_status: SpecificFilter<"accounting_mapping_status">["value"]
  order_type: SpecificFilter<"order_type">["value"]
  import_type: SpecificFilter<"import_type">["value"]
  fee_type: SpecificFilter<"fee_type">["value"]
  relative_fiat_value: SpecificFilter<"relative_fiat_value">["value"]
  range_fiat_value: SpecificFilter<"range_fiat_value">["value"]
  complexity: SpecificFilter<"complexity">["value"]
  synchronization_status: SpecificFilter<"synchronization_status">["value"]
  synchronization_mode: SpecificFilter<"synchronization_mode">["value"]
  has_invoice: SpecificFilter<"has_invoice">["value"]
  transaction_volume: SpecificFilter<"transaction_volume">["value"]
  transaction_id: SpecificFilter<"transaction_id">["value"]
}

export type FilterTypeWithDate = "start_date" | "end_date"
export type FilterTypeWithoutDate = Exclude<FilterType, FilterTypeWithDate>

const TransactionFilter = (props: Props): JSX.Element => {
  const { setFilter, filter } = props
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [startDateTemp, setStartDateTemp] = useState<PlainDate | undefined>(undefined)
  const [endDateTemp, setEndDateTemp] = useState<PlainDate | undefined>(undefined)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [filterLoaded, setFilterLoaded] = useState<GetTransactionFilterDto | undefined>()
  const { isNeedReview, isTab } = useContext(FilterContext)
  const [isErrorValidation, setIsErrorValidation] = useState<boolean>(false)
  const [isFullHistory, setIsFullHistory] = useState(
    startDateTemp?.toBackendDate() || startDateTemp?.toBackendDate() ? false : true,
  )

  const onSave = () => {
    const startDate = findFilter(tempFilter, "start_date")
    const endDate = findFilter(tempFilter, "end_date")
    if (startDate || endDate) {
      Mixpanel.track("DatePickerFilterTransaction", { startDate, endDate })
    }
  }

  const {
    tempFilter,
    tempFilterDate,
    tempFilterWithoutDate,
    setTempFilterDate,
    setTempFilterWithoutDate,
    OpenButton,
    SaveButton,
  } = useFilterTransaction({
    setFilter,
    filter,
    defaultParams: defaultTransactionParams,
    setIsDrawerOpen,
    onSave,
    setEndDateTemp,
    setStartDateTemp,
    setFilterLoaded,
  })

  const canSaveIsNotFullHistory = !isFullHistory ? startDateTemp !== undefined || endDateTemp !== undefined : true

  const canSave = useMemo(() => {
    const countFilterCanBeSaved = tempFilter.filters
      .map((filter) => filter.value)
      .filter((e) => e !== undefined && !(Array.isArray(e) && e.length === 0)).length
    const canSave = countFilterCanBeSaved > 0 && countFilterCanBeSaved === tempFilter.filters.length

    return canSave
  }, [tempFilter])

  const sendDataToMixpanel = () => {
    if (findFilter(tempFilter, "labels")?.value === null) Mixpanel.track("TransactionFiltersWithNoLabelOnly")
    if (findFilter(tempFilter, "valuation_status")?.value === "noValuation")
      Mixpanel.track("TransactionFiltersWithNoValuation")
    if (findFilter(tempFilter, "cost_basis_error_statuses")?.value?.includes("missing_fiat_rate"))
      Mixpanel.track("TransactionFiltersMissingFiatRate")
    if (findFilter(tempFilter, "cost_basis_error_statuses")?.value?.includes("negative_balance"))
      Mixpanel.track("TransactionFiltersMissingVolume")
  }
  const basicDialog = useDialog()
  const toast = useToast()
  const { mutateAsync: createTransactionFiltersMutation } = api.filter.useCreateFilters()
  const { mutateAsync: deleteTransactionFilterMutation } = api.filter.useDeleteFilter()

  const createFavoriteFilter = async (name: string) => {
    try {
      await createTransactionFiltersMutation({
        createTransactionFiltersDto: { ...tempFilter, name: name },
      })

      toast.open(`Filter ${name} add to favorite`, { variant: "default" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const deleteTransactionFilter = useCallback(
    async (savedFilter: GetTransactionFilterDto) => {
      try {
        await deleteTransactionFilterMutation({
          filterId: savedFilter.id,
        })

        toast.open(`Filter ${savedFilter.name} deleted`, { variant: "default" })
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [deleteTransactionFilterMutation, toast],
  )

  const deleteSavedFilter = useCallback(
    (savedFilter: GetTransactionFilterDto, actionAfterConfirm?: () => void) => {
      if (savedFilter?.isInLabelRule) {
        basicDialog.showDialog({
          title: "Warning?",
          content: (
            <Typography variant="h5">
              You are about to delete a saved filter that it is used on a label rule.
              <br />
              If you delete it, the label rule and all the labels already applied by this rule will be removed as well.
              <br />
              Are you sure you want to delete this saved filter?
            </Typography>
          ),
          yesText: "Confirm",
          noText: "Cancel",
          onAccept: () => {
            deleteTransactionFilter(savedFilter)
            if (actionAfterConfirm) actionAfterConfirm()
          },
        })
      } else {
        deleteTransactionFilter(savedFilter)
        if (actionAfterConfirm) actionAfterConfirm()
      }
    },
    [basicDialog, deleteTransactionFilter],
  )

  const filterCount = useMemo(() => {
    return props.filter.filters.length + (isNeedReview ? (isTab("readyToBeSynchronized") ? -3 : -1) : 0)
  }, [props.filter, isNeedReview, isTab])

  const canClear = filterCount > 0 || newTransactionHasAnyFilter(tempFilter)

  const [tab, setTab] = useState<number>(0)

  const handleChange = (_: any, newTab: number) => {
    setTab(newTab)
  }

  function a11yProps(index: number) {
    return {
      "id": `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  const CustomTabs = () => (
    <>
      <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Filter" {...a11yProps(0)} />
        <Tab label="Favorite filters" {...a11yProps(1)} />
      </Tabs>
    </>
  )

  return (
    <Box display="flex">
      <DrawerUI title="Filter" onClose={() => setIsDrawerOpen(false)} isOpen={isDrawerOpen}>
        {basicDialog.dialog}
        <DrawerCategory mt={1}>
          <DrawerTabs display="flex" flexDirection="column" mt={0} tabs={<CustomTabs />}>
            <TabPanel tab={tab} index={0}>
              <NewFilterTab
                tempFilter={tempFilter}
                tempFilterWithoutDate={tempFilterWithoutDate}
                setTempFilterWithoutDate={setTempFilterWithoutDate}
                tempFilterDate={tempFilterDate}
                setTempFilterDate={setTempFilterDate}
                startDateTemp={startDateTemp}
                setStartDateTemp={setStartDateTemp}
                endDateTemp={endDateTemp}
                setEndDateTemp={setEndDateTemp}
                setIsModalOpen={setIsModalOpen}
                deleteTransactionFilter={deleteSavedFilter}
                setFilterLoaded={setFilterLoaded}
                isFullHistory={isFullHistory}
                setIsFullHistory={setIsFullHistory}
                filterLoaded={filterLoaded}
                isErrorValidation={isErrorValidation}
                setIsErrorValidation={setIsErrorValidation}
                saveButton={
                  <SaveButton
                    onSaveClick={sendDataToMixpanel}
                    filterCount={filterCount}
                    canClear={canClear}
                    canSave={canSave && !isErrorValidation && canSaveIsNotFullHistory}
                  />
                }
                canSave={canSave && !isErrorValidation && canSaveIsNotFullHistory}
              />
            </TabPanel>
            <TabPanel tab={tab} index={1}>
              <FavoriteTab
                deleteTransactionFilter={deleteSavedFilter}
                setValue={setTab}
                setStartDateTemp={setStartDateTemp}
                setEndDateTemp={setEndDateTemp}
                setTempFilterWithoutDate={setTempFilterWithoutDate}
                setFilterLoaded={setFilterLoaded}
              />
            </TabPanel>
          </DrawerTabs>
        </DrawerCategory>
      </DrawerUI>
      <OpenButton filterCount={filterCount} />
      <FavoriteModale
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={(name: string) => {
          createFavoriteFilter(name)
          setIsModalOpen(false)
        }}
      />
    </Box>
  )
}

export default TransactionFilter
