import { useTypedController } from "@hookform/strictly-typed"
import {
  Box,
  FormLabel,
  InputAdornment,
  Link,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core"
import { AccountCircleRounded } from "@material-ui/icons"
import { Alert } from "@material-ui/lab"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom"

import { UncontrolledLoadingButton, useLoadingButton } from "components/LoadingButton"
import PasswordField from "components/PasswordField"
import { code2faRules, passwordRules } from "components/ReactHookForm/rules"
import { SelectSignIn } from "components/SelectSignInUp"
import TitleWithDivider from "components/TitleWithDivider"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import { URLS } from "../../routes"
import api from "services/api"
import { GetUserDtoAccountCreatedViaEnum } from "services/api/openapi"
import { Mixpanel } from "services/mixpanel"
import { isTestAccountEnabled } from "services/variables"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface LoginForm {
  email: string
  password: string
  code2fa?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  formInputField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: "0 auto",
    maxWidth: "38em",
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "1.2em",
  },
  linkAnotherSigninMethod: {
    cursor: "pointer",
  },
}))

const LoginScene = (): JSX.Element => {
  const classes = useStyles()
  const { control, handleSubmit, unregister, formState, getValues } = useForm<LoginForm>({ mode: "onChange" })
  const [has2fa, setHas2fa] = useState<boolean | null>(null)
  const [isEmailConfirmed, setIsEmailConfirmed] = useState<boolean | null>(null)
  const TypedController = useTypedController<LoginForm>({ control })
  const history = useHistory()
  const toast = useToast()
  const { search, pathname } = useLocation()
  const [loginFrom, setLoginFrom] = useState<"cryptio" | null>("cryptio")

  const onXeroConnect = useCallback(async () => {
    try {
      const url = await api.accounting.getLoginOAuthRedirectUrl({
        integration: "xero",
      })

      window.location.href = url.url
    } catch (e) {
      toastCatch(e, toast)
    }
  }, [toast])

  const selectLoginFrom = useCallback(
    (selected: GetUserDtoAccountCreatedViaEnum) => {
      switch (selected) {
        case "cryptio":
          return setLoginFrom("cryptio")
        case "xero":
          return onXeroConnect()
      }
    },
    [setLoginFrom, onXeroConnect],
  )

  useEffect(() => {
    const params = new URLSearchParams(search)
    const loginFrom = params.get("login_from") as GetUserDtoAccountCreatedViaEnum | null

    if (loginFrom) {
      selectLoginFrom(loginFrom)
      params.delete("login_from")
      history.replace({
        pathname,
        search: params.toString(),
      })
    }
  }, [search, history, pathname, selectLoginFrom])

  const { mutateAsync: loginMutation } = api.user.useLogin()
  const { mutateAsync: getPreLoginStatus } = api.user.useLazyGetPreLoginStatus()
  const { mutateAsync: resendConfirmationEmail } = api.user.useResendConfirmationEmail()

  api.user.useUser({
    onSuccess: (user) => {
      Mixpanel.identify(user)
      Mixpanel.track("Login")
    },
  })

  const [SignInButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = async (form: LoginForm) => {
    try {
      const preLoginStatus = await getPreLoginStatus({
        email: form.email,
      })

      if (preLoginStatus.has2Fa !== has2fa) {
        setHas2fa(preLoginStatus.has2Fa)
        if (preLoginStatus.has2Fa === false) {
          unregister("code2fa")
        }
        if ((has2fa === null || has2fa === false) && preLoginStatus.has2Fa === true) {
          return
        }
      }

      if (preLoginStatus.isEmailConfirmed !== isEmailConfirmed) {
        setIsEmailConfirmed(preLoginStatus.isEmailConfirmed)
        if (preLoginStatus.isEmailConfirmed === false) {
          return
        }
      }

      await loginMutation({
        loginUserDto: form,
      })

      const params = new URLSearchParams(search)
      const redirect = params.get("redirect")
      params.delete("redirect")

      history.push({
        pathname: redirect || URLS.Portfolio,
        search: params.toString(),
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const resendEmailConfirmation = async () => {
    try {
      const email = getValues("email")
      await resendConfirmationEmail({
        resendConfirmationEmail: {
          email,
        },
      })
      toast.open("Confirmation email sent", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  return (
    <Box pt={15}>
      <Paper className={classes.container}>
        <TitleWithDivider title="Sign in" mb={3} component="h1" />
        {loginFrom === null ? (
          <>
            <Typography>Please choose a sign in method below.</Typography>
            <SelectSignIn onClick={selectLoginFrom} mb={1} />
          </>
        ) : (
          <form onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
            <FormLabel htmlFor="email-textfield">Email</FormLabel>
            <TypedController
              name="email"
              defaultValue={""}
              rules={{ required: true }}
              render={(props) => (
                <TextField
                  id="email-textfield"
                  className={classes.formInputField}
                  type="email"
                  {...props}
                  placeholder="Email"
                  fullWidth={true}
                  error={!!formState.errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleRounded />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Box display="flex" justifyContent="space-between" mt={1}>
              <FormLabel htmlFor="password-textfield">Password</FormLabel>
              <Link component={RouterLink} to={URLS.Account.ForgottenPassword}>
                <strong>
                  <u>Forgot password?</u>
                </strong>
              </Link>
            </Box>
            <TypedController
              name="password"
              defaultValue={""}
              rules={passwordRules}
              render={(props) => (
                <PasswordField
                  id="password-textfield"
                  className={classes.formInputField}
                  {...props}
                  placeholder="Password"
                  fullWidth
                  error={!!formState.errors.password}
                />
              )}
            />

            {has2fa && (
              <>
                <Box display="flex" justifyContent="space-between" mt={1}>
                  <FormLabel htmlFor="code2fa-textfield">Two factor authentication code</FormLabel>
                </Box>
                <TypedController
                  name="code2fa"
                  defaultValue={""}
                  rules={code2faRules}
                  render={(props) => (
                    <TextField
                      id="code2fa-textfield"
                      className={classes.formInputField}
                      {...props}
                      error={!!formState.errors.code2fa}
                      placeholder="Code 2fa"
                      fullWidth={true}
                    />
                  )}
                />
              </>
            )}

            {isEmailConfirmed === false && (
              <Box display="flex" flexDirection="column" justifyContent="center" p={2} mt={1}>
                <Alert severity="error" style={{ justifyContent: "center" }}>
                  Email not confirmed
                </Alert>
                <Box mt={1} display="flex" justifyContent="center">
                  <ButtonUI mode={Mode.OUTLINEDHIGHLIGHT} onClick={resendEmailConfirmation}>
                    Resend link
                  </ButtonUI>
                </Box>
              </Box>
            )}

            <Box mt={2} display="flex" justifyContent="center">
              <SignInButton className={classes.submitButton} disabled={!formState.isValid} type="submit">
                Sign in
              </SignInButton>
              {isTestAccountEnabled && (
                <Box ml={4}>
                  <UncontrolledLoadingButton
                    className={classes.submitButton}
                    // random valid password (matching regex requirements)
                    onClick={() =>
                      onSubmit({
                        email: "whatever@cryptio.co",
                        password: "123Abc!?",
                      })
                    }
                  >
                    Test account
                  </UncontrolledLoadingButton>
                </Box>
              )}
            </Box>
          </form>
        )}
      </Paper>

      <Paper className={classes.container}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="body1" color="textSecondary">
            Not registered yet?{" "}
            <Link component={RouterLink} to={`${URLS.Account.Register}${search}`}>
              Create an account
            </Link>
          </Typography>
          {loginFrom !== null && (
            <Typography variant="body1" color="textSecondary">
              Do you want to use another sign in method?{" "}
              <Link onClick={() => setLoginFrom(null)} className={classes.linkAnotherSigninMethod}>
                Click here
              </Link>
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

export default LoginScene
