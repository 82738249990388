import { useRegisterActions } from "kbar"
import { useHistory } from "react-router"
import { URLS } from "routes"

function usePageActions() {
  const history = useHistory()
  useRegisterActions([
    {
      id: "transactions",
      name: "Transactions",
      keywords: "transaction",
      perform: () => history.push(URLS.Transactions.getUrl()),
    },
    {
      id: "contact",
      name: "Contact",
      keywords: "contact",
      perform: () => history.push(URLS.Business.Contacts.getUrl()),
    },
  ])
}

export default usePageActions
