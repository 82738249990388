import { TextField } from "@material-ui/core"
import { Autocomplete, AutocompleteProps } from "@material-ui/lab"
import React, { useContext, useEffect, useState } from "react"
import { UseQueryResult } from "react-query"

import { PaginationQuery } from "services/api/pagination"
import { DEFAULT_MAX_AUTOCOMPLETION_RESULTS } from "services/constants"
import { WorkspaceContext } from "services/context/workspaceContext"
import { SelfPaginatedQuery } from "services/utils/types"
import LoadingSpinner from "../misc/LoadingSpinner"
import NetworkErrorMessage from "../misc/NetworkErrorMessage"
import PermissionDisabled, { PermissionDisabledProps } from "../Permission/PermissionDisabled"

type BaseQuery = PaginationQuery & { query?: string }

const HARD_OPTION_COUNT_LIMIT = 500

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export default function CustomSelector<
  QueryVars extends BaseQuery,
  AutocompleteValue,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>(
  props: Omit<
    AutocompleteProps<AutocompleteValue, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "options" | "inputValue" | "onInputChange"
  > & {
    defaultPaginatedQueryProps: Optional<QueryVars, keyof PaginationQuery>
    // Implies that AutocompleteValue extends Results
    // (if AutocompleteValue === Dto | string, Result can be Dto, string, or both)
    usePaginatedQuery: (
      query: QueryVars,
      options?: { enabled?: boolean },
    ) => UseQueryResult<SelfPaginatedQuery<AutocompleteValue>>
    inputRef?: React.Ref<unknown>
    label?: string
    placeholder?: string
    error?: boolean
    minInputLengthForScroll?: number
    onInputBlur?: (typingInput: string) => void
    additionalsOption?: AutocompleteValue[]
    customProcessOption?: (data: AutocompleteValue[]) => AutocompleteValue[]
    permission?: Omit<PermissionDisabledProps, "children">
    disabled?: boolean
    maxAutocompletionResults?: number
  },
): JSX.Element {
  const {
    defaultPaginatedQueryProps,
    usePaginatedQuery,
    label,
    placeholder,
    error,
    inputRef,
    onInputBlur,
    minInputLengthForScroll,
    filterOptions,
    additionalsOption = [],
    customProcessOption = (data: AutocompleteValue[]) => data,
    permission,
    disabled = false,
    maxAutocompletionResults = DEFAULT_MAX_AUTOCOMPLETION_RESULTS,
    ...propRest
  } = props
  const [typingInput, setTypingInput] = useState("")
  const [query, setQuery] = useState("")

  const workspaceCtx = useContext(WorkspaceContext)
  const canRead = permission ? workspaceCtx.workspace.userRights[permission.permission] : true

  const queryResult = usePaginatedQuery(
    {
      ...defaultPaginatedQueryProps,
      page: defaultPaginatedQueryProps.page ?? 0,
      limit:
        minInputLengthForScroll && query.length >= minInputLengthForScroll
          ? HARD_OPTION_COUNT_LIMIT
          : defaultPaginatedQueryProps.limit || maxAutocompletionResults,
      query,
    } as QueryVars,
    {
      enabled: canRead,
    },
  )

  useEffect(() => {
    const timeOutId = setTimeout(() => setQuery(typingInput), 500)
    return () => clearTimeout(timeOutId)
  }, [typingInput, setQuery])

  if (queryResult.isError) return <NetworkErrorMessage additionalData={queryResult} />

  if (queryResult.isLoading) return <LoadingSpinner />

  const processOptions = () =>
    customProcessOption(queryResult.data?.data ? [...(queryResult.data?.data ?? []), ...additionalsOption] : [])

  const autocomplete = (
    <Autocomplete<AutocompleteValue, Multiple, DisableClearable, FreeSolo>
      {...propRest}
      filterOptions={filterOptions || ((opt) => opt)}
      options={processOptions()}
      inputValue={typingInput}
      disabled={disabled}
      onInputChange={(_, value) => setTypingInput(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          innerRef={inputRef}
          label={label}
          placeholder={placeholder}
          error={error}
          onBlur={onInputBlur ? () => onInputBlur(typingInput) : undefined}
        />
      )}
    />
  )

  if (permission && !canRead) {
    return <PermissionDisabled {...permission}>{autocomplete}</PermissionDisabled>
  }

  return autocomplete
}
