import { Typography } from "@material-ui/core"
import { prettyAccountingIntegrationNameMap } from "pure-shared"
import React from "react"
import { TransactionSynchronizationSubcomponentsProps } from "."
import { WorkspaceAccountingIntegrationDto } from "../../../../services/api/aliases"
import { QuickbooksEnv } from "../../../../services/variables"
import { DrawerSection } from "../../../Drawer/DrawerItems"
import ExternalLink from "../../../ExternalLink"
import { accountingSyncStatusToText } from "../../GeneratedTable/useGenerateCell"

const legacyAccountingSyncStatusToText = (accountingSyncStatus: string, errorMessage?: string | null) => {
  switch (accountingSyncStatus) {
    case "unsynced":
      return "One transaction is not synced."
    case "pending":
    case "syncing":
      return "One transaction is being synced..."
    case "synced":
      return "One transaction has been synced."
    case "error":
    default:
      return `An error occurred during the synchronization of one transaction. ${
        errorMessage ? ` "${errorMessage}".` : ""
      }`
  }
}

const linkToExternalJournal = (journalId: string, integration: WorkspaceAccountingIntegrationDto) => {
  switch (integration.name) {
    case "quickbooks":
      return `https://app.${QuickbooksEnv === "sandbox" ? "sandbox." : ""}qbo.intuit.com/app/journal?txnId=${journalId}`
    case "xero":
      if (integration.xeroOrganisationShortCode === null) {
        return `https://go.xero.com/Journal/Edit.aspx?invoiceID=${journalId}`
      }
      return `https://go.xero.com/organisationlogin/default.aspx?shortcode=${integration.xeroOrganisationShortCode}&redirecturl=/Journal/Edit.aspx?invoiceID=${journalId}`
    case "netsuite":
      return `https://${integration.companyId}.app.netsuite.com/app/accounting/transactions/journal.nl?id=${journalId}`
    default:
      return ""
  }
}

function SynchronizationStatus({
  accountingIntegration,
  transaction,
  isConsolidated,
}: TransactionSynchronizationSubcomponentsProps) {
  function StatusLine({ statusText, externalId }: { statusText: string; externalId: string | null }) {
    return (
      <>
        <Typography>{statusText}</Typography>
        {externalId && (
          <ExternalLink href={linkToExternalJournal(externalId, accountingIntegration)}>
            See journal in {prettyAccountingIntegrationNameMap[accountingIntegration.name]}
          </ExternalLink>
        )}
      </>
    )
  }

  const accountingJournal = transaction.accountingJournal

  if (!isConsolidated) {
    const statusText = accountingSyncStatusToText(
      accountingJournal?.status ?? "unsynced",
      accountingJournal?.errorMessage,
    )
    const externalId = accountingJournal?.status === "synced" ? accountingJournal.externalId : null

    return (
      <DrawerSection name="Synchronization status">
        <StatusLine statusText={statusText} externalId={externalId} />
      </DrawerSection>
    )
  } else {
    const statusLines = accountingJournal?.oldStatuses?.map((oldStatus, idx) => {
      const statusText = legacyAccountingSyncStatusToText(oldStatus ?? "unsynced", accountingJournal.oldErrors?.[idx])
      const externalId = (oldStatus === "synced" ? accountingJournal.oldJournalIds?.[idx] : null) ?? null

      return <StatusLine statusText={statusText} externalId={externalId} />
    })

    return <DrawerSection name="Synchronization status">{statusLines}</DrawerSection>
  }
}

export default SynchronizationStatus
