import { UseQueryOptions } from "react-query"

import { queryClient } from "../../.."
import { usePaginatedWorkspaceQuery, useWorkspaceMutation, useWorkspaceQuery } from "../../context/workspaceContext"
import { WithoutWorkspaceId } from "../aliases"
import { ImpairmentApi, GetImpairmentsRequest, PaginatedImpairmentResultDto, GetImpairmentDto } from "../openapi"
import { GET_WORKSPACES_OR_DEFAULT_KEY } from "./workspace"

export const GET_IMPAIRMENTS_KEY = "getImpairments"
export const GET_IMPAIRMENT_KEY = "getImpairment"

export const impairmentActions = {
  useImpairments: (
    variables: WithoutWorkspaceId<GetImpairmentsRequest>,
    options?: UseQueryOptions<PaginatedImpairmentResultDto>,
  ) => {
    return usePaginatedWorkspaceQuery(
      [GET_IMPAIRMENTS_KEY, variables],
      ImpairmentApi.getImpairments,
      variables,
      options,
    )
  },
  useImpairment: (impairmentId: string | undefined | null, option?: UseQueryOptions<GetImpairmentDto>) => {
    return useWorkspaceQuery(
      [GET_IMPAIRMENT_KEY, impairmentId],
      ImpairmentApi.getOneImpairment,
      { impairmentId: impairmentId ?? "" },
      {
        enabled: !!impairmentId,
        ...option,
      },
    )
  },
  useCreateImpairment: () => {
    return useWorkspaceMutation(ImpairmentApi.createImpairment, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_IMPAIRMENTS_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useUpdateImpairmentValuation: () => {
    return useWorkspaceMutation(ImpairmentApi.updateImpairmentValuation, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_IMPAIRMENTS_KEY)
        queryClient.invalidateQueries(GET_IMPAIRMENT_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useUpdateCostBasis: () => {
    return useWorkspaceMutation(ImpairmentApi.updateCostBasisImpairment, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useApplyImpairment: () => {
    return useWorkspaceMutation(ImpairmentApi.applyImpairment, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_IMPAIRMENTS_KEY)
        queryClient.invalidateQueries(GET_IMPAIRMENT_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useUnapplyImpairment: () => {
    return useWorkspaceMutation(ImpairmentApi.unapplyImpairment, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_IMPAIRMENTS_KEY)
        queryClient.invalidateQueries(GET_IMPAIRMENT_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useDeleteImpairment: () => {
    return useWorkspaceMutation(ImpairmentApi.deleteImpairment, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_IMPAIRMENTS_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
}
