import BigNumber from "bignumber.js"

const bigToFormatString = (amount: BigNumber): string => {
  if (amount.eq(0)) {
    return "0.00"
  }
  if (amount.gte(1e6) || amount.lte(0.001)) {
    return amount.toExponential(3)
  }
  if (amount.gte(1e5)) {
    return amount.toFixed(1)
  }
  if (amount.gte(1e4)) {
    return amount.toFixed(2)
  }
  return amount.toFixed(3)
}

export default bigToFormatString
