import { Box, Checkbox, FormLabel, makeStyles, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useContext, useMemo, useState } from "react"

import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import useFilterDrawer from "components/Hooks/useFilterDrawer"
import InputClearable from "components/Input/InputClearable"
import { WithoutWorkspaceId } from "services/api/aliases"
import { AssetPaginatedParams, assetTypeArray, backOfficeAssetHasAnyFilter } from ".."
import { defaultBackOfficeGetAssetsParams, prettyAssetTypeMap } from "../index"
import { UserContext } from "services/context/userContext"
import CustomSelector from "components/selector/CustomSelector"
import api from "services/api"
import { WorkspaceContext } from "services/context/workspaceContext"
import WorkspaceOptionInAutocomplete from "components/AutoCompleteOptions/WorkspaceOptionInAutocomplete"
import DrawerUI from "CryptioUI/Drawer"

interface Props {
  filter: AssetPaginatedParams
  setFilter: (newValue: WithoutWorkspaceId<AssetPaginatedParams>) => void
  isAdmin: boolean
}

const useStyles = makeStyles(() => ({
  checkbox: {
    paddingLeft: 0,
  },
}))

const BackOfficeAssetFilter = (props: Props): JSX.Element => {
  const classes = useStyles()
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const { OpenButton, SaveButton, tempFilter, updateFilter } = useFilterDrawer({
    ...props,
    defaultParams: defaultBackOfficeGetAssetsParams,
    setIsDrawerOpen,
  })

  const { user } = useContext(UserContext)
  const { workspace } = useContext(WorkspaceContext)
  const isAdmin = user.role !== "normal"

  const filterCount = useMemo(() => {
    const filter = props.filter
    let res = 0
    if (filter.query) res++
    if (filter.type) res++
    if (filter.queryWorkspace) res++
    if (filter.address) res++
    return res
  }, [props.filter])

  const canClear = filterCount > 0 || backOfficeAssetHasAnyFilter(tempFilter)

  return (
    <>
      <OpenButton filterCount={filterCount} />

      <DrawerUI title="Asset Filter" onClose={() => setIsDrawerOpen(false)} isOpen={isDrawerOpen}>
        <DrawerCategory title="Filter">
          <DrawerFormSection htmlFor="search-name-textfield" name="Search by name">
            <InputClearable
              id="search-name-textfield"
              value={tempFilter.query || ""}
              onChange={(value) =>
                updateFilter({
                  query: value,
                })
              }
              placeholder="Name or symbol"
              fullWidth
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="search-type-select" name="Filter by type">
            <Autocomplete
              id="search-type-select"
              getOptionLabel={(opt) => prettyAssetTypeMap[opt]}
              options={props.isAdmin ? assetTypeArray : assetTypeArray.filter((type) => type !== "exchange_unknown")}
              value={tempFilter.type ?? null}
              onChange={(_, newValue) => {
                updateFilter({ type: newValue ?? undefined })
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </DrawerFormSection>

          {isAdmin && (
            <DrawerFormSection htmlFor="workspace-select" name="Asset in workspace">
              <CustomSelector
                id="workspace-select"
                defaultValue={null}
                defaultPaginatedQueryProps={{}}
                size="small"
                filterSelectedOptions
                value={tempFilter.queryWorkspace ?? null}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                onChange={(_, newValue) => {
                  updateFilter({ queryWorkspace: newValue ?? undefined })
                }}
                usePaginatedQuery={api.backOffice.workspace.useWorkspaces}
                renderOption={(option) => <WorkspaceOptionInAutocomplete workspace={option} />}
              />
            </DrawerFormSection>
          )}

          <Box mt={2} display="flex" alignItems="center">
            <Checkbox
              color="primary"
              id="asset-in-workspace-checkbox"
              className={classes.checkbox}
              onChange={(_, newValue) => {
                updateFilter({ queryWorkspace: newValue ? workspace : undefined })
              }}
              checked={!!tempFilter.queryWorkspace}
            />
            <FormLabel htmlFor="asset-in-workspace-checkbox">Show assets in workspace</FormLabel>
          </Box>

          <DrawerFormSection htmlFor="search-address-textfield" name="Filter by address">
            <InputClearable
              id="search-address-textfield"
              value={tempFilter.address ?? ""}
              onChange={(value) =>
                updateFilter({
                  address: value,
                })
              }
              fullWidth
            />
          </DrawerFormSection>

          <SaveButton filterCount={filterCount} canClear={canClear} />
        </DrawerCategory>
      </DrawerUI>
    </>
  )
}

export default BackOfficeAssetFilter
