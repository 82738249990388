import { Box } from "@material-ui/core"
import React, { useEffect } from "react"
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom"
import * as Tooltip from "@radix-ui/react-tooltip"

import CookiesBanner from "./components/CookieBanner"
import AppLayout from "./components/layout"
import LoadingSpinner from "./components/misc/LoadingSpinner"
import VersionChecker from "./components/VersionChecker"
import { URLS } from "./routes"
import ChartAccountAutomatedMappingScene from "./scenes/Accounting/AutomatedMapping"
import ChartAccountScene from "./scenes/Accounting/ChartAccount"
import AccountingLoginScene from "./scenes/Accounting/Login"
import BackOfficeScene from "./scenes/BackOffice"
import BackOfficeAssetScene from "./scenes/BackOffice/Assets"
import BackOfficeBillingScene from "./scenes/BackOffice/Billing"
import BackOfficeLimitationAlmostReachedScene from "./scenes/BackOffice/LimitationAlmostReached"
import BackOfficeUserScene from "./scenes/BackOffice/Users"
import BackOfficeWorkspaceScene from "./scenes/BackOffice/Workspaces"
import BillingCheckoutScene from "./scenes/Billing/Checkout"
import BillingCheckoutCanceledScene from "./scenes/Billing/CheckoutCanceled"
import BillingSimulatorScene from "./scenes/Billing/Simulator"
import BillingStatusScene from "./scenes/Billing/Status"
import ConfirmEmailScene from "./scenes/ConfirmEmail"
import ContactsScene from "./scenes/Contacts"
import FabHelper from "./scenes/FabHelper"
import ImportScene from "./scenes/Imports"
import IntegrationsScene from "./scenes/Integrations"
import LabelsScene from "./scenes/Labels"
import NoWorkspaceView from "./scenes/NoWorkspace"
import DashboardScene from "./scenes/Dashboard"
import ReportsScene from "./scenes/Reports"
import DeFiScene from "./scenes/DeFi"
import SettingsScene from "./scenes/Settings"
import UnidentifiedAddressesScene from "./scenes/UnidentifiedAddresses"
import WorkspaceScene from "./scenes/Workspace"
import WorkspaceInvitationScene from "./scenes/WorkspaceInvitation"
import WorkspaceRedirectScene from "./scenes/WorkspaceRedirect"
import api from "./services/api"
import { GetUserDto } from "./services/api/openapi"
import { GDPRContextProvider } from "./services/context/gdprContext"
import { FilterContextProvider } from "./services/context/filterContext"
import { AccountingFilterContextProvider } from "services/context/accountingFilterContext"
import { WorkspaceContextProvider } from "./services/context/workspaceContext"
import { useLocalStorage } from "./services/misc/useLocalStorage"
import { isOnboardingEnabled } from "./services/variables"
import { UserContextProvider } from "./services/context/userContext"
import { SelectTransactionPageContextProvider } from "./services/context/selectTransactionPageContext"
import ImpairmentScene from "./scenes/Accounting/Impairment"
import TransactionsScene from "./scenes/Transactions"
import TransactionsReviewScene from "./scenes/TransactionsReview"
import UrlShortenerRedirectScene from "./scenes/UrlShortenerRedirect"
import { useZendeskManager } from "./services/context/ZendeskContext"
import ChartAccountAutomatedMappingReviewScene from "scenes/Accounting/AutomatedMappingReview"
import BackOfficeJobsScene from "scenes/BackOffice/Jobs"
import { KBarProvider } from "kbar"
import { KbarWrapper } from "services/misc/Kbar"
import BackOfficeView from "components/misc/BackOfficeView"
import { ToastProvider } from "CryptioUI/Toaster"

interface Props {
  user: GetUserDto
}

const PrivateApp = ({ user }: Props): JSX.Element => {
  const { search, pathname } = useLocation()
  const history = useHistory()
  const [currentWorkspaceId, setCurrentWorkspaceId] = useLocalStorage<string | undefined>("workspaceId", undefined)
  const { updateZendeskWorkspaceId } = useZendeskManager()

  const { isLoading, isPreviousData, data } = api.workspace.useGetWorkspaceOrDefault(
    {
      lastWorkspaceId: currentWorkspaceId,
    },
    {
      keepPreviousData: true,
      notifyOnChangeProps: ["data", "error", "isPreviousData"],
      refetchInterval: 5000,
    },
  )

  useEffect(() => {
    const queryParams = new URLSearchParams(search)

    const redirect = queryParams.get("redirect")
    if (redirect) {
      queryParams.delete("redirect")

      history.replace({
        pathname: redirect || pathname,
        search: queryParams.toString(),
      })
    }
  }, [search, history, pathname])

  {
    const newWorkspaceId = data?.workspace?.id
    useEffect(() => {
      updateZendeskWorkspaceId(newWorkspaceId)

      if (!currentWorkspaceId && newWorkspaceId) {
        setCurrentWorkspaceId(newWorkspaceId)
      }
    }, [currentWorkspaceId, newWorkspaceId, setCurrentWorkspaceId, updateZendeskWorkspaceId])
  }

  if (isLoading || data?.workspace === undefined) {
    return (
      <Box height="100vh">
        <LoadingSpinner />
      </Box>
    )
  }

  if (data.workspace === null) {
    // TODO: using router here breaks history.push in NoWorkspaceView
    return (
      // <Switch>
      //   <Route
      //     exact
      //     path={URLS.NoWorkspace}
      //     component={() => <NoWorkspaceView onCreateOrJoin={setCurrentWorkspaceId} />}
      //   />
      //   <Redirect to={URLS.NoWorkspace} />
      // </Switch>
      <ToastProvider>
        <NoWorkspaceView onCreateOrJoin={setCurrentWorkspaceId} />
      </ToastProvider>
    )
  }

  if (isPreviousData) data.workspace.name = "Loading..."

  const App: JSX.Element = (
    <GDPRContextProvider>
      <CookiesBanner />
      <ToastProvider>
        <Tooltip.Provider>
          <FilterContextProvider>
            <AccountingFilterContextProvider>
              <UserContextProvider user={user}>
                <SelectTransactionPageContextProvider>
                  <WorkspaceContextProvider workspace={data.workspace} setCurrentWorkspaceId={setCurrentWorkspaceId}>
                    <AppLayout user={user}>
                      <BackOfficeView>
                        <KbarWrapper />
                      </BackOfficeView>
                      <Switch>
                        <Route exact path={URLS.Redirect.basePath} component={WorkspaceRedirectScene} />
                        <Route exact path={URLS.UrlShortener.Redirect} component={UrlShortenerRedirectScene} />

                        <Route
                          path={[URLS.Accounting.OAuth.Login, URLS.Accounting.OAuth.Register]}
                          component={AccountingLoginScene}
                        />

                        <Route exact path={URLS.Business.Labels.basePath} component={LabelsScene} />

                        <Route exact path={URLS.Business.Contacts.basePath} component={ContactsScene} />
                        <Route
                          exact
                          path={URLS.Business.UnidentifiedAddresses}
                          component={UnidentifiedAddressesScene}
                        />
                        <Route exact path={URLS.Business.Integrations} component={IntegrationsScene} />

                        <Route exact path={URLS.Import.basePath} component={ImportScene} />
                        <Route exact path={URLS.Reports.basePath} component={ReportsScene} />

                        <Route exact path={URLS.Transactions.basePath} component={TransactionsScene} />
                        <Route exact path={URLS.DeFi.basePath} component={DeFiScene} />
                        <Route
                          exact
                          path={URLS.TransactionsReviewMissingLabel.basePath}
                          component={TransactionsReviewScene}
                        />
                        <Route
                          exact
                          path={URLS.TransactionsReviewInternalTransfer.basePath}
                          component={TransactionsReviewScene}
                        />
                        <Route
                          exact
                          path={URLS.TransactionsReviewIncompleteCOA.basePath}
                          component={TransactionsReviewScene}
                        />
                        <Route
                          exact
                          path={URLS.TransactionsReviewReadyToBeSynced.basePath}
                          component={TransactionsReviewScene}
                        />

                        <Route
                          exact
                          path={[
                            URLS.Accounting.Integrations,
                            URLS.Accounting.OAuth.Connect.Xero,
                            URLS.Accounting.OAuth.Connect.QuickBooks,
                          ]}
                          component={ChartAccountScene}
                        />
                        <Route
                          exact
                          path={URLS.Accounting.AutomatedMapping}
                          component={ChartAccountAutomatedMappingScene}
                        />
                        <Route
                          exact
                          path={URLS.Accounting.Impairment}
                          component={ImpairmentScene}
                          // feature="impairment"
                        />
                        <Route
                          exact
                          path={URLS.Accounting.AutomatedMappingReview}
                          component={ChartAccountAutomatedMappingReviewScene}
                        />

                        <Route exact path={URLS.Portfolio} component={DashboardScene} />

                        <Route exact path={URLS.WorkspaceInvitation} component={WorkspaceInvitationScene} />
                        <Route exact path={URLS.Workspaces} component={WorkspaceScene} />

                        <Route exact path={URLS.Settings} component={SettingsScene} />

                        <Route path={URLS.Account.ConfirmEmail} component={ConfirmEmailScene} />

                        <Route exact path={URLS.Asset} component={BackOfficeAssetScene} />

                        <Route path={URLS.Account.ConfirmEmail} component={ConfirmEmailScene} />

                        <Route exact path={URLS.Asset} component={BackOfficeAssetScene} />

                        <Route exact path={URLS.Billing.Simulator} component={BillingSimulatorScene} />
                        <Route exact path={URLS.Billing.Status} component={BillingStatusScene} />
                        <Route exact path={URLS.Billing.Checkout.Review.basePath} component={BillingCheckoutScene} />
                        <Route exact path={URLS.Billing.Checkout.Canceled} component={BillingCheckoutCanceledScene} />

                        {/* BackOffice */}
                        {/* We can't use React.Fragment, so we use an Array instead */}
                        {/* https://dev.to/tylerlwsmith/how-to-get-around-react-router-4-not-allowing-nested-components-inside-of-a-switch-2hl */}
                        {(user.role === "business_administrator" ||
                          user.role === "reporter" ||
                          user.role === "tech_administrator") && [
                          <Route
                            exact
                            key={URLS.BackOffice.Home}
                            path={URLS.BackOffice.Home}
                            component={BackOfficeScene}
                          />,
                          <Route
                            exact
                            key={URLS.BackOffice.User.basePath}
                            path={URLS.BackOffice.User.basePath}
                            component={BackOfficeUserScene}
                          />,
                          <Route
                            exact
                            key={URLS.BackOffice.Asset}
                            path={URLS.BackOffice.Asset}
                            component={BackOfficeAssetScene}
                          />,
                          <Route
                            exact
                            key={URLS.BackOffice.Billing}
                            path={URLS.BackOffice.Billing}
                            component={BackOfficeBillingScene}
                          />,
                          <Route
                            exact
                            key={URLS.BackOffice.Workspace.basePath}
                            path={URLS.BackOffice.Workspace.basePath}
                            component={BackOfficeWorkspaceScene}
                          />,
                          <Route
                            exact
                            key={URLS.BackOffice.LimitationAlmostReached}
                            path={URLS.BackOffice.LimitationAlmostReached}
                            component={BackOfficeLimitationAlmostReachedScene}
                          />,
                          <Route
                            exact
                            key={URLS.BackOffice.Jobs}
                            path={URLS.BackOffice.Jobs}
                            component={BackOfficeJobsScene}
                          />,
                        ]}

                        <Redirect to={URLS.Portfolio} />
                      </Switch>
                    </AppLayout>

                    <VersionChecker />
                    {isOnboardingEnabled && <FabHelper />}
                  </WorkspaceContextProvider>
                </SelectTransactionPageContextProvider>
              </UserContextProvider>
            </AccountingFilterContextProvider>
          </FilterContextProvider>
        </Tooltip.Provider>
      </ToastProvider>
    </GDPRContextProvider>
  )

  if (user.role !== "normal") return <KBarProvider>{App}</KBarProvider>

  return App
}

export default PrivateApp
