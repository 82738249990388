import { Box, IconButton, Link, makeStyles, Theme } from "@material-ui/core"
import { AddCircleRounded, OpenInNewRounded } from "@material-ui/icons"
import React, { useContext, useMemo, useState } from "react"
import { Link as RouterLink, useHistory } from "react-router-dom"

import AddressTypography from "components/AddressTypography"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import { IExtendedDataTableColumn } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import { URLS } from "../../../routes"
import api from "services/api"
import { GetUnknownContactDto, GetUnknownContactQuerySortByEnum } from "services/api/openapi"
import { ThemeContext } from "services/context/themeContext"
import { widgetTableStyle } from "./widgetTableStyle"
import CardUI from "CryptioUI/Card"
import TypographyUI from "CryptioUI/Typography"
import { TypographyVariant } from "CryptioUI/types"
import DialogCreateContact from "components/Dialog/DialogCreateContact"

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    seeMoreLink: {
      "color": "white",
      "&:hover": {
        color: "white",
      },
    },
  }
})

const TopAddresses = (): JSX.Element => {
  const muiThemeContext = useContext(ThemeContext)
  const classes = useStyles()
  const history = useHistory()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [address, setAddress] = useState<string>("")
  const contacts = api.contact.useUnknownContacts({
    page: 0,
    limit: 5,
    sortBy: "movement_count",
    sortDirection: "descending",
  })

  const columns = useMemo<IExtendedDataTableColumn<GetUnknownContactDto, GetUnknownContactQuerySortByEnum>[]>(
    () => [
      {
        name: "Address",
        selector: "address",
        minWidth: "60%",
        format: function AddressDisplay(row: GetUnknownContactDto) {
          return (
            <AddressTypography component="span" data-test-id={row.address}>
              {row.address}
            </AddressTypography>
          )
        },
      },
      {
        name: "Movements",
        selector: "movementCount",
        format: (row: GetUnknownContactDto) => {
          return row.movementCount
        },
        width: "130px",
      },
      {
        name: "",
        selector: "createContact",
        // FIXME: Hover circle on icon buttons is cut by the table cell
        format: function Create(row: GetUnknownContactDto) {
          return (
            <>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row">
                <IconButton
                  color="primary"
                  title="See transactions"
                  onClick={() =>
                    history.push(
                      URLS.Transactions.getUrl({
                        filters: [{ type: "addresses", value: [row.address], isNot: false }],
                      }),
                    )
                  }
                >
                  <OpenInNewRounded />
                </IconButton>
                <IconButton
                  color="primary"
                  title="Create contact"
                  onClick={() => {
                    setAddress(row.address)
                    setIsOpen(true)
                  }}
                >
                  <AddCircleRounded />
                </IconButton>
              </Box>
            </>
          )
        },
      },
    ],
    [history],
  )
  const componentAsMemo = useMemo(
    () => (
      <CardUI
        data-test-id="unrealized-gains-losses"
        fullWidth={true}
        className="flex flex-row justify-start items-start"
      >
        <div className="flex flex-col justify-start items-start w-full pt-8">
          <div className="flex flex-row w-full justify-between items-center px-8">
            <DialogCreateContact address={address} isOpen={isOpen} onClose={() => setIsOpen(false)} />
            <div className="w-fit">
              <TypographyUI variant={TypographyVariant.H4}>Unidentified addresses</TypographyUI>
            </div>
            <div>
              <Link component={RouterLink} to={URLS.Business.UnidentifiedAddresses} className={classes.seeMoreLink}>
                <TypographyUI className="text-primary-400" variant={TypographyVariant.CAPTION}>
                  See more
                </TypographyUI>
              </Link>
            </div>
          </div>
          <div className="mt-8 w-full rounded-b-xl  overflow-x-auto">
            {contacts.data?.data?.length === 0 ? (
              <div className="flex w-full justify-center items-center p-6">
                <TypographyUI variant={TypographyVariant.BODY}>
                  All addresses have been identified. Good Job!
                </TypographyUI>
              </div>
            ) : (
              <ServerSideTable<GetUnknownContactDto, GetUnknownContactQuerySortByEnum>
                customStyle={widgetTableStyle(muiThemeContext.type)}
                pagination={false}
                columns={columns}
                items={contacts.data?.data ?? []}
                limit={contacts.data?.limit ?? 0}
                totalCount={contacts.data?.data.length ?? 0}
              />
            )}
          </div>
        </div>
      </CardUI>
    ),
    [contacts.data, address, isOpen, classes.seeMoreLink, columns, muiThemeContext.type],
  )
  if (contacts.isError) return <NetworkErrorMessage additionalData={contacts} />
  if (contacts.isLoading || contacts.data === undefined) return <LoadingSpinner />

  return <>{componentAsMemo}</>
}
export default TopAddresses
