import { Box, Checkbox, FormLabel, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { AccountingIntegrationNameEnum } from "pure-shared"
import React, { useMemo, useState } from "react"
import { validate } from "uuid"

import BackOfficePlanInAutocomplete from "components/AutoCompleteOptions/BackOfficePlanInAutocomplete"
import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import useFilterDrawer from "components/Hooks/useFilterDrawer"
import InputClearable from "components/Input/InputClearable"
import InputNumber from "components/Input/InputNumber"
import CustomSelector from "components/selector/CustomSelector"
import DrawerUI from "CryptioUI/Drawer"
import api from "services/api"
import {
  BackOfficeGetFullPlanDto,
  BackOfficeGetPlansRequest,
  BackOfficeGetWorkspacesAccountingIntegrationEnum,
  BackOfficeGetWorkspacesSubscriptionTypeEnum,
} from "services/api/openapi"
import isNumberStringValid from "services/utils/isNumberStringValid"
import { BackOfficeParameters, backOfficeWorkspaceHasAnyFilter, defaultBackOfficeGetWorkspaceParams } from "../index"

// const useStyles = makeStyles((theme: Theme) => ({
//   limitationUsageTypeComplete: {
//     minWidth: "100px",
//     marginLeft: theme.spacing(1),
//   },
//   checkbox: {
//     paddingLeft: 0,
//   },
// }))

const accountingIntegrationOptions: BackOfficeGetWorkspacesAccountingIntegrationEnum[] =
  Object.values(AccountingIntegrationNameEnum)

interface Props {
  filter: Partial<BackOfficeParameters>
  setFilter: (newValue: Partial<BackOfficeParameters>) => void
}

const BackOfficeWorkspaceFilter = (props: Props): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const { OpenButton, SaveButton, tempFilter, updateFilter } = useFilterDrawer({
    ...props,
    defaultParams: defaultBackOfficeGetWorkspaceParams,
    setIsDrawerOpen,
  })
  const filterCount = useMemo(() => {
    const filter = props.filter
    let res = 0
    if (filter.workspaceId) res++
    if (filter.query) res++
    if (filter.minimumUser) res++
    if (filter.minimumTransaction) res++
    if (filter.subscriptionType) res++
    if (filter.plan) res++
    if (filter.limitation) res++
    return res
  }, [props.filter])

  const canClear = filterCount > 0 || backOfficeWorkspaceHasAnyFilter(tempFilter)
  const errorOnId = tempFilter.workspaceId ? !validate(tempFilter.workspaceId) : false

  return (
    <>
      <OpenButton filterCount={filterCount} />

      <DrawerUI title="Workspace Filter" onClose={() => setIsDrawerOpen(false)} isOpen={isDrawerOpen}>
        <DrawerCategory title="Filter" mb={3}>
          <DrawerFormSection htmlFor="search-id-textfield" name="Search by ID">
            <InputClearable
              error={errorOnId}
              helperText={errorOnId ? "Invalid ID" : null}
              id="search-id-textfield"
              value={tempFilter.workspaceId || ""}
              onChange={(value) =>
                updateFilter({
                  workspaceId: value,
                })
              }
              placeholder="Workspace ID"
              fullWidth
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="search-name-textfield" name="Search by name">
            <InputClearable
              id="search-name-textfield"
              value={tempFilter.query || ""}
              onChange={(value) =>
                updateFilter({
                  query: value,
                })
              }
              placeholder="Name"
              fullWidth
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="minimum-user-textfield" name="Minimum User Count">
            <InputNumber
              id="minimum-user-textfield"
              value={tempFilter.minimumUser ?? ""}
              onChange={(value) => {
                updateFilter({
                  minimumUser: value,
                })
              }}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="minimum-transaction-textfield" name="Minimum Transaction Count">
            <InputNumber
              id="minimum-transaction-textfield"
              value={tempFilter.minimumTransaction ?? ""}
              onChange={(value) => {
                updateFilter({
                  minimumTransaction: value,
                })
              }}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="subscription-type-select" name="Subscription type">
            <Autocomplete<BackOfficeGetWorkspacesSubscriptionTypeEnum, false>
              id="subscription-type-select"
              options={["paying", "trial"]}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={tempFilter.subscriptionType ?? null}
              onChange={(_, newValue) => updateFilter({ subscriptionType: newValue ?? undefined })}
              renderInput={(params) => <TextField {...params} />}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="plan-select" name="Subscription">
            <CustomSelector<BackOfficeGetPlansRequest, BackOfficeGetFullPlanDto>
              id="plan-select"
              fullWidth
              value={tempFilter.plan ?? null}
              onChange={(_, newValue) => updateFilter({ plan: newValue ?? undefined })}
              getOptionLabel={(option) => option.name}
              defaultPaginatedQueryProps={{
                sortBy: "created_at",
                sortDirection: "descending",
              }}
              disabled={tempFilter.subscriptionType === "trial"}
              getOptionSelected={(option, value) => option.id === value.id}
              usePaginatedQuery={api.backOffice.billing.usePlans}
              size="small"
              placeholder="Type to search..."
              renderOption={(option) => <BackOfficePlanInAutocomplete plan={option} />}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="accounting-integration-select" name="Accounting integration">
            <Box display="flex" alignItems="center" mt={2}>
              <Checkbox
                color="primary"
                id="has-accounting-integration-checkbox"
                style={{ paddingLeft: 0 }}
                onChange={() => updateFilter({ hasAccountingIntegration: !tempFilter.hasAccountingIntegration })}
                checked={tempFilter.hasAccountingIntegration}
              />
              <FormLabel htmlFor="has-accounting-integration-checkbox">Has accounting integration?</FormLabel>
            </Box>
            <Autocomplete
              id="accounting-integration-select"
              options={accountingIntegrationOptions}
              autoComplete
              includeInputInList
              disabled={!tempFilter.hasAccountingIntegration}
              value={tempFilter.accountingIntegration ?? null}
              onChange={(_, newValue) => updateFilter({ accountingIntegration: newValue ?? undefined })}
              renderInput={(params) => <TextField {...params} style={{ textTransform: "capitalize" }} />}
            />
          </DrawerFormSection>
          {/*
  Maybe later
          <DrawerFormSection
            name={
              <Box display="flex" alignItems="center">
                <Checkbox
                  id="limitation-checkbox"
                  className={classes.checkbox}
                  onChange={(_, checked) =>
                    updateFilter({
                      limitation: checked
                        ? { maxRemainingUsage: undefined, type: "transaction", usageType: "percent" }
                        : undefined,
                    })
                  }
                  checked={tempFilter.limitation !== undefined}
                />
                <FormLabel htmlFor="limitation-checkbox">Limitation filter</FormLabel>
              </Box>
            }
          >
            {tempFilter.limitation && (
              <>
                <Box>
                  <Typography>Remaining less than</Typography>
                  <InputNumber
                    id="minimum-transaction-textfield"
                    value={tempFilter.limitation.maxRemainingUsage ?? ""}
                    onChange={(newValue) =>
                      updateFilter({ limitation: { ...tempFilter.limitation!, maxRemainingUsage: newValue } })
                    }
                  />
                  <UsageTypeAutocomplete
                    id="limitation-type-select"
                    className={classes.limitationUsageTypeComplete}
                    value={tempFilter.limitation.usageType}
                    disableClearable
                    renderProps={{
                      variant: "standard",
                    }}
                    onChange={(newValue) =>
                      updateFilter({ limitation: { ...tempFilter.limitation!, usageType: newValue } })
                    }
                  />
                  <Typography>of</Typography>
                  <WorkspacesLimitationTypeAutocomplete
                    className={classes.limitationUsageTypeComplete}
                    value={tempFilter.limitation.type}
                    disableClearable
                    renderProps={{
                      variant: "standard",
                    }}
                    onChange={(newValue) => updateFilter({ limitation: { ...tempFilter.limitation!, type: newValue } })}
                  />
                </Box>
              </>
            )}
          </DrawerFormSection> */}

          <SaveButton
            filterCount={filterCount}
            canClear={canClear}
            canSave={
              (tempFilter.minimumUser === undefined || isNumberStringValid(tempFilter.minimumUser)) &&
              (tempFilter.minimumTransaction === undefined || isNumberStringValid(tempFilter.minimumTransaction)) &&
              (tempFilter.limitation === undefined ||
                (tempFilter.limitation.maxRemainingUsage !== undefined &&
                  isNumberStringValid(tempFilter.limitation.maxRemainingUsage))) &&
              !errorOnId
            }
          />
        </DrawerCategory>
      </DrawerUI>
    </>
  )
}

export default BackOfficeWorkspaceFilter
