import { useTypedController } from "@hookform/strictly-typed"
import { Box, Divider, InputAdornment, Link, makeStyles, Paper, TextField, Theme, Typography } from "@material-ui/core"
import { AccountCircleRounded } from "@material-ui/icons"
import React from "react"
import { useForm } from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"

import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import TitleWithDivider from "components/TitleWithDivider"
import { URLS } from "../../routes"
import api from "services/api"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface ForgotPasswordForm {
  email: string
}

const useStyles = makeStyles((theme: Theme) => ({
  bottomDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: "0 auto",
    maxWidth: "38em",
  },
  submitButton: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "1.2em",
  },
}))

const ForgotPasswordScene = (): JSX.Element => {
  const classes = useStyles()
  const { control, handleSubmit, reset, formState } = useForm<ForgotPasswordForm>({ mode: "onChange" })
  const TypedController = useTypedController<ForgotPasswordForm>({ control })
  const toast = useToast()

  const { mutateAsync: forgotPasswordMutation } = api.user.useForgotPassword()

  const [SendEmailButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = async (form: ForgotPasswordForm) => {
    try {
      await forgotPasswordMutation({ forgotPasswordDto: form })

      toast.open(`An email has been sent to ${form.email}`, {
        variant: "success",
      })
    } catch (e) {
      toastCatch(e, toast)
    }

    reset()
  }

  return (
    <Box pt={20}>
      <Paper component="form" className={classes.container} onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
        <TitleWithDivider title="Forgotten password" component="h1" />
        <DrawerFormSection htmlFor="email-textfield" name="Email">
          <TypedController
            name="email"
            defaultValue={""}
            rules={{
              required: true,
            }}
            render={(props) => (
              <TextField
                id="email-textfield"
                type="email"
                {...props}
                fullWidth={true}
                error={!!formState.errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleRounded />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </DrawerFormSection>

        <Box my={2} display="flex" justifyContent="center">
          <SendEmailButton
            className={classes.submitButton}
            type="submit"
            disabled={!formState.isValid || !!formState.errors.email}
          >
            Send email
          </SendEmailButton>
        </Box>

        <Divider className={classes.bottomDivider} />

        <Box display="flex" justifyContent="center">
          <Typography variant="body1" color="textSecondary">
            Not registered yet?{" "}
            <Link component={RouterLink} to={URLS.Account.Register}>
              Create an account
            </Link>
          </Typography>
        </Box>
      </Paper>
    </Box>
  )
}

export default ForgotPasswordScene
