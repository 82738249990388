import React from "react"
import * as Popover from "@radix-ui/react-popover"

export interface PopoverProps {
  children: React.ReactNode
  Trigger: React.ReactNode
  disabled?: boolean
}

const PopoverUI = ({ Trigger, children, disabled = false }: PopoverProps): JSX.Element => {
  const popoverStyle =
    "bg-white p-1 pt-[22px] border border-grey-200 rounded-xl menu_dropshadow flex items-center justify-center flex-col"
  return (
    <>
      <Popover.Root>
        <Popover.Trigger disabled={disabled}>{Trigger}</Popover.Trigger>
        <Popover.Content sideOffset={5} side="left" className={popoverStyle} defaultChecked>
          {children}
        </Popover.Content>
      </Popover.Root>
    </>
  )
}

export default PopoverUI
