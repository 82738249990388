import { Box, Typography } from "@material-ui/core"
import { DrawerSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import useDialog from "components/misc/useDialog"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import { toastCatch } from "components/ReactHookForm/utils"
import WarningTypography from "components/WarningTypography"
import { useToast } from "CryptioUI/Toaster"
import api from "services/api"
import { GetPeggableStablecoinsDto } from "services/api/openapi"

interface Props {
  stablecoins: GetPeggableStablecoinsDto
  workspaceId: string
}

export function WorkspaceApplyPeg({ stablecoins, workspaceId }: Props) {
  const { mutateAsync: applyPeggedStablecoinsMutation } = api.workspace.useApplyPeggedStablecoins()
  const [ApplyPegButton, handleButtonCallback] = useLoadingButton()
  const basicDialog = useDialog()
  const toast = useToast()

  const isAnyStablecoinPegged = stablecoins.stablecoins.some((coin) => coin.isPegged)

  async function applyPeggedStablecoins() {
    try {
      await applyPeggedStablecoinsMutation({
        workspaceId,
      })
      toast.open(`Pegging of the entire history started`, {
        variant: "success",
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const handleClick = async () => {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          You can't un-peg the historical prices of your stablecoins once this operation is done. The price of your
          selected stablecoins will remain 1:1 USD for all your transactions history.{" "}
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: handleButtonCallback(applyPeggedStablecoins),
    })
  }

  return (
    <>
      {basicDialog.dialog}
      <DrawerSection name="Apply pegging on the entire transactions history">
        <Box mt={2} mb={2}>
          <Typography variant="body2">
            By default, the pegging is applied to transactions coming after the activation of the system. Note that if
            you apply the pegging system on past transactions, it will update their cost basis. You can't un-peg the
            historical prices of your stablecoins once this operation is done.
            <br />
            If you have a period lock activated, the transactions of this period won't be impacted.
          </Typography>
        </Box>
        <PermissionDisabled
          permission="can_modify_workspace"
          action="apply the pegging to the entire history"
          titlePermissionEnable="Apply"
        >
          <ApplyPegButton disabled={!isAnyStablecoinPegged} onClick={handleClick}>
            Peg history
          </ApplyPegButton>
        </PermissionDisabled>
        {!isAnyStablecoinPegged && (
          <WarningTypography mt={2}>
            No stablecoins are pegged right now. The peg history button is disabled.
          </WarningTypography>
        )}
      </DrawerSection>
    </>
  )
}
