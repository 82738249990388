import { UseQueryOptions } from "react-query"

import { queryClient } from "../../.."
import { usePaginatedWorkspaceQuery, useWorkspaceMutation, useWorkspaceQuery } from "../../context/workspaceContext"
import { GetCOAMappingDto, WithoutWorkspaceId } from "../aliases"
import { ChartAccountsApi, GetChartAccountsRequest, GetCOAMappingsRequest } from "../openapi"
import { GET_TRANSACTION_KEY, GET_TRANSACTIONS_KEY } from "./transactions"
import { GET_WORKSPACE_METRICS_KEY, GET_WORKSPACES_OR_DEFAULT_KEY } from "./workspace"

export const GET_CHART_ACCOUNTS_KEY = "getChartAccounts"
export const GET_CHART_ACCOUNT_MAPPINGS_KEY = "getChartAccountMappings"
export const GET_CHART_ACCOUNT_MAPPING_KEY = "getChartAccountMapping"
export const HAS_INTERNAL_TRANSFER_MAPPING_KEY = "hasInternalTransferMapping"

export const chartAccountActions = {
  useChartAccounts: (variables: WithoutWorkspaceId<GetChartAccountsRequest>) => {
    return usePaginatedWorkspaceQuery([GET_CHART_ACCOUNTS_KEY, variables], ChartAccountsApi.getChartAccounts, variables)
  },
  useUpdateChartAccountVisible: () => {
    return useWorkspaceMutation(ChartAccountsApi.updateChartAccountsVisible, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_CHART_ACCOUNTS_KEY)
      },
    })
  },
  useChartAccountMappings: (variables: WithoutWorkspaceId<GetCOAMappingsRequest>) => {
    return usePaginatedWorkspaceQuery(
      [GET_CHART_ACCOUNT_MAPPINGS_KEY, variables],
      ChartAccountsApi.getCOAMappings,
      variables,
    )
  },
  useHasInternalTransferMapping: () => {
    return useWorkspaceQuery([HAS_INTERNAL_TRANSFER_MAPPING_KEY], ChartAccountsApi.hasInternalTransferMapping, {})
  },
  useChartAccountMapping: (mappingId: string | undefined | null, option?: UseQueryOptions<GetCOAMappingDto>) => {
    return useWorkspaceQuery(
      [GET_CHART_ACCOUNT_MAPPING_KEY, mappingId],
      ChartAccountsApi.getCOAMapping,
      { mappingId: mappingId ?? "" },
      {
        enabled: !!mappingId,
        ...option,
      },
    )
  },
  // TODO: we use Create for update for csv
  // TODO: rename update/create COA to something related to "import CSV"
  useReplaceChartAccounts: () => {
    return useWorkspaceMutation(ChartAccountsApi.replaceChartAccounts, {
      onSuccess: () => {
        // More accounts
        queryClient.invalidateQueries(GET_CHART_ACCOUNTS_KEY)

        // Can create a workspace accounting integration
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)

        // Can create the default mapping
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPINGS_KEY)
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPING_KEY)

        // Can remove (on update) some accounts => remove some from transaction
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
      },
    })
  },
  useCreateChartAccounts: () => {
    return useWorkspaceMutation(ChartAccountsApi.createChartAccounts, {
      onSuccess: () => {
        // More accounts
        queryClient.invalidateQueries(GET_CHART_ACCOUNTS_KEY)

        // Can create a workspace accounting integration
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)

        // Can create the default mapping
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPINGS_KEY)
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPING_KEY)

        // Can remove (on update) some accounts => remove some from transaction
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
      },
    })
  },
  useCreateMapping: () => {
    return useWorkspaceMutation(ChartAccountsApi.createCOAMapping, {
      onSuccess: () => {
        // Create a mapping
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPINGS_KEY)
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPING_KEY)

        // Make the mapping dirty
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)

        queryClient.invalidateQueries(HAS_INTERNAL_TRANSFER_MAPPING_KEY)
      },
    })
  },
  useUpdateMapping: () => {
    return useWorkspaceMutation(ChartAccountsApi.updateCOAMapping, {
      onSuccess: () => {
        // Update a mapping
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPINGS_KEY)
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPING_KEY)

        // Make the mapping dirty
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)

        queryClient.invalidateQueries(HAS_INTERNAL_TRANSFER_MAPPING_KEY)
      },
    })
  },
  useDeleteMapping: () => {
    return useWorkspaceMutation(ChartAccountsApi.deleteCOAMapping, {
      onSuccess: () => {
        // Delete a mapping
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPINGS_KEY)
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPING_KEY)

        // Make the mapping dirty
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)

        queryClient.invalidateQueries(HAS_INTERNAL_TRANSFER_MAPPING_KEY)
      },
    })
  },
  useCalculateAutomatedMapping: () => {
    return useWorkspaceMutation(ChartAccountsApi.calculateAutomatedMapping, {
      onSuccess: () => {
        // Update transaction count
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPINGS_KEY)
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPING_KEY)

        // Updating transaction_accounting
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)

        // No longer dirty
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
}
