import { Box, createStyles, makeStyles, Theme } from "@material-ui/core"
import { ReplayRounded } from "@material-ui/icons"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { GetTransactionFilterDto } from "services/api/openapi"
import { datePickerDateFormat, PlainDate } from "components/DateRangePicker"
import ButtonUI from "CryptioUI/Button"
import { ButtonSize, Mode } from "CryptioUI/types"
import { iconStyleMaterialUI } from "CryptioUI/Utilities/config"

import { pluralize } from "services/utils/textUtils"
import { TransactionParams } from "components/CoreTransaction"
import { FilterTypeWithDateState, FilterTypeWithoutDateState } from "."
import ButtonGroupUI from "CryptioUI/ButtonGroup"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clearButton: {
      marginRight: theme.spacing(2),
    },
  }),
)

interface Props {
  defaultParams: TransactionParams
  filter: TransactionParams
  setFilter: (newValue: TransactionParams) => void
  setIsDrawerOpen: (isOpen: boolean) => void
  onSave?: () => void
  setEndDateTemp: (value: PlainDate | undefined) => void
  setStartDateTemp: (value: PlainDate | undefined) => void
  setFilterLoaded: (value: GetTransactionFilterDto | undefined) => void
}

interface OpenButtonProps {
  filterCount: number
}

interface SaveButtonProps {
  filterCount: number
  canClear?: boolean
  canSave?: boolean
  onSaveClick?: () => void
}

function useFilterTransaction(props: Props) {
  const {
    defaultParams,
    filter,
    setFilter,
    setIsDrawerOpen,
    onSave,
    setEndDateTemp,
    setStartDateTemp,
    setFilterLoaded,
  } = props
  const classes = useStyles()
  const [tempFilter, setTempFilter] = useState<TransactionParams>(filter)
  const [tempFilterDate, setTempFilterDate] = useState<FilterTypeWithDateState[]>([])
  const [tempFilterWithoutDate, setTempFilterWithoutDate] = useState<FilterTypeWithoutDateState[]>([])

  useEffect(() => {
    setTempFilter({ filters: [...tempFilterDate, ...tempFilterWithoutDate] })
  }, [tempFilterDate, tempFilterWithoutDate])

  useEffect(() => {
    const convertedFilters = filter.filters
      .map((e) => {
        if (e.type !== "start_date" && e.type !== "end_date") return e
      })
      .filter((e) => e !== undefined)

    setTempFilterWithoutDate(convertedFilters as FilterTypeWithoutDateState[])
  }, [filter])

  useEffect(() => {
    const convertedFiltersStartDate = filter.filters.filter((e) => {
      if (e.type === "start_date") return e
    })

    const convertedFiltersEndDate = filter.filters.filter((e) => {
      if (e.type === "end_date") return e
    })

    // I will clean this when refactoring date picker
    const cleanValueStart = (convertedFiltersStartDate.length > 0 ? convertedFiltersStartDate[0].value : "") as string
    const dayjsStartDate = dayjs(cleanValueStart, datePickerDateFormat)
    const plainDateStart = new PlainDate(dayjsStartDate)

    const cleanValueEnd = (convertedFiltersEndDate.length > 0 ? convertedFiltersEndDate[0].value : "") as string
    const dayjsEndDate = dayjs(cleanValueEnd, datePickerDateFormat)
    const plainDateEnd = new PlainDate(dayjsEndDate)

    if (cleanValueStart.length > 0) {
      setStartDateTemp(plainDateStart)
    }
    if (cleanValueEnd.length > 0) {
      setEndDateTemp(plainDateEnd)
    }
    setTempFilterDate([...convertedFiltersStartDate, ...convertedFiltersEndDate] as FilterTypeWithDateState[])
  }, [filter, setEndDateTemp, setStartDateTemp])

  const OpenButton = ({ filterCount }: OpenButtonProps): JSX.Element => {
    function reset() {
      setFilter(defaultParams)
      setStartDateTemp(undefined)
      setEndDateTemp(undefined)
    }

    return filterCount === 0 ? (
      <ButtonUI iconPosition="left" size={ButtonSize.LG} onClick={() => setIsDrawerOpen(true)}>
        Filters
      </ButtonUI>
    ) : (
      <Box>
        <ButtonGroupUI mode={Mode.OUTLINED}>
          <ButtonUI mode={Mode.OUTLINEDHIGHLIGHT} size={ButtonSize.LG} onClick={() => setIsDrawerOpen(true)}>
            {filterCount} {pluralize(filterCount > 1, "filter")} activated
          </ButtonUI>
          <ButtonUI
            mode={Mode.CONTAINED}
            size={ButtonSize.LG}
            Icon={<ReplayRounded style={iconStyleMaterialUI} />}
            onClick={reset}
            data-test-id="reset-filter"
          >
            Reset
          </ButtonUI>
        </ButtonGroupUI>
      </Box>
    )
  }

  const SaveButton = ({ filterCount, canClear = true, canSave = true, onSaveClick }: SaveButtonProps): JSX.Element => {
    const saveFilter = () => {
      setIsDrawerOpen(false)
      setFilter(tempFilter)
      if (onSave) {
        onSave()
      }
      if (onSaveClick) onSaveClick()
    }
    const resetFilter = () => {
      if (filterCount > 0) setFilter(defaultParams)
      setTempFilter(defaultParams)
      setTempFilterWithoutDate([])
      setTempFilterDate([])
      setEndDateTemp(undefined)
      setStartDateTemp(undefined)
      setFilterLoaded(undefined)
    }

    return (
      <div className="mt-4 flex row">
        <ButtonUI
          size={ButtonSize.LG}
          className={classes.clearButton}
          mode={Mode.OUTLINED}
          onClick={resetFilter}
          disabled={canClear === false}
        >
          Clear
        </ButtonUI>
        <ButtonUI disabled={canSave === false} onClick={saveFilter}>
          Apply
        </ButtonUI>
      </div>
    )
  }

  return {
    tempFilter,
    tempFilterDate,
    tempFilterWithoutDate,
    OpenButton,
    SaveButton,
    setTempFilterDate,
    setTempFilterWithoutDate,
  }
}

export default useFilterTransaction
