import React, { useContext } from "react"
import { Action, useRegisterActions } from "kbar"
import { useHistory } from "react-router"
import { URLS } from "routes"
import { AccountCircleRounded, Build, Directions, SupervisorAccountRounded } from "@material-ui/icons"
import { isUuid4 } from "pure-shared"
import { WorkspaceContext } from "services/context/workspaceContext"
import useClipboardContent from "../useClipboardContent"

function useBackOfficeActions() {
  const clipboard = useClipboardContent()
  const history = useHistory()
  const workspaceCtx = useContext(WorkspaceContext)

  const seeAsUserWorkspace = (workspaceId: string) => {
    workspaceCtx.changeWorkspace(workspaceId)
    history.push(URLS.Portfolio)
  }

  const actions: Action[] = [
    {
      id: "backoffice",
      name: "Back Office",
      keywords: "backoffice",
      icon: <Build />,
      priority: 3,
    },
    {
      id: "manageWorkspaces",
      name: "Manage Workspaces",
      keywords: "workspace",
      perform: () => history.push(URLS.BackOffice.Workspace.getUrl()),
      parent: "backoffice",
      icon: <SupervisorAccountRounded />,
      priority: 1,
      shortcut: ["w"],
    },
    {
      id: "manageUsers",
      name: "Manage Users",
      keywords: "user",
      perform: () => history.push(URLS.BackOffice.User.getUrl()),
      parent: "backoffice",
      icon: <AccountCircleRounded />,
      priority: 0,
      shortcut: ["u"],
    },
  ]

  if (isUuid4(clipboard))
    actions.push({
      id: "goToWorkspace",
      name: `Go to Workspace ${clipboard}`,
      keywords: "go to workspace",
      perform: () => seeAsUserWorkspace(clipboard),
      parent: "backoffice",
      icon: <Directions />,
      priority: 2,
      shortcut: ["g"],
    })

  useRegisterActions(actions, [clipboard])
}

export default useBackOfficeActions
