import { KeyboardDatePicker } from "@material-ui/pickers"
import dayjs from "dayjs"
import React from "react"
import { datePickerDateFormat, PlainDate } from "../DateRangePicker"

interface Props {
  id?: string
  value: PlainDate | undefined
  onChange: (value: PlainDate) => void
  disableFuture?: boolean
  maxDateMessage?: React.ReactNode
  maxDate?: string
  fullWidth?: boolean
  disabled?: boolean
}

const PlainDatePicker = (props: Props) => {
  const { value, onChange, ...rest } = props

  const onChangeDate = (date: string | null | undefined) => {
    if (!date) return
    const dayjsDate = dayjs.utc(date, datePickerDateFormat)
    const plainDate = new PlainDate(dayjsDate)
    if (plainDate !== value) {
      onChange(plainDate)
    }
  }

  const checkValue = () => (value?.date.isValid() ? value?.date.toISOString() ?? null : null)

  return (
    <KeyboardDatePicker
      {...rest}
      format={datePickerDateFormat}
      value={checkValue()}
      onChange={(_, newDate) => onChangeDate(newDate)}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
    />
  )
}

export default PlainDatePicker
