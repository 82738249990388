import React from "react"

import { BoldWeight, TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"

interface StatusProps {
  isOpen: boolean
}

const Status = (props: StatusProps) => {
  const { isOpen } = props

  return (
    <div className="flex flex-row gap-4 justify-center items-center">
      <div
        className={`rounded-full w-4 h-4 ${
          isOpen ? "bg-label-green-200" : "bg-grey-200"
        }  border-solid border-[1px] border-grey-300`}
      />
      <TypographyUI variant={TypographyVariant.BODY} boldness={BoldWeight.REGULAR}>
        {isOpen ? "open" : "closed"}
      </TypographyUI>
    </div>
  )
}

export default Status
