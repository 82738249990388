import React from "react"
import { twMerge } from "tailwind-merge"

import { TypographyProps, TypographyVariant, ElementType, BoldWeight, BoldType } from "CryptioUI/types"

import TextEllipsisWrapper from "./TextEllipsisWrapper"
import TextLinkWrapper from "./TextLinkWrapper"
import VariableComponent from "./VariableComponent"

type DefaultComponentNameType = {
  [key in TypographyVariant]: ElementType
}

type DefaultFontSizeType = {
  [key in TypographyVariant]: string
}

const TypographyUI = (props: TypographyProps): JSX.Element => {
  const defaultComponentName: DefaultComponentNameType = {
    [TypographyVariant.H1]: ElementType.H1,
    [TypographyVariant.H2]: ElementType.H2,
    [TypographyVariant.H3]: ElementType.H3,
    [TypographyVariant.H4]: ElementType.H4,
    [TypographyVariant.CAPTION]: ElementType.SPAN,
    [TypographyVariant.BODY]: ElementType.P,
    [TypographyVariant.BUTTON]: ElementType.SPAN,
  }

  const defaultFontSize: DefaultFontSizeType = {
    [TypographyVariant.H1]: "text-4xl",
    [TypographyVariant.H2]: "text-3xl",
    [TypographyVariant.H3]: "text-xl",
    [TypographyVariant.H4]: "text-lg",
    [TypographyVariant.CAPTION]: "leading-5	text-xs",
    [TypographyVariant.BODY]: "text-sm",
    [TypographyVariant.BUTTON]: "leading-none text-sm",
  }

  const boldStyle: BoldType = {
    [BoldWeight.REGULAR]: "font-normal font-sans",
    [BoldWeight.MEDIUM]: "font-medium font-sans",
    [BoldWeight.BOLD]: "font-bold font-sans",
  }

  const {
    className,
    variant,
    children,
    componentName = defaultComponentName[variant],
    boldness = BoldWeight.REGULAR,
    uppercase = false,
    newTab = false,
    url = undefined,
    ellipsisPosition,
  } = props

  const target = newTab ? "_blank" : ""
  const baseStyle = "h-fit"
  const addUpercase = uppercase ? "uppercase" : ""
  const endEllipsisStyle = ellipsisPosition ? "w-full truncate" : "w-fit"
  const linkStyle = url ? "text-primary-300 underline" : ""
  const finaleStyle = twMerge(
    [
      boldStyle[boldness],
      baseStyle,
      linkStyle,
      addUpercase,
      defaultFontSize[variant],
      endEllipsisStyle,
      className,
    ].join(" "),
  )

  return (
    <VariableComponent className={finaleStyle} componentName={componentName}>
      <TextLinkWrapper url={url} target={target}>
        <TextEllipsisWrapper endEllipsisStyle={endEllipsisStyle} ellipsisPosition={ellipsisPosition}>
          {children}
        </TextEllipsisWrapper>
      </TextLinkWrapper>
    </VariableComponent>
  )
}
export default TypographyUI
