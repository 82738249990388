import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useMemo, useState } from "react"
import { validate } from "uuid"

import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import useFilterDrawer from "components/Hooks/useFilterDrawer"
import InputClearable from "components/Input/InputClearable"
import InputNumber from "components/Input/InputNumber"
import DateRangeFilter from "components/DateRangeFilter"
import {
  BackOfficeGetUsersEmailConfirmedEnum,
  BackOfficeGetUsersHasAccountingIntegrationEnum,
  BackOfficeGetUsersIs2FaEnabledEnum,
} from "services/api/openapi"
import isNumberStringValid from "services/utils/isNumberStringValid"
import { BackOfficeParameters, backOfficeUserHasAnyFilter, defaultBackOfficeGetUserParams } from "../index"
import { PlainDate } from "components/DateRangePicker"
import DrawerUI from "CryptioUI/Drawer"

interface Props {
  filter: Partial<BackOfficeParameters>
  setFilter: (newValue: Partial<BackOfficeParameters>) => void
}

const BackOfficeUserFilter = (props: Props): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const { OpenButton, SaveButton, tempFilter, updateFilter } = useFilterDrawer({
    ...props,
    defaultParams: defaultBackOfficeGetUserParams,
    setIsDrawerOpen,
  })

  const filterCount = useMemo(() => {
    const filter = props.filter
    let res = 0
    if (filter.userId) res++
    if (filter.queryCompany) res++
    if (filter.queryEmail) res++
    if (filter.registerStartDate || filter.registerEndDate) res++
    if (filter.lastLoggedInStartDate || filter.lastLoggedInEndDate) res++
    if (filter.emailConfirmed && filter.emailConfirmed !== "Both") res++
    if (filter.is2FaEnabled && filter.is2FaEnabled !== "Both") res++
    if (filter.hasAccountingIntegration && filter.hasAccountingIntegration !== "Both") res++
    if (filter.minimumLabel) res++
    if (filter.minimumTransaction) res++
    if (filter.minimumWallet) res++
    if (filter.inWorkspaceId) res++
    return res
  }, [props.filter])

  const canClear = filterCount > 0 || backOfficeUserHasAnyFilter(tempFilter)
  const errorOnId = tempFilter.userId ? !validate(tempFilter.userId) : false

  return (
    <>
      <OpenButton filterCount={filterCount} />

      <DrawerUI title="User Filter" onClose={() => setIsDrawerOpen(false)} isOpen={isDrawerOpen}>
        <DrawerCategory title="Filter" mb={3}>
          <DrawerFormSection htmlFor="search-id-textfield" name="Search by ID">
            <InputClearable
              error={errorOnId}
              helperText={errorOnId ? "Invalid ID" : null}
              id="search-id-textfield"
              value={tempFilter.userId || ""}
              onChange={(value) =>
                updateFilter({
                  userId: value,
                })
              }
              placeholder="User ID"
              fullWidth
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="search-workspace-id-textfield" name="User in workspace ID">
            <InputClearable
              id="search-workspace-id-textfield"
              value={tempFilter.inWorkspaceId || ""}
              onChange={(value) =>
                updateFilter({
                  inWorkspaceId: value,
                })
              }
              placeholder="Workspace ID"
              fullWidth
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="search-email-textfield" name="Search by email">
            <InputClearable
              id="search-email-textfield"
              value={tempFilter.queryEmail || ""}
              onChange={(value) =>
                updateFilter({
                  queryEmail: value,
                })
              }
              placeholder="Email"
              fullWidth
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="search-company-textfield" name="Search by company name">
            <InputClearable
              id="search-company-textfield"
              value={tempFilter.queryCompany || ""}
              onChange={(value) =>
                updateFilter({
                  queryCompany: value,
                })
              }
              placeholder="Company"
              fullWidth
            />
          </DrawerFormSection>

          <DrawerFormSection name="Register date">
            <DateRangeFilter
              value={{
                startDate: tempFilter.registerStartDate
                  ? PlainDate.fromBackendDate(tempFilter.registerStartDate)
                  : undefined,
                endDate: tempFilter.registerEndDate ? PlainDate.fromBackendDate(tempFilter.registerEndDate) : undefined,
              }}
              onDateRangeChange={(value) =>
                updateFilter({
                  registerStartDate: value.startDate?.toBackendDate(),
                  registerEndDate: value.endDate?.toBackendDate(),
                })
              }
            />
          </DrawerFormSection>

          <DrawerFormSection name="Last logged in">
            <DateRangeFilter
              value={{
                startDate: tempFilter.lastLoggedInStartDate
                  ? PlainDate.fromBackendDate(tempFilter.lastLoggedInStartDate)
                  : undefined,
                endDate: tempFilter.lastLoggedInEndDate
                  ? PlainDate.fromBackendDate(tempFilter.lastLoggedInEndDate)
                  : undefined,
              }}
              onDateRangeChange={(value) =>
                updateFilter({
                  lastLoggedInStartDate: value.startDate?.toBackendDate(),
                  lastLoggedInEndDate: value.endDate?.toBackendDate(),
                })
              }
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="minimum-wallet-textfield" name="Minimum Wallet Count">
            <InputNumber
              id="minimum-wallet-textfield"
              value={tempFilter.minimumWallet ?? ""}
              onChange={(value) => {
                updateFilter({
                  minimumWallet: value,
                })
              }}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="minimum-label-textfield" name="Minimum Label Count">
            <InputNumber
              id="minimum-label-textfield"
              value={tempFilter.minimumLabel ?? ""}
              onChange={(value) => {
                updateFilter({
                  minimumLabel: value,
                })
              }}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="minimum-transaction-textfield" name="Minimum Transaction Count">
            <InputNumber
              id="minimum-transaction-textfield"
              value={tempFilter.minimumTransaction ?? ""}
              onChange={(value) => {
                updateFilter({
                  minimumTransaction: value,
                })
              }}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="has-integration-select" name="Has an accounting integration?">
            <Autocomplete<BackOfficeGetUsersHasAccountingIntegrationEnum, false, true>
              id="has-integration-select"
              options={["Has an accounting integration", "Has no accounting integration", "Both"]}
              autoComplete
              includeInputInList
              filterSelectedOptions
              disableClearable
              value={tempFilter.hasAccountingIntegration ?? "Both"}
              onChange={(_, newValue) => updateFilter({ hasAccountingIntegration: newValue })}
              renderInput={(params) => <TextField {...params} />}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="email-confirmed-select" name="Is email confirmed?">
            <Autocomplete<BackOfficeGetUsersEmailConfirmedEnum, false, true>
              id="email-confirmed-select"
              options={["Confirmed", "Not confirmed", "Both"]}
              autoComplete
              includeInputInList
              filterSelectedOptions
              disableClearable
              value={tempFilter.emailConfirmed ?? "Both"}
              onChange={(_, newValue) => updateFilter({ emailConfirmed: newValue })}
              renderInput={(params) => <TextField {...params} />}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="is-2fa-enabled-select" name="Is two auth factor enabled?">
            <Autocomplete<BackOfficeGetUsersIs2FaEnabledEnum, false, true>
              id="is-2fa-enabled-select"
              options={["Enabled", "Disabled", "Both"]}
              autoComplete
              includeInputInList
              filterSelectedOptions
              disableClearable
              value={tempFilter.is2FaEnabled ?? "Both"}
              onChange={(_, newValue) => updateFilter({ is2FaEnabled: newValue })}
              renderInput={(params) => <TextField {...params} />}
            />
          </DrawerFormSection>

          <SaveButton
            filterCount={filterCount}
            canClear={canClear}
            canSave={
              (tempFilter.minimumLabel === undefined || isNumberStringValid(tempFilter.minimumLabel)) &&
              (tempFilter.minimumTransaction === undefined || isNumberStringValid(tempFilter.minimumTransaction)) &&
              (tempFilter.minimumWallet === undefined || isNumberStringValid(tempFilter.minimumWallet)) &&
              !errorOnId
            }
          />
        </DrawerCategory>
      </DrawerUI>
    </>
  )
}

export default BackOfficeUserFilter
