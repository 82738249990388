import { gtagEnabled, gtagId } from "../variables"
import { getScript } from "./getScript"

declare global {
  interface Window {
    // This variable is added by the Google Tag script
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any
  }
}

export async function getGtag(): Promise<Gtag.Gtag> {
  if (gtagEnabled) {
    try {
      return await getScript<Gtag.Gtag>(
        `https://www.googletagmanager.com/gtag/js?id=${gtagId}`,
        "gtag",
        false,
        "gtag",
        () => {
          // Code taken from the original google tag setup script
          window.dataLayer = window.dataLayer || []
          window.gtag = function gtag() {
            window.dataLayer.push(arguments) // eslint-disable-line prefer-rest-params
          }
          gtag("js", new Date())
          gtag("config", gtagId)
        },
      )
    } catch (_) {} // eslint-disable-line no-empty
  }

  // If anything did not work, just return an empty function.
  // Google Tag is only used for analytics or ads, so failing to load the script is
  // not a problem. The app needs to fully work without it.
  return function gtag() {}
}
