import { IconButton, InputAdornment, TextField, TextFieldProps } from "@material-ui/core"
import { ClearRounded } from "@material-ui/icons"
import React from "react"

interface Props extends Omit<TextFieldProps, "onError" | "variant" | "value" | "onChange"> {
  onChange: (value: string) => void
  onClear?: () => void
  value?: string
}

const InputClearable = (props: Props) => {
  const { value, onChange, onClear = () => onChange(""), ...rest } = props
  return (
    <TextField
      {...rest}
      value={value || ""}
      onChange={(x) => onChange(x.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClear} style={{ padding: 0 }}>
              <ClearRounded color="disabled" fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default InputClearable
