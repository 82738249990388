import React from "react"
// @ts-ignore
import MiddleEllipsis from "react-middle-ellipsis"

import { TextEllipsisWrapperProps } from "../types"

const TextEllipsisWrapper = (props: TextEllipsisWrapperProps): JSX.Element => {
  const { children, ellipsisPosition } = props

  return (
    <>
      {ellipsisPosition === "middle" ? (
        <MiddleEllipsis>
          <>{children}</>
        </MiddleEllipsis>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default TextEllipsisWrapper
