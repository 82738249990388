import { ThemeContext } from "services/context/themeContext"
import { WorkspaceFeature } from "services/api/openapi"
import { Box, Checkbox, makeStyles, Switch, Theme, Typography } from "@material-ui/core"
import { useContext, useEffect, useMemo, useState } from "react"
import BaseTable from "components/Table"
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from "services/constants"
import { widgetTableStyle } from "../../../Dashboard/widgets/widgetTableStyle"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import { DrawerCategory } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import api from "services/api"
import { IDataTableColumn } from "react-data-table-component"
import { useForm } from "react-hook-form"
import { useTypedController } from "@hookform/strictly-typed"
import DebouncedTextField from "components/selector/DebouncedTextField"
import { pluralize } from "services/utils/textUtils"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface WorkspaceFeaturesProps {
  workspaceId: string
}

type WorkspaceFeaturesFormType = {
  features: WorkspaceFeature[]
}

const useStyles = makeStyles((theme: Theme) => ({
  validateButton: {
    marginRight: theme.spacing(2),
  },
}))

const BackOfficeWorkspaceFeatures = (props: WorkspaceFeaturesProps) => {
  const classes = useStyles()

  const toast = useToast()
  const muiThemeContext = useContext(ThemeContext)
  const [SaveFeaturesButton, handleButtonCallback] = useLoadingButton()

  const workspaceFeatures = api.backOffice.workspace.useGetWorkspaceFeatures({ workspaceId: props.workspaceId })
  const { mutateAsync: updateWorkspaceFeaturesMutation } = api.backOffice.workspace.useUpdateWorkspaceFeatures()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [showPackageFeatures, setShowPackageFeatures] = useState<boolean>(false)
  const [search, setSearch] = useState<string>("")
  const [rowSelected, setRowSelected] = useState<WorkspaceFeature[]>([])

  const currentFeatureStates = useMemo<WorkspaceFeature[]>(
    () => JSON.parse(JSON.stringify(workspaceFeatures?.data?.features ?? [])),
    [workspaceFeatures?.data?.features],
  )

  const currentFeaturesFiltered = useMemo<WorkspaceFeature[]>(() => {
    return currentFeatureStates.filter(
      (feature) =>
        feature.name.toLowerCase().includes(search.toLowerCase()) &&
        (!showPackageFeatures ? feature.source !== "workspace_package" && feature.source !== "global_package" : true),
    )
  }, [currentFeatureStates, search, showPackageFeatures])

  const { handleSubmit, control } = useForm<WorkspaceFeaturesFormType>({
    mode: "onChange",
    defaultValues: {
      features: currentFeatureStates,
    },
  })

  const featuresToUpdate = useMemo<WorkspaceFeature[]>(() => {
    if (!workspaceFeatures?.data?.features) return []

    return workspaceFeatures.data.features.filter((feature) => {
      const row = currentFeatureStates.find((selected) => selected.id === feature.id)
      return row && row.isEnabled !== feature.isEnabled
    })
  }, [workspaceFeatures, currentFeatureStates])

  useEffect(() => {
    setRowSelected(currentFeatureStates)
  }, [currentFeatureStates])

  const TypedController = useTypedController<WorkspaceFeaturesFormType>({ control })

  const onSubmit = async () => {
    try {
      await updateWorkspaceFeaturesMutation({
        workspaceId: props.workspaceId,
        backOfficeWorkspaceFeaturesDto: {
          features: featuresToUpdate,
        },
      })
      toast.open("Workspace features updated", { variant: "success" })
      onClose()
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const columns: IDataTableColumn<WorkspaceFeature>[] = useMemo(
    () => [
      {
        maxWidth: "100px",
        selector: "checkbox",
        format: function formatCheckbox(row) {
          return (
            <TypedController
              name={["features"]}
              rules={{ required: false }}
              render={({ onChange, ...rest }) => {
                const customChange = () => {
                  const newRow = rowSelected.map((feature) => {
                    if (feature.id === row.id) {
                      feature.isEnabled = !row.isEnabled
                    }
                    return feature
                  })
                  onChange(newRow)
                  setRowSelected(newRow)
                }
                return (
                  <Checkbox
                    color="primary"
                    id={`${row.id}-checkbox`}
                    onChange={customChange}
                    checked={row.isEnabled}
                    disabled={row.source === "workspace_package" || row.source === "global_package"}
                    {...rest}
                  />
                )
              }}
            />
          )
        },
      },
      {
        maxWidth: "300px",
        name: "Name",
        selector: "name",
        sortable: true,
      },
      {
        name: "Source",
        selector: "source",
        sortable: true,
      },
    ],
    [TypedController, setRowSelected, rowSelected],
  )

  if (workspaceFeatures.isError) return <NetworkErrorMessage additionalData={workspaceFeatures} />
  if (workspaceFeatures.isLoading || workspaceFeatures.data === undefined) return <LoadingSpinner />

  const onClose = () => setIsModalOpen(false)
  const onOpen = () => setIsModalOpen(true)

  return (
    <DrawerCategory title="Workspace features">
      <div className="mt-4">
        {workspaceFeatures.isLoading && <LoadingSpinner />}
        {workspaceFeatures.isError && <NetworkErrorMessage small />}
        {workspaceFeatures.data && <ButtonUI onClick={onOpen}>Edit workspace features</ButtonUI>}
      </div>
      <ModalUI isOpen={isModalOpen} onClose={onClose} className="w-[800px] h-auto" title="Edit workspace features">
        <div className="mt-1 mb-1 mr-1 flex justify-between">
          <div className="flex justify-between items-center">
            <Switch checked={showPackageFeatures} onChange={(x) => setShowPackageFeatures(x.target.checked)} />
            <Typography>Show package features</Typography>
          </div>
          <DebouncedTextField placeholder="Search by name" value={search} setValue={setSearch} />
        </div>
        <BaseTable<WorkspaceFeature, "name">
          columns={columns}
          items={currentFeaturesFiltered}
          fixedScroll="400px"
          defaultParams={{
            sortBy: "name",
            sortDirection: "ascending",
          }}
          paginationRowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          paginationTotalRows={currentFeaturesFiltered.length}
          paginationPerPage={50}
          pagination={currentFeatureStates.length > DEFAULT_ROWS_PER_PAGE}
          customStyle={widgetTableStyle(muiThemeContext.type)}
          sortServer={false}
        />
        <Box component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
          <div className="flex justify-between items-center">
            <Typography variant="body1">
              {currentFeaturesFiltered.length} {pluralize(currentFeaturesFiltered.length > 1, "element")} found
            </Typography>
            <SaveFeaturesButton
              disabled={featuresToUpdate.length === 0}
              className={classes.validateButton}
              type="submit"
            >
              Save
            </SaveFeaturesButton>
          </div>
        </Box>
      </ModalUI>
    </DrawerCategory>
  )
}

export default BackOfficeWorkspaceFeatures
