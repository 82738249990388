import { useTypedController } from "@hookform/strictly-typed"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { Delete as DeleteIcon } from "@material-ui/icons"
import { BLOCKCHAIN_NAMES, BlockchainNameType } from "pure-shared"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import BaseTable from "components/Table"
import { IExtendedDataTableColumn } from "components/Table/interface"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { AssetBlockchainLink, GetPublicAssetDto } from "services/api/openapi"
import { toastCatch } from "components/ReactHookForm/utils"
import { useToast } from "CryptioUI/Toaster"

interface BlockchainLinkCreationDialogProps {
  asset: GetPublicAssetDto
  initial?: AssetBlockchainLink
  availableBlockchains: BlockchainNameType[]
  onCompleted: () => void
}

const formId = "create-asset-blockchain_link"

interface Form {
  blockchain: BlockchainNameType
  tokenAddress: string
}

function BlockchainLinkCreationDialog({
  asset,
  initial,
  availableBlockchains,
  onCompleted,
}: BlockchainLinkCreationDialogProps) {
  const { handleSubmit, control, formState, reset } = useForm<Form>({
    mode: "onChange",
    defaultValues: {
      blockchain: initial?.blockchain,
    },
  })
  const TypedController = useTypedController<Form>({ control })
  const [CreateButton, handleButtonCallback] = useLoadingButton()
  const { mutateAsync: createAssetBlockchainLink } = api.backOffice.asset.useCreateAssetBlockchainLink()
  const toast = useToast()

  const onSubmit = async ({ blockchain, tokenAddress }: Form) => {
    try {
      await createAssetBlockchainLink({
        assetId: asset.id,
        backOfficeCreateBlockchainLinkQuery: {
          blockchain: initial?.blockchain ?? blockchain,
          tokenAddress,
        },
      })
      toast.open("Blockchain link " + (initial ? "updated" : "created"), { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
    reset()
    onCompleted()
  }

  return (
    <DialogContent>
      <DialogTitle disableTypography>
        <Typography variant="h1" component="h2" style={{ textAlign: "center" }}>
          {initial ? `Update ${asset.name} link to ${initial.blockchain}` : `Link ${asset.name} to a chain`}
        </Typography>
      </DialogTitle>
      <form id={formId} onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
        {!initial && (
          <>
            <FormLabel htmlFor="blockchain-link-select">Blockchain</FormLabel>
            <TypedController
              name="blockchain"
              rules={{ required: true }}
              render={(props) => (
                <Select fullWidth id="blockchain-link-select" {...props}>
                  {availableBlockchains.map((p) => (
                    <MenuItem key={p} value={p}>
                      {p}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </>
        )}

        <Box mt={2}>
          <FormLabel htmlFor="blockchain-link-key-field">Token address</FormLabel>
          <TypedController
            name="tokenAddress"
            rules={{ required: true }}
            defaultValue={initial?.tokenAddress}
            render={(props) => <TextField {...props} id="blockchain-link-key-field" fullWidth margin="normal" />}
          />
        </Box>
      </form>
      <DialogActions>
        <CreateButton disabled={!formState.isValid} type="submit" form={formId}>
          {initial ? "Update" : "Create"}
        </CreateButton>
      </DialogActions>
    </DialogContent>
  )
}

interface AssetBlockchainLinksProps {
  asset: GetPublicAssetDto
}

export function AssetBlockchainLinks({ asset }: AssetBlockchainLinksProps) {
  const { mutateAsync: deleteAssetBlockchainLink } = api.backOffice.asset.useDeleteAssetBlockchainLink()
  const [creationDialogIsOpen, setCreationDialogIsOpen] = useState<boolean>(false)
  const [creationDialogInitialValue, setCreationDialogInitialValue] = useState<AssetBlockchainLink | undefined>()

  const unlinkedBlockchains = BLOCKCHAIN_NAMES.filter((b) => asset.blockchainLinks.every((bl) => bl.blockchain !== b))

  const onDeleteBlockchainLink = async (blockchain: BlockchainNameType) => {
    await deleteAssetBlockchainLink({
      assetId: asset.id,
      blockchain,
    })
  }

  const columns: IExtendedDataTableColumn<AssetBlockchainLink>[] = [
    {
      name: "Blockchain",
      selector: "blockchain",
    },
    {
      name: "Token address",
      selector: "tokenAddress",
    },
    {
      name: "Actions",
      selector: "delete",
      format: function DeleteBlockchainLink(row) {
        const [DeleteButton, handleButtonCallback] = useLoadingButton()
        return (
          <DeleteButton
            mode={Mode.TEXTONLY}
            onClick={handleButtonCallback(() => onDeleteBlockchainLink(row.blockchain))}
          >
            <DeleteIcon />
          </DeleteButton>
        )
      },
    },
  ]
  const creationDialog = (
    <Dialog open={creationDialogIsOpen} onClose={() => setCreationDialogIsOpen(false)}>
      <BlockchainLinkCreationDialog
        asset={asset}
        initial={creationDialogInitialValue}
        availableBlockchains={unlinkedBlockchains}
        onCompleted={() => setCreationDialogIsOpen(false)}
      />
    </Dialog>
  )

  return (
    <DrawerCategory title="Token addresses">
      {creationDialog}
      {asset.blockchainLinks.length === 0 ? (
        <Box mt={2}>
          <Typography variant="body1">This asset is not linked to any blockchain</Typography>
        </Box>
      ) : (
        <DrawerFormSection name="Click a row to edit">
          <BaseTable
            columns={columns}
            items={asset.blockchainLinks}
            onRowClicked={(row) => {
              setCreationDialogInitialValue(row)
              setCreationDialogIsOpen(true)
            }}
          />
        </DrawerFormSection>
      )}
      <Box mt={2}>
        <ButtonUI
          onClick={() => {
            setCreationDialogInitialValue(undefined)
            setCreationDialogIsOpen(true)
          }}
        >
          Add
        </ButtonUI>
      </Box>
    </DrawerCategory>
  )
}
