import { Box, Typography } from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import React, { useContext } from "react"

import { queryClient } from "../../.."
import { DrawerCategory } from "components/Drawer/DrawerItems"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import { DrawerProp } from "components/misc/useDrawer"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { GetWorkspaceDto } from "services/api/openapi/models"
import { GET_WORKSPACES_KEY, GET_WORKSPACES_OR_DEFAULT_KEY } from "services/api/routes/workspace"
import { UserContext } from "services/context/userContext"
import { WorkspaceContext } from "services/context/workspaceContext"
import { useGetWorkspaceOnboardingProgress } from "../../FabHelper/index"
import WorkspaceTimeline from "../WorkspaceTimeline"
import { WorkspaceAccountingOptions } from "./WorkspaceAccountingOptions"
import WorkspaceForm from "./WorkspaceForm"
import WorkspaceLockTransaction from "./WorkspaceLockTransactions"
import WorkspacePeggedStablecoins from "./WorkspacePeggedStablecoins"
import WorkspaceSummary from "./WorkspaceSummary"
import { FeatureGate } from "components/Feature/FeatureGate"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const WorkspaceDrawer = (props: DrawerProp<GetWorkspaceDto, true>): JSX.Element => {
  const { item: workspace, onClose } = props
  const toast = useToast()
  const workspaceCtx = useContext(WorkspaceContext)

  const basicDialog = useDialog()

  const isCreateMode = workspace === null

  const { mutateAsync: deleteWorkspaceUserMutation } = api.workspace.useDeleteWorkspaceUser()
  const { mutateAsync: updateShowOnboarding } = api.workspace.useUpdateShowOnboarding()
  const { user } = useContext(UserContext)

  const changeShowOnboarding = async (progress: boolean) => {
    try {
      await updateShowOnboarding({
        updateShowOnboardingDto: {
          showOnboarding: progress,
        },
      })
      toast.open("Changes saved", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }
  const metrics = api.workspace.useGetWorkspaceMetrics({ enabled: isCreateMode === false })
  const finish = useGetWorkspaceOnboardingProgress(metrics.data).finish
  if (isCreateMode === false && metrics.isError) return <NetworkErrorMessage additionalData={metrics} />
  if (isCreateMode === false && (metrics.isLoading || metrics.data === undefined)) {
    return <LoadingSpinner />
  }

  const doDeleteUser = async (userId: string) => {
    if (!workspace) return
    try {
      await deleteWorkspaceUserMutation({ workspaceId: workspace.id, userId })
      if (userId === user.id) {
        queryClient.invalidateQueries(GET_WORKSPACES_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
        toast.open("Left workspace", { variant: "success" })
        onClose()
      } else {
        toast.open("User deleted", { variant: "success" })
      }
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const askLeaveWorkspace = () => {
    if (!workspace) return
    basicDialog.showDialog({
      title: "Leave workspace?",
      content: (
        <Typography variant="h5">
          Do you really want to leave the workspace <b>{workspace.name}</b>?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: () => doDeleteUser(user.id),
    })
  }

  return (
    <Box mb={3}>
      {basicDialog.dialog}

      {workspace?.userRights.can_modify_workspace ||
      workspace?.userRights.can_modify_workspace_currency ||
      isCreateMode ? (
        <WorkspaceForm {...props} />
      ) : (
        <DrawerCategory title="Workspace">
          <Box mt={2}>
            <Typography variant="h5">Name</Typography>
            <Typography variant="body2" color="textSecondary">
              {workspace?.name}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="h5">Description</Typography>
            <Typography variant="body2" color="textSecondary">
              {workspace?.description}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="h5">Currency</Typography>
            <Typography variant="body2" color="textSecondary">
              {workspace?.defaultCurrencyName}
            </Typography>
          </Box>
        </DrawerCategory>
      )}

      {workspace && <WorkspaceAccountingOptions workspace={workspace} setFormDirty={props.setFormDirty} />}

      {workspace && (
        <>
          <WorkspacePeggedStablecoins item={workspace} />
          <FeatureGate feature="lock">
            <WorkspaceLockTransaction workspace={workspace} />
          </FeatureGate>
          {/* <WorkspaceRetroactivityTradeConsolidation workspace={workspace} />
          <WorkspaceRetroactivityNFT workspace={workspace} /> */}
          <DrawerCategory title="Workspace Progress">
            <Box mt={3} />
            <Typography variant="body2">
              Click to show the progress of the workspace&apos;s onboarding if it is not completed.
            </Typography>
            <Box mt={2} />
            <PermissionDisabled permission="can_modify_workspace" action="modify workspace">
              {workspaceCtx.workspace.showOnboarding === true ? (
                <ButtonUI mode={Mode.OUTLINEDHIGHLIGHT} disabled={finish} onClick={() => changeShowOnboarding(false)}>
                  Hide progress
                </ButtonUI>
              ) : (
                <ButtonUI mode={Mode.OUTLINEDHIGHLIGHT} disabled={finish} onClick={() => changeShowOnboarding(true)}>
                  Show progress
                </ButtonUI>
              )}
            </PermissionDisabled>
          </DrawerCategory>

          <WorkspaceSummary workspace={workspace} onClose={onClose} />

          <DrawerCategory title="Leave workspace">
            <Box mt={3} />

            {workspace.ownerUserId !== user.id ? (
              <>
                <Typography variant="body2">
                  Leaving a workspace is an irreversible action. You will need to ask for an invitation in order to join
                  this workspace again.
                </Typography>
                <Box mt={2} />
                <ButtonUI Icon={<Delete />} mode={Mode.OUTLINEDHIGHLIGHT} onClick={askLeaveWorkspace}>
                  Leave workspace
                </ButtonUI>
              </>
            ) : (
              <Typography variant="body2">
                You cannot leave a workspace if you are the owner of that workspace.
              </Typography>
            )}
          </DrawerCategory>

          <WorkspaceTimeline workspaceId={workspace.id} size={10} />
        </>
      )}
    </Box>
  )
}

export default WorkspaceDrawer
