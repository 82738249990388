import { Box, Paper } from "@material-ui/core"
import dayjs from "dayjs"
import React from "react"
import { IDataTableColumn } from "react-data-table-component"

import BaseContainer from "components/Container"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDrawer from "components/misc/useDrawer"
import { GenericSorting } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import TableTitleWithTotalCount from "components/Table/TableTitleWithTotalCount"
import api from "services/api"
import {
  BackOfficeGetMinimalUserDto,
  BackOfficeGetUsersRequest,
  BackOfficeGetUsersSortByEnum,
} from "services/api/openapi"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import BackOfficeUserFilter from "./filters/BackOfficeUserFilter"

const columns: IDataTableColumn<BackOfficeGetMinimalUserDto>[] = [
  {
    name: "Email",
    selector: "email",
  },
  {
    name: "Last Session",
    selector: "lastLoggedInAt",
    format: (row) => dayjs(row.lastLoggedInAt).fromNow(),
  },
  {
    name: "Sessions Count",
    selector: "loginCount",
  },
]

export const defaultBackOfficeUserSorting: GenericSorting<BackOfficeGetUsersSortByEnum> = {
  sortDirection: "descending",
  sortBy: "created_at",
}

export type BackOfficeParameters = Omit<
  BackOfficeGetUsersRequest,
  | "minimumTransaction"
  | "minimumLabel"
  | "minimumWallet"
  | "registerStartDate"
  | "registerEndDate"
  | "lastLoggedInStartDate"
  | "lastLoggedInEndDate"
> & {
  registerStartDate: string | undefined
  registerEndDate: string | undefined
  lastLoggedInStartDate: string | undefined
  lastLoggedInEndDate: string | undefined
  minimumWallet: string | undefined
  minimumLabel: string | undefined
  minimumTransaction: string | undefined
}

export const defaultBackOfficeGetUserParams: BackOfficeParameters = {
  page: 0,
  limit: 10,
  userId: undefined,
  queryCompany: "",
  queryEmail: "",
  registerStartDate: undefined,
  registerEndDate: undefined,
  lastLoggedInStartDate: undefined,
  lastLoggedInEndDate: undefined,
  minimumWallet: undefined,
  minimumLabel: undefined,
  minimumTransaction: undefined,
  inWorkspaceId: undefined,
  emailConfirmed: "Both",
  is2FaEnabled: "Both",
  hasAccountingIntegration: "Both",
}

export const backOfficeUserHasAnyFilter = (filters: Partial<BackOfficeParameters>) =>
  Boolean(
    filters.userId?.length ||
      filters.queryCompany ||
      filters.queryEmail ||
      filters.registerStartDate ||
      filters.registerEndDate ||
      filters.lastLoggedInStartDate ||
      filters.lastLoggedInEndDate ||
      filters.minimumWallet ||
      filters.minimumLabel ||
      filters.minimumTransaction ||
      filters.emailConfirmed !== "Both" ||
      filters.is2FaEnabled !== "Both" ||
      filters.hasAccountingIntegration !== "Both" ||
      filters.inWorkspaceId?.length,
  )

const BackOfficeUserScene = (): JSX.Element => {
  const [userDrawer, openUserDrawer] = useDrawer("backoffice-user")
  const { params, setLimit, setPage, setSorting, setParams } = usePaginatedParams<
    Partial<BackOfficeParameters>,
    BackOfficeGetUsersSortByEnum
  >(defaultBackOfficeGetUserParams, defaultBackOfficeUserSorting)

  const users = api.backOffice.user.useUsers({
    ...params,
    registerStartDate: params.registerStartDate,
    registerEndDate: params.registerEndDate,
    lastLoggedInStartDate: params.lastLoggedInStartDate,
    lastLoggedInEndDate: params.lastLoggedInEndDate,
    userId: params.userId?.length ? params.userId : undefined,
    inWorkspaceId: params.inWorkspaceId?.length ? params.inWorkspaceId : undefined,
    minimumLabel: params.minimumLabel !== undefined ? Number(params.minimumLabel) : undefined,
    minimumTransaction: params.minimumTransaction !== undefined ? Number(params.minimumTransaction) : undefined,
    minimumWallet: params.minimumWallet !== undefined ? Number(params.minimumWallet) : undefined,
  })

  if (users.isError) return <NetworkErrorMessage small={false} additionalData={users} />

  if (users.isLoading || users.data === undefined) return <LoadingSpinner />

  return (
    <BaseContainer>
      {userDrawer}
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="flex-end">
        <TableTitleWithTotalCount title="Users" qualifier="User" totalCount={users.data.totalCount} />
        <Box display="flex" alignItems="flex-end">
          <Box mr={2}>
            <BackOfficeUserFilter setFilter={setParams} filter={params} />
          </Box>
        </Box>
      </Box>
      <Paper>
        <ServerSideTable<BackOfficeGetMinimalUserDto, BackOfficeGetUsersSortByEnum>
          columns={columns}
          items={users.data.data}
          limit={users.data.limit}
          totalCount={users.data.totalCount}
          defaultParams={params}
          onPageChange={setPage}
          onLimitChange={setLimit}
          onSort={setSorting}
          onRowClicked={(row) => openUserDrawer(row.id)}
        />
      </Paper>
    </BaseContainer>
  )
}

export default BackOfficeUserScene
