import { useTypedController } from "@hookform/strictly-typed"
import _ from "lodash"
import { Box, Checkbox, FormLabel, IconButton, TextField, Typography } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { ReactComponent as Delete } from "CryptioUI/assets/icons/delete.svg"
import { ReactComponent as Add } from "CryptioUI/assets/icons/add.svg"

import ChartAccountOptionInAutocomplete from "components/AutoCompleteOptions/ChartAccountOptionInAutocomplete"
import LabelOptionInAutocomplete from "components/AutoCompleteOptions/LabelOptionInAutocomplete"
import WalletOptionInAutocomplete from "components/AutoCompleteOptions/WalletOptionInAutocomplete"
import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import useDialog from "components/misc/useDialog"
import useDrawer, { DrawerProp } from "components/misc/useDrawer"
import CustomSelector from "components/selector/CustomSelector"
import api from "services/api"
import { BasicElement, GetCOAMappingDto, WithoutWorkspaceId } from "services/api/aliases"
import {
  AutomatedMappingDefaultAccount,
  COAAccountElement,
  COAWalletElement,
  CreateAutomatedMappingDto,
  GetChartAccountDto,
  GetChartAccountsTypesEnum,
  GetCOAMappingsTypeEnum,
  GetLabelDto,
  GetWalletDto,
  GetWalletsRequest,
  IndividualMappingTypeEnum,
  UpdateAutomatedMappingDto,
} from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { Mixpanel } from "services/mixpanel"
import DialogIndividualMapping from "../DialogCreateIndividualMapping"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import LoadingSpinner from "components/misc/LoadingSpinner"
import ButtonUI from "CryptioUI/Button"
import { AccountingFilterContext, Tabs } from "services/context/accountingFilterContext"
import { iconStyleBlack, iconStyleWhite } from "CryptioUI/Utilities/config"
import { WalletKindsExchangeMapping, WalletKindsWalletMapping } from "pure-shared"
import { IndividualMappingItem } from "./index"
import TooltipUI from "CryptioUI/Tooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const defaultMappedAccountOptions: {
  name: keyof DefaultMappedAccountOptions & keyof AutomatedMappingDefaultAccount
  displayName: string
  acceptTypes?: GetChartAccountsTypesEnum[]
}[] = [
  {
    name: "gainsAccount",
    displayName: "Map all realized gains in a default gain account",
    acceptTypes: ["expense", "income"],
  },
  {
    name: "lossesAccount",
    displayName: "Map all realized losses in a default loss account",
    acceptTypes: ["expense", "income"],
  },
  {
    name: "feesAccount",
    displayName: "Select the expense account to book all fees from your wallets and exchanges",
    acceptTypes: ["expense"],
  },
]

type MappingTypeMap = {
  [key in Tabs as string]: string
}

const defaultMappingType: MappingTypeMap = {
  ["label_mapping"]: "Label mapping",
  ["wallet_mapping"]: "Wallet mapping",
  ["exchange_mapping"]: "Exchange mapping",
}

const mappingTypeOptions: GetCOAMappingsTypeEnum[] = ["wallet_mapping", "exchange_mapping", "label_mapping"]

interface DefaultMappedAccountOptions {
  gainsAccount: COAAccountElement | null
  lossesAccount: COAAccountElement | null
  feesAccount: COAAccountElement | null
}
interface MappingFormEditionType extends DefaultMappedAccountOptions {
  mappingType: GetCOAMappingsTypeEnum | null
  wallets: GetWalletDto[]
  label: BasicElement | null
  isInternalTransferLabel: boolean
  assetAccount: COAAccountElement | null
  expenseAccount: COAAccountElement | null
  labelAccount: COAAccountElement | null
}

const sortIndividualMapping = (a: IndividualMappingItem, b: IndividualMappingItem) => {
  if (a.type !== b.type) {
    return a.type.localeCompare(b.type)
  }
  if (a.asset.id < b.asset.id) {
    return -1
  }
  if (a.asset.id > b.asset.id) {
    return 1
  }
  return 0
}

const MappingFormEdition = (props: DrawerProp<GetCOAMappingDto, true>) => {
  const workspaceCtx = useContext(WorkspaceContext)
  const { isOpen, item: mapping, onClose, setFormDirty, formData } = props
  const { isNeedReview, tab } = useContext(AccountingFilterContext)
  const defaultValuesIsNeedReview = {
    wallets: formData?.wallets as unknown as COAWalletElement[],
    label: formData?.row as unknown as BasicElement,
    mappingType: tab as GetCOAMappingsTypeEnum,
    isInternalTransferLabel: mapping?.type === "label_mapping" ? mapping.isInternalTransferLabel : false,
  }
  const basicDefaultValues = {
    isInternalTransferLabel: mapping?.type === "label_mapping" ? mapping.isInternalTransferLabel : false,
    label: mapping?.type === "label_mapping" ? mapping?.label : undefined,
    wallets: mapping?.type === "wallet_mapping" ? mapping?.wallets : undefined,
  }

  const { handleSubmit, control, formState, reset, watch } = useForm<MappingFormEditionType>({
    mode: "onChange",
    defaultValues: isNeedReview ? defaultValuesIsNeedReview : basicDefaultValues,
  })
  const TypedController = useTypedController<MappingFormEditionType>({ control })
  const toast = useToast()
  const basicDialog = useDialog()

  const { mutateAsync: createMappingMutation } = api.chartAccount.useCreateMapping()
  const { mutateAsync: updateMappingMutation } = api.chartAccount.useUpdateMapping()
  const hasInternalTransferMapping = api.chartAccount.useHasInternalTransferMapping()

  const watchAllFields = watch()
  const [assetDrawer, openAssetDrawer] = useDrawer("asset")
  const [individualMappings, setIndividualMappings] = useState<IndividualMappingItem[]>([])
  const [individualMappingType, setIndividualMappingType] = useState<IndividualMappingTypeEnum | null>(null)

  const isFormDirty = !!(
    (formState.isValid &&
      formState.isDirty &&
      (!mapping ||
        (mapping.type === "default_mapping" &&
          (mapping.feesAccount !== watchAllFields.feesAccount ||
            mapping.gainsAccount !== watchAllFields.gainsAccount ||
            mapping.lossesAccount !== watchAllFields.lossesAccount)) ||
        (mapping.type === "label_mapping" &&
          (mapping.labelAccount.id !== watchAllFields.labelAccount?.id ||
            mapping.label?.id !== watchAllFields.label?.id ||
            mapping.isInternalTransferLabel !== watchAllFields.isInternalTransferLabel)) ||
        ((mapping.type === "exchange_mapping" || mapping.type === "wallet_mapping") &&
          (mapping.assetAccount?.id !== watchAllFields.assetAccount?.id ||
            mapping.expenseAccount?.id !== watchAllFields.expenseAccount?.id ||
            _.intersectionBy([watchAllFields.wallets, mapping?.wallets], "id"))))) ||
    (mapping &&
      (mapping.type === "default_mapping" ||
        mapping.type === "exchange_mapping" ||
        mapping.type === "wallet_mapping") &&
      JSON.stringify(individualMappings.sort(sortIndividualMapping)) !==
        JSON.stringify(mapping.individualMappings.sort(sortIndividualMapping)))
  )

  useEffect(() => {
    setFormDirty(isFormDirty)
  }, [isFormDirty, setFormDirty])

  const isEditMode = !!mapping
  const isCreateMode = mapping === null

  const confirmChangeType = (onAccept: () => void) => {
    if (isFormDirty) {
      basicDialog.showDialog({
        title: "Change without saving?",
        content: <Typography variant="h5">You will loose all your individual mappings</Typography>,
        yesText: "Yes",
        noText: "Cancel",
        onAccept,
      })
    }
  }

  useEffect(() => {
    if (isOpen) {
      if (
        mapping &&
        (mapping.type === "default_mapping" || mapping.type === "wallet_mapping" || mapping.type === "exchange_mapping")
      ) {
        setIndividualMappings([...mapping.individualMappings])
        if (mapping.type === "wallet_mapping" || mapping.type === "exchange_mapping") {
          reset({
            wallets: mapping.wallets,
          })
        }
      } else {
        setIndividualMappings([])
        reset()
      }
    }
  }, [isOpen, mapping, reset, setIndividualMappings])

  const [SaveMappingButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = useCallback(
    async (form: MappingFormEditionType) => {
      try {
        if (mapping) {
          // TODO: Fix form typing ?
          await updateMappingMutation({
            updateAutomatedMappingDto: {
              data: {
                mappingId: mapping.id,
                type: mapping.type,
                feesAccountId: form.feesAccount?.id,
                gainsAccountId: form.gainsAccount?.id,
                lossesAccountId: form.lossesAccount?.id,
                isFavorite: mapping.type === "label_mapping" ? mapping.isFavorite : false,
                walletIds: form.wallets?.map((wallet) => wallet.id) ?? null,
                labelId: form.isInternalTransferLabel ? null : form.label?.id,
                isInternalTransferLabel: form.isInternalTransferLabel ?? false,
                assetAccountId: form.assetAccount?.id,
                expenseAccountId: form.expenseAccount?.id,
                labelAccountId: form.labelAccount?.id,
                individualMappings:
                  individualMappings?.map((map) => ({
                    assetId: map.asset.id,
                    accountId: map.account.id,
                    type: map.type,
                  })) ?? [],
              },
            } as UpdateAutomatedMappingDto,
          })
          toast.open("Mapping update successful", { variant: "success" })
        } else {
          if (!form.mappingType) {
            return
          }
          await createMappingMutation({
            createAutomatedMappingDto: {
              data: {
                type: form.mappingType,
                walletIds: form.wallets?.map((wallet) => wallet.id) ?? null,
                labelId: form.isInternalTransferLabel ? null : form.label?.id,
                isInternalTransferLabel: form.isInternalTransferLabel ?? false,
                isFavorite: false,
                assetAccountId: form.assetAccount?.id,
                expenseAccountId: form.expenseAccount?.id,
                labelAccountId: form.labelAccount?.id,
                individualMappings:
                  individualMappings?.map((map) => ({
                    assetId: map.asset.id,
                    accountId: map.account.id,
                    type: map.type,
                  })) ?? [],
              },
            } as CreateAutomatedMappingDto,
          })
          toast.open("Mapping created", { variant: "success" })
        }
        onClose()
      } catch (e) {
        toastCatch(e, toast)
      }
      if (!mapping && form.mappingType) Mixpanel.track("CreateMapping", { mappingType: form.mappingType })
    },
    [onClose, createMappingMutation, updateMappingMutation, toast, mapping, individualMappings],
  )

  if (hasInternalTransferMapping.isError) return <NetworkErrorMessage additionalData={hasInternalTransferMapping} />

  if (hasInternalTransferMapping.isLoading || hasInternalTransferMapping.data === undefined) return <LoadingSpinner />

  const displayMappings = (mappingsArray: IndividualMappingItem[]) => {
    return mappingsArray.map((individualMap) => (
      <Box key={individualMap.asset.id} mt={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography>
          <Typography
            component="span"
            style={{ fontWeight: "bold", cursor: "pointer" }}
            onClick={() => openAssetDrawer(individualMap.asset.id)}
          >
            {individualMap.asset.name}
          </Typography>{" "}
          is mapped to{" "}
          <Typography component="span" style={{ fontWeight: "bold" }}>
            {individualMap.account.name}
          </Typography>
        </Typography>
        <IconButton
          onClick={() => {
            const newMappings = individualMappings.concat()
            newMappings.splice(newMappings.indexOf(individualMap), 1)
            setIndividualMappings(newMappings)
          }}
          style={{ padding: 0 }}
        >
          <Delete className={iconStyleBlack} />
        </IconButton>
      </Box>
    ))
  }

  return (
    <>
      <DialogIndividualMapping
        walletIds={watchAllFields.wallets ? watchAllFields.wallets.map((wallet) => wallet.id) : undefined}
        isOpen={individualMappingType !== null}
        actualMapping={individualMappings}
        mappingType={individualMappingType}
        onClose={() => setIndividualMappingType(null)}
        addMapping={(newMapping) => setIndividualMappings(individualMappings.concat([newMapping]))}
      />

      {assetDrawer}

      <DrawerCategory
        component="form"
        title={isEditMode ? "Update mapping" : "New mapping"}
        onSubmit={handleButtonCallback(handleSubmit(onSubmit))}
      >
        {basicDialog.dialog}

        {isCreateMode && (
          <DrawerFormSection htmlFor="type-select" name="Type">
            <TypedController
              key={"mappingType"}
              name={"mappingType"}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <Autocomplete
                  id="type-select"
                  value={value}
                  disabled={isNeedReview}
                  onChange={(_, newValue) => {
                    if ((value === "exchange_mapping" || value === "wallet_mapping") && individualMappings.length) {
                      confirmChangeType(() => {
                        setIndividualMappings([])
                        onChange(newValue)
                      })
                    } else {
                      onChange(newValue)
                    }
                  }}
                  getOptionLabel={(option) => defaultMappingType[option]}
                  options={mappingTypeOptions}
                  renderInput={(params) => <TextField {...params} placeholder="Select a mapping type" />}
                  size="small"
                />
              )}
            ></TypedController>
          </DrawerFormSection>
        )}

        {mapping && mapping.type && (
          <>
            {isEditMode && mapping.type === "default_mapping" && (
              <>
                {defaultMappedAccountOptions.map((account) => (
                  <DrawerFormSection htmlFor={account.name} name={account.displayName} key={account.name}>
                    <TypedController
                      key={account.name}
                      name={account.name}
                      defaultValue={
                        (mapping.type === "default_mapping" &&
                          mapping.type === "default_mapping" &&
                          mapping[account.name]) ||
                        null
                      }
                      rules={{ required: true }}
                      render={({ ref: propRef, onChange, ...rest }) => (
                        <CustomSelector
                          id={account.name}
                          {...rest}
                          inputRef={propRef}
                          fullWidth
                          onChange={(_, newValue) => onChange(newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultPaginatedQueryProps={{
                            types: account.acceptTypes,
                          }}
                          getOptionSelected={(option, value) => option.id === value.id}
                          error={!!formState.errors[account.name]}
                          usePaginatedQuery={api.chartAccount.useChartAccounts}
                          size="small"
                          renderOption={(option) => (
                            <ChartAccountOptionInAutocomplete account={option as GetChartAccountDto} />
                          )}
                          placeholder="Search account"
                        />
                      )}
                    />
                  </DrawerFormSection>
                ))}

                <DrawerFormSection name="Map all realized gains of a particular asset in a default gain account">
                  <ButtonUI
                    onClick={() => {
                      setIndividualMappingType("gains_mapping")
                    }}
                    Icon={<Add className={iconStyleWhite} />}
                  >
                    Individual mapping
                  </ButtonUI>

                  {displayMappings(individualMappings.filter((mapping) => mapping.type === "gains_mapping"))}
                </DrawerFormSection>

                <DrawerFormSection name="Map all realized losses of a particular asset in a default loss account">
                  <ButtonUI
                    onClick={() => {
                      setIndividualMappingType("losses_mapping")
                    }}
                    Icon={<Add className={iconStyleWhite} />}
                  >
                    Individual mapping
                  </ButtonUI>

                  {displayMappings(individualMappings.filter((mapping) => mapping.type === "losses_mapping"))}
                </DrawerFormSection>
              </>
            )}

            {(mapping.type === "exchange_mapping" || mapping.type === "wallet_mapping") && (
              <>
                <DrawerFormSection
                  htmlFor="source-select"
                  name={`Select the ${mapping.type === "wallet_mapping" ? "wallet(s)" : "exchange(s)"} you want to map`}
                >
                  <TypedController
                    name="wallets"
                    rules={{ required: true }}
                    render={({ ref: propRef, value, onChange }) => {
                      return (
                        <CustomSelector<WithoutWorkspaceId<GetWalletsRequest>, GetWalletDto, true>
                          id="source-select"
                          value={value}
                          inputRef={propRef}
                          multiple
                          onChange={(_, newValue) => {
                            if (
                              (mapping.type === "exchange_mapping" || mapping.type === "wallet_mapping") &&
                              individualMappings.length
                            ) {
                              confirmChangeType(() => {
                                setIndividualMappings([])
                                onChange(newValue)
                              })
                            } else {
                              onChange(newValue)
                            }
                          }}
                          getOptionLabel={(option) => option.name}
                          defaultPaginatedQueryProps={{
                            sortBy: "name",
                            sortDirection: "ascending",
                            walletTypeTypes:
                              mapping.type === "wallet_mapping" ? WalletKindsWalletMapping : WalletKindsExchangeMapping,
                            excludeWithMapping: true,
                          }}
                          usePaginatedQuery={api.wallet.useWallets}
                          size="small"
                          filterSelectedOptions
                          renderOption={(option) => <WalletOptionInAutocomplete wallet={option} />}
                          getOptionSelected={(option, value) => option.id === value.id}
                          disableCloseOnSelect
                        />
                      )
                    }}
                  />
                </DrawerFormSection>

                <DrawerFormSection name="Map the top assets with their respective asset account">
                  <ButtonUI
                    onClick={() => {
                      setIndividualMappingType(mapping.type)
                    }}
                    disabled={!watchAllFields.wallets}
                    Icon={<Add className={iconStyleWhite} />}
                  >
                    Individual mapping
                  </ButtonUI>

                  {displayMappings(individualMappings)}
                </DrawerFormSection>

                <DrawerFormSection
                  htmlFor="asset-account-select"
                  name="Map all the other assets with a default asset account"
                >
                  <TypedController
                    name="assetAccount"
                    rules={{ required: false }}
                    defaultValue={
                      mapping && (mapping.type === "wallet_mapping" || mapping.type === "exchange_mapping")
                        ? mapping.assetAccount
                        : null
                    }
                    render={({ ref: propRef, onChange, ...rest }) => (
                      <CustomSelector
                        id="asset-account-select"
                        {...rest}
                        inputRef={propRef}
                        onChange={(_, newValue) => onChange(newValue)}
                        getOptionLabel={(option) => option.name}
                        defaultPaginatedQueryProps={{
                          sortBy: "name",
                          sortDirection: "ascending",
                          types: ["asset"],
                        }}
                        getOptionSelected={(option, value) => option.id === value.id}
                        usePaginatedQuery={api.chartAccount.useChartAccounts}
                        size="small"
                        filterSelectedOptions
                        renderOption={(option) => (
                          <ChartAccountOptionInAutocomplete account={option as GetChartAccountDto} />
                        )}
                      />
                    )}
                  />
                </DrawerFormSection>

                <DrawerFormSection
                  htmlFor="expense-account-select"
                  name="Select the expense account to book the fees (optional). It will replace the default account for fees in the default mapping"
                >
                  <TypedController
                    name="expenseAccount"
                    rules={{ required: false }}
                    defaultValue={
                      mapping && (mapping.type === "wallet_mapping" || mapping.type === "exchange_mapping")
                        ? mapping.expenseAccount
                        : null
                    }
                    render={({ ref: propRef, onChange, ...rest }) => (
                      <CustomSelector
                        id="expense-account-select"
                        {...rest}
                        inputRef={propRef}
                        onChange={(_, newValue) => onChange(newValue)}
                        getOptionLabel={(option) => option.name}
                        defaultPaginatedQueryProps={{
                          sortBy: "name",
                          sortDirection: "ascending",
                          types: ["expense"],
                        }}
                        getOptionSelected={(option, value) => option.id === value.id}
                        usePaginatedQuery={api.chartAccount.useChartAccounts}
                        size="small"
                        filterSelectedOptions
                        renderOption={(option) => (
                          <ChartAccountOptionInAutocomplete account={option as GetChartAccountDto} />
                        )}
                      />
                    )}
                  />
                </DrawerFormSection>
              </>
            )}

            {((isEditMode && mapping && mapping.type === "label_mapping") ||
              (isCreateMode && watchAllFields.mappingType === "label_mapping")) && (
              <>
                <DrawerFormSection htmlFor="label-select" name="Label">
                  <TypedController
                    name="label"
                    rules={{ required: false }}
                    render={({ ref, onChange, value, ...rest }) => {
                      return (
                        <CustomSelector
                          id="label-select"
                          value={value}
                          {...rest}
                          inputRef={ref}
                          fullWidth
                          onChange={(_, newValue) => onChange(newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultPaginatedQueryProps={{
                            withNoMappingOnly: true,
                          }}
                          getOptionSelected={(option, value) => option.id === value.id}
                          disabled={watchAllFields.isInternalTransferLabel || (formData?.row ? true : false)}
                          error={!!formState.errors.label}
                          usePaginatedQuery={api.label.useLabels}
                          size="small"
                          placeholder="Type to search..."
                          renderOption={(option) => <LabelOptionInAutocomplete label={option as GetLabelDto} />}
                        />
                      )
                    }}
                  />
                </DrawerFormSection>
                <Box display="flex" alignItems="center" mt={3}>
                  <TypedController
                    name="isInternalTransferLabel"
                    defaultValue={mapping && mapping.type === "label_mapping" ? mapping.isInternalTransferLabel : false}
                    rules={{ required: false }}
                    render={({ onChange, value, ...rest }) =>
                      hasInternalTransferMapping.data.mappingExists &&
                      (!mapping || !(mapping.type === "label_mapping" && mapping.isInternalTransferLabel)) ? (
                        <TooltipUI content="Cannot have multiple internal transfer mapping">
                          <span>
                            <Checkbox
                              id="internal-mapping-checkbox"
                              style={{ paddingLeft: 0 }}
                              onChange={() => onChange(!value)}
                              checked={value}
                              disabled
                              color="primary"
                              {...rest}
                            />
                          </span>
                        </TooltipUI>
                      ) : (
                        <Checkbox
                          id="internal-mapping-checkbox"
                          style={{ paddingLeft: 0 }}
                          onChange={() => onChange(!value)}
                          checked={value}
                          color="primary"
                          {...rest}
                        />
                      )
                    }
                  />
                  <FormLabel htmlFor="internal-mapping-checkbox">Internal Transfer</FormLabel>
                </Box>

                <DrawerFormSection
                  htmlFor="label-account-select"
                  name="Select an account to map all transactions with this label"
                >
                  <TypedController
                    name="labelAccount"
                    rules={{ required: true }}
                    defaultValue={mapping && mapping.type === "label_mapping" ? mapping.labelAccount : null}
                    render={({ ref, onChange, ...rest }) => (
                      <CustomSelector
                        id="label-account-select"
                        {...rest}
                        inputRef={ref}
                        onChange={(_, newValue) => onChange(newValue)}
                        getOptionLabel={(option) => option.name}
                        defaultPaginatedQueryProps={{
                          sortBy: "name",
                          sortDirection: "ascending",
                          types: workspaceCtx.workspace.areChartAccountMappingsRestricted
                            ? ["liability", "income", "expense", "equity"]
                            : undefined,
                        }}
                        getOptionSelected={(option, value) => option.id === value.id}
                        usePaginatedQuery={api.chartAccount.useChartAccounts}
                        size="small"
                        placeholder="Search account"
                        filterSelectedOptions
                        renderOption={(option) => (
                          <ChartAccountOptionInAutocomplete account={option as GetChartAccountDto} />
                        )}
                      />
                    )}
                  />
                </DrawerFormSection>
              </>
            )}
          </>
        )}

        <Box mt={3}>
          <SaveMappingButton
            disabled={
              !isFormDirty ||
              (mapping?.type === "label_mapping" && !(watchAllFields.isInternalTransferLabel || watchAllFields.label))
            }
            type="submit"
          >
            {isEditMode && "Save"}
            {isCreateMode && "Create"}
          </SaveMappingButton>
        </Box>
      </DrawerCategory>
    </>
  )
}

export default MappingFormEdition
