import { Box, Button, ButtonGroup, FormControlLabel, FormLabel, Switch } from "@material-ui/core"
import { KeyboardDatePicker } from "@material-ui/pickers"
import dayjs, { Dayjs } from "dayjs"
import { PlainDateFormat, plainDateToUtcDayJs } from "pure-shared"
import React, { useEffect, useState } from "react"

// TODO: use a lib like `js-joda` to handle Plain Date things (front and backend) for display etc. (report / wallet / filters / date pickers)
// TODO: with string serialisation we cannot keep the invalid state (when date is after max date / before min date etc.)
export class PlainDate {
  public readonly isValid: boolean
  public readonly date: Dayjs

  constructor(date: Dayjs) {
    this.isValid = date.isValid()
    this.date = date
  }

  toBackendDate() {
    if (!this.isValid) return "Invalid date"
    return this.date.format(PlainDateFormat)
  }

  valueOf() {
    return this.date.valueOf()
  }

  static fromBackendDate(date: string): PlainDate {
    return new PlainDate(plainDateToUtcDayJs(date))
  }
}

export interface DateRange {
  startDate?: PlainDate
  endDate?: PlainDate
}

export interface NonNullableDateRange {
  startDate: PlainDate
  endDate: PlainDate
}

type DateRangeSelected<NonNullable extends boolean | undefined> = NonNullable extends true
  ? NonNullableDateRange
  : DateRange

interface Props<NonNullable extends boolean | undefined> {
  value: DateRangeSelected<NonNullable>
  onChange: (value: DateRangeSelected<NonNullable>) => void
  fullHistoryButtonText?: NonNullable extends true ? undefined : string
  disableFuture?: boolean
  nonNullable?: NonNullable
  enableQuickRangePickers?: boolean
  maxStartDate?: string
  maxEndDate?: string
  disabledStartDate?: boolean
  disabledEndDate?: boolean
  disabledSwitch?: boolean
}

interface QuickRangePicker {
  name: string
  computeRange: (startDate: Dayjs) => Dayjs
}

const quickRangePickers: QuickRangePicker[] = [
  {
    name: "1 year",
    computeRange: (startDate) => startDate.add(1, "year"),
  },
  {
    name: "6 months",
    computeRange: (startDate) => startDate.add(6, "month"),
  },
  {
    name: "1 month",
    computeRange: (startDate) => startDate.add(1, "month"),
  },
]

export const datePickerDateFormat = "YYYY/MM/DD"

function DateRangePicker<NonNullable extends boolean | undefined>(props: Props<NonNullable>): JSX.Element {
  const {
    value,
    onChange,
    fullHistoryButtonText,
    disableFuture = true,
    nonNullable,
    enableQuickRangePickers,
    maxStartDate,
    maxEndDate,
    disabledStartDate = false,
    disabledEndDate = false,
    disabledSwitch = false,
  } = props
  const [pickerVisible, setPickerVisible] = useState(Boolean(value.startDate || value.endDate))
  const [internalStartDate, setInternalStartDate] = useState<PlainDate | undefined>()
  const [internalEndDate, setInternalEndDate] = useState<PlainDate | undefined>()

  useEffect(() => setInternalStartDate(value.startDate), [value.startDate])
  useEffect(() => setInternalEndDate(value.endDate), [value.endDate])

  const handleFullHistorySwitch = (checked: boolean) => {
    if (!nonNullable) {
      if (checked) {
        setPickerVisible(false)
        onChange({
          startDate: undefined,
          endDate: undefined,
        } as DateRangeSelected<NonNullable>)
      } else {
        setPickerVisible(true)
      }
    }
  }

  const handleStartDateChange = (date: string | null | undefined) => {
    let plainDate: PlainDate | undefined
    if (date) {
      const dayjsDate = dayjs(date, datePickerDateFormat)
      plainDate = new PlainDate(dayjsDate)
    }
    onDateChange(plainDate, value.endDate ?? internalEndDate)
  }

  const handleEndDateChange = (date: string | null | undefined) => {
    let plainDate: PlainDate | undefined
    if (date) {
      const dayjsDate = dayjs(date, datePickerDateFormat)
      plainDate = new PlainDate(dayjsDate)
    }
    onDateChange(value.startDate ?? internalStartDate, plainDate)
  }

  const onDateChange = (startDate?: PlainDate, endDate?: PlainDate) => {
    if (startDate !== undefined && endDate !== undefined && startDate > endDate) {
      if (startDate !== value.startDate && !nonNullable) {
        onChange({
          startDate,
          endDate: undefined,
        } as DateRangeSelected<NonNullable>)
      } else {
        setInternalStartDate(startDate)
        setInternalEndDate(endDate)
      }
    } else if ((startDate && !startDate.isValid) || (endDate && !endDate.isValid)) {
      return
    } else if (startDate !== value.startDate || endDate !== value.endDate) {
      if ((startDate && endDate) || !nonNullable) {
        onChange({
          startDate,
          endDate,
        } as DateRangeSelected<NonNullable>)
      }
    }
  }

  return (
    <>
      {!nonNullable && (
        <Box mt={2} mb={1}>
          <FormControlLabel
            control={
              <Switch
                checked={!pickerVisible}
                onChange={(x) => handleFullHistorySwitch(x.target.checked)}
                name="fetch-complete-history"
                disabled={disabledSwitch}
              />
            }
            label={fullHistoryButtonText}
          />
        </Box>
      )}

      <Box mt={1} display={pickerVisible ? "flex" : "none"} alignItems="center" justifyContent="space-between">
        <Box>
          <Box mb={1}>
            <FormLabel htmlFor="start-date">Start date</FormLabel>
          </Box>
          <Box mb={2}>
            <KeyboardDatePicker
              id="start-date"
              value={internalStartDate?.date.toISOString() ?? null}
              format={datePickerDateFormat}
              disableFuture={disableFuture}
              disabled={disabledStartDate}
              onChange={(_, start) => handleStartDateChange(start)}
              maxDate={maxStartDate}
            />
          </Box>

          <Box mb={1}>
            <FormLabel htmlFor="end-date">End date</FormLabel>
          </Box>
          <Box>
            <KeyboardDatePicker
              id="end-date"
              value={internalEndDate?.date.toISOString() ?? null}
              format={datePickerDateFormat}
              disableFuture={disableFuture}
              onChange={(_, end) => handleEndDateChange(end)}
              minDate={internalStartDate}
              disabled={disabledEndDate}
              minDateMessage="The end date must be greater or equal to the start date"
              maxDate={maxEndDate}
            />
          </Box>
        </Box>

        {enableQuickRangePickers && (
          <Box ml={2}>
            <ButtonGroup orientation="vertical" variant="contained" color="primary">
              {quickRangePickers.map((picker) => (
                <Button
                  key={picker.name}
                  onClick={() => {
                    if (internalStartDate) {
                      onDateChange(
                        value.startDate ?? internalStartDate,
                        new PlainDate(picker.computeRange(internalStartDate.date)),
                      )
                    }
                  }}
                >
                  {picker.name}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        )}
      </Box>
    </>
  )
}

export default DateRangePicker
