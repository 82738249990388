import { Button } from "@material-ui/core"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import dayjs from "dayjs"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { TextColor, Orange, WarningColor } from "../../materialTheme"

import { URLS } from "../../routes"
import api from "services/api"
import LoadingSpinner from "../misc/LoadingSpinner"
import TypographyUI from "CryptioUI/Typography"
import { TypographyVariant } from "CryptioUI/types/typography"
import TooltipUI from "CryptioUI/Tooltip"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    trialExpiredButton: {
      "background": Orange,
      "borderRadius": theme.shape.borderRadius,
      "border": 0,
      "fontSize": theme.typography.h5.fontSize,
      "fontWeight": theme.typography.h5.fontWeight,
      "color": TextColor,
      "paddingTop": theme.spacing(0.5),
      "paddingBottom": theme.spacing(0.5),
      "paddingLeft": theme.spacing(1),
      "paddingRight": theme.spacing(1),
      "maxHeight": "32px",
      "overflow": "hidden",
      "white-space": "nowrap",
      "max-width": "250px",
      "min-width": "100px",
      "&:hover": {
        color: TextColor,
        background: WarningColor,
      },
      "&>span": {
        "text-overflow": "ellipsis",
      },
    },
  }),
)

export const BillingInfoInMenu = (): JSX.Element => {
  const classes = useStyles()

  const subscription = api.billing.useShortSubscription()

  if (subscription.isLoading || subscription.data === undefined) return <LoadingSpinner />

  if (
    subscription.data.type === "paying" &&
    subscription.data.payingDetails?.isPaid === false &&
    subscription.data.payingDetails.source === "stripe"
  )
    return (
      <Button className={classes.trialExpiredButton} component={RouterLink} to={URLS.Billing.Status}>
        Awaiting payment
      </Button>
    )

  if (subscription.data.type !== "trial" || subscription.data.periodTo === null) return <></>

  const trialDisplay = (
    <TypographyUI variant={TypographyVariant.BODY} ellipsisPosition="end">
      Trial with {dayjs(subscription.data.periodTo).fromNow(true)} remaining
    </TypographyUI>
  )

  const expiredDisplay = (
    <TypographyUI variant={TypographyVariant.BODY} ellipsisPosition="end">
      Trial expired
    </TypographyUI>
  )

  return (
    <TooltipUI content={subscription.data.isExpired ? expiredDisplay : trialDisplay}>
      <Button className={classes.trialExpiredButton} component={RouterLink} to={URLS.Billing.Status}>
        {subscription.data.isExpired ? <>{expiredDisplay}</> : <>{trialDisplay}</>}
      </Button>
    </TooltipUI>
  )
}
