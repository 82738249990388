import React, { Fragment, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"

interface CustomWrapperModaleProps {
  children: JSX.Element
  isOpen: boolean
}

const CustomWrapperModale = (props: CustomWrapperModaleProps) => {
  const { children, isOpen } = props
  const completeButtonRef = useRef(null)

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={completeButtonRef}
        as="div"
        className="fixed bottom-0 left-0 right-0 z-[1201] overflow-y-auto"
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-500"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="inline-block w-full overflow-visible h-full transition-all transform">{children}</div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

export default CustomWrapperModale
