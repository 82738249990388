/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  CreateBatchWalletResult,
  CreateBatchWalletsDto,
  DeleteWalletsDto,
  GetErrorCsvSignedUrl,
  GetWalletDto,
  GetWalletTypeDto,
  PaginatedWalletResultDto,
  RefreshWalletsDto,
  UpdateWalletCredentialDto,
  UpdateWalletDto,
  WalletToggleAutoFlagBorrow,
} from "../models"

export interface AddCsvToWalletRequest {
  workspaceId: string
  walletId: string
}

export interface BackOfficeRunAutoFlagBorrowRequest {
  walletId: string
  workspaceId: string
}

export interface BackOfficeToggleAutoFlagBorrowRequest {
  walletId: string
  workspaceId: string
  walletToggleAutoFlagBorrow: WalletToggleAutoFlagBorrow
}

export interface CreateBatchWalletsRequest {
  workspaceId: string
  createBatchWalletsDto: CreateBatchWalletsDto
}

export interface DeleteWalletsRequest {
  workspaceId: string
  deleteWalletsDto: DeleteWalletsDto
}

export interface DownloadWalletErrorCsvRequest {
  workspaceId: string
  walletSourceId: string
}

export interface GetTypesRequest {
  workspaceId: string
  onlyInWorkspace?: boolean
}

export interface GetWalletRequest {
  workspaceId: string
  walletId: string
}

export interface GetWalletsRequest {
  page: number
  limit: number
  workspaceId: string
  query?: string
  sortBy?: GetWalletsSortByEnum
  sortDirection?: GetWalletsSortDirectionEnum
  typeNames?: Array<GetWalletsTypeNamesEnum>
  walletTypeTypes?: Array<GetWalletsWalletTypeTypesEnum>
  wallets?: Array<string>
  walletsAddress?: Array<string>
  excludeWithMapping?: boolean
  minimumTransactions?: number
  walletSourceType?: Array<GetWalletsWalletSourceTypeEnum>
  excludedIds?: Array<string>
}

export interface RefreshAllWalletsRequest {
  workspaceId: string
}

export interface RefreshWalletsRequest {
  workspaceId: string
  refreshWalletsDto: RefreshWalletsDto
}

export interface ReplaceErrorCSVRequest {
  workspaceId: string
  walletId: string
  file: Blob
}

export interface UpdateWalletRequest {
  workspaceId: string
  walletId: string
  updateWalletDto: UpdateWalletDto
}

export interface UpdateWalletCredentialRequest {
  workspaceId: string
  walletId: string
  updateWalletCredentialDto: UpdateWalletCredentialDto
}

export interface ValuationUpdateRequest {
  workspaceId: string
  walletId: string
}

export interface WorkspaceValuationUpdateRequest {
  workspaceId: string
}

/**
 *
 */
export class WalletsApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static addCsvToWallet(params: AddCsvToWalletRequest) {
    return localWalletsApi.addCsvToWallet(params)
  }

  /**
   * Add a CSV source to a wallet
   */
  addCsvToWallet({ workspaceId, walletId }: AddCsvToWalletRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/wallets/custom/${encodeURIComponent(walletId)}`,
      undefined,
      true,
    )
  }

  static backOfficeRunAutoFlagBorrow(params: BackOfficeRunAutoFlagBorrowRequest) {
    return localWalletsApi.backOfficeRunAutoFlagBorrow(params)
  }

  /**
   */
  backOfficeRunAutoFlagBorrow({ walletId, workspaceId }: BackOfficeRunAutoFlagBorrowRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/wallets/wallet/${encodeURIComponent(walletId)}/borrow-auto-flag`,
      undefined,
      true,
    )
  }

  static backOfficeToggleAutoFlagBorrow(params: BackOfficeToggleAutoFlagBorrowRequest) {
    return localWalletsApi.backOfficeToggleAutoFlagBorrow(params)
  }

  /**
   */
  backOfficeToggleAutoFlagBorrow({
    walletToggleAutoFlagBorrow,
    walletId,
    workspaceId,
  }: BackOfficeToggleAutoFlagBorrowRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/wallets/wallet/${encodeURIComponent(walletId)}/borrow-auto-flag`,
      walletToggleAutoFlagBorrow,
      true,
    )
  }

  static createBatchWallets(params: CreateBatchWalletsRequest) {
    return localWalletsApi.createBatchWallets(params)
  }

  /**
   * Create wallets
   */
  createBatchWallets({
    createBatchWalletsDto,
    workspaceId,
  }: CreateBatchWalletsRequest): Promise<CreateBatchWalletResult> {
    return this.restInstance.post(`/workspaces/${encodeURIComponent(workspaceId)}/wallets`, createBatchWalletsDto)
  }

  static deleteWallets(params: DeleteWalletsRequest) {
    return localWalletsApi.deleteWallets(params)
  }

  /**
   * delete some wallets
   */
  deleteWallets({ deleteWalletsDto, workspaceId }: DeleteWalletsRequest): Promise<void> {
    return this.restInstance.delete(`/workspaces/${encodeURIComponent(workspaceId)}/wallets`, deleteWalletsDto)
  }

  static downloadWalletErrorCsv(params: DownloadWalletErrorCsvRequest) {
    return localWalletsApi.downloadWalletErrorCsv(params)
  }

  /**
   * Get a signed url to download the error csv of a wallet
   */
  downloadWalletErrorCsv({
    workspaceId,
    walletSourceId,
  }: DownloadWalletErrorCsvRequest): Promise<GetErrorCsvSignedUrl> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/wallets/${encodeURIComponent(walletSourceId)}/errorCsv`,
    )
  }

  static getTypes(params: GetTypesRequest) {
    return localWalletsApi.getTypes(params)
  }

  /**
   * Get wallet types
   */
  getTypes({ workspaceId, ...queryParameters }: GetTypesRequest): Promise<Array<GetWalletTypeDto>> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/wallets/types?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }

  static getWallet(params: GetWalletRequest) {
    return localWalletsApi.getWallet(params)
  }

  /**
   * Get one wallet
   */
  getWallet({ workspaceId, walletId }: GetWalletRequest): Promise<GetWalletDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/wallets/${encodeURIComponent(walletId)}`,
    )
  }

  static getWallets(params: GetWalletsRequest) {
    return localWalletsApi.getWallets(params)
  }

  /**
   * Get user\'s wallets
   */
  getWallets({ workspaceId, ...queryParameters }: GetWalletsRequest): Promise<PaginatedWalletResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/wallets?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
    )
  }

  static refreshAllWallets(params: RefreshAllWalletsRequest) {
    return localWalletsApi.refreshAllWallets(params)
  }

  /**
   * refresh all wallets
   */
  refreshAllWallets({ workspaceId }: RefreshAllWalletsRequest): Promise<number> {
    return this.restInstance.post(`/workspaces/${encodeURIComponent(workspaceId)}/wallets/refresh/all`, undefined)
  }

  static refreshWallets(params: RefreshWalletsRequest) {
    return localWalletsApi.refreshWallets(params)
  }

  /**
   * refresh some wallets
   */
  refreshWallets({ refreshWalletsDto, workspaceId }: RefreshWalletsRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/wallets/refresh`,
      refreshWalletsDto,
      true,
    )
  }

  static replaceErrorCSV(params: ReplaceErrorCSVRequest) {
    return localWalletsApi.replaceErrorCSV(params)
  }

  /**
   * Replace CSV with an error
   */
  replaceErrorCSV({ file, workspaceId, walletId }: ReplaceErrorCSVRequest): Promise<void> {
    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }]
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (file !== undefined) {
      formParams.append("file", file)
    }
    return this.restInstance.formData(
      `/workspaces/${encodeURIComponent(workspaceId)}/wallets/custom/replace/${encodeURIComponent(walletId)}`,
      formParams,
      "post",
      true,
    )
  }

  static updateWallet(params: UpdateWalletRequest) {
    return localWalletsApi.updateWallet(params)
  }

  /**
   * update a wallet
   */
  updateWallet({ updateWalletDto, workspaceId, walletId }: UpdateWalletRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/wallets/${encodeURIComponent(walletId)}`,
      updateWalletDto,
      true,
    )
  }

  static updateWalletCredential(params: UpdateWalletCredentialRequest) {
    return localWalletsApi.updateWalletCredential(params)
  }

  /**
   */
  updateWalletCredential({
    updateWalletCredentialDto,
    workspaceId,
    walletId,
  }: UpdateWalletCredentialRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/wallets/${encodeURIComponent(walletId)}/credentials`,
      updateWalletCredentialDto,
      true,
    )
  }

  static valuationUpdate(params: ValuationUpdateRequest) {
    return localWalletsApi.valuationUpdate(params)
  }

  /**
   * Update valuation of a single wallet
   */
  valuationUpdate({ workspaceId, walletId }: ValuationUpdateRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/wallets/${encodeURIComponent(walletId)}/valuation`,
      undefined,
      true,
    )
  }

  static workspaceValuationUpdate(params: WorkspaceValuationUpdateRequest) {
    return localWalletsApi.workspaceValuationUpdate(params)
  }

  /**
   * Update the valuation of all wallets in the workspace
   */
  workspaceValuationUpdate({ workspaceId }: WorkspaceValuationUpdateRequest): Promise<void> {
    return this.restInstance.put(`/workspaces/${encodeURIComponent(workspaceId)}/wallets/valuation`, undefined, true)
  }
}

const localWalletsApi = new WalletsApi()

/**
 * @export
 * @enum {string}
 */
export type GetWalletsSortByEnum =
  | "transaction_count"
  | "movement_count"
  | "name"
  | "type"
  | "created_at"
  | "last_updated_at"
/**
 * @export
 * @enum {string}
 */
export type GetWalletsSortDirectionEnum = "ascending" | "descending"
/**
 * @export
 * @enum {string}
 */
export type GetWalletsTypeNamesEnum =
  | "Arbitrum"
  | "Avalanche"
  | "Binance Smart Chain (Legacy)"
  | "Binance Smart Chain"
  | "Bitcoin"
  | "Cardano"
  | "Ethereum"
  | "Fantom"
  | "Filecoin"
  | "Litecoin"
  | "Near"
  | "Optimism"
  | "Optimism (Legacy)"
  | "Polygon"
  | "Polygon (Legacy)"
  | "Tezos"
  | "Zilliqa"
  | "Linea"
  | "zkSync"
  | "Base"
  | "Anchorage"
  | "Binance"
  | "Binance US"
  | "Binance File"
  | "BitGo"
  | "Bitcoin Suisse"
  | "Bitfinex"
  | "Bitstamp"
  | "Bittrex"
  | "Coinbase Commerce"
  | "Coinbase Prime"
  | "Coinbase Pro"
  | "Coinbase"
  | "Deribit"
  | "FTX (OTC)"
  | "FTX US"
  | "FTX"
  | "Gate.io"
  | "Gemini"
  | "HitBTC"
  | "HitBTC (Legacy)"
  | "Huobi"
  | "Kraken"
  | "Fireblocks"
  | "Nexo"
  | "Spot"
  | "Kucoin"
  | "Deribit"
  | "Custom"
  | "Custom API"
/**
 * @export
 * @enum {string}
 */
export type GetWalletsWalletTypeTypesEnum = "chain" | "exchange" | "custody" | "custom"
/**
 * @export
 * @enum {string}
 */
export type GetWalletsWalletSourceTypeEnum =
  | "Arbitrum"
  | "Avalanche"
  | "Binance Smart Chain (Legacy)"
  | "Binance Smart Chain"
  | "Bitcoin"
  | "Cardano"
  | "Ethereum"
  | "Fantom"
  | "Filecoin"
  | "Litecoin"
  | "Near"
  | "Optimism"
  | "Optimism (Legacy)"
  | "Polygon"
  | "Polygon (Legacy)"
  | "Tezos"
  | "Zilliqa"
  | "Linea"
  | "zkSync"
  | "Base"
  | "Anchorage"
  | "Binance"
  | "Binance US"
  | "Binance File"
  | "BitGo"
  | "Bitcoin Suisse"
  | "Bitfinex"
  | "Bitstamp"
  | "Bittrex"
  | "Coinbase Commerce"
  | "Coinbase Prime"
  | "Coinbase Pro"
  | "Coinbase"
  | "Deribit"
  | "FTX (OTC)"
  | "FTX US"
  | "FTX"
  | "Gate.io"
  | "Gemini"
  | "HitBTC"
  | "HitBTC (Legacy)"
  | "Huobi"
  | "Kraken"
  | "Fireblocks"
  | "Nexo"
  | "Spot"
  | "Kucoin"
  | "Deribit"
  | "Custom"
  | "Custom API"
