import { Box, makeStyles, Typography } from "@material-ui/core"
import { AddRounded, HighlightOffRounded, UpdateRounded } from "@material-ui/icons"
import { Pagination, TimelineOppositeContent } from "@material-ui/lab"
import Timeline from "@material-ui/lab/Timeline"
import TimelineConnector from "@material-ui/lab/TimelineConnector"
import TimelineContent from "@material-ui/lab/TimelineContent"
import TimelineDot from "@material-ui/lab/TimelineDot"
import TimelineItem from "@material-ui/lab/TimelineItem"
import TimelineSeparator from "@material-ui/lab/TimelineSeparator"
import dayjs from "dayjs"
import React, { useState } from "react"

import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import TitleWithDivider from "components/TitleWithDivider"
import { ErrorColor, LightBlue, Orange } from "../../materialTheme"
import api from "services/api"
import { GetHistoryDtoTypeEnum } from "services/api/openapi/models"
import { camelCaseToTitleCase } from "services/utils/textUtils"

interface Props {
  size: number
  workspaceId: string
}

const useStyles = makeStyles(() => ({
  oppositeContent: {
    flex: 0,
    padding: 0,
  },
  timelineItem: {
    padding: 0,
  },
}))

const WorkspaceTimelineIcon = ({ type }: { type: GetHistoryDtoTypeEnum }): JSX.Element => {
  if (type === "create") {
    return (
      <TimelineDot style={{ backgroundColor: LightBlue }}>
        <AddRounded />
      </TimelineDot>
    )
  }
  if (type === "update") {
    return (
      <TimelineDot style={{ backgroundColor: Orange }}>
        <UpdateRounded />
      </TimelineDot>
    )
  }
  return (
    <TimelineDot style={{ backgroundColor: ErrorColor }}>
      <HighlightOffRounded />
    </TimelineDot>
  )
}

const WorkspaceTimeline = ({ size, workspaceId }: Props): JSX.Element => {
  const classes = useStyles()
  const [page, setPage] = useState<number>(1)
  const workspaceHistory = api.history.useHistoryWorkspace({
    workspaceId,
    limit: size,
    page,
  })

  if (workspaceHistory.isError) return <NetworkErrorMessage additionalData={workspaceHistory} />
  if (workspaceHistory.isLoading || workspaceHistory.data === undefined) return <LoadingSpinner />

  if (workspaceHistory.data.totalCount === 0) {
    return (
      <Box display="flex" justifyContent="flex-start" flexDirection="column">
        <TitleWithDivider title={"Action history"} variant="h4" mt={4} mb={2} />
        <Typography>No history</Typography>
      </Box>
    )
  }

  return (
    <div className="flex justify-start flex-col">
      <TitleWithDivider title={"Action history"} variant="h4" mt={4} />
      <div className="flex justify-center items-start flex-col w-full px-10">
        <Timeline>
          {workspaceHistory.data.data.map((history, idx) => (
            <TimelineItem className={classes.timelineItem} key={idx}>
              <TimelineOppositeContent className={classes.oppositeContent} />
              <TimelineSeparator>
                <WorkspaceTimelineIcon type={history.type} />
                {idx + 1 !== workspaceHistory.data?.data.length && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography>
                  {history.userEmail ?? "User"} {history.type}
                  {"d "}
                  {camelCaseToTitleCase(history.tableName)}
                  <br />
                  <Typography component="span" color="textSecondary">
                    {dayjs(history.actionAt).fromNow()}
                  </Typography>
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
        <div className="w-full flex justify-center items-center">
          <Pagination
            count={Math.ceil(workspaceHistory.data.totalCount / size)}
            siblingCount={0}
            boundaryCount={1}
            showFirstButton
            showLastButton
            page={page}
            onChange={(_, value) => setPage(value)}
          />
        </div>
      </div>
    </div>
  )
}

export default WorkspaceTimeline
