import { Box, Typography } from "@material-ui/core"
import BigNumber from "bignumber.js"
import React from "react"

import api from "services/api"
import { isStripeEnabled } from "services/variables"

interface Props {
  planId: string
  limitAddons?: {
    id: string
    additionalCount: number
  }[]
  totalUsdPrice: BigNumber
}

const ProrationDetails = (props: Props): JSX.Element => {
  if (!isStripeEnabled) return <></>

  const upcomingInvoice = api.billing.useChangeRetrieveUpcomingInvoiceData(
    {
      updateSubscriptionDto: {
        planId: props.planId,
        limitAddOns: (props.limitAddons || []).map((x) => ({
          addonId: x.id,
          additional: x.additionalCount,
        })),
        featureAddOnIds: [],
      },
    },
    {
      refetchInterval: 5000, // quite important since the price will change over time
    },
  )

  if (upcomingInvoice.isError) return <></>

  if (upcomingInvoice.isLoading || upcomingInvoice.data === undefined) return <></>

  const priceToPay = new BigNumber(upcomingInvoice.data.total / 100).minus(props.totalUsdPrice)

  if (priceToPay.isZero()) {
    return <></>
  }

  return (
    <Box ml={2}>
      <Typography variant="body1">To be paid now: ${priceToPay.toFixed(2)} USD</Typography>
    </Box>
  )
}

export default ProrationDetails
