export interface TypographyProps {
  variant: TypographyVariant
  children: React.ReactNode
  className?: string
  uppercase?: boolean
  componentName?: ElementType
  url?: string
  newTab?: boolean
  boldness?: BoldWeight
  onClick?: () => void
  ellipsisPosition?: "end" | "middle"
}
export interface TextLinkWrapperProps {
  children: React.ReactNode
  url?: string
  target: string
}

export interface TextEllipsisWrapperProps {
  children: React.ReactNode
  ellipsisPosition?: "end" | "middle"
  endEllipsisStyle: string
}

export type BalisesType = {
  [key in ElementType]: JSX.Element
}

export interface VariableComponentProps {
  children: React.ReactNode
  componentName: ElementType
  className?: string
}

export enum TypographyVariant {
  BODY = "body",
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  CAPTION = "caption",
  BUTTON = "button",
}

export enum ElementType {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  P = "p",
  SPAN = "SPAN",
  LABEL = "LABEL",
}

export enum BoldWeight {
  REGULAR = "400",
  MEDIUM = "500",
  BOLD = "700",
}

export type BoldType = {
  [key in BoldWeight]: string
}
