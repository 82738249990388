import { Box, Typography, makeStyles, Theme } from "@material-ui/core"
import TotalCount from "components/filter/TotalCount"
import { LoadingButton } from "components/LoadingButton"
import MainTitleView from "components/MainTitleView"
import ButtonUI from "CryptioUI/Button"
import { ButtonSize, Mode } from "CryptioUI/types"
import { iconStyleWhite } from "CryptioUI/Utilities/config"
import React, { useContext } from "react"
import { useHistory } from "react-router"
import { URLS } from "routes"
import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import { GetCOAMappingsRequest } from "services/api/openapi"
import { AccountingFilterContext } from "services/context/accountingFilterContext"
import { WorkspaceContext } from "services/context/workspaceContext"
import { Mixpanel } from "services/mixpanel"
import AccountingTabsButtons from "./AccountingTabsButtons"
import ChartAccountUpdateModal from "./ChartAccountUpdateModal"
import ChartAccountAddModal from "./ChartAccountAddModal"
import { ReactComponent as Add } from "CryptioUI/assets/icons/add.svg"
import { ReactComponent as Reset } from "CryptioUI/assets/icons/reset.svg"
import useDrawer from "components/misc/useDrawer"
import { pluralize } from "services/utils/textUtils"
import TooltipUI from "CryptioUI/Tooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  needReview: {
    paddingLeft: theme.spacing(3),
    minWidth: "8em",
  },
}))

interface ControllerProps {
  title: React.ReactNode
  totalCount: number
  selectedCount?: number
  qualifier: string
  qualifierSuffix?: string
  helpTooltipContent?: string
  helpTooltipUrl?: string
  onClick?: () => void
  maxTotalCount: number | null
  item: string
  isRefetching: boolean
  filter: WithoutWorkspaceId<GetCOAMappingsRequest>
  setFilters: (newParams: Partial<GetCOAMappingsRequest>) => void
  walletCount?: number
  selectedWalletCount?: number
}

const Controller = (props: ControllerProps) => {
  const {
    title,
    totalCount,
    helpTooltipContent,
    isRefetching,
    maxTotalCount,
    // filter,
    walletCount = 0,
    selectedWalletCount = 0,
    // setFilters,
  } = props
  const classes = useStyles()
  const history = useHistory()
  const { isNeedReview } = useContext(AccountingFilterContext)
  const workspaceCtx = useContext(WorkspaceContext)
  const { mutateAsync: calculateAutomatedMappingMutation } = api.chartAccount.useCalculateAutomatedMapping()
  const toast = useToast()
  const calculateAutomatedMapping = async () => {
    try {
      await calculateAutomatedMappingMutation({})
      toast.open("Updating mappings...", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }
  const [mappingDrawer, openMappingDrawer] = useDrawer("chart-account-mapping")
  const styleIsNeedReview = !isNeedReview ? "h-32" : ""
  const globalContainerStyle = ["flex flex-row justify-between", styleIsNeedReview].join(" ")

  return (
    <div className={globalContainerStyle}>
      {mappingDrawer}
      <div className="flex flex-col justify-between mb-4">
        <div>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <MainTitleView title={title} helpTooltipContent={helpTooltipContent} />
            <Box className={classes.needReview}>
              {/* <Badge
								badgeContent={isNeedReview ? 0 : countToReview}
								color="error"
							> */}
              <ButtonUI
                size={ButtonSize.MD}
                mode={isNeedReview ? Mode.OUTLINEDHIGHLIGHT : Mode.DEFAULT}
                onClick={() => {
                  Mixpanel.track("ClickNeedReview")
                  if (!isNeedReview) {
                    history.push(URLS.Accounting.AutomatedMappingReview)
                  } else {
                    history.push(URLS.Accounting.AutomatedMapping)
                  }
                }}
              >
                Needs review
              </ButtonUI>
              {/* </Badge> */}
            </Box>
          </Box>
          <Box>
            <Typography variant="body1">
              <TotalCount
                isRefetching={isRefetching}
                totalCount={totalCount}
                maxTotalCount={maxTotalCount}
                item="transaction"
              />
              {walletCount > 0 && selectedWalletCount && (
                <TooltipUI content={selectedWalletCount}>
                  <span>
                    {" "}
                    - {walletCount} {pluralize(walletCount > 1, "row")} selected
                  </span>
                </TooltipUI>
              )}
            </Typography>
          </Box>
        </div>
        {isNeedReview && (
          <div className="flex mt-4">
            <AccountingTabsButtons />
          </div>
        )}
      </div>
      <div className="flex items-end justify-end flex-row mb-4">
        {!isNeedReview && (
          <>
            <Box mr={2}>
              <ChartAccountAddModal />
            </Box>
            <Box mr={2}>
              <ChartAccountUpdateModal />
            </Box>
            <Box mr={2}>
              <LoadingButton
                pending={workspaceCtx.workspace.coaMappingStatus.status !== "inactive"}
                mode={Mode.DEFAULT}
                onClick={calculateAutomatedMapping}
                disabled={workspaceCtx.workspace.coaMappingStatus.status !== "inactive"}
                Icon={<Reset className={iconStyleWhite} />}
              >
                Apply mappings
              </LoadingButton>
            </Box>
          </>
        )}
        <ButtonUI onClick={() => openMappingDrawer(null)} Icon={<Add className={iconStyleWhite} />}>
          Mapping
        </ButtonUI>
      </div>
    </div>
  )
}

export default Controller
