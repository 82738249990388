import { useContext, useMemo } from "react"
import { WorkspaceContext } from "services/context/workspaceContext"
import { ColumnsName } from "./ContainerColumn"

export interface ConfigTable {
  name: ColumnsName
  minWidth: string
  maxWidth: string
  omit: boolean
}

export const useConfigTable = (): ConfigTable[] => {
  const { workspace } = useContext(WorkspaceContext)

  const columnsConfig = useMemo(() => {
    return [
      { name: ColumnsName.CHECKBOX, minWidth: "45px", maxWidth: "45px", omit: false },
      { name: ColumnsName.DATE, minWidth: "80px", maxWidth: "80px", omit: false },
      { name: ColumnsName.FROMTO, minWidth: "160px", maxWidth: "160px", omit: false },
      { name: ColumnsName.INCOMING, minWidth: "130px", maxWidth: "auto", omit: false },
      { name: ColumnsName.OUTGOING, minWidth: "130px", maxWidth: "auto", omit: false },
      { name: ColumnsName.FEE, minWidth: "130px", maxWidth: "auto", omit: false },
      { name: ColumnsName.COSTBASIS, minWidth: "110px", maxWidth: "auto", omit: false },
      { name: ColumnsName.GAINSLOSSES, minWidth: "110px", maxWidth: "auto", omit: false },
      { name: ColumnsName.LABEL, minWidth: "155px", maxWidth: "auto", omit: !workspace.userRights.can_read_label },
      {
        name: ColumnsName.SYNC,
        minWidth: "50px",
        maxWidth: "50px",
        omit: !workspace.accountingIntegration,
      },
    ]
  }, [workspace.userRights.can_read_label, workspace.accountingIntegration])

  return columnsConfig
}
