import React from "react"
import { Box, Switch } from "@material-ui/core"
import { LockOpen, Lock } from "@material-ui/icons"

interface LockSwitchProps {
  isChecked: boolean
  onChange: () => void
  id?: string
  disabled?: boolean
}

const LockSwitch = (props: LockSwitchProps): JSX.Element => {
  const { isChecked, onChange, id = "lockSwitch", disabled = false } = props

  return (
    <Box ml={2} display="flex" alignItems="center">
      <Switch id={id} color="primary" checked={isChecked} disabled={disabled} onChange={onChange} />
      {isChecked ? <Lock /> : <LockOpen />}
    </Box>
  )
}

export default LockSwitch
