import React from "react"
import { KBarPortal, KBarPositioner, KBarAnimator, KBarSearch } from "kbar"
import useBackOfficeActions from "./actions/useBackOfficeActions"
import usePageActions from "./actions/usePageActions"
import WrapperKBarResults from "./WrapperKBarResults"

export function KbarWrapper() {
  useBackOfficeActions()
  usePageActions()
  return (
    <KBarPortal>
      <KBarPositioner>
        <div className=" bg-grey-400 opacity-50 fixed inset-0" />
        <KBarAnimator className="max-w-[600px] w-full bg-white text-primary-400 rounded-xl overflow-hidden shadow-xl">
          <div className="text-base box-border outline-none border-none">
            <KBarSearch className="w-full py-3 px-4 bg-white text-primary-400 outline-none" />
          </div>
          <WrapperKBarResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  )
}
