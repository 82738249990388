import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import { DashboardRounded } from "@material-ui/icons"
import BigNumber from "bignumber.js"
import React, { useContext, useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"

import BaseContainer from "components/Container"
import { FeatureGate } from "components/Feature/FeatureGate"
import MainTitleView from "components/MainTitleView"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import ButtonUI from "CryptioUI/Button"
import { URLS } from "../../routes"
import api from "services/api"
import { WorkspaceContext } from "services/context/workspaceContext"
import { Mixpanel } from "services/mixpanel"
import DashboardAsset from "./widgets/DashboardAsset"
import TopAddresses from "./widgets/TopAddresses"
import TopGainLoss from "./widgets/TopGainLoss"
import DefiOverview from "./widgets/DefiOverview"

const useStyles = makeStyles((theme: Theme) => ({
  noTransactionText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}))

const MainTitle = () => <MainTitleView title="Dashboard" helpTooltipContent="Global overview of your portfolio" />

const DashboardScene = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  const { workspace } = useContext(WorkspaceContext)
  const assets = api.balance.useOverviewBalances(
    {
      page: 1,
      limit: 10,
      sortBy: "amount_in_usd",
      sortDirection: "descending",
    },
    {
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    Mixpanel.track("ViewDashboard")
  }, [])

  const cleanAssets = useMemo(() => {
    if (!assets.data?.data?.filter) return []
    return assets.data.data.filter((x) => new BigNumber(x.amount).gt(0) && x.amountInUsd && x.usdRateToDefaultCurrency)
  }, [assets.data])
  if (assets.isError) return <NetworkErrorMessage small={false} additionalData={assets} />
  if (assets.isLoading || assets.data === undefined) return <LoadingSpinner />

  const dashboardDisabled = (
    <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <DashboardRounded fontSize="large" />
      <Typography className={classes.noTransactionText} variant="h4">
        The dashboard is disabled on this workspace.
        <br />
        Please contact the Cryptio support if you believe this is a mistake.{" "}
      </Typography>
    </Box>
  )

  const dashboardNoTransactions = (
    <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <DashboardRounded fontSize="large" />
      <Typography className={classes.noTransactionText} variant="h4">
        You don&apos;t have any transaction.
        <br />
        Click the button below to go to the imports page.
      </Typography>
      <ButtonUI onClick={() => history.push({ pathname: URLS.Import.basePath, state: { openSourceSelector: true } })}>
        Go to Imports
      </ButtonUI>
    </Box>
  )

  const dashboardContent = (
    <div className="flex flex-col gap-8 pt-8 w-full">
      <div className="grid xl:grid-cols-2 grid-cols-1 gap-8">
        <DashboardAsset assets={cleanAssets} />
        <TopAddresses />
      </div>
      <FeatureGate feature="defi_position">
        <DefiOverview />
      </FeatureGate>
      <TopGainLoss type="Gains" />
      <TopGainLoss type="Losses" />
    </div>
  )

  return (
    <BaseContainer>
      <MainTitle />
      <FeatureGate feature="dashboard" fallback={dashboardDisabled}>
        {workspace.transactionCount !== 0 ? dashboardContent : dashboardNoTransactions}
      </FeatureGate>
    </BaseContainer>
  )
}

export default React.memo(DashboardScene)
