import { Box, Typography } from "@material-ui/core"
import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import { DrawerCategory } from "components/Drawer/DrawerItems"
import useDrawer, { DrawerProp } from "components/misc/useDrawer"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import { URLS } from "../../../../routes"
import { BackOfficeGetFullUserDto } from "services/api/openapi/models"
import { WorkspaceContext } from "services/context/workspaceContext"
import BackOfficeUserForm from "./BackOfficeUserForm"
import BackOfficeUserMetrics from "./BackOfficeUserMetrics"
import BackOfficeUserSummary from "./BackOfficeUserSummary"

const BackOfficeUserDrawer = (props: DrawerProp<BackOfficeGetFullUserDto, false>) => {
  const { item: user } = props
  const [workspaceDrawer, openWorkspaceDrawer] = useDrawer("backoffice-workspace")
  const workspaceCtx = useContext(WorkspaceContext)

  const history = useHistory()

  const seeAsUserWorkspace = (workspaceId: string) => {
    workspaceCtx.changeWorkspace(workspaceId)
    history.push(URLS.Portfolio)
  }

  return (
    <Box mb={3}>
      {workspaceDrawer}

      <BackOfficeUserForm {...props} />

      <BackOfficeUserSummary user={user} />

      <BackOfficeUserMetrics user={user} />

      <DrawerCategory title="Workspaces">
        {user.workspaces.map((workspace) => (
          <Box
            key={workspace.id}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <ButtonUI mode={Mode.TEXTONLY} onClick={() => openWorkspaceDrawer(workspace.id)}>
              <Typography variant="body2">{workspace.name}</Typography>
            </ButtonUI>
            <ButtonUI onClick={() => seeAsUserWorkspace(workspace.id)}>See as User</ButtonUI>
          </Box>
        ))}
      </DrawerCategory>
    </Box>
  )
}

export default BackOfficeUserDrawer
