import { OverviewBalanceDto } from "services/api/openapi/models"

const noDuplicateAssets = (assets: OverviewBalanceDto[]) => {
  const nameCounts: Map<string, number> = new Map()

  for (const asset of assets) {
    const assetName: string = asset.assetName ?? ""

    if (nameCounts.has(assetName)) {
      nameCounts.set(assetName, (nameCounts.get(assetName) ?? 0) + 1)
    } else {
      nameCounts.set(assetName, 1)
    }
  }

  const uniqueNameBalances: OverviewBalanceDto[] = []
  for (const asset of assets) {
    const assetName: string = asset.assetName ?? ""
    const nameCount = nameCounts.get(assetName) ?? 0

    if (nameCount > 1) {
      nameCounts.set(assetName, (nameCounts.get(assetName) ?? 0) - 1)
    } else {
      nameCounts.delete(assetName)
    }
    uniqueNameBalances.push({
      ...asset,
      assetName: nameCount <= 1 ? asset.assetName : `${asset.assetName} (${nameCount})`,
      assetSymbol: nameCount <= 1 ? asset.assetSymbol : `${asset.assetSymbol} (${nameCount})`,
    })
  }

  return uniqueNameBalances
}

export default noDuplicateAssets
