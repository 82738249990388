/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  AddRequestFinanceIntegrationQuery,
  AddRequestFinanceIntegrationResult,
  FireblocksConnectionDto,
  FireblocksResultDto,
  FireblocksUpdateCredentialsDto,
  FireblocksUpdateSettingsDto,
  GetBusinessIntegrationDto,
  GetRequestFinanceDto,
} from "../models"

export interface AddRequestFinanceIntegrationRequest {
  workspaceId: string
  addRequestFinanceIntegrationQuery: AddRequestFinanceIntegrationQuery
}

export interface ConnectToFireblocksRequest {
  workspaceId: string
  fireblocksConnectionDto: FireblocksConnectionDto
}

export interface DeleteRequestFinanceIntegrationRequest {
  workspaceId: string
  integrationId: string
}

export interface DisconnectFromFireblocksRequest {
  workspaceId: string
}

export interface GetBusinessIntegrationsRequest {
  workspaceId: string
}

export interface GetFireblocksRequest {
  workspaceId: string
}

export interface GetRequestFinanceIntegrationsRequest {
  workspaceId: string
}

export interface SynchronizeAllRequestFinanceIntegrationsRequest {
  workspaceId: string
}

export interface SynchronizeOneRequestFinanceIntegrationsRequest {
  workspaceId: string
  integrationId: string
}

export interface UpdateFireblocksRequest {
  workspaceId: string
}

export interface UpdateFireblocksCredentialsRequest {
  workspaceId: string
  fireblocksUpdateCredentialsDto: FireblocksUpdateCredentialsDto
}

export interface UpdateFireblocksSettingsRequest {
  workspaceId: string
  fireblocksUpdateSettingsDto: FireblocksUpdateSettingsDto
}

/**
 *
 */
export class IntegrationsApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static addRequestFinanceIntegration(params: AddRequestFinanceIntegrationRequest) {
    return localIntegrationsApi.addRequestFinanceIntegration(params)
  }

  /**
   * Add a new integration to Request Finance and connect it
   */
  addRequestFinanceIntegration({
    addRequestFinanceIntegrationQuery,
    workspaceId,
  }: AddRequestFinanceIntegrationRequest): Promise<AddRequestFinanceIntegrationResult> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/integrations/request_finance`,
      addRequestFinanceIntegrationQuery,
    )
  }

  static connectToFireblocks(params: ConnectToFireblocksRequest) {
    return localIntegrationsApi.connectToFireblocks(params)
  }

  /**
   * Connect the user to Fireblocks with its api key
   */
  connectToFireblocks({ fireblocksConnectionDto, workspaceId }: ConnectToFireblocksRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/integrations/fireblocks`,
      fireblocksConnectionDto,
      true,
    )
  }

  static deleteRequestFinanceIntegration(params: DeleteRequestFinanceIntegrationRequest) {
    return localIntegrationsApi.deleteRequestFinanceIntegration(params)
  }

  /**
   * Delete a Request Finance integration, while keeping all synced data
   */
  deleteRequestFinanceIntegration({
    workspaceId,
    integrationId,
  }: DeleteRequestFinanceIntegrationRequest): Promise<void> {
    return this.restInstance.delete(
      `/workspaces/${encodeURIComponent(workspaceId)}/integrations/request_finance/${encodeURIComponent(
        integrationId,
      )}`,
      undefined,
    )
  }

  static disconnectFromFireblocks(params: DisconnectFromFireblocksRequest) {
    return localIntegrationsApi.disconnectFromFireblocks(params)
  }

  /**
   * Disconnect the user from Fireblocks
   */
  disconnectFromFireblocks({ workspaceId }: DisconnectFromFireblocksRequest): Promise<void> {
    return this.restInstance.delete(`/workspaces/${encodeURIComponent(workspaceId)}/integrations/fireblocks`, undefined)
  }

  static getBusinessIntegrations(params: GetBusinessIntegrationsRequest) {
    return localIntegrationsApi.getBusinessIntegrations(params)
  }

  /**
   * Get Business integrations
   */
  getBusinessIntegrations({ workspaceId }: GetBusinessIntegrationsRequest): Promise<Array<GetBusinessIntegrationDto>> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/integrations`)
  }

  static getFireblocks(params: GetFireblocksRequest) {
    return localIntegrationsApi.getFireblocks(params)
  }

  /**
   * Is the workspace connected to Fireblocks
   */
  getFireblocks({ workspaceId }: GetFireblocksRequest): Promise<FireblocksResultDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/integrations/fireblocks`)
  }

  static getRequestFinanceIntegrations(params: GetRequestFinanceIntegrationsRequest) {
    return localIntegrationsApi.getRequestFinanceIntegrations(params)
  }

  /**
   * Get all the Request Finance integrations of the workspace
   */
  getRequestFinanceIntegrations({ workspaceId }: GetRequestFinanceIntegrationsRequest): Promise<GetRequestFinanceDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/integrations/request_finance`)
  }

  static synchronizeAllRequestFinanceIntegrations(params: SynchronizeAllRequestFinanceIntegrationsRequest) {
    return localIntegrationsApi.synchronizeAllRequestFinanceIntegrations(params)
  }

  /**
   * Synchronize all Request Finance integrations
   */
  synchronizeAllRequestFinanceIntegrations({
    workspaceId,
  }: SynchronizeAllRequestFinanceIntegrationsRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/integrations/request_finance/synchronize`,
      undefined,
      true,
    )
  }

  static synchronizeOneRequestFinanceIntegrations(params: SynchronizeOneRequestFinanceIntegrationsRequest) {
    return localIntegrationsApi.synchronizeOneRequestFinanceIntegrations(params)
  }

  /**
   * Synchronize all Request Finance integrations
   */
  synchronizeOneRequestFinanceIntegrations({
    workspaceId,
    integrationId,
  }: SynchronizeOneRequestFinanceIntegrationsRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/integrations/request_finance/synchronize/${encodeURIComponent(
        integrationId,
      )}`,
      undefined,
      true,
    )
  }

  static updateFireblocks(params: UpdateFireblocksRequest) {
    return localIntegrationsApi.updateFireblocks(params)
  }

  /**
   * Synchronize with Fireblocks
   */
  updateFireblocks({ workspaceId }: UpdateFireblocksRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/integrations/fireblocks/update`,
      undefined,
      true,
    )
  }

  static updateFireblocksCredentials(params: UpdateFireblocksCredentialsRequest) {
    return localIntegrationsApi.updateFireblocksCredentials(params)
  }

  /**
   * Update Fireblocks credentials
   */
  updateFireblocksCredentials({
    fireblocksUpdateCredentialsDto,
    workspaceId,
  }: UpdateFireblocksCredentialsRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/integrations/fireblocks/update_credentials`,
      fireblocksUpdateCredentialsDto,
      true,
    )
  }

  static updateFireblocksSettings(params: UpdateFireblocksSettingsRequest) {
    return localIntegrationsApi.updateFireblocksSettings(params)
  }

  /**
   * Update Fireblocks settings
   */
  updateFireblocksSettings({
    fireblocksUpdateSettingsDto,
    workspaceId,
  }: UpdateFireblocksSettingsRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/integrations/fireblocks/update_settings`,
      fireblocksUpdateSettingsDto,
      true,
    )
  }
}

const localIntegrationsApi = new IntegrationsApi()
