import SimpleAutocompleteFactory from "components/Autocomplete/SimpleAutocompleteFactory"

import { TransactionFilterValuationStatusValueEnum } from "services/api/openapi/models"

export const ValuationStatusFilter = SimpleAutocompleteFactory<TransactionFilterValuationStatusValueEnum>({
  noValuation: "Transactions without valuation",
  customValuation: "Transactions with custom valuation",
  systemValuation: "Transactions with automatic valuation",
})

export default ValuationStatusFilter
