/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"

export interface BackOfficeRefreshMovementCountRequest {
  workspaceId: string
  contactId: string
}

/**
 *
 */
export class BackOfficeContactApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static backOfficeRefreshMovementCount(params: BackOfficeRefreshMovementCountRequest) {
    return localBackOfficeContactApi.backOfficeRefreshMovementCount(params)
  }

  /**
   */
  backOfficeRefreshMovementCount({ workspaceId, contactId }: BackOfficeRefreshMovementCountRequest): Promise<void> {
    return this.restInstance.post(
      `/back-office/workspace/${encodeURIComponent(workspaceId)}/contact/${encodeURIComponent(
        contactId,
      )}/movement-count`,
      undefined,
      true,
    )
  }
}

const localBackOfficeContactApi = new BackOfficeContactApi()
