import React, { useCallback, useEffect, useState } from "react"
import { FilterTypeWithDate, FilterTypeWithDateState } from ".."
import { PlainDate } from "components/DateRangePicker"
import { PrettyFilterDateType } from "../useFilterMap"
import DatePicker from "CryptioUI/DatePicker"
import TypographyUI from "CryptioUI/Typography"
import { Dates, TypographyVariant } from "CryptioUI/types"
import dayjs from "dayjs"
import moment from "moment"
import { ReactComponent as Delete } from "CryptioUI/assets/icons/delete.svg"
import Toggle from "CryptioUI/Toggle"

interface Props {
  filtersDate: FilterTypeWithDateState[]
  setFiltersDate: (newFilter: FilterTypeWithDateState[]) => void
  mapFactoryDate: PrettyFilterDateType
  startDateTemp: PlainDate | undefined
  setStartDateTemp: (value: PlainDate | undefined) => void
  endDateTemp: PlainDate | undefined
  setEndDateTemp: (value: PlainDate | undefined) => void
  isFullHistory: boolean
  setIsFullHistory: (isFullHistory: boolean) => void
}

const FullHistory = (props: Props): JSX.Element => {
  const {
    filtersDate,
    setFiltersDate,
    mapFactoryDate,
    startDateTemp,
    setStartDateTemp,
    endDateTemp,
    setEndDateTemp,
    isFullHistory,
    setIsFullHistory,
  } = props

  const [dates, setDates] = useState<Dates>({
    startDate: startDateTemp?.toBackendDate() ? moment(startDateTemp?.toBackendDate()) : null,
    endDate: endDateTemp?.toBackendDate() ? moment(endDateTemp?.toBackendDate()) : null,
  })

  const updateFullHistoryStatus = (newStatus: boolean) => {
    if (newStatus) resetDateFilter()
    setIsFullHistory(newStatus)
  }

  useEffect(() => {
    if (isFullHistory && (startDateTemp !== undefined || endDateTemp !== undefined)) setIsFullHistory(false)
  }, [isFullHistory, startDateTemp, endDateTemp, setIsFullHistory])

  const resetDateFilter = () => {
    setStartDateTemp(undefined)
    setEndDateTemp(undefined)
    updateDate(undefined, "start_date")
    updateDate(undefined, "end_date")
    setDates({
      startDate: null,
      endDate: null,
    })
  }

  const updateDate = useCallback(
    (date: PlainDate | undefined, name: FilterTypeWithDate) => {
      const cpy = JSON.parse(JSON.stringify(filtersDate))

      if (date === undefined) {
        const idx = cpy.findIndex((e: any) => e.type === name)

        if (idx !== -1) {
          cpy.splice(idx, 1)
          setFiltersDate(cpy)
        }
      } else {
        const idx = cpy.findIndex((e: any) => e.type === name)

        if (idx === -1) {
          const cpyDefaultValue = mapFactoryDate[name].defaultValue

          cpyDefaultValue.value = date.toBackendDate()
          cpy.push(cpyDefaultValue)
          setFiltersDate(cpy)
        } else {
          if (cpy[idx].value !== date.toBackendDate()) {
            cpy[idx].value = date.toBackendDate()
            setFiltersDate(cpy)
          }
        }
      }
    },
    [filtersDate, mapFactoryDate, setFiltersDate],
  )

  useEffect(() => {
    updateDate(startDateTemp, "start_date")
  }, [startDateTemp, updateDate])

  useEffect(() => {
    updateDate(endDateTemp, "end_date")
  }, [endDateTemp, updateDate])

  useEffect(() => {
    if (dates.startDate === null) {
      setStartDateTemp(undefined)
    } else {
      const start = new PlainDate(dayjs(dates.startDate?.toString()))
      setStartDateTemp(start)
    }

    if (dates.endDate === null) {
      setEndDateTemp(undefined)
    } else {
      const start = new PlainDate(dayjs(dates.endDate?.toString()))
      setEndDateTemp(start)
    }
  }, [dates, setStartDateTemp, setEndDateTemp])

  return (
    <div className="flex flex-col gap-y-2 justify-center items-start mt-8">
      <div className="flex flex-row justify-start items-center gap-x-2 ml-[-4px]">
        <Toggle checked={isFullHistory} setChecked={updateFullHistoryStatus} disabled={false} />
        <TypographyUI variant={TypographyVariant.BODY} className="text-grey-400">
          Select full history
        </TypographyUI>
      </div>
      {!isFullHistory && (
        <div className="flex flex-row gap-4 justify-start items-center">
          <TypographyUI variant={TypographyVariant.BODY} className="text-grey-400">
            <div className="flex flex-row justify-center items-center gap-1">Filter by date : </div>
          </TypographyUI>
          <DatePicker dates={dates} setDates={setDates} />
          {(dates.startDate !== null || dates.endDate !== null) && (
            <button onClick={resetDateFilter}>
              <Delete className="w-4 h-4" />
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default FullHistory
