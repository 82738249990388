import { Box } from "@material-ui/core"
import React from "react"

import { OtherPartyAliasDto } from "services/api/openapi"
import AddressTypography from "./AddressTypography"
import AddressTitleTooltip from "./AddressTitleTooltip"

import WalletOtherParty from "./WalletOtherParty"

interface WithOtherPartiesProps {
  subtitle: string
  otherParty: OtherPartyAliasDto
  uniqueOtherPartyAliases: OtherPartyAliasDto[]
  openWalletDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  openContactDrawer: <PassedItemId extends string | null>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? Partial<Record<string, unknown>> | undefined : undefined) | undefined,
  ) => void
  setIsOpen: (isOpen: boolean) => void
}

const WithOtherParties = (props: WithOtherPartiesProps) => {
  const { subtitle, otherParty, uniqueOtherPartyAliases, openWalletDrawer, openContactDrawer, setIsOpen } = props

  return (
    <Box mt={0.25}>
      {otherParty.alias && (
        <WalletOtherParty
          subtitle={subtitle}
          name={otherParty.alias.name}
          title={<AddressTitleTooltip otherParties={uniqueOtherPartyAliases} />}
          onClick={() =>
            (otherParty.alias?.type === "wallet" ? openWalletDrawer : openContactDrawer)(
              (otherParty.alias as NonNullable<typeof otherParty.alias>).id,
            )
          }
          type={otherParty.alias?.type}
          nbOtherParty={uniqueOtherPartyAliases.length}
        />
      )}
      {otherParty.address && !otherParty.alias && (
        <AddressTypography
          subtitle={subtitle}
          component="span"
          onClick={() => setIsOpen(true)}
          noWrap
          variant="body2"
          style={{ fontWeight: "bold" }}
          otherParties={uniqueOtherPartyAliases}
        >
          {otherParty.address}
        </AddressTypography>
      )}
    </Box>
  )
}

export default WithOtherParties
