import React, { useContext, useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router"

import LoadingSpinner from "components/misc/LoadingSpinner"
import { URLS } from "../../routes"
import api from "services/api"
import { WorkspaceContext } from "services/context/workspaceContext"
import { useToast } from "CryptioUI/Toaster"

const WorkspaceRedirectScene = () => {
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const { search } = useLocation()
  const workspaceCtx = useContext(WorkspaceContext)
  const history = useHistory()
  const toast = useToast()
  const hasWorkspaceRight = api.workspace.useHasWorkspaceRight(workspaceId)

  useEffect(() => {
    workspaceCtx.changeWorkspace(workspaceId)
  }, [workspaceCtx, workspaceId])

  if (hasWorkspaceRight.data && hasWorkspaceRight.data.hasWorkspaceRight === false) {
    // Avoids a warning
    setTimeout(() => {
      toast.open("You don't have access to this workspace", { variant: "danger" })
      history.replace(URLS.Portfolio)
    })
  }

  if (workspaceCtx.workspace.id === workspaceId) {
    const queryParams = new URLSearchParams(search)
    const redirect = queryParams.get("workspaceRedirect")

    if (redirect) {
      queryParams.delete("workspaceRedirect")
    }

    // redirect url may already contain params
    const pathname = redirect || URLS.Portfolio

    // if none to add, keep pathname,
    // else if already have one, append using `&`
    // else add with `?`
    const additionalParams = queryParams.toString()
    const pathnameWithParams =
      additionalParams === "" ? pathname : `${pathname}${pathname.includes("?") ? "&" : "?"}${additionalParams}`

    // I'm doing a set a setTimeout to avoid a warning to be triggered
    setTimeout(() => history.replace(pathnameWithParams))
  }

  return <LoadingSpinner />
}

export default WorkspaceRedirectScene
