const emotionTheme = {
  screens: {
    sm: "480px",
    md: "768px",
    lg: "976px",
    xl: "1440px",
  },
  colors: {
    primary: {
      100: "#EFF6F6",
      200: "#B5D3D3",
      300: "#117664",
      400: "#02373D",
      500: "#81999E",
      600: "#E9FAFB",
    },
    secondary: {
      100: "#E5E6FF",
      200: "#C3C5FC",
      300: "#827DFF",
      400: "#625DF5",
      500: "#4B44FD",
    },
    tertiary: "#B5D3D3",
    grey: {
      100: "#F2F4F8",
      200: "#E9EAF0",
      300: "#B9C6D3",
      400: "#60657A",
      500: "#262A3E",
    },
    success: {
      100: "#DAF9F7",
      200: "#96FFFA",
      300: "#21DBD1",
      400: "#168D87",
      500: "#055753",
    },
    info: {
      100: "#D1EAFF",
      200: "#69BBFF",
      300: "#0890FF",
      400: "#066FC4",
      500: "#03599E",
    },
    warning: {
      100: "#FAF3EC",
      200: "#F8D9B7",
      300: "#EFAA5C",
      400: "#F48D17",
      500: "#BD6806",
    },
    danger: {
      100: "#FFE9F1",
      200: "#FFBDD5",
      300: "#F84082",
      400: "#C3255E",
      500: "#85133C",
    },
    black: "#000000",
    white: "#FFFFFF",
    rose: "#FFE9DF",
    disabled: "#E9EAF0",
    label: {
      indigo: {
        100: "#F2F3FF",
        200: "#625DF5",
        300: "#1F1AB9",
      },
      red: {
        100: "#FFEFF3",
        200: "#FF3366",
        300: "#B51C42",
      },
      turquoise: {
        100: "#E4F4F5",
        200: "#27C9D6",
        300: "#007680",
      },
      orange: {
        100: "#FCF7EE",
        200: "#FFAB00",
        300: "#B97F08",
      },
      brown: {
        100: "#FEF0EE",
        200: "#A96D64",
        300: "#5E342E",
      },
      green: {
        100: "#E7FCEC",
        200: "#3DC45F",
        300: "#048D27",
      },
      blue: {
        100: "#EFF8FF",
        200: "#69BBFF",
        300: "#0078DC",
      },
      grey: {
        100: "#F2F3F4",
        200: "#9EA9BD",
        300: "#616F8A",
      },
      pink: {
        100: "#FFF3FD",
        200: "#FD80E9",
        300: "#AD2A98",
      },
      cream: {
        100: "#FBFAF4",
        200: "#EDEBDD",
        300: "#CDC9AD",
      },
    },
  },
  fontFamily: {
    sans: "Inter, SF Pro Display, sans-serif",
    serif: "TRY Vesterbro, serif",
  },
}

export default emotionTheme
