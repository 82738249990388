/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import { CreateUrlShortenerDto, CreateUrlShortenerResponseDto } from "../models"

export interface CreateRequest {
  createUrlShortenerDto: CreateUrlShortenerDto
}

export interface GetRequest {
  urlId: string
}

/**
 *
 */
export class UrlShortenerApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static create(params: CreateRequest) {
    return localUrlShortenerApi.create(params)
  }

  /**
   */
  create({ createUrlShortenerDto }: CreateRequest): Promise<CreateUrlShortenerResponseDto> {
    return this.restInstance.post(`/url-shortener`, createUrlShortenerDto)
  }

  static get(params: GetRequest) {
    return localUrlShortenerApi.get(params)
  }

  /**
   */
  get({ urlId }: GetRequest): Promise<CreateUrlShortenerResponseDto> {
    return this.restInstance.get(`/url-shortener/${encodeURIComponent(urlId)}`)
  }
}

const localUrlShortenerApi = new UrlShortenerApi()
