import React, { useState, useEffect } from "react"
import moment from "moment"
import "react-dates/lib/css/_datepicker.css"

import PopoverUI from "CryptioUI/Popover"
import { DatePickerProps, Dates, Periods, PeriodLabels } from "CryptioUI/types"

import "./datepicker.css"
import DatePickerTrigger from "./DatePickerTrigger"
import DatePickerTextfields from "./DatePickerTextfields"
import DatePickerTagGroup from "./DatePickerTagGroup"
import Calendar from "./Calendar"

const defaultPeriods: Periods = {
  today: {
    active: false,
    label: "Today",
    startDate: moment(),
    endDate: moment(),
  },
  yesterday: {
    active: false,
    label: "Yesterday",
    startDate: moment().subtract(1, "days"),
    endDate: moment().subtract(1, "days"),
  },
  thisWeek: {
    active: false,
    label: "This week",
    startDate: moment().startOf("isoWeek"),
    endDate: moment(),
  },
  lastWeek: {
    active: false,
    label: "Last week",
    startDate: moment().subtract(1, "weeks").startOf("isoWeek"),
    endDate: moment().subtract(1, "weeks").endOf("isoWeek"),
  },
  last30: {
    active: false,
    label: "Last 30 days",
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
  },
  thisMonth: {
    active: false,
    label: "This month",
    startDate: moment().startOf("month"),
    endDate: moment(),
  },
  lastMonth: {
    active: false,
    label: "Last month",
    startDate: moment().subtract(1, "months").startOf("month"),
    endDate: moment().subtract(1, "months").endOf("month"),
  },
  thisYear: {
    active: false,
    label: "This year",
    startDate: moment().startOf("year"),
    endDate: moment(),
  },
  lastYear: {
    active: false,
    label: "Last year",
    startDate: moment().subtract(1, "years").startOf("year"),
    endDate: moment().subtract(1, "years").endOf("year"),
  },
  all: {
    active: false,
    label: "All",
    startDate: moment("01/01/2000"),
    endDate: moment(),
  },
}

const DatePicker = (props: DatePickerProps): JSX.Element => {
  const { disabled = false, dates, setDates } = props
  const [customDates, setCustomDates] = useState({
    startDate: dates.startDate ? dates.startDate.format("MM/DD/YYYY") : "",
    endDate: dates.endDate ? dates.endDate.format("MM/DD/YYYY") : "",
  })
  const [periods, setPeriods] = useState<Periods>(defaultPeriods)
  const [activePeriod, setActivePeriod] = useState<PeriodLabels | null>(null)

  const handleDateChange = (date: Dates) => {
    setDates({ startDate: date.startDate, endDate: date.endDate })
    setPeriods({ ...defaultPeriods })
    setActivePeriod(null)
    setCustomDates({
      startDate: date.startDate ? date.startDate.format("MM/DD/YYYY") : "",
      endDate: date.endDate ? date.endDate.format("MM/DD/YYYY") : "",
    })
  }

  const handlePeriodSelection = (period: PeriodLabels) => {
    setDates({ startDate: periods[period].startDate, endDate: periods[period].endDate })
    let newPeriods = { ...periods }
    Object.keys(newPeriods).forEach((key) => {
      newPeriods[key].active = false
    })
    newPeriods[period].active = true
    setActivePeriod(period)
    setPeriods(newPeriods)
  }

  useEffect(() => {
    if (dates.startDate && dates.endDate)
      setCustomDates({ startDate: dates.startDate.format("MM/DD/YYYY"), endDate: dates.endDate.format("MM/DD/YYYY") })
  }, [dates])

  return (
    <>
      <PopoverUI Trigger={<DatePickerTrigger dates={dates} />}>
        <DatePickerTextfields
          dates={dates}
          setDates={setDates}
          customDates={customDates}
          setCustomDates={setCustomDates}
        />
        <Calendar
          dates={dates}
          handleDateChange={handleDateChange}
          disabled={disabled}
          activePeriod={activePeriod}
          periods={periods}
        />
        <DatePickerTagGroup periods={periods} handlePeriodSelection={handlePeriodSelection} />
      </PopoverUI>
    </>
  )
}

export default DatePicker
