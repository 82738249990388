import { Box, Link, makeStyles, MobileStepper, Paper, Theme, Typography, useTheme } from "@material-ui/core"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"
import { AccountCreatedViaType } from "pure-shared"
import React, { useState } from "react"
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom"

import { useLoadingButton } from "components/LoadingButton"
import TitleWithDivider from "components/TitleWithDivider"
import ButtonUI from "CryptioUI/Button"
import { ButtonSize, Mode } from "CryptioUI/types"
import { URLS } from "../../routes"
import api from "services/api"
import { GetUserDtoAccountCreatedViaEnum } from "services/api/openapi"
import useGDPRConsent from "services/context/gdprContext"
import { getGtag } from "services/misc/getGtag"
import { gadsSignupConversionLabel, gtagId } from "services/variables"
import {
  RegistrationData,
  registrationFormId,
  RegistrationState,
  RegistrationSteps,
  RegistrationStepsIndexes,
} from "./state"
import { Step0 } from "./Step0"
import { Step1 } from "./Step1"
import { Step2 } from "./Step2"
import { RequiredIndicator } from "./utils"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: "0 auto",
    maxWidth: "38em",
    marginBottom: theme.spacing(3),
  },
  controls: {
    border: "unset",
    backgroundColor: "unset",
  },
  linkAnotherSigninMethod: {
    cursor: "pointer",
  },
}))

type SetStep = <T extends RegistrationStepsIndexes>(stepIndex: T, data: RegistrationSteps[T]) => void

const RegisterScene = (): JSX.Element => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const toast = useToast()
  const { search } = useLocation()
  const { consentState } = useGDPRConsent()
  const [RegisterButton, handleRegisterButtonCallback] = useLoadingButton()

  const [currentStep, setCurrentStep] = useState<RegistrationStepsIndexes>(1)
  const [data, setData] = useState<RegistrationState>([{ accountCreationVia: "cryptio" }])
  const [formValid, setFormValid] = useState<boolean>(false)

  const setStepData: SetStep = (index, newData) => {
    data[index] = newData
    setData(Array.from(data) as RegistrationState)
  }

  const { mutateAsync: registerMutation } = api.user.useRegister()

  const onExternalRegister = async (external: AccountCreatedViaType) => {
    if (external === "xero") {
      const url = await api.accounting.getRegisterOAuthRedirectUrl({ integration: "xero" })

      window.location.href = url.url
    } else {
      throw new Error("Unsupported service")
    }
  }

  const onRegister = async (data: RegistrationData): Promise<void> => {
    const { email, password, firstName, lastName, companyName, companyPosition } = data
    try {
      await registerMutation({
        createUserDto: {
          email,
          password,
          firstName,
          lastName,
          company: companyName,
          positionInCompany: companyPosition,
        },
      })

      toast.open("Account created, please check your email", { variant: "default" })

      const loginFrom: GetUserDtoAccountCreatedViaEnum = "cryptio"
      history.push({
        pathname: URLS.Account.Login,
        search: `login_from=${loginFrom}`,
      })

      if (consentState === "accepted") {
        getGtag().then((gtag) => {
          gtag("event", "conversion", { send_to: `${gtagId}/${gadsSignupConversionLabel}` })
        })
      }
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const ButtonComponent = currentStep === 2 ? RegisterButton : ButtonUI

  return (
    <Box pt={15}>
      <Paper className={classes.container}>
        <Box display="flex" flexDirection="column" padding={3}>
          <TitleWithDivider
            title={`Create an account${
              currentStep > 0 && data[0] && data[0].accountCreationVia === "xero" ? " with Xero" : ""
            }`}
            component="h1"
            mb={3}
          />

          <Box display={currentStep !== 0 ? "none" : undefined}>
            <Step0
              onNext={(newData) => {
                setStepData(0, newData)
                if (newData.accountCreationVia === "cryptio") {
                  setCurrentStep(1)
                } else {
                  onExternalRegister("xero")
                }
              }}
              onValid={(valid) => currentStep === 0 && setFormValid(valid)}
            />
          </Box>

          <Box display={currentStep !== 1 ? "none" : undefined}>
            <Step1
              onNext={(newData) => {
                setStepData(1, newData)
                setCurrentStep(2)
              }}
              onValid={(valid) => currentStep === 1 && setFormValid(valid)}
            />
          </Box>

          <Box display={currentStep !== 2 ? "none" : undefined}>
            <Step2
              onNext={handleRegisterButtonCallback(async (newData) => {
                setStepData(2, newData)
                if (data[0] && data[1] && data[2]) {
                  await onRegister({ ...data[0], ...data[1], ...data[2] })
                }
              })}
              onValid={(valid) => currentStep === 2 && setFormValid(valid)}
            />
          </Box>
        </Box>

        {currentStep !== 0 && (
          <>
            <Box textAlign="center">
              <Typography variant="body2" color="textSecondary">
                Fields marked with <RequiredIndicator /> are required
              </Typography>
            </Box>
            <MobileStepper
              variant="dots"
              steps={3}
              position="static"
              activeStep={currentStep}
              className={classes.controls}
              backButton={
                <ButtonUI
                  mode={Mode.TEXTONLY}
                  size={ButtonSize.LG}
                  onClick={() => setCurrentStep((currentStep - 1) as RegistrationStepsIndexes)}
                >
                  {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  Previous
                </ButtonUI>
              }
              nextButton={
                <ButtonComponent
                  mode={Mode.TEXTONLY}
                  form={registrationFormId(currentStep)}
                  size={ButtonSize.LG}
                  type="submit"
                  disabled={!formValid}
                >
                  {currentStep === 2 ? "Register" : "Next"}
                  {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </ButtonComponent>
              }
            />
          </>
        )}
      </Paper>

      <Paper className={classes.container}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding={3}>
          <Typography variant="body1" color="textSecondary">
            Already have an account?{" "}
            <Link component={RouterLink} to={`${URLS.Account.Login}${search}`}>
              Sign in
            </Link>
          </Typography>
          {currentStep !== 0 && (
            <Typography variant="body1" color="textSecondary">
              Do you want to use another registration method?{" "}
              <Link onClick={() => setCurrentStep(0)} className={classes.linkAnotherSigninMethod}>
                Click here
              </Link>
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

export default RegisterScene
