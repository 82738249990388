import React, { useMemo } from "react"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"

import { FullMovementDto, GetLabelDto, GetLabelsRequest } from "services/api/openapi"
import { WithoutWorkspaceId } from "services/api/aliases"
import api from "services/api"
import CustomSelector from "components/selector/CustomSelector"
import LabelOptionInAutocomplete from "components/AutoCompleteOptions/LabelOptionInAutocomplete"
import { mockInternalTransferLabel } from "../TransactionLabelDrawer/NonTaxabelLabelSelector"

interface NonTaxabelLabelSelectorProps {
  transaction: FullMovementDto
  setNewLabelName: (newLabelName: string | null) => void
  nonTaxablelabelsToAdd: GetLabelDto[]
  setNonTaxableLabelsToAdd: (newValue: GetLabelDto[]) => void
  isInternalTransfer: boolean
  isNonTaxableEvent: boolean
  isLockedTransaction: boolean
}

const NonTaxabelLabelSelector = (props: NonTaxabelLabelSelectorProps) => {
  const {
    transaction,
    setNewLabelName,
    nonTaxablelabelsToAdd,
    setNonTaxableLabelsToAdd,
    isInternalTransfer,
    isNonTaxableEvent,
    isLockedTransaction,
  } = props

  const isAlreadyNonTaxableEvent = useMemo(() => {
    const isFound = transaction.labels.find((label) => label.name === "Non taxable event")

    return isFound !== undefined ? true : false
  }, [transaction.labels])

  const optionsNonTaxableLabel = useMemo((): GetLabelDto[] => {
    const labels: GetLabelDto[] = [mockInternalTransferLabel]

    if (transaction.isInternalTransfer || isAlreadyNonTaxableEvent || isInternalTransfer || isNonTaxableEvent) {
      return []
    }
    return labels
  }, [transaction.isInternalTransfer, isAlreadyNonTaxableEvent, isInternalTransfer, isNonTaxableEvent])

  const uniqueLabelIds = useMemo<string[]>(() => {
    const labels = new Array(...nonTaxablelabelsToAdd)
    const index = labels.findIndex((label) => label.name === mockInternalTransferLabel.name)

    labels.splice(index, 1) // Avoid network error, don't have better solution for now

    const labelsIdArray = Array.from(
      new Set(labels.map((x) => x.id).concat(transaction.labels.map((x) => x.labelId) ?? [])),
    )
    if (labelsIdArray.includes(mockInternalTransferLabel.id)) {
      return []
    }
    return labelsIdArray
  }, [transaction, nonTaxablelabelsToAdd])

  const customProcessOption = (data: (string | GetLabelDto)[]) => {
    if (isNonTaxableEvent || isInternalTransfer || transaction.isInternalTransfer) {
      const index = data.findIndex((label) => {
        if (typeof label === "string") {
          return false
        }
        return label.name === "Non taxable event"
      })
      data.splice(index, 1)
    }
    return data
  }

  return (
    <CustomSelector<WithoutWorkspaceId<GetLabelsRequest>["getLabelQuery"], GetLabelDto | string, true, undefined, true>
      getOptionLabel={(option) => {
        if (typeof option === "string") return option
        return (option as GetLabelDto).name
      }}
      defaultPaginatedQueryProps={{
        excludedIds: uniqueLabelIds,
        nonTaxableType: "non_taxable_only",
      }}
      usePaginatedQuery={api.label.useLabels}
      size="small"
      label="Select labels"
      autoComplete
      multiple
      additionalsOption={optionsNonTaxableLabel}
      includeInputInList
      disabled={isLockedTransaction}
      filterSelectedOptions
      disableCloseOnSelect
      getOptionSelected={() => false}
      customProcessOption={customProcessOption}
      // TODO: check if string => false // or id === id
      // getOptionSelected={(option, value) => option.id === value.id}
      // Limit ?
      // getOptionDisabled={labelsToAdd.length >= 10 ? () => true : () => false}
      value={nonTaxablelabelsToAdd}
      onChange={(_event, newValue) => {
        if (newValue.length > 0 && typeof newValue[newValue.length - 1] === "string") {
          // timeout to avoid instant validation of the dialog's form.
          setTimeout(() => {
            setNewLabelName(newValue[newValue.length - 1] as string)
          })
        } else {
          setNonTaxableLabelsToAdd(newValue as GetLabelDto[])
        }
      }}
      renderOption={(option) => {
        if (typeof option === "string") return `${option}`
        else return <LabelOptionInAutocomplete label={option} />
      }}
      filterOptions={(options, params) => {
        const filter = createFilterOptions<GetLabelDto>()
        const filtered = filter(options as GetLabelDto[], params) as (GetLabelDto | string)[]

        if (params.inputValue !== "") filtered.push(params.inputValue)

        return filtered
      }}
    />
  )
}

export default NonTaxabelLabelSelector
