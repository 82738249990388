import qs from "qs"
import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router"

import Backdrop from "components/Backdrop/Backdrop"
import BaseContainer from "components/Container"
import { URLS } from "../../../routes"
import api from "services/api"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const AccountingLoginScene = (): JSX.Element => {
  const { pathname, search } = useLocation()
  const history = useHistory()
  const user = api.user.useUser()
  const { mutateAsync: loginMutation } = api.accounting.useLoginWithXero()
  const { mutateAsync: registerMutation } = api.accounting.useRegisterWithXero()
  const toast = useToast()

  const isRegister = pathname === URLS.Accounting.OAuth.Register

  useEffect(() => {
    const onLogin = async () => {
      try {
        const params = qs.parse(search, { ignoreQueryPrefix: true })

        if (isRegister) {
          await registerMutation(params)
          toast.open("Your account has been created", { variant: "success" })
        } else {
          await loginMutation(params)
          toast.open("You are now logged in", { variant: "success" })
        }
        history.replace(URLS.Portfolio)
      } catch (e) {
        toastCatch(e, toast)
        if (isRegister) {
          history.replace(URLS.Account.Register)
        } else {
          history.replace(URLS.Account.Login)
        }
      }
    }

    if (user.isError) onLogin()
  }, [user.isError, toast, history, isRegister, loginMutation, registerMutation, search])

  return (
    <BaseContainer>
      <Backdrop keepOpen />
    </BaseContainer>
  )
}

export default AccountingLoginScene
