import { Box } from "@material-ui/core"
import React from "react"
import useDialog from "../../misc/useDialog"
import { DrawerProp } from "../../misc/useDrawer"

import TradeForm from "./TradeForm"
import TradeSummary from "./TradeSummary"
import { GetTradeDto } from "services/api/routes/transactions"

const TradeDrawer = (props: DrawerProp<GetTradeDto, false>) => {
  const { item: trade } = props
  const basicDialog = useDialog()

  return (
    <Box mb={3}>
      {basicDialog.dialog}

      <TradeForm {...props} />

      <TradeSummary trade={trade} />
    </Box>
  )
}

export default TradeDrawer
