import { GetReportModuleDto, GetReportTypeDto } from "../api/openapi"

export type ReportInfos = {
  module: GetReportModuleDto
  type: GetReportTypeDto
}

function findReportType(data: GetReportModuleDto[] | undefined, moduleName: string, typeName: string): ReportInfos {
  if (!data) throw new Error("No report modules")

  const reportModule = data.find((t) => t.name === moduleName)
  if (!reportModule) throw new Error(`Report module ${moduleName} does not exist`)

  const reportType = reportModule.types.find((t) => t.name === typeName)
  if (!reportType || reportType.formats.length < 1)
    throw new Error(`Report type ${typeName} does not exist in module ${moduleName}`)

  return { module: reportModule, type: reportType }
}

export default findReportType
