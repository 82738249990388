import React from "react"
import { CostBasisIds, Trade } from "../.."
import { NewInListTransactionDto, NewInListMovementDto } from "services/api/openapi"
import { generateDevelopedMvt } from "./GenerateComplexTransaction/generateDevelopedMvt"
import { generateCollapsedTransaction } from "./GenerateComplexTransaction/generateCollapsedTransaction"
import { generateSimpleTransaction } from "./GenerateSimpleTransaction/generateSimpleTransaction"
import { convertMovements } from "../../utils/convertMovement"
import ContainerRow from "../ContainerRow"
import { ColumnsName } from "../ContainerColumn"
import { DeveloppedRowsType } from "../useDeveloppedTransactionPage"
import { ConfigTable } from "../useConfigTable"
import { SelectTransactionPageContextValue } from "services/context/selectTransactionPageContext"
import { SelectedRowsType } from "../useBulkSelectorTransactionPage"
import { GetTradeRequest } from "services/api/routes/transactions"
import { WithoutWorkspaceId } from "services/api/aliases"
import { GetMovementDto } from "../../../../services/api/routes/transactions"

interface GeneratedRowsProps {
  transaction: NewInListTransactionDto
  openTradeDrawer: <PassedItemId extends WithoutWorkspaceId<GetTradeRequest> | null>(
    data: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  columnsNameArray: ColumnsName[]
  openWalletMovementDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  openMovementLabelDrawer: <PassedItemId extends GetMovementDto>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  setCostBasisDrawerTransactionId: (ids: CostBasisIds | null) => void
  openMovementDrawer: <PassedItemId extends GetMovementDto>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  openTransactionDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  selectedRows: SelectedRowsType | undefined
  setSelectedRows: (row: SelectedRowsType | undefined) => void
  developpedRows: DeveloppedRowsType | undefined
  setDeveloppedRows: (row: DeveloppedRowsType | undefined) => void
  columnsConfig: ConfigTable[]
  openTransactionLabelDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  mvtSelected: SelectTransactionPageContextValue
  isLastRow: boolean
}

interface SimpleTransactionProps extends GeneratedRowsProps {
  loneMovements: NewInListMovementDto[]
  trades: Trade[]
  nbIn: number
  nbOut: number
  nbFee: number
  isComplex: boolean
}

const SimpleTransaction = (props: SimpleTransactionProps) => {
  const columns = generateSimpleTransaction(
    props.transaction,
    props.columnsNameArray,
    props.openWalletMovementDrawer,
    props.openMovementLabelDrawer,
    props.setCostBasisDrawerTransactionId,
    props.loneMovements,
    props.trades,
    props.nbIn,
    props.nbOut,
    props.nbFee,
    props.selectedRows,
    props.setSelectedRows,
    props.developpedRows,
    props.setDeveloppedRows,
    props.columnsConfig,
    props.openTransactionLabelDrawer,
  )

  return (
    <ContainerRow borderBottom={props.isLastRow} onClick={() => props.openTransactionDrawer(props.transaction.id)}>
      {columns}
    </ContainerRow>
  )
}

interface ComplexTransactionProps extends GeneratedRowsProps {
  loneMovements: NewInListMovementDto[]
  trades: Trade[]
  nbIn: number
  nbOut: number
  nbFee: number
  isComplex: boolean
}

const ComplexTransaction = (props: ComplexTransactionProps) => {
  if (props.mvtSelected.selectedRows !== undefined && props.mvtSelected.addRows !== undefined) {
    props.mvtSelected.addRows(props.loneMovements)
  }

  const isRowDevelopped = (id: string): boolean => {
    if (props.developpedRows?.type === "positiveAdd") return !props.developpedRows.itemIds.includes(id)
    else if (props.developpedRows?.type === "negativeAdd") return props.developpedRows.excludedIds.includes(id)
    return true
  }

  const complexTransaction = () => {
    const column = generateCollapsedTransaction(
      props.transaction,
      props.columnsNameArray,
      props.openWalletMovementDrawer,
      props.openMovementLabelDrawer,
      props.setCostBasisDrawerTransactionId,
      props.loneMovements,
      props.trades,
      props.nbIn,
      props.nbOut,
      props.nbFee,
      props.developpedRows,
      props.selectedRows,
      props.setSelectedRows,
      props.setDeveloppedRows,
      props.columnsConfig,
      props.openTransactionLabelDrawer,
    )

    return (
      <ContainerRow
        borderBottom={!isRowDevelopped(props.transaction.id) || props.isLastRow ? true : false}
        onClick={() => props.openTransactionDrawer(props.transaction.id)}
      >
        {column}
      </ContainerRow>
    )
  }

  const rowMvts = props.loneMovements.map((mvt) => {
    const columns = generateDevelopedMvt(
      props.transaction,
      props.columnsNameArray,
      props.openWalletMovementDrawer,
      props.openMovementLabelDrawer,
      props.setCostBasisDrawerTransactionId,
      mvt,
      undefined,
      props.developpedRows,
      props.setDeveloppedRows,
      props.columnsConfig,
      props.selectedRows,
      props.setSelectedRows,
      props.openTransactionLabelDrawer,
      props.mvtSelected,
    )

    return (
      <ContainerRow
        key={mvt.id}
        noBorderTop
        onClick={() => props.openMovementDrawer({ movementId: mvt.id })}
        borderBottom={props.isLastRow}
        isMatchingFilter={mvt.filter}
      >
        {columns}
      </ContainerRow>
    )
  })

  const rowTrades = props.trades.map((trade) => {
    if (!trade.in.filter && !trade.out.filter) return <></>

    const columns = generateDevelopedMvt(
      props.transaction,
      props.columnsNameArray,
      props.openWalletMovementDrawer,
      props.openMovementLabelDrawer,
      props.setCostBasisDrawerTransactionId,
      undefined,
      trade,
      props.developpedRows,
      props.setDeveloppedRows,
      props.columnsConfig,
      props.selectedRows,
      props.setSelectedRows,
      props.openTransactionLabelDrawer,
      props.mvtSelected,
    )

    return (
      <ContainerRow
        key={trade.out.id}
        noBorderTop
        borderBottom={props.isLastRow}
        onClick={() => props.openTradeDrawer({ movementInId: trade.in.id, movementOutId: trade.out.id })}
      >
        {columns}
      </ContainerRow>
    )
  })

  return (
    <>
      {complexTransaction()}
      {isRowDevelopped(props.transaction.id) ? (
        <></>
      ) : (
        <>
          {rowMvts}
          {rowTrades}
        </>
      )}
    </>
  )
}

const GeneratedRows = (props: GeneratedRowsProps) => {
  const {
    transaction,
    columnsNameArray,
    openWalletMovementDrawer,
    openMovementLabelDrawer,
    setCostBasisDrawerTransactionId,
    openMovementDrawer,
    openTransactionDrawer,
    openTradeDrawer,
    selectedRows,
    setSelectedRows,
    developpedRows,
    setDeveloppedRows,
    openTransactionLabelDrawer,
    columnsConfig,
    mvtSelected,
    isLastRow,
  } = props

  const [loneMovements, trades] = convertMovements(transaction)
  const nbIn = transaction.incomingCount
  const nbOut = transaction.outgoingCount
  const nbFee = transaction.feeCount
  const isComplex = transaction.complexity === "complex"

  if (!isComplex) {
    return (
      <SimpleTransaction
        transaction={transaction}
        columnsNameArray={columnsNameArray}
        openWalletMovementDrawer={openWalletMovementDrawer}
        openMovementLabelDrawer={openMovementLabelDrawer}
        setCostBasisDrawerTransactionId={setCostBasisDrawerTransactionId}
        openMovementDrawer={openMovementDrawer}
        openTransactionDrawer={openTransactionDrawer}
        openTradeDrawer={openTradeDrawer}
        loneMovements={loneMovements}
        trades={trades}
        nbIn={nbIn}
        nbOut={nbOut}
        nbFee={nbFee}
        isComplex={isComplex}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        developpedRows={developpedRows}
        setDeveloppedRows={setDeveloppedRows}
        columnsConfig={columnsConfig}
        openTransactionLabelDrawer={openTransactionLabelDrawer}
        mvtSelected={mvtSelected}
        isLastRow={isLastRow}
      />
    )
  }
  return (
    <ComplexTransaction
      transaction={transaction}
      columnsNameArray={columnsNameArray}
      openWalletMovementDrawer={openWalletMovementDrawer}
      openMovementLabelDrawer={openMovementLabelDrawer}
      openTransactionLabelDrawer={openTransactionLabelDrawer}
      setCostBasisDrawerTransactionId={setCostBasisDrawerTransactionId}
      openMovementDrawer={openMovementDrawer}
      openTransactionDrawer={openTransactionDrawer}
      openTradeDrawer={openTradeDrawer}
      loneMovements={loneMovements}
      trades={trades}
      nbIn={nbIn}
      nbOut={nbOut}
      nbFee={nbFee}
      isComplex={isComplex}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      developpedRows={developpedRows}
      setDeveloppedRows={setDeveloppedRows}
      columnsConfig={columnsConfig}
      mvtSelected={mvtSelected}
      isLastRow={isLastRow}
    />
  )
}

export default GeneratedRows
