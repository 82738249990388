import React from "react"

import { ReactComponent as ManualIconSvg } from "CryptioUI/assets/icons/free.svg"
import { ReactComponent as AutomaticIconSvg } from "CryptioUI/assets/icons/menu/integrations.svg"
import { getIconFilterFromContrast } from "services/utils/imgUtils"
import { css } from "@emotion/css"

export interface LabelIconProps {
  type?: "automatic" | "manual"
  color: string
  size?: number
}

const LabelIcon = ({ type, color, size = 12 }: LabelIconProps): JSX.Element => {
  const iconFilter = getIconFilterFromContrast(color)
  const iconStyle = () =>
    css`
      width: ${size}px;
      height: ${size}px;
      ${iconFilter}
    `
  return (
    <>
      {type === "automatic" ? <AutomaticIconSvg className={iconStyle()} /> : <ManualIconSvg className={iconStyle()} />}
    </>
  )
}

export default LabelIcon
