import { useTypedController } from "@hookform/strictly-typed"
import { Box, Checkbox, FormLabel, Typography } from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import React, { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"

import { DrawerCategory } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import useDialog from "components/misc/useDialog"
import { DrawerProp } from "components/misc/useDrawer"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { BackOfficeGetFullUserDto, BackOfficeUpdateUserDto } from "services/api/openapi/models"
import { toastCatch } from "components/ReactHookForm/utils"
import { useToast } from "CryptioUI/Toaster"

type FormType = Omit<BackOfficeUpdateUserDto, "id">

const BackOfficeUserForm = (props: DrawerProp<BackOfficeGetFullUserDto, false>) => {
  const { onClose, isOpen, setFormDirty, item: user } = props
  const { handleSubmit, control, formState, reset } = useForm<FormType>({
    mode: "onChange",
  })
  const toast = useToast()
  const TypedController = useTypedController<FormType>({ control })

  const basicDialog = useDialog()

  const { mutateAsync: updateUserMutation } = api.backOffice.user.useUpdateUser()
  // const [deleteUserMutation] = api.backOffice.user.useDeleteUser()

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen, reset])

  const [UpdateUserButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = useCallback(
    async (form: FormType) => {
      try {
        await updateUserMutation({
          userId: user.id,
          backOfficeUpdateUserDto: form,
        })

        toast.open("User updated", { variant: "success" })

        onClose()
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [onClose, user, updateUserMutation, toast],
  )

  const isFormDirty = formState.isValid && formState.isDirty

  useEffect(() => setFormDirty(isFormDirty), [isFormDirty, setFormDirty])

  const doDeleteUser = async () => {
    try {
      // For now the button is disabled
      // TODO: Determine who should have the permission to delete users
      // await deleteUserMutation({ userId: user.id })
      toast.open("User deleted", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
    onClose()
  }

  const askDeleteUser = () => {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to delete the user <b>{user.email}</b>? This action is irreversible.
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doDeleteUser,
    })
  }

  return (
    <DrawerCategory component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))} title="User Edition">
      {basicDialog.dialog}

      <Box display="flex" alignItems="center" mt={3}>
        <TypedController
          name="isAccountantPartner"
          defaultValue={user.isAccountantPartner}
          rules={{ required: false }}
          render={({ onChange, value, ...rest }) => (
            <Checkbox
              color="primary"
              id="is-accountant-partner-checkbox"
              style={{ paddingLeft: 0 }}
              onChange={() => onChange(!value)}
              checked={value}
              {...rest}
            />
          )}
        />
        <FormLabel htmlFor="is-accountant-partner-checkbox">Is accountant partner?</FormLabel>
      </Box>

      <Box mt={3} display="flex" justifyContent="space-between">
        <UpdateUserButton disabled={!isFormDirty} type="submit">
          Save
        </UpdateUserButton>
        <ButtonUI Icon={<Delete />} mode={Mode.CONTAINED} onClick={askDeleteUser} disabled>
          Delete
        </ButtonUI>
      </Box>
    </DrawerCategory>
  )
}

export default BackOfficeUserForm
