import { Box, Typography } from "@material-ui/core"
import React from "react"
import { DrawerCategory } from "components/Drawer/DrawerItems"
import { UncontrolledLoadingButton } from "components/LoadingButton"

import AdministratorInformation from "components/misc/AdministratorInformation"
import BackOfficeView from "components/misc/BackOfficeView"
import { DrawerProp } from "components/misc/useDrawer"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { GetLabelDto } from "services/api/openapi"
import LabelForm from "./LabelForm"
import ButtonModalImportCsv from "../ModalImportCsv"
import { toastCatch } from "components/ReactHookForm/utils"
import { useToast } from "CryptioUI/Toaster"

const hasLabelARule = (label: GetLabelDto): boolean => label.filter !== null || label.from !== null || label.to !== null

const CreateLabelDrawer = (props: DrawerProp<GetLabelDto, true>) => {
  const { item: label, onClose, setTitle, title } = props
  const toast = useToast()
  const { mutateAsync: updateLabelRuleMutation } = api.backOffice.label.useUpdateLabelRule()
  const newTitle = `Label ${label ? "edition" : "creation"}`

  const updateLabelRule = async () => {
    if (!label) return
    try {
      await updateLabelRuleMutation({
        labelId: label.id,
      })
      toast.open("Updating rule", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  if (setTitle && title !== newTitle) {
    setTitle(newTitle)
  }
  if (label && label.isGlobal) {
    return (
      <Box>
        <AdministratorInformation infos={[{ id: label.id, extended: "Label" }]} />
        <Box my={2}>
          <Typography variant="h5">
            Label <b>{label.name}</b> is global. Global labels can not be edited.
          </Typography>
        </Box>
        <ButtonModalImportCsv labelId={label.id} />
        <ButtonUI mode={Mode.CONTAINED} onClick={onClose}>
          Close
        </ButtonUI>
      </Box>
    )
  }
  return (
    <>
      {label && (
        <>
          <AdministratorInformation infos={[{ id: label.id, extended: "Label" }]} />
          {hasLabelARule(label) && (
            <BackOfficeView>
              <DrawerCategory title="Force update rule">
                <Box my={2} />
                <UncontrolledLoadingButton onClick={updateLabelRule}>Update label rule</UncontrolledLoadingButton>
              </DrawerCategory>
            </BackOfficeView>
          )}
        </>
      )}
      <LabelForm {...props} />
    </>
  )
}

export default CreateLabelDrawer
