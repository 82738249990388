/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  AddFavoriteReportDto,
  AddFavoriteReportFilterDto,
  BulkReportsActionDto,
  CanMarkLedgerEntriesAsSynchronizedDto,
  CreateReportDto,
  CreateReportResult,
  DeleteFavoriteReportDto,
  EditFavoriteReportFilterDto,
  GetReportBulkSignedUrlDto,
  GetReportDto,
  GetReportModuleDto,
  GetReportSignedUrlDto,
  PaginatedFavoriteReportFilterResultDto,
  PaginatedReportResultDto,
  UpdateExportFormatHiddenColumnsDto,
} from "../models"

export interface AddFavoriteReportRequest {
  workspaceId: string
  addFavoriteReportDto: AddFavoriteReportDto
}

export interface AddFavoriteReportFilterRequest {
  workspaceId: string
  addFavoriteReportFilterDto: AddFavoriteReportFilterDto
}

export interface CanMarkLedgerEntriesAsSynchronizedRequest {
  workspaceId: string
}

export interface CreateReportRequest {
  workspaceId: string
  createReportDto: CreateReportDto
}

export interface DeleteBulkReportsRequest {
  workspaceId: string
  bulkReportsActionDto: BulkReportsActionDto
}

export interface DeleteFavoriteReportRequest {
  workspaceId: string
  deleteFavoriteReportDto: DeleteFavoriteReportDto
}

export interface DeleteFavoriteReportFilterRequest {
  workspaceId: string
  id: string
}

export interface DeleteReportRequest {
  reportId: string
  workspaceId: string
}

export interface EditFavoriteReportFilterRequest {
  workspaceId: string
  id: string
  editFavoriteReportFilterDto: EditFavoriteReportFilterDto
}

export interface GetBulkSignedUrlRequest {
  workspaceId: string
  bulkReportsActionDto: BulkReportsActionDto
}

export interface GetDownloadSignedUrlRequest {
  workspaceId: string
  reportId: string
}

export interface GetFavoriteReportFiltersRequest {
  workspaceId: string
  page: number
  limit: number
  sortBy?: GetFavoriteReportFiltersSortByEnum
  sortDirection?: GetFavoriteReportFiltersSortDirectionEnum
  query?: string
  userId?: string
  excludedIds?: Array<string>
}

export interface GetReportRequest {
  workspaceId: string
  reportId: string
}

export interface GetReportTypesRequest {
  workspaceId: string
}

export interface GetReportsRequest {
  page: number
  limit: number
  workspaceId: string
  sortBy?: GetReportsSortByEnum
  sortDirection?: GetReportsSortDirectionEnum
  reportModule?: GetReportsReportModuleEnum
  reportType?: GetReportsReportTypeEnum
  individualId?: string
  assetIds?: Array<string>
  walletIds?: Array<string>
  startDate?: string | null
  endDate?: string | null
  excludedIds?: Array<string>
}

export interface MarkLedgerEntriesAsSynchronizedRequest {
  workspaceId: string
}

export interface UpdateExportFormatHiddenColumnsRequest {
  workspaceId: string
  exportFormatId: string
  updateExportFormatHiddenColumnsDto: UpdateExportFormatHiddenColumnsDto
}

/**
 *
 */
export class ReportsApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static addFavoriteReport(params: AddFavoriteReportRequest) {
    return localReportsApi.addFavoriteReport(params)
  }

  /**
   * Add favorite report to a workspace
   */
  addFavoriteReport({ addFavoriteReportDto, workspaceId }: AddFavoriteReportRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports/favorite`,
      addFavoriteReportDto,
      true,
    )
  }

  static addFavoriteReportFilter(params: AddFavoriteReportFilterRequest) {
    return localReportsApi.addFavoriteReportFilter(params)
  }

  /**
   * Add a favorite report filter to a workspace
   */
  addFavoriteReportFilter({ addFavoriteReportFilterDto, workspaceId }: AddFavoriteReportFilterRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports/favorite/filter`,
      addFavoriteReportFilterDto,
      true,
    )
  }

  static canMarkLedgerEntriesAsSynchronized(params: CanMarkLedgerEntriesAsSynchronizedRequest) {
    return localReportsApi.canMarkLedgerEntriesAsSynchronized(params)
  }

  /**
   * Check if user can mark all the error free transactions from the latest ledger entry report as synchronized
   */
  canMarkLedgerEntriesAsSynchronized({
    workspaceId,
  }: CanMarkLedgerEntriesAsSynchronizedRequest): Promise<CanMarkLedgerEntriesAsSynchronizedDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports/can-mark-ledger-entries-as-synchronized`,
    )
  }

  static createReport(params: CreateReportRequest) {
    return localReportsApi.createReport(params)
  }

  /**
   * Create a report and add a job to process it
   */
  createReport({ createReportDto, workspaceId }: CreateReportRequest): Promise<CreateReportResult> {
    return this.restInstance.post(`/workspaces/${encodeURIComponent(workspaceId)}/reports`, createReportDto)
  }

  static deleteBulkReports(params: DeleteBulkReportsRequest) {
    return localReportsApi.deleteBulkReports(params)
  }

  /**
   * Delete some reports
   */
  deleteBulkReports({ bulkReportsActionDto, workspaceId }: DeleteBulkReportsRequest): Promise<void> {
    return this.restInstance.delete(`/workspaces/${encodeURIComponent(workspaceId)}/reports`, bulkReportsActionDto)
  }

  static deleteFavoriteReport(params: DeleteFavoriteReportRequest) {
    return localReportsApi.deleteFavoriteReport(params)
  }

  /**
   * Delete favorite report from workspace
   */
  deleteFavoriteReport({ deleteFavoriteReportDto, workspaceId }: DeleteFavoriteReportRequest): Promise<void> {
    return this.restInstance.delete(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports/favorite`,
      deleteFavoriteReportDto,
    )
  }

  static deleteFavoriteReportFilter(params: DeleteFavoriteReportFilterRequest) {
    return localReportsApi.deleteFavoriteReportFilter(params)
  }

  /**
   * Delete a favorite report filter to a workspace
   */
  deleteFavoriteReportFilter({ workspaceId, id }: DeleteFavoriteReportFilterRequest): Promise<void> {
    return this.restInstance.delete(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports/favorite/filter/${encodeURIComponent(id)}`,
      undefined,
    )
  }

  static deleteReport(params: DeleteReportRequest) {
    return localReportsApi.deleteReport(params)
  }

  /**
   * Delete a report
   */
  deleteReport({ reportId, workspaceId }: DeleteReportRequest): Promise<void> {
    return this.restInstance.delete(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports/${encodeURIComponent(reportId)}`,
      undefined,
    )
  }

  static editFavoriteReportFilter(params: EditFavoriteReportFilterRequest) {
    return localReportsApi.editFavoriteReportFilter(params)
  }

  /**
   * Edit a favorite report filter to a workspace
   */
  editFavoriteReportFilter({
    editFavoriteReportFilterDto,
    workspaceId,
    id,
  }: EditFavoriteReportFilterRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports/favorite/filter/${encodeURIComponent(id)}`,
      editFavoriteReportFilterDto,
      true,
    )
  }

  static getBulkSignedUrl(params: GetBulkSignedUrlRequest) {
    return localReportsApi.getBulkSignedUrl(params)
  }

  /**
   * Get signed urls from filters
   */
  getBulkSignedUrl({ bulkReportsActionDto, workspaceId }: GetBulkSignedUrlRequest): Promise<GetReportBulkSignedUrlDto> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports/get/download`,
      bulkReportsActionDto,
    )
  }

  static getDownloadSignedUrl(params: GetDownloadSignedUrlRequest) {
    return localReportsApi.getDownloadSignedUrl(params)
  }

  /**
   * Get a signed url to download a report
   */
  getDownloadSignedUrl({ workspaceId, reportId }: GetDownloadSignedUrlRequest): Promise<GetReportSignedUrlDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports/${encodeURIComponent(reportId)}/download`,
    )
  }

  static getFavoriteReportFilters(params: GetFavoriteReportFiltersRequest) {
    return localReportsApi.getFavoriteReportFilters(params)
  }

  /**
   * Get favorite report filters
   */
  getFavoriteReportFilters({
    workspaceId,
    ...queryParameters
  }: GetFavoriteReportFiltersRequest): Promise<PaginatedFavoriteReportFilterResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports/favorite/filters?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }

  static getReport(params: GetReportRequest) {
    return localReportsApi.getReport(params)
  }

  /**
   * Get one report
   */
  getReport({ workspaceId, reportId }: GetReportRequest): Promise<GetReportDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports/${encodeURIComponent(reportId)}`,
    )
  }

  static getReportTypes(params: GetReportTypesRequest) {
    return localReportsApi.getReportTypes(params)
  }

  /**
   * Get report types
   */
  getReportTypes({ workspaceId }: GetReportTypesRequest): Promise<Array<GetReportModuleDto>> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/reports/types`)
  }

  static getReports(params: GetReportsRequest) {
    return localReportsApi.getReports(params)
  }

  /**
   * Get user\'s reports
   */
  getReports({ workspaceId, ...queryParameters }: GetReportsRequest): Promise<PaginatedReportResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
    )
  }

  static markLedgerEntriesAsSynchronized(params: MarkLedgerEntriesAsSynchronizedRequest) {
    return localReportsApi.markLedgerEntriesAsSynchronized(params)
  }

  /**
   * Mark all the error free transactions from the latest ledger entry report as synchronized
   */
  markLedgerEntriesAsSynchronized({ workspaceId }: MarkLedgerEntriesAsSynchronizedRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports/mark-ledger-entries-as-synchronized`,
      undefined,
      true,
    )
  }

  static updateExportFormatHiddenColumns(params: UpdateExportFormatHiddenColumnsRequest) {
    return localReportsApi.updateExportFormatHiddenColumns(params)
  }

  /**
   * Get report hidden columns
   */
  updateExportFormatHiddenColumns({
    updateExportFormatHiddenColumnsDto,
    workspaceId,
    exportFormatId,
  }: UpdateExportFormatHiddenColumnsRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/reports/format/${encodeURIComponent(exportFormatId)}/columns`,
      updateExportFormatHiddenColumnsDto,
      true,
    )
  }
}

const localReportsApi = new ReportsApi()

/**
 * @export
 * @enum {string}
 */
export type GetFavoriteReportFiltersSortByEnum = "name" | "created_at"
/**
 * @export
 * @enum {string}
 */
export type GetFavoriteReportFiltersSortDirectionEnum = "ascending" | "descending"
/**
 * @export
 * @enum {string}
 */
export type GetReportsSortByEnum = "name" | "created_at" | "asset_id" | "start_date" | "end_date" | "type"
/**
 * @export
 * @enum {string}
 */
export type GetReportsSortDirectionEnum = "ascending" | "descending"
/**
 * @export
 * @enum {string}
 */
export type GetReportsReportModuleEnum =
  | "accounting"
  | "financial"
  | "audit"
  | "consensys"
  | "filecoin"
  | "favorite"
  | "admin"
/**
 * @export
 * @enum {string}
 */
export type GetReportsReportTypeEnum =
  | "total_per_day"
  | "asset_breakdown"
  | "wallet_breakdown"
  | "per_day_breakdown"
  | "portfolio_asset_breakdown"
  | "portfolio_wallet_breakdown"
  | "current_balances"
  | "end_of_day_balances"
  | "cost_basis"
  | "ledger"
  | "summarized_ledger"
  | "transactions"
  | "new_transaction_history"
  | "transactions_custom_export"
  | "movements"
  | "assets"
  | "chainlink_node_operator"
  | "bank_statement"
  | "unidentified_addresses"
  | "fireblocks_missing_addresses"
  | "custom"
  | "impairment"
  | "impairment_balance"
  | "impairment_transaction_detail"
  | "contacts"
  | "asset_roll_forward"
  | "detailed_asset_roll_forward"
  | "fees_expenditures"
  | "transfers_statement"
  | "sources"
  | "highest_missing_volume"
  | "audit_trail"
  | "journal_sequential_numbering"
  | "administrator"
  | "trial_balances"
  | "filecoin_reward_tracking"
  | "consensys_transactions"
  | "consensys_asset_roll_forward"
  | "consensys_rgl_asset_roll_forward"
  | "consensys_asset_roll_forward_tax"
  | "new_consensys_asset_roll_forward"
  | "consensys_provider_fee_historical_balances"
  | "consensys_asset_breakdown_recap"
  | "consensys_ledger_entries"
