/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  BackOfficeBulkUpdateWorkspaceDto,
  BackOfficeDuplicateWorkspaceDto,
  BackOfficeGetFullWorkspaceDto,
  BackOfficePaginatedWorkspaceResultDto,
  BackOfficeStartManualSubscriptionDto,
  BackOfficeUpdateSubscriptionTrialEndDate,
  BackOfficeUpdateWorkspaceDto,
  BackOfficeWorkspaceFeaturesDto,
} from "../models"

export interface BackOfficeDeleteWorkspaceRequest {
  workspaceId: string
}

export interface BackOfficeDuplicateWorkspaceRequest {
  workspaceId: string
  backOfficeDuplicateWorkspaceDto: BackOfficeDuplicateWorkspaceDto
}

export interface BackOfficeGetWorkspaceRequest {
  workspaceId: string
}

export interface BackOfficeGetWorkspaceFeaturesRequest {
  workspaceId: string
}

export interface BackOfficeGetWorkspacesRequest {
  page: number
  limit: number
  query?: string
  subscriptionType?: BackOfficeGetWorkspacesSubscriptionTypeEnum
  accountingIntegration?: BackOfficeGetWorkspacesAccountingIntegrationEnum
  freeTrialMaxDaysRemaining?: number
  limitationType?: BackOfficeGetWorkspacesLimitationTypeEnum
  sortBy?: BackOfficeGetWorkspacesSortByEnum
  sortDirection?: BackOfficeGetWorkspacesSortDirectionEnum
  excludedIds?: Array<string>
  minimumTransaction?: number
  minimumUser?: number
  hasAccountingIntegration?: boolean
  planId?: string
  workspaceId?: string
  maxRemainingUsagePercent?: number
  maxRemainingUsageCount?: number
}

export interface BackOfficeRemoveSubscriptionRequest {
  workspaceId: string
}

export interface BackOfficeStartBalancesRequest {
  backOfficeBulkUpdateWorkspaceDto: BackOfficeBulkUpdateWorkspaceDto
}

export interface BackOfficeStartContactTrxCountRequest {
  backOfficeBulkUpdateWorkspaceDto: BackOfficeBulkUpdateWorkspaceDto
}

export interface BackOfficeStartCostBasisForAllRequest {
  backOfficeBulkUpdateWorkspaceDto: BackOfficeBulkUpdateWorkspaceDto
}

export interface BackOfficeStartManualSubscriptionRequest {
  workspaceId: string
  backOfficeStartManualSubscriptionDto: BackOfficeStartManualSubscriptionDto
}

export interface BackOfficeStartUnknownAddressRequest {
  backOfficeBulkUpdateWorkspaceDto: BackOfficeBulkUpdateWorkspaceDto
}

export interface BackOfficeToggleWorkspaceBookmarkRequest {
  workspaceId: string
}

export interface BackOfficeUpdateAllLabelRulesRequest {
  backOfficeBulkUpdateWorkspaceDto: BackOfficeBulkUpdateWorkspaceDto
}

export interface BackOfficeUpdateAllWorkspaceInternalTransferRequest {
  backOfficeBulkUpdateWorkspaceDto: BackOfficeBulkUpdateWorkspaceDto
}

export interface BackOfficeUpdateAllWorkspaceInternalTransferReviewRequest {
  backOfficeBulkUpdateWorkspaceDto: BackOfficeBulkUpdateWorkspaceDto
}

export interface BackOfficeUpdateAllWorkspaceMappingsRequest {
  backOfficeBulkUpdateWorkspaceDto: BackOfficeBulkUpdateWorkspaceDto
}

export interface BackOfficeUpdateSubscriptionTrialEndDateRequest {
  backOfficeUpdateSubscriptionTrialEndDate: BackOfficeUpdateSubscriptionTrialEndDate
}

export interface BackOfficeUpdateWorkspaceRequest {
  workspaceId: string
  backOfficeUpdateWorkspaceDto: BackOfficeUpdateWorkspaceDto
}

export interface BackOfficeUpdateWorkspaceFeaturesRequest {
  workspaceId: string
  backOfficeWorkspaceFeaturesDto: BackOfficeWorkspaceFeaturesDto
}

export interface ChangeWorkspaceUserOwnershipRequest {
  workspaceId: string
  userId: string
}

/**
 *
 */
export class BackOfficeWorkspaceApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static backOfficeDeleteWorkspace(params: BackOfficeDeleteWorkspaceRequest) {
    return localBackOfficeWorkspaceApi.backOfficeDeleteWorkspace(params)
  }

  /**
   * Delete a workspace
   */
  backOfficeDeleteWorkspace({ workspaceId }: BackOfficeDeleteWorkspaceRequest): Promise<void> {
    return this.restInstance.post(`/back-office/workspace/${encodeURIComponent(workspaceId)}/delete`, undefined, true)
  }

  static backOfficeDuplicateWorkspace(params: BackOfficeDuplicateWorkspaceRequest) {
    return localBackOfficeWorkspaceApi.backOfficeDuplicateWorkspace(params)
  }

  /**
   * Duplicate a workspace
   */
  backOfficeDuplicateWorkspace({
    backOfficeDuplicateWorkspaceDto,
    workspaceId,
  }: BackOfficeDuplicateWorkspaceRequest): Promise<void> {
    return this.restInstance.post(
      `/back-office/workspace/${encodeURIComponent(workspaceId)}/duplicate`,
      backOfficeDuplicateWorkspaceDto,
      true,
    )
  }

  static backOfficeGetWorkspace(params: BackOfficeGetWorkspaceRequest) {
    return localBackOfficeWorkspaceApi.backOfficeGetWorkspace(params)
  }

  /**
   */
  backOfficeGetWorkspace({ workspaceId }: BackOfficeGetWorkspaceRequest): Promise<BackOfficeGetFullWorkspaceDto> {
    return this.restInstance.get(`/back-office/workspace/${encodeURIComponent(workspaceId)}`)
  }

  static backOfficeGetWorkspaceFeatures(params: BackOfficeGetWorkspaceFeaturesRequest) {
    return localBackOfficeWorkspaceApi.backOfficeGetWorkspaceFeatures(params)
  }

  /**
   * Get workspace features
   */
  backOfficeGetWorkspaceFeatures({
    workspaceId,
  }: BackOfficeGetWorkspaceFeaturesRequest): Promise<BackOfficeWorkspaceFeaturesDto> {
    return this.restInstance.get(`/back-office/workspace/${encodeURIComponent(workspaceId)}/features`)
  }

  static backOfficeGetWorkspaces(params: BackOfficeGetWorkspacesRequest) {
    return localBackOfficeWorkspaceApi.backOfficeGetWorkspaces(params)
  }

  /**
   * Get workspaces
   */
  backOfficeGetWorkspaces({
    ...queryParameters
  }: BackOfficeGetWorkspacesRequest): Promise<BackOfficePaginatedWorkspaceResultDto> {
    return this.restInstance.get(`/back-office/workspace?${this.restInstance.encodeObjectToUrl(queryParameters)}`)
  }

  static backOfficeRemoveSubscription(params: BackOfficeRemoveSubscriptionRequest) {
    return localBackOfficeWorkspaceApi.backOfficeRemoveSubscription(params)
  }

  /**
   */
  backOfficeRemoveSubscription({ workspaceId }: BackOfficeRemoveSubscriptionRequest): Promise<void> {
    return this.restInstance.delete(`/back-office/workspace/${encodeURIComponent(workspaceId)}/subscription`, undefined)
  }

  static backOfficeStartBalances(params: BackOfficeStartBalancesRequest) {
    return localBackOfficeWorkspaceApi.backOfficeStartBalances(params)
  }

  /**
   */
  backOfficeStartBalances({ backOfficeBulkUpdateWorkspaceDto }: BackOfficeStartBalancesRequest): Promise<void> {
    return this.restInstance.post(`/back-office/workspace/balance`, backOfficeBulkUpdateWorkspaceDto, true)
  }

  static backOfficeStartContactTrxCount(params: BackOfficeStartContactTrxCountRequest) {
    return localBackOfficeWorkspaceApi.backOfficeStartContactTrxCount(params)
  }

  /**
   */
  backOfficeStartContactTrxCount({
    backOfficeBulkUpdateWorkspaceDto,
  }: BackOfficeStartContactTrxCountRequest): Promise<void> {
    return this.restInstance.post(`/back-office/workspace/contact-trx-count`, backOfficeBulkUpdateWorkspaceDto, true)
  }

  static backOfficeStartCostBasisForAll(params: BackOfficeStartCostBasisForAllRequest) {
    return localBackOfficeWorkspaceApi.backOfficeStartCostBasisForAll(params)
  }

  /**
   */
  backOfficeStartCostBasisForAll({
    backOfficeBulkUpdateWorkspaceDto,
  }: BackOfficeStartCostBasisForAllRequest): Promise<void> {
    return this.restInstance.post(`/back-office/workspace/cost-basis`, backOfficeBulkUpdateWorkspaceDto, true)
  }

  static backOfficeStartManualSubscription(params: BackOfficeStartManualSubscriptionRequest) {
    return localBackOfficeWorkspaceApi.backOfficeStartManualSubscription(params)
  }

  /**
   */
  backOfficeStartManualSubscription({
    backOfficeStartManualSubscriptionDto,
    workspaceId,
  }: BackOfficeStartManualSubscriptionRequest): Promise<void> {
    return this.restInstance.post(
      `/back-office/workspace/${encodeURIComponent(workspaceId)}/subscription`,
      backOfficeStartManualSubscriptionDto,
      true,
    )
  }

  static backOfficeStartUnknownAddress(params: BackOfficeStartUnknownAddressRequest) {
    return localBackOfficeWorkspaceApi.backOfficeStartUnknownAddress(params)
  }

  /**
   */
  backOfficeStartUnknownAddress({
    backOfficeBulkUpdateWorkspaceDto,
  }: BackOfficeStartUnknownAddressRequest): Promise<void> {
    return this.restInstance.post(`/back-office/workspace/unknown-address`, backOfficeBulkUpdateWorkspaceDto, true)
  }

  static backOfficeToggleWorkspaceBookmark(params: BackOfficeToggleWorkspaceBookmarkRequest) {
    return localBackOfficeWorkspaceApi.backOfficeToggleWorkspaceBookmark(params)
  }

  /**
   * Bookmark or unbookmark a workspace depending on its previous state
   */
  backOfficeToggleWorkspaceBookmark({ workspaceId }: BackOfficeToggleWorkspaceBookmarkRequest): Promise<void> {
    return this.restInstance.post(`/back-office/workspace/${encodeURIComponent(workspaceId)}/bookmark`, undefined, true)
  }

  static backOfficeUpdateAllLabelRules(params: BackOfficeUpdateAllLabelRulesRequest) {
    return localBackOfficeWorkspaceApi.backOfficeUpdateAllLabelRules(params)
  }

  /**
   * Batch apply label rules
   */
  backOfficeUpdateAllLabelRules({
    backOfficeBulkUpdateWorkspaceDto,
  }: BackOfficeUpdateAllLabelRulesRequest): Promise<void> {
    return this.restInstance.post(`/back-office/workspace/label-rules`, backOfficeBulkUpdateWorkspaceDto, true)
  }

  static backOfficeUpdateAllWorkspaceInternalTransfer(params: BackOfficeUpdateAllWorkspaceInternalTransferRequest) {
    return localBackOfficeWorkspaceApi.backOfficeUpdateAllWorkspaceInternalTransfer(params)
  }

  /**
   */
  backOfficeUpdateAllWorkspaceInternalTransfer({
    backOfficeBulkUpdateWorkspaceDto,
  }: BackOfficeUpdateAllWorkspaceInternalTransferRequest): Promise<void> {
    return this.restInstance.post(
      `/back-office/workspace/flag-internal-transfer`,
      backOfficeBulkUpdateWorkspaceDto,
      true,
    )
  }

  static backOfficeUpdateAllWorkspaceInternalTransferReview(
    params: BackOfficeUpdateAllWorkspaceInternalTransferReviewRequest,
  ) {
    return localBackOfficeWorkspaceApi.backOfficeUpdateAllWorkspaceInternalTransferReview(params)
  }

  /**
   */
  backOfficeUpdateAllWorkspaceInternalTransferReview({
    backOfficeBulkUpdateWorkspaceDto,
  }: BackOfficeUpdateAllWorkspaceInternalTransferReviewRequest): Promise<void> {
    return this.restInstance.post(
      `/back-office/workspace/flag-internal-transfer-review`,
      backOfficeBulkUpdateWorkspaceDto,
      true,
    )
  }

  static backOfficeUpdateAllWorkspaceMappings(params: BackOfficeUpdateAllWorkspaceMappingsRequest) {
    return localBackOfficeWorkspaceApi.backOfficeUpdateAllWorkspaceMappings(params)
  }

  /**
   */
  backOfficeUpdateAllWorkspaceMappings({
    backOfficeBulkUpdateWorkspaceDto,
  }: BackOfficeUpdateAllWorkspaceMappingsRequest): Promise<void> {
    return this.restInstance.post(
      `/back-office/workspace/coa-automated-mapping`,
      backOfficeBulkUpdateWorkspaceDto,
      true,
    )
  }

  static backOfficeUpdateSubscriptionTrialEndDate(params: BackOfficeUpdateSubscriptionTrialEndDateRequest) {
    return localBackOfficeWorkspaceApi.backOfficeUpdateSubscriptionTrialEndDate(params)
  }

  /**
   */
  backOfficeUpdateSubscriptionTrialEndDate({
    backOfficeUpdateSubscriptionTrialEndDate,
  }: BackOfficeUpdateSubscriptionTrialEndDateRequest): Promise<void> {
    return this.restInstance.put(
      `/back-office/workspace/subscription/trial-end-date`,
      backOfficeUpdateSubscriptionTrialEndDate,
      true,
    )
  }

  static backOfficeUpdateWorkspace(params: BackOfficeUpdateWorkspaceRequest) {
    return localBackOfficeWorkspaceApi.backOfficeUpdateWorkspace(params)
  }

  /**
   */
  backOfficeUpdateWorkspace({
    backOfficeUpdateWorkspaceDto,
    workspaceId,
  }: BackOfficeUpdateWorkspaceRequest): Promise<void> {
    return this.restInstance.put(
      `/back-office/workspace/${encodeURIComponent(workspaceId)}`,
      backOfficeUpdateWorkspaceDto,
      true,
    )
  }

  static backOfficeUpdateWorkspaceFeatures(params: BackOfficeUpdateWorkspaceFeaturesRequest) {
    return localBackOfficeWorkspaceApi.backOfficeUpdateWorkspaceFeatures(params)
  }

  /**
   * Update workspace features
   */
  backOfficeUpdateWorkspaceFeatures({
    backOfficeWorkspaceFeaturesDto,
    workspaceId,
  }: BackOfficeUpdateWorkspaceFeaturesRequest): Promise<void> {
    return this.restInstance.post(
      `/back-office/workspace/${encodeURIComponent(workspaceId)}/features`,
      backOfficeWorkspaceFeaturesDto,
      true,
    )
  }

  static changeWorkspaceUserOwnership(params: ChangeWorkspaceUserOwnershipRequest) {
    return localBackOfficeWorkspaceApi.changeWorkspaceUserOwnership(params)
  }

  /**
   * change user ownership for a workspace
   */
  changeWorkspaceUserOwnership({ workspaceId, userId }: ChangeWorkspaceUserOwnershipRequest): Promise<void> {
    return this.restInstance.post(
      `/back-office/workspace/userownership/${encodeURIComponent(workspaceId)}/${encodeURIComponent(userId)}`,
      undefined,
      true,
    )
  }
}

const localBackOfficeWorkspaceApi = new BackOfficeWorkspaceApi()

/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetWorkspacesSubscriptionTypeEnum = "trial" | "paying"
/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetWorkspacesAccountingIntegrationEnum = "xero" | "quickbooks" | "custom" | "netsuite"
/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetWorkspacesLimitationTypeEnum =
  | "csv_wallet"
  | "chain_wallet"
  | "custody_wallet"
  | "exchange_wallet"
  | "transaction"
  | "user_per_workspace"
  | "role_per_workspace"
  | "report"
  | "label"
  | "invoice"
  | "contact"
  | "chart_account"
  | "transaction_filter"
  | "contact_wallet_per_label_rule"
/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetWorkspacesSortByEnum = "name" | "created_at" | "remaining_limitation"
/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetWorkspacesSortDirectionEnum = "ascending" | "descending"
