import { Box, Typography } from "@material-ui/core"
import { Delete, GetAppRounded } from "@material-ui/icons"
import React, { useContext, useEffect } from "react"

import { DrawerCategory } from "components/Drawer/DrawerItems"
import { UncontrolledLoadingButton } from "components/LoadingButton"
import useDialog from "components/misc/useDialog"
import { DrawerProp } from "components/misc/useDrawer"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { GetReportDto } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import download from "services/utils/download"
import ReportForm, { DrawerReportForm } from "./ReportForm"
import ReportSummary from "./ReportSummary"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const ReportDrawer = (props: DrawerProp<GetReportDto, true, DrawerReportForm>) => {
  const { item: report, onClose, setTitle, title } = props
  const toast = useToast()
  const basicDialog = useDialog()
  const workspaceCtx = useContext(WorkspaceContext)

  const { mutateAsync: deleteReportMutation } = api.report.useDeleteReport()

  const handleDownload = async () => {
    if (!report) return
    try {
      const { signedUrl } = await api.report.getSignedUrl(workspaceCtx, report.id)
      download(signedUrl)
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const doDeleteReport = async () => {
    if (!report) return
    try {
      await deleteReportMutation({ reportId: report.id })
      toast.open("Report deleted", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
    onClose()
  }

  useEffect(() => {
    if (report && setTitle && title !== "Edit report") {
      setTitle("Edit report")
    }
  }, [setTitle, report, title])

  const askDeleteReport = () => {
    if (!report) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">Do you really want to delete this report? This action is irreversible.</Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doDeleteReport,
    })
  }

  if (!report) {
    return <ReportForm {...props} />
  }

  return (
    <>
      {basicDialog.dialog}
      <ReportSummary report={report} />
      <DrawerCategory title="Report download">
        <Box mt={3} />
        <Typography variant="body2">Click the button below to download the report.</Typography>
        <Box mt={1} />
        <PermissionDisabled permission="can_download_report" action="download a report">
          <UncontrolledLoadingButton
            disabled={report.error !== null || report?.generationStatus.status !== "inactive"}
            Icon={<GetAppRounded />}
            onClick={handleDownload}
          >
            Download
          </UncontrolledLoadingButton>
        </PermissionDisabled>
      </DrawerCategory>
      <DrawerCategory title="Report deletion">
        <Box mt={3} />
        <Typography variant="body2">The report will be deleted. This action is irreversible.</Typography>
        <Box mt={1} />
        <PermissionDisabled permission="can_remove_report" action="delete a report">
          <ButtonUI Icon={<Delete />} mode={Mode.DEFAULT} onClick={askDeleteReport}>
            Delete
          </ButtonUI>
        </PermissionDisabled>
      </DrawerCategory>
    </>
  )
}

export default ReportDrawer
