import { useQuery } from "react-query"

import { DefaultApi } from "../openapi"

export const GET_VERSION_KEY = "getVersion"

export const miscActions = {
  useVersion: () => {
    return useQuery({
      queryKey: GET_VERSION_KEY,
      queryFn: () => DefaultApi.version(),
      refetchInterval: 5000,
      cacheTime: 0,
    })
  },
}
