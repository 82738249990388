import BigNumber from "bignumber.js"
import dayjs from "dayjs"

export const USDollarId = "1df60fdc-fe52-4fee-9ffd-7898e02852d0"

export const asyncDelay = async (delayMs: number): Promise<void> => {
  return new Promise((r) => setTimeout(r, delayMs))
}

export const equalIgnoreCase = function (str1?: string | null, str2?: string | null): boolean {
  if (!str1 || !str2) return false
  return str1.toUpperCase() === str2.toUpperCase()
}

export const bigToFormatString = (amount: BigNumber): string => {
  if (amount.eq(0)) {
    return "0.00"
  }
  if (amount.gte(1e6) || amount.lte(0.001)) {
    return amount.toExponential(3)
  }
  if (amount.gte(1e5)) {
    return amount.toFixed(1)
  }
  if (amount.gte(1e4)) {
    return amount.toFixed(2)
  }
  return amount.toFixed(3)
}

export const convertRawNumber = (value: BigNumber.Value | null, shift: number): BigNumber => {
  // maxShift is a arbitrary limitation
  const MAX_SHIFT = 40

  if (!value || Math.abs(shift) > MAX_SHIFT) return new BigNumber(0)
  return new BigNumber(value).shiftedBy(shift)
}

export function getEnvValue(key: string, defaultValue?: string): string {
  const value = process.env[key] ?? defaultValue
  if (value === undefined) throw new Error(`'${key}' is missing from env`)
  return value
}

export const isNullOrEmpty = (value: string | null | undefined): boolean => {
  return !(typeof value === "string" && value.length > 0)
}

type UrlEncodableObject = {
  [key: string]: Array<string | number | boolean> | string | number | boolean | undefined
}

export function encodeObjectToUrl(o: object): string {
  const obj = o as UrlEncodableObject
  const params = []
  for (const key in obj) {
    const value = obj[key]
    if (Array.isArray(value)) {
      if (value.length > 0) params.push(key + "=" + encodeURIComponent(value.join(",")))
      // TODO: should we keep !== undefined or fix it ?
    } else if (value !== undefined) {
      params.push(key + "=" + encodeURIComponent(value))
    }
  }
  return params.join("&")
}

export function prettyEmptyOrNull(symbol: string | null) {
  if (symbol === null) {
    return "(Unknown)"
  }

  if (symbol.length === 0) {
    return "(Empty)"
  }

  return symbol
}

// Cast for Object.entries
export const ObjectTypedEntries = <T>(object: T) => Object.entries(object) as ObjectEntries<T>

// remapObject({ 'k': 'v' }, ([k,v]) => k + v)).k === 'kv'
export const remapObject = <T, K extends string, V>(
  object: T,
  mappedFct: (entry: ObjectEntries<T>[number]) => [K, V],
) =>
  ObjectTypedEntries(object).reduce((record, entry) => {
    const [k, v] = mappedFct(entry)
    record[k] = v
    return record
  }, {} as Record<K, V>)

export function isInteger(value: string) {
  return /^-?\d+$/.test(value)
}

export const recordFromEntries = <Key extends string | symbol, Value>(
  entries: (readonly [Key, Value])[],
): Record<Key, Value | undefined> => Object.fromEntries(entries) as Record<Key, Value | undefined>

export const ObjectTypedKeys = <T>(object: T) => Object.keys(object) as (keyof T)[]

// Collect all the values contained in an AsyncGenerator<T>.
export const collect = async <T>(generator: AsyncGenerator<T, void, void>): Promise<T[]> => {
  const buffer = new Array<T>()

  for await (const e of generator) {
    buffer.push(e)
  }

  return buffer
}

export type IfEquals<T, U, Y = unknown, N = never> = (<G>() => G extends T ? 1 : 2) extends <G>() => G extends U ? 1 : 2
  ? Y
  : N

export const arrayToRecord = <A extends readonly string[], V>(
  array: A,
  mapper: (key: A[number]) => V,
): Record<A[number], V> => Object.fromEntries(array.map((elem) => [elem, mapper(elem)])) as Record<A[number], V>

export function assertUnreachable(_x: never, error: Error): never {
  throw error
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stringifyBasicClasses = (o: any) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  JSON.stringify(o, function (key: string, value: any) {
    const rawValue = this[key]
    if (dayjs.isDayjs(rawValue)) {
      return { __dayjs: rawValue.toISOString() }
    }
    if (rawValue instanceof Set) {
      return { __set: [...rawValue] }
    }
    if (rawValue instanceof Map) {
      return { __map: [...rawValue] }
    }
    return value
  })

export const parseBasicClasses = (s: string) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  JSON.parse(s, function (_key: string, value: any) {
    if (typeof value === "object" && value !== null) {
      if ("__dayjs" in value) return dayjs(value.__dayjs)
      if ("__set" in value) return new Set(value.__set)
      if ("__map" in value) return new Map(value.__map)
    }
    return value
  })

export type Include<T, U> = T extends U ? T : never

// https://stackoverflow.com/questions/54520676/in-typescript-how-to-get-the-keys-of-an-object-type-whose-values-are-of-a-given
// Get the union of all keys of an object having a value of a certain type
export type KeysMatching<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T]

// https://stackoverflow.com/questions/60141960/typescript-key-value-relation-preserving-object-entries-type
// Keeps the key type in Object.entries, useful for constants objects
export type PickByValue<T, V> = Pick<T, { [K in keyof T]: T[K] extends V ? K : never }[keyof T]>
export type ObjectEntries<T> = {
  [K in keyof T]: [keyof PickByValue<T, T[K]>, T[K]]
}[keyof T][]
// const obj = { "key": "value" } as const
// const entries = Object.entries(obj)
// typeof entries => [string, "value"][]
// ObjectEntries<typeof obj> => ["key", "value"][]

// Get values of an object
// { a: 1, b: 2, c: "str" } => 1 | 2 | "str"
export type ObjectValues<T> = { [K in keyof T]: T[K] }[keyof T]

// Allow to filter keys based on a type condition (SubType<SomeType, string | number> will remove all keys that are not string | number)
type FilterKeys<Base, Condition> = {
  [Key in keyof Base]: Base[Key] extends Condition ? Key : never
}
type AllowedNames<Base, Condition> = FilterKeys<Base, Condition>[keyof Base]

export type SubType<Base, Condition> = Pick<Base, AllowedNames<Base, Condition>>

// https://stackoverflow.com/questions/53503813/get-dictionary-object-keys-as-tuple-in-typescript
export type UnionToIntersection<U> = (U extends never ? never : (arg: U) => never) extends (arg: infer I) => void
  ? I
  : never
export type UnionToTuple<T> = UnionToIntersection<T extends never ? never : (t: T) => T> extends (_: never) => infer W
  ? [...UnionToTuple<Exclude<T, W>>, W]
  : []

export function stripBom(value: string) {
  // Catches EFBBBF (UTF-8 BOM) because the buffer-to-string
  // conversion translates it to FEFF (UTF-16 BOM).
  if (value.charCodeAt(0) === 0xfeff) {
    return value.slice(1)
  }

  return value
}
