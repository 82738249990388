import { UseQueryOptions } from "react-query"

import { queryClient } from "../../.."
import { usePaginatedWorkspaceQuery, useWorkspaceMutation, useWorkspaceQuery } from "../../context/workspaceContext"
import { WithoutWorkspaceId } from "../aliases"
import { GetLabelDto, GetLabelsRequest, LabelsApi, PaginatedLabelResultDto } from "../openapi"
import { GET_TRANSACTION_KEY, GET_TRANSACTIONS_KEY } from "./transactions"
import { GET_WORKSPACE_METRICS_KEY, GET_WORKSPACES_OR_DEFAULT_KEY } from "./workspace"

export const GET_LABELS_KEY = "getLabels"
export const GET_LABEL_KEY = "getLabel"

export const labelActions = {
  useLabels: (
    variables: WithoutWorkspaceId<GetLabelsRequest>["getLabelQuery"],
    options?: UseQueryOptions<PaginatedLabelResultDto>,
  ) => {
    return usePaginatedWorkspaceQuery(
      [GET_LABELS_KEY, variables],
      LabelsApi.getLabels,
      { getLabelQuery: variables },
      options,
    )
  },
  isLabelNameAvailable: LabelsApi.isLabelNameAvailable,
  useCreateLabels: () => {
    return useWorkspaceMutation(LabelsApi.createLabel, {
      onSuccess: () => {
        // More label
        queryClient.invalidateQueries(GET_LABELS_KEY)

        // You might create a label that automatically associate with some transaction
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
      },
    })
  },
  useUpdateLabels: () => {
    return useWorkspaceMutation(LabelsApi.updateLabel, {
      onSuccess: () => {
        // Update labels
        queryClient.invalidateQueries(GET_LABELS_KEY)
        queryClient.invalidateQueries(GET_LABEL_KEY)

        // You might create a label that automatically associate with some transaction
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)

        // Can make COA dirty if label has a mapping
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useDeleteLabel: () => {
    return useWorkspaceMutation(LabelsApi.deleteLabel, {
      onSuccess: () => {
        // Less label
        queryClient.invalidateQueries(GET_LABELS_KEY)

        // delete label associated with some transaction
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)

        // Can make COA dirty if label has a mapping
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
      },
    })
  },
  useLabel: (labelId: string | undefined | null, option?: UseQueryOptions<GetLabelDto>) => {
    return useWorkspaceQuery(
      [GET_LABEL_KEY, labelId],
      LabelsApi.getLabel,
      { labelId: labelId ?? "" },
      {
        enabled: !!labelId,
        ...option,
      },
    )
  },
}
