import React from "react"

import { Mode, TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import TooltipUI from "CryptioUI/Tooltip"
import ButtonUI from "CryptioUI/Button"
import useDrawer from "components/misc/useDrawer"

interface PositionDeFiProps {
  positionName: string
  walletId: string
  walletName: string
  left?: boolean
}

const PositionDeFi = (props: PositionDeFiProps) => {
  const { positionName, walletId, walletName, left = false } = props
  const [walletDrawer, openWalletDrawer] = useDrawer("wallet")
  const position = left ? "start" : "end"
  const positionNameProcessed = positionName === "Unknown" ? "Unknown Asset" : positionName
  const size = position === "end" ? "w-[400px] max-w-[400px]" : "w-[180px] max-w-[180px]"
  const wrappredSize = position === "end" ? "w-[380px]" : "w-[130px]"

  return (
    <div className={`flex flex-col items-center justify-${position}`}>
      {walletDrawer}
      <div className={`flex justify-${position}`}>
        <div
          className={`${size} overflow-hidden whitespace-nowrap text-ellipsis flex justify-${position}`}
          data-tag="allowRowEvents"
        >
          <TooltipUI content={positionNameProcessed} side="bottom" alignment="end">
            <div>
              <TypographyUI variant={TypographyVariant.BODY} className="text-grey-500">
                {positionNameProcessed}
              </TypographyUI>
            </div>
          </TooltipUI>
        </div>
      </div>
      <div
        className={`${size} overflow-hidden whitespace-nowrap text-ellipsis flex justify-${position} item-center gap-x-2`}
        data-tag="allowRowEvents"
      >
        <ButtonUI
          mode={Mode.TEXTONLY}
          className="!p-0 !h-fit"
          onClick={() => {
            openWalletDrawer(walletId)
          }}
        >
          <div className={`${wrappredSize} flex justify-${position} overflow-hidden whitespace-nowrap text-ellipsis`}>
            <TooltipUI content={walletName} side="bottom" alignment="end">
              <div>
                <TypographyUI variant={TypographyVariant.CAPTION} className="text-grey-400 underline">
                  {walletName}
                </TypographyUI>
              </div>
            </TooltipUI>
          </div>
        </ButtonUI>
      </div>
    </div>
  )
}

export default PositionDeFi
