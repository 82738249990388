import { Box, DialogActions, TextField } from "@material-ui/core"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { useLoadingButton } from "components/LoadingButton"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { Mode } from "CryptioUI/types"
import api from "services/api"

import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { AccountingIntegrationNameType, prettyAccountingIntegrationNameMap } from "pure-shared"
import { useTypedController } from "@hookform/strictly-typed"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface TbaModalCsvForm {
  accountId: string
  tokenId: string
  tokenSecret: string
  consumerKey: string
  consumerSecret: string
}

const TbaModalForm = ({
  buttonText,
  buttonDisabled,
  integrationName,
  askConnect,
}: {
  askConnect: (onConnect: () => void) => void
  buttonText: string
  buttonDisabled?: boolean
  integrationName: AccountingIntegrationNameType
}): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { handleSubmit, control, formState } = useForm<TbaModalCsvForm>({
    mode: "onChange",
    defaultValues: {
      accountId: "",
      consumerKey: "",
      consumerSecret: "",
      tokenId: "",
      tokenSecret: "",
    },
  })
  const TypedController = useTypedController<TbaModalCsvForm>({ control })
  const toast = useToast()

  const [ImportSelectionButton, handleButtonCallback] = useLoadingButton()
  const { mutateAsync: connectToTbaInterationMutation } = api.accounting.useConnectToTbaIntegration()
  const onSubmit = async (form: TbaModalCsvForm) => {
    try {
      // Need filter, empty item in array
      await connectToTbaInterationMutation({
        integrationName,
        connectToTbaIntegrationDto: form,
      })

      toast.open("COA successfully imported", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const accountingTbaFormId = "accounting-tba-form"

  return (
    <>
      <ButtonUI disabled={buttonDisabled} onClick={() => askConnect(() => setIsModalOpen(true))}>
        {buttonText}
      </ButtonUI>
      <ModalUI
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="w-[1000px] h-auto"
        title={`Connect to ${prettyAccountingIntegrationNameMap[integrationName]}`}
      >
        <Box component="form" id={accountingTbaFormId} onSubmit={handleButtonCallback(handleSubmit(onSubmit))} mb={1}>
          <DrawerFormSection htmlFor="account-id-textfield" name="Account ID">
            <TypedController
              name="accountId"
              rules={{ required: true }}
              render={(props) => <TextField id="account-id-textfield" {...props} fullWidth={true} />}
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="consumer-key-textfield" name="Consumer Key">
            <TypedController
              name="consumerKey"
              rules={{ required: true }}
              render={(props) => <TextField id="consumer-key-textfield" {...props} fullWidth={true} />}
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="consumer-secret-textfield" name="Consumer Secret">
            <TypedController
              name="consumerSecret"
              rules={{ required: true }}
              render={(props) => <TextField id="consumer-secret-textfield" {...props} fullWidth={true} />}
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="token-id-textfield" name="Token ID">
            <TypedController
              name="tokenId"
              rules={{ required: true }}
              render={(props) => <TextField id="token-id-textfield" {...props} fullWidth={true} />}
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="token-secret-textfield" name="Token Secret">
            <TypedController
              name="tokenSecret"
              rules={{ required: true }}
              render={(props) => <TextField id="token-secret-textfield" {...props} fullWidth={true} />}
            />
          </DrawerFormSection>
        </Box>
        <DialogActions>
          <ButtonUI onClick={() => setIsModalOpen(false)} mode={Mode.CONTAINED}>
            Cancel
          </ButtonUI>
          <ImportSelectionButton type="submit" form={accountingTbaFormId} disabled={!formState.isValid}>
            Connect
          </ImportSelectionButton>
        </DialogActions>
      </ModalUI>
    </>
  )
}

export default TbaModalForm
