import { useTypedController } from "@hookform/strictly-typed"
import {
  Avatar,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core"
import { checkIntegrationFormat } from "pure-shared"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { ReactComponent as Add } from "CryptioUI/assets/icons/add.svg"

import FieldHelper from "components/FieldHelper"
import { ReactComponent as Reset } from "CryptioUI/assets/icons/reset.svg"
import { ReactComponent as MenuLogs } from "CryptioUI/assets/icons/menu/logs.svg"
import { UncontrolledLoadingButton } from "components/LoadingButton"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import TitleWithDivider from "components/TitleWithDivider"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { pluralize } from "services/utils/textUtils"
import { showConfirmation } from "../../utils"
import { RequestIntegrationsList } from "./RequestIntegrationsList"
import { iconStyleWhite } from "CryptioUI/Utilities/config"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

export interface IntegrationForm {
  name: string
  apiKey: string
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    marginRight: "16px",
  },
  marginLeft: {
    marginLeft: theme.spacing(1.5),
  },
  numberOfInvoices: {
    display: "inline-block",
  },
  invoiceNumber: {
    fontWeight: "bold",
    marginRight: theme.spacing(0.5),
    fontSize: 16,
  },
  paper: { minWidth: "400px" },
}))

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  connect: (form: IntegrationForm) => Promise<void>
  formId: string
}

function AddApiKeyDialog({ isOpen, onClose, connect, formId }: ModalProps): JSX.Element {
  const { control, handleSubmit, formState } = useForm<IntegrationForm>({ mode: "onChange" })
  const TypedController = useTypedController<IntegrationForm>({ control })

  return (
    <ModalUI isOpen={isOpen} onClose={onClose}>
      <DialogTitle id="connect-dialog-title">
        <TitleWithDivider title="Connect to Request Finance" variant="h4" />
      </DialogTitle>

      <DialogContent>
        <form id={formId} onSubmit={handleSubmit(connect)}>
          <Box mt={2}>
            <FormLabel htmlFor="name-textfield">Name</FormLabel>
            <TypedController
              name="name"
              defaultValue=""
              rules={{ required: true, minLength: 1 }}
              render={(props) => <TextField {...props} id="name-textfield" fullWidth margin="normal" />}
            />
          </Box>
          <Box mt={2}>
            <Grid container alignItems="center">
              <FormLabel htmlFor="apiKey-textfield">API key</FormLabel>
              <Box ml={1}>
                <FieldHelper
                  helpUrl={"https://docs.request.network/docs/guides/3-Portal-API/0-portal-intro/#portal-outlook"}
                />
              </Box>
            </Grid>
            <TypedController
              name="apiKey"
              defaultValue=""
              rules={{ required: true, minLength: 1 }}
              render={(props) => (
                <TextField {...props} id="apiKey-textfield" fullWidth variant="standard" margin="normal" />
              )}
            />
          </Box>
          <DialogActions>
            <ButtonUI mode={Mode.CONTAINED} onClick={onClose}>
              Cancel
            </ButtonUI>
            <ButtonUI disabled={!formState.isValid} type="submit" form={formId}>
              Connect
            </ButtonUI>
          </DialogActions>
        </form>
      </DialogContent>
    </ModalUI>
  )
}

export function RequestNetworkIntegration(): JSX.Element {
  const classes = useStyles()
  const toast = useToast()

  const { mutateAsync: addRequestFinanceIntegrationMutation } = api.integrations.useAddRequestFinanceIntegration()
  const { mutateAsync: synchronizeRequestFinanceMutation } = api.integrations.useSynchronizeRequestFinance()
  const { mutateAsync: synchronizeOneRequestFinanceIntegrationMutation } =
    api.integrations.useSynchronizeOneRequestFinanceIntegration()

  const [isAddApiKeyOpen, setIsApiKeyOpen] = useState(false)
  const [isListIntegrationsOpen, setIsListIntegrationsOpen] = useState(false)

  const connection = api.integrations.useRequestFinance()
  const dialog = useDialog()

  async function submitImport({ name, apiKey }: IntegrationForm) {
    try {
      const { success, id } = await addRequestFinanceIntegrationMutation({
        addRequestFinanceIntegrationQuery: {
          name,
          apiKey,
        },
      })
      if (success) {
        await synchronizeOneRequestFinanceIntegrationMutation({ integrationId: id! })
        setIsApiKeyOpen(false)
        toast.open("Integration with Request Finance succeeded", {
          variant: "success",
        })
      } else {
        toast.open("Failed to connect to Request Finance ", {
          variant: "danger",
        })
      }
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  async function connect(form: IntegrationForm) {
    if (!checkIntegrationFormat("request_network", form.apiKey)) {
      showConfirmation(dialog, () => submitImport(form))
    } else {
      submitImport(form)
    }
  }

  if (connection.isError) return <NetworkErrorMessage additionalData={connection} />
  if (connection.isLoading || connection.data === undefined) return <LoadingSpinner />

  const hasConnections = connection.data.integrations.length > 0

  return (
    <>
      <AddApiKeyDialog
        isOpen={isAddApiKeyOpen}
        onClose={() => setIsApiKeyOpen(false)}
        connect={connect}
        formId="business-integrations-request-form"
      />
      <RequestIntegrationsList
        isOpen={isListIntegrationsOpen}
        onClose={() => setIsListIntegrationsOpen(false)}
        integrations={connection.data.integrations}
      />
      {dialog.dialog}
      <Box display="flex" alignItems="center">
        <Avatar
          alt={"Request Finance"}
          variant={"rounded"}
          src={"/images/integrations/request-network.jpeg"}
          className={classes.avatar}
        />
        <Typography variant="h2">Request Finance</Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          Connecting a Request Finance account allows to pull invoices and automatically attach them to their related
          transactions.
        </Typography>
      </Box>
      <Box mb={2} bottom="0" position="absolute">
        <Grid container>
          <Grid xs={12}>
            {hasConnections && connection.data?.totalInvoices !== undefined ? (
              <>
                <Typography className={classes.numberOfInvoices}>
                  <span className={classes.invoiceNumber}>{`${connection.data.totalInvoices}`}</span>
                  {`${pluralize(connection.data.totalInvoices > 1, "invoice")} imported.`}
                </Typography>
              </>
            ) : undefined}
          </Grid>
          <Grid xs={12}>
            <div className="flex flex-row mt-4">
              <UncontrolledLoadingButton
                Icon={<Add className={iconStyleWhite} />}
                onClick={() => {
                  setIsApiKeyOpen(true)
                }}
              >
                Add an API key
              </UncontrolledLoadingButton>
              {hasConnections && (
                <>
                  <UncontrolledLoadingButton
                    Icon={<Reset className={iconStyleWhite} />}
                    onClick={async () => {
                      synchronizeRequestFinanceMutation({})
                      toast.open("Updating Request Finance...", { variant: "default" })
                      await new Promise((r) => setTimeout(r, 10000))
                    }}
                    className={classes.marginLeft}
                  >
                    Update
                  </UncontrolledLoadingButton>
                  <ButtonUI
                    onClick={() => setIsListIntegrationsOpen(true)}
                    Icon={<MenuLogs className={iconStyleWhite} />}
                    className={classes.marginLeft}
                  >
                    View connections
                  </ButtonUI>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
