import { Box, createStyles, Grid, makeStyles } from "@material-ui/core"
import React from "react"
import { GetLabelDto, MovementLabelDto, NewInListMovementDto } from "services/api/openapi"
import AddLabelButton from "./AddLabelButton"
import TagLabel from "./TagLabel"
import { mockInternalTransferLabel } from "../../TransactionLabelDrawer/NonTaxabelLabelSelector"
import TagUI from "CryptioUI/Tag"
import { correspondanceHex, TagSize } from "CryptioUI/types/tag"
import { ReactComponent as Help } from "CryptioUI/assets/icons/help-circle.svg"
import LabelIcon from "components/Labels/LabelIcon"
import TooltipUI from "CryptioUI/Tooltip"

const useStyles = makeStyles(() =>
  createStyles({
    labelInternalIcon: { marginLeft: "5px", fontSize: 16 },
  }),
)

export const InternalLabelView = (props: {
  label: GetLabelDto
  isAutomatic: boolean | null
  onClick: () => void
  isSeveralLabel?: boolean
}): JSX.Element => {
  const classes = useStyles()
  const labelColor = props.label.color ? correspondanceHex[props.label.color] : "grey"

  return (
    <>
      <TagUI
        onClick={props.onClick}
        color={labelColor}
        label={props.label.name}
        Icon={<LabelIcon type={props.isAutomatic ? "automatic" : "manual"} color={props.label.color} />}
        size={TagSize.SM}
        className={props.isSeveralLabel ? "max-w-[105px]" : ""}
        ellipsisPosition="end"
      />
      {props.label.isGlobal === true && props.label.name === mockInternalTransferLabel.name && (
        <TooltipUI content="No cost basis is computed on internal transfers (except for the transaction fees associated with the transfers)">
          <Help className={classes.labelInternalIcon} />
        </TooltipUI>
      )}
    </>
  )
}

interface Props {
  labels: MovementLabelDto[]
  onClick: () => void
  mainMovement: NewInListMovementDto | undefined
}

export const LabelsView = ({ labels, onClick, mainMovement }: Props) => {
  const getNumberOfExtraLabels = () => {
    return `+ ${labels.length - 1 + (mainMovement?.isInternalTransfer ? 1 : 0)}`
  }
  if (labels.length === 0 && !mainMovement?.isInternalTransfer) {
    return <AddLabelButton onClick={onClick} />
  }

  const isSeveralLabel = labels.length + (mainMovement?.isInternalTransfer ? 1 : 0) > 1

  if (labels.length + (mainMovement?.isInternalTransfer ? 1 : 0) === 1) {
    return mainMovement?.isInternalTransfer ? (
      <InternalLabelView
        label={mockInternalTransferLabel}
        isAutomatic={mainMovement?.isInternalTransferSystemAttributed}
        onClick={onClick}
      />
    ) : (
      <TagLabel label={labels[0]} onClick={onClick} isSeveralLabel={isSeveralLabel} />
    )
  } else {
    return (
      <Box data-tag="allowRowEvents">
        <Grid justifyContent="center" container spacing={1}>
          <Grid item>
            <Box pt={1} className="flex justify-center items-center">
              {mainMovement?.isInternalTransfer ? (
                <InternalLabelView
                  label={mockInternalTransferLabel}
                  isAutomatic={mainMovement?.isInternalTransferSystemAttributed}
                  onClick={onClick}
                />
              ) : (
                <TagLabel label={labels[0]} onClick={onClick} isSeveralLabel={isSeveralLabel} />
              )}
            </Box>
          </Grid>
          <Grid item>
            <Box py={1} className="flex justify-center items-center">
              <TagUI
                ellipsisPosition="end"
                label={getNumberOfExtraLabels()}
                onClick={onClick}
                size={TagSize.SM}
                className={isSeveralLabel ? "max-w-[105px]" : ""}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default LabelsView
