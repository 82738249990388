import { Box, BoxProps, Divider, FormLabel, FormLabelProps } from "@material-ui/core"
import React from "react"
import { TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"

interface DrawerCategoryProps extends BoxProps {
  title?: string
  tabs?: JSX.Element
}

interface DrawerTabsProps extends BoxProps {
  tabs?: JSX.Element
}

export const DrawerTabs = ({ tabs, mt = 4.5, children, ...props }: DrawerTabsProps): JSX.Element => {
  return (
    <Box {...props} mt={mt}>
      <Box mt={0.5}>{tabs}</Box>
      <Divider />
      {children}
    </Box>
  )
}

export const DrawerCategory = ({ title, mt = 3, children, ...props }: DrawerCategoryProps): JSX.Element => {
  return (
    <Box mt={mt} component="section" {...props} role="presentation">
      <Box mb={1.5}>
        <TypographyUI variant={TypographyVariant.H4}>{title}</TypographyUI>
      </Box>
      <Divider />
      {children}
    </Box>
  )
}

interface DrawerSectionProps extends BoxProps {
  name: JSX.Element | string
  labelGap?: BoxProps["mb"]
}

export const DrawerSection = ({ name, mt = 3, labelGap = 0.5, children, ...props }: DrawerSectionProps) => {
  // TODO: replace FormLabel by typography + color ?
  return (
    <Box mt={mt} component="section" {...props}>
      <Box mb={labelGap} component="h5">
        <FormLabel>{name}</FormLabel>
      </Box>
      {children}
    </Box>
  )
}

export const DrawerFormSection = ({
  name,
  htmlFor,
  mt = 3,
  labelGap = 1.5,
  children,
  ...props
}: DrawerSectionProps & { htmlFor?: FormLabelProps["htmlFor"] }) => {
  return (
    <Box mt={mt} component="section" {...props}>
      <Box mb={labelGap} component="h5">
        <FormLabel htmlFor={htmlFor}>{name}</FormLabel>
      </Box>
      {children}
    </Box>
  )
}
