/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  CreateImpairmentDto,
  GetImpairmentDto,
  PaginatedImpairmentResultDto,
  UpdateImpairmentValuationDto,
} from "../models"

export interface ApplyImpairmentRequest {
  workspaceId: string
  impairmentId: string
}

export interface CreateImpairmentRequest {
  workspaceId: string
  createImpairmentDto: CreateImpairmentDto
}

export interface DeleteImpairmentRequest {
  workspaceId: string
  impairmentId: string
}

export interface GetImpairmentsRequest {
  workspaceId: string
  page: number
  limit: number
  sortBy?: GetImpairmentsSortByEnum
  sortDirection?: GetImpairmentsSortDirectionEnum
  query?: string
  isApplied?: boolean
  excludedIds?: Array<string>
}

export interface GetOneImpairmentRequest {
  workspaceId: string
  impairmentId: string
}

export interface UnapplyImpairmentRequest {
  workspaceId: string
  impairmentId: string
}

export interface UpdateCostBasisImpairmentRequest {
  workspaceId: string
}

export interface UpdateImpairmentValuationRequest {
  workspaceId: string
  impairmentId: string
  updateImpairmentValuationDto: UpdateImpairmentValuationDto
}

/**
 *
 */
export class ImpairmentApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static applyImpairment(params: ApplyImpairmentRequest) {
    return localImpairmentApi.applyImpairment(params)
  }

  /**
   * Apply an impairment
   */
  applyImpairment({ workspaceId, impairmentId }: ApplyImpairmentRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/impairment/${encodeURIComponent(impairmentId)}/apply`,
      undefined,
      true,
    )
  }

  static createImpairment(params: CreateImpairmentRequest) {
    return localImpairmentApi.createImpairment(params)
  }

  /**
   * Create an impairment
   */
  createImpairment({ createImpairmentDto, workspaceId }: CreateImpairmentRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/impairment`,
      createImpairmentDto,
      true,
    )
  }

  static deleteImpairment(params: DeleteImpairmentRequest) {
    return localImpairmentApi.deleteImpairment(params)
  }

  /**
   * Delete one impairment
   */
  deleteImpairment({ workspaceId, impairmentId }: DeleteImpairmentRequest): Promise<void> {
    return this.restInstance.delete(
      `/workspaces/${encodeURIComponent(workspaceId)}/impairment/${encodeURIComponent(impairmentId)}`,
      undefined,
    )
  }

  static getImpairments(params: GetImpairmentsRequest) {
    return localImpairmentApi.getImpairments(params)
  }

  /**
   * Get all impairments
   */
  getImpairments({ workspaceId, ...queryParameters }: GetImpairmentsRequest): Promise<PaginatedImpairmentResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/impairment?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }

  static getOneImpairment(params: GetOneImpairmentRequest) {
    return localImpairmentApi.getOneImpairment(params)
  }

  /**
   * Get one impairment
   */
  getOneImpairment({ workspaceId, impairmentId }: GetOneImpairmentRequest): Promise<GetImpairmentDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/impairment/${encodeURIComponent(impairmentId)}`,
    )
  }

  static unapplyImpairment(params: UnapplyImpairmentRequest) {
    return localImpairmentApi.unapplyImpairment(params)
  }

  /**
   * Unapply an impairment
   */
  unapplyImpairment({ workspaceId, impairmentId }: UnapplyImpairmentRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/impairment/${encodeURIComponent(impairmentId)}/unapply`,
      undefined,
      true,
    )
  }

  static updateCostBasisImpairment(params: UpdateCostBasisImpairmentRequest) {
    return localImpairmentApi.updateCostBasisImpairment(params)
  }

  /**
   * Update cost basis impairment
   */
  updateCostBasisImpairment({ workspaceId }: UpdateCostBasisImpairmentRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/impairment/cost-basis`,
      undefined,
      true,
    )
  }

  static updateImpairmentValuation(params: UpdateImpairmentValuationRequest) {
    return localImpairmentApi.updateImpairmentValuation(params)
  }

  /**
   * Update valuation
   */
  updateImpairmentValuation({
    updateImpairmentValuationDto,
    workspaceId,
    impairmentId,
  }: UpdateImpairmentValuationRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/impairment/${encodeURIComponent(impairmentId)}/valuation`,
      updateImpairmentValuationDto,
      true,
    )
  }
}

const localImpairmentApi = new ImpairmentApi()

/**
 * @export
 * @enum {string}
 */
export type GetImpairmentsSortByEnum = "created_at" | "end_date"
/**
 * @export
 * @enum {string}
 */
export type GetImpairmentsSortDirectionEnum = "ascending" | "descending"
