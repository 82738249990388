import { Box, Link, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import {
  BusinessCenterRounded,
  CachedRounded,
  MoreHorizRounded,
  PublicRounded,
  WarningRounded,
} from "@material-ui/icons"
import React, { useContext } from "react"
import { Link as RouterLink } from "react-router-dom"
import { ReactComponent as AddIcon } from "CryptioUI/assets/icons/add.svg"
import { ReactComponent as Share } from "../../CryptioUI/assets/icons/share.svg"
import { ReactComponent as Delete } from "../../CryptioUI/assets/icons/delete.svg"

import AddressTypography from "components/AddressTypography"
import BaseContainer from "components/Container"
import useBulkSelector from "components/Hooks/useBulkSelector"
import MainTitleView from "components/MainTitleView"
import CopyableText from "components/misc/copyableText"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import useDrawer from "components/misc/useDrawer"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import PermissionReadView from "components/Permission/PermissionReadView"
import { GenericSorting, IExtendedDataTableColumn } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import TableTitleWithTotalCount from "components/Table/TableTitleWithTotalCount"
import ButtonUI from "CryptioUI/Button"
import { iconStyleWhite } from "CryptioUI/Utilities/config"
import { URLS } from "../../routes"
import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import { GetContactDto, GetContactQuery, GetContactsSortByEnum } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import { Mixpanel } from "services/mixpanel"
import { PaginationParameters } from "services/urlParse"
import { pluralize } from "services/utils/textUtils"
import toFilterElement from "services/utils/toFilterElement"
import ContactFilter from "./filters/ContactFilter"
import findReportType from "../../services/utils/reports"
import WarningTypography from "components/WarningTypography"
import TooltipUI from "CryptioUI/Tooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

export interface ContactParameters {
  address: string | undefined
  query: string | undefined
}

export const defaultContactSorting: GenericSorting<GetContactsSortByEnum> = {
  sortDirection: "descending",
  sortBy: "created_at",
}

export const defaultContactParams: ContactParameters = {
  query: undefined,
  address: undefined,
}

const useStyles = makeStyles((theme: Theme) => ({
  noContactText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  linkWithIcon: {
    padding: theme.spacing(1),
  },
  selectMenuItem: {
    "&.Mui-focusVisible": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))

export const contactHasAnyFilter = (filters: ContactParameters) => Boolean(filters.query?.length || filters.address)

const paramsToFilter = (
  params: PaginationParameters & Partial<ContactParameters> & Partial<GenericSorting<GetContactsSortByEnum>>,
): WithoutWorkspaceId<GetContactQuery> => {
  return {
    page: params.page,
    limit: params.limit,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
    query: params.query === undefined || params.query.length === 0 ? undefined : params.query,
    address: params.address,
  }
}

const ContactsScene = (): JSX.Element => {
  const classes = useStyles()

  const { workspace } = useContext(WorkspaceContext)

  const { params, setParams, setLimit, setPage, setSorting } = usePaginatedParams<
    ContactParameters,
    GetContactsSortByEnum
  >(defaultContactParams, defaultContactSorting)
  const contacts = api.contact.useContacts(
    {
      page: params.page,
      limit: params.limit,
      sortBy: params.sortBy,
      sortDirection: params.sortDirection,
      query: params.query,
      address: params.address,
    },
    {
      enabled: workspace.userRights.can_read_contact,
      refetchInterval: 2000,
      notifyOnChangeProps: ["data", "error", "isError", "isLoading", "isFetched"],
    },
  )
  const { mutateAsync: deleteContactMutation } = api.contact.useDeleteContacts()

  const [contactDrawer, openContactDrawer] = useDrawer("contact")
  const viewTitle = "Contacts"
  const viewDescription =
    "Contacts allow you to quickly identify an address, or a group of addresses. Click to know more!"
  const viewHelpURL =
    "https://support.cryptio.co/hc/en-gb/articles/7037996189969-Identifying-managing-and-creating-Contacts"

  const { selectedRows, selectedRowCount, setSelectedRows, bulkSelectorColumn } = useBulkSelector(
    contacts.data,
    (c) => c.id,
  )

  const basicDialog = useDialog()
  const toast = useToast()

  const { mutateAsync: createReportMutation } = api.report.useCreateReports()
  const reportModules = api.report.useReportTypes()

  const exportContacts = async () => {
    try {
      const reportInfos = findReportType(reportModules.data, "audit", "contacts")
      const [transactionFormat] = reportInfos.type.formats

      await createReportMutation({
        createReportDto: {
          reportModule: reportInfos.module.name,
          fileType: "csv",
          allColumns: true,
          exportFormatId: transactionFormat.id,
          startDate: null,
          endDate: null,
          walletIds: [],
          assetIds: [],
          impairmentId: null,
          includeNft: true,
          inputDate: null,
        },
      })
      Mixpanel.track("ReportCreated", {
        reportName: reportInfos.type.name,
        reportFormat: reportInfos.type.formats[0].name,
      })
      toast.open("The report is being generated in your Reports page", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const setParamsWithDialog = (newParams: ContactParameters) => {
    if (selectedRows === undefined) setParams(newParams)
    else {
      basicDialog.showDialog({
        title: "Update filter?",
        content: <Typography variant="h5">If you update the filter you will loose your selection</Typography>,
        yesText: "Yes",
        noText: "Cancel",
        onAccept: () => {
          setSelectedRows(undefined)
          setParams(newParams)
        },
      })
    }
  }

  async function doDeleteSelectedContacts() {
    try {
      await deleteContactMutation({
        deleteContactsDto: {
          filtered:
            selectedRows?.type === "filtered"
              ? {
                  excludedIds: selectedRows.excludedIds,
                  filter: paramsToFilter(params),
                }
              : undefined,
          individuals:
            selectedRows?.type === "individuals"
              ? {
                  contactIds: selectedRows.itemIds,
                }
              : undefined,
        },
      })
      toast.open("Selected contacts deleted", {
        variant: "success",
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  function askDeleteSelectedContacts() {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to delete the selected contacts? This action is irreversible.
          <Box mt={2}>
            <WarningTypography>
              Please note that if a contact is included in label rule and you delete it, the rule will be deleted as
              well (but the labels already applied by the rule will remain). Are you sure you want to delete the
              contacts?
            </WarningTypography>
          </Box>
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doDeleteSelectedContacts,
    })
  }

  if (!workspace.userRights.can_read_contact) return <PermissionReadView viewTitle={viewTitle} itemName="contacts" />
  if (contacts.isError) return <NetworkErrorMessage small={false} additionalData={contacts} />
  if (contacts.isLoading || contacts.data === undefined) return <LoadingSpinner />
  if (reportModules.isError) return <NetworkErrorMessage small={false} additionalData={reportModules} />
  if (reportModules.isLoading || reportModules.data === undefined) return <LoadingSpinner />

  const columns: IExtendedDataTableColumn<GetContactDto, GetContactsSortByEnum>[] = [
    bulkSelectorColumn,
    {
      name: "Name",
      selector: "name",
      sortable: true,
      sortName: "name",
    },
    {
      name: "Addresses",
      selector: "addresses",
      format: function formatContactAddresses(row) {
        return row.addressCount > 1 ? (
          `${row.addressCount} addresses`
        ) : (
          <CopyableText text={row.addresses[0]}>
            <AddressTypography component="span">{row.addresses[0]}</AddressTypography>
          </CopyableText>
        )
      },
    },
    {
      name: "Movements",
      selector: "movementCount",
      center: true,
      sortable: true,
      sortName: "movement_count",
      format: function formatContactAddresses(row) {
        switch (row.status) {
          case "active":
            return (
              <TooltipUI content="Calculating">
                <CachedRounded data-test-id="pending" />
              </TooltipUI>
            )
          case "pending":
            return (
              <TooltipUI content="Pending">
                <MoreHorizRounded data-test-id="pending" />
              </TooltipUI>
            )
          case "error":
            return (
              <TooltipUI content="Error">
                <Typography>
                  <WarningRounded />
                </Typography>
              </TooltipUI>
            )
          case "inactive":
          default:
            if (row.movementCount === 0) return "0"
            return (
              <Link
                className={classes.linkWithIcon}
                component={RouterLink}
                to={URLS.Transactions.getUrl({
                  filters: [{ type: "contacts", value: [toFilterElement(row)], isNot: false }],
                })}
                underline="always"
              >
                {row.movementCount}
              </Link>
            )
        }
      },
    },
    {
      name: "Global",
      selector: "isGlobal",
      center: true,
      sortable: false,
      format: function formatContactAddresses(row) {
        return row.isGlobal ? (
          <TooltipUI content="This contact is global">
            <PublicRounded data-tag="allowRowEvents" />
          </TooltipUI>
        ) : (
          "-"
        )
      },
    },
  ]

  const addContactButton = (
    <PermissionDisabled permission="can_create_contact" action="create contacts">
      <ButtonUI onClick={() => openContactDrawer(null)} Icon={<AddIcon className={iconStyleWhite} />}>
        Contact
      </ButtonUI>
    </PermissionDisabled>
  )

  if (contacts.data.totalCount === 0 && params.page > 1) {
    // I'm doing a set a setTimeout to avoid a warning to be triggered
    setTimeout(() => {
      setPage(1)
    }, 0)
    return <LoadingSpinner />
  }

  const hasAnyFilter = contactHasAnyFilter(params)

  const Header = () => {
    return (
      <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {hasAnyFilter ? (
          <>
            <Typography className={classes.noContactText} variant="h4">
              There are no contacts that match the selected filters.
              <br />
              Click the following buttons to reset or update the filters.
            </Typography>
            <Box display="flex" flexDirection="row">
              <ContactFilter setFilter={setParams} filter={params} />
            </Box>
          </>
        ) : (
          <>
            <BusinessCenterRounded fontSize="large" />
            <Typography className={classes.noContactText} variant="h4">
              This contact list is empty.
              <br />
              Click below to add contacts.
            </Typography>
            <Box display="flex" flexDirection="row">
              {addContactButton}
            </Box>
          </>
        )}
      </Box>
    )
  }

  return (
    <BaseContainer>
      {contactDrawer}
      {basicDialog.dialog}
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="flex-end">
        {contacts.data.totalCount > 0 ? (
          <TableTitleWithTotalCount
            onClick={() => Mixpanel.track("ContactsInfo")}
            title={viewTitle}
            qualifier="contact"
            totalCount={contacts.data.totalCount}
            selectedCount={selectedRowCount}
            helpTooltipContent={viewDescription}
            helpTooltipUrl={viewHelpURL}
          />
        ) : (
          <MainTitleView
            onClick={() => Mixpanel.track("ContactsInfo")}
            title={viewTitle}
            helpTooltipContent={viewDescription}
            helpTooltipUrl={viewHelpURL}
          />
        )}

        <Box display="flex" alignItems="flex-end">
          {selectedRowCount > 0 && (
            <Box>
              <PermissionDisabled permission="can_remove_contact" action="delete selected contacts">
                <ButtonUI onClick={askDeleteSelectedContacts} Icon={<Delete className={iconStyleWhite} />}>
                  Delete {selectedRowCount} {pluralize(selectedRowCount > 1, "contact")}
                </ButtonUI>
              </PermissionDisabled>
            </Box>
          )}
          <Box ml={2}>
            <ContactFilter setFilter={setParamsWithDialog} filter={params} />
          </Box>
          <Box ml={2}>{addContactButton}</Box>
          <Box ml={2}>
            <PermissionDisabled permission="can_create_report" action="create contacts report">
              <ButtonUI onClick={exportContacts} Icon={<Share className={iconStyleWhite} />}>
                Export
              </ButtonUI>
            </PermissionDisabled>
          </Box>
        </Box>
      </Box>
      {contacts.data.totalCount === 0 ? (
        <Header />
      ) : (
        <Paper>
          <ServerSideTable<GetContactDto, GetContactsSortByEnum>
            columns={columns}
            items={contacts.data.data}
            limit={contacts.data.limit}
            totalCount={contacts.data.totalCount}
            defaultParams={params}
            onPageChange={setPage}
            onLimitChange={setLimit}
            onSort={setSorting}
            onRowClicked={(row) => openContactDrawer(row.id)}
          />
        </Paper>
      )}
    </BaseContainer>
  )
}

export default ContactsScene
