import { useMutation } from "react-query"

import { queryClient } from "../../.."
import { useWorkspaceMutation, useWorkspaceQuery } from "../../context/workspaceContext"
import { AccountingApi } from "../openapi"
import { GET_CHART_ACCOUNT_MAPPING_KEY, GET_CHART_ACCOUNT_MAPPINGS_KEY, GET_CHART_ACCOUNTS_KEY } from "./chartAccount"
import { GET_TRANSACTION_KEY, GET_TRANSACTIONS_KEY } from "./transactions"
import { GET_USER_KEY } from "./user"
import { GET_WORKSPACE_METRICS_KEY, GET_WORKSPACES_OR_DEFAULT_KEY } from "./workspace"

export const GET_INTEGRATIONS_KEY = "getIntegrations"
export const GET_INTEGRATION_STATUS_KEY = "getIntegrationStatus"

export const accountingActions = {
  getOAuthRedirectUrl: AccountingApi.getOAuthRedirectUrl,
  getRegisterOAuthRedirectUrl: AccountingApi.getRegisterOAuthRedirectUrl,
  getLoginOAuthRedirectUrl: AccountingApi.getLoginOAuthRedirectUrl,
  useLoginWithXero: () => {
    return useMutation(AccountingApi.loginWithXeroIntegration, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_USER_KEY)
      },
    })
  },
  useRegisterWithXero: () => {
    return useMutation(AccountingApi.registerWithXeroIntegration, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_USER_KEY)
      },
    })
  },
  useConnectToOAuth2Integration: () => {
    return useMutation(AccountingApi.connectToOauth2Integration, {
      onSuccess: () => {
        // If already connected, it updates the coa

        // same as useCreateChartAccounts
        queryClient.invalidateQueries(GET_CHART_ACCOUNTS_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPINGS_KEY)
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPING_KEY)
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
      },
    })
  },
  useConnectToTbaIntegration: () => {
    return useWorkspaceMutation(AccountingApi.connectToTbaIntegration, {
      onSuccess: () => {
        // If already connected, it updates the coa

        // same as useCreateChartAccounts
        queryClient.invalidateQueries(GET_CHART_ACCOUNTS_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPINGS_KEY)
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPING_KEY)
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
      },
    })
  },
  useDisconnectFromIntegration: () => {
    return useWorkspaceMutation(AccountingApi.disconnectFromIntegration, {
      onSuccess: () => {
        // Delete COA
        queryClient.invalidateQueries(GET_CHART_ACCOUNTS_KEY)

        // Delete integration
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)

        // Delete mappings
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPINGS_KEY)
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPING_KEY)

        // Remove transaction_accounting
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
      },
    })
  },
  useUpdateExternalCoaIntegration: () => {
    return useWorkspaceMutation(AccountingApi.updateExternalCoaIntegration, {
      onSuccess: () => {
        // Add / remove COA
        queryClient.invalidateQueries(GET_CHART_ACCOUNTS_KEY)

        // TODO: Can make the coa dirty ?
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)

        // Can update mapping if some accounts removed
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPINGS_KEY)
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPING_KEY)

        // remove accounts from transaction_accounting
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
      },
    })
  },
  useIntegrations: () => {
    return useWorkspaceQuery([GET_INTEGRATIONS_KEY], AccountingApi.getCOAIntegrations, {})
  },
  useCheckStatus: (enabled: boolean) => {
    return useWorkspaceQuery(
      [GET_INTEGRATION_STATUS_KEY],
      AccountingApi.checkConnectionStatus,
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
        },
        enabled,
      },
    )
  },
}
