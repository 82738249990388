import { Box, makeStyles, Theme, Typography, TypographyProps } from "@material-ui/core"
import { HelpRounded } from "@material-ui/icons"
import TooltipUI from "CryptioUI/Tooltip"
import React from "react"

interface Props extends Pick<TypographyProps, "variant"> {
  title: React.ReactNode
  helpTooltipContent?: string
  helpTooltipUrl?: string
  onClick?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontWeight: 400,
    fontSize: "2.5rem",
  },
  tooltipIcon: {
    marginLeft: theme.spacing(1.5),
  },
  helpButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  linkNoDecoration: {
    "color": theme.palette.primary.main,
    "paddingTop": theme.spacing(0.75),
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}))

const MainTitleView = ({ title, variant = "h1", helpTooltipContent, helpTooltipUrl, onClick }: Props) => {
  const classes = useStyles()

  return (
    <Box className={classes.outerContainer}>
      <Typography className={classes.title} variant={variant}>
        {title}
      </Typography>
      {helpTooltipContent !== undefined && (
        <TooltipUI content={<Typography>{helpTooltipContent}</Typography>} classNameTrigger="ml-4">
          {helpTooltipUrl ? (
            <a
              onClick={() => onClick && onClick()}
              className={classes.linkNoDecoration}
              target="_blank"
              rel="noopener noreferrer"
              href={helpTooltipUrl}
            >
              <HelpRounded className={helpTooltipUrl ? classes.helpButton : ""} />
            </a>
          ) : (
            <HelpRounded className={helpTooltipUrl ? classes.helpButton : ""} />
          )}
        </TooltipUI>
      )}
    </Box>
  )
}

export default MainTitleView
