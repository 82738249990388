import { useTypedController } from "@hookform/strictly-typed"
import { Box } from "@material-ui/core"
import dayjs from "dayjs"
import React from "react"
import { ReportFormComponentProps } from "."
import SimpleAutocompleteFactory from "components/Autocomplete/SimpleAutocompleteFactory"
import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { DrawerReportForm } from "../ReportForm"
import _ from "lodash"

const consensysDateYearAutocompleteMap: Record<string, string> = Object.fromEntries(
  _.range(2018, 2030).map((year) => [year.toString(), year.toString()]),
)

const consensysDateMonthAutocompleteMap: Record<string, string> = {
  "1": "01",
  "2": "02",
  "3": "03",
  "4": "04",
  "5": "05",
  "6": "06",
  "7": "07",
  "8": "08",
  "9": "09",
  "10": "10",
  "11": "11",
  "12": "12",
}

const ConsensysDateMonthAutocomplete = SimpleAutocompleteFactory(consensysDateMonthAutocompleteMap)
const ConsensysDateYearAutocomplete = SimpleAutocompleteFactory(consensysDateYearAutocompleteMap)

export type ReportConsensysDateFormValue = {
  year: number | null
  month: number | null
}

const ReportConsensysDate = ({ control }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })
  return (
    <TypedController
      name="consensysDate"
      rules={{ required: true, validate: (t) => Boolean(t && t.year && t.month) }}
      defaultValue={{ year: null, month: null }}
      render={({ value, onChange }) => (
        <Box>
          <DrawerFormSection name="Year">
            <ConsensysDateYearAutocomplete
              value={value?.year?.toString() || null}
              filterOptions={(options) => options.filter((option) => Number(option) <= dayjs().year())}
              onChange={(v) => {
                const newYear = v ? Number(v) : null
                onChange({
                  year: newYear,
                  month:
                    value?.month && newYear === dayjs().year() && value.month >= dayjs().get("month") + 1
                      ? null
                      : value?.month || null,
                })
              }}
            />
          </DrawerFormSection>
          <DrawerFormSection name="Month">
            <ConsensysDateMonthAutocomplete
              filterOptions={(opt) =>
                value?.year === dayjs().year() ? opt.filter((o) => Number(o) < dayjs().get("month") + 1) : opt
              }
              value={value?.month?.toString() || null}
              onChange={(v) => onChange({ year: value?.year || null, month: v ? Number(v) : null })}
            />
          </DrawerFormSection>
        </Box>
      )}
    />
  )
}

export default ReportConsensysDate
