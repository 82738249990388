import { Include, ObjectTypedKeys } from "."

export enum CostBasisErrorStatus {
  missing_usd_rate = "missing_usd_rate",
  negative_balance = "negative_balance",
  missing_fiat_rate = "missing_fiat_rate",
  impacted_by_missing_price = "impacted_by_missing_price",
}

export type CostBasisErrorStatusType = keyof typeof CostBasisErrorStatus
export const prettyCostBasisErrorStatusMap: Record<CostBasisErrorStatusType, string> = {
  negative_balance: "Missing volume",
  missing_usd_rate: "Missing price",
  missing_fiat_rate: "Missing fiat rate",
  impacted_by_missing_price: "Previous missing price",
}

export type TransactionTaxLine = {
  mappedTransactionId: string
  consumedTransactionId: string
  transactionDate: string
  consumedVolume: string
  remainingVolume: string
  usdRate: string | null
  usdRateToDefaultCurrency: string | null
}

export type TransactionAccountingSyncStatusType = "unsynced" | "pending" | "syncing" | "synced" | "error"
export type TransactionMovementType = "incoming" | "outgoing" | "fee"

interface TransactionMovement {
  transactionId: string

  errorStatuses: CostBasisErrorStatusType[] | null

  // Balance after the transaction
  balance: string
  walletBalance: string

  // Sum of all acquisition cost of unsold
  unsoldTotalCostUsd: string
  unsoldTotalCostFiat: string

  // Depends of cost basis algorithm
  // Cost basis of the transaction
  costBasisUsd: string | null
  costBasisFiat: string | null

  // only make sense for wac workspace
  wacPerUnitUsd: string | null
  wacPerUnitFiat: string | null
  borrowWacPerUnitUsd: string | null
  borrowWacPerUnitFiat: string | null
}

export interface TransactionIncomingMovement extends TransactionMovement {
  // "0" if none
  repayedVolume: string

  borrowCostBasisUsd: string | null
  borrowCostBasisFiat: string | null

  // inverse of gain
  borrowGainUsd: string | null
  borrowGainFiat: string | null
}

export interface TransactionOutgoingMovement extends TransactionMovement {
  borrowedVolume: string

  // difference of transaction value and cost basis (how much I bought it and how much I sold it => gain or loss)
  gainUsd: string | null
  gainFiat: string | null
}

export type TransactionMovementWithTaxLines<T extends TransactionMovement> = T & {
  // Full detail of the cost basis (tracking)
  taxLines: TransactionTaxLine[]
}

export interface TransactionVolumeData {
  asset: string | null
  assetId: string
  volume: string
  usdRate: string | null
  originalUsdRate: string | null
}

export enum GlobalLabel {
  // Special labels
  marginsBorrow = "Margins borrow",
  nonTaxableEvent = "Non taxable event",
  wrap = "Wrap",
  unwrap = "Unwrap",

  additionalKrakenFee = "Additional Kraken Fee", // can still be applied to the additional kraken fee but does that make sense ?
  borrow = "Borrow",
  borrowInterest = "Borrow Interest",
  contractCall = "Contract Call",
  contractCreation = "Contract Creation",
  debt = "Debt",
  lendingInterest = "Lending Interest",
  marginFee = "Margin Fee",
  marginTrade = "Margin Trade",
  repay = "Repay",
  stakingRewards = "Staking Rewards",
  supply = "Supply",
  swap = "Swap",
  staking = "Staking",
  airdrop = "Airdrop",
  rebase = "Rebase",
  rebate = "Rebate",
  generatedTransfer = "Generated Transfer",

  // NFT
  nft = "NFT",
  nftSell = "NFT Sell",
  nftPurchase = "NFT Purchase",
  nftMint = "NFT Mint",

  // Futures
  insuranceFee = "Insurance Fee",
  futuresPnl = "Futures PNL",
  futuresFee = "Futures Fee",

  // Near
  nearMultisend = "Near Multisend",
  nearLockup = "Near Lockup",
  nearCreateAccount = "Near Create Account",

  // Filecoin
  filecoinMinerFee = "Filecoin Miner Fee",
  filecoinBurnFee = "Filecoin Burn Fee",
  filecoinOverestimationBurn = "Filecoin Overestimation Burn",
  filecoinReward = "Filecoin Reward",
}

export interface TransactionExplorer {
  displayableName: string
  sourceName: SourceNamesType
  getLink: (txHash: string) => string
}

export interface NFTExplorer {
  displayableName: string
  sourceName: SourceNamesType
  getLink: (address: string, tokenId: string) => string
}

export interface PriceData {
  price: string
}

export type OtherPartyAliasType = "contact" | "global_contact" | "wallet"

export interface OtherPartyAlias {
  address: string
  alias: {
    id: string
    name: string
    type: OtherPartyAliasType
  } | null
}

// /!\ keys must match values to use the keyof keyword of typescript
export enum ChartAccountTypeEnum {
  asset = "asset",
  liability = "liability",
  income = "income",
  expense = "expense",
  equity = "equity",
}
export type ChartAccountType = keyof typeof ChartAccountTypeEnum

export interface ChartAccountModel {
  id: string
  externalIdentifier: string | null
  name: string
  code: string | null
  type: ChartAccountType
}
export interface IndividualChartAccountModel {
  account: ChartAccountModel
  assetId: string
}

export enum WalletApiKeySecurityTypeEnum {
  none = "none",
  normal = "normal",
  advanced = "advanced",
}
export type WalletApiKeySecurityType = keyof typeof WalletApiKeySecurityTypeEnum

export enum AutomatedMappingTypeEnum {
  default_account_mapping = "default_account_mapping",
  wallet_mapping = "wallet_mapping",
  exchange_mapping = "exchange_mapping",
  label_mapping = "label_mapping",
}
export type AutomatedMappingType = keyof typeof AutomatedMappingTypeEnum

// Reflect name of accounting integrations in db
export enum AccountingIntegrationNameEnum {
  xero = "xero",
  quickbooks = "quickbooks",
  custom = "custom",
  netsuite = "netsuite",
}
export type AccountingIntegrationNameType = keyof typeof AccountingIntegrationNameEnum
export const prettyAccountingIntegrationNameMap: Record<AccountingIntegrationNameEnum, string> = {
  xero: "Xero",
  quickbooks: "QuickBooks",
  custom: "Custom",
  netsuite: "NetSuite",
}
export const accountingIntegrationNameOptions = ObjectTypedKeys(AccountingIntegrationNameEnum)

export enum AccountingIntegrationCredentialEnum {
  oauth2 = "oauth2",
  custom = "custom",
  tba = "tba",
}

// Reflect name of business integrations in db
export const BusinessIntegrationNameArray = ["request_network", "fireblocks"] as const
export type BusinessIntegrationNameType = typeof BusinessIntegrationNameArray[number]
export const prettyBusinessIntegrationNameMap: Record<BusinessIntegrationNameType, string> = {
  request_network: "Request Network",
  fireblocks: "Fireblocks",
}
export const businessIntegrationNameOptions = Object.keys(BusinessIntegrationNameArray) as BusinessIntegrationNameType[]

export enum AssetTypeEnum {
  fiat = "fiat",
  exchange_unknown = "exchange_unknown",
  token = "token",
  main_chain = "main_chain",
}
export type AssetType = keyof typeof AssetTypeEnum

export enum OAuthRedirectUrlActionTypeEnum {
  connect = "connect",
  login = "login",
  register = "register",
}
export type OAuthRedirectUrlActionType = keyof typeof OAuthRedirectUrlActionTypeEnum
export const oauthRedirectUrlActionOptions = ObjectTypedKeys(OAuthRedirectUrlActionTypeEnum)

export enum AccountCreatedViaTypeEnum {
  cryptio = "cryptio",
  xero = "xero",
}
export type AccountCreatedViaType = keyof typeof AccountCreatedViaTypeEnum

export const prettyAccountCreatedViaMap: Record<AccountCreatedViaType, string> = {
  xero: "Xero",
  cryptio: "Cryptio",
}

// Names of blockchains on which we index tokens
export const BLOCKCHAIN_NAMES = [
  "Arbitrum",
  "Avalanche",
  "Bitcoin",
  "Binance Smart Chain",
  "Cardano",
  "Ethereum",
  "Fantom",
  "Filecoin",
  "Litecoin",
  "Near",
  "Optimism",
  "Polkadot",
  "Polygon",
  "Solana",
  "Tezos",
  "Zilliqa",
  "Linea",
  "zkSync",
  "Base",
] as const

export type BlockchainNameType = typeof BLOCKCHAIN_NAMES[number]

export enum WalletImportErrorEnum {
  invalidCredentials = "invalidCredentials",
  krakenAPIKeyAlreadyUsed = "krakenAPIKeyAlreadyUsed",
  permissionDenied = "permissionDenied",
  otherError = "otherError",
  csvWrongFormat = "csvWrongFormat",
  csvWrongFormatWithReport = "csvWrongFormatWithReport",
  csvEmpty = "csvEmpty",
  csvErrorWithReport = "csvErrorWithReport",
  notImported = "notImported",
}

export type WalletImportErrorType = keyof typeof WalletImportErrorEnum

export class WalletImportError extends Error {
  constructor(public errorType: WalletImportErrorType, details?: string) {
    super(`Wallet import error: ${errorType}: ${details || "no details"}`)

    Object.setPrototypeOf(this, WalletImportError.prototype)
  }
}

export class CsvImportError extends WalletImportError {
  constructor(errorType: WalletImportErrorType, public errorData?: object[]) {
    super(errorType, errorData ? "Invalid CSV. See error report." : "Invalid CSV.")

    Object.setPrototypeOf(this, CsvImportError.prototype)
  }
}

export const ColorHex: { [key: string]: string } = {
  "gray": "#94939F",
  "orange": "#EAAB00",
  "purple": "#4F57C8",
  "red": "#FA4739",
  "green": "#2FDC77",
  "blue": "#2FAEDC",
  "dark blue": "#363650",
  "yellow": "#FAFA39",
  "pink": "#93024D",
}

export const ColorNames: string[] = Object.keys(ColorHex)

export enum AssetProviderEnum {
  kaiko = "kaiko",
  coin_gecko = "coin_gecko",
  coin_market_cap = "coin_market_cap",
}
export type AssetProviderType = keyof typeof AssetProviderEnum

export const assetProviderList = ObjectTypedKeys(AssetProviderEnum)

export enum WorkspaceStatusEnum {
  ready = "ready",
  duplicating = "duplicating",
  error = "error",
}
export type WorkspaceStatusType = keyof typeof WorkspaceStatusEnum

export const ChainSourceNamesArray = [
  "Arbitrum",
  "Avalanche",
  "Binance Smart Chain (Legacy)",
  "Binance Smart Chain",
  "Bitcoin",
  "Cardano",
  "Ethereum",
  "Fantom",
  "Filecoin",
  "Litecoin",
  "Near",
  "Optimism",
  "Optimism (Legacy)",
  "Polygon",
  "Polygon (Legacy)",
  "Tezos",
  "Zilliqa",
  "Linea",
  "zkSync",
  "Base",
] as const
export type ChainSourceNamesType = typeof ChainSourceNamesArray[number]

// Note: Only used to make sure that all the blockchain list is complete
export const ChainToBlockchainMap: Record<ChainSourceNamesType, BlockchainNameType> = {
  "Arbitrum": "Arbitrum",
  "Avalanche": "Avalanche",
  "Binance Smart Chain (Legacy)": "Binance Smart Chain",
  "Binance Smart Chain": "Binance Smart Chain",
  "Bitcoin": "Bitcoin",
  "Cardano": "Cardano",
  "Ethereum": "Ethereum",
  "Fantom": "Fantom",
  "Filecoin": "Filecoin",
  "Litecoin": "Litecoin",
  "Near": "Near",
  "Optimism": "Optimism",
  "Optimism (Legacy)": "Optimism",
  "Polygon": "Polygon",
  "Polygon (Legacy)": "Polygon",
  "Tezos": "Tezos",
  "Zilliqa": "Zilliqa",
  "Linea": "Linea",
  "zkSync": "zkSync",
  "Base": "Base",
}

export const ExchangeSourceNamesArray = [
  "Anchorage",
  "Binance",
  "Binance US",
  "Binance File",
  "BitGo",
  "Bitcoin Suisse",
  "Bitfinex",
  "Bitstamp",
  "Bittrex",
  "Coinbase Commerce",
  "Coinbase Prime",
  "Coinbase Pro",
  "Coinbase",
  "Deribit",
  "FTX (OTC)",
  "FTX US",
  "FTX",
  "Gate.io",
  "Gemini",
  "HitBTC",
  "HitBTC (Legacy)",
  "Huobi",
  "Kraken",
  // "Liquid",
  "Fireblocks",
  "Nexo",
  // "Poloniex Futures",
  // "Poloniex",
  "Spot",
  "Kucoin",
  "Deribit",
] as const
export type ExchangeSourceNamesType = typeof ExchangeSourceNamesArray[number]

export const CustomSourceNamesArray = ["Custom", "Custom API"] as const
export type CustomSourceNamesType = typeof CustomSourceNamesArray[number]

export const SourceNamesArray = [
  ...ChainSourceNamesArray,
  ...ExchangeSourceNamesArray,
  ...CustomSourceNamesArray,
] as const

export type SourceNamesType = typeof SourceNamesArray[number]

// FIXME: How to keep this in sync with the available sources ? Not all of them
// should be accessible or documented through the API
export const APISourceNamesEnum = [
  // "Arbitrum",
  "Anchorage",
  "Binance",
  "Binance Smart Chain",
  "Binance Smart Chain (Legacy)",
  "BitGo",
  "Bitcoin",
  "Bitfinex",
  "Bittrex",
  "Cardano",
  "Coinbase",
  "Coinbase Commerce",
  "Coinbase Prime",
  "Coinbase Pro",
  "Custom API",
  "Ethereum",
  "FTX",
  "FTX (OTC)",
  "FTX US",
  "Filecoin",
  "Fireblocks",
  "Gate.io",
  "Gemini",
  "HitBTC",
  "HitBTC (Legacy)",
  "Kraken",
  "Optimism",
  "Near",
  "Polygon",
  "Polygon (Legacy)",
  "Tezos",
  "Zilliqa",
  "Linea",
  "zkSync",
] as const

export type APISourceNamesType = Include<typeof APISourceNamesEnum[number], SourceNamesType>

export const CostBasisAlgorithmNameArray = [
  "fifo-universal",
  "fifo-per-wallet",
  "wac-perpetual-universal",
  "wac-perpetual-per-wallet",
  "lifo-universal",
  "lifo-per-wallet",
  "hifo-universal",
  "hifo-per-wallet",

  // Custom
  "fifo-consensys",
  "fifo-defi-saver",
] as const
export type CostBasisAlgorithmNameType = typeof CostBasisAlgorithmNameArray[number]

export const isWacMap: Record<CostBasisAlgorithmNameType, boolean> = {
  "fifo-universal": false,
  "fifo-per-wallet": false,
  "lifo-universal": false,
  "lifo-per-wallet": false,
  "hifo-universal": false,
  "hifo-per-wallet": false,
  "wac-perpetual-universal": true,
  "wac-perpetual-per-wallet": true,

  // Custom
  "fifo-consensys": false,
  "fifo-defi-saver": false,
}

// Transaction type
export const TransactionTypeArray = [
  "simple_fee",
  "simple_movement",
  "simple_trade",
  "complex_unbookable",
  "complex_bookable",
] as const
export type TransactionType = typeof TransactionTypeArray[number]

export const ImpairmentMethodologyArray = ["lowest-price", "last-price"]
export type ImpairmentMethodologyType = typeof ImpairmentMethodologyArray[number]

export enum TransactionComplexity {
  simple = "simple",
  complex = "complex",
}

const TransactionComplexityMapping: Record<TransactionType, TransactionComplexity> = {
  simple_fee: TransactionComplexity.simple,
  simple_movement: TransactionComplexity.simple,
  simple_trade: TransactionComplexity.simple,
  complex_unbookable: TransactionComplexity.complex,
  complex_bookable: TransactionComplexity.complex,
}

export const getTransactionComplexity = (transactionType: TransactionType): TransactionComplexity =>
  TransactionComplexityMapping[transactionType]

export enum UserRoleEnum {
  normal = "normal",
  tech_administrator = "tech_administrator",
  business_administrator = "business_administrator",
  reporter = "reporter",
}

export enum WalletTypeKindEnum {
  chain = "chain",
  exchange = "exchange",
  custody = "custody",
  custom = "custom",
}

export const WalletKindsWalletMapping = [WalletTypeKindEnum.chain, WalletTypeKindEnum.custom]
export const WalletKindsExchangeMapping = [
  WalletTypeKindEnum.exchange,
  WalletTypeKindEnum.custody,
  WalletTypeKindEnum.custom,
]
