import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import CopyableText from "components/misc/copyableText"
import { TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import { HttpErrorDetailsDto, UnknownErrorDetailsDto, WalletSourceErrorDetailsDto } from "services/api/openapi"

export interface ErrorDetailsProps {
  errorDetails: WalletSourceErrorDetailsDto[]
}

function OneErrorDetail({ details }: { details: HttpErrorDetailsDto | UnknownErrorDetailsDto | null }) {
  if (details === null) {
    return <TypographyUI variant={TypographyVariant.BODY}>No details</TypographyUI>
  }
  const copyText = JSON.stringify(details)

  if (details.type === "http") {
    return (
      <>
        <TypographyUI variant={TypographyVariant.BODY}>{details.error}</TypographyUI>
        <CopyableText noTypography={true} text={copyText}>
          <pre style={{ whiteSpace: "normal", wordBreak: "break-all" }}>{JSON.stringify(details.body)}</pre>
        </CopyableText>
      </>
    )
  } else {
    return (
      <>
        <TypographyUI variant={TypographyVariant.BODY}>Unknown error type</TypographyUI>
        <CopyableText noTypography={true} text={copyText}>
          <TypographyUI variant={TypographyVariant.BODY}>Error: {details.error}</TypographyUI>
        </CopyableText>
      </>
    )
  }
}

export function ErrorDetails({ errorDetails }: ErrorDetailsProps) {
  return (
    <DrawerCategory title="Import error details">
      <DrawerSection name="Main source transaction import error">
        <OneErrorDetail details={errorDetails[0].transactionImportError} />
      </DrawerSection>
      <DrawerSection name="Main source balance import error">
        <OneErrorDetail details={errorDetails[0].balanceImportError} />
      </DrawerSection>
    </DrawerCategory>
  )
}
