import { Chip, TextField } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import React, { useMemo, useState } from "react"

import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import useFilterDrawer from "components/Hooks/useFilterDrawer"
import InputClearable from "components/Input/InputClearable"
import InputNumber from "components/Input/InputNumber"
import DrawerUI from "CryptioUI/Drawer"
import { GetWalletTypeDto } from "services/api/openapi"
import isNumberStringValid from "services/utils/isNumberStringValid"
import { defaultImportParams, ImportParameters, walletHasAnyFilter } from "../index"
import TooltipUI from "CryptioUI/Tooltip"

interface Props {
  filter: ImportParameters
  setFilter: (newValue: ImportParameters) => void
  sources: GetWalletTypeDto[]
}

const WalletFilter = (props: Props): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const { OpenButton, SaveButton, tempFilter, updateFilter } = useFilterDrawer({
    ...props,
    defaultParams: defaultImportParams,
    setIsDrawerOpen,
  })

  const filterCount = useMemo(() => {
    const filter = props.filter
    let res = 0
    if (filter.query !== undefined && filter.query.length > 0) res++
    if (filter.minimumTransactions !== undefined) res++
    if (filter.typeNames !== undefined && filter.typeNames.length > 0) res++
    if (filter.walletsAddress !== undefined && filter.walletsAddress.length > 0) res++
    return res
  }, [props.filter])

  const canClear = useMemo(() => {
    return filterCount > 0 || walletHasAnyFilter(tempFilter)
  }, [filterCount, tempFilter])

  const findSource = () => {
    if (props.filter.typeNames === undefined) {
      return []
    } else {
      return props.sources.filter((x) => props.filter.typeNames?.indexOf(x.name) !== -1)
    }
  }

  return (
    <>
      <OpenButton filterCount={filterCount} />

      <DrawerUI title="Source Filter" onClose={() => setIsDrawerOpen(false)} isOpen={isDrawerOpen}>
        <DrawerCategory title="Filter">
          <DrawerFormSection htmlFor="search-name-textfield" name="Search by name">
            <InputClearable
              id="search-name-textfield"
              value={tempFilter.query || ""}
              onChange={(value) => {
                if (value.length === 0) {
                  updateFilter({
                    query: undefined,
                  })
                }
                updateFilter({
                  query: value,
                })
              }}
              placeholder="Name"
              fullWidth
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="search-address-select" name="Search by source addresses">
            <Autocomplete
              multiple
              id="search-address-select"
              defaultValue={tempFilter.walletsAddress || undefined}
              freeSolo
              size="small"
              renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip size="small" variant="default" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TooltipUI content="Input a source address and press Enter. Multiple source address can be filtered at once.">
                  <TextField
                    {...params}
                    placeholder={
                      tempFilter.walletsAddress && tempFilter.walletsAddress.length > 0 ? "" : "No source address"
                    }
                  />
                </TooltipUI>
              )}
              onChange={(_, value: string[]) => {
                if (value.length === 0) {
                  updateFilter({
                    walletsAddress: undefined,
                  })
                }
                updateFilter({
                  walletsAddress: value,
                })
              }}
              options={[]}
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="minimum-transaction-textfield" name="Minimum transactions count">
            <InputNumber
              id="minimum-transaction-textfield"
              value={tempFilter.minimumTransactions ?? ""}
              onChange={(value) => {
                updateFilter({
                  minimumTransactions: value,
                })
              }}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="search-source-select" name="Type">
            <Autocomplete
              id="search-source-select"
              defaultValue={findSource()}
              multiple
              fullWidth
              onChange={(_, newValue) => {
                updateFilter({
                  typeNames: newValue.map((x) => x.name),
                })
              }}
              size="small"
              options={props.sources}
              getOptionLabel={(option: GetWalletTypeDto) => option.name}
              renderInput={(params) => <TextField {...params} placeholder="Type to search..." />}
            />
          </DrawerFormSection>

          <SaveButton
            filterCount={filterCount}
            canClear={canClear}
            canSave={
              tempFilter.minimumTransactions === undefined || isNumberStringValid(tempFilter.minimumTransactions)
            }
          />
        </DrawerCategory>
      </DrawerUI>
    </>
  )
}

export default WalletFilter
