import { UseQueryOptions } from "react-query"

import { queryClient } from "../../.."
import { usePaginatedWorkspaceQuery, useWorkspaceMutation, useWorkspaceQuery } from "../../context/workspaceContext"
import { WithoutWorkspaceId } from "../aliases"
import {
  ContactsApi,
  GetContactDto,
  GetContactsRequest,
  GetUnknownContactsRequest,
  PaginatedContactResultDto,
} from "../openapi"
import { GET_TRANSACTION_KEY, GET_TRANSACTIONS_KEY, GET_MOVEMENT_KEY } from "./transactions"
import { GET_WORKSPACE_METRICS_KEY, GET_WORKSPACES_OR_DEFAULT_KEY } from "./workspace"

export const GET_CONTACTS_KEY = "getContacts"
export const GET_CONTACT_KEY = "getContact"
export const GET_UNKNOWN_CONTACTS_KEY = "getUnknownContacts"

export const contactActions = {
  useContacts: (
    variables: WithoutWorkspaceId<GetContactsRequest>,
    options?: UseQueryOptions<PaginatedContactResultDto>,
  ) => {
    return usePaginatedWorkspaceQuery([GET_CONTACTS_KEY, variables], ContactsApi.getContacts, variables, options)
  },
  useUnknownContacts: (variables: GetUnknownContactsRequest["getUnknownContactQuery"]) => {
    return usePaginatedWorkspaceQuery([GET_UNKNOWN_CONTACTS_KEY, variables], ContactsApi.getUnknownContacts, {
      getUnknownContactQuery: variables,
    })
  },
  useCreateContacts: () => {
    return useWorkspaceMutation(ContactsApi.createContacts, {
      onSuccess: () => {
        // Less unknown contact
        queryClient.invalidateQueries(GET_UNKNOWN_CONTACTS_KEY)

        // Update From/To
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)

        // Update From/To
        queryClient.invalidateQueries(GET_MOVEMENT_KEY)

        // New contact
        queryClient.invalidateQueries(GET_CONTACTS_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
      },
    })
  },
  useUpdateContacts: () => {
    return useWorkspaceMutation(ContactsApi.updateContact, {
      onSuccess: () => {
        // Update contacts
        queryClient.invalidateQueries(GET_CONTACTS_KEY)
        queryClient.invalidateQueries(GET_CONTACT_KEY)

        // More or less unknown
        queryClient.invalidateQueries(GET_UNKNOWN_CONTACTS_KEY)

        // Update From/To
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)

        // Update From/To
        queryClient.invalidateQueries(GET_MOVEMENT_KEY)

        // TODO: find the reason why we invalidate this query here
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useBatchUpdateContacts: () => {
    return useWorkspaceMutation(ContactsApi.batchUpdateContact, {
      onSuccess: () => {
        // Update contacts
        queryClient.invalidateQueries(GET_CONTACTS_KEY)
        queryClient.invalidateQueries(GET_CONTACT_KEY)

        // More or less unknown
        queryClient.invalidateQueries(GET_UNKNOWN_CONTACTS_KEY)

        // Update From/To
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)

        // Update From/To
        queryClient.invalidateQueries(GET_MOVEMENT_KEY)

        // TODO: find the reason why we invalidate this query here
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useDeleteContacts: () => {
    return useWorkspaceMutation(ContactsApi.deleteContacts, {
      onSuccess: () => {
        // Deleting a contact
        queryClient.invalidateQueries(GET_CONTACTS_KEY)

        // More or less unknown
        queryClient.invalidateQueries(GET_UNKNOWN_CONTACTS_KEY)

        // Update From/To
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)

        // Update From/To
        queryClient.invalidateQueries(GET_MOVEMENT_KEY)

        // TODO: find the reason why we invalidate this query here
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)

        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
      },
    })
  },
  useContact: (contactId: string | undefined | null, option?: UseQueryOptions<GetContactDto>) => {
    return useWorkspaceQuery(
      [GET_CONTACT_KEY, contactId],
      ContactsApi.getContact,
      { contactId: contactId ?? "" },
      {
        enabled: !!contactId,
        ...option,
        refetchInterval: 1000,
      },
    )
  },
}
