import React from "react"
import { MovementLabelDto } from "services/api/openapi"
import TagUI from "CryptioUI/Tag"
import { correspondanceHex, TagSize } from "CryptioUI/types/tag"
import LabelIcon from "components/Labels/LabelIcon"

export interface TagLabelProps {
  label: MovementLabelDto
  onClick: () => void
  isSeveralLabel?: boolean
}

const TagLabel = ({ label, onClick, isSeveralLabel = false }: TagLabelProps): JSX.Element => {
  const labelIcon = <LabelIcon type={label.isAutomatic ? "automatic" : "manual"} color={label.color} />

  return (
    <TagUI
      ellipsisPosition="end"
      onClick={onClick}
      color={correspondanceHex[label.color] ? correspondanceHex[label.color] : "cream"}
      label={label.name}
      iconPosition="left"
      Icon={labelIcon}
      className={isSeveralLabel ? "max-w-[105px]" : ""}
      size={TagSize.SM}
    />
  )
}

export default TagLabel
