import React, { useCallback, useContext } from "react"

import { WarningAdminFeature } from "components/Feature/WarningAdminFeature"

import { BoldWeight, Mode, TypographyVariant } from "CryptioUI/types"
import CardUI from "CryptioUI/Card"
import TypographyUI from "CryptioUI/Typography"
import { GetReportModuleDto, GetReportTypeDto, ReportTypeFormatDto } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { StarBorderRounded, StarRounded } from "@material-ui/icons"
import api from "services/api"
import ButtonUI from "CryptioUI/Button"
import { iconStyleGold } from "CryptioUI/Utilities/config"
import { FeatureGate } from "components/Feature/FeatureGate"
import TooltipUI from "CryptioUI/Tooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface ReportTypesFormProps {
  savedModule: GetReportModuleDto | null
  reportType: GetReportTypeDto | null
  setReportType: (newReportType: GetReportTypeDto | null) => void
  setReportFormat: (value: React.SetStateAction<ReportTypeFormatDto | null>) => void
  setReturnAction: ((newAction: (() => void) | undefined) => void) | undefined
  setSavedModule: (value: React.SetStateAction<GetReportModuleDto | null>) => void
  setValue: () => void
  setTitle?: (newTitle: string) => void
}

const ReportTypesForm = (props: ReportTypesFormProps) => {
  const { workspace } = useContext(WorkspaceContext)

  const {
    savedModule,
    reportType,
    setReportType,
    setReportFormat,
    setReturnAction,
    setSavedModule,
    setValue,
    setTitle,
  } = props

  const newReturnAction = (savedModule: GetReportModuleDto) => {
    if (setReturnAction !== undefined) {
      setReturnAction(() => () => {
        setReportType(null)
        if (setTitle !== undefined) setTitle(savedModule.displayName)
        setReturnAction(() => () => {
          setSavedModule(null)
        })
      })
    }
  }

  const onReportCardClick = (type: GetReportTypeDto, savedModule: GetReportModuleDto) => {
    if (!type) return setReportType(null)
    setReportType(type)
    setReportFormat(type.formats.length === 1 ? type.formats[0] : null)
    if (setTitle !== undefined) setTitle(type.displayName)
    if (type.name === "chainlink_node_operator") setValue()
    newReturnAction(savedModule)
  }
  const { mutateAsync: addFavoriteReport } = api.report.useAddFavoriteReport()
  const { mutateAsync: deleteFavoriteReport } = api.report.useDeleteFavoriteReport()
  const toast = useToast()

  const onClick = useCallback(
    async (report: GetReportTypeDto) => {
      try {
        // react-query is so fast that the isFavorite is updated between the api call and the open toast..
        const isFavorite = report.isFavorite
        if (isFavorite) {
          await deleteFavoriteReport({ deleteFavoriteReportDto: { type: report.name, reportModule: report.module } })
        } else {
          await addFavoriteReport({ addFavoriteReportDto: { type: report.name, reportModule: report.module } })
        }
        toast.open(`Report ${report.displayName} ${isFavorite ? "removed from" : "added to"} favorites`, {
          variant: "success",
        })
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [deleteFavoriteReport, addFavoriteReport, toast],
  )

  const accountingReportTypes = ["ledger", "summarized_ledger", "journal_sequential_numbering"]

  return (
    <>
      {savedModule !== null && !reportType && (
        <div className="flex flex-col justify-center items-center gap-y-6 mt-6">
          {savedModule.types.map((type) => {
            return (
              (savedModule.name !== "favorite" || type.isFavorite) && (
                <WarningAdminFeature
                  isAdminFeature={
                    type !== null &&
                    type.formats.length !== 0 &&
                    type.formats.every((f) => f.isFeatureEnabledOnlyForAdmin)
                  }
                >
                  <CardUI
                    fullWidth={true}
                    className="w-full flex flex-row justify-start items-start"
                    disabled={workspace.accountingIntegration ? undefined : accountingReportTypes.includes(type.name)}
                    onClick={() => onReportCardClick(type, savedModule)}
                  >
                    <div className="flex flex-col p-6 w-full">
                      <div className="flex flex-row items-center justify-between w-full">
                        <TypographyUI
                          variant={TypographyVariant.BODY}
                          boldness={BoldWeight.MEDIUM}
                          className="flex justify-start items-start text-justify"
                        >
                          {type.displayName}
                        </TypographyUI>
                        <div className="w-fit">
                          <FeatureGate feature="favorite_report">
                            <TooltipUI content="Set this report as favorite">
                              <ButtonUI
                                onClick={async (e) => {
                                  e.stopPropagation()
                                  onClick(type)
                                  type.isFavorite = !type.isFavorite
                                }}
                                mode={Mode.TEXTONLY}
                                className="h-0 px-0"
                              >
                                {type.isFavorite ? <StarRounded className={iconStyleGold} /> : <StarBorderRounded />}
                              </ButtonUI>
                            </TooltipUI>
                          </FeatureGate>
                        </div>
                      </div>
                      <TypographyUI
                        variant={TypographyVariant.CAPTION}
                        boldness={BoldWeight.REGULAR}
                        className="flex justify-start items-start text-justify text-grey-400 whitespace-normal pr-8"
                      >
                        {type.description}
                      </TypographyUI>
                    </div>
                  </CardUI>
                </WarningAdminFeature>
              )
            )
          })}
        </div>
      )}
    </>
  )
}

export default ReportTypesForm
