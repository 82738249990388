import React from "react"
import { twMerge } from "tailwind-merge"

import { ButtonUIProps, Mode, ButtonSize, ModeType, SizeType, TypographyVariant, BoldWeight } from "CryptioUI/types"
import IconPositionWrapper from "CryptioUI/Utilities/IconPositionWrapper"
import TypographyUI from "CryptioUI/Typography"

export const modeStyle: ModeType = {
  [Mode.DEFAULT]:
    "bg-primary-400 rounded-xl text-white text-md hover:bg-primary-300 focus:outline focus:outline-4 focus:outline-primary-100 active:bg-primary-500 active:outline active:outline-4 active:outline-offset-0",
  [Mode.CONTAINED]:
    "bg-grey-200 rounded-xl text-grey-500 text-md hover:bg-grey-300 focus:outline focus:outline-4 focus:outline-primary-100 active:bg-grey-400 active:text-white active:outline active:outline-4 active:outline-offset-0",
  [Mode.OUTLINED]:
    "box-border bg-white rounded-xl text-grey-400 border border-grey-200 text-md hover:bg-grey-300 hover:border-grey-300 focus:outline focus:outline-4 focus:outline-primary-100 active:bg-grey-400 active:border-grey-400 active:text-white active:outline active:outline-4 active:outline-offset-0",
  [Mode.DESTRUCTIVE]:
    "bg-grey-200 rounded-xl text-danger-300 text-md hover:bg-grey-300 focus:outline focus:outline-4 focus:outline-primary-100 active:bg-grey-400 active:text-danger-200 active:outline active:outline-4 active:outline-offset-0",
  [Mode.OUTLINEDHIGHLIGHT]:
    "box-border rounded-xl text-primary-400 border border-primary-400 text-md hover:border-primary-300 hover:text-primary-300 focus:outline focus:outline-4 focus:outline-primary-100 active:border-primary-500 active:text-primary-500 active:outline active:outline-4 active:outline-offset-0",
  [Mode.TEXTONLY]:
    "text-primary-400 border-none hover:text-black focus:rounded-xl focus:outline focus:outline-4 outline-none active:text-primary-500 active:rounded-xl active:outline active:outline-4 disabled:text-grey-300",
  [Mode.SHADOWED]:
    "text-primary-400 border-2 border-white shadow-[inset_0_-2px_0px_0px_#E9FAFB] hover:text-black focus:shadow-none focus:rounded-xl focus:outline focus:outline-4 focus:outline-primary-100 active:text-primary-500 active:shadow-none active:rounded-xl active:outline-primary-500 active:outline-4 disabled:text-grey-300 disabled:shadow-none",
}
export const sizeStyle: SizeType = {
  [ButtonSize.SM]: "py-1 ",
  [ButtonSize.MD]: "py-2 ",
  [ButtonSize.LG]: "py-3 ",
}

const ButtonUI = (props: ButtonUIProps): JSX.Element => {
  const {
    type = "button",
    size = ButtonSize.LG,
    Icon,
    onClick,
    children,
    disabled,
    className = "",
    classNameContainer = "",
    iconPosition = "left",
    mode = Mode.DEFAULT,
    id = "",
    fullWidth = false,
    classNameIconWrapper = "",
    form,
    ...rest
  } = props

  const widthStyle = fullWidth ? "w-full" : ""
  const commonStyle = "px-4 flex justify-center items-center h-[35px]"
  const genericDisabledStyle =
    "disabled:bg-grey-200 disabled:border-none disabled:text-grey-300 disabled:pointer-events-auto"
  const genericStyle = twMerge([widthStyle, commonStyle, modeStyle[mode], sizeStyle[size]].join(" "))
  const finalStyle =
    mode !== Mode.TEXTONLY && mode !== Mode.SHADOWED
      ? twMerge([genericStyle, genericDisabledStyle, className].join(" "))
      : twMerge([genericStyle, className].join(" "))
  const wrapperDisabledEventStyle = [widthStyle, genericDisabledStyle, classNameContainer].join(" ")
  const finalClassNameIconWrapper = [widthStyle, classNameIconWrapper].join(" ")

  return (
    <div className={wrapperDisabledEventStyle}>
      <button
        form={form}
        id={id}
        type={type}
        className={finalStyle}
        disabled={disabled}
        onClick={onClick}
        role="button"
        {...rest}
      >
        <IconPositionWrapper
          className={finalClassNameIconWrapper}
          disabled={disabled}
          Icon={Icon}
          iconOnly={!children}
          iconPosition={iconPosition}
          iconSpacing={8}
        >
          {children && (
            <TypographyUI ellipsisPosition="end" variant={TypographyVariant.BUTTON} boldness={BoldWeight.MEDIUM}>
              <>{children}</>
            </TypographyUI>
          )}
        </IconPositionWrapper>
      </button>
    </div>
  )
}

export default ButtonUI
