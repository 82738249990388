import { UseQueryOptions } from "react-query"

import { usePaginatedWorkspaceQuery, useWorkspaceQuery } from "../../context/workspaceContext"
import { WithoutWorkspaceId } from "../aliases"
import {
  BalanceApi,
  GetOverviewBalancesRequest,
  GetUnrealizedGainBalancesRequest,
  PaginatedOverviewBalanceResultDto,
  PaginatedUnrealizedGainBalanceResultDto,
} from "../openapi"

export const GET_OVERVIEW_BALANCE_KEY = "getOverviewBalances"
export const GET_GLOBAL_BALANCE_KEY = "getGlobalBalance"
export const GET_UNREALIZED_BALANCE_KEY = "getUnrealizedBalance"

export const balanceActions = {
  useOverviewBalances: (
    variables: WithoutWorkspaceId<GetOverviewBalancesRequest>,
    config?: UseQueryOptions<PaginatedOverviewBalanceResultDto>,
  ) => {
    return usePaginatedWorkspaceQuery(
      [GET_OVERVIEW_BALANCE_KEY, variables],
      BalanceApi.getOverviewBalances,
      variables,
      config,
    )
  },
  useUnrealizedBalances: (
    variables: WithoutWorkspaceId<GetUnrealizedGainBalancesRequest>,
    config?: UseQueryOptions<PaginatedUnrealizedGainBalanceResultDto>,
  ) => {
    return usePaginatedWorkspaceQuery(
      [GET_UNREALIZED_BALANCE_KEY, variables],
      BalanceApi.getUnrealizedGainBalances,
      variables,
      config,
    )
  },
  useGlobalBalance: () => {
    return useWorkspaceQuery([GET_GLOBAL_BALANCE_KEY], BalanceApi.getGlobalBalances, {})
  },
}
