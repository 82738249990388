import React from "react"
import { Box, Link } from "@material-ui/core"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { LightGrey, PrimaryColor } from "../../../materialTheme"

import useGDPRConsent from "services/context/gdprContext"

const useStyles = makeStyles(() =>
  createStyles({
    footer: {
      position: "relative",
      bottom: "-3rem",
      height: "3rem",
      lineHeight: "39px",
      borderTop: `1px solid ${LightGrey}`,
      textAlign: "right",
      paddingRight: "7em",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flexDirection: "row",
    },
    bubble: {
      backgroundColor: PrimaryColor,
      height: "0.48rem",
      width: "0.48rem",
      borderRadius: "100%",
    },
  }),
)

export default function Footer() {
  const classes = useStyles()
  const { openConsentDialog } = useGDPRConsent()

  const Separator = () => (
    <Box component="span" ml={1.2} mr={1.2}>
      <div className={classes.bubble} />
    </Box>
  )

  return (
    <Box className={classes.footer}>
      <Link component="a" variant="body2" href="https://cryptio.co/privacy">
        Privacy Policy
      </Link>
      <Separator />
      <Link component="a" variant="body2" href="https://cryptio.co/terms">
        Terms &amp; Conditions
      </Link>
      <Separator />
      <Link component="button" variant="body2" onClick={openConsentDialog}>
        Privacy Settings
      </Link>
    </Box>
  )
}
