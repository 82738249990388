import React from "react"

import { TransactionParams } from ".."
import { Box } from "@material-ui/core"
import { ReactComponent as Share } from "CryptioUI/assets/icons/share.svg"
import api from "services/api"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import LoadingSpinner from "components/misc/LoadingSpinner"
import { UncontrolledLoadingButton } from "components/LoadingButton"
import { NewInListTransactionDto } from "services/api/openapi"
import { Mixpanel } from "services/mixpanel"
import findReportType from "services/utils/reports"
import { useToast } from "CryptioUI/Toaster"
import { iconStyleWhite } from "CryptioUI/Utilities/config"
import { toastCatch } from "components/ReactHookForm/utils"

interface Props {
  filter: TransactionParams
  transactions: NewInListTransactionDto[]
}

const ExportFilter = (props: Props): JSX.Element => {
  const { filter, transactions } = props
  const { mutateAsync: createReportMutation } = api.report.useCreateReports()
  const reportModules = api.report.useReportTypes()
  const toast = useToast()

  const exportTransaction = async () => {
    try {
      const reportInfos = findReportType(reportModules.data, "consensys", "consensys_transactions")
      const [transactionFormat] = reportInfos.type.formats

      await createReportMutation({
        createReportDto: {
          reportModule: reportInfos.module.name,
          filters: filter.filters,
          fileType: "csv",
          allColumns: true,
          exportFormatId: transactionFormat.id,
          startDate: null,
          endDate: null,
          walletIds: [],
          assetIds: [],
          impairmentId: null,
          includeNft: true,
          inputDate: null,
        },
      })
      Mixpanel.track("CreateFilteredTransactions", {
        reportModuleName: reportInfos.module.name,
        transactionFormatId: transactionFormat.id,
        filters: filter.filters,
      })
      toast.open("The report is being generated in your Reports page", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  if (reportModules.isError) return <NetworkErrorMessage small={false} additionalData={reportModules} />
  if (reportModules.isLoading || reportModules.data === undefined) return <LoadingSpinner />

  return (
    <>
      {filter.filters.length > 0 && transactions.length > 0 && (
        <Box mr={2}>
          <UncontrolledLoadingButton onClick={exportTransaction} Icon={<Share className={iconStyleWhite} />}>
            Export filtered transactions
          </UncontrolledLoadingButton>
        </Box>
      )}
    </>
  )
}

export default ExportFilter
