import React from "react"
import { DrawerProp } from "../../../../components/misc/useDrawer"
import { GetLabelDto } from "../../../../services/api/openapi"
import Creation from "./Creation"
import Edition from "./Edition"

const LabelForm = (props: DrawerProp<GetLabelDto, true>) => {
  const { item: label } = props
  const isCreateMode = label === null

  return <>{isCreateMode ? <Creation {...props} /> : <Edition {...props} />}</>
}

export default LabelForm
