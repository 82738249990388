/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  ConnectToTbaIntegrationDto,
  GetAccountingIntegrationsDto,
  GetConnectionStatusDto,
  GetOAuthRedirectUrlDto,
} from "../models"

export interface CheckConnectionStatusRequest {
  workspaceId: string
}

export interface ConnectToOauth2IntegrationRequest {
  integrationName: ConnectToOauth2IntegrationIntegrationNameEnum
  error?: string
  state?: string
  code?: string
}

export interface ConnectToTbaIntegrationRequest {
  workspaceId: string
  integrationName: string
  connectToTbaIntegrationDto: ConnectToTbaIntegrationDto
}

export interface DisconnectFromIntegrationRequest {
  workspaceId: string
}

export interface GetCOAIntegrationsRequest {
  workspaceId: string
}

export interface GetLoginOAuthRedirectUrlRequest {
  integration: GetLoginOAuthRedirectUrlIntegrationEnum
}

export interface GetOAuthRedirectUrlRequest {
  workspaceId: string
  integrationName: string
}

export interface GetRegisterOAuthRedirectUrlRequest {
  integration: GetRegisterOAuthRedirectUrlIntegrationEnum
}

export interface LoginWithXeroIntegrationRequest {
  scope?: string
  code?: string
}

export interface RegisterWithXeroIntegrationRequest {
  state?: string
  scope?: string
  code?: string
}

export interface UpdateExternalCoaIntegrationRequest {
  workspaceId: string
}

/**
 *
 */
export class AccountingApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static checkConnectionStatus(params: CheckConnectionStatusRequest) {
    return localAccountingApi.checkConnectionStatus(params)
  }

  /**
   * Check connection status
   */
  checkConnectionStatus({ workspaceId }: CheckConnectionStatusRequest): Promise<GetConnectionStatusDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/accounting/integration/status`)
  }

  static connectToOauth2Integration(params: ConnectToOauth2IntegrationRequest) {
    return localAccountingApi.connectToOauth2Integration(params)
  }

  /**
   * Connect to integration
   */
  connectToOauth2Integration({
    integrationName,
    ...queryParameters
  }: ConnectToOauth2IntegrationRequest): Promise<void> {
    return this.restInstance.post(
      `/accounting/integration/${encodeURIComponent(integrationName)}?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
      undefined,
      true,
    )
  }

  static connectToTbaIntegration(params: ConnectToTbaIntegrationRequest) {
    return localAccountingApi.connectToTbaIntegration(params)
  }

  /**
   * Connect to token based integration
   */
  connectToTbaIntegration({
    connectToTbaIntegrationDto,
    workspaceId,
    integrationName,
  }: ConnectToTbaIntegrationRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/accounting/tba/integration/${encodeURIComponent(
        integrationName,
      )}`,
      connectToTbaIntegrationDto,
      true,
    )
  }

  static disconnectFromIntegration(params: DisconnectFromIntegrationRequest) {
    return localAccountingApi.disconnectFromIntegration(params)
  }

  /**
   * Disconnect from COA integration
   */
  disconnectFromIntegration({ workspaceId }: DisconnectFromIntegrationRequest): Promise<void> {
    return this.restInstance.delete(`/workspaces/${encodeURIComponent(workspaceId)}/accounting/integration`, undefined)
  }

  static getCOAIntegrations(params: GetCOAIntegrationsRequest) {
    return localAccountingApi.getCOAIntegrations(params)
  }

  /**
   * Get COA integrations
   */
  getCOAIntegrations({ workspaceId }: GetCOAIntegrationsRequest): Promise<Array<GetAccountingIntegrationsDto>> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/accounting/integration`)
  }

  static getLoginOAuthRedirectUrl(params: GetLoginOAuthRedirectUrlRequest) {
    return localAccountingApi.getLoginOAuthRedirectUrl(params)
  }

  /**
   * Get url to redirect user (oauth)
   */
  getLoginOAuthRedirectUrl({ integration }: GetLoginOAuthRedirectUrlRequest): Promise<GetOAuthRedirectUrlDto> {
    return this.restInstance.get(`/accounting/integration/${encodeURIComponent(integration)}/redirect/login`)
  }

  static getOAuthRedirectUrl(params: GetOAuthRedirectUrlRequest) {
    return localAccountingApi.getOAuthRedirectUrl(params)
  }

  /**
   * Get url to redirect user (oauth)
   */
  getOAuthRedirectUrl({ workspaceId, integrationName }: GetOAuthRedirectUrlRequest): Promise<GetOAuthRedirectUrlDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/accounting/integrationName/${encodeURIComponent(
        integrationName,
      )}/redirect`,
    )
  }

  static getRegisterOAuthRedirectUrl(params: GetRegisterOAuthRedirectUrlRequest) {
    return localAccountingApi.getRegisterOAuthRedirectUrl(params)
  }

  /**
   * Get url to redirect user (oauth)
   */
  getRegisterOAuthRedirectUrl({ integration }: GetRegisterOAuthRedirectUrlRequest): Promise<GetOAuthRedirectUrlDto> {
    return this.restInstance.post(
      `/accounting/integration/${encodeURIComponent(integration)}/redirect/register`,
      undefined,
    )
  }

  static loginWithXeroIntegration(params: LoginWithXeroIntegrationRequest) {
    return localAccountingApi.loginWithXeroIntegration(params)
  }

  /**
   * Connect to integration
   */
  loginWithXeroIntegration({ ...queryParameters }: LoginWithXeroIntegrationRequest): Promise<void> {
    return this.restInstance.post(
      `/accounting/login/Xero?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
      undefined,
      true,
    )
  }

  static registerWithXeroIntegration(params: RegisterWithXeroIntegrationRequest) {
    return localAccountingApi.registerWithXeroIntegration(params)
  }

  /**
   * Connect to integration
   */
  registerWithXeroIntegration({ ...queryParameters }: RegisterWithXeroIntegrationRequest): Promise<void> {
    return this.restInstance.post(
      `/accounting/register/Xero?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
      undefined,
      true,
    )
  }

  static updateExternalCoaIntegration(params: UpdateExternalCoaIntegrationRequest) {
    return localAccountingApi.updateExternalCoaIntegration(params)
  }

  /**
   * Update coa
   */
  updateExternalCoaIntegration({ workspaceId }: UpdateExternalCoaIntegrationRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/accounting/integration`,
      undefined,
      true,
    )
  }
}

const localAccountingApi = new AccountingApi()

/**
 * @export
 * @enum {string}
 */
export type ConnectToOauth2IntegrationIntegrationNameEnum = "xero" | "quickbooks" | "custom" | "netsuite"
/**
 * @export
 * @enum {string}
 */
export type GetLoginOAuthRedirectUrlIntegrationEnum = "xero" | "quickbooks" | "custom" | "netsuite"
/**
 * @export
 * @enum {string}
 */
export type GetRegisterOAuthRedirectUrlIntegrationEnum = "xero" | "quickbooks" | "custom" | "netsuite"
