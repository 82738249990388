import { CachedRounded, WarningRounded } from "@material-ui/icons"
import BigNumber from "bignumber.js"
import { prettyCostBasisErrorStatusMap } from "pure-shared"
import React, { useContext } from "react"
import { CostBasisIds } from "../.."
import TypographyAllowEvent from "components/TypographyAllowEvent"
import ButtonUI from "CryptioUI/Button"
import { ButtonSize, Mode, TypographyVariant } from "CryptioUI/types"
import { NewInListMovementDto } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { CustomCellDirection } from "../../GeneratedTable/useGenerateCell"

import CostBasisView from "./CostBasisView"
import TooltipUI from "CryptioUI/Tooltip"
import TypographyUI from "CryptioUI/Typography"

interface Props {
  isComplex: boolean
  incomingMovement: NewInListMovementDto | undefined
  outgoingMovement: NewInListMovementDto | undefined
  feeMovement: NewInListMovementDto | undefined
  setCostBasisDrawerTransactionId: (ids: CostBasisIds | null) => void
  direction: CustomCellDirection
}

const CostBasis = (props: Props) => {
  const { workspace } = useContext(WorkspaceContext)
  const { isComplex, incomingMovement, outgoingMovement, feeMovement, setCostBasisDrawerTransactionId, direction } =
    props

  if (isComplex) {
    return <TypographyAllowEvent variant={TypographyVariant.BODY}>-</TypographyAllowEvent>
  }
  if (workspace.costBasisStatus.status !== "inactive") {
    return <CachedRounded />
  }
  if (workspace.isCostBasisDirty) {
    return (
      <TooltipUI
        content={
          <TypographyAllowEvent variant={TypographyVariant.BODY}>
            Some modifications on pricing have been made. Please update the cost basis.
          </TypographyAllowEvent>
        }
      >
        <WarningRounded titleAccess="warning" />
      </TooltipUI>
    )
  }

  // TODO: display something that explains that the cost basis will run eventually ?
  // This could be fixed by hiding importing transaction (running job)
  if (
    (incomingMovement && !incomingMovement.costBasis) ||
    (outgoingMovement && !outgoingMovement.costBasis) ||
    (feeMovement && !feeMovement.costBasis)
  ) {
    return <TypographyAllowEvent variant={TypographyVariant.BODY}>Unprocessed</TypographyAllowEvent>
  }

  const mainCostBasisError =
    outgoingMovement?.costBasis?.mainCostBasisError ||
    feeMovement?.costBasis?.mainCostBasisError ||
    incomingMovement?.costBasis?.mainCostBasisError
  if (mainCostBasisError) {
    return (
      <ButtonUI
        size={ButtonSize.MD}
        mode={Mode.TEXTONLY}
        className="p-0 hover:underline text-warning-300 hover:text-warning-400"
        onClick={() =>
          setCostBasisDrawerTransactionId({
            feeMovementId: feeMovement?.id,
            outgoingMovementId: outgoingMovement?.id,
            incomingMovementId: incomingMovement?.id,
          })
        }
        //         mode={Mode.TEXTONLY}
      >
        {/* <TypographyAllowEvent variant={TypographyVariant.BODY} className="text-warning-300"> */}
        {prettyCostBasisErrorStatusMap[mainCostBasisError]}
        {/* </TypographyAllowEvent> */}
      </ButtonUI>
    )
  }
  if (incomingMovement?.isInternalTransfer === true || outgoingMovement?.isInternalTransfer === true) {
    return (
      <ButtonUI
        size={ButtonSize.MD}
        className="p-0 hover:underline hover:text-grey-400"
        onClick={() =>
          setCostBasisDrawerTransactionId({
            feeMovementId: feeMovement?.id,
            outgoingMovementId: outgoingMovement?.id,
            incomingMovementId: incomingMovement?.id,
          })
        }
        mode={Mode.TEXTONLY}
      >
        <TypographyUI variant={TypographyVariant.BODY}>Internal</TypographyUI>
      </ButtonUI>
    )
  }

  // NTE cost basis
  if (incomingMovement?.costBasis?.isNte || outgoingMovement?.costBasis?.isNte) {
    return (
      <ButtonUI
        size={ButtonSize.MD}
        className="p-0 hover:underline hover:text-grey-400"
        onClick={() =>
          setCostBasisDrawerTransactionId({
            feeMovementId: feeMovement?.id,
            outgoingMovementId: outgoingMovement?.id,
            incomingMovementId: incomingMovement?.id,
          })
        }
        mode={Mode.TEXTONLY}
      >
        <TypographyUI variant={TypographyVariant.BODY}>Non taxable event</TypographyUI>
      </ButtonUI>
    )
  }

  // NTE without cost basis
  if (incomingMovement?.labels.some((l) => l.isNonTaxable) || outgoingMovement?.labels.some((l) => l.isNonTaxable)) {
    return (
      <ButtonUI
        size={ButtonSize.MD}
        className="p-0 hover:underline hover:text-grey-400"
        onClick={() =>
          setCostBasisDrawerTransactionId({
            feeMovementId: feeMovement?.id,
            outgoingMovementId: outgoingMovement?.id,
            incomingMovementId: incomingMovement?.id,
          })
        }
        mode={Mode.TEXTONLY}
      >
        <TypographyUI variant={TypographyVariant.BODY}>Non taxable event</TypographyUI>
      </ButtonUI>
    )
  }

  // todo: borrow cost basis modal
  // if (incomingMovement.costBasis.isRepay) {
  //   return (
  //     <ButtonUI
  //       className={classes.costBasisButton}
  //       onClick={() => setCostBasisDrawerTransactionId({ feeMovementId: feeMovement?.id, outgoingMovementId: outgoingMovement?.id, incomingMovementId: incomingMovement?.id })}
  //               mode={Mode.TEXTONLY}
  //     >
  //       <TypographyAllowEvent variant={TypographyVariant.BODY}>Repay</TypographyAllowEvent>
  //     </ButtonUI>
  //   )
  // }
  // if (incomingMovement.costBasis.isBorrow) {
  //   return (
  //     <ButtonUI
  //       className={classes.costBasisButton}
  //       onClick={() => setCostBasisDrawerTransactionId({ feeMovementId: feeMovement?.id, outgoingMovementId: outgoingMovement?.id, incomingMovementId: incomingMovement?.id })}
  //               mode={Mode.TEXTONLY}
  //     >
  //       <TypographyAllowEvent variant={TypographyVariant.BODY}>Borrow</TypographyAllowEvent>
  //     </ButtonUI>
  //   )
  // }

  if (direction === CustomCellDirection.IN) {
    return (
      <ButtonUI
        size={ButtonSize.MD}
        className="p-0 hover:underline hover:text-grey-400"
        onClick={() =>
          setCostBasisDrawerTransactionId({
            feeMovementId: feeMovement?.id,
            outgoingMovementId: outgoingMovement?.id,
            incomingMovementId: incomingMovement?.id,
          })
        }
        mode={Mode.TEXTONLY}
      >
        <TypographyUI variant={TypographyVariant.BODY}>Deposit</TypographyUI>
      </ButtonUI>
    )
  }

  const costBasisSum =
    outgoingMovement?.costBasis?.costBasisFiat && feeMovement?.costBasis?.costBasisFiat
      ? new BigNumber(outgoingMovement.costBasis.costBasisFiat).plus(feeMovement.costBasis.costBasisFiat).toString()
      : outgoingMovement?.costBasis?.costBasisFiat || feeMovement?.costBasis?.costBasisFiat
  return (
    <ButtonUI
      size={ButtonSize.MD}
      className="p-0"
      onClick={() =>
        setCostBasisDrawerTransactionId({
          feeMovementId: feeMovement?.id,
          outgoingMovementId: outgoingMovement?.id,
          incomingMovementId: incomingMovement?.id,
        })
      }
      mode={Mode.TEXTONLY}
    >
      <CostBasisView extended costBasisFiat={costBasisSum ?? null} isButton />
    </ButtonUI>
  )
}

export default CostBasis
