import BigNumber from "bignumber.js"
import numbro from "numbro"
import { prettyEmptyOrNull } from "pure-shared"
import React, { useContext } from "react"

import { getSymbol } from "services/utils/AssetSymbols"
import { WorkspaceContext } from "services/context/workspaceContext"
import { prettyNumber } from "services/utils/processNumber"
import useDrawer from "components/misc/useDrawer"
import TypographyAllowEvent from "components/TypographyAllowEvent"
import TooltipUI from "CryptioUI/Tooltip"
import TypographyUI from "CryptioUI/Typography"
import { Mode, TypographyVariant } from "CryptioUI/types"
import ButtonUI from "CryptioUI/Button"

interface Props {
  volume: string
  assetToUsdRate: string | null
  direction: string
  isFee: boolean
  assetSymbol: string
  assetId: string
  nftId?: null | string
  usdToFiatRate: string | null
  alignLeft?: boolean
  fullNumber?: boolean
}

export const AssetView = (props: Props): JSX.Element => {
  const [assetDrawer, openAssetDrawer] = useDrawer("asset")
  const {
    volume,
    assetToUsdRate,
    direction,
    isFee,
    assetSymbol,
    assetId,
    nftId = null,
    usdToFiatRate,
    alignLeft = false,
    fullNumber = false,
  } = props

  const { workspace } = useContext(WorkspaceContext)

  let waySymbol = undefined
  if (direction === "in") waySymbol = "+"
  else if (direction === "out") waySymbol = "-"

  let wayColor = undefined
  if (direction === "in") wayColor = "text-label-green-200"
  else if (direction === "out") wayColor = "text-label-red-200"
  if (isFee) wayColor = "text-grey-500"

  let wayColorHover = undefined
  if (direction === "in") wayColorHover = "hover:text-label-green-300"
  else if (direction === "out") wayColorHover = "hover:text-label-red-300"
  if (isFee) wayColorHover = "hover:text-grey-400"

  const volumeProcessed = new BigNumber(volume)
  const usdRateProcessed = assetToUsdRate ? new BigNumber(assetToUsdRate) : null
  const amountInUsd = usdRateProcessed && volumeProcessed.multipliedBy(usdRateProcessed)
  const amountInUserFiatProcessed = usdToFiatRate ? new BigNumber(usdToFiatRate) : null
  const amountInUserFiat =
    amountInUsd && amountInUserFiatProcessed && amountInUsd.multipliedBy(amountInUserFiatProcessed)
  const userFiatSymbol = getSymbol(workspace.defaultCurrencySymbol)

  const numbroConfig = {
    average: volumeProcessed.gt(1000) ? true : false,
    mantissa: volumeProcessed.gt(1000) ? 1 : 4,
    trimMantissa: true,
  }

  const assetAmountFormat = (() => {
    if (nftId) {
      if (nftId.length < 8) return <>{nftId}</>
      else return <>{nftId.slice(0, 8)}&hellip;</>
    }

    const fullNumberValue = volumeProcessed.decimalPlaces(11, BigNumber.ROUND_UP).toString()
    const numberWithLessDecimals = numbro(volumeProcessed.decimalPlaces(4, BigNumber.ROUND_UP).toString()).format(
      numbroConfig,
    )
    const numberLimited =
      numberWithLessDecimals.length <= 8 ? numberWithLessDecimals : <>{numberWithLessDecimals.slice(0, 8)}&hellip;</>

    return (
      <>
        {waySymbol && !isFee ? waySymbol : ""}
        {fullNumber ? fullNumberValue : numberLimited}
      </>
    )
  })()

  const tooltipTitleComponent = (
    <TypographyUI variant={TypographyVariant.BODY}>
      {nftId ? (
        <>
          Token&nbsp;ID&nbsp;
          {nftId}
        </>
      ) : (
        volumeProcessed.toString()
      )}
    </TypographyUI>
  )

  const wrapperSize = fullNumber ? "w-[300px] max-w-[300px]" : "w-[130px] max-w-[130px]"
  const assetAmountSize = fullNumber ? "max-w-[200px]" : "max-w-[70px]"
  const assetSymbolSize = fullNumber ? "w-[90px] max-w-[90px]]" : "w-[60px] max-w-[60px]"

  return (
    <div className={`flex flex-col ${alignLeft ? "" : "items-end"} `}>
      {assetDrawer}
      <div
        className={`flex flex-row ${wrapperSize} overflow-hidden whitespace-nowrap text-ellipsis ${
          alignLeft ? "" : "justify-end"
        }`}
      >
        <TooltipUI content={tooltipTitleComponent}>
          <div data-test-id="asset-volume">
            <TypographyAllowEvent
              variant={TypographyVariant.BODY}
              className={`${wayColor} overflow-hidden whitespace-nowrap text-ellipsis ${assetAmountSize}`}
            >
              {assetAmountFormat}
            </TypographyAllowEvent>
          </div>
        </TooltipUI>
        <TooltipUI content={prettyEmptyOrNull(assetSymbol)}>
          <div data-test-id="asset-symbol">
            <ButtonUI
              mode={Mode.TEXTONLY}
              className={`p-0 h-fit ${wayColor} hover:underline ${wayColorHover} pl-1 w-full`}
              onClick={() => openAssetDrawer(assetId)}
            >
              <TypographyUI
                variant={TypographyVariant.BODY}
                className={`text-left overflow-hidden whitespace-nowrap text-ellipsis ${assetSymbolSize}`}
                ellipsisPosition="end"
              >
                {prettyEmptyOrNull(assetSymbol)}
              </TypographyUI>
            </ButtonUI>
          </div>
        </TooltipUI>
      </div>
      {amountInUserFiat !== null && (
        <TooltipUI
          content={
            userFiatSymbol +
            " " +
            (fullNumber
              ? amountInUserFiat.decimalPlaces(11, BigNumber.ROUND_UP).toString()
              : numbro(amountInUserFiat.decimalPlaces(2, BigNumber.ROUND_UP)).format(prettyNumber(amountInUserFiat)))
          }
        >
          <div data-test-id="valuation">
            <TypographyAllowEvent variant={TypographyVariant.CAPTION} className="text-grey-400">
              {userFiatSymbol +
                " " +
                numbro(amountInUserFiat.decimalPlaces(2, BigNumber.ROUND_UP)).format(prettyNumber(amountInUserFiat))}
            </TypographyAllowEvent>
          </div>
        </TooltipUI>
      )}
    </div>
  )
}

export default AssetView
