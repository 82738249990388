import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router"
import { NewInListMovementDto } from "../api/openapi"

import ChildrenProps from "../misc/childrenProps"

export type Tabs = "missingLabel" | "internalTransfer"

export interface SelectTransactionPageContextValue {
  selectedRows: SelectedRowsTransactionPageType | undefined
  setSelectedRows: (row: SelectedRowsTransactionPageType | undefined) => void
  isPageSelected: boolean
  selectedRowCount: number
  onPageSelected: () => void
  addRows: (incomingRows: NewInListMovementDto[]) => void
}

export type SelectedRowsTransactionPageType =
  | {
      type: "positiveAdd"
      itemIds: string[]
    }
  | {
      type: "negativeAdd"
      excludedIds: string[]
    }

export const SelectTransactionPageContext = React.createContext<SelectTransactionPageContextValue>(
  undefined as unknown as SelectTransactionPageContextValue,
)

type AllProps = ChildrenProps

export const SelectTransactionPageContextProvider = (props: AllProps): JSX.Element => {
  const [rows, setRow] = useState<NewInListMovementDto[]>([])
  const [savedPathname, setSavedPathname] = useState("")
  const [savedSearch, setSavedSearch] = useState("")
  const { search, pathname } = useLocation()

  const addRows = (incomingRows: NewInListMovementDto[]) => {
    const isAlreadyImport = rows.find((row) => row.id === incomingRows[0].id)

    if (isAlreadyImport === undefined) {
      const newsRows = rows.concat(incomingRows)
      setRow(newsRows)
    }
  }

  const [selectedRows, setSelectedRows] = useState<SelectedRowsTransactionPageType | undefined>(undefined)

  const { isPageSelected, onPageSelected, selectedRowCount } = useMemo(() => {
    const isPageSelected = (() => {
      if (rows === undefined) return false
      if (selectedRows?.type === "positiveAdd") {
        // all transaction on this page are in the selected transaction list
        return rows.every((x) => selectedRows.itemIds.includes(x.id))
      } else if (selectedRows?.type === "negativeAdd") {
        // all transaction on this page are NOT in the excluded transaction list
        return rows.every((x) => !selectedRows.excludedIds.includes(x.id))
      }
      return false
    })()

    const selectedRowCount = (() => {
      if (rows === undefined) return 0
      if (selectedRows?.type === "positiveAdd") return selectedRows.itemIds.length
      else if (selectedRows?.type === "negativeAdd") return rows.length - selectedRows.excludedIds.length

      return 0
    })()
    // rows, selectedRows
    const onPageSelected = () => {
      if (rows === undefined) return

      if (selectedRows?.type === "positiveAdd") {
        if (isPageSelected) {
          setSelectedRows({
            // remove all transaction ids from this page
            ...selectedRows,
            itemIds: selectedRows.itemIds.filter((x) => !rows.some((tx) => tx.id === x)),
          })
        } else {
          setSelectedRows({
            // add all transaction ids from this page
            ...selectedRows,
            itemIds: Array.from(new Set(selectedRows.itemIds.concat(rows.map((x) => x.id)))),
          })
        }
      } else if (selectedRows?.type === "negativeAdd") {
        if (isPageSelected) {
          setSelectedRows({
            // add transaction to excluded list
            ...selectedRows,
            excludedIds: Array.from(new Set(selectedRows.excludedIds.concat(rows.map((x) => x.id)))),
          })
        } else {
          setSelectedRows({
            // remove transaction from excluded list
            ...selectedRows,
            excludedIds: selectedRows.excludedIds.filter((x) => !rows.some((tx) => tx.id === x)),
          })
        }
      } else {
        setSelectedRows({
          // create a new selection list
          type: "positiveAdd",
          itemIds: rows.map((x) => x.id),
        })
      }
    }

    return {
      isPageSelected,
      selectedRowCount,
      onPageSelected,
    }
  }, [selectedRows, rows])

  useEffect(() => {
    if (selectedRows === undefined || !rows) return
    if (selectedRows.type === "positiveAdd" && selectedRows.itemIds.length === 0) setSelectedRows(undefined) // if the user deselected all rows in individual mode, remove selection
    if (selectedRows.type === "negativeAdd" && selectedRows.excludedIds.length === rows.length) {
      // if the user excluded all rows in filtered mode, remove selection
      setSelectedRows(undefined)
    }
    if (selectedRows.type === "positiveAdd" && selectedRows.itemIds.length === rows.length) {
      // If the user manually selected all pages like a dumb ass, move to filtered
      setSelectedRows({
        type: "negativeAdd",
        excludedIds: [],
      })
    }
  }, [selectedRows, rows])

  useEffect(() => {
    if (savedPathname !== pathname) {
      setRow([])
      setSelectedRows(undefined)
      setSavedPathname(pathname)
    }
    if (savedSearch !== search) {
      setRow([])
      setSelectedRows(undefined)
      setSavedSearch(search)
    }
  }, [setSavedPathname, setSavedSearch, setRow, pathname, search, savedPathname, savedSearch])

  return (
    <SelectTransactionPageContext.Provider
      value={{
        selectedRows,
        setSelectedRows,
        isPageSelected,
        selectedRowCount,
        onPageSelected,
        addRows,
      }}
    >
      {props.children}
    </SelectTransactionPageContext.Provider>
  )
}
