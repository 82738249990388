import { useTypedController } from "@hookform/strictly-typed"
import { Box, Checkbox, FormLabel, makeStyles } from "@material-ui/core"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import { useLoadingButton } from "components/LoadingButton"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import TitleWithDivider from "components/TitleWithDivider"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import { URLS } from "../../routes"
import api from "services/api"
import { pluralize } from "services/utils/textUtils"
import { NoWorkspaceInvitationCard } from "./index"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles(() => ({
  checkbox: {
    paddingLeft: 0,
  },
}))

type Props = {
  onCreateOrJoin: (workspaceId: string) => void
  goBack: () => void
  invitationCount: number
}

type InvitationForm = {
  invitations: { workspaceId: string; join: boolean }[]
}

const NoWorkspaceInvitationForm = ({ onCreateOrJoin, goBack, invitationCount }: Props) => {
  const classes = useStyles()
  const [JoinWorkspacesButton, joinWorkspacesButtonCallback] = useLoadingButton()
  const { control, handleSubmit, formState, reset, watch } = useForm<InvitationForm>({ mode: "onChange" })
  const TypedController = useTypedController<InvitationForm>({ control })
  const history = useHistory()
  const invitations = api.workspace.useWorkspaceInvitations({ limit: 100, page: 1 })

  const watchAllFields = watch()
  const joinCount = watchAllFields.invitations?.filter((i) => i.join).length ?? 0

  useEffect(() => {
    if (invitations.data) {
      reset({ invitations: invitations.data.data.map((i) => ({ workspaceId: i.workspaceId, join: false })) })
    }
  }, [invitations.data, reset])

  const toast = useToast()

  const { mutateAsync: acceptInvitationMutation } = api.workspace.useAcceptWorkspaceInvitations()
  const onSubmit = async (form: InvitationForm) => {
    const invitations = form.invitations.filter((i) => i.join)

    if (invitations.length === 0) return

    try {
      await acceptInvitationMutation({
        acceptWorkspaceInvitationsDto: {
          workspaceIds: invitations.map((i) => i.workspaceId),
        },
      })

      onCreateOrJoin(invitations[0].workspaceId)
      if (invitations.length === 1) {
        history.push(URLS.Portfolio)
      } else {
        // I think the redirect is overrided
        history.push(URLS.Workspaces)
      }
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  return (
    <Box>
      <TitleWithDivider title="Join a new workspace" mb={3} />
      <form onSubmit={joinWorkspacesButtonCallback(handleSubmit(onSubmit))}>
        <Box p={5} display="flex" justifyContent="space-between">
          <NoWorkspaceInvitationCard invitationCount={invitationCount} selected />
          <Box
            width="45%"
            p={1}
            maxHeight="12rem"
            display="flex"
            alignItems="start"
            flexDirection="column"
            style={{ overflowY: "auto" }}
          >
            {invitations.isLoading && <LoadingSpinner />}
            {invitations.isError && <NetworkErrorMessage additionalData={invitations} />}
            {invitations.data &&
              invitations.data.data.map((invitation, idx) => (
                <Box
                  display="flex"
                  alignItems="center"
                  key={`invitation-${invitation.workspaceId}`}
                  data-test-id="workspace-invitation"
                >
                  <TypedController
                    name={["invitations", idx]}
                    defaultValue={{ workspaceId: invitation.workspaceId, join: false }}
                    render={({ onChange, value, ...rest }) => (
                      <Checkbox
                        id={`join-workspace-checkbox-${idx}`}
                        className={classes.checkbox}
                        onChange={(_, checked) => onChange({ workspaceId: invitation.workspaceId, join: checked })}
                        checked={value.join}
                        color="primary"
                        {...rest}
                      />
                    )}
                  />
                  <FormLabel htmlFor={`join-workspace-checkbox-${idx}`}>{invitation.workspaceName}</FormLabel>
                </Box>
              ))}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <ButtonUI onClick={goBack} mode={Mode.CONTAINED}>
            Previous
          </ButtonUI>
          <JoinWorkspacesButton type="submit" disabled={!formState.isValid || joinCount === 0}>
            Join {joinCount} {pluralize(joinCount > 1, "workspace")}
          </JoinWorkspacesButton>
        </Box>
      </form>
    </Box>
  )
}

export default NoWorkspaceInvitationForm
