import { useTypedController } from "@hookform/strictly-typed"
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import React, { useCallback, useEffect, useMemo } from "react"
import { Control, useForm } from "react-hook-form"

import { DrawerFormSection } from "components/Drawer/DrawerItems"
import InputNumber from "components/Input/InputNumber"
import { useLoadingButton } from "components/LoadingButton"
import useDialog from "components/misc/useDialog"
import { limitTypes } from "components/Stripe/constants"
import ModalUI from "CryptioUI/Modal"
import api from "services/api"
import { GetPlanHardLimitDtoLimitTypeEnum } from "services/api/openapi"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

type BackOfficeBillingDialogProps = {
  onClose: () => void
  isOpen: boolean
  setFormDirty: (newValue: boolean) => void
  formData?: Partial<BackOfficeBillingPlanForm>
}
export type BackOfficeBillingPlanForm = {
  name: string
  description: string
  usdPricePer: {
    month: string
    year: string
  }
  hardLimits: Record<GetPlanHardLimitDtoLimitTypeEnum, string>
}

const validLimits = (value: string) => /^\d*$/.test(value)
const LimitOptionForm = ({ control }: { control: Control<BackOfficeBillingPlanForm> }) => {
  const TypedController = useTypedController<BackOfficeBillingPlanForm>({ control })
  // TODO: ask for a workspace and compare usage of workspace
  const currentWorkspaceUsage = null
  return (
    <Box p={2} mt={3} component={Paper}>
      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Limit</TableCell>
              <TableCell align="right">Maximum</TableCell>
              {currentWorkspaceUsage && <TableCell align="right">Workspace usage</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {limitTypes.map((hardLimit, idx) => (
              <TableRow key={idx}>
                <TableCell>{hardLimit.name}</TableCell>
                <TableCell align="right">
                  <TypedController
                    name={["hardLimits", hardLimit.type]}
                    rules={{
                      validate: validLimits,
                    }}
                    render={({ ref: _, ...props }) => (
                      <InputNumber {...props} emptyValue={""} isValueValid={validLimits} />
                    )}
                  />
                </TableCell>
                {currentWorkspaceUsage && (
                  <TableCell align="right">
                    {/* {currentWorkspaceUsage.find((x) => x.limitType === hardLimit.limitType)?.count} */}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
const useStyles = makeStyles(() => ({
  dialogRoot: {
    height: "500px",
    overflowY: "scroll",
  },
  paper: { minWidth: "770px" },
}))

const BackOfficeBillingDialog = (props: BackOfficeBillingDialogProps) => {
  const classes = useStyles()
  const { onClose, isOpen, setFormDirty, formData } = props

  const defaultValues: BackOfficeBillingPlanForm = useMemo(
    () => ({
      name: "",
      description: "",
      usdPricePer: {
        year: "",
        month: "",
      },
      ...formData,
      hardLimits: {
        csv_wallet: "",
        chain_wallet: "",
        custody_wallet: "",
        exchange_wallet: "",
        transaction: "",
        user_per_workspace: "",
        role_per_workspace: "",
        report: "",
        label: "",
        invoice: "",
        contact: "",
        chart_account: "",
        transaction_filter: "",
        contact_wallet_per_label_rule: "",
        ...formData?.hardLimits,
      },
    }),
    [formData],
  )
  const { handleSubmit, control, formState, reset, watch } = useForm<BackOfficeBillingPlanForm>({
    mode: "onChange",
    defaultValues,
  })

  const toast = useToast()
  const TypedController = useTypedController<BackOfficeBillingPlanForm>({ control })

  const basicDialog = useDialog()

  const { mutateAsync: createPlanMutation } = api.backOffice.billing.useCreatePlan()

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues)
    }
  }, [isOpen, reset, defaultValues])

  const [UpdateWorkspaceButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = useCallback(
    async (form: BackOfficeBillingPlanForm) => {
      try {
        await createPlanMutation({
          backOfficeCreatePlanDto: {
            name: form.name,
            description: form.description,
            usdPricePerMonth: form.usdPricePer.month,
            usdPricePerYear: form.usdPricePer.year,
            hardLimits: Object.entries(form.hardLimits)
              .filter(([_, maximum]) => maximum !== "")
              .map(([limit, maximum]) => ({
                limitType: limit as GetPlanHardLimitDtoLimitTypeEnum,
                maximum: Number(maximum),
              })),
          },
        })

        toast.open("Workspace updated", { variant: "success" })

        onClose()
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [onClose, createPlanMutation, toast],
  )

  const watchAllFields = watch()
  const isPriceValid = (value: string) => (value ? Boolean(!isNaN(Number(value)) && Number(value) >= 0) : true)
  const isFormDirty =
    formState.isDirty &&
    isPriceValid(watchAllFields.usdPricePer.month) &&
    isPriceValid(watchAllFields.usdPricePer.year) &&
    Object.values(watchAllFields.hardLimits).every(validLimits)

  useEffect(() => setFormDirty(isFormDirty), [isFormDirty, setFormDirty])

  return (
    <ModalUI isOpen={isOpen} onClose={onClose}>
      <Box component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
        {basicDialog.dialog}
        <DialogTitle>
          <Typography variant="h1" component="h2" style={{ textAlign: "center" }}>
            Create plan
          </Typography>
        </DialogTitle>

        <DialogContent className={classes.dialogRoot}>
          <DrawerFormSection mt={0} htmlFor="plan-name" name="Name">
            <TypedController
              name="name"
              rules={{
                required: true,
              }}
              render={(props) => <TextField fullWidth id="plan-name" {...props} />}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="plan-description" name="Description">
            <TypedController
              name="description"
              rules={{
                required: true,
              }}
              render={(props) => <TextField fullWidth id="plan-description" {...props} />}
            />
          </DrawerFormSection>

          <TypedController
            name="usdPricePer"
            rules={{
              required: true,
              validate: ({ month, year }) => isPriceValid(month) && isPriceValid(year),
            }}
            render={({ value, onChange }) => (
              <>
                <DrawerFormSection htmlFor="input-price-per-month" name="USD price per month">
                  <InputNumber
                    id="input-price-per-month"
                    value={value.month}
                    emptyValue={""}
                    isValueValid={isPriceValid}
                    onChange={(month) =>
                      onChange({
                        month,
                        year: (Number(month) * 12 * 0.88).toString(),
                      })
                    }
                  />
                </DrawerFormSection>

                <Box mt={1} />
                <Typography variant="body2">
                  A discount of 12% is automatically applied to subscriptions paid annually.
                </Typography>

                <DrawerFormSection htmlFor="input-price-per-year" name="USD price per year">
                  <InputNumber
                    id="input-price-per-year"
                    value={value.year}
                    emptyValue={""}
                    isValueValid={isPriceValid}
                    onChange={(year) =>
                      onChange({
                        month: (Number(year) / 0.88 / 12).toString(),
                        year,
                      })
                    }
                  />
                </DrawerFormSection>
              </>
            )}
          />

          <LimitOptionForm control={control} />
        </DialogContent>

        <DialogActions>
          <UpdateWorkspaceButton disabled={!formState.isValid} type="submit">
            Create
          </UpdateWorkspaceButton>
        </DialogActions>
      </Box>
    </ModalUI>
  )
}

export default BackOfficeBillingDialog
