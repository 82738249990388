import React from "react"

import { makeStyles, Theme } from "@material-ui/core"
import { ReactComponent as Save } from "CryptioUI/assets/icons/floppy-disc.svg"
import { ReactComponent as Delete } from "CryptioUI/assets/icons/delete.svg"
import { ReactComponent as FavoriteOff } from "CryptioUI/assets/icons/favorite-off.svg"
import { GetTransactionFilterDto } from "services/api/openapi"
import { iconStylePrimary } from "CryptioUI/Utilities/config"
import IconButton from "../IconButton"

const useStyles = makeStyles((theme: Theme) => ({
  addFilterContainer: {
    "paddingTop": theme.spacing(1.5),
    "display": "flex",
    "flexDirection": "row",
    "alignItems": "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  deleteIcon: {
    "&:hover": {
      cursor: "pointer",
    },
    "height": "1.1em",
    "width": "1.1em",
  },
}))

interface Props {
  updateFavoriteFilter: (name: string, filterId: string) => Promise<void>
  setIsModalOpen: (value: boolean) => void
  deleteTransactionFilter?: (savedFilter: GetTransactionFilterDto, actionAfterConfirm?: () => void) => void
  setFilterLoaded?: (filter: GetTransactionFilterDto | undefined) => void
  filterLoaded?: GetTransactionFilterDto | undefined
  canSave: boolean
}

const FavoriteButton = (props: Props): JSX.Element => {
  const { updateFavoriteFilter, setIsModalOpen, deleteTransactionFilter, setFilterLoaded, filterLoaded, canSave } =
    props
  const classes = useStyles()

  return (
    <div className={classes.addFilterContainer}>
      {filterLoaded === undefined || !setFilterLoaded || !deleteTransactionFilter ? (
        <IconButton
          disabled={!canSave}
          onClick={() => {
            setIsModalOpen(true)
          }}
          title="Favorite"
          icon={<FavoriteOff className={iconStylePrimary} />}
        />
      ) : (
        <>
          <IconButton
            onClick={() => updateFavoriteFilter(filterLoaded.name, filterLoaded.id)}
            title="Update"
            icon={<Save className={classes.deleteIcon} />}
          />
          <IconButton
            onClick={() => {
              deleteTransactionFilter(filterLoaded, () => setFilterLoaded(undefined))
            }}
            title="Delete"
            icon={<Delete className={classes.deleteIcon} />}
          />
        </>
      )}
    </div>
  )
}

export default FavoriteButton
