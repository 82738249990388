import { useTypedController } from "@hookform/strictly-typed"
import { Box, TextField, Typography } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React from "react"
import { useForm } from "react-hook-form"

import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import useDialog from "components/misc/useDialog"
import { DrawerProp } from "components/misc/useDrawer"
import WarningTypography from "components/WarningTypography"
import api from "services/api"
import { BackOfficeFromUnknownAssetToKnownDto, GetPublicAssetDto } from "services/api/openapi/models"
import { assetTypeArray, prettyAssetTypeMap } from "../.."
import ButtonUI from "CryptioUI/Button"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const AssetAdminUpdateAsset = (props: DrawerProp<GetPublicAssetDto, false>) => {
  const { item: asset, onClose } = props
  const toast = useToast()

  const basicDialog = useDialog()

  const { handleSubmit, control } = useForm<BackOfficeFromUnknownAssetToKnownDto>({
    mode: "onChange",
  })
  const TypedController = useTypedController<BackOfficeFromUnknownAssetToKnownDto>({ control })

  const { mutateAsync: fromUnknownAssetToKnownMutation } = api.backOffice.asset.useFromUnknownAssetToKnown()
  const doUpdateAsset = async (form: BackOfficeFromUnknownAssetToKnownDto) => {
    try {
      if (form.newType === "exchange_unknown") {
        toast.open("Cannot remap an exchange_unknown asset to another exchange_unknown", { variant: "danger" })
        return
      }
      await fromUnknownAssetToKnownMutation({
        unknownAssetId: asset.id,
        backOfficeFromUnknownAssetToKnownDto: form,
      })
      toast.open("Asset updated", { variant: "success" })
      onClose()
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const askUpdateAsset = (form: BackOfficeFromUnknownAssetToKnownDto) => {
    basicDialog.showDialog({
      title: "Update asset",
      content: (
        <Typography variant="h5">
          Do you really want to update the asset <b>{asset.uniqueSymbol}</b>?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: () => doUpdateAsset(form),
    })
  }

  return (
    <DrawerCategory title="Update an asset" component="form" onSubmit={handleSubmit(askUpdateAsset)}>
      {basicDialog.dialog}
      {asset.type === "exchange_unknown" && (
        <WarningTypography>Only use this if the asset doesn&apos;t already exists</WarningTypography>
      )}

      <DrawerFormSection name="Full name of the asset" htmlFor="update-asset-name">
        <TypedController
          id="update-asset-name"
          name="newName"
          rules={{ required: true }}
          defaultValue={asset.name ?? ""}
          render={(props) => <TextField {...props} fullWidth={true} />}
        />
      </DrawerFormSection>

      <DrawerFormSection name="New type" htmlFor="update-asset-type">
        <TypedController
          id="update-asset-type"
          name="newType"
          defaultValue={"token"}
          rules={{ required: true }}
          render={({ ref: _, onChange, ...props }) => (
            <Autocomplete
              id="search-type-select"
              getOptionLabel={(opt) => prettyAssetTypeMap[opt]}
              options={assetTypeArray}
              {...props}
              onChange={(_, newValue) => onChange(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          )}
        />
      </DrawerFormSection>
      <Box mt={3}>
        <ButtonUI type="submit">Update</ButtonUI>
      </Box>
    </DrawerCategory>
  )
}

export default AssetAdminUpdateAsset
