import { useEffect, useState } from "react"

const useClipboard = (updateFrequency = 1000): string => {
  const [clipboard, setClipboardContent] = useState("")

  useEffect(() => {
    let readClipboardIntervalId: NodeJS.Timer | null = null
    if (navigator.permissions && navigator.clipboard) {
      readClipboardIntervalId = setInterval(() => {
        navigator.clipboard.readText().then((clipboardContent) => {
          setClipboardContent(clipboardContent)
        })
      }, updateFrequency)
    }

    return () => {
      if (navigator.clipboard && readClipboardIntervalId !== null) {
        clearInterval(readClipboardIntervalId)
      }
    }
  }, [updateFrequency])

  return clipboard
}

export default useClipboard
