import { useTypedController } from "@hookform/strictly-typed"
import React, { useContext } from "react"
import { Control } from "react-hook-form"

import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import { GetLabelDto, GetLabelsRequest } from "services/api/openapi"
import { TransactionBulkForm } from "."
import { TransactionParams } from ".."
import { DrawerFormSection } from "../../Drawer/DrawerItems"
import CustomSelector from "../../selector/CustomSelector"
import LabelOptionInAutocomplete from "../../AutoCompleteOptions/LabelOptionInAutocomplete"
import { SelectedRowsType } from "../GeneratedTable/useBulkSelectorTransactionPage"
import PermissionDisabled from "../../../components/Permission/PermissionDisabled"
import { makeStyles, Theme, Typography } from "@material-ui/core"
import { createStyles } from "@material-ui/styles"
import { WarningRounded } from "@material-ui/icons"
import { WorkspaceContext } from "services/context/workspaceContext"

interface Props {
  params: TransactionParams
  selectedRows?: SelectedRowsType
  excludedLabelIds: string[]
  control: Control<TransactionBulkForm>
  watchAllFields: {
    note: string
    emptyNotes: boolean
    fiatRate: string
    resetRates: boolean
    labelsToAdd: GetLabelDto[]
    labelsToRemove: GetLabelDto[]
    removeInternalTransfer: boolean
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typographyWarning: {
      fontWeight: 800,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    iconWarning: {
      marginRight: theme.spacing(2.5),
      marginBottom: theme.spacing(0.4),
    },
  }),
)

const BulkLabel = (props: Props) => {
  const { excludedLabelIds, control, watchAllFields, selectedRows } = props
  const TypedController = useTypedController<TransactionBulkForm>({ control })
  const classes = useStyles()
  const workspaceCtx = useContext(WorkspaceContext)

  return (
    <>
      <DrawerFormSection htmlFor="label-add-select" name="Label to add">
        <TypedController
          name="labelsToAdd"
          defaultValue={[]}
          rules={{ required: true }}
          render={({ ref, onChange, ...rest }) => (
            <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
              <CustomSelector<WithoutWorkspaceId<GetLabelsRequest>["getLabelQuery"], GetLabelDto, true>
                id="label-add-select"
                {...rest}
                permission={{
                  placement: "right",
                  permission: "can_read_label",
                  action: "read labels",
                }}
                inputRef={ref}
                fullWidth
                multiple
                disableCloseOnSelect
                onChange={(_, newValue) => onChange(newValue)}
                getOptionLabel={(option) => option.name}
                defaultPaginatedQueryProps={{
                  excludedIds: excludedLabelIds,
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                usePaginatedQuery={api.label.useLabels}
                size="small"
                placeholder="Type to search..."
                renderOption={(option) => <LabelOptionInAutocomplete label={option} />}
              />
            </PermissionDisabled>
          )}
        />
        {workspaceCtx.workspace.lock.isEnabled &&
          watchAllFields &&
          watchAllFields.labelsToAdd &&
          watchAllFields.labelsToAdd.length > 0 && (
            <Typography className={classes.typographyWarning}>
              <WarningRounded className={classes.iconWarning} /> Note that labels can't be added on locked transactions
            </Typography>
          )}
      </DrawerFormSection>
      <DrawerFormSection htmlFor="label-remove-select" name="Label to remove">
        <TypedController
          name="labelsToRemove"
          defaultValue={[]}
          rules={{ required: true }}
          render={({ ref, onChange, ...rest }) => (
            <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
              <CustomSelector<WithoutWorkspaceId<GetLabelsRequest>["getLabelQuery"], GetLabelDto, true>
                id="label-remove-select"
                {...rest}
                permission={{
                  placement: "right",
                  permission: "can_read_label",
                  action: "read labels",
                }}
                inputRef={ref}
                fullWidth
                multiple
                disableCloseOnSelect
                onChange={(_, newValue) => onChange(newValue)}
                getOptionLabel={(option) => option.name}
                defaultPaginatedQueryProps={{
                  excludedIds: excludedLabelIds,
                  transationIdsToInclude: selectedRows?.type === "positiveAdd" ? selectedRows.itemIds : undefined,
                  transationIdsToExclude: selectedRows?.type === "negativeAdd" ? selectedRows.excludedIds : undefined,
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                usePaginatedQuery={api.label.useLabels}
                size="small"
                placeholder="Type to search..."
                renderOption={(option) => <LabelOptionInAutocomplete label={option} />}
              />
            </PermissionDisabled>
          )}
        />
        {workspaceCtx.workspace.lock.isEnabled &&
          watchAllFields &&
          watchAllFields.labelsToRemove &&
          watchAllFields.labelsToRemove.length > 0 && (
            <Typography className={classes.typographyWarning}>
              <WarningRounded className={classes.iconWarning} /> Note that labels can't be removed on locked
              transactions
            </Typography>
          )}
      </DrawerFormSection>
    </>
  )
}

export default BulkLabel
