import { useTypedController } from "@hookform/strictly-typed"
import { Box, Checkbox, FormLabel, TextField } from "@material-ui/core"
import React, { useContext } from "react"
import { Control, FormState } from "react-hook-form"
import { TransactionBulkForm } from "./index"

import { WorkspaceContext } from "services/context/workspaceContext"
import { DrawerFormSection } from "../../Drawer/DrawerItems"
import PermissionDisabled from "../../Permission/PermissionDisabled"

interface Props {
  formState: FormState<TransactionBulkForm>
  watchAllFields: TransactionBulkForm
  control: Control<TransactionBulkForm>
  isValidStringNumber: (str: string) => boolean
}

const BulkUSD = (props: Props) => {
  const { formState, watchAllFields, control, isValidStringNumber } = props
  const { workspace } = useContext(WorkspaceContext)
  const TypedController = useTypedController<TransactionBulkForm>({ control })

  return (
    <>
      <DrawerFormSection htmlFor="usd-rate-textfield" name={`${workspace.defaultCurrencyName} rate`}>
        <TypedController
          name="fiatRate"
          defaultValue=""
          rules={{
            required: false,
            validate: (v) => v.length === 0 || isValidStringNumber(v),
          }}
          render={(props) => (
            <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
              <TextField
                id="usd-rate-textfield"
                disabled={watchAllFields.resetRates}
                error={!!formState.errors.fiatRate}
                {...props}
                fullWidth
              />
            </PermissionDisabled>
          )}
        />
      </DrawerFormSection>
      <Box display="flex" alignItems="center" mt={1}>
        <TypedController
          name="resetRates"
          defaultChecked={false}
          defaultValue={false}
          render={({ onChange, value, ...rest }) => (
            <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
              <Checkbox
                color="primary"
                id="reset-rates-checkbox"
                onChange={() => onChange(!value)}
                checked={value}
                {...rest}
              />
            </PermissionDisabled>
          )}
        />
        <FormLabel htmlFor="reset-rates-checkbox">{`Reset all ${workspace.defaultCurrencyName} rates`}</FormLabel>
      </Box>
    </>
  )
}

export default BulkUSD
