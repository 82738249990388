import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core"
import { ReactComponent as Delete } from "CryptioUI/assets/icons/delete.svg"
import { ReactComponent as Warning } from "CryptioUI/assets/icons/warning.svg"
import React, { useContext, useMemo } from "react"

import { LabelCircleColor } from "components/Labels/LabelCircleColor"
import LoadingSpinner from "components/misc/LoadingSpinner"
import useDialog from "components/misc/useDialog"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import ServerSideTable from "components/Table/ServerSideTable"
import api from "services/api"
import { FullMovementDto, MovementCounterPartyDtoDirectionEnum } from "services/api/openapi"
import { mockInternalTransferLabel } from "../TransactionLabelDrawer/NonTaxabelLabelSelector"
import useCounterPartyTableColumn from "./useCounterPartyTableColumn"
import { newColorHex, correspondanceHex } from "CryptioUI/types/tag"
import useDrawer from "../../misc/useDrawer"
import { iconStyleBlack } from "CryptioUI/Utilities/config"
import { isLockedTransaction } from "services/utils/isLocked"
import { WorkspaceContext } from "services/context/workspaceContext"
import ConditionalTooltip from "components/ConditionalTooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface ShowLabelAlreadyActivatedProps {
  movement: FullMovementDto
}

const useStyles = makeStyles((theme: Theme) => ({
  typographyWarning: {
    fontWeight: 800,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  iconWarning: {
    marginRight: theme.spacing(4.5),
    marginBottom: theme.spacing(0.4),
  },
  container: {
    display: "flex",
    overflowX: "scroll",
    width: "100%",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.12)",
    borderRadius: "2px",
    minHeight: "fit-content",
    height: "fit-content",
  },
}))

const ShowLabelAlreadyActivated = (props: ShowLabelAlreadyActivatedProps) => {
  const toast = useToast()
  const { movement } = props
  const basicDialog = useDialog()
  const { mutateAsync: bulkUpdateMovementsMutation } = api.transaction.useBulkUpdateMovements()
  const classes = useStyles()
  const { workspace } = useContext(WorkspaceContext)

  const isLocked = isLockedTransaction(
    workspace.lock.isEnabled,
    movement.transactionDate,
    workspace.lock.exclusiveEndDate,
  )

  const counterParty = api.transaction.useMovement({ movementId: movement.internalTransferCounterpartyId ?? "" })
  const { mutateAsync: bulkUpdateMovementMutation } = api.transaction.useBulkUpdateMovements()

  const doRemoveLabel = async (labelId: string) => {
    try {
      await bulkUpdateMovementsMutation({
        movementBulkUpdateDto: {
          labelIdsToRemove: [labelId],
          individualMovements: {
            movementIds: [movement.id],
          },
        },
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const doRemoveInternalTransfer = async () => {
    try {
      await bulkUpdateMovementMutation({
        movementBulkUpdateDto: {
          removeInternalTransfer: true,
          individualMovements: {
            movementIds: [movement.id],
          },
        },
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const askRemoveInternalTransfer = () => {
    return basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to remove the label internal transfer? It will automatically delete the counterparty if it
          exists
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doRemoveInternalTransfer,
    })
  }

  const [walletDrawer, openWalletDrawer] = useDrawer("wallet")
  const columns = useCounterPartyTableColumn({ openWalletDrawer })
  const [movementDrawer, openMovementDrawer] = useDrawer("movement")

  const serverSideTable = useMemo(() => {
    if (counterParty.data === undefined) {
      return <></>
    }

    return (
      <Paper>
        {movementDrawer}
        <ServerSideTable<FullMovementDto, MovementCounterPartyDtoDirectionEnum>
          columns={columns}
          items={[counterParty.data] || []}
          totalCount={1}
          pagination={false}
          onRowClicked={() => openMovementDrawer({ movementId: counterParty.data.id })}
        />
      </Paper>
    )
  }, [counterParty, columns, movementDrawer, openMovementDrawer])

  if (movement.internalTransferCounterpartyId && (counterParty.isLoading || counterParty.data === undefined)) {
    return (
      <Box mt={2}>
        <LoadingSpinner />
      </Box>
    )
  }

  return (
    <Box mt={2}>
      {walletDrawer}
      {basicDialog.dialog}
      <Paper>
        {movement.labels.length > 0 || movement.isInternalTransfer ? (
          <List>
            {movement.labels.map((label) => (
              <ListItem key={label.labelId}>
                <ListItemAvatar>
                  <>
                    {label.color && (
                      <LabelCircleColor
                        color={newColorHex[correspondanceHex[label.color]]}
                        type={label.isAutomatic ? "automatic" : "manual"}
                        size={32}
                      />
                    )}
                  </>
                </ListItemAvatar>
                <ListItemText primary={label.name} />
                <ListItemSecondaryAction>
                  <PermissionDisabled
                    permission="can_modify_transaction"
                    action="modify a transaction"
                    titlePermissionEnable={
                      isLocked ? "This label can't be deleted because this transaction is locked" : "Delete"
                    }
                  >
                    <ConditionalTooltip
                      tooltipMessage="This label can't be deleted because this transaction is locked"
                      disabled={isLocked}
                    >
                      <IconButton
                        onClick={() => doRemoveLabel(label.labelId)}
                        edge="end"
                        aria-label="delete"
                        disabled={isLocked}
                      >
                        <Delete className={iconStyleBlack} />
                      </IconButton>
                    </ConditionalTooltip>
                  </PermissionDisabled>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            {movement.isInternalTransfer && (
              <ListItem>
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <ListItemAvatar>
                      <>
                        {mockInternalTransferLabel.color && (
                          <LabelCircleColor
                            color={newColorHex[correspondanceHex[mockInternalTransferLabel.color]]}
                            size={32}
                          />
                        )}
                      </>
                    </ListItemAvatar>
                    <ListItemText primary={mockInternalTransferLabel.name} />
                    <div>
                      <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
                        <IconButton
                          disabled={(movement.isInternalTransferSystemAttributed ?? false) || isLocked}
                          onClick={askRemoveInternalTransfer}
                          edge="end"
                          aria-label="delete"
                        >
                          <Delete className={iconStyleBlack} />
                        </IconButton>
                      </PermissionDisabled>
                    </div>
                  </div>
                  {counterParty.data ? (
                    <>
                      <Typography className={classes.typographyWarning}>Counter party already linked:</Typography>
                      <div className={classes.container}>{serverSideTable}</div>
                    </>
                  ) : (
                    <Typography className={classes.typographyWarning}>
                      <Warning className={iconStyleBlack} /> No counter party found
                    </Typography>
                  )}
                </div>
              </ListItem>
            )}
          </List>
        ) : (
          <Box display="flex" justifyContent="center" p={1}>
            <Typography variant="body1">No labels</Typography>
          </Box>
        )}
      </Paper>
    </Box>
  )
}

export default ShowLabelAlreadyActivated
