import { Dialog, Transition } from "@headlessui/react"
import React, { Fragment, useRef } from "react"

import { WrapperDrawerProps } from "CryptioUI/types"

const DrawerUI = ({
  isOpen,
  onClose,
  children,
  side,
  delayDurationIn,
  delayDurationOut,
}: WrapperDrawerProps): JSX.Element => {
  const completeButtonRef = useRef(null)
  const isRigtSide = side == "right"
  const startPosition = isRigtSide ? "translate-x-full" : "-translate-x-full"
  const endPosition = isRigtSide ? "translate-x-0" : "translate-x-0"
  const inset = isRigtSide ? "right-0" : "left-0"

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog initialFocus={completeButtonRef} className="fixed inset-0 z-[1201] overflow-y-auto" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-1000"
          enterFrom="opacity-0 scale-80"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-grey-400 opacity-50" />
        </Transition.Child>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter={`ease-out ${delayDurationIn}`}
            enterFrom={`opacity-0 scale-95 ${startPosition}`}
            enterTo={`opacity-100 scale-100  ${endPosition}`}
            leave={`ease-in ${delayDurationOut}`}
            leaveFrom={`opacity-100 scale-100 ${endPosition}`}
            leaveTo={`opacity-0 scale-95 ${startPosition}`}
          >
            <div className={`h-screen p-6 top-0 ${inset} w-5/12 min-w-[580px] fixed `}>
              <div className=" w-full h-full overflow-auto text-left align-middle transition-all transform drop-shadow-lg bg-white shadow-xl rounded-xl">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default DrawerUI
