import { useMutation, useQuery, UseQueryOptions } from "react-query"

import { queryClient } from "../../../.."
import {
  BackOfficeApi,
  BackOfficeGetWorkspaceFeaturesRequest,
  BackOfficeGetWorkspacesRequest,
  BackOfficePaginatedWorkspaceResultDto,
  BackOfficeWorkspaceApi,
} from "../../openapi"
import { GET_FULL_SUBSCRIPTION_KEY, GET_SHORT_SUBSCRIPTION_KEY } from "../billing"
import { GET_WORKSPACE_KEY, GET_WORKSPACES_LIST_SHORT_KEY } from "../workspace"
import { BACK_OFFICE_GET_PLAN_KEY, BACK_OFFICE_GET_PLANS_KEY } from "./billing"
import { useWorkspaceMutation, useWorkspaceQuery } from "../../../context/workspaceContext"

const BACK_OFFICE_GET_WORKSPACE_KEY = "backOfficeGetWorkspace"
const BACK_OFFICE_GET_WORKSPACES_KEY = "backOfficeGetWorkspaces"
const BACK_OFFICE_GET_WORKSPACE_FEATURES_KEY = "backOfficeGetWorkspaceFeatures"
const BACK_OFFICE_GET_WORKSPACE_QUICKBOOKS = "backOfficeGetQuickbooksAutoIncrement"

export const backOfficeWorkspaceActions = {
  useWorkspace: (workspaceId: string | null) => {
    return useQuery(
      [BACK_OFFICE_GET_WORKSPACE_KEY, workspaceId],
      () => BackOfficeWorkspaceApi.backOfficeGetWorkspace({ workspaceId: workspaceId ?? "" }),
      {
        enabled: Boolean(workspaceId),
      },
    )
  },
  useWorkspaces: (
    variables: BackOfficeGetWorkspacesRequest,
    options?: UseQueryOptions<BackOfficePaginatedWorkspaceResultDto>,
  ) => {
    return useQuery<BackOfficePaginatedWorkspaceResultDto>(
      [BACK_OFFICE_GET_WORKSPACES_KEY, variables],
      () => BackOfficeWorkspaceApi.backOfficeGetWorkspaces(variables),
      {
        ...options,
        keepPreviousData: true,
      },
    )
  },
  useUpdateWorkspace: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeUpdateWorkspace, {
      onSuccess: () => {
        // Update workspace
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACES_KEY)

        // If self
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
      },
    })
  },
  useDeleteWorkspace: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeDeleteWorkspace, {
      onSuccess: () => {
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACES_KEY)

        // If self
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
      },
    })
  },
  useBackOfficeToggleBookmark: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeToggleWorkspaceBookmark, {
      onSuccess: () => {
        // Order changed
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACES_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_LIST_SHORT_KEY)
      },
    })
  },
  useUpdateAllWorkspaceCostBasis: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeStartCostBasisForAll, {})
  },
  useUpdateAllWorkspaceUnknownAddress: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeStartUnknownAddress, {})
  },
  useUpdateAllWorkspaceBalances: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeStartBalances, {})
  },
  useUpdateAllWorkspaceContactTrxCount: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeStartContactTrxCount, {})
  },
  useUpdateAllWorkspaceLabelRules: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeUpdateAllLabelRules, {})
  },
  useUpdateAllWorkspaceMappings: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeUpdateAllWorkspaceMappings, {})
  },
  useUpdateAllWorkspaceInternalTransfer: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeUpdateAllWorkspaceInternalTransfer, {})
  },
  useUpdateAllWorkspaceInternalTransferReview: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeUpdateAllWorkspaceInternalTransferReview, {})
  },
  useUpdateWorkspaceSubscriptionTrialEndDate: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeUpdateSubscriptionTrialEndDate, {
      onSuccess: () => {
        // Update workspace
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACES_KEY)

        queryClient.invalidateQueries(GET_FULL_SUBSCRIPTION_KEY)
        queryClient.invalidateQueries(GET_SHORT_SUBSCRIPTION_KEY)

        // If self
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
      },
    })
  },
  useStartManualSubscription: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeStartManualSubscription, {
      onSuccess: () => {
        // Update workspace
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACES_KEY)

        queryClient.invalidateQueries(BACK_OFFICE_GET_PLAN_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_PLANS_KEY)

        queryClient.invalidateQueries(GET_FULL_SUBSCRIPTION_KEY)
        queryClient.invalidateQueries(GET_SHORT_SUBSCRIPTION_KEY)

        // If self
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
      },
    })
  },
  useRemoveSubscription: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeRemoveSubscription, {
      onSuccess: () => {
        // Update workspace
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACES_KEY)

        queryClient.invalidateQueries(BACK_OFFICE_GET_PLAN_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_PLANS_KEY)

        queryClient.invalidateQueries(GET_FULL_SUBSCRIPTION_KEY)
        queryClient.invalidateQueries(GET_SHORT_SUBSCRIPTION_KEY)

        // If self
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
      },
    })
  },
  useDuplicateWorkspace: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeDuplicateWorkspace, {
      onSuccess: () => {
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACES_KEY)
      },
    })
  },
  useGetWorkspaceFeatures: (params: BackOfficeGetWorkspaceFeaturesRequest) => {
    return useQuery([BACK_OFFICE_GET_WORKSPACE_FEATURES_KEY, params], () =>
      BackOfficeWorkspaceApi.backOfficeGetWorkspaceFeatures(params),
    )
  },
  useUpdateWorkspaceFeatures: () => {
    return useMutation(BackOfficeWorkspaceApi.backOfficeUpdateWorkspaceFeatures, {
      onSuccess: () => {
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACE_FEATURES_KEY)
      },
    })
  },
  useGetQuickbooksAutoIncrement: () => {
    return useWorkspaceQuery(
      [BACK_OFFICE_GET_WORKSPACE_QUICKBOOKS],
      BackOfficeApi.backOfficeGetQuickbooksAutoIncremental,
      {},
    )
  },
  useToggleQuickbooksAutoIncrement: () => {
    return useWorkspaceMutation(BackOfficeApi.backOfficeToggleQuickbooksAutoIncrement, {
      onSuccess: () => {
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACE_QUICKBOOKS)
      },
    })
  },
  useChangeWorkspaceUserOwnership: () => {
    return useMutation(BackOfficeWorkspaceApi.changeWorkspaceUserOwnership, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACES_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_WORKSPACE_KEY)
      },
    })
  },
}
