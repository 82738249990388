import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { explorers } from "pure-shared"
import React from "react"
import AddressTypography from "components/AddressTypography"
import ExternalLink from "components/ExternalLink"
import CopyableText from "components/misc/copyableText"

interface Props {
  txHash: string
  sourceName: string
}

const useStyles = makeStyles(() => ({
  content: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}))

const TxHashLink = ({ txHash, sourceName }: Props) => {
  const classes = useStyles()

  const explorer = explorers.find((x) => x.sourceName === sourceName)

  return (
    <Box display="flex">
      <Typography component="span" variant="body2" className={classes.content}>
        <CopyableText text={txHash}>
          <AddressTypography component="span">{txHash}</AddressTypography>
        </CopyableText>
        {explorer && <ExternalLink href={explorer.getLink(txHash)}>View on {explorer.displayableName}</ExternalLink>}
      </Typography>
    </Box>
  )
}

export default TxHashLink
