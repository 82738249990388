import { NewFullTransactionDto } from "services/api/openapi"
import { WarningAdminFeature } from "../../Feature/WarningAdminFeature"
import BackOfficeView from "../../misc/BackOfficeView"
import { WorkspaceContext } from "services/context/workspaceContext"
import { useContext } from "react"
import api from "services/api"
import { ButtonSize } from "CryptioUI/types"
import { ReactComponent as Synchronize } from "CryptioUI/assets/icons/synchronize.svg"
import { iconStyleWhite } from "CryptioUI/Utilities/config"
import ButtonUI from "CryptioUI/Button"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface TransactionValuationUpdateProps {
  transaction: NewFullTransactionDto
}

const TransactionValuationUpdate = ({ transaction }: TransactionValuationUpdateProps) => {
  const workspaceCtx = useContext(WorkspaceContext)
  const toast = useToast()

  const { mutateAsync: updateTransactionValuationListMutation } =
    api.backOffice.wallet.useUpdateTransactionListValuation()

  const backofficeValuationUpdateTransaction = async () => {
    try {
      await updateTransactionValuationListMutation({
        backOfficeUpdateTransactionListValuation: {
          transactionIds: [transaction.id],
        },
      })
      toast.open("Updating valuation", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  return (
    <BackOfficeView>
      <div className="flex mt-3">
        <WarningAdminFeature isAdminFeature={true}>
          <ButtonUI
            disabled={workspaceCtx.workspace.hasValuationJobsRunning}
            Icon={<Synchronize className={iconStyleWhite} />}
            onClick={() => backofficeValuationUpdateTransaction()}
            size={ButtonSize.MD}
          >
            Revaluate transaction
          </ButtonUI>
        </WarningAdminFeature>
      </div>
    </BackOfficeView>
  )
}

export default TransactionValuationUpdate
