import { Box, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import { ComputerRounded } from "@material-ui/icons"
import dayjs from "dayjs"
import React, { useContext, useMemo, useState } from "react"
import { IDataTableColumn } from "react-data-table-component"
import { useHistory } from "react-router"

import BaseContainer from "components/Container"
import { UncontrolledLoadingButton } from "components/LoadingButton"
import MainTitleView from "components/MainTitleView"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import ServerSideTable from "components/Table/ServerSideTable"
import TableTitleWithTotalCount from "components/Table/TableTitleWithTotalCount"
import ButtonUI from "CryptioUI/Button"
import { ButtonSize } from "CryptioUI/types"
import { URLS } from "../../routes"
import api from "services/api"
import { GetWorkspaceInvitationDto } from "services/api/openapi"
import { DEFAULT_ROWS_PER_PAGE } from "services/constants"
import { WorkspaceContext } from "services/context/workspaceContext"
import { useLocalStorage } from "services/misc/useLocalStorage"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  noInvitationText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  joinButton: {
    marginRight: theme.spacing(1),
  },
}))

const WorkspaceInvitationScene = (): JSX.Element => {
  const classes = useStyles()
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useLocalStorage<number>("workspace_invitations_view_limit", DEFAULT_ROWS_PER_PAGE)
  const workspaceCtx = useContext(WorkspaceContext)
  const history = useHistory()

  const toast = useToast()

  const basicDialog = useDialog()

  const { mutateAsync: acceptInvitationMutation } = api.workspace.useAcceptWorkspaceInvitations()
  const { mutateAsync: declineInvitationMutation } = api.workspace.useDeclineWorkspaceInvitation()

  const workspaceInvitations = api.workspace.useWorkspaceInvitations(
    {
      page: page,
      limit: limit,
    },
    {
      refetchInterval: 1000,
    },
  )
  const user = api.user.useUser()

  const columns = useMemo<IDataTableColumn<GetWorkspaceInvitationDto>[]>(() => {
    const doAcceptInvitation = async (invitation: GetWorkspaceInvitationDto) => {
      try {
        await acceptInvitationMutation({ acceptWorkspaceInvitationsDto: { workspaceIds: [invitation.workspaceId] } })
        workspaceCtx.changeWorkspace(invitation.workspaceId)
        history.push(URLS.Portfolio)
        toast.open(`Workspace ${invitation.workspaceName} was joined`, { variant: "success" })
      } catch (e) {
        toastCatch(e, toast)
      }
    }

    const doDeclineInvitation = async (invitation: GetWorkspaceInvitationDto) => {
      try {
        await declineInvitationMutation({ workspaceId: invitation.workspaceId })
        toast.open(`Invitation declined`, { variant: "success" })
      } catch (e) {
        toastCatch(e, toast)
      }
    }

    const askDeclineInvitation = (invitation: GetWorkspaceInvitationDto) => {
      basicDialog.showDialog({
        title: "Decline invitation?",
        content: (
          <Typography variant="h5">
            Do you really want to decline the invitation of <b>{invitation.ownerEmail}</b>?
          </Typography>
        ),
        yesText: "Yes",
        noText: "Cancel",
        onAccept: () => doDeclineInvitation(invitation),
      })
    }

    return [
      {
        name: "Date",
        selector: "invitedAt",
        format: ({ invitedAt }) => dayjs(invitedAt).fromNow(),
      },
      {
        name: "Name",
        selector: "workspaceName",
      },
      {
        name: "Owner name",
        selector: "ownerName",
      },
      {
        name: "Actions",
        selector: "id",
        format: function formatSelectWorkspace(row) {
          return (
            <div className="flex">
              <UncontrolledLoadingButton size={ButtonSize.SM} onClick={() => doAcceptInvitation(row)}>
                Join
              </UncontrolledLoadingButton>
              <ButtonUI size={ButtonSize.SM} onClick={() => askDeclineInvitation(row)} className="ml-2">
                Decline
              </ButtonUI>
            </div>
          )
        },
      },
    ]
  }, [acceptInvitationMutation, declineInvitationMutation, toast, basicDialog, history, workspaceCtx])

  if (workspaceInvitations.isError) return <NetworkErrorMessage small={false} additionalData={workspaceInvitations} />
  if (user.isError) return <NetworkErrorMessage small={false} additionalData={user} />

  if (
    workspaceInvitations.isLoading ||
    workspaceInvitations.data === undefined ||
    user.isLoading ||
    user.data === undefined
  )
    return <LoadingSpinner />

  if (workspaceInvitations.data.totalCount === 0) {
    return (
      <BaseContainer>
        {basicDialog.dialog}
        <MainTitleView title="Workspace invitations" />
        <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <ComputerRounded fontSize="large" />
          <Typography className={classes.noInvitationText} variant="h4">
            There are no pending invitations...
          </Typography>
        </Box>
      </BaseContainer>
    )
  }

  return (
    <BaseContainer>
      {basicDialog.dialog}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box>
          <TableTitleWithTotalCount
            title="Workspace invitations"
            qualifier="invitation"
            totalCount={workspaceInvitations.data.totalCount}
          />
        </Box>
      </Box>
      <Paper>
        <ServerSideTable<GetWorkspaceInvitationDto>
          columns={columns}
          items={workspaceInvitations.data.data}
          limit={workspaceInvitations.data.limit}
          totalCount={workspaceInvitations.data.totalCount}
          defaultParams={{
            page,
          }}
          onPageChange={setPage}
          onLimitChange={setLimit}
        />
      </Paper>
    </BaseContainer>
  )
}

export default WorkspaceInvitationScene
