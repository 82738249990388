import { Trade } from ".."
import { NewFullTransactionDto, NewInListTransactionDto } from "services/api/openapi"

export const convertMovements = <TTransaction extends NewInListTransactionDto | NewFullTransactionDto>(
  transaction: TTransaction,
): [TTransaction["movements"], Trade<TTransaction["movements"][number]>[]] => {
  const trades = transaction.trades.reduce((a, c) => {
    const mvtIn = transaction.movements.find((m) => m.id === c.movementInId)
    const mvtOut = transaction.movements.find((m) => m.id === c.movementOutId)

    if (!mvtIn || !mvtOut) {
      throw new Error("Missing movement for trade reconstruction")
    }

    const trade: Trade = {
      in: mvtIn,
      out: mvtOut,
    }

    return a.concat(trade)
  }, new Array<Trade>())

  // Weird typescript error fixed by spreading the array
  const movements = [...transaction.movements].reduce((a, c) => {
    if (transaction.trades.some((t) => t.movementInId === c.id || t.movementOutId === c.id)) return a
    return a.concat(c)
  }, new Array<TTransaction["movements"][number]>())

  return [movements, trades]
}
