import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core"
import React from "react"
import ButtonUI from "CryptioUI/Button"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      weight: "bold",
    },
    logo: {
      width: "200px",
      height: "200px",
      marginRight: theme.spacing(2),
    },
  }),
)

interface Props {
  setValue: (value: number) => void
}

const FavoriteNoFilters = (props: Props): JSX.Element => {
  const { setValue } = props
  const classes = useStyles()

  return (
    <Box m={2} justifyContent="center" alignItems="center" width="100%" display="flex" flexDirection="column">
      <img src={"/images/gear.png"} alt="gear no filter" className={classes.logo} />
      <Typography variant={"h4"} className={classes.subtitle}>
        No favorite filters
      </Typography>
      <Box mt={4}>
        <ButtonUI
          onClick={() => {
            setValue(0)
          }}
        >
          New Filter
        </ButtonUI>
      </Box>
    </Box>
  )
}

export default FavoriteNoFilters
