import { Box, createStyles, makeStyles, TextField, Theme } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { ObjectTypedKeys } from "pure-shared"
import React, { useContext, useMemo } from "react"
import { FilterTypeWithoutDate, FilterTypeWithoutDateState } from ".."
import { GetUserDto, GetWorkspaceDto } from "services/api/openapi"
import { FilterContext } from "services/context/filterContext"
import { UserContext } from "services/context/userContext"
import { WorkspaceContext } from "services/context/workspaceContext"
import { PrettyFilterType } from "../useFilterMap"
import WarningTypography from "components/WarningTypography"
import ConditionalTooltip from "components/ConditionalTooltip"
import { ReactComponent as Delete } from "CryptioUI/assets/icons/delete.svg"
import IsNot, { IsNotType } from "./IsNot"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lineFilterContainer: {
      display: "flex",
      justifyContent: "centre",
      alignItems: "flex-start",
      flexDirection: "row",
      marginTop: theme.spacing(1.5),
    },
    selectorFilter: {
      flexGrow: 1,
      marginRight: theme.spacing(1),
    },
    selectorIsNot: {
      flexGrow: 0.2,
      marginRight: theme.spacing(1),
    },
    selectorValue: {
      maxWidht: "20em",
      width: "20em",
    },
    deleteButton: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    deleteIcon: {
      "&:hover": {
        cursor: "pointer",
      },
      "height": "1.1em",
      "width": "1.1em",
    },
  }),
)

const adminFilters: FilterTypeWithoutDate[] = []
const accountingFilters: FilterTypeWithoutDate[] = ["transaction_mapping_status", "accounting_mapping_status"]
const thirdPartyAccountingFilters: FilterTypeWithoutDate[] = ["synchronization_status", "synchronization_mode"]
const numericFilters: FilterTypeWithoutDate[] = ["relative_fiat_value"]
const rangedFilters: FilterTypeWithoutDate[] = ["range_fiat_value"]
const feeWarningFilters: FilterTypeWithoutDate[] = ["relative_fiat_value", "range_fiat_value"]

export const isFilterAvailable = (
  currentFilter: FilterTypeWithoutDate,
  workspace: GetWorkspaceDto,
  user: GetUserDto,
): boolean => {
  const doNothaveAccessToAdminFilters = user.role === "normal" && adminFilters.includes(currentFilter)
  const doNothaveAccessToAcountingFilters =
    workspace.accountingIntegration === null && accountingFilters.includes(currentFilter)
  const doNotHaveAccessToSyncroFilters =
    !workspace.accountingIntegration && thirdPartyAccountingFilters.includes(currentFilter)

  if (doNothaveAccessToAdminFilters || doNothaveAccessToAcountingFilters || doNotHaveAccessToSyncroFilters) {
    return false
  }
  return true
}

interface Props {
  mapFactory: PrettyFilterType
  tempFilterWithoutDate: FilterTypeWithoutDateState[]
  setTempFilterWithoutDate: (value: FilterTypeWithoutDateState[]) => void
  setIsErrorValidation: (newValue: boolean) => void
  isErrorValidation: boolean
}

function useGenerateFilters(props: Props) {
  const { mapFactory, tempFilterWithoutDate, setTempFilterWithoutDate, setIsErrorValidation, isErrorValidation } = props
  const { isNeedReview, isTab } = useContext(FilterContext)
  const { workspace } = useContext(WorkspaceContext)
  const { user } = useContext(UserContext)

  const classes = useStyles()

  const transactionSyncTypeArray = ObjectTypedKeys(mapFactory)

  const convertBooleanToIsNotEnum = (isNot: boolean) => {
    if (isNot) return "isNot"
    else return "is"
  }

  const convertIsNotEnumToBoolean = (isNot: string) => {
    if (isNot === "is") return false
    else return true
  }

  const getFilterIsNotType = (filterKey: FilterTypeWithoutDate): IsNotType => {
    if (rangedFilters.includes(filterKey)) return "ranged"
    if (numericFilters.includes(filterKey)) return "numeric"
    return "normal"
  }

  const filtersEditable = useMemo(
    () =>
      tempFilterWithoutDate.map((filter, index) => {
        const isDisabled =
          isNeedReview &&
          ((isTab("missingLabel") && filter.type === "labels") ||
            (isTab("internalTransfer") && filter.type === "internal_transfer_status") ||
            (isTab("incompleteChartOfAccounts") && filter.type === "accounting_mapping_status") ||
            (isTab("readyToBeSynchronized") &&
              (filter.type === "synchronization_status" ||
                filter.type === "cost_basis_error_statuses" ||
                filter.type === "accounting_mapping_status")))

        // TODO: Better typing or validation
        if (mapFactory[filter.type].validation) {
          // @ts-ignore
          const isError = mapFactory[filter.type].validation(filter.value)
          if (isErrorValidation !== !isError) {
            setIsErrorValidation(!isError)
          }
        }

        return (
          <div key={filter.type}>
            <ConditionalTooltip
              tooltipMessage="This review module is using this filter by default"
              disabled={isDisabled}
            >
              <div className={classes.lineFilterContainer} data-test-id="filter-row">
                <div className={classes.selectorFilter} data-test-id="filter-type">
                  <Autocomplete
                    getOptionLabel={(option) => mapFactory[option].name}
                    size="small"
                    multiple={false}
                    options={transactionSyncTypeArray}
                    autoComplete
                    filterOptions={(options, state) => {
                      return options.filter((option) => {
                        const isAlreadySelected = tempFilterWithoutDate.some(
                          (currentOption) => currentOption.type === option,
                        )
                        if (isAlreadySelected || !isFilterAvailable(option, workspace, user)) {
                          return false
                        }
                        return mapFactory[option].name.toLowerCase().includes(state.inputValue.toLowerCase())
                      })
                    }}
                    fullWidth
                    getOptionSelected={(option, value) => mapFactory[option].key === value}
                    includeInputInList
                    disableCloseOnSelect
                    disableClearable
                    disabled={isDisabled}
                    filterSelectedOptions
                    value={mapFactory[filter.type].key}
                    onChange={(_, newValue) => {
                      if (newValue) {
                        const cpy = JSON.parse(JSON.stringify(tempFilterWithoutDate))

                        cpy[index] = mapFactory[newValue].defaultValue
                        setTempFilterWithoutDate(cpy)
                      }
                    }}
                    renderInput={(params) => <TextField {...params} className="capitalize" />}
                  />
                </div>
                <div className={classes.selectorIsNot} data-test-id="filter-relation">
                  <IsNot
                    type={getFilterIsNotType(filter.type)}
                    value={convertBooleanToIsNotEnum(tempFilterWithoutDate[index].isNot)}
                    disabled={isDisabled}
                    onChange={(value) => {
                      const cpy = JSON.parse(JSON.stringify(tempFilterWithoutDate))

                      cpy[index].isNot = convertIsNotEnumToBoolean(value ?? "")
                      setTempFilterWithoutDate(cpy)
                    }}
                  />
                </div>
                <div className={classes.selectorValue} data-test-id="filter-value">
                  {
                    // It's ugly but I don't have any other solution :/
                    mapFactory[tempFilterWithoutDate[index].type].render({
                      // @ts-ignore
                      value: filter.value,
                      // @ts-ignore
                      index: index,
                      // @ts-ignore
                      disabled: isDisabled,
                    })
                  }
                </div>
                <div className="flex justify-center items-center h-[28px] pl-2">
                  <button
                    className={classes.deleteButton}
                    disabled={isDisabled}
                    onClick={() => {
                      const cpy = JSON.parse(JSON.stringify(tempFilterWithoutDate))

                      cpy.splice(index, 1)
                      setTempFilterWithoutDate(cpy)
                    }}
                  >
                    <Delete className={classes.deleteIcon} />
                  </button>
                </div>
              </div>
            </ConditionalTooltip>
            {feeWarningFilters.includes(mapFactory[filter.type].key) && (
              <Box mt={0.5}>
                <WarningTypography>Fees are not taken into account in this filter</WarningTypography>
              </Box>
            )}
          </div>
        )
      }),
    [
      workspace,
      user,
      tempFilterWithoutDate,
      isNeedReview,
      isTab,
      classes.deleteButton,
      classes.deleteIcon,
      classes.lineFilterContainer,
      classes.selectorFilter,
      classes.selectorIsNot,
      classes.selectorValue,
      mapFactory,
      setTempFilterWithoutDate,
      transactionSyncTypeArray,
      isErrorValidation,
      setIsErrorValidation,
    ],
  )

  return {
    filtersEditable,
  }
}

export default useGenerateFilters
