export const BASE_PATH = "http://localhost".replace(/\/+$/, "")

const isBlob = (value: any) => typeof Blob !== "undefined" && value instanceof Blob

export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
}

export type FetchAPI = WindowOrWorkerGlobalScope["fetch"]

export type Json = any
export type HTTPMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE" | "OPTIONS" | "HEAD"
export type HTTPHeaders = { [key: string]: string }
export type HTTPQuery = {
  [key: string]: string | number | null | boolean | Array<string | number | null | boolean> | HTTPQuery
}
export type HTTPBody = Json | FormData | URLSearchParams
export type ModelPropertyNaming = "camelCase" | "snake_case" | "PascalCase" | "original"

export interface FetchParams {
  url: string
  init: RequestInit
}

export interface RequestOpts {
  path: string
  method: HTTPMethod
  headers: HTTPHeaders
  query?: HTTPQuery
  body?: HTTPBody
}

export function exists(json: any, key: string) {
  const value = json[key]
  return value !== null && value !== undefined
}

export function mapValues(data: any, fn: (item: any) => any) {
  return Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: fn(data[key]) }), {})
}

export function canConsumeForm(consumes: Consume[]): boolean {
  for (const consume of consumes) {
    if ("multipart/form-data" === consume.contentType) {
      return true
    }
  }
  return false
}

export interface Consume {
  contentType: string
}

export interface ApiResponse<T> {
  raw: Response
  value(): Promise<T>
}
