import { Box } from "@material-ui/core"
import { ToastProvider } from "CryptioUI/Toaster"
import React from "react"

import LoadingSpinner from "./components/misc/LoadingSpinner"
import PrivateApp from "./PrivateApp"
import PublicApp from "./PublicApp"
import XeroPostRegister from "./scenes/Register/XeroPostRegister"
import api from "./services/api"

const LoginGate = (): JSX.Element => {
  const user = api.user.useUser()

  if (user.isLoading) {
    return (
      <Box height="100vh">
        <LoadingSpinner />
      </Box>
    )
  }

  if (user.data === undefined) {
    return <PublicApp />
  }

  if (user.data.isXeroRegisterCompleted === false) {
    return (
      <ToastProvider>
        <XeroPostRegister user={user.data} />
      </ToastProvider>
    )
  }

  return <PrivateApp user={user.data} />
}

export default LoginGate
