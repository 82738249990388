import { Grid, makeStyles, Typography } from "@material-ui/core"
import { prettyEmptyOrNull } from "pure-shared"
import React, { useContext } from "react"

import { GetAssetDto } from "services/api/openapi"
import BackOfficeView from "../misc/BackOfficeView"
import { UserContext } from "services/context/userContext"
import { isProd } from "services/variables"

interface Props {
  asset: GetAssetDto
}

const useStyles = makeStyles(() => ({
  regular: {},
  unknownExchange: {
    backgroundColor: "#E57373",
  },
}))

const AssetOptionInAutocomplete = ({ asset }: Props) => {
  const classes = useStyles()
  const userCtx = useContext(UserContext)

  const isBackofficeUser =
    !isProd ||
    userCtx.user.role === "business_administrator" ||
    userCtx.user.role === "reporter" ||
    userCtx.user.role === "tech_administrator"
  const style = asset.type === "exchange_unknown" && isBackofficeUser ? classes.unknownExchange : classes.regular

  return (
    <Grid container alignItems="center" className={style}>
      <Grid item xs>
        <Typography variant="body1">{prettyEmptyOrNull(asset.symbol)}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {asset.name}
        </Typography>
        <BackOfficeView>
          <Typography variant="subtitle1" color="textSecondary">
            {asset.uniqueSymbol}
          </Typography>
        </BackOfficeView>
      </Grid>
    </Grid>
  )
}

export default AssetOptionInAutocomplete
