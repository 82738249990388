import { PaletteType } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
// @ts-ignore
import type {} from "@material-ui/lab/themeAugmentation"

export const PrimaryColor = "#02373D"
const SecondaryColor = "#FFFFFF"
export const BackgroundColor = "#F5F5F5"
export const AlternateBackgroundColor = "#D9D9DD"

export const TextColor = "#262A3E"
export const GreenColor = "#168D87"
export const RedColor = "#C3255E"
export const White = "#FFFFFF"
export const LightGrey = "#E9EAF0"
export const DarkGrey = "#404040"
export const LightBlue = "#69BBFF"
export const Orange = "#EFAA5C"
export const Disabled = "#b6b9c6"

export const GainColor = GreenColor
export const LossColor = RedColor
export const FeeColor = PrimaryColor

export const WarningColor = "#e67e22"

export const ErrorColor = RedColor

export const LinkColor = "#1e70bf"

// custom theme for this app
const getTheme = (paletteType: PaletteType) =>
  createTheme({
    props: {
      MuiDrawer: {
        anchor: "right",
      },
      MuiPopover: {
        keepMounted: true,
      },
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiButton: {
        variant: "contained",
        color: "primary",
      },
      MuiTextField: {
        variant: "standard",
      },
      MuiSwitch: {
        color: "primary",
      },
    },
    overrides: {
      MuiTableCell: {
        root: {
          padding: 0,
          paddingTop: "8px",
          paddingBottom: "8px",
        },
      },
      MuiAutocomplete: {
        option: {
          // Hover
          // '&[data-focus="true"]': {
          //   backgroundColor:
          // },
          // Selected

          // selected option(s) in autocomplete are yellow (Secondary color) otherwise
          '&[aria-selected="true"]': {
            backgroundColor: LightGrey,
          },
        },
      },
      MuiListItem: {
        root: {
          // weird yellow focus navbar
          "&.Mui-focusVisible": {
            backgroundColor: PrimaryColor,
          },
        },
      },
      MuiAvatar: {
        square: {
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
        },
      },
      MuiFormControl: {
        root: {
          borderColor: PrimaryColor,
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: "1.1rem",
        },
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: 0,
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color: PrimaryColor,
        },
      },
      MuiButton: {
        root: {
          textTransform: "none",
        },
      },
      MuiOutlinedInput: {
        input: {
          padding: "12px",
        },
      },
      MuiPaper: {
        root: {
          // border: "2px solid rgba(100, 100, 100, 0.1)",
        },
        /*elevation1: {
          boxShadow: "none",
        },*/
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: "white",
          color: PrimaryColor,
          borderColor: AlternateBackgroundColor,
          border: `1px solid`,
          boxShadow: "2px 2px 15px 0 rgba(0,0,0,0.15)",
          fontSize: "0.9rem",
        },
      },
      MuiMenuItem: {
        root: {
          // HACK: Without this Badges are cut off in MenuItem.
          // Material UI issue #21639
          // TODO: Remove this when upgrading to Material UI 5.0.
          overflow: "visible",
        },
      },
    },
    shape: {
      borderRadius: 8,
    },
    palette: {
      type: paletteType,
      primary: {
        main: PrimaryColor,
      },
      secondary: {
        main: SecondaryColor,
      },
      background: {
        default: BackgroundColor,
      },
      text: {
        primary: TextColor,
        disabled: Disabled,
      },
      divider: AlternateBackgroundColor,
      action: {
        selected: SecondaryColor,
      },
    },
    typography: {
      fontFamily: `"Nunito"`,
      h1: {
        fontSize: "2.75rem",
        fontWeight: 400,
      },
      h2: {
        fontSize: "2.2rem",
      },
      h3: {
        fontSize: "1.75rem",
        fontWeight: 500,
        letterSpacing: 0,
      },
      h4: {
        fontSize: "1.55rem",
      },
      h5: {
        fontSize: "1.1rem",
      },
      h6: {
        fontSize: "1rem", // please set a size if you want to use it
      },
      body1: {
        fontSize: "1.12rem",
        fontWeight: 300,
      },
      body2: {
        fontSize: "1.00rem",
      },
      subtitle1: {
        fontSize: "0.95rem",
        fontWeight: 300,
      },
      subtitle2: {
        fontSize: "0.90rem",
        fontWeight: 400,
      },
    },
  })

export default getTheme
