import { useQuery, UseQueryOptions } from "react-query"
import { queryClient } from "../../../.."
import { useWorkspaceMutation, useWorkspaceQuery } from "../../../context/workspaceContext"
import {
  BackOfficeApi,
  BackOfficeDecryptWalletCredentialsRequest,
  GetMovementMetadataDto,
  GetTransactionMetadataDto,
} from "../../openapi"
import { GET_WALLETS_KEY } from "../wallet"

const BACK_OFFICE_GET_WALLET_CREDENTIALS_KEY = "backOfficeGetWalletCredentials"
const BACK_OFFICE_GET_TRANSACTION_METADATA_KEY = "backOfficeGetTransactionMetadata"
const BACK_OFFICE_GET_MOVEMENT_METADATA_KEY = "backOfficeGetMovementMetadata"

export const backOfficeWalletActions = {
  useUpdateTransactionValuation: () => {
    return useWorkspaceMutation(BackOfficeApi.backOfficeUpdateTransactionValuation, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WALLETS_KEY)
      },
    })
  },
  useBreakTrade: () => {
    return useWorkspaceMutation(BackOfficeApi.backOfficeBreakTrade, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WALLETS_KEY)
      },
    })
  },
  useWalletCredentials: (variables: BackOfficeDecryptWalletCredentialsRequest) => {
    return useQuery([BACK_OFFICE_GET_WALLET_CREDENTIALS_KEY, variables], () =>
      BackOfficeApi.backOfficeDecryptWalletCredentials(variables),
    )
  },
  useTransactionMetadata: (transactionId: string, option?: UseQueryOptions<GetTransactionMetadataDto>) => {
    return useWorkspaceQuery(
      [BACK_OFFICE_GET_TRANSACTION_METADATA_KEY, transactionId],
      BackOfficeApi.backOfficeTransactionMetadata,
      { transactionId: transactionId },
      {
        ...option,
      },
    )
  },
  useMovementMetadata: (movementId: string, option?: UseQueryOptions<GetMovementMetadataDto>) => {
    return useWorkspaceQuery(
      [BACK_OFFICE_GET_MOVEMENT_METADATA_KEY, movementId],
      BackOfficeApi.backOfficeMovementMetadata,
      { movementId: movementId },
      {
        ...option,
      },
    )
  },
  useUpdateTransactionListValuation: () => {
    return useWorkspaceMutation(BackOfficeApi.backOfficeUpdateTransactionsValuation, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WALLETS_KEY)
      },
    })
  },
}
