import { Box, Divider, Typography } from "@material-ui/core"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { WarningRounded } from "@material-ui/icons"
import TooltipUI from "CryptioUI/Tooltip"
import { TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import { notNullGuard } from "pure-shared"
import React from "react"

import { GetWorkspaceDto } from "../../services/api/openapi"
import { roundToTwoDecimalMax } from "../../services/utils/processNumber"
import Spinner from "../Spinner/Spinner"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningIcon: {
      marginRight: theme.spacing(1.5),
      color: "white",
    },
  }),
)

export const WorkspaceStatusDisplay = ({ workspace }: { workspace: GetWorkspaceDto }): JSX.Element => {
  const classes = useStyles()

  const costBasis = {
    ...workspace.costBasisStatus,
    progress: roundToTwoDecimalMax(workspace.costBasisStatus.progress),
  }

  const costBasisImpaired = {
    ...workspace.costBasisImpairment,
    progress: roundToTwoDecimalMax(workspace.costBasisImpairment.progress),
  }

  const mapping = {
    ...workspace.coaMappingStatus,
    progress: roundToTwoDecimalMax(workspace.coaMappingStatus.progress),
  }
  const deleting = {
    ...workspace.deletingStatus,
    progress: roundToTwoDecimalMax(workspace.deletingStatus.progress),
  }

  let costBasisDisplay: JSX.Element | null = null
  switch (costBasis.status) {
    case "pending":
      costBasisDisplay = <Typography variant="body2">Pending cost basis calculation...</Typography>
      break
    case "active":
      costBasisDisplay = (
        <Typography variant="body2">
          Calculating cost basis... {costBasis.progress !== null && `(${costBasis.progress}%)`}
        </Typography>
      )
      break
    default:
      break
  }

  let costBasisImpairmentDisplay: JSX.Element | null = null
  switch (costBasisImpaired.status) {
    case "pending":
      costBasisImpairmentDisplay = <Typography variant="body2">Pending cost basis impairment...</Typography>
      break
    case "active":
      costBasisImpairmentDisplay = (
        <Typography variant="body2">
          Impairing cost basis... {costBasisImpaired.progress !== null && `(${costBasisImpaired.progress}%)`}
        </Typography>
      )
      break
    default:
      break
  }

  let mappingDisplay: JSX.Element | null = null
  switch (mapping.status) {
    case "pending":
      mappingDisplay = <Typography variant="body2">Pending chart account mapping calculation...</Typography>
      break
    case "active":
      mappingDisplay = (
        <Typography variant="body2">
          Calculating chart of account mapping... {mapping.progress !== null && `(${mapping.progress}%)`}
        </Typography>
      )
      break
    default:
      break
  }

  let lockDisplay: JSX.Element | null = null
  switch (workspace.lockStatus.status) {
    case "pending":
      lockDisplay = <Typography variant="body2">Pending lock...</Typography>
      break
    case "active":
      lockDisplay = <Typography variant="body2">Updating lock...</Typography>
      break
    default:
      break
  }

  let deletingDisplay: JSX.Element | null = null
  switch (deleting.status) {
    case "active":
      deletingDisplay = <Typography variant="body2">Workspace deleting...</Typography>
      break
    default:
      break
  }

  const statusDisplay = (() => {
    switch (workspace.status) {
      case "duplicating":
        return <Typography variant="body2">Duplicating workspace...</Typography>
      case "error":
        return <Typography variant="body2">Error during workspace creation</Typography>
      default:
        return null
    }
  })()

  const valuationUpdatingDisplay = workspace.hasValuationJobsRunning ? (
    <Typography variant="body2">Updating wallet valuation...</Typography>
  ) : null

  // Keep fixed index for react
  const displays = [
    lockDisplay,
    costBasisDisplay,
    mappingDisplay,
    costBasisImpairmentDisplay,
    deletingDisplay,
    statusDisplay,
    valuationUpdatingDisplay,
  ]

  if (displays.filter(notNullGuard).length === 0) {
    if (workspace.isCoaMappingDirty || workspace.isCostBasisDirty || workspace.isCostBasisImpairmentDirty) {
      return (
        <TooltipUI
          content={
            <Box>
              {workspace.isCostBasisDirty && (
                <Typography variant="body2">
                  Some modifications on pricing have been made. Please update the cost basis.
                </Typography>
              )}

              {workspace.isCostBasisImpairmentDirty && (
                <Typography variant="body2">
                  Some modifications on impairment have been made. Please update the cost basis impairment.
                </Typography>
              )}

              {workspace.isCoaMappingDirty && (
                <>
                  {workspace.isCostBasisDirty && <Divider style={{ marginTop: 8, marginBottom: 8 }} />}
                  <Typography variant="body2">
                    You have done some modifications regarding the chart of accounts mapping. Please go to the Chart of
                    accounts and make sure to apply the new mappings.
                  </Typography>
                </>
              )}
            </Box>
          }
        >
          <WarningRounded className={classes.warningIcon} data-test-id="workspace-status-warning" />
        </TooltipUI>
      )
    }
    return <></>
  }

  const activeComputation = [costBasis, costBasisImpaired, mapping].filter(
    (status) => status.status === "active" && status.progress !== null,
  )

  return (
    <TooltipUI
      content={
        <Box>
          {displays.map((display, i) => (
            <React.Fragment key={i}>{display}</React.Fragment>
          ))}
        </Box>
      }
    >
      <Box display="flex" justifyContent="center" mr={1.5}>
        {workspace.status === "error" ? (
          <WarningRounded className={classes.warningIcon} />
        ) : (
          <Box data-test-id="coa-update-icon">
            <Spinner />
          </Box>
        )}
        {activeComputation.length > 0 && (
          <TypographyUI variant={TypographyVariant.BUTTON} className="text-white">
            &nbsp;
            {(
              activeComputation.reduce((sum, { progress }) => sum + (progress ?? 0), 0) / activeComputation.length
            ).toFixed(2)}
            %
          </TypographyUI>
        )}
      </Box>
    </TooltipUI>
  )
}
