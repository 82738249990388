import { ButtonGroup } from "@material-ui/core"
import React from "react"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"

import { SubscriptionPayingDetailsPeriodTypeEnum } from "services/api/openapi"

interface Props {
  value: SubscriptionPayingDetailsPeriodTypeEnum
  setValue: (val: SubscriptionPayingDetailsPeriodTypeEnum) => void
}

//TODO: check that it's really 12% discount
const PeriodSelector = (props: Props): JSX.Element => (
  <ButtonGroup disableElevation variant="contained" color="primary">
    <ButtonUI
      onClick={() => props.setValue("monthly")}
      mode={props.value === "monthly" ? Mode.DEFAULT : Mode.CONTAINED}
    >
      Monthly
    </ButtonUI>
    <ButtonUI onClick={() => props.setValue("yearly")} mode={props.value === "yearly" ? Mode.OUTLINED : Mode.CONTAINED}>
      Yearly
    </ButtonUI>
  </ButtonGroup>
)

export default PeriodSelector
