import React from "react"
import { IDataTableProps } from "react-data-table-component"

import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from "services/constants"
import BaseTable, { BaseTableProps } from "."

interface Props<T, TSortEnum extends string | undefined> extends BaseTableProps<T, TSortEnum> {
  totalCount: number
  limit?: number
  onLimitChange?: (newLimit: number) => void
  onPageChange?: (newPage: number) => void

  paginationRowsPerPageOptions?: number[]

  onRowClicked?: IDataTableProps<T>["onRowClicked"]
  expandComponent?: IDataTableProps<T>["expandableRowsComponent"]
  dense?: IDataTableProps<T>["dense"]
  conditionalRowStyles?: IDataTableProps<T>["conditionalRowStyles"]
}

const ServerSideTable = <T, TSortEnum extends string | undefined = undefined>(
  props: Props<T, TSortEnum>,
): JSX.Element => {
  const {
    totalCount,
    limit,
    paginationRowsPerPageOptions,
    fixedScroll,
    pagination = true,
    defaultParams,
    expandOnRowClicked,
    conditionalRowStyles,
    dense,
    onRowClicked = undefined,
  } = props
  return (
    <BaseTable<T, TSortEnum>
      conditionalRowStyles={conditionalRowStyles}
      customStyle={{
        tableWrapper: {
          style: {
            BorderRadius: "12px",
            width: "100%",
          },
        },
        rows: {
          style: {
            // "borderRadius": "8px",
            "minHeight": "64px",
            "maxHeight": "64px",
            "height": "64px",
            "&:hover": {
              cursor: onRowClicked ? "pointer" : "auto",
            },
          },
          highlightOnHoverStyle: {
            borderRadius: 0,
          },
        },
        headRow: {
          style: {
            maxHeight: "42px",
            minHeight: "42px",
            height: "42px",
            backgroundColor: "#F2F4F8",
          },
        },
        headCells: {
          style: {
            textTransform: "uppercase",
            fontFamily: "Inter",
            color: "#60657A",
            fontSize: "12px",
            fontWeight: 700,
          },
        },
        pagination: {
          style: {
            maxHeight: "42px",
            minHeight: "42px",
            height: "42px",
            backgroundColor: "transparant",
          },
        },
      }}
      expandOnRowClicked={expandOnRowClicked}
      fixedScroll={fixedScroll}
      columns={props.columns}
      items={props.items}
      onSort={props.onSort}
      onSelectedChange={props.onSelectedChange}
      onRowClicked={props.onRowClicked}
      expandComponent={props.expandComponent}
      pagination={pagination}
      paginationServer
      onChangePage={props.onPageChange}
      onChangeRowsPerPage={props.onLimitChange}
      paginationRowsPerPageOptions={paginationRowsPerPageOptions ?? DEFAULT_ROWS_PER_PAGE_OPTIONS}
      paginationTotalRows={totalCount}
      paginationPerPage={limit}
      dense={dense}
      defaultParams={defaultParams}
    />
  )
}

export default ServerSideTable
