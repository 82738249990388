import { Link } from "@material-ui/core"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import React, { useContext } from "react"
import { Link as RouterLink } from "react-router-dom"

import { URLS } from "../../routes"
import { GetUserDto } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import ChildrenProps from "services/misc/childrenProps"
import Footer from "./Footer"
import PrivateLayout from "./PrivateLayout"
import PublicLayout from "./PublicLayout"
import BannerDefiModule from "scenes/DeFi/BannerDefiModule"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      overflowX: "auto",
      minWidth: "100vw",
    },
    grow: {
      flexGrow: 1,
      marginBottom: "-3rem",
    },
    title: {
      width: "145px",
      minWidth: "145px",
      display: "flex",
      justifyContent: "center",
    },
    logo: {
      width: "120px",
      minWidth: "120px",
    },
    warningBanner: {
      width: "100%",
      height: "100vh",
      border: `${theme.spacing(1.5)}px #ba000d solid`,
      zIndex: 10000,
      position: "fixed",
      top: 0,
      left: 0,
      pointerEvents: "none",
    },
    warningPadding: {
      padding: theme.spacing(1.5),
    },
  }),
)

interface Props extends ChildrenProps {
  user?: GetUserDto
  noButton?: boolean
}

const AppLayout = (props: Props): JSX.Element => {
  const { user, noButton = false } = props
  const classes = useStyles()
  const workspaceCtx = useContext(WorkspaceContext)

  return (
    <div className={workspaceCtx?.workspace.isInAdminMode === true ? classes.warningPadding : ""}>
      <div className={workspaceCtx?.workspace.isInAdminMode === true ? classes.warningBanner : ""} />
      <div className={classes.root}>
        <div className="bg-primary-400 drop-shadow-lg h-fit min-w-fit w-full flex flex-row overflow-x-overlay justify-between px-20">
          <div className="flex flex-row justify-center overflow-auto items-center w-full">
            <div className="flex justify-start items-center w-fit">
              <Link component={RouterLink} to={URLS.Portfolio} underline="none" className={classes.title}>
                <img src={`/newLogo.svg`} alt="The Cryptio logo" className={classes.logo} />
              </Link>
            </div>
            {user ? <PrivateLayout user={user} /> : <PublicLayout noButton={noButton} />}
          </div>
        </div>
        <BannerDefiModule />
        <main className={classes.grow}>{props.children}</main>
        <Footer />
      </div>
    </div>
  )
}

export default AppLayout
