import { useTypedController } from "@hookform/strictly-typed"
import { Typography, Grid } from "@material-ui/core"
import dayjs from "dayjs"
import { ReportFormComponentProps } from "."
import { DrawerFormSection } from "../../../../components/Drawer/DrawerItems"
import CustomSelector from "../../../../components/selector/CustomSelector"
import api from "../../../../services/api"
import { DrawerReportForm } from "../ReportForm"

const ReportSelectImpairment = ({ control }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })

  return (
    <DrawerFormSection htmlFor="source-select" name="Applied impairment">
      <TypedController
        name="impairment"
        defaultValue={null}
        rules={{ required: true }}
        render={({ ref, onChange, value, ...rest }) => (
          <CustomSelector
            id="source-select"
            {...rest}
            value={value}
            inputRef={ref}
            onChange={(_, newValue) => onChange(newValue)}
            getOptionLabel={(option) => option.endDate}
            defaultPaginatedQueryProps={{
              sortDirection: "ascending",
              isApplied: true,
              excludedIds: value ? [value.id] : undefined,
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            usePaginatedQuery={api.impairment.useImpairments}
            size="small"
            filterSelectedOptions
            renderOption={(option) => (
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="body1">{option.endDate}</Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    Applied {dayjs(option.appliedAt).tz().fromNow()}
                  </Typography>
                </Grid>
              </Grid>
            )}
          />
        )}
      />
    </DrawerFormSection>
  )
}

export default ReportSelectImpairment
