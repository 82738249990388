import { Box, IconButton, makeStyles, Paper, Typography } from "@material-ui/core"
import { StarBorderRounded, StarRounded } from "@material-ui/icons"
import React, { useCallback, useContext, useMemo } from "react"
import { IDataTableColumn } from "react-data-table-component"

import BaseContainer from "components/Container"
import MainTitleView from "components/MainTitleView"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDrawer from "components/misc/useDrawer"
import { GenericSorting } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import TableTitleWithTotalCount from "components/Table/TableTitleWithTotalCount"
import ButtonUI from "CryptioUI/Button"
import { ButtonSize, Mode } from "CryptioUI/types"
import { iconStyleWhite } from "CryptioUI/Utilities/config"
import api from "services/api"
import { GetMinimalWorkspaceDto, GetWorkspacesSortByEnum } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import WorkspaceFilter from "./filters/WorkspaceFilter"
import WorkspaceInvitationExplanation from "./WorkspaceInvitationExplanation"
import { ReactComponent as AddIcon } from "CryptioUI/assets/icons/add.svg"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

export interface WorkspaceParameters {
  queryName: string
  minimumUser: string | undefined
  minimumTransaction: string | undefined
}

export const defaultWorkspaceParams: WorkspaceParameters = {
  queryName: "",
  minimumTransaction: undefined,
  minimumUser: undefined,
}

export const workspaceHasAnyFilter = (filters: WorkspaceParameters) =>
  Boolean(filters.queryName || filters.minimumTransaction || filters.minimumUser)

const defaultWorkspaceSorting: GenericSorting<GetWorkspacesSortByEnum> = {
  sortDirection: "descending",
  sortBy: "created_at",
}

const useStyles = makeStyles((theme) => ({
  noWorkspaceText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}))

const WorkspaceScene = (): JSX.Element => {
  const classes = useStyles()
  const workspaceCtx = useContext(WorkspaceContext)

  // const [limit, setLimit] = useLocalStorage<number>("workspaces_view_limit", DEFAULT_ROWS_PER_PAGE)
  const { params, setLimit, setPage, setParams } = usePaginatedParams<WorkspaceParameters, GetWorkspacesSortByEnum>(
    defaultWorkspaceParams,
    defaultWorkspaceSorting,
  )

  const user = api.user.useUser()
  const workspaces = api.workspace.useWorkspaces({
    page: params.page,
    limit: params.limit,
    queryName: params.queryName,
    minimumTransaction: params.minimumTransaction ? Number(params.minimumTransaction) : undefined,
    minimumUser: params.minimumUser ? Number(params.minimumUser) : undefined,
  })

  const [workspaceDrawer, openWorkspaceDrawer] = useDrawer("workspace")
  const toast = useToast()

  const { mutateAsync: toggleBookmarkMutation } = api.workspace.useToggleBookmark()

  const toggleBookmark = useCallback(
    async (workspace: GetMinimalWorkspaceDto) => {
      try {
        await toggleBookmarkMutation({ workspaceId: workspace.id })
        toast.open(`Workspace ${workspace.isBookmarked ? "removed from" : "added to"} bookmarks`, {
          variant: "success",
        })
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [toggleBookmarkMutation, toast],
  )

  const columns = useMemo<IDataTableColumn<GetMinimalWorkspaceDto>[]>(
    () => [
      {
        name: "Name",
        selector: "name",
      },
      {
        name: "Description",
        selector: "description",
      },
      {
        name: "Role",
        selector: "roleName",
        format: ({ ownerUserId, roleName }) => (ownerUserId === user.data?.id ? "Owner" : roleName),
      },
      {
        name: "Currency",
        selector: "defaultCurrencySymbol",
      },
      {
        name: "Users",
        selector: "userCount",
      },
      {
        name: "Transactions",
        selector: "transactionCount",
      },
      {
        name: "Actions",
        selector: "id",
        minWidth: "230px",
        format: function formatSelectWorkspace(row: GetMinimalWorkspaceDto) {
          const currentWorkspace = row.id === workspaceCtx.workspace.id
          return (
            <ButtonUI
              mode={currentWorkspace ? Mode.CONTAINED : Mode.DEFAULT}
              size={ButtonSize.SM}
              onClick={() => {
                if (!currentWorkspace) workspaceCtx.changeWorkspace(row.id)
              }}
            >
              {currentWorkspace ? "SELECTED WORKSPACE" : "SWITCH WORKSPACE"}
            </ButtonUI>
          )
        },
      },
      {
        name: "",
        maxWidth: "10px",
        cell: function bookmarkWorkspace(row) {
          return (
            <IconButton onClick={() => toggleBookmark(row)}>
              {row.isBookmarked ? <StarRounded /> : <StarBorderRounded />}
            </IconButton>
          )
        },
      },
    ],
    [workspaceCtx, user.data?.id, toggleBookmark],
  )
  if (user.isError || workspaces.isError) return <NetworkErrorMessage small={false} additionalData={user} />

  const addWorkspaceButton = (
    <ButtonUI onClick={() => openWorkspaceDrawer(null)} Icon={<AddIcon className={iconStyleWhite} />}>
      Workspace
    </ButtonUI>
  )

  if (workspaces.isLoading || workspaces.data === undefined || user.isLoading || user.data === undefined)
    return <LoadingSpinner />

  if (workspaces.data.totalCount === 0) {
    if (params.page > 1) {
      // I'm doing a set a setTimeout to avoid a warning to be triggered
      setTimeout(() => setPage(1), 0)
      return <LoadingSpinner />
    }

    return (
      <BaseContainer>
        {workspaceDrawer}

        <MainTitleView title="Workspaces" />
        <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography className={classes.noWorkspaceText} variant="h4">
            There are no workspace that match the selected filters.
            <br />
            Click the following buttons to reset or update the filters.
          </Typography>
          <Box display="flex" flexDirection="row">
            <WorkspaceFilter setFilter={setParams} filter={params} />
          </Box>
        </Box>
      </BaseContainer>
    )
  }

  return (
    <BaseContainer>
      <WorkspaceInvitationExplanation />
      {workspaceDrawer}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box>
          <TableTitleWithTotalCount
            title="Workspaces"
            qualifier="workspace"
            totalCount={workspaces.data.totalCount}
            helpTooltipContent="Manage workspaces and workspace parameters such as currency, timezone... Click to know more!"
            helpTooltipUrl="https://support.cryptio.co/hc/en-gb/sections/6787376412817-Workspace-Settings"
          />
        </Box>
        <Box display="flex" alignItems="flex-end">
          <WorkspaceFilter setFilter={setParams} filter={params} />

          <Box ml={2} />

          {addWorkspaceButton}
        </Box>
      </Box>
      <Paper>
        <ServerSideTable<GetMinimalWorkspaceDto, GetWorkspacesSortByEnum>
          columns={columns}
          items={workspaces.data.data}
          limit={workspaces.data.limit}
          totalCount={workspaces.data.totalCount}
          defaultParams={params}
          onPageChange={setPage}
          onLimitChange={setLimit}
          onRowClicked={(row) => openWorkspaceDrawer(row.id)}
        />
      </Paper>
    </BaseContainer>
  )
}

export default WorkspaceScene
