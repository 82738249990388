import { useTypedController } from "@hookform/strictly-typed"
import { Box, Checkbox, FormLabel, makeStyles } from "@material-ui/core"
import React from "react"
import { Control } from "react-hook-form"
import { TransactionBulkForm } from "./index"

import PermissionDisabled from "../../Permission/PermissionDisabled"
import TooltipUI from "CryptioUI/Tooltip"

interface Props {
  control: Control<TransactionBulkForm>
}

const useStyles = makeStyles(() => ({
  checkbox: {
    paddingLeft: 0,
  },
}))

const BulkInternalTransfer = (props: Props) => {
  const { control } = props
  const classes = useStyles()
  const TypedController = useTypedController<TransactionBulkForm>({ control })

  return (
    <Box mt={2}>
      <TypedController
        name="autoInternalTransferReview"
        defaultValue={false}
        render={({ onChange, value, ...rest }) => (
          <>
            <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
              <Checkbox
                id="auto-internal-transfer-review-checkbox"
                color="primary"
                className={classes.checkbox}
                onChange={() => onChange(!value)}
                checked={value}
                {...rest}
              />
            </PermissionDisabled>
            <TooltipUI content="Only the potential internal transfers with only one counter-part found by our software can be labelled in a bulk fashion.">
              <FormLabel htmlFor="auto-internal-transfer-review-checkbox">Label as internal transfer</FormLabel>
            </TooltipUI>
          </>
        )}
      />
    </Box>
  )
}

export default BulkInternalTransfer
