import { Box } from "@material-ui/core"
import React from "react"
import api from "services/api"
import { GetTransactionFilterDto } from "services/api/openapi"
import FavoriteCard, { FavoriteCardProps } from "./FavoriteCard"
import FavoriteNoFilters from "./FavoriteNoFilters"

type Props = Omit<FavoriteCardProps, "filter">

const FavoriteTab = (props: Props): JSX.Element => {
  const filters = api.filter.useFilters({
    page: 1,
    limit: 50,
  })

  if (!filters.data) return <></>

  return (
    <Box mt={2}>
      {filters.data.data.length ? (
        filters.data.data.map((filter: GetTransactionFilterDto) => {
          return <FavoriteCard filter={filter} {...props} />
        })
      ) : (
        <FavoriteNoFilters setValue={props.setValue} />
      )}
    </Box>
  )
}

export default FavoriteTab
