import { Box, createStyles, makeStyles, Theme } from "@material-ui/core"
import { CheckCircleRounded } from "@material-ui/icons"
import React from "react"

import { WarningAdminFeature } from "components/Feature/WarningAdminFeature"
import CardImportUI from "CryptioUI/CardImport"
import { GetWalletTypeDto } from "services/api/openapi"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    imgBox: {
      width: "100%",
      height: "0px",
      padding: "33.3333% 0px 0px",
      position: "relative",
      userSelect: "none",
      opacity: "1",
      transition: "all 0.3s ease 0s",
    },
    sourceImg: {
      maxWidth: "80%",
      maxHeight: "80%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    selectedIcon: {
      color: theme.palette.primary.main,
      position: "absolute",
      right: 0,
      top: 0,
    },
  }),
)

interface Props {
  source: GetWalletTypeDto
  isSelected?: boolean
  onSelected?: () => void
}

const SourceElement = ({ source, isSelected, onSelected }: Props): JSX.Element => {
  const classes = useStyles()

  const onClick = () => {
    if (onSelected) onSelected()
  }

  return (
    <Box className={classes.root}>
      <WarningAdminFeature isAdminFeature={source.isFeatureEnabledOnlyForAdmin}>
        {isSelected && <CheckCircleRounded className={classes.selectedIcon} />}
        <CardImportUI onClick={onClick} src={source.logo} label={source.name} />
      </WarningAdminFeature>
    </Box>
  )
}

export default SourceElement
