import { Typography } from "@material-ui/core"
import dayjs from "dayjs"
import { TransactionSynchronizationSubcomponentsProps } from "."
import { DrawerSection } from "../../../Drawer/DrawerItems"

function SynchronizationDate({ transaction, isConsolidated }: TransactionSynchronizationSubcomponentsProps) {
  return (
    <DrawerSection name="Last synchronization date">
      {!isConsolidated ? (
        <Typography style={{ textTransform: "capitalize" }}>
          {transaction.accountingJournal?.synchronizedAt
            ? dayjs(transaction.accountingJournal.synchronizedAt).fromNow()
            : "Not synchronized"}
        </Typography>
      ) : (
        transaction.accountingJournal?.oldDates?.map((date, idx) => (
          <Typography key={idx}>
            One transaction
            {date ? ` has been synchronized ${dayjs(date).fromNow()}` : " is not synchronized"}
          </Typography>
        ))
      )}
    </DrawerSection>
  )
}

export default SynchronizationDate
