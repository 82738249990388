import React from "react"
import { GetUserDtoRoleEnum } from "services/api/openapi"

import ChildrenProps from "services/misc/childrenProps"
import RoleGuard from "../Guard/RoleGuard"

const BackOfficeView = ({
  children,
  adminOnly = false,
  techAdminOnly = false,
  persistent = false,
  fallback,
}: ChildrenProps & {
  adminOnly?: boolean
  techAdminOnly?: boolean
  persistent?: boolean
  fallback?: JSX.Element
}): JSX.Element => {
  const acceptedRoles: GetUserDtoRoleEnum[] = ["tech_administrator"]
  if (!techAdminOnly) acceptedRoles.push("business_administrator")
  if (!adminOnly && !techAdminOnly) acceptedRoles.push("reporter")
  return (
    <RoleGuard acceptedRoles={acceptedRoles} persistent={persistent} fallback={fallback}>
      <>{children}</>
    </RoleGuard>
  )
}

export default BackOfficeView
