import React, { useEffect } from "react"
import { ReactComponent as Retry } from "CryptioUI/assets/icons/reset.svg"

import api from "services/api"
import { currentVersion } from "services/variables"
import { useToast } from "CryptioUI/Toaster"
import { iconStyleWhite } from "CryptioUI/Utilities/config"

export default function VersionChecker(): JSX.Element {
  const { data, status } = api.misc.useVersion()
  const toast = useToast()

  useEffect(() => {
    if (status === "success" && data && data.version !== currentVersion) {
      toast.open("A new version of the application is available.", {
        variant: "warning",
        noAutohide: true,
        actionButton: (
          <button onClick={() => window.location.reload()} className="toast__close-btn ml-3">
            <Retry className={iconStyleWhite} width={14} />
          </button>
        ),
      })
    }
  }, [data, status, toast])

  return <></>
}
