import api from "../../../services/api"
import { Typography } from "@material-ui/core"
import useDialog from "../../../components/misc/useDialog"
import { BackOfficeBulkUpdateWorkspaceDto, BackOfficePaginatedWorkspaceResultDto } from "../../../services/api/openapi"
import { useState } from "react"
import { pluralize } from "../../../services/utils/textUtils"
import SplitButton from "CryptioUI/SplitButton"
import { Popover } from "@headlessui/react"
import TypographyUI from "CryptioUI/Typography"
import { TypographyVariant } from "CryptioUI/types"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface BackOfficeWorkspaceUpdateProps {
  workspaces: BackOfficePaginatedWorkspaceResultDto
  filters: BackOfficeBulkUpdateWorkspaceDto
}

type UpdateType =
  | "cost_basis"
  | "unknown_addresses"
  | "balances"
  | "contact_trx_count"
  | "internal_transfers"
  | "internal_transfers_reviews"
  | "mappings"
  | "label_rules"
type UpdateAction = {
  type: UpdateType
  label: string
  action: () => void
}
type UpdateActionList = { [key in UpdateType]: UpdateAction }

const BackOfficeWorkspaceUpdateButton = (props: BackOfficeWorkspaceUpdateProps) => {
  const basicDialog = useDialog()
  const toast = useToast()

  const [currentAction, setCurrentAction] = useState<UpdateType>("cost_basis")

  const { mutateAsync: updateCostBasisMutation } = api.backOffice.workspace.useUpdateAllWorkspaceCostBasis()
  const { mutateAsync: updateUnknownAddressMutation } = api.backOffice.workspace.useUpdateAllWorkspaceUnknownAddress()
  const { mutateAsync: updateBalancesMutation } = api.backOffice.workspace.useUpdateAllWorkspaceBalances()
  const { mutateAsync: updateContactTrxCount } = api.backOffice.workspace.useUpdateAllWorkspaceContactTrxCount()
  const { mutateAsync: updateLabelRules } = api.backOffice.workspace.useUpdateAllWorkspaceLabelRules()
  const { mutateAsync: updateInternalTransfer } = api.backOffice.workspace.useUpdateAllWorkspaceInternalTransfer()
  const { mutateAsync: updateInternalTransferReview } =
    api.backOffice.workspace.useUpdateAllWorkspaceInternalTransferReview()
  const { mutateAsync: updateMappings } = api.backOffice.workspace.useUpdateAllWorkspaceMappings()

  const doUpdateCostBasis = async () => {
    try {
      await updateCostBasisMutation({
        backOfficeBulkUpdateWorkspaceDto: props.filters,
      })
      toast.open("Updating cost basis...", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }
  const doUpdateUnknownAddress = async () => {
    try {
      await updateUnknownAddressMutation({
        backOfficeBulkUpdateWorkspaceDto: props.filters,
      })
      toast.open("Updating Unknown addresses...", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }
  const doUpdateBalances = async () => {
    try {
      await updateBalancesMutation({
        backOfficeBulkUpdateWorkspaceDto: props.filters,
      })
      toast.open("Updating Balances...", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }
  const doUpdateContactTrxCount = async () => {
    try {
      await updateContactTrxCount({
        backOfficeBulkUpdateWorkspaceDto: props.filters,
      })
      toast.open("Updating Contact trx count...", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }
  const doUpdateInternalTransfer = async () => {
    try {
      await updateInternalTransfer({
        backOfficeBulkUpdateWorkspaceDto: props.filters,
      })
      toast.open("Updating internal transfers...", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }
  const doUpdateInternalTransferReview = async () => {
    try {
      await updateInternalTransferReview({
        backOfficeBulkUpdateWorkspaceDto: props.filters,
      })
      toast.open("Updating internal transfers review...", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }
  const doUpdateMappings = async () => {
    try {
      await updateMappings({
        backOfficeBulkUpdateWorkspaceDto: props.filters,
      })
      toast.open("Updating mappings...", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }
  const doUpdateLabelRules = async () => {
    try {
      await updateLabelRules({
        backOfficeBulkUpdateWorkspaceDto: props.filters,
      })
      toast.open("Updating label rules...", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const workspacesToText = (count: number) => `${count} ${pluralize(count > 1, "workspace")}`

  const askUpdateCostBasis = () => {
    if (!props.workspaces) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to update cost basis for {workspacesToText(props.workspaces.totalCount)}?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doUpdateCostBasis,
    })
  }
  const askUpdateUknownAddress = () => {
    if (!props.workspaces) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to update unknown address for {workspacesToText(props.workspaces.totalCount)}?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doUpdateUnknownAddress,
    })
  }
  const askUpdateBalances = () => {
    if (!props.workspaces) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to update balances for {workspacesToText(props.workspaces.totalCount)}?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doUpdateBalances,
    })
  }
  const askUpdateContactTrxCount = () => {
    if (!props.workspaces) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to update contact transaction count for {workspacesToText(props.workspaces.totalCount)}?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doUpdateContactTrxCount,
    })
  }
  const askUpdateInternalTransfer = () => {
    if (!props.workspaces) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to update internal transfers for {workspacesToText(props.workspaces.totalCount)}?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doUpdateInternalTransfer,
    })
  }
  const askUpdateInternalTransferReview = () => {
    if (!props.workspaces) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to update internal transfers review for {workspacesToText(props.workspaces.totalCount)}?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doUpdateInternalTransferReview,
    })
  }
  const askUpdateMappings = () => {
    if (!props.workspaces) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to update mappings for {workspacesToText(props.workspaces.totalCount)}?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doUpdateMappings,
    })
  }
  const askUpdateLabelRules = () => {
    if (!props.workspaces) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to update label rules for {workspacesToText(props.workspaces.totalCount)}?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doUpdateLabelRules,
    })
  }

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const updateActions: UpdateActionList = {
    cost_basis: {
      type: "cost_basis",
      label: "cost basis",
      action: askUpdateCostBasis,
    },
    unknown_addresses: {
      type: "unknown_addresses",
      label: "unknown addresses",
      action: askUpdateUknownAddress,
    },
    balances: {
      type: "balances",
      label: "balances",
      action: askUpdateBalances,
    },
    contact_trx_count: {
      type: "contact_trx_count",
      label: "contact transactions count",
      action: askUpdateContactTrxCount,
    },
    internal_transfers: {
      type: "internal_transfers",
      label: "internal transfers",
      action: askUpdateInternalTransfer,
    },
    internal_transfers_reviews: {
      type: "internal_transfers_reviews",
      label: "internal transfers reviews",
      action: askUpdateInternalTransferReview,
    },
    mappings: {
      type: "mappings",
      label: "mappings",
      action: askUpdateMappings,
    },
    label_rules: {
      type: "label_rules",
      label: "label rules",
      action: askUpdateLabelRules,
    },
  }

  return (
    <>
      {basicDialog.dialog}
      <SplitButton
        buttonLabel={`Update all ${updateActions[currentAction].label}`}
        buttonAction={updateActions[currentAction].action}
        PopoverContent={Object.values(updateActions).map((value, index) => (
          <Popover.Button
            className="w-full p-2 hover:bg-grey-100"
            key={`update-action-${index}`}
            onClick={() => {
              setCurrentAction(value.type)
            }}
          >
            <TypographyUI variant={TypographyVariant.BUTTON}>{capitalizeFirstLetter(value.label)}</TypographyUI>
          </Popover.Button>
        ))}
      />
    </>
  )
}

export default BackOfficeWorkspaceUpdateButton
