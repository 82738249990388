import React from "react"

import { TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import { camelCaseToTitleCase } from "services/utils/textUtils"
import TooltipUI from "CryptioUI/Tooltip"

interface ApplicationDeFiProps {
  protocolLogo: string | null
  protocolName: string
  blockchainLogo: string | null
  blockchainName: string
  left?: boolean
}

const ApplicationDeFi = (props: ApplicationDeFiProps) => {
  const { protocolLogo, protocolName, blockchainLogo, blockchainName, left = false } = props
  const position = left ? "start" : "end"
  const size = position === "end" ? "w-[200px] max-w-[200px]" : "w-[100px] max-w-[100px]"

  return (
    <div className="flex flex-row justify-center items-center gap-2">
      {left && (
        <div>
          {protocolLogo && (
            <img
              src={protocolLogo}
              className="w-[41px] h-[41px] min-h-[41px] min-w-[41px] rounded-full border-grey-300 border-[0.5px] border-solid"
            />
          )}
        </div>
      )}
      <div className="flex flex-col gap-y-1">
        <div className={`flex w-full justify-${position}`} data-tag="allowRowEvents">
          <div
            className={`${size} overflow-hidden whitespace-nowrap text-ellipsis flex justify-${position}`}
            data-tag="allowRowEvents"
          >
            <TooltipUI side="bottom" alignment="end" content={camelCaseToTitleCase(protocolName)}>
              <div>
                <TypographyUI variant={TypographyVariant.BUTTON} className="text-grey-500">
                  {camelCaseToTitleCase(protocolName)}
                </TypographyUI>
              </div>
            </TooltipUI>
          </div>
        </div>
        <div className={`flex flex-defiPosition justify-${position} items-center gap-2`}>
          {left && (
            <div className="bg-grey-200 rounded-full">
              {blockchainLogo && <img src={blockchainLogo} className="w-[18px] h-[18px] rounded-full" />}
            </div>
          )}
          <div
            className={`${size} overflow-hidden whitespace-nowrap text-ellipsis flex justify-${position}`}
            data-tag="allowRowEvents"
          >
            <TooltipUI side="bottom" alignment="end" content={blockchainName}>
              <div>
                <TypographyUI variant={TypographyVariant.BUTTON} className="text-grey-500">
                  {blockchainName}
                </TypographyUI>
              </div>
            </TooltipUI>
          </div>
          {!left && (
            <div className="bg-grey-200 rounded-full">
              {blockchainLogo && <img src={blockchainLogo} className="w-[18px] h-[18px] rounded-full" />}
            </div>
          )}
        </div>
      </div>
      {!left && (
        <div>
          {protocolLogo && (
            <img
              src={protocolLogo}
              className="w-[41px] h-[41px] min-h-[41px] min-w-[41px] rounded-full border-grey-300 border-[0.5px] border-solid"
            />
          )}
        </div>
      )}
    </div>
  )
}

export default ApplicationDeFi
