import { Box, Link, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import { AssignmentReturnedRounded, HelpRounded } from "@material-ui/icons"
import dayjs from "dayjs"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom"
import { ReactComponent as Reset } from "CryptioUI/assets/icons/reset.svg"
import { ReactComponent as Add } from "CryptioUI/assets/icons/add.svg"
import { ReactComponent as Delete } from "CryptioUI/assets/icons/delete.svg"
import { ReactComponent as Share } from "CryptioUI/assets/icons/share.svg"
import { ReactComponent as Dollars } from "CryptioUI/assets/icons/menu/billing.svg"

import AddressTypography from "components/AddressTypography"
import BaseContainer from "components/Container"
import useBulkSelector from "components/Hooks/useBulkSelector"
import { UncontrolledLoadingButton } from "components/LoadingButton"
import MainTitleView from "components/MainTitleView"
import CopyableText from "components/misc/copyableText"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import useDrawer from "components/misc/useDrawer"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import PermissionReadView from "components/Permission/PermissionReadView"
import { GenericSorting, IExtendedDataTableColumn } from "components/Table/interface"
import OmitColumnSelector from "components/Table/OmitColumnSelector"
import ServerSideTable from "components/Table/ServerSideTable"
import ModalUI from "CryptioUI/Modal"
import { iconStyleBlack, iconStyleGrey, iconStyleWhite } from "CryptioUI/Utilities/config"
import { GreenColor, RedColor } from "../../materialTheme"
import { URLS } from "../../routes"
import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import { GetWalletDto, GetWalletsRequest, GetWalletsSortByEnum } from "services/api/openapi"
import { UserContext } from "services/context/userContext"
import { WorkspaceContext } from "services/context/workspaceContext"
import { useLocalStorage } from "services/misc/useLocalStorage"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import { PaginationParameters } from "services/urlParse"
import { pluralize } from "services/utils/textUtils"
import toFilterElement from "services/utils/toFilterElement"
import WalletFilter from "./filters/WalletFilter"
import { getHumanReadableWalletStatus } from "./ImportStatus"
import WalletImporter from "./WalletDialog"
import { Mode } from "CryptioUI/types"
import ButtonUI from "CryptioUI/Button"
import { Mixpanel } from "services/mixpanel"
import findReportType from "services/utils/reports"
import WarningTypography from "components/WarningTypography"
import { errorExplanations } from "./ImportDrawer/constants"
import TooltipUI from "CryptioUI/Tooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  noWalletText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  paper: { minWidth: "770px" },
  tagVersionLegacy: {
    padding: theme.spacing(1),
    backgroundColor: RedColor,
    borderRadius: theme.shape.borderRadius,
  },
  tagVersionNew: {
    padding: theme.spacing(1),
    backgroundColor: GreenColor,
    borderRadius: theme.shape.borderRadius,
  },
  tooltipIcon: {
    marginLeft: theme.spacing(0.5),
    height: "1em",
    width: "1em",
  },
}))

export const defaultImportSorting: GenericSorting<GetWalletsSortByEnum> = {
  sortDirection: "descending",
  sortBy: "created_at",
}

export type ImportParameters = Omit<Partial<WithoutWorkspaceId<GetWalletsRequest>>, "minimumTransactions"> & {
  minimumTransactions?: string
}
export const defaultImportParams: ImportParameters = {
  page: 0,
  limit: 10,
  query: undefined,
  typeNames: undefined,
  minimumTransactions: undefined,
  wallets: [],
  walletsAddress: undefined,
}

export const walletHasAnyFilter = (filters: ImportParameters) =>
  Boolean(
    filters?.query?.length ||
      filters?.minimumTransactions ||
      filters?.typeNames?.length ||
      filters?.walletTypeTypes?.length,
  )

const viewTitle = "Sources"
const viewDescription = "Import transactions into Cryptio. Click to know more!"
const viewHelpURL = "https://support.cryptio.co/hc/en-gb/articles/7375737834385-Quick-Start-Guide-for-End-Users"

interface ImportRouteState {
  openSourceSelector: boolean
}

export const paramsToFilter = (
  params: PaginationParameters & Partial<ImportParameters>,
): WithoutWorkspaceId<GetWalletsRequest> => {
  return {
    page: params.page,
    limit: params.limit,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
    minimumTransactions: params.minimumTransactions !== undefined ? Number(params.minimumTransactions) : undefined,
    query: params.query === undefined || params.query.length === 0 ? undefined : params.query,
    typeNames: params.typeNames,
    wallets: params.wallets,
    walletsAddress: params.walletsAddress,
  }
}

const ImportScene = (): JSX.Element => {
  const { params, setLimit, setPage, setSorting, setParams } = usePaginatedParams<
    Partial<ImportParameters>,
    GetWalletsSortByEnum
  >(defaultImportParams, defaultImportSorting)

  const classes = useStyles()
  const [walletDrawer, openWalletDrawer] = useDrawer("wallet")
  const [isInLockPeriod, setIsInLockPeriod] = useState(false)
  const { user } = useContext(UserContext)
  const history = useHistory()
  const state = useLocation<ImportRouteState | undefined>().state
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(state?.openSourceSelector ? true : false)

  const workspaceCtx = useContext(WorkspaceContext)

  const [hiddenColumns, setHiddenColumns] = useLocalStorage<string[]>(`hidden_columns_imports`, [])

  const types = api.wallet.useTypes({
    onlyInWorkspace: true,
  })

  const wallets = api.wallet.useWallets(paramsToFilter(params), {
    refetchInterval: 2000,
  })

  const { mutateAsync: updateTransactionValuationMutation } = api.backOffice.wallet.useUpdateTransactionValuation()
  const basicDialog = useDialog()
  const toast = useToast()
  const { mutateAsync: refreshAllSourcesMutation } = api.wallet.useRefreshAllSources()
  const { mutateAsync: refreshSomeSourcesMutation } = api.wallet.useRefreshSources()
  const { mutateAsync: deleteWalletsMutation } = api.wallet.useDeleteWallets()
  const { selectedRows, selectedRowCount, setSelectedRows, bulkSelectorColumn } = useBulkSelector(
    wallets.data,
    (o) => o.id,
  )

  const { mutateAsync: createReportMutation } = api.report.useCreateReports()
  const reportModules = api.report.useReportTypes()

  const exportSources = async () => {
    try {
      const reportInfos = findReportType(reportModules.data, "audit", "sources")
      const [transactionFormat] = reportInfos.type.formats

      await createReportMutation({
        createReportDto: {
          reportModule: reportInfos.module.name,
          fileType: "csv",
          allColumns: true,
          exportFormatId: transactionFormat.id,
          startDate: null,
          endDate: null,
          walletIds: [],
          assetIds: [],
          impairmentId: null,
          includeNft: true,
          inputDate: null,
        },
      })
      Mixpanel.track("ReportCreated", {
        reportName: reportInfos.type.name,
        reportFormat: reportInfos.type.formats[0].name,
      })
      toast.open("The report is being generated in your Reports page", {
        variant: "success",
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const setParamsWithDialog = (newParams: ImportParameters) => {
    if (selectedRows === undefined) setParams(newParams)
    else {
      basicDialog.showDialog({
        title: "Update filter?",
        content: <Typography variant="h5">If you update the filter you will loose your selection</Typography>,
        yesText: "Yes",
        noText: "Cancel",
        onAccept: () => {
          setSelectedRows(undefined)
          setParams(newParams)
        },
      })
    }
  }

  useEffect(() => {
    if (selectedRows && selectedRows.type === "filtered" && selectedRows.excludedIds.length > 0) {
      setIsInLockPeriod(!selectedRows?.excludedIds.some((e) => wallets?.data?.data.some((w) => w.id === e)))
    } else if (selectedRows && selectedRows.type === "individuals" && selectedRows.itemIds.length > 0) {
      setIsInLockPeriod(selectedRows?.itemIds.some((e) => wallets?.data?.data.some((w) => w.id === e)))
    }
  }, [selectedRows, wallets?.data?.data])

  const columns = useMemo<IExtendedDataTableColumn<GetWalletDto, GetWalletsSortByEnum>[]>(
    () => [
      bulkSelectorColumn,
      {
        name: "Name",
        selector: "name",
        sortable: true,
        sortName: "name",
        //TODO: this breaks lock:wallet e2e tests
        //format: function formatAddress(row: GetWalletDto): JSX.Element | string {
        //  return (
        //    <TooltipUI content={<Typography>{row.name}</Typography>}>
        //      <Typography variant="body2">{row.name}</Typography>
        //    </TooltipUI>
        //  )
        //},
      },
      {
        name: "Type",
        selector: "sourceName",
        sortable: true,
        sortName: "type",
        typeNames: "source_id",
      },
      {
        name: "Address",
        selector: "address",
        typeNames: "address",
        format: function formatAddress(row: GetWalletDto): JSX.Element | string {
          if (row.address === null) return "-"
          return (
            <CopyableText text={row.address}>
              <AddressTypography component="span">{row.address}</AddressTypography>
            </CopyableText>
          )
        },
      },
      {
        name: "Import Date",
        selector: "createdAt",
        sortable: true,
        sortName: "created_at",
        format: function formatCreatedAt(row: GetWalletDto): JSX.Element {
          return <span>{dayjs(row.createdAt).format("LLL")}</span>
        },
      },
      {
        name: "Status",
        selector: "importStatus",
        format: function formatStatus(row: GetWalletDto) {
          const statusString = getHumanReadableWalletStatus(row)
          return statusString === "Error - csv" ? (
            <div className="flex flex-row justify-center items-center">
              {statusString}
              <TooltipUI content={<Typography>{errorExplanations.csvErrorWithReport}</Typography>}>
                <HelpRounded className={classes.tooltipIcon} />
              </TooltipUI>
            </div>
          ) : (
            statusString
          )
        },
      },
      {
        name: "Last Updated",
        selector: "lastUpdated",
        sortable: true,
        sortName: "last_updated_at",
        format: function formatLastUpdated(row: GetWalletDto): JSX.Element {
          if (row.lastUpdatedAt === null) return <span>Never updated</span>
          return <span>{dayjs(row.lastUpdatedAt).fromNow()}</span>
        },
      },
      {
        name: "Transactions",
        selector: "transactionCount",
        sortable: true,
        sortName: "transaction_count",
        format: function formatContactAddresses(row) {
          if (row.transactionCount === 0) return "0"
          return (
            <Box>
              <Link
                component={RouterLink}
                to={URLS.Transactions.getUrl({
                  filters: [
                    {
                      type: "wallets",
                      value: [toFilterElement(row)],
                      isNot: false,
                    },
                  ],
                })}
                underline="always"
              >
                {row.transactionCount}
              </Link>
            </Box>
          )
        },
      },
      {
        name: "Version",
        selector: "version",
        omit: user.role === "normal",
        format: function formatVersion(row: GetWalletDto) {
          return row.hasLegacy ? (
            <TooltipUI
              content={
                "Sources marked as “Legacy” have been imported before the ledgers update. We recommend to re-import these sources. Please reach out to customer support if you need help."
              }
            >
              <div className={classes.tagVersionLegacy}>Legacy</div>
            </TooltipUI>
          ) : (
            <TooltipUI
              content={
                "Sources marked as “New” have been imported after the ledgers update. They don’t require any form of maintenance."
              }
            >
              <div className={classes.tagVersionNew}>New</div>
            </TooltipUI>
          )
        },
      },
    ],
    [bulkSelectorColumn, classes.tagVersionLegacy, classes.tagVersionNew, classes.tooltipIcon, user.role],
  )

  const omittedColumns = useMemo(() => {
    return columns.map((c) => ({
      ...c,
      omit: c.omit || hiddenColumns.includes(c.name as string),
    }))
  }, [columns, hiddenColumns])

  const { workspace } = useContext(WorkspaceContext)

  if (!workspace.userRights.can_read_wallet) return <PermissionReadView viewTitle={viewTitle} itemName="wallets" />
  if (wallets.isError) return <NetworkErrorMessage small={false} additionalData={wallets} />
  if (wallets.isLoading || wallets.data === undefined) return <LoadingSpinner />
  if (types.isError) return <NetworkErrorMessage small={false} additionalData={types} />
  if (types.isLoading || types.data === undefined) return <LoadingSpinner />
  if (reportModules.isError) return <NetworkErrorMessage small={false} additionalData={reportModules} />
  if (reportModules.isLoading || reportModules.data === undefined) return <LoadingSpinner />

  const refreshAllSources = async () => {
    try {
      await refreshAllSourcesMutation({})
      toast.open("Workspace refreshing...", {
        variant: "success",
      })
    } catch {
      toast.open("Could not refresh the workspace", {
        variant: "warning",
      })
    }
  }

  const refreshSelectedSources = async () => {
    try {
      await refreshSomeSourcesMutation({
        refreshWalletsDto: {
          filtered:
            selectedRows?.type === "filtered"
              ? {
                  excludedIds: selectedRows.excludedIds,
                  filter: paramsToFilter(params),
                }
              : undefined,
          individuals:
            selectedRows?.type === "individuals"
              ? {
                  walletIds: selectedRows.itemIds,
                }
              : undefined,
        },
      })
      toast.open("Selected sources updating...", {
        variant: "success",
      })
    } catch {
      toast.open("Could not update the selected sources", {
        variant: "warning",
      })
    }
  }

  const updateAllWallets = async () => {
    await refreshAllSources()
  }

  const valuateSelectedSources = async () => {
    try {
      await updateTransactionValuationMutation({
        backOfficeUpdateTransactionValuation: {
          filtered:
            selectedRows?.type === "filtered"
              ? {
                  excludedWalletIds: selectedRows.excludedIds,
                }
              : undefined,
          individuals:
            selectedRows?.type === "individuals"
              ? {
                  walletIds: selectedRows.itemIds,
                }
              : undefined,
        },
      })
      toast.open("Updating valorisation", { variant: "success" })
    } catch {
      toast.open("Could not valuate selected sources", {
        variant: "warning",
      })
    }
  }
  const askRefreshSelectedSources = () => {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: <Typography variant="h5">Do you really want to update the selected sources?</Typography>,
      yesText: "Yes",
      noText: "Cancel",
      onAccept: refreshSelectedSources,
    })
  }
  const doDeleteSelectedSources = async () => {
    try {
      await deleteWalletsMutation({
        deleteWalletsDto: {
          filtered:
            selectedRows?.type === "filtered"
              ? {
                  excludedIds: selectedRows.excludedIds,
                  filter: paramsToFilter(params),
                }
              : undefined,
          individuals:
            selectedRows?.type === "individuals"
              ? {
                  walletIds: selectedRows.itemIds,
                }
              : undefined,
        },
      })
      setSelectedRows(undefined)
      toast.open("Selected sources deleted", {
        variant: "success",
      })
    } catch {
      toast.open("Could not delete the selected sources", {
        variant: "danger",
      })
    }
  }

  const askDeleteSelectedSources = () => {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to delete the selected sources? This action is irreversible.
          <Box mt={2}>
            <WarningTypography>
              Please note that if a source is included in label rule and you delete it, the rule will be deleted as well
              (but the labels already applied by the rule will remain). Are you sure you want to delete the sources?
            </WarningTypography>
          </Box>
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doDeleteSelectedSources,
      className: "w-[900px]",
    })
  }

  const openImportSource = async () => {
    setDialogIsOpen(true)
  }

  const handleCloseDialog = () => {
    if (state) history.replace(URLS.Import.basePath)
    setDialogIsOpen(false)
  }

  const addWalletButton = (
    <PermissionDisabled permission="can_create_wallet" action="import sources">
      <ButtonUI onClick={openImportSource} Icon={<Add className={iconStyleWhite} />}>
        Import
      </ButtonUI>
    </PermissionDisabled>
  )

  const addWalletDialog = (
    <ModalUI className="w-[900px]" isOpen={dialogIsOpen} onClose={handleCloseDialog}>
      <WalletImporter onClose={handleCloseDialog} />
    </ModalUI>
  )

  const hasAnyFilter = walletHasAnyFilter(params)
  const Header = () => {
    return (
      <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {hasAnyFilter ? (
          <>
            <Typography className={classes.noWalletText} variant="h4">
              There are no source that match the selected filters.
              <br />
              Click the following buttons to reset or update the filters.
            </Typography>
            <Box display="flex" flexDirection="row">
              <WalletFilter sources={types.data} setFilter={setParamsWithDialog} filter={params} />
            </Box>
          </>
        ) : (
          <>
            <AssignmentReturnedRounded fontSize="large" />
            <Typography className={classes.noWalletText} variant="h4">
              This source list is empty.
              <br />
              Click the button below to add one.
            </Typography>
            {addWalletButton}
          </>
        )}
      </Box>
    )
  }

  const askUpdateAllWallets = () => {
    return basicDialog.showDialog({
      title: "Are you sure?",
      content: <Typography variant="h5">Do you really want to update all sources?</Typography>,
      yesText: "Yes",
      noText: "Cancel",
      onAccept: updateAllWallets,
    })
  }

  return (
    <BaseContainer>
      {basicDialog.dialog}
      {walletDrawer}
      <div className="mb-4 flex flex-row justify-between h-24">
        <div className="flex flex-col items-start">
          <MainTitleView title={viewTitle} helpTooltipContent={viewDescription} helpTooltipUrl={viewHelpURL} />

          {wallets.data.totalCount > 0 && (
            <Typography variant="body1">
              <span data-test-id="sources-count">{wallets.data.totalCount}</span>{" "}
              {pluralize(wallets.data.totalCount > 1, "source")}
              {selectedRowCount > 0 && (
                <>
                  <span> - </span>
                  {selectedRowCount} selected&nbsp;
                </>
              )}
            </Typography>
          )}
        </div>
        <div className="flex flex-col justify-end items-end mr-4">
          <div>
            {selectedRowCount > 0 && (
              <div className="flex flex-row space-x-4 mb-4">
                <div>
                  {workspaceCtx.workspace.isInAdminMode && (
                    <ButtonUI
                      mode={Mode.CONTAINED}
                      className=""
                      onClick={valuateSelectedSources}
                      Icon={<Dollars className={iconStyleBlack} />}
                    >
                      Valuate {selectedRowCount} {pluralize(selectedRowCount > 1, "source")}
                    </ButtonUI>
                  )}
                </div>
                <div>
                  <PermissionDisabled permission="can_remove_wallet" action="delete selected sources">
                    <TooltipUI
                      content={
                        "Source with transactions locked can't be deleted. To delete them, please unlock your period. Note that this action will update your cost basis."
                      }
                      disabled={isInLockPeriod && workspaceCtx.workspace.lock.isEnabled}
                    >
                      <div>
                        <ButtonUI
                          mode={Mode.CONTAINED}
                          onClick={askDeleteSelectedSources}
                          Icon={<Delete className={iconStyleBlack} />}
                          disabled={isInLockPeriod && workspaceCtx.workspace.lock.isEnabled}
                        >
                          Delete {selectedRowCount} {pluralize(selectedRowCount > 1, "source")}
                        </ButtonUI>
                      </div>
                    </TooltipUI>
                  </PermissionDisabled>
                </div>
                <div>
                  <PermissionDisabled permission="can_modify_wallet" action="update selected sources">
                    <ButtonUI
                      mode={Mode.CONTAINED}
                      onClick={askRefreshSelectedSources}
                      Icon={<Reset className={iconStyleBlack} />}
                    >
                      Update {selectedRowCount} {pluralize(selectedRowCount > 1, "source")}
                    </ButtonUI>
                  </PermissionDisabled>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-row">
            <div className="mr-4">
              <PermissionDisabled permission="can_modify_wallet" action="update all sources">
                <UncontrolledLoadingButton
                  onClick={askUpdateAllWallets}
                  disabled={wallets.data.totalCount === 0}
                  Icon={<Reset className={wallets.data.totalCount === 0 ? iconStyleGrey : iconStyleWhite} />}
                >
                  Update all
                </UncontrolledLoadingButton>
              </PermissionDisabled>
            </div>

            <WalletFilter sources={types.data} setFilter={setParamsWithDialog} filter={params} />
            <Box ml={2}>{addWalletButton}</Box>
            <Box ml={2}>
              <PermissionDisabled permission="can_create_report" action="create sources report">
                <ButtonUI onClick={exportSources} Icon={<Share className={iconStyleWhite} />}>
                  Export
                </ButtonUI>
              </PermissionDisabled>
            </Box>
            <OmitColumnSelector
              columns={columns.filter((column) => column.selector !== "checkbox")}
              hiddenColumns={hiddenColumns}
              setHiddenColumns={setHiddenColumns}
            />
          </div>
        </div>
      </div>
      {wallets.data.totalCount === 0 && wallets.data.page === 1 ? (
        <Header />
      ) : (
        <Paper>
          <ServerSideTable<GetWalletDto, GetWalletsSortByEnum>
            columns={omittedColumns}
            items={wallets.data.data}
            limit={wallets.data.limit}
            defaultParams={params}
            totalCount={wallets.data.totalCount}
            onPageChange={setPage}
            onLimitChange={setLimit}
            onSort={setSorting}
            onRowClicked={(row) => openWalletDrawer(row.id)}
          />
        </Paper>
      )}
      {addWalletDialog}
    </BaseContainer>
  )
}

export default ImportScene
