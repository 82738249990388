import TooltipUI from "CryptioUI/Tooltip"
import { SubType } from "pure-shared"
import React, { useContext } from "react"

import { GetFullWorkspaceRoleDto } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"

export interface PermissionDisabledProps {
  permission: keyof SubType<GetFullWorkspaceRoleDto, boolean>
  children: JSX.Element
  action: string
  placement?: "right" | "left" | "bottom" | "top"
  titlePermissionEnable?: string

  // Workspace Form in creation mode..?
  byPass?: boolean
}

const PermissionDisabled = (props: PermissionDisabledProps): JSX.Element => {
  const workspaceCtx = useContext(WorkspaceContext)
  if (props.byPass === true || workspaceCtx.workspace.userRights[props.permission]) {
    return props.titlePermissionEnable ? (
      // The span is also necessary here as button of forms are often disabled until it is complete / valid
      <TooltipUI side={props.placement} content={`${props.titlePermissionEnable ?? ""}`}>
        <span>{props.children}</span>
      </TooltipUI>
    ) : (
      props.children
    )
  }

  return (
    <TooltipUI
      side={props.placement}
      content={`Your current role does not allow you to ${props.action}. An administrator can change your role.`}
    >
      <span>{React.cloneElement(props.children, { disabled: true })}</span>
    </TooltipUI>
  )
}

export default PermissionDisabled
