import { Box, Divider, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import React from "react"

import ChildrenProps from "services/misc/childrenProps"

const useStyles = makeStyles((theme: Theme) => ({
  dividerMargins: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
  },
  descriptionMargins: {
    marginTop: theme.spacing(1),
  },
}))

const SettingsCategory = ({
  title,
  description,
  children,
}: { title: string; description?: string } & ChildrenProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Box component={Paper} padding={3} mt={2}>
      <Typography variant="h3">{title}</Typography>
      {description && (
        <Typography variant="body1" className={classes.descriptionMargins}>
          {description}
        </Typography>
      )}

      <Divider className={classes.dividerMargins} />

      {children}
    </Box>
  )
}

export default SettingsCategory
