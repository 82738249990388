import { queryClient } from "../../../.."
import { useWorkspaceMutation } from "../../../context/workspaceContext"
import { BackOfficeApi } from "../../openapi"
import { GET_LABELS_KEY } from "../label"

export const backOfficeLabelActions = {
  useUpdateLabelRule: () => {
    return useWorkspaceMutation(BackOfficeApi.backOfficeUpdateLabelRule, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_LABELS_KEY)
      },
    })
  },
}
