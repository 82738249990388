import { Checkbox, makeStyles, Theme, Typography } from "@material-ui/core"
import CheckBox from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank"
import React, { ChangeEvent } from "react"

import { Disabled } from "../../materialTheme"
import ConditionalTooltip from "../ConditionalTooltip"

interface MakeStylesProps {
  disabled?: boolean
  color: string
}

const handleColor = (disabled: boolean | undefined, color: string, theme: Theme): string => {
  if (disabled) {
    return Disabled
  }
  switch (color) {
    case "primary":
      return theme.palette.text.primary
    case "default":
      return Disabled
    default:
      return Disabled
  }
}

const useStyles = makeStyles<Theme, MakeStylesProps>((theme: Theme) => ({
  containerCheckLine: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    height: "fit-content",
    width: "fit-content",
  },
  containerCheckbox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    height: "100%",
    minHeight: "100%",
    paddingRight: theme.spacing(2),
  },
  checkbox: {
    padding: "0px",
    margin: "0px",
  },
  containerText: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    width: "100%",
  },
  typography: {
    "fontWeight": 500,
    "&:hover": {
      cursor: "pointer",
    },
    "color": ({ disabled, color }): string => handleColor(disabled, color, theme),
  },
}))

interface CheckLineProps {
  id?: string
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  text: string
  checked: boolean
  disabled?: boolean
  tooltipMessage?: string
  color?: string
}

const CheckLine = (props: CheckLineProps) => {
  const { id, onChange, text, checked, disabled = false, color = "default", tooltipMessage = "" } = props
  const classes = useStyles({ disabled, color })

  return (
    <ConditionalTooltip tooltipMessage={tooltipMessage} disabled={disabled}>
      <div className={classes.containerCheckLine}>
        <div className={classes.containerCheckbox}>
          <Checkbox
            disabled={disabled}
            id={id}
            color="primary"
            className={classes.checkbox}
            checked={checked}
            icon={<CheckBoxOutlineBlank />}
            checkedIcon={<CheckBox color={"primary"} />}
            onChange={onChange}
          />
        </div>
        <div className={classes.containerText}>
          <Typography component="label" className={classes.typography} variant="body1" htmlFor={id}>
            {text}
          </Typography>
        </div>
      </div>
    </ConditionalTooltip>
  )
}

export default CheckLine
