import React from "react"
import { css } from "@emotion/css"

import { Periods, PeriodLabels } from "../../types"

import DatePickerTag from "./DatePickerTag"

export interface DatePickerTagGroupProps {
  periods: Periods
  handlePeriodSelection: (type: PeriodLabels) => void
}

const DatePickerTagGroup = ({ periods, handlePeriodSelection }: DatePickerTagGroupProps): JSX.Element => {
  return (
    <div className="flex flex-column items-center justify-center w-[95%] border-t border-grey-200 py-4">
      <div
        className={css`
          box-sizing: border-box;
          width: 450px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;

          button {
            margin: 4px;
          }
        `}
      >
        {Object.entries(periods).map(([key, item]) => (
          <DatePickerTag
            label={item.label}
            key={"datepickertag" + item.label}
            onClick={() => {
              handlePeriodSelection(key as PeriodLabels)
            }}
            active={item.active}
          />
        ))}
      </div>
    </div>
  )
}

export default DatePickerTagGroup
