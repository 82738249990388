import React from "react"
import { css } from "@emotion/css"
import { twMerge } from "tailwind-merge"

import { BannerUIProps, BannerMode, BannerModeType } from "CryptioUI/types"
import IconPositionWrapper from "CryptioUI/Utilities/IconPositionWrapper"
import { ReactComponent as CloseStroke } from "CryptioUI/assets/icons/close-stroke.svg"

import CustomWrapperModale from "./CustomWrappedModale"

const BannerUI = (props: BannerUIProps): JSX.Element => {
  const { Icon, onClose, children, className = "", iconPosition = "left", mode = BannerMode.ERROR, isOpen } = props

  const modeStyle: BannerModeType = {
    [BannerMode.INFO]: "bg-info-300",
    [BannerMode.WARNING]: "bg-warning-300",
    [BannerMode.ERROR]: "bg-danger-300",
  }

  const commonStyle = "py-4 px-8 text-white text-sm leading-4 min-h-fit w-full flex justify-center items-center"
  const finalStyle = twMerge([commonStyle, modeStyle[mode], className].join(" "))

  const CloseIcon = () => (
    <CloseStroke
      className={css`
        height: 14px;
        width: 14px;
        filter: invert(100%) sepia(40%) saturate(0%) hue-rotate(267deg) brightness(102%) contrast(103%);
      `}
    />
  )

  return (
    <>
      <CustomWrapperModale isOpen={isOpen}>
        <div className={finalStyle}>
          <div className="w-full">
            <IconPositionWrapper Icon={<>{Icon}</>} iconOnly={!children} iconPosition={iconPosition} iconSpacing={8}>
              {children}
            </IconPositionWrapper>
          </div>
          <div
            className="w-3.5 mx-2 hover:cursor-pointer"
            onClick={() => {
              onClose(false)
            }}
          >
            <CloseIcon />
          </div>
        </div>
      </CustomWrapperModale>
    </>
  )
}

export default BannerUI
