import { accountingActions } from "./routes/accounting"
import { backOfficeAssetActions } from "./routes/backOffice/asset"
import { backOfficeBillingActions } from "./routes/backOffice/billing"
import { backOfficeUserActions } from "./routes/backOffice/user"
import { backOfficeWalletActions } from "./routes/backOffice/wallet"
import { backOfficeWorkspaceActions } from "./routes/backOffice/workspace"
import { balanceActions } from "./routes/balance"
import { billingActions } from "./routes/billing"
import { chartAccountActions } from "./routes/chartAccount"
import { contactActions } from "./routes/contact"
import { historyActions } from "./routes/history"
import { integrationsActions } from "./routes/integrations"
import { invoicesActions } from "./routes/invoices"
import { labelActions } from "./routes/label"
import { miscActions } from "./routes/misc"
import { reportActions } from "./routes/report"
import { transactionsActions } from "./routes/transactions"
import { userActions } from "./routes/user"
import { walletActions } from "./routes/wallet"
import { workspaceActions } from "./routes/workspace"
import { assetActions } from "./routes/asset"
import { filterActions } from "./routes/filter"
import { backOfficeContactActions } from "./routes/backOffice/contact"
import { urlShortenerActions } from "./routes/urlShortener"
import { impairmentActions } from "./routes/impairment"
import { backOfficeLabelActions } from "./routes/backOffice/label"
import { backOfficeJobsActions } from "./routes/backOffice/jobs"

const api = {
  asset: assetActions,
  label: labelActions,
  user: userActions,
  contact: contactActions,
  chartAccount: chartAccountActions,
  impairment: impairmentActions,
  transaction: transactionsActions,
  wallet: walletActions,
  balance: balanceActions,
  report: reportActions,
  workspace: workspaceActions,
  backOffice: {
    user: backOfficeUserActions,
    workspace: backOfficeWorkspaceActions,
    wallet: backOfficeWalletActions,
    asset: backOfficeAssetActions,
    billing: backOfficeBillingActions,
    contact: backOfficeContactActions,
    label: backOfficeLabelActions,
    jobs: backOfficeJobsActions,
  },
  history: historyActions,
  integrations: integrationsActions,
  invoices: invoicesActions,
  accounting: accountingActions,
  billing: billingActions,
  misc: miscActions,
  filter: filterActions,
  urlShortener: urlShortenerActions,
}

export default api
