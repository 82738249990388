/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  Confirm2faActivationDto,
  ConfirmEmailDto,
  CreateUserDto,
  ForgotPasswordDto,
  GenerateQRCodeDto,
  GetAvailableCurrencyDto,
  GetCostBasisAlgorithmDto,
  GetUserDto,
  IsEmailAvailableDto,
  LoginUserDto,
  PreLoginAccountStatus,
  ResendConfirmationEmail,
  ResetPasswordDto,
  ResetPasswordWithTokenDto,
  UpdateUserDefaultCurrencyDto,
  XeroPostRegisterDto,
} from "../models"

export interface Confirm2faActivationRequest {
  confirm2faActivationDto: Confirm2faActivationDto
}

export interface ConfirmEmailRequest {
  confirmEmailDto: ConfirmEmailDto
}

export interface Delete2faRequest {
  confirm2faActivationDto: Confirm2faActivationDto
}

export interface ForgotPasswordRequest {
  forgotPasswordDto: ForgotPasswordDto
}

export interface GetCostBasisAlgorithmRequest {
  workspaceId?: string
}

export interface GetPreLoginStatusRequest {
  email: string
}

export interface IsEmailAvailableRequest {
  email: string
}

export interface LoginRequest {
  loginUserDto: LoginUserDto
}

export interface RegisterRequest {
  createUserDto: CreateUserDto
}

export interface ResendConfirmationEmailRequest {
  resendConfirmationEmail: ResendConfirmationEmail
}

export interface ResetForgottenPasswordRequest {
  resetPasswordWithTokenDto: ResetPasswordWithTokenDto
}

export interface ResetPasswordRequest {
  resetPasswordDto: ResetPasswordDto
}

export interface SetUserDefaultCurrencyRequest {
  updateUserDefaultCurrencyDto: UpdateUserDefaultCurrencyDto
}

export interface XeroPostRegisterRequest {
  xeroPostRegisterDto: XeroPostRegisterDto
}

/**
 *
 */
export class UserApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static confirm2faActivation(params: Confirm2faActivationRequest) {
    return localUserApi.confirm2faActivation(params)
  }

  /**
   * Confirm 2fa activation with valid code from app
   */
  confirm2faActivation({ confirm2faActivationDto }: Confirm2faActivationRequest): Promise<void> {
    return this.restInstance.post(`/user/2fa`, confirm2faActivationDto, true)
  }

  static confirmEmail(params: ConfirmEmailRequest) {
    return localUserApi.confirmEmail(params)
  }

  /**
   * Confirm email with token
   */
  confirmEmail({ confirmEmailDto }: ConfirmEmailRequest): Promise<void> {
    return this.restInstance.post(`/user/confirm_email`, confirmEmailDto, true)
  }

  static delete2fa(params: Delete2faRequest) {
    return localUserApi.delete2fa(params)
  }

  /**
   * Delete 2fa activation with valid code from app
   */
  delete2fa({ confirm2faActivationDto }: Delete2faRequest): Promise<void> {
    return this.restInstance.delete(`/user/2fa`, confirm2faActivationDto)
  }

  static deleteUser() {
    return localUserApi.deleteUser()
  }

  /**
   * Delete user
   */
  deleteUser(): Promise<void> {
    return this.restInstance.delete(`/user`, undefined)
  }

  static forgotPassword(params: ForgotPasswordRequest) {
    return localUserApi.forgotPassword(params)
  }

  /**
   * Send email to reset password
   */
  forgotPassword({ forgotPasswordDto }: ForgotPasswordRequest): Promise<void> {
    return this.restInstance.post(`/user/forgot-password`, forgotPasswordDto, true)
  }

  static generate2faQRCode() {
    return localUserApi.generate2faQRCode()
  }

  /**
   * Generate QRCode for 2fa app
   */
  generate2faQRCode(): Promise<GenerateQRCodeDto> {
    return this.restInstance.get(`/user/2fa`)
  }

  static getAllAvailableFiats() {
    return localUserApi.getAllAvailableFiats()
  }

  /**
   * Get all available currencies
   */
  getAllAvailableFiats(): Promise<Array<GetAvailableCurrencyDto>> {
    return this.restInstance.get(`/user/available_currencies`)
  }

  static getCostBasisAlgorithm(params: GetCostBasisAlgorithmRequest) {
    return localUserApi.getCostBasisAlgorithm(params)
  }

  /**
   * if no workspace provided, the algorithms included in the global package are returned, this is useful when you create a workspace
   * Get cost basis algorithm algorithms
   */
  getCostBasisAlgorithm({
    ...queryParameters
  }: GetCostBasisAlgorithmRequest): Promise<Array<GetCostBasisAlgorithmDto>> {
    return this.restInstance.get(`/user/algorithms?${this.restInstance.encodeObjectToUrl(queryParameters)}`)
  }

  static getPreLoginStatus(params: GetPreLoginStatusRequest) {
    return localUserApi.getPreLoginStatus(params)
  }

  /**
   * Get pre-login status
   */
  getPreLoginStatus({ email }: GetPreLoginStatusRequest): Promise<PreLoginAccountStatus> {
    return this.restInstance.get(`/user/prelogin/${encodeURIComponent(email)}`)
  }

  static getUser() {
    return localUserApi.getUser()
  }

  /**
   * Get the user\'s data
   */
  getUser(): Promise<GetUserDto> {
    return this.restInstance.get(`/user`)
  }

  static isEmailAvailable(params: IsEmailAvailableRequest) {
    return localUserApi.isEmailAvailable(params)
  }

  /**
   * Check if an email address is already used by another account
   */
  isEmailAvailable({ email }: IsEmailAvailableRequest): Promise<IsEmailAvailableDto> {
    return this.restInstance.get(`/user/is-email-available/${encodeURIComponent(email)}`)
  }

  static login(params: LoginRequest) {
    return localUserApi.login(params)
  }

  /**
   * Log user in
   */
  login({ loginUserDto }: LoginRequest): Promise<void> {
    return this.restInstance.post(`/user/login`, loginUserDto, true)
  }

  static logout() {
    return localUserApi.logout()
  }

  /**
   * Delete user\'s session
   */
  logout(): Promise<void> {
    return this.restInstance.post(`/user/logout`, undefined, true)
  }

  static register(params: RegisterRequest) {
    return localUserApi.register(params)
  }

  /**
   * Create a new user
   */
  register({ createUserDto }: RegisterRequest): Promise<void> {
    return this.restInstance.post(`/user/register`, createUserDto, true)
  }

  static resendConfirmationEmail(params: ResendConfirmationEmailRequest) {
    return localUserApi.resendConfirmationEmail(params)
  }

  /**
   * Resend confirmation email
   */
  resendConfirmationEmail({ resendConfirmationEmail }: ResendConfirmationEmailRequest): Promise<void> {
    return this.restInstance.post(`/user/resend-confirmation-email`, resendConfirmationEmail, true)
  }

  static resetForgottenPassword(params: ResetForgottenPasswordRequest) {
    return localUserApi.resetForgottenPassword(params)
  }

  /**
   * Reset password with token verification
   */
  resetForgottenPassword({ resetPasswordWithTokenDto }: ResetForgottenPasswordRequest): Promise<void> {
    return this.restInstance.post(`/user/reset-forgotten-password`, resetPasswordWithTokenDto, true)
  }

  static resetPassword(params: ResetPasswordRequest) {
    return localUserApi.resetPassword(params)
  }

  /**
   * Reset password with old password verification
   */
  resetPassword({ resetPasswordDto }: ResetPasswordRequest): Promise<void> {
    return this.restInstance.post(`/user/reset-password`, resetPasswordDto, true)
  }

  static setUserDefaultCurrency(params: SetUserDefaultCurrencyRequest) {
    return localUserApi.setUserDefaultCurrency(params)
  }

  /**
   * set the user\'s default currency
   */
  setUserDefaultCurrency({ updateUserDefaultCurrencyDto }: SetUserDefaultCurrencyRequest): Promise<void> {
    return this.restInstance.post(`/user/default_currency`, updateUserDefaultCurrencyDto, true)
  }

  static xeroPostRegister(params: XeroPostRegisterRequest) {
    return localUserApi.xeroPostRegister(params)
  }

  /**
   * Post register data from xero
   */
  xeroPostRegister({ xeroPostRegisterDto }: XeroPostRegisterRequest): Promise<void> {
    return this.restInstance.post(`/user/xero/post-register`, xeroPostRegisterDto, true)
  }
}

const localUserApi = new UserApi()
