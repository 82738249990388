import { Grid, Typography } from "@material-ui/core"
import React from "react"

import { GetWorkspaceDto } from "../../services/api/openapi"

export type WorkspaceInAutocomplete = Pick<GetWorkspaceDto, "name" | "description">
interface Props {
  workspace: WorkspaceInAutocomplete
}

const WorkspaceOptionInAutocomplete = ({ workspace }: Props) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Typography variant="body1">{workspace.name}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {workspace.description}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default WorkspaceOptionInAutocomplete
