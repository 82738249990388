import TypographyAllowEvent from "components/TypographyAllowEvent"
import ButtonUI from "CryptioUI/Button"
import TooltipUI from "CryptioUI/Tooltip"
import { Mode, TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import React from "react"
import { AliasDtoTypeEnum } from "services/api/openapi"

interface WalletOtherParty {
  subtitle: string
  name: string
  title?: JSX.Element | string
  onClick: () => void
  type: AliasDtoTypeEnum
  nbOtherParty?: number
}

const WalletOtherParty = (props: WalletOtherParty) => {
  const { subtitle, name, onClick, nbOtherParty = 0 } = props

  if (!name) return <></>

  const text = nbOtherParty > 1 ? `${nbOtherParty} other parties` : name
  const width = subtitle === "from:" ? "w-[110px] max-w-[110px]" : "w-[130px] max-w-[130px]"
  return (
    <div className="flex flex-row overflow-hidden w-[150px] max-w-[150px] whitespace-nowrap overflow-ellipsis">
      <TooltipUI content={text} noMaxWidth>
        <p className="flex h-fit flex-row items-center justify-center">
          <TypographyAllowEvent
            variant={subtitle === "from:" ? TypographyVariant.BUTTON : TypographyVariant.CAPTION}
            className={`flex ${subtitle === "from:" ? "text-grey-500" : "text-grey-400"}`}
          >
            <span className="pr-1">{subtitle}</span>
          </TypographyAllowEvent>
          <ButtonUI
            mode={Mode.TEXTONLY}
            className={`p-0 h-fit text-left ${width} ${
              subtitle === "from:" ? "text-grey-500 hover:underline" : "text-grey-400"
            }`}
            onClick={onClick}
          >
            <div className="flex justify-center h-fit">
              <TypographyUI
                ellipsisPosition="end"
                variant={subtitle === "from:" ? TypographyVariant.BUTTON : TypographyVariant.CAPTION}
                className={`text-left overflow-hidden whitespace-nowrap text-ellipsis ${width}
                ${
                  subtitle === "from:"
                    ? " text-grey-500 hover:text-grey-400 hover:underline"
                    : "underline text-grey-400 hover:text-grey-500"
                }`}
              >
                {text ?? "-"}
              </TypographyUI>
            </div>
          </ButtonUI>
        </p>
      </TooltipUI>
      {/* {!(nbOtherParty > 1) && (
        <>
          {type !== "wallet" ? (
            <RiContactsBook2Fill className={classes.logo} />
          ) : (
            <FaWallet className={classes.logo} />
          )}
        </>
      )} */}
    </div>
  )
}

export default WalletOtherParty
