/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import { GenerateApiKeyDto, GenerateApiQuery, PaginatedApiKeyResultDto } from "../models"

export interface DeleteApiKeyRequest {
  workspaceId: string
  apiKey: string
}

export interface GenerateApiKeyRequest {
  workspaceId: string
  generateApiQuery: GenerateApiQuery
}

export interface ListApiKeysRequest {
  workspaceId: string
  page: number
  limit: number
  excludedIds?: Array<string>
}

/**
 *
 */
export class ApiApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static deleteApiKey(params: DeleteApiKeyRequest) {
    return localApiApi.deleteApiKey(params)
  }

  /**
   * Delete api key
   */
  deleteApiKey({ workspaceId, apiKey }: DeleteApiKeyRequest): Promise<void> {
    return this.restInstance.delete(
      `/workspaces/${encodeURIComponent(workspaceId)}/api/${encodeURIComponent(apiKey)}`,
      undefined,
    )
  }

  static generateApiKey(params: GenerateApiKeyRequest) {
    return localApiApi.generateApiKey(params)
  }

  /**
   * Generate api key
   */
  generateApiKey({ generateApiQuery, workspaceId }: GenerateApiKeyRequest): Promise<GenerateApiKeyDto> {
    return this.restInstance.post(`/workspaces/${encodeURIComponent(workspaceId)}/api/generate`, generateApiQuery)
  }

  static listApiKeys(params: ListApiKeysRequest) {
    return localApiApi.listApiKeys(params)
  }

  /**
   * List all api keys
   */
  listApiKeys({ workspaceId, ...queryParameters }: ListApiKeysRequest): Promise<PaginatedApiKeyResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/api/keys?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
    )
  }
}

const localApiApi = new ApiApi()
