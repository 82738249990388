import React, { useMemo } from "react"
import { DefiAssetDto } from "services/api/openapi"

import BigNumber from "bignumber.js"
import numbro from "numbro"
import { BoldWeight, TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import TooltipUI from "CryptioUI/Tooltip"

interface ImpermanentLossProps {
  total: string | undefined
  token0: DefiAssetDto | undefined
  token1: DefiAssetDto | undefined
}

const ImpermanentLoss = (props: ImpermanentLossProps) => {
  const { total, token0, token1 } = props
  const position = "end"

  const { totalValue, totalValueRounded, prettyNumber } = useMemo(() => {
    if (total) {
      const totalValue = new BigNumber(total ?? "- -")
      const totalValueRounded = totalValue.decimalPlaces(2, BigNumber.ROUND_HALF_UP)
      const prettyNumber = numbro(totalValueRounded?.toString()).format({
        thousandSeparated: true,
      })
      return { totalValue, totalValueRounded, prettyNumber }
    } else {
      return { totalValue: undefined, totalValueRounded: undefined, prettyNumber: "- -" }
    }
  }, [total])

  const { token0Value, assetVolumeToken0, prettVolumeToken0 } = useMemo(() => {
    if (token0) {
      const token0Value = new BigNumber(token0.volume)
      const assetVolumeToken0 = token0Value.decimalPlaces(2, BigNumber.ROUND_HALF_UP)
      const prettVolumeToken0 = numbro(assetVolumeToken0?.toString()).format({
        thousandSeparated: true,
      })
      return { token0Value, assetVolumeToken0, prettVolumeToken0 }
    } else {
      return { token0Value: undefined, assetVolumeToken0: undefined, prettVolumeToken0: "- -" }
    }
  }, [token0])

  const { token1Value, assetVolumeToken1, prettVolumeToken1 } = useMemo(() => {
    if (token1) {
      const token1Value = new BigNumber(token1.volume)
      const assetVolumeToken1 = token1Value.decimalPlaces(2, BigNumber.ROUND_HALF_UP)
      const prettVolumeToken1 = numbro(assetVolumeToken1?.toString()).format({
        thousandSeparated: true,
      })
      return { token1Value, assetVolumeToken1, prettVolumeToken1 }
    } else {
      return { token1Value: undefined, assetVolumeToken1: undefined, prettVolumeToken1: "- -" }
    }
  }, [token1])

  interface TypographyImpermentProps {
    type: "total" | "token"
    value: BigNumber | undefined
    valueRounded: BigNumber | undefined
    valuePretty: string | undefined
    assetSymbol?: string
  }

  const TypographyImperment = ({ type, value, valueRounded, valuePretty, assetSymbol }: TypographyImpermentProps) => {
    if (!value || value?.toString() === "0") {
      return (
        <>
          {type === "total" && (
            <TypographyUI className={"text-grey-400"} variant={TypographyVariant.BODY}>
              - -
            </TypographyUI>
          )}
        </>
      )
    }
    if (value && value.absoluteValue().isLessThan(0.1)) {
      return (
        <TooltipUI
          side="bottom"
          alignment="end"
          content={`${type === "total" ? "$ " : ""}${numbro(value?.toString()).format({
            thousandSeparated: true,
          })} ${assetSymbol ?? ""}`}
        >
          <div>
            <TypographyUI
              variant={type === "token" ? TypographyVariant.CAPTION : TypographyVariant.BODY}
              boldness={BoldWeight.MEDIUM}
              className={type === "token" ? "text-grey-400" : "text-label-red-200"}
            >
              {`< ${type === "total" ? "$ " : ""}0.1 ${assetSymbol ?? ""}`}
            </TypographyUI>
          </div>
        </TooltipUI>
      )
    }
    return (
      <TooltipUI side="bottom" alignment="end" content={`$ ${valueRounded} ${assetSymbol ?? ""}`}>
        <div
          className={`w-[160px] max-w-[160px] overflow-hidden whitespace-nowrap text-ellipsis flex justify-${position}`}
          data-tag="allowRowEvents"
        >
          <TypographyUI
            variant={type === "token" ? TypographyVariant.CAPTION : TypographyVariant.BODY}
            boldness={BoldWeight.MEDIUM}
            className={type === "token" ? "text-grey-400" : "text-label-red-200"}
          >{`${type === "total" ? "$ " : ""}${valuePretty} ${assetSymbol ?? ""}`}</TypographyUI>
        </div>
      </TooltipUI>
    )
  }

  return (
    <div className={`flex flex-col w-[160px] max-w-[160px] items-${position}`} data-tag="allowRowEvents">
      <TypographyImperment
        type="total"
        value={totalValue}
        valueRounded={totalValueRounded}
        valuePretty={prettyNumber}
      />
      <TypographyImperment
        type="token"
        value={token0Value}
        valueRounded={assetVolumeToken0}
        valuePretty={prettVolumeToken0}
        assetSymbol={token0?.assetSymbol}
      />
      <TypographyImperment
        type="token"
        value={token1Value}
        valueRounded={assetVolumeToken1}
        valuePretty={prettVolumeToken1}
        assetSymbol={token1?.assetSymbol}
      />
    </div>
  )
}

export default ImpermanentLoss
