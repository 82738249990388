import React, { useCallback } from "react"
import { Helmet } from "react-helmet"
import { Route, RouteProps } from "react-router-dom"

interface SceneRouteProps extends RouteProps {
  title: string
  description: string
  path: string
  component: () => JSX.Element
}

export function SceneRoute({ title, description, path, component, ...routeProps }: SceneRouteProps) {
  const componentWithHelmet = useCallback(
    () => (
      <>
        <Helmet>
          <title>{title} | Cryptio</title>
          <meta name="description" content={description} />
        </Helmet>
        {component()}
      </>
    ),
    [component, title, description],
  )

  return <Route path={path} component={componentWithHelmet} {...routeProps} />
}

export default SceneRoute
