import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import React from "react"
import { LightGrey } from "../../../materialTheme"

const STOP_PROP_TAG = "allowRowEvents"
interface ContainerRowProps {
  children?: React.ReactNode
  noBorderTop?: boolean
  borderBottom?: boolean
  nonClickable?: boolean
  onClick?: () => void
  isMatchingFilter?: boolean
  height?: string
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    minWidth: "fit-content",
    paddingRight: "15px",
    minHeight: (props: { height: string | undefined }) => (props.height ? props.height : "64px"),
    height: (props: { height: string | undefined }) => (props.height ? props.height : "64px"),
    maxHeight: (props: { height: string | undefined }) => (props.height ? props.height : "fit-content"),
    width: "100%",
    borderBottomLeftRadius: (props: { height: string | undefined }) => (props.height ? "12px" : "0px"),
    borderBottomRightRadius: (props: { height: string | undefined }) => (props.height ? "12px" : "0px"),
    overflow: "hidden",
    backgroundColor: "white",
    border: "none",
  },
  clickable: {
    "&:hover": {
      backgroundColor: "#F5F5F5",
      cursor: "pointer",
    },
  },
  borderTop: {
    borderTop: "solid",
    borderWidth: "1px",
    borderColor: LightGrey,
  },
  borderBottom: {
    borderBottom: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: LightGrey,
  },
  filtered: {
    opacity: 0.25,
  },
}))

const ContainerRow = (props: ContainerRowProps) => {
  const {
    children,
    noBorderTop = false,
    borderBottom = false,
    onClick,
    nonClickable,
    isMatchingFilter = true,
    height,
  } = props
  const classes = useStyles({ height })

  const rowStyle = () => {
    return clsx(
      classes.container,
      !noBorderTop ? classes.borderTop : "",
      borderBottom ? classes.borderBottom : "",
      !nonClickable ? classes.clickable : "",
      !isMatchingFilter ? classes.filtered : "",
    )
  }

  const handleClickRow = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement
    if (target && target.getAttribute("data-tag") === STOP_PROP_TAG) {
      if (onClick) onClick()
    }
  }

  return (
    <div data-test-id="row" className={rowStyle()} onClick={handleClickRow}>
      {children}
    </div>
  )
}

export default ContainerRow
