import { Box, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import React from "react"

import BaseContainer from "components/Container"
import { WarningAdminFeature } from "components/Feature/WarningAdminFeature"
import MainTitleView from "components/MainTitleView"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import api from "services/api"
import { GetBusinessIntegrationDtoNameEnum } from "services/api/openapi"
import { FireblocksIntegration } from "./integrations/Fireblocks"
import { RequestNetworkIntegration } from "./integrations/RequestNetwork"

const useStyles = makeStyles((theme: Theme) => ({
  integration: {
    padding: theme.spacing(2),
    position: "relative",
    minHeight: "250px",
  },
  sourceContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: theme.spacing(3),
  },
  noIntegrationText: {
    textAlign: "center",
  },
}))

function Integration({ name }: { name: GetBusinessIntegrationDtoNameEnum }) {
  if (name === "request_network") {
    return <RequestNetworkIntegration />
  }
  if (name === "fireblocks") {
    return <FireblocksIntegration />
  }
  return <></>
}

const IntegrationsScene = (): JSX.Element => {
  const classes = useStyles()
  const integrations = api.integrations.useBusinessIntegrations()
  if (integrations.isError) return <NetworkErrorMessage small={false} additionalData={integrations} />
  if (integrations.isLoading || integrations.data === undefined) return <LoadingSpinner />

  return (
    <>
      <BaseContainer>
        <MainTitleView title="Business integrations" />
        {integrations.data.length ? (
          <Box mt={3} className={classes.sourceContainer}>
            {integrations.data.map((integration) => (
              <WarningAdminFeature isAdminFeature={integration.isFeatureEnabledOnlyForAdmin} key={integration.name}>
                <Paper className={classes.integration}>
                  <Integration name={integration.name} />
                </Paper>
              </WarningAdminFeature>
            ))}
          </Box>
        ) : (
          <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h4" className={classes.noIntegrationText}>
              Business integrations are disabled on this workspace.
              <br />
              Please contact the Cryptio support if you believe this is a mistake.
            </Typography>
          </Box>
        )}
      </BaseContainer>
    </>
  )
}

export default IntegrationsScene
