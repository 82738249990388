/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  AcceptWorkspaceInvitationsDto,
  CheckLockDto,
  CreateWorkspaceDto,
  CreateWorkspaceInvitationDto,
  CreateWorkspaceResponseDto,
  CreateWorkspaceRoleDto,
  GetMinimalWorkspaceRoleDto,
  GetPeggableStablecoinsDto,
  GetWorkspaceDto,
  GetWorkspaceMetricsDto,
  GetWorkspaceOrDefaultDto,
  HasWorkspaceRightDto,
  PaginatedWorkspaceInvitationResultDto,
  PaginatedWorkspaceListShortDto,
  PaginatedWorkspaceResultDto,
  PaginatedWorkspaceUserResultDto,
  SetLockDateDto,
  UpdatePeggedStablecoinsDto,
  UpdateShowOnboardingDto,
  UpdateWorkspaceAccountingOptionsDto,
  UpdateWorkspaceCurrencyDto,
  UpdateWorkspaceDto,
  UpdateWorkspaceRoleDto,
  UpdateWorkspaceUserDto,
  WorkspaceRetroactivitySettings,
} from "../models"

export interface AcceptWorkspaceInvitationsRequest {
  acceptWorkspaceInvitationsDto: AcceptWorkspaceInvitationsDto
}

export interface ApplyPeggedStablecoinsDtoRequest {
  workspaceId: string
}

export interface ChangeShowOnboardingRequest {
  workspaceId: string
  updateShowOnboardingDto: UpdateShowOnboardingDto
}

export interface CheckLockRequest {
  workspaceId: string
}

export interface CreateWorkspaceInvitationRequest {
  workspaceId: string
  createWorkspaceInvitationDto: CreateWorkspaceInvitationDto
}

export interface CreateWorkspaceRolesRequest {
  workspaceId: string
  createWorkspaceRoleDto: CreateWorkspaceRoleDto
}

export interface CreateWorkspacesRequest {
  createWorkspaceDto: CreateWorkspaceDto
}

export interface DeclineWorkspaceInvitationRequest {
  workspaceId: string
}

export interface DeleteWorkspaceInvitationRequest {
  workspaceId: string
  email: string
}

export interface DeleteWorkspaceRoleRequest {
  workspaceId: string
  roleId: string
}

export interface DeleteWorkspaceUserRequest {
  workspaceId: string
  userId: string
}

export interface DisableLockRequest {
  workspaceId: string
}

export interface GetPeggableStablecoinsDtoRequest {
  workspaceId: string
}

export interface GetWorkspaceRequest {
  workspaceId: string
}

export interface GetWorkspaceInvitationsRequest {
  page: number
  limit: number
  workspaceId: string
  excludedIds?: Array<string>
}

export interface GetWorkspaceMetricsRequest {
  workspaceId: string
}

export interface GetWorkspaceOrDefaultRequest {
  lastWorkspaceId?: string
}

export interface GetWorkspaceRolesRequest {
  workspaceId: string
}

export interface GetWorkspaceUsersRequest {
  workspaceId: string
  page: number
  limit: number
  excludedIds?: Array<string>
}

export interface GetWorkspacesRequest {
  page: number
  limit: number
  queryName?: string
  sortBy?: GetWorkspacesSortByEnum
  sortDirection?: GetWorkspacesSortDirectionEnum
  minimumUser?: number
  minimumTransaction?: number
  excludedIds?: Array<string>
}

export interface GetWorkspacesListShortRequest {
  page: number
  limit: number
  excludedIds?: Array<string>
}

export interface HasWorkspaceRightRequest {
  workspaceId: string
}

export interface RetroactivitySettingsRequest {
  workspaceId: string
}

export interface SetHasSeenGlobalLabelsExplanationRequest {
  workspaceId: string
}

export interface ToggleRetroactivityRequest {
  workspaceId: string
  element: ToggleRetroactivityElementEnum
}

export interface ToggleWorkspaceBookmarkRequest {
  workspaceId: string
}

export interface UpdateLockDateRequest {
  workspaceId: string
  setLockDateDto: SetLockDateDto
}

export interface UpdatePeggedStablecoinsDtoRequest {
  workspaceId: string
  updatePeggedStablecoinsDto: UpdatePeggedStablecoinsDto
}

export interface UpdateWorkspaceRequest {
  workspaceId: string
  updateWorkspaceDto: UpdateWorkspaceDto
}

export interface UpdateWorkspaceAccountingOptionsRequest {
  workspaceId: string
  updateWorkspaceAccountingOptionsDto: UpdateWorkspaceAccountingOptionsDto
}

export interface UpdateWorkspaceCurrencyRequest {
  workspaceId: string
  updateWorkspaceCurrencyDto: UpdateWorkspaceCurrencyDto
}

export interface UpdateWorkspaceRolesRequest {
  workspaceId: string
  roleId: string
  updateWorkspaceRoleDto: UpdateWorkspaceRoleDto
}

export interface UpdateWorkspaceUserRequest {
  workspaceId: string
  userId: string
  updateWorkspaceUserDto: UpdateWorkspaceUserDto
}

/**
 *
 */
export class WorkspacesApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static acceptWorkspaceInvitations(params: AcceptWorkspaceInvitationsRequest) {
    return localWorkspacesApi.acceptWorkspaceInvitations(params)
  }

  /**
   * Accept an invitation
   */
  acceptWorkspaceInvitations({ acceptWorkspaceInvitationsDto }: AcceptWorkspaceInvitationsRequest): Promise<void> {
    return this.restInstance.post(`/workspaces/invitations/accept`, acceptWorkspaceInvitationsDto, true)
  }

  static applyPeggedStablecoinsDto(params: ApplyPeggedStablecoinsDtoRequest) {
    return localWorkspacesApi.applyPeggedStablecoinsDto(params)
  }

  /**
   * Apply pegged stablecoins on the workspace history
   */
  applyPeggedStablecoinsDto({ workspaceId }: ApplyPeggedStablecoinsDtoRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/apply-pegged-stablecoins`,
      undefined,
      true,
    )
  }

  static changeShowOnboarding(params: ChangeShowOnboardingRequest) {
    return localWorkspacesApi.changeShowOnboarding(params)
  }

  /**
   * Set whether to show the onboarding or not
   */
  changeShowOnboarding({ updateShowOnboardingDto, workspaceId }: ChangeShowOnboardingRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/onboarding/update`,
      updateShowOnboardingDto,
      true,
    )
  }

  static checkLock(params: CheckLockRequest) {
    return localWorkspacesApi.checkLock(params)
  }

  /**
   * Check if a workspace can be locked
   */
  checkLock({ workspaceId }: CheckLockRequest): Promise<CheckLockDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/lock/check`)
  }

  static createWorkspaceInvitation(params: CreateWorkspaceInvitationRequest) {
    return localWorkspacesApi.createWorkspaceInvitation(params)
  }

  /**
   * Create an invitation and send a mail
   */
  createWorkspaceInvitation({
    createWorkspaceInvitationDto,
    workspaceId,
  }: CreateWorkspaceInvitationRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/invitations`,
      createWorkspaceInvitationDto,
      true,
    )
  }

  static createWorkspaceRoles(params: CreateWorkspaceRolesRequest) {
    return localWorkspacesApi.createWorkspaceRoles(params)
  }

  /**
   * Create a workspace role
   */
  createWorkspaceRoles({
    createWorkspaceRoleDto,
    workspaceId,
  }: CreateWorkspaceRolesRequest): Promise<GetMinimalWorkspaceRoleDto> {
    return this.restInstance.post(`/workspaces/${encodeURIComponent(workspaceId)}/roles`, createWorkspaceRoleDto)
  }

  static createWorkspaces(params: CreateWorkspacesRequest) {
    return localWorkspacesApi.createWorkspaces(params)
  }

  /**
   * Create a workspace
   */
  createWorkspaces({ createWorkspaceDto }: CreateWorkspacesRequest): Promise<CreateWorkspaceResponseDto> {
    return this.restInstance.post(`/workspaces`, createWorkspaceDto)
  }

  static declineWorkspaceInvitation(params: DeclineWorkspaceInvitationRequest) {
    return localWorkspacesApi.declineWorkspaceInvitation(params)
  }

  /**
   * Decline an invitation
   */
  declineWorkspaceInvitation({ workspaceId }: DeclineWorkspaceInvitationRequest): Promise<void> {
    return this.restInstance.delete(`/workspaces/${encodeURIComponent(workspaceId)}/invitations/decline`, undefined)
  }

  static deleteWorkspaceInvitation(params: DeleteWorkspaceInvitationRequest) {
    return localWorkspacesApi.deleteWorkspaceInvitation(params)
  }

  /**
   * Delete an invitation
   */
  deleteWorkspaceInvitation({ workspaceId, email }: DeleteWorkspaceInvitationRequest): Promise<void> {
    return this.restInstance.delete(
      `/workspaces/${encodeURIComponent(workspaceId)}/invitations/delete/${encodeURIComponent(email)}`,
      undefined,
    )
  }

  static deleteWorkspaceRole(params: DeleteWorkspaceRoleRequest) {
    return localWorkspacesApi.deleteWorkspaceRole(params)
  }

  /**
   * Remove a role from a workspace
   */
  deleteWorkspaceRole({ workspaceId, roleId }: DeleteWorkspaceRoleRequest): Promise<void> {
    return this.restInstance.delete(
      `/workspaces/${encodeURIComponent(workspaceId)}/roles/${encodeURIComponent(roleId)}`,
      undefined,
    )
  }

  static deleteWorkspaceUser(params: DeleteWorkspaceUserRequest) {
    return localWorkspacesApi.deleteWorkspaceUser(params)
  }

  /**
   * Remove a user from a workspace
   */
  deleteWorkspaceUser({ workspaceId, userId }: DeleteWorkspaceUserRequest): Promise<void> {
    return this.restInstance.delete(
      `/workspaces/${encodeURIComponent(workspaceId)}/users/${encodeURIComponent(userId)}`,
      undefined,
    )
  }

  static disableLock(params: DisableLockRequest) {
    return localWorkspacesApi.disableLock(params)
  }

  /**
   * Toggle workspace lock
   */
  disableLock({ workspaceId }: DisableLockRequest): Promise<void> {
    return this.restInstance.post(`/workspaces/${encodeURIComponent(workspaceId)}/lock/disable`, undefined, true)
  }

  static getPeggableStablecoinsDto(params: GetPeggableStablecoinsDtoRequest) {
    return localWorkspacesApi.getPeggableStablecoinsDto(params)
  }

  /**
   * Get 1:1 stablecoin list
   */
  getPeggableStablecoinsDto({ workspaceId }: GetPeggableStablecoinsDtoRequest): Promise<GetPeggableStablecoinsDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/peggable-stablecoins`)
  }

  static getWorkspace(params: GetWorkspaceRequest) {
    return localWorkspacesApi.getWorkspace(params)
  }

  /**
   * Get one workspace
   */
  getWorkspace({ workspaceId }: GetWorkspaceRequest): Promise<GetWorkspaceDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}`)
  }

  static getWorkspaceInvitations(params: GetWorkspaceInvitationsRequest) {
    return localWorkspacesApi.getWorkspaceInvitations(params)
  }

  /**
   * Get user\'s invitations
   */
  getWorkspaceInvitations({
    workspaceId,
    ...queryParameters
  }: GetWorkspaceInvitationsRequest): Promise<PaginatedWorkspaceInvitationResultDto> {
    return this.restInstance.get(`/workspaces/invitations?${this.restInstance.encodeObjectToUrl(queryParameters)}`)
  }

  static getWorkspaceMetrics(params: GetWorkspaceMetricsRequest) {
    return localWorkspacesApi.getWorkspaceMetrics(params)
  }

  /**
   * Get workspace metrics
   */
  getWorkspaceMetrics({ workspaceId }: GetWorkspaceMetricsRequest): Promise<GetWorkspaceMetricsDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/metrics`)
  }

  static getWorkspaceOrDefault(params: GetWorkspaceOrDefaultRequest) {
    return localWorkspacesApi.getWorkspaceOrDefault(params)
  }

  /**
   * Get a workspaces or the user\'s default workspace
   */
  getWorkspaceOrDefault({ ...queryParameters }: GetWorkspaceOrDefaultRequest): Promise<GetWorkspaceOrDefaultDto> {
    return this.restInstance.get(`/workspaces/getOrDefault?${this.restInstance.encodeObjectToUrl(queryParameters)}`)
  }

  static getWorkspaceRoles(params: GetWorkspaceRolesRequest) {
    return localWorkspacesApi.getWorkspaceRoles(params)
  }

  /**
   * Get workspace roles
   */
  getWorkspaceRoles({ workspaceId }: GetWorkspaceRolesRequest): Promise<Array<GetMinimalWorkspaceRoleDto>> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/roles`)
  }

  static getWorkspaceUsers(params: GetWorkspaceUsersRequest) {
    return localWorkspacesApi.getWorkspaceUsers(params)
  }

  /**
   * Get workspace\'s users
   */
  getWorkspaceUsers({
    workspaceId,
    ...queryParameters
  }: GetWorkspaceUsersRequest): Promise<PaginatedWorkspaceUserResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/users?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
    )
  }

  static getWorkspaces(params: GetWorkspacesRequest) {
    return localWorkspacesApi.getWorkspaces(params)
  }

  /**
   * Get user\'s workspaces
   */
  getWorkspaces({ ...queryParameters }: GetWorkspacesRequest): Promise<PaginatedWorkspaceResultDto> {
    return this.restInstance.get(`/workspaces?${this.restInstance.encodeObjectToUrl(queryParameters)}`)
  }

  static getWorkspacesListShort(params: GetWorkspacesListShortRequest) {
    return localWorkspacesApi.getWorkspacesListShort(params)
  }

  /**
   * Get user\'s workspaces list and bookmarks
   */
  getWorkspacesListShort({
    ...queryParameters
  }: GetWorkspacesListShortRequest): Promise<PaginatedWorkspaceListShortDto> {
    return this.restInstance.get(`/workspaces/list-short?${this.restInstance.encodeObjectToUrl(queryParameters)}`)
  }

  static hasWorkspaceRight(params: HasWorkspaceRightRequest) {
    return localWorkspacesApi.hasWorkspaceRight(params)
  }

  /**
   * if a user can access a workspace or not
   */
  hasWorkspaceRight({ workspaceId }: HasWorkspaceRightRequest): Promise<HasWorkspaceRightDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/has-right`)
  }

  static retroactivitySettings(params: RetroactivitySettingsRequest) {
    return localWorkspacesApi.retroactivitySettings(params)
  }

  /**
   */
  retroactivitySettings({ workspaceId }: RetroactivitySettingsRequest): Promise<WorkspaceRetroactivitySettings> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/retroactivity/settings`)
  }

  static setHasSeenGlobalLabelsExplanation(params: SetHasSeenGlobalLabelsExplanationRequest) {
    return localWorkspacesApi.setHasSeenGlobalLabelsExplanation(params)
  }

  /**
   * Change has_seen_global_labels_explanation state
   */
  setHasSeenGlobalLabelsExplanation({ workspaceId }: SetHasSeenGlobalLabelsExplanationRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/global-labels/update`,
      undefined,
      true,
    )
  }

  static toggleRetroactivity(params: ToggleRetroactivityRequest) {
    return localWorkspacesApi.toggleRetroactivity(params)
  }

  /**
   * Toggle the workspace retroactivity
   */
  toggleRetroactivity({ workspaceId, element }: ToggleRetroactivityRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/retroactivity/${encodeURIComponent(element)}/toggle`,
      undefined,
      true,
    )
  }

  static toggleWorkspaceBookmark(params: ToggleWorkspaceBookmarkRequest) {
    return localWorkspacesApi.toggleWorkspaceBookmark(params)
  }

  /**
   * Bookmark or unbookmark a workspace depending on its previous state
   */
  toggleWorkspaceBookmark({ workspaceId }: ToggleWorkspaceBookmarkRequest): Promise<void> {
    return this.restInstance.post(`/workspaces/${encodeURIComponent(workspaceId)}/bookmark`, undefined, true)
  }

  static updateLockDate(params: UpdateLockDateRequest) {
    return localWorkspacesApi.updateLockDate(params)
  }

  /**
   * Set lock date to a workspace
   */
  updateLockDate({ setLockDateDto, workspaceId }: UpdateLockDateRequest): Promise<void> {
    return this.restInstance.put(`/workspaces/${encodeURIComponent(workspaceId)}/lock`, setLockDateDto, true)
  }

  static updatePeggedStablecoinsDto(params: UpdatePeggedStablecoinsDtoRequest) {
    return localWorkspacesApi.updatePeggedStablecoinsDto(params)
  }

  /**
   * Update 1:1 stablecoin list
   */
  updatePeggedStablecoinsDto({
    updatePeggedStablecoinsDto,
    workspaceId,
  }: UpdatePeggedStablecoinsDtoRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/pegged-stablecoins`,
      updatePeggedStablecoinsDto,
      true,
    )
  }

  static updateWorkspace(params: UpdateWorkspaceRequest) {
    return localWorkspacesApi.updateWorkspace(params)
  }

  /**
   * Update a workspace
   */
  updateWorkspace({ updateWorkspaceDto, workspaceId }: UpdateWorkspaceRequest): Promise<void> {
    return this.restInstance.put(`/workspaces/${encodeURIComponent(workspaceId)}`, updateWorkspaceDto, true)
  }

  static updateWorkspaceAccountingOptions(params: UpdateWorkspaceAccountingOptionsRequest) {
    return localWorkspacesApi.updateWorkspaceAccountingOptions(params)
  }

  /**
   * Update a workspace accounting settings
   */
  updateWorkspaceAccountingOptions({
    updateWorkspaceAccountingOptionsDto,
    workspaceId,
  }: UpdateWorkspaceAccountingOptionsRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/accounting-options`,
      updateWorkspaceAccountingOptionsDto,
      true,
    )
  }

  static updateWorkspaceCurrency(params: UpdateWorkspaceCurrencyRequest) {
    return localWorkspacesApi.updateWorkspaceCurrency(params)
  }

  /**
   * Update a workspace currency
   */
  updateWorkspaceCurrency({ updateWorkspaceCurrencyDto, workspaceId }: UpdateWorkspaceCurrencyRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/currency`,
      updateWorkspaceCurrencyDto,
      true,
    )
  }

  static updateWorkspaceRoles(params: UpdateWorkspaceRolesRequest) {
    return localWorkspacesApi.updateWorkspaceRoles(params)
  }

  /**
   * Update a workspace role
   */
  updateWorkspaceRoles({
    updateWorkspaceRoleDto,
    workspaceId,
    roleId,
  }: UpdateWorkspaceRolesRequest): Promise<GetMinimalWorkspaceRoleDto> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/roles/${encodeURIComponent(roleId)}`,
      updateWorkspaceRoleDto,
    )
  }

  static updateWorkspaceUser(params: UpdateWorkspaceUserRequest) {
    return localWorkspacesApi.updateWorkspaceUser(params)
  }

  /**
   * Update a user in a workspace
   */
  updateWorkspaceUser({ updateWorkspaceUserDto, workspaceId, userId }: UpdateWorkspaceUserRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/users/${encodeURIComponent(userId)}`,
      updateWorkspaceUserDto,
      true,
    )
  }
}

const localWorkspacesApi = new WorkspacesApi()

/**
 * @export
 * @enum {string}
 */
export type GetWorkspacesSortByEnum = "created_at" | "is_bookmarked"
/**
 * @export
 * @enum {string}
 */
export type GetWorkspacesSortDirectionEnum = "ascending" | "descending"
/**
 * @export
 * @enum {string}
 */
export type ToggleRetroactivityElementEnum = "postprocess-defi" | "nft"
