/* eslint-disable @typescript-eslint/no-explicit-any */
// The form data can be anything...

import { Box, Typography } from "@material-ui/core"
import React, { useCallback, useMemo, useState } from "react"
import { UseQueryOptions, UseQueryResult } from "react-query"
import DrawerUI from "CryptioUI/Drawer"

import PublicAssetDrawer from "scenes/BackOffice/Assets/AssetDrawer"
import BackOfficeBillingDrawer from "scenes/BackOffice/Billing/BackOfficeBillingDrawer"
import BackOfficeUserDrawer from "scenes/BackOffice/Users/BackOfficeUserDrawer"
import BackOfficeWorkspaceDrawer from "scenes/BackOffice/Workspaces/BackOfficeWorkspaceDrawer"
import ContactDrawer from "scenes/Contacts/ContactDrawer"
import WalletDrawer from "scenes/Imports/ImportDrawer/WalletDrawer"
import FireblocksIntegrationEditionDrawer from "scenes/Integrations/integrations/Fireblocks/FireblocksIntegrationEditionDrawer"
import LabelDrawer from "scenes/Labels/LabelDrawer"
import ReportDrawer from "scenes/Reports/ReportDrawer"
import { DrawerReportForm } from "scenes/Reports/ReportDrawer/ReportForm"
import WorkspaceDrawer from "scenes/Workspace/WorkspaceDrawer"
import api from "services/api"
import { GetCOAMappingDto, WithoutWorkspaceId } from "services/api/aliases"
import {
  BackOfficeGetFullPlanDto,
  BackOfficeGetFullUserDto,
  BackOfficeGetFullWorkspaceDto,
  FireblocksResultDto,
  GetContactDto,
  GetLabelDto,
  GetReportDto,
  GetWalletDto,
  GetWorkspaceDto,
  GetPublicAssetDto,
  FullMovementDto,
  NewFullTransactionDto,
  GetImpairmentDto,
  DefiLivePositionDto,
} from "services/api/openapi"
import { GetTradeDto, GetTradeRequest } from "services/api/routes/transactions"
import MovementDrawer from "../CoreTransaction/MovementDrawer"
import MovementLabelDrawer from "../CoreTransaction/MovementLabelDrawer"
import TradeDrawer from "../CoreTransaction/TradeDrawer"
import TransactionDrawer from "../CoreTransaction/TransactionDrawer"
import TransactionLabelDrawer from "../CoreTransaction/TransactionLabelDrawer"
import LoadingSpinner from "./LoadingSpinner"
import NetworkErrorMessage from "./NetworkErrorMessage"
import useDialog from "./useDialog"
import ImpairmentDrawer from "../../scenes/Accounting/Impairment/ImpairmentDrawer"
import MappingDrawer from "components/CoreAutomatedMapping/MappingDrawer"
import DefiDrawer from "scenes/DeFi/DefiDrawer"
import { GetMovementDto } from "services/api/routes/transactions"

// formData is undefined when item is not null and formData can have a value only when item is null
// If item isn't nullable, formData will always be undefined
export interface DrawerProp<
  T,
  Nullable extends boolean,
  FormPrefilledData extends Nullable extends false ? undefined : Record<string, unknown> = Nullable extends false
    ? undefined
    : Record<string, unknown>,
> {
  item: Nullable extends false ? T : T | null
  onClose: () => void
  isOpen: boolean
  isFormDirty: boolean
  setFormDirty: (newValue: boolean) => void
  setFormDirtyData: (data: any) => void
  formData?: Partial<FormPrefilledData>
  returnAction?: (() => void) | undefined
  setTitle?: (newTitle: string) => void
  title: string
  setReturnAction?: (newAction: (() => void) | undefined) => void
}

interface DrawerType<
  T,
  Nullable extends boolean,
  U = string,
  FormPrefilledData extends Nullable extends false ? undefined : Record<string, unknown> = Nullable extends false
    ? undefined
    : Record<string, unknown>,
> {
  useSingleItem: (data: U | null, config?: UseQueryOptions<T, unknown, T>) => UseQueryResult<T>
  Drawer: (props: DrawerProp<T, Nullable, FormPrefilledData>) => JSX.Element
  titleDrawer: string
}

type AllDrawersType = {
  "transaction": DrawerType<NewFullTransactionDto, false>
  "movement": DrawerType<FullMovementDto, false, GetMovementDto>
  "defi": DrawerType<DefiLivePositionDto, false>
  "trade": DrawerType<GetTradeDto, false, WithoutWorkspaceId<GetTradeRequest>>
  "movement-label": DrawerType<FullMovementDto, false, GetMovementDto>
  "transaction-label": DrawerType<NewFullTransactionDto, false>
  "contact": DrawerType<GetContactDto, true>
  "label": DrawerType<GetLabelDto, true>
  "chart-account-mapping": DrawerType<GetCOAMappingDto, true>
  "wallet": DrawerType<GetWalletDto, false>
  "report": DrawerType<GetReportDto, true, string, DrawerReportForm>
  "workspace": DrawerType<GetWorkspaceDto, true>
  "backoffice-user": DrawerType<BackOfficeGetFullUserDto, false>
  "backoffice-workspace": DrawerType<BackOfficeGetFullWorkspaceDto, false>
  "backoffice-plan": DrawerType<BackOfficeGetFullPlanDto, false>
  "integration-edition-fireblocks": DrawerType<FireblocksResultDto, true>
  "asset": DrawerType<GetPublicAssetDto, false>
  "impairment": DrawerType<GetImpairmentDto, true>
}

const Drawers: AllDrawersType = {
  "movement-label": {
    useSingleItem: api.transaction.useMovement,
    Drawer: MovementLabelDrawer,
    titleDrawer: "Labels details",
  },
  "defi": {
    useSingleItem: api.workspace.useDefiPosition,
    Drawer: DefiDrawer,
    titleDrawer: "Defi details",
  },
  "transaction-label": {
    useSingleItem: api.transaction.useNewTransaction,
    Drawer: TransactionLabelDrawer,
    titleDrawer: "Labels details",
  },
  "transaction": {
    useSingleItem: api.transaction.useNewTransaction,
    Drawer: TransactionDrawer,
    titleDrawer: "Transaction details",
  },
  "movement": {
    useSingleItem: api.transaction.useMovement,
    Drawer: MovementDrawer,
    titleDrawer: "Movement details",
  },
  "trade": {
    useSingleItem: api.transaction.useTrade,
    Drawer: TradeDrawer,
    titleDrawer: "Trade details",
  },
  "contact": {
    useSingleItem: api.contact.useContact,
    Drawer: ContactDrawer,
    titleDrawer: "Contact details",
  },
  "label": {
    useSingleItem: api.label.useLabel,
    Drawer: LabelDrawer,
    titleDrawer: "Label edition",
  },
  "chart-account-mapping": {
    useSingleItem: api.chartAccount.useChartAccountMapping,
    Drawer: MappingDrawer,
    titleDrawer: "COA mapping details",
  },
  "wallet": {
    useSingleItem: api.wallet.useWallet,
    Drawer: WalletDrawer,
    titleDrawer: "Source edition",
  },
  "report": {
    useSingleItem: api.report.useReport,
    Drawer: ReportDrawer,
    titleDrawer: "New report",
  },
  "workspace": {
    useSingleItem: api.workspace.useWorkspace,
    Drawer: WorkspaceDrawer,
    titleDrawer: "Workspace edition",
  },
  "backoffice-user": {
    useSingleItem: api.backOffice.user.useUser,
    Drawer: BackOfficeUserDrawer,
    titleDrawer: "",
  },
  "backoffice-workspace": {
    useSingleItem: api.backOffice.workspace.useWorkspace,
    Drawer: BackOfficeWorkspaceDrawer,
    titleDrawer: "",
  },
  "backoffice-plan": {
    useSingleItem: api.backOffice.billing.usePlan,
    Drawer: BackOfficeBillingDrawer,
    titleDrawer: "",
  },
  "integration-edition-fireblocks": {
    useSingleItem: api.integrations.useFireblocks,
    Drawer: FireblocksIntegrationEditionDrawer,
    titleDrawer: "",
  },
  "asset": {
    useSingleItem: api.asset.usePublicAsset,
    Drawer: PublicAssetDrawer,
    titleDrawer: "Asset edition",
  },
  "impairment": {
    useSingleItem: api.impairment.useImpairment,
    Drawer: ImpairmentDrawer,
    titleDrawer: "Impairment details",
  },
}

interface InternalDrawerProps<Type extends keyof AllDrawersType, U = string> {
  type: Type
  data?: U | null
  formData?: Parameters<AllDrawersType[Type]["Drawer"]>[0]["formData"]
  setFormDirty: (newValue: boolean) => void
  setFormDirtyData: (data: any) => void
  onClose: () => void
  isFormDirty: boolean
  setTitle: (newTitle: string) => void
  title: string
  returnAction: (() => void) | undefined
  setReturnAction: (newAction: (() => void) | undefined) => void
}

const InternalDrawer = <Type extends keyof AllDrawersType>(
  props: InternalDrawerProps<Type, Parameters<AllDrawersType[Type]["useSingleItem"]>[0]>,
) => {
  const datas = Drawers[props.type]
  // @ts-ignore: type is too complex
  const data = datas.useSingleItem(props.data ?? null)

  if (props.data === undefined) {
    return <></>
  }
  if (props.data && data.isError) {
    return (
      <Box pt={25}>
        <NetworkErrorMessage size={{ height: 256, width: 256 }} small={false} additionalData={data} />
      </Box>
    )
  }
  if (props.data && (data.isLoading || data.data === undefined)) {
    return <LoadingSpinner />
  }
  return (
    // TODO: fix type
    <datas.Drawer
      item={(data.data ?? null) as never}
      onClose={props.onClose}
      setFormDirty={props.setFormDirty}
      setFormDirtyData={props.setFormDirtyData}
      formData={props.formData as never}
      isOpen={true}
      title={props.title}
      isFormDirty={props.isFormDirty}
      setTitle={props.setTitle}
      returnAction={props.returnAction}
      setReturnAction={props.setReturnAction}
    />
  )
}

interface Props {
  onExit?: (formDirtyData: any) => void
}

const useDrawer = <Type extends keyof AllDrawersType>(type: Type, props?: Props) => {
  type OpenDrawerProps = Parameters<AllDrawersType[Type]["Drawer"]>[0]
  type ItemId = Parameters<AllDrawersType[Type]["useSingleItem"]>[0]
  type FormData = OpenDrawerProps["formData"]
  const [drawerData, setDrawerData] = useState<{ data: ItemId; formData: FormData } | undefined>(undefined)
  const basicDialog = useDialog()
  const [isFormDirty, setFormDirty] = useState<boolean>(false)
  const [title, setTitle] = useState<string>(Drawers[type].titleDrawer)
  const [returnAction, setReturnAction] = useState<() => void>()
  const [formDirtyData, setFormDirtyData] = useState<any>({})

  const openDrawer = useCallback(
    <PassedItemId extends ItemId>(data: PassedItemId, formData?: null extends PassedItemId ? FormData : undefined) => {
      setDrawerData({ data, formData })
    },
    [setDrawerData],
  )
  const onClose = useCallback(() => {
    setDrawerData(undefined)
    setFormDirty(false)
    setFormDirtyData({})
  }, [setFormDirty, setDrawerData])

  const closeDrawer = useCallback(() => {
    if (isFormDirty) {
      basicDialog.showDialog({
        title: "Quit without saving?",
        content: <Typography variant="h5">Are you sure you want to quit without saving?</Typography>,
        yesText: "Yes",
        noText: "Cancel",
        onAccept: () => {
          if (props?.onExit) props.onExit(formDirtyData)
          onClose()
        },
      })
    } else onClose()
  }, [onClose, isFormDirty, basicDialog, formDirtyData, props])

  const drawer = useMemo(
    () => (
      <DrawerUI onClose={closeDrawer} isOpen={drawerData !== undefined} title={title} returnAction={returnAction}>
        {basicDialog.dialog}
        <InternalDrawer
          setTitle={setTitle}
          title={title}
          returnAction={returnAction}
          setReturnAction={setReturnAction}
          onClose={onClose}
          setFormDirty={setFormDirty}
          setFormDirtyData={setFormDirtyData}
          data={drawerData?.data}
          formData={drawerData?.formData}
          type={type}
          isFormDirty={isFormDirty}
        />
      </DrawerUI>
    ),
    [basicDialog.dialog, closeDrawer, onClose, setFormDirty, drawerData, type, isFormDirty, returnAction, title],
  )

  return [drawer, openDrawer, closeDrawer] as [typeof drawer, typeof openDrawer, typeof closeDrawer]
}

export default useDrawer
