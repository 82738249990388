import { makeStyles, Theme, Typography } from "@material-ui/core"
import WarningIcon from "@material-ui/icons/Warning"
import LoadingSpinner from "components/misc/LoadingSpinner"
import React from "react"
import { UseQueryResult } from "react-query"

import { GetLabelDto, MovementCounterPartyDto } from "services/api/openapi"
import DisclamerInternalTransfer from "./DisclamerInternalTransfer"

const useStyles = makeStyles((theme: Theme) => ({
  containerSection: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  typographyWarning: {
    fontWeight: 800,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  iconWarning: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.4),
  },
}))

interface CountrolCounterPartyProps {
  counterParties: UseQueryResult<MovementCounterPartyDto[], unknown>
  labelsToAdd: GetLabelDto[]
  setConfirmedInternalTransfer: (newValue: boolean) => void
  isInternalTransfer: boolean
  confirmedInternalTransfer: boolean
  setSelectedGuessedOtherParty: (newValue: MovementCounterPartyDto | null) => void
  selectedGuessedOtherParty: MovementCounterPartyDto | null
}

const CountrolCounterParty = (props: CountrolCounterPartyProps) => {
  const classes = useStyles()

  const {
    counterParties,
    labelsToAdd,
    setConfirmedInternalTransfer,
    isInternalTransfer,
    confirmedInternalTransfer,
    setSelectedGuessedOtherParty,
    selectedGuessedOtherParty,
  } = props

  if (counterParties.isLoading || counterParties.data === undefined) {
    return <LoadingSpinner />
  }

  return (
    <>
      {(counterParties?.data?.length ? true : false) ? (
        <DisclamerInternalTransfer
          counterParties={counterParties}
          labelsToAdd={labelsToAdd}
          setConfirmedInternalTransfer={setConfirmedInternalTransfer}
          isGlobalLabelInternalTransfer={isInternalTransfer}
          confirmedInternalTransfer={confirmedInternalTransfer}
          setSelectedGuessedOtherParty={setSelectedGuessedOtherParty}
          selectedGuessedOtherParty={selectedGuessedOtherParty}
        />
      ) : (
        <div className={classes.containerSection}>
          <Typography className={classes.typographyWarning}>
            <WarningIcon className={classes.iconWarning} /> No counter party found
          </Typography>
        </div>
      )}
    </>
  )
}

export default CountrolCounterParty
