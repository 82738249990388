import { Typography, TypographyProps } from "@material-ui/core"
import TooltipUI from "CryptioUI/Tooltip"
import React from "react"
// @ts-ignore
import MiddleEllipsis from "react-middle-ellipsis"

import { WithComponentProp } from "services/utils/types"

type Props = { children: string } & WithComponentProp<Omit<TypographyProps, "children">>

const AddressTypography = ({ children: address, ...rest }: Props) => {
  return (
    <TooltipUI content={address ?? ""} noMaxWidth>
      <MiddleEllipsis>
        <span>
          <Typography {...rest}>{address}</Typography>
        </span>
      </MiddleEllipsis>
    </TooltipUI>
  )
}

export default AddressTypography
