import { Box, Checkbox, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { getReportColumnMap } from "pure-shared"
import React, { useContext, useEffect, useMemo } from "react"

import { DrawerFormSection } from "components/Drawer/DrawerItems"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import { GetReportTypeDto, ReportTypeFormatDto } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { pluralize } from "services/utils/textUtils"
import { DrawerReportForm } from "./ReportForm"

interface Props {
  reportType: GetReportTypeDto
  reportFormat: ReportTypeFormatDto
  onChange: (newValue: DrawerReportForm["hiddenColumns"]) => void
  value: DrawerReportForm["hiddenColumns"]
  allColumnsHidden: boolean
}

const ReportHideColumnForm = ({ onChange, value, reportType, reportFormat, allColumnsHidden }: Props) => {
  const { workspace } = useContext(WorkspaceContext)

  const reportPrettyColumnNames = useMemo(
    // TODO: better typing
    () => {
      const sheetMapFunction =
        getReportColumnMap[reportType.name as "transactions"][
          reportFormat.name as keyof typeof getReportColumnMap["transactions"]
        ]

      // It shouldn't happen on prod (it may happen locally if you don't have a clean database)
      // let's avoid a crash if for any reason it happens
      if (!sheetMapFunction) return null

      const sheetMap = sheetMapFunction(workspace)

      if (!("default" in sheetMap)) return null
      // @ts-ignore
      return sheetMap["default"]["columns"]
    },
    [reportType.name, reportFormat.name, workspace],
  )

  useEffect(() => {
    const hiddenColumns = reportFormat.columns.filter((c) => !c.isVisible)
    onChange({ allColumns: true, columns: hiddenColumns })
  }, [reportFormat.columns, onChange])

  // TODO: Handle customizing columns for multi sheet reports
  if (reportPrettyColumnNames === null) return <></>

  return (
    <DrawerFormSection name={`Customize columns for ${reportType.displayName} (${reportFormat.displayName})`}>
      <Box display="flex" mb={2}>
        <ButtonUI
          mode={value.allColumns ? Mode.DEFAULT : Mode.CONTAINED}
          onClick={() => onChange({ allColumns: true, columns: value.columns })}
        >
          All columns
        </ButtonUI>
        <Box mr={2} />
        <ButtonUI
          mode={!value.allColumns ? Mode.DEFAULT : Mode.CONTAINED}
          onClick={() => onChange({ allColumns: false, columns: value.columns })}
        >
          Selected columns
        </ButtonUI>
      </Box>
      {!value.allColumns && (
        <DrawerFormSection name="Columns">
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={reportFormat.columns}
            disableCloseOnSelect
            getOptionLabel={(option) => reportPrettyColumnNames[option.name as keyof typeof reportPrettyColumnNames]}
            getOptionSelected={(opt, value) => opt.name === value.name}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox color="primary" style={{ marginRight: 8 }} checked={!selected} />
                {reportPrettyColumnNames[option.name as keyof typeof reportPrettyColumnNames]}
              </>
            )}
            value={value.columns}
            onChange={(_, newValue) => onChange({ columns: newValue, allColumns: false })}
            renderInput={({ InputProps, ...params }) => (
              <TextField
                ref={InputProps.ref}
                className={InputProps.className}
                error={allColumnsHidden}
                {...params}
                placeholder={`${value.columns.length} ${pluralize(value.columns.length > 1, "column")} removed`}
              />
            )}
          />
        </DrawerFormSection>
      )}
    </DrawerFormSection>
  )
}

export default ReportHideColumnForm
