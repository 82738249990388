import React from "react"
import { TextField } from "@material-ui/core"
import moment from "moment"

import { Dates } from "../../types"

type CustomDate = {
  startDate: string
  endDate: string
}

export interface DatePickerTextfieldsProps {
  dates: Dates
  setDates: (dates: Dates) => void
  customDates: CustomDate
  setCustomDates: (data: CustomDate) => void
}

const DatePickerTextfields = ({
  dates,
  setDates,
  customDates,
  setCustomDates,
}: DatePickerTextfieldsProps): JSX.Element => {
  const isInputEmpty = (type: string) => {
    if (type === "start") return customDates.startDate === "" ? true : false
    if (type === "end") return customDates.endDate === "" ? true : false
  }
  const getNewDates = (type: string) => {
    const newStartDate = customDates.startDate !== "" ? moment(customDates.startDate, "MM/DD/YYYY") : null
    const newEndDate = customDates.endDate !== "" ? moment(customDates.endDate, "MM/DD/YYYY") : null

    return { date: type === "start" ? newStartDate : newEndDate, start: newStartDate, end: newEndDate }
  }
  const getEmptyDates = (type: string) => {
    return { startDate: type === "start" ? null : dates.startDate, endDate: type === "end" ? null : dates.endDate }
  }
  const getCustomDates = () => {
    return {
      startDate: dates.startDate ? dates.startDate.format("MM/DD/YYYY") : "",
      endDate: dates.endDate ? dates.endDate.format("MM/DD/YYYY") : "",
    }
  }
  const handleInputDate = (type: string) => {
    const handleNewDates = () => {
      if (
        newDates.date &&
        newDates.date.isValid() &&
        !(newDates.end?.isBefore(newDates.start) || newDates.start?.isAfter(newDates.end))
      )
        setDates({ startDate: newDates.start, endDate: newDates.end })
      else setCustomDates(getCustomDates())
    }

    if (isInputEmpty(type)) {
      setDates(getEmptyDates(type))
      return
    }
    const newDates = getNewDates(type)
    handleNewDates()
  }

  return (
    <div className="flex flex-row items-center justify-around w-[95%] border-b border-grey-200 mb-4 pb-4">
      <TextField
        label="Start Date"
        placeholder="MM/DD/YYYY"
        value={customDates.startDate}
        onChange={(e) => setCustomDates({ startDate: e.target.value, endDate: customDates.endDate })}
        onBlur={() => {
          handleInputDate("start")
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") handleInputDate("start")
        }}
      />
      <TextField
        label="End Date"
        placeholder="MM/DD/YYYY"
        value={customDates.endDate}
        onChange={(e) => setCustomDates({ startDate: customDates.startDate, endDate: e.target.value })}
        onBlur={() => {
          handleInputDate("end")
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") handleInputDate("end")
        }}
      />
    </div>
  )
}

export default DatePickerTextfields
