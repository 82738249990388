import { useTypedController } from "@hookform/strictly-typed"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { Delete as DeleteIcon } from "@material-ui/icons"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { DrawerCategory } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import BaseTable from "components/Table"
import { IExtendedDataTableColumn } from "components/Table/interface"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { BackOfficeGetAssetExchangeLinksDto, GetPublicAssetDto, GetWalletTypeDto } from "services/api/openapi"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface ExchangeLinkCreationDialogProps {
  asset: GetPublicAssetDto
  availableExchanges: GetWalletTypeDto[]
  onCompleted: () => void
}

const formId = "create-asset-exchange_link"

interface Form {
  walletTypeId?: string
  exchangeSymbol: string
}

function ExchangeLinkCreationDialog({ asset, availableExchanges, onCompleted }: ExchangeLinkCreationDialogProps) {
  const { handleSubmit, control, formState, reset } = useForm<Form>({
    mode: "onChange",
  })
  const TypedController = useTypedController<Form>({ control })
  const [CreateButton, handleButtonCallback] = useLoadingButton()
  const { mutateAsync: createAssetExchangeLink } = api.backOffice.asset.useCreateAssetExchangeLink()
  const toast = useToast()

  const onSubmit = async ({ walletTypeId, exchangeSymbol }: Form) => {
    if (walletTypeId === undefined) return

    try {
      await createAssetExchangeLink({
        assetId: asset.id,
        backOfficeCreateAssetExchangeLinkQuery: {
          walletTypeId,
          exchangeSymbol,
        },
      })
      toast.open("Exchange link created", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
    reset()
    onCompleted()
  }

  return (
    <DialogContent>
      <DialogTitle disableTypography>
        <Typography variant="h1" component="h2" style={{ textAlign: "center" }}>
          Link {asset.name} to an exchange
        </Typography>
      </DialogTitle>
      <form id={formId} onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
        <FormLabel htmlFor="exchange-link-select">Exchange</FormLabel>
        <TypedController
          name="walletTypeId"
          rules={{ required: true }}
          defaultValue={undefined}
          render={(props) => (
            <Select fullWidth id="exchange-link-select" {...props}>
              {availableExchanges.map((p) => (
                <MenuItem key={p.id} value={p.id}>
                  {p.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />

        <Box mt={2}>
          <FormLabel htmlFor="exchange-link-key-field">Exchange symbol</FormLabel>
          <TypedController
            name="exchangeSymbol"
            rules={{ required: true }}
            defaultValue={""}
            render={(props) => <TextField {...props} id="exchange-link-key-field" fullWidth margin="normal" />}
          />
        </Box>
      </form>
      <DialogActions>
        <CreateButton disabled={!formState.isValid} type="submit" form={formId}>
          Create
        </CreateButton>
      </DialogActions>
    </DialogContent>
  )
}

interface AssetExchangeLinksProps {
  asset: GetPublicAssetDto
}

export function AssetExchangeLinks({ asset }: AssetExchangeLinksProps) {
  const exchangeLinks = api.backOffice.asset.useAssetLinks(asset.id)
  const sources = api.wallet.useTypes({})
  const { mutateAsync: deleteAssetExchangeLink } = api.backOffice.asset.useDeleteAssetExchangeLink()
  const [creationDialogIsOpen, setCreationDialogIsOpen] = useState<boolean>(false)

  if (sources.isError) return <NetworkErrorMessage small={false} additionalData={sources} />
  if (exchangeLinks.isError) return <NetworkErrorMessage small={false} additionalData={exchangeLinks} />
  if (exchangeLinks.isLoading || exchangeLinks.data === undefined || sources.isLoading || sources.data === undefined)
    return <LoadingSpinner />

  const availabledExchanges = sources.data.filter((s) => s.type === "exchange")

  const onDeleteExchangeLink = async ({ walletTypeId, exchangeSymbol }: BackOfficeGetAssetExchangeLinksDto) => {
    await deleteAssetExchangeLink({
      assetId: asset.id,
      backOfficeDeleteAssetExchangeLinkQuery: {
        walletTypeId,
        exchangeSymbol,
      },
    })
  }

  const columns: IExtendedDataTableColumn<BackOfficeGetAssetExchangeLinksDto>[] = [
    {
      name: "Exchange",
      selector: "walletTypeName",
    },
    {
      name: "Exchange symbol",
      selector: "exchangeSymbol",
    },
    {
      name: "Actions",
      selector: "delete",
      format: function DeleteExchangeLink(row) {
        const [DeleteButton, handleButtonCallback] = useLoadingButton()
        return (
          <DeleteButton mode={Mode.TEXTONLY} onClick={handleButtonCallback(() => onDeleteExchangeLink(row))}>
            <DeleteIcon />
          </DeleteButton>
        )
      },
    },
  ]
  const creationDialog = (
    <Dialog open={creationDialogIsOpen} onClose={() => setCreationDialogIsOpen(false)}>
      <ExchangeLinkCreationDialog
        asset={asset}
        availableExchanges={availabledExchanges}
        onCompleted={() => setCreationDialogIsOpen(false)}
      />
    </Dialog>
  )

  return (
    <DrawerCategory title="Exchange links">
      {creationDialog}
      {exchangeLinks.data.length === 0 ? (
        <Box mt={2}>
          <Typography variant="body1">This asset is not linked to any exchange</Typography>
        </Box>
      ) : (
        <BaseTable columns={columns} items={exchangeLinks.data} />
      )}
      <Box mt={2}>
        <ButtonUI
          onClick={() => {
            setCreationDialogIsOpen(true)
          }}
        >
          Add
        </ButtonUI>
      </Box>
    </DrawerCategory>
  )
}
