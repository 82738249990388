import { Box, Paper, Typography } from "@material-ui/core"
import { useStripe } from "@stripe/react-stripe-js"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"

import BaseContainer from "components/Container"
import MainTitleView from "components/MainTitleView"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import PeriodSelector from "components/Stripe/PeriodSelector"
import PlanWithAddonView from "components/Stripe/planWithAddonView"
import { ExtendedLimitAddon, getExtendedLimitAddon, getPlanWithAddons } from "components/Stripe/PricingCalculator"
import ButtonUI from "CryptioUI/Button"
import { URLS } from "../../../routes"
import api from "services/api"
import { SubscriptionPayingDetailsPeriodTypeEnum } from "services/api/openapi"
import { useParams } from "services/misc/useParams"
import ProrationDetails from "./prorationDetails"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

export interface BillingCheckoutParameters {
  planId: string
  limitAddons?: {
    id: string
    additionalCount: number
  }[]
  featureAddons?: {
    id: string
  }[]
}

const BillingCheckoutScene = (): JSX.Element => {
  const toast = useToast()
  const { params } = useParams<BillingCheckoutParameters>()
  const [period, setPeriod] = useState<SubscriptionPayingDetailsPeriodTypeEnum>("yearly")
  const { mutateAsync: createStripeCheckoutSession } = api.billing.useCreateStripeCheckoutSession()
  const { mutateAsync: createChangeSubscription } = api.billing.useChangeSubscription()
  const subscription = api.billing.useFullSubscription()

  const plan = api.billing.usePlan(params.planId)
  const packages = api.billing.usePackages()
  const stripe = useStripe()
  const history = useHistory()

  useEffect(() => {
    if (!params.planId) {
      history.push(URLS.Portfolio)
    }
  }, [params.planId, history])

  useEffect(() => {
    if (subscription.data && subscription.data.payingDetails !== null)
      setPeriod(subscription.data.payingDetails.periodType)
  }, [subscription.data])

  if (subscription.isError) return <NetworkErrorMessage additionalData={subscription} />

  if (subscription.isLoading || subscription.data === undefined) return <LoadingSpinner />

  if (packages.isError) return <NetworkErrorMessage additionalData={packages} />

  if (packages.isLoading || packages.data === undefined) return <LoadingSpinner />

  if (plan.isError) return <NetworkErrorMessage additionalData={plan} />

  if (plan.isLoading || plan.data === undefined) return <LoadingSpinner />

  const limitAddons: ExtendedLimitAddon[] = (params.limitAddons || []).map((laddon) => {
    const addon = packages.data.limitAddons.find((x) => x.id === laddon.id)
    if (!addon) throw new Error(`Add-on not found`)

    return getExtendedLimitAddon(addon, laddon.additionalCount)
  })

  const featureAddons = (params.featureAddons || []).map((fadodn) => {
    const addon = packages.data.featureAddons.find((x) => x.id === fadodn.id)
    if (!addon) throw new Error(`Add-on not found`)

    return addon
  })

  const changeSubscription = async () => {
    try {
      await createChangeSubscription({
        updateSubscriptionDto: {
          planId: plan.data.id,
          limitAddOns: limitAddons.map((x) => ({
            addonId: x.id,
            additional: x.additionalRequired,
          })),
          featureAddOnIds: featureAddons.map((x) => x.id),
        },
      })
      history.push(URLS.Billing.Status + "?checkout_success_from=updated_subscription")
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const openStripeCheckoutSession = async () => {
    try {
      const stripeCheckoutSession = await createStripeCheckoutSession({
        startSubscriptionDto: {
          planId: plan.data.id,
          periodType: period,
          limitAddOns: limitAddons.map((x) => ({
            addonId: x.id,
            additional: x.additionalRequired,
          })),
          featureAddOnIds: featureAddons.map((x) => x.id),
        },
      })
      stripe
        ?.redirectToCheckout({
          sessionId: stripeCheckoutSession.sessionId,
        })
        .then((x) => console.error(x))
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const planWithAddons = getPlanWithAddons(plan.data, limitAddons, featureAddons)

  return (
    <BaseContainer>
      <MainTitleView title="Checkout" />
      {subscription.data.type === "paying" && subscription.data.payingDetails?.source === "stripe" && (
        <ProrationDetails
          planId={params.planId}
          totalUsdPrice={
            period === "monthly" ? planWithAddons.totalUsdPricePerMonth : planWithAddons.totalUsdPricePerYear
          }
          limitAddons={params.limitAddons}
        />
      )}
      <Box p={2} mt={2} component={Paper}>
        {subscription.data.type === "trial" && <PeriodSelector value={period} setValue={setPeriod} />}
        <Box my={1}>
          {subscription.data.type === "paying" ? (
            <Typography variant="h4">New subscription price:</Typography>
          ) : (
            <Typography variant="h4">Total:</Typography>
          )}
          {period === "monthly" && (
            <Typography variant="h5">${planWithAddons.totalUsdPricePerMonth.toFixed(2)} USD / Month</Typography>
          )}
          {period === "yearly" && (
            <Typography variant="h5">${planWithAddons.totalUsdPricePerYear.toFixed(2)} USD / Year</Typography>
          )}
        </Box>
        {subscription.data.type === "paying" ? (
          <ButtonUI onClick={changeSubscription}>Change subscription</ButtonUI>
        ) : (
          <ButtonUI onClick={openStripeCheckoutSession}>Proceed to checkout</ButtonUI>
        )}
      </Box>
      <Box p={2} mt={2} component={Paper}>
        <PlanWithAddonView
          plan={planWithAddons.plan}
          featureAddons={planWithAddons.featureAddons}
          limitAddons={planWithAddons.limitAddons}
          currentWorkspaceUsage={packages.data.currentWorkspaceUsage}
        />
      </Box>
    </BaseContainer>
  )
}

export default BillingCheckoutScene
