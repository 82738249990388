import { useTypedController } from "@hookform/strictly-typed"
import { Box, DialogActions, Typography } from "@material-ui/core"
import { createFilterOptions } from "@material-ui/lab"
import React from "react"
import { useForm } from "react-hook-form"

import CustomSelector from "components/selector/CustomSelector"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import { GetContactDto, GetContactsRequest } from "services/api/openapi"
import ContactOptionInAutocomplete from "../AutoCompleteOptions/ContactOptionInAutocomplete"
import { useLoadingButton } from "../LoadingButton"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface ContactCreatorForm {
  name: string
  contact: string | GetContactDto | null
}

const DialogCreateContact = (props: { address: string; isOpen: boolean; onClose: () => void }) => {
  const { mutateAsync: createContactMutation } = api.contact.useCreateContacts()
  const { mutateAsync: updateContactMutation } = api.contact.useUpdateContacts()
  const { handleSubmit, control, formState } = useForm<ContactCreatorForm>({
    mode: "onChange",
  })
  const TypedController = useTypedController<ContactCreatorForm>({ control })
  const toast = useToast()

  const [CreateContactButton, handleButtonCallback] = useLoadingButton()

  const createContact = handleSubmit(async (form) => {
    if (!form.contact) {
      return
    }

    try {
      if (typeof form.contact === "string") {
        await createContactMutation({
          createContactsDto: {
            contacts: [
              {
                name: form.contact,
                addresses: [props.address],
              },
            ],
          },
        })
        toast.open("Contact created", { variant: "success" })
      } else {
        const selectedContact = form.contact as GetContactDto
        await updateContactMutation({
          updateContactDto: {
            id: selectedContact.id,
            name: selectedContact.name,
            addresses: [...selectedContact.addresses, props.address],
          },
        })
        toast.open("Contact updated", { variant: "success" })
      }

      props.onClose()
    } catch (e) {
      toastCatch(e, toast)
    }
  })

  const createContactFormId = "create-contact-form"

  return (
    <ModalUI
      isOpen={props.isOpen}
      onCancel={props.onClose}
      title="Create contact"
      onClose={props.onClose}
      className="max-w-[600px]"
    >
      <Typography variant="body1">
        Use the selector below to either associate the address <strong>{props.address}</strong> to an already existing
        contact, or create a new one.
      </Typography>
      <br />

      <Box component="form" id={createContactFormId} onSubmit={handleButtonCallback(createContact)} mb={1}>
        <TypedController
          name="contact"
          rules={{ required: true }}
          defaultValue={null}
          render={({ ref, onChange, ...rest }) => (
            <CustomSelector<WithoutWorkspaceId<GetContactsRequest>, string | GetContactDto, false, false, true>
              fullWidth
              {...rest}
              onChange={(_, newValue) => onChange(newValue)}
              filterOptions={(options, params) => {
                const filter = createFilterOptions<GetContactDto>({
                  stringify: (opt) => opt.name,
                })

                const filtered: (GetContactDto | string)[] = filter(options as GetContactDto[], params)

                if (
                  (params.inputValue !== "" &&
                    !options.find((opt) => (opt as GetContactDto).name === params.inputValue)) ||
                  filtered.length === 0
                ) {
                  filtered.push(params.inputValue)
                }
                return filtered
              }}
              getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
              inputRef={ref}
              defaultPaginatedQueryProps={{
                sortBy: "movement_count",
                sortDirection: "descending",
              }}
              getOptionSelected={(opt, other) => {
                if (typeof opt !== typeof other) {
                  return false
                }
                if (typeof opt === "string") {
                  return opt === other
                }
                return opt.id === (other as GetContactDto).id
              }}
              usePaginatedQuery={api.contact.useContacts}
              freeSolo
              onInputBlur={(typingInput) => {
                if (
                  typingInput &&
                  (!rest.value ||
                    (typeof rest.value === "string" ? typingInput !== rest.value : rest.value.name !== typingInput))
                ) {
                  onChange(typingInput)
                }
              }}
              size="small"
              placeholder="Type to search..."
              getOptionDisabled={(option) => option === ""}
              renderOption={(option) => {
                if (option === "") return "No options"
                if (typeof option === "string") return `Create contact "${option}"`
                return <ContactOptionInAutocomplete contact={option as GetContactDto} />
              }}
            />
          )}
        />
      </Box>

      <DialogActions>
        <ButtonUI onClick={props.onClose} mode={Mode.OUTLINED}>
          Cancel
        </ButtonUI>
        <CreateContactButton disabled={!formState.isValid} type="submit" form={createContactFormId}>
          Create
        </CreateContactButton>
      </DialogActions>
    </ModalUI>
  )
}

export default DialogCreateContact
