import MuiDialogTitle from "@material-ui/core/DialogTitle"
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles"
import React from "react"
import ModalUI from "CryptioUI/Modal"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

interface Props {
  open: boolean
  onRefuse?: () => void
  onAccept: () => Promise<void> | void
  title: string
  content: React.ReactNode
  noText?: string
  yesText: string
  onCancel: () => void
  className: string
}

interface DialogTitleProps extends WithStyles<typeof styles> {
  id?: string
  children: React.ReactNode
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
    </MuiDialogTitle>
  )
})

const BasicDialog = (props: Props): JSX.Element => {
  const { open, onAccept, title, content, noText, yesText, onCancel, onRefuse, className } = props

  return (
    <ModalUI
      isOpen={open}
      onCancel={onRefuse}
      title={title}
      cancelTitle={noText ?? undefined}
      onClose={onCancel}
      actionTitle={yesText}
      onAction={onAccept}
      className={className}
    >
      {content}
      {/* <DialogActions>
        {props.data.noText && (
          <ButtonUI color="default" onClick={props.data.onRefuse}>
            {props.data.noText}
          </ButtonUI>
        )}
        <UncontrolledLoadingButton color="primary" onClick={props.data.onAccept}>
          {props.data.yesText}
        </UncontrolledLoadingButton>
      </DialogActions> */}
    </ModalUI>
  )
}

export default BasicDialog
