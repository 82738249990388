import { Box, Typography } from "@material-ui/core"
import React from "react"
import { UseQueryResult } from "react-query"

interface Props {
  additionalData?: UseQueryResult<unknown>
  small?: boolean
  size?: {
    height: number
    width: number
  }
}

const NetworkErrorMessage = ({
  additionalData,
  small = true,
  size = { height: 512, width: 512 },
}: Props): JSX.Element => {
  console.error(additionalData?.error)
  if (small) return <p>Network error</p>

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" flexDirection="column">
      <img width={size.width} height={size.height} src="/images/network_error.svg" alt="" />
      <Box pt={5}>
        <Typography variant="h3">Network error</Typography>
      </Box>
    </Box>
  )
}

export default NetworkErrorMessage
