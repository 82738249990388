import { Dialog } from "@headlessui/react"
import React from "react"

import { BoldWeight, ButtonSize, HeaderDrawerProps, Mode, TypographyVariant } from "../types"
import ButtonUI from "../Button"
import Typography from "../Typography"
import { ReactComponent as CloseStroke } from "../assets/icons/close-stroke.svg"

const HeaderModalUI = (props: HeaderDrawerProps): JSX.Element => {
  const { title, onClose } = props
  const CloseIcon = () => <CloseStroke className="h-4 w-4" />

  return (
    <Dialog.Title as="h3">
      <div className="h-fit py-2 px-4 flex items-center w-full justify-between">
        <Typography variant={TypographyVariant.H4} boldness={BoldWeight.MEDIUM}>
          {title}
        </Typography>
        <ButtonUI
          className="!p-0"
          mode={Mode.TEXTONLY}
          size={ButtonSize.MD}
          Icon={<>{CloseIcon}</>}
          onClick={onClose}
        />
      </div>
    </Dialog.Title>
  )
}

export default HeaderModalUI
