import { Box, BoxProps, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import { WarningRounded } from "@material-ui/icons"
import React from "react"

import ChildrenProps from "services/misc/childrenProps"

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  warningIcon: {
    marginRight: theme.spacing(1),
  },
}))

type Props = Omit<BoxProps, "component" | "display" | "children"> & ChildrenProps & { paper?: boolean }

const WarningTypography = ({ children, paper, ...props }: Props) => {
  const classes = useStyles()
  return (
    <Box {...props} component={paper ? Paper : undefined} className={paper ? classes.paper : undefined} display="flex">
      <WarningRounded className={classes.warningIcon} />
      <Typography variant="body1">{children}</Typography>
    </Box>
  )
}

export default WarningTypography
