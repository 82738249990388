import React from "react"

import { makeStyles, Typography } from "@material-ui/core"
import { Mode } from "CryptioUI/types"
import ButtonUI from "CryptioUI/Button"

const useStyles = makeStyles(() => ({
  addFilter: {
    textDecoration: "underline",
  },
  container: {
    paddingLeft: 0,
  },
}))

interface Props {
  onClick: () => void
  title: string
  icon: React.ReactNode
  disabled?: boolean
}

const IconButton = (props: Props): JSX.Element => {
  const { onClick, title, icon, disabled = false } = props
  const classes = useStyles()

  return (
    <ButtonUI
      Icon={<>{icon}</>}
      mode={Mode.TEXTONLY}
      disabled={disabled}
      onClick={onClick}
      className={classes.container}
    >
      <Typography variant="body2" className={classes.addFilter}>
        {title}
      </Typography>
    </ButtonUI>
  )
}

export default IconButton
