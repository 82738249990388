import { Box, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"

import WalletOtherParty from "./WalletOtherParty"

interface NoOtherPartyProps {
  walletDrawer: JSX.Element
  contactDrawer: JSX.Element
  isFrom: boolean
  wallet: string
  onClickWallet: () => void
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    overflow: "hidden",
    width: "100%",
  },
}))

const NoOtherParty = (props: NoOtherPartyProps) => {
  const { walletDrawer, contactDrawer, isFrom, wallet, onClickWallet } = props
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {walletDrawer}
      {contactDrawer}
      <Grid container wrap="nowrap" direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs zeroMinWidth>
          <WalletOtherParty subtitle={isFrom ? "from:" : "to:"} name={wallet} onClick={onClickWallet} type={"wallet"} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default NoOtherParty
