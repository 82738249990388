import { useMutation, useQuery, UseQueryOptions } from "react-query"

import { queryClient } from "../../../.."
import { usePaginatedWorkspaceQuery } from "../../../context/workspaceContext"
import { WithoutWorkspaceId } from "../../aliases"
import { BackOfficeBillingApi, BackOfficeGetPlansRequest, BackOfficePaginatedPlansResultDto } from "../../openapi"

export const BACK_OFFICE_GET_PLANS_KEY = "backOfficeGetPlans"
export const BACK_OFFICE_GET_PLAN_KEY = "backOfficeGetPlan"

export const backOfficeBillingActions = {
  usePlans: (
    variables: WithoutWorkspaceId<BackOfficeGetPlansRequest>,
    options?: UseQueryOptions<BackOfficePaginatedPlansResultDto>,
  ) => {
    return usePaginatedWorkspaceQuery(
      [BACK_OFFICE_GET_PLANS_KEY, variables],
      () => BackOfficeBillingApi.backOfficeGetPlans(variables),
      variables,
      options,
    )
  },
  usePlan: (planId: string | null) => {
    return useQuery(
      [BACK_OFFICE_GET_PLAN_KEY, planId],
      () => BackOfficeBillingApi.backOfficeGetPlan({ planId: planId ?? "" }),
      { enabled: planId !== null },
    )
  },
  useCreatePlan: () => {
    return useMutation(BackOfficeBillingApi.backOfficeCreatePlan, {
      onSuccess: () => {
        // Update workspace
        queryClient.invalidateQueries(BACK_OFFICE_GET_PLANS_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_PLAN_KEY)
      },
    })
  },
  useTogglePlanVisibility: () => {
    return useMutation(BackOfficeBillingApi.backOfficeToggleVisibility, {
      onSuccess: () => {
        // Update workspace
        queryClient.invalidateQueries(BACK_OFFICE_GET_PLANS_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_PLAN_KEY)
      },
    })
  },
}
