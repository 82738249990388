import { useTypedController } from "@hookform/strictly-typed"
import { Box, FormControlLabel, Switch } from "@material-ui/core"
import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { ReportFormComponentProps } from "."
import { DrawerReportForm } from "../ReportForm"

export const IncludeNftByDefault = ({ control }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })

  return <TypedController name="includeNft" defaultValue={true} />
}

export const IncludeNftToggle = ({ control }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })

  return (
    <DrawerFormSection name="NFT">
      <TypedController
        name="includeNft"
        defaultValue={false}
        rules={{
          required: false,
        }}
        render={({ onChange, value }) => (
          <Box mt={2} mb={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={value}
                  onChange={(newValue) => {
                    onChange(newValue.target.checked)
                  }}
                  name="include-nft"
                />
              }
              label="Include NFT"
            />
          </Box>
        )}
      />
    </DrawerFormSection>
  )
}
