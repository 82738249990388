import { Grid, Typography } from "@material-ui/core"
import React from "react"

import { BackOfficeGetFullPlanDto } from "services/api/openapi"
import { pluralize } from "services/utils/textUtils"

interface Props {
  plan: BackOfficeGetFullPlanDto
}

const BackOfficePlanInAutocomplete = ({ plan }: Props) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Typography variant="body1">
          {plan.name}
          {plan.isPublic ? "" : " (private)"}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {plan.usdPricePerMonth} $ per month / {plan.usdPricePerYear} $ per year
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {plan.activeSubscriptionCount} {pluralize(plan.activeSubscriptionCount > 0, "active workspace")}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default BackOfficePlanInAutocomplete
