/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import { GetAssetCsvSignedUrlDto, GetPublicAssetDto, PaginatedPublicAssetResultDto } from "../models"

export interface DownloadAssetCsvRequest {
  workspaceId: string
}

export interface GetOnePublicAssetRequest {
  assetId: string
  workspaceId: string
}

export interface GetPublicAssetsRequest {
  page: number
  limit: number
  workspaceId: string
  query?: string
  sortBy?: GetPublicAssetsSortByEnum
  sortDirection?: GetPublicAssetsSortDirectionEnum
  type?: GetPublicAssetsTypeEnum
  address?: string
  includeEmptyAssets?: boolean
  queryWorkspaceId?: string
  assets?: Array<string>
  excludedIds?: Array<string>
}

/**
 *
 */
export class AssetApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static downloadAssetCsv(params: DownloadAssetCsvRequest) {
    return localAssetApi.downloadAssetCsv(params)
  }

  /**
   * Get a signed url to download the Cryptio asset list
   */
  downloadAssetCsv({ workspaceId }: DownloadAssetCsvRequest): Promise<GetAssetCsvSignedUrlDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/asset/asset-csv`)
  }

  static getOnePublicAsset(params: GetOnePublicAssetRequest) {
    return localAssetApi.getOnePublicAsset(params)
  }

  /**
   * Get one public asset
   */
  getOnePublicAsset({ assetId, workspaceId }: GetOnePublicAssetRequest): Promise<GetPublicAssetDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/asset/assets/public/${encodeURIComponent(assetId)}`,
    )
  }

  static getPublicAssets(params: GetPublicAssetsRequest) {
    return localAssetApi.getPublicAssets(params)
  }

  /**
   * Get all public assets
   */
  getPublicAssets({ workspaceId, ...queryParameters }: GetPublicAssetsRequest): Promise<PaginatedPublicAssetResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/asset/assets/public?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }
}

const localAssetApi = new AssetApi()

/**
 * @export
 * @enum {string}
 */
export type GetPublicAssetsSortByEnum = "name" | "symbol" | "created_at" | "market_cap_rank"
/**
 * @export
 * @enum {string}
 */
export type GetPublicAssetsSortDirectionEnum = "ascending" | "descending"
/**
 * @export
 * @enum {string}
 */
export type GetPublicAssetsTypeEnum = "fiat" | "exchange_unknown" | "token" | "main_chain"
