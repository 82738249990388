import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from "@material-ui/core"
import { Delete as DeleteIcon } from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import LoadingSpinner from "components/misc/LoadingSpinner"
import useDrawer from "components/misc/useDrawer"
import { toastCatch } from "components/ReactHookForm/utils"
import { useToast } from "CryptioUI/Toaster"
import dayjs from "dayjs"
import { REGEX_UUID_V4 } from "pure-shared"
import React, { useState } from "react"
import api from "services/api"
import { BackOfficeQueueNamesDtoQueueNamesEnum, BackOfficeWorkspaceJobDto } from "services/api/openapi"

// ¯\_(ツ)_/¯

const BackOfficeJobsScene = (): JSX.Element => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs centered value={value} onChange={handleChange}>
          <Tab label="Workspaces" />
          <Tab label="Queues" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <BOWorkspaceJobsSelector />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BOQueueJobsSelector />
      </TabPanel>
    </Box>
  )
}

const isUuid4 = (uuid: string) => REGEX_UUID_V4.test(uuid)

const BOWorkspaceJobsSelector = (): JSX.Element => {
  const [queueName, setQueueName] = useState<string | null>(null)
  const [workspaceId, setWorkspaceId] = useState<string | undefined>(undefined)

  const queueNames = api.backOffice.jobs.useQueueNames()

  if (queueNames.isLoading || queueNames.data === undefined) return <LoadingSpinner />

  const isEverythingGood = workspaceId != undefined && isUuid4(workspaceId)

  return (
    <Box>
      <Box
        display="flex"
        p={2}
        justifyContent="center"
        alignItems="end"
        mb={4}
        style={{ border: isEverythingGood ? undefined : "solid red" }}
      >
        <Autocomplete
          options={queueNames.data.queueNames}
          onChange={(_e, v) => setQueueName(v)}
          renderInput={(params) => <TextField {...params} label="Queue Name" />}
          style={{ width: 500 }}
        />
        <Box mr={8} />
        <TextField
          style={{ width: 400 }}
          placeholder="Workspace ID"
          value={workspaceId}
          onChange={(e) => setWorkspaceId(e.target.value)}
        ></TextField>
      </Box>
      <Divider />
      {isEverythingGood && <BOWorkspaceJobs workspaceId={workspaceId} queueName={queueName} />}
    </Box>
  )
}

const BOWorkspaceJobs = (props: { workspaceId: string; queueName: string | null }): JSX.Element => {
  const jobs = api.backOffice.jobs.useWorkspaceJobs(
    props.workspaceId,
    (props.queueName as BackOfficeQueueNamesDtoQueueNamesEnum) ?? undefined,
  )

  if (jobs.isLoading || jobs.data === undefined) return <LoadingSpinner />

  return <BOJobsViewer jobs={jobs.data.jobs} />
}

const BOQueueJobsSelector = (): JSX.Element => {
  const [queueName, setQueueName] = useState<string | null>(null)

  const queueNames = api.backOffice.jobs.useQueueNames()

  if (queueNames.isLoading || queueNames.data === undefined) return <LoadingSpinner />

  const isEverythingGood = queueName !== null

  return (
    <Box>
      <Box
        display="flex"
        p={2}
        justifyContent="center"
        alignItems="end"
        mb={4}
        style={{ border: isEverythingGood ? undefined : "solid red" }}
      >
        <Autocomplete
          options={queueNames.data.queueNames}
          onChange={(_e, v) => setQueueName(v)}
          renderInput={(params) => <TextField {...params} label="Queue Name" />}
          style={{ width: 500 }}
        />
      </Box>
      {isEverythingGood && <BOQueueJobs queueName={queueName} />}
    </Box>
  )
}

const BOQueueJobs = (props: { queueName: string }): JSX.Element => {
  const jobs = api.backOffice.jobs.useQueueJobs(props.queueName)

  if (jobs.isLoading || jobs.data === undefined) return <LoadingSpinner />

  return <BOJobsViewer jobs={jobs.data.jobs} />
}

const BOJobsViewer = ({ jobs }: { jobs: BackOfficeWorkspaceJobDto[] }): JSX.Element => {
  const [workspaceDrawer, openWorkspaceDrawer] = useDrawer("backoffice-workspace")
  const toast = useToast()
  const { mutateAsync: mutateKilljob } = api.backOffice.jobs.useKillJob()

  const killJob = async (jobId: string) => {
    try {
      await mutateKilljob({
        jobId,
      })
      toast.open("Job killed", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  if (jobs.length === 0) return <>No jobs</>

  return (
    <>
      {workspaceDrawer}
      <TableContainer component={Paper}>
        <Table style={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Queue</TableCell>
              <TableCell>WorkspaceId</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last updated at</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs
              .sort((a, b) => a.status.localeCompare(b.status))
              .map((job) => (
                <TableRow key={job.jobId}>
                  <TableCell>{job.queueName}</TableCell>
                  <TableCell>
                    <Button variant="text" onClick={() => openWorkspaceDrawer(job.workspaceId)}>
                      {job.workspaceId}
                    </Button>
                  </TableCell>
                  <TableCell>{job.status}</TableCell>
                  <TableCell>{dayjs.unix(job.lastUpdatedAt / 1000).fromNow()}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => killJob(job.jobId)} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

export default BackOfficeJobsScene
