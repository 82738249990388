import { IconButton, InputAdornment, TextField, TextFieldProps } from "@material-ui/core"
import { LockRounded, VisibilityOffRounded, VisibilityRounded } from "@material-ui/icons"
import React, { useState } from "react"

const PasswordField = React.forwardRef(
  ({ variant = "standard", ...props }: Omit<TextFieldProps, "startAdornment" | "endAdornment" | "type">, ref) => {
    const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false)

    return (
      <TextField
        type={isPasswordVisible ? "text" : "password"}
        {...props}
        variant={variant}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockRounded />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton style={{ padding: 0 }} onClick={() => setPasswordVisibility(!isPasswordVisible)}>
                {isPasswordVisible ? <VisibilityRounded /> : <VisibilityOffRounded />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        innerRef={ref}
      />
    )
  },
)

PasswordField.displayName = "PasswordField"

export default PasswordField
