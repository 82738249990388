import BigNumber from "bignumber.js"

function prettyNumber(x: BigNumber.Value) {
  const bigX = new BigNumber(x)
  return {
    average: bigX.gte(1000) ? true : false,
    mantissa: bigX.gte(1000) ? 1 : 4,
    trimMantissa: true,
  }
}

function roundToTwoDecimalMax(num: number): number
function roundToTwoDecimalMax(num: number | null): number | null
function roundToTwoDecimalMax(num: number | null): number | null {
  if (num !== null) {
    return Math.round((num + Number.EPSILON) * 100) / 100
  }
  return null
}

export { prettyNumber, roundToTwoDecimalMax }
