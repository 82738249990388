import { Box, makeStyles } from "@material-ui/core"
import React, { useCallback, useContext } from "react"
import { defaultImportParams, defaultImportSorting, ImportParameters, paramsToFilter } from "scenes/Imports"
import api from "services/api"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import { GetWalletsSortByEnum } from "services/api/openapi"

import { AccountingFilterContext, Tabs } from "services/context/accountingFilterContext"
import { WorkspaceContext } from "services/context/workspaceContext"
import { Mixpanel } from "services/mixpanel"

const useStyles = makeStyles(() => ({
  tabOn: {
    opacity: 1,
  },
  tabOff: {
    opacity: 0.5,
  },
  buttonTab: {
    borderBottomWidth: "0px",
  },
}))

const AccountingTabsButtons = (): JSX.Element => {
  const classes = useStyles()
  const { tab, changeTab, isTab } = useContext(AccountingFilterContext)
  const { workspace } = useContext(WorkspaceContext)

  const { params } = usePaginatedParams<Partial<ImportParameters>, GetWalletsSortByEnum>(
    defaultImportParams,
    defaultImportSorting,
  )

  const wallets = api.wallet.useWallets(paramsToFilter(params), {
    refetchInterval: 2000,
  })

  const setNewFilter = useCallback(
    (tabClick: Tabs) => {
      changeTab(tabClick)
    },
    [changeTab],
  )

  const isAnyWalletImported = wallets.data?.data ? wallets.data?.data.length > 0 : false

  return (
    <>
      <Box display="flex" alignItems="flex-end" overflow-x="scroll">
        <Box mr={3} className={tab === "label_mapping" ? classes.tabOn : classes.tabOff}>
          <ButtonUI
            className={classes.buttonTab}
            mode={isTab("label_mapping") ? Mode.DEFAULT : Mode.CONTAINED}
            onClick={() => {
              Mixpanel.track("ClickLabelsToMapCOA")
              setNewFilter("label_mapping")
            }}
          >
            Labels to map
          </ButtonUI>
        </Box>
        {isAnyWalletImported && (
          <>
            <Box mr={3} className={tab === "wallet_mapping" ? classes.tabOn : classes.tabOff}>
              <ButtonUI
                className={classes.buttonTab}
                mode={isTab("wallet_mapping") ? Mode.DEFAULT : Mode.CONTAINED}
                onClick={() => {
                  Mixpanel.track("ClickWalletsToMapCOA")
                  setNewFilter("wallet_mapping")
                }}
              >
                Wallets to map
              </ButtonUI>
            </Box>
            {workspace.accountingIntegration !== null && (
              <Box mr={3} className={tab === "exchange_mapping" ? classes.tabOn : classes.tabOff}>
                <ButtonUI
                  className={classes.buttonTab}
                  mode={isTab("exchange_mapping") ? Mode.DEFAULT : Mode.CONTAINED}
                  onClick={() => {
                    Mixpanel.track("ClickExchangesToMapCOA")
                    setNewFilter("exchange_mapping")
                  }}
                >
                  Exchanges to map
                </ButtonUI>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  )
}

export default AccountingTabsButtons
