import { UncontrolledLoadingButton } from "../../../components/LoadingButton"
import api from "../../../services/api"
import LoadingSpinner from "../../../components/misc/LoadingSpinner"
import NetworkErrorMessage from "../../../components/misc/NetworkErrorMessage"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const QuickBooksAutoIncrement = () => {
  const toast = useToast()
  const { mutateAsync: toggleBackofficeQuickbooksMutation } =
    api.backOffice.workspace.useToggleQuickbooksAutoIncrement()

  const autoIncrement = api.backOffice.workspace.useGetQuickbooksAutoIncrement()

  if (autoIncrement.isLoading) return <LoadingSpinner />
  if (autoIncrement.isError) return <NetworkErrorMessage small additionalData={autoIncrement} />

  const onToggle = async () => {
    try {
      await toggleBackofficeQuickbooksMutation({})
      toast.open(`Quickbooks Auto Increment ${autoIncrement.data?.isAutoIncrement ? "disabled" : "enabled"}`, {
        variant: "success",
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  return (
    <UncontrolledLoadingButton onClick={onToggle}>
      {autoIncrement.data?.isAutoIncrement ? "Disable" : "Enable"} Auto Increment
    </UncontrolledLoadingButton>
  )
}

export default QuickBooksAutoIncrement
