import { UseQueryOptions } from "react-query"

import { queryClient } from "../../.."
import { usePaginatedWorkspaceQuery, useWorkspaceMutation, useWorkspaceQuery } from "../../context/workspaceContext"
import { WithoutWorkspaceId } from "../aliases"
import {
  GetTransactionAssetsRequest,
  TransactionsApi,
  FullMovementDto,
  MovementCounterPartyDto,
  GetNewTransactionsRequest,
  NewPaginatedTransactionResultDto,
  NewFullTransactionDto,
  GetNewTransactionMovementsRequest,
  NewPaginatedTransactionMovementResultDto,
  PaginatedMovementCostBasisTaxLine,
} from "../openapi"
// import { GET_LABEL_KEY, GET_LABELS_KEY } from "./label"
import { GET_WORKSPACE_METRICS_KEY, GET_WORKSPACES_OR_DEFAULT_KEY } from "./workspace"
import {
  GetOneMovementRequest,
  GetTransactionTaxLinesRequest,
} from "../../../../../tests/api/src/internal-api/openapi/apis/TransactionsApi"

export const GET_TRANSACTIONS_KEY = "getTransactions"
export const GET_TRANSACTION_KEY = "getTransaction"
export const GET_MOVEMENT_KEY = "getMovement"
export const GET_TRANSACTION_ASSETS_KEY = "getTransactionAssets"
export const GET_TRANSACTION_COUNTER_PARTIES = "getTransactionCounterParties"
export const GET_TRANSACTION_REVIEW_METRICS = "getTransactionReviewMetrics"
export const GET_TRANSACTION_TAX_LINES = "getTransactionTaxLines"
export const DELETE_TRANSACTIONS = "deleteTransactions"

export type GetTradeRequest = { movementInId: string; movementOutId: string; workspaceId: string }
export type GetTradeDto = { movementIn: FullMovementDto; movementOut: FullMovementDto }
export type GetMovementDto = WithoutWorkspaceId<GetOneMovementRequest>

// Export the final action.
export const transactionsActions = {
  useReviewMetrics: () => {
    return useWorkspaceQuery([GET_TRANSACTION_REVIEW_METRICS], TransactionsApi.getReviewMetrics, {})
  },
  useMovement: (data: GetMovementDto | null, option?: UseQueryOptions<FullMovementDto>) => {
    return useWorkspaceQuery(
      [GET_MOVEMENT_KEY, data?.movementId],
      TransactionsApi.getOneMovement,
      {
        movementId: data ? data.movementId : "",
      },
      {
        enabled: !!data?.movementId,
        ...option,
      },
    )
  },
  useMovementTaxLines: (
    data: WithoutWorkspaceId<GetTransactionTaxLinesRequest> | null,
    option?: UseQueryOptions<PaginatedMovementCostBasisTaxLine>,
  ) => {
    return useWorkspaceQuery(
      [GET_TRANSACTION_TAX_LINES, data?.movementId],
      TransactionsApi.getTransactionTaxLines,
      {
        movementId: data ? data.movementId : "",
        page: data?.page ? data.page : 0,
        limit: data?.limit ? data.limit : 1,
      },
      {
        enabled: !!data?.movementId,
        ...option,
      },
    )
  },
  useTrade: (data: WithoutWorkspaceId<GetTradeRequest> | undefined | null, option?: UseQueryOptions<GetTradeDto>) => {
    return useWorkspaceQuery<GetTradeDto, GetTradeRequest>(
      [GET_MOVEMENT_KEY, data],
      async ({ movementInId, movementOutId, workspaceId }) => {
        return {
          movementIn: await TransactionsApi.getOneMovement({
            movementId: movementInId,
            workspaceId,
          }),
          movementOut: await TransactionsApi.getOneMovement({
            movementId: movementOutId,
            workspaceId,
          }),
        }
      },
      data ?? { movementInId: "", movementOutId: "" },
      {
        enabled: !!data,
        ...option,
      },
    )
  },
  useGuessCounterParties: (movementId: string, option?: UseQueryOptions<MovementCounterPartyDto[]>) => {
    return useWorkspaceQuery(
      [GET_TRANSACTION_COUNTER_PARTIES, movementId],
      TransactionsApi.guessInternalTransferCounterParties,
      { movementId: movementId },
      option,
    )
  },
  useTransactions: (
    variables: WithoutWorkspaceId<GetNewTransactionsRequest>,
    config?: UseQueryOptions<NewPaginatedTransactionResultDto, unknown, NewPaginatedTransactionResultDto>,
  ) => {
    return usePaginatedWorkspaceQuery(
      [GET_TRANSACTIONS_KEY, variables],
      TransactionsApi.getNewTransactions,
      variables,
      config,
    )
  },
  useNewTransaction: (transactionId: string | undefined | null, option?: UseQueryOptions<NewFullTransactionDto>) => {
    return useWorkspaceQuery(
      [GET_TRANSACTION_KEY, transactionId],
      TransactionsApi.getOneNewTransaction,
      { transactionId: transactionId ?? "" },
      {
        enabled: !!transactionId,
        ...option,
      },
    )
  },
  useNewTransactionMovements: (
    variables: WithoutWorkspaceId<GetNewTransactionMovementsRequest>,
    config?: UseQueryOptions<
      NewPaginatedTransactionMovementResultDto,
      unknown,
      NewPaginatedTransactionMovementResultDto
    >,
  ) => {
    return useWorkspaceQuery(
      [GET_TRANSACTION_KEY, variables],
      TransactionsApi.getNewTransactionMovements,
      variables,
      config,
    )
  },
  useAssets: (variables: WithoutWorkspaceId<GetTransactionAssetsRequest>) => {
    return usePaginatedWorkspaceQuery(
      [GET_TRANSACTION_ASSETS_KEY, variables],
      TransactionsApi.getTransactionAssets,
      variables,
    )
  },
  useUpdateCostBasis: () => {
    return useWorkspaceMutation(TransactionsApi.updateCostBasis, {
      onSuccess: () => {
        // Update full transaction
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_MOVEMENT_KEY)

        // Updating cost basis dirtiness
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useUpdateTransactionInvoice: () => {
    return useWorkspaceMutation(TransactionsApi.updateTransactionInvoice, {
      onSuccess: () => {
        // Update transaction
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)

        // Can make the workspace dirty
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useUpdateTransactionJournalLines: () => {
    return useWorkspaceMutation(TransactionsApi.updateSimpleTransactionAccountingJournalLine, {
      onSuccess: () => {
        // Update transaction
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)

        // Can make the workspace dirty
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useBulkUpdateMovements: () => {
    return useWorkspaceMutation(TransactionsApi.updateBulkMovements, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
        queryClient.invalidateQueries(GET_MOVEMENT_KEY)

        // Can make the workspace dirty
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useInsertInternalTransfers: () => {
    return useWorkspaceMutation(TransactionsApi.insertInternalTransfer, {
      onSuccess: () => {
        // Update labels
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_MOVEMENT_KEY)

        // maybe -(1|2) to review
        queryClient.invalidateQueries(GET_TRANSACTION_REVIEW_METRICS)

        // Can make workspace dirty if internal transfer added / removed
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useDeleteTransactions: () => {
    return useWorkspaceMutation(TransactionsApi.deleteTransactionCustom, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
        queryClient.invalidateQueries(GET_MOVEMENT_KEY)
      },
    })
  },
}
