import { Box } from "@material-ui/core"
import React, { useCallback, useMemo } from "react"

import api from "services/api"
import { FullMovementDto, GetLabelDto, MovementCounterPartyDto } from "services/api/openapi"
import { Mixpanel } from "services/mixpanel"
import { ReactComponent as AddIcon } from "CryptioUI/assets/icons/add.svg"
import { pluralize } from "services/utils/textUtils"
import { UseQueryResult } from "react-query"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import { UncontrolledLoadingButton } from "components/LoadingButton"
import { mockInternalTransferLabel } from "../TransactionLabelDrawer/NonTaxabelLabelSelector"
import { iconStyleWhite } from "CryptioUI/Utilities/config"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface AddLabelButtonProps {
  nonTaxableLabels: GetLabelDto[]
  nonTaxablelabelsToAdd: GetLabelDto[]
  selectedGuessedOtherParty: MovementCounterPartyDto | null
  confirmedInternalTransfer: boolean
  movement: FullMovementDto
  onClose: () => void
  counterParties: UseQueryResult<MovementCounterPartyDto[], unknown>
  labelsToAdd: GetLabelDto[]
  setLabelsToAdd: (newLabel: GetLabelDto[]) => void
  isInternalTransfer: boolean
}

const AddLabelButton = (props: AddLabelButtonProps) => {
  const toast = useToast()

  const {
    nonTaxablelabelsToAdd,
    selectedGuessedOtherParty,
    confirmedInternalTransfer,
    movement,
    onClose,
    counterParties,
    labelsToAdd,
    setLabelsToAdd,
    isInternalTransfer,
  } = props

  const { mutateAsync: bulkUpdateMovementsMutation } = api.transaction.useBulkUpdateMovements()
  const { mutateAsync: insertInternalTransfersMutation } = api.transaction.useInsertInternalTransfers()

  const numberNonTaxablelabelsToAdd = useCallback((): number => {
    const isIncompleteInternalTransfer = isInternalTransfer && !selectedGuessedOtherParty ? -1 : 0

    return nonTaxablelabelsToAdd.length + isIncompleteInternalTransfer
  }, [isInternalTransfer, nonTaxablelabelsToAdd.length, selectedGuessedOtherParty])

  const calculeNbNewLabel = useMemo(() => {
    return labelsToAdd.length + numberNonTaxablelabelsToAdd()
  }, [labelsToAdd, numberNonTaxablelabelsToAdd])

  const buttonDesable = (): boolean => {
    return (
      (labelsToAdd.length || numberNonTaxablelabelsToAdd() ? false : true) &&
      !(confirmedInternalTransfer && isInternalTransfer && selectedGuessedOtherParty)
    )
  }

  const tooltopError = (): string => {
    if (isInternalTransfer) {
      if (!counterParties?.data?.length) {
        return `Label internal transfer can't be add without a valid counter party`
      }
    }
    if (buttonDesable()) {
      return "No label selected"
    }
    return ""
  }

  const addSelectedLabels = async () => {
    if (labelsToAdd.length > 0 || numberNonTaxablelabelsToAdd() > 0 || isInternalTransfer) {
      try {
        if (isInternalTransfer && selectedGuessedOtherParty) {
          await insertInternalTransfersMutation({
            createInternalMovementLinkDto: {
              movementId: movement.id,
              counterMovementId: selectedGuessedOtherParty?.id ?? null,
            },
          })
        }
        if (labelsToAdd.length > 0) {
          await bulkUpdateMovementsMutation({
            movementBulkUpdateDto: {
              labelIdsToAdd: labelsToAdd.map((l) => l.id),
              individualMovements: {
                movementIds: [movement.id],
              },
            },
          })
        }
        const nonTaxablelabelsToAddCpy = new Array(...nonTaxablelabelsToAdd)
        const index = nonTaxablelabelsToAddCpy.findIndex((label) => label.name === mockInternalTransferLabel.name)

        if (index !== -1) {
          nonTaxablelabelsToAddCpy.splice(index, 1)
        }
        if (nonTaxablelabelsToAddCpy.length > 0) {
          await bulkUpdateMovementsMutation({
            movementBulkUpdateDto: {
              labelIdsToAdd: nonTaxablelabelsToAddCpy.map((l) => l.id),
              individualMovements: {
                movementIds: [movement.id],
              },
            },
          })
        }
        toast.open("Label list updated", { variant: "success" })
      } catch (e) {
        toastCatch(e, toast)
      }
      labelsToAdd.forEach((x) => Mixpanel.track("TransactionLabelAssigned", { labelName: x.name }))
    }
    setLabelsToAdd([])
    onClose()
  }

  return (
    <Box mt={1}>
      <PermissionDisabled
        permission="can_modify_transaction"
        action="modify a transaction"
        titlePermissionEnable={tooltopError()}
      >
        <Box>
          <Box mt={3}>
            <UncontrolledLoadingButton
              disabled={buttonDesable()}
              fullWidth
              Icon={<AddIcon className={iconStyleWhite} />}
              onClick={addSelectedLabels}
            >
              Add
              {pluralize(labelsToAdd.length + numberNonTaxablelabelsToAdd() > 1, ` ${calculeNbNewLabel} label`)}
            </UncontrolledLoadingButton>
          </Box>
        </Box>
      </PermissionDisabled>
    </Box>
  )
}

export default AddLabelButton
