import { Box, DialogActions } from "@material-ui/core"
import React, { useContext, useMemo } from "react"

import BaseTable from "components/Table"
import { IExtendedDataTableColumn } from "components/Table/interface"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { BackOfficeGetUsersSortByEnum } from "services/api/openapi"
import { BackOfficeWorkspaceInfo } from "services/api/openapi/models"
import { ThemeContext } from "services/context/themeContext"
import { widgetTableStyle } from "../../../Dashboard/widgets/widgetTableStyle"

export type WorkspaceInfoKey = keyof Pick<
  BackOfficeWorkspaceInfo,
  "transactionCount" | "labelCount" | "contactCount" | "mappingCount" | "accountingIntegrationName"
>
interface BackOfficeUserMetricModalProps {
  workspaceInfoKey: WorkspaceInfoKey
  workspaces: BackOfficeWorkspaceInfo[]
  isOpen: boolean
  onClose: () => void
}

const workspaceInfoKeyToTitle: Record<WorkspaceInfoKey, string> = {
  transactionCount: "Transaction count",
  labelCount: "Label count",
  contactCount: "Contact count",
  mappingCount: "Mapping count",
  accountingIntegrationName: "Accounting integrations",
}

const BackOfficeUserMetricModal = ({
  isOpen,
  onClose,
  workspaces,
  workspaceInfoKey,
}: BackOfficeUserMetricModalProps) => {
  const muiThemeContext = useContext(ThemeContext)
  const columns = useMemo<IExtendedDataTableColumn<BackOfficeWorkspaceInfo, BackOfficeGetUsersSortByEnum>[]>(
    () => [
      {
        name: "Workspace",
        selector: "name",
      },
      {
        name: workspaceInfoKey === "accountingIntegrationName" ? "Integration name" : "Count",
        selector: workspaceInfoKey,
        format: (row) => row[workspaceInfoKey] ?? "-",
      },
    ],
    [workspaceInfoKey],
  )

  return (
    <ModalUI
      isOpen={isOpen}
      onClose={onClose}
      className="w-[700px] h-auto"
      title={workspaceInfoKeyToTitle[workspaceInfoKey]}
    >
      <BaseTable<BackOfficeWorkspaceInfo, BackOfficeGetUsersSortByEnum>
        customStyle={widgetTableStyle(muiThemeContext.type)}
        columns={columns}
        items={workspaces}
        fixedScroll="500px"
      />
      <Box>
        <DialogActions>
          <ButtonUI onClick={onClose}>Close</ButtonUI>
        </DialogActions>
      </Box>
    </ModalUI>
  )
}

export default BackOfficeUserMetricModal
