import { useTypedController } from "@hookform/strictly-typed"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { ReportLedgerEntriesTransactionPrettyMap, ReportLedgerEntriesTransactionTypesArray } from "pure-shared"
import React from "react"
import { ReportFormComponentProps } from "."
import { DateRange } from "components/DateRangePicker"
import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { DrawerReportForm } from "../ReportForm"

export interface ReportPortfolioType {
  displayName: string
  date: DateRange
}

const ReportLedgerEntriesTransactionTypeOption = ({ control }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })

  return (
    <DrawerFormSection htmlFor="ledger-select" name="Transaction type">
      <TypedController
        name="ledgerEntriesTransactionType"
        defaultValue={"all"}
        rules={{ required: true }}
        render={({ onChange, ...rest }) => (
          <Autocomplete
            id="ledger-select"
            {...rest}
            disableClearable
            onChange={(_, newValue) => onChange(newValue)}
            getOptionLabel={(option) => ReportLedgerEntriesTransactionPrettyMap[option]}
            options={[...ReportLedgerEntriesTransactionTypesArray]}
            renderInput={(params) => <TextField {...params} />}
            size="small"
          />
        )}
      />
    </DrawerFormSection>
  )
}

export default ReportLedgerEntriesTransactionTypeOption
