import { Box, Link, makeStyles, Theme, Typography } from "@material-ui/core"
import dayjs from "dayjs"
import React from "react"
import useDrawer from "components/misc/useDrawer"
import { FullMovementDto } from "services/api/openapi"

import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import AdministratorInformation from "components/misc/AdministratorInformation"
import AssetView from "../Cells/AssetView"
import CostBasisView from "../Cells/CostBasis/CostBasisView"
import GainView from "../Cells/GainView"
import TxHashLink from "../Cells/TxHashLink"
import OtherParty from "../Cells/OtherParty"
import BackOfficeView from "../../misc/BackOfficeView"
import MovementMetadata from "./MovementMetadata"
import NftLink from "../Cells/NftLink"
import CopyableText from "components/misc/copyableText"

const useStyles = makeStyles((theme: Theme) => ({
  containerAssetView: {
    maxWidth: "auto",
  },
  emptyPadding: {
    paddingLeft: theme.spacing(4.5),
  },
}))

const MovementSummary = ({ movement }: { movement: FullMovementDto }) => {
  const [walletDrawer, openWalletDrawer] = useDrawer("wallet")
  const classes = useStyles()

  return (
    <Box>
      <AdministratorInformation infos={[{ id: movement.id, extended: "Movement" }]} />
      <BackOfficeView techAdminOnly>
        <MovementMetadata movementId={movement.id} />
      </BackOfficeView>
      <DrawerCategory title="Movement summary">
        {walletDrawer}

        <DrawerSection name="Other Party">
          {movement?.otherPartyAlias?.address ? (
            <OtherParty
              direction={movement.direction}
              wallet={movement.wallet.name}
              otherPartiesAliases={[movement.otherPartyAlias]}
              onClickWallet={() => openWalletDrawer(movement.wallet.id)}
            />
          ) : (
            <div className={classes.emptyPadding}>
              <Typography variant="body1">-</Typography>
            </div>
          )}
        </DrawerSection>

        <DrawerSection name="Date">
          <Typography variant="body2">
            {dayjs(movement.transactionDate).tz().format("L")} at {dayjs(movement.transactionDate).tz().format("LTS")}
          </Typography>
        </DrawerSection>

        <DrawerSection name="Source">
          <Link
            onClick={() => {
              openWalletDrawer(movement.wallet.id)
            }}
            style={{ cursor: "pointer" }}
            underline="always"
          >
            {movement.wallet.name}
          </Link>
        </DrawerSection>

        <DrawerSection name="Direction">
          <Typography variant="body1" style={{ textTransform: "capitalize" }}>
            {movement.direction}
          </Typography>
        </DrawerSection>

        {movement.direction === "in" && (
          <DrawerSection name="Incoming">
            <div className={classes.containerAssetView}>
              <AssetView
                assetId={movement.assetId}
                assetSymbol={movement.assetSymbol}
                volume={movement.volume}
                direction={movement.direction}
                assetToUsdRate={movement.assetToUsdRate}
                isFee={movement.isFee}
                usdToFiatRate={movement.usdToFiatRate}
                alignLeft={true}
                fullNumber
              />
            </div>
          </DrawerSection>
        )}
        {!movement.isFee && movement.direction === "out" && (
          <DrawerSection name="Outgoing">
            <div className={classes.containerAssetView}>
              <AssetView
                assetId={movement.assetId}
                assetSymbol={movement.assetSymbol}
                volume={movement.volume}
                direction={movement.direction}
                assetToUsdRate={movement.assetToUsdRate}
                isFee={movement.isFee}
                usdToFiatRate={movement.usdToFiatRate}
                alignLeft={true}
                fullNumber
              />
            </div>
          </DrawerSection>
        )}
        {movement.isFee && (
          <DrawerSection name="Fee">
            <div className={classes.containerAssetView}>
              <AssetView
                assetId={movement.assetId}
                assetSymbol={movement.assetSymbol}
                volume={movement.volume}
                direction={movement.direction}
                assetToUsdRate={movement.assetToUsdRate}
                isFee={movement.isFee}
                usdToFiatRate={movement.usdToFiatRate}
                alignLeft={true}
                fullNumber
              />
            </div>
          </DrawerSection>
        )}

        {movement.costBasis && !movement.costBasis.mainCostBasisError && movement.costBasis.costBasisFiat !== null && (
          <DrawerSection name="Cost basis">
            <CostBasisView costBasisFiat={movement.costBasis.costBasisFiat} />
          </DrawerSection>
        )}
        {movement.costBasis && !movement.costBasis.mainCostBasisError && movement.costBasis.gainFiat !== null && (
          <DrawerSection name="Gains/Losses">
            <GainView feeGainFiat={null} outgoingGainFiat={movement.costBasis.gainFiat} />
          </DrawerSection>
        )}
        {movement.transactionHash && (
          <DrawerSection name="Transaction hash">
            <TxHashLink txHash={movement.transactionHash} sourceName={movement.wallet.sourceName} />
          </DrawerSection>
        )}
        {movement.isNft && movement.assetTokenAddress && movement.assetTokenId && (
          <DrawerSection name="NFT Information">
            <CopyableText text={movement.assetTokenId} allowEvent>
              Token Id: {movement.assetTokenId}
            </CopyableText>
            <NftLink
              address={movement.assetTokenAddress}
              tokenId={movement.assetTokenId}
              sourceName={movement.wallet.sourceName}
            />
          </DrawerSection>
        )}
      </DrawerCategory>
    </Box>
  )
}

export default MovementSummary
