import { Box, createStyles, makeStyles } from "@material-ui/core"
import { useStripe } from "@stripe/react-stripe-js"
import React, { useMemo } from "react"
import { useHistory } from "react-router-dom"
import { ButtonSize } from "CryptioUI/types"

import { URLS } from "../../routes"
import api from "services/api"
import { GetFeatureAddonDto, SubscriptionPayingDetailsPeriodTypeEnum } from "services/api/openapi"
import { isStripeEnabled } from "services/variables"
import { UncontrolledLoadingButton } from "../LoadingButton"
import LoadingSpinner from "../misc/LoadingSpinner"
import NetworkErrorMessage from "../misc/NetworkErrorMessage"
import { ExtendedLimitAddon } from "./PricingCalculator"
import TooltipUI from "CryptioUI/Tooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface Props {
  period: SubscriptionPayingDetailsPeriodTypeEnum
  featureAddons: GetFeatureAddonDto[]
  limitAddons: ExtendedLimitAddon[]
  planId: string
  isError?: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    subscribeButton: {
      "&:hover": {
        color: theme.palette.primary.contrastText,
      },
    },
    checkoutBox: {
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
  }),
)

const ChangePricingButton = ({ period, featureAddons, limitAddons, planId, isError }: Props): JSX.Element => {
  const toast = useToast()
  const { mutateAsync: createStripeCheckoutSession } = api.billing.useCreateStripeCheckoutSession()
  const subscription = api.billing.useFullSubscription()
  const stripe = useStripe()
  const classes = useStyles()

  const isSubscriptionIdentical = useMemo(() => {
    if (subscription.data === undefined) return false
    if (subscription.data.plan.id !== planId) return false
    if (subscription.data.limitAddons.length !== limitAddons.length) return false
    if (
      !subscription.data.limitAddons.every((sla) =>
        limitAddons.some((x) => x.id === sla.id && x.additionalRequired === sla.additional),
      )
    )
      return false
    if (subscription.data.featureAddons.length !== featureAddons.length) return false
    if (!subscription.data.featureAddons.every((sla) => featureAddons.some((x) => x.id === sla.id))) return false

    return true
  }, [subscription.data, featureAddons, limitAddons, planId])

  const { mutateAsync: createChangeSubscription } = api.billing.useChangeSubscription()

  const packages = api.billing.usePackages()
  const history = useHistory()

  if (subscription.isError) return <NetworkErrorMessage additionalData={subscription} />

  if (subscription.isLoading || subscription.data === undefined) return <LoadingSpinner />

  if (packages.isError) return <NetworkErrorMessage additionalData={packages} />

  if (packages.isLoading || packages.data === undefined) return <LoadingSpinner />

  const changeSubscription = async () => {
    try {
      await createChangeSubscription({
        updateSubscriptionDto: {
          planId: planId,
          limitAddOns: limitAddons.map((x) => ({
            addonId: x.id,
            additional: x.additionalRequired,
          })),
          featureAddOnIds: featureAddons.map((x) => x.id),
        },
      })
      history.push(URLS.Billing.Status + "?checkout_success_from=updated_subscription")
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const openStripeCheckoutSession = async () => {
    try {
      const stripeCheckoutSession = await createStripeCheckoutSession({
        startSubscriptionDto: {
          planId: planId,
          periodType: period,
          limitAddOns: limitAddons.map((x) => ({
            addonId: x.id,
            additional: x.additionalRequired,
          })),
          featureAddOnIds: featureAddons.map((x) => x.id),
        },
      })
      stripe
        ?.redirectToCheckout({
          sessionId: stripeCheckoutSession.sessionId,
        })
        .then((x) => console.error(x))
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const subscriptionButton =
    subscription.data.type === "paying" ? (
      <UncontrolledLoadingButton
        size={ButtonSize.LG}
        disabled={isSubscriptionIdentical || isError || !isStripeEnabled}
        onClick={changeSubscription}
        className={classes.subscribeButton}
      >
        Change subscription
      </UncontrolledLoadingButton>
    ) : (
      <UncontrolledLoadingButton
        size={ButtonSize.LG}
        disabled={isError || !isStripeEnabled}
        onClick={openStripeCheckoutSession}
      >
        Proceed to checkout
      </UncontrolledLoadingButton>
    )

  return (
    <Box height="100%" p={2}>
      <Box className={classes.checkoutBox}>
        <Box ml={1}>
          {isStripeEnabled ? (
            subscriptionButton
          ) : (
            <TooltipUI content="Stripe is disabled">
              <span>{subscriptionButton}</span>
            </TooltipUI>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ChangePricingButton
