import "./style.css"

import "react-dates/initialize"
import DayJsUtils from "@date-io/dayjs"
import { CssBaseline, NoSsr } from "@material-ui/core"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import React from "react"
import "./index.css"
import ReactDOM from "react-dom"
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { BrowserRouter } from "react-router-dom"

import DefaultTitle from "./components/DefaultTitle"
import { MobileGate } from "./components/MobileGate"
import LoginGate from "./LoginGate"
import { isProd, isStripeEnabled, StripePublishableKey, zendeskKey } from "./services/variables"
import { ThemeContextProvider } from "./services/context/themeContext"
import { ZendeskProvider } from "./services/context/ZendeskContext"

dayjs.extend(timezone)
dayjs.extend(utc)

const queryCache = new QueryCache()
const mutationCache = new MutationCache()
export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      notifyOnChangeProps: ["data", "error", "isError", "isLoading"],
    },
  },
})

const stripePromise = isStripeEnabled ? loadStripe(StripePublishableKey) : null

ReactDOM.render(
  <>
    <CssBaseline />
    <NoSsr>
      <ThemeContextProvider>
        <Elements stripe={stripePromise}>
          <QueryClientProvider client={queryClient}>
            <ZendeskProvider zendeskKey={zendeskKey} enabled={isProd}>
              <BrowserRouter>
                <MuiPickersUtilsProvider utils={DayJsUtils}>
                  <DefaultTitle />
                  <MobileGate>
                    <LoginGate />
                  </MobileGate>
                </MuiPickersUtilsProvider>
              </BrowserRouter>
            </ZendeskProvider>
            {!isProd && <ReactQueryDevtools />}
          </QueryClientProvider>
        </Elements>
      </ThemeContextProvider>
    </NoSsr>
  </>,
  document.getElementById("root"),
)
