import { useTypedController } from "@hookform/strictly-typed"
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core"
import React from "react"
import { useForm } from "react-hook-form"

import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import api from "services/api"
import { BackOfficeDuplicateWorkspaceDto, BackOfficeGetFullWorkspaceDto } from "services/api/openapi"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface Props {
  workspace: BackOfficeGetFullWorkspaceDto
  onClose: () => void
}

type Form = Pick<BackOfficeDuplicateWorkspaceDto, "newName"> & BackOfficeDuplicateWorkspaceDto["thingsToDuplicate"]
const duplicationOptions: {
  name: string
  description: string
  key: keyof BackOfficeDuplicateWorkspaceDto["thingsToDuplicate"]
}[] = [
  {
    name: "Sources and transactions",
    description:
      "Duplicate all sources, all transactions, all global labels links, as well as all global contacts needs.",
    key: "sourcesAndTransactions",
  },
  {
    name: "Contacts",
    description: "Duplicate all contacts, as well as contacts links.",
    key: "contacts",
  },
  {
    name: "Labels",
    description: "Duplicate all labels, as well as labels links.",
    key: "labels",
  },
  {
    name: "Invoices",
    description: "Duplicate all invoices, and link them to the appropriate transactions.",
    key: "invoices",
  },
  {
    name: "Request integration",
    description: "Duplicate the Request integration of the workpace.",
    key: "requestIntegration",
  },
  {
    name: "Fireblocks integration",
    description: "Duplicate the Fireblocks integration of the workspace.",
    key: "fireblocksIntegration",
  },
]
const duplicateFormId = "backoffice-workspace-duplicate"

function BackOfficeDuplicateDialog({ workspace, onClose }: Props) {
  const { mutateAsync: duplicateWorkspaceMutation } = api.backOffice.workspace.useDuplicateWorkspace()
  const { control, handleSubmit } = useForm<Form>({
    mode: "onChange",
  })
  const TypedController = useTypedController<Form>({ control })
  const [DuplicateButton, handleButton] = useLoadingButton()
  const toast = useToast()

  const duplicateWorkspace = async ({
    newName,
    contacts,
    fireblocksIntegration,
    invoices,
    labels,
    requestIntegration,
    sourcesAndTransactions,
  }: Form) => {
    try {
      await duplicateWorkspaceMutation({
        backOfficeDuplicateWorkspaceDto: {
          newName,
          thingsToDuplicate: {
            contacts,
            fireblocksIntegration,
            invoices,
            labels,
            requestIntegration,
            sourcesAndTransactions,
          },
        },
        workspaceId: workspace.id,
      })
      toast.open("Workspace duplicated", { variant: "success" })
      onClose()
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  return (
    <>
      <DialogTitle>
        <Typography variant="h3">Duplicate workspace</Typography>
      </DialogTitle>
      <DialogContent>
        <form id={duplicateFormId} onSubmit={handleButton(handleSubmit(duplicateWorkspace))}>
          <DrawerFormSection htmlFor="dup-name-textfield" name="New workspace name">
            <TypedController
              name="newName"
              defaultValue={`Copy of ${workspace.name}`}
              rules={{ required: true, minLength: 1 }}
              render={(props) => <TextField {...props} id="dup-name-textfield" />}
            />
          </DrawerFormSection>

          <Box display="grid" gridTemplateColumns="1fr 1fr">
            {duplicationOptions.map((option) => {
              const id = `dup-option-${option.key}`
              return (
                <Box key={option.key} mt={2}>
                  <TypedController
                    name={option.key}
                    defaultValue={false}
                    render={({ onChange, value, ...props }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...props}
                            color="primary"
                            id={id}
                            checked={value}
                            onChange={() => onChange(!value)}
                          />
                        }
                        label={option.name}
                        title={option.description}
                      />
                    )}
                  />
                </Box>
              )
            })}
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <DuplicateButton type="submit" form={duplicateFormId}>
          Duplicate
        </DuplicateButton>
      </DialogActions>
    </>
  )
}

export default BackOfficeDuplicateDialog
