import React, { useEffect, useState } from "react"

import { ModalProps } from "../types"

import WrapperModal from "./WrapperModal"
import HeaderModalUI from "./HeaderModal"
import ControlModal from "./ControlModal"

const ModalUI = (props: ModalProps): JSX.Element => {
  const {
    children,
    title,
    delayDurationIn = "duration-300",
    delayDurationOut = "duration-200",
    isOpen,
    destructive = false,
    onClose,
    onAction,
    actionTitle = "Ok",
    onCancel = () => onClose(),
    cancelTitle = "Cancel",
    className = "min-w-[600px] w-fit h-fit",
    disabled = false,
    isLoadingButton = false,
    isLoadingButtonPending = false,
    disableCancel = false,
  } = props
  const [isRemoved, setIsRemoved] = useState(false)

  useEffect(() => {
    if (!isOpen && isRemoved) setTimeout(() => setIsRemoved(false), 200)
    else {
      if (isRemoved !== isOpen) setIsRemoved(isOpen)
    }
  }, [isOpen, isRemoved])

  return (
    <>
      {isRemoved && (
        <WrapperModal
          isOpen={isOpen}
          onClose={onClose}
          delayDurationIn={delayDurationIn}
          delayDurationOut={delayDurationOut}
          className={className}
        >
          {title && <HeaderModalUI title={title} onClose={onClose} />}
          <div className="px-4 pt-2">{children}</div>
          {onAction && (
            <ControlModal
              destructive={destructive}
              onAction={onAction}
              actionTitle={actionTitle}
              onCancel={onCancel}
              cancelTitle={cancelTitle}
              disabled={disabled}
              isLoadingButton={isLoadingButton}
              isLoadingButtonPending={isLoadingButtonPending}
              disableCancel={disableCancel}
            />
          )}
        </WrapperModal>
      )}
    </>
  )
}

export default ModalUI
