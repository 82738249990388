import { Box, IconButton, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import {
  ControlPointDuplicate,
  DeleteRounded,
  DescriptionRounded,
  GetAppRounded,
  HelpRounded,
} from "@material-ui/icons"
import dayjs from "dayjs"
import React, { useContext, useMemo } from "react"
import { ReactComponent as AddIcon } from "CryptioUI/assets/icons/add.svg"

import BaseContainer from "components/Container"
import MainTitleView from "components/MainTitleView"
import BackOfficeView from "components/misc/BackOfficeView"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import useDrawer from "components/misc/useDrawer"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import PermissionReadView from "components/Permission/PermissionReadView"
import { GenericSorting, IExtendedDataTableColumn } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import TableTitleWithTotalCount from "components/Table/TableTitleWithTotalCount"
import api from "services/api"
import { BulkReportsActionDto, GetReportDto, GetReportsRequest, GetReportsSortByEnum } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import { Mixpanel } from "services/mixpanel"
import download from "services/utils/download"
import { getHumanReadableReportStatus } from "./ReportStatus"
import useBulkSelector, { SelectedRowsType } from "components/Hooks/useBulkSelector"
import { pluralize } from "services/utils/textUtils"
import { UncontrolledLoadingButton } from "components/LoadingButton"
import { asyncDelay } from "pure-shared"
import { WithoutWorkspaceId } from "services/api/aliases"
import { PaginationParameters } from "services/urlParse"
import ReportFilter from "./filters/ReportFilter"
import ButtonUI from "CryptioUI/Button"
import { iconStyleWhite } from "CryptioUI/Utilities/config"
import TooltipUI from "CryptioUI/Tooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  noReportText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}))

export const defaultReportSorting: GenericSorting<GetReportsSortByEnum> = {
  sortDirection: "descending",
  sortBy: "created_at",
}

const reportBulkActionFromSelectedRows = (selectedRows: SelectedRowsType | undefined): BulkReportsActionDto => {
  return {
    filtered:
      selectedRows?.type === "filtered"
        ? {
            excludedIds: selectedRows.excludedIds,
          }
        : undefined,
    individuals:
      selectedRows?.type === "individuals"
        ? {
            reportIds: selectedRows.itemIds,
          }
        : undefined,
  }
}

export type ReportParameters = Partial<WithoutWorkspaceId<GetReportsRequest>>
export const defaultReportParams: ReportParameters = {
  page: 0,
  limit: 10,
  assetIds: undefined,
  walletIds: undefined,
  startDate: undefined,
  endDate: undefined,
  reportModule: undefined,
  reportType: undefined,
  individualId: undefined,
}

export const reportHasAnyFilter = (filters: ReportParameters) =>
  Boolean(
    filters.individualId ||
      filters.reportModule ||
      filters.reportType ||
      filters.assetIds ||
      filters.walletIds ||
      filters.startDate ||
      filters.endDate,
  )

const paramsToFilter = (
  params: PaginationParameters & Partial<ReportParameters>,
): WithoutWorkspaceId<GetReportsRequest> => {
  return {
    page: params.page,
    limit: params.limit,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
    reportModule: params.reportModule,
    reportType: params.reportType,
    individualId: params.individualId,
    assetIds: params.assetIds,
    walletIds: params.walletIds,
    startDate: params.startDate,
    endDate: params.endDate,
  }
}

const ReportsScene = (): JSX.Element => {
  const classes = useStyles()

  const { params, setLimit, setPage, setSorting, setParams } = usePaginatedParams<
    Partial<ReportParameters>,
    GetReportsSortByEnum
  >(defaultReportParams, defaultReportSorting)

  const toast = useToast()
  const workspaceCtx = useContext(WorkspaceContext)

  const { mutateAsync: deleteReportMutation } = api.report.useDeleteReport()
  const { mutateAsync: deleteBulkReportsMutation } = api.report.useDeleteBulkReports()
  const { mutateAsync: createReportMutation } = api.report.useCreateReports()

  const setParamsWithDialog = (newParams: ReportParameters) => {
    if (selectedRows === undefined) setParams(newParams)
    else {
      basicDialog.showDialog({
        title: "Update filter?",
        content: <Typography variant="h5">If you update the filter you will loose your selection</Typography>,
        yesText: "Yes",
        noText: "Cancel",
        onAccept: () => {
          setSelectedRows(undefined)
          setParams(newParams)
        },
      })
    }
  }

  const reports = api.report.useReports(paramsToFilter(params), {
    refetchInterval: 2000,
    enabled: workspaceCtx.workspace.userRights.can_read_report,
  })

  const { selectedRows, selectedRowCount, bulkSelectorColumn, setSelectedRows } = useBulkSelector(
    reports.data,
    (r) => r.id,
  )

  const basicDialog = useDialog()

  const doDeleteSelectedReports = async () => {
    try {
      await deleteBulkReportsMutation({
        bulkReportsActionDto: reportBulkActionFromSelectedRows(selectedRows),
      })
      setSelectedRows(undefined)
      toast.open("Selected reports have been deleted", {
        variant: "success",
      })
    } catch {
      toast.open("Could not delete the selected reports", {
        variant: "danger",
      })
    }
  }

  const filterCount = useMemo(() => {
    const filter = params
    let res = 0
    if (filter.individualId !== undefined) res++
    return res
  }, [params])

  const askDeleteSelectedReports = () => {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to delete the selected reports? This action is irreversible.
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doDeleteSelectedReports,
    })
  }

  const handleBulkDownload = async () => {
    try {
      const res = await api.report.getBulkSignedUrl({
        workspaceId: workspace.id,
        bulkReportsActionDto: reportBulkActionFromSelectedRows(selectedRows),
      })

      for (const url of res.signedUrls) {
        download(url)
        await asyncDelay(1000)
      }
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const columns: IExtendedDataTableColumn<GetReportDto, GetReportsSortByEnum>[] = useMemo(() => {
    const handleDelete = async (reportId: string) => {
      try {
        await deleteReportMutation({ reportId })
        toast.open("Report deleted", { variant: "success" })
      } catch (e) {
        toastCatch(e, toast)
      }
    }

    const askDeleteReport = (report: GetReportDto) => {
      basicDialog.showDialog({
        title: "Are you sure?",
        content: (
          <Typography variant="h5">Do you really want to delete this report? This action is irreversible.</Typography>
        ),
        yesText: "Yes",
        noText: "Cancel",
        onAccept: () => handleDelete(report.id),
      })
    }

    const duplicateReport = async (report: GetReportDto) => {
      try {
        await createReportMutation({
          createReportDto: {
            reportModule: report.reportModuleName,
            allColumns: true,
            assetIds: report.assetIds,
            startDate: report.startDate,
            endDate: report.endDate,
            exportFormatId: report.exportFormatId,
            fileType: report.fileType,
            walletIds: report.walletIds,
            filters: report.filters ?? undefined,
            includeNft: report.includeNft,
            ledgerEntriesTransactionType: report.ledgerEntriesTransactionType ?? undefined,
            impairmentId: report.impairmentId,
            inputDate: report.inputDate,
          },
        })
      } catch (e) {
        toastCatch(e, toast)
      }
    }

    const handleDownload = async (reportId: string) => {
      try {
        const res = await api.report.getSignedUrl(workspaceCtx, reportId)

        download(res.signedUrl)
      } catch (e) {
        toastCatch(e, toast)
      }
    }

    return [
      bulkSelectorColumn,
      {
        name: "Created at",
        selector: "createdAt",
        sortable: true,
        sortName: "created_at",
        minWidth: "150px",
        format: (row) => dayjs(row.createdAt).fromNow(),
      },
      // Dates does not contain hour / timezone information, they shouldn't be timezoned
      {
        name: "Start Date",
        selector: "startDate",
        sortable: true,
        sortName: "start_date",
        minWidth: "150px",
        format: (row) => (row.startDate ? dayjs(row.startDate).format("L") : "-"),
      },
      {
        name: "End Date",
        selector: "endDate",
        sortable: true,
        sortName: "end_date",
        format: (row) => (row.endDate ? dayjs(row.endDate).format("L") : "-"),
      },
      {
        name: "Type",
        selector: "typeDisplayName",
        sortable: true,
        sortName: "type",
        format: (row) => {
          // FIXME: Dirty rename until we find a better solution
          if (row.type === "transactions" && row.filters !== null) {
            return "Filtered transaction history"
          }
          return row.typeDisplayName
        },
      },
      {
        name: "Format",
        selector: "formatDisplayName",
      },
      {
        name: "Status",
        selector: "generationStatus",
        format: (row) => {
          if (row.generationStatus.status === "pending")
            return (
              <div>
                <span className="pr-2.5">{getHumanReadableReportStatus(row.generationStatus, row.error)}</span>
                <TooltipUI content="Some of your workspace resources are computing. The report generation will start when the computing will be over.">
                  <HelpRounded />
                </TooltipUI>
              </div>
            )

          return getHumanReadableReportStatus(row.generationStatus, row.error)
        },
      },
      {
        name: "Asset",
        selector: "asset",
        format: (row) => {
          if (row.assetNames.length === 0) {
            return "-"
          }
          if (row.assetNames.length === 1) {
            return row.assetNames[0]
          }
          return `${row.assetNames.length} assets`
        },
      },
      {
        name: "Sources",
        selector: "walletNames", // If no selector, nothing show up, but here I need 2 selectors..
        format: (row) => {
          if (row.walletNames.length === 0) {
            return "-"
          }
          if (row.walletNames.length === 1) {
            return row.walletNames[0]
          }
          return `${row.walletNames.length} wallets`
        },
      },
      {
        name: "Actions",
        selector: "action", // If no selector, nothing show up, but here I need 2 selectors..
        minWidth: "10Opx",
        format: function formatAction(row): JSX.Element {
          return (
            <Box display="flex" alignItems="center">
              <PermissionDisabled
                permission="can_download_report"
                action="download a report"
                titlePermissionEnable="Download"
              >
                <IconButton
                  aria-label="download"
                  disabled={row.generationStatus.status !== "inactive" || row.error !== null}
                  onClick={() => handleDownload(row.id)}
                  size="small"
                  style={{ margin: 3 }}
                >
                  <GetAppRounded />
                </IconButton>
              </PermissionDisabled>
              <PermissionDisabled
                permission="can_remove_report"
                action="delete a report"
                titlePermissionEnable="Delete"
              >
                <IconButton aria-label="delete" onClick={() => askDeleteReport(row)} size="small" style={{ margin: 3 }}>
                  <DeleteRounded />
                </IconButton>
              </PermissionDisabled>
              <BackOfficeView>
                <PermissionDisabled
                  permission="can_create_report"
                  action="duplicate a report"
                  titlePermissionEnable="Duplicate"
                >
                  <IconButton onClick={() => duplicateReport(row)} size="small" style={{ margin: 3 }}>
                    <ControlPointDuplicate />
                  </IconButton>
                </PermissionDisabled>
              </BackOfficeView>
            </Box>
          )
        },
      },
    ]
  }, [bulkSelectorColumn, deleteReportMutation, workspaceCtx, toast, basicDialog, createReportMutation])

  const [reportDrawer, openReportDrawer] = useDrawer("report", {
    onExit: (formData) => {
      Mixpanel.track("ReportCreationCanceled", {
        reportName: formData.reportType?.name,
        reportFormat: formData.reportFormat?.name,
      })
    },
  })
  const { workspace } = useContext(WorkspaceContext)
  const viewTitle = "Reports"

  if (!workspace.userRights.can_read_report) return <PermissionReadView viewTitle={viewTitle} itemName="reports" />
  if (reports.isError) return <NetworkErrorMessage small={false} additionalData={reports} />
  if (reports.isLoading || reports.data === undefined) return <LoadingSpinner />

  const addReportButton = (
    <PermissionDisabled permission="can_create_report" action="create a report">
      <ButtonUI onClick={() => openReportDrawer(null)} Icon={<AddIcon className={iconStyleWhite} />}>
        Report
      </ButtonUI>
    </PermissionDisabled>
  )

  const viewDescription = "Generate various reports. Click to know more!"
  const viewHelpURL = "https://support.cryptio.co/hc/en-gb/articles/7455509327889-How-to-select-the-right-report"
  const hasAnyFilter = reportHasAnyFilter(params)

  if (reports.data.totalCount === 0) {
    if (params.page > 1) {
      // I'm doing a set a setTimeout to avoid a warning to be triggered
      setTimeout(() => setPage(1), 0)
      return <LoadingSpinner />
    }

    return (
      <BaseContainer>
        {reportDrawer}
        <MainTitleView title={viewTitle} helpTooltipContent={viewDescription} helpTooltipUrl={viewHelpURL} />
        <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          {hasAnyFilter ? (
            <>
              <DescriptionRounded fontSize="large" />
              <Typography className={classes.noReportText} variant="h4">
                There are no report that match the selected {pluralize(filterCount > 1, "filter")}.
                <br />
                Click the following buttons to reset or update the filters.
              </Typography>
              <Box display="flex" flexDirection="row">
                <ReportFilter setFilter={setParamsWithDialog} filter={params} />
              </Box>
            </>
          ) : (
            <>
              <DescriptionRounded fontSize="large" />
              <Typography className={classes.noReportText} variant="h4">
                This report list is empty.
                <br />
                Click below to add reports.
              </Typography>
              {addReportButton}
            </>
          )}
        </Box>
      </BaseContainer>
    )
  }

  return (
    <BaseContainer>
      {basicDialog.dialog}
      {reportDrawer}

      <Box mb={2} display="flex" justifyContent="space-between" alignItems="flex-end">
        <TableTitleWithTotalCount
          title={viewTitle}
          qualifier="Report"
          totalCount={reports.data.totalCount}
          selectedCount={selectedRowCount}
          helpTooltipContent={viewDescription}
          helpTooltipUrl={viewHelpURL}
        />
        <Box display="flex" alignItems="flex-end">
          {selectedRowCount > 0 && (
            <>
              <BackOfficeView>
                <Box mr={1}>
                  <UncontrolledLoadingButton onClick={handleBulkDownload}>
                    Download {selectedRowCount} {pluralize(selectedRowCount > 1, "report")}
                  </UncontrolledLoadingButton>
                </Box>
              </BackOfficeView>
              <Box mr={1}>
                <ButtonUI onClick={askDeleteSelectedReports}>
                  Delete {selectedRowCount} {pluralize(selectedRowCount > 1, "report")}
                </ButtonUI>
              </Box>
            </>
          )}
          <Box mr={2}>
            <ReportFilter setFilter={setParamsWithDialog} filter={params} />
          </Box>
          {addReportButton}
        </Box>
      </Box>
      <Paper>
        <ServerSideTable<GetReportDto, GetReportsSortByEnum>
          conditionalRowStyles={[
            {
              when: (row) => row.isAdminReport,
              style: {
                border: `3px #ba000d solid !important`,
                marginBottom: `0.5px`,
                borderRadius: "0px",
              },
            },
          ]}
          columns={columns}
          items={reports.data.data}
          limit={reports.data.limit}
          onPageChange={setPage}
          onLimitChange={setLimit}
          defaultParams={params}
          totalCount={reports.data.totalCount}
          onSort={setSorting}
          onRowClicked={(row) => openReportDrawer(row.id)}
        />
      </Paper>
    </BaseContainer>
  )
}

export default ReportsScene
