import { Box, Link, makeStyles, Theme, Typography } from "@material-ui/core"
import dayjs from "dayjs"
import React from "react"
import useDrawer from "../../misc/useDrawer"

import { DrawerCategory, DrawerSection } from "../../Drawer/DrawerItems"
import AdministratorInformation from "../../misc/AdministratorInformation"
import AssetView from "../Cells/AssetView"
import CostBasisView from "../Cells/CostBasis/CostBasisView"
import GainView from "../Cells/GainView"
import TxHashLink from "../Cells/TxHashLink"
import OtherParty from "../Cells/OtherParty"
import { GetTradeDto } from "services/api/routes/transactions"

const useStyles = makeStyles((theme: Theme) => ({
  containerAssetView: {
    maxWidth: "auto",
  },
  emptyPadding: {
    paddingLeft: theme.spacing(4.5),
  },
}))

const MovementSummary = ({ trade }: { trade: GetTradeDto }) => {
  const [walletDrawer, openWalletDrawer] = useDrawer("wallet")
  const classes = useStyles()

  return (
    <Box>
      <AdministratorInformation
        infos={[
          { id: trade.movementIn.id, extended: "Trade in" },
          { id: trade.movementOut.id, extended: "Trade out" },
        ]}
      />
      <DrawerCategory title="Trade summary">
        {walletDrawer}

        <DrawerSection name="Other party deposit">
          {trade.movementIn?.otherPartyAlias?.address ? (
            <OtherParty
              direction={trade.movementIn.direction}
              wallet={trade.movementIn.wallet.name}
              otherPartiesAliases={[trade.movementIn.otherPartyAlias]}
              onClickWallet={() => openWalletDrawer(trade.movementIn.wallet.id)}
            />
          ) : (
            <div className={classes.emptyPadding}>
              <Typography variant="body1">-</Typography>
            </div>
          )}
        </DrawerSection>
        <DrawerSection name="Other party withdrawal">
          {trade.movementOut?.otherPartyAlias?.address ? (
            <OtherParty
              direction={trade.movementOut.direction}
              wallet={trade.movementOut.wallet.name}
              otherPartiesAliases={[trade.movementOut.otherPartyAlias]}
              onClickWallet={() => openWalletDrawer(trade.movementOut.wallet.id)}
            />
          ) : (
            <div className={classes.emptyPadding}>
              <Typography variant="body1">-</Typography>
            </div>
          )}
        </DrawerSection>

        <DrawerSection name="Date">
          <Typography variant="body2">
            {dayjs(trade.movementIn.transactionDate).tz().format("L")} at{" "}
            {dayjs(trade.movementIn.transactionDate).tz().format("LTS")}
          </Typography>
        </DrawerSection>

        <DrawerSection name="Source">
          <Link
            onClick={() => {
              openWalletDrawer(trade.movementIn.wallet.id)
            }}
            style={{ cursor: "pointer" }}
            underline="always"
          >
            {trade.movementIn.wallet.name}
          </Link>
        </DrawerSection>

        <DrawerSection name="Direction">
          <Typography variant="body1" style={{ textTransform: "capitalize" }}>
            "Trade"
          </Typography>
        </DrawerSection>

        <DrawerSection name="Incoming">
          <div className={classes.containerAssetView}>
            <AssetView
              assetId={trade.movementIn.assetId}
              assetSymbol={trade.movementIn.assetSymbol}
              volume={trade.movementIn.volume}
              direction={trade.movementIn.direction}
              assetToUsdRate={trade.movementIn.assetToUsdRate}
              isFee={trade.movementIn.isFee}
              usdToFiatRate={trade.movementIn.usdToFiatRate}
              alignLeft={true}
              fullNumber
            />
          </div>
        </DrawerSection>

        <DrawerSection name="Outgoing">
          <div className={classes.containerAssetView}>
            <AssetView
              assetId={trade.movementOut.assetId}
              assetSymbol={trade.movementOut.assetSymbol}
              volume={trade.movementOut.volume}
              direction={trade.movementOut.direction}
              assetToUsdRate={trade.movementOut.assetToUsdRate}
              isFee={trade.movementOut.isFee}
              usdToFiatRate={trade.movementOut.usdToFiatRate}
              alignLeft={true}
              fullNumber
            />
          </div>
        </DrawerSection>

        {trade.movementOut.costBasis &&
          !trade.movementOut.costBasis.mainCostBasisError &&
          trade.movementOut.costBasis.costBasisFiat !== null && (
            <DrawerSection name="Cost basis">
              <CostBasisView costBasisFiat={trade.movementOut.costBasis.costBasisFiat} />
            </DrawerSection>
          )}
        {trade.movementOut.costBasis &&
          !trade.movementOut.costBasis.mainCostBasisError &&
          trade.movementOut.costBasis.gainFiat !== null && (
            <DrawerSection name="Gains/Losses">
              <div className="flex justify-start">
                <GainView feeGainFiat={null} outgoingGainFiat={trade.movementOut.costBasis.gainFiat} />
              </div>
            </DrawerSection>
          )}
        {trade.movementOut.transactionHash && (
          <DrawerSection name="Transaction hash">
            <TxHashLink txHash={trade.movementOut.transactionHash} sourceName={trade.movementOut.wallet.sourceName} />
          </DrawerSection>
        )}
      </DrawerCategory>
    </Box>
  )
}

export default MovementSummary
