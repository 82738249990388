import { Box, BoxProps, Divider, Typography, TypographyVariant } from "@material-ui/core"
import React from "react"

interface Props extends BoxProps {
  title: string
  variant?: TypographyVariant
  component?: React.ElementType
}

const TitleWithDivider = ({ title, variant = "h5", component, ...props }: Props): JSX.Element => {
  return (
    <Box {...props}>
      <Box mb={1.5}>
        <Typography variant={variant} {...(component ? { component } : {})}>
          {title}
        </Typography>
      </Box>
      <Divider />
    </Box>
  )
}

export default TitleWithDivider
