import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router"
import { TransactionParams } from "components/CoreTransaction"
import { findFilter } from "components/CoreTransaction/filters/TransactionFilter"

import ChildrenProps from "../misc/childrenProps"

export type Tabs = "missingLabel" | "internalTransfer" | "incompleteChartOfAccounts" | "readyToBeSynchronized"

interface ContextValue {
  isNeedReview: boolean
  isTab: (tabToCheck: Tabs) => boolean
  isFilterByTransactionId: boolean
  countFilters: (filterCount: number, filter: TransactionParams) => number
}

export const FilterContext = React.createContext<ContextValue>(undefined as unknown as ContextValue)

type AllProps = ChildrenProps

export const FilterContextProvider = (props: AllProps): JSX.Element => {
  const { pathname } = useLocation()
  const isNeedReview = useMemo((): boolean => pathname.includes("needReview"), [pathname])
  const { search } = useLocation()
  const [isFilterByTransactionId, setIsFilterByTransactionId] = useState(false)
  const isTab = (tabToCheck: Tabs): boolean => pathname.includes(tabToCheck)

  const countFilters = (filterCount: number, filter: TransactionParams) => {
    if (
      isNeedReview &&
      (findFilter(filter, "internal_transfer_status")?.value === "potential" ||
        isTab("missingLabel") ||
        isTab("incompleteChartOfAccounts"))
    ) {
      return filterCount - (isNeedReview ? 1 : 0)
    }
    return filterCount
  }

  useEffect(() => {
    const ret = search.includes("transactionId")

    if (ret && !isFilterByTransactionId) setIsFilterByTransactionId(true)
    if (!ret && isFilterByTransactionId) setIsFilterByTransactionId(false)
  }, [search, isFilterByTransactionId])

  return (
    <FilterContext.Provider
      value={{
        isNeedReview,
        isTab,
        countFilters,
        isFilterByTransactionId,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  )
}
