import { Box, CircularProgress, makeStyles, Paper, Theme, Typography } from "@material-ui/core"

import React, { useContext, useMemo } from "react"

import BaseContainer from "components/Container"
import MainTitleView from "components/MainTitleView"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import PermissionReadView from "components/Permission/PermissionReadView"
import { IExtendedDataTableColumn } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import TableTitleWithTotalCount from "components/Table/TableTitleWithTotalCount"
import api from "services/api"
import {
  DefiBlockchainDto,
  DefiLivePositionDto,
  DefiProtocolDto,
  GetLivePositionsStateEnum,
} from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import { Mixpanel } from "services/mixpanel"
import { GreenColor } from "materialTheme"
import dayjs from "dayjs"
import PositionDeFi from "./DefiDrawer/PositionDeFi"
import AssetDeFi from "./DefiDrawer/AssetDefi"
import ApplicationDeFi from "./DefiDrawer/ApplicationDeFi"
import Status from "./DefiDrawer/Status"
import useDrawer from "components/misc/useDrawer"
import TypographyUI from "CryptioUI/Typography"
import { TypographyVariant } from "CryptioUI/types"
import DeFiFilter from "./filters/DeFiFilter"
import {
  DefiPositionTypeDto,
  DefiPositionNameDto,
  GetWalletDto,
} from "../../../../tests/api/src/internal-api/openapi/models/index"
import { pluralize } from "services/utils/textUtils"

export interface DeFiParameters {
  page: number
  limit: number
  workspaceId: string
  excludedIds?: Array<string>

  state?: GetLivePositionsStateEnum
  applications?: Array<DefiProtocolDto>
  blockchains?: Array<DefiBlockchainDto>
  positionsNames?: Array<DefiPositionNameDto>
  positionsTypes?: Array<DefiPositionTypeDto>
  walletIds?: Array<GetWalletDto>
}

export const defaultDefiParams: DeFiParameters = {
  page: 20,
  limit: 100,
  workspaceId: "",
  state: undefined,
  applications: undefined,
  blockchains: undefined,
  positionsNames: undefined,
  positionsTypes: undefined,
  walletIds: undefined,
}

export type NewDeFiPaginationQueryDtoSortDirectionEnum = "ascending" | "descending"

type defaultDeFiSortingType = {
  sortDirection: NewDeFiPaginationQueryDtoSortDirectionEnum
  sortBy: "transaction_date"
}

export const defaultDeFiSorting: defaultDeFiSortingType = {
  sortDirection: "descending",
  sortBy: "transaction_date",
}

const useStyles = makeStyles((theme: Theme) => ({
  noContactText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  claimableText: {
    color: GreenColor,
    fontWeight: "bold",
  },
  valueText: {
    fontWeight: "bold",
  },
  positionText: {
    width: "200px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "flex",
    justifyContent: "flex-end",
  },
}))

export const labelHasAnyFilter = (filters: DeFiParameters) =>
  Boolean(
    filters.state ||
      filters.applications ||
      filters.blockchains ||
      filters.positionsNames ||
      filters.positionsTypes ||
      filters.walletIds,
  )

const DeFiScene = (): JSX.Element => {
  const classes = useStyles()

  const { workspace } = useContext(WorkspaceContext)

  const { params, setLimit, setPage, setParams } = usePaginatedParams<DeFiParameters, "transaction_date">(
    defaultDefiParams,
    defaultDeFiSorting,
  )

  const defiPositions = api.workspace.useAllDefiPositions(
    {
      page: params.page,
      limit: params.limit,
      excludedIds: params.excludedIds,
      state: params.state,
      applicationsIds: params.applications?.map((v) => v.id),
      blockchainIds: params.blockchains?.map((v) => v.id),
      positionsNames: params.positionsNames?.map((v) => v.name),
      positionsTypes: params.positionsTypes?.map((v) => v.name),
      walletIds: params.walletIds?.map((w) => w.id),
    },
    {
      enabled: workspace.userRights.can_read_contact,
      refetchInterval: 2000,
      notifyOnChangeProps: ["data", "error", "isError", "isLoading", "isFetched"],
    },
  )
  const viewTitle = "DeFi (Beta)"
  const viewDescription = "Browse your DeFi positions On Ethereum, Polygon, Avalanche & BSC"

  const basicDialog = useDialog()
  const [defiDrawer, openDefiDrawer] = useDrawer("defi")

  const filterCount = useMemo(() => {
    let res = 0
    if (params.state !== undefined) res++
    if (params.applications !== undefined) res++
    if (params.blockchains !== undefined) res++
    if (params.positionsNames !== undefined) res++
    if (params.positionsTypes !== undefined) res++
    if (params.walletIds !== undefined) res++
    return res
  }, [params])

  if (!workspace.userRights.can_read_contact)
    return <PermissionReadView viewTitle={viewTitle} itemName="defiPositions" />
  if (defiPositions.isError) return <NetworkErrorMessage small={false} additionalData={defiPositions} />
  if (defiPositions.isLoading || defiPositions.data === undefined) return <LoadingSpinner />

  const columns: IExtendedDataTableColumn<DefiLivePositionDto>[] = [
    {
      width: "3rem",
    },
    {
      name: "Status",
      selector: "Status",
      minWidth: "100px",
      maxWidth: "125px",
      format: function formatContactAddresses(row) {
        return <Status isOpen={row.active} />
      },
    },
    {
      name: "Application",
      selector: "Application",
      minWidth: "200px",
      format: function formatContactAddresses(row) {
        return (
          <ApplicationDeFi
            left
            protocolLogo={row.protocol.logo}
            protocolName={row.protocol.name}
            blockchainLogo={row.blockchain.logo}
            blockchainName={row.blockchain.name}
          />
        )
      },
    },
    {
      name: "Position Name",
      selector: "Position",
      minWidth: "215px",
      format: function formatContactAddresses(row) {
        return <PositionDeFi left positionName={row.name} walletId={row.walletId} walletName={row.walletName} />
      },
    },
    {
      name: "Position Type",
      selector: "Position",
      minWidth: "215px",
      format: function formatContactAddresses(row) {
        return (
          <TypographyUI variant={TypographyVariant.BODY} className="text-grey-500">
            {row.positionType}
          </TypographyUI>
        )
      },
    },
    {
      name: "Claimable",
      selector: "Claimable",
      minWidth: "200px",
      format: function formatContactAddresses(row) {
        return (
          <AssetDeFi
            left
            assets={row.rewards.assets}
            totalValueUsd={row.rewards.totalValueUsd}
            color={"text-label-green-200"}
          />
        )
      },
    },
    {
      name: "Deposit",
      selector: "Deposit",
      minWidth: "200px",
      format: function formatContactAddresses(row) {
        return <AssetDeFi left assets={row.supplies.assets} totalValueUsd={row.supplies.totalValueUsd} />
      },
    },
    {
      name: "Total Value",
      selector: "Total Value",
      minWidth: "200px",
      format: function formatContactAddresses(row) {
        return <AssetDeFi left assets={row.total.assets} totalValueUsd={row.total.totalValueUsd} />
      },
    },
    {
      width: "3rem",
    },
  ]

  const hasAnyFilter = labelHasAnyFilter(params)

  if (defiPositions.data.totalCount === 0) {
    if (params && params.page > 1) {
      // I'm doing a set a setTimeout to avoid a warning to be triggered
      setTimeout(() => setPage(1), 0)
      return <LoadingSpinner />
    }

    return (
      <BaseContainer>
        {defiDrawer}
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <MainTitleView title={viewTitle} helpTooltipContent={viewDescription} />
        </Box>
        <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          {hasAnyFilter ? (
            <>
              <img src={"/images/nothing.png"} alt="nothing" className="w-64 h-64 m-2" />
              <Typography className="text-center mt-2 mb-4" variant="h4">
                There are no DeFi position that match the selected {pluralize(filterCount > 1, "filter")}.
                <br />
                Click the following buttons to reset or update the filters.
              </Typography>
              <div className="pt-4">
                <DeFiFilter setFilter={setParams} filter={params} />
              </div>
            </>
          ) : (
            <>
              <img src={"/images/nothing.png"} alt="nothing" className="w-64 h-64 m-2" />
              <Typography className={classes.noContactText} variant="h4">
                Your DeFi position list is empty
              </Typography>
            </>
          )}
        </Box>
      </BaseContainer>
    )
  }

  return (
    <BaseContainer>
      {defiDrawer}
      {basicDialog.dialog}
      <div className="mb-4 flex justify-between items-end">
        {defiPositions.data.totalCount > 0 ? (
          <TableTitleWithTotalCount
            onClick={() => Mixpanel.track("ContactsInfo")}
            title={viewTitle}
            qualifier="position"
            totalCount={defiPositions.data.totalCount}
            helpTooltipContent={viewDescription}
          />
        ) : (
          <MainTitleView title={viewTitle} helpTooltipContent={viewDescription} />
        )}
        <div className="flex justify-center flex-row items-end gap-6">
          {defiPositions.data.totalCount !== 0 && (
            <div className="flex flex-row justify-end items-end gap-2">
              <TypographyUI variant={TypographyVariant.BODY}>
                {`Last update: ${
                  defiPositions.data.jobStatus.status === "pending"
                    ? "Update in progress…"
                    : dayjs(defiPositions.data.lastUpdate).format("LLL")
                }`}
              </TypographyUI>
              {defiPositions.data.jobStatus.status === "pending" && (
                <div className="flex justify-center items-center">
                  <CircularProgress size={20} />
                </div>
              )}
            </div>
          )}
          <div className="mr-2">
            <DeFiFilter setFilter={setParams} filter={params} />
          </div>
        </div>
      </div>
      <Paper>
        <ServerSideTable<DefiLivePositionDto, "transaction_date" | undefined>
          columns={columns}
          items={defiPositions.data.data}
          totalCount={defiPositions.data.totalCount}
          limit={defiPositions.data.limit}
          defaultParams={params}
          onPageChange={setPage}
          onLimitChange={setLimit}
          onRowClicked={(row) => openDefiDrawer(row.id)}
        />
      </Paper>
    </BaseContainer>
  )
}

export default DeFiScene
