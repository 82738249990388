import { css } from "@emotion/css"
import { Box } from "@material-ui/core"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import { ReactComponent as Transaction } from "../../CryptioUI/assets/icons/menu/transactions.svg"
import { ReactComponent as WatchLater } from "../../CryptioUI/assets/icons/watch-later.svg"

interface Props {
  isSavedFilter: boolean
  onChange: (isSavedFilter: boolean) => void
}

const LabelRuleEditor = ({ isSavedFilter, onChange }: Props): JSX.Element => {
  return (
    <Box display="flex" flexDirection="row" mt={4} alignItems="center">
      <ButtonUI
        mode={!isSavedFilter ? Mode.DEFAULT : Mode.OUTLINEDHIGHLIGHT}
        onClick={() => onChange(false)}
        Icon={
          <Transaction
            className={css`
              height: 14px;
              width: 14px;
              filter: ${!isSavedFilter
                ? "invert(99%) sepia(1%) saturate(0%) hue-rotate(318deg) brightness(104%) contrast(101%)"
                : "invert(13%) sepia(62%) saturate(1642%) hue-rotate(156deg) brightness(93%) contrast(98%)"};
            `}
          />
        }
      >
        From/To
      </ButtonUI>
      <Box ml={2}>
        <ButtonUI
          mode={isSavedFilter ? Mode.DEFAULT : Mode.OUTLINEDHIGHLIGHT}
          onClick={() => onChange(true)}
          Icon={
            <WatchLater
              className={css`
                height: 14px;
                width: 14px;
                filter: ${isSavedFilter
                  ? "invert(99%) sepia(1%) saturate(0%) hue-rotate(318deg) brightness(104%) contrast(101%)"
                  : "invert(13%) sepia(62%) saturate(1642%) hue-rotate(156deg) brightness(93%) contrast(98%)"};
              `}
            />
          }
        >
          Favorite Filter
        </ButtonUI>
      </Box>
    </Box>
  )
}

export default LabelRuleEditor
