import { Box, IconButton, Link, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import { PermContactCalendarRounded, StarBorderRounded, StarRounded } from "@material-ui/icons"
import React, { useCallback, useContext, useMemo } from "react"
import { Prompt, useHistory } from "react-router"

import BaseContainer from "components/Container"
import { mockInternalTransferLabel } from "components/CoreTransaction/TransactionLabelDrawer/NonTaxabelLabelSelector"
import MainTitleView from "components/MainTitleView"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDrawer from "components/misc/useDrawer"
import { GenericSorting, IExtendedDataTableColumn } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import ButtonUI from "CryptioUI/Button"
import api from "services/api"
import { GetCOAMappingDto, WithoutWorkspaceId } from "services/api/aliases"
import {
  AutomatedMappingLabel,
  GetCOAMappingsRequest,
  GetCOAMappingsSortByEnum,
  GetCOAMappingsTypeEnum,
} from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import MappingFilter from "./filters/MappingFilter"
import { AccountingFilterContext } from "services/context/accountingFilterContext"
import { URLS } from "routes"
import LabelTab from "./AccountingsTabs/LabelTab"
import ExchangeWalletTab from "./AccountingsTabs/ExchangeWalletTab"
import Controller from "./Controller"
import TooltipUI from "CryptioUI/Tooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

type MappingTypeMapName = Record<GetCOAMappingsTypeEnum, { displayName: string; description: string }>

export const mappingTypeMapName: MappingTypeMapName = {
  default_mapping: {
    displayName: "Default account mapping",
    description: "The default account mapping allows you to report you gains, losses, and fee expenses",
  },
  wallet_mapping: {
    displayName: "Wallet mapping",
    description: "A wallet mapping allows to map accounts at the wallet level.",
  },
  exchange_mapping: {
    displayName: "Exchange mapping",
    description: "A wallet mapping allows to map accounts at the exchange level.",
  },
  label_mapping: {
    displayName: "Label mapping",
    description: "A label mapping allows to map accounts to all transactions having a particular label.",
  },
}

export const CoaMappingTypes: GetCOAMappingsTypeEnum[] = Object.keys(mappingTypeMapName) as GetCOAMappingsTypeEnum[]

const useStyles = makeStyles((theme: Theme) => ({
  noMappingText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}))

const defaultMappingSorting: GenericSorting<GetCOAMappingsSortByEnum> = {
  sortDirection: "descending",
  sortBy: "created_at",
}

export const defaultMappingParams: WithoutWorkspaceId<GetCOAMappingsRequest> = {
  type: undefined,
  excludedIds: undefined,
  accountType: undefined,
  individualId: undefined,
  page: 0,
  limit: 10,
}

export const mappingHasAnyFilter = (filters: Partial<GetCOAMappingsRequest>) =>
  Boolean(filters.type || filters.accountType || filters.individualId)

const getDefaultAccountMappingAccountTypes = (row: GetCOAMappingDto) => {
  if (row.type !== "default_mapping") {
    return []
  }

  // like clsx ?
  return [
    (row.gainsAccount?.type === "expense" ||
      row.lossesAccount?.type === "expense" ||
      row.feesAccount?.type === "expense") &&
      "expense",
    (row.gainsAccount?.type === "income" ||
      row.lossesAccount?.type === "income" ||
      row.feesAccount?.type === "income") &&
      "income",
  ].filter(Boolean)
}

const CoreChartAccountAutomatedMapping = (): JSX.Element => {
  const classes = useStyles()
  const workspaceCtx = useContext(WorkspaceContext)
  const history = useHistory()
  const toast = useToast()
  const [mappingDrawer, openMappingDrawer] = useDrawer("chart-account-mapping")
  const { isTab, isNeedReview } = useContext(AccountingFilterContext)

  const { mutateAsync: toggleFavoriteMutation } = api.chartAccount.useUpdateMapping()

  const { params, setParams, setLimit, setPage, setSorting } = usePaginatedParams<
    Partial<GetCOAMappingsRequest>,
    GetCOAMappingsSortByEnum
  >(defaultMappingParams, defaultMappingSorting)

  const [walletDrawer, openWalletDrawer] = useDrawer("wallet")
  const [labelDrawer, openLabelDrawer] = useDrawer("label")

  const toggleLabelMappingFavorite = useCallback(
    async (coa: AutomatedMappingLabel) => {
      try {
        await toggleFavoriteMutation({
          updateAutomatedMappingDto: {
            data: {
              ...coa,
              labelId: coa.label?.id ?? null,
              labelAccountId: coa.labelAccount?.id ?? null,
              mappingId: coa.id,
              isFavorite: !coa.isFavorite,
            },
          },
        })
        toast.open(`Label mapping ${coa.isFavorite ? "removed from" : "added to"} favorites`, { variant: "danger" })
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [toggleFavoriteMutation, toast],
  )

  const columns = useMemo<IExtendedDataTableColumn<GetCOAMappingDto, GetCOAMappingsSortByEnum>[]>(
    () => [
      {
        name: "Type",
        selector: "type",
        format: (row) => mappingTypeMapName[row.type].displayName,
        // sortable: true,
        // sortName: "type",
      },
      {
        name: "On",
        selector: "on",
        format: (row) => {
          switch (row.type) {
            case "wallet_mapping":
            case "exchange_mapping":
              return (
                <TooltipUI content="Source">
                  <div className="flex max-w-[300px] w-[300px]">
                    {row.wallets.map((wallet, index) => (
                      <React.Fragment key={`coa-tooltip-${wallet.id}`}>
                        {index > 0 && <Typography>,&nbsp;</Typography>}
                        <Link underline="always" onClick={() => openWalletDrawer(wallet.id)}>
                          <Typography>{wallet.name}</Typography>
                        </Link>
                      </React.Fragment>
                    ))}
                  </div>
                </TooltipUI>
              )
            case "label_mapping":
              if (row.label) {
                const { label } = row
                return (
                  <TooltipUI content="Label">
                    <Link underline="always" onClick={() => openLabelDrawer(label.id)}>
                      <Typography>{label.name}</Typography>
                    </Link>
                  </TooltipUI>
                )
              } else if (row.isInternalTransferLabel) {
                return (
                  <TooltipUI content="Label">
                    <Typography>{mockInternalTransferLabel.name}</Typography>
                  </TooltipUI>
                )
              }
              return "-"
            default:
              return "-"
          }
        },
      },
      {
        name: "Transactions",
        selector: "transactionCount",
        sortable: true,
        sortName: "transaction_count",
        format: (row) => (row.transactionCount === -1 ? "-" : row.transactionCount),
      },
      {
        name: "Account type",
        cell: function formatAccountType(row) {
          if (row.type === "label_mapping") {
            return row.labelAccount.type
          }
          if (row.type === "wallet_mapping" || row.type === "exchange_mapping") {
            return `asset${row.expenseAccount ? ", expense" : ""}`
          }
          return getDefaultAccountMappingAccountTypes(row).join(", ")
        },
      },
      {
        maxWidth: "200px",
        name: "Label mapping priority",
        selector: "id",
        cell: function cellFavoriteCOA(row) {
          if (row.type !== "label_mapping") return <></>

          return (
            <TooltipUI content="Select the labels that should be given the priority in your chart of account mappings when several labels are applied to one movement">
              <IconButton onClick={() => toggleLabelMappingFavorite(row)}>
                {row.isFavorite ? <StarRounded /> : <StarBorderRounded />}
              </IconButton>
            </TooltipUI>
          )
        },
      },
    ],
    [openLabelDrawer, openWalletDrawer, toggleLabelMappingFavorite],
  )

  const mappings = api.chartAccount.useChartAccountMappings({
    page: params.page,
    limit: params.limit,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
    type: params.type,
    accountType: params.accountType,
    individualId: params.individualId,
  })

  if (mappings.isError) return <NetworkErrorMessage small={false} additionalData={mappings} />

  if (mappings.isLoading || mappings.data === undefined) return <LoadingSpinner />

  const viewTitle = "Chart of accounts"
  const viewDescription =
    "Automate the mapping of accounts from your chart of accounts to the transactions in your Cryptio subledger. Click to know more!"
  const viewHelpURL = "https://support.cryptio.co/hc/en-gb/articles/9083538867089-Introduction-to-the-Chart-of-Accounts"

  if (workspaceCtx.workspace.accountingIntegration === null) {
    return (
      <BaseContainer>
        {mappingDrawer}
        <MainTitleView title={viewTitle} helpTooltipContent={viewDescription} helpTooltipUrl={viewHelpURL} />
        <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <PermContactCalendarRounded fontSize="large" />
          <Typography className={classes.noMappingText} variant="h4">
            You do not have any accounting integration connected.
            <br />
            Click the button below to go to the integration view.
          </Typography>
          <ButtonUI onClick={() => history.push(URLS.Accounting.Integrations)}>Go to Integrations</ButtonUI>
        </Box>
      </BaseContainer>
    )
  }

  if (mappings.data.data.length === 0) {
    if (params.page > 1) {
      setTimeout(() => setPage(1), 0)
      return <LoadingSpinner />
    }
  }

  return (
    <BaseContainer>
      {walletDrawer}
      {labelDrawer}
      {mappingDrawer}
      <Prompt
        when={workspaceCtx.workspace.isCoaMappingDirty && workspaceCtx.workspace.coaMappingStatus.status === "inactive"}
        message={(location) => {
          return location.pathname === "/chart_of_accounts/automated_mapping"
            ? true
            : "Changes have not been applied. Are you sure you want to leave this page?"
        }}
      />
      <Controller
        maxTotalCount={null}
        item={""}
        isRefetching={false}
        setFilters={setParams}
        filter={params}
        title={viewTitle}
        qualifier="mapping"
        totalCount={mappings.data.totalCount}
        helpTooltipContent={viewDescription}
        helpTooltipUrl={viewHelpURL}
      />
      {!isNeedReview ? (
        <>
          {mappings.data.data.length === 0 ? (
            <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography className={classes.noMappingText} variant="h4">
                There are no mappings that match the selected filters.
                <br />
                Click the following buttons to reset or update the filters.
              </Typography>
              <Box display="flex" flexDirection="row">
                <MappingFilter setFilter={setParams} filter={params} />
              </Box>
            </Box>
          ) : (
            <Paper>
              <ServerSideTable<GetCOAMappingDto, GetCOAMappingsSortByEnum>
                columns={columns}
                items={mappings.data.data}
                totalCount={mappings.data.totalCount}
                defaultParams={params}
                limit={mappings.data.limit}
                onPageChange={setPage}
                onLimitChange={setLimit}
                onSort={setSorting}
                onRowClicked={(row) => openMappingDrawer(row.id)}
              />
            </Paper>
          )}
        </>
      ) : (
        <>
          {isTab("label_mapping") && <LabelTab openMappingDrawer={openMappingDrawer} />}
          {(isTab("wallet_mapping") || isTab("exchange_mapping")) && (
            <ExchangeWalletTab openMappingDrawer={openMappingDrawer} />
          )}
        </>
      )}
    </BaseContainer>
  )
}

export default CoreChartAccountAutomatedMapping
