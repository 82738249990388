/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  AutomatedMappingDefaultAccount,
  AutomatedMappingExchange,
  AutomatedMappingLabel,
  AutomatedMappingWallet,
  CreateAutomatedMappingDto,
  CreateChartAccountsDto,
  GetHasInternalTransferMappingDto,
  GetMissingMappingMetricsDto,
  PaginatedAutomatedMappingResultDto,
  PaginatedChartAccountResultDto,
  ReplaceChartAccountsDto,
  UpdateAutomatedMappingDto,
  UpdateChartAccountsVisibleDto,
} from "../models"

export interface CalculateAutomatedMappingRequest {
  workspaceId: string
}

export interface CreateCOAMappingRequest {
  workspaceId: string
  createAutomatedMappingDto: CreateAutomatedMappingDto
}

export interface CreateChartAccountsRequest {
  workspaceId: string
  createChartAccountsDto: CreateChartAccountsDto
}

export interface DeleteCOAMappingRequest {
  workspaceId: string
  mappingId: string
}

export interface GetCOAMappingRequest {
  workspaceId: string
  mappingId: string
}

export interface GetCOAMappingsRequest {
  workspaceId: string
  page: number
  limit: number
  sortBy?: GetCOAMappingsSortByEnum
  sortDirection?: GetCOAMappingsSortDirectionEnum
  type?: GetCOAMappingsTypeEnum
  accountType?: GetCOAMappingsAccountTypeEnum
  individualId?: string
  excludedIds?: Array<string>
}

export interface GetChartAccountsRequest {
  page: number
  limit: number
  workspaceId: string
  query?: string
  sortBy?: GetChartAccountsSortByEnum
  sortDirection?: GetChartAccountsSortDirectionEnum
  types?: Array<GetChartAccountsTypesEnum>
  visibleState?: GetChartAccountsVisibleStateEnum
  excludedIds?: Array<string>
}

export interface GetMissingMappingMetricsRequest {
  workspaceId: string
}

export interface HasInternalTransferMappingRequest {
  workspaceId: string
}

export interface ReplaceChartAccountsRequest {
  workspaceId: string
  replaceChartAccountsDto: ReplaceChartAccountsDto
}

export interface UpdateCOAMappingRequest {
  workspaceId: string
  updateAutomatedMappingDto: UpdateAutomatedMappingDto
}

export interface UpdateChartAccountsVisibleRequest {
  workspaceId: string
  updateChartAccountsVisibleDto: UpdateChartAccountsVisibleDto
}

/**
 *
 */
export class ChartAccountsApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static calculateAutomatedMapping(params: CalculateAutomatedMappingRequest) {
    return localChartAccountsApi.calculateAutomatedMapping(params)
  }

  /**
   * Calculate updated mappings
   */
  calculateAutomatedMapping({ workspaceId }: CalculateAutomatedMappingRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/chart_accounts/mapping/compute`,
      undefined,
      true,
    )
  }

  static createCOAMapping(params: CreateCOAMappingRequest) {
    return localChartAccountsApi.createCOAMapping(params)
  }

  /**
   * Create a new mapping
   */
  createCOAMapping({ createAutomatedMappingDto, workspaceId }: CreateCOAMappingRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/chart_accounts/mapping`,
      createAutomatedMappingDto,
      true,
    )
  }

  static createChartAccounts(params: CreateChartAccountsRequest) {
    return localChartAccountsApi.createChartAccounts(params)
  }

  /**
   * Create many accounts
   */
  createChartAccounts({ createChartAccountsDto, workspaceId }: CreateChartAccountsRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/chart_accounts`,
      createChartAccountsDto,
      true,
    )
  }

  static deleteCOAMapping(params: DeleteCOAMappingRequest) {
    return localChartAccountsApi.deleteCOAMapping(params)
  }

  /**
   * Delete a mapping
   */
  deleteCOAMapping({ workspaceId, mappingId }: DeleteCOAMappingRequest): Promise<void> {
    return this.restInstance.delete(
      `/workspaces/${encodeURIComponent(workspaceId)}/chart_accounts/mapping/${encodeURIComponent(mappingId)}`,
      undefined,
    )
  }

  static getCOAMapping(params: GetCOAMappingRequest) {
    return localChartAccountsApi.getCOAMapping(params)
  }

  /**
   * Get one mapping
   */
  getCOAMapping({
    workspaceId,
    mappingId,
  }: GetCOAMappingRequest): Promise<
    AutomatedMappingDefaultAccount | AutomatedMappingWallet | AutomatedMappingExchange | AutomatedMappingLabel
  > {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/chart_accounts/mapping/${encodeURIComponent(mappingId)}`,
    )
  }

  static getCOAMappings(params: GetCOAMappingsRequest) {
    return localChartAccountsApi.getCOAMappings(params)
  }

  /**
   * Get mappings
   */
  getCOAMappings({
    workspaceId,
    ...queryParameters
  }: GetCOAMappingsRequest): Promise<PaginatedAutomatedMappingResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/chart_accounts/mapping?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }

  static getChartAccounts(params: GetChartAccountsRequest) {
    return localChartAccountsApi.getChartAccounts(params)
  }

  /**
   * Get user\'s chart of accounts
   */
  getChartAccounts({
    workspaceId,
    ...queryParameters
  }: GetChartAccountsRequest): Promise<PaginatedChartAccountResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/chart_accounts?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }

  static getMissingMappingMetrics(params: GetMissingMappingMetricsRequest) {
    return localChartAccountsApi.getMissingMappingMetrics(params)
  }

  /**
   * Get metrics on missing mappings
   */
  getMissingMappingMetrics({ workspaceId }: GetMissingMappingMetricsRequest): Promise<GetMissingMappingMetricsDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/chart_accounts/mapping/review/metrics`)
  }

  static hasInternalTransferMapping(params: HasInternalTransferMappingRequest) {
    return localChartAccountsApi.hasInternalTransferMapping(params)
  }

  /**
   * Check if user has internal transfer mapping
   */
  hasInternalTransferMapping({
    workspaceId,
  }: HasInternalTransferMappingRequest): Promise<GetHasInternalTransferMappingDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/chart_accounts/has-internal-transfer-mapping`,
    )
  }

  static replaceChartAccounts(params: ReplaceChartAccountsRequest) {
    return localChartAccountsApi.replaceChartAccounts(params)
  }

  /**
   * Replace many accounts
   */
  replaceChartAccounts({ replaceChartAccountsDto, workspaceId }: ReplaceChartAccountsRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/chart_accounts/replace`,
      replaceChartAccountsDto,
      true,
    )
  }

  static updateCOAMapping(params: UpdateCOAMappingRequest) {
    return localChartAccountsApi.updateCOAMapping(params)
  }

  /**
   * Update a mapping
   */
  updateCOAMapping({ updateAutomatedMappingDto, workspaceId }: UpdateCOAMappingRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/chart_accounts/mapping`,
      updateAutomatedMappingDto,
      true,
    )
  }

  static updateChartAccountsVisible(params: UpdateChartAccountsVisibleRequest) {
    return localChartAccountsApi.updateChartAccountsVisible(params)
  }

  /**
   * Update many accounts
   */
  updateChartAccountsVisible({
    updateChartAccountsVisibleDto,
    workspaceId,
  }: UpdateChartAccountsVisibleRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/chart_accounts`,
      updateChartAccountsVisibleDto,
      true,
    )
  }
}

const localChartAccountsApi = new ChartAccountsApi()

/**
 * @export
 * @enum {string}
 */
export type GetCOAMappingsSortByEnum = "transaction_count" | "created_at"
/**
 * @export
 * @enum {string}
 */
export type GetCOAMappingsSortDirectionEnum = "ascending" | "descending"
/**
 * @export
 * @enum {string}
 */
export type GetCOAMappingsTypeEnum = "default_mapping" | "wallet_mapping" | "exchange_mapping" | "label_mapping"
/**
 * @export
 * @enum {string}
 */
export type GetCOAMappingsAccountTypeEnum = "asset" | "liability" | "income" | "expense" | "equity"
/**
 * @export
 * @enum {string}
 */
export type GetChartAccountsSortByEnum = "name" | "description" | "code"
/**
 * @export
 * @enum {string}
 */
export type GetChartAccountsSortDirectionEnum = "ascending" | "descending"
/**
 * @export
 * @enum {string}
 */
export type GetChartAccountsTypesEnum = "asset" | "liability" | "income" | "expense" | "equity"
/**
 * @export
 * @enum {string}
 */
export type GetChartAccountsVisibleStateEnum = "visibleOnly" | "hiddenOnly" | "all"
