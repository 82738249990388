import { Box, DialogActions, Typography } from "@material-ui/core"
import { CheckRounded, WarningRounded } from "@material-ui/icons"
import dayjs from "dayjs"
import React, { useContext, useRef, useState } from "react"
import { IDataTableColumn } from "react-data-table-component"
import { useForm } from "react-hook-form"

import { PlainDate } from "components/DateRangePicker"
import { useLoadingButton } from "components/LoadingButton"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import BaseTable from "components/Table"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { CreateBatchWalletsDto } from "services/api/openapi"
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from "services/constants"
import { ThemeContext } from "services/context/themeContext"
import parseCsvFile, {
  CsvModalType,
  CsvParseOptions,
  findRowByRowIdFilterByErrorString,
  WithCsvInfos,
} from "services/csvParse"
import { widgetTableStyle } from "../Dashboard/widgets/widgetTableStyle"
import InputButtonUI from "CryptioUI/InputButton"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface CreateWalletCsv {
  name: string
  notes?: string
  type: string
  startDate?: string
  endDate?: string
}

type WalletWithCsvInfos = WithCsvInfos<CreateBatchWalletsDto["wallets"][0]>
interface WalletModalCsvForm {
  wallets: WalletWithCsvInfos[]
}

const columns: IDataTableColumn<WalletWithCsvInfos>[] = [
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Notes",
    selector: "notes",
    sortable: true,
  },
  {
    name: "Type",
    selector: "type",
    sortable: true,
  },
  {
    name: "Start date",
    selector: "startDate",
    sortable: true,
    format: (row) => (row.startDate ? dayjs.utc(row.startDate).format("YYYY/MM/DD") : ""),
  },
  {
    name: "End date",
    selector: "endDate",
    sortable: true,
    format: (row) => (row.endDate ? dayjs.utc(row.endDate).format("YYYY/MM/DD") : ""),
  },
  {
    name: "Status",
    selector: "errorString",
    sortable: true,
    format: function formatAddress(row) {
      return row.errorString ? (
        <Typography color="error" variant="body1">
          <Box display="flex">
            <Box pr={1}>
              <WarningRounded />
            </Box>
            {row.errorString}
          </Box>
        </Typography>
      ) : (
        <CheckRounded color="action" />
      )
    },
  },
]

interface Props {
  onClose: () => void
}

const WalletModalCsv = (props: Props): JSX.Element => {
  const [modalData, setModalData] = useState<CsvModalType<CreateBatchWalletsDto["wallets"][0]>>({
    isOpen: false,
    data: [],
  })
  const muiThemeContext = useContext(ThemeContext)
  const { handleSubmit } = useForm<WalletModalCsvForm>()
  const toast = useToast()

  const [selectedRows, setSelectedRows] = useState<{
    allSelected: boolean
    selectedCount: number
    selectedRows: WalletWithCsvInfos[]
  }>({ allSelected: false, selectedCount: 0, selectedRows: [] })

  const [ImportSelectionButton, handleButtonCallback] = useLoadingButton()
  const { mutateAsync: createContactMutation } = api.wallet.useCreateBatchWallets()
  const onSubmit = async () => {
    try {
      await createContactMutation({
        createBatchWalletsDto: { wallets: findRowByRowIdFilterByErrorString(selectedRows) },
      })

      toast.open("Success", { variant: "success" })

      setModalData({
        isOpen: false,
        data: [],
      })
      props.onClose()
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const fileField = useRef<HTMLInputElement>(null)

  const types = api.wallet.useTypes({
    onlyInWorkspace: false,
  })

  if (types.isError) return <NetworkErrorMessage additionalData={types} />

  if (types.isLoading || types.data === undefined) return <LoadingSpinner />

  const lowercasedSourceName = types.data.map(({ name, ...s }) => ({
    ...s,
    name: name.toLowerCase(),
  }))
  const handleFileChange = () => {
    if (!fileField.current || !fileField.current.files || !fileField.current.files.length) {
      return
    }

    const options: CsvParseOptions<CreateWalletCsv, CreateBatchWalletsDto["wallets"][0]> = {
      file: fileField.current.files[0],
      requiredColumns: ["name", "type"],
      setModalData,
      toast,
      transformData: (data) =>
        data.map(({ name, type, startDate, endDate, ...rawCredentials }, index) => {
          const importTypeName = type.toLowerCase().trim()
          const formatStartDate = dayjs(startDate)
          const formatEndDate = dayjs(endDate)
          const matchingSource = lowercasedSourceName.find((s) => s.name === importTypeName)
          let errorString: string | null = null

          const credentials: Record<string, string> = {}

          if (
            matchingSource === undefined ||
            matchingSource.credentialFields.some((f) => f.type === "file" && f.isRequired)
          ) {
            errorString = "Invalid type"
          } else {
            const credentialMeta = [
              {
                dbName: "address",
                validNames: ["address"],
                errorMessage: "Missing wallet address",
              },
              {
                dbName: "apiKey",
                validNames: ["apiKey", "api_key"],
                errorMessage: "Missing API key",
              },
              {
                dbName: "apiSecret",
                validNames: ["apiSecret", "api_secret"],
                errorMessage: "Missing API secret",
              },
              {
                dbName: "apiPassphrase",
                validNames: ["apiPassphrase", "passphrase"],
                errorMessage: "Missing passphrase",
              },
              {
                dbName: "subAccount",
                validNames: ["subAccount", "subaccount"],
                errorMessage: "Missing sub account",
              },
              {
                dbName: "btcsClientNumber",
                validNames: ["btcsClientNumber"],
                errorMessage: "Missing client number",
              },
              {
                dbName: "btcsAccountNumber",
                validNames: ["btcsAccountNumber"],
                errorMessage: "Missing account number",
              },
            ]

            const csvCredentialsEntries = Object.entries(rawCredentials)

            for (const credentialField of matchingSource.credentialFields) {
              const metadata = credentialMeta.find((meta) => meta.validNames.includes(credentialField.name))

              if (!metadata) {
                // Ignore for now
                continue
              }
              const fieldValue = csvCredentialsEntries.find(
                ([credentialKey, value]) => metadata.validNames.includes(credentialKey) && value,
              )

              if (fieldValue) {
                credentials[metadata.dbName] = fieldValue[1]
              } else if (credentialField.isRequired) {
                errorString = metadata.errorMessage
              }
            }
          }
          return {
            name,
            startDate: startDate ? new PlainDate(formatStartDate.utc(true)).toBackendDate() : null,
            endDate: endDate ? new PlainDate(formatEndDate.utc(true)).toBackendDate() : null,
            type: matchingSource?.name,
            typeId: matchingSource?.id ?? "",
            credentials,
            errorString,
            rowId: index,
          }
        }),
    }

    parseCsvFile<CreateWalletCsv, CreateBatchWalletsDto["wallets"][0]>(options)
  }

  return (
    <>
      <InputButtonUI
        htmlFor="transactions"
        type="file"
        accept=".csv"
        ref={fileField}
        name="transactions"
        id="transactions"
        onClick={() => {
          if (fileField.current) {
            fileField.current.value = ""
            fileField.current.files = null
          }
        }}
        onChange={handleFileChange}
      >
        Batch sources import
      </InputButtonUI>
      <div className="ml-4">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.google.com/spreadsheets/d/1Bax-jChTMXxTa0uLV3uTBJ12zoPph0NCBXRG_gaDKqQ"
        >
          <ButtonUI>See CSV Example</ButtonUI>
        </a>
      </div>
      <ModalUI
        isOpen={modalData.isOpen}
        onClose={() => setModalData({ isOpen: false, data: [] })}
        className="w-[1200px] h-auto"
        title="Import wallets"
      >
        <BaseTable<WalletWithCsvInfos, "errorString">
          columns={columns}
          items={modalData.data}
          fixedScroll="400px"
          onSelectedChange={setSelectedRows}
          paginationRowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          paginationTotalRows={modalData.data.length}
          paginationPerPage={50}
          pagination={modalData.data.length > DEFAULT_ROWS_PER_PAGE}
          customStyle={widgetTableStyle(muiThemeContext.type)}
          defaultParams={{
            sortDirection: "ascending",
            sortBy: "errorString",
          }}
          sortServer={false}
        />
        <Box component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              {modalData.data.length} element
              {modalData.data.length > 1 ? "s" : ""} found
            </Typography>
            <div className="flex flex-row space-x-4">
              <ButtonUI onClick={() => setModalData({ isOpen: false, data: [] })} mode={Mode.CONTAINED}>
                Close
              </ButtonUI>
              <ImportSelectionButton disabled={selectedRows.selectedCount === 0} type="submit">
                Import selection
              </ImportSelectionButton>
            </div>
          </DialogActions>
        </Box>
      </ModalUI>
    </>
  )
}

export default WalletModalCsv
