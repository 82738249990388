import { queryClient } from "../../../.."
import { useWorkspaceMutation } from "../../../context/workspaceContext"
import { BackOfficeContactApi } from "../../openapi"
import { GET_CONTACTS_KEY, GET_CONTACT_KEY } from "../contact"

export const backOfficeContactActions = {
  useUpdateMovementCounter: () => {
    return useWorkspaceMutation(BackOfficeContactApi.backOfficeRefreshMovementCount, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_CONTACTS_KEY)
        queryClient.invalidateQueries(GET_CONTACT_KEY)
      },
    })
  },
}
