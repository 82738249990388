import { Avatar, Box, createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import { prettyAccountingIntegrationNameMap } from "pure-shared"
import React from "react"

import { UncontrolledLoadingButton } from "components/LoadingButton"
import { Mode } from "CryptioUI/types"
import ChartAccountModalCsv from "./ChartAccountModalCsv"
import { IntegrationBlockProps } from "."

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      minHeight: "250px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    disabledRoot: {
      padding: theme.spacing(2),
      minHeight: "250px",
      backgroundColor: "#EDEDED",
      cursor: "not-allowed",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    containerText: {
      height: "100%",
    },
    avatarCustom: {
      "marginRight": "16px",
      "& img": {
        border: "solid #fff",
      },
    },
  }),
)

const CustomIntegrationBlock = ({
  onDisconnect,
  askConnect,
  workspaceIntegration,
  disabled,
  integration,
}: IntegrationBlockProps) => {
  const classes = useStyles()
  const integrationName = prettyAccountingIntegrationNameMap[integration.name]

  return (
    <Paper className={disabled && !workspaceIntegration ? classes.disabledRoot : classes.root}>
      <Box display="flex" alignItems="center">
        <Avatar alt={integrationName} src={integration.logo} className={classes.avatarCustom} />
        <Typography variant="h2">{integrationName}</Typography>
      </Box>
      <Box mb={6} mt={2}>
        <Typography variant="body1">
          When importing a chart of accounts via CSV, it is not possible to synchronize transactions with a third party
          accounting system. However, it is still possible to use the automated chart of accounts mapping features and
          generate ledger entries reports.
          <a
            className="text-primary-300"
            href={"https://docs.google.com/spreadsheets/d/1xKBkMD9fGm10RpIrzavp0GCyCW1ThWW9o7pNUr_6MGk"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {` Click here to open a chart of accounts CSV example.`}
          </a>
        </Typography>
      </Box>
      <div className="mt-4 flex flex-row space-x-4">
        {workspaceIntegration && (
          <UncontrolledLoadingButton onClick={onDisconnect} mode={Mode.CONTAINED}>
            Disconnect
          </UncontrolledLoadingButton>
        )}
        <ChartAccountModalCsv
          buttonText={workspaceIntegration ? "Update COA" : "Import a COA via CSV"}
          buttonDisabled={!workspaceIntegration && disabled}
          askConnect={askConnect}
        />
      </div>
    </Paper>
  )
}

export default CustomIntegrationBlock
