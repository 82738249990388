/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  CreateInvoiceDto,
  DeleteInvoicesDto,
  GetInvoiceSignedUrlDto,
  Invoice,
  PaginatedInvoiceResultDto,
} from "../models"

export interface CreateInvoiceRequest {
  workspaceId: string
  createInvoiceDto: CreateInvoiceDto
}

export interface DeleteInvoiceRequest {
  workspaceId: string
  deleteInvoicesDto: DeleteInvoicesDto
}

export interface DownloadInvoiceRequest {
  workspaceId: string
  invoiceId: string
}

export interface GetInvoicesRequest {
  page: number
  limit: number
  sortBy: GetInvoicesSortByEnum
  workspaceId: string
  query?: string
  sortDirection?: GetInvoicesSortDirectionEnum
  invoiceRef?: Array<string>
  excludedIds?: Array<string>
}

export interface UploadInvoiceFileRequest {
  workspaceId: string
  invoiceId: string
}

/**
 *
 */
export class InvoicesApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static createInvoice(params: CreateInvoiceRequest) {
    return localInvoicesApi.createInvoice(params)
  }

  /**
   * Create invoice
   */
  createInvoice({ createInvoiceDto, workspaceId }: CreateInvoiceRequest): Promise<Invoice> {
    return this.restInstance.post(`/workspaces/${encodeURIComponent(workspaceId)}/invoices`, createInvoiceDto)
  }

  static deleteInvoice(params: DeleteInvoiceRequest) {
    return localInvoicesApi.deleteInvoice(params)
  }

  /**
   * Delete an invoice
   */
  deleteInvoice({ deleteInvoicesDto, workspaceId }: DeleteInvoiceRequest): Promise<void> {
    return this.restInstance.delete(`/workspaces/${encodeURIComponent(workspaceId)}/invoices`, deleteInvoicesDto)
  }

  static downloadInvoice(params: DownloadInvoiceRequest) {
    return localInvoicesApi.downloadInvoice(params)
  }

  /**
   * Get a signed url to download an invoice
   */
  downloadInvoice({ workspaceId, invoiceId }: DownloadInvoiceRequest): Promise<GetInvoiceSignedUrlDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/invoices/${encodeURIComponent(invoiceId)}/download`,
    )
  }

  static getInvoices(params: GetInvoicesRequest) {
    return localInvoicesApi.getInvoices(params)
  }

  /**
   * Get user\'s transaction history
   */
  getInvoices({ workspaceId, ...queryParameters }: GetInvoicesRequest): Promise<PaginatedInvoiceResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/invoices?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
    )
  }

  static uploadInvoiceFile(params: UploadInvoiceFileRequest) {
    return localInvoicesApi.uploadInvoiceFile(params)
  }

  /**
   * Upload invoice file
   */
  uploadInvoiceFile({ workspaceId, invoiceId }: UploadInvoiceFileRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/invoices/${encodeURIComponent(invoiceId)}/upload`,
      undefined,
      true,
    )
  }
}

const localInvoicesApi = new InvoicesApi()

/**
 * @export
 * @enum {string}
 */
export type GetInvoicesSortByEnum = "invoice_ref"
/**
 * @export
 * @enum {string}
 */
export type GetInvoicesSortDirectionEnum = "ascending" | "descending"
