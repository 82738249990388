import { UseQueryOptions } from "react-query"

import { queryClient } from "../../.."
import { usePaginatedWorkspaceQuery, useWorkspaceMutation, useWorkspaceQuery } from "../../context/workspaceContext"
import { WithoutWorkspaceId } from "../aliases"
import {
  BillingApi,
  BillingCheckoutUpcomingInvoiceData,
  BillingStandardUpcomingInvoiceData,
  GetChangeUpcomingInvoiceRequest,
  GetFullSubscriptionDto,
  GetInvoiceRequest,
  PaginatedSubscriptionInvoiceResultDto,
} from "../openapi"

export const GET_SHORT_SUBSCRIPTION_KEY = "getShortSubscription"
export const GET_FULL_SUBSCRIPTION_KEY = "getFullSubscription"
export const GET_PACKAGES_KEY = "getPackages"
export const GET_CHECKOUT_KEY = "getCheckout"
export const GET_INVOICES_KEY = "getInvoices"
export const GET_UPCOMING_INVOICE_DATA = "GetUpcomingInvoiceData"
export const GET_CHANGE_UPCOMING_INVOICE_DATA = "GetChangeUpcomingInvoiceData"
export const GET_ONE_PLAN = "getOnePlan"

export const billingActions = {
  useCreateStripeCheckoutSession: () => {
    return useWorkspaceMutation(BillingApi.createStripeCheckoutSession, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_SHORT_SUBSCRIPTION_KEY)
        queryClient.invalidateQueries(GET_FULL_SUBSCRIPTION_KEY)
        queryClient.invalidateQueries(GET_INVOICES_KEY)
        queryClient.invalidateQueries(GET_UPCOMING_INVOICE_DATA)
        queryClient.invalidateQueries(GET_CHANGE_UPCOMING_INVOICE_DATA)
      },
    })
  },
  useChangeSubscription: () => {
    return useWorkspaceMutation(BillingApi.changeSubscription, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_SHORT_SUBSCRIPTION_KEY)
        queryClient.invalidateQueries(GET_FULL_SUBSCRIPTION_KEY)
        queryClient.invalidateQueries(GET_INVOICES_KEY)
        queryClient.invalidateQueries(GET_UPCOMING_INVOICE_DATA)
        queryClient.invalidateQueries(GET_CHANGE_UPCOMING_INVOICE_DATA)
      },
    })
  },
  useCancelSubscription: () => {
    return useWorkspaceMutation(BillingApi.cancelSubscription, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_SHORT_SUBSCRIPTION_KEY)
        queryClient.invalidateQueries(GET_FULL_SUBSCRIPTION_KEY)
        queryClient.invalidateQueries(GET_UPCOMING_INVOICE_DATA)
        queryClient.invalidateQueries(GET_CHANGE_UPCOMING_INVOICE_DATA)
      },
    })
  },
  useReactivateSubscription: () => {
    return useWorkspaceMutation(BillingApi.reactivateSubscription, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_SHORT_SUBSCRIPTION_KEY)
        queryClient.invalidateQueries(GET_FULL_SUBSCRIPTION_KEY)
        queryClient.invalidateQueries(GET_UPCOMING_INVOICE_DATA)
        queryClient.invalidateQueries(GET_CHANGE_UPCOMING_INVOICE_DATA)
      },
    })
  },
  useRetrieveUpcomingInvoiceData: (options?: UseQueryOptions<BillingStandardUpcomingInvoiceData>) => {
    return useWorkspaceQuery([GET_UPCOMING_INVOICE_DATA], BillingApi.getUpcomingInvoice, {}, options)
  },
  useChangeRetrieveUpcomingInvoiceData: (
    variables: WithoutWorkspaceId<GetChangeUpcomingInvoiceRequest>,
    options?: UseQueryOptions<BillingCheckoutUpcomingInvoiceData>,
  ) => {
    return useWorkspaceQuery(
      [GET_CHANGE_UPCOMING_INVOICE_DATA, variables],
      BillingApi.getChangeUpcomingInvoice,
      variables,
      options,
    )
  },
  useShortSubscription: () => {
    return useWorkspaceQuery([GET_SHORT_SUBSCRIPTION_KEY], BillingApi.getShortSubscription, {})
  },
  useFullSubscription: (options?: UseQueryOptions<GetFullSubscriptionDto>) => {
    return useWorkspaceQuery([GET_FULL_SUBSCRIPTION_KEY], BillingApi.getFullSubscription, {}, options)
  },
  useInvoices: (
    variables: WithoutWorkspaceId<GetInvoiceRequest>,
    options?: UseQueryOptions<PaginatedSubscriptionInvoiceResultDto>,
  ) => {
    return usePaginatedWorkspaceQuery([GET_INVOICES_KEY, variables], BillingApi.getInvoice, variables, options)
  },
  usePackages: () => {
    return useWorkspaceQuery([GET_PACKAGES_KEY], BillingApi.getPackages, {})
  },
  usePlan: (planId: string) => {
    return useWorkspaceQuery([GET_ONE_PLAN], BillingApi.getOnePlan, { planId })
  },
}
