import BigNumber from "bignumber.js"
import numbro from "numbro"
import { prettyEmptyOrNull } from "pure-shared"
import React, { useContext } from "react"
import TypographyAllowEvent from "components/TypographyAllowEvent"

import { GainColor, LossColor } from "../../../materialTheme"
import { WorkspaceContext } from "services/context/workspaceContext"
import { getSymbol } from "services/utils/AssetSymbols"
import bigToFormatString from "services/utils/bigToString"
import { prettyNumber } from "services/utils/processNumber"
import { BoldWeight, TypographyVariant } from "CryptioUI/types"
import { ReactComponent as ArrowUpRight } from "CryptioUI/assets/icons/arrow-up-right.svg"
import { ReactComponent as ArrowDownRight } from "CryptioUI/assets/icons/arrow-down-right.svg"

interface Props {
  outgoingGainFiat: string | null
  feeGainFiat: string | null
  extended?: boolean
}

const InternalGainView = (props: { gain: BigNumber; symbol: string | null; color: string; extended?: boolean }) => {
  return (
    <div className="flex flex-row justify-center items-center">
      {props.gain.gt(0) ? (
        <div className="flex justify-center items-center bg-label-green-100 p-2 mr-2 rounded-full">
          <ArrowUpRight className="text-label-green-200 w-3 h-3" />
        </div>
      ) : (
        <div className="flex justify-center items-center bg-label-red-100 p-2 mr-2 rounded-full">
          <ArrowDownRight className="text-label-red-200 w-3 h-3" />
        </div>
      )}
      <TypographyAllowEvent variant={TypographyVariant.BODY} boldness={BoldWeight.REGULAR} className={`text-grey-500`}>
        {prettyEmptyOrNull(props.symbol)}
        {` ${
          props.extended
            ? numbro(props.gain.abs()).format(prettyNumber(props.gain.abs()))
            : bigToFormatString(props.gain.abs())
        }`}
      </TypographyAllowEvent>
    </div>
  )
}

const GainView = ({ outgoingGainFiat, feeGainFiat, extended }: Props) => {
  const { workspace } = useContext(WorkspaceContext)

  const userFiatSymbol = getSymbol(workspace.defaultCurrencySymbol)

  const gainSum =
    outgoingGainFiat && feeGainFiat
      ? new BigNumber(outgoingGainFiat).plus(feeGainFiat)
      : outgoingGainFiat || feeGainFiat

  if (!gainSum) {
    return <TypographyAllowEvent variant={TypographyVariant.BODY}>-</TypographyAllowEvent>
  }

  let gain = new BigNumber(gainSum)
  if (extended) {
    gain = gain.decimalPlaces(2, BigNumber.ROUND_UP)
  }
  return (
    <InternalGainView
      extended={extended}
      gain={gain}
      symbol={userFiatSymbol}
      color={gain.gt(0) ? GainColor : LossColor}
    />
  )
}

export default GainView
