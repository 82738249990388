import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import React, { ChangeEvent, useEffect, useState } from "react"
import { UseQueryResult } from "react-query"
import CheckLine from "components/CheckLine"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"

import { GetLabelDto, MovementCounterPartyDto } from "services/api/openapi"
import SelectCounterParty from "./SelectCounterParty"

const useStyles = makeStyles((theme: Theme) => ({
  containerSection: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  typography: {
    fontWeight: 500,
  },
}))

interface DisclamerInternalTransferProps {
  counterParties: UseQueryResult<MovementCounterPartyDto[], unknown>
  labelsToAdd: GetLabelDto[]
  setConfirmedInternalTransfer: (isConfirmedInternalTransfer: boolean) => void
  isGlobalLabelInternalTransfer: boolean
  confirmedInternalTransfer: boolean
  setSelectedGuessedOtherParty: (newCounterParty: MovementCounterPartyDto | null) => void
  selectedGuessedOtherParty: MovementCounterPartyDto | null
}

const DisclamerInternalTransfer = (props: DisclamerInternalTransferProps) => {
  const {
    counterParties,
    setConfirmedInternalTransfer,
    isGlobalLabelInternalTransfer,
    confirmedInternalTransfer,
    setSelectedGuessedOtherParty,
    selectedGuessedOtherParty,
  } = props
  const classes = useStyles()
  const [isInternal, setIsInternal] = useState(false)

  useEffect(() => setConfirmedInternalTransfer(false), [isGlobalLabelInternalTransfer, setConfirmedInternalTransfer])

  return (
    <Box>
      <div className={classes.containerSection}>
        <Box mt={3}>
          <Typography className={classes.typography} variant="body1">
            Are you sure this transaction is an internal transfer?
          </Typography>
        </Box>
      </div>
      <Box>
        <div className={classes.containerSection}>
          <CheckLine
            id="involved-source-checkbox"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setConfirmedInternalTransfer(e.target.checked)}
            text="The involved sources have been imported into Cryptio"
            color="primary"
            checked={confirmedInternalTransfer}
          />
        </div>
        <div className={classes.containerSection}>
          <CheckLine
            id="same-entity-checkbox"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setIsInternal(e.target.checked)}
            text="This transaction is a transfer From/To an account belonging to the same company, not from another entity of the same group"
            color="primary"
            checked={isInternal}
          />
        </div>
      </Box>
      {counterParties.isError && <NetworkErrorMessage additionalData={counterParties} />}
      <SelectCounterParty
        setSelectedGuessedOtherParty={setSelectedGuessedOtherParty}
        selectedGuessedOtherParty={selectedGuessedOtherParty}
        counterParties={counterParties}
        confirmedInternalTransfer={confirmedInternalTransfer}
        isInternal={isInternal}
      />
    </Box>
  )
}

export default DisclamerInternalTransfer
