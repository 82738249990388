import React from "react"
import { useMatches, KBarResults, ActionId, ActionImpl } from "kbar"

const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl
      active: boolean
      currentRootActionId: ActionId
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors
      const index = action.ancestors.findIndex((ancestor) => ancestor.id === currentRootActionId)
      return action.ancestors.slice(index + 1)
    }, [action.ancestors, currentRootActionId])

    return (
      <div
        ref={ref}
        className={`flex items-center justify-between cursor-pointer py-3 px-4 ${
          active ? "border-l-2 pl-[12px] bg-grey-100" : "bg-label-transparent"
        } `}
      >
        <div className="flex gap-2 items-center text-sm">
          {action.icon && action.icon}
          <div className="flex flex-col">
            <div>
              {ancestors.length > 0 &&
                ancestors.map((ancestor) => (
                  <React.Fragment key={ancestor.id}>
                    <span className="opacity-50 mr-2">{ancestor.name}</span>
                    <span className="mr-2">&rsaquo;</span>
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && <span className="text-xs">{action.subtitle}</span>}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div aria-hidden className="grid col-auto	gap-1">
            {action.shortcut.map((sc) => (
              <kbd key={sc} className="py-1 px-2 bg-grey-100 rounded text-sm">
                {sc}
              </kbd>
            ))}
          </div>
        ) : null}
      </div>
    )
  },
)

function WrapperKBarResults() {
  const { results, rootActionId } = useMatches()

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === "string" ? (
          <div className="py-2 px-4 text-xs uppercase opacity-50">{item}</div>
        ) : (
          <ResultItem action={item} active={active} currentRootActionId={rootActionId ?? "0"} />
        )
      }
    />
  )
}

export default WrapperKBarResults
