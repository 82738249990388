import { Box, createStyles, makeStyles, MenuItem, SvgIconTypeMap, Theme, Typography } from "@material-ui/core"
import { OverridableComponent } from "@material-ui/core/OverridableComponent"
import {
  AccountCircleRounded,
  DescriptionRounded,
  LocalAtmRounded,
  MoneyRounded,
  PeopleRounded,
  AssignmentRounded,
} from "@material-ui/icons"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import relativeTime from "dayjs/plugin/relativeTime"
import React from "react"
import { NavLink } from "react-router-dom"

import BaseContainer from "components/Container"
import MainTitleView from "components/MainTitleView"
import { URLS } from "../../routes"

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

interface LinkWithIconProp {
  title: string
  url: string
  Icon: OverridableComponent<SvgIconTypeMap>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkTypography: {
      fontWeight: 400,
    },
    iconSpacer: {
      marginRight: theme.spacing(0.5),
    },
  }),
)

const LinkWithIcon = ({ title, url, Icon }: LinkWithIconProp) => {
  const classes = useStyles()
  return (
    <MenuItem component={NavLink} to={url}>
      <Icon className={classes.iconSpacer} />
      <Typography className={classes.linkTypography}>{title}</Typography>
    </MenuItem>
  )
}

const BackOfficeScene = (): JSX.Element => {
  return (
    <BaseContainer>
      <MainTitleView title="Back Office" />
      <Box mt={3}>
        <LinkWithIcon title="Manage users" url={URLS.BackOffice.User.basePath} Icon={AccountCircleRounded} />
        <LinkWithIcon title="Manage workspaces" url={URLS.BackOffice.Workspace.basePath} Icon={PeopleRounded} />
        <LinkWithIcon title="Manage assets" url={URLS.BackOffice.Asset} Icon={MoneyRounded} />
        <LinkWithIcon title="Manage subscription plans" url={URLS.BackOffice.Billing} Icon={LocalAtmRounded} />
        <LinkWithIcon
          title="Limitation almost reached"
          url={URLS.BackOffice.LimitationAlmostReached}
          Icon={DescriptionRounded}
        />
        <LinkWithIcon title="Manage jobs" url={URLS.BackOffice.Jobs} Icon={AssignmentRounded} />
        {/* <LinkWithIcon title="Accounting" url={URLS.BackOffice.Accounting} Icon={MoneyRounded} /> */}
      </Box>
    </BaseContainer>
  )
}

export default BackOfficeScene
