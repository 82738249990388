import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import GetAppIcon from "@material-ui/icons/GetApp"
import { Pagination } from "@material-ui/lab"
import dayjs from "dayjs"
import React, { useState } from "react"

import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import api from "services/api"
import { SubscriptionInvoice } from "services/api/openapi"
import TooltipUI from "CryptioUI/Tooltip"

const useStyles = makeStyles((_theme: Theme) => ({
  payButton: {},
}))

const InvoiceMiniView = (): JSX.Element => {
  const classes = useStyles()
  const [page, setPage] = useState<number>(1)
  const pageLimit = 5

  const invoices = api.billing.useInvoices(
    {
      limit: pageLimit,
      page: page,
      sortDirection: "descending",
    },
    {
      refetchInterval: 5000,
    },
  )

  if (invoices.isError) return <NetworkErrorMessage additionalData={invoices} />

  if (invoices.isLoading || invoices.data === undefined) return <LoadingSpinner />

  if (invoices.data.data.length === 0) return <Typography>No invoices yet</Typography>

  const downloadPdf = (invoice: SubscriptionInvoice) => {
    const link = document.createElement("a")
    link.download = `invoice.pdf`
    //@ts-ignore
    link.href = invoice.downloadLink
    link.click()
  }

  return (
    <>
      <List dense={true}>
        {invoices.data.data.map((invoice) => (
          <ListItem key={invoice.id}>
            <ListItemIcon style={{ minWidth: 0 }}>
              <Box mr={1}>
                <TooltipUI content={invoice.status ?? ""}>
                  {invoice.status === "paid" ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
                </TooltipUI>
              </Box>
            </ListItemIcon>
            <Typography variant="body2">{dayjs(invoice.invoiceDate).tz().format("LLL")}</Typography>
            <Box mr={2} />
            <Typography variant="body2">{`${invoice.planName} Plan${
              invoice.addonCount ? ` (${invoice.addonCount} addon)` : ""
            }`}</Typography>
            {invoice.paymentLink && invoice.status === "open" && (
              <>
                <Box mr={2} />
                <Button
                  variant="outlined"
                  component="a"
                  href={invoice.paymentLink}
                  className={classes.payButton}
                  target="_blank"
                >
                  Pay the invoice
                </Button>
              </>
            )}
            <ListItemSecondaryAction>
              <TooltipUI content="Download PDF">
                <IconButton edge="end" aria-label="download pdf" onClick={() => downloadPdf(invoice)}>
                  <GetAppIcon />
                </IconButton>
              </TooltipUI>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      {invoices.data.totalCount > pageLimit && (
        <Box display="flex" justifyContent="center">
          <Pagination
            color="primary"
            count={Math.ceil(invoices.data.totalCount / pageLimit)}
            page={page}
            onChange={(_, newPage) => setPage(newPage)}
          />
        </Box>
      )}
    </>
  )
}

export default InvoiceMiniView
