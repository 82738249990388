/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"

export interface StripeWebhookRequest {
  stripeSignature: string
}

/**
 *
 */
export class BillingStripeApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static stripeWebhook(params: StripeWebhookRequest) {
    return localBillingStripeApi.stripeWebhook(params)
  }

  /**
   * stripe webhook
   */
  stripeWebhook({}: StripeWebhookRequest): Promise<void> {
    return this.restInstance.post(`/billing/stripe/webhook`, undefined, true)
  }
}

const localBillingStripeApi = new BillingStripeApi()
