import { Box, Typography } from "@material-ui/core"
import dayjs from "dayjs"
import React from "react"
import _ from "lodash"

import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import AdministratorInformation from "components/misc/AdministratorInformation"
import { GetReportDto } from "services/api/openapi"
import { getHumanReadableReportStatus } from "../ReportStatus"

const ReportSummary = ({ report }: { report: GetReportDto }) => {
  return (
    <Box>
      <AdministratorInformation
        infos={[
          { id: report.id, extended: "Report" },
          { id: report.exportFormatId, extended: "Report Format" },
        ]}
      />
      <DrawerCategory title="Report summary">
        <DrawerSection name="Type">
          <Typography variant="body2">{report.typeDisplayName}</Typography>
        </DrawerSection>

        <DrawerSection name="Format">
          <Typography variant="body2">{report.formatDisplayName}</Typography>
        </DrawerSection>

        <DrawerSection name="Status">
          <Typography variant="body2">{getHumanReadableReportStatus(report.generationStatus, report.error)}</Typography>
        </DrawerSection>

        {(report.startDate || report.endDate) && (
          <DrawerSection name="Date">
            <Typography variant="body2">
              <>
                {report.startDate && <span>{dayjs(report.startDate).format("L")}</span>}
                {report.startDate && report.endDate && <span> - </span>}
                {report.endDate && <span>{dayjs(report.endDate).format("L")}</span>}
              </>
            </Typography>
          </DrawerSection>
        )}

        {report.assetNames.length > 0 && (
          <DrawerSection name="Assets">
            {report.assetNames.map((assetName) => (
              <Typography variant="body2" key={assetName}>
                {assetName}
              </Typography>
            ))}
          </DrawerSection>
        )}

        {report.walletNames.length > 0 && (
          <DrawerSection name="Wallets">
            {report.walletNames.map((walletName) => (
              <Typography variant="body2" key={walletName}>
                {walletName}
              </Typography>
            ))}
          </DrawerSection>
        )}

        {report.type === "transactions" && report.filters !== null && (
          <DrawerSection name="Filters">
            {report.filters.map((filter) => (
              <Typography variant="body2" key={filter.type}>
                {_.startCase(filter.type)}
              </Typography>
            ))}
          </DrawerSection>
        )}
      </DrawerCategory>
    </Box>
  )
}

export default ReportSummary
