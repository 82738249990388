import { useTypedController } from "@hookform/strictly-typed"
import { Box, Checkbox, FormLabel, Link, makeStyles, TextField, Theme, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"

import { AutocompleteCustomOption } from "components/Autocomplete/AutocompleteCustomOption"
import { positionNames, registrationFormId, RegistrationStepProps, RegistrationSteps } from "./state"
import { RequiredIndicator } from "./utils"

const useStyles = makeStyles((theme: Theme) => ({
  formInputField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))

type RegisterForm = RegistrationSteps[2]

export const Step2 = (props: RegistrationStepProps<2> & { firstName?: string; lastName?: string }) => {
  const classes = useStyles()
  const { onNext, onValid, firstName, lastName } = props
  const { control, handleSubmit, formState, setValue, errors, getValues, trigger } = useForm<RegisterForm>({
    mode: "onChange",
  })
  const TypedController = useTypedController<RegisterForm>({ control })

  useEffect(() => {
    if (firstName) {
      setValue("firstName", firstName)
      trigger("firstName")
    }
    if (lastName) {
      setValue("lastName", lastName)
      trigger("lastName")
    }
  }, [firstName, lastName, getValues, setValue, trigger])

  const onSubmit = (form: RegisterForm) => {
    onNext(form)
  }

  const formValid = formState.isValid
  useEffect(() => {
    onValid(formValid)
  }, [formValid, onValid])

  return (
    <form id={registrationFormId(2)} onSubmit={handleSubmit(onSubmit)}>
      <FormLabel htmlFor="first-name-textfield">
        First name <RequiredIndicator />
      </FormLabel>
      <TypedController
        name="firstName"
        defaultValue=""
        rules={{
          required: true,
        }}
        render={(props) => (
          <TextField id="first-name-textfield" className={classes.formInputField} fullWidth={true} {...props} />
        )}
      />

      <Box mt={1}>
        <FormLabel htmlFor="last-name-textfield">
          Last name <RequiredIndicator />
        </FormLabel>
        <TypedController
          name="lastName"
          defaultValue=""
          rules={{
            required: true,
          }}
          render={(props) => (
            <TextField id="last-name-textfield" className={classes.formInputField} fullWidth={true} {...props} />
          )}
        />
      </Box>

      <Box mt={1}>
        <FormLabel htmlFor="company-name-textfield">
          Company name <RequiredIndicator />
        </FormLabel>
        <TypedController
          name="companyName"
          defaultValue=""
          rules={{
            required: true,
          }}
          render={(props) => (
            <TextField id="company-name-textfield" className={classes.formInputField} fullWidth={true} {...props} />
          )}
        />
      </Box>

      <Box mt={1}>
        <FormLabel htmlFor="company-position-textfield">
          Company position <RequiredIndicator />
        </FormLabel>
        <TypedController
          name="companyPosition"
          rules={{
            required: true,
          }}
          // react-hook-form is a pain for that, the required rule prevents the form to be submitted when it is null
          // we should be able to set the default value as null, I think ?
          defaultValue={null as unknown as string}
          render={({ onChange, ref, ...rest }) => (
            <AutocompleteCustomOption
              openOnFocus
              id="company-position-textfield"
              options={positionNames}
              className={classes.formInputField}
              fullWidth={true}
              renderInput={(params) => <TextField {...params} />}
              onChange={(_, v) => v && onChange(v)}
              autoSelect={true}
              createCustomOption={(x) => x}
              customOptionLabel="Custom position"
              innerRef={ref}
              {...rest}
            />
          )}
        />
      </Box>

      <Box display="flex" alignItems="flex-start" flexDirection="row" mt={3}>
        <TypedController
          name="acceptTermsOfUse"
          rules={{
            required: true,
            validate: (value) => !!value,
          }}
          defaultValue={false}
          render={({ onChange, value, ...props }) => (
            <Checkbox
              id="tos-checkbox"
              checked={value}
              {...props}
              onChange={(_, newValue) => onChange(newValue)}
              color="primary"
              size="small"
              style={{
                padding: "2px",
                marginRight: "5px",
              }}
            />
          )}
        />

        <Typography component={FormLabel} htmlFor="tos-checkbox" variant="body1">
          I agree to Cryptio’s{" "}
          <Link target="_blank" rel="noopener noreferrer" href="https://cryptio.co/terms">
            <u>
              <b>Terms and Conditions</b>
            </u>
          </Link>{" "}
          and I want to create an account <RequiredIndicator />
        </Typography>
      </Box>

      {errors?.acceptTermsOfUse && (
        <Box display="flex" justifyContent="center">
          <Typography color="error" variant="body1">
            You must accept Terms and Conditions to register
          </Typography>
        </Box>
      )}
    </form>
  )
}
