import React from "react"

import DateRangePicker, { DateRange } from "./DateRangePicker"

interface Props {
  value: DateRange
  onDateRangeChange: (newValue: DateRange) => void
}

const DateRangeFilter = (props: Props): JSX.Element => {
  const handleOnChange = (dateRange: DateRange) => {
    if (dateRange.startDate?.isValid === false) {
      dateRange.startDate = undefined
    }
    if (dateRange.endDate?.isValid === false) {
      dateRange.endDate = undefined
    }
    props.onDateRangeChange(dateRange)
  }

  return <DateRangePicker onChange={handleOnChange} value={props.value} fullHistoryButtonText="Select full history" />
}

export default DateRangeFilter
