import React from "react"
import { twMerge } from "tailwind-merge"

import ButtonUI from "CryptioUI/Button"
import { Mode, TypographyVariant } from "CryptioUI/types"
import { CardImportProps } from "CryptioUI/types/cardImport"
import Typography from "CryptioUI/Typography"

const CardImportUI = (props: CardImportProps) => {
  const { onClick, label, disabled = false, className = "", src } = props
  const commonStyle =
    "flex flex-col justify-center items-center bg-white shadow-md border border-grey-200 w-full px-3 rounded-xl text-grey-500 text-md hover:bg-grey-100 focus:outline focus:outline-4 focus:outline-primary-100 active:bg-grey-300 active:enabled:text-white active:enabled:outline active:enabled:outline-4 active:enabled:outline-offset-0 h-fit"
  const genericDisabledStyle =
    "disabled:bg-grey-200 border border-grey-200 disabled:text-grey-500 striped disabled:grayscale"
  const finalStyle = twMerge([commonStyle, genericDisabledStyle, className].join(" "))

  return (
    <ButtonUI className={finalStyle} onClick={onClick} mode={Mode.CONTAINED} disabled={disabled}>
      <div className="flex justify-center items-center box-border h-16 w-full px-6">
        <img src={src} alt={label} className="max-h-16" />
      </div>
      <div className="bg-grey-200 h-px w-full" />
      <div className="flex justify-center items-center box-border w-full py-4 h-4">
        <Typography variant={TypographyVariant.CAPTION}>{label}</Typography>
      </div>
    </ButtonUI>
  )
}

export default CardImportUI
