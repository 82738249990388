import { useTypedController } from "@hookform/strictly-typed"
import { Box, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import dayjs from "dayjs"
import React, { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"

import WorkspaceAlgorithmSelect from "components/Autocomplete/WorkspaceDefaultAlgorithmSelect"
import { CurrencyField } from "components/CurrencyField"
import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import useDialog from "components/misc/useDialog"
import { DrawerProp } from "components/misc/useDrawer"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import api from "services/api"
import { CreateWorkspaceDto, GetCostBasisAlgorithmDtoNameEnum, GetWorkspaceDto } from "services/api/openapi/models"
import timezones from "services/timezones"
import ConditionalTooltip from "components/ConditionalTooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

type FormType = Omit<CreateWorkspaceDto, "costBasisAlgorithmName" | "isAlgorithmUniversal"> & {
  costBasisAlgorithm: GetCostBasisAlgorithmDtoNameEnum
}

const WorkspaceForm = ({
  item: workspace,
  isOpen,
  onClose,
  setFormDirty,
}: DrawerProp<GetWorkspaceDto, true>): JSX.Element => {
  const { handleSubmit, control, formState, reset, watch } = useForm<FormType>({ mode: "onChange" })
  const toast = useToast()
  const TypedController = useTypedController<FormType>({ control })

  const basicDialog = useDialog()

  const watchAllFields = watch()
  const isFormDirty =
    formState.isValid &&
    formState.isDirty &&
    (watchAllFields.name !== workspace?.name ||
      watchAllFields.description !== workspace?.description ||
      watchAllFields.defaultCurrencyId !== workspace?.defaultCurrencyId ||
      watchAllFields.timezone !== workspace.timezone ||
      watchAllFields.costBasisAlgorithm !== workspace.costBasisAlgorithmName)

  useEffect(() => setFormDirty(isFormDirty), [isFormDirty, setFormDirty])

  const isEditMode = !!workspace
  const isCreateMode = workspace === null

  const { mutateAsync: createWorkspaceMutation } = api.workspace.useCreateWorkspace()
  const { mutateAsync: updateWorkspaceMutation } = api.workspace.useUpdateWorkspace()
  const { mutateAsync: updateWorkspaceCurrencyMutation } = api.workspace.useUpdateWorkspaceCurrency()

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen, workspace, reset])

  const [SaveWorkspaceButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = useCallback(
    async ({ defaultCurrencyId, costBasisAlgorithm, ...form }: FormType) => {
      try {
        if (!defaultCurrencyId) {
          // Should never occur
          throw Error("Must have a default currency")
        }
        if (workspace) {
          if (workspace.defaultCurrencyId !== defaultCurrencyId) {
            await updateWorkspaceCurrencyMutation({
              updateWorkspaceCurrencyDto: {
                defaultCurrencyId: defaultCurrencyId,
              },
              workspaceId: workspace.id,
            })
          }
          if (
            form.name !== workspace.name ||
            form.description !== workspace.description ||
            form.timezone !== workspace.timezone ||
            costBasisAlgorithm !== workspace.costBasisAlgorithmName
          ) {
            await updateWorkspaceMutation({
              updateWorkspaceDto: {
                name: form.name,
                description: form.description || null,
                timezone: form.timezone,
                costBasisAlgorithmName: costBasisAlgorithm,
              },
              workspaceId: workspace.id,
            })
          }
          toast.open("Workspace updated", { variant: "success" })
        }
        if (isCreateMode) {
          await createWorkspaceMutation({
            createWorkspaceDto: {
              ...form,
              description: form.description || null,
              costBasisAlgorithmName: costBasisAlgorithm,
              defaultCurrencyId,
            },
          })
          toast.open("Workspace created", { variant: "success" })
        }
        onClose()
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [
      onClose,
      workspace,
      updateWorkspaceMutation,
      createWorkspaceMutation,
      toast,
      isCreateMode,
      updateWorkspaceCurrencyMutation,
    ],
  )

  return (
    <DrawerCategory
      component="form"
      onSubmit={handleButtonCallback(handleSubmit(onSubmit))}
      title={isCreateMode ? "Create Workspace" : "Workspace Edition"}
    >
      {basicDialog.dialog}

      <DrawerFormSection htmlFor="name-textfield" name="Name">
        <TypedController
          name="name"
          defaultValue={workspace?.name ?? ""}
          rules={{ required: true, minLength: 1, maxLength: 256 }}
          render={(props) => (
            <PermissionDisabled
              placement={"right"}
              permission="can_modify_workspace"
              action="edit workspace settings"
              byPass={isCreateMode}
            >
              <TextField id="name-textfield" {...props} placeholder="Name" fullWidth />
            </PermissionDisabled>
          )}
        />
      </DrawerFormSection>

      <DrawerFormSection htmlFor="description-textfield" name="Description">
        <TypedController
          name="description"
          defaultValue={workspace?.description ?? ""}
          render={(props) => (
            <PermissionDisabled
              placement={"right"}
              permission="can_modify_workspace"
              action="edit workspace settings"
              byPass={isCreateMode}
            >
              <TextField id="description-textfield" {...props} placeholder="Description" fullWidth />
            </PermissionDisabled>
          )}
        />
      </DrawerFormSection>

      <DrawerFormSection htmlFor="currency-select" name="Currency">
        <TypedController
          name="defaultCurrencyId"
          defaultValue={workspace?.defaultCurrencyId}
          rules={{
            required: true,
          }}
          render={(props) => (
            <PermissionDisabled
              placement={"right"}
              permission="can_modify_workspace_currency"
              action="edit workspace currency"
              byPass={isCreateMode}
            >
              <ConditionalTooltip
                tooltipMessage={"The currency of a locked workspace can't be edited"}
                disabled={workspace?.lock.isEnabled ?? false}
              >
                <CurrencyField id="currency-select" {...props} disabled={workspace?.lock.isEnabled} />
              </ConditionalTooltip>
            </PermissionDisabled>
          )}
        />
      </DrawerFormSection>

      <DrawerFormSection htmlFor="timezone-select" name="Timezone">
        <TypedController
          name="timezone"
          defaultValue={workspace?.timezone ?? dayjs.tz.guess()}
          rules={{ required: true }}
          render={({ onChange, ...rest }) => (
            <PermissionDisabled
              placement={"right"}
              permission="can_modify_workspace"
              action="edit workspace settings"
              byPass={isCreateMode}
            >
              <Autocomplete
                {...rest}
                id="timezone-select"
                disableClearable
                onChange={(_, newValue) => onChange(newValue)}
                options={timezones}
                renderInput={(params) => <TextField {...params} placeholder="Select a timezone" />}
                size="small"
              />
            </PermissionDisabled>
          )}
        />
      </DrawerFormSection>

      <DrawerFormSection htmlFor="algorithm-select" name="Cost basis methodology">
        <TypedController
          name="costBasisAlgorithm"
          defaultValue={workspace?.costBasisAlgorithmName ?? "fifo-per-wallet"}
          rules={{ required: true }}
          render={({ ref: _ref, ...rest }) => (
            <WorkspaceAlgorithmSelect
              id="algorithm-select"
              workspaceId={workspace?.id}
              isWorkspaceLockEnabled={workspace?.lock.isEnabled}
              {...rest}
            />
          )}
        />
        {/* TODO: add link */}
        {/* {(watchAllFields.algorithm?.name ?? workspace?.defaultAlgorithm) === "fifo" && (
          <Box mt={1}>
            <Typography variant="body1">
              <ExternalLink href="" underline="always">
                Learn more
              </ExternalLink>{" "}
              on Universal vs Per Wallet application for FIFO
            </Typography>
          </Box>
        )} */}
      </DrawerFormSection>

      <Box mt={2} display="flex" justifyContent="space-between">
        <SaveWorkspaceButton disabled={!isFormDirty} type="submit">
          {isEditMode && "Save"}
          {isCreateMode && "Create"}
        </SaveWorkspaceButton>
      </Box>
    </DrawerCategory>
  )
}

export default WorkspaceForm
