import { Box, Typography } from "@material-ui/core"
import { isInteger } from "pure-shared"
import React, { useMemo, useState } from "react"

import CopyableText from "components/misc/copyableText"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDrawer from "components/misc/useDrawer"
import { GenericSorting, IExtendedDataTableColumn } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import api from "services/api"
import {
  BackOfficeGetMinimalWorkspaceDto,
  BackOfficeGetWorkspacesLimitationTypeEnum,
  BackOfficeGetWorkspacesSortByEnum,
} from "services/api/openapi"
import LimitationTable, { LimitationUsageType } from "./LimitationTable"

type LimitationTableProps = {
  limitationType: BackOfficeGetWorkspacesLimitationTypeEnum
  limitationIdentifier: string
  limitationColumn: string
  // usageType: "count" | "percent"
  defaultMaxRemainingUsage: number
}
const LimitationAlmostReachedTable = (props: LimitationTableProps) => {
  const [usageType, setUsageType] = useState<LimitationUsageType>("count")
  const [maxRemainingUsage, setMaxRemainingUsage] = useState(props.defaultMaxRemainingUsage.toString())

  const { limitationIdentifier, limitationType, limitationColumn } = props
  const [workspaceDrawer, openWorkspaceDrawer] = useDrawer("backoffice-workspace")

  const [page, setPage] = useState(1)
  const [sorting, setSorting] = useState<GenericSorting<BackOfficeGetWorkspacesSortByEnum>>({
    sortBy: "remaining_limitation",
    sortDirection: "ascending",
  })

  const isQueryValid = isInteger(maxRemainingUsage)

  const workspaces = api.backOffice.workspace.useWorkspaces(
    {
      page,
      ...sorting,
      limit: 5,
      maxRemainingUsageCount: usageType === "count" ? Number(maxRemainingUsage) : undefined,
      maxRemainingUsagePercent: usageType === "percent" ? Number(maxRemainingUsage) : undefined,
      limitationType: limitationType,
      // freeTrialMaxDaysRemaining: params.freeTrialMaxDaysRemaining,
    },
    { enabled: isQueryValid },
  )

  const limitationTableColumns = useMemo<
    IExtendedDataTableColumn<BackOfficeGetMinimalWorkspaceDto, BackOfficeGetWorkspacesSortByEnum>[]
  >(
    () => [
      {
        name: `${limitationColumn} left`,
        selector: "remainingLimitation",
        sortable: true,
        sortName: "remaining_limitation",
      },
      {
        name: "Workspace name",
        selector: "name",
      },
      {
        name: "Workspace ID",
        selector: "id",
        format: function format(row) {
          return <CopyableText>{row.id}</CopyableText>
        },
      },
    ],
    [limitationColumn],
  )

  if (workspaces.isError) {
    return (
      <LimitationTable title={`${limitationIdentifier} limitation almost reached`}>
        <NetworkErrorMessage additionalData={workspaces} />
      </LimitationTable>
    )
  }

  if (workspaces.isLoading || workspaces.data === undefined) {
    return (
      <LimitationTable title={`${limitationIdentifier} limitation almost reached`}>
        <LoadingSpinner />
      </LimitationTable>
    )
  }

  if (!isQueryValid) {
    return (
      <LimitationTable
        title={`${limitationIdentifier} limitation almost reached`}
        filterName={limitationColumn}
        usageType={usageType}
        setUsageType={setUsageType}
        maxRemainingUsage={maxRemainingUsage}
        setMaxRemainingUsage={setMaxRemainingUsage}
      >
        <Box display="flex" alignItems="center" justifyContent="center" p={3}>
          <Typography>Invalid query</Typography>
        </Box>
      </LimitationTable>
    )
  }

  return (
    <LimitationTable
      title={`${limitationIdentifier} limitation almost reached`}
      filterName={limitationColumn}
      usageType={usageType}
      setUsageType={setUsageType}
      maxRemainingUsage={maxRemainingUsage}
      setMaxRemainingUsage={setMaxRemainingUsage}
    >
      {workspaceDrawer}
      <ServerSideTable<BackOfficeGetMinimalWorkspaceDto, BackOfficeGetWorkspacesSortByEnum>
        columns={limitationTableColumns}
        items={workspaces.data.data}
        limit={5}
        paginationRowsPerPageOptions={[]}
        totalCount={workspaces.data.totalCount}
        defaultParams={{
          ...sorting,
          page,
        }}
        onPageChange={setPage}
        onSort={setSorting}
        onRowClicked={(row) => openWorkspaceDrawer(row.id)}
        dense
      />
    </LimitationTable>
  )
}

export default LimitationAlmostReachedTable
