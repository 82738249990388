function download(url: string, filename?: string) {
  const element = document.createElement("a")
  element.setAttribute("href", url)
  if (filename) {
    element.setAttribute("download", filename)
  }

  element.style.display = "none"
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export default download
