import React, { useMemo } from "react"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"

import {
  GetLabelDto,
  GetLabelsRequest,
  MovementLabelDto,
  NewFullTransactionDto,
  NewInListMovementDto,
} from "services/api/openapi"
import { WithoutWorkspaceId } from "services/api/aliases"
import api from "services/api"
import CustomSelector from "components/selector/CustomSelector"
import LabelOptionInAutocomplete from "components/AutoCompleteOptions/LabelOptionInAutocomplete"

interface NonTaxabelLabelSelectorProps {
  transaction: NewFullTransactionDto
  setNewLabelName: (newLabelName: string | null) => void
  nonTaxablelabelsToAdd: GetLabelDto[]
  setNonTaxableLabelsToAdd: (newValue: GetLabelDto[]) => void
  isInternalTransfer: boolean
  isNonTaxableEvent: boolean
  mainMvt: NewInListMovementDto | undefined
  isLockedTransaction: boolean
}

export const mockInternalTransferLabel: GetLabelDto = {
  // transactionCount: 0,
  isGlobal: false,
  from: null,
  to: null,
  filter: null,
  id: "InternalTransfer",
  name: "Internal Transfer",
  color: "#93024D",
  movementCount: 0,
  isUpToDate: false,
  isNonTaxable: false,
  status: "inactive",
  isAppliedInLockedPeriod: false,
  isRuleBroken: false,
}

// No better solution for now... :/
export const mockInternalTransferLabelMvt: MovementLabelDto = {
  labelId: "no id for internal transfer",
  name: "Internal Transfer",
  color: "#93024D",
  isGlobal: false,
  isNonTaxable: false,
  isAutomatic: false,
}

const NonTaxabelLabelSelector = (props: NonTaxabelLabelSelectorProps) => {
  const {
    transaction,
    setNewLabelName,
    nonTaxablelabelsToAdd,
    setNonTaxableLabelsToAdd,
    isInternalTransfer,
    isNonTaxableEvent,
    mainMvt,
    isLockedTransaction,
  } = props

  const isAlreadyNonTaxableEvent = useMemo(() => {
    const isFound = transaction.movements.find((mvt) => mvt.labels.find((label) => label.name === "Non taxable event"))

    return isFound !== undefined ? true : false
  }, [transaction.movements])

  const checkIsInternalTransfer = useMemo(
    () => transaction.movements.find((mvt) => mvt.isInternalTransfer),
    [transaction.movements],
  )

  const optionsNonTaxableLabel = useMemo((): GetLabelDto[] => {
    const labels: GetLabelDto[] = [mockInternalTransferLabel]

    if (checkIsInternalTransfer || isAlreadyNonTaxableEvent || isInternalTransfer || isNonTaxableEvent) {
      return []
    }
    return labels
  }, [isAlreadyNonTaxableEvent, isInternalTransfer, isNonTaxableEvent, checkIsInternalTransfer])

  const uniqueLabelIds = useMemo<string[]>(() => {
    const labels = new Array(...nonTaxablelabelsToAdd)
    const index = labels.findIndex((label) => label.name === mockInternalTransferLabel.name)

    labels.splice(index, 1) // Avoid network error, don't have better solution for now

    const labelsIdArray = Array.from(
      new Set(nonTaxablelabelsToAdd.map((x) => x.id).concat(mainMvt?.labels.map((x) => x.labelId) ?? [])),
    )
    if (labelsIdArray.includes(mockInternalTransferLabel.id)) {
      return []
    }
    return labelsIdArray
  }, [nonTaxablelabelsToAdd, mainMvt?.labels])

  const customProcessOption = (data: (string | GetLabelDto)[]) => {
    if (isNonTaxableEvent || isInternalTransfer || checkIsInternalTransfer) {
      const index = data.findIndex((label) => {
        if (typeof label === "string") {
          return false
        }
        return label.name === "Non taxable event"
      })
      data.splice(index, 1)
    }
    return data
  }

  return (
    <CustomSelector<WithoutWorkspaceId<GetLabelsRequest>["getLabelQuery"], GetLabelDto | string, true, undefined, true>
      getOptionLabel={(option) => {
        if (typeof option === "string") return option
        option.isNonTaxable = false
        return (option as GetLabelDto).name
      }}
      defaultPaginatedQueryProps={{
        // sortBy: "transaction_count",
        excludedIds: uniqueLabelIds,
        nonTaxableType: "non_taxable_only",
      }}
      usePaginatedQuery={api.label.useLabels}
      size="small"
      label="Select labels"
      autoComplete
      multiple
      additionalsOption={optionsNonTaxableLabel}
      includeInputInList
      disabled={isLockedTransaction}
      filterSelectedOptions
      disableCloseOnSelect
      getOptionSelected={() => false}
      customProcessOption={customProcessOption}
      // TODO: check if string => false // or id === id
      // getOptionSelected={(option, value) => option.id === value.id}
      // Limit ?
      getOptionDisabled={nonTaxablelabelsToAdd.length >= 10 ? () => true : () => false}
      value={nonTaxablelabelsToAdd}
      onChange={(_event, newValue) => {
        if (newValue.length > 0 && typeof newValue[newValue.length - 1] === "string") {
          // timeout to avoid instant validation of the dialog's form.
          setTimeout(() => {
            setNewLabelName(newValue[newValue.length - 1] as string)
          })
        } else {
          setNonTaxableLabelsToAdd(newValue as GetLabelDto[])
        }
      }}
      renderOption={(option) => {
        if (typeof option === "string") return `${option}`
        else return <LabelOptionInAutocomplete label={option} />
      }}
      filterOptions={(options, params) => {
        const filter = createFilterOptions<GetLabelDto>()
        const filtered = filter(options as GetLabelDto[], params) as (GetLabelDto | string)[]

        if (params.inputValue !== "") filtered.push(params.inputValue)

        return filtered
      }}
    />
  )
}

export default NonTaxabelLabelSelector
