import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import React from "react"
import { LightGrey } from "../../../materialTheme"
import { ConfigTable } from "./useConfigTable"

interface ContainerColumnProps {
  children: React.ReactNode
  isBorderRight?: boolean
  columnName: string
  columnsConfig: ConfigTable[]
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "64px",
    minWidth: ({ minWidth }: any) => minWidth ?? "auto",
    maxWidth: ({ maxWidth }: any) => maxWidth ?? "auto",
    width: "100%",
    marginLeft: "1rem",
  },
  developped: {
    border: "none",
    borderRight: "solid",
    borderWidth: "1px",
    borderRightColor: LightGrey,
  },
}))

export enum ColumnsName {
  CHECKBOX = "CheckBox",
  DATE = "Date",
  FROMTO = "From/To",
  INCOMING = "Incoming",
  OUTGOING = "Outgoing",
  FEE = "Fee",
  COSTBASIS = "Cost basis",
  GAINSLOSSES = "Gains/Losses",
  LABEL = "Label",
  SYNC = "Sync",
}

const ContainerColumn = (props: ContainerColumnProps) => {
  const { children, isBorderRight = false, columnName, columnsConfig } = props
  const column = columnsConfig.find((columnConfig) => (columnConfig.name === columnName ? columnConfig : undefined))
  const classes = useStyles({ minWidth: column?.minWidth, maxWidth: column?.maxWidth })

  const cellStyle = () => {
    if (isBorderRight && columnName === ColumnsName.CHECKBOX) {
      return clsx(classes.container, classes.developped)
    }
    return classes.container
  }

  return (
    <div className={cellStyle()} data-tag="allowRowEvents" role="gridcell">
      {children}
    </div>
  )
}

export default ContainerColumn
