import { Box, createStyles, Divider, makeStyles, Theme, Typography } from "@material-ui/core"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React from "react"

import {
  GetFeatureAddonDto,
  GetPlanDto,
  GetWorkspaceUsageLimitDto,
  SubscriptionPayingDetailsPeriodTypeEnum,
} from "services/api/openapi"
import LimitAddonDetailedViewer from "./limitAddonDetailedView"
import PlanView from "./PlanView"
import { ExtendedLimitAddon } from "./PricingCalculator"

interface Props {
  plan: GetPlanDto
  limitAddons?: ExtendedLimitAddon[]
  featureAddons?: GetFeatureAddonDto[]
  planInAccordion?: boolean
  currentWorkspaceUsage?: Array<GetWorkspaceUsageLimitDto>
  showOnlyPeriodPrice?: SubscriptionPayingDetailsPeriodTypeEnum
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      color: theme.palette.text.secondary,
    },
  }),
)

const PlanWithAddonView = ({
  plan,
  featureAddons,
  limitAddons,
  currentWorkspaceUsage,
  showOnlyPeriodPrice,
}: Props): JSX.Element => {
  const classes = useStyles()

  const [expanded, setExpanded] = React.useState<boolean>(false)

  const handleChange = () => setExpanded((isExpended) => !isExpended)

  return (
    <>
      <Accordion key={plan.id} expanded={expanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography className={classes.secondaryHeading}>Click to expand</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column">
            <PlanView
              plan={plan}
              currentWorkspaceUsage={currentWorkspaceUsage}
              showOnlyPeriodPrice={showOnlyPeriodPrice}
            />
            {((limitAddons && limitAddons.length > 0) || (featureAddons && featureAddons.length > 0)) && (
              <Box mt={2}>
                <Typography variant="h4" gutterBottom>
                  Add-ons
                </Typography>
                <Box mb={2} className={classes.root}>
                  {limitAddons &&
                    limitAddons.map((addon, idx) => (
                      <>
                        <Box my={2}>
                          <LimitAddonDetailedViewer
                            key={addon.id}
                            addon={addon}
                            showOnlyPeriodPrice={showOnlyPeriodPrice}
                          />
                        </Box>
                        {idx !== limitAddons.length - 1 && <Divider />}
                      </>
                    ))}

                  {/*featureAddons &&
              featureAddons.map((addon) => (
                    <FeatureAddonDetailedViewer
                      key={addon.id}
                      addon={addon}
                      showOnlyPeriodPrice={showOnlyPeriodPrice}
                    />
              ))*/}
                </Box>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default PlanWithAddonView
