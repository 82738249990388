import { Dialog, Transition } from "@headlessui/react"
import React, { Fragment, useRef } from "react"
import { twMerge } from "tailwind-merge"

import { WrapperModalProps } from "CryptioUI/types"

const WrapperModalUI = ({
  isOpen,
  onClose,
  children,
  delayDurationIn,
  delayDurationOut,
  className,
}: WrapperModalProps): JSX.Element => {
  const completeButtonRef = useRef(null)

  const basicStyle =
    "inline-block p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl "
  const style = twMerge([basicStyle, className].join(" "))

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog initialFocus={completeButtonRef} className="fixed inset-0 z-[1201] overflow-y-auto" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-500"
          enterFrom="opacity-0 scale-80"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-grey-400 opacity-50" />
        </Transition.Child>
        <div className="min-h-screen px-4 text-center">
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter={`ease-out ${delayDurationIn}`}
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave={`ease-in ${delayDurationOut}`}
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className={style}>{children}</div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default WrapperModalUI
