import { UseQueryOptions } from "react-query"

import { queryClient } from "../../.."
import {
  usePaginatedWorkspaceQuery,
  useWorkspaceMutation,
  useWorkspaceQuery,
  WorkspaceContextValue,
} from "../../context/workspaceContext"
import { WithoutWorkspaceId } from "../aliases"
import rest from "../http"
import {
  AddCsvToWalletRequest,
  GetErrorCsvSignedUrl,
  GetTypesRequest,
  GetWalletDto,
  GetWalletsRequest,
  PaginatedWalletResultDto,
  WalletsApi,
} from "../openapi"
import { GET_CHART_ACCOUNT_MAPPING_KEY, GET_CHART_ACCOUNT_MAPPINGS_KEY } from "./chartAccount"
import { GET_LABEL_KEY, GET_LABELS_KEY } from "./label"
import { GET_TRANSACTION_KEY, GET_TRANSACTIONS_KEY } from "./transactions"
import { GET_WORKSPACE_METRICS_KEY, GET_WORKSPACES_OR_DEFAULT_KEY } from "./workspace"
import { GET_FIREBLOCKS } from "./integrations"

export const GET_WALLETS_KEY = "getWallets"
export const GET_WALLETS_SOURCE_KEY = "getWalletsSources"
export const GET_WALLET_KEY = "getWallet"
export const GET_SOURCES_KEY = "getSources"

type AddCsvToWalletRequestWithFile = AddCsvToWalletRequest & { file: File }
const internalAddCsvToWallet = async ({
  file,
  walletId,
  workspaceId,
}: AddCsvToWalletRequestWithFile): Promise<GetWalletDto> => {
  const formData = new FormData()
  formData.append("file", file, file.name)

  // I think we can safely ignore the result here. The import might still trigger an error later on but the
  // upload itself should be considered a success.
  return rest.uploadFile<GetWalletDto>(`/workspaces/${workspaceId}/wallets/custom/${walletId}`, "POST", formData, true)
}

export const walletActions = {
  useWalletsSources: (
    variables: WithoutWorkspaceId<GetWalletsRequest>,
    config?: UseQueryOptions<PaginatedWalletResultDto>,
  ) => {
    return usePaginatedWorkspaceQuery([GET_WALLETS_SOURCE_KEY, variables], WalletsApi.getWallets, variables, config)
  },

  useWallets: (
    variables: WithoutWorkspaceId<GetWalletsRequest>,
    config?: UseQueryOptions<PaginatedWalletResultDto>,
  ) => {
    return usePaginatedWorkspaceQuery([GET_WALLETS_KEY, variables], WalletsApi.getWallets, variables, config)
  },
  useWallet: (walletId: string | undefined | null, option?: UseQueryOptions<GetWalletDto>) => {
    return useWorkspaceQuery(
      [GET_WALLET_KEY, walletId],
      WalletsApi.getWallet,
      { walletId: walletId ?? "" },
      {
        enabled: !!walletId,
        ...option,
      },
    )
  },
  useTypes: (variables: WithoutWorkspaceId<GetTypesRequest>) => {
    return useWorkspaceQuery([GET_SOURCES_KEY, variables], WalletsApi.getTypes, variables)
  },
  useRefreshAllSources: () => {
    return useWorkspaceMutation(WalletsApi.refreshAllWallets, {
      onSuccess: () => {
        // update status
        queryClient.invalidateQueries(GET_WALLETS_KEY)
        queryClient.invalidateQueries(GET_WALLET_KEY)

        // TODO: maybe notify workspace and see a global loading ?
      },
    })
  },
  useRefreshSources: () => {
    return useWorkspaceMutation(WalletsApi.refreshWallets, {
      onSuccess: () => {
        // Update status
        queryClient.invalidateQueries(GET_WALLETS_KEY)
        queryClient.invalidateQueries(GET_WALLET_KEY)
      },
    })
  },
  useCreateBatchWallets: () => {
    return useWorkspaceMutation(WalletsApi.createBatchWallets, {
      onSuccess: () => {
        // More wallet
        queryClient.invalidateQueries(GET_WALLETS_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
        queryClient.invalidateQueries(GET_SOURCES_KEY)
        queryClient.invalidateQueries(GET_FIREBLOCKS)
      },
    })
  },

  useAddCsvToWallet: () => {
    return useWorkspaceMutation(/* WalletsApi.createCustomWallet */ internalAddCsvToWallet, {
      onSuccess: () => {
        // More wallet
        queryClient.invalidateQueries(GET_WALLETS_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
        queryClient.invalidateQueries(GET_SOURCES_KEY)
      },
    })
  },

  useUpdateWallet: () => {
    return useWorkspaceMutation(WalletsApi.updateWallet, {
      onSuccess: () => {
        // Update wallet
        queryClient.invalidateQueries(GET_WALLETS_KEY)
        queryClient.invalidateQueries(GET_WALLET_KEY)

        // Update From/To
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)

        // Update From/To
        queryClient.invalidateQueries(GET_LABELS_KEY)
        queryClient.invalidateQueries(GET_LABEL_KEY)

        // Update mapping
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPINGS_KEY)
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPING_KEY)
      },
    })
  },
  useUpdateWalletCredentials: () => {
    return useWorkspaceMutation(WalletsApi.updateWalletCredential, {
      onSuccess: () => {
        // Update wallet
        queryClient.invalidateQueries(GET_WALLETS_KEY)
        queryClient.invalidateQueries(GET_WALLET_KEY)
      },
    })
  },
  useDeleteWallets: () => {
    return useWorkspaceMutation(WalletsApi.deleteWallets, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
        // Remove a wallet
        queryClient.invalidateQueries(GET_WALLETS_KEY)
        queryClient.invalidateQueries(GET_WALLET_KEY)
        queryClient.invalidateQueries(GET_SOURCES_KEY)

        // Delete transactions
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)

        // Update label transaction count / From/To
        queryClient.invalidateQueries(GET_LABELS_KEY)
        queryClient.invalidateQueries(GET_LABEL_KEY)

        // Restart Cost basis / make COA dirty
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)

        // May delete a mapping
        queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPINGS_KEY)
      },
    })
  },
  getErrorCsvSignedUrl: (workspaceCtx: WorkspaceContextValue, walletId: string): Promise<GetErrorCsvSignedUrl> => {
    return rest.get<GetErrorCsvSignedUrl>(`/workspaces/${workspaceCtx.workspace.id}/wallets/${walletId}/errorCsv`)
  },
  useValuationUpdate: () => {
    return useWorkspaceMutation(WalletsApi.valuationUpdate, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useWorkspaceValuationUpdate: () => {
    return useWorkspaceMutation(WalletsApi.workspaceValuationUpdate, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useToggleAutoFlagBorrow: () => {
    return useWorkspaceMutation(WalletsApi.backOfficeToggleAutoFlagBorrow, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WALLET_KEY)
      },
    })
  },
  useUpdateAutoFlagBorrow: () => {
    return useWorkspaceMutation(WalletsApi.backOfficeRunAutoFlagBorrow, {
      onSuccess: () => {},
    })
  },
  useReplaceCSVWithError: () => {
    return useWorkspaceMutation(WalletsApi.replaceErrorCSV, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WALLETS_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
        queryClient.invalidateQueries(GET_SOURCES_KEY)
      },
    })
  },
}
