export const pluralize = (isPlural: boolean, text: string, pluralSuffix = "s"): string =>
  text + (isPlural ? pluralSuffix : "")

export const camelCaseToTitleCase = (name: string) =>
  name
    .replace(/(_|-)/g, " ")
    .trim()
    .replace(/\w\S*/g, function (str) {
      return str.charAt(0).toUpperCase() + str.substr(1)
    })
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")

export function EllipsisText(str: string, maxChar: number) {
  if (str.length > 10) {
    return str.substr(0, maxChar / 2) + "..." + str.substr(str.length - maxChar / 2, str.length)
  }
  return str
}

export function pickTextColorBasedOnBgColorSimple(bgColor: string, lightColor: string, darkColor: string) {
  var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor
  var r = Number("0x" + color.substring(0, 2)) // hexToR
  var g = Number("0x" + color.substring(2, 4)) // hexToG
  var b = Number("0x" + color.substring(4, 6)) // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor
}

export const cropName = (name: string | null, maxNbOfChar: number) => {
  if (!name) return null
  if (name.length > maxNbOfChar) return name.slice(0, maxNbOfChar) + "..."
  return name
}
