import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core"
import { ReactComponent as Delete } from "CryptioUI/assets/icons/delete.svg"
import { ReactComponent as Warning } from "CryptioUI/assets/icons/warning.svg"
import React, { useContext, useMemo } from "react"

import { LabelCircleColor } from "components/Labels/LabelCircleColor"
import LoadingSpinner from "components/misc/LoadingSpinner"
import useDialog from "components/misc/useDialog"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import ServerSideTable from "components/Table/ServerSideTable"
import api from "services/api"
import {
  FullMovementDto,
  MovementCounterPartyDtoDirectionEnum,
  NewFullTransactionDto,
  NewInListMovementDto,
} from "services/api/openapi"
import { WorkspaceContext } from "../../../services/context/workspaceContext"
import { isLockedTransaction } from "../../../services/utils/isLocked"
import { mockInternalTransferLabel } from "./NonTaxabelLabelSelector"
import useCounterPartyTableColumn from "./useCounterPartyTableColumn"
import { correspondanceHex, newColorHex } from "CryptioUI/types/tag"
import useDrawer from "../../misc/useDrawer"
import { iconStyleBlack } from "CryptioUI/Utilities/config"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface ShowLabelAlreadyActivatedProps {
  transaction: NewFullTransactionDto
  mainMvt: NewInListMovementDto | undefined
}

const useStyles = makeStyles((theme: Theme) => ({
  typographyWarning: {
    fontWeight: 800,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  iconWarning: {
    marginRight: theme.spacing(4.5),
    marginBottom: theme.spacing(0.4),
  },
  container: {
    display: "flex",
    overflowX: "scroll",
    width: "100%",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.12)",
    borderRadius: "2px",
    minHeight: "fit-content",
    height: "fit-content",
  },
}))

const ShowLabelAlreadyActivated = (props: ShowLabelAlreadyActivatedProps) => {
  const toast = useToast()
  const { transaction, mainMvt } = props
  const basicDialog = useDialog()
  const classes = useStyles()
  const { mutateAsync: bulkUpdateMovementsMutation } = api.transaction.useBulkUpdateMovements()
  const { workspace } = useContext(WorkspaceContext)

  const isLocked = isLockedTransaction(
    workspace.lock.isEnabled,
    transaction.transactionDate,
    workspace.lock.exclusiveEndDate,
  )

  const counterParty = api.transaction.useMovement({ movementId: mainMvt?.internalTransferCounterpartyId ?? "" })
  const { mutateAsync: bulkUpdateMovementMutation } = api.transaction.useBulkUpdateMovements()

  const doRemoveLabel = async (labelId: string) => {
    try {
      await bulkUpdateMovementsMutation({
        movementBulkUpdateDto: {
          labelIdsToRemove: [labelId],
          individualTransactions: {
            transactionIds: [transaction.id],
          },
        },
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const doRemoveInternalTransfer = async () => {
    try {
      await bulkUpdateMovementMutation({
        movementBulkUpdateDto: {
          removeInternalTransfer: true,
          individualTransactions: {
            transactionIds: [transaction.id],
          },
        },
      })
      toast.open("Internal transfer removed", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const askRemoveInternalTransfer = () => {
    return basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to remove the label internal transfer? It will automatically delete the counterparty if it
          exists
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doRemoveInternalTransfer,
    })
  }

  const [walletDrawer, openWalletDrawer] = useDrawer("wallet")
  const columns = useCounterPartyTableColumn({ openWalletDrawer })
  const [movementDrawer, openMovementDrawer] = useDrawer("movement")

  const serverSideTable = useMemo(() => {
    if (counterParty.data === undefined) {
      return <></>
    }

    return (
      <Paper>
        {movementDrawer}
        <ServerSideTable<FullMovementDto, MovementCounterPartyDtoDirectionEnum>
          columns={columns}
          items={[counterParty.data] || []}
          totalCount={1}
          pagination={false}
          onRowClicked={() => openMovementDrawer({ movementId: counterParty.data.id })}
        />
      </Paper>
    )
  }, [counterParty, columns, movementDrawer, openMovementDrawer])

  if (mainMvt?.internalTransferCounterpartyId && (counterParty.isLoading || counterParty.data === undefined)) {
    return (
      <Box mt={2}>
        <LoadingSpinner />
      </Box>
    )
  }

  const labels = transaction.movements.map((mvt) => mvt.labels).flat(1)

  const uniqLabels = labels.filter((value, index, self) => index === self.findIndex((t) => t.labelId === value.labelId))

  return (
    <Box mt={2}>
      {walletDrawer}
      {basicDialog.dialog}
      <Paper>
        {uniqLabels.length > 0 || mainMvt?.isInternalTransfer ? (
          <List>
            {uniqLabels.map((label) => (
              <ListItem key={label.labelId}>
                <ListItemAvatar>
                  <>
                    {newColorHex[correspondanceHex[label.color]] && (
                      <LabelCircleColor
                        color={newColorHex[correspondanceHex[label.color]]}
                        type={label.isAutomatic ? "automatic" : "manual"}
                        size={32}
                      />
                    )}
                  </>
                </ListItemAvatar>
                <ListItemText primary={label.name} data-test-id="label-name-drawer" />
                <ListItemSecondaryAction>
                  <PermissionDisabled
                    permission="can_modify_transaction"
                    action="modify a transaction"
                    titlePermissionEnable={
                      isLocked ? "This label can't be deleted because this transaction is locked" : "Delete"
                    }
                  >
                    <IconButton
                      onClick={() => doRemoveLabel(label.labelId)}
                      edge="end"
                      aria-label="delete"
                      disabled={isLocked}
                    >
                      <Delete className={iconStyleBlack} />
                    </IconButton>
                  </PermissionDisabled>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            {mainMvt?.isInternalTransfer && (
              <ListItem>
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <ListItemAvatar>
                      <>
                        {mockInternalTransferLabel.color && (
                          <LabelCircleColor color={mockInternalTransferLabel.color} />
                        )}
                      </>
                    </ListItemAvatar>
                    <ListItemText primary={mockInternalTransferLabel.name} data-test-id="label-name-drawer" />
                    <div>
                      <PermissionDisabled
                        permission="can_modify_transaction"
                        action="modify a transaction"
                        titlePermissionEnable={
                          isLocked ? "This label can't be deleted because this transaction is locked" : "Modify"
                        }
                      >
                        <IconButton
                          disabled={(mainMvt?.isInternalTransferSystemAttributed ?? false) || isLocked}
                          onClick={askRemoveInternalTransfer}
                          edge="end"
                          aria-label="delete"
                        >
                          <Delete className={iconStyleBlack} />
                        </IconButton>
                      </PermissionDisabled>
                    </div>
                  </div>
                  {counterParty.data ? (
                    <>
                      <Typography className={classes.typographyWarning}>Counter party already linked:</Typography>
                      <div className={classes.container}>{serverSideTable}</div>
                    </>
                  ) : (
                    <Typography className={classes.typographyWarning}>
                      <Warning className={iconStyleBlack} /> No counter party found
                    </Typography>
                  )}
                </div>
              </ListItem>
            )}
          </List>
        ) : (
          <Box display="flex" justifyContent="center" p={1}>
            <Typography variant="body1">No labels</Typography>
          </Box>
        )}
      </Paper>
    </Box>
  )
}

export default ShowLabelAlreadyActivated
