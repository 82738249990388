import { Box, Grid, IconButton, Typography } from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import React, { useContext } from "react"

import { queryClient } from "../../.."
import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import api from "services/api"
import { GetWorkspaceDto, GetWorkspaceUserDto } from "services/api/openapi/models"
import { GET_WORKSPACES_KEY, GET_WORKSPACES_OR_DEFAULT_KEY } from "services/api/routes/workspace"
import { UserContext } from "services/context/userContext"
import WorkspaceMembers from "../drawer/WorkspaceMembers"
import WorkspaceInvitationModal from "../WorkspaceInvitationModal"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const WorkspaceSummary = ({ workspace, onClose }: { workspace: GetWorkspaceDto; onClose: () => void }): JSX.Element => {
  const toast = useToast()

  const { mutateAsync: deleteWorkspaceUserMutation } = api.workspace.useDeleteWorkspaceUser()
  const { mutateAsync: changeWorkspaceUserOwnershipMutation } =
    api.backOffice.workspace.useChangeWorkspaceUserOwnership()
  const { mutateAsync: deleteWorkspaceInvitationMutation } = api.workspace.useDeleteWorkspaceInvitation()

  const basicDialog = useDialog()
  const { user } = useContext(UserContext)

  const doDeleteUser = async (userId: string) => {
    if (!workspace) return
    try {
      await deleteWorkspaceUserMutation({ workspaceId: workspace.id, userId })
      if (userId === user.id) {
        queryClient.invalidateQueries(GET_WORKSPACES_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
        toast.open("Workspace leaved", { variant: "success" })
        onClose()
      } else {
        toast.open("User deleted", { variant: "success" })
      }
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const doChangeWorkspaceOwnershipUser = async (userId: string) => {
    if (!workspace) return
    try {
      await changeWorkspaceUserOwnershipMutation({ workspaceId: workspace.id, userId })
      toast.open("User ownership changed", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const doDeleteInvitation = async (email: string) => {
    if (!workspace) return
    try {
      await deleteWorkspaceInvitationMutation({ workspaceId: workspace.id, email })
      toast.open("Invitation deleted", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const askDeleteInvitation = (email: string) => {
    if (!workspace) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to delete invitation <b>{email}</b>? This action is irreversible.
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: () => doDeleteInvitation(email),
    })
  }

  const askDeleteUser = (userToDelete: GetWorkspaceUserDto) => {
    if (!workspace) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to remove the user <b>{userToDelete.userName}</b>?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: () => doDeleteUser(userToDelete.userId),
    })
  }

  const askChangeOwnershipUser = (userToSetOwner: GetWorkspaceUserDto) => {
    if (!workspace) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to set Workspace ownership to user <b>{userToSetOwner.userName}</b>?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: () => doChangeWorkspaceOwnershipUser(userToSetOwner.userId),
    })
  }

  const workspaceUsers = api.workspace.useWorkspaceUsers(
    {
      workspaceId: workspace?.id ?? "",
      page: 0,
      limit: 100,
    },
    { enabled: !!workspace },
  )
  const workspaceRoles = api.workspace.useWorkspaceRoles(
    {
      workspaceId: workspace?.id ?? "",
    },
    { enabled: !!workspace },
  )

  if (workspaceRoles.isError) return <NetworkErrorMessage additionalData={workspaceRoles} />
  if (workspaceUsers.isError) return <NetworkErrorMessage additionalData={workspaceUsers} />

  if (
    workspaceUsers.isLoading ||
    workspaceUsers.data === undefined ||
    workspaceRoles.isLoading ||
    workspaceRoles.data === undefined
  ) {
    return <LoadingSpinner />
  }

  return (
    <DrawerCategory title="Workspace summary">
      {basicDialog.dialog}

      <DrawerSection name="Transactions">
        <Typography variant="body1">{workspace.transactionCount} Transactions</Typography>
      </DrawerSection>

      <DrawerSection name="Workspace members">
        <WorkspaceMembers
          askDeleteUser={askDeleteUser}
          workspace={workspace}
          roles={workspaceRoles.data}
          workspaceUsers={workspaceUsers.data}
          askOwnershipUser={askChangeOwnershipUser}
        />
      </DrawerSection>

      {workspace.invitations.length !== 0 && (
        <DrawerSection name="Workspace invitations">
          <Box mt={1}>
            {workspace.invitations.map((invitation) => (
              <Box key={invitation.email}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={2}>
                    {/* TODO: Can invite <=> Can delete invite ? */}
                    {workspace.userRights.can_invite_workspace_user && (
                      <IconButton style={{ padding: 0 }} onClick={() => askDeleteInvitation(invitation.email)}>
                        <Delete />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <Box>
                      <Typography noWrap data-test-id="workspace-invitation">
                        {invitation.email}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        </DrawerSection>
      )}

      {workspace.userRights.can_invite_workspace_user && (
        <DrawerSection name="Invite new user" display="flex" flexDirection="column">
          <WorkspaceInvitationModal workspaceId={workspace.id} />
        </DrawerSection>
      )}
    </DrawerCategory>
  )
}

export default WorkspaceSummary
