import { useTypedController } from "@hookform/strictly-typed"
import { Box, Checkbox, Dialog, FormLabel, makeStyles, TextField, Typography } from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import useDialog from "components/misc/useDialog"
import { DrawerProp } from "components/misc/useDrawer"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { BackOfficeGetFullWorkspaceDto, BackOfficeUpdateWorkspaceDto } from "services/api/openapi/models"
import BackOfficeDuplicateDialog from "./BackOfficeDuplicateDialog"
import { toastCatch } from "components/ReactHookForm/utils"
import { useToast } from "CryptioUI/Toaster"
import PermissionDisabled from "../../../../components/Permission/PermissionDisabled"

type FormType = BackOfficeUpdateWorkspaceDto & {
  deleteStripeCustomId: boolean
}
const useStyles = makeStyles(() => ({
  checkbox: {
    paddingLeft: 0,
  },
}))

const BackOfficeWorkspaceForm = (props: DrawerProp<BackOfficeGetFullWorkspaceDto, false>) => {
  const classes = useStyles()
  const { onClose, isOpen, setFormDirty, item: workspace } = props
  const { handleSubmit, control, formState, reset, watch } = useForm<FormType>({
    mode: "onChange",
  })
  const toast = useToast()
  const TypedController = useTypedController<FormType>({ control })

  const basicDialog = useDialog()
  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState<boolean>(false)

  const watchAllFields = watch()

  const { mutateAsync: updateWorkspaceMutation } = api.backOffice.workspace.useUpdateWorkspace()
  const { mutateAsync: deleteWorkspaceMutation } = api.backOffice.workspace.useDeleteWorkspace()

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen, reset])

  const [UpdateWorkspaceButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = useCallback(
    async (form: FormType) => {
      try {
        await updateWorkspaceMutation({
          workspaceId: workspace.id,
          backOfficeUpdateWorkspaceDto: {
            // If string is empty => null
            stripeCustomerId: form.deleteStripeCustomId ? null : form.stripeCustomerId || null,
          },
        })

        toast.open("Workspace updated", { variant: "success" })

        onClose()
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [onClose, workspace, updateWorkspaceMutation, toast],
  )

  const isFormDirty =
    formState.isDirty &&
    (watchAllFields.deleteStripeCustomId === true ||
      watchAllFields.stripeCustomerId !== (workspace.stripeCustomerId || ""))

  useEffect(() => setFormDirty(isFormDirty), [isFormDirty, setFormDirty])

  const doDeleteWorkspace = async () => {
    try {
      await deleteWorkspaceMutation({ workspaceId: workspace.id })
      toast.open("Deleting Workspace", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
    onClose()
  }

  const askDeleteWorkspace = () => {
    basicDialog.showDialog({
      title: "Delete workspace?",
      content: (
        <Typography variant="h5">
          Do you really want to delete the workspace <b>{workspace.name}</b>?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doDeleteWorkspace,
    })
  }

  const duplicateDialog = (
    <Dialog open={duplicateDialogIsOpen} onClose={() => setDuplicateDialogIsOpen(false)}>
      <BackOfficeDuplicateDialog
        workspace={workspace}
        onClose={() => {
          setDuplicateDialogIsOpen(false)
          onClose()
        }}
      />
    </Dialog>
  )

  return (
    <>
      {duplicateDialog}
      {basicDialog.dialog}
      <DrawerCategory
        component="form"
        onSubmit={handleButtonCallback(handleSubmit(onSubmit))}
        title="Workspace Edition"
      >
        <DrawerFormSection htmlFor="stripe-custom-id-select" name="Stripe Customer ID">
          <TypedController
            name="stripeCustomerId"
            defaultValue={workspace.stripeCustomerId || ""}
            rules={{ required: false }}
            render={(rest) => (
              <TextField
                disabled={watchAllFields.deleteStripeCustomId}
                {...rest}
                id="stripe-custom-id-select"
                fullWidth
              />
            )}
          />

          <Box display="flex" alignItems="center" mt={1}>
            <TypedController
              name="deleteStripeCustomId"
              defaultChecked={false}
              render={({ onChange, value, ...rest }) => (
                <Checkbox
                  color="primary"
                  id="empty-stripe-customer"
                  className={classes.checkbox}
                  onChange={() => onChange(!value)}
                  checked={value}
                  {...rest}
                />
              )}
            />
            <FormLabel htmlFor="empty-stripe-customer">Delete customer id</FormLabel>
          </Box>
        </DrawerFormSection>

        <Box mt={3} display="flex" justifyContent="space-between">
          <UpdateWorkspaceButton disabled={!isFormDirty} type="submit">
            Save
          </UpdateWorkspaceButton>
          <Box>
            <Box component="span" mr={1}>
              <ButtonUI Icon={<FileCopyIcon />} onClick={() => setDuplicateDialogIsOpen(true)}>
                Duplicate
              </ButtonUI>
            </Box>
            <PermissionDisabled permission="can_modify_workspace_user" action="change workspace owner">
              <ButtonUI Icon={<Delete />} mode={Mode.CONTAINED} onClick={askDeleteWorkspace}>
                Delete
              </ButtonUI>
            </PermissionDisabled>
          </Box>
        </Box>
      </DrawerCategory>
    </>
  )
}

export default BackOfficeWorkspaceForm
