import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

const CopyIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M19 21H8V7h11m0-2H8a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2m-3-4H4a2 2 0 0 0-2 2v14h2V3h12V1z"
      fill="#626262"
    />
  </SvgIcon>
)

export default CopyIcon
