import { Box, Typography } from "@material-ui/core"
import useDialog from "components/misc/useDialog"
import { useToast } from "CryptioUI/Toaster"
import dayjs from "dayjs"
import React, { useContext } from "react"

import { DrawerCategory, DrawerSection } from "../../../../components/Drawer/DrawerItems"
import { UncontrolledLoadingButton } from "../../../../components/LoadingButton"
import AdministratorInformation from "../../../../components/misc/AdministratorInformation"
import BackOfficeView from "../../../../components/misc/BackOfficeView"
import { DrawerProp } from "../../../../components/misc/useDrawer"
import PermissionDisabled from "../../../../components/Permission/PermissionDisabled"
import { toastCatch } from "../../../../components/ReactHookForm/utils"
import api from "../../../../services/api"
import { GetImpairmentDto } from "../../../../services/api/openapi"
import { WorkspaceContext } from "../../../../services/context/workspaceContext"
import ImpairmentForm from "./ImpairmentForm"

const ImpairmentDrawer = (props: DrawerProp<GetImpairmentDto, true>) => {
  const { item: impairment } = props
  const { workspace } = useContext(WorkspaceContext)
  const toast = useToast()
  const basicDialog = useDialog()

  const { mutateAsync: updateImpairmentValuationMutation } = api.impairment.useUpdateImpairmentValuation()

  const updateImpairmentValuation = async (overridePrice: boolean) => {
    if (!impairment) return
    try {
      await updateImpairmentValuationMutation({
        impairmentId: impairment.id,
        updateImpairmentValuationDto: {
          overridePrice,
        },
      })
      toast.open("Updating impairment valuation ", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const askUpdateImpairmentValuation = (overridePrice: boolean) => {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">Do you really want to delete the mapping? This action is irreversible.</Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: () => updateImpairmentValuation(overridePrice),
    })
  }

  return (
    <>
      {basicDialog.dialog}
      {!impairment && <ImpairmentForm {...props} />}

      {impairment && (
        <Box>
          <AdministratorInformation infos={[{ extended: "Impairment", id: impairment.id }]} />

          <BackOfficeView>
            <DrawerCategory title="Update impairment valuation">
              <Box mt={2} />
              <PermissionDisabled permission="can_modify_impairment" action="update impairment valuation">
                <UncontrolledLoadingButton
                  onClick={() => askUpdateImpairmentValuation(false)}
                  disabled={!workspace.lock.isEnabled}
                >
                  Update valuation
                </UncontrolledLoadingButton>
              </PermissionDisabled>
              <Box mt={2} />
              <PermissionDisabled permission="can_modify_impairment" action="overried impairment valuation">
                <UncontrolledLoadingButton
                  onClick={() => askUpdateImpairmentValuation(true)}
                  disabled={!workspace.lock.isEnabled}
                >
                  Override valuation
                </UncontrolledLoadingButton>
              </PermissionDisabled>
            </DrawerCategory>
          </BackOfficeView>
          <DrawerCategory title="Impairment Summary">
            <DrawerSection name="Period">
              <Typography variant="body2">
                {dayjs(impairment.startDate).format("L")} - {dayjs(impairment.endDate).format("L")}
              </Typography>
            </DrawerSection>
            <DrawerSection name="Description">
              <Typography variant="body2">{impairment.description}</Typography>
            </DrawerSection>
          </DrawerCategory>
        </Box>
      )}
    </>
  )
}

export default ImpairmentDrawer
