import React from "react"

import { TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"

interface DrawerElementUIProps {
  nameElement: string
  children: JSX.Element
}

const DrawerElementUI = (props: DrawerElementUIProps) => {
  const { nameElement, children } = props

  return (
    <div className="flex flex-col justify-between gap-4 w-full">
      <div className="flex flex-row justify-between">
        <div className="min-w-fit">
          <TypographyUI className="text-grey-400" variant={TypographyVariant.BODY}>
            {nameElement}
          </TypographyUI>
        </div>
        {children}
      </div>
      <div className="border-b border-grey-200 w-full" />
    </div>
  )
}

export default DrawerElementUI
