import { Box } from "@material-ui/core"

import React, { useEffect } from "react"

import { DrawerProp } from "components/misc/useDrawer"

import { BoldWeight, Mode, TypographyVariant } from "CryptioUI/types"
import { DefiLivePositionDto } from "services/api/openapi"
import TypographyUI from "CryptioUI/Typography"
import AssetDeFi from "./AssetDefi"
import ApplicationDeFi from "./ApplicationDeFi"
import PositionDeFi from "./PositionDeFi"
import DrawerElement from "CryptioUI/DrawerElement"
import Status from "./Status"
import ProccessedValue from "./ProccessedValue"
import ButtonUI from "CryptioUI/Button"
import { useHistory } from "react-router"
import { URLS } from "routes"
import { pluralize } from "services/utils/textUtils"
import ImpermanentLoss from "./ImpermanentLoss"
import TechAdministratorInformation from "./TechAdministratorInformation"

const DefiDrawer = (props: DrawerProp<DefiLivePositionDto, false>) => {
  const { item: defiPosition, setTitle, title } = props

  useEffect(() => {
    if (title !== defiPosition.name && setTitle) {
      setTitle(defiPosition.name)
    }
  }, [defiPosition.name, setTitle, title])
  const history = useHistory()

  const uniqTransactionsHashes = defiPosition.yieldPnl?.transactionHashes.filter(
    (value, index, self) => index === self.findIndex((transactionHash) => transactionHash === value),
  )

  return (
    <Box mb={3}>
      <div className="py-8">
        <TypographyUI variant={TypographyVariant.H3} boldness={BoldWeight.MEDIUM}>
          Contract position summary
        </TypographyUI>
      </div>
      <div className="flex flex-col justify-between gap-4">
        <DrawerElement nameElement="Realized P&L">
          <ProccessedValue value={defiPosition.yieldPnl?.realizedPnlFiat ?? null} />
        </DrawerElement>
        {defiPosition.active && (
          <DrawerElement nameElement="Unrealized P&L">
            <ProccessedValue value={defiPosition.yieldPnl?.pnlFiat ?? null} />
          </DrawerElement>
        )}
        <DrawerElement nameElement="Yield">
          <ProccessedValue value={defiPosition.yieldPnl?.yieldFiat ?? null} />
        </DrawerElement>
        <DrawerElement nameElement="Impermanent Loss">
          <ImpermanentLoss
            total={defiPosition.poolData?.totalFiat}
            token0={defiPosition.poolData?.detailed[0].token0}
            token1={defiPosition.poolData?.detailed[0].token1}
          />
        </DrawerElement>
        <DrawerElement nameElement="Status">
          <Status isOpen={defiPosition.active} />
        </DrawerElement>
        <DrawerElement nameElement="Application">
          <ApplicationDeFi
            protocolLogo={defiPosition.protocol.logo}
            protocolName={defiPosition.protocol.name}
            blockchainLogo={defiPosition.blockchain.logo}
            blockchainName={defiPosition.blockchain.name}
          />
        </DrawerElement>
        <DrawerElement nameElement="Position">
          <PositionDeFi
            positionName={defiPosition.name}
            walletId={defiPosition.walletId}
            walletName={defiPosition.walletName}
          />
        </DrawerElement>
        <DrawerElement nameElement="Claimaible">
          <AssetDeFi
            assets={defiPosition.rewards.assets}
            totalValueUsd={defiPosition.rewards.totalValueUsd}
            color={"text-label-green-200"}
          />
        </DrawerElement>
        <DrawerElement nameElement="Deposit">
          <AssetDeFi assets={defiPosition.supplies.assets} totalValueUsd={defiPosition.supplies.totalValueUsd} />
        </DrawerElement>
        <DrawerElement nameElement="Total Value">
          <AssetDeFi assets={defiPosition.total.assets} totalValueUsd={defiPosition.total.totalValueUsd} />
        </DrawerElement>
        <div className="flex w-full justify-end">
          {defiPosition.yieldPnl && uniqTransactionsHashes ? (
            <ButtonUI
              mode={Mode.TEXTONLY}
              className="!p-0 !h-fit"
              onClick={() => {
                history.push(
                  URLS.Transactions.getUrl({
                    filters: [
                      {
                        type: "transaction_hash",
                        value: defiPosition.yieldPnl?.transactionHashes || [],
                        isNot: false,
                      },
                    ],
                  }),
                )
              }}
            >
              <TypographyUI variant={TypographyVariant.CAPTION} className="text-grey-400 underline">
                {`See ${uniqTransactionsHashes.length} related ${pluralize(
                  uniqTransactionsHashes.length > 1,
                  "transaction",
                )}`}
              </TypographyUI>
            </ButtonUI>
          ) : (
            <TypographyUI variant={TypographyVariant.CAPTION} className="text-grey-400">
              No related transaction available
            </TypographyUI>
          )}
        </div>
      </div>
      <TechAdministratorInformation positionId={defiPosition.id} />
    </Box>
  )
}

export default DefiDrawer
