import { Box, DialogActions, makeStyles, Theme, Typography } from "@material-ui/core"
import { CheckRounded, WarningRounded } from "@material-ui/icons"
import React, { useContext, useRef, useState } from "react"
import { IDataTableColumn } from "react-data-table-component"
import { useForm } from "react-hook-form"

import { useLoadingButton } from "components/LoadingButton"
import BaseTable from "components/Table"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { CreateContactsDto } from "services/api/openapi"
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from "services/constants"
import { ThemeContext } from "services/context/themeContext"
import parseCsvFile, {
  CsvModalType,
  CsvParseOptions,
  findRowByRowIdFilterByErrorString,
  WithCsvInfos,
} from "services/csvParse"
import { DataTableSelectedRowState } from "services/utils/types"
import { widgetTableStyle } from "../Dashboard/widgets/widgetTableStyle"
import InputButtonUI from "CryptioUI/InputButton"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

// Special for this csv because of addresses
type ContactWithCsvInfos = WithCsvInfos<{
  name: string
  address: string
}>

const useStyles = makeStyles((theme: Theme) => ({
  validateButton: {
    marginRight: theme.spacing(2),
  },
}))

const columns: IDataTableColumn<ContactWithCsvInfos>[] = [
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Address",
    selector: "address",
    sortable: true,
  },
  {
    name: "Status",
    selector: "errorString",
    sortable: true,
    format: function formatAddress(row) {
      return row.errorString ? (
        <Typography color="error" variant="body1">
          <Box display="flex">
            <Box pr={1}>
              <WarningRounded />
            </Box>
            {row.errorString}
          </Box>
        </Typography>
      ) : (
        <CheckRounded color="action" />
      )
    },
  },
]

const ContactModalCsv = (): JSX.Element => {
  const classes = useStyles()
  const [modalData, setModalData] = useState<CsvModalType<ContactWithCsvInfos>>({
    isOpen: false,
    data: [],
  })
  const muiThemeContext = useContext(ThemeContext)
  const { handleSubmit } = useForm<Record<string, unknown>>()
  const toast = useToast()
  const [selectedRows, setSelectedRows] = useState<DataTableSelectedRowState<ContactWithCsvInfos>>({
    allSelected: false,
    selectedCount: 0,
    selectedRows: [],
  })

  const fileField = useRef<HTMLInputElement>(null)

  const handleFileChange = () => {
    if (!fileField.current || !fileField.current.files || !fileField.current.files.length) {
      return
    }

    const options: CsvParseOptions<ContactWithCsvInfos> = {
      file: fileField.current.files[0],
      requiredColumns: ["name", "address"],
      setModalData,
      toast,
      transformData: (data) =>
        data.map((row, index) => {
          let errorString: string | null = null
          if (!row.name.length) {
            errorString = "Missing name"
          } else if (!row.address.length) {
            errorString = "Missing address"
          }
          return {
            ...row,
            errorString,
            rowId: index,
          }
        }),
    }

    parseCsvFile<ContactWithCsvInfos>(options)
  }

  const { mutateAsync: createContactMutation } = api.contact.useCreateContacts()
  const [ImportSelectionButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = async () => {
    // Need filter, empty item in array
    const contacts = findRowByRowIdFilterByErrorString(selectedRows).reduce((prev, elem) => {
      if (prev[elem.name]) {
        prev[elem.name].addresses.push(elem.address)
      } else {
        prev[elem.name] = {
          name: elem.name,
          addresses: [elem.address],
        }
      }
      return prev
    }, {} as Record<string, CreateContactsDto["contacts"][0]>)
    try {
      await createContactMutation({ createContactsDto: { contacts: Object.values(contacts) } })
    } catch (e) {
      toastCatch(e, toast)
    }

    setModalData({
      isOpen: false,
      data: [],
    })
  }

  return (
    <>
      <InputButtonUI
        mode={Mode.CONTAINED}
        htmlFor="contacts"
        type="file"
        accept=".csv"
        ref={fileField}
        name="contacts"
        id="contacts"
        onChange={handleFileChange}
        onClick={() => {
          if (fileField.current) {
            fileField.current.value = ""
            fileField.current.files = null
          }
        }}
      >
        Import from CSV
      </InputButtonUI>
      <ModalUI
        isOpen={modalData.isOpen}
        onClose={() => setModalData({ isOpen: false, data: [] })}
        className="w-[1000px] h-auto"
        title="Import Contacts"
      >
        <BaseTable<ContactWithCsvInfos, "errorString">
          columns={columns}
          items={modalData.data}
          fixedScroll="400px"
          onSelectedChange={setSelectedRows}
          paginationRowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          paginationTotalRows={modalData.data.length}
          paginationPerPage={50}
          pagination={modalData.data.length > DEFAULT_ROWS_PER_PAGE}
          customStyle={widgetTableStyle(muiThemeContext.type)}
          defaultParams={{
            sortBy: "errorString",
            sortDirection: "ascending",
          }}
          sortServer={false}
        />
        <Box component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              {modalData.data.length} element
              {modalData.data.length > 1 ? "s" : ""} found
            </Typography>
            <div className="flex flex-row">
              <ImportSelectionButton
                disabled={selectedRows.selectedCount === 0}
                className={classes.validateButton}
                type="submit"
              >
                Import selection
              </ImportSelectionButton>
              <ButtonUI onClick={() => setModalData({ isOpen: false, data: [] })} mode={Mode.CONTAINED}>
                Close
              </ButtonUI>
            </div>
          </DialogActions>
        </Box>
      </ModalUI>
    </>
  )
}

export default ContactModalCsv
