import React from "react"
import ReactJson from "react-json-view"

import { DrawerCategory, DrawerSection } from "../../../components/Drawer/DrawerItems"
import api from "../../../services/api"
import { GetWalletDto } from "../../../services/api/openapi"
import NetworkErrorMessage from "../../../components/misc/NetworkErrorMessage"
import LoadingSpinner from "../../../components/misc/LoadingSpinner"
import copy from "copy-to-clipboard"
import BackOfficeView from "../../../components/misc/BackOfficeView"

type WalletTechAdministratorProps = { wallet: GetWalletDto }
const InnerWalletTechAdministrator = ({ wallet }: WalletTechAdministratorProps) => {
  const credentials = api.backOffice.wallet.useWalletCredentials({ walletId: wallet.id })

  if (credentials.isError) {
    return <NetworkErrorMessage additionalData={credentials} />
  }
  if (credentials.isLoading || credentials.data === undefined) {
    return <LoadingSpinner />
  }

  return (
    <DrawerSection name="Credentials" labelGap={1}>
      <ReactJson
        displayDataTypes={false}
        displayObjectSize={false}
        name={"credentials"}
        src={credentials.data.credentials}
        enableClipboard={(props) => copy(JSON.stringify(props.src, null, 2))}
      />
    </DrawerSection>
  )
}

const WalletTechAdministrator = (props: WalletTechAdministratorProps) => {
  return (
    <BackOfficeView techAdminOnly>
      <DrawerCategory title="Tech Administrator information">
        <InnerWalletTechAdministrator {...props} />
      </DrawerCategory>
    </BackOfficeView>
  )
}

export default WalletTechAdministrator
