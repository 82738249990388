import { Box, Link, makeStyles, Theme, Typography } from "@material-ui/core"
import dayjs from "dayjs"
import React, { useMemo } from "react"

import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import AdministratorInformation, { Information } from "components/misc/AdministratorInformation"
import useDrawer from "components/misc/useDrawer"
import { URLS } from "../../../routes"
import { NewFullTransactionDto } from "services/api/openapi/models"
import AssetView from "../Cells/AssetView"
import CostBasisView from "../Cells/CostBasis/CostBasisView"
import GainView from "../Cells/GainView"
import OtherParty from "../Cells/OtherParty"
import TxHashLink from "../Cells/TxHashLink"
import {
  getMainMovement,
  getOtherParties,
} from "../GeneratedTable/GeneratedRows/GenerateSimpleTransaction/generateSimpleTransaction"
import { convertMovements } from "../utils/convertMovement"
import api from "services/api"
import BackOfficeView from "../../misc/BackOfficeView"
import { UncontrolledLoadingButton } from "../../LoadingButton"
import { WarningAdminFeature } from "../../Feature/WarningAdminFeature"
import WarningTypography from "../../WarningTypography"
import TransactionMetadata from "./TransactionMetadata"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import { iconStyleBlack } from "CryptioUI/Utilities/config"
import { ReactComponent as Delete } from "CryptioUI/assets/icons/delete.svg"
import { Mode } from "CryptioUI/types"
import useDialog from "components/misc/useDialog"
import ButtonUI from "CryptioUI/Button"
import { CustomSourceNamesArray } from "pure-shared"

const useStyles = makeStyles((theme: Theme) => ({
  containerAssetView: {
    maxWidth: "auto",
  },
  emptyPadding: {
    paddingLeft: theme.spacing(4.5),
  },
}))

interface TransactionSummaryProps {
  transaction: NewFullTransactionDto
  onClose: () => void
}

const TransactionSummary = ({ transaction, onClose }: TransactionSummaryProps) => {
  const [walletDrawer, openWalletDrawer] = useDrawer("wallet")
  const classes = useStyles()
  const toast = useToast()

  const [loneMovements, trades] = convertMovements(transaction)
  const nbIn = transaction.incomingCount
  const nbOut = transaction.outgoingCount
  const nbFee = transaction.feeCount
  const mainMvt = getMainMovement(loneMovements, trades, nbIn, nbOut, nbFee)
  const otherParties = getOtherParties(loneMovements, trades)

  const { mutateAsync: breakTradeMutation } = api.backOffice.wallet.useBreakTrade()
  const { mutateAsync: deleteTransactions } = api.transaction.useDeleteTransactions()

  const backofficeBreakTrade = async () => {
    try {
      await breakTradeMutation({
        backOfficeBreakTrade: {
          transactionIds: [transaction.id],
        },
      })
      toast.open("Breaking trade", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const movementsSummary = transaction.movements.map((mvt) => {
    return (
      <div key={mvt.id}>
        {mvt.direction === "in" && (
          <DrawerSection name="Incoming">
            <div className={classes.containerAssetView}>
              <AssetView
                assetId={mvt.assetId}
                assetSymbol={mvt.assetSymbol}
                volume={mvt.volume}
                direction={mvt.direction}
                assetToUsdRate={mvt.assetToUsdRate}
                isFee={mvt.isFee}
                usdToFiatRate={transaction.usdToFiatRate}
                alignLeft={true}
                fullNumber
              />
            </div>
          </DrawerSection>
        )}
        {!mvt.isFee && mvt.direction === "out" && (
          <DrawerSection name="Outgoing">
            <div className={classes.containerAssetView}>
              <AssetView
                assetId={mvt.assetId}
                assetSymbol={mvt.assetSymbol}
                volume={mvt.volume}
                direction={mvt.direction}
                assetToUsdRate={mvt.assetToUsdRate}
                isFee={mvt.isFee}
                usdToFiatRate={transaction.usdToFiatRate}
                alignLeft={true}
                fullNumber
              />
            </div>
          </DrawerSection>
        )}
        {mvt.isFee && (
          <DrawerSection name="Fee">
            <div className={classes.containerAssetView}>
              <AssetView
                assetId={mvt.assetId}
                assetSymbol={mvt.assetSymbol}
                volume={mvt.volume}
                direction={mvt.direction}
                assetToUsdRate={mvt.assetToUsdRate}
                isFee={mvt.isFee}
                usdToFiatRate={transaction.usdToFiatRate}
                alignLeft={true}
                fullNumber
              />
            </div>
          </DrawerSection>
        )}
        {mvt && mvt.direction === "out" && (
          <>
            {mvt.costBasis && !mvt.costBasis.mainCostBasisError && mvt.costBasis.costBasisFiat !== null && (
              <DrawerSection name={mvt.isFee ? "Fee Cost basis" : "Outgoing Cost basis"}>
                <CostBasisView costBasisFiat={mvt.costBasis.costBasisFiat} />
              </DrawerSection>
            )}
            {mvt.costBasis && !mvt.costBasis.mainCostBasisError && mvt.costBasis.gainFiat !== null && (
              <DrawerSection name={mvt.isFee ? "Fee Gains/Losses" : "Outgoing Gains/Losses"}>
                <div className="flex justify-start">
                  <GainView feeGainFiat={null} outgoingGainFiat={mvt.costBasis.gainFiat} />
                </div>
              </DrawerSection>
            )}
          </>
        )}
      </div>
    )
  })

  const informationIds = useMemo(() => {
    const transactionId: Information[] = [
      {
        id: transaction.id,
        extended: "Transaction",
        shareUrl: URLS.Transactions.getUrl({
          filters: [{ type: "transaction_id", value: [transaction.id], isNot: false }],
        }),
      },
    ]

    const movementIds: Information[] =
      transaction.complexity === "complex"
        ? []
        : transaction.movements.map((mvt) => {
            if (mvt.direction === "in") {
              return {
                id: mvt.id,
                extended: "Incoming",
              }
            } else if (mvt.direction === "out" && !mvt.isFee) {
              return {
                id: mvt.id,
                extended: "Outgoing",
              }
            } else {
              return {
                id: mvt.id,
                extended: "Fee",
              }
            }
          })

    return <AdministratorInformation infos={transactionId.concat(movementIds)} />
  }, [transaction])

  const totalMvs = nbIn + nbOut + nbFee
  const nbFetchedMvs = transaction.movements.length
  const doDeleteTransaction = async () => {
    if (!transaction) return
    try {
      await deleteTransactions({
        deleteTransactionCustomDto: { walletId: transaction.wallet.id, transactionIds: [transaction.id] },
      })
      toast.open("Transaction deleted", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
    onClose()
  }

  const basicDialog = useDialog()
  const askDeleteTransaction = () => {
    if (!transaction) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <>
          <Typography variant="h5" component="p">
            Do you really want to delete this transaction ? This action is irreversible.
          </Typography>
          <Typography variant="h5" component="p">
            This transaction is part of the wallet <b>{transaction.wallet.name}</b>
          </Typography>
        </>
      ),

      yesText: "Yes",
      noText: "Cancel",
      onAccept: doDeleteTransaction,
    })
  }
  return (
    <Box>
      {basicDialog.dialog}
      {informationIds}
      <BackOfficeView techAdminOnly>
        <TransactionMetadata transactionId={transaction.id} />
      </BackOfficeView>
      {totalMvs > nbFetchedMvs && (
        <WarningTypography mt={3}>
          This transactions contains more than {nbFetchedMvs} movements. We only display the first {nbFetchedMvs}{" "}
          movements
        </WarningTypography>
      )}
      {transaction.type == "simple_trade" && (
        <BackOfficeView adminOnly>
          <DrawerSection name="Split trade into movements">
            <Box display="flex">
              <WarningAdminFeature isAdminFeature>
                <UncontrolledLoadingButton onClick={backofficeBreakTrade}>Split trade</UncontrolledLoadingButton>
              </WarningAdminFeature>
            </Box>
          </DrawerSection>
        </BackOfficeView>
      )}
      <DrawerCategory title="Transaction summary">
        {walletDrawer}
        {mainMvt && (
          <>
            <DrawerSection key={mainMvt.id} name="Other Party">
              <OtherParty
                direction={mainMvt.direction}
                wallet={transaction.wallet.name}
                otherPartiesAliases={otherParties}
                onClickWallet={() => openWalletDrawer(transaction.wallet.id)}
              />
            </DrawerSection>
          </>
        )}
        {movementsSummary}
        <DrawerSection name="Date">
          <Typography variant="body2">
            {dayjs(transaction.transactionDate).tz().format("L")} at{" "}
            {dayjs(transaction.transactionDate).tz().format("LTS")}
          </Typography>
        </DrawerSection>

        <DrawerSection name="Source">
          <Link
            onClick={() => openWalletDrawer(transaction.wallet.id)}
            style={{ cursor: "pointer" }}
            underline="always"
          >
            {transaction.wallet.name}
          </Link>
        </DrawerSection>

        {transaction.transactionHash && (
          <DrawerSection name="Transaction hash">
            <TxHashLink txHash={transaction.transactionHash} sourceName={transaction.wallet?.sourceName} />
          </DrawerSection>
        )}
      </DrawerCategory>
      {!CustomSourceNamesArray.every((customSourceName) => customSourceName !== transaction.wallet.sourceName) && (
        <PermissionDisabled permission="can_remove_transaction" action="delete transaction">
          <DrawerCategory title="Delete custom transaction">
            <Box mt={2} />
            <Typography variant="body2">
              Click to delete this custom Transaction. This action is irreversible.
            </Typography>
            <Box mt={2}>
              <div>
                <ButtonUI
                  Icon={<Delete className={iconStyleBlack} />}
                  mode={Mode.CONTAINED}
                  onClick={askDeleteTransaction}
                >
                  Delete
                </ButtonUI>
              </div>
            </Box>
          </DrawerCategory>
        </PermissionDisabled>
      )}
    </Box>
  )
}

export default TransactionSummary
