import { Box, Link, Typography } from "@material-ui/core"
import dayjs from "dayjs"
import React, { useMemo } from "react"
import { Link as RouterLink } from "react-router-dom"

import AddressTypography from "components/AddressTypography"
import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import AdministratorInformation, { Information } from "components/misc/AdministratorInformation"
import CopyableText from "components/misc/copyableText"
import { URLS } from "../../../routes"
import { GetWalletDto } from "services/api/openapi"
import { pluralize } from "services/utils/textUtils"
import toFilterElement from "services/utils/toFilterElement"
import { getHumanReadableWalletStatus } from "../ImportStatus"
import WalletTechAdministrator from "./WalletTechAdministrator"

const WalletSummary = ({ wallet, onClose }: { wallet: GetWalletDto; onClose: () => void }) => {
  const informationIds = useMemo(() => {
    const walletId: Information[] = [
      {
        id: wallet.id,
        extended: "Wallet",
      },
    ]

    const sourceId: Information[] = [
      {
        id: wallet.mainWalletSourceId,
        extended: "Main wallet source",
      },
    ]

    return <AdministratorInformation infos={walletId.concat(sourceId)} />
  }, [wallet])

  return (
    <Box>
      {informationIds}
      <WalletTechAdministrator wallet={wallet} />

      <DrawerCategory title="Source summary">
        <DrawerSection name="Name">
          <Typography variant="body1">{wallet.name}</Typography>
        </DrawerSection>

        <DrawerSection name="Type">
          <Typography variant="body1">{wallet.sourceName}</Typography>
        </DrawerSection>

        {wallet.address && (
          <DrawerSection name="Address">
            <Typography variant="body1">
              <CopyableText text={wallet.address}>
                <AddressTypography component="span">{wallet.address}</AddressTypography>
              </CopyableText>
            </Typography>
          </DrawerSection>
        )}

        <DrawerSection name="Status">
          <Typography variant="body1">{getHumanReadableWalletStatus(wallet)}</Typography>
        </DrawerSection>

        {/* Dates does not contain hour / timezone information, they shouldn't be timezoned */}
        {wallet.startDate && wallet.endDate ? (
          <DrawerSection name="Date range">
            <Typography variant="body2">
              {dayjs(wallet.startDate).format("L")} to {dayjs(wallet.endDate).format("L")}
            </Typography>
          </DrawerSection>
        ) : (
          <>
            {wallet.startDate && (
              <DrawerSection name="Starting date">
                <Typography variant="body2">{dayjs(wallet.startDate).format("L")}</Typography>
              </DrawerSection>
            )}
            {wallet.endDate && (
              <DrawerSection name="Ending date">
                <Typography variant="body2">{dayjs(wallet.endDate).format("L")}</Typography>
              </DrawerSection>
            )}
          </>
        )}

        <DrawerSection name="Last update">
          <Typography variant="body1">
            {wallet.lastUpdatedAt ? (
              <span>Last updated {dayjs(wallet.lastUpdatedAt).fromNow()}</span>
            ) : (
              <span>Never updated</span>
            )}
          </Typography>
        </DrawerSection>

        <DrawerSection name="Transactions">
          <Link
            onClick={onClose}
            component={RouterLink}
            to={URLS.Transactions.getUrl({
              filters: [{ type: "wallets", value: [toFilterElement(wallet)], isNot: false }],
            })}
            underline="always"
          >
            <Typography variant="body1">
              {wallet.transactionCount} {pluralize(wallet.transactionCount > 1, "transaction")}
            </Typography>
          </Link>
        </DrawerSection>
      </DrawerCategory>
    </Box>
  )
}

export default WalletSummary
