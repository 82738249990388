import React from "react"
import { CostBasisIds, Trade } from "../../.."
import {
  MovementLabelDto,
  NewInListMovementDto,
  NewInListTransactionDto,
  OtherPartyAliasDto,
} from "services/api/openapi"
import ContainerColumn, { ColumnsName } from "../../ContainerColumn"
import { CustomCellDirection } from "../../useGenerateCell"
import GeneratedCell from "../../GeneratedCell"
import { DeveloppedRowsType } from "../../useDeveloppedTransactionPage"
import { ConfigTable } from "../../useConfigTable"
import { SelectTransactionPageContextValue } from "services/context/selectTransactionPageContext"
import { SelectedRowsType } from "../../useBulkSelectorTransactionPage"
import { GetMovementDto } from "../../../../../services/api/routes/transactions"

const getDirection = (mvt: NewInListMovementDto | undefined): CustomCellDirection => {
  if (!mvt) return CustomCellDirection.UNDEFINED
  if (mvt.direction === "in") return CustomCellDirection.IN
  if (mvt.direction === "out" && !mvt.isFee) return CustomCellDirection.OUT
  if (mvt.isFee) return CustomCellDirection.FEE
  return CustomCellDirection.UNDEFINED
}

const getOtherParties = (mvt: NewInListMovementDto | undefined, trade: Trade | undefined) => {
  const otherParties: OtherPartyAliasDto[] = []

  if (
    mvt &&
    mvt.otherPartyAlias &&
    (mvt.otherPartyAlias.address || mvt.otherPartyAlias.alias || mvt.otherPartyAlias.blockchain)
  ) {
    otherParties.push(mvt.otherPartyAlias)
  }
  if (trade && trade.in.otherPartyAlias) {
    otherParties.push(trade.in.otherPartyAlias)
  }
  if (trade && trade.out.otherPartyAlias) {
    otherParties.push(trade.out.otherPartyAlias)
  }
  return otherParties
}

const getAllLabels = (mvt: NewInListMovementDto | undefined, trade: Trade | undefined) => {
  const labels: Array<MovementLabelDto>[] = []

  if (mvt) {
    labels.push(mvt.labels)
  }
  if (trade) {
    labels.push(trade.in.labels)
    labels.push(trade.out.labels)
  }
  return labels.flat(1)
}

const getMainMovement = (mvt: NewInListMovementDto | undefined, trade: Trade | undefined) => {
  if (trade) return trade.out
  return mvt
}

const getIncoming = (mvt: NewInListMovementDto | undefined, trade: Trade | undefined) => {
  if (trade) {
    return trade.in
  }
  return mvt?.direction === "in" ? mvt : undefined
}

const getOutgoing = (mvt: NewInListMovementDto | undefined, trade: Trade | undefined) => {
  if (trade) {
    return trade.out
  }
  return mvt?.direction === "out" && !mvt?.isFee ? mvt : undefined
}

export const generateDevelopedMvt = (
  transaction: NewInListTransactionDto,
  columnsNameArray: ColumnsName[],
  openWalletMovementDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void,
  openMovementLabelDrawer: <PassedItemId extends GetMovementDto>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void,
  setCostBasisDrawerTransactionId: (ids: CostBasisIds | null) => void,
  mvt: NewInListMovementDto | undefined,
  trade: Trade | undefined,
  developpedRows: DeveloppedRowsType | undefined,
  setDeveloppedRows: (row: DeveloppedRowsType | undefined) => void,
  columnsConfig: ConfigTable[],
  selectedRows: SelectedRowsType | undefined,
  setSelectedRows: (row: SelectedRowsType | undefined) => void,
  openTransactionLabelDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void,
  mvtSelected: SelectTransactionPageContextValue,
) => {
  const mainMovement = getMainMovement(mvt, trade)
  const otherPartiesAliases = getOtherParties(mvt, trade)
  const labels = getAllLabels(mvt, trade)
  const direction = getDirection(mainMovement)
  const fee = mvt?.isFee ? mvt : undefined
  const incoming = getIncoming(mvt, trade)
  const outgoing = getOutgoing(mvt, trade)

  return columnsNameArray.map((columnName: string) => (
    <ContainerColumn key={columnName} columnName={columnName} columnsConfig={columnsConfig}>
      <GeneratedCell
        columnName={columnName}
        dataToDisplay={{
          columnName,
          transactionDate: transaction.transactionDate,
          incoming,
          outgoing,
          fee,
          wallet: transaction.wallet,
          otherPartiesAliases,
          labels,
          id: transaction.id,
          direction,
          mainMovement,
          isDevelopped: true,
          usdToFiatRate: transaction.usdToFiatRate,
          developpedRows,
          selectedRows,
          setSelectedRows,
          setDeveloppedRows,
          accountingJournal: mainMovement?.accountingJournal,
          mvtSelected,
        }}
        openWalletDrawer={openWalletMovementDrawer}
        openMovementLabelDrawer={openMovementLabelDrawer}
        openTransactionLabelDrawer={openTransactionLabelDrawer}
        setCostBasisDrawerTransactionId={setCostBasisDrawerTransactionId}
      />
    </ContainerColumn>
  ))
}
