import LoadingSpinner from "../../../components/misc/LoadingSpinner"
import NetworkErrorMessage from "../../../components/misc/NetworkErrorMessage"
import { DrawerFormSection } from "../../../components/Drawer/DrawerItems"
import { GetReportsReportModuleEnum, GetReportsReportTypeEnum } from "../../../services/api/openapi"
import api from "../../../services/api"
import { Autocomplete } from "@material-ui/lab"
import { ReportParameters } from "../index"
import { TextField, Chip } from "@material-ui/core"
import CustomSelector from "components/selector/CustomSelector"
import { WithoutWorkspaceId } from "../../../services/api/aliases"
import { GetWalletsRequest } from "../../../../../tests/api/src/internal-api/openapi/apis/WalletsApi"
import WalletOptionInAutocomplete from "components/AutoCompleteOptions/WalletOptionInAutocomplete"
import { GetWalletDto } from "../../../../../tests/api/src/internal-api/openapi/models/index"
import AssetFilter, {
  AssetWithDefaultingName,
} from "../../../components/CoreTransaction/filters/TransactionFilter/forms/AssetFilter"
import DateRangePicker from "components/DateRangePicker"
import dayjs from "dayjs"
import { PlainDate } from "../../../components/DateRangePicker"

interface ReportModuleFilterProps {
  tempFilter: ReportParameters
  updateFilter: (newValue: Partial<ReportParameters>) => void
}

const ReportModuleFilter = ({ tempFilter, updateFilter }: ReportModuleFilterProps) => {
  const reportModules = api.report.useReportTypes()
  const workspaceWallet = api.wallet.useWallets({ page: 0, limit: 10, wallets: tempFilter.walletIds })
  const workspaceAsset = api.asset.usePublicAssets({ page: 0, limit: 10, assets: tempFilter.assetIds })

  if (reportModules.isLoading) return <LoadingSpinner />
  if (reportModules.isError || !reportModules.data) return <NetworkErrorMessage small additionalData={reportModules} />

  const selectedModule = reportModules.data.find((module) => module.name === tempFilter.reportModule)
  const workspaceAssetFormat =
    tempFilter.assetIds && workspaceAsset.data
      ? workspaceAsset.data.data
          .filter((asset) => tempFilter.assetIds?.includes(asset.id))
          .map(
            (asset): AssetWithDefaultingName => ({
              type: asset.type,
              id: asset.id,
              symbol: asset.symbol,
              uniqueSymbol: asset.uniqueSymbol,
              name: asset.name ? asset.name : "",
            }),
          )
      : []
  const workspaceWalletFormat =
    tempFilter.walletIds && workspaceWallet.data && tempFilter.walletIds.length == workspaceWallet.data.data.length
      ? workspaceWallet.data.data
      : []
  const dateRange = {
    startDate: tempFilter.startDate ? new PlainDate(dayjs(tempFilter.startDate)) : undefined,
    endDate: tempFilter.endDate ? new PlainDate(dayjs(tempFilter.endDate)) : undefined,
  }

  return (
    <>
      <DrawerFormSection htmlFor="report-module-textfield" name="Filter by report module">
        <Autocomplete
          id="report-module-select"
          getOptionLabel={(option) => option.displayName}
          options={reportModules.data}
          autoComplete
          value={selectedModule ?? null}
          onChange={(_, newValue) =>
            updateFilter({
              reportModule: (newValue?.name as GetReportsReportModuleEnum) ?? undefined,
              reportType: undefined,
            })
          }
          renderInput={(params) => <TextField {...params} />}
        />
      </DrawerFormSection>
      <DrawerFormSection htmlFor="report-type-textfield" name="Filter by report type">
        <Autocomplete
          id="report-type-select"
          getOptionLabel={(option) => option.displayName}
          options={selectedModule?.types ?? []}
          autoComplete
          value={selectedModule?.types.find((type) => type.name === tempFilter.reportType) ?? null}
          onChange={(_, newValue) =>
            updateFilter({ reportType: (newValue?.name as GetReportsReportTypeEnum) ?? undefined })
          }
          disabled={selectedModule === undefined}
          renderInput={(params) => <TextField {...params} />}
        />
      </DrawerFormSection>
      <DrawerFormSection htmlFor="wallet-ids-textfield" name="Filter by wallet ids">
        <CustomSelector<WithoutWorkspaceId<GetWalletsRequest>, GetWalletDto, true, false, true>
          id="wallets-select"
          value={workspaceWalletFormat}
          multiple
          defaultPaginatedQueryProps={{
            sortBy: "name",
            sortDirection: "ascending",
          }}
          size="small"
          disableCloseOnSelect
          filterSelectedOptions
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          onChange={(_, newValue) => {
            if (typeof newValue === "string") return
            updateFilter({ walletIds: newValue.map((wallet) => (wallet as GetWalletDto).id) })
          }}
          usePaginatedQuery={api.wallet.useWallets}
          renderOption={(option) => <WalletOptionInAutocomplete wallet={option} />}
        />
      </DrawerFormSection>
      <DrawerFormSection htmlFor="asset-ids-textfield" name="Filter by asset ids">
        <AssetFilter
          assets={workspaceAssetFormat}
          size="small"
          id="asset-select"
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                variant="default"
                label={(option as AssetWithDefaultingName).symbol}
                {...getTagProps({ index })}
              />
            ))
          }
          onAssetsChange={(value) => {
            updateFilter({ assetIds: value.map((asset) => asset.id) })
          }}
        />
      </DrawerFormSection>
      <DrawerFormSection htmlFor="date" name="Filter by Date">
        <DateRangePicker
          value={dateRange}
          onChange={(date) => {
            updateFilter({ endDate: date.endDate?.toBackendDate(), startDate: date.startDate?.toBackendDate() })
          }}
        />
      </DrawerFormSection>
    </>
  )
}

export default ReportModuleFilter
