import { useTypedController } from "@hookform/strictly-typed"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import dayjs from "dayjs"
import React, { useMemo } from "react"
import { ReportFormComponentProps } from "."
import { DateRange, PlainDate } from "components/DateRangePicker"
import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { DrawerReportForm } from "../ReportForm"

export interface ReportPortfolioType {
  displayName: string
  date: DateRange
}

const ReportPortfolioRange = ({ control }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })
  const portfolioTypeOption = useMemo<ReportPortfolioType[]>(() => {
    const startOfYear = dayjs.utc().startOf("year")
    const startOfMonth = dayjs.utc().startOf("month")

    return [
      {
        displayName: "last 3 months",
        date: {
          startDate: new PlainDate(startOfMonth.subtract(3, "month").startOf("month")),
          endDate: new PlainDate(startOfMonth.subtract(1, "month").endOf("month").startOf("day")),
        },
      },
      {
        displayName: "last 6 months",
        date: {
          startDate: new PlainDate(startOfMonth.subtract(6, "month").startOf("month")),
          endDate: new PlainDate(startOfMonth.subtract(1, "month").endOf("month").startOf("day")),
        },
      },
      {
        displayName: "last 9 months",
        date: {
          startDate: new PlainDate(startOfMonth.subtract(9, "month").startOf("month")),
          endDate: new PlainDate(startOfMonth.subtract(1, "month").endOf("month").startOf("day")),
        },
      },
      {
        displayName: "last 12 months",
        date: {
          startDate: new PlainDate(startOfMonth.subtract(12, "month").startOf("month")),
          endDate: new PlainDate(startOfMonth.subtract(1, "month").endOf("month").startOf("day")),
        },
      },
      ...Array(5)
        .fill(0)
        .map((_, idx) => ({
          displayName: `full year - ${startOfYear.year() - (idx + 1)}`,
          date: {
            startDate: new PlainDate(startOfYear.subtract(idx + 1, "year").startOf("year")),
            endDate: new PlainDate(
              startOfYear
                .subtract(idx + 1, "year")
                .endOf("year")
                .startOf("day"),
            ),
          },
        })),
    ]
  }, [])

  return (
    <DrawerFormSection htmlFor="date-range-select" name="Date range">
      <TypedController
        name="portfolioType"
        defaultValue={portfolioTypeOption[0]}
        rules={{ required: true }}
        render={({ onChange, ...rest }) => (
          <Autocomplete
            id="date-range-select"
            {...rest}
            disableClearable
            onChange={(_, newValue) => onChange(newValue)}
            getOptionLabel={(option) => option.displayName}
            options={portfolioTypeOption}
            renderInput={(params) => <TextField {...params} />}
            size="small"
          />
        )}
      />
    </DrawerFormSection>
  )
}

export default ReportPortfolioRange
