import React from "react"
import { css } from "@emotion/css"
import { twMerge } from "tailwind-merge"

export interface IconPositionWrapperProps {
  iconPosition?: "left" | "right"
  Icon?: JSX.Element | undefined
  children: React.ReactNode
  iconSpacing?: number
  iconOnly?: boolean
  className?: string
  disabled?: boolean
}

const IconPositionWrapper = ({
  iconPosition = "left",
  Icon,
  children,
  iconSpacing = 0,
  iconOnly = false,
  className = "",
  disabled = false,
}: IconPositionWrapperProps) => {
  const commonStyle = "flex flex-row w-full justify-center items-center p-0 m-0 max-h-fit h-fit"
  const finalStyle = twMerge([commonStyle, className].join(" "))
  let customMargin = iconPosition === "left" ? `0 ${iconSpacing}px 0 0` : `0 0 0 ${iconSpacing}px`
  const IconComponent = (): JSX.Element => (
    <>
      {Icon && (
        <div
          className={css`
            ${iconOnly ? "" : `margin: ${customMargin}`};
            height: fit-content;
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: ${disabled ? "0.3" : "1"};
          `}
        >
          {Icon}
        </div>
      )}
    </>
  )

  return (
    <div className={finalStyle}>
      {iconPosition === "left" && <IconComponent />}
      {children}
      {iconPosition === "right" && <IconComponent />}
    </div>
  )
}

export default IconPositionWrapper
