import React from "react"
import * as Tooltip from "@radix-ui/react-tooltip"
import { twMerge } from "tailwind-merge"
import { css } from "@emotion/css"

import { TooltipProps } from "CryptioUI/types/tooltip"
import { TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
// import Body from "../Typography/Body"

const TooltipUI = ({
  children,
  content,
  side = "bottom",
  alignment = "center",
  delayDuration = 700,
  classNameTrigger = "",
  disabled = false,
  noMaxWidth = false,
  classNameContent = "",
  maxWidth = 300,
}: TooltipProps): JSX.Element => {
  const tooltipStyle = "bg-grey-500 py-2 px-3 !text-white rounded z-[9999]"
  const maxWidthStyle = noMaxWidth ? "" : `${maxWidth}px`

  return (
    <Tooltip.Root delayDuration={delayDuration}>
      <Tooltip.Trigger data-testid="tooltip" className={classNameTrigger} asChild>
        {children}
      </Tooltip.Trigger>
      {!disabled && (
        <Tooltip.Content
          side={side}
          sideOffset={5}
          align={alignment}
          className={twMerge([tooltipStyle, classNameContent].join(" "))}
        >
          <div
            className={css`
              max-width: ${maxWidthStyle};
            `}
          >
            <TypographyUI variant={TypographyVariant.BODY}>{content}</TypographyUI>
          </div>
        </Tooltip.Content>
      )}
    </Tooltip.Root>
  )
}

export default TooltipUI
