import { Box, Button, Paper } from "@material-ui/core"
import { AddRounded, UpdateRounded } from "@material-ui/icons"
import { useToast } from "CryptioUI/Toaster"
import { iconStyleMaterialUI } from "CryptioUI/Utilities/config"
import React, { useContext } from "react"

import BaseContainer from "../../../components/Container"
import { LoadingButton } from "../../../components/LoadingButton"
import MainTitleView from "../../../components/MainTitleView"
import LoadingSpinner from "../../../components/misc/LoadingSpinner"
import NetworkErrorMessage from "../../../components/misc/NetworkErrorMessage"
import useDrawer from "../../../components/misc/useDrawer"
import PermissionDisabled from "../../../components/Permission/PermissionDisabled"
import PermissionReadView from "../../../components/Permission/PermissionReadView"
import { toastCatch } from "../../../components/ReactHookForm/utils"
import { GenericSorting } from "../../../components/Table/interface"
import ServerSideTable from "../../../components/Table/ServerSideTable"
import api from "../../../services/api"
import { GetImpairmentDto, GetImpairmentsRequest, GetImpairmentsSortByEnum } from "../../../services/api/openapi"
import { WorkspaceContext } from "../../../services/context/workspaceContext"
import { usePaginatedParams } from "../../../services/misc/usePaginatedParams"
import { useImpairmentColumns } from "./useImpairmentColumns"

export const defaultImpairmentSorting: GenericSorting<GetImpairmentsSortByEnum> = {
  sortDirection: "descending",
  sortBy: "end_date",
}

const ImpairmentScene = (): JSX.Element => {
  const workspaceCtx = useContext(WorkspaceContext)
  const { workspace } = workspaceCtx
  const toast = useToast()
  const { params, setLimit, setPage, setSorting } = usePaginatedParams<
    Partial<GetImpairmentsRequest>,
    GetImpairmentsSortByEnum
  >({}, defaultImpairmentSorting)
  const [impairmentDrawer, openImpairmentDrawer] = useDrawer("impairment")

  const viewTitle = "Impairment"
  const impairments = api.impairment.useImpairments(
    {
      page: params.page,
      limit: params.limit,
      sortBy: params.sortBy,
      sortDirection: params.sortDirection,
    },
    { refetchInterval: 2000 },
  )

  const { mutateAsync: updateCostBasisImpairmentMutation } = api.impairment.useUpdateCostBasis()

  const { columns, basicDialog } = useImpairmentColumns()

  if (!workspace.userRights.can_read_impairment)
    return <PermissionReadView viewTitle={viewTitle} itemName="impairments" />

  if (impairments.isError) return <NetworkErrorMessage small={false} additionalData={impairments} />

  if (impairments.isLoading || impairments.data === undefined) return <LoadingSpinner />

  const updateCostBasis = async () => {
    try {
      await updateCostBasisImpairmentMutation({})
      toast.open("Impairing cost basis...", { variant: "default" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  return (
    <BaseContainer>
      {impairmentDrawer}
      {basicDialog.dialog}
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="flex-end">
        <MainTitleView
          title={viewTitle}
          // helpTooltipContent=""
          // helpTooltipUrl=""
        />
        <Box justifyContent="center" display="flex" flexDirection="row" alignItems="center">
          <PermissionDisabled permission="can_update_cost_basis_impairment" action="update cost basis impairment">
            <LoadingButton
              pending={workspace.costBasisImpairment.status !== "inactive"}
              onClick={updateCostBasis}
              disabled={workspace.costBasisImpairment.status !== "inactive" || !workspace.lock.isEnabled}
              Icon={<UpdateRounded style={iconStyleMaterialUI} />}
            >
              Update cost basis impairment
            </LoadingButton>
          </PermissionDisabled>
          <Box ml={2}>
            <PermissionDisabled permission="can_create_impairment" action="create impairments">
              <Button
                disabled={!workspace.lock.isEnabled}
                onClick={() => openImpairmentDrawer(null)}
                startIcon={<AddRounded />}
              >
                Impairment
              </Button>
            </PermissionDisabled>
          </Box>
        </Box>
      </Box>

      <Paper>
        <ServerSideTable<GetImpairmentDto, GetImpairmentsSortByEnum>
          columns={columns}
          items={impairments.data.data}
          limit={impairments.data.limit}
          totalCount={impairments.data.totalCount}
          defaultParams={params}
          onPageChange={setPage}
          onLimitChange={setLimit}
          onSort={setSorting}
          onRowClicked={(row) => openImpairmentDrawer(row.id)}
        />
      </Paper>
    </BaseContainer>
  )
}

export default ImpairmentScene
