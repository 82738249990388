import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"

import { GetLabelDto } from "services/api/openapi"
import { pluralize } from "services/utils/textUtils"
import { correspondanceHex, newColorHex } from "CryptioUI/types/tag"

const useStyles = makeStyles(() =>
  createStyles({
    color: {
      display: "inline-block",
      width: 14,
      height: 14,
      flexShrink: 0,
      borderRadius: 3,
      marginRight: 8,
      marginTop: 2,
    },
    text: {
      flexGrow: 1,
    },
  }),
)

interface Props {
  label: GetLabelDto
}

const LabelOptionInAutocomplete = ({ label }: Props) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Typography variant="body1">
          <span
            className={classes.color}
            style={{ backgroundColor: newColorHex[correspondanceHex[label.color]] || undefined }}
          />
          <span className={classes.text}>{label.name}</span>
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {label.movementCount} {pluralize(label.movementCount > 1, "movement")}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default LabelOptionInAutocomplete
