import { useTypedController } from "@hookform/strictly-typed"
import { FormLabel, InputAdornment, makeStyles, Theme } from "@material-ui/core"
import { AccountCircleRounded } from "@material-ui/icons"
import qs from "qs"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useLocation } from "react-router-dom"

import { InputEmail } from "components/Input/InputEmail"
import PasswordField from "components/PasswordField"
import PasswordRequirementSentence from "components/PasswordRequirementSentence"
import { passwordRules } from "components/ReactHookForm/rules"
import { UserApi } from "services/api/openapi"
import { registrationFormId, RegistrationStepProps, RegistrationSteps } from "./state"
import { RequiredIndicator } from "./utils"

type RegisterForm = RegistrationSteps[1]

const useStyles = makeStyles((theme: Theme) => ({
  formInputField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))

export const Step1 = (props: RegistrationStepProps<1>) => {
  const classes = useStyles()
  const { search } = useLocation()

  const { onNext, onValid } = props

  const { control, handleSubmit, errors, formState, watch, setError, setValue, trigger } = useForm<RegisterForm>({
    mode: "onChange",
  })
  const TypedController = useTypedController<RegisterForm>({ control })
  const { password: watchPassword = "", confirmPassword: watchConfirmPassword = "" } = watch()

  const onSubmit = (newData: RegisterForm) => {
    onNext(newData)
  }

  const formValid = formState.isValid
  useEffect(() => {
    onValid(formValid)
  }, [formValid, onValid])

  useEffect(() => {
    if (watchPassword !== "") trigger("confirmPassword")
  }, [watchPassword, trigger])

  const { email } = qs.parse(search, { ignoreQueryPrefix: true })
  const defaultEmail = typeof email === "string" ? email : ""

  useEffect(() => {
    if (watchPassword !== "") trigger("confirmPassword")
  }, [watchPassword, trigger])

  return (
    <form id={registrationFormId(1)} onSubmit={handleSubmit(onSubmit)}>
      <FormLabel htmlFor="email-textfield">
        Email <RequiredIndicator />
      </FormLabel>
      <TypedController
        name="email"
        rules={{
          required: true,
        }}
        defaultValue={defaultEmail}
        render={(props) => (
          <InputEmail
            {...props}
            id="email-textfield"
            className={classes.formInputField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleRounded />
                </InputAdornment>
              ),
            }}
            fullWidth={true}
            error={errors?.email !== undefined}
            onBlur={async () => {
              const email = watch("email")
              if (email === "" || errors?.email !== undefined) return
              const res = await UserApi.isEmailAvailable({ email }).catch((_) => undefined)
              if (res?.isAvailable === false) {
                setError("email", { message: "Email address already taken" })
                setValue("email", watch("email"))
              }
            }}
            onError={({ message, value }) => {
              setError("email", { message })
              setValue("email", value)
            }}
            helperText={errors?.email?.message}
          />
        )}
      />

      <FormLabel htmlFor="password-textfield">
        Password <RequiredIndicator />
      </FormLabel>
      <TypedController
        name="password"
        rules={passwordRules}
        defaultValue=""
        render={(props) => (
          <PasswordField
            id="password-textfield"
            className={classes.formInputField}
            fullWidth
            {...props}
            error={errors?.password !== undefined}
          />
        )}
      />

      <FormLabel htmlFor="confirm-password-textfield">
        Confirm password <RequiredIndicator />
      </FormLabel>
      <TypedController
        name="confirmPassword"
        defaultValue=""
        rules={{
          ...passwordRules,
          validate: (confirmPassword: string) => confirmPassword === watchPassword,
        }}
        render={(props) => (
          <PasswordField
            id="confirm-password-textfield"
            className={classes.formInputField}
            fullWidth
            {...props}
            error={errors?.confirmPassword !== undefined}
          />
        )}
      />

      <PasswordRequirementSentence password={watchPassword} confirmPassword={watchConfirmPassword} />
    </form>
  )
}
