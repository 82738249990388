export enum BannerMode {
  INFO = "Info",
  WARNING = "Warning",
  ERROR = "Error",
}

export interface BannerUIProps {
  Icon?: React.ComponentType
  iconPosition?: "left" | "right"
  mode: BannerMode
  children: JSX.Element
  className?: string
  isOpen: boolean
  onClose: (isOpen: boolean) => void
}

export type BannerModeType = {
  [key in BannerMode]: string
}
