import { Avatar, Box, Grid, Typography } from "@material-ui/core"
import { ImageOutlined } from "@material-ui/icons"
import { prettyEmptyOrNull } from "pure-shared"
import React, { useState } from "react"

import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import { DrawerProp } from "components/misc/useDrawer"
import PermissionRemapAsset from "components/Permission/PermissionRemapAsset"
import CustomSelector from "components/selector/CustomSelector"
import ButtonUI from "CryptioUI/Button"
import api from "services/api"
import { GetPublicAssetDto } from "services/api/openapi/models"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const AssetAdminRemapAsset = (props: DrawerProp<GetPublicAssetDto, false>) => {
  const { item: asset, onClose } = props
  const toast = useToast()

  const basicDialog = useDialog()

  const [remapToAsset, setRemapToAsset] = useState<GetPublicAssetDto | null>(null)
  const { mutateAsync: remapUnknownAssetMutation } = api.backOffice.asset.useRemapUnknownAsset()
  const assetLinks = api.backOffice.asset.useAssetLinks(asset.id)
  if (assetLinks.isError) return <NetworkErrorMessage additionalData={assetLinks} />
  if (assetLinks.isLoading || assetLinks.data === undefined) return <LoadingSpinner />
  const doAssetRemap = async () => {
    try {
      if (!remapToAsset || asset.type !== "exchange_unknown") {
        return
      }
      if (remapToAsset.type === "exchange_unknown") {
        toast.open("Cannot remap an exchange_unknown asset to another exchange_unknown", { variant: "danger" })
        return
      }
      toast.open("Remapping asset, it can take some time", {
        variant: "default",
      })
      await remapUnknownAssetMutation({
        unknownAssetId: asset.id,
        toAssetId: remapToAsset.id,
      })
      toast.open("Asset remapped", { variant: "success" })
      onClose()
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const askAssetRemap = () => {
    basicDialog.showDialog({
      title: "Remap occurrences?",
      content: (
        <Typography variant="h5">
          Do you really want to remap all occurrences of asset <b>{asset.uniqueSymbol}</b>?
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doAssetRemap,
    })
  }

  return (
    <DrawerCategory title="Remap to asset">
      {basicDialog.dialog}

      <DrawerFormSection name="Asset" htmlFor="remap-asset-select">
        <CustomSelector
          id="remap-asset-select"
          value={remapToAsset}
          onChange={(_, newValue) => setRemapToAsset(newValue)}
          disabled={asset.type !== "exchange_unknown"}
          filterOptions={(x) => x}
          minInputLengthForScroll={2}
          // onChange={(_, newValue) => onChange(newValue)}
          getOptionLabel={(option) => option.name || prettyEmptyOrNull(option.symbol)}
          defaultPaginatedQueryProps={{
            sortBy: "market_cap_rank",
            sortDirection: "ascending",
            excludedIds: [asset.id],
          }}
          usePaginatedQuery={api.asset.usePublicAssets}
          size="small"
          placeholder="Type to search..."
          renderOption={(option) => (
            <Grid container alignItems="center">
              <Grid item xs>
                <Box display="flex" alignItems="center">
                  <Box mr={2}>
                    {option.logoUrl ? (
                      <Avatar
                        style={{ width: 16, height: 16 }}
                        alt={prettyEmptyOrNull(option.symbol)}
                        src={option.logoUrl}
                      />
                    ) : (
                      <Avatar style={{ width: 16, height: 16 }} alt={prettyEmptyOrNull(option.symbol)}>
                        <ImageOutlined />
                      </Avatar>
                    )}
                  </Box>
                  <Typography variant="body1">{prettyEmptyOrNull(option.symbol)}</Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {prettyEmptyOrNull(option.name)} ({option.uniqueSymbol})
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Market cap rank: {option.marketCapRank ? `#${option.marketCapRank}` : "-"} | {option.type}
                </Typography>
              </Grid>
            </Grid>
          )}
        />
      </DrawerFormSection>
      <Box mt={3}>
        <PermissionRemapAsset assetLinks={assetLinks.data}>
          <ButtonUI onClick={askAssetRemap} disabled={remapToAsset === null}>
            Remap asset
          </ButtonUI>
        </PermissionRemapAsset>
      </Box>
    </DrawerCategory>
  )
}

export default AssetAdminRemapAsset
