import React from "react"
import { Helmet } from "react-helmet"

export function DefaultTitle() {
  return (
    <Helmet>
      <title>Cryptio</title>
      <meta
        name="description"
        content="Institutional-grade accounting, audit, and tax software for your crypto assets."
      />
    </Helmet>
  )
}

export default DefaultTitle
