import { Link, LinkProps } from "@material-ui/core"
import React from "react"

import { LinkColor } from "../materialTheme"

const ExternalLink = (props: LinkProps): JSX.Element => {
  return <Link {...props} style={{ color: LinkColor }} target="_blank" rel="noopener noreferrer" />
}

export default ExternalLink
