import React from "react"
import { twMerge } from "tailwind-merge"

import { InputCheckboxUiProps } from "CryptioUI/types/checkbox"

const InputCheckbox = (props: InputCheckboxUiProps): JSX.Element => {
  const { value, label = undefined, name, checked, handleChange, className = "", disabled = false, style = {} } = props
  const commonStyle = [
    "w-fit flex items-center text-sm font-medium font-normal",
    checked ? "accent-grey-500" : "accent-grey-100",
  ].join(" ")

  const disabledStyle = "text-grey-300"
  const finalStyle = twMerge([commonStyle, disabled ? disabledStyle : null, className].join(" "))

  return (
    <div className={finalStyle}>
      <input
        className={`${label ? "mr-2" : ""} cursor-pointer border-grey-300 border-2 w-4 h-4`}
        style={style}
        onChange={() => handleChange(!checked)}
        type="checkbox"
        value={value}
        id={value}
        name={name}
        checked={checked}
        disabled={disabled}
      />
      {label && (
        <label className="cursor-pointer" htmlFor={value}>
          {label}
        </label>
      )}
    </div>
  )
}

export default InputCheckbox
