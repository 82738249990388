/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import { BackOfficeCreatePlanDto, BackOfficeGetFullPlanDto, BackOfficePaginatedPlansResultDto } from "../models"

export interface BackOfficeCreatePlanRequest {
  backOfficeCreatePlanDto: BackOfficeCreatePlanDto
}

export interface BackOfficeGetPlanRequest {
  planId: string
}

export interface BackOfficeGetPlansRequest {
  page: number
  limit: number
  query?: string
  sortBy?: BackOfficeGetPlansSortByEnum
  sortDirection?: BackOfficeGetPlansSortDirectionEnum
  minimumActiveSubscription?: number
  minimumAllTimeSubscription?: number
  planId?: string
  excludedIds?: Array<string>
}

export interface BackOfficeToggleVisibilityRequest {
  planId: string
}

/**
 *
 */
export class BackOfficeBillingApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static backOfficeCreatePlan(params: BackOfficeCreatePlanRequest) {
    return localBackOfficeBillingApi.backOfficeCreatePlan(params)
  }

  /**
   */
  backOfficeCreatePlan({ backOfficeCreatePlanDto }: BackOfficeCreatePlanRequest): Promise<void> {
    return this.restInstance.post(`/back-office/billing`, backOfficeCreatePlanDto, true)
  }

  static backOfficeGetPlan(params: BackOfficeGetPlanRequest) {
    return localBackOfficeBillingApi.backOfficeGetPlan(params)
  }

  /**
   */
  backOfficeGetPlan({ planId }: BackOfficeGetPlanRequest): Promise<BackOfficeGetFullPlanDto> {
    return this.restInstance.get(`/back-office/billing/${encodeURIComponent(planId)}`)
  }

  static backOfficeGetPlans(params: BackOfficeGetPlansRequest) {
    return localBackOfficeBillingApi.backOfficeGetPlans(params)
  }

  /**
   * Get subscription plans
   */
  backOfficeGetPlans({ ...queryParameters }: BackOfficeGetPlansRequest): Promise<BackOfficePaginatedPlansResultDto> {
    return this.restInstance.get(`/back-office/billing?${this.restInstance.encodeObjectToUrl(queryParameters)}`)
  }

  static backOfficeToggleVisibility(params: BackOfficeToggleVisibilityRequest) {
    return localBackOfficeBillingApi.backOfficeToggleVisibility(params)
  }

  /**
   */
  backOfficeToggleVisibility({ planId }: BackOfficeToggleVisibilityRequest): Promise<void> {
    return this.restInstance.put(`/back-office/billing/${encodeURIComponent(planId)}/visibilty`, undefined, true)
  }
}

const localBackOfficeBillingApi = new BackOfficeBillingApi()

/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetPlansSortByEnum =
  | "name"
  | "created_at"
  | "usd_price_per_month"
  | "usd_price_per_year"
  | "active_subscription_count"
  | "all_time_subscription_count"
/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetPlansSortDirectionEnum = "ascending" | "descending"
