import { Box, Typography } from "@material-ui/core"
import React from "react"
import { MovementLabelDto, NewInListMovementDto } from "services/api/openapi"
import { pluralize } from "services/utils/textUtils"
import { mockInternalTransferLabel } from "../../TransactionLabelDrawer/NonTaxabelLabelSelector"
import LabelsView, { InternalLabelView } from "./LabelsView"
import TagLabel from "./TagLabel"
import TooltipUI from "CryptioUI/Tooltip"
import { GetMovementDto } from "../../../../services/api/routes/transactions"

interface Props {
  labels: MovementLabelDto[]
  isComplex: boolean
  isDevelopped: boolean
  transactionId: string
  movementId: string
  openMovementLabelDrawer: <PassedItemId extends GetMovementDto>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  openTransactionLabelDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  mainMovement: NewInListMovementDto | undefined
}

export const Label = (props: Props) => {
  const {
    labels,
    openMovementLabelDrawer,
    movementId,
    isComplex,
    transactionId,
    isDevelopped,
    openTransactionLabelDrawer,
    mainMovement,
  } = props

  const uniqLabels = labels.filter((value, index, self) => index === self.findIndex((t) => t.labelId === value.labelId))

  const tooltopCollapsedTransaction = labels.map((label) => {
    return (
      <div className="flex py-1" key={label.labelId}>
        <TagLabel
          label={label}
          onClick={() => {
            return
          }}
        />
      </div>
    )
  })

  if (mainMovement?.isInternalTransfer) {
    tooltopCollapsedTransaction.push(
      <Box key={mockInternalTransferLabel.id} py={0.4}>
        <InternalLabelView
          label={mockInternalTransferLabel}
          isAutomatic={mainMovement?.isInternalTransferSystemAttributed}
          onClick={() => {
            return
          }}
        />
      </Box>,
    )
  }

  if (isComplex) {
    return (
      <TooltipUI
        content={labels.length ? <>{tooltopCollapsedTransaction}</> : ""}
        classNameContent="flex flex-col justify-center items-center"
        noMaxWidth
      >
        <Typography variant="body2" color="primary">
          {labels.length === 0 ? "No label" : `${labels.length} ${pluralize(labels.length > 1, "label")}`}
        </Typography>
      </TooltipUI>
    )
  }

  if (isDevelopped) {
    return (
      <LabelsView
        labels={uniqLabels}
        onClick={() => openMovementLabelDrawer({ movementId })}
        mainMovement={mainMovement}
      />
    )
  }
  return (
    <LabelsView
      labels={uniqLabels}
      onClick={() => openTransactionLabelDrawer(transactionId)}
      mainMovement={mainMovement}
    />
  )
}

export default Label
