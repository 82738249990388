/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  BackOfficeBreakTrade,
  BackOfficeQuickbooksAutoIncrementDto,
  BackOfficeUpdateTransactionListValuation,
  BackOfficeUpdateTransactionValuation,
  GetMovementMetadataDto,
  GetTransactionMetadataDto,
  GetWalletCredentialsDto,
} from "../models"

export interface BackOfficeBreakTradeRequest {
  workspaceId: string
  backOfficeBreakTrade: BackOfficeBreakTrade
}

export interface BackOfficeDecryptWalletCredentialsRequest {
  walletId: string
}

export interface BackOfficeGetQuickbooksAutoIncrementalRequest {
  workspaceId: string
}

export interface BackOfficeMovementMetadataRequest {
  workspaceId: string
  movementId: string
}

export interface BackOfficeToggleQuickbooksAutoIncrementRequest {
  workspaceId: string
}

export interface BackOfficeTransactionMetadataRequest {
  workspaceId: string
  transactionId: string
}

export interface BackOfficeUpdateLabelRuleRequest {
  workspaceId: string
  labelId: string
}

export interface BackOfficeUpdateTransactionValuationRequest {
  workspaceId: string
  backOfficeUpdateTransactionValuation: BackOfficeUpdateTransactionValuation
}

export interface BackOfficeUpdateTransactionsValuationRequest {
  workspaceId: string
  backOfficeUpdateTransactionListValuation: BackOfficeUpdateTransactionListValuation
}

/**
 *
 */
export class BackOfficeApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static backOfficeBreakTrade(params: BackOfficeBreakTradeRequest) {
    return localBackOfficeApi.backOfficeBreakTrade(params)
  }

  /**
   * break trades into movements
   */
  backOfficeBreakTrade({ backOfficeBreakTrade, workspaceId }: BackOfficeBreakTradeRequest): Promise<void> {
    return this.restInstance.post(`/back-office/${encodeURIComponent(workspaceId)}/break`, backOfficeBreakTrade, true)
  }

  static backOfficeDecryptWalletCredentials(params: BackOfficeDecryptWalletCredentialsRequest) {
    return localBackOfficeApi.backOfficeDecryptWalletCredentials(params)
  }

  /**
   * get decrypted credentials
   */
  backOfficeDecryptWalletCredentials({
    walletId,
  }: BackOfficeDecryptWalletCredentialsRequest): Promise<GetWalletCredentialsDto> {
    return this.restInstance.get(`/back-office/decrypt/${encodeURIComponent(walletId)}`)
  }

  static backOfficeGetQuickbooksAutoIncremental(params: BackOfficeGetQuickbooksAutoIncrementalRequest) {
    return localBackOfficeApi.backOfficeGetQuickbooksAutoIncremental(params)
  }

  /**
   * get auto increment status of quickbooks integration
   */
  backOfficeGetQuickbooksAutoIncremental({
    workspaceId,
  }: BackOfficeGetQuickbooksAutoIncrementalRequest): Promise<BackOfficeQuickbooksAutoIncrementDto> {
    return this.restInstance.get(`/back-office/${encodeURIComponent(workspaceId)}/quickbooks/auto-increment`)
  }

  static backOfficeMovementMetadata(params: BackOfficeMovementMetadataRequest) {
    return localBackOfficeApi.backOfficeMovementMetadata(params)
  }

  /**
   * get movement metadata
   */
  backOfficeMovementMetadata({
    workspaceId,
    movementId,
  }: BackOfficeMovementMetadataRequest): Promise<GetMovementMetadataDto> {
    return this.restInstance.get(
      `/back-office/${encodeURIComponent(workspaceId)}/movement/metadata/${encodeURIComponent(movementId)}`,
    )
  }

  static backOfficeToggleQuickbooksAutoIncrement(params: BackOfficeToggleQuickbooksAutoIncrementRequest) {
    return localBackOfficeApi.backOfficeToggleQuickbooksAutoIncrement(params)
  }

  /**
   * toggle auto increment status of quickbooks integration
   */
  backOfficeToggleQuickbooksAutoIncrement({
    workspaceId,
  }: BackOfficeToggleQuickbooksAutoIncrementRequest): Promise<void> {
    return this.restInstance.post(
      `/back-office/${encodeURIComponent(workspaceId)}/quickbooks/auto-increment`,
      undefined,
      true,
    )
  }

  static backOfficeTransactionMetadata(params: BackOfficeTransactionMetadataRequest) {
    return localBackOfficeApi.backOfficeTransactionMetadata(params)
  }

  /**
   * get transaction metadata
   */
  backOfficeTransactionMetadata({
    workspaceId,
    transactionId,
  }: BackOfficeTransactionMetadataRequest): Promise<GetTransactionMetadataDto> {
    return this.restInstance.get(
      `/back-office/${encodeURIComponent(workspaceId)}/transaction/metadata/${encodeURIComponent(transactionId)}`,
    )
  }

  static backOfficeUpdateLabelRule(params: BackOfficeUpdateLabelRuleRequest) {
    return localBackOfficeApi.backOfficeUpdateLabelRule(params)
  }

  /**
   * update label rule
   */
  backOfficeUpdateLabelRule({ workspaceId, labelId }: BackOfficeUpdateLabelRuleRequest): Promise<void> {
    return this.restInstance.put(
      `/back-office/${encodeURIComponent(workspaceId)}/label/rule/${encodeURIComponent(labelId)}`,
      undefined,
      true,
    )
  }

  static backOfficeUpdateTransactionValuation(params: BackOfficeUpdateTransactionValuationRequest) {
    return localBackOfficeApi.backOfficeUpdateTransactionValuation(params)
  }

  /**
   * update wallets valuation
   */
  backOfficeUpdateTransactionValuation({
    backOfficeUpdateTransactionValuation,
    workspaceId,
  }: BackOfficeUpdateTransactionValuationRequest): Promise<void> {
    return this.restInstance.post(
      `/back-office/${encodeURIComponent(workspaceId)}/wallet/valuation`,
      backOfficeUpdateTransactionValuation,
      true,
    )
  }

  static backOfficeUpdateTransactionsValuation(params: BackOfficeUpdateTransactionsValuationRequest) {
    return localBackOfficeApi.backOfficeUpdateTransactionsValuation(params)
  }

  /**
   * update valuation of a list of transactions
   */
  backOfficeUpdateTransactionsValuation({
    backOfficeUpdateTransactionListValuation,
    workspaceId,
  }: BackOfficeUpdateTransactionsValuationRequest): Promise<void> {
    return this.restInstance.post(
      `/back-office/${encodeURIComponent(workspaceId)}/transactions/valuation`,
      backOfficeUpdateTransactionListValuation,
      true,
    )
  }
}

const localBackOfficeApi = new BackOfficeApi()
