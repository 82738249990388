import { useTypedController } from "@hookform/strictly-typed"
import { Box, Checkbox, FormLabel, makeStyles, Theme, Typography } from "@material-ui/core"
import { HelpRounded } from "@material-ui/icons"
import { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { DrawerCategory } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import api from "services/api"
import { GetWorkspaceDto } from "services/api/openapi"
import TooltipUI from "CryptioUI/Tooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const helpTextMappingRestrictedOption =
  "By de-selecting the restriction, you will allow the manual chart of accounts mapping to be mapped to any account type"

const helpTextCryptioIndicatorOption =
  'Activate this option if you want Cryptio to add a "CR" indicator on the memo of your journal entry so you can know which transactions have been synchronized from Cryptio into your accounting system.'

const useStyles = makeStyles((theme: Theme) => ({
  tooltipIcon: {
    marginLeft: theme.spacing(1.5),
  },
  helpButton: {
    "&:hover": {
      cursor: "help",
    },
  },
}))

export interface WorkspaceAccountingOptions {
  workspace: GetWorkspaceDto
  setFormDirty: (newValue: boolean) => void
}

interface Form {
  areChartAccountMappingsRestricted: boolean | undefined
  addCryptioPrefixInAccountingJournal: boolean | undefined
}

export function WorkspaceAccountingOptions({ workspace, setFormDirty }: WorkspaceAccountingOptions) {
  const classes = useStyles()

  const { handleSubmit, control, formState, watch } = useForm<Form>({ mode: "onChange" })
  const TypedController = useTypedController<Form>({ control })

  const { mutateAsync: updateWorkspaceAccountingOptionsMutation } = api.workspace.useUpdateWorkspaceAccountingOptions()
  const [SaveWorkspaceButton, handleButtonCallback] = useLoadingButton()
  const toast = useToast()

  const watchAllFields = watch()
  const isFormDirty =
    (formState.isValid &&
      watchAllFields.areChartAccountMappingsRestricted !== workspace.areChartAccountMappingsRestricted) ||
    (watchAllFields.addCryptioPrefixInAccountingJournal !== undefined &&
      watchAllFields.addCryptioPrefixInAccountingJournal !== workspace.addCryptioPrefixInAccountingJournal)

  useEffect(() => setFormDirty(isFormDirty), [isFormDirty, setFormDirty])

  const onSubmit = useCallback(
    async ({ addCryptioPrefixInAccountingJournal, areChartAccountMappingsRestricted }: Form) => {
      try {
        await updateWorkspaceAccountingOptionsMutation({
          workspaceId: workspace.id,
          updateWorkspaceAccountingOptionsDto: {
            addCryptioPrefixInAccountingJournal:
              addCryptioPrefixInAccountingJournal ?? workspace.addCryptioPrefixInAccountingJournal,
            areChartAccountMappingsRestricted:
              areChartAccountMappingsRestricted ?? workspace.areChartAccountMappingsRestricted,
          },
        })
        toast.open("Workspace updated", { variant: "success" })
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [updateWorkspaceAccountingOptionsMutation, toast, workspace],
  )

  return (
    <DrawerCategory
      component="form"
      onSubmit={handleButtonCallback(handleSubmit(onSubmit))}
      title="Accounting integration"
    >
      <Box display="flex" alignItems="center" mt={3}>
        <TypedController
          name="areChartAccountMappingsRestricted"
          defaultValue={workspace.areChartAccountMappingsRestricted}
          rules={{ required: false }}
          render={({ onChange, value, ...rest }) => (
            <PermissionDisabled permission="can_restrict_mapping" action="restrict mapping">
              <Checkbox
                color="primary"
                id="restricted-mappings-checkbox"
                style={{ paddingLeft: 0 }}
                onChange={() => onChange(!value)}
                checked={value}
                {...rest}
              />
            </PermissionDisabled>
          )}
        />
        <FormLabel htmlFor="restricted-mappings-checkbox">Are mappings restricted&nbsp;?</FormLabel>
        <TooltipUI
          classNameTrigger={classes.tooltipIcon}
          content={<Typography>{helpTextMappingRestrictedOption}</Typography>}
        >
          <HelpRounded className={classes.helpButton} />
        </TooltipUI>
      </Box>

      {workspace.accountingIntegration && workspace.accountingIntegration.name !== "custom" && (
        <Box display="flex" alignItems="center" mt={3}>
          <TypedController
            name="addCryptioPrefixInAccountingJournal"
            defaultValue={workspace.addCryptioPrefixInAccountingJournal}
            rules={{ required: false }}
            render={({ onChange, value, ...rest }) => (
              <PermissionDisabled permission="can_add_journal_entry_indicator" action="add cryptio indicator">
                <Checkbox
                  color="primary"
                  id="cryptio-indicator-checkbox"
                  style={{ paddingLeft: 0 }}
                  onChange={() => onChange(!value)}
                  checked={value}
                  {...rest}
                />
              </PermissionDisabled>
            )}
          />
          <FormLabel htmlFor="cryptio-indicator-checkbox">Cryptio indicator in journal entry</FormLabel>
          <TooltipUI
            classNameTrigger={classes.tooltipIcon}
            content={<Typography>{helpTextCryptioIndicatorOption}</Typography>}
          >
            <HelpRounded className={classes.helpButton} />
          </TooltipUI>
        </Box>
      )}

      <Box mt={2} display="flex" justifyContent="space-between">
        <SaveWorkspaceButton disabled={!isFormDirty} type="submit">
          Save
        </SaveWorkspaceButton>
      </Box>
    </DrawerCategory>
  )
}
