import { NFTExplorer, nftExplorers } from "pure-shared"
import ExternalLink from "../../ExternalLink"
import { makeStyles, Typography } from "@material-ui/core"

interface Props {
  address: string
  tokenId: string
  sourceName: string
}

const useStyles = makeStyles(() => ({
  content: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}))

const NftLink = ({ address, tokenId, sourceName }: Props) => {
  const classes = useStyles()

  const explorer = nftExplorers.find((exp: NFTExplorer) => exp.sourceName === sourceName)

  return (
    <Typography component="span" variant="body2" className={classes.content}>
      {explorer && (
        <ExternalLink href={explorer.getLink(address, tokenId)}>
          See NFT page on {explorer.displayableName}
        </ExternalLink>
      )}
    </Typography>
  )
}

export default NftLink
