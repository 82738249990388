import React, { SVGProps } from "react"

import * as svg from "./svg"

export interface IconProps {
  name: keyof typeof svg
  disabled?: boolean
}

function Icon({ name, disabled = false, ...props }: IconProps & SVGProps<any>): JSX.Element {
  const MySvg = svg[name]
  const color = disabled ? "#E9EAF0" : props?.color

  return <MySvg {...props} color={color} />
}

export default Icon
