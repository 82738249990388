import { useTypedController } from "@hookform/strictly-typed"
import { Box, InputLabel, makeStyles, MenuItem, Select, TextField, Theme, Typography } from "@material-ui/core"
import { WarningRounded } from "@material-ui/icons"
import { ColorHex } from "pure-shared"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import ContactOptionInAutocomplete from "../../../../components/AutoCompleteOptions/ContactOptionInAutocomplete"
import WalletOptionInAutocomplete from "../../../../components/AutoCompleteOptions/WalletOptionInAutocomplete"
import { DrawerCategory, DrawerFormSection } from "../../../../components/Drawer/DrawerItems"
import LabelColorSelector from "../../../../components/Labels/LabelColorSelector"
import { useLoadingButton, UncontrolledLoadingButton } from "../../../../components/LoadingButton"
import useDialog from "../../../../components/misc/useDialog"
import { DrawerProp } from "../../../../components/misc/useDrawer"
import PermissionDisabled from "../../../../components/Permission/PermissionDisabled"
import PermissionText from "../../../../components/Permission/PermissionText"
import CustomSelector from "../../../../components/selector/CustomSelector"
import { Mode } from "../../../../CryptioUI/types"
import api from "../../../../services/api"
import { BasicElement, WithoutWorkspaceId } from "../../../../services/api/aliases"
import {
  CreateLabelDto,
  GetLabelDto,
  LabelAssociationDtoTypeEnum,
  GetContactDto,
  GetWalletDto,
  GetFiltersRequest,
  GetTransactionFilterDto,
  GetWalletsRequest,
  GetContactsRequest,
} from "../../../../services/api/openapi"
import { WorkspaceContext } from "../../../../services/context/workspaceContext"
import { Mixpanel } from "../../../../services/mixpanel"
import { ReactComponent as Delete } from "CryptioUI/assets/icons/delete.svg"
import { iconStyleBlack } from "CryptioUI/Utilities/config"
import ConditionalTooltip from "components/ConditionalTooltip"
import _ from "lodash"
import LabelRuleEditor from "components/Labels/LabelRuleEditor"
import ButtonModalImportCsv from "scenes/Labels/ModalImportCsv"
import LoadingSpinner from "components/misc/LoadingSpinner"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  formNameField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },
  linkIcon: {
    display: "inline-flex",
    verticalAlign: "top",
    marginLeft: theme.spacing(1),
  },
  csvExampleMenuItem: {
    padding: 0,
    marginTop: theme.spacing(3),
    textAlign: "end",
  },
  warningIcon: {
    marginRight: theme.spacing(1),
  },
  warningText: {
    textJustify: "auto",
  },
  associationLabel: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
  },
  associationLabelSelector: {
    marginRight: theme.spacing(1),
  },
}))

interface FormType extends Pick<CreateLabelDto, "name" | "color"> {
  savedFilter: GetTransactionFilterDto
}

const Edition = ({ item: label, isOpen, onClose, setFormDirty }: DrawerProp<GetLabelDto, true>) => {
  const classes = useStyles()
  const { handleSubmit, control, formState, reset, watch, setError } = useForm<FormType>({ mode: "onChange" })
  const toast = useToast()
  const TypedController = useTypedController<FormType>({ control })
  const workspaceCtx = useContext(WorkspaceContext)

  const [fromType, setFromType] = useState<LabelAssociationDtoTypeEnum>("contact")
  const [toType, setToType] = useState<LabelAssociationDtoTypeEnum>("wallet")

  const [walletFrom, setWalletFrom] = useState<BasicElement[] | null>(null)
  const [walletTo, setWalletTo] = useState<BasicElement[] | null>(null)
  const [contactFrom, setContactFrom] = useState<BasicElement[] | null>(null)
  const [contactTo, setContactTo] = useState<BasicElement[] | null>(null)
  const [savedFilter, setSavedFilter] = useState<GetTransactionFilterDto | undefined>(undefined)
  const [isSavedFilter, setIsSavedFilter] = useState(label?.filter !== null)

  const filters = api.filter.useFilters({
    page: 1,
    limit: 10,
  })

  const isLabelWithoutRule = label
    ? label.filter === null && !label.isGlobal && label.from === null && label.to === null
    : true
  const basicDialog = useDialog()
  const watchAllFields = watch()
  const isAssociationEqual = (newAssociation: BasicElement[] | null, oldAssociation: BasicElement[] | undefined) => {
    return _.isEqual(
      [...(newAssociation ?? [])].map((item) => item.id).sort(),
      [...(oldAssociation ?? [])].map((item) => item.id).sort(),
    )
  }
  const isFormDirty =
    !!(
      (formState.isValid &&
        formState.isDirty &&
        (watchAllFields.name !== label?.name || watchAllFields.color !== label?.color)) ||
      (watchAllFields.savedFilter && watchAllFields.savedFilter.id !== label?.filter?.id)
    ) ||
    (label &&
      (!isAssociationEqual(fromType === "contact" ? contactFrom : walletFrom, label.from?.items) ||
        !isAssociationEqual(toType === "contact" ? contactTo : walletTo, label.to?.items)))

  useEffect(() => setFormDirty(isFormDirty ?? false), [isFormDirty, setFormDirty])

  const { mutateAsync: updateLabelMutation } = api.label.useUpdateLabels()
  const { mutateAsync: deleteLabelMutation } = api.label.useDeleteLabel()

  useEffect(() => {
    if (isOpen) {
      reset()
      setContactFrom(label?.from?.type === "contact" ? label.from.items : null)
      setContactTo(label?.to?.type === "contact" ? label.to.items : null)
      setWalletFrom(label?.from?.type === "wallet" ? label.from.items : null)
      setWalletTo(label?.to?.type === "wallet" ? label.to.items : null)
      setFromType(label?.from?.type || "wallet")
      setToType(label?.to?.type || "wallet")

      const savedFilterFound = filters.data?.data.find((filter) => filter.id === label?.filter?.id)

      if (savedFilterFound) setSavedFilter(savedFilterFound)
    }
  }, [isOpen, label, reset, filters.data?.data, setSavedFilter])

  if (fromType === "contact" && toType === "contact") throw new Error("from & to cannot be both contact")

  const [SaveLabelButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = useCallback(
    async ({ savedFilter, color, name }: FormType) => {
      try {
        if (label) {
          await updateLabelMutation({
            labelId: label.id,
            updateLabelDto: {
              color: color,
              name: name,
              contactFromIds: contactFrom?.map((item) => item.id) ?? null,
              contactToIds: contactTo?.map((item) => item.id) ?? null,
              walletFromIds: walletFrom?.map((item) => item.id) ?? null,
              walletToIds: walletTo?.map((item) => item.id) ?? null,
              filterId: savedFilter?.id ?? null,
              isRuleBroken: label.isRuleBroken,
            },
          })

          toast.open("Label updated", { variant: "success" })
        }
        onClose()
      } catch (e) {
        toastCatch(e, toast)
      }
      Mixpanel.track("EditLabel")
    },
    [onClose, label, updateLabelMutation, toast, contactTo, contactFrom, walletTo, walletFrom],
  )

  const doDeleteLabel = async () => {
    if (!label) return
    try {
      await deleteLabelMutation({ labelId: label.id })
      toast.open("Label deleted", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
    onClose()
  }

  const askDeleteLabel = () => {
    if (!label) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          Do you really want to delete the label <b>{label.name}</b>? This action is irreversible.
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doDeleteLabel,
    })
  }
  if (!label) return <LoadingSpinner />
  return (
    <DrawerCategory title={"Label edition"} onSubmit={handleButtonCallback(handleSubmit(onSubmit))} component="form">
      {basicDialog.dialog}
      <PermissionText permission={"can_modify_label"} action={"edit labels"} />
      <DrawerFormSection display="flex" flexDirection="column" htmlFor="name-textfield" name="Name">
        <Box display="flex" alignItems="flex-start">
          <TypedController
            name="name"
            defaultValue={label.name || ""}
            rules={{
              required: true,
              validate: (value) => value.trim().length >= 3,
            }}
            render={(props) => (
              <TextField
                id="name-textfield"
                className={classes.formNameField}
                {...props}
                placeholder="Name"
                onBlur={async (x) => {
                  const labelName = x.target.value.trim()

                  if (labelName === label.name || labelName === "" || formState.errors.name !== undefined) return

                  const res = await api.label
                    .isLabelNameAvailable({ workspaceId: workspaceCtx.workspace.id, name: labelName })
                    .catch(() => undefined)

                  if (res?.isAvailable === false) {
                    setError("name", { message: "This label name already exists" })
                  }
                }}
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message}
              />
            )}
          />
          <TypedController
            name="color"
            defaultValue={label.color || ColorHex.blue}
            rules={{ required: true }}
            render={({ value, onChange }) => <LabelColorSelector value={value} onChange={onChange} />}
          />
        </Box>
      </DrawerFormSection>

      <Box /* component={Paper} */ display="flex" mt={3}>
        <WarningRounded className={classes.warningIcon} />
        <Typography variant="body2" className={classes.warningText}>
          {label.isRuleBroken
            ? "The rule of this label has been broken because at least one component of the rule has been deleted."
            : "Editing the rules of a label will remove this label from all the transactions it is assigned to."}
        </Typography>
      </Box>
      {!label.isRuleBroken && (
        <>
          {isLabelWithoutRule && <LabelRuleEditor isSavedFilter={isSavedFilter} onChange={setIsSavedFilter} />}
          {!isSavedFilter ? (
            <>
              <Box component="section">
                <ConditionalTooltip
                  tooltipMessage="This rule can't be updated as it would impact the labelling of locked transactions. To modify this rule, please unlock your period."
                  disabled={label.isAppliedInLockedPeriod}
                >
                  <div>
                    <Box className={classes.associationLabel}>
                      <Select
                        labelId="label-from-select-label"
                        id="label-from-select"
                        disabled={label.isAppliedInLockedPeriod}
                        className={classes.associationLabelSelector}
                        value={fromType}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                          setFromType(event.target.value as LabelAssociationDtoTypeEnum)
                          setContactFrom(null)
                          setWalletFrom(null)
                          // A user cannot set from:contact and to:contact
                          if (event.target.value === "contact" && toType === "contact") {
                            setToType("wallet")
                            setContactTo(null)
                            setWalletTo(null)
                          }
                        }}
                      >
                        <MenuItem value={"contact"}>Contact</MenuItem>
                        <MenuItem value={"wallet"}>Wallet</MenuItem>
                      </Select>
                      <InputLabel id="label-from-select-label">From</InputLabel>
                    </Box>
                    {fromType === "contact" && (
                      <CustomSelector<WithoutWorkspaceId<GetContactsRequest>, BasicElement, true>
                        fullWidth
                        disabled={label.isAppliedInLockedPeriod}
                        value={contactFrom ?? []}
                        onChange={(_, newValue) => setContactFrom(newValue.length > 0 ? newValue : null)}
                        getOptionLabel={(option) => option.name}
                        defaultPaginatedQueryProps={{
                          sortBy: "movement_count",
                          sortDirection: "descending",
                          excludedIds: contactFrom?.map((contact) => contact.id),
                        }}
                        multiple
                        getOptionDisabled={contactFrom && contactFrom.length >= 50 ? () => true : () => false}
                        disableCloseOnSelect
                        filterSelectedOptions
                        getOptionSelected={(option, value) => option.id === value.id}
                        usePaginatedQuery={api.contact.useContacts}
                        size="small"
                        placeholder="Type to search..."
                        renderOption={(option) => <ContactOptionInAutocomplete contact={option as GetContactDto} />}
                      />
                    )}
                    {fromType === "wallet" && (
                      <CustomSelector<WithoutWorkspaceId<GetWalletsRequest>, BasicElement, true>
                        fullWidth
                        disabled={label.isAppliedInLockedPeriod}
                        value={walletFrom ?? []}
                        onChange={(_, newValue) => setWalletFrom(newValue.length > 0 ? newValue : null)}
                        getOptionLabel={(option) => option.name}
                        defaultPaginatedQueryProps={{
                          sortBy: "transaction_count",
                          sortDirection: "descending",
                          excludedIds: walletFrom?.map((wallet) => wallet.id),
                        }}
                        multiple
                        getOptionDisabled={walletFrom && walletFrom.length >= 50 ? () => true : () => false}
                        disableCloseOnSelect
                        filterSelectedOptions
                        getOptionSelected={(option, value) => option.id === value.id}
                        usePaginatedQuery={api.wallet.useWallets}
                        size="small"
                        placeholder="Type to search..."
                        renderOption={(option) => <WalletOptionInAutocomplete wallet={option as GetWalletDto} />}
                      />
                    )}
                  </div>
                </ConditionalTooltip>
              </Box>

              <Box component="section">
                <ConditionalTooltip
                  tooltipMessage="This rule can't be updated as it would impact the labelling of locked transactions. To modify this rule, please unlock your period."
                  disabled={label.isAppliedInLockedPeriod}
                >
                  <div>
                    <Box className={classes.associationLabel}>
                      <Select
                        labelId="label-to-select-label"
                        id="label-to-select"
                        disabled={label.isAppliedInLockedPeriod}
                        className={classes.associationLabelSelector}
                        value={toType}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                          setToType(event.target.value as LabelAssociationDtoTypeEnum)
                          setContactTo(null)
                          setWalletTo(null)

                          // A user cannot set from:contact and to:contact
                          if (event.target.value === "contact" && fromType === "contact") {
                            setFromType("wallet")
                            setContactFrom(null)
                            setWalletFrom(null)
                          }
                        }}
                      >
                        <MenuItem value={"contact"}>Contact</MenuItem>
                        <MenuItem value={"wallet"}>Wallet</MenuItem>
                      </Select>
                      <InputLabel id="label-to-select-label">To</InputLabel>
                    </Box>
                    {toType === "contact" && (
                      <CustomSelector<WithoutWorkspaceId<GetContactsRequest>, BasicElement, true>
                        fullWidth
                        disabled={label.isAppliedInLockedPeriod}
                        value={contactTo ?? []}
                        onChange={(_, newValue) => setContactTo(newValue.length > 0 ? newValue : null)}
                        getOptionLabel={(option) => option.name}
                        defaultPaginatedQueryProps={{
                          sortBy: "movement_count",
                          sortDirection: "descending",
                          excludedIds: contactTo?.map((contact) => contact.id),
                        }}
                        multiple
                        getOptionDisabled={contactTo && contactTo.length >= 50 ? () => true : () => false}
                        disableCloseOnSelect
                        filterSelectedOptions
                        getOptionSelected={(option, value) => option.id === value.id}
                        usePaginatedQuery={api.contact.useContacts}
                        size="small"
                        placeholder="Type to search..."
                        renderOption={(option) => <ContactOptionInAutocomplete contact={option as GetContactDto} />}
                      />
                    )}
                    {toType === "wallet" && (
                      <CustomSelector<WithoutWorkspaceId<GetWalletsRequest>, BasicElement, true>
                        fullWidth
                        disabled={label.isAppliedInLockedPeriod}
                        value={walletTo ?? []}
                        onChange={(_, newValue) => setWalletTo(newValue.length > 0 ? newValue : null)}
                        getOptionLabel={(option) => option.name}
                        defaultPaginatedQueryProps={{
                          sortBy: "transaction_count",
                          sortDirection: "descending",
                          excludedIds: walletTo?.map((wallet) => wallet.id),
                        }}
                        multiple
                        getOptionDisabled={walletTo && walletTo.length >= 50 ? () => true : () => false}
                        disableCloseOnSelect
                        filterSelectedOptions
                        getOptionSelected={(option, value) => option.id === value.id}
                        usePaginatedQuery={api.wallet.useWallets}
                        size="small"
                        placeholder="Type to search..."
                        renderOption={(option) => <WalletOptionInAutocomplete wallet={option as GetWalletDto} />}
                      />
                    )}
                  </div>
                </ConditionalTooltip>
              </Box>
            </>
          ) : (
            <>
              <Box mt={4}>
                <TypedController
                  name="savedFilter"
                  defaultValue={savedFilter}
                  rules={{ required: false }}
                  render={({ value, onChange }) => (
                    <CustomSelector<WithoutWorkspaceId<GetFiltersRequest>, GetTransactionFilterDto, false>
                      id="savedFilter"
                      size="small"
                      disableCloseOnSelect
                      maxAutocompletionResults={20}
                      filterSelectedOptions
                      placeholder={value ? "" : "Pick a favorite filter"}
                      getOptionSelected={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => option.name}
                      onChange={(_e, newValue) => {
                        onChange(newValue)
                      }}
                      value={value ?? null}
                      usePaginatedQuery={api.filter.useFilters}
                      defaultPaginatedQueryProps={{}}
                    />
                  )}
                />
              </Box>
            </>
          )}
        </>
      )}
      <ButtonModalImportCsv labelId={label.id} />
      <Box marginTop={3} display="flex" justifyContent="space-between">
        <ConditionalTooltip
          tooltipMessage="This rule can't be updated as it would impact the labelling of locked transactions. To modify this rule, please unlock your period."
          disabled={label.isAppliedInLockedPeriod}
        >
          <div>
            <PermissionDisabled permission="can_modify_label" action="edit labels">
              <SaveLabelButton disabled={!isFormDirty} type="submit">
                Save
              </SaveLabelButton>
            </PermissionDisabled>
          </div>
        </ConditionalTooltip>
        <ConditionalTooltip
          tooltipMessage="This label can't be deleted as it would impact the labelling of locked transactions. To delete this label, please unlock your period."
          disabled={label ? label.isAppliedInLockedPeriod : false}
        >
          <div>
            <PermissionDisabled permission="can_remove_label" action="delete labels">
              <UncontrolledLoadingButton
                Icon={<Delete className={iconStyleBlack} />}
                mode={Mode.CONTAINED}
                onClick={askDeleteLabel}
                disabled={label.isAppliedInLockedPeriod}
              >
                Delete
              </UncontrolledLoadingButton>
            </PermissionDisabled>
          </div>
        </ConditionalTooltip>
      </Box>
    </DrawerCategory>
  )
}

export default Edition
