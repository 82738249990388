import { Popover, Transition } from "@headlessui/react"
import { css } from "@emotion/css"

import ButtonGroupUI from "CryptioUI/ButtonGroup"
import { ReactComponent as ArrowDown } from "CryptioUI/assets/icons/chevron-down.svg"
import { iconStyleWhite } from "CryptioUI/Utilities/config"
import ButtonUI from "CryptioUI/Button"
import { Mode, ButtonSize } from "CryptioUI/types"

export interface SplitButtonProps {
  buttonAction: () => void
  buttonLabel: string
  PopoverContent: React.ReactNode
  Icon?: JSX.Element
  variant?: Mode
  disabled?: boolean
  className?: string
  iconPosition?: "left" | "right"
  size?: ButtonSize
  iconClassName?: string
}

const SplitButton = ({
  buttonAction,
  buttonLabel,
  PopoverContent,
  Icon,
  variant = Mode.DEFAULT,
  disabled = false,
  className = "",
  iconPosition = "left",
  size = ButtonSize.LG,
  iconClassName,
}: SplitButtonProps): JSX.Element => {
  return (
    <Popover className="relative">
      <ButtonGroupUI>
        <ButtonUI
          onClick={buttonAction}
          Icon={Icon}
          iconPosition={iconPosition}
          mode={variant}
          disabled={disabled}
          className={className}
          size={size}
        >
          {buttonLabel}
        </ButtonUI>
        <Popover.Button>
          <ButtonUI
            className={css`
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-left: 1px solid rgba(255, 255, 255, 0.1);

              :active {
                outline: none;
              }
            `}
            Icon={<ArrowDown className={iconClassName ? iconClassName : iconStyleWhite} />}
            mode={variant}
            disabled={disabled}
            size={size}
          />
        </Popover.Button>
      </ButtonGroupUI>
      <div className="absolute z-[1200] w-full mt-4">
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Popover.Panel className="bg-white rounded-xl drop-shadow overflow-hidden">{PopoverContent}</Popover.Panel>
        </Transition>
      </div>
    </Popover>
  )
}

export default SplitButton
