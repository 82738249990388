import React, { MouseEventHandler } from "react"

export enum Mode {
  DEFAULT = "Default",
  CONTAINED = "Contained",
  OUTLINED = "Outlined",
  DESTRUCTIVE = "Destructive",
  TEXTONLY = "TextOnly",
  OUTLINEDHIGHLIGHT = "OutlinedHighlight",
  SHADOWED = "Shadowed",
}

export enum ButtonSize {
  SM = "small",
  MD = "medium",
  LG = "large",
}

export interface ButtonUIProps {
  type?: "button" | "submit" | "reset" | undefined
  size?: ButtonSize
  Icon?: JSX.Element
  disabled?: boolean
  iconPosition?: "left" | "right"
  ellipsisPosition?: "end" | "middle"
  mode?: Mode
  onClick?: (() => void) | MouseEventHandler<HTMLButtonElement>
  children?: JSX.Element | string | React.ReactFragment
  className?: string
  classNameContainer?: string
  classNameIconWrapper?: string
  id?: string
  fullWidth?: boolean
  form?: string
}

export type ModeType = {
  [key in Mode]: string
}

export type SizeType = {
  [key in ButtonSize]: string
}

export type ButtonGroupUIProps = {
  children: JSX.Element | JSX.Element[]
  mode?: Mode
  disabled?: boolean
  size?: ButtonSize
}
