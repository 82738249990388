import { GenericJobStatus } from "services/api/openapi"
import { roundToTwoDecimalMax } from "services/utils/processNumber"

export const getHumanReadableReportStatus = (status: GenericJobStatus, error: string | null): string => {
  switch (status.status) {
    case "active":
      const statusProcessRounded = roundToTwoDecimalMax(status.progress)
      if (status.progress !== null) return `Processing ${statusProcessRounded}%`
      return `Processing`
    case "pending":
      return "Pending"
    case "error":
      return "Error"
    case "inactive":
      if (error !== null) return "Error"
      return "Ready"
  }
}
