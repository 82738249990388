import { ProviderContext } from "CryptioUI/Toaster/Context"
import Papa from "papaparse"

import { pluralize } from "./utils/textUtils"
import { DataTableSelectedRowState } from "./utils/types"

export type WithCsvInfos<T> = T & { rowId: number; errorString: string | null }
export interface CsvModalType<Item> {
  isOpen: boolean
  data: WithCsvInfos<Item>[]
}

// export const defaultTransformData = <TObject>(data: TObject[]): WithCsvInfos<TObject>[] =>
//   data.map((row, index) => ({ ...row, rowId: index, errorString: null }))

export const findRowByRowIdFilterByErrorString = function <TObject extends WithCsvInfos<unknown>>(
  selectedRows: DataTableSelectedRowState<TObject>,
): TObject[] {
  // if (selectedRows.allSelected) {
  return selectedRows.selectedRows.filter((row) => row.errorString === null)
  // }
  // const filteredSelectedRows = selectedRows.selectedRows.filter(
  //   (row) => row.errorString === null,
  // )
  // return rows.filter(
  //   (row) =>
  //     row &&
  //     row.errorString === null &&
  //     filteredSelectedRows.find((sRow) => sRow.rowId === row.rowId),
  // )
}

// TODO: Properly type TObject to prevent non string keys
export type CsvParseOptions<TObject, OutObject = TObject> = {
  file: File
  requiredColumns: (keyof TObject)[]
  setModalData: (newValue: CsvModalType<OutObject>) => void
  toast: ProviderContext
  transformData: (data: TObject[]) => WithCsvInfos<OutObject>[]
  columnAliases?: Record<string, keyof TObject>
}

const parseCsvFile = <TObject, OutObject = TObject>(options: CsvParseOptions<TObject, OutObject>) => {
  const lowercaseToActualColumns: Map<string, string> = new Map(
    options.requiredColumns.map((col) => [(col as string).toLowerCase(), col as string] as const),
  )
  const transformHeader = (column: string) => {
    column = column.toLowerCase()
    if (options.columnAliases && options.columnAliases[column]) {
      column = options.columnAliases[column] as string
    }
    return lowercaseToActualColumns.get(column.toLowerCase()) ?? column
  }

  Papa.parse<TObject>(options.file, {
    header: true,
    preview: 1,
    skipEmptyLines: true,
    transformHeader,
    complete: ({ data: [preview] }) => {
      const missingColumns = options.requiredColumns.filter((column) => preview[column] === undefined)

      if (missingColumns.length) {
        options.toast.open(`Missing ${pluralize(missingColumns.length > 1, "column")} (${missingColumns.join(", ")})`, {
          variant: "danger",
        })
        return
      }

      Papa.parse<TObject>(options.file, {
        header: true,
        skipEmptyLines: true,
        transformHeader,

        complete: (results) => {
          options.setModalData({
            isOpen: true,
            data: options.transformData(results.data),
          })
        },
      })
    },
  })
}

export default parseCsvFile
