import { useContext } from "react"
import { GetUserDtoRoleEnum } from "services/api/openapi"
import { UserContext } from "services/context/userContext"

export interface RoleGuardProps {
  children: JSX.Element
  acceptedRoles: GetUserDtoRoleEnum[]
  fallback?: JSX.Element
  persistent?: boolean
}

const RoleGuard = ({ acceptedRoles, children, fallback, persistent }: RoleGuardProps) => {
  const { user } = useContext(UserContext)

  if (user.role && acceptedRoles.includes(user.role) && (persistent === true || !user.hideAdminFeatures))
    return children
  return fallback ? fallback : <></>
}

export default RoleGuard
