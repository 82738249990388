import { useTypedController } from "@hookform/strictly-typed"
import { Box, Checkbox, FormLabel, IconButton, makeStyles, TextField, Theme, Typography } from "@material-ui/core"
import { ReactComponent as AddIcon } from "CryptioUI/assets/icons/add.svg"
import { ReactComponent as Paperclip } from "CryptioUI/assets/icons/paperclip.svg"

import BigNumber from "bignumber.js"
import React, { useCallback, useContext, useMemo, useState } from "react"
import { useForm } from "react-hook-form"

import { DrawerCategory, DrawerFormSection, DrawerSection } from "components/Drawer/DrawerItems"
import ChartAccountOptionInAutocomplete from "components/AutoCompleteOptions/ChartAccountOptionInAutocomplete"

import { useLoadingButton } from "components/LoadingButton"
import { DrawerProp } from "components/misc/useDrawer"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import CustomSelector from "components/selector/CustomSelector"
import api from "services/api"
import { NewFullMovementDto, NewFullTransactionDto } from "services/api/openapi/models"
import { GetChartAccountsTypesEnum, FullMovementAccounting, COAAccountElement } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { Mixpanel } from "services/mixpanel"
import download from "services/utils/download"
import { openInNewTab } from "services/utils/openInNewTab"
import AddInvoiceModal from "../invoices/AddInvoiceModal"
import LinkWithInvoiceModal from "../invoices/LinkWithInvoiceModal"
import { convertMovements } from "../utils/convertMovement"
import { Trade } from ".."
import TradeRateField, { TradeRateValue } from "./TradeRateField"
import { notNullGuard } from "pure-shared"
import { isLockedTransaction } from "../../../services/utils/isLocked"
import ButtonUI from "CryptioUI/Button"
import { ButtonSize } from "CryptioUI/types"
import { iconStyleWhite } from "CryptioUI/Utilities/config"
import { ClearRounded } from "@material-ui/icons"
import TransactionValuationUpdate from "./TransactionValuationUpdate"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  validateButton: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  invoiceTypography: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  invoiceBox: {
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  invoiceBoxDownload: {
    "&:hover > .MuiTypography-root": {
      textDecoration: "underline",
    },
  },
  checkboxManualMapping: {
    paddingLeft: 0,
  },
}))

interface JournalLineAccountData {
  displayName: string
  accounting: FullMovementAccounting | null
  currentAccount: COAAccountElement | null
  // undefined means all
  acceptedType?: GetChartAccountsTypesEnum[]
}

interface TransactionUpdateForm /* extends TransactionUpdateFormAccounts */ {
  notes: string
  rates: TradeRateValue
  isManualMapping: boolean
  // FIXME: Only the current credited and debited account should be stored in
  // the form, not the whole line
  journalLines: JournalLineData[]
}
interface JournalLineData {
  lineId: string | null
  credited: JournalLineAccountData
  debited: JournalLineAccountData
}

const TransactionForm = ({ item: transaction }: DrawerProp<NewFullTransactionDto, false>) => {
  const classes = useStyles()
  const [isAddInvoiceModalOpen, setIsAddInvoiceModalOpen] = useState<boolean>(false)
  const [isLinkInvoiceModalOpen, setIsLinkInvoiceModalOpen] = useState<boolean>(false)
  const toast = useToast()

  const [, _setTypedRate] = useState({
    incoming: "",
    outgoing: "",
    lastModified: "none" as "none" | "incoming" | "outgoing",
  })

  const { mutateAsync: updateTransactionInvoiceMutation } = api.transaction.useUpdateTransactionInvoice()
  const { mutateAsync: updateTransactionJournalLinesMutation } = api.transaction.useUpdateTransactionJournalLines()

  const workspaceCtx = useContext(WorkspaceContext)

  const unLinkInvoice = async () => {
    try {
      await updateTransactionInvoiceMutation({
        updateTransactionInvoiceDto: {
          transactionId: transaction.id,
          invoiceId: null,
        },
      })
      toast.open("Successfully unlinked the invoice with the transaction", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const getMainMovement = (
    loneMovements: NewFullMovementDto[],
    trades: Trade[],
    nbIn: number,
    nbOut: number,
    nbFee: number,
  ) => {
    if (trades.length) return trades[0].out
    if (nbIn) return loneMovements.filter((mvt) => mvt.direction === "in")[0]
    if (nbOut) return loneMovements.filter((mvt) => mvt.direction === "out" && !mvt.isFee)[0]
    if (nbFee) return loneMovements.filter((mvt) => mvt.isFee)[0]
    return undefined
  }

  const { trades, inMovements, outMovements, feeMovements, isComplex, mainMvt } = useMemo(() => {
    const [loneMovements, trades] = convertMovements(transaction)
    const inMovements = loneMovements.filter((x) => x.direction === "in")
    const outMovements = loneMovements.filter((x) => x.direction === "out" && x.isFee === false)
    const feeMovements = loneMovements.filter((x) => x.isFee)
    const mainMvt = getMainMovement(loneMovements, trades, inMovements.length, outMovements.length, feeMovements.length)
    const isComplex = transaction.complexity === "complex"

    return {
      loneMovements,
      trades,
      inMovements,
      outMovements,
      feeMovements,
      isComplex,
      mainMvt,
    }
  }, [transaction])

  const incoming = useMemo(() => {
    if (trades.length > 0) return trades[0].in
    if (mainMvt && mainMvt.direction === "in") return mainMvt
    return undefined
  }, [trades, mainMvt])

  const outgoing = useMemo(() => {
    if (trades.length > 0) return trades[0].out
    if (mainMvt && mainMvt.direction === "out" && !mainMvt.isFee) return mainMvt
    return undefined
  }, [trades, mainMvt])

  const feeMovement = useMemo(() => {
    return feeMovements.length > 0 ? feeMovements[0] : undefined
  }, [feeMovements])
  const { mutateAsync: bulkUpdateMovementMutation } = api.transaction.useBulkUpdateMovements()

  const hasAccountingIntegration = workspaceCtx.workspace.accountingIntegration !== null
  const isManualMapping = transaction.movements.some((m) => m.accounting?.isManualMapping)
  const isMappingRunning = workspaceCtx.workspace.coaMappingStatus.status !== "inactive"
  const journalLinesAreInserted = transaction.movements.every((m) => m.accounting !== null)
  const canUpdateJournalLines = !isMappingRunning && !isComplex && journalLinesAreInserted && hasAccountingIntegration

  const onSubmit = useCallback(
    async (form: TransactionUpdateForm) => {
      try {
        if (
          (form.rates.inRate && new BigNumber(form.rates.inRate).isNegative()) ||
          (form.rates.outRate && new BigNumber(form.rates.outRate).isNegative()) ||
          (form.rates.feeRate && new BigNumber(form.rates.feeRate).isNegative())
        )
          throw new Error("Rate cannot be a negative value")
        if (
          incoming &&
          form.rates.inRate &&
          form.rates.inRate !== "" &&
          form.rates.inRate !== incoming.assetToFiatRate
        ) {
          await bulkUpdateMovementMutation({
            movementBulkUpdateDto: {
              individualMovements: {
                movementIds: [incoming.id],
              },
              fiatRate: form.rates.inRate !== incoming.originalAssetToFiatRate ? form.rates.inRate : undefined,
              resetRates: form.rates.inRate === incoming.originalAssetToFiatRate ? true : undefined,
              note: form.notes,
            },
          })
        }
        if (
          outgoing &&
          form.rates.outRate &&
          form.rates.outRate !== "" &&
          form.rates.outRate !== outgoing.assetToFiatRate
        ) {
          await bulkUpdateMovementMutation({
            movementBulkUpdateDto: {
              individualMovements: {
                movementIds: [outgoing.id],
              },
              fiatRate: form.rates.outRate !== outgoing.originalAssetToFiatRate ? form.rates.outRate : undefined,
              resetRates: form.rates.outRate === outgoing.originalAssetToFiatRate ? true : undefined,
              note: form.notes,
            },
          })
        }
        if (
          feeMovement &&
          form.rates.feeRate &&
          form.rates.feeRate !== "" &&
          form.rates.feeRate !== feeMovement.assetToFiatRate
        ) {
          await bulkUpdateMovementMutation({
            movementBulkUpdateDto: {
              individualMovements: {
                movementIds: [feeMovement.id],
              },
              fiatRate: form.rates.feeRate !== feeMovement.originalAssetToFiatRate ? form.rates.feeRate : undefined,
              resetRates: form.rates.feeRate === feeMovement.originalAssetToFiatRate ? true : undefined,
              note: form.notes,
            },
          })
        }
        if (!isComplex) {
          await bulkUpdateMovementMutation({
            movementBulkUpdateDto: {
              individualTransactions: {
                transactionIds: [transaction.id],
              },
              note: form.notes,
            },
          })
        }

        if (canUpdateJournalLines) {
          await updateTransactionJournalLinesMutation({
            updateSimpleTransactionJournalLinesDto: {
              isManualMapping: form.isManualMapping,
              lines: form.journalLines
                .map((line) =>
                  line.lineId === null
                    ? null
                    : {
                        lineId: line.lineId,
                        debitedAccountId: line.debited?.currentAccount?.id ?? null,
                        creditedAccountingId: line.credited?.currentAccount?.id ?? null,
                      },
                )
                .filter(notNullGuard),
            },
          })
        }
        toast.open("Transaction updated", { variant: "success" })
      } catch (e) {
        toastCatch(e, toast)
      }
      if (form.notes !== mainMvt?.note) Mixpanel.track("TransactionNoteEdited")
    },
    [
      canUpdateJournalLines,
      toast,
      bulkUpdateMovementMutation,
      incoming,
      isComplex,
      mainMvt?.note,
      outgoing,
      transaction.id,
      updateTransactionJournalLinesMutation,
      feeMovement,
    ],
  )

  const baseJournalLineData = useMemo(() => {
    const autos: JournalLineData[] = []
    if (!isComplex) {
      if (trades.length === 1) {
        const [trade] = trades
        autos.push({
          lineId: trade.in.accounting?.journalLineId ?? null,
          debited: {
            accounting: trade.in.accounting,
            currentAccount: trade.in.accounting?.debitedAccount ?? null,
            displayName: `Incoming ${trade.in.assetSymbol} debited account`,
            acceptedType: ["asset", "liability"],
          },
          credited: {
            accounting: trade.out.accounting,
            currentAccount: trade.out.accounting?.creditedAccount ?? null,
            displayName: `Outgoing ${trade.out.assetSymbol} credited account`,
            acceptedType: ["asset", "liability"],
          },
        })
      } else if (inMovements.length === 1) {
        const [movement] = inMovements
        autos.push({
          lineId: movement.accounting?.journalLineId ?? null,
          debited: {
            accounting: movement.accounting,
            currentAccount: movement.accounting?.debitedAccount ?? null,
            displayName: `Debited ${movement.assetSymbol} account`,
            acceptedType: ["asset"],
          },
          credited: {
            accounting: movement.accounting,
            currentAccount: movement.accounting?.creditedAccount ?? null,
            displayName: "Credited account",
            acceptedType: movement.isInternalTransfer ? undefined : ["income", "liability", "equity"],
          },
        })
      } else if (outMovements.length === 1) {
        const [movement] = outMovements
        autos.push({
          lineId: movement.accounting?.journalLineId ?? null,
          credited: {
            accounting: movement.accounting,
            currentAccount: movement.accounting?.creditedAccount ?? null,
            displayName: `Credited ${movement.assetSymbol} account`,
            acceptedType: ["asset"],
          },
          debited: {
            accounting: movement.accounting,
            currentAccount: movement.accounting?.debitedAccount ?? null,
            displayName: "Debited account",
            acceptedType: movement.isInternalTransfer ? undefined : ["expense", "liability", "equity"],
          },
        })
      }

      if (feeMovements.length === 1) {
        const [movement] = feeMovements

        autos.push({
          lineId: movement.accounting?.journalLineId ?? null,
          credited: {
            accounting: movement.accounting,
            currentAccount: movement.accounting?.creditedAccount ?? null,
            displayName: `Fee credited ${movement.assetSymbol} account`,
            acceptedType: ["asset"],
          },
          debited: {
            accounting: movement.accounting,
            currentAccount: movement.accounting?.debitedAccount ?? null,
            displayName: `Fee debited account`,
            acceptedType: ["expense"],
          },
        })
      }
    }
    return autos
  }, [feeMovements, inMovements, isComplex, outMovements, trades])

  const defaultFormValues: TransactionUpdateForm = useMemo(
    () => ({
      notes: mainMvt?.note ?? "",
      rates: {
        inRate: incoming?.assetToFiatRate ?? "",
        outRate: outgoing?.assetToFiatRate ?? "",
        feeRate: feeMovement?.assetToFiatRate ?? "",
      },
      isManualMapping,
      journalLines: baseJournalLineData,
    }),
    [
      mainMvt?.note,
      isManualMapping,
      baseJournalLineData,
      incoming?.assetToFiatRate,
      outgoing?.assetToFiatRate,
      feeMovement?.assetToFiatRate,
    ],
  )

  const { control, handleSubmit, formState, watch } = useForm<TransactionUpdateForm>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  })

  const TypedController = useTypedController<TransactionUpdateForm>({ control })
  const isFormDirty = formState.isValid && formState.isDirty

  const [UpdateBulkTransactionButton, handleButtonCallback] = useLoadingButton()

  const watchAllFields = watch()

  const isLocked = isLockedTransaction(
    workspaceCtx.workspace.lock.isEnabled,
    transaction.transactionDate,
    workspaceCtx.workspace.lock.exclusiveEndDate,
  )

  return (
    <>
      <DrawerCategory
        component="form"
        title="Transaction settings"
        onSubmit={handleButtonCallback(handleSubmit(onSubmit))}
      >
        {!isComplex && (
          <TypedController
            name="rates"
            defaultValue={{
              inRate: incoming?.assetToFiatRate ?? "",
              outRate: outgoing?.assetToFiatRate ?? "",
              feeRate: feeMovement?.assetToFiatRate ?? "",
            }}
            render={({ value, onChange }) => (
              <TradeRateField
                disabled={isLocked}
                incoming={incoming}
                outgoing={outgoing}
                feeMovement={feeMovement}
                value={value}
                onChange={onChange}
              />
            )}
          />
        )}

        {mainMvt && !isComplex && (
          <DrawerFormSection htmlFor="notes-textfield" name="Notes">
            <TypedController
              name="notes"
              defaultValue={mainMvt.note ?? ""}
              render={(props) => (
                <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
                  <TextField id="notes-textfield" {...props} multiline placeholder="Write anything..." fullWidth />
                </PermissionDisabled>
              )}
            />
          </DrawerFormSection>
        )}

        {/* hasAccountingIntegration && isComplex && (
          <DrawerSection name="Manual chart of accounts mapping">
            <Typography>
              TODO: Contact us?
            </Typography>
          </DrawerSection>
        ) */}
        {hasAccountingIntegration && !isComplex && (
          <>
            {isMappingRunning && (
              <DrawerSection name="Manual chart of accounts mapping">
                {/* TODO: phrasing? */}
                <Typography>
                  You cannot update manual mappings when automated mappings are computing, please wait
                </Typography>
              </DrawerSection>
            )}
            {!isMappingRunning && !journalLinesAreInserted && (
              <DrawerSection name="Manual chart of accounts mapping">
                {/* TODO: button or something? */}
                <Typography>Please apply mappings first</Typography>
              </DrawerSection>
            )}
            {canUpdateJournalLines && (
              <Box mt={3} component="section">
                <Box display="flex" alignItems="center">
                  <TypedController
                    name="isManualMapping"
                    defaultValue={isManualMapping}
                    rules={{ required: false }}
                    render={({ onChange, value, ...rest }) => (
                      <PermissionDisabled permission="can_modify_transaction" action="modify transaction">
                        <Checkbox
                          color="primary"
                          id="manual-mapping-checkbox"
                          className={classes.checkboxManualMapping}
                          onChange={() => onChange(!value)}
                          checked={value}
                          {...rest}
                        />
                      </PermissionDisabled>
                    )}
                  />
                  <FormLabel htmlFor="manual-mapping-checkbox">Manual chart of accounts mapping</FormLabel>
                </Box>

                {baseJournalLineData.map((defaultJournalLine, idx) => (
                  <Box key={transaction.id + idx}>
                    <TypedController
                      name={["journalLines", idx]}
                      rules={{ required: false }}
                      defaultValue={defaultJournalLine}
                      render={({ ref: _ref, onChange, value: { credited, debited } }) => (
                        <Box mt={idx === 0 ? 0.5 : 2}>
                          <PermissionDisabled
                            placement={"right"}
                            permission="can_modify_transaction"
                            action="modify transaction"
                          >
                            <CustomSelector
                              value={credited.currentAccount}
                              onChange={(_, newValue) =>
                                onChange({
                                  debited,
                                  lineId: defaultJournalLine.lineId,
                                  credited: {
                                    ...credited,
                                    currentAccount: newValue,
                                  },
                                })
                              }
                              getOptionLabel={(option) => option.name}
                              defaultPaginatedQueryProps={{
                                sortBy: "name",
                                sortDirection: "ascending",
                                types: workspaceCtx.workspace.areChartAccountMappingsRestricted
                                  ? credited.acceptedType
                                  : undefined,
                              }}
                              label={credited.displayName}
                              disabled={!watchAllFields.isManualMapping}
                              getOptionSelected={(option, value) => option.id === value.id}
                              usePaginatedQuery={api.chartAccount.useChartAccounts}
                              size="small"
                              filterSelectedOptions
                              renderOption={(option) => <ChartAccountOptionInAutocomplete account={option} />}
                            />
                          </PermissionDisabled>
                          <Box mt={2} />
                          <PermissionDisabled
                            placement={"right"}
                            permission="can_modify_transaction"
                            action="modify transaction"
                          >
                            <CustomSelector
                              value={debited.currentAccount}
                              onChange={(_, newValue) =>
                                onChange({
                                  credited,
                                  lineId: defaultJournalLine.lineId,
                                  debited: {
                                    ...debited,
                                    currentAccount: newValue,
                                  },
                                })
                              }
                              getOptionLabel={(option) => option.name}
                              defaultPaginatedQueryProps={{
                                sortBy: "name",
                                sortDirection: "ascending",
                                types: workspaceCtx.workspace.areChartAccountMappingsRestricted
                                  ? debited.acceptedType
                                  : undefined,
                              }}
                              label={debited.displayName}
                              disabled={!watchAllFields.isManualMapping}
                              getOptionSelected={(option, value) => option.id === value.id}
                              usePaginatedQuery={api.chartAccount.useChartAccounts}
                              size="small"
                              filterSelectedOptions
                              renderOption={(option) => <ChartAccountOptionInAutocomplete account={option} />}
                            />
                          </PermissionDisabled>
                        </Box>
                      )}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}

        <Box mt={3}>
          {!isComplex && (
            <PermissionDisabled permission="can_modify_transaction" action="modify transaction">
              <UpdateBulkTransactionButton type="submit" className={classes.validateButton} disabled={!isFormDirty}>
                Save
              </UpdateBulkTransactionButton>
            </PermissionDisabled>
          )}
        </Box>
        <DrawerSection name="Invoice">
          <Box display="flex" pt={1}>
            {transaction.invoice ? (
              <Box color="primary" display="flex" alignItems="center" className={classes.invoiceBox}>
                <Box
                  className={classes.invoiceBoxDownload}
                  onClick={() => {
                    if (transaction.invoice.source === "request_network" && transaction.invoice.filename) {
                      openInNewTab(`https://invoicing.request.network/${transaction.invoice.filename}`, true)
                    } else {
                      api.invoices
                        .getSignedUrl(workspaceCtx, transaction.invoice.id)
                        .then((res) => download(res.signedUrl))
                    }
                  }}
                >
                  <Typography className={classes.invoiceTypography} variant="body1">
                    {transaction.invoice.invoiceRef}
                    {transaction.invoice.filename && transaction.invoice.source === "internal_pdf" && (
                      <span> | {transaction.invoice.filename}</span>
                    )}
                  </Typography>
                </Box>
                <IconButton onClick={() => unLinkInvoice()}>
                  <ClearRounded htmlColor="#fff" />
                </IconButton>
              </Box>
            ) : (
              <>
                <Box mr={2}>
                  <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
                    <ButtonUI
                      size={ButtonSize.MD}
                      onClick={() => setIsAddInvoiceModalOpen(true)}
                      Icon={<AddIcon className={iconStyleWhite} />}
                    >
                      Add invoice
                    </ButtonUI>
                  </PermissionDisabled>
                </Box>
                <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
                  <ButtonUI
                    size={ButtonSize.MD}
                    onClick={() => setIsLinkInvoiceModalOpen(true)}
                    Icon={<Paperclip className={iconStyleWhite} />}
                  >
                    Link with existing file
                  </ButtonUI>
                </PermissionDisabled>
              </>
            )}
          </Box>
        </DrawerSection>
        <TransactionValuationUpdate transaction={transaction} />
      </DrawerCategory>
      <AddInvoiceModal
        transactionId={transaction.id}
        isOpen={isAddInvoiceModalOpen}
        setIsOpen={setIsAddInvoiceModalOpen}
      />
      <LinkWithInvoiceModal
        transactionId={transaction.id}
        isOpen={isLinkInvoiceModalOpen}
        setIsOpen={setIsLinkInvoiceModalOpen}
      />
    </>
  )
}

export default TransactionForm
