import { Box } from "@material-ui/core"
import React from "react"

import { DrawerCategory } from "components/Drawer/DrawerItems"
import { DrawerProp } from "components/misc/useDrawer"
import { GetPublicAssetDto } from "services/api/openapi/models"
import AssetAdminRemapAsset from "./AdminCategories/AssetAdminRemapAsset"
import AssetAdminUpdateAsset from "./AdminCategories/AssetAdminUpdateAsset"
import { AssetBlockchainLinks } from "./AssetBlockchainLinks"
import { AssetExchangeLinks } from "./AssetExchangeLink"
import { AssetProviders } from "./AssetProviders"

const AssetDrawerAdminCategories = (props: DrawerProp<GetPublicAssetDto, false>) => {
  const { item: asset } = props

  return (
    <Box>
      {asset.type === "exchange_unknown" && <AssetAdminRemapAsset {...props} />}
      <AssetAdminUpdateAsset {...props} />
      <DrawerCategory title="Create an asset" />

      <AssetProviders asset={asset} />

      <AssetBlockchainLinks asset={asset} />

      <AssetExchangeLinks asset={asset} />
    </Box>
  )
}

export default AssetDrawerAdminCategories
