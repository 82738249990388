import { Box, Divider, Link, Paper, Typography } from "@material-ui/core"
import dayjs from "dayjs"
import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import BackOfficePlanInAutocomplete from "components/AutoCompleteOptions/BackOfficePlanInAutocomplete"
import PlainDatePicker from "components/DatePickers/DatePicker"
import DateRangePicker, { NonNullableDateRange, PlainDate } from "components/DateRangePicker"
import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import CopyableText from "components/misc/copyableText"
import useDialog from "components/misc/useDialog"
import useDrawer, { DrawerProp } from "components/misc/useDrawer"
import CustomSelector from "components/selector/CustomSelector"
import SubscriptionUsage from "components/Stripe/SubscriptionUsage"
import ButtonUI from "CryptioUI/Button"
import { URLS } from "../../../../routes"
import api from "services/api"
import { BackOfficeGetFullWorkspaceDto, BackOfficeGetMinimalPlanDto } from "services/api/openapi/models"
import { WorkspaceContext } from "services/context/workspaceContext"
import { pluralize } from "services/utils/textUtils"
import { stripeIsLiveEnvironment } from "services/variables"
import BackOfficeWorkspaceForm from "./BackOfficeWorkspaceForm"
import BackOfficeWorkspaceFeatures from "./BackOfficeWorkspaceFeatures"
import BackOfficeView from "../../../../components/misc/BackOfficeView"
import { toastCatch } from "components/ReactHookForm/utils"
import { useToast } from "CryptioUI/Toaster"

const BackOfficeWorkspaceDrawer = (props: DrawerProp<BackOfficeGetFullWorkspaceDto, false>) => {
  const { item: workspace } = props
  const [workspaceDrawer, openWorkspaceDrawer] = useDrawer("workspace")
  const [userDrawer, openUserDrawer] = useDrawer("backoffice-user")
  const workspaceCtx = useContext(WorkspaceContext)
  const [plan, setPlan] = useState<BackOfficeGetMinimalPlanDto | null>(null)
  const [newPlanDateRange, setNewPlanDateRange] = useState<NonNullableDateRange>({
    startDate: new PlainDate(dayjs.utc()),
    endDate: new PlainDate(dayjs.utc().add(1, "year")),
  })

  const checkoutLink = plan
    ? `${window.location.origin}${URLS.Redirect.getUrl(
        workspace.id,
        URLS.Billing.Checkout.Review.getUrl({ planId: plan.id }),
      )}`
    : null

  const history = useHistory()

  const [selectedDate, setSelectedDate] = useState<PlainDate>(new PlainDate(dayjs.utc()))

  useEffect(() => {
    if (workspace.subscription.type === "trial" && workspace.subscription.periodTo)
      setSelectedDate(new PlainDate(dayjs.utc(workspace.subscription.periodTo)))
  }, [workspace])

  const toast = useToast()

  const { mutateAsync: createChangeSubscriptionEndDate } =
    api.backOffice.workspace.useUpdateWorkspaceSubscriptionTrialEndDate()
  const { mutateAsync: startManualSubscriptionMutation } = api.backOffice.workspace.useStartManualSubscription()
  const { mutateAsync: removeSubscriptionMutation } = api.backOffice.workspace.useRemoveSubscription()

  const seeAsUserWorkspace = () => {
    workspaceCtx.changeWorkspace(workspace.id)
    history.push(URLS.Portfolio)
  }

  const basicDialog = useDialog()

  const onStartManualSubscription = async () => {
    if (plan === null) return
    try {
      await startManualSubscriptionMutation({
        workspaceId: workspace.id,
        backOfficeStartManualSubscriptionDto: {
          planId: plan.id,
          startDate: newPlanDateRange.startDate.toBackendDate(),
          endDate: newPlanDateRange.endDate.toBackendDate(),
        },
      })
      toast.open(`Workspace subscribed to the plan ${plan.name}`, { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const onRemoveSubscription = async () => {
    try {
      await removeSubscriptionMutation({
        workspaceId: workspace.id,
      })
      toast.open("Workspace unsubscribed", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const askStartManualSubscription = async () => {
    if (plan === null) return
    basicDialog.showDialog({
      title: "Confirm subscription",
      content: (
        <Box>
          <Typography>
            Are you sure you want to subscribe the workspace <b>{workspace.name}</b> to the plan <b>{plan.name}</b> for
            one year?
          </Typography>
        </Box>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: onStartManualSubscription,
    })
  }

  const askRemoveSubscription = async () => {
    basicDialog.showDialog({
      title: "Confirm unsubscription",
      content: (
        <Box>
          <Typography>
            Are you sure you want to remove all active subscriptions from the workspace <b>{workspace.name}</b>?
          </Typography>
        </Box>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: onRemoveSubscription,
    })
  }

  const updateSubscriptionTrial = async () => {
    try {
      await createChangeSubscriptionEndDate({
        backOfficeUpdateSubscriptionTrialEndDate: {
          workspaceId: workspace.id,
          endDate: selectedDate.toBackendDate(),
        },
      })
      toast.open("Subscription updated", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  return (
    <Box mb={3}>
      {userDrawer}
      {workspaceDrawer}
      {basicDialog.dialog}

      <BackOfficeWorkspaceForm {...props} />

      <DrawerCategory title="Workspace Summary">
        <DrawerSection name="Name">
          <Typography variant="body2" color="textSecondary">
            {workspace.name}
          </Typography>
        </DrawerSection>

        <DrawerSection name="Description">
          <Typography variant="body2" color="textSecondary">
            {workspace.description}
          </Typography>
        </DrawerSection>

        <DrawerSection name="Owner">
          <Link
            onClick={() => {
              openUserDrawer(workspace.ownerUserId)
            }}
            style={{ cursor: "pointer" }}
            underline="always"
          >
            {workspace.ownerUserEmail}
          </Link>
        </DrawerSection>

        <DrawerSection name="Workspace ID">
          <Typography variant="body2" color="textSecondary">
            <CopyableText>{workspace.id}</CopyableText>
          </Typography>
        </DrawerSection>

        <DrawerSection name="Currency">
          <Typography variant="body2" color="textSecondary">
            {workspace.defaultCurrencyAssetName}
          </Typography>
        </DrawerSection>

        <DrawerSection name="Cost basis methodology">
          <Typography variant="body2" color="textSecondary">
            {workspace.costBasisAlgorithmName}
          </Typography>
        </DrawerSection>
      </DrawerCategory>

      <DrawerCategory title="Workspace subscription">
        <DrawerSection name="State">
          <Box>
            <Typography variant="body1">{workspace.subscription.type}</Typography>
            <Typography variant="body2">From {dayjs(workspace.subscription.periodFrom).tz().format("L LT")}</Typography>
            {workspace.subscription.type === "paying" && workspace.subscription.periodTo && (
              <Typography variant="body2">
                Ends on {dayjs(workspace.subscription.periodTo).tz().format("L LT")}
              </Typography>
            )}
            {workspace.subscription.type === "trial" &&
              workspace.subscription.periodTo &&
              !workspace.subscription.isExpired && (
                <Typography variant="body2">
                  There are {dayjs(workspace.subscription.periodTo).fromNow(true)} remaining in the trial.
                </Typography>
              )}
            {workspace.subscription.type === "trial" &&
              workspace.subscription.isExpired &&
              workspace.subscription.periodTo && (
                <Typography variant="body2">
                  Ended on {dayjs(workspace.subscription.periodTo).tz().format("L LT")}
                  <br />
                  The subscription is expired
                </Typography>
              )}
          </Box>
        </DrawerSection>

        {workspace.subscription.type === "trial" && (
          <>
            <DrawerSection name="Extend trial">
              <Box mt={1} mb={1.5}>
                <PlainDatePicker id="date-picker-dialog" value={selectedDate} onChange={setSelectedDate} />
              </Box>
              <ButtonUI disabled={!selectedDate.isValid} onClick={updateSubscriptionTrial}>
                Update Trial end
              </ButtonUI>
            </DrawerSection>

            <Box pt={3}>
              <Divider />
            </Box>

            <DrawerSection name="Change plan">
              <Box mt={1}>
                <CustomSelector
                  id="plan-select"
                  fullWidth
                  value={plan}
                  onChange={(_, newValue) => setPlan(newValue)}
                  getOptionLabel={(option) => option.name}
                  defaultPaginatedQueryProps={{
                    sortBy: "created_at",
                    sortDirection: "descending",
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  usePaginatedQuery={api.backOffice.billing.usePlans}
                  size="small"
                  placeholder="Select a plan"
                  renderOption={(option) => <BackOfficePlanInAutocomplete plan={option} />}
                />
                <Box mt={2} />
                <DateRangePicker
                  value={newPlanDateRange}
                  onChange={setNewPlanDateRange}
                  disableFuture={false}
                  nonNullable={true}
                  enableQuickRangePickers={true}
                />

                {checkoutLink && (
                  <Box mt={3}>
                    <CopyableText text={checkoutLink}>
                      <Typography variant="body2">Click to copy checkout link with workspace redirect:</Typography>
                    </CopyableText>
                  </Box>
                )}
                {plan && (
                  <Box mt={1}>
                    <ButtonUI onClick={askStartManualSubscription}>
                      Subscribe this user from {newPlanDateRange.startDate.date.toString()} to{" "}
                      {newPlanDateRange.endDate.date.toString()} with this plan (without stripe)
                    </ButtonUI>
                  </Box>
                )}
              </Box>
            </DrawerSection>
          </>
        )}

        {workspace.subscription.payingDetails && (
          <DrawerSection name="Payment">
            <Typography variant="body1">Period type {workspace.subscription.payingDetails.periodType}</Typography>
            <Typography variant="body1">Source {workspace.subscription.payingDetails.source}</Typography>
            <Typography variant="body1">Is paid {workspace.subscription.payingDetails.isPaid}</Typography>
          </DrawerSection>
        )}

        {workspace.subscription.type === "paying" && (
          <>
            <DrawerSection name="Usage">
              <Paper>
                <SubscriptionUsage subscription={workspace.subscription} />
              </Paper>
            </DrawerSection>
            {(workspace.stripeSubscriptionId || workspace.stripeCustomerId) && (
              <DrawerSection name="Stripe link">
                {workspace.stripeSubscriptionId && (
                  <Link
                    href={`https://dashboard.stripe.com${stripeIsLiveEnvironment ? "" : "/test"}/subscriptions/${
                      workspace.stripeSubscriptionId
                    }`}
                    underline="always"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stripe subscription
                  </Link>
                )}
                <br />
                {workspace.stripeCustomerId && (
                  <Link
                    href={`https://dashboard.stripe.com${stripeIsLiveEnvironment ? "" : "/test"}/customers/${
                      workspace.stripeCustomerId
                    }`}
                    underline="always"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stripe customer
                  </Link>
                )}
              </DrawerSection>
            )}
          </>
        )}
      </DrawerCategory>

      <BackOfficeView adminOnly>
        <DrawerSection name="Remove subscription">
          <div className="mt-2">
            <ButtonUI onClick={askRemoveSubscription}>Remove workspace subscription</ButtonUI>
          </div>
        </DrawerSection>
      </BackOfficeView>

      <BackOfficeView techAdminOnly>
        <BackOfficeWorkspaceFeatures workspaceId={workspace.id} />
      </BackOfficeView>

      <DrawerCategory title="Workspace metrics">
        <DrawerSection name="Users">
          <ButtonUI
            onClick={() =>
              history.push(
                URLS.BackOffice.User.getUrl({
                  inWorkspaceId: workspace.id,
                }),
              )
            }
          >
            {workspace.userCount} {pluralize(workspace.userCount > 1, "user")}
          </ButtonUI>
        </DrawerSection>

        <DrawerSection name="Transactions">
          <Typography variant="body2" color="textSecondary">
            {workspace.transactionCount} {pluralize(workspace.transactionCount > 1, "transaction")}
          </Typography>
        </DrawerSection>
      </DrawerCategory>

      <DrawerCategory title="Other">
        <Box mt={2}>
          <ButtonUI onClick={() => openWorkspaceDrawer(workspace.id)}>See workspace settings</ButtonUI>
        </Box>
        <Box mt={2}>
          <ButtonUI onClick={seeAsUserWorkspace}>See as User</ButtonUI>
        </Box>
      </DrawerCategory>
    </Box>
  )
}

export default BackOfficeWorkspaceDrawer
