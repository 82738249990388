import React, { useCallback, useState } from "react"

import ModalUI from "CryptioUI/Modal"
import { useTypedController } from "@hookform/strictly-typed"
import { useForm } from "react-hook-form"
import ButtonUI from "CryptioUI/Button"
import { Autocomplete } from "@material-ui/lab"
import { DrawerFormSection } from "../Drawer/DrawerItems"
import { TextField, Box } from "@material-ui/core"
import { useLoadingButton } from "../LoadingButton"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { CreateChartAccountDtoTypeEnum } from "services/api/openapi"
import DialogDataConfirmQuit from "components/misc/BasicDialogContext/ReusableContextData/ConfirmQuit"
import useDialog from "components/misc/useDialog"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface FormType {
  code: string
  name: string
  type: CreateChartAccountDtoTypeEnum | null
}
const COATypeOptions: CreateChartAccountDtoTypeEnum[] = ["income", "expense", "asset", "liability", "equity"]

const ChartAccountAddModal = (): JSX.Element => {
  const { handleSubmit, control, formState } = useForm<FormType>({
    mode: "onChange",
    defaultValues: {
      code: "",
      name: "",
      type: null,
    },
  })
  const TypedController = useTypedController<FormType>({ control })
  const [SaveTransactionButton, handleButtonCallback] = useLoadingButton()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { mutateAsync: createChartAccountMutation } = api.chartAccount.useCreateChartAccounts()
  const onClose = () => setIsOpen(false)
  const basicDialog = useDialog()
  const toast = useToast()

  const closeModal = () => {
    if (formState.isDirty) {
      basicDialog.showDialog(DialogDataConfirmQuit(onClose))
    } else onClose()
  }
  const onSubmit = useCallback(
    async (form: FormType) => {
      try {
        if (!form.type) {
          throw new Error("You must provide a COA type")
        }
        await createChartAccountMutation({
          createChartAccountsDto: {
            accounts: [
              { name: form.name, description: "", code: form.code, type: form.type, externalIdentifier: null },
            ],
          },
        })
        onClose()
        toast.open("COA successfully imported", { variant: "success" })
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [createChartAccountMutation, toast],
  )

  return (
    <>
      {basicDialog.dialog}
      <ButtonUI onClick={() => setIsOpen(true)}>Add new COA</ButtonUI>
      <ModalUI isOpen={isOpen} onClose={closeModal} className="w-[1000px] h-auto" title="Add Chart of accounts">
        <Box component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))} mb={1}>
          <DrawerFormSection htmlFor="code-coa-textfield" name="Code">
            <TypedController
              name="code"
              defaultValue={""}
              rules={{ required: true, minLength: 1, maxLength: 256 }}
              render={(props) => (
                <TextField
                  id="code-coa-textfield"
                  {...props}
                  placeholder="Code"
                  fullWidth={true}
                  error={!!formState.errors.name}
                />
              )}
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="name-coa-textfield" name="Name">
            <TypedController
              name="name"
              defaultValue={""}
              rules={{ required: true, minLength: 1, maxLength: 256 }}
              render={(props) => (
                <TextField
                  id="name-coa-textfield"
                  {...props}
                  placeholder="Name"
                  fullWidth={true}
                  error={!!formState.errors.name}
                />
              )}
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="type-coa-select" name="Type">
            <TypedController
              name="type"
              rules={{ required: true, validate: (value) => value != null }}
              render={({ value, onChange }) => (
                <Autocomplete
                  id="type-coa-select"
                  value={value}
                  onChange={(_, newValue) => {
                    onChange(newValue)
                  }}
                  options={COATypeOptions}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select a mapping type" error={!!formState.errors.type} />
                  )}
                  size="small"
                />
              )}
            />
          </DrawerFormSection>
          <div className="flex flex-row gap-x-4 mt-6 justify-end">
            <ButtonUI onClick={closeModal} mode={Mode.CONTAINED}>
              Close
            </ButtonUI>
            <SaveTransactionButton disabled={!formState.isValid} type="submit">
              Save
            </SaveTransactionButton>
          </div>
        </Box>
      </ModalUI>
    </>
  )
}

export default ChartAccountAddModal
