import { makeStyles, Tab, Tabs } from "@material-ui/core"

import React, { useEffect, useState } from "react"

import { CostBasisIds } from ".."

import useDrawer from "../../misc/useDrawer"

import { DrawerTabs } from "../../Drawer/DrawerItems"
import TabPanel from "../filters/TransactionFilter/TabPannel"
import CostBasisMvt from "./CostBasisMvt"
import ModalUI from "CryptioUI/Modal"

const useStyles = makeStyles(() => ({
  disabled: {
    "&.Mui-disabled": {
      color: "#C4C7D1",
    },
  },
}))

interface Props {
  movementIds: CostBasisIds | null
  onClose: () => void
}

const CostBasisModal = ({ movementIds, onClose }: Props): JSX.Element => {
  const [tab, setTab] = useState<number | undefined>(undefined)
  const classes = useStyles()

  useEffect(() => {
    if (movementIds?.feeMovementId !== undefined) setTab(2)
    if (movementIds?.outgoingMovementId !== undefined) setTab(1)
    if (movementIds?.incomingMovementId !== undefined) setTab(0)
  }, [movementIds])

  const [movementDrawer, openMovementDrawer] = useDrawer("movement")

  function a11yProps(index: number) {
    return {
      "id": `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (_: any, newTab: number) => {
    setTab(newTab)
  }

  const CustomTabs = () => (
    <>
      <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example" indicatorColor="primary">
        <Tab
          className={classes.disabled}
          label="Incoming"
          {...a11yProps(0)}
          disabled={!movementIds?.incomingMovementId}
        />
        <Tab
          className={classes.disabled}
          label="Outgoing"
          {...a11yProps(1)}
          disabled={!movementIds?.outgoingMovementId}
        />
        <Tab className={classes.disabled} label="Fee" {...a11yProps(2)} disabled={!movementIds?.feeMovementId} />
      </Tabs>
    </>
  )

  return (
    <ModalUI
      isOpen={movementIds !== null}
      onClose={onClose}
      className="min-h-[600px] w-5/6 h-fit"
      title="Cost basis detail"
    >
      {movementDrawer}
      {tab !== undefined && (
        <DrawerTabs display="flex" flexDirection="column" mt={0} tabs={<CustomTabs />}>
          <TabPanel tab={tab} index={0}>
            {movementIds && movementIds.incomingMovementId && (
              <CostBasisMvt
                movementId={movementIds.incomingMovementId}
                onClose={onClose}
                openMovementDrawer={openMovementDrawer}
              />
            )}
          </TabPanel>
          <TabPanel tab={tab} index={1}>
            {movementIds && movementIds.outgoingMovementId && (
              <CostBasisMvt
                movementId={movementIds.outgoingMovementId}
                onClose={onClose}
                openMovementDrawer={openMovementDrawer}
              />
            )}
          </TabPanel>
          <TabPanel tab={tab} index={2}>
            {movementIds && movementIds.feeMovementId && (
              <CostBasisMvt
                movementId={movementIds.feeMovementId}
                onClose={onClose}
                openMovementDrawer={openMovementDrawer}
              />
            )}
          </TabPanel>
          {/* TODO: what to display if no taxLines ? */}
        </DrawerTabs>
      )}
    </ModalUI>
  )
}

export default CostBasisModal
