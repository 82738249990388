import { Box } from "@material-ui/core"
import React, { useContext } from "react"

import { DrawerProp } from "components/misc/useDrawer"
import { NewFullTransactionDto } from "services/api/openapi/models"
import { WorkspaceContext } from "services/context/workspaceContext"
import TransactionForm from "./TransactionForm"
import TransactionSummary from "./TransactionSummary"
import TransactionSynchronization from "./TransactionSynchronization"

const TransactionDrawer = (props: DrawerProp<NewFullTransactionDto, false>) => {
  const { item: transaction } = props

  const workspaceCtx = useContext(WorkspaceContext)

  return (
    <Box mb={3}>
      <TransactionForm {...props} />

      <TransactionSummary transaction={transaction} onClose={props.onClose} />

      {/* TODO: right for syncing with xero */}
      {/* workspaceCtx.workspace.userRights.can_sync_transaction && */}
      {workspaceCtx.workspace.accountingIntegration && <TransactionSynchronization {...props} />}
    </Box>
  )
}

export default TransactionDrawer
