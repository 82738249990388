import { Box, Button, makeStyles, Theme, Typography } from "@material-ui/core"
import dayjs from "dayjs"
import React, { useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"

import useDialog from "components/misc/useDialog"
import UpcomingInvoice from "components/Stripe/UpcomingInvoice"
import { URLS } from "../../../routes"
import api from "services/api"
import { GetFullSubscriptionDto } from "services/api/openapi"
import { useQuery } from "services/misc/useQuery"

interface Props {
  subscription: GetFullSubscriptionDto
}

const useStyles = makeStyles((theme: Theme) => ({
  subscribeButton: {
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
}))

const PayingSubscriptionView = ({ subscription }: Props): JSX.Element => {
  const classes = useStyles()
  const basicDialog = useDialog()
  const { mutateAsync: cancelSusbcriptionAsync } = api.billing.useCancelSubscription()
  const { mutateAsync: reactivateSusbcriptionAsync } = api.billing.useReactivateSubscription()

  const query = useQuery()
  const checkoutSuccessFrom = query.get("checkout_success_from")

  const doCancellation = async () => {
    await cancelSusbcriptionAsync({})
  }

  const onAskCancellation = () => {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          You are about to cancel your subscription.
          <br />
          You will not be charged anymore.
          <br />
          The advantages associated with your subscription will remain active until the end of the current period.
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: () => doCancellation(),
    })
  }

  const doReactivation = async () => {
    await reactivateSusbcriptionAsync({})
  }

  const onAskReactivation = () => {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">
          You are about to re-enable your subscription.
          <br />
          You will be charged at the beginning of the next period.
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: () => doReactivation(),
    })
  }

  useEffect(() => {
    if (checkoutSuccessFrom === "new_subscription") {
      basicDialog.showDialog({
        title: "You have subscribed to Cryptio!",
        content: (
          <Typography variant="h5">
            Congratulations! You have subscribed to Cryptio.
            <br />
            You have nothing else to do.
            <br />
            We thank you for your purchase and we hope you will enjoy using Cryptio.
          </Typography>
        ),
        yesText: "Ok",
        onAccept: () => {},
      })
    } else if (checkoutSuccessFrom === "updated_subscription") {
      basicDialog.showDialog({
        title: "Your subscription has been updated!",
        content: (
          <Typography variant="h5">
            Congratulations! Your subscription has been updated.
            <br />
            We thank you for your purchase.
          </Typography>
        ),
        yesText: "Ok",
        onAccept: () => {},
      })
    }

    // WARN Do NOT pass `basicDialog` as a dependency. Functions can not be
    // compared like primitives so this will trigger an infinite render loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutSuccessFrom])

  if (!subscription.payingDetails) return <p>Error</p>

  return (
    <Box>
      {basicDialog.dialog}
      <Typography variant="h4" gutterBottom>
        You have subscribed to Cryptio
      </Typography>
      {subscription.periodTo && subscription.payingDetails.source === "stripe" && (
        <Typography variant="body2">
          You have cancelled your subscription.
          <br />
          Your subscription will expire on {dayjs(subscription.periodTo).tz().format("LLL")}.
        </Typography>
      )}
      {subscription.periodTo && subscription.payingDetails.source === "manual" && (
        <Typography variant="body2">
          Your subscription will expire on {dayjs(subscription.periodTo).tz().format("LLL")}.
        </Typography>
      )}
      {subscription.payingDetails.source === "stripe" && (
        <Box mt={2}>
          {!subscription.periodTo ? (
            <>
              {!subscription.payingDetails.isPaid && (
                <Box>
                  <Typography color="error" variant="h5">
                    You have a pending open invoice. Your account will be disabled in a few days.
                  </Typography>
                </Box>
              )}
              <UpcomingInvoice />
              <Button
                variant="contained"
                color="primary"
                className={classes.subscribeButton}
                component={RouterLink}
                to={URLS.Billing.Simulator}
              >
                Change subscription
              </Button>
              <Box display="inline" mr={1} />
              <Button color="primary" variant="outlined" onClick={onAskCancellation}>
                Cancel subscription
              </Button>
            </>
          ) : (
            <Button color="primary" variant="contained" onClick={onAskReactivation}>
              Reactivate subscription
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}

export default PayingSubscriptionView
