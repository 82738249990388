import { Typography } from "@material-ui/core"
import React from "react"

import { GainColor, LossColor } from "../materialTheme"

const passwordRequirements = [
  {
    key: 0,
    match: /.{8,}/,
    name: "at least 8 characters",
    afterText: " including: ",
  },
  {
    key: 1,
    match: /[a-z]/,
    name: "a lowercase letter",
    afterText: ", ",
  },
  {
    key: 2,
    match: /[A-Z]/,
    name: "an uppercase letter",
    afterText: ", ",
  },
  {
    key: 3,
    match: /\d/,
    name: "a digit",
    afterText: ", and ",
  },
  {
    key: 4,
    match: /[^A-Za-z0-9]/,
    name: "a special character",
    afterText: ".",
  },
]

const PasswordRequirementSentence = ({
  password,
  confirmPassword,
}: {
  password: string
  confirmPassword?: string
}): JSX.Element => {
  return (
    <Typography>
      Make sure it&apos;s{" "}
      {passwordRequirements.map((pr) => (
        // Todo: replace GainColor && LossColor
        <React.Fragment key={pr.key}>
          <span style={{ color: pr.match.test(password) ? GainColor : LossColor }}>{pr.name}</span>
          {pr.afterText}
        </React.Fragment>
      ))}
      {confirmPassword !== undefined && (
        <>
          {" "}
          The two{" "}
          <span style={{ color: password === confirmPassword && password.length > 0 ? GainColor : LossColor }}>
            passwords must match
          </span>
          .
        </>
      )}
    </Typography>
  )
}

export default PasswordRequirementSentence
