import { BoxProps } from "@material-ui/core"
import React from "react"
import { UseQueryResult } from "react-query"
import ModalUI from "CryptioUI/Modal"

import LoadingSpinner from "../misc/LoadingSpinner"
import NetworkErrorMessage from "../misc/NetworkErrorMessage"

interface Props extends BoxProps {
  isOpen: boolean
  onClose: () => void
  title?: string
  inlineContainer?: BoxProps
}

export const LoadingModal = (props: Props) => (
  <ModalUI {...props}>
    <LoadingSpinner />
  </ModalUI>
)

export const ErrorModal = ({ additionalData, ...props }: Props & { additionalData?: UseQueryResult<unknown> }) => (
  <ModalUI {...props}>
    <NetworkErrorMessage additionalData={additionalData} />
  </ModalUI>
)
