import React, { useMemo, useState } from "react"

import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import useFilterDrawer from "components/Hooks/useFilterDrawer"
import InputClearable from "components/Input/InputClearable"
import InputNumber from "components/Input/InputNumber"
import DrawerUI from "CryptioUI/Drawer"
import isNumberStringValid from "services/utils/isNumberStringValid"
import { defaultWorkspaceParams, workspaceHasAnyFilter, WorkspaceParameters } from "../index"

interface Props {
  filter: WorkspaceParameters
  setFilter: (newValue: WorkspaceParameters) => void
}

const WorkspaceFilter = (props: Props): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const { OpenButton, SaveButton, tempFilter, updateFilter } = useFilterDrawer({
    ...props,
    defaultParams: defaultWorkspaceParams,
    setIsDrawerOpen,
  })

  const filterCount = useMemo(() => {
    const filter = props.filter
    let res = 0
    if (filter.queryName) res++
    if (filter.minimumTransaction) res++
    if (filter.minimumUser) res++
    return res
  }, [props.filter])

  const canClear = filterCount > 0 || workspaceHasAnyFilter(tempFilter)

  return (
    <>
      <OpenButton filterCount={filterCount} />

      <DrawerUI title="Workspace Filter" onClose={() => setIsDrawerOpen(false)} isOpen={isDrawerOpen}>
        <DrawerCategory title="Filter">
          <DrawerFormSection htmlFor="search-name-textfield" name="Search by name">
            <InputClearable
              id="search-name-textfield"
              value={tempFilter.queryName || ""}
              onChange={(value) =>
                updateFilter({
                  queryName: value,
                })
              }
              placeholder="Name"
              fullWidth
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="minimum-user-textfield" name="Minimum User Count">
            <InputNumber
              id="minimum-user-textfield"
              value={tempFilter.minimumUser ?? ""}
              onChange={(value) => {
                updateFilter({
                  minimumUser: value,
                })
              }}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="minimum-transaction-textfield" name="Minimum Transactions Count">
            <InputNumber
              id="minimum-transaction-textfield"
              value={tempFilter.minimumTransaction ?? ""}
              onChange={(value) => {
                updateFilter({
                  minimumTransaction: value,
                })
              }}
            />
          </DrawerFormSection>

          <SaveButton
            filterCount={filterCount}
            canClear={canClear}
            canSave={
              (tempFilter.minimumTransaction === undefined || isNumberStringValid(tempFilter.minimumTransaction)) &&
              (tempFilter.minimumUser === undefined || isNumberStringValid(tempFilter.minimumUser))
            }
          />
        </DrawerCategory>
      </DrawerUI>
    </>
  )
}

export default WorkspaceFilter
