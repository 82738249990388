import SimpleAutocompleteFactory from "components/Autocomplete/SimpleAutocompleteFactory"
import { TransactionFilterOrderTypeValueEnum } from "services/api/openapi"

export const transactionFilterOrderPrettyMap = {
  incoming: "Incoming",
  outgoing: "Outgoing",
  trade: "Trade",
  fee: "Fee",
}

export const OrderTypeFilter = SimpleAutocompleteFactory<TransactionFilterOrderTypeValueEnum>(
  transactionFilterOrderPrettyMap,
)

export default OrderTypeFilter
