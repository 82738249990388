import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import TooltipUI from "CryptioUI/Tooltip"
import React from "react"

import { GetFullSubscriptionDto } from "services/api/openapi"
import { limitTypes } from "./constants"

interface Props {
  subscription: GetFullSubscriptionDto
}

const SubscriptionUsage = ({ subscription }: Props): JSX.Element => (
  <TableContainer component={Box}>
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <b>Name</b>
          </TableCell>
          <TableCell align="right">
            <b>Current usage</b>
          </TableCell>
          <TableCell align="right">
            <b>Maximum</b>
          </TableCell>
          <TableCell align="right">
            <b>Remaining</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {limitTypes.map((limitType) => {
          const packageLimit = subscription.packageLimits.find((x) => x.limitType === limitType.type)
          if (!packageLimit) return "invalid"
          const usage = subscription.currentWorkspaceUsage.find((x) => x.limitType === limitType.type)?.count || 0

          return (
            <TableRow key={limitType.type}>
              <TableCell align="left">{limitType.name}</TableCell>
              <TableCell align="right">{usage}</TableCell>
              <TableCell align="right">
                <TooltipUI content={`Plan: ${packageLimit.planMaximum}, Add-on: ${packageLimit.addonAdditional || 0}`}>
                  <span>{packageLimit.planMaximum + (packageLimit.addonAdditional || 0)}</span>
                </TooltipUI>
              </TableCell>
              <TableCell align="right">
                {packageLimit.planMaximum + (packageLimit.addonAdditional || 0) - usage}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </TableContainer>
)

export default SubscriptionUsage
