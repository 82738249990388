import { useTypedController } from "@hookform/strictly-typed"
import { Box, FormLabel, TextField } from "@material-ui/core"
import React, { ChangeEvent } from "react"
import { Control } from "react-hook-form"

import { WalletCredentialField } from "services/api/openapi"
import { camelCaseToTitleCase } from "services/utils/textUtils"

interface BasicStringProps {
  field: WalletCredentialField
  control: Control<Record<string, any>> | undefined
  isRequired: boolean
}

export default function BasicString({ field, control, isRequired }: BasicStringProps) {
  const TypedController = useTypedController<any>({ control })
  const isRequiredName = () => (!isRequired ? " (optional)" : "")

  return (
    <Box mt={2}>
      <FormLabel htmlFor={`${field.name}-textfield`}>{camelCaseToTitleCase(field.name) + isRequiredName()}</FormLabel>
      <TypedController
        name={field.name}
        defaultValue={!isRequired ? null : ""}
        rules={{ required: field.isRequired, minLength: 1, pattern: /[^-\s]/ }}
        render={({ onChange, value }) => {
          return (
            <>
              <TextField
                value={value}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  onChange(event.target.value)
                }}
                id={`${field.name}-textfield`}
                fullWidth
                margin="normal"
              />
            </>
          )
        }}
      />
    </Box>
  )
}
