import { Box, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { URLS } from "../../routes"
import api from "services/api"

interface LabelsRouteState {
  openGlobalLabelsExplanation: boolean
}

export default function GlobalLabelsExplanation() {
  const state = useLocation<LabelsRouteState | undefined>().state
  const history = useHistory()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { mutateAsync: updateHasSeenGlobalLabelsExplanation } = api.workspace.useUpdateHasSeenGlobalLabelsExplanation()
  useEffect(() => {
    setIsOpen(state?.openGlobalLabelsExplanation ? true : false)
  }, [state])

  const onClose = () => {
    setIsOpen(false)
    if (state) history.replace(URLS.Business.Labels.getUrl({ labelType: "global_label" }))
  }
  const setHasSeenGlobalLabelsExplanation = async () => {
    await updateHasSeenGlobalLabelsExplanation({})
    onClose()
  }

  return (
    <ModalUI isOpen={isOpen} onClose={onClose} className="w-[600px] h-[270px]" title="What are global labels?">
      <Typography>
        Global labels are smart contract interactions, internal transfers, exchange activity (staking, etc) that are in
        the transaction history of your imported sources.
      </Typography>
      <Typography>Cryptio picks up these transactions and auto-labels them for you.</Typography>
      <Box mt={4} display="flex" justifyContent="flex-end">
        <ButtonUI onClick={setHasSeenGlobalLabelsExplanation}>
          <Typography>Got it</Typography>
        </ButtonUI>
      </Box>
    </ModalUI>
  )
}
