import { Box, createStyles, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import React from "react"

import { LightGrey, PrimaryColor } from "../materialTheme"
import { isProd } from "services/variables"

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      width: "100vw",
      backgroundColor: PrimaryColor,
      color: LightGrey,
      display: "flex",
      flexFlow: "column",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
    },
    logo: {
      maxWidth: "230px",
    },
  }),
)

function MobileNotSupported() {
  const classes = useStyle()

  return (
    <Box className={classes.root}>
      <img src="/newLogo.svg" alt="The Cryptio logo" className={classes.logo} />
      <Box fontSize="1.3rem" margin={4} textAlign="center">
        <p>Cryptio is a desktop application and does not have mobile support (yet!).</p>
        <p>Please, come back on a desktop device with a larger screen to access the application.</p>
      </Box>
    </Box>
  )
}

interface Props {
  children: React.ReactNode
}

export function MobileGate(props: Props) {
  const { children } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`)

  return <>{isMobile && isProd ? <MobileNotSupported /> : children}</>
}
