import { useTypedController } from "@hookform/strictly-typed"
import { Box, FormLabel } from "@material-ui/core"
import React from "react"
import { Control } from "react-hook-form"

import PasswordField from "components/PasswordField"

import { WalletCredentialField } from "services/api/openapi"
import { camelCaseToTitleCase } from "services/utils/textUtils"

interface HiddenStringProps {
  field: WalletCredentialField
  control: Control<Record<string, any>> | undefined
  isRequired: boolean
}

export default function HiddenString({ field, control, isRequired }: HiddenStringProps) {
  const TypedController = useTypedController<any>({ control })
  const isRequiredName = () => (!isRequired ? " (optional)" : "")

  return (
    <Box mt={2}>
      <FormLabel htmlFor={`${field.name}-textfield`}>{camelCaseToTitleCase(field.name) + isRequiredName()}</FormLabel>
      <TypedController
        name={field.name}
        defaultValue=""
        rules={{ required: field.isRequired, minLength: 1 }}
        render={(props) => <PasswordField {...props} id={`${field.name}-textfield`} fullWidth margin="normal" />}
      />
    </Box>
  )
}
