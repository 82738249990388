import React, { useContext } from "react"

import { TransactionParams } from "../../.."

import FullHistory from "./FullHistory"

import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import { ReactComponent as PlusCircle } from "CryptioUI/assets/icons/plus-circle.svg"
import { ObjectTypedKeys } from "pure-shared"
import { useFilterMap } from "../useFilterMap"
import api from "services/api"
import FavoriteButton from "../FavoriteTab/FavoriteButton"
import IconButton from "../IconButton"
import { GetTransactionFilterDto } from "services/api/openapi"
import { FilterTypeWithDateState, FilterTypeWithoutDateState } from ".."
import { PlainDate } from "components/DateRangePicker"
import useGenerateFilters, { isFilterAvailable } from "./useGenerateFilters"
import { WorkspaceContext } from "services/context/workspaceContext"
import { UserContext } from "services/context/userContext"
import { iconStylePrimary } from "CryptioUI/Utilities/config"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  addFilterContainer: {
    "paddingTop": theme.spacing(1.5),
    "display": "flex",
    "flexDirection": "row",
    "alignItems": "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  containerButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  loadIcon: {
    height: "1.1em",
    width: "1.1em",
  },
}))

interface Props {
  tempFilter: TransactionParams
  tempFilterWithoutDate: FilterTypeWithoutDateState[]
  setTempFilterWithoutDate: (value: FilterTypeWithoutDateState[]) => void
  tempFilterDate: FilterTypeWithDateState[]
  setTempFilterDate: (value: FilterTypeWithDateState[]) => void
  startDateTemp: PlainDate | undefined
  setStartDateTemp: (value: PlainDate | undefined) => void
  endDateTemp: PlainDate | undefined
  setEndDateTemp: (value: PlainDate | undefined) => void
  setIsModalOpen: (isOpen: boolean) => void
  deleteTransactionFilter: (savedFilter: GetTransactionFilterDto, actionAfterConfirm?: () => void) => void
  setFilterLoaded: (filterLoaded: GetTransactionFilterDto | undefined) => void
  filterLoaded: GetTransactionFilterDto | undefined
  saveButton: JSX.Element
  canSave: boolean
  setIsErrorValidation: (newValue: boolean) => void
  isErrorValidation: boolean
  isFullHistory: boolean
  setIsFullHistory: (isFullHistory: boolean) => void
}

const NewFilterTab = (props: Props): JSX.Element => {
  const {
    tempFilter,
    tempFilterWithoutDate,
    setTempFilterWithoutDate,
    tempFilterDate,
    setTempFilterDate,
    startDateTemp,
    setStartDateTemp,
    endDateTemp,
    setEndDateTemp,
    setIsModalOpen,
    deleteTransactionFilter,
    setFilterLoaded,
    filterLoaded,
    saveButton,
    canSave,
    setIsErrorValidation,
    isErrorValidation,
    isFullHistory,
    setIsFullHistory,
  } = props
  const classes = useStyles()
  const toast = useToast()
  const { mutateAsync: updateTransactionFiltersMutation } = api.filter.useUpdateFilters()
  const { workspace } = useContext(WorkspaceContext)
  const { user } = useContext(UserContext)

  const updateFavoriteFilter = async (name: string, filterId: string) => {
    try {
      await updateTransactionFiltersMutation({
        updateTransactionFiltersDto: { ...tempFilter, name: name },
        filterId: filterId,
      })

      toast.open(`Filter ${name} updated`, { variant: "default" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const { mapFactory, mapFactoryDate } = useFilterMap({
    filters: tempFilterWithoutDate,
    setFilters: setTempFilterWithoutDate,
  })

  const transactionSyncTypeArrayRef = ObjectTypedKeys(mapFactory)
    .filter((key) => !tempFilterWithoutDate.some((f) => f.type === key))
    .filter((option) => isFilterAvailable(option, workspace, user))

  const { filtersEditable } = useGenerateFilters({
    mapFactory,
    tempFilterWithoutDate,
    setTempFilterWithoutDate,
    setIsErrorValidation,
    isErrorValidation,
  })

  return (
    <>
      {filterLoaded !== undefined && (
        <Box pt={4} display="flex" flexDirection="row" alignItems="center">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
              {`Filter name:`}
            </Typography>
          </Box>
          <Box pl={1}>
            <Typography variant="h5" color="primary">
              {`${filterLoaded.name}`}
            </Typography>
          </Box>
        </Box>
      )}
      <FullHistory
        filtersDate={tempFilterDate}
        setFiltersDate={setTempFilterDate}
        mapFactoryDate={mapFactoryDate}
        startDateTemp={startDateTemp}
        setStartDateTemp={setStartDateTemp}
        isFullHistory={isFullHistory}
        setIsFullHistory={setIsFullHistory}
        endDateTemp={endDateTemp}
        setEndDateTemp={setEndDateTemp}
      />
      <>{filtersEditable}</>
      {transactionSyncTypeArrayRef.length ? (
        <div className={classes.containerButton}>
          <div className={classes.addFilterContainer}>
            <IconButton
              onClick={() => {
                const cpy = JSON.parse(JSON.stringify(tempFilterWithoutDate))

                cpy.push(mapFactory[transactionSyncTypeArrayRef[0]].defaultValue)
                setTempFilterWithoutDate(cpy)
              }}
              title="Add filter"
              icon={<PlusCircle className={iconStylePrimary} />}
            />
          </div>
          <FavoriteButton
            updateFavoriteFilter={updateFavoriteFilter}
            setIsModalOpen={setIsModalOpen}
            deleteTransactionFilter={deleteTransactionFilter}
            setFilterLoaded={setFilterLoaded}
            filterLoaded={filterLoaded}
            canSave={canSave}
          />
        </div>
      ) : (
        <></>
      )}
      {saveButton}
    </>
  )
}

export default NewFilterTab
