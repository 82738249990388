import dayjs from "dayjs"
import React, { useContext, useEffect } from "react"
import {
  MutationFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query"

import { queryClient } from "../.."
import { WithoutWorkspaceId } from "../api/aliases"
import { GetWorkspaceDto } from "../api/openapi"
import { GET_CHART_ACCOUNT_MAPPING_KEY, GET_CHART_ACCOUNT_MAPPINGS_KEY } from "../api/routes/chartAccount"
import { GET_TRANSACTION_KEY, GET_TRANSACTIONS_KEY } from "../api/routes/transactions"
import ChildrenProps from "../misc/childrenProps"

export interface WorkspaceContextValue {
  workspace: GetWorkspaceDto
  changeWorkspace: (newWorkspaceId: string) => void
}

export const WorkspaceContext = React.createContext<WorkspaceContextValue>(
  undefined as unknown as WorkspaceContextValue,
)

type AllProps = ChildrenProps & {
  workspace: GetWorkspaceDto
  setCurrentWorkspaceId: (workspaceId: string) => void
}

export interface WorkspacePrefix {
  workspaceId: string
}

export function usePaginatedWorkspaceQuery<
  TQueryFnData,
  TVariables extends WorkspacePrefix,
  TError = unknown,
  TData = TQueryFnData,
>(
  queryKey: QueryKey,
  queryFn: (variables: TVariables) => Promise<TQueryFnData>,
  variables: WithoutWorkspaceId<TVariables>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, QueryKey>,
): UseQueryResult<TData, TError> {
  const workspaceCtx = useContext(WorkspaceContext)
  return useQuery<TQueryFnData, TError, TData, QueryKey>(
    [...queryKey, workspaceCtx.workspace.id],
    () =>
      queryFn({
        workspaceId: workspaceCtx.workspace.id,
        ...variables,
      } as TVariables),
    {
      ...options,
      keepPreviousData: true,
      enabled:
        (!options || options.enabled) && localStorage.workspaceId !== undefined && localStorage.workspaceId !== null,
    },
  )
}

export function useWorkspaceQuery<
  TQueryFnData,
  TVariables extends WorkspacePrefix,
  TError = unknown,
  TData = TQueryFnData,
>(
  queryKey: readonly unknown[],
  queryFn: (variables: TVariables) => Promise<TQueryFnData>,
  variables: WithoutWorkspaceId<TVariables>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, QueryKey>,
): UseQueryResult<TData, TError> {
  const workspaceCtx = useContext(WorkspaceContext)
  return useQuery<TQueryFnData, TError, TData, QueryKey>(
    [...queryKey, workspaceCtx.workspace.id],
    () =>
      queryFn({
        workspaceId: workspaceCtx.workspace.id,
        ...variables,
      } as TVariables),
    {
      ...options,
      enabled:
        (!options || options.enabled) && localStorage.workspaceId !== undefined && localStorage.workspaceId !== null,
    },
  )
}

export function useWorkspaceMutation<TData, TVariables extends WorkspacePrefix, TError = unknown, TContext = unknown>(
  func: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, WithoutWorkspaceId<TVariables>, TContext>,
): UseMutationResult<TData, TError, WithoutWorkspaceId<TVariables>, TContext> {
  const workspaceCtx = useContext(WorkspaceContext)
  return useMutation(
    (inputVars: WithoutWorkspaceId<TVariables>) =>
      func({
        ...inputVars,
        workspaceId: workspaceCtx.workspace.id,
      } as TVariables),
    options,
  )
}

export const WorkspaceContextProvider = (props: AllProps): JSX.Element => {
  const { workspace, setCurrentWorkspaceId } = props

  useEffect(() => {
    dayjs.tz.setDefault(workspace.timezone)
  }, [workspace.timezone])

  const costBasisStatus = workspace.costBasisStatus.status
  useEffect(() => {
    if (costBasisStatus === "inactive") {
      queryClient.invalidateQueries(GET_TRANSACTION_KEY)
      queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
    }
  }, [costBasisStatus])

  const coaStatus = workspace.coaMappingStatus.status
  useEffect(() => {
    if (coaStatus === "inactive") {
      queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPING_KEY)
      queryClient.invalidateQueries(GET_CHART_ACCOUNT_MAPPINGS_KEY)
      queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
    }
  }, [coaStatus])

  const deletingStatus = workspace.deletingStatus.status
  useEffect(() => {
    if (deletingStatus === "inactive") {
      queryClient.invalidateQueries(GET_TRANSACTION_KEY)
      queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
    }
  }, [deletingStatus])

  return (
    <WorkspaceContext.Provider
      value={{
        workspace: workspace,
        changeWorkspace: setCurrentWorkspaceId,
      }}
    >
      {props.children}
    </WorkspaceContext.Provider>
  )
}
