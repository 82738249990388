import { CircularProgress, TextField } from "@material-ui/core"
import { Autocomplete, AutocompleteGetTagProps } from "@material-ui/lab"
import { prettyEmptyOrNull } from "pure-shared"
import React, { useMemo, useState } from "react"

import api from "services/api"
import { BasicElement } from "services/api/aliases"
import { GetAssetDto } from "services/api/openapi"
import AssetOptionInAutocomplete from "../../../../AutoCompleteOptions/AssetOptionInAutocomplete"

export type AssetWithDefaultingName = Omit<GetAssetDto, "name"> & { name: string }

interface Props {
  id?: string
  assets: AssetWithDefaultingName[]
  onAssetsChange: (newValue: AssetWithDefaultingName[]) => void
  renderTags?: ((value: BasicElement[], getTagProps: AutocompleteGetTagProps) => React.ReactNode) | undefined
  size?: "small" | "medium" | undefined
  placeholder?: string
}

const AssetFilter = (props: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState("")
  const [limitValue, setLimitValue] = useState<number>(5)

  const assets = api.transaction.useAssets({
    limit: limitValue,
    page: 1,
    query: inputValue,
    type: undefined,
    excludedIds: props.assets.map((a) => a.id),
  })

  const assetsWithName = useMemo(() => {
    if (assets.data?.data) {
      return assets.data.data.map((asset) => ({
        ...asset,
        name: prettyEmptyOrNull(asset.name),
      }))
    }
    return []
  }, [assets.data])

  return (
    <Autocomplete
      id={props.id}
      multiple
      renderTags={props.renderTags ?? undefined}
      getOptionLabel={(option) => option.name}
      filterOptions={(x) => x}
      options={assetsWithName}
      disableCloseOnSelect
      filterSelectedOptions
      getOptionDisabled={props.assets.length >= 10 ? () => true : () => false}
      getOptionSelected={(option, value) => option.id === value.id}
      loading={assets.isLoading}
      value={props.assets}
      size={props.size}
      onChange={(event, newValue) => {
        props.onAssetsChange(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
        if (newInputValue.length > 1) {
          setLimitValue(500)
        } else {
          setLimitValue(5)
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {assets.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(option) => <AssetOptionInAutocomplete asset={option} />}
    />
  )
}

export default AssetFilter
