import { Checkbox } from "@material-ui/core"
import { ExpandMoreRounded } from "@material-ui/icons"
import React, { useContext, useEffect } from "react"
import DataTable, { IDataTableProps, IDataTableStyles } from "react-data-table-component"

import { ThemeContext } from "services/context/themeContext"
import { GenericSorting, IExtendedDataTableColumn, SortDirectionEnum } from "./interface"
import { DataTableThemeName, getDataTableStyle } from "./theme"

// from react-data-table
interface PaginationProps {
  pagination?: boolean
  paginationServer?: boolean
  paginationServerOptions?: {
    persistSelectedOnSort?: boolean
    persistSelectedOnPageChange?: boolean
  }
  paginationResetDefaultPage?: boolean
  paginationTotalRows?: number
  paginationPerPage?: number
  paginationRowsPerPageOptions?: number[]
  onChangeRowsPerPage?: (currentRowsPerPage: number, currentPage: number) => void
  onChangePage?: (page: number) => void
  paginationTarget?: string
}

export interface BaseTableProps<T, TSortEnum extends string | undefined> extends PaginationProps {
  columns: IExtendedDataTableColumn<T, TSortEnum>[]
  items: T[]

  customStyle?: IDataTableStyles
  onSort?: (newValue: GenericSorting<TSortEnum>) => void
  onSelectedChange?: (newSelected: { allSelected: boolean; selectedCount: number; selectedRows: T[] }) => void
  fixedScroll?: IDataTableProps<T>["fixedHeaderScrollHeight"]
  onRowClicked?: IDataTableProps<T>["onRowClicked"]
  expandComponent?: IDataTableProps<T>["expandableRowsComponent"]
  expandOnRowClicked?: boolean

  defaultParams?: {
    sortBy?: TSortEnum
    sortDirection?: SortDirectionEnum
    page?: number
  }
  keyField?: IDataTableProps<T>["keyField"]
  sortServer?: IDataTableProps<T>["sortServer"]
  selectableRowsComponent?: IDataTableProps<T>["selectableRowsComponent"]
  selectableRowSelected?: IDataTableProps<T>["selectableRowSelected"]
  selectableRowsNoSelectAll?: IDataTableProps<T>["selectableRowsNoSelectAll"]
  dense?: IDataTableProps<T>["dense"]
  conditionalRowStyles?: IDataTableProps<T>["conditionalRowStyles"]
}

const BaseTable = <T, TSortEnum extends string | undefined = undefined>(
  props: BaseTableProps<T, TSortEnum>,
): JSX.Element => {
  const muiThemeContext = useContext(ThemeContext)
  const {
    columns,
    items,
    onSelectedChange,
    onRowClicked,
    expandComponent,
    fixedScroll,
    keyField,
    expandOnRowClicked,
    sortServer = true,
    customStyle = getDataTableStyle(muiThemeContext.type),
    selectableRowsComponent,
    selectableRowSelected,
    conditionalRowStyles,
    dense,
    onChangePage,
    defaultParams,
  } = props

  // Sorting callback: transactions are sorted by the latest clicked column.
  const onSortChanged = (column: IExtendedDataTableColumn<T, TSortEnum>, direction: "asc" | "desc") => {
    if (props.onSort && column.sortName) {
      props.onSort({
        sortBy: column.sortName,
        sortDirection: direction === "asc" ? "ascending" : "descending",
      })
    }
  }

  useEffect(() => {
    if (onChangePage && defaultParams && defaultParams.page !== 1 && items.length === 0) {
      onChangePage(1)
    }
  }, [onChangePage, defaultParams, items])

  return (
    <DataTable
      conditionalRowStyles={conditionalRowStyles}
      persistTableHead={true}
      expandOnRowClicked={expandOnRowClicked}
      noDataComponent={""}
      columns={columns}
      theme={DataTableThemeName}
      customStyles={customStyle}
      data={items}
      dense={dense}
      responsive
      onSort={props.onSort && onSortChanged}
      sortServer={sortServer}
      noContextMenu
      noHeader
      expandOnRowDoubleClicked
      selectableRowsHighlight
      highlightOnHover
      selectableRowSelected={selectableRowSelected}
      selectableRows={!!onSelectedChange}
      selectableRowsComponent={selectableRowsComponent ?? Checkbox}
      sortIcon={<ExpandMoreRounded />}
      selectableRowsComponentProps={{ color: "primary" }}
      onSelectedRowsChange={onSelectedChange}
      expandableRows={!!expandComponent}
      expandableRowsComponent={expandComponent}
      onRowClicked={onRowClicked}
      pagination={props.pagination}
      paginationServer={props.paginationServer}
      paginationPerPage={props.paginationPerPage}
      paginationServerOptions={props.paginationServerOptions}
      paginationResetDefaultPage={props.paginationResetDefaultPage}
      selectableRowsNoSelectAll={props.selectableRowsNoSelectAll}
      paginationTotalRows={props.paginationTotalRows}
      paginationRowsPerPageOptions={props.paginationRowsPerPageOptions}
      paginationDefaultPage={props.defaultParams?.page}
      defaultSortAsc={props.defaultParams && props.defaultParams.sortDirection === "ascending"}
      defaultSortField={props.defaultParams?.sortBy}
      onChangeRowsPerPage={props.onChangeRowsPerPage}
      onChangePage={props.onChangePage}
      fixedHeader={!!fixedScroll}
      fixedHeaderScrollHeight={fixedScroll}
      keyField={keyField}
    />
  )
}

export default BaseTable
