import { Box, DialogActions, makeStyles, Theme, Typography } from "@material-ui/core"
import { CheckRounded, WarningRounded } from "@material-ui/icons"
import { ColorHex } from "pure-shared"
import React, { useContext, useRef, useState } from "react"
import { IDataTableColumn } from "react-data-table-component"
import { useForm } from "react-hook-form"

import { LabelCircleColor } from "components/Labels/LabelCircleColor"
import { useLoadingButton } from "components/LoadingButton"
import BaseTable from "components/Table"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { CreateLabelDto } from "services/api/openapi"
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from "services/constants"
import { ThemeContext } from "services/context/themeContext"
import parseCsvFile, {
  CsvModalType,
  CsvParseOptions,
  findRowByRowIdFilterByErrorString,
  WithCsvInfos,
} from "services/csvParse"
import { widgetTableStyle } from "../../Dashboard/widgets/widgetTableStyle"
import InputButtonUI from "CryptioUI/InputButton"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"
// Special for this csv because of addresses

type LabelWithCsvInfos = WithCsvInfos<CreateLabelDto>
interface LabelCreationModalCsvForm {
  labels: LabelWithCsvInfos[]
}

const useStyles = makeStyles((theme: Theme) => ({
  validateButton: {
    marginRight: theme.spacing(2),
  },
}))

const columns: IDataTableColumn<LabelWithCsvInfos>[] = [
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Color",
    selector: "color",
    sortable: true,
    format: function formatColor(row) {
      return <LabelCircleColor color={row.color} />
    },
  },
  {
    name: "Status",
    selector: "errorString",
    sortable: true,
    format: function formatAddress(row) {
      return row.errorString ? (
        <Typography color="error" variant="body1">
          <Box display="flex">
            <Box pr={1}>
              <WarningRounded />
            </Box>
            {row.errorString}
          </Box>
        </Typography>
      ) : (
        <CheckRounded color="action" />
      )
    },
  },
]

const LabelCreationModalCsv = (): JSX.Element => {
  const classes = useStyles()
  const [modalData, setModalData] = useState<CsvModalType<CreateLabelDto>>({
    isOpen: false,
    data: [],
  })
  const muiThemeContext = useContext(ThemeContext)
  const { handleSubmit } = useForm<LabelCreationModalCsvForm>()
  const toast = useToast()

  const [selectedRows, setSelectedRows] = useState<{
    allSelected: boolean
    selectedCount: number
    selectedRows: LabelWithCsvInfos[]
  }>({ allSelected: false, selectedCount: 0, selectedRows: [] })

  const fileField = useRef<HTMLInputElement>(null)

  const handleFileChange = () => {
    if (!fileField.current || !fileField.current.files || !fileField.current.files.length) {
      return
    }

    const options: CsvParseOptions<CreateLabelDto> = {
      file: fileField.current.files[0],
      requiredColumns: ["name", "color"],
      setModalData,
      toast,
      transformData: (data) =>
        data.map(({ color, name }, index): WithCsvInfos<CreateLabelDto> => {
          let errorString: string | null = null
          if (!name.length) {
            errorString = "Missing name"
          }
          return {
            name,
            // Not handled for now
            walletFromIds: null,
            walletToIds: null,
            contactFromIds: null,
            contactToIds: null,
            errorString,
            rowId: index,
            filterId: null,
            // Assign random color if missing / invalid
            color: color.trim() || ColorHex.gray,
          }
        }),
    }

    parseCsvFile<CreateLabelDto>(options)
  }

  const [ImportSelectionButton, handleButtonCallback] = useLoadingButton()
  const { mutateAsync: createContactMutation } = api.label.useCreateLabels()
  const onSubmit = async () => {
    try {
      // Need filter, empty item in array
      await createContactMutation({
        createLabelsDto: {
          labels: findRowByRowIdFilterByErrorString(selectedRows),
        },
      })
    } catch (e) {
      toastCatch(e as Error, toast)
    }

    setModalData({
      isOpen: false,
      data: [],
    })
  }

  return (
    <>
      <InputButtonUI
        mode={Mode.CONTAINED}
        type="file"
        htmlFor="labels"
        accept=".csv"
        ref={fileField}
        id="labels"
        onChange={handleFileChange}
        onClick={() => {
          if (fileField.current) {
            fileField.current.value = ""
            fileField.current.files = null
          }
        }}
      >
        Import from CSV
      </InputButtonUI>
      <ModalUI
        isOpen={modalData.isOpen}
        onClose={() => setModalData({ isOpen: false, data: [] })}
        className="w-[1000px] h-auto"
        title="Import labels"
      >
        <BaseTable<LabelWithCsvInfos, "errorString">
          columns={columns}
          items={modalData.data}
          fixedScroll="400px"
          onSelectedChange={setSelectedRows}
          paginationRowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          paginationTotalRows={modalData.data.length}
          paginationPerPage={50}
          pagination={modalData.data.length > DEFAULT_ROWS_PER_PAGE}
          customStyle={widgetTableStyle(muiThemeContext.type)}
          defaultParams={{
            sortBy: "errorString",
            sortDirection: "ascending",
          }}
          sortServer={false}
        />
        <Box component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              {modalData.data.length} element
              {modalData.data.length > 1 ? "s" : ""} found
            </Typography>
            <div className="flex flex-row">
              <ImportSelectionButton
                disabled={selectedRows.selectedCount === 0}
                className={classes.validateButton}
                type="submit"
              >
                Import selection
              </ImportSelectionButton>
              <ButtonUI onClick={() => setModalData({ isOpen: false, data: [] })} mode={Mode.CONTAINED}>
                Close
              </ButtonUI>
            </div>
          </DialogActions>
        </Box>
      </ModalUI>
    </>
  )
}

export default LabelCreationModalCsv
