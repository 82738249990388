import BigNumber from "bignumber.js"
import TypographyAllowEvent from "components/TypographyAllowEvent"
import { TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import numbro from "numbro"
import { bigToFormatString, prettyEmptyOrNull } from "pure-shared"
import React, { useContext } from "react"
import { WorkspaceContext } from "services/context/workspaceContext"
import { getSymbol } from "services/utils/AssetSymbols"
import { prettyNumber } from "services/utils/processNumber"

interface Props {
  costBasisFiat: string | null
  extended?: boolean
  isButton?: boolean
}

const InternalCostBasisView = (props: {
  costBasis: BigNumber
  symbol: string | null
  extended?: boolean
  isButton: boolean
}) => (
  <TypographyUI
    variant={TypographyVariant.BODY}
    className={`text-grey-500 ${props.isButton ? "hover:underline hover:text-grey-400" : ""}`}
  >
    {prettyEmptyOrNull(props.symbol)}{" "}
    {props.extended
      ? numbro(props.costBasis).format(prettyNumber(props.costBasis))
      : bigToFormatString(props.costBasis)}
  </TypographyUI>
)

const CostBasisView = ({ costBasisFiat, extended, isButton = false }: Props) => {
  const { workspace } = useContext(WorkspaceContext)

  if (!costBasisFiat) {
    return (
      <TypographyAllowEvent variant={TypographyVariant.BODY} className="text-grey-500">
        -
      </TypographyAllowEvent>
    )
  }

  const userFiatSymbol = getSymbol(workspace.defaultCurrencySymbol)

  let costBasis = new BigNumber(costBasisFiat)
  if (extended) {
    costBasis = costBasis.decimalPlaces(2, BigNumber.ROUND_UP)
  }
  return <InternalCostBasisView extended={extended} costBasis={costBasis} symbol={userFiatSymbol} isButton={isButton} />
}

export default CostBasisView
