import SimpleAutocompleteFactory from "components/Autocomplete/SimpleAutocompleteFactory"
import { FullCostBasisDtoMainCostBasisErrorEnum } from "services/api/openapi"

type CostBasisErrorType = Exclude<FullCostBasisDtoMainCostBasisErrorEnum, "missing_fiat_rate">

export const TransactionCostBasisErrorStatus = SimpleAutocompleteFactory<CostBasisErrorType>({
  negative_balance: "Missing volume",
  missing_usd_rate: "Missing price",
  impacted_by_missing_price: "Previous missing price",
})

export default TransactionCostBasisErrorStatus
