import { useTypedController } from "@hookform/strictly-typed"
import { Box, makeStyles, TextField, Theme } from "@material-ui/core"
import React from "react"
import { useForm } from "react-hook-form"

import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import PasswordField from "components/PasswordField"
import PasswordRequirementSentence from "components/PasswordRequirementSentence"
import { code2faRules, passwordRules } from "components/ReactHookForm/rules"
import api from "services/api"
import { GetUserDto } from "services/api/openapi"
import SettingsCategory from "./SettingsCategory"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface ResetPasswordForm {
  password: string
  newPassword: string
  confirmPassword: string
  code2fa?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontSize: "1.2em",
  },
}))

const ResetPassword = ({ user }: { user: GetUserDto }): JSX.Element => {
  const classes = useStyles()
  const { control, handleSubmit, reset, formState, watch } = useForm<ResetPasswordForm>({ mode: "onChange" })
  const TypedController = useTypedController<ResetPasswordForm>({ control })
  const toast = useToast()

  const { newPassword: watchNewPassword = "", confirmPassword: watchConfirmPassword = "" } = watch()

  const { mutateAsync: resetPasswordMutation } = api.user.useResetPassword()

  const [ResetPasswordButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = async (form: ResetPasswordForm) => {
    try {
      await resetPasswordMutation({ resetPasswordDto: { id: user.id, ...form } })

      toast.open("Successfully updated password", { variant: "success" })

      reset()
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  return (
    <SettingsCategory title="Reset password" description="Fill the form to reset your password.">
      <Box
        component="form"
        onSubmit={handleButtonCallback(handleSubmit(onSubmit))}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <DrawerFormSection htmlFor="old-password-textfield" name="Old password" mt={0}>
          <TypedController
            name="password"
            defaultValue={""}
            rules={passwordRules}
            render={(props) => (
              <PasswordField
                id="old-password-textfield"
                error={!!formState.errors.password}
                {...props}
                placeholder="Old password"
              />
            )}
          />
        </DrawerFormSection>

        <DrawerFormSection htmlFor="new-password-textfield" name="New password">
          <TypedController
            name="newPassword"
            defaultValue={""}
            rules={passwordRules}
            render={(props) => (
              <PasswordField
                id="new-password-textfield"
                error={!!formState.errors.newPassword}
                {...props}
                placeholder="New password"
              />
            )}
          />
        </DrawerFormSection>

        <DrawerFormSection htmlFor="confirm-password-textfield" name="Confirm password">
          <TypedController
            name="confirmPassword"
            defaultValue={""}
            rules={{
              ...passwordRules,
              validate: (confirmPassword: string) => confirmPassword === watchNewPassword,
            }}
            render={(props) => (
              <PasswordField
                id="confirm-password-textfield"
                {...props}
                error={!!formState.errors.confirmPassword}
                placeholder="Confirm password"
              />
            )}
          />
        </DrawerFormSection>

        <Box maxWidth="40rem" mt={3}>
          <PasswordRequirementSentence password={watchNewPassword} confirmPassword={watchConfirmPassword} />
        </Box>

        {user.is2Fa && (
          <DrawerFormSection htmlFor="code2fa-textfield" name="Code Two Factor Auth">
            {/* TODO: pretty component for 2fa */}
            <TypedController
              name="code2fa"
              defaultValue={""}
              rules={code2faRules}
              render={(props) => (
                <TextField id="code2fa-textfield" {...props} error={!!formState.errors.code2fa} placeholder="code2fa" />
              )}
            />
          </DrawerFormSection>
        )}

        <Box mt={3} display="flex" justifyContent="center">
          <ResetPasswordButton className={classes.submitButton} type="submit" disabled={!formState.isValid}>
            Reset password
          </ResetPasswordButton>
        </Box>
      </Box>
    </SettingsCategory>
  )
}

export default ResetPassword
