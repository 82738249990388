import { WalletImportErrorEnum } from "pure-shared"
import { GetWalletDto, GetWalletDtoStepNameEnum } from "services/api/openapi"

const prettyStatusMap: Record<GetWalletDtoStepNameEnum, { pending: string; active: string }> = {
  delete: {
    pending: "Pending deletion",
    active: "Deleting",
  },
  import: {
    pending: "Pending import",
    active: "Importing",
  },
  create: {
    pending: "Pending creation",
    active: "Creating",
  },
  valuate: {
    pending: "Pending valuation",
    active: "Valuating",
  },
  post_process: {
    pending: "Pending post process",
    active: "Post processing",
  },
}

export const getHumanReadableWalletStatus = (
  wallet: Pick<GetWalletDto, "hasError" | "status" | "stepName" | "lastCsvSource">,
): string => {
  // Step name should always be set for active/pending status
  switch (wallet.status) {
    case "active":
      if (!wallet.stepName) return "Importing"
      return prettyStatusMap[wallet.stepName].active
    case "pending":
      if (!wallet.stepName) return "Queued"
      return prettyStatusMap[wallet.stepName].pending
    case "error":
      return "Error"
    case "inactive":
      if (wallet.hasError) {
        if (wallet.lastCsvSource?.error === WalletImportErrorEnum.notImported) return "Empty"
        else if (
          wallet.lastCsvSource?.error === WalletImportErrorEnum.csvErrorWithReport ||
          wallet.lastCsvSource?.error === WalletImportErrorEnum.csvWrongFormatWithReport
        )
          return "Error - csv"
        else return "Error"
      }
      return "Ready"
  }
}
