import React from "react"
import { GetUserDto } from "../api/openapi"

import ChildrenProps from "../misc/childrenProps"

interface ContextValue {
  user: GetUserDto
}

export const UserContext = React.createContext<ContextValue>(undefined as unknown as ContextValue)

interface AllProps extends ChildrenProps {
  user: GetUserDto
}

export const UserContextProvider = (props: AllProps): JSX.Element => (
  <UserContext.Provider value={{ user: props.user }}>{props.children}</UserContext.Provider>
)
