/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  CreateLabelResponseDto,
  CreateLabelsDto,
  GetLabelDto,
  GetLabelQuery,
  IsLabelNameAvailable,
  PaginatedLabelResultDto,
  UpdateLabelDto,
} from "../models"

export interface CreateLabelRequest {
  workspaceId: string
  createLabelsDto: CreateLabelsDto
}

export interface DeleteLabelRequest {
  labelId: string
  workspaceId: string
}

export interface GetLabelRequest {
  workspaceId: string
  labelId: string
}

export interface GetLabelsRequest {
  workspaceId: string
  getLabelQuery: GetLabelQuery
}

export interface IsLabelNameAvailableRequest {
  workspaceId: string
  name: string
}

export interface UpdateLabelRequest {
  workspaceId: string
  labelId: string
  updateLabelDto: UpdateLabelDto
}

/**
 *
 */
export class LabelsApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static createLabel(params: CreateLabelRequest) {
    return localLabelsApi.createLabel(params)
  }

  /**
   * Create labels
   */
  createLabel({ createLabelsDto, workspaceId }: CreateLabelRequest): Promise<Array<CreateLabelResponseDto>> {
    return this.restInstance.post(`/workspaces/${encodeURIComponent(workspaceId)}/labels`, createLabelsDto)
  }

  static deleteLabel(params: DeleteLabelRequest) {
    return localLabelsApi.deleteLabel(params)
  }

  /**
   * Delete a label
   */
  deleteLabel({ labelId, workspaceId }: DeleteLabelRequest): Promise<void> {
    return this.restInstance.delete(
      `/workspaces/${encodeURIComponent(workspaceId)}/labels/${encodeURIComponent(labelId)}`,
      undefined,
    )
  }

  static getLabel(params: GetLabelRequest) {
    return localLabelsApi.getLabel(params)
  }

  /**
   * Get one label
   */
  getLabel({ workspaceId, labelId }: GetLabelRequest): Promise<GetLabelDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/labels/${encodeURIComponent(labelId)}`)
  }

  static getLabels(params: GetLabelsRequest) {
    return localLabelsApi.getLabels(params)
  }

  /**
   * Get user\'s labels
   */
  getLabels({ getLabelQuery, workspaceId }: GetLabelsRequest): Promise<PaginatedLabelResultDto> {
    return this.restInstance.post(`/workspaces/${encodeURIComponent(workspaceId)}/labels/get`, getLabelQuery)
  }

  static isLabelNameAvailable(params: IsLabelNameAvailableRequest) {
    return localLabelsApi.isLabelNameAvailable(params)
  }

  /**
   */
  isLabelNameAvailable({ workspaceId, name }: IsLabelNameAvailableRequest): Promise<IsLabelNameAvailable> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/labels/is-name-available/${encodeURIComponent(name)}`,
    )
  }

  static updateLabel(params: UpdateLabelRequest) {
    return localLabelsApi.updateLabel(params)
  }

  /**
   * Update a label
   */
  updateLabel({ updateLabelDto, workspaceId, labelId }: UpdateLabelRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/labels/${encodeURIComponent(labelId)}`,
      updateLabelDto,
      true,
    )
  }
}

const localLabelsApi = new LabelsApi()
