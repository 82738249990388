import AccountBalanceIcon from "@material-ui/icons/AccountBalance"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import ListAltIcon from "@material-ui/icons/ListAlt"
import PersonIcon from "@material-ui/icons/Person"
import RepeatIcon from "@material-ui/icons/Repeat"
import React from "react"

import { GetFeatureAddonDtoFeatureEnum, GetWorkspaceUsageLimitDtoLimitTypeEnum } from "services/api/openapi"

export interface LimitType {
  name: string
  type: GetWorkspaceUsageLimitDtoLimitTypeEnum
  softMax: number
  icon: () => JSX.Element
}

interface FeatureType {
  name: string
  type: GetFeatureAddonDtoFeatureEnum
}

export const limitTypes: LimitType[] = [
  {
    name: "User in this workspace",
    type: "user_per_workspace",
    softMax: 50,
    icon: function limitIcon() {
      return <PersonIcon fontSize="large" />
    },
  },
  {
    name: "Chain wallet",
    type: "chain_wallet",
    softMax: 1000,
    icon: function limitIcon() {
      return <AccountBalanceWalletIcon fontSize="large" />
    },
  },
  {
    name: "Exchange source",
    type: "exchange_wallet",
    softMax: 1000,
    icon: function limitIcon() {
      return <RepeatIcon fontSize="large" />
    },
  },
  {
    name: "Custody source",
    type: "custody_wallet",
    softMax: 1000,
    icon: function limitIcon() {
      return <AccountBalanceIcon fontSize="large" />
    },
  },
  {
    name: "CSV source",
    type: "csv_wallet",
    softMax: 1000,
    icon: function limitIcon() {
      return <InsertDriveFileIcon fontSize="large" />
    },
  },
  {
    name: "Transaction count",
    type: "transaction",
    softMax: 100000000,
    icon: function limitIcon() {
      return <ListAltIcon fontSize="large" />
    },
  },
]

export const featureTypes: FeatureType[] = [
  {
    name: "Contacts",
    type: "contacts",
  },
  {
    name: "Labels",
    type: "labels",
  },
  {
    name: "Defi",
    type: "defi",
  },
  {
    name: "FIFO cost basis",
    type: "fifo_cost_basis",
  },
  {
    name: "Invoices",
    type: "invoice",
  },
  {
    name: "Manual COA import",
    type: "manual_coa_import",
  },
  {
    name: "QuickBooks connection",
    type: "quickbooks_connection",
  },
  {
    name: "Xero connection",
    type: "xero_connection",
  },
  {
    name: "Transaction report",
    type: "report_transaction",
  },
  {
    name: "Ledger entries report",
    type: "report_ledger_entries",
  },
  {
    name: "Historical balances report",
    type: "report_historical_balances",
  },
  {
    name: "Cost basis report",
    type: "report_cost_basis",
  },
  {
    name: "Bank statement report",
    type: "report_bank_statement",
  },
]
