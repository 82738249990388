import { UseQueryOptions } from "react-query"
import { queryClient } from "../../.."

import { usePaginatedWorkspaceQuery, useWorkspaceMutation, useWorkspaceQuery } from "../../context/workspaceContext"
import { WithoutWorkspaceId } from "../aliases"
import { FiltersApi, GetFiltersRequest, GetTransactionFilterDto, PaginatedTransactionFilterResultDto } from "../openapi"

export const GET_FILTERS_KEY = "getFilters"
export const GET_FILTER_KEY = "getFilter"

export const filterActions = {
  useFilters: (
    variables: WithoutWorkspaceId<GetFiltersRequest>,
    options?: UseQueryOptions<PaginatedTransactionFilterResultDto>,
  ) => {
    return usePaginatedWorkspaceQuery([GET_FILTERS_KEY, variables], FiltersApi.getFilters, variables, options)
  },
  useFilter: (filterId: string | undefined | null, option?: UseQueryOptions<GetTransactionFilterDto>) => {
    return useWorkspaceQuery(
      [GET_FILTER_KEY, filterId],
      FiltersApi.getOneFilter,
      { filterId: filterId ?? "" },
      {
        enabled: !!filterId,
        ...option,
      },
    )
  },
  useCreateFilters: () => {
    return useWorkspaceMutation(FiltersApi.createFilter, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_FILTERS_KEY)
      },
    })
  },
  useUpdateFilters: () => {
    return useWorkspaceMutation(FiltersApi.updateFilter, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_FILTERS_KEY)
      },
    })
  },
  useDeleteFilter: () => {
    return useWorkspaceMutation(FiltersApi.deleteFilter, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_FILTERS_KEY)
        queryClient.invalidateQueries(GET_FILTER_KEY)
      },
    })
  },
}
