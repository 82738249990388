import React, { useMemo } from "react"
import { GenericSorting, SortDirectionEnum } from "components/Table/interface"
import { SelectTransactionPageContextValue } from "services/context/selectTransactionPageContext"
import DropDownCheckBox from "../../Cells/DropDownCheckBox"
import Header from "../../Cells/Header"
import ContainerColumn, { ColumnsName } from "../ContainerColumn"
import { SelectedRowsType } from "../useBulkSelectorTransactionPage"
import { ConfigTable } from "../useConfigTable"
import ContainerRowHeader from "../ContainerRowHeader"

interface GeneratedRowHeaderProps {
  setSelectedRows: (rows: SelectedRowsType | undefined) => void
  filteredColumnsNameArray: ColumnsName[]
  onPageSelected: () => void
  isPageSelected: boolean
  columnsConfig: ConfigTable[]
  mvtSelected: SelectTransactionPageContextValue
  setSorting: (sorting: GenericSorting<"transaction_date">) => void
  sortDirection: SortDirectionEnum
  selectedRows: SelectedRowsType | undefined
}

const GeneratedRowHeader = (props: GeneratedRowHeaderProps): JSX.Element => {
  const {
    setSelectedRows,
    filteredColumnsNameArray,
    onPageSelected,
    isPageSelected,
    columnsConfig,
    mvtSelected,
    setSorting,
    sortDirection,
    selectedRows,
  } = props

  const rowHeader = useMemo(() => {
    const columnsHeader = filteredColumnsNameArray.map((columnName: string) => (
      <ContainerColumn key={columnName} columnName={columnName} columnsConfig={columnsConfig}>
        {columnName === ColumnsName.CHECKBOX ? (
          <DropDownCheckBox
            setSelectedRows={setSelectedRows}
            isPageSelected={isPageSelected}
            onPageSelected={onPageSelected}
            mvtSelected={mvtSelected}
            selectedRows={selectedRows}
          />
        ) : (
          <Header columnName={columnName} setSorting={setSorting} sortDirection={sortDirection} />
        )}
      </ContainerColumn>
    ))

    return (
      <ContainerRowHeader noBorderTop nonClickable>
        {columnsHeader}
      </ContainerRowHeader>
    )
  }, [
    filteredColumnsNameArray,
    isPageSelected,
    setSelectedRows,
    onPageSelected,
    columnsConfig,
    mvtSelected,
    setSorting,
    sortDirection,
    selectedRows,
  ])

  return <>{rowHeader}</>
}

export default GeneratedRowHeader
