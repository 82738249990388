import React from "react"

import { VariableComponentProps, BalisesType, ElementType } from "../types"

const VariableComponent = (props: VariableComponentProps): JSX.Element => {
  const { componentName, children, className } = props

  const balises: BalisesType = {
    [ElementType.H1]: <h1 className={className}>{children}</h1>,
    [ElementType.H2]: <h2 className={className}>{children}</h2>,
    [ElementType.H3]: <h3 className={className}>{children}</h3>,
    [ElementType.H4]: <h4 className={className}>{children}</h4>,
    [ElementType.P]: <p className={className}>{children}</p>,
    [ElementType.SPAN]: <span className={className}>{children}</span>,
    [ElementType.LABEL]: <label className={className}>{children}</label>,
  }

  return balises[componentName]
}

export default VariableComponent
