import { Backdrop as MUIBackdrop, createStyles, makeStyles, Theme } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import React, { useState } from "react"

import { White } from "../../materialTheme"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      opacity: 0.1,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: White,
    },
  }),
)

interface Props {
  style?: React.CSSProperties
  keepOpen?: boolean
}

export default function Backdrop({ keepOpen = false }: Props): JSX.Element {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(true)

  return (
    <MUIBackdrop className={classes.backdrop} open={isOpen} onClick={keepOpen ? undefined : () => setIsOpen(false)}>
      <CircularProgress color="inherit" />
    </MUIBackdrop>
  )
}
