import { Box, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { URLS } from "../../routes"

interface WorkspaceRouteState {
  openWorkspaceInvitationExplanation: boolean
}

export default function WorkspaceInvitationExplanation() {
  const state = useLocation<WorkspaceRouteState | undefined>().state
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(state?.openWorkspaceInvitationExplanation ? true : false)
  useEffect(() => {
    setIsOpen(state?.openWorkspaceInvitationExplanation ? true : false)
  }, [state])

  const onClose = () => {
    setIsOpen(false)
    if (state) history.replace(URLS.Workspaces)
  }

  return (
    <ModalUI isOpen={isOpen} onClose={onClose} className="w-[600px] h-[335px]" title="How to invite your team?">
      <ul>
        <Typography component="li">Click on the workspace you want to invite your team on.</Typography>
        <Box pt={2}>
          <Typography component="li">
            In the side panel click on the &quot;Invite a user&quot; button (only available if you have the right
            permissions).
          </Typography>
        </Box>
        <Box pt={2}>
          <Typography component="li">Enter your team member email and assign them a role.</Typography>
        </Box>
      </ul>
      <Box mt={4} display="flex" justifyContent="flex-end">
        <ButtonUI onClick={onClose}>
          <Typography>Got it</Typography>
        </ButtonUI>
      </Box>
    </ModalUI>
  )
}
