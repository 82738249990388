import React from "react"
import { Box } from "@material-ui/core"

import { pluralize } from "services/utils/textUtils"
import Spinner from "../Spinner/Spinner"
import numbro from "numbro"
import TooltipUI from "CryptioUI/Tooltip"

interface TotalCountProps {
  totalCount: number
  maxTotalCount: number | null
  item: string
  isRefetching: boolean
}

export default function TotalCount({ totalCount, maxTotalCount, item, isRefetching }: TotalCountProps) {
  const incompleteCount = totalCount === maxTotalCount
  const totalCountText = `${incompleteCount ? "+" : ""} ${pluralize(totalCount > 1, item)}`
  const readableCount = numbro(totalCount.toString()).format({ thousandSeparated: true })

  if (isRefetching) {
    return (
      <Box display="flex">
        Refetching ...
        <Box ml={2}>
          <Spinner />
        </Box>
      </Box>
    )
  }

  if (incompleteCount) {
    return (
      <TooltipUI
        content={`There may be more ${pluralize(
          true,
          item,
        )}. Please apply more filters to select a smaller group of ${pluralize(true, item)}.`}
      >
        <>
          <span data-test-id="totalCountTx">{readableCount}</span>
          <span>{totalCountText}</span>
        </>
      </TooltipUI>
    )
  } else {
    return (
      <>
        <span data-test-id="totalCountTx">{readableCount}</span>
        <span>{totalCountText}</span>
      </>
    )
  }
}
