import { useTypedController } from "@hookform/strictly-typed"
import { Box, InputLabel, makeStyles, MenuItem, Select, TextField, Theme, Typography } from "@material-ui/core"
import { WarningRounded } from "@material-ui/icons"
import { ColorHex } from "pure-shared"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import ContactOptionInAutocomplete from "../../../../components/AutoCompleteOptions/ContactOptionInAutocomplete"
import WalletOptionInAutocomplete from "../../../../components/AutoCompleteOptions/WalletOptionInAutocomplete"
import { DrawerCategory, DrawerFormSection } from "../../../../components/Drawer/DrawerItems"
import LabelColorSelector from "../../../../components/Labels/LabelColorSelector"
import { useLoadingButton } from "../../../../components/LoadingButton"
import useDialog from "../../../../components/misc/useDialog"
import { DrawerProp } from "../../../../components/misc/useDrawer"
import PermissionDisabled from "../../../../components/Permission/PermissionDisabled"
import PermissionText from "../../../../components/Permission/PermissionText"
import CustomSelector from "../../../../components/selector/CustomSelector"
import api from "../../../../services/api"
import { BasicElement, WithoutWorkspaceId } from "../../../../services/api/aliases"
import {
  CreateLabelDto,
  GetLabelDto,
  LabelAssociationDtoTypeEnum,
  GetContactDto,
  GetWalletDto,
  GetFiltersRequest,
  GetTransactionFilterDto,
  GetContactsRequest,
  GetWalletsRequest,
} from "../../../../services/api/openapi"
import { WorkspaceContext } from "../../../../services/context/workspaceContext"
import { Mixpanel } from "../../../../services/mixpanel"
import ConditionalTooltip from "../../../../components/ConditionalTooltip"
import LabelRuleEditor from "components/Labels/LabelRuleEditor"
import ButtonModalImportCsv from "scenes/Labels/ModalImportCsv"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  formNameField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },
  linkIcon: {
    display: "inline-flex",
    verticalAlign: "top",
    marginLeft: theme.spacing(1),
  },
  csvExampleMenuItem: {
    padding: 0,
    marginTop: theme.spacing(3),
    textAlign: "end",
  },
  warningIcon: {
    marginRight: theme.spacing(1),
  },
  warningText: {
    textJustify: "auto",
  },
  associationLabel: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
  },
  associationLabelSelector: {
    marginRight: theme.spacing(1),
  },
}))

interface FormType extends Pick<CreateLabelDto, "name" | "color"> {
  savedFilter?: GetTransactionFilterDto
}

const Creation = ({ isOpen, onClose, setFormDirty }: DrawerProp<GetLabelDto, true>) => {
  const classes = useStyles()
  const { handleSubmit, control, formState, reset, watch, setError } = useForm<FormType>({ mode: "onChange" })
  const toast = useToast()
  const TypedController = useTypedController<FormType>({ control })
  const workspaceCtx = useContext(WorkspaceContext)
  const [isSavedFilter, setIsSavedFilter] = useState(false)

  const [fromType, setFromType] = useState<LabelAssociationDtoTypeEnum>("contact")
  const [toType, setToType] = useState<LabelAssociationDtoTypeEnum>("wallet")

  const [walletFrom, setWalletFrom] = useState<BasicElement[] | null>(null)
  const [walletTo, setWalletTo] = useState<BasicElement[] | null>(null)
  const [contactFrom, setContactFrom] = useState<BasicElement[] | null>(null)
  const [contactTo, setContactTo] = useState<BasicElement[] | null>(null)

  const basicDialog = useDialog()

  const watchAllFields = watch()

  const isFormDirty = formState.isValid && formState.isDirty

  useEffect(() => setFormDirty(isFormDirty), [isFormDirty, setFormDirty])

  const { mutateAsync: createLabelMutation } = api.label.useCreateLabels()

  useEffect(() => {
    if (isOpen) {
      reset()
      setContactFrom(null)
      setContactTo(null)
      setWalletFrom(null)
      setWalletTo(null)
      setFromType("wallet")
      setToType("wallet")
    }
  }, [isOpen, reset])

  if (fromType === "contact" && toType === "contact") throw new Error("from & to cannot be both contact")

  const [SaveLabelButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = useCallback(
    async ({ savedFilter, color, name }: FormType) => {
      try {
        await createLabelMutation({
          createLabelsDto: {
            labels: [
              {
                color: color,
                name: name,
                contactFromIds: contactFrom?.map((item) => item.id) ?? null,
                contactToIds: contactTo?.map((item) => item.id) ?? null,
                walletFromIds: walletFrom?.map((item) => item.id) ?? null,
                walletToIds: walletTo?.map((item) => item.id) ?? null,
                filterId: savedFilter?.id ?? null,
              },
            ],
          },
        })
        toast.open("Label created", { variant: "success" })
        onClose()
      } catch (e) {
        toastCatch(e, toast)
      }
      Mixpanel.track("CreateLabel")
    },
    [onClose, createLabelMutation, toast, contactTo, contactFrom, walletTo, walletFrom],
  )

  return (
    <DrawerCategory title={"New label"} onSubmit={handleButtonCallback(handleSubmit(onSubmit))} component="form">
      {basicDialog.dialog}
      <PermissionText permission={"can_create_label"} action={"create labels"} />
      {workspaceCtx.workspace.lock.isEnabled && (
        <Box mt={2} display="flex" alignItems="center">
          <Box mr={2}>
            <WarningRounded color="primary" />
          </Box>
          <Typography variant="body2">
            Please note that the label rule won't apply label on locked transactions. If you want this rule to be
            applied on all your history, please unlock your period first to execute this action.
          </Typography>
        </Box>
      )}
      <DrawerFormSection display="flex" flexDirection="column" htmlFor="name-textfield" name="Name">
        <Box display="flex" alignItems="flex-start">
          <TypedController
            name="name"
            defaultValue={""}
            rules={{
              required: true,
              validate: (value) => value.trim().length >= 3,
            }}
            render={(props) => (
              <TextField
                id="name-textfield"
                className={classes.formNameField}
                {...props}
                placeholder="Name"
                onBlur={async (x) => {
                  const labelName = x.target.value.trim()

                  if (labelName === "" || formState.errors.name !== undefined) return

                  const res = await api.label
                    .isLabelNameAvailable({ workspaceId: workspaceCtx.workspace.id, name: labelName })
                    .catch(() => undefined)

                  if (res?.isAvailable === false) {
                    setError("name", { message: "This label name already exists" })
                  }
                }}
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message}
              />
            )}
          />
          <TypedController
            name="color"
            defaultValue={ColorHex.blue}
            rules={{ required: true }}
            render={({ value, onChange }) => <LabelColorSelector value={value} onChange={onChange} />}
          />
        </Box>
      </DrawerFormSection>

      <LabelRuleEditor isSavedFilter={isSavedFilter} onChange={setIsSavedFilter} />

      {!isSavedFilter ? (
        <>
          <Box component="section">
            <Box className={classes.associationLabel}>
              <Select
                labelId="label-from-select-label"
                id="label-from-select"
                className={classes.associationLabelSelector}
                value={fromType}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setFromType(event.target.value as LabelAssociationDtoTypeEnum)
                  setContactFrom(null)
                  setWalletFrom(null)
                  // A user cannot set from:contact and to:contact
                  if (event.target.value === "contact" && toType === "contact") {
                    setToType("wallet")
                    setContactTo(null)
                    setWalletTo(null)
                  }
                }}
              >
                <MenuItem value={"contact"}>Contact</MenuItem>
                <MenuItem value={"wallet"}>Wallet</MenuItem>
              </Select>
              <InputLabel id="label-from-select-label">From</InputLabel>
            </Box>
            {fromType === "contact" && (
              <CustomSelector<WithoutWorkspaceId<GetContactsRequest>, BasicElement, true>
                fullWidth
                value={contactFrom ?? []}
                onChange={(_, newValue) => setContactFrom(newValue.length > 0 ? newValue : null)}
                getOptionLabel={(option) => option.name}
                defaultPaginatedQueryProps={{
                  sortBy: "movement_count",
                  sortDirection: "descending",
                  excludedIds: contactFrom?.map((contact) => contact.id),
                }}
                multiple
                getOptionDisabled={contactFrom && contactFrom.length >= 50 ? () => true : () => false}
                disableCloseOnSelect
                filterSelectedOptions
                getOptionSelected={(option, value) => option.id === value.id}
                usePaginatedQuery={api.contact.useContacts}
                size="small"
                placeholder="Type to search..."
                renderOption={(option) => <ContactOptionInAutocomplete contact={option as GetContactDto} />}
              />
            )}
            {fromType === "wallet" && (
              <CustomSelector<WithoutWorkspaceId<GetWalletsRequest>, BasicElement, true>
                fullWidth
                value={walletFrom ?? []}
                onChange={(_, newValue) => setWalletFrom(newValue.length > 0 ? newValue : null)}
                getOptionLabel={(option) => option.name}
                defaultPaginatedQueryProps={{
                  sortBy: "transaction_count",
                  sortDirection: "descending",
                  excludedIds: walletFrom?.map((wallet) => wallet.id),
                }}
                multiple
                getOptionDisabled={walletFrom && walletFrom.length >= 50 ? () => true : () => false}
                disableCloseOnSelect
                filterSelectedOptions
                getOptionSelected={(option, value) => option.id === value.id}
                usePaginatedQuery={api.wallet.useWallets}
                size="small"
                placeholder="Type to search..."
                renderOption={(option) => <WalletOptionInAutocomplete wallet={option as GetWalletDto} />}
              />
            )}
          </Box>

          <Box component="section">
            <Box className={classes.associationLabel}>
              <Select
                labelId="label-to-select-label"
                id="label-to-select"
                className={classes.associationLabelSelector}
                value={toType}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setToType(event.target.value as LabelAssociationDtoTypeEnum)
                  setContactTo(null)
                  setWalletTo(null)

                  // A user cannot set from:contact and to:contact
                  if (event.target.value === "contact" && fromType === "contact") {
                    setFromType("wallet")
                    setContactFrom(null)
                    setWalletFrom(null)
                  }
                }}
              >
                <MenuItem value={"contact"}>Contact</MenuItem>
                <MenuItem value={"wallet"}>Wallet</MenuItem>
              </Select>
              <InputLabel id="label-to-select-label">To</InputLabel>
            </Box>
            {toType === "contact" && (
              <CustomSelector<WithoutWorkspaceId<GetContactsRequest>, BasicElement, true>
                fullWidth
                value={contactTo ?? []}
                onChange={(_, newValue) => setContactTo(newValue.length > 0 ? newValue : null)}
                getOptionLabel={(option) => option.name}
                defaultPaginatedQueryProps={{
                  sortBy: "movement_count",
                  sortDirection: "descending",
                  excludedIds: contactTo?.map((contact) => contact.id),
                }}
                multiple
                getOptionDisabled={contactTo && contactTo.length >= 50 ? () => true : () => false}
                disableCloseOnSelect
                filterSelectedOptions
                getOptionSelected={(option, value) => option.id === value.id}
                usePaginatedQuery={api.contact.useContacts}
                size="small"
                placeholder="Type to search..."
                renderOption={(option) => <ContactOptionInAutocomplete contact={option as GetContactDto} />}
              />
            )}
            {toType === "wallet" && (
              <CustomSelector<WithoutWorkspaceId<GetWalletsRequest>, BasicElement, true>
                fullWidth
                value={walletTo ?? []}
                onChange={(_, newValue) => setWalletTo(newValue.length > 0 ? newValue : null)}
                getOptionLabel={(option) => option.name}
                defaultPaginatedQueryProps={{
                  sortBy: "transaction_count",
                  sortDirection: "descending",
                  excludedIds: walletTo?.map((wallet) => wallet.id),
                }}
                multiple
                getOptionDisabled={walletTo && walletTo.length >= 50 ? () => true : () => false}
                disableCloseOnSelect
                filterSelectedOptions
                getOptionSelected={(option, value) => option.id === value.id}
                usePaginatedQuery={api.wallet.useWallets}
                size="small"
                placeholder="Type to search..."
                renderOption={(option) => <WalletOptionInAutocomplete wallet={option as GetWalletDto} />}
              />
            )}
          </Box>
        </>
      ) : (
        <Box mt={4}>
          <TypedController
            name="savedFilter"
            defaultValue={undefined}
            rules={{ required: false }}
            render={({ value, onChange }) => (
              <CustomSelector<WithoutWorkspaceId<GetFiltersRequest>, GetTransactionFilterDto, false>
                id="savedFilter"
                size="small"
                disableCloseOnSelect
                maxAutocompletionResults={20}
                filterSelectedOptions
                placeholder={value ? "" : "Pick a favorite filter"}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                onChange={(_e, newValue) => {
                  onChange(newValue)
                }}
                value={value ?? null}
                usePaginatedQuery={api.filter.useFilters}
                defaultPaginatedQueryProps={{}}
              />
            )}
          />
        </Box>
      )}
      <Box marginTop={3} display="flex" justifyContent="space-between">
        <PermissionDisabled permission={"can_create_label"} action={"create labels"}>
          <ConditionalTooltip
            tooltipMessage={
              "The save filter contains at least one filter that is not supported to create labeling rule. See the list of not supported filter below: Labels, Labels Application, Synchronization Status, Transactions Mapping, Internal Transfer Status, Valuation Status, Chart Of Accounts, Relative Fiat Value, Cost Basis Error, Synchronization Mode, Note, Has Invoice"
            }
            disabled={watchAllFields.savedFilter?.hasForbiddenLabelRuleFilter ?? false}
          >
            <SaveLabelButton
              disabled={!isFormDirty || watchAllFields.savedFilter?.hasForbiddenLabelRuleFilter}
              type="submit"
            >
              {"Create"}
            </SaveLabelButton>
          </ConditionalTooltip>
        </PermissionDisabled>
      </Box>
      <ButtonModalImportCsv />
    </DrawerCategory>
  )
}

export default Creation
