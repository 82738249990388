import React from "react"
import { CostBasisIds, Trade } from "../../.."
import {
  MovementLabelDto,
  NewInListMovementDto,
  NewInListTransactionDto,
  OtherPartyAliasDto,
} from "services/api/openapi"
import ContainerColumn, { ColumnsName } from "../../ContainerColumn"
import { CustomCellDirection } from "../../useGenerateCell"
import GeneratedCell from "../../GeneratedCell"
import { DeveloppedRowsType } from "../../useDeveloppedTransactionPage"
import { ConfigTable } from "../../useConfigTable"
import { mockInternalTransferLabelMvt } from "../../../TransactionLabelDrawer/NonTaxabelLabelSelector"
import { pluralize } from "services/utils/textUtils"
import { SelectedRowsType } from "../../useBulkSelectorTransactionPage"
import { GetMovementDto } from "../../../../../services/api/routes/transactions"

const getOtherParties = (loneMovements: NewInListMovementDto[], trades: Trade[]) => {
  const otherParties: OtherPartyAliasDto[] = []

  loneMovements.forEach((mvt) => {
    if (
      mvt.otherPartyAlias &&
      (mvt.otherPartyAlias.address || mvt.otherPartyAlias.alias || mvt.otherPartyAlias.blockchain)
    ) {
      otherParties.push(mvt.otherPartyAlias)
    }
  })
  trades.forEach((trade) => {
    if (trade.in.otherPartyAlias) {
      otherParties.push(trade.in.otherPartyAlias)
    }
    if (trade.out.otherPartyAlias) {
      otherParties.push(trade.out.otherPartyAlias)
    }
  })
  return otherParties
}

const getAllLabels = (loneMovements: NewInListMovementDto[], trades: Trade[]) => {
  const labels: Array<MovementLabelDto>[] = []
  loneMovements.forEach((mvt) => {
    labels.push(mvt.labels)
    if (mvt.isInternalTransfer) {
      labels.push([mockInternalTransferLabelMvt])
    }
  })
  trades.forEach((trade) => {
    labels.push(trade.in.labels)
    labels.push(trade.out.labels)
    if (trade.in.isInternalTransfer || trade.out.isInternalTransfer) {
      labels.push([mockInternalTransferLabelMvt])
    }
  })
  return labels.flat(1)
}

const getDirection = (nbTrade: number, nbIn: number, nbOut: number, nbFee: number): CustomCellDirection => {
  if (nbTrade) return CustomCellDirection.TRADE
  if (nbIn) return CustomCellDirection.IN
  if (nbOut) return CustomCellDirection.OUT
  if (nbFee) return CustomCellDirection.FEE
  return CustomCellDirection.UNDEFINED
}

const getMainMovement = (
  loneMovements: NewInListMovementDto[],
  trades: Trade[],
  nbIn: number,
  nbOut: number,
  nbFee: number,
) => {
  if (trades.length) return trades[0].out
  if (nbIn) return loneMovements.filter((mvt) => mvt.direction === "in")[0]
  if (nbOut) return loneMovements.filter((mvt) => mvt.direction === "out")[0]
  if (nbFee) return loneMovements.filter((mvt) => mvt.isFee)[0]
  return undefined
}

const getIncoming = (loneMovements: NewInListMovementDto[], trades: Trade[], nbIn: number, nbOut: number) => {
  if (trades.length === 0 && nbIn === 1) {
    return loneMovements.find((m) => m.direction === "in")
  }
  if (trades.length === 1 && nbIn === 0 && nbOut === 0) {
    return trades[0].in
  }
  return undefined
}

const getOutgoing = (
  loneMovements: NewInListMovementDto[],
  trades: Trade[],
  nbIn: number,
  nbOut: number,
  nbFee: number,
) => {
  if (trades.length === 0 && nbOut === 1 && nbFee === 1) {
    return loneMovements.find((m) => m.direction === "out" && !m.isFee)
  }
  if (trades.length === 0 && nbOut === 1 && nbFee === 0) {
    return loneMovements.find((m) => m.direction === "out")
  }
  if (trades.length === 1 && nbIn === 0 && nbOut === 0) {
    return trades[0].out
  }
  return undefined
}

const getFee = (loneMovements: NewInListMovementDto[], nbFee: number) => {
  if (nbFee === 1) {
    return loneMovements.find((m) => m.isFee)
  }
  return undefined
}

export const generateCollapsedTransaction = (
  transaction: NewInListTransactionDto,
  columnsNameArray: ColumnsName[],
  openWalletMovementDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void,
  openMovementLabelDrawer: <PassedItemId extends GetMovementDto>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void,
  setCostBasisDrawerTransactionId: (ids: CostBasisIds | null) => void,
  loneMovements: NewInListMovementDto[],
  trades: Trade[],
  nbIn: number,
  nbOut: number,
  nbFee: number,
  developpedRows: DeveloppedRowsType | undefined,
  selectedRows: SelectedRowsType | undefined,
  setSelectedRows: (row: SelectedRowsType | undefined) => void,
  setDeveloppedRows: (row: DeveloppedRowsType | undefined) => void,
  columnsConfig: ConfigTable[],
  openTransactionLabelDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void,
) => {
  const otherPartiesAliases = getOtherParties(loneMovements, trades)
  const labels = getAllLabels(loneMovements, trades)
  const direction = getDirection(trades.length, nbIn, nbOut, nbFee)
  const mainMovement = getMainMovement(loneMovements, trades, nbIn, nbOut, nbFee)
  const fee = getFee(loneMovements, nbFee)
  const incoming = getIncoming(loneMovements, trades, nbIn, nbOut)
  const outgoing = getOutgoing(loneMovements, trades, nbIn, nbOut, nbFee)
  const complexData = {
    feeStatus:
      transaction.feeCount > 1
        ? `${transaction.feeCount} ${pluralize(transaction.feeCount > 1, "movement")}`
        : undefined,
    incomingStatus:
      transaction.incomingCount > 1
        ? `${transaction.incomingCount} ${pluralize(transaction.incomingCount > 1, "movement")}`
        : undefined,
    outgoingStatus:
      transaction.outgoingCount > 1
        ? `${transaction.outgoingCount} ${pluralize(transaction.outgoingCount > 1, "movement")}`
        : undefined,
  }

  return columnsNameArray.map((columnName: string) => (
    <ContainerColumn key={columnName} columnName={columnName} columnsConfig={columnsConfig}>
      <GeneratedCell
        columnName={columnName}
        dataToDisplay={{
          columnName,
          transactionDate: transaction.transactionDate,
          incoming,
          outgoing,
          fee,
          wallet: transaction.wallet,
          otherPartiesAliases,
          labels,
          id: transaction.id,
          direction,
          mainMovement,
          isComplex: true,
          complexData,
          selectedRows,
          setSelectedRows,
          usdToFiatRate: transaction.usdToFiatRate,
          developpedRows,
          setDeveloppedRows,
          accountingJournal: mainMovement?.accountingJournal,
        }}
        openWalletDrawer={openWalletMovementDrawer}
        openMovementLabelDrawer={openMovementLabelDrawer}
        openTransactionLabelDrawer={openTransactionLabelDrawer}
        setCostBasisDrawerTransactionId={setCostBasisDrawerTransactionId}
      />
    </ContainerColumn>
  ))
}
