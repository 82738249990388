import { Box, DialogContent, DialogTitle, makeStyles, Typography } from "@material-ui/core"
import React, { useState } from "react"

import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import api from "services/api"
import { GetWalletTypeDto } from "services/api/openapi"
import WalletModalCsv from "../ImportModalCsv"
import FireblocksSource from "./Source/FireblocksSource"
import GenericFieldSource from "./Source/GenericFieldSource"
import SourceSelector from "./SourceSelector"

interface Props {
  onClose?: () => void
}

const useStyles = makeStyles(() => ({
  dialogRoot: {
    height: "500px",
    overflowY: "scroll",
  },
}))

const WalletImporter = (props: Props): JSX.Element => {
  const classes = useStyles()

  const [selectedSource, setSelectedSource] = useState<GetWalletTypeDto | undefined>(undefined)

  const types = api.wallet.useTypes({ onlyInWorkspace: false })
  if (types.isLoading || types.data === undefined) return <LoadingSpinner />
  if (types.isError) return <NetworkErrorMessage additionalData={types} />

  const Header = () => (
    <DialogTitle id="form-dialog-title" disableTypography style={{ display: "flex" }}>
      <Box flex="1"></Box>
      <Typography variant="h1" component="h2" style={{ textAlign: "center" }}>
        Import{selectedSource ? ` ${selectedSource.name}` : ""}
      </Typography>
      <Box flex="1" display="flex" justifyContent="flex-end" alignItems="center">
        {selectedSource && selectedSource.helpLink && (
          <a target="_blank" rel="noopener noreferrer" href={selectedSource.helpLink}>
            Need help
          </a>
        )}
      </Box>
    </DialogTitle>
  )

  if (!selectedSource)
    return (
      <DialogContent>
        <Header />
        <Box className={classes.dialogRoot}>
          <SourceSelector sources={types.data} value={selectedSource} onChange={setSelectedSource} />
        </Box>
        <div className="flex justify-end pt-8 w-full space-4">
          <WalletModalCsv onClose={props.onClose ?? (() => {})} />
        </div>
      </DialogContent>
    )

  const getSourceForm = (): JSX.Element => {
    if (selectedSource.name === "Fireblocks")
      return (
        <FireblocksSource
          onClose={props.onClose}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
        />
      )
    // if (selectedSource.credentialType === "custom_csv")
    //   return (
    //     <CustomSource onClose={props.onClose} selectedSource={selectedSource} setSelectedSource={setSelectedSource} />
    //   )
    return (
      <GenericFieldSource
        onClose={props.onClose}
        selectedSource={selectedSource}
        setSelectedSource={setSelectedSource}
      />
    )
  }

  return (
    <>
      <Header />
      <DialogContent>{getSourceForm()}</DialogContent>
    </>
  )
}

export default WalletImporter
