import React, { useMemo, useState } from "react"
import { validate } from "uuid"

import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import useFilterDrawer from "components/Hooks/useFilterDrawer"
import InputClearable from "components/Input/InputClearable"
import InputNumber from "components/Input/InputNumber"
import DrawerUI from "CryptioUI/Drawer"
import isNumberStringValid from "services/utils/isNumberStringValid"
import { backOfficeBillingHasAnyFilter, BackOfficeBillingParameters, defaultBackOfficeGetPlanParams } from "../index"

interface Props {
  filter: Partial<BackOfficeBillingParameters>
  setFilter: (newValue: Partial<BackOfficeBillingParameters>) => void
}

const BackOfficeBillingFilter = (props: Props): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const { OpenButton, SaveButton, tempFilter, updateFilter } = useFilterDrawer({
    ...props,
    defaultParams: defaultBackOfficeGetPlanParams,
    setIsDrawerOpen,
  })

  const filterCount = useMemo(() => {
    const filter = props.filter
    let res = 0
    if (filter.planId) res++
    if (filter.query) res++
    if (filter.minimumActiveSubscription) res++
    if (filter.minimumAllTimeSubscription) res++
    return res
  }, [props.filter])

  const canClear = filterCount > 0 || backOfficeBillingHasAnyFilter(tempFilter)
  const errorOnId = tempFilter.planId ? !validate(tempFilter.planId) : false

  return (
    <>
      <OpenButton filterCount={filterCount} />

      <DrawerUI title="Billing Filter" onClose={() => setIsDrawerOpen(false)} isOpen={isDrawerOpen}>
        <DrawerCategory title="Filter" mb={3}>
          <DrawerFormSection htmlFor="search-id-textfield" name="Search by ID">
            <InputClearable
              error={errorOnId}
              helperText={errorOnId ? "Invalid ID" : null}
              id="search-id-textfield"
              value={tempFilter.planId || ""}
              onChange={(value) =>
                updateFilter({
                  planId: value,
                })
              }
              placeholder="Plan ID"
              fullWidth
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="search-name-textfield" name="Search by name">
            <InputClearable
              id="search-name-textfield"
              value={tempFilter.query || ""}
              onChange={(value) =>
                updateFilter({
                  query: value,
                })
              }
              placeholder="Name"
              fullWidth
            />
          </DrawerFormSection>
          <DrawerFormSection htmlFor="minimum-user-textfield" name="Minimum active users">
            <InputNumber
              id="minimum-user-textfield"
              value={tempFilter.minimumActiveSubscription ?? ""}
              onChange={(value) => {
                updateFilter({
                  minimumActiveSubscription: value,
                })
              }}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="minimum-total-textfield" name="Minimum total users">
            <InputNumber
              id="minimum-total-textfield"
              value={tempFilter.minimumAllTimeSubscription ?? ""}
              onChange={(value) => {
                updateFilter({
                  minimumAllTimeSubscription: value,
                })
              }}
            />
          </DrawerFormSection>

          <SaveButton
            filterCount={filterCount}
            canClear={canClear}
            canSave={
              (tempFilter.minimumActiveSubscription === undefined ||
                isNumberStringValid(tempFilter.minimumActiveSubscription)) &&
              (tempFilter.minimumAllTimeSubscription === undefined ||
                isNumberStringValid(tempFilter.minimumAllTimeSubscription)) &&
              !errorOnId
            }
          />
        </DrawerCategory>
      </DrawerUI>
    </>
  )
}

export default BackOfficeBillingFilter
