import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useMemo, useState } from "react"

import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import useFilterDrawer from "components/Hooks/useFilterDrawer"
import DrawerUI from "CryptioUI/Drawer"
import { WithoutWorkspaceId } from "services/api/aliases"
import { GetCOAMappingsRequest } from "services/api/openapi"
import { CHART_ACCOUNT_TYPES } from "services/constants"
import { CoaMappingTypes, defaultMappingParams, mappingHasAnyFilter, mappingTypeMapName } from "../index"
import BackOfficeView from "components/misc/BackOfficeView"
import InputClearable from "components/Input/InputClearable"
import { REGEX_UUID_V4 } from "pure-shared"

interface Props {
  filter: WithoutWorkspaceId<GetCOAMappingsRequest>
  setFilter: (newValue: WithoutWorkspaceId<GetCOAMappingsRequest>) => void
}

const MappingFilter = (props: Props): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const { OpenButton, SaveButton, tempFilter, updateFilter, setIsErrorValidation } = useFilterDrawer({
    ...props,
    defaultParams: defaultMappingParams,
    setIsDrawerOpen,
  })

  const filterCount = useMemo(() => {
    const filter = props.filter
    let res = 0
    if (filter.type) res++
    if (filter.accountType) res++
    if (filter.individualId) res++
    return res
  }, [props.filter])

  const canClear = filterCount > 0 || mappingHasAnyFilter(tempFilter)
  const isUuid4 = (uuid: string) => REGEX_UUID_V4.test(uuid)

  useMemo(() => {
    setIsErrorValidation(tempFilter.individualId !== undefined && !isUuid4(tempFilter.individualId))
  }, [setIsErrorValidation, tempFilter])

  return (
    <>
      <OpenButton filterCount={filterCount} />

      <DrawerUI title="Mapping Filter" onClose={() => setIsDrawerOpen(false)} isOpen={isDrawerOpen}>
        <DrawerCategory title="Filter">
          <DrawerFormSection htmlFor="type-select" name="Filter by type">
            <Autocomplete
              id="type-select"
              onChange={(_, newValue) => updateFilter({ type: newValue ?? undefined })}
              options={CoaMappingTypes}
              value={tempFilter.type}
              getOptionLabel={(v) => mappingTypeMapName[v].displayName}
              renderInput={(params) => <TextField {...params} />}
              size="small"
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="account-type-select" name="Filter by account type">
            <Autocomplete
              id="account-type-select"
              onChange={(_, newValue) => updateFilter({ accountType: newValue ?? undefined })}
              value={tempFilter.accountType}
              options={CHART_ACCOUNT_TYPES}
              renderInput={(params) => <TextField {...params} />}
              size="small"
            />
          </DrawerFormSection>

          <BackOfficeView>
            <DrawerFormSection htmlFor="cao-id-textfield" name="Filter by chart of accounts ID">
              <InputClearable
                id="cao-id-textfield"
                onChange={(newValue) => updateFilter({ individualId: newValue ?? undefined })}
                value={tempFilter.individualId}
                fullWidth
                error={tempFilter.individualId !== undefined && !isUuid4(tempFilter.individualId)}
              />
            </DrawerFormSection>
          </BackOfficeView>

          <SaveButton filterCount={filterCount} canClear={canClear} />
        </DrawerCategory>
      </DrawerUI>
    </>
  )
}

export default MappingFilter
