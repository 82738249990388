import { usePaginatedWorkspaceQuery, useWorkspaceQuery } from "../../context/workspaceContext"
import { WithoutWorkspaceId } from "../aliases"
import { GetPublicAssetsRequest } from "../openapi"
import { AssetApi } from "../openapi/apis/AssetApi"

export const GET_PUBLIC_ASSETS_KEY = "getPublicAssets"
export const GET_PUBLIC_ASSET_KEY = "getPublicAsset"

export const assetActions = {
  usePublicAssets: (variables: WithoutWorkspaceId<GetPublicAssetsRequest>) => {
    return usePaginatedWorkspaceQuery([GET_PUBLIC_ASSETS_KEY, variables], AssetApi.getPublicAssets, variables)
  },
  usePublicAsset: (assetId: string | null) => {
    return useWorkspaceQuery(
      [GET_PUBLIC_ASSET_KEY, assetId],
      AssetApi.getOnePublicAsset,
      { assetId: assetId ?? "" },
      { enabled: assetId !== null },
    )
  },
  getAssetCsvSignedUrl: () => {
    return AssetApi.downloadAssetCsv({
      workspaceId: "workspaceId",
    })
  },
}
