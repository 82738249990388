import { defaultTransactionParams, defaultTransactionSorting } from "./components/CoreTransaction"
import { defaultBackOfficeGetUserParams, defaultBackOfficeUserSorting } from "./scenes/BackOffice/Users"
import { defaultBackOfficeGetWorkspaceParams, defaultBackOfficeWorkspaceSorting } from "./scenes/BackOffice/Workspaces"
import { BillingCheckoutParameters } from "./scenes/Billing/Checkout"
import { defaultContactParams, defaultContactSorting } from "./scenes/Contacts"
import { defaultImportParams, defaultImportSorting } from "./scenes/Imports"
import { defaultLabelParams, defaultLabelSorting } from "./scenes/Labels"
import { defaultReportSorting } from "./scenes/Reports"
import { parametrizableRoute, parametrizableTableRoute } from "./services/urlParse"

export const URLS = {
  Redirect: {
    basePath: "/redirect/workspace/:workspaceId",
    getUrl: (workspaceId: string, redirectUrl: string) =>
      `/redirect/workspace/${workspaceId}?workspaceRedirect=${encodeURI(redirectUrl)}`,
  },
  Portfolio: "/dashboard",
  Transactions: parametrizableTableRoute("/transactions", defaultTransactionParams, defaultTransactionSorting),
  DeFi: parametrizableTableRoute("/defi", defaultTransactionParams, defaultTransactionSorting),
  TransactionsReviewMissingLabel: parametrizableTableRoute(
    "/transactions/needReview/missingLabel",
    defaultTransactionParams,
    defaultTransactionSorting,
  ),
  TransactionsReviewInternalTransfer: parametrizableTableRoute(
    "/transactions/needReview/internalTransfer",
    defaultTransactionParams,
    defaultTransactionSorting,
  ),
  TransactionsReviewIncompleteCOA: parametrizableTableRoute(
    "/transactions/needReview/incompleteChartOfAccounts",
    defaultTransactionParams,
    defaultTransactionSorting,
  ),
  TransactionsReviewReadyToBeSynced: parametrizableTableRoute(
    "/transactions/needReview/readyToBeSynchronized",
    defaultTransactionParams,
    defaultTransactionSorting,
  ),
  Business: {
    Integrations: "/business/integrations",
    UnidentifiedAddresses: "/unidentified_addresses",
    Contacts: parametrizableTableRoute("/contacts", defaultContactParams, defaultContactSorting),
    Labels: parametrizableTableRoute("/labels", defaultLabelParams, defaultLabelSorting),
  },
  Accounting: {
    OAuth: {
      Login: "/accounting/oauth/login/Xero", // :integration
      Register: "/accounting/oauth/register/Xero", // :integration
      Connect: {
        Xero: "/accounting/oauth/connect/Xero",
        QuickBooks: "/accounting/oauth/connect/QuickBooks",
      },
    },
    Integrations: "/chart_of_accounts/integrations",
    AutomatedMapping: "/chart_of_accounts/automated_mapping",
    Impairment: "/accounting/impairment",
    AutomatedMappingReview: "/chart_of_accounts/automated_mapping/needReview",
  },
  Reports: parametrizableTableRoute("/reports", {}, defaultReportSorting),
  Import: parametrizableTableRoute("/imports", defaultImportParams, defaultImportSorting),
  Asset: "/assets",
  Workspaces: "/workspaces",
  WorkspaceInvitation: "/workspaces/invitation",
  Settings: "/settings",
  Account: {
    Login: "/login",
    Register: "/register",
    SingleSignUp: "/register/ssu/xero", // :service
    ForgottenPassword: "/forgotten_password",
    ResetForgottenPassword: "/forgotten_password/reset/:email/:token",
    ConfirmEmail: "/confirm_email/:email/:token",
  },
  BackOffice: {
    Home: "/back_office",
    Asset: "/back_office/asset",
    User: parametrizableTableRoute("/back_office/user", defaultBackOfficeGetUserParams, defaultBackOfficeUserSorting),
    Workspace: parametrizableTableRoute(
      "/back_office/workspace",
      defaultBackOfficeGetWorkspaceParams,
      defaultBackOfficeWorkspaceSorting,
    ),
    LimitationAlmostReached: "/back_office/limitation",
    Accounting: "/back_office/accounting",
    Billing: "/back_office/billing",
    Jobs: "/back_office/jobs",
  },
  Billing: {
    Simulator: "/billing/simulator",
    Status: "/billing",
    Checkout: {
      Review: parametrizableRoute<BillingCheckoutParameters>("/billing/checkout"),
      Canceled: "/billing/checkout/canceled",
    },
  },
  UrlShortener: {
    Redirect: "/short/:urlId",
  },
}
