import React, { useRef, useState, useEffect } from "react"
import { DayPickerRangeController, isInclusivelyBeforeDay, FocusedInputShape } from "react-dates"
import moment from "moment"

import { Dates, IconProps, PeriodLabels, Periods, RenderMonthElement } from "../../types"
import { ReactComponent as ArrowLeft } from "../../assets/icons/chevron-left.svg"
import { ReactComponent as ArrowRight } from "../../assets/icons/chevron-right.svg"

export interface CalendarProps {
  dates: Dates
  handleDateChange: (data: Dates) => void
  disabled: boolean
  activePeriod: PeriodLabels | null
  periods: Periods
}

const Calendar = (props: CalendarProps): JSX.Element => {
  const { dates, handleDateChange, disabled, activePeriod, periods } = props
  const [needToChangeYear, setNeedToChangeYear] = useState(false)
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>("startDate")
  const monthController = useRef<RenderMonthElement>()

  const ArrowIcon = ({ type }: IconProps): JSX.Element => {
    const iconStyle = "w-4 h-4"
    if (type === "prev") return <ArrowLeft className={iconStyle} />
    else return <ArrowRight className={iconStyle} />
  }

  useEffect(() => {
    if (activePeriod && needToChangeYear) {
      let finalYear = moment(periods[activePeriod].endDate)
      if (periods[activePeriod].endDate.month() === 11) finalYear.add(1, "months")
      monthController.current?.onYearSelect(monthController.current.month, finalYear.format("YYYY"))
      setNeedToChangeYear(false)
    }
  }, [needToChangeYear, activePeriod, periods])

  useEffect(() => {
    const handleFocus = () => {
      if (!activePeriod) return
      monthController.current?.onMonthSelect(monthController.current.month, periods[activePeriod].endDate.format("M"))
      if (monthController.current?.month.format("YYYY") !== periods[activePeriod].endDate.format("YYYY"))
        setNeedToChangeYear(true)
    }
    handleFocus()
  }, [activePeriod, periods])

  return (
    <DayPickerRangeController
      startDate={dates.startDate}
      endDate={dates.endDate}
      onDatesChange={handleDateChange}
      focusedInput={focusedInput || "startDate"}
      onFocusChange={setFocusedInput}
      initialVisibleMonth={() => moment().subtract(1, "months")}
      numberOfMonths={2}
      hideKeyboardShortcutsPanel
      verticalBorderSpacing={4}
      minimumNights={0}
      enableOutsideDays
      firstDayOfWeek={1}
      navPrev={<ArrowIcon type="prev" />}
      navNext={<ArrowIcon type="next" />}
      maxDate={moment().add(1, "months")}
      isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
      disabled={disabled}
      renderMonthElement={(...args) => {
        if (monthController) {
          monthController.current = {
            month: args[0].month,
            onYearSelect: args[0].onYearSelect,
            onMonthSelect: args[0].onMonthSelect,
          }
          return args[0].month.format("MMMM YYYY")
        }
      }}
    />
  )
}

export default Calendar
