import { Box, Checkbox, FormLabel, makeStyles, TextField, Theme, Typography } from "@material-ui/core"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { prettyEmptyOrNull } from "pure-shared"
import { COAAccountElement, GetInvoiceDto, FullMovementDto } from "services/api/openapi/models"
import { WorkspaceContext } from "services/context/workspaceContext"
import { useForm } from "react-hook-form"
import { useTypedController } from "@hookform/strictly-typed"
import BigNumber from "bignumber.js"
import { Mixpanel } from "services/mixpanel"
import api from "services/api"
import { GetChartAccountsTypesEnum } from "services/api/openapi"
import { DrawerProp } from "../../misc/useDrawer"
import { useLoadingButton } from "../../LoadingButton"
import PermissionDisabled from "../../Permission/PermissionDisabled"
import CustomSelector from "../../selector/CustomSelector"
import { DrawerCategory, DrawerFormSection } from "../../Drawer/DrawerItems"
import ChartAccountOptionInAutocomplete from "../../AutoCompleteOptions/ChartAccountOptionInAutocomplete"
import { GetTradeDto } from "services/api/routes/transactions"
import TradeRateField, { TradeRateValue } from "../TransactionDrawer/TradeRateField"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface MovementUpdateFormAccounts {
  debitedAccount: COAAccountElement | null
  creditedAccount: COAAccountElement | null
}
interface MovementUpdateForm extends MovementUpdateFormAccounts {
  notes: string
  rates: TradeRateValue
  isManualMapping: boolean
}

interface COAAutocompleteData {
  displayName: string
  coaKey: keyof MovementUpdateFormAccounts
  // undefined means all
  acceptedType?: GetChartAccountsTypesEnum[]
}
const chartAccountAutocompletes: (mv: FullMovementDto) => COAAutocompleteData[] = (mv) => {
  // TODO: if trade: take other party symbol
  if (mv.accounting?.tradeTransactionId && mv.accounting.tradeAssetSymbol) {
    const incomingDebitedName = `${prettyEmptyOrNull(
      mv.direction === "in" ? mv.assetSymbol : mv.accounting.tradeAssetSymbol,
    )} debited account`
    const outgoingCreditedName = `${prettyEmptyOrNull(
      mv.direction === "out" ? mv.assetSymbol : mv.accounting.tradeAssetSymbol,
    )} credited account`
    return [
      {
        displayName: incomingDebitedName,
        coaKey: "debitedAccount",
        acceptedType: ["asset", "liability"],
      },
      {
        displayName: outgoingCreditedName,
        coaKey: "creditedAccount",
        acceptedType: ["asset", "liability"],
      },
    ]
  }

  if (mv.isFee) {
    return [
      {
        displayName: "Fee credited account",
        coaKey: "creditedAccount",
        acceptedType: ["asset"],
      },
      {
        displayName: "Fee debited account",
        coaKey: "debitedAccount",
        acceptedType: ["expense"],
      },
    ]
  }

  if (mv.direction === "in") {
    return [
      {
        displayName: "Debited account",
        coaKey: "debitedAccount",
        acceptedType: ["asset"],
      },
      {
        displayName: "Credited account",
        coaKey: "creditedAccount",
        acceptedType: mv.isInternalTransfer ? undefined : ["income", "liability", "equity"],
      },
    ]
  }
  return [
    {
      displayName: "Credited account",
      coaKey: "creditedAccount",
      acceptedType: ["asset"],
    },
    {
      displayName: "Debited account",
      coaKey: "debitedAccount",
      acceptedType: mv.isInternalTransfer ? undefined : ["expense", "liability", "equity"],
    },
  ]
}

const useStyles = makeStyles((theme: Theme) => ({
  validateButton: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  invoiceTypography: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  invoiceBox: {
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  invoiceBoxDownload: {
    "&:hover > .MuiTypography-root": {
      textDecoration: "underline",
    },
  },
  checkboxManualMapping: {
    paddingLeft: 0,
  },
}))

const MovementForm = ({ item: trade, isOpen }: DrawerProp<GetTradeDto, false>) => {
  const classes = useStyles()

  const defaultFormValues: MovementUpdateForm = useMemo(
    () => ({
      notes: trade.movementIn.note ?? "",
      rates: {
        inRate: trade.movementIn.assetToFiatRate ?? undefined,
        outRate: trade.movementOut.assetToFiatRate ?? undefined,
      },
      isManualMapping: trade.movementIn.accounting?.isManualMapping ?? false,
      debitedAccount: trade.movementIn.accounting?.debitedAccount ?? null,
      creditedAccount: trade.movementIn.accounting?.creditedAccount ?? null,
    }),
    [trade.movementIn, trade.movementOut],
  )

  const { control, reset, handleSubmit, watch, formState } = useForm<MovementUpdateForm>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  })
  const watchAllFields = watch()
  const isFormDirty = useMemo(
    () =>
      formState.isValid &&
      formState.isDirty &&
      (watchAllFields.notes !== trade.movementIn.note ||
        watchAllFields.rates !== (trade.movementIn.assetToUsdRate ?? "") ||
        (watchAllFields.isManualMapping !== undefined &&
          trade.movementIn.accounting &&
          trade.movementIn.accounting.isManualMapping !== watchAllFields.isManualMapping)),
    [
      formState.isValid,
      formState.isDirty,
      watchAllFields.notes,
      watchAllFields.rates,
      watchAllFields.isManualMapping,
      trade.movementIn.note,
      trade.movementIn.assetToUsdRate,
      trade.movementIn.accounting,
    ],
  )

  const TypedController = useTypedController<MovementUpdateForm>({ control })
  const [setInvoiceTransaction] = useState<GetInvoiceDto | null>(null)
  const toast = useToast()

  const { mutateAsync: updateMovementsMutation } = api.transaction.useBulkUpdateMovements()

  const workspaceCtx = useContext(WorkspaceContext)

  useEffect(() => {
    if (isOpen) {
      reset(defaultFormValues)
    }
  }, [isOpen, defaultFormValues, reset, setInvoiceTransaction])

  const [SaveTransactionButton, handleButtonCallback] = useLoadingButton()

  const onSubmit = useCallback(
    async (form: MovementUpdateForm) => {
      try {
        if (
          (form.rates.inRate && new BigNumber(form.rates.inRate).isNegative()) ||
          (form.rates.outRate && new BigNumber(form.rates.outRate).isNegative())
        )
          throw new Error("Rate cannot be a negative value")

        const isManualMapping = form.isManualMapping ?? trade.movementIn.accounting?.isManualMapping ?? false

        if (form.rates.inRate && form.rates.inRate != "" && form.rates.inRate !== trade.movementIn.assetToFiatRate) {
          await updateMovementsMutation({
            movementBulkUpdateDto: {
              individualMovements: {
                movementIds: [trade.movementIn.id],
              },
              fiatRate: form.rates.inRate !== trade.movementIn.originalAssetToFiatRate ? form.rates.inRate : undefined,
              resetRates: form.rates.inRate === trade.movementIn.originalAssetToFiatRate ? true : undefined,
              note: form.notes,
              manualMapping: isManualMapping
                ? {
                    debitedAccountId: form.debitedAccount?.id ?? null,
                    creditedAccountId: form.creditedAccount?.id ?? null,
                  }
                : undefined,
              resetManualMapping: !isManualMapping ? true : undefined,
            },
          })
        }

        if (
          form.rates.outRate &&
          form.rates.outRate != "" &&
          form.rates.outRate !== trade.movementOut.assetToFiatRate
        ) {
          await updateMovementsMutation({
            movementBulkUpdateDto: {
              individualMovements: {
                movementIds: [trade.movementOut.id],
              },
              fiatRate:
                form.rates.outRate !== trade.movementOut.originalAssetToFiatRate ? form.rates.outRate : undefined,
              resetRates: form.rates.outRate === trade.movementOut.originalAssetToFiatRate ? true : undefined,
              note: form.notes,
              manualMapping: isManualMapping
                ? {
                    debitedAccountId: form.debitedAccount?.id ?? null,
                    creditedAccountId: form.creditedAccount?.id ?? null,
                  }
                : undefined,
              resetManualMapping: !isManualMapping ? true : undefined,
            },
          })
        }

        toast.open("Trade updated", { variant: "success" })
      } catch (e) {
        toastCatch(e, toast)
      }
      if (form.notes !== trade.movementIn.note) Mixpanel.track("TransactionNoteEdited")
    },
    [trade.movementIn, trade.movementOut, toast, updateMovementsMutation],
  )

  return (
    <>
      <DrawerCategory component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))} title="Trade settings">
        <DrawerFormSection
          htmlFor="volume-textfield"
          name={`Volume ${workspaceCtx.workspace.defaultCurrencyName} rate`}
          mt={2}
        >
          <TypedController
            name="rates"
            defaultValue={{
              inRate: defaultFormValues.rates.inRate,
              outRate: defaultFormValues.rates.outRate,
            }}
            render={({ value, onChange }) => (
              <TradeRateField
                incoming={trade.movementIn}
                outgoing={trade.movementOut}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </DrawerFormSection>

        {workspaceCtx.workspace.accountingIntegration !== null && (
          <Box mt={3}>
            <Box display="flex" alignItems="center">
              <TypedController
                name="isManualMapping"
                rules={{ required: false }}
                render={({ onChange, value, ...rest }) => (
                  <PermissionDisabled permission="can_modify_transaction" action="modify transaction">
                    <Checkbox
                      color="primary"
                      id="manual-mapping-checkbox"
                      className={classes.checkboxManualMapping}
                      onChange={() => onChange(!value)}
                      checked={value}
                      {...rest}
                    />
                  </PermissionDisabled>
                )}
              />
              <FormLabel htmlFor="manual-mapping-checkbox">Manual chart of accounts mapping</FormLabel>
            </Box>

            {chartAccountAutocompletes(trade.movementIn).map((coaAuto) => (
              <Box mt={2} key={coaAuto.coaKey}>
                <TypedController
                  name={coaAuto.coaKey}
                  rules={{ required: false }}
                  defaultValue={trade.movementIn.accounting?.[coaAuto.coaKey] ?? null}
                  render={({ ref: _ref, onChange, ...rest }) => (
                    <PermissionDisabled
                      placement="right"
                      permission="can_modify_transaction"
                      action="modify transaction"
                    >
                      <CustomSelector
                        {...rest}
                        onChange={(_, newValue) => onChange(newValue)}
                        getOptionLabel={(option) => option.name}
                        defaultPaginatedQueryProps={{
                          sortBy: "name",
                          sortDirection: "ascending",
                          types: workspaceCtx.workspace.areChartAccountMappingsRestricted
                            ? coaAuto.acceptedType
                            : undefined,
                        }}
                        label={coaAuto.displayName}
                        disabled={!watchAllFields.isManualMapping || !trade.movementIn.accounting}
                        getOptionSelected={(option, value) => option.id === value.id}
                        usePaginatedQuery={api.chartAccount.useChartAccounts}
                        size="small"
                        filterSelectedOptions
                        renderOption={(option) => <ChartAccountOptionInAutocomplete account={option} />}
                      />
                    </PermissionDisabled>
                  )}
                />
              </Box>
            ))}
          </Box>
        )}

        <DrawerFormSection htmlFor="notes-textfield" name="Notes">
          <TypedController
            name="notes"
            defaultValue={trade.movementIn.note ?? ""}
            render={(props) => (
              <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
                <TextField id="notes-textfield" {...props} multiline placeholder="Write anything..." fullWidth />
              </PermissionDisabled>
            )}
          />
        </DrawerFormSection>

        <Box marginTop={3}>
          <SaveTransactionButton disabled={!isFormDirty} className={classes.validateButton} type="submit">
            <Typography variant="body1">Save</Typography>
          </SaveTransactionButton>
        </Box>
      </DrawerCategory>
    </>
  )
}

export default MovementForm
