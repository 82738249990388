import React, { useState } from "react"

import { FullMovementDtoDirectionEnum, OtherPartyAliasDto } from "services/api/openapi"
import DialogCreateContact from "components/Dialog/DialogCreateContact"
import useDrawer from "components/misc/useDrawer"

import { CustomCellDirection } from "../../GeneratedTable/useGenerateCell"
import WalletOtherParty from "./WalletOtherParty"
import NoOtherParty from "./NoOtherParty"
import WithOtherParties from "./WithOtherParties"
interface OtherPartyProps {
  otherPartiesAliases: OtherPartyAliasDto[]
  onClickWallet: () => void
  wallet: string
  direction: CustomCellDirection | FullMovementDtoDirectionEnum
}

const OtherParty = (props: OtherPartyProps) => {
  const { otherPartiesAliases, onClickWallet, wallet, direction } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [contactDrawer, openContactDrawer] = useDrawer("contact")
  const [walletDrawer, openWalletDrawer] = useDrawer("wallet")
  const uniqueOtherPartyAliases = otherPartiesAliases.filter(
    (value, index, self) => index === self.findIndex((otherParty) => otherParty.address === value.address),
  )
  const otherParty = uniqueOtherPartyAliases[0]

  const isFrom =
    direction === CustomCellDirection.OUT ||
    direction === CustomCellDirection.TRADE ||
    direction === CustomCellDirection.FEE

  if (!uniqueOtherPartyAliases.length) {
    return (
      <NoOtherParty
        walletDrawer={walletDrawer}
        contactDrawer={contactDrawer}
        isFrom={isFrom}
        onClickWallet={onClickWallet}
        wallet={wallet}
      />
    )
  }

  return (
    <div className="flex justify-start overflow-hidden w-full">
      {walletDrawer}
      {contactDrawer}
      <DialogCreateContact
        address={otherParty.address}
        isOpen={uniqueOtherPartyAliases.length > 1 ? false : isOpen}
        onClose={() => setIsOpen(false)}
      />
      <div>
        {isFrom ? (
          <div className="flex flex-col gap-1">
            <WalletOtherParty subtitle="from:" name={wallet} onClick={onClickWallet} type={"wallet"} />
            <WithOtherParties
              subtitle="to:"
              otherParty={otherParty}
              uniqueOtherPartyAliases={uniqueOtherPartyAliases}
              openWalletDrawer={openWalletDrawer}
              openContactDrawer={openContactDrawer}
              setIsOpen={setIsOpen}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-1">
            <WithOtherParties
              subtitle="from:"
              otherParty={otherParty}
              uniqueOtherPartyAliases={uniqueOtherPartyAliases}
              openWalletDrawer={openWalletDrawer}
              openContactDrawer={openContactDrawer}
              setIsOpen={setIsOpen}
            />
            <WalletOtherParty subtitle="to:" name={wallet} onClick={onClickWallet} type={"wallet"} />
          </div>
        )}
      </div>
    </div>
  )
}

export default OtherParty
