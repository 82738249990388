import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import WarningIcon from "@material-ui/icons/Warning"
import React, { useContext, useMemo, useState } from "react"

import { GetLabelDto, FullMovementDto, MovementCounterPartyDto } from "services/api/openapi"
import AddLabelButton from "./AddLabelButton"
import CountrolCounterParty from "./ControlCounterParty"
import RegularLabelSelector from "./RegularLabelSelector"
import ShowLabelAlreadyActivated from "./ShowLabelAlreadyActivated"
import MovementLabelDrawerModal from "./MovementLabelDrawerModal"
import NonTaxabelLabelSelector from "./NonTaxabelLabelSelector"
import api from "services/api"
import TitleWithDivider from "components/TitleWithDivider"
import { DrawerProp } from "components/misc/useDrawer"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import { mockInternalTransferLabel } from "../TransactionLabelDrawer/NonTaxabelLabelSelector"
import { WorkspaceContext } from "../../../services/context/workspaceContext"
import { isLockedTransaction } from "../../../services/utils/isLocked"
import ConditionalTooltip from "../../ConditionalTooltip"

export type NonTaxableLabels = "Internal Transfer" | "Non taxable event"

const useStyles = makeStyles((theme: Theme) => ({
  containerSection: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  typographyWarning: {
    fontWeight: 800,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  iconWarning: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.4),
  },
}))

const MovementLabelDrawer = (props: DrawerProp<FullMovementDto, false>) => {
  const { item: movement, onClose } = props
  const classes = useStyles()
  const [labelsToAdd, setLabelsToAdd] = useState<GetLabelDto[]>([])
  const [newLabelName, setNewLabelName] = useState<string | null>(null)
  const [nonTaxablelabelsToAdd, setNonTaxableLabelsToAdd] = useState<GetLabelDto[]>([])
  const [confirmedInternalTransfer, setConfirmedInternalTransfer] = useState<boolean>(false)
  const { workspace } = useContext(WorkspaceContext)

  const [nonTaxableLabels] = useState<GetLabelDto[]>([])
  const [selectedGuessedOtherParty, setSelectedGuessedOtherParty] = useState<MovementCounterPartyDto | null>(null)

  const isInternalTransfer = useMemo(() => {
    const isFound = nonTaxablelabelsToAdd.find((label) => label.name === mockInternalTransferLabel.name)

    return isFound !== undefined ? true : false
  }, [nonTaxablelabelsToAdd])

  const isNonTaxableEvent = useMemo(() => {
    const isFound = nonTaxablelabelsToAdd.find((label) => label.name === "Non taxable event")

    return isFound !== undefined ? true : false
  }, [nonTaxablelabelsToAdd])

  const showAddLabelButton = (): boolean =>
    labelsToAdd.length > 0 || nonTaxablelabelsToAdd.length > 0 || isInternalTransfer

  const counterParties = api.transaction.useGuessCounterParties(movement.id, {
    enabled: isInternalTransfer,
  })

  const closeDrawer = () => {
    setNewLabelName(null)
    onClose()
  }

  const isLocked = isLockedTransaction(
    workspace.lock.isEnabled,
    movement.transactionDate,
    workspace.lock.exclusiveEndDate,
  )

  return (
    <>
      <Box mt={3} />
      <TitleWithDivider title="Label list" variant="h4" />
      <ShowLabelAlreadyActivated movement={movement} />
      <Box mt={4} mb={2}>
        <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
          <>
            <ConditionalTooltip tooltipMessage={"Labels can't be applied on locked transaction"} disabled={isLocked}>
              <Box>
                <Typography variant="h5">Taxable event</Typography>
                <RegularLabelSelector
                  movement={movement}
                  setNewLabelName={setNewLabelName}
                  labelsToAdd={labelsToAdd}
                  setLabelsToAdd={setLabelsToAdd}
                  isLockedTransaction={isLocked}
                />
              </Box>
            </ConditionalTooltip>
            <ConditionalTooltip tooltipMessage={"Labels can't be applied on locked transaction"} disabled={isLocked}>
              <Box mt={2}>
                <Typography variant="h5">Non taxable event</Typography>
                <NonTaxabelLabelSelector
                  isInternalTransfer={isInternalTransfer}
                  isNonTaxableEvent={isNonTaxableEvent}
                  transaction={movement}
                  isLockedTransaction={isLocked}
                  setNewLabelName={setNewLabelName}
                  nonTaxablelabelsToAdd={nonTaxablelabelsToAdd}
                  setNonTaxableLabelsToAdd={setNonTaxableLabelsToAdd}
                />
              </Box>
            </ConditionalTooltip>
            <Box mt={2} />
            {isInternalTransfer && !isLocked && (
              <CountrolCounterParty
                counterParties={counterParties}
                labelsToAdd={labelsToAdd}
                setConfirmedInternalTransfer={setConfirmedInternalTransfer}
                isInternalTransfer={isInternalTransfer}
                confirmedInternalTransfer={confirmedInternalTransfer}
                setSelectedGuessedOtherParty={setSelectedGuessedOtherParty}
                selectedGuessedOtherParty={selectedGuessedOtherParty}
              />
            )}
            {isNonTaxableEvent && !isLocked && (
              <div className={classes.containerSection}>
                <Typography className={classes.typographyWarning}>
                  <WarningIcon className={classes.iconWarning} /> {"No cost basis is computed on non taxable events"}
                </Typography>
              </div>
            )}
          </>
        </PermissionDisabled>
        {showAddLabelButton() && (
          <AddLabelButton
            isInternalTransfer={isInternalTransfer}
            nonTaxablelabelsToAdd={nonTaxablelabelsToAdd}
            nonTaxableLabels={nonTaxableLabels}
            selectedGuessedOtherParty={selectedGuessedOtherParty}
            confirmedInternalTransfer={confirmedInternalTransfer}
            movement={movement}
            onClose={closeDrawer}
            counterParties={counterParties}
            labelsToAdd={labelsToAdd}
            setLabelsToAdd={setLabelsToAdd}
          />
        )}
      </Box>
      <MovementLabelDrawerModal labelName={newLabelName} onClose={() => setNewLabelName(null)} movement={movement} />
    </>
  )
}

export default MovementLabelDrawer
