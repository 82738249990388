import { PaletteType, ThemeProvider } from "@material-ui/core"
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react"
import React from "react"

import { createDataTableTheme } from "components/Table/theme"
import getTheme from "../../materialTheme"
import ChildrenProps from "../misc/childrenProps"
import { useLocalStorage } from "../misc/useLocalStorage"
import emotionTheme from "../../emotionTheme"

interface ContextValue {
  type: PaletteType
  changeType: (newType: PaletteType) => void
}

export const ThemeContext = React.createContext<ContextValue>(undefined as unknown as ContextValue)

type AllProps = ChildrenProps

export const ThemeContextProvider = (props: AllProps): JSX.Element => {
  const [type, setType] = useLocalStorage<PaletteType>("theme", "light")

  createDataTableTheme(type)

  return (
    <ThemeContext.Provider
      value={{
        type,
        changeType: setType,
      }}
    >
      <ThemeProvider theme={getTheme(type)}>
        <EmotionThemeProvider theme={emotionTheme}>{props.children}</EmotionThemeProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}
