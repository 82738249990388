import { AccountingIntegrationCredentialEnum } from "pure-shared"
import { WorkspaceAccountingIntegrationDto } from "services/api/aliases"
import { GetAccountingIntegrationsDto } from "services/api/openapi"
import CustomIntegrationBlock from "./CustomIntegrationBlock"
import OAuth2IntegrationBlock from "./OAuth2IntegrationBlock"
import TbaIntegrationBlock from "./TbaIntegrationBlock"

export interface IntegrationBlockProps {
  disabled: boolean

  integration: GetAccountingIntegrationsDto
  workspaceIntegration: WorkspaceAccountingIntegrationDto | null

  askConnect: (onConnect: () => void) => void
  onDisconnect: () => void
  onUpdate: () => void
}

const credentialBlockMap: Record<AccountingIntegrationCredentialEnum, (props: IntegrationBlockProps) => JSX.Element> = {
  oauth2: OAuth2IntegrationBlock,
  tba: TbaIntegrationBlock,
  custom: CustomIntegrationBlock,
}

const IntegrationBlock = (props: IntegrationBlockProps) => {
  const Block = credentialBlockMap[props.integration.credentialType]
  return <Block {...props} />
}

export default IntegrationBlock
