import { Box, Typography } from "@material-ui/core"
import React, { useContext, useEffect } from "react"
import { useForm } from "react-hook-form"

import api from "services/api"
import { GetLabelDto } from "services/api/openapi"
import BulkLabel from "./BulkLabel"
import BulkNotes from "./BulkNotes"
import BulkUSD from "./BulkUSDRates"
import { pluralize } from "services/utils/textUtils"
import { TransactionParams } from ".."
import { DrawerCategory } from "../../Drawer/DrawerItems"
import DialogDataConfirmQuit from "../../misc/BasicDialogContext/ReusableContextData/ConfirmQuit"
import { useLoadingButton } from "../../LoadingButton"
import useDialog from "../../misc/useDialog"
import { SelectedRowsType } from "../GeneratedTable/useBulkSelectorTransactionPage"
import { SelectTransactionPageContextValue } from "services/context/selectTransactionPageContext"
import BigNumber from "bignumber.js"
import BulkManuallySync from "./BulkManuallySync"
import DrawerUI from "CryptioUI/Drawer"
import BulkInternalTransfer from "./BulkInternalTransfer"
import { FilterContext } from "../../../services/context/filterContext"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface Props {
  isOpen: boolean
  onClose: () => void
  params: TransactionParams
  selectedRows?: SelectedRowsType
  selectedCount: number
  mvtSelected: SelectTransactionPageContextValue
}

export interface SynchronizationBulkForm {
  syncToAccountingSystem: boolean
  markAsUnsynchronized: boolean
  markAsManuallySynchronized: boolean
}

export type TransactionBulkForm = {
  note: string
  emptyNotes: boolean
  fiatRate: string
  resetRates: boolean
  labelsToAdd: GetLabelDto[]
  labelsToRemove: GetLabelDto[]
  removeInternalTransfer: boolean
  synchronization: SynchronizationBulkForm
  autoInternalTransferReview: boolean
}

const BulkMovementDrawer = (props: Props) => {
  const { selectedRows, selectedCount, params, isOpen, onClose, mvtSelected } = props
  const { isTab } = useContext(FilterContext)

  const toast = useToast()

  const { control, formState, handleSubmit, reset, watch } = useForm<TransactionBulkForm>({ mode: "onChange" })

  const { mutateAsync: bulkUpdateMovementMutation } = api.transaction.useBulkUpdateMovements()

  const basicDialog = useDialog()

  const isValidStringNumber = (str: string) => !isNaN(Number(str)) && !isNaN(parseFloat(str))
  const watchAllFields = watch()
  const isFormDirty = formState.isValid && formState.isDirty

  const excludedLabelIds = watchAllFields.labelsToAdd?.concat(watchAllFields.labelsToRemove ?? []).map((l) => l.id)

  const closeDrawer = () => {
    if (isFormDirty) {
      basicDialog.showDialog(DialogDataConfirmQuit(onClose))
    } else onClose()
  }

  const getSynchronizationAction = (form: TransactionBulkForm) => {
    if (form.synchronization.syncToAccountingSystem) return "syncToAccountingSystem"
    if (form.synchronization.markAsManuallySynchronized) return "manuallyMarkAsSynchronized"
    if (form.synchronization.markAsUnsynchronized) return "markAsUnsynchronized"
    return undefined
  }

  const [UpdateBulkTransactionButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = async (form: TransactionBulkForm) => {
    try {
      if (form.fiatRate && new BigNumber(form.fiatRate).isNegative()) throw new Error("Rate cannot be a negative value")
      await bulkUpdateMovementMutation({
        movementBulkUpdateDto: {
          emptyNotes: form.emptyNotes,
          note: !form.emptyNotes && form.note.length > 0 ? form.note : undefined,
          resetRates: form.resetRates,
          fiatRate: !form.resetRates && isValidStringNumber(form.fiatRate) ? form.fiatRate : undefined,
          labelIdsToRemove: form.labelsToRemove.map((l) => l.id),
          labelIdsToAdd: form.labelsToAdd.map((l) => l.id),
          autoInternalTransferReview: form.autoInternalTransferReview,
          synchronizationAction: getSynchronizationAction(form),
          // removeInternalTransfer: form.removeInternalTransfer,
          filteredTransactions:
            selectedRows?.type === "negativeAdd"
              ? {
                  excludedIds: selectedRows.excludedIds,
                  ...params,
                }
              : undefined,
          individualTransactions:
            selectedRows?.type === "positiveAdd"
              ? {
                  transactionIds: selectedRows.itemIds,
                  ...params,
                }
              : undefined,
          individualMovements:
            mvtSelected.selectedRows?.type === "positiveAdd"
              ? {
                  movementIds: mvtSelected.selectedRows.itemIds,
                }
              : undefined,
        },
      })
      toast.open("Updating transactions...", { variant: "success" })
      onClose()
    } catch (e) {
      toastCatch(e, toast)
    }
    /* if (form.labelsToAdd.length > 0 || form.labelsToRemove.length > 0) Mixpanel.track("BatchTransactionEditLabel")
     * if (form.note.length > 0 || form.emptyNotes) Mixpanel.track("TransactionBulkNoteEdited") */
  }

  const rowCount = selectedCount + mvtSelected.selectedRowCount
  const confirmUpdate = (form: TransactionBulkForm) => {
    if (isFormDirty) {
      basicDialog.showDialog({
        title: "Are you sure?",
        content: (
          <Typography variant="h5">
            You are about to update {rowCount} selected {pluralize(rowCount > 1, "row")}. This action is irreversible.
            Do you want to continue?
          </Typography>
        ),
        yesText: "Yes",
        noText: "Cancel",
        onAccept: () => {
          onSubmit(form)
          basicDialog.closeDialog()
        },
      })
    }
  }

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen, selectedRows, reset])

  return (
    <DrawerUI onClose={closeDrawer} isOpen={isOpen} title="Batch edition">
      {basicDialog.dialog}
      <DrawerCategory component="form" onSubmit={handleButtonCallback(handleSubmit(confirmUpdate))}>
        <BulkNotes watchAllFields={watchAllFields} control={control} />
        <BulkUSD
          formState={formState}
          watchAllFields={watchAllFields}
          control={control}
          isValidStringNumber={isValidStringNumber}
        />
        <BulkLabel
          params={params}
          selectedRows={selectedRows}
          excludedLabelIds={excludedLabelIds}
          control={control}
          watchAllFields={watchAllFields}
        />
        {isTab("internalTransfer") && <BulkInternalTransfer control={control} />}
        <BulkManuallySync control={control} />
        <Box mt={3}>
          <UpdateBulkTransactionButton type="submit" disabled={!isFormDirty}>
            Save
          </UpdateBulkTransactionButton>
        </Box>
      </DrawerCategory>
    </DrawerUI>
  )
}

export default BulkMovementDrawer
