import CircularProgress from "@material-ui/core/CircularProgress"
import * as React from "react"
import ButtonUI from "CryptioUI/Button"
import { ButtonUIProps, Mode } from "CryptioUI/types"

interface Props {
  pending: boolean
}

// CircularProgress size property comes from
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Button/Button.js#L251

// The controlled button is useful when you want to manage its state yourself
// or when you don't have an onClick function (submit button in forms)
export const LoadingButton = (props: Props & ButtonUIProps) => {
  const { pending = false, children, Icon, disabled, ...rest } = props

  return (
    <ButtonUI mode={Mode.DEFAULT} Icon={pending ? undefined : Icon} {...rest} disabled={pending || disabled}>
      <div className="flex flex-row">
        {pending && (
          <div className="mr-2 flex justify-center items-center">
            <CircularProgress size={14} className="p-0 m-0" />
          </div>
        )}
        {children}
      </div>
    </ButtonUI>
  )
}

// The uncontrolled loading button manages its state internally
// On click, the button turns to pending mode (loading state),
//     await the promise passed (onClick property) and turns back to înitial state
// To be used when we have an onClick promise function
export const UncontrolledLoadingButton = (
  props: {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<unknown> | unknown
    delayMs?: number
  } & Omit<ButtonUIProps, "onClick">,
) => {
  const [pending, setPending] = React.useState<boolean>(false)
  const { children, onClick, delayMs, ...rest } = props

  return (
    <LoadingButton
      {...rest}
      pending={pending}
      onClick={async (e) => {
        setPending(true)
        await onClick(e)
        if (delayMs) {
          setTimeout(() => setPending(false), delayMs)
        } else {
          setPending(false)
        }
      }}
    >
      {children}
    </LoadingButton>
  )
}

// The hook allows a nicer syntax when dealing with forms with promised onSubmit callback
// All you have to do is replacing the MUI <ButtonUI> by the returned button (first element in array)
// And wrapping the onSubmit callback in the handler

// with react hook form it should be something like:
// <form onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
// - handleButtonCallback is returned by this hook
// - handleSubmit is returned by the useForm of RHF
// - onSubmit is your callback that handles the form submit
export const useLoadingButton = (defaultPending = false) => {
  const [pending, setPending] = React.useState<boolean>(defaultPending)

  const Button = (props: ButtonUIProps) => {
    return <LoadingButton {...props} pending={pending} />
  }

  const handleButtonCallback =
    <T extends (...args: any[]) => Promise<void>>( // eslint-disable-line @typescript-eslint/no-explicit-any
      callback: T,
    ) =>
    async (...args: Parameters<T>) => {
      setPending(true)
      try {
        await callback(...args)
      } finally {
        setPending(false)
      }
    }

  return [Button, handleButtonCallback] as [typeof Button, typeof handleButtonCallback]
}
