/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  BackOfficeCreateAssetExchangeLinkQuery,
  BackOfficeCreateAssetProviderQuery,
  BackOfficeCreateBlockchainLinkQuery,
  BackOfficeDeleteAssetExchangeLinkQuery,
  BackOfficeFromUnknownAssetToKnownDto,
  BackOfficeGetAssetDto,
  BackOfficeGetAssetExchangeLinksDto,
  BackOfficeGetAssetProvidersDto,
  BackOfficePaginatedAssetResultDto,
} from "../models"

export interface BackOfficeCreateAssetLinkRequest {
  assetId: string
  backOfficeCreateAssetExchangeLinkQuery: BackOfficeCreateAssetExchangeLinkQuery
}

export interface BackOfficeCreateProviderRequest {
  assetId: string
  backOfficeCreateAssetProviderQuery: BackOfficeCreateAssetProviderQuery
}

export interface BackOfficeDeleteAssetLinksRequest {
  assetId: string
  backOfficeDeleteAssetExchangeLinkQuery: BackOfficeDeleteAssetExchangeLinkQuery
}

export interface BackOfficeDeleteBlockchainLinkRequest {
  assetId: string
  blockchain: BackOfficeDeleteBlockchainLinkBlockchainEnum
}

export interface BackOfficeDeleteProviderRequest {
  assetId: string
  provider: BackOfficeDeleteProviderProviderEnum
}

export interface BackOfficeFindAssetLinksRequest {
  assetId: string
}

export interface BackOfficeFromUnknownAssetToKnownRequest {
  unknownAssetId: string
  backOfficeFromUnknownAssetToKnownDto: BackOfficeFromUnknownAssetToKnownDto
}

export interface BackOfficeGetAssetsRequest {
  page: number
  limit: number
  query?: string
  sortBy?: BackOfficeGetAssetsSortByEnum
  sortDirection?: BackOfficeGetAssetsSortDirectionEnum
  type?: BackOfficeGetAssetsTypeEnum
  address?: string
  includeEmptyAssets?: boolean
  queryWorkspaceId?: string
  assets?: Array<string>
  excludedIds?: Array<string>
}

export interface BackOfficeGetOneAssetRequest {
  assetId: string
}

export interface BackOfficeLinkAssetToBlockchainRequest {
  assetId: string
  backOfficeCreateBlockchainLinkQuery: BackOfficeCreateBlockchainLinkQuery
}

export interface BackOfficeListProvidersRequest {
  assetId: string
}

export interface BackOfficeRemapUnknownAssetRequest {
  unknownAssetId: string
  toAssetId: string
}

/**
 *
 */
export class BackOfficeAssetsApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static backOfficeCreateAssetLink(params: BackOfficeCreateAssetLinkRequest) {
    return localBackOfficeAssetsApi.backOfficeCreateAssetLink(params)
  }

  /**
   * Link an asset to an exchange
   */
  backOfficeCreateAssetLink({
    backOfficeCreateAssetExchangeLinkQuery,
    assetId,
  }: BackOfficeCreateAssetLinkRequest): Promise<void> {
    return this.restInstance.post(
      `/back-office/assets/${encodeURIComponent(assetId)}/links`,
      backOfficeCreateAssetExchangeLinkQuery,
      true,
    )
  }

  static backOfficeCreateProvider(params: BackOfficeCreateProviderRequest) {
    return localBackOfficeAssetsApi.backOfficeCreateProvider(params)
  }

  /**
   * Add or update a provider to an asset
   */
  backOfficeCreateProvider({
    backOfficeCreateAssetProviderQuery,
    assetId,
  }: BackOfficeCreateProviderRequest): Promise<void> {
    return this.restInstance.put(
      `/back-office/assets/provider/${encodeURIComponent(assetId)}`,
      backOfficeCreateAssetProviderQuery,
      true,
    )
  }

  static backOfficeDeleteAssetLinks(params: BackOfficeDeleteAssetLinksRequest) {
    return localBackOfficeAssetsApi.backOfficeDeleteAssetLinks(params)
  }

  /**
   * Delete an asset\'s link to an exchange
   */
  backOfficeDeleteAssetLinks({
    backOfficeDeleteAssetExchangeLinkQuery,
    assetId,
  }: BackOfficeDeleteAssetLinksRequest): Promise<void> {
    return this.restInstance.delete(
      `/back-office/assets/${encodeURIComponent(assetId)}/links`,
      backOfficeDeleteAssetExchangeLinkQuery,
    )
  }

  static backOfficeDeleteBlockchainLink(params: BackOfficeDeleteBlockchainLinkRequest) {
    return localBackOfficeAssetsApi.backOfficeDeleteBlockchainLink(params)
  }

  /**
   * Remove a link between an asset and a blockchain
   */
  backOfficeDeleteBlockchainLink({ assetId, blockchain }: BackOfficeDeleteBlockchainLinkRequest): Promise<void> {
    return this.restInstance.delete(
      `/back-office/assets/blockchain-link/${encodeURIComponent(assetId)}/${encodeURIComponent(blockchain)}`,
      undefined,
    )
  }

  static backOfficeDeleteProvider(params: BackOfficeDeleteProviderRequest) {
    return localBackOfficeAssetsApi.backOfficeDeleteProvider(params)
  }

  /**
   * Remove a provider from an asset
   */
  backOfficeDeleteProvider({ assetId, provider }: BackOfficeDeleteProviderRequest): Promise<void> {
    return this.restInstance.delete(
      `/back-office/assets/provider/${encodeURIComponent(assetId)}/${encodeURIComponent(provider)}`,
      undefined,
    )
  }

  static backOfficeFindAssetLinks(params: BackOfficeFindAssetLinksRequest) {
    return localBackOfficeAssetsApi.backOfficeFindAssetLinks(params)
  }

  /**
   * Get all asset links
   */
  backOfficeFindAssetLinks({
    assetId,
  }: BackOfficeFindAssetLinksRequest): Promise<Array<BackOfficeGetAssetExchangeLinksDto>> {
    return this.restInstance.get(`/back-office/assets/${encodeURIComponent(assetId)}/links`)
  }

  static backOfficeFromUnknownAssetToKnown(params: BackOfficeFromUnknownAssetToKnownRequest) {
    return localBackOfficeAssetsApi.backOfficeFromUnknownAssetToKnown(params)
  }

  /**
   * If we don\'t have the asset in the db, we update the name, add a blockchain address and change its type
   */
  backOfficeFromUnknownAssetToKnown({
    backOfficeFromUnknownAssetToKnownDto,
    unknownAssetId,
  }: BackOfficeFromUnknownAssetToKnownRequest): Promise<void> {
    return this.restInstance.put(
      `/back-office/assets/exchange-unknown/${encodeURIComponent(unknownAssetId)}`,
      backOfficeFromUnknownAssetToKnownDto,
      true,
    )
  }

  static backOfficeGetAssets(params: BackOfficeGetAssetsRequest) {
    return localBackOfficeAssetsApi.backOfficeGetAssets(params)
  }

  /**
   * Get all assets
   */
  backOfficeGetAssets({ ...queryParameters }: BackOfficeGetAssetsRequest): Promise<BackOfficePaginatedAssetResultDto> {
    return this.restInstance.get(`/back-office/assets?${this.restInstance.encodeObjectToUrl(queryParameters)}`)
  }

  static backOfficeGetOneAsset(params: BackOfficeGetOneAssetRequest) {
    return localBackOfficeAssetsApi.backOfficeGetOneAsset(params)
  }

  /**
   * Get one asset
   */
  backOfficeGetOneAsset({ assetId }: BackOfficeGetOneAssetRequest): Promise<BackOfficeGetAssetDto> {
    return this.restInstance.get(`/back-office/assets/${encodeURIComponent(assetId)}`)
  }

  static backOfficeLinkAssetToBlockchain(params: BackOfficeLinkAssetToBlockchainRequest) {
    return localBackOfficeAssetsApi.backOfficeLinkAssetToBlockchain(params)
  }

  /**
   * Link an asset to a blockchain
   */
  backOfficeLinkAssetToBlockchain({
    backOfficeCreateBlockchainLinkQuery,
    assetId,
  }: BackOfficeLinkAssetToBlockchainRequest): Promise<void> {
    return this.restInstance.put(
      `/back-office/assets/blockchain-link/${encodeURIComponent(assetId)}`,
      backOfficeCreateBlockchainLinkQuery,
      true,
    )
  }

  static backOfficeListProviders(params: BackOfficeListProvidersRequest) {
    return localBackOfficeAssetsApi.backOfficeListProviders(params)
  }

  /**
   * List the providers of an asset
   */
  backOfficeListProviders({ assetId }: BackOfficeListProvidersRequest): Promise<BackOfficeGetAssetProvidersDto> {
    return this.restInstance.get(`/back-office/assets/provider/${encodeURIComponent(assetId)}`)
  }

  static backOfficeRemapUnknownAsset(params: BackOfficeRemapUnknownAssetRequest) {
    return localBackOfficeAssetsApi.backOfficeRemapUnknownAsset(params)
  }

  /**
   * If we already have the asset in the db, we remap every occurrence of the unknown and delete it
   */
  backOfficeRemapUnknownAsset({ unknownAssetId, toAssetId }: BackOfficeRemapUnknownAssetRequest): Promise<void> {
    return this.restInstance.put(
      `/back-office/assets/exchange-unknown/${encodeURIComponent(unknownAssetId)}/remap-to/${encodeURIComponent(
        toAssetId,
      )}`,
      undefined,
      true,
    )
  }
}

const localBackOfficeAssetsApi = new BackOfficeAssetsApi()

/**
 * @export
 * @enum {string}
 */
export type BackOfficeDeleteBlockchainLinkBlockchainEnum =
  | "Arbitrum"
  | "Avalanche"
  | "Bitcoin"
  | "Binance Smart Chain"
  | "Cardano"
  | "Ethereum"
  | "Fantom"
  | "Filecoin"
  | "Litecoin"
  | "Near"
  | "Optimism"
  | "Polkadot"
  | "Polygon"
  | "Solana"
  | "Tezos"
  | "Zilliqa"
  | "Linea"
  | "zkSync"
  | "Base"
/**
 * @export
 * @enum {string}
 */
export type BackOfficeDeleteProviderProviderEnum = "kaiko" | "coin_gecko" | "coin_market_cap"
/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetAssetsSortByEnum = "name" | "symbol" | "created_at" | "market_cap_rank"
/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetAssetsSortDirectionEnum = "ascending" | "descending"
/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetAssetsTypeEnum = "fiat" | "exchange_unknown" | "token" | "main_chain"
