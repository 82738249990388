import { Box, createStyles, IconButton, Input, makeStyles, Typography } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import ReplayIcon from "@material-ui/icons/Replay"
import TooltipUI from "CryptioUI/Tooltip"
import React from "react"

import { LimitType } from "./constants"

interface Props {
  value: number
  current: number
  limitType: LimitType
  onChange: (value: number) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    inputDiv: {
      "&>input": {
        textAlign: "center",
        width: "90px",
      },
    },
  }),
)

const LimitEditor = (props: Props): JSX.Element => {
  const classes = useStyles()
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.target.value)))
      props.onChange(event.target.value === "" ? 0 : Number(event.target.value) - props.current)
  }

  const increment = props.limitType.softMax > 100000 ? 1000 : 1

  return (
    <Box display="flex" flexDirection="row" p={1} alignItems="center" width="500px">
      <Box mr={1}>{props.limitType.icon()}</Box>
      <Typography style={{ minWidth: "180px" }}>{props.limitType.name}</Typography>
      <TooltipUI content="Decrease amount">
        <IconButton
          aria-label="decrease"
          disabled={props.value <= 0}
          onClick={() => props.onChange(props.value - increment)}
        >
          <RemoveIcon fontSize="large" />
        </IconButton>
      </TooltipUI>
      <TooltipUI
        content={
          props.value < 0
            ? `Value must be higher or equal to ${props.current}`
            : `Current usage: ${props.current} | Additionals: ${props.value}`
        }
      >
        <Input
          className={classes.inputDiv}
          value={props.value + props.current}
          margin="dense"
          onChange={handleInputChange}
          error={props.value < 0}
        />
      </TooltipUI>
      <TooltipUI content="Increase amount">
        <IconButton aria-label="increase" onClick={() => props.onChange(props.value + increment)}>
          <AddIcon fontSize="large" />
        </IconButton>
      </TooltipUI>
      {props.value > 0 && (
        <TooltipUI content="Reset amount">
          <IconButton aria-label="reset" onClick={() => props.onChange(0)}>
            <ReplayIcon fontSize="medium" />
          </IconButton>
        </TooltipUI>
      )}
    </Box>
  )
}

export default LimitEditor
