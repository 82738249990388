import { useTypedController } from "@hookform/strictly-typed"
import React from "react"
import { ReportFormComponentProps } from "."
import { DrawerReportForm } from "../ReportForm"
import ReportHideColumnForm from "../ReportHideColumnForm"

const ReportHideColumns = ({ control, reportFormat, reportType, allColumnsHidden }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })

  return (
    <TypedController
      name="hiddenColumns"
      rules={{ required: false }}
      defaultValue={{
        allColumns: true,
        columns: reportFormat.columns.filter((c) => !c.isVisible),
      }}
      render={({ onChange, value }) => (
        <ReportHideColumnForm
          onChange={onChange}
          value={value}
          reportType={reportType}
          reportFormat={reportFormat}
          allColumnsHidden={allColumnsHidden}
        />
      )}
    />
  )
}

export default ReportHideColumns
