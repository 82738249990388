import { Link, Typography } from "@material-ui/core"
import { ProviderContext as ToastProviderContext } from "CryptioUI/Toaster/Context"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

import { URLS } from "../../routes"
import {
  CryptioBillingLimitError,
  CryptioDuplicationError,
  CryptioDuplicationErrorTypeEnum,
  CryptioGenericError,
  CryptioTrialExpiredError,
  CryptioValidationError,
} from "services/api/openapi"

type CryptioError =
  | CryptioBillingLimitError
  | CryptioDuplicationError
  | CryptioGenericError
  | CryptioTrialExpiredError
  | CryptioValidationError

const cryptioErrorTypes: CryptioDuplicationErrorTypeEnum[] = [
  "duplication",
  "validation",
  "billing-limit",
  "trial-expired",
  "generic",
]

function isCryptioError(err: unknown): err is CryptioError {
  return (
    typeof err === "object" &&
    "type" in (err as Record<string, unknown>) &&
    cryptioErrorTypes.includes((err as { type: unknown }).type as CryptioDuplicationErrorTypeEnum)
  )
}

export const toastCatch = (err: unknown, toast: ToastProviderContext) => {
  if (!isCryptioError(err)) {
    toast.open((typeof err === "object" && (err as { message?: string }).message) || "Internal error", {
      variant: "danger",
    })
    return
  }

  const errorType = err["type"]
  switch (errorType) {
    case "billing-limit": {
      const billingLimitError = err as CryptioBillingLimitError
      if (billingLimitError.limit === "transaction") {
        toast.open(
          <>
            <Typography align="center">
              You have reached the maximum number of transactions allowed
              <br />
              <Link color="inherit" component={RouterLink} to={URLS.Billing.Status} underline="always">
                Upgrade your subscription
              </Link>
            </Typography>
          </>,
          { variant: "danger", duration: 10000 },
        )
      } else {
        toast.open(
          <>
            <Typography align="center">
              You have reached the maximum allowed, the limit is <b>{billingLimitError.hardLimit}</b>&nbsp;
              {billingLimitError.current && (
                <span>
                  and you would have <b>{billingLimitError.current}</b>
                </span>
              )}
              <br />
              <Link color="inherit" component={RouterLink} to={URLS.Billing.Status} underline="always">
                Upgrade your subscription
              </Link>
            </Typography>
          </>,
          { variant: "danger", duration: 10000 },
        )
      }
      return
    }
    case "trial-expired":
      toast.open(
        <Typography align="center">
          Your trial has expired
          <br />
          <Link color="inherit" component={RouterLink} to={URLS.Billing.Status} underline="always">
            Upgrade your subscription
          </Link>
        </Typography>,
        { variant: "danger", duration: 10000 },
      )
      return
    case "validation":
    case "duplication":
    case "generic":
    default:
      toast.open(err.message || "Internal error", { variant: "danger" })
      return
  }
}
