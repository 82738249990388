import React from "react"
import { twMerge } from "tailwind-merge"

import IconPositionWrapper from "CryptioUI/Utilities/IconPositionWrapper"
import { TagProps, TagSize } from "CryptioUI/types/tag"
import { Mode, TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import ButtonUI from "CryptioUI/Button"

const TagUI = ({
  onClick,
  label,
  iconPosition = "left",
  Icon,
  disabled = false,
  className = "",
  color = "default",
  size = TagSize.LG,
  ellipsisPosition,
  width = "max-w-[135px]",
}: TagProps) => {
  const customStyles = {
    indigo: "text-white bg-label-indigo-200 hover:bg-label-indigo-300 striped-indigo",
    red: "text-white bg-label-red-200 hover:bg-label-red-300 striped-red",
    turquoise: "text-white bg-label-turquoise-200 hover:bg-label-turquoise-300 striped-turquoise",
    orange: "text-white bg-label-orange-200 hover:bg-label-orange-300 striped-orange",
    brown: "text-white bg-label-brown-200 hover:bg-label-brown-300 striped-brown",
    green: "text-white bg-label-green-200 hover:bg-label-green-300 striped-green",
    blue: "text-white bg-label-blue-200 hover:bg-label-blue-300 striped-blue",
    grey: "text-white bg-label-grey-200 hover:bg-label-grey-300 striped-grey",
    pink: "text-white bg-label-pink-200 hover:bg-label-pink-300 striped-pink",
    cream: "bg-label-cream-200 text-grey-400 hover:bg-label-cream-300 striped-cream",
    default: "text-grey-400 bg-grey-100 border border-grey-200 hover:bg-grey-300 striped !active:bg-primary-500",
  }
  const tagStyle = "flex justify-center align-center wrap text-xs font-medium rounded-full disabled:text-grey-400 h-fit"
  const sizeStyle = size === TagSize.LG ? "py-2 px-3" : "py-1.5 px-3"
  const finalStyle = twMerge([tagStyle, customStyles[color], sizeStyle, className].join(" "))

  return (
    <ButtonUI
      className={finalStyle}
      mode={Mode.CONTAINED}
      onClick={onClick}
      disabled={disabled}
      data-test-id="cryptio-ui-tag"
    >
      <IconPositionWrapper
        className={width}
        iconPosition={iconPosition}
        iconSpacing={size === TagSize.SM ? 4 : 8}
        Icon={Icon}
      >
        <TypographyUI
          ellipsisPosition={ellipsisPosition}
          variant={size === TagSize.SM ? TypographyVariant.CAPTION : TypographyVariant.BODY}
        >
          {label}
        </TypographyUI>
      </IconPositionWrapper>
    </ButtonUI>
  )
}

export default TagUI
