import SimpleAutocompleteFactory from "components/Autocomplete/SimpleAutocompleteFactory"

import { TransactionFilterSynchronizationStatusValueEnum } from "services/api/openapi/models"

export const TransactionSynchronizationFilter =
  SimpleAutocompleteFactory<TransactionFilterSynchronizationStatusValueEnum>({
    synced: "Synchronization successful",
    unsynced: "Not synchronized",
    error: "Synchronization failed",
  })

export default TransactionSynchronizationFilter
