import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import React, { useMemo } from "react"

import LabelOptionInAutocomplete from "components/AutoCompleteOptions/LabelOptionInAutocomplete"
import CustomSelector from "components/selector/CustomSelector"
import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import { FullMovementDto, GetLabelDto, GetLabelsRequest } from "services/api/openapi"

interface RegularLabelSelectorProps {
  movement: FullMovementDto
  setNewLabelName: (newLabelName: string | null) => void
  labelsToAdd: GetLabelDto[]
  setLabelsToAdd: (newValue: GetLabelDto[]) => void
  isLockedTransaction: boolean
}

const RegularLabelSelector = (props: RegularLabelSelectorProps) => {
  const { movement, setNewLabelName, labelsToAdd, setLabelsToAdd, isLockedTransaction } = props

  const uniqueLabelIds = useMemo<string[]>(
    () => Array.from(new Set(labelsToAdd.map((x) => x.id).concat(movement.labels.map((x) => x.labelId) ?? []))),
    [movement, labelsToAdd],
  )

  return (
    <CustomSelector<WithoutWorkspaceId<GetLabelsRequest>["getLabelQuery"], GetLabelDto | string, true, undefined, true>
      getOptionLabel={(option) => {
        if (typeof option === "string") return option
        option.isNonTaxable = false
        return (option as GetLabelDto).name
      }}
      defaultPaginatedQueryProps={{
        // sortBy: "transaction_count",
        excludedIds: uniqueLabelIds,
        nonTaxableType: "exclude_non_taxable",
      }}
      usePaginatedQuery={api.label.useLabels}
      size="small"
      label="Select standard labels"
      autoComplete
      disabled={isLockedTransaction}
      freeSolo
      multiple
      includeInputInList
      filterSelectedOptions
      disableCloseOnSelect
      getOptionSelected={() => false}
      // TODO: check if string => false // or id === id
      // getOptionSelected={(option, value) => option.id === value.id}
      // Limit ?
      // getOptionDisabled={labelsToAdd.length >= 10 ? () => true : () => false}
      value={labelsToAdd}
      onChange={(_event, newValue) => {
        if (newValue.length > 0 && typeof newValue[newValue.length - 1] === "string") {
          // timeout to avoid instant validation of the dialog's form.
          setTimeout(() => {
            setNewLabelName(newValue[newValue.length - 1] as string)
          })
        } else {
          setLabelsToAdd(newValue as GetLabelDto[])
        }
      }}
      renderOption={(option) => {
        if (typeof option === "string") return `Create "${option}"`
        else return <LabelOptionInAutocomplete label={option} />
      }}
      filterOptions={(options, params) => {
        const filter = createFilterOptions<GetLabelDto>()
        const filtered = filter(options as GetLabelDto[], params) as (GetLabelDto | string)[]

        if (params.inputValue !== "") filtered.push(params.inputValue)

        return filtered
      }}
    />
  )
}

export default RegularLabelSelector
