/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  BillingCheckoutSession,
  BillingCheckoutUpcomingInvoiceData,
  BillingStandardUpcomingInvoiceData,
  GetFullSubscriptionDto,
  GetPackagesDto,
  GetPlanDto,
  GetShortSubscriptionDto,
  PaginatedSubscriptionInvoiceResultDto,
  StartSubscriptionDto,
  UpdateSubscriptionDto,
} from "../models"

export interface CancelSubscriptionRequest {
  workspaceId: string
}

export interface ChangeSubscriptionRequest {
  workspaceId: string
  updateSubscriptionDto: UpdateSubscriptionDto
}

export interface CreateStripeCheckoutSessionRequest {
  workspaceId: string
  startSubscriptionDto: StartSubscriptionDto
}

export interface GetChangeUpcomingInvoiceRequest {
  workspaceId: string
  updateSubscriptionDto: UpdateSubscriptionDto
}

export interface GetFullSubscriptionRequest {
  workspaceId: string
}

export interface GetInvoiceRequest {
  page: number
  limit: number
  workspaceId: string
  sortDirection?: GetInvoiceSortDirectionEnum
  excludedIds?: Array<string>
}

export interface GetOnePlanRequest {
  workspaceId: string
  planId: string
}

export interface GetPackagesRequest {
  workspaceId: string
}

export interface GetShortSubscriptionRequest {
  workspaceId: string
}

export interface GetUpcomingInvoiceRequest {
  workspaceId: string
}

export interface ReactivateSubscriptionRequest {
  workspaceId: string
}

/**
 *
 */
export class BillingApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static cancelSubscription(params: CancelSubscriptionRequest) {
    return localBillingApi.cancelSubscription(params)
  }

  /**
   * test
   */
  cancelSubscription({ workspaceId }: CancelSubscriptionRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/billing/cancel-subscription`,
      undefined,
      true,
    )
  }

  static changeSubscription(params: ChangeSubscriptionRequest) {
    return localBillingApi.changeSubscription(params)
  }

  /**
   * Change subscription
   */
  changeSubscription({ updateSubscriptionDto, workspaceId }: ChangeSubscriptionRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/billing/change-subscription`,
      updateSubscriptionDto,
      true,
    )
  }

  static createStripeCheckoutSession(params: CreateStripeCheckoutSessionRequest) {
    return localBillingApi.createStripeCheckoutSession(params)
  }

  /**
   * Create a stripe checkout session
   */
  createStripeCheckoutSession({
    startSubscriptionDto,
    workspaceId,
  }: CreateStripeCheckoutSessionRequest): Promise<BillingCheckoutSession> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/billing/stripe-checkout-session`,
      startSubscriptionDto,
    )
  }

  static getChangeUpcomingInvoice(params: GetChangeUpcomingInvoiceRequest) {
    return localBillingApi.getChangeUpcomingInvoice(params)
  }

  /**
   * Get upcoming invoice for subscription change
   */
  getChangeUpcomingInvoice({
    updateSubscriptionDto,
    workspaceId,
  }: GetChangeUpcomingInvoiceRequest): Promise<BillingCheckoutUpcomingInvoiceData> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/billing/change-upcoming-invoice`,
      updateSubscriptionDto,
    )
  }

  static getFullSubscription(params: GetFullSubscriptionRequest) {
    return localBillingApi.getFullSubscription(params)
  }

  /**
   * Get the user active subscription with all informations
   */
  getFullSubscription({ workspaceId }: GetFullSubscriptionRequest): Promise<GetFullSubscriptionDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/billing/full`)
  }

  static getInvoice(params: GetInvoiceRequest) {
    return localBillingApi.getInvoice(params)
  }

  /**
   * Get invoices history
   */
  getInvoice({ workspaceId, ...queryParameters }: GetInvoiceRequest): Promise<PaginatedSubscriptionInvoiceResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/billing/invoices?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }

  static getOnePlan(params: GetOnePlanRequest) {
    return localBillingApi.getOnePlan(params)
  }

  /**
   * Get plan by id
   */
  getOnePlan({ workspaceId, planId }: GetOnePlanRequest): Promise<GetPlanDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/billing/plan/${encodeURIComponent(planId)}`,
    )
  }

  static getPackages(params: GetPackagesRequest) {
    return localBillingApi.getPackages(params)
  }

  /**
   * Get available packages
   */
  getPackages({ workspaceId }: GetPackagesRequest): Promise<GetPackagesDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/billing/packages`)
  }

  static getShortSubscription(params: GetShortSubscriptionRequest) {
    return localBillingApi.getShortSubscription(params)
  }

  /**
   * Get the user active subscription with only basic informations
   */
  getShortSubscription({ workspaceId }: GetShortSubscriptionRequest): Promise<GetShortSubscriptionDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/billing/short`)
  }

  static getUpcomingInvoice(params: GetUpcomingInvoiceRequest) {
    return localBillingApi.getUpcomingInvoice(params)
  }

  /**
   * Get regular upcoming invoice
   */
  getUpcomingInvoice({ workspaceId }: GetUpcomingInvoiceRequest): Promise<BillingStandardUpcomingInvoiceData> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/billing/upcoming-invoice`)
  }

  static reactivateSubscription(params: ReactivateSubscriptionRequest) {
    return localBillingApi.reactivateSubscription(params)
  }

  /**
   * test
   */
  reactivateSubscription({ workspaceId }: ReactivateSubscriptionRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/billing/reactivate-subscription`,
      undefined,
      true,
    )
  }
}

const localBillingApi = new BillingApi()

/**
 * @export
 * @enum {string}
 */
export type GetInvoiceSortDirectionEnum = "ascending" | "descending"
