import { UseQueryOptions } from "react-query"

import { usePaginatedWorkspaceQuery } from "../../context/workspaceContext"
import { GetWorkspaceHistoryRequest, HistoryApi, PaginatedHistoryResultDto } from "../openapi"

export const GET_WORKSPACEHISTORY_KEY = "getWorkspaceHistory"

export const historyActions = {
  useHistoryWorkspace: (variables: GetWorkspaceHistoryRequest, config?: UseQueryOptions<PaginatedHistoryResultDto>) => {
    return usePaginatedWorkspaceQuery(
      [GET_WORKSPACEHISTORY_KEY, variables],
      HistoryApi.getWorkspaceHistory,
      variables,
      config,
    )
  },
}
