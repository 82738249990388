import { useTypedController } from "@hookform/strictly-typed"
import { Box, Checkbox, DialogActions, FormLabel, makeStyles, Theme, Typography } from "@material-ui/core"
import { prettyEmptyOrNull } from "pure-shared"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { IDataTableColumn } from "react-data-table-component"
import { useForm } from "react-hook-form"

import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import { DrawerProp } from "components/misc/useDrawer"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import BaseTable from "components/Table"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import api from "services/api"
import { GetWorkspaceDto, PeggableStablecoin } from "services/api/openapi/models"
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from "services/constants"
import { ThemeContext } from "services/context/themeContext"
import { widgetTableStyle } from "../../Dashboard/widgets/widgetTableStyle"
import { WorkspaceApplyPeg } from "./WorkspaceApplyPeg"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

type FormType = {
  selectAll: boolean
  stablecoins: PeggableStablecoin[]
}

const useStyles = makeStyles((theme: Theme) => ({
  validateButton: {
    marginRight: theme.spacing(2),
  },
}))

const WorkspacePeggedStablecoins = ({
  item: workspace,
}: Pick<DrawerProp<GetWorkspaceDto, false>, "item">): JSX.Element => {
  const toast = useToast()
  const peggableTokens = api.workspace.usePeggableStablecoins({ workspaceId: workspace.id })
  const { mutateAsync: setPeggedStablecoinsMutation } = api.workspace.useSetPeggedStablecoins()
  const currentStablecoinsStates = useMemo(
    () => JSON.parse(JSON.stringify(peggableTokens?.data?.stablecoins ?? [])) as PeggableStablecoin[],
    [peggableTokens?.data?.stablecoins],
  )

  const currentSelectAllStates = useMemo(() => {
    const isSelectAll = peggableTokens?.data?.stablecoins.filter((s) => !s.isPegged)

    return isSelectAll?.length === 0
  }, [peggableTokens?.data?.stablecoins])

  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [rowSelected, setRowSelected] = useState<PeggableStablecoin[]>([])

  const { handleSubmit, control } = useForm<FormType>({
    mode: "onChange",
    defaultValues: {
      selectAll: currentSelectAllStates,
      stablecoins: currentStablecoinsStates,
    },
  })

  const isSelectedDirty = useCallback(() => {
    const ret = rowSelected.filter((s, idx) => {
      if (s.isPegged !== peggableTokens?.data?.stablecoins[idx].isPegged) return s
    })

    const isDirty = ret?.length > 0

    return isDirty
  }, [rowSelected, peggableTokens?.data?.stablecoins])

  const isFormDirty = selectAll !== currentSelectAllStates || isSelectedDirty()

  useEffect(() => {
    setRowSelected(currentStablecoinsStates)
  }, [currentStablecoinsStates])

  useEffect(() => {
    const ret = rowSelected.filter((s) => !s.isPegged)
    const isSelectAll = ret?.length === 0
    if (isSelectAll) setSelectAll(true)
    if (!isSelectAll && isSelectAll !== selectAll) setSelectAll(false)
  }, [setSelectAll, selectAll, rowSelected, peggableTokens?.data?.stablecoins])

  const TypedController = useTypedController<FormType>({ control })
  const classes = useStyles()
  const muiThemeContext = useContext(ThemeContext)

  const onSubmit = async () => {
    try {
      await setPeggedStablecoinsMutation({
        workspaceId: workspace.id,
        updatePeggedStablecoinsDto: {
          stablecoinsIds: rowSelected.filter((s) => s.isPegged).map((s) => s.id),
        },
      })
      toast.open("List updated", { variant: "success" })
      onClose()
    } catch (e) {
      toastCatch(e, toast)
    }
  }
  const [SavePeggedListButton, handleButtonCallback] = useLoadingButton()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const columns: IDataTableColumn<PeggableStablecoin>[] = useMemo(
    () => [
      {
        name: (
          <TypedController
            name={["selectAll"]}
            rules={{ required: false }}
            render={({ onChange, ...rest }) => {
              const customChange = () => {
                const newRow = rowSelected.map((s) => {
                  s.isPegged = !selectAll
                  return s
                })
                onChange(!selectAll)
                setRowSelected(newRow)
                setSelectAll(!selectAll)
              }
              return (
                <PermissionDisabled permission="can_modify_transaction" action="modify transaction">
                  <Checkbox
                    color="primary"
                    id={`all-pegged-checkbox`}
                    onChange={customChange}
                    checked={selectAll}
                    {...rest}
                  />
                </PermissionDisabled>
              )
            }}
          />
        ),
        selector: "checkbox",
        format: function formatCheckbox(row) {
          return (
            <TypedController
              name={["stablecoins"]}
              rules={{ required: false }}
              render={({ onChange, ...rest }) => {
                const customChange = () => {
                  const newRow = rowSelected.map((s) => {
                    if (s.id === row.id) {
                      s.isPegged = !row.isPegged
                    }
                    return s
                  })
                  onChange(newRow)
                  setRowSelected(newRow)
                }
                return (
                  <PermissionDisabled permission="can_modify_transaction" action="modify transaction">
                    <Checkbox
                      color="primary"
                      id={`${row.symbol}-pegged-checkbox`}
                      onChange={customChange}
                      checked={row.isPegged}
                      {...rest}
                    />
                  </PermissionDisabled>
                )
              }}
            />
          )
        },
      },
      {
        name: "Assets",
        selector: "name",
        sortable: true,
        format: function formatAssets(row) {
          return (
            <FormLabel htmlFor={`${row.symbol}-pegged-checkbox`}>
              {prettyEmptyOrNull(row.name)} ({prettyEmptyOrNull(row.symbol)})
            </FormLabel>
          )
        },
      },
    ],
    [TypedController, setRowSelected, setSelectAll, rowSelected, selectAll],
  )

  if (peggableTokens.isError) return <NetworkErrorMessage additionalData={peggableTokens} />
  if (peggableTokens.isLoading || peggableTokens.data === undefined) return <LoadingSpinner />

  const onClose = () => setIsModalOpen(false)
  const onOpen = () => setIsModalOpen(true)

  return (
    <DrawerCategory title="Peg stablecoins 1:1 with the US dollar">
      <DrawerSection name="Select stablecoins to peg" mt={2}>
        <Box mt={2} mb={2}>
          <Typography variant="body2">
            {
              "Select the USD stablecoins for which you would like one amount of stablecoin to be strictly equal to one USD."
            }
          </Typography>
        </Box>
        <ButtonUI onClick={onOpen}>+ Select stablecoins</ButtonUI>
      </DrawerSection>
      <ModalUI
        isOpen={isModalOpen}
        onClose={onClose}
        className="w-[1000px] h-auto"
        title="Select Peg stablecoins 1:1 with the US dollar"
      >
        <BaseTable<PeggableStablecoin, "errorString">
          columns={columns}
          items={currentStablecoinsStates}
          fixedScroll="400px"
          defaultParams={{
            sortDirection: "ascending",
          }}
          paginationRowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          paginationTotalRows={currentStablecoinsStates.length}
          paginationPerPage={50}
          pagination={currentStablecoinsStates.length > DEFAULT_ROWS_PER_PAGE}
          customStyle={widgetTableStyle(muiThemeContext.type)}
          sortServer={false}
        />
        <Box component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              {currentStablecoinsStates.length} element
              {currentStablecoinsStates.length > 1 ? "s" : ""} found
            </Typography>
            <Box>
              <SavePeggedListButton disabled={!isFormDirty} className={classes.validateButton} type="submit">
                Save
              </SavePeggedListButton>
            </Box>
          </DialogActions>
        </Box>
      </ModalUI>
      <WorkspaceApplyPeg stablecoins={peggableTokens.data} workspaceId={workspace.id} />
    </DrawerCategory>
  )
}

export default WorkspacePeggedStablecoins
