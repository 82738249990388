import React from "react"
import { css } from "@emotion/css"

export default function Loader({ width = "20px", height = "20px", className = "" }) {
  const animation = css`
    display: inline-block;
    width: ${width};
    height: ${height};
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s linear 0s infinite;
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  `

  return <div className={`${animation} ${className}`}></div>
}
