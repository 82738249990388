import { Typography } from "@material-ui/core"
import { SubType } from "pure-shared"
import React, { useContext } from "react"

import { GetFullWorkspaceRoleDto } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"

interface Props {
  permission: keyof SubType<GetFullWorkspaceRoleDto, boolean>
  action: string
}

const PermissionText = (props: Props): JSX.Element => {
  const workspaceCtx = useContext(WorkspaceContext)
  if (workspaceCtx.workspace.userRights[props.permission]) {
    return <></>
  }
  return (
    <Typography style={{ fontStyle: "italic" }}>
      Your current role does not allow to {props.action}.
      <br />
      An administrator can change your role.
    </Typography>
  )
}

export default PermissionText
