import { TypographyProps } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import React from "react"

import { WithComponentProp } from "services/utils/types"

const TypographyAllowEvent = ({ children, ...props }: WithComponentProp<TypographyProps>): JSX.Element => {
  return (
    <TypographyUI {...props}>
      <span data-tag="allowRowEvents">{children}</span>
    </TypographyUI>
  )
}

export default TypographyAllowEvent
