import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import { Add as AddIcon } from "@material-ui/icons"
import { WalletImportErrorEnum } from "pure-shared"
import React, { useContext, useState } from "react"
import ConditionalTooltip from "../../../components/ConditionalTooltip"

import { ReactComponent as Reset } from "CryptioUI/assets/icons/reset.svg"
import { ReactComponent as Delete } from "CryptioUI/assets/icons/delete.svg"

import { DrawerCategory } from "components/Drawer/DrawerItems"
import { FeatureGate } from "components/Feature/FeatureGate"
import { LoadingButton, UncontrolledLoadingButton } from "components/LoadingButton"
import useDialog from "components/misc/useDialog"
import { DrawerProp } from "components/misc/useDrawer"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import WarningTypography from "components/WarningTypography"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { GetWalletDto } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import download from "services/utils/download"
import { pluralize } from "services/utils/textUtils"
import { ImportDrawerAutoFlagBorrowSection } from "./AutoFlagBorrowSection"
import { errorExplanations } from "./constants"
import ImportUpdateCredentialDialog from "./ImportUpdateCredentialDialog"
import UpdateCustomSourceModal from "./UpdateCustomSourceModal"
import WalletForm from "./WalletForm"
import WalletSummary from "./WalletSummary"
import { iconStyleBlack, iconStyleWhite } from "CryptioUI/Utilities/config"
import { ErrorDetails } from "./ErrorDetails"
import BackOfficeView from "../../../components/misc/BackOfficeView"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"
import { Share } from "CryptioUI/Icon/svg"
import findReportType from "services/utils/reports"
import { Mixpanel } from "services/mixpanel"

const useStyles = makeStyles((theme: Theme) => ({
  downloadCsvButton: {
    marginTop: theme.spacing(1),
  },
}))

const WalletDrawer = (props: DrawerProp<GetWalletDto, false>) => {
  const { item: wallet, onClose } = props
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
  const [isCustomModalOpen, setIsCustomModalOpen] = useState<boolean>(false)

  const basicDialog = useDialog()
  const workspaceCtx = useContext(WorkspaceContext)

  const toast = useToast()
  const classes = useStyles()

  const { mutateAsync: deleteWalletsMutation } = api.wallet.useDeleteWallets()
  const { mutateAsync: refreshSourcesMutation } = api.wallet.useRefreshSources()
  const { mutateAsync: createReportMutation } = api.report.useCreateReports()

  const reportModules = api.report.useReportTypes()

  const types = api.wallet.useTypes({ onlyInWorkspace: false })
  const fieldWallet = types?.data?.filter((source) => source.name === wallet.sourceName)
  const isWalletUpdatable = fieldWallet?.length
    ? fieldWallet[0].credentialFields.find((field) => field.isUpdatable === true) ?? false
    : false

  const refreshSource = async () => {
    try {
      await refreshSourcesMutation({
        refreshWalletsDto: {
          individuals: {
            walletIds: [wallet.id],
          },
        },
      })
      toast.open("Updating source...", {
        variant: "success",
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const doDeleteWallet = async () => {
    try {
      await deleteWalletsMutation({
        deleteWalletsDto: {
          individuals: {
            walletIds: [wallet.id],
          },
        },
      })
      toast.open("Deleting source...", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
    onClose()
  }

  const exportTransaction = async () => {
    try {
      const reportType = findReportType(reportModules.data, "audit", "transactions_custom_export")
      const [transactionFormat] = reportType.type.formats
      await createReportMutation({
        createReportDto: {
          reportModule: reportType.module.name,
          fileType: "csv",
          allColumns: true,
          exportFormatId: transactionFormat.id,
          startDate: null,
          endDate: null,
          walletIds: [wallet.id],
          assetIds: [],
          impairmentId: null,
          includeNft: true,
          inputDate: null,
        },
      })
      Mixpanel.track("ReportCreated", {
        reportName: reportType.type.name,
        reportFormat: reportType.type.formats[0].name,
      })
      toast.open("The report is being generated in your Reports page", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const { mutateAsync: valuationUpdateMutation } = api.wallet.useValuationUpdate()

  const valuationUpdate = async () => {
    try {
      await valuationUpdateMutation({
        walletId: wallet.id,
      })
      toast.open("Updating valuation", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const askDeleteWallet = () => {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <>
          <Typography variant="h5" component="p">
            Do you really want to delete the source <b>{wallet.name}</b>? This action is irreversible.
          </Typography>
          {wallet.transactionCount > 0 && (
            <span>
              <br />
              You will lose <b>{wallet.transactionCount}</b> {pluralize(wallet.transactionCount > 1, "transaction")}.
            </span>
          )}
          {wallet.labelRuleCount > 0 && (
            <Typography variant="h5" component="p">
              This source is included in <strong>{wallet.labelRuleCount}</strong>{" "}
              {pluralize(wallet.labelRuleCount > 1, "label rule")}. If you delete it, the{" "}
              {pluralize(wallet.labelRuleCount > 1, "rule")} will be deleted as well (but the labels already applied by
              the {pluralize(wallet.labelRuleCount > 1, "rule")} will remain). Are you sure you want to delete the
              source?
            </Typography>
          )}
        </>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doDeleteWallet,
    })
  }

  const isLockingConflicting = workspaceCtx.workspace.lock.isEnabled && wallet.hasTransactionInLockedPeriod

  const isWalletDeleting = wallet.stepName === "delete"
  return (
    <Box mb={3}>
      {basicDialog.dialog}
      <ImportUpdateCredentialDialog onClose={() => setDialogOpen(false)} isOpen={isDialogOpen} wallet={wallet} />
      <WalletForm {...props} />

      <WalletSummary wallet={wallet} onClose={onClose} />

      {isWalletUpdatable && (
        <DrawerCategory title="Source credentials">
          <Box mt={3}>
            <Typography variant="body2">
              If the API keys associated with a source expired, you can update them so that you don&apos;t have to
              delete the source and import it again.
            </Typography>
            <Box mt={2} />
            <Typography variant="body2">
              Click to update the credentials. Once the credentials are updated, you will be able to update the source.
            </Typography>
            <Box mt={2} />
            <PermissionDisabled permission="can_modify_wallet" action="update credentials">
              <ButtonUI onClick={() => setDialogOpen(true)}>Update credentials</ButtonUI>
            </PermissionDisabled>
          </Box>
        </DrawerCategory>
      )}

      {!wallet.isCsvOnly && (
        <DrawerCategory title="Source update">
          {isWalletDeleting && (
            <WarningTypography mt={3}>This source is being deleted. It can not be updated.</WarningTypography>
          )}

          <Box mt={3}>
            <Typography variant="body2">
              Click to update this source. Only newer transactions will be fetched.
            </Typography>
            <Box mt={2}>
              <PermissionDisabled permission="can_modify_wallet" action="update sources">
                <UncontrolledLoadingButton
                  // TODO: if custom csv => open modal, upload file and submit
                  onClick={() => refreshSource()}
                  Icon={<Reset className={iconStyleWhite} />}
                  disabled={isWalletDeleting}
                >
                  Update source
                </UncontrolledLoadingButton>
              </PermissionDisabled>
            </Box>
          </Box>
        </DrawerCategory>
      )}

      {!wallet.isApiOnly &&
        (() => {
          // TODO: Now both normal update and importing more transaction via CSV
          // can be done on the same source. The two sections should not have the
          // same name? What should be the title of the import section?
          const sourceUpdateCategory = (
            <DrawerCategory title="Import transactions">
              <UpdateCustomSourceModal
                isOpen={isCustomModalOpen}
                onClose={() => setIsCustomModalOpen(false)}
                wallet={wallet}
              />

              {isWalletDeleting && (
                <WarningTypography mt={3}>This source is being deleted. It can not be updated.</WarningTypography>
              )}

              <Box mt={3}>
                {wallet.hasError ? (
                  <Typography variant="body2">
                    The last CSV you uploaded contained errors. You can upload a new CSV to replace it.
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    You can add new transactions to this source. All transactions present in the uploaded file will be
                    imported, even if it was already imported in Cryptio. This can cause duplicates so be careful to
                    only include newer transactions in the CSV you are about to upload.
                  </Typography>
                )}
                <Box mt={2} />
                <PermissionDisabled permission="can_modify_wallet" action="update sources">
                  <UncontrolledLoadingButton
                    // TODO: if custom csv => open modal, upload file and submit
                    onClick={() => setIsCustomModalOpen(true)}
                    Icon={<AddIcon />}
                    disabled={isWalletDeleting}
                  >
                    {wallet.hasError ? <>Replace CSV</> : <>Add transactions</>}
                  </UncontrolledLoadingButton>
                </PermissionDisabled>
              </Box>
            </DrawerCategory>
          )
          // Feature gate only if there are no errors
          if (wallet.hasError) {
            return sourceUpdateCategory
          } else if (wallet.isCsvOnly) {
            return <FeatureGate feature="add_csv_transactions">{sourceUpdateCategory}</FeatureGate>
          } else {
            return <FeatureGate feature="add_csv_to_any_wallet_type">{sourceUpdateCategory}</FeatureGate>
          }
        })()}

      {wallet.isCsvOnly && wallet.isApiOnly && (
        <DrawerCategory title="Source update">
          <WarningTypography mt={3}>This source can only be updated through the API.</WarningTypography>
        </DrawerCategory>
      )}

      <FeatureGate feature="auto_flag_borrow">
        <ImportDrawerAutoFlagBorrowSection wallet={wallet} />
      </FeatureGate>

      <FeatureGate feature="update_valuation">
        <DrawerCategory title="Valuation update">
          <Box mt={2}>
            <Typography variant="body2">
              Updating the source valuation may reduce the number of missing prices.
            </Typography>
            <Box mt={2}>
              {/* TODO: if custom csv => open modal, upload file and submit */}
              <UncontrolledLoadingButton
                disabled={workspaceCtx.workspace.hasValuationJobsRunning}
                onClick={valuationUpdate}
                Icon={<Reset className={iconStyleWhite} />}
              >
                Update valuation
              </UncontrolledLoadingButton>
            </Box>
          </Box>
        </DrawerCategory>
      </FeatureGate>

      {(wallet.status === "error" || wallet.hasError) && (
        <DrawerCategory title="Import status">
          <Box mt={3} />
          {wallet.lastCsvSource?.error === WalletImportErrorEnum.notImported ? (
            <Typography variant="body2">This source is still empty.</Typography>
          ) : (
            <Typography variant="body2">
              The transactions could not be imported.
              <br />
              {/* TODO: Add human readable error explanation */}
              {wallet.lastCsvSource?.error ? <b>{errorExplanations[wallet.lastCsvSource.error]}</b> : <></>}
            </Typography>
          )}
          {wallet.lastCsvSource && wallet.lastCsvSource.hasErrorReport && (
            <PermissionDisabled permission="can_read_wallet" action="download error report">
              <UncontrolledLoadingButton
                className={classes.downloadCsvButton}
                onClick={() =>
                  api.wallet
                    .getErrorCsvSignedUrl(workspaceCtx, wallet.lastCsvSource!.id)
                    .then((res) => download(res.signedUrl))
                }
              >
                Download error report
              </UncontrolledLoadingButton>
            </PermissionDisabled>
          )}
        </DrawerCategory>
      )}
      {wallet.isCsvOnly && (
        <PermissionDisabled permission="can_create_report" action="create contacts report">
          <DrawerCategory title="Export original file">
            <Box paddingTop={2} />
            <Typography variant="body2">Export all transaction you have imported for this custom wallet.</Typography>
            <Box paddingTop={2}>
              <ButtonUI onClick={exportTransaction} Icon={<Share className={iconStyleWhite} />}>
                Export
              </ButtonUI>
            </Box>
          </DrawerCategory>
        </PermissionDisabled>
      )}
      <DrawerCategory title="Source deletion">
        <Box mt={3} />
        <Typography variant="body2">Click to delete this source. This action is irreversible.</Typography>
        <Box mt={2}>
          <ConditionalTooltip
            tooltipMessage="This source can't be deleted as some of its transactions are locked. To delete this source, please unlock your period. Please note that this action will update your cost basis."
            disabled={isLockingConflicting}
          >
            <div>
              <PermissionDisabled permission="can_remove_wallet" action="delete sources">
                <LoadingButton
                  Icon={<Delete className={iconStyleBlack} />}
                  mode={Mode.CONTAINED}
                  onClick={askDeleteWallet}
                  pending={isWalletDeleting}
                  disabled={isLockingConflicting}
                >
                  Delete
                </LoadingButton>
              </PermissionDisabled>
            </div>
          </ConditionalTooltip>
        </Box>
      </DrawerCategory>

      {(wallet.status === "error" || wallet.hasError) && wallet.errorDetails && (
        <BackOfficeView>
          <ErrorDetails errorDetails={wallet.errorDetails} />
        </BackOfficeView>
      )}
    </Box>
  )
}

export default WalletDrawer
