import { useTypedController } from "@hookform/strictly-typed"
import { Box, Divider, Link, makeStyles, Paper, TextField, Theme, Typography } from "@material-ui/core"
import { AccountCircleRounded } from "@material-ui/icons"
import React from "react"
import { useForm } from "react-hook-form"
import { Link as RouterLink, useHistory, useRouteMatch } from "react-router-dom"

import { DrawerCategory, DrawerFormSection, DrawerSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import PasswordField from "components/PasswordField"
import PasswordRequirementSentence from "components/PasswordRequirementSentence"
import { code2faRules, passwordRules } from "components/ReactHookForm/rules"
import { URLS } from "../../routes"
import api from "services/api"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface ResetPasswordForm {
  password: string
  confirmPassword: string
  code2fa?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  bottomDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: "0 auto",
    maxWidth: "38em",
  },
  submitButton: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "1.2em",
  },
  accountIcon: {
    marginRight: theme.spacing(2),
  },
}))

const ResetPasswordScene = (): JSX.Element => {
  const classes = useStyles()
  const { control, handleSubmit, formState, watch, unregister } = useForm<ResetPasswordForm>({ mode: "onChange" })
  const TypedController = useTypedController<ResetPasswordForm>({ control })
  const routeMatch = useRouteMatch<{ email: string; token: string }>()
  const history = useHistory()
  const toast = useToast()
  const { password: watchPassword = "", confirmPassword: watchConfirmPassword = "" } = watch()
  const preLoginStatus = api.user.useGetPreLoginStatus(routeMatch.params.email)
  const { mutateAsync: forgotPasswordMutation } = api.user.useResetForgottenPassword()
  const { mutateAsync: loginMutation } = api.user.useLogin()

  const [ResetPasswordButton, handleButtonCallback] = useLoadingButton()
  if (preLoginStatus.isError) return <NetworkErrorMessage small={false} additionalData={preLoginStatus} />
  if (preLoginStatus.isLoading || preLoginStatus.data === undefined) return <LoadingSpinner />
  if (preLoginStatus.data.has2Fa === false) {
    unregister("code2fa")
  }

  const onSubmit = async (form: ResetPasswordForm) => {
    try {
      await forgotPasswordMutation({
        resetPasswordWithTokenDto: {
          ...form,
          email: routeMatch.params.email,
          token: routeMatch.params.token as string,
        },
      })

      await loginMutation({
        loginUserDto: {
          email: routeMatch.params.email,
          password: form.password,
          code2fa: form.code2fa,
        },
      })

      toast.open("Successfully updated password", { variant: "success" })

      history.push(URLS.Account.Login)
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  return (
    <Box pt={20}>
      <Paper component="form" className={classes.container} onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
        <DrawerCategory title="Reset password">
          <DrawerSection name="Email">
            <Box display="flex" pt={1}>
              <AccountCircleRounded className={classes.accountIcon} />
              <Typography>{routeMatch.params.email}</Typography>
            </Box>
          </DrawerSection>

          <DrawerFormSection htmlFor="password-textfield" name="Choose password">
            <TypedController
              name="password"
              defaultValue={""}
              rules={passwordRules}
              render={(props) => (
                <PasswordField id="password-textfield" fullWidth {...props} error={!!formState.errors.password} />
              )}
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="confirm-password-textfield" name="Confirm password">
            <TypedController
              name="confirmPassword"
              defaultValue={""}
              rules={{
                ...passwordRules,
                validate: (confirmPassword: string) => confirmPassword === watchPassword,
              }}
              render={(props) => (
                <PasswordField
                  id="confirm-password-textfield"
                  fullWidth
                  {...props}
                  error={!!formState.errors.confirmPassword}
                />
              )}
            />
          </DrawerFormSection>

          {preLoginStatus.data?.has2Fa && (
            <DrawerFormSection htmlFor="code2fa" name="Two factor authentication code">
              <TypedController
                name="code2fa"
                defaultValue={""}
                rules={code2faRules}
                render={(props) => (
                  <TextField
                    id="code2fa-textfield"
                    error={!!formState.errors.code2fa}
                    placeholder="Code 2fa"
                    {...props}
                    fullWidth={true}
                  />
                )}
              />
            </DrawerFormSection>
          )}

          <PasswordRequirementSentence password={watchPassword} confirmPassword={watchConfirmPassword} />

          <Box my={2} display="flex" justifyContent="center">
            <ResetPasswordButton className={classes.submitButton} type="submit" disabled={!formState.isValid}>
              Reset password
            </ResetPasswordButton>
          </Box>

          <Divider className={classes.bottomDivider} />

          <Box display="flex" justifyContent="center">
            <Typography variant="body1" color="textSecondary">
              Not registered yet?
              <Link component={RouterLink} to={URLS.Account.Register}>
                {" "}
                Create an account
              </Link>
            </Typography>
          </Box>
        </DrawerCategory>
      </Paper>
    </Box>
  )
}

export default ResetPasswordScene
