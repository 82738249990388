import { Box, Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Typography } from "@material-ui/core"
import dayjs from "dayjs"
import { RefreshRounded, DeleteRounded } from "@material-ui/icons"

import BaseTable from "components/Table"
import { IExtendedDataTableColumn } from "components/Table/interface"
import TitleWithDivider from "components/TitleWithDivider"
import { RequestFinanceIntegrationDto } from "services/api/openapi"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import api from "services/api"
import useDialog from "components/misc/useDialog"
import { useEffect } from "react"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface RequestIntegrationsListProps {
  isOpen: boolean
  onClose: () => void
  integrations: RequestFinanceIntegrationDto[]
}

const useStyles = makeStyles(() => ({
  paper: { minWidth: "800px", minHeight: "250px" },
}))

export function RequestIntegrationsList({ isOpen, onClose, integrations }: RequestIntegrationsListProps) {
  const classes = useStyles()
  const toast = useToast()
  const dialog = useDialog()

  useEffect(() => {
    if (integrations.length === 0) {
      onClose()
    }
  }, [integrations.length, onClose])

  const { mutateAsync: synchronizeOneRequestFinanceIntegrationMutation } =
    api.integrations.useSynchronizeOneRequestFinanceIntegration()
  const { mutateAsync: deleteRequestFinanceIntegrationMutation } = api.integrations.useDeleteRequestFinanceIntegration()

  async function synchronizeIntegration(integrationId: string) {
    try {
      await synchronizeOneRequestFinanceIntegrationMutation({ integrationId })
      toast.open("Updating Request Finance integration...", {
        variant: "default",
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  async function doDeleteIntegration(integrationId: string) {
    try {
      await deleteRequestFinanceIntegrationMutation({ integrationId })
      toast.open("Request Finance integration deleted", {
        variant: "success",
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  function askDeleteIntegration(integrationId: string) {
    dialog.showDialog({
      title: "Are you sure?",
      content: <Typography variant="h5">Do you really want to delete this integration?</Typography>,
      yesText: "Yes",
      noText: "Cancel",
      onAccept: () => doDeleteIntegration(integrationId),
    })
  }

  const columns: IExtendedDataTableColumn<RequestFinanceIntegrationDto>[] = [
    {
      name: "Name",
      selector: "name",
    },
    {
      name: "Creation Date",
      selector: "createdAt",
      format: function formatCreatedAt(row: RequestFinanceIntegrationDto): JSX.Element {
        return <>{row.createdAt ? dayjs(row.createdAt).format("LLL") : "Unknown"}</>
      },
    },
    {
      name: "Invoice count",
      selector: "invoices",
    },
    {
      name: "Actions",
      selector: "action", // If no selector, nothing show up, but here I need 2 selectors..
      format: function formatAction(row: RequestFinanceIntegrationDto): JSX.Element {
        return (
          <Box display="flex" alignItems="center">
            <PermissionDisabled permission="can_download_report" action="download a report">
              <IconButton
                aria-label="Update"
                disabled={row.error !== null}
                onClick={() => synchronizeIntegration(row.id)}
              >
                <RefreshRounded />
              </IconButton>
            </PermissionDisabled>
            <PermissionDisabled permission="can_remove_report" action="delete a report" titlePermissionEnable="Delete">
              <IconButton aria-label="delete" onClick={() => askDeleteIntegration(row.id)}>
                <DeleteRounded />
              </IconButton>
            </PermissionDisabled>
          </Box>
        )
      },
    },
  ]

  return (
    <>
      {dialog.dialog}
      <Dialog open={isOpen} onClose={onClose} classes={{ paper: classes.paper }}>
        <DialogTitle id="connect-dialog-title">
          <TitleWithDivider title="Request Finance connections" />
        </DialogTitle>
        <DialogContent>
          <BaseTable columns={columns} items={integrations} />
        </DialogContent>
      </Dialog>
    </>
  )
}
