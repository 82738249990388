import React from "react"
import { CostBasisIds } from "../.."
import useGenerateCell, { DataToDisplay } from "../useGenerateCell"
import { GetMovementDto } from "../../../../services/api/routes/transactions"

interface GeneratedCellProps {
  columnName: string
  dataToDisplay: DataToDisplay
  openWalletDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  openMovementLabelDrawer: <PassedItemId extends GetMovementDto>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  setCostBasisDrawerTransactionId: (ids: CostBasisIds | null) => void
  isBorderRight?: boolean
  openTransactionLabelDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
}

const GeneratedCell = (props: GeneratedCellProps) => {
  const {
    dataToDisplay,
    openWalletDrawer,
    openMovementLabelDrawer,
    setCostBasisDrawerTransactionId,
    openTransactionLabelDrawer,
  } = props

  const cell = useGenerateCell({
    dataToDisplay,
    openWalletDrawer,
    openMovementLabelDrawer,
    setCostBasisDrawerTransactionId,
    openTransactionLabelDrawer,
  })

  return <>{cell}</>
}

export default GeneratedCell
