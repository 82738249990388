import { Box } from "@material-ui/core"
import dayjs from "dayjs"
import React, { useMemo } from "react"

import { IExtendedDataTableColumn } from "components/Table/interface"
import TypographyAllowEvent from "components/TypographyAllowEvent"
import { FullMovementDto, MovementCounterPartyDto, MovementCounterPartyDtoDirectionEnum } from "services/api/openapi"
import AssetView from "../Cells/AssetView"
import OtherParty from "../Cells/OtherParty"

import CheckboxCellInternalTransfer from "./ControlCounterParty/DisclamerInternalTransfer/CheckboxCellInternalTransfer"
import { BoldWeight, TypographyVariant } from "CryptioUI/types"

interface CounterPartyTableColumnProps {
  setSelectedGuessedOtherParty?: (newCounterParty: MovementCounterPartyDto | null) => void
  selectedGuessedOtherParty?: MovementCounterPartyDto | null
  openWalletDrawer: (walletId: string) => void
}

const useCounterPartyTableColumn = (props: CounterPartyTableColumnProps) => {
  const { setSelectedGuessedOtherParty, selectedGuessedOtherParty, openWalletDrawer } = props

  const columns = useMemo<
    IExtendedDataTableColumn<MovementCounterPartyDto | FullMovementDto, MovementCounterPartyDtoDirectionEnum>[]
  >(() => {
    return [
      {
        name: "", // Name of the column (as displayed to the user)
        selector: "checkbox", // Property that will be read and displayed
        maxWidth: "100px",
        sortable: false,
        omit: !setSelectedGuessedOtherParty,
        format: function formatX(row) {
          if (!setSelectedGuessedOtherParty || selectedGuessedOtherParty === undefined) {
            return <></>
          }
          return (
            <Box>
              <CheckboxCellInternalTransfer
                counterParty={row}
                setSelectedGuessedOtherParty={setSelectedGuessedOtherParty}
                selectedGuessedOtherParty={selectedGuessedOtherParty}
              />
            </Box>
          )
        },
      },
      {
        name: "Date", // Name of the column (as displayed to the user)
        maxWidth: "125px",
        selector: "transactionDate",
        format: function formatX(row) {
          return (
            <Box>
              <TypographyAllowEvent variant={TypographyVariant.BODY} boldness={BoldWeight.MEDIUM}>
                {dayjs(row.transactionDate).tz().format("L")}
              </TypographyAllowEvent>
              <TypographyAllowEvent variant={TypographyVariant.CAPTION}>
                {dayjs(row.transactionDate).tz().format("LTS")}
              </TypographyAllowEvent>
            </Box>
          )
        },
      },
      {
        center: true,
        name: "Other Party",
        minWidth: "260px",
        selector: "otherParties",
        format: function formatX(row) {
          return (
            <OtherParty
              wallet={row.wallet.name}
              otherPartiesAliases={row.otherPartyAlias ? [row.otherPartyAlias] : []}
              direction={row.direction}
              onClickWallet={() => openWalletDrawer(row.wallet.id)}
            />
          )
        },
      },
      {
        center: true,
        name: "Amount",
        width: "auto",
        sortable: true,
        cell: function formatX(row) {
          return (
            <AssetView
              assetId={row.assetId}
              assetSymbol={row.assetSymbol}
              volume={row.volume}
              direction={row.direction}
              assetToUsdRate={row.assetToUsdRate}
              isFee={row.isFee}
              usdToFiatRate={row.usdToFiatRate}
            />
          )
        },
      },
    ]
  }, [setSelectedGuessedOtherParty, selectedGuessedOtherParty, openWalletDrawer])

  return columns
}

export default useCounterPartyTableColumn
