import { useTypedController } from "@hookform/strictly-typed"
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core"
import { ReactComponent as Warning } from "CryptioUI/assets/icons/warning.svg"
import React, { useCallback, useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import LabelColorSelector from "components/Labels/LabelColorSelector"
import { useLoadingButton } from "components/LoadingButton"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import { newColorHex } from "CryptioUI/types/tag"

import api from "services/api"
import { NewInListMovementDto } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  selectColor: {
    minWidth: "64px",
    marginLeft: theme.spacing(2),
  },
}))

const MovementLabelDrawerModal = ({
  labelName,
  onClose,
  movement,
}: {
  labelName: string | null
  onClose: () => void
  movement: NewInListMovementDto | undefined
}) => {
  const classes = useStyles()
  type FormType = { name: string; color: string }
  const { mutateAsync: createLabelMutation } = api.label.useCreateLabels()
  const { handleSubmit, control, formState, reset, setError } = useForm<FormType>({
    mode: "onChange",
    defaultValues: { name: labelName ?? "" },
  })
  const TypedController = useTypedController<FormType>({ control })
  const workspaceCtx = useContext(WorkspaceContext)
  const toast = useToast()

  const checkLabelNameAvailable = useCallback(
    async (labelName: string) => {
      if (labelName === "" || formState.errors.name !== undefined) return

      const res = await api.label
        .isLabelNameAvailable({ workspaceId: workspaceCtx.workspace.id, name: labelName })
        .catch(() => undefined)

      if (res?.isAvailable === false) {
        setError("name", { message: "This label name already exists" })
      }
    },
    [setError, workspaceCtx.workspace.id, formState.errors.name],
  )

  useEffect(() => {
    reset({ name: labelName ?? "", color: newColorHex.turquoise })
    checkLabelNameAvailable(labelName ?? "")
  }, [labelName, checkLabelNameAvailable, reset])

  const { mutateAsync: bulkUpdateMovementMutation } = api.transaction.useBulkUpdateMovements()

  const [CreateContactButton, handleButtonCallback] = useLoadingButton()

  const createNewLabel = async (form: FormType) => {
    try {
      const newLabel = await createLabelMutation({
        createLabelsDto: {
          labels: [
            {
              ...form,
              contactFromIds: null,
              contactToIds: null,
              walletFromIds: null,
              walletToIds: null,
              filterId: null,
            },
          ],
        },
      })

      if (newLabel === undefined) return

      await bulkUpdateMovementMutation({
        movementBulkUpdateDto: {
          labelIdsToAdd: newLabel.map((x) => x.id),
          individualMovements: {
            movementIds: [movement?.id ?? ""],
          },
        },
      })

      onClose()
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const formId = "transaction-label-modal-form"
  // TODO: once the design system select is good to go, need to replace Dialog with ModalUI
  return (
    <Dialog open={labelName !== null} onClose={onClose}>
      <Box padding={2} fontSize="1.4rem">
        <DialogTitle>
          <Typography variant="h4">Create label</Typography>
        </DialogTitle>
        <Box
          display="flex"
          alignItems="flex-start"
          padding={2}
          component="form"
          id={formId}
          onSubmit={handleButtonCallback(handleSubmit(createNewLabel))}
        >
          <TypedController
            name="name"
            defaultValue={""}
            rules={{
              required: true,
              validate: (value) => value.trim().length >= 3,
            }}
            render={(props) => (
              <TextField
                id="name-textfield"
                {...props}
                label="Name"
                placeholder="Write anything..."
                fullWidth
                onBlur={(x) => {
                  const labelName = x.target.value.trim()

                  return checkLabelNameAvailable(labelName)
                }}
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message}
              />
            )}
          />
          <Box pt={2}>
            <FormControl className={classes.selectColor}>
              <TypedController
                name="color"
                defaultValue={newColorHex.turquoise}
                rules={{ required: true }}
                render={({ value, onChange }) => <LabelColorSelector value={value} onChange={onChange} />}
              />
            </FormControl>
          </Box>
        </Box>
        <Box mt={2} display="flex" alignItems="center">
          <Box mr={2}>
            <Warning />
          </Box>
          <Typography variant="body2">
            In order to create rules or edit labels, please navigate to the Labels page in the Accounting section.
          </Typography>
        </Box>
        <Box mt={2}>
          <DialogActions>
            <ButtonUI onClick={onClose} mode={Mode.CONTAINED}>
              Cancel
            </ButtonUI>
            <CreateContactButton disabled={!formState.isValid} type="submit" form={formId}>
              Create
            </CreateContactButton>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}

export default MovementLabelDrawerModal
