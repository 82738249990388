import { Box, makeStyles, Theme } from "@material-ui/core"
import { ExitToAppRounded } from "@material-ui/icons"
import { DrawerCategory } from "components/Drawer/DrawerItems"
import ExternalLink from "components/ExternalLink"
import LabelCreationModalCsv from "./LabelCreationModalCsv"
import LabelUpdateModalCsv from "./LabelUpdateModalCsv"

const useStyles = makeStyles((theme: Theme) => ({
  linkIcon: {
    display: "inline-flex",
    verticalAlign: "top",
    marginLeft: theme.spacing(1),
  },
  csvExampleMenuItem: {
    padding: 0,
    marginTop: theme.spacing(3),
    textAlign: "end",
  },
}))

export interface ButtonModalImportCsvProp {
  labelId?: string
}

const ButtonModalImportCsv = ({ labelId }: ButtonModalImportCsvProp) => {
  const classes = useStyles()

  return (
    <DrawerCategory title="Batch import">
      <Box mt={3} />
      {labelId ? <LabelUpdateModalCsv labelId={labelId} /> : <LabelCreationModalCsv />}
      <Box mt={2} />
      <ExternalLink
        className={classes.csvExampleMenuItem}
        href={
          labelId
            ? "https://docs.google.com/spreadsheets/d/1b5aT7BAnyU7H_Nx_gKmxjblbVdzr9jmsejmXjGJned4"
            : "https://docs.google.com/spreadsheets/d/1EQtOAt7GgUtopvIKbNIRewZbTzO7yzw8ZG3xCjRXHiI"
        }
      >
        See CSV example
        <ExitToAppRounded className={classes.linkIcon} />
      </ExternalLink>
    </DrawerCategory>
  )
}

export default ButtonModalImportCsv
