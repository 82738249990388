import { PaletteType } from "@material-ui/core"
import { createTheme } from "react-data-table-component"

import getTheme from "../../materialTheme"

export const DataTableThemeName = "cryptio"

export const createDataTableTheme = (paletteType: PaletteType) => {
  const { palette } = getTheme(paletteType)

  return createTheme(DataTableThemeName, {
    text: {
      primary: palette.text.primary,
      secondary: palette.text.secondary,
      disabled: palette.text.disabled,
    },
    background: {
      default: palette.background.paper,
    },
    context: {
      background: "#FF00FF",
      text: "#FF00FF",
    },
    divider: {
      default: palette.divider,
    },
    action: {
      button: "#FF00FF",
      hover: "#FF00FF",
      focus: "#FF00FF",
      disabled: "#FF00FF",
    },
    sortFocus: {
      default: palette.primary.light,
    },
    selected: {
      default: palette.background.default,
      text: palette.getContrastText(palette.background.default),
    },
    highlightOnHover: {
      default: palette.action.hover,
      text: palette.primary.main,
    },
    striped: {
      default: "#FF00FF",
      text: "#FF00FF",
    },
  })
}

export const getDataTableStyle = (paletteType: PaletteType) => {
  const { typography, spacing, palette } = getTheme(paletteType)
  const theme = createDataTableTheme(paletteType)

  return {
    table: {
      style: {
        color: theme.text.primary,
        backgroundColor: theme.background.default,
      },
    },
    tableWrapper: {
      style: {
        display: "table",
      },
    },
    header: {
      style: {
        fontSize: typography.body1.fontSize,
        color: theme.text.primary,
        backgroundColor: theme.background.default,
        minHeight: "56px",
        paddingLeft: "16px",
        paddingRight: "8px",
      },
    },
    subHeader: {
      style: {
        backgroundColor: theme.background.default,
        minHeight: "52px",
      },
    },
    head: {
      style: {},
    },
    headRow: {
      style: {
        backgroundColor: theme.background.default,
        minHeight: "56px",
        borderBottomWidth: "1px",
        borderBottomColor: theme.divider.default,
        borderBottomStyle: "solid",
      },
      denseStyle: {
        minHeight: "32px",
      },
    },
    headCells: {
      style: {
        fontSize: typography.body1.fontSize,
        fontWeight: 500,
        backgroundColor: theme.highlightOnHover.default,
        color: theme.text.primary,
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
      activeSortStyle: {
        "color": theme.text.primary,
        "&:focus": {
          outline: "none",
        },
        "&:hover:not(:focus)": {
          color: theme.sortFocus.default,
        },
      },
      inactiveSortStyle: {
        "&:focus": {
          outline: "none",
          color: theme.sortFocus.default,
        },
        "&:hover": {
          color: theme.sortFocus.default,
        },
      },
    },
    contextMenu: {
      style: {
        backgroundColor: theme.context.background,
        fontSize: "18px",
        fontWeight: 400,
        color: theme.context.text,
        paddingLeft: spacing(2),
        paddingRight: spacing(1),
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
    cells: {
      style: {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
        wordBreak: "break-word",
      },
    },
    rows: {
      style: {
        "fontSize": "13px",
        "color": theme.text.primary,
        "backgroundColor": theme.background.default,
        "minHeight": "65px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: theme.divider.default,
        },
        "cursor": "pointer",
      },
      denseStyle: {
        minHeight: "32px",
      },
      selectedHighlightStyle: {
        // use nth-of-type(n) to override other nth selectors
        "&:nth-of-type(n)": {
          color: theme.selected.text,
          backgroundColor: theme.selected.default,
          borderBottomColor: theme.background.default,
        },
      },
      highlightOnHoverStyle: {
        color: theme.highlightOnHover.text,
        backgroundColor: theme.highlightOnHover.default,
        transitionDuration: "0.15s",
        transitionProperty: "background-color",
        borderBottomColor: theme.background.default,
        outlineStyle: "solid",
        outlineWidth: "1px",
        outlineColor: theme.background.default,
      },
      stripedStyle: {
        color: theme.striped.text,
        backgroundColor: theme.striped.default,
      },
    },
    expanderRow: {
      style: {
        color: theme.text.primary,
        backgroundColor: theme.background.default,
      },
    },
    expanderCell: {
      style: {
        flex: "0 0 48px",
      },
    },
    expanderButton: {
      style: {
        "color": theme.button.default,
        "fill": theme.button.default,
        "backgroundColor": "transparent",
        "borderRadius": "2px",
        "transition": "0.25s",
        "height": "100%",
        "width": "100%",
        "&:hover:enabled": {
          cursor: "pointer",
        },
        "&:disabled": {
          color: theme.button.disabled,
        },
        "&:hover:not(:disabled)": {
          cursor: "pointer",
          backgroundColor: theme.button.hover,
        },
        "&:focus": {
          outline: "none",
          backgroundColor: theme.button.focus,
        },
        "svg": {
          margin: "auto",
        },
      },
    },
    pagination: {
      style: {
        color: theme.text.secondary,
        fontSize: "13px",
        minHeight: "56px",
        backgroundColor: theme.background.default,
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: theme.divider.default,
      },
      pageButtonsStyle: {
        "borderRadius": "50%",
        "height": "40px",
        "width": "40px",
        "padding": "8px",
        "margin": "px",
        "cursor": "pointer",
        "transition": "0.4s",
        "color": theme.button.default,
        "fill": theme.button.default,
        "backgroundColor": "transparent",
        "&:disabled": {
          cursor: "unset",
          color: theme.button.disabled,
          fill: theme.button.disabled,
        },
        "&:hover:not(:disabled)": {
          backgroundColor: theme.button.hover,
        },
        "&:focus": {
          outline: "none",
          backgroundColor: "transparent",
        },
      },
    },
    noData: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.text.primary,
        backgroundColor: palette.background.default,
      },
    },
    progress: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.text.primary,
        backgroundColor: theme.background.default,
      },
    },
  }
}
