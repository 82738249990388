import { useTypedController } from "@hookform/strictly-typed"
import { prettyEmptyOrNull } from "pure-shared"
import { ReportFormComponentProps } from "."
import AssetOptionInAutocomplete from "components/AutoCompleteOptions/AssetOptionInAutocomplete"
import { DrawerFormSection } from "components/Drawer/DrawerItems"
import CustomSelector from "components/selector/CustomSelector"
import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import { GetAssetDto, GetWalletsTypeNamesEnum, GetTransactionAssetsRequest } from "services/api/openapi"
import { DrawerReportForm } from "../ReportForm"

export const chainlinkNodeOPeratorValidSourceNames: GetWalletsTypeNamesEnum[] = ["Ethereum"]

const ReportMultiSelectAsset = ({ control }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })

  return (
    <DrawerFormSection htmlFor="asset-select" name="Asset">
      <TypedController
        name="assets"
        defaultValue={[]}
        render={({ ref, onChange, ...rest }) => (
          <CustomSelector<WithoutWorkspaceId<GetTransactionAssetsRequest>, GetAssetDto, true>
            id="asset-select"
            {...rest}
            inputRef={ref}
            onChange={(_, newValue) => onChange(newValue)}
            getOptionLabel={(option) => option.name || prettyEmptyOrNull(option.symbol)}
            defaultPaginatedQueryProps={{
              // sortBy: "symbol",
              type: "both",
            }}
            minInputLengthForScroll={4}
            getOptionDisabled={rest.value && rest.value.length >= 20 ? () => true : () => false}
            getOptionSelected={(option, value) => option.id === value.id}
            multiple
            disableCloseOnSelect
            usePaginatedQuery={api.transaction.useAssets}
            size="small"
            placeholder={rest.value?.length ? undefined : "All"}
            filterSelectedOptions
            renderOption={(option) => <AssetOptionInAutocomplete asset={option} />}
          />
        )}
      />
    </DrawerFormSection>
  )
}

export default ReportMultiSelectAsset
