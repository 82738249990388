import { Box, makeStyles, Paper, Theme } from "@material-ui/core"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"

import { useLoadingButton } from "components/LoadingButton"
import TitleWithDivider from "components/TitleWithDivider"
import { URLS } from "../../routes"
import api from "services/api"
import { GetUserDto } from "services/api/openapi"
import { registrationFormId, RegistrationSteps } from "./state"
import { Step2 } from "./Step2"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: "0 auto",
    maxWidth: "38em",
    marginBottom: theme.spacing(3),
  },
  logo: {
    maxWidth: "300px",
    marginTop: theme.spacing(5),
  },
}))

type NoWorkspaceForm = RegistrationSteps[2]

const XeroPostRegister = ({ user }: { user: GetUserDto }) => {
  const classes = useStyles()
  const [PostRegisterButton, handlePostRegisterButtonCallback] = useLoadingButton()
  const toast = useToast()
  const [isFormValid, setFormIsValid] = useState(false)
  const history = useHistory()

  const { mutateAsync: postRegisterMutation } = api.user.useXeroPostRegister()
  const onSubmit = async (form: NoWorkspaceForm) => {
    try {
      await postRegisterMutation({
        xeroPostRegisterDto: {
          company: form.companyName,
          positionInCompany: form.companyPosition,
          firstName: form.firstName,
          lastName: form.lastName,
        },
      })
      history.push(URLS.Portfolio)
      toast.open("Account created", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <img src="/images/xero-x-cryptio.png" alt="" className={classes.logo} />
      <Box pt={5}>
        <Paper className={classes.container}>
          <Box display="flex" flexDirection="column" padding={3}>
            <TitleWithDivider title="Create an account with Xero" mb={3} />
            <Step2
              onValid={setFormIsValid}
              onNext={handlePostRegisterButtonCallback(onSubmit)}
              firstName={user.firstName}
              lastName={user.lastName}
            />

            <Box mt={3} display="flex" justifyContent="end">
              <PostRegisterButton type="submit" form={registrationFormId(2)} disabled={!isFormValid}>
                Register
              </PostRegisterButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default XeroPostRegister
