import { useTypedController } from "@hookform/strictly-typed"
import { Box, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import ConditionalTooltip from "../../../components/ConditionalTooltip"
import PlainDatePicker from "../../../components/DatePickers/DatePicker"
import { PlainDate } from "../../../components/DateRangePicker"

import { DrawerCategory } from "../../../components/Drawer/DrawerItems"
import { useLoadingButton } from "../../../components/LoadingButton"
import PermissionDisabled from "../../../components/Permission/PermissionDisabled"
import api from "../../../services/api"
import { GetWorkspaceDto } from "../../../services/api/openapi"
import LockSwitch from "./LockSwitch"
import WarningTypography from "../../../components/WarningTypography"
import LoadingSpinner from "../../../components/misc/LoadingSpinner"
import NetworkErrorMessage from "../../../components/misc/NetworkErrorMessage"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface Form {
  isLock: boolean
  date: PlainDate | undefined
}

const formId = "lock-transaction"

const WorkspaceLockTransaction = (props: { workspace: GetWorkspaceDto }): JSX.Element => {
  const { workspace } = props
  const { handleSubmit, control, watch, setValue } = useForm<Form>({
    mode: "onChange",
    defaultValues: {
      isLock: workspace.lock.isEnabled,
      date: workspace.lock.inclusivePlainEndDate
        ? PlainDate.fromBackendDate(workspace.lock.inclusivePlainEndDate)
        : undefined,
    },
  })

  const [CreateButton, handleButtonCallback] = useLoadingButton()
  const TypedController = useTypedController<Form>({ control })
  const toast = useToast()
  const watchAllFields = watch()

  const isDirty = !!(
    (watchAllFields.isLock !== workspace.lock.isEnabled && (watchAllFields.date?.isValid || !watchAllFields.isLock)) ||
    (watchAllFields.isLock &&
      watchAllFields.date?.isValid &&
      watchAllFields.date.toBackendDate() !== workspace.lock.inclusivePlainEndDate)
  )

  const { mutateAsync: updateDateLockTransaction } = api.workspace.useUpdateLock()
  const { mutateAsync: disableLockTransaction } = api.workspace.useDisableLock()

  const canLock = api.workspace.useCheckLock(workspace.id)

  useEffect(() => {
    setValue("isLock", workspace.lock.isEnabled)
  }, [workspace.lock.isEnabled, setValue])

  const onSubmit = async ({ isLock, date }: Form) => {
    try {
      if (!isLock) {
        await disableLockTransaction({ workspaceId: workspace.id })
        toast.open("Unlocking workspace...", { variant: "success" })
      } else {
        if (date) {
          await updateDateLockTransaction({
            workspaceId: workspace.id,
            setLockDateDto: {
              lockDate: date.toBackendDate(),
            },
          })
          toast.open("Locking workspace...", { variant: "success" })
        }
      }
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  if (canLock.isLoading) {
    return (
      <DrawerCategory title="Lock a period">
        <LoadingSpinner />
      </DrawerCategory>
    )
  }
  if (canLock.isError) {
    return (
      <DrawerCategory title="Lock a period">
        <NetworkErrorMessage />
      </DrawerCategory>
    )
  }

  return (
    <DrawerCategory title="Lock a period">
      <Box mt={2} />
      <Typography variant="body2">
        You can choose a date ‘to’/until which you can lock all your previous data, thus ensuring that your cost basis
        and labels don&apos;t change on the transactions of the selected period.
      </Typography>
      {canLock.data?.canLock === false && (
        <WarningTypography mt={2}>
          One or several assets of your workspace are currently blocking the lock feature. Please reach out to the
          support so we can make the feature available on your workspace.
        </WarningTypography>
      )}
      <Box component="form" id={formId} onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
        <Box mt={2} />
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" mt={2} width="100%">
          <TypedController
            name="date"
            rules={{ required: true }}
            render={({ value, onChange }) => (
              <PlainDatePicker disabled={canLock.data?.canLock === false} value={value} onChange={onChange} />
            )}
          />
          <TypedController
            name="isLock"
            rules={{ required: false }}
            render={({ value, onChange }) => (
              <LockSwitch
                isChecked={value}
                disabled={canLock.data?.canLock === false}
                onChange={() => onChange(!value)}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <PermissionDisabled permission="can_modify_lock" action="modify lock">
            <ConditionalTooltip
              tooltipMessage="Lock is already being updated.."
              disabled={workspace.lockStatus.status !== "inactive"}
            >
              <CreateButton
                disabled={!isDirty || workspace.lockStatus.status !== "inactive" || canLock.data?.canLock === false}
                type="submit"
                form={formId}
              >
                Save
              </CreateButton>
            </ConditionalTooltip>
          </PermissionDisabled>
        </Box>
      </Box>
    </DrawerCategory>
  )
}

export default WorkspaceLockTransaction
