import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import BigNumber from "bignumber.js"
import React, { useState } from "react"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"

import { GetPlanDto, GetWorkspaceUsageLimitDto, SubscriptionPayingDetailsPeriodTypeEnum } from "services/api/openapi"
import { limitTypes } from "./constants"

interface Props {
  plan: GetPlanDto
  currentWorkspaceUsage?: GetWorkspaceUsageLimitDto[]
  showOnlyPeriodPrice?: SubscriptionPayingDetailsPeriodTypeEnum
}

const PlanView = ({ plan, currentWorkspaceUsage, showOnlyPeriodPrice }: Props): JSX.Element => {
  const [showDetails, setShowDetails] = useState<boolean>(false)

  return (
    <Box>
      <Typography variant="h3">{plan.name}</Typography>
      <Typography variant="body1" gutterBottom>
        Plan
      </Typography>
      <Box mt={1} />
      <Typography variant="body2">{plan.description}</Typography>
      {!new BigNumber(plan.usdPricePerMonth).eq(0) && !new BigNumber(plan.usdPricePerYear).eq(0) && (
        <>
          {showOnlyPeriodPrice !== "yearly" && (
            <Typography variant="subtitle1">${new BigNumber(plan.usdPricePerMonth).toFixed(2)} USD / Month</Typography>
          )}
          {showOnlyPeriodPrice !== "monthly" && (
            <Typography variant="subtitle1">${new BigNumber(plan.usdPricePerYear).toFixed(2)} USD / Year</Typography>
          )}
        </>
      )}
      {/*
      {plan.features.length > 0 && (
        <>
          <Typography gutterBottom>Features</Typography>
          <FeatureChipList features={plan.features} />
        </>
      )}
      */}
      <Box>
        {plan.hardLimits.length > 0 ? (
          <>
            <ButtonUI
              mode={Mode.OUTLINED}
              iconPosition="right"
              Icon={showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              onClick={() => setShowDetails(!showDetails)}
            >
              Package details
            </ButtonUI>
            <Collapse in={showDetails}>
              <Box mt={2} maxWidth="700px">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Limit</TableCell>
                        <TableCell align="right">Maximum</TableCell>
                        {currentWorkspaceUsage && <TableCell align="right">Workspace usage</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {limitTypes.map((limitType, idx) => {
                        const hardLimit = plan.hardLimits.find((x) => x.limitType === limitType.type)
                        if (!hardLimit) return null
                        return (
                          <TableRow key={idx}>
                            <TableCell>{limitType.name}</TableCell>
                            <TableCell align="right">{hardLimit.maximum}</TableCell>
                            {currentWorkspaceUsage && (
                              <TableCell align="right">
                                {currentWorkspaceUsage.find((x) => x.limitType === hardLimit.limitType)?.count}
                              </TableCell>
                            )}
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Collapse>
          </>
        ) : (
          <Typography> - </Typography>
        )}
      </Box>
    </Box>
  )
}
export default PlanView
