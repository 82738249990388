import { CheckRounded, ErrorRounded, FiberManualRecordRounded, HourglassEmptyRounded } from "@material-ui/icons"
import React, { useContext } from "react"
import TypographyAllowEvent from "components/TypographyAllowEvent"
import { GreenColor, LightGrey, RedColor, TextColor } from "../../../materialTheme"

import {
  MinimalTransactionAccounting,
  MinimalTransactionAccountingStatusEnum,
  MovementLabelDto,
  NewInListMovementDto,
  OtherPartyAliasDto,
  TransactionWalletDto,
} from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import GainView from "../Cells/GainView"
import Date from "../Cells/Date"

import { ColumnsName } from "./ContainerColumn"
import { DeveloppedRowsType } from "./useDeveloppedTransactionPage"
import Label from "../Cells/Label"
import AssetView from "../Cells/AssetView"
import OtherParty from "../Cells/OtherParty"
import { SelectTransactionPageContextValue } from "services/context/selectTransactionPageContext"
import Checkbox from "../Cells/Checkbox"
import CostBasis from "../Cells/CostBasis"
import { SelectedRowsType } from "./useBulkSelectorTransactionPage"
import { CostBasisIds } from ".."
import TooltipUI from "CryptioUI/Tooltip"
import { GetMovementDto } from "../../../services/api/routes/transactions"
import { TypographyVariant } from "CryptioUI/types"

export enum CustomCellDirection {
  IN = "in",
  OUT = "out",
  TRADE = "trade",
  FEE = "fee",
  UNDEFINED = "",
}

export interface DataToDisplay {
  columnName: string
  transactionDate: string
  incoming: NewInListMovementDto | undefined
  outgoing: NewInListMovementDto | undefined
  fee: NewInListMovementDto | undefined
  wallet: TransactionWalletDto
  otherPartiesAliases: OtherPartyAliasDto[]
  labels: Array<MovementLabelDto>
  id: string
  direction: CustomCellDirection
  mainMovement: NewInListMovementDto | undefined
  isComplex?: boolean
  complexData?: {
    feeStatus: string | undefined
    incomingStatus: string | undefined
    outgoingStatus: string | undefined
  } | null
  isDevelopped?: boolean
  usdToFiatRate: string | null
  selectedRows?: SelectedRowsType | undefined
  setSelectedRows?: (row: SelectedRowsType | undefined) => void
  accountingJournal: MinimalTransactionAccounting | null | undefined
  developpedRows: DeveloppedRowsType | undefined
  setDeveloppedRows: (row: DeveloppedRowsType | undefined) => void
  mvtSelected?: SelectTransactionPageContextValue
}

interface UseGenerateCellProps {
  dataToDisplay: DataToDisplay
  openWalletDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  openMovementLabelDrawer: <PassedItemId extends GetMovementDto>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  setCostBasisDrawerTransactionId: (ids: CostBasisIds | null) => void
  openTransactionLabelDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
}

export const accountingSyncStatusToText = (
  accountingSyncStatus: MinimalTransactionAccountingStatusEnum,
  errorMessage?: string | null,
) => {
  switch (accountingSyncStatus) {
    case "unsynced":
      return "This transaction is not synced."
    case "pending":
    case "syncing":
      return "This transaction is being synced..."
    case "synced":
      return "This transaction has been synced."
    case "error":
    default:
      return `An error occurred during the synchronization. ${errorMessage ? ` "${errorMessage}".` : ""}`
  }
}

const useGenerateCell = (params: UseGenerateCellProps) => {
  const {
    dataToDisplay,
    openWalletDrawer,
    openMovementLabelDrawer,
    setCostBasisDrawerTransactionId,
    openTransactionLabelDrawer,
  } = params
  const {
    transactionDate,
    columnName,
    incoming,
    outgoing,
    fee,
    wallet,
    otherPartiesAliases,
    labels,
    id,
    direction,
    mainMovement,
    isComplex = false,
    complexData = undefined,
    isDevelopped = false,
    usdToFiatRate,
    selectedRows,
    setSelectedRows,
    accountingJournal,
    developpedRows,
    setDeveloppedRows,
    mvtSelected,
  } = dataToDisplay
  const { workspace } = useContext(WorkspaceContext)

  switch (columnName) {
    case ColumnsName.CHECKBOX:
      return (
        <Checkbox
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          developpedRows={developpedRows}
          setDeveloppedRows={setDeveloppedRows}
          mvtSelected={mvtSelected}
          mainMovement={mainMovement}
          id={id}
          isDevelopped={isDevelopped}
          isComplex={isComplex}
        />
      )
    case ColumnsName.DATE:
      return <Date isDevelopped={isDevelopped} transactionDate={transactionDate} direction={direction} />
    case ColumnsName.FROMTO:
      return (
        <OtherParty
          wallet={wallet.name}
          direction={mainMovement?.direction ?? direction}
          onClickWallet={() => openWalletDrawer(wallet.id)}
          otherPartiesAliases={otherPartiesAliases}
        />
      )
    case ColumnsName.INCOMING:
      if (isComplex && complexData && complexData.incomingStatus) {
        return (
          <TypographyAllowEvent className="text-grey-400" variant={TypographyVariant.BODY}>
            {complexData.incomingStatus}
          </TypographyAllowEvent>
        )
      }
      return incoming ? (
        <AssetView
          assetId={incoming.assetId}
          assetSymbol={incoming.assetSymbol}
          volume={incoming.volume}
          direction={incoming.direction}
          assetToUsdRate={incoming.assetToUsdRate}
          isFee={incoming.isFee}
          usdToFiatRate={usdToFiatRate}
          alignLeft
        />
      ) : (
        <TypographyAllowEvent variant={TypographyVariant.BODY}>-</TypographyAllowEvent>
      )
    case ColumnsName.OUTGOING:
      if (isComplex && complexData && complexData.outgoingStatus) {
        return (
          <TypographyAllowEvent className="text-grey-400" variant={TypographyVariant.BODY}>
            {complexData.outgoingStatus}
          </TypographyAllowEvent>
        )
      }
      return outgoing ? (
        <AssetView
          assetId={outgoing.assetId}
          assetSymbol={outgoing.assetSymbol}
          volume={outgoing.volume}
          direction={outgoing.direction}
          assetToUsdRate={outgoing.assetToUsdRate}
          isFee={outgoing.isFee}
          usdToFiatRate={usdToFiatRate}
          alignLeft
        />
      ) : (
        <TypographyAllowEvent variant={TypographyVariant.BODY}>-</TypographyAllowEvent>
      )
    case ColumnsName.FEE:
      if (isComplex && complexData && complexData.feeStatus) {
        return (
          <TypographyAllowEvent className="text-grey-400" variant={TypographyVariant.BODY}>
            {complexData.feeStatus}
          </TypographyAllowEvent>
        )
      }
      return fee ? (
        <AssetView
          assetId={fee.assetId}
          assetSymbol={fee.assetSymbol}
          volume={fee.volume}
          direction={fee.direction}
          assetToUsdRate={fee.assetToUsdRate}
          isFee={fee.isFee}
          usdToFiatRate={usdToFiatRate}
          alignLeft
        />
      ) : (
        <TypographyAllowEvent variant={TypographyVariant.BODY}>-</TypographyAllowEvent>
      )
    case ColumnsName.COSTBASIS:
      return (
        <CostBasis
          isComplex={isComplex}
          incomingMovement={incoming}
          outgoingMovement={outgoing}
          feeMovement={fee}
          setCostBasisDrawerTransactionId={setCostBasisDrawerTransactionId}
          direction={direction}
        />
      )
    case ColumnsName.GAINSLOSSES:
      if (
        isComplex ||
        workspace.isCostBasisDirty ||
        mainMovement?.isInternalTransfer === true ||
        !mainMovement?.costBasis ||
        !mainMovement.costBasis.gainFiat ||
        mainMovement.costBasis.mainCostBasisError ||
        workspace.costBasisStatus.status !== "inactive"
      ) {
        return <TypographyAllowEvent variant={TypographyVariant.BODY}>-</TypographyAllowEvent>
      }
      return (
        <GainView
          extended
          outgoingGainFiat={outgoing?.costBasis?.gainFiat ?? null}
          feeGainFiat={fee?.costBasis?.gainFiat ?? null}
        />
      )
    case ColumnsName.LABEL:
      return (
        <Label
          labels={labels}
          openMovementLabelDrawer={openMovementLabelDrawer}
          openTransactionLabelDrawer={openTransactionLabelDrawer}
          movementId={mainMovement?.id ?? ""}
          transactionId={id}
          isComplex={isComplex}
          isDevelopped={isDevelopped}
          mainMovement={mainMovement}
        />
      )
    case ColumnsName.SYNC:
      if (isDevelopped) {
        return <></>
      }
      const status = accountingJournal?.status ?? "unsynced"

      let icon: JSX.Element
      switch (status) {
        case "unsynced":
          icon = <FiberManualRecordRounded htmlColor={LightGrey} />
          break
        case "pending":
        case "syncing":
          icon = <HourglassEmptyRounded htmlColor={TextColor} />
          break
        case "synced":
          icon = <CheckRounded htmlColor={GreenColor} />
          break
        case "error":
        default:
          icon = <ErrorRounded htmlColor={RedColor} />
          break
      }
      return (
        <div className="flex w-full justify-center">
          <TooltipUI
            content={
              <TypographyAllowEvent variant={TypographyVariant.BODY}>
                {accountingSyncStatusToText(status)}
              </TypographyAllowEvent>
            }
          >
            {icon}
          </TooltipUI>
        </div>
      )
  }
}

export default useGenerateCell
