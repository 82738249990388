import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import BannerUI from "CryptioUI/Banner"
import TypographyUI from "CryptioUI/Typography"
import { BannerMode, TypographyVariant } from "CryptioUI/types"

export interface BannerDefiModuleParams {}

const BannerDefiModule = (): JSX.Element => {
  const { location } = useHistory()
  const [isOpen, setIsOpen] = useState(true)

  return (
    <>
      {location.pathname === "/defi" && (
        <BannerUI isOpen={isOpen} mode={BannerMode.INFO} onClose={() => setIsOpen(false)}>
          <div className="flex flex-row gap-4 justify-center items-center">
            <TypographyUI variant={TypographyVariant.BODY}>P&L calculation only on</TypographyUI>
            <a target="_blank" rel="noopener noreferrer" href="https://aave.com/">
              <img src={`/aaveLogo.png`} alt="aave logo" className="w-6 h-6" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://compound.finance/">
              <img src={`/compoundLogo.png`} alt="The Cryptio logo" className="w-6 h-6" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://uniswap.org/">
              <img src={`/uniswapLogo.png`} alt="The Cryptio logo" className="w-6 h-6 rounded-full bg-grey-200" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://lido.fi/">
              <img src={`/lidoLogo.png`} alt="The Cryptio logo" className="w-6 h-6" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.sushi.com/">
              <img src={`/sushiSwapLogo.png`} className="w-6 h-6 rounded-full bg-grey-200" alt="The Cryptio logo" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.convexfinance.com/">
              <img src={`/convexLogo.png`} alt="The Cryptio logo" className="w-6 h-6 rounded-full" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://curve.fi/#/ethereum/swap">
              <img src={`/curveLogo.png`} alt="The Cryptio logo" className="w-6 h-6" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://makerdao.com/en/">
              <img src={`/makerLogo.png`} alt="The Cryptio logo" className="w-6 h-6" />
            </a>
          </div>
        </BannerUI>
      )}
    </>
  )
}

export default BannerDefiModule
