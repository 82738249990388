import { TextField, TextFieldProps } from "@material-ui/core"
import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from "@material-ui/lab"
import React from "react"

interface Props<TEnum extends string, DisableClearable extends boolean, Multiple extends boolean>
  extends Omit<
    AutocompleteProps<TEnum, Multiple, DisableClearable, false>,
    "getOptionLabel" | "options" | "value" | "onChange" | "renderInput" | "autoComplete" | "includeInputInList"
  > {
  id?: string
  value: Multiple extends true
    ? DisableClearable extends false
      ? TEnum[] | null
      : TEnum[]
    : DisableClearable extends false
    ? NonNullable<TEnum>
    : TEnum | null
  onChange: (newValue: DisableClearable extends true ? NonNullable<TEnum> : TEnum | null) => void
  renderProps?: Exclude<TextFieldProps, AutocompleteRenderInputParams>
  placeholder?: string
}

function SimpleAutocompleteFactory<TEnum extends string>(
  prettyMap: Record<TEnum, string>,
): <DisableClearable extends boolean, Multiple extends boolean>(
  props: Props<TEnum, DisableClearable, Multiple>,
) => JSX.Element {
  const options = Object.keys(prettyMap) as TEnum[]

  return function Component<DisableClearable extends boolean, Multiple extends boolean>({
    onChange,
    renderProps,
    value,
    ...props
  }: Props<TEnum, DisableClearable, Multiple>): JSX.Element {
    return (
      <Autocomplete<TEnum, Multiple, DisableClearable, false>
        {...props}
        getOptionLabel={(option) => prettyMap[option]}
        options={options}
        autoComplete
        includeInputInList
        // @ts-ignore: todo: type
        value={value}
        // @ts-ignore
        onChange={(_, newValue) => onChange(newValue)}
        renderInput={(params) => <TextField {...params} {...renderProps} placeholder={props.placeholder} />}
      />
    )
  }
}

export default SimpleAutocompleteFactory
