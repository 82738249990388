import { IconButton, InputAdornment, TextField, TextFieldProps } from "@material-ui/core"
import { ClearRounded } from "@material-ui/icons"
import React, { useEffect, useState } from "react"

type Props = Omit<TextFieldProps, "onChange" | "value"> & {
  value?: string
  setValue: (newValue: string) => void
  clearable?: boolean
}

const DebouncedTextField = ({ value = "", setValue, ...textFielProps }: Props): JSX.Element => {
  const [typingInput, setTypingInput] = useState(value)

  useEffect(() => {
    if (typingInput !== value) {
      const timeOutId = setTimeout(() => setValue(typingInput), 500)
      return () => clearTimeout(timeOutId)
    }
  }, [typingInput, setValue, value])

  return (
    <TextField
      value={typingInput}
      {...textFielProps}
      color="secondary"
      onChange={(x) => setTypingInput(x.target.value)}
      InputProps={{
        endAdornment: (
          /* clearable && typingInput &&  */ <InputAdornment position="end">
            <IconButton onClick={() => setTypingInput("")} style={{ padding: 0 }}>
              <ClearRounded color="disabled" fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default DebouncedTextField
