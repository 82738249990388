import React from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import * as Tooltip from "@radix-ui/react-tooltip"

import CookiesBanner from "./components/CookieBanner"
import AppLayout from "./components/layout"
import SceneRoute from "./components/SceneRoute"
import VersionChecker from "./components/VersionChecker"
import { URLS } from "./routes"
import AccountingLoginScene from "./scenes/Accounting/Login"
import ConfirmEmailScene from "./scenes/ConfirmEmail"
import ForgotPasswordScene from "./scenes/ForgotPassword"
import LoginScene from "./scenes/Login"
import RegisterScene from "./scenes/Register"
import XeroSingleSignUpScene from "./scenes/Register/XeroSingleSignUp"
import ResetPasswordScene from "./scenes/ResetPassword"
import { GDPRContextProvider } from "./services/context/gdprContext"
import { ToastProvider } from "CryptioUI/Toaster"

type LocationState =
  | {
      redirect: string | undefined
    }
  | undefined

const PublicApp = (): JSX.Element => {
  const { pathname, state, search } = useLocation<LocationState>()

  const redirect: string | undefined = state?.redirect

  return (
    <GDPRContextProvider>
      {/* <div */}
      {/*   style={{ */}
      {/*     padding: 20, */}
      {/*     position: "absolute", */}
      {/*     bottom: 0, */}
      {/*     left: 0, */}
      {/*     width: "100%", */}
      {/*     zIndex: 1202, */}
      {/*     backgroundColor: "#ba000d", */}
      {/*     color: "white", */}
      {/*     fontSize: 16, */}
      {/*     textAlign: "center", */}
      {/*   }} */}
      {/* > */}
      {/*   We are aware of an incident that prevents users from accessing Cryptio. We are currently working on resolving */}
      {/*   the issue. */}
      {/* </div> */}
      <CookiesBanner />
      <ToastProvider>
        <Tooltip.Provider>
          <AppLayout>
            <Switch>
              <Route
                path={[URLS.Accounting.OAuth.Login, URLS.Accounting.OAuth.Register]}
                component={AccountingLoginScene}
              />
              <Route path={URLS.Account.SingleSignUp} component={XeroSingleSignUpScene} />
              <SceneRoute
                exact
                path={URLS.Account.Register}
                component={RegisterScene}
                title="Register"
                description="Institutional-grade accounting, audit, and tax software for your crypto assets."
              />
              <SceneRoute
                exact
                path={URLS.Account.Login}
                component={LoginScene}
                title="Login"
                description="Customer Login for Cryptio Accounting Software"
              />
              <SceneRoute
                exact
                path={URLS.Account.ForgottenPassword}
                component={ForgotPasswordScene}
                title="Forgot password"
                description="Reset your password and recover access to you Cryptio account"
              />
              <Route path={URLS.Account.ResetForgottenPassword} component={ResetPasswordScene} />
              <Route path={URLS.Account.ConfirmEmail} component={ConfirmEmailScene} />
              <Redirect
                to={{
                  pathname: URLS.Account.Login,
                  search: (() => {
                    if (redirect && redirect.includes("logout")) {
                      return redirect
                    }

                    if (pathname.length > 1) {
                      if (search) {
                        const params = new URLSearchParams(search)

                        params.delete("redirect")
                        return `${params.toString()}&redirect=${pathname}`
                      }

                      return `?redirect=${pathname}`
                    }

                    return search
                  })(),
                }}
              />
            </Switch>
            <VersionChecker />
          </AppLayout>
        </Tooltip.Provider>
      </ToastProvider>
    </GDPRContextProvider>
  )
}

export default PublicApp
