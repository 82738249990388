import { useTypedController } from "@hookform/strictly-typed"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { Delete as DeleteIcon } from "@material-ui/icons"
import { assetProviderList, AssetProviderType } from "pure-shared"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import BaseTable from "components/Table"
import { IExtendedDataTableColumn } from "components/Table/interface"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { BackOfficeAssetProviderDto, GetPublicAssetDto } from "services/api/openapi"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface AssetProvidersProps {
  asset: GetPublicAssetDto
}

interface ProviderCreationDialogProps {
  asset: GetPublicAssetDto
  initial?: BackOfficeAssetProviderDto
  availableProviders: AssetProviderType[]
  onCompleted: () => void
}

const providerCreationFormId = "create-asset-provider"

interface CreateAssetProviderForm {
  provider: AssetProviderType
  providerKey: string
  priority: string
}

function ProviderCreationDialog({ asset, initial, availableProviders, onCompleted }: ProviderCreationDialogProps) {
  const { handleSubmit, control, formState, reset } = useForm<CreateAssetProviderForm>({
    mode: "onChange",
    defaultValues: {
      provider: initial?.provider,
    },
  })
  const TypedController = useTypedController<CreateAssetProviderForm>({ control })
  const [CreateButton, handleButtonCallback] = useLoadingButton()
  const { mutateAsync: createAssetProvider } = api.backOffice.asset.useCreateAssetProvider()
  const actionWord = initial ? "Update" : "Create"
  const toast = useToast()

  const onSubmit = async ({ priority, provider, providerKey }: CreateAssetProviderForm) => {
    try {
      await createAssetProvider({
        assetId: asset.id,
        backOfficeCreateAssetProviderQuery: {
          provider: initial?.provider ?? provider,
          providerKey,
          priority: parseInt(priority),
        },
      })
      reset()
      onCompleted()
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  return (
    <DialogContent>
      <DialogTitle disableTypography>
        <Typography variant="h1" component="h2" style={{ textAlign: "center" }}>
          {actionWord}
          {initial ? " " + initial.provider : ""} provider for {asset.name}{" "}
        </Typography>
      </DialogTitle>
      <form id={providerCreationFormId} onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
        {!initial && (
          <>
            <FormLabel htmlFor="provider-select">Provider name</FormLabel>
            <TypedController
              name="provider"
              rules={{ required: true }}
              render={(props) => (
                <Select fullWidth id="provider-select" {...props}>
                  {availableProviders.map((p) => (
                    <MenuItem key={p} value={p}>
                      {p}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </>
        )}

        <Box mt={2}>
          <FormLabel htmlFor="provider-key-field">Provider key</FormLabel>
          <TypedController
            name="providerKey"
            rules={{ required: true }}
            defaultValue={initial?.providerKey}
            render={(props) => <TextField {...props} id="provider-key-field" fullWidth margin="normal" />}
          />
        </Box>

        <Box mt={2}>
          <FormLabel htmlFor="priority-field">Priority</FormLabel>
          <TypedController
            name="priority"
            rules={{ required: true, validate: (v) => !isNaN(parseInt(v)) }}
            defaultValue={initial?.priority.toString()}
            render={(props) => <TextField {...props} type="number" id="provider-key-field" fullWidth margin="normal" />}
          />
        </Box>
      </form>
      <DialogActions>
        <CreateButton disabled={!formState.isValid} type="submit" form={providerCreationFormId}>
          {actionWord}
        </CreateButton>
      </DialogActions>
    </DialogContent>
  )
}

export function AssetProviders({ asset }: AssetProvidersProps) {
  const providers = api.backOffice.asset.useAssetProviders(asset.id)
  const { mutateAsync: deleteAssetProvider } = api.backOffice.asset.useDeleteAssetProvider()
  const [creationDialogIsOpen, setCreationDialogIsOpen] = useState<boolean>(false)
  const [creationDialogInitialValue, setCreationDialogInitialValue] = useState<BackOfficeAssetProviderDto | undefined>()

  if (providers.isError) return <NetworkErrorMessage small={false} additionalData={providers} />
  if (providers.isLoading || providers.data === undefined) return <LoadingSpinner />

  const unusedProviders = assetProviderList.filter((p) => providers.data.providers.every((o) => o.provider !== p))

  const onDeleteProvider = async (provider: AssetProviderType) => {
    await deleteAssetProvider({
      assetId: asset.id,
      provider,
    })
  }

  const columns: IExtendedDataTableColumn<BackOfficeAssetProviderDto>[] = [
    {
      name: "Provider",
      selector: "provider",
    },
    {
      name: "Provider key",
      selector: "providerKey",
    },
    {
      name: "Priority",
      selector: "priority",
    },
    {
      name: "Actions",
      selector: "delete",
      format: function DeleteProvider(row) {
        const [DeleteButton, handleButtonCallback] = useLoadingButton()
        return (
          <DeleteButton mode={Mode.TEXTONLY} onClick={handleButtonCallback(() => onDeleteProvider(row.provider))}>
            <DeleteIcon />
          </DeleteButton>
        )
      },
    },
  ]
  const creationDialog = (
    <Dialog open={creationDialogIsOpen} onClose={() => setCreationDialogIsOpen(false)}>
      <ProviderCreationDialog
        asset={asset}
        initial={creationDialogInitialValue}
        availableProviders={unusedProviders}
        onCompleted={() => setCreationDialogIsOpen(false)}
      />
    </Dialog>
  )

  return (
    <DrawerCategory title="Providers">
      <DrawerFormSection name="Click a row to edit">
        {creationDialog}
        <BaseTable
          columns={columns}
          items={providers.data.providers}
          onRowClicked={(row) => {
            setCreationDialogInitialValue(row)
            setCreationDialogIsOpen(true)
          }}
        />
        <Box mt={2}>
          <ButtonUI
            onClick={() => {
              setCreationDialogInitialValue(undefined)
              setCreationDialogIsOpen(true)
            }}
          >
            Add
          </ButtonUI>
        </Box>
      </DrawerFormSection>
    </DrawerCategory>
  )
}
