import { Box, IconButton, Link, Typography } from "@material-ui/core"
import { ReactComponent as Share } from "CryptioUI/assets/icons/share.svg"
import BigNumber from "bignumber.js"
import dayjs from "dayjs"
import numbro from "numbro"
import { prettyEmptyOrNull, costBasisErrorStatusToString } from "pure-shared"
import React, { useContext, useMemo, useState, useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"

import LoadingSpinner from "../../misc/LoadingSpinner"
import NetworkErrorMessage from "../../misc/NetworkErrorMessage"
import BaseTable from "../../Table"
import { IExtendedDataTableColumn } from "../../Table/interface"
import { URLS } from "../../../routes"
import { widgetTableStyle } from "scenes/Dashboard/widgets/widgetTableStyle"
import api from "services/api"
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from "services/constants"
import { ThemeContext } from "services/context/themeContext"
import { WorkspaceContext } from "services/context/workspaceContext"
import { getSymbol } from "services/utils/AssetSymbols"
import bigToFormatString from "services/utils/bigToString"
import toFilterElement from "services/utils/toFilterElement"
import { mockInternalTransferLabel } from "../TransactionLabelDrawer/NonTaxabelLabelSelector"
import { iconStyleBlack } from "CryptioUI/Utilities/config"
import { GetMovementDto } from "../../../services/api/routes/transactions"

interface Props {
  movementId: string
  onClose: () => void
  openMovementDrawer: <PassedItemId extends GetMovementDto>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
}

type TableTransactionTaxLineType = "Outgoing" | "Fee" | "Borrow" | "Repay"
type TableTransactionTaxLine = {
  id: string
  type: TableTransactionTaxLineType
  consumedMovementId: string | null
  transactionDate: string
  consumedVolume: string
  oldAssetToFiatRate: BigNumber | null
  currentAssetToFiatRate: BigNumber | null
}

const CostBasisMvt = ({ movementId, onClose, openMovementDrawer }: Props): JSX.Element => {
  const { workspace } = useContext(WorkspaceContext)
  const muiThemeContext = useContext(ThemeContext)
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(DEFAULT_ROWS_PER_PAGE)

  const movement = api.transaction.useMovement({ movementId })
  const movementTaxlines = api.transaction.useMovementTaxLines({ movementId, page, limit })

  useEffect(() => {
    if (page || limit) {
      movementTaxlines.refetch()
    }
  }, [page, limit, movementTaxlines])
  const userFiatSymbol = getSymbol(workspace.defaultCurrencySymbol)
  const taxLines = useMemo<TableTransactionTaxLine[]>(() => {
    if (!movement || movement.isError || movement.isLoading || movement.data === undefined || !movement.data.costBasis)
      return []
    // Must add some prefix for key (array) of reactjs
    const taxLineType: TableTransactionTaxLineType =
      movement.data.direction === "in"
        ? "Repay" // Must be Repay
        : movement.data.isFee
        ? "Fee"
        : "Outgoing"

    const currentAssetToFiatRate =
      movement.data.assetToUsdRate && movement.data.usdToFiatRate
        ? new BigNumber(movement.data.assetToUsdRate).multipliedBy(movement.data.usdToFiatRate)
        : null

    if (
      !movementTaxlines ||
      movementTaxlines.isError ||
      movementTaxlines.isLoading ||
      movementTaxlines.data === undefined
    )
      return []
    const lines: TableTransactionTaxLine[] =
      movementTaxlines.data?.data.map(
        ({ consumedMovementId, consumedVolume, transactionDate, assetToUsdRate, usdToFiatRate }) => ({
          id: `cb-${consumedMovementId}`,
          type: taxLineType,
          consumedMovementId,
          consumedVolume,
          transactionDate,
          oldAssetToFiatRate:
            assetToUsdRate && usdToFiatRate ? new BigNumber(assetToUsdRate).multipliedBy(usdToFiatRate) : null,
          currentAssetToFiatRate,
        }),
      ) ?? []

    if (
      movement.data.costBasis.borrow.borrowedVolume &&
      !new BigNumber(movement.data.costBasis.borrow.borrowedVolume).isZero()
    ) {
      lines.push({
        id: `cb-borrow`,
        type: "Borrow",
        consumedMovementId: null,
        consumedVolume: movement.data.costBasis.borrow.borrowedVolume,
        transactionDate: movement.data.transactionDate,
        oldAssetToFiatRate: null,
        currentAssetToFiatRate,
      })
    }

    return lines
  }, [movement, movementTaxlines])

  const columns = useMemo<IExtendedDataTableColumn<TableTransactionTaxLine>[]>(
    () => [
      {
        name: "Date",
        selector: "transactionDate",
        format: function formatX(row) {
          if (!row.transactionDate) {
            return <Typography variant="body1">-</Typography>
          }
          return (
            <Box>
              <Typography variant="body1">{dayjs(row.transactionDate).tz().format("L")}</Typography>
              <Typography variant="subtitle1">{dayjs(row.transactionDate).tz().format("LTS")}</Typography>
            </Box>
          )
        },
      },
      {
        name: "Volume",
        selector: "volume",
        format: (row) => bigToFormatString(new BigNumber(row.consumedVolume)),
      },
      {
        name: "Purchase unit price",
        selector: "rate",
        format: (row) => {
          if (!row.oldAssetToFiatRate) {
            return "Missing rate"
          }
          return `${userFiatSymbol}${bigToFormatString(row.oldAssetToFiatRate)}`
        },
      },
      {
        name: "Cost basis",
        selector: "cb",
        format: (row) => {
          if (row.type === "Borrow") return "-"
          if (!row.oldAssetToFiatRate) {
            return "-"
          }
          const costBasisFiat = row.oldAssetToFiatRate.multipliedBy(row.consumedVolume)
          return `${userFiatSymbol}${bigToFormatString(costBasisFiat)}`
        },
      },
      {
        name: "Sell unit price",
        selector: "rate",
        format: (row) => {
          if (!row.currentAssetToFiatRate) {
            return "Missing rate"
          }
          return `${userFiatSymbol}${bigToFormatString(row.currentAssetToFiatRate)}`
        },
      },
      {
        name: "Proceeds",
        selector: "pr",
        format: (row) => {
          if (row.type === "Borrow") return "-"
          if (!row.currentAssetToFiatRate) {
            return "-"
          }
          const transactionValueFiat = row.currentAssetToFiatRate.multipliedBy(row.consumedVolume)
          return `${userFiatSymbol}${bigToFormatString(transactionValueFiat)}`
        },
      },
      {
        name: "Difference",
        selector: "rate",
        format: ({ currentAssetToFiatRate, oldAssetToFiatRate }) => {
          if (!currentAssetToFiatRate || !oldAssetToFiatRate || currentAssetToFiatRate.isZero()) {
            return "-"
          }
          const diff = currentAssetToFiatRate.minus(oldAssetToFiatRate)
          const percent = diff.dividedBy(oldAssetToFiatRate).multipliedBy(100)
          return `${percent.isNegative() ? "" : "+"}${percent.toFixed(2)}%`
        },
      },
      {
        name: "Action",
        width: "100px",
        cell: function Action({ consumedMovementId }) {
          if (consumedMovementId) {
            return (
              <IconButton onClick={() => openMovementDrawer({ movementId: consumedMovementId })}>
                <Share className={iconStyleBlack} />
              </IconButton>
            )
          }
          return <Typography>-</Typography>
        },
      },
    ],
    [openMovementDrawer, userFiatSymbol],
  )

  if (movement.isError || movementTaxlines.isError) return <NetworkErrorMessage additionalData={movement} />

  if (
    movement.isLoading ||
    movement.data === undefined ||
    movementTaxlines.isLoading ||
    movementTaxlines.data === undefined
  )
    return <LoadingSpinner />

  // Not cost basis NTE but label NTE
  if (
    movement.data.costBasis?.isNte !== true &&
    !movement.data.isFee &&
    movement.data.labels.some((l) => l.isNonTaxable)
  ) {
    return <Typography variant="body2">Non taxable event doesn&apos;t have a cost basis</Typography>
  }

  if (!movement.data.costBasis) {
    // Should not happen
    return <Typography variant="body2">Cost basis not computed</Typography>
  }

  const CostBasisError = () => {
    if (
      !movement.data.costBasis ||
      !movement.data.costBasis.errorStatuses ||
      (movement.data.costBasis.errorStatuses.includes("impacted_by_missing_price") === false &&
        movement.data.costBasis.errorStatuses.includes("missing_usd_rate") === false)
    )
      return <></>
    const asset = {
      id: movement.data.assetId,
      name: prettyEmptyOrNull(movement.data.assetSymbol),
    }
    return (
      <Box mt={4}>
        <Typography>
          Some movements with asset <b>{asset.name}</b> are not priced so we can&apos;t compute the cost basis.{" "}
          <Link
            component={RouterLink}
            to={URLS.Transactions.getUrl({
              filters: [
                { type: "assets", value: [toFilterElement(asset)], isNot: false },
                { type: "valuation_status", value: "noValuation", isNot: false },
              ],
            })}
            onClick={onClose}
            underline="always"
          >
            Click here
          </Link>{" "}
          to filter by movements with this asset and a missing price.
        </Typography>
      </Box>
    )
  }

  const formatFiatValue = (number: string | BigNumber) =>
    numbro(new BigNumber(number).toFixed(4)).format({ prefix: userFiatSymbol ?? "$", thousandSeparated: true })

  const totalCostBasis = (() => {
    if (movement.data.isInternalTransfer) return mockInternalTransferLabel.name
    if (!movement.data.costBasis) return null
    if (movement.data.costBasis.errorStatuses) {
      return costBasisErrorStatusToString(
        movement.data.costBasis.errorStatuses,
        movement.data.assetSymbol,
        movement.data.volume,
      )
    }
    if (movement.data.direction === "in" && !movement.data.costBasis.isRepay) return null
    return formatFiatValue(new BigNumber(movement.data.costBasis.costBasisFiat ?? 0))
  })()

  const rowStyling = "flex flex-row w-[48%] justify-between p-3 border-b border-grey-200"

  const costBasisInfo = (
    <>
      {movement.data.costBasis && (
        <div className="flex flex-row-reverse justify-between mt-4 flex-wrap">
          <div className={rowStyling}>
            <Typography variant="body1">
              <b>Portfolio {movement.data.assetSymbol} Inventory: </b>
            </Typography>
            <Typography variant="body1">{formatFiatValue(movement.data.costBasis.workspaceInventoryFiat)}</Typography>
          </div>
          <div className={rowStyling}>
            <Typography variant="body1">
              <b>Portfolio {movement.data.assetSymbol} Balance:</b>
            </Typography>
            <Typography variant="body1">
              {new BigNumber(movement.data.costBasis.workspaceBalance).toString()}
            </Typography>
          </div>
          <div className={rowStyling}>
            <Typography variant="body1">
              <b>Wallet {movement.data.assetSymbol} Inventory: </b>
            </Typography>
            <Typography variant="body1">{formatFiatValue(movement.data.costBasis.walletInventoryFiat)}</Typography>
          </div>
          {movement.data.costBasis.wac && (
            <div className={rowStyling}>
              <Typography variant="body1">
                <b>{movement.data.assetSymbol} Borrow Wac Per Unit: </b>
              </Typography>
              <Typography variant="body1">
                {formatFiatValue(movement.data.costBasis.wac.borrowWacPerUnitFiat)}
              </Typography>
            </div>
          )}
          <div className={rowStyling}>
            <Typography variant="body1">
              <b>Wallet {movement.data.assetSymbol} Balance: </b>
            </Typography>
            <Typography variant="body1">{new BigNumber(movement.data.costBasis.walletBalance).toString()}</Typography>
          </div>
          {movement.data.costBasis.wac && (
            <div className={rowStyling}>
              <Typography variant="body1">
                <b>{movement.data.assetSymbol} Wac Per Unit: </b>
              </Typography>
              <Typography variant="body1">{formatFiatValue(movement.data.costBasis.wac.wacPerUnitFiat)}</Typography>
            </div>
          )}
          {movement.data.costBasis.isRepay && movement.data.costBasis.borrow.borrowCostBasisFiat && (
            <div className={rowStyling}>
              <Typography variant="body1">
                <b>Total borrow cost basis:</b>
              </Typography>
              <Typography variant="body1">
                {" "}
                {formatFiatValue(movement.data.costBasis.borrow.borrowCostBasisFiat)}
              </Typography>
            </div>
          )}
          {totalCostBasis && (
            <div className={rowStyling}>
              <Typography variant="body1">
                <b>Total cost basis:</b>
              </Typography>
              <Typography variant="body1"> {totalCostBasis}</Typography>
            </div>
          )}
          {movement.data.costBasis.isRepay && movement.data.costBasis.borrow.borrowGainFiat && (
            <div className={rowStyling}>
              <Typography variant="body1">
                <b>Total borrow gain:</b>
              </Typography>
              <Typography variant="body1">{formatFiatValue(movement.data.costBasis.borrow.borrowGainFiat)}</Typography>
            </div>
          )}
          {movement.data.costBasis.gainFiat && (
            <div className={rowStyling}>
              <Typography variant="body1">
                <b>Total gain:</b>
              </Typography>
              <Typography variant="body1">{formatFiatValue(movement.data.costBasis.gainFiat)}</Typography>
            </div>
          )}
        </div>
      )}
      <CostBasisError />
    </>
  )

  if (workspace.costBasisAlgorithmName.startsWith("wac")) {
    return (
      <Box p={2} m={2}>
        <Typography>
          This workspace is using the WAC calculation methodology. Hence there are no tax lines to display. Please{" "}
          <Link component={RouterLink} to={URLS.Reports.basePath} underline="always">
            generate a cost basis report
          </Link>{" "}
          instead.
        </Typography>
        <Box mt={2}>{costBasisInfo}</Box>
      </Box>
    )
  }

  return (
    <>
      {taxLines.length > 0 && (
        <BaseTable
          columns={columns}
          items={taxLines}
          fixedScroll="400px"
          onChangePage={(curPage: number) => setPage(curPage)}
          onChangeRowsPerPage={(curlimit: number) => setLimit(curlimit)}
          paginationRowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          paginationTotalRows={movementTaxlines.data.totalCount}
          paginationPerPage={DEFAULT_ROWS_PER_PAGE}
          paginationServer
          pagination={movementTaxlines.data.totalCount > DEFAULT_ROWS_PER_PAGE}
          customStyle={widgetTableStyle(muiThemeContext.type)}
          keyField="id"
        />
      )}
      <Box mt={1}>{costBasisInfo}</Box>
    </>
  )
}

export default CostBasisMvt
