import { Box, IconButton, Paper, Typography } from "@material-ui/core"
import dayjs from "dayjs"
import React, { useCallback, useContext, useMemo } from "react"
import { IDataTableColumn } from "react-data-table-component"

// import SimpleAutocompleteFactory from "components/Autocomplete/SimpleAutocompleteFactory"
import BaseContainer from "components/Container"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDrawer from "components/misc/useDrawer"
import { GenericSorting } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import TableTitleWithTotalCount from "components/Table/TableTitleWithTotalCount"
import api from "services/api"
import {
  BackOfficeBulkUpdateWorkspaceDto,
  BackOfficeGetFullPlanDto,
  BackOfficeGetMinimalWorkspaceDto,
  BackOfficeGetWorkspacesLimitationTypeEnum,
  BackOfficeGetWorkspacesRequest,
  BackOfficeGetWorkspacesSortByEnum,
} from "services/api/openapi"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import BackOfficeWorkspaceFilter from "./filters/BackOfficeWorkspaceFilter"
import { StarBorderRounded, StarRounded } from "@material-ui/icons"
import BackOfficeView from "../../../components/misc/BackOfficeView"
import BackOfficeWorkspaceUpdateButton from "./BackOfficeWorkspaceUpdateButton"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"
import { WorkspaceContext } from "services/context/workspaceContext"
import ButtonUI from "CryptioUI/Button"
import { ButtonSize, Mode } from "CryptioUI/types"

export const defaultBackOfficeWorkspaceSorting: GenericSorting<BackOfficeGetWorkspacesSortByEnum> = {
  sortDirection: "descending",
  sortBy: "created_at",
}

type UsageType = "percent" | "count"
export type BackOfficeParameters = Omit<
  BackOfficeGetWorkspacesRequest,
  "minimumTransaction" | "minimumUser" | "planId" | "limitationType"
> & {
  minimumTransaction: string | undefined
  minimumUser: string | undefined
  plan: BackOfficeGetFullPlanDto | undefined
  limitation:
    | {
        type: BackOfficeGetWorkspacesLimitationTypeEnum
        usageType: UsageType
        maxRemainingUsage: string | undefined
      }
    | undefined
}

// const UsageTypeAutocomplete = SimpleAutocompleteFactory<UsageType>({
//   percent: "Percent",
//   count: "Count",
// })
// const WorkspacesLimitationTypeAutocomplete =
//   SimpleAutocompleteFactory<BackOfficeGetWorkspacesLimitationTypeEnum>({
//     csv_wallet: "Csv wallet",
//     chain_wallet: "Chain wallet",
//     custody_wallet: "Custody wallet",
//     exchange_wallet: "Exchange wallet",
//     transaction: "Transaction",
//     user_per_workspace: "User per workspace",
//     role_per_workspace: "Role per workspace",
//     report: "Report",
//     label: "Label",
//     invoice: "Invoice",
//     contact: "Contact",
//     chart_account: "Chart account",
//   })

export const defaultBackOfficeGetWorkspaceParams: BackOfficeParameters = {
  page: 0,
  limit: 10,
  workspaceId: undefined,
  query: undefined,
  minimumUser: undefined,
  minimumTransaction: undefined,
  subscriptionType: undefined,
  plan: undefined,
  limitation: undefined,
}

export const backOfficeWorkspaceHasAnyFilter = (filters: Partial<BackOfficeParameters>) =>
  Boolean(
    filters.workspaceId ||
      filters.query ||
      filters.minimumUser ||
      filters.minimumTransaction ||
      filters.subscriptionType ||
      filters.plan ||
      filters.limitation,
  )

const BackOfficeWorkspaceScene = (): JSX.Element => {
  const toast = useToast()
  const [workspaceDrawer, openWorkspaceDrawer] = useDrawer("backoffice-workspace")
  const { params, setLimit, setPage, setSorting, setParams } = usePaginatedParams<
    Partial<BackOfficeParameters>,
    BackOfficeGetWorkspacesSortByEnum
  >(defaultBackOfficeGetWorkspaceParams, defaultBackOfficeWorkspaceSorting)
  const workspaceCtx = useContext(WorkspaceContext)

  const columns: IDataTableColumn<BackOfficeGetMinimalWorkspaceDto>[] = [
    {
      name: "Name",
      selector: "name",
    },
    {
      name: "Owner",
      selector: "ownerUserEmail",
    },
    {
      name: "Subscription",
      selector: "subscriptionType",
      format: function lalala(row) {
        return (
          <Box>
            <Typography variant="h5">{row.subscriptionType}</Typography>
            <Typography variant="body2">From {dayjs(row.subscriptionPeriodFrom).tz().format("L LT")}</Typography>
            {row.subscriptionType === "paying" && row.subscriptionPeriodTo && (
              <Typography variant="body2">Ends on {dayjs(row.subscriptionPeriodTo).tz().format("L LT")}</Typography>
            )}
            {row.subscriptionType === "trial" && row.subscriptionPeriodTo && !row.subscriptionIsExpired && (
              <Typography variant="body2">
                There are {dayjs(row.subscriptionPeriodTo).fromNow(true)} remaining in the trial.
              </Typography>
            )}
            {row.subscriptionType === "trial" && row.subscriptionIsExpired && row.subscriptionPeriodTo && (
              <Typography variant="body2">
                Ended on {dayjs(row.subscriptionPeriodTo).tz().format("L LT")}
                <br />
                The subscription is expired
              </Typography>
            )}
          </Box>
        )
      },
    },
    {
      name: "Actions",
      minWidth: "150px",
      cell: function formatActions(row) {
        const currentWorkspace = row.id === workspaceCtx.workspace.id
        return (
          <>
            <div className="mr-2">
              <ButtonUI
                mode={currentWorkspace ? Mode.CONTAINED : Mode.DEFAULT}
                size={ButtonSize.SM}
                onClick={() => {
                  if (!currentWorkspace) workspaceCtx.changeWorkspace(row.id)
                }}
              >
                {currentWorkspace ? "SELECTED" : "SWITCH"}
              </ButtonUI>
            </div>
            <IconButton onClick={() => toggleBookmark(row)}>
              {row.isBookmarked ? <StarRounded /> : <StarBorderRounded />}
            </IconButton>
          </>
        )
      },
    },
  ]

  const workspaces = api.backOffice.workspace.useWorkspaces({
    page: params.page,
    limit: params.limit,
    workspaceId: params.workspaceId || undefined,
    query: params.query,
    subscriptionType: params.subscriptionType,
    planId: params.plan?.id,
    minimumTransaction: params.minimumTransaction !== undefined ? Number(params.minimumTransaction) : undefined,
    minimumUser: params.minimumUser !== undefined ? Number(params.minimumUser) : undefined,
    hasAccountingIntegration: params.hasAccountingIntegration,
    accountingIntegration: params.accountingIntegration,
    maxRemainingUsageCount:
      params.limitation?.usageType === "count" ? Number(params.limitation.maxRemainingUsage) : undefined,
    maxRemainingUsagePercent:
      params.limitation?.usageType === "percent" ? Number(params.limitation.maxRemainingUsage) : undefined,
    limitationType: params.limitation?.type,
    freeTrialMaxDaysRemaining: params.freeTrialMaxDaysRemaining,
  })

  const filtersToUpdateQuery = useMemo<BackOfficeBulkUpdateWorkspaceDto>(() => {
    return {
      workspaceId: params.workspaceId || undefined,
      query: params.query,
      subscriptionType: params.subscriptionType,
      planId: params.plan?.id,
      minimumTransaction: params.minimumTransaction !== undefined ? Number(params.minimumTransaction) : undefined,
      minimumUser: params.minimumUser !== undefined ? Number(params.minimumUser) : undefined,
      hasAccountingIntegration: params.hasAccountingIntegration,
      accountingIntegration: params.accountingIntegration,
      maxRemainingUsageCount:
        params.limitation?.usageType === "count" ? Number(params.limitation.maxRemainingUsage) : undefined,
      maxRemainingUsagePercent:
        params.limitation?.usageType === "percent" ? Number(params.limitation.maxRemainingUsage) : undefined,
      limitationType: params.limitation?.type,
      freeTrialMaxDaysRemaining: params.freeTrialMaxDaysRemaining,
    }
  }, [params])

  const { mutateAsync: toggleBookmarkMutation } = api.backOffice.workspace.useBackOfficeToggleBookmark()

  const toggleBookmark = useCallback(
    async (workspace: BackOfficeGetMinimalWorkspaceDto) => {
      try {
        await toggleBookmarkMutation({ workspaceId: workspace.id })
        toast.open(`Workspace ${workspace.isBookmarked ? "removed from" : "added to"} bookmarks`, {
          variant: "success",
        })
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [toggleBookmarkMutation, toast],
  )

  if (workspaces.isError) return <NetworkErrorMessage additionalData={workspaces} />

  if (workspaces.isLoading || workspaces.data === undefined) return <LoadingSpinner />

  return (
    <BaseContainer>
      {workspaceDrawer}

      <Box mb={2} display="flex" justifyContent="space-between" alignItems="flex-end">
        <TableTitleWithTotalCount title="Workspaces" qualifier="Workspace" totalCount={workspaces.data.totalCount} />
        <Box display="flex" alignItems="flex-end">
          {workspaces.data && (
            <BackOfficeView techAdminOnly>
              <BackOfficeWorkspaceUpdateButton workspaces={workspaces.data} filters={filtersToUpdateQuery} />
            </BackOfficeView>
          )}
          <div className="ml-2">
            <BackOfficeWorkspaceFilter setFilter={setParams} filter={params} />
          </div>
        </Box>
      </Box>
      <Paper>
        <ServerSideTable<BackOfficeGetMinimalWorkspaceDto, BackOfficeGetWorkspacesSortByEnum>
          columns={columns}
          items={workspaces.data.data}
          limit={workspaces.data.limit}
          totalCount={workspaces.data.totalCount}
          defaultParams={params}
          onPageChange={setPage}
          onLimitChange={setLimit}
          onSort={setSorting}
          onRowClicked={(row) => openWorkspaceDrawer(row.id)}
        />
      </Paper>
    </BaseContainer>
  )
}

export default BackOfficeWorkspaceScene
