import React from "react"

import BigNumber from "bignumber.js"
import numbro from "numbro"
import { BoldWeight, TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import TooltipUI from "CryptioUI/Tooltip"

interface ProccessedValueProps {
  value: string | null
}

const ProccessedValue = (props: ProccessedValueProps) => {
  const { value } = props

  if (!value) {
    return (
      <TypographyUI className="text-grey-400" variant={TypographyVariant.BODY}>
        - -
      </TypographyUI>
    )
  }

  const totalValue = new BigNumber(value)
  const totalValueRounded = totalValue.decimalPlaces(2, BigNumber.ROUND_HALF_UP)
  const prettyNumber = numbro(totalValueRounded?.toString()).format({
    thousandSeparated: true,
  })

  const position = "end"

  const TotalValueUSD = () => {
    if (totalValue.toString() === "0") {
      return (
        <TypographyUI className="text-grey-400" variant={TypographyVariant.BODY}>
          - -
        </TypographyUI>
      )
    }
    if (totalValue.absoluteValue().isLessThan(0.1)) {
      return (
        <TooltipUI
          side="bottom"
          alignment="end"
          content={`$ ${numbro(totalValue?.toString()).format({
            thousandSeparated: true,
          })}`}
        >
          <div>
            <TypographyUI variant={TypographyVariant.BODY} boldness={BoldWeight.MEDIUM} className="text-grey-400">
              {"< $ 0.1"}
            </TypographyUI>
          </div>
        </TooltipUI>
      )
    }
    return (
      <TooltipUI side="bottom" alignment="end" content={`$ ${totalValueRounded}`}>
        <div
          className={`w-[160px] max-w-[160px] overflow-hidden whitespace-nowrap text-ellipsis flex justify-${position}`}
          data-tag="allowRowEvents"
        >
          <TypographyUI
            variant={TypographyVariant.BODY}
            boldness={BoldWeight.MEDIUM}
            className="text-grey-400"
          >{`$ ${prettyNumber}`}</TypographyUI>
        </div>
      </TooltipUI>
    )
  }

  return (
    <div className={`flex flex-col w-[160px] max-w-[160px] items-${position}`} data-tag="allowRowEvents">
      <TotalValueUSD />
    </div>
  )
}

export default ProccessedValue
