import { useTypedController } from "@hookform/strictly-typed"
import { Box, FormLabel, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import dayjs from "dayjs"
import React from "react"
import { useForm } from "react-hook-form"

import WorkspaceAlgorithmSelect from "components/Autocomplete/WorkspaceDefaultAlgorithmSelect"
import { CurrencyField } from "components/CurrencyField"
import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import TitleWithDivider from "components/TitleWithDivider"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { GetCostBasisAlgorithmDtoNameEnum } from "services/api/openapi"
import timezones from "services/timezones"
import { USDollarId } from "pure-shared"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

type Props = {
  onCreateOrJoin: (workspaceId: string) => void
  goBack: () => void
}

type NoWorkspaceForm = {
  name: string
  defaultCurrencyId: string
  timezone: string
  description: string
  costBasisAlgorithm: GetCostBasisAlgorithmDtoNameEnum
}

const NoWorkspaceCreateWorkspaceForm = ({ onCreateOrJoin, goBack }: Props) => {
  const { control, handleSubmit, formState } = useForm<NoWorkspaceForm>({ mode: "onChange" })
  const TypedController = useTypedController<NoWorkspaceForm>({ control })
  const [CreateWorkspaceButton, handleCreateWorkspaceButtonCallback] = useLoadingButton()
  const toast = useToast()

  const { mutateAsync: createWorkspaceMutation } = api.workspace.useCreateWorkspace()
  const onSubmit = async ({ costBasisAlgorithm, ...form }: NoWorkspaceForm) => {
    try {
      const { workspaceId } = await createWorkspaceMutation({
        createWorkspaceDto: {
          ...form,
          description: form.description || null,
          costBasisAlgorithmName: costBasisAlgorithm,
        },
      })
      toast.open("Workspace created", { variant: "success" })
      onCreateOrJoin(workspaceId)
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  // FIXME: duplicated code front/src/scenes/Workspace/WorkspaceDrawer/WorkspaceForm.tsx

  return (
    <Box>
      <TitleWithDivider title="Create a new workspace" mb={3} />
      <form onSubmit={handleCreateWorkspaceButtonCallback(handleSubmit(onSubmit))}>
        <Box>
          <FormLabel htmlFor="workspace-name-textfield">Workspace name</FormLabel>
          <Box mt={1} />
          <TypedController
            name="name"
            defaultValue=""
            rules={{
              required: true,
            }}
            render={(props) => <TextField id="workspace-name-textfield" fullWidth={true} {...props} />}
          />
        </Box>

        <Box mt={3}>
          <FormLabel htmlFor="currency-textfield">Default currency</FormLabel>
          <Box mt={1} />
          <TypedController
            name="defaultCurrencyId"
            defaultValue={USDollarId}
            rules={{
              required: true,
            }}
            render={(props) => <CurrencyField id="currency-textfield" {...props} />}
          />
        </Box>

        <Box mt={3}>
          <FormLabel htmlFor="timezone-select">Timezone</FormLabel>
          <Box mt={1} />
          <TypedController
            name="timezone"
            defaultValue={dayjs.tz.guess()}
            rules={{ required: true }}
            render={({ onChange, ...rest }) => (
              <Autocomplete
                {...rest}
                disableClearable
                id="timezone-select"
                onChange={(_, newValue) => onChange(newValue)}
                options={timezones}
                renderInput={(params) => <TextField {...params} placeholder="Timezone" />}
                size="small"
              />
            )}
          />
        </Box>

        <Box mt={3}>
          <FormLabel htmlFor="algorithm-select">Cost basis methodology</FormLabel>
          <Box mt={1} />
          <TypedController
            name="costBasisAlgorithm"
            defaultValue="fifo-per-wallet"
            rules={{ required: true }}
            render={({ ref: _ref, ...rest }) => <WorkspaceAlgorithmSelect id="algorithm-select" {...rest} />}
          />
        </Box>

        <DrawerFormSection htmlFor="description-textfield" name="Description">
          <TypedController
            name="description"
            defaultValue={""}
            render={(props) => <TextField id="description-textfield" {...props} placeholder="Description" fullWidth />}
          />
        </DrawerFormSection>

        <Box mt={3} display="flex" justifyContent="space-between">
          <ButtonUI onClick={goBack} mode={Mode.CONTAINED}>
            Previous
          </ButtonUI>
          <CreateWorkspaceButton type="submit" disabled={!formState.isValid}>
            Create
          </CreateWorkspaceButton>
        </Box>
      </form>
    </Box>
  )
}

export default NoWorkspaceCreateWorkspaceForm
