import { Box, createStyles, Link, makeStyles, Paper, Typography } from "@material-ui/core"
import LocalAtmIcon from "@material-ui/icons/LocalAtm"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

import BaseContainer from "components/Container"
import ExternalLink from "components/ExternalLink"
import { FeatureGate } from "components/Feature/FeatureGate"
import MainTitleView from "components/MainTitleView"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import SubscriptionUsage from "components/Stripe/SubscriptionUsage"
import { URLS } from "../../../routes"
import api from "services/api"
import InvoiceMiniView from "./Invoices"
import PayingSubscriptionView from "./PayingSubscription"
import TrialSubscriptionView from "./TrialSubscription"

const useStyles = makeStyles((theme) =>
  createStyles({
    standardBlock: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    subscribeButton: {
      "&:hover": {
        color: theme.palette.primary.contrastText,
      },
    },
    featureDisabledText: {
      textAlign: "center",
    },
  }),
)

const BillingStatusScene = (): JSX.Element => {
  const classes = useStyles()

  const subscription = api.billing.useFullSubscription({
    refetchInterval: 10000,
  })

  if (subscription.isError) return <NetworkErrorMessage additionalData={subscription} />

  if (subscription.isLoading || subscription.data === undefined) return <LoadingSpinner />

  const billingFeatureDisabled = (
    <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <LocalAtmIcon fontSize="large" />
      <Typography className={classes.featureDisabledText} variant="h4">
        If you wish to subscribe, cancel your subscription, update your subscription, <br /> or get information about
        our pricing, please <ExternalLink href="https://cryptio.co/pricing">contact us</ExternalLink>.
      </Typography>
    </Box>
  )

  return (
    <BaseContainer>
      <MainTitleView title="Subscription" />

      <FeatureGate feature="billing" fallback={billingFeatureDisabled}>
        <Box mt={2} />
        <Box className={classes.standardBlock} component={Paper}>
          {subscription.data.type === "trial" && <TrialSubscriptionView subscription={subscription.data} />}
          {subscription.data.type === "paying" && <PayingSubscriptionView subscription={subscription.data} />}
        </Box>
        {subscription.data.type === "paying" && subscription.data.payingDetails?.source === "stripe" && (
          <Box className={classes.standardBlock} component={Paper}>
            <Typography variant="h4" gutterBottom>
              Subscription details
            </Typography>
            {/*
            <Box mt={1}>
              <Typography variant="h5" gutterBottom>
                Features
              </Typography>
              <FeatureChipList features={subscription.data.packageFeatures} />
            </Box>
            */}
            <Box mb={1} mt={1}>
              <Typography gutterBottom>
                These are your current workspace limitations. If you want to upgrade your limitations,&nbsp;
                <Link underline="always" component={RouterLink} to={URLS.Billing.Simulator}>
                  please change your subscription
                </Link>
                .
              </Typography>
            </Box>
            <SubscriptionUsage subscription={subscription.data} />
          </Box>
        )}
        <Box className={classes.standardBlock} component={Paper}>
          <Typography variant="h4" gutterBottom>
            Invoices
          </Typography>
          <InvoiceMiniView />
        </Box>
      </FeatureGate>
    </BaseContainer>
  )
}

export default BillingStatusScene
