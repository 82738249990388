import { Fragment } from "react"
import { Switch } from "@headlessui/react"
import { twMerge } from "tailwind-merge"

import { ToggleProps } from "CryptioUI/types"

import { ReactComponent as Cross } from "../assets/icons/close-stroke.svg"
import { ReactComponent as Check } from "../assets/icons/check.svg"

export default function Toggle({ checked: isChecked, setChecked, disabled }: ToggleProps) {
  const checkedStyle =
    "bg-primary-400 text-primary-400 hover:bg-primary-300 hover:text-primary-300 focus:border-primary-200 active:bg-primary-500 active:text-primary-500 active:border-primary-200 disabled:bg-primary-200 disabled:text-primary-200 disabled:border-white"
  const commonStyle = "relative inline-flex h-[32px] w-[54px] items-center rounded-full border-4 border-white"
  const uncheckedStyle =
    "bg-grey-300 text-grey-400 hover:bg-grey-400 focus:border-primary-100 active:bg-grey-500 active:text-grey-500 active:border-primary-100 disabled:bg-grey-200 disabled:border-white disabled:text-grey-400"

  return (
    <Switch checked={isChecked} onChange={setChecked} as={Fragment}>
      {({ checked }) => (
        <button disabled={disabled} className={twMerge(commonStyle, checked ? checkedStyle : uncheckedStyle)}>
          <span className="sr-only">Enable notifications</span>
          <span
            className={`${
              checked ? "translate-x-7" : "translate-x-2"
            } inline-block h-4 w-4 transform rounded-full bg-white transition flex items-center justify-center`}
          >
            {checked ? <Check width={7} color="inherit" /> : <Cross width={6} color="inherit" />}
          </span>
        </button>
      )}
    </Switch>
  )
}
