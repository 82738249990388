import { BusinessIntegrationNameType, SourceNamesType } from "./types"

type CheckFormat<TName extends string> = {
  [key in TName]?: { test: () => boolean }
}

type IntegrationFormat = CheckFormat<BusinessIntegrationNameType>
type WalletSourceFormat = CheckFormat<SourceNamesType>

export const REGEX_UUID_V4 = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
export const isUuid4 = (uuid: string) => REGEX_UUID_V4.test(uuid)

const REGEX_HEXA_64 = "^[0-9A-Fa-f]{64}$"
const REGEX_HEXA_LOWER_CASE_64 = "^[0-9a-f]{64}$"
const REGEX_HEXA_32 = "^[0-9A-Fa-f]{32}$"

const REGEX_BASE64 = "^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$"

function regexAlphaNumBuilder(length: number): string {
  return `^[0-9A-Za-z]{${length}}$`
}

export const checkIntegrationFormat = function (
  integrationName: BusinessIntegrationNameType,
  rawApiKey: string,
): boolean {
  const apiKey = rawApiKey.trim()

  const integrationsValidation: IntegrationFormat = {
    request_network: {
      test: () => {
        return new RegExp("^[A-Z0-9]{7}-[A-Z0-9]{7}-[A-Z0-9]{7}-[A-Z0-9]{7}$").test(apiKey)
      },
    },
    fireblocks: {
      test: () => {
        return new RegExp("^[A-F0-9]{8}-([A-F0-9]{4}-){3}[A-F0-9]{12}$", "i").test(apiKey)
      },
    },
  }

  const validationFunction = integrationsValidation[integrationName]
  if (validationFunction === undefined) {
    return true
  }

  return validationFunction.test()
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const checkImportFormat = function (sourceName: SourceNamesType, form: any): boolean {
  const address = form?.address?.trim() ?? ""
  const apiKey = form?.apiKey?.trim() ?? ""
  const apiSecret = form?.apiSecret?.trim() ?? ""
  // const _passPhrase = form?.rawPassPhrase?.trim() ?? ""

  const sourcesValidation: WalletSourceFormat = {
    "Arbitrum": {
      test: () => {
        return new RegExp("^0x[a-fA-F0-9]{40}$").test(address)
      },
    },
    "Anchorage": {
      test: () => {
        return new RegExp(REGEX_HEXA_64).test(apiKey)
      },
    },
    "Binance": {
      test: () => {
        return new RegExp(regexAlphaNumBuilder(64)).test(apiKey) && new RegExp(regexAlphaNumBuilder(64)).test(apiSecret)
      },
    },
    "Bitcoin": {
      test: () => {
        return (
          new RegExp("^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,59}$").test(address) ||
          ["xpub", "ypub", "zpub"].includes(address.slice(0, 4))
        )
      },
    },
    "Bitfinex": {
      test: () => {
        return new RegExp(regexAlphaNumBuilder(43)).test(apiKey) && new RegExp(regexAlphaNumBuilder(43)).test(apiSecret)
      },
    },
    "BitGo": {
      test: () => {
        return new RegExp("^v2x[0-9A-Fa-f]{64}$").test(apiKey) || new RegExp(REGEX_HEXA_LOWER_CASE_64).test(apiKey)
      },
    },
    "Bitstamp": {
      test: () => {
        return new RegExp("^[0-9A-Za-z]{1,}$").test(apiKey)
      },
    },
    "Bittrex": {
      test: () => {
        return new RegExp(REGEX_HEXA_32).test(apiKey) && new RegExp(REGEX_HEXA_32).test(apiSecret)
      },
    },
    "Cardano": {
      test: () => {
        return new RegExp("^(Ae2|DdzFF|addr1|stake1)").test(address)
      },
    },
    "Coinbase": {
      test: () => {
        return new RegExp(regexAlphaNumBuilder(16)).test(apiKey) && new RegExp(regexAlphaNumBuilder(32)).test(apiSecret)
      },
    },
    "Coinbase Commerce": {
      test: () => {
        return REGEX_UUID_V4.test(apiKey)
      },
    },
    "Coinbase Pro": {
      test: () => {
        return new RegExp(REGEX_HEXA_32).test(apiKey) && new RegExp(REGEX_BASE64).test(apiSecret)
      },
    },
    "Avalanche": {
      test: () => {
        return new RegExp("^0x[a-fA-F0-9]{40}$").test(address)
      },
    },
    "Ethereum": {
      test: () => {
        return new RegExp("^0x[a-fA-F0-9]{40}$").test(address)
      },
    },
    "Polygon": {
      test: () => {
        return new RegExp("^0x[a-fA-F0-9]{40}$").test(address)
      },
    },
    "Polygon (Legacy)": {
      test: () => {
        return new RegExp("^0x[a-fA-F0-9]{40}$").test(address)
      },
    },
    "Zilliqa": {
      test: () => {
        return new RegExp("^zil1.+$").test(address)
      },
    },
    "Binance Smart Chain": {
      test: () => {
        return new RegExp("^0x[a-fA-F0-9]{40}$").test(address)
      },
    },
    "Binance Smart Chain (Legacy)": {
      test: () => {
        return new RegExp("^0x[a-fA-F0-9]{40}$").test(address)
      },
    },
    "Filecoin": {
      test: () => {
        switch (address[1]) {
          case "0":
            return new RegExp("^f0[0-9]{1,19}$").test(address)
          case "1":
          case "2":
          case "3":
            // Not sure with this one / can be more precise
            return new RegExp("^f[1-3][A-Za-z2-7]+$").test(address)
          default:
            return false
        }
      },
    },
    "Gemini": {
      test: () => {
        return new RegExp("^account-[a-zA-Z0-9]{20}$").test(apiKey) && new RegExp("^[0-9A-Za-z]{28}$").test(apiSecret)
      },
    },
    "HitBTC": {
      test: () => {
        return apiKey.length === 32 && apiSecret.length === 32
      },
    },
    "Kraken": {
      test: () => {
        const keyLength = apiKey?.length
        const secretLength = apiSecret?.length
        return (
          new RegExp(REGEX_BASE64).test(apiKey) &&
          keyLength > 45 &&
          keyLength < 65 &&
          new RegExp(REGEX_BASE64).test(apiSecret) &&
          secretLength > 70 &&
          secretLength < 100
        )
      },
    },
    "Litecoin": {
      test: () => {
        return new RegExp("[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}").test(address)
      },
    },
    "Near": {
      test: () => {
        return /^(([a-z0-9]+[-_])*[a-z0-9]+\.)*([a-z0-9]+[-_])*[a-z0-9]+$/.test(address)
      },
    },
    // "Solana": {
    //   test: () => {
    //     return new RegExp(regexAlphaNumBuilder(44)).test(address)
    //   },
    // },
    "Tezos": {
      test: () => {
        return new RegExp("^(tz1|tz2|KT1)[0-9a-zA-Z]{33}$").test(address)
      },
    },

    // TODO: validation
    "Coinbase Prime": {
      test: () => {
        return true
      },
    },
    "FTX": {
      test: () => {
        return true
      },
    },
    "FTX US": {
      test: () => {
        return true
      },
    },
    "FTX (OTC)": {
      test: () => {
        return true
      },
    },
    // "Liquid": {
    //   test: () => {
    //     return true
    //   },
    // },
    // "Poloniex": {
    //   test: () => {
    //     return true
    //   },
    // },
    // "Poloniex Futures": {
    //   test: () => {
    //     return true
    //   },
    // },
    "Bitcoin Suisse": {
      test: () => true,
    },
    "Spot": {
      test: () => true,
    },
  }

  const validationFunction = sourcesValidation[sourceName]
  if (validationFunction === undefined) {
    return true
  }

  return validationFunction.test()
}
