import { Box } from "@material-ui/core"
import React from "react"

import BaseContainer from "components/Container"
import MainTitleView from "components/MainTitleView"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import PricingCalculator from "components/Stripe/PricingCalculator"
import api from "services/api"

const BillingSimulatorScene = (): JSX.Element => {
  const packages = api.billing.usePackages()
  const subscription = api.billing.useFullSubscription()

  if (subscription.isError) return <NetworkErrorMessage additionalData={subscription} />

  if (subscription.isLoading || subscription.data === undefined) return <LoadingSpinner />

  if (packages.isError) return <NetworkErrorMessage additionalData={packages} />

  if (packages.isLoading || packages.data === undefined) return <LoadingSpinner />

  return (
    <BaseContainer>
      <MainTitleView title="Subscription" />
      <Box mt={2} />
      <PricingCalculator existingPeriod={subscription.data.payingDetails?.periodType} packages={packages.data} />
    </BaseContainer>
  )
}

export default BillingSimulatorScene
