import { Box, Typography } from "@material-ui/core"
import React from "react"

import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import AdministratorInformation from "components/misc/AdministratorInformation"
import { GetCOAMappingDto } from "services/api/aliases"
import { mappingTypeMapName } from ".."

const MappingSummary = ({ mapping }: { mapping: GetCOAMappingDto }) => {
  return (
    <Box>
      <AdministratorInformation infos={[{ id: mapping.id, extended: "COA", createdAt: mapping.createdAt }]} />
      <DrawerCategory title="Mapping Summary">
        <DrawerSection name="Type">
          <Typography variant="body2">{mappingTypeMapName[mapping.type].displayName}</Typography>
        </DrawerSection>

        <DrawerSection name="Description">
          <Typography variant="body2">{mappingTypeMapName[mapping.type].description}</Typography>
        </DrawerSection>
      </DrawerCategory>
    </Box>
  )
}

export default MappingSummary
