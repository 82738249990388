import React from "react"

import { LoadingButton } from "components/LoadingButton"

import { ButtonSize, ControlModalProps, Mode } from "../types"
import ButtonUI from "../Button"

const ControlModal = (props: ControlModalProps): JSX.Element => {
  const {
    destructive = false,
    onAction,
    actionTitle,
    onCancel,
    cancelTitle,
    disabled,
    isLoadingButton,
    isLoadingButtonPending,
    disableCancel,
  } = props

  return (
    <div className="flex mt-4 px-4 pt-2 w-full justify-end">
      <ButtonUI mode={Mode.CONTAINED} size={ButtonSize.LG} onClick={onCancel} disabled={disableCancel}>
        <>{cancelTitle}</>
      </ButtonUI>
      {isLoadingButton ? (
        <LoadingButton
          className="ml-4"
          mode={destructive ? Mode.DESTRUCTIVE : Mode.DEFAULT}
          size={ButtonSize.LG}
          onClick={() => {
            onAction()
          }}
          pending={isLoadingButtonPending}
        >
          {actionTitle}
        </LoadingButton>
      ) : (
        <ButtonUI
          className="ml-4"
          mode={destructive ? Mode.DESTRUCTIVE : Mode.DEFAULT}
          size={ButtonSize.LG}
          onClick={() => {
            onAction()
          }}
          disabled={disabled}
        >
          <>{actionTitle}</>
        </ButtonUI>
      )}
    </div>
  )
}

export default ControlModal
