import { useTypedController } from "@hookform/strictly-typed"
import { Box, Checkbox, FormLabel, TextField } from "@material-ui/core"
import React from "react"
import { Control } from "react-hook-form"
import { DrawerFormSection } from "../../Drawer/DrawerItems"
import PermissionDisabled from "../../Permission/PermissionDisabled"

import { GetLabelDto } from "services/api/openapi"

interface Props {
  watchAllFields: TransactionBulkForm
  control: Control<TransactionBulkForm>
}

type TransactionBulkForm = {
  note: string
  emptyNotes: boolean
  fiatRate: string
  resetRates: boolean
  labelsToAdd: GetLabelDto[]
  labelsToRemove: GetLabelDto[]
  removeInternalTransfer: boolean
}

const BulkNotes = (props: Props) => {
  const { watchAllFields, control } = props
  const TypedController = useTypedController<TransactionBulkForm>({ control })

  return (
    <>
      <DrawerFormSection htmlFor="notes-textfield" name="Notes">
        <TypedController
          name="note"
          defaultValue=""
          render={(props) => (
            <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
              <TextField
                {...props}
                id="notes-textfield"
                multiline
                placeholder="Write anything..."
                fullWidth
                disabled={watchAllFields.emptyNotes}
              />
            </PermissionDisabled>
          )}
        />
      </DrawerFormSection>
      <Box display="flex" alignItems="center" mt={1}>
        <TypedController
          name="emptyNotes"
          defaultChecked={false}
          defaultValue={false}
          render={({ onChange, value, ...rest }) => (
            <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
              <Checkbox
                color="primary"
                id="empty-notes-checkbox"
                onChange={() => onChange(!value)}
                checked={value}
                {...rest}
              />
            </PermissionDisabled>
          )}
        />
        <FormLabel htmlFor="empty-notes-checkbox">Clear all notes</FormLabel>
      </Box>
    </>
  )
}

export default BulkNotes
