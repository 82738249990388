import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import qs from "qs"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router"

import Backdrop from "components/Backdrop/Backdrop"
import BaseContainer from "components/Container"
import { WarningAdminFeature } from "components/Feature/WarningAdminFeature"
import MainTitleView from "components/MainTitleView"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import { URLS } from "../../../routes"
import api from "services/api"
import { ConnectToOauth2IntegrationIntegrationNameEnum } from "services/api/openapi"
import { WorkspaceContext } from "services/context/workspaceContext"
import IntegrationBlock from "./IntegrationBlocks"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  sourceContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: theme.spacing(3),
  },
  noIntegrationText: {
    textAlign: "center",
  },
}))

const ChartAccountScene = (): JSX.Element => {
  const classes = useStyles()
  const toast = useToast()
  const { pathname, search } = useLocation()
  const history = useHistory()
  const { mutateAsync: connectToOAuth2IntegrationMutation } = api.accounting.useConnectToOAuth2Integration()
  const [isBackdropOpen, setIsBackdropOpen] = useState<boolean>(false)

  const connectToIntegration = useCallback(async () => {
    let integrationName: ConnectToOauth2IntegrationIntegrationNameEnum
    if (pathname === URLS.Accounting.OAuth.Connect.QuickBooks) {
      integrationName = "quickbooks"
    } else if (pathname === URLS.Accounting.OAuth.Connect.Xero) {
      integrationName = "xero"
    } else {
      return
    }

    setIsBackdropOpen(true)

    try {
      // Forwarding params
      const params = {
        integrationName,
        ...qs.parse(search, { ignoreQueryPrefix: true }),
      }

      await connectToOAuth2IntegrationMutation(params)

      toast.open("Successfully connected to integration and imported chart of accounts", { variant: "success" })
      history.replace(URLS.Accounting.AutomatedMapping)
    } catch (e) {
      toastCatch(e, toast)
    }
    setIsBackdropOpen(false)
  }, [connectToOAuth2IntegrationMutation, toast, history, pathname, search])

  useEffect(() => {
    connectToIntegration()
  }, [connectToOAuth2IntegrationMutation, connectToIntegration])

  const workspaceCtx = useContext(WorkspaceContext)
  const integrations = api.accounting.useIntegrations()
  const coaMappings = api.chartAccount.useChartAccountMappings({
    limit: 1,
    page: 1,
    type: "default_mapping",
  })
  const { mutateAsync: disconnectIntegrationMutation } = api.accounting.useDisconnectFromIntegration()
  const { mutateAsync: updateExternalCoaIntegrationMutation } = api.accounting.useUpdateExternalCoaIntegration()

  // Update the status of the connection with Xero/QBO if needed
  api.accounting.useCheckStatus(
    workspaceCtx.workspace.userRights.can_update_accounting_integration_connection_status &&
      workspaceCtx.workspace.accountingIntegration !== null &&
      workspaceCtx.workspace.accountingIntegration.name !== "custom",
  )

  const basicDialog = useDialog()

  if (integrations.isError) return <NetworkErrorMessage small={false} additionalData={integrations} />
  if (coaMappings.isError) return <NetworkErrorMessage small={false} additionalData={coaMappings} />
  if (
    coaMappings.isLoading ||
    coaMappings.data === undefined ||
    integrations.isLoading ||
    integrations.data === undefined
  )
    return <LoadingSpinner />

  const onUpdate = async () => {
    try {
      await updateExternalCoaIntegrationMutation({})
      toast.open("Chart of accounts updated", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const onDisconnect = async () => {
    try {
      await disconnectIntegrationMutation({})
      toast.open("Disconnected from integration", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const askConnect = (onConnect: () => void) => {
    if (coaMappings.data.data.length === 0) {
      // No default mapping => first connection ever
      return onConnect()
    }

    basicDialog.showDialog({
      title: "Warning: potential data loss",
      content: (
        <Typography variant="h5">
          <>
            You have connected to an accounting integration before.
            <br /> If you connect to another accounting integration, or another account, you will lose transaction
            mappings and COA entries.
          </>
        </Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: onConnect,
    })
  }

  return (
    <BaseContainer>
      {isBackdropOpen && <Backdrop />}
      {basicDialog.dialog}
      <MainTitleView
        title="Accounting integrations"
        helpTooltipContent="Push transactions from your subledger (Cryptio) to your main ledger (Xero, QuickBooks...). Click to know more!"
        helpTooltipUrl="https://support.cryptio.co/hc/en-gb/articles/9086103218833-Introduction-to-Transaction-Reconciliation"
      />
      {integrations.data.length ? (
        <Box mt={3} className={classes.sourceContainer}>
          {integrations.data.map((integration) => (
            <WarningAdminFeature isAdminFeature={integration.isFeatureEnabledOnlyForAdmin} key={integration.name}>
              <IntegrationBlock
                integration={integration}
                workspaceIntegration={
                  integration.name === workspaceCtx.workspace.accountingIntegration?.name
                    ? workspaceCtx.workspace.accountingIntegration
                    : null
                }
                disabled={!!workspaceCtx.workspace.accountingIntegration}
                onUpdate={onUpdate}
                askConnect={askConnect}
                onDisconnect={onDisconnect}
              />
            </WarningAdminFeature>
          ))}
        </Box>
      ) : (
        <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h4" className={classes.noIntegrationText}>
            Accounting integrations are disabled on this workspace.
            <br />
            Please contact the Cryptio support if you believe this is a mistake.
          </Typography>
        </Box>
      )}
    </BaseContainer>
  )
}

export default ChartAccountScene
