import { makeStyles } from "@material-ui/styles"
import { TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import React from "react"
import { OtherPartyAliasDto } from "services/api/openapi"

type AddressTitleTooltipProps = {
  otherParties: OtherPartyAliasDto[]
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
}))

const AddressTitleTooltip = (props: AddressTitleTooltipProps) => {
  const { otherParties } = props
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {otherParties.map((otherParty, index) => {
        if (index === 10) {
          return (
            <TypographyUI key={otherParty.address} variant={TypographyVariant.BODY}>
              {`and ${otherParties.length - 10} other address ...`}
            </TypographyUI>
          )
        }
        if (index > 10) {
          return <></>
        }
        return (
          <TypographyUI key={otherParty.address} variant={TypographyVariant.BODY}>
            {otherParty.alias ? null : otherParty.address}
          </TypographyUI>
        )
      })}
    </div>
  )
}

export default AddressTitleTooltip
