import SimpleAutocompleteFactory from "components/Autocomplete/SimpleAutocompleteFactory"
import { TransactionFilterInternalTransferStatusValueEnum } from "services/api/openapi"

export type TransactionFilterInternalTransferStatusValueType = Exclude<
  TransactionFilterInternalTransferStatusValueEnum,
  "reviewed" | "notReviewed"
>

export const UsageTypeAutocomplete = SimpleAutocompleteFactory<TransactionFilterInternalTransferStatusValueType>({
  potential: "Potential",
  internalTransfer: "Internal Transfer",
})
