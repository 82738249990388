import JsonCrush from "jsoncrush"
import { useMemo } from "react"
import { useHistory, useLocation } from "react-router-dom"

import { GenericSorting } from "components/Table/interface"
import { URLS } from "../../routes"
import { DEFAULT_ROWS_PER_PAGE } from "services/constants"
import {
  BasicParameters,
  formatUrl,
  PaginatedParamsReturnType,
  PaginationParameters,
  paramUrlName,
} from "services/urlParse"

export const usePaginatedParams = <TParam, TSortEnum>(
  defaultValue: TParam,
  defaultSorting: GenericSorting<TSortEnum>,
  defaultPagination?: PaginationParameters,
): PaginatedParamsReturnType<TSortEnum, TParam> => {
  type CompleteParameters = BasicParameters<TSortEnum> & TParam

  const { search, pathname } = useLocation()
  const history = useHistory()

  const parameters = useMemo(() => {
    const urlParamQuery = new URLSearchParams(search)
    const rawParameters = urlParamQuery.get(paramUrlName)

    try {
      return rawParameters ? JSON.parse(JsonCrush.uncrush(rawParameters)) : {}
    } catch (e) {
      console.error(e)
      history.push(URLS.Portfolio)
    }
  }, [search, history])

  const setters = useMemo<PaginatedParamsReturnType<TSortEnum, TParam>>(() => {
    const defaultParams: CompleteParameters = {
      ...defaultValue,
      ...defaultSorting,
      ...(defaultPagination ?? { page: 1, limit: DEFAULT_ROWS_PER_PAGE }),
    }

    const params: CompleteParameters = {
      ...defaultParams,
      ...parameters,
    }

    const setValue = (setter: (oldValue: CompleteParameters) => CompleteParameters) => {
      const newValue = setter(params)
      const newUrl = formatUrl<CompleteParameters>(pathname, newValue, defaultParams)
      history.push(newUrl)
    }

    return {
      params,
      setLimit: (limit) => setValue((p) => ({ ...p, page: 1, limit })),
      setPage: (page) => setValue((p) => ({ ...p, page })),
      setSorting: (sorting) =>
        setValue((previous) => ({
          ...previous,
          page: 1,
          sortBy: sorting.sortBy,
          sortDirection: sorting.sortDirection,
        })),
      setParams: (params) =>
        setValue((p) => {
          return { ...p, ...params, page: 1 }
        }),
    }
  }, [history, pathname, parameters, defaultValue, defaultSorting, defaultPagination])

  return setters
}
