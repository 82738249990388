import { useTypedController } from "@hookform/strictly-typed"
import React from "react"
import { ReportFormComponentProps } from "."
import SimpleAutocompleteFactory from "components/Autocomplete/SimpleAutocompleteFactory"
import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { CreateReportDtoFileTypeEnum } from "services/api/openapi"
import { DrawerReportForm } from "../ReportForm"

const ReportFileTypeAutocomplete = SimpleAutocompleteFactory<CreateReportDtoFileTypeEnum>({
  csv: "CSV",
  xlsx: "xlsx",
  legacy_excel: "xlsx",
})

const ReportFormFileType = ({ control, reportFormat }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })

  return (
    <DrawerFormSection htmlFor="file-type-select" name="Format">
      <TypedController
        name="fileType"
        defaultValue={"csv"}
        rules={{ required: true }}
        render={({ onChange, value }) => (
          <ReportFileTypeAutocomplete
            id="file-type-select"
            value={value ?? null}
            // Keep the correct xlsx
            filterOptions={(options) =>
              options.filter(
                (option) =>
                  (option === "csv" && reportFormat.availableAsCsv) ||
                  (option === "xlsx" && reportFormat.availableAsXlsx) ||
                  (option === "legacy_excel" && reportFormat.availableAsLegacyExcel),
              )
            }
            onChange={(value) => onChange(value ?? undefined)}
          />
        )}
      />
    </DrawerFormSection>
  )
}

export default ReportFormFileType
