import { useMutation, useQuery, UseQueryOptions } from "react-query"

import { queryClient } from "../../.."
import { usePaginatedWorkspaceQuery, useWorkspaceMutation, useWorkspaceQuery } from "../../context/workspaceContext"
import rest from "../http"
import {
  GetMinimalWorkspaceRoleDto,
  GetPeggableStablecoinsDto,
  GetPeggableStablecoinsDtoRequest,
  GetWorkspaceDto,
  GetWorkspaceMetricsDto,
  GetWorkspaceOrDefaultDto,
  GetWorkspaceOrDefaultRequest,
  GetWorkspaceRolesRequest,
  GetWorkspacesListShortRequest,
  GetWorkspacesRequest,
  GetWorkspaceUsersRequest,
  PaginatedWorkspaceInvitationResultDto,
  PaginatedWorkspaceResultDto,
  PaginatedWorkspaceListShortDto,
  PaginatedWorkspaceUserResultDto,
  UserApi,
  WorkspacesApi,
  DefiApi,
  GetLivePositionsRequest,
  PaginatedDefiLivePositionResult,
  DefiLivePositionDto,
} from "../openapi"
import { getPaginationQuery, PaginationQuery } from "../pagination"
import { GET_USER_KEY } from "./user"
import { GET_MOVEMENT_KEY, GET_TRANSACTION_KEY, GET_TRANSACTIONS_KEY } from "./transactions"
import { WithoutWorkspaceId } from "../aliases"
import { GetDefiProtocolsRequest } from "../../../../../tests/api/src/internal-api/openapi/apis/DefiApi"
import { GetDefiNetworkRequest, GetPositionNamesRequest, GetPositionTypesRequest } from "../openapi/apis/DefiApi"

export const GET_WORKSPACES_KEY = "getWorkspaces"
export const GET_WORKSPACES_LIST_SHORT_KEY = "getWorkspacesListShort"
export const GET_WORKSPACE_KEY = "getWorkspace"
export const GET_WORKSPACES_OR_DEFAULT_KEY = "getWorkspaceOrDefault"
export const GET_WORKSPACE_USERS_KEY = "getWorkspaceUsers"
export const GET_WORKSPACE_ROLES_KEY = "getWorkspaceRoles"
export const GET_WORKSPACE_INVITATIONS_KEY = "getWorkspaceInvitations"
export const GET_WORKSPACE_METRICS_KEY = "getWorkspaceMetrics"
export const GET_COST_BASIS_ALGORITHM = "getCostBasisAlgorithm"
export const GET_PEGGED_STABLECOINS_KEY = "GET_PEGGED_STABLECOINS_KEY"
export const GET_WORKSPACE_LOCK_CHECK_KEY = "getWorkspaceLockCheck"
export const GET_TOTAL_DEFI_POSITIONS = "getTotalDefiPositions"
export const GET_POSTPROCESS_RETROACTIVITY_SETTINGS = "getPostprocessRetroactivitySettings"
export const GET_POSTPROCESS_TOGGLE_RETROACTIVITY = "getPostprocessToggleRetroactivity"
export const GET_ALL_DEFI_POSITIONS = "getAllDefiPositions"
export const GET_DEFI_POSITION = "getDefiPosition"
export const GET_DEFI_PROTOCOL = "getDefiProtocol"
export const GET_DEFI_NETWORKS = "getDefiNetworks"
export const GET_POSITION_TYPE = "getPositionType"
export const GET_POSITION_NAME = "getPositionName"

export const workspaceActions = {
  useGetWorkspaceOrDefault: (
    variables: GetWorkspaceOrDefaultRequest,
    config?: UseQueryOptions<GetWorkspaceOrDefaultDto>,
  ) => {
    return useQuery<GetWorkspaceOrDefaultDto>(
      [GET_WORKSPACES_OR_DEFAULT_KEY, variables],
      () => WorkspacesApi.getWorkspaceOrDefault(variables),
      {
        ...config,
        onError: () => {
          queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
          queryClient.invalidateQueries(GET_USER_KEY)
        },
      },
    )
  },
  useWorkspaces: (variables: GetWorkspacesRequest, config?: UseQueryOptions<PaginatedWorkspaceResultDto>) => {
    return useQuery<PaginatedWorkspaceResultDto>(
      [GET_WORKSPACES_KEY, variables],
      () => WorkspacesApi.getWorkspaces(variables),
      {
        ...config,
        keepPreviousData: true,
        enabled: localStorage.workspaceId !== undefined && localStorage.workspaceId !== null,
      },
    )
  },
  useWorkspacesList: (
    variables: GetWorkspacesListShortRequest,
    config?: UseQueryOptions<PaginatedWorkspaceListShortDto>,
  ) => {
    return useQuery<PaginatedWorkspaceListShortDto>(
      [GET_WORKSPACES_LIST_SHORT_KEY, variables],
      () => WorkspacesApi.getWorkspacesListShort(variables),
      {
        ...config,
        keepPreviousData: true,
        enabled: localStorage.workspaceId !== undefined && localStorage.workspaceId !== null,
      },
    )
  },
  useWorkspace: (workspaceId: string | undefined | null, option?: UseQueryOptions<GetWorkspaceDto>) => {
    return useWorkspaceQuery(
      [GET_WORKSPACE_KEY, workspaceId],
      WorkspacesApi.getWorkspace,
      { workspaceId: workspaceId ?? "" },
      {
        enabled: !!workspaceId,
        ...option,
      },
    )
  },
  useGetWorkspaceMetrics: (option?: UseQueryOptions<GetWorkspaceMetricsDto>) => {
    return useWorkspaceQuery(
      [GET_WORKSPACE_METRICS_KEY],
      WorkspacesApi.getWorkspaceMetrics,
      {},
      {
        ...option,
      },
    )
  },
  useWorkspaceUsers: (
    variables: GetWorkspaceUsersRequest,
    config?: UseQueryOptions<PaginatedWorkspaceUserResultDto>,
  ) => {
    return useQuery<PaginatedWorkspaceUserResultDto>(
      [GET_WORKSPACE_USERS_KEY, variables],
      () => WorkspacesApi.getWorkspaceUsers(variables),
      {
        ...config,
        keepPreviousData: true,
      },
    )
  },
  useWorkspaceRoles: (variables: GetWorkspaceRolesRequest, config?: UseQueryOptions<GetMinimalWorkspaceRoleDto[]>) => {
    return useQuery<GetMinimalWorkspaceRoleDto[]>(
      [GET_WORKSPACE_ROLES_KEY, variables],
      () => WorkspacesApi.getWorkspaceRoles(variables),
      config,
    )
  },
  usePeggableStablecoins: (variables: GetPeggableStablecoinsDtoRequest) => {
    return useQuery<GetPeggableStablecoinsDto>([GET_PEGGED_STABLECOINS_KEY], () =>
      WorkspacesApi.getPeggableStablecoinsDto(variables),
    )
  },
  useSetPeggedStablecoins: () => {
    return useMutation(WorkspacesApi.updatePeggedStablecoinsDto, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PEGGED_STABLECOINS_KEY)
      },
    })
  },
  useApplyPeggedStablecoins: () => {
    return useMutation(WorkspacesApi.applyPeggedStablecoinsDto, {
      onSuccess: () => {
        // TODO: Which query to invalidate ? This is long running so not sure whether it makes sense to invalidatr transactions
      },
    })
  },
  useCreateWorkspace: () => {
    return useMutation(WorkspacesApi.createWorkspaces, {
      onSuccess: () => {
        // More workspace
        queryClient.invalidateQueries(GET_WORKSPACES_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useCreateWorkspaceRole: () => {
    return useMutation(WorkspacesApi.createWorkspaceRoles, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACES_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_ROLES_KEY)
      },
    })
  },
  useUpdateWorkspace: () => {
    return useMutation(WorkspacesApi.updateWorkspace, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACES_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_LIST_SHORT_KEY)
      },
    })
  },
  useUpdateWorkspaceCurrency: () => {
    return useMutation(WorkspacesApi.updateWorkspaceCurrency, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
        queryClient.invalidateQueries(GET_TRANSACTIONS_KEY)
        queryClient.invalidateQueries(GET_MOVEMENT_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useUpdateWorkspaceAccountingOptions: () => {
    return useMutation(WorkspacesApi.updateWorkspaceAccountingOptions, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACES_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useUpdateWorkspaceUser: () => {
    return useMutation(WorkspacesApi.updateWorkspaceUser, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_USERS_KEY)
      },
    })
  },
  useUpdateWorkspaceRole: () => {
    return useMutation(WorkspacesApi.updateWorkspaceRoles, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_ROLES_KEY)
      },
    })
  },
  useDeleteWorkspaceUser: () => {
    return useMutation(WorkspacesApi.deleteWorkspaceUser, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_USERS_KEY)
      },
    })
  },
  useDeleteWorkspaceRole: () => {
    return useMutation(WorkspacesApi.deleteWorkspaceRole, {
      onSuccess: () => {
        // Will fail if user has the role so no need to invalidate anything else
        queryClient.invalidateQueries(GET_WORKSPACE_ROLES_KEY)
      },
    })
  },
  useCreateWorkspaceInvitation: () => {
    return useMutation(WorkspacesApi.createWorkspaceInvitation, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACES_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_INVITATIONS_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
      },
    })
  },
  useWorkspaceInvitations: (
    variables: PaginationQuery,
    config?: UseQueryOptions<PaginatedWorkspaceInvitationResultDto>,
  ) => {
    return useQuery<PaginatedWorkspaceInvitationResultDto>(
      [GET_WORKSPACE_INVITATIONS_KEY, variables],
      () => rest.get<PaginatedWorkspaceInvitationResultDto>(`/workspaces/invitations?${getPaginationQuery(variables)}`),
      {
        ...config,
        keepPreviousData: true,
      },
    )
  },
  useAcceptWorkspaceInvitations: () => {
    return useMutation(WorkspacesApi.acceptWorkspaceInvitations, {
      onSuccess: () => {
        // More workspace
        queryClient.invalidateQueries(GET_WORKSPACES_KEY)

        // Switch to the workspace
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)

        // Remove invitation
        queryClient.invalidateQueries(GET_WORKSPACE_INVITATIONS_KEY)

        // update notification
        queryClient.invalidateQueries(GET_USER_KEY)
      },
    })
  },
  useDeclineWorkspaceInvitation: () => {
    return useMutation(WorkspacesApi.declineWorkspaceInvitation, {
      onSuccess: () => {
        // Delete invitation
        queryClient.invalidateQueries(GET_WORKSPACE_INVITATIONS_KEY)

        // update notification
        queryClient.invalidateQueries(GET_USER_KEY)
      },
    })
  },
  useDeleteWorkspaceInvitation: () => {
    return useMutation(WorkspacesApi.deleteWorkspaceInvitation, {
      onSuccess: () => {
        // Update invitation list
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
      },
    })
  },
  useToggleBookmark: () => {
    return useMutation(WorkspacesApi.toggleWorkspaceBookmark, {
      onSuccess: () => {
        // Order changed
        queryClient.invalidateQueries(GET_WORKSPACES_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_LIST_SHORT_KEY)
      },
    })
  },
  useUpdateShowOnboarding: () => {
    return useWorkspaceMutation(WorkspacesApi.changeShowOnboarding, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },

  useUpdateHasSeenGlobalLabelsExplanation: () => {
    return useWorkspaceMutation(WorkspacesApi.setHasSeenGlobalLabelsExplanation, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACE_METRICS_KEY)
      },
    })
  },

  useCostBasisAlgorithm: (workspaceId?: string) => {
    return useQuery([GET_COST_BASIS_ALGORITHM, workspaceId], () => UserApi.getCostBasisAlgorithm({ workspaceId }))
  },
  useHasWorkspaceRight: (workspaceId: string) => {
    return useQuery([GET_COST_BASIS_ALGORITHM, workspaceId], () => WorkspacesApi.hasWorkspaceRight({ workspaceId }))
  },

  useUpdateLock: () => {
    return useMutation(WorkspacesApi.updateLockDate, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useDisableLock: () => {
    return useMutation(WorkspacesApi.disableLock, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
        queryClient.invalidateQueries(GET_WORKSPACES_OR_DEFAULT_KEY)
      },
    })
  },
  useCheckLock: (workspaceId: string) => {
    return useQuery([GET_WORKSPACE_LOCK_CHECK_KEY, workspaceId], () => WorkspacesApi.checkLock({ workspaceId }), {})
  },
  useTotalDefiPositions: (workspaceId: string) => {
    return useQuery([GET_TOTAL_DEFI_POSITIONS, workspaceId], () =>
      DefiApi.totalDefiPositions({ workspaceId: workspaceId }),
    )
  },

  usePostprocessRetroactivitySettings: (workspaceId: string) => {
    return useQuery([GET_POSTPROCESS_RETROACTIVITY_SETTINGS, workspaceId], () =>
      WorkspacesApi.retroactivitySettings({ workspaceId: workspaceId }),
    )
  },

  usePostprocessToggleRetroactivity: () => {
    return useMutation(WorkspacesApi.toggleRetroactivity, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_POSTPROCESS_TOGGLE_RETROACTIVITY)
        queryClient.invalidateQueries(GET_POSTPROCESS_RETROACTIVITY_SETTINGS)
      },
    })
  },

  useAllDefiPositions: (
    variables: WithoutWorkspaceId<GetLivePositionsRequest>,
    config?: UseQueryOptions<PaginatedDefiLivePositionResult, unknown, PaginatedDefiLivePositionResult>,
  ) => {
    return usePaginatedWorkspaceQuery([GET_ALL_DEFI_POSITIONS, variables], DefiApi.getLivePositions, variables, config)
  },

  useDefiPosition: (defiId: string | undefined | null, option?: UseQueryOptions<DefiLivePositionDto>) => {
    return useWorkspaceQuery(
      [GET_DEFI_POSITION, defiId],
      DefiApi.getLivePosition,
      { positionId: defiId ?? "" },
      {
        enabled: !!defiId,
        ...option,
      },
    )
  },

  useDefiProtocol: (variables: WithoutWorkspaceId<GetDefiProtocolsRequest>) => {
    return useWorkspaceQuery([GET_DEFI_PROTOCOL, variables], DefiApi.getDefiProtocols, variables)
  },

  useDefiNetwork: (variables: WithoutWorkspaceId<GetDefiNetworkRequest>) => {
    return useWorkspaceQuery([GET_DEFI_NETWORKS, variables], DefiApi.getDefiNetwork, variables)
  },

  usePositionType: (variables: WithoutWorkspaceId<GetPositionTypesRequest>) => {
    return useWorkspaceQuery([GET_POSITION_TYPE, variables], DefiApi.getPositionTypes, variables)
  },

  usePositionName: (variables: WithoutWorkspaceId<GetPositionNamesRequest>) => {
    return useWorkspaceQuery([GET_POSITION_NAME, variables], DefiApi.getPositionNames, variables)
  },
}
