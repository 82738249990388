import dayjs, { Dayjs } from "dayjs"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)

export const removeSecondsFromISO = function (date: Dayjs): string {
  return date.toISOString().split(".")[0] + "Z"
}

export const PlainDateFormat = "YYYY-MM-DD"
export const plainDateToUtcDayJs = (plainDate: string): Dayjs => {
  return dayjs.utc(plainDate, PlainDateFormat)
}
