import { useMutation, useQuery } from "react-query"

import { queryClient } from "../../../.."
import { usePaginatedWorkspaceQuery } from "../../../context/workspaceContext"
import { BackOfficeAssetsApi, BackOfficeGetAssetsRequest } from "../../openapi"
import { GET_PUBLIC_ASSETS_KEY, GET_PUBLIC_ASSET_KEY } from "../asset"

export const GET_BACKOFFICE_ASSETS_KEY = "getBackOfficeAssets"
export const GET_BACKOFFICE_ASSET_KEY = "getBackOfficeAsset"
export const GET_BACKOFFICE_ASSET_LINKS_KEY = "getBackOfficeAssetLinks"
const BACK_OFFICE_GET_ASSET_PROVIDERS = "BACK_OFFICE_GET_ASSET_PROVIDERS"

export const backOfficeAssetActions = {
  useAssetLinks: (assetId: string) => {
    return useQuery([GET_BACKOFFICE_ASSET_LINKS_KEY, assetId], () =>
      BackOfficeAssetsApi.backOfficeFindAssetLinks({ assetId }),
    )
  },
  useCreateAssetExchangeLink: () => {
    return useMutation(BackOfficeAssetsApi.backOfficeCreateAssetLink, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_BACKOFFICE_ASSET_LINKS_KEY)
      },
    })
  },
  useDeleteAssetExchangeLink: () => {
    return useMutation(BackOfficeAssetsApi.backOfficeDeleteAssetLinks, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_BACKOFFICE_ASSET_LINKS_KEY)
      },
    })
  },
  useAssets: (variables: BackOfficeGetAssetsRequest) => {
    return useQuery([GET_BACKOFFICE_ASSETS_KEY, variables], () => BackOfficeAssetsApi.backOfficeGetAssets(variables), {
      keepPreviousData: true,
    })
  },
  useAsset: (assetId: string | null) => {
    return usePaginatedWorkspaceQuery(
      [GET_PUBLIC_ASSET_KEY, assetId],
      () => BackOfficeAssetsApi.backOfficeGetOneAsset({ assetId: assetId ?? "" }),
      { assetId: assetId ?? "" },
      { enabled: assetId !== null },
    )
  },
  useFromUnknownAssetToKnown: () => {
    return useMutation(BackOfficeAssetsApi.backOfficeFromUnknownAssetToKnown, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PUBLIC_ASSET_KEY)
        queryClient.invalidateQueries(GET_PUBLIC_ASSETS_KEY)
        queryClient.invalidateQueries(GET_BACKOFFICE_ASSETS_KEY)
        queryClient.invalidateQueries(GET_BACKOFFICE_ASSET_KEY)
      },
    })
  },
  useRemapUnknownAsset: () => {
    return useMutation(BackOfficeAssetsApi.backOfficeRemapUnknownAsset, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PUBLIC_ASSET_KEY)
        queryClient.invalidateQueries(GET_PUBLIC_ASSETS_KEY)
        queryClient.invalidateQueries(GET_BACKOFFICE_ASSETS_KEY)
        queryClient.invalidateQueries(GET_BACKOFFICE_ASSET_KEY)
      },
    })
  },
  useAssetProviders: (assetId: string) => {
    return useQuery([BACK_OFFICE_GET_ASSET_PROVIDERS, assetId], () =>
      BackOfficeAssetsApi.backOfficeListProviders({ assetId }),
    )
  },
  useCreateAssetProvider: () => {
    return useMutation(BackOfficeAssetsApi.backOfficeCreateProvider, {
      onSuccess: () => {
        queryClient.invalidateQueries(BACK_OFFICE_GET_ASSET_PROVIDERS)
      },
    })
  },
  useDeleteAssetProvider: () => {
    return useMutation(BackOfficeAssetsApi.backOfficeDeleteProvider, {
      onSuccess: () => {
        queryClient.invalidateQueries(BACK_OFFICE_GET_ASSET_PROVIDERS)
      },
    })
  },
  useCreateAssetBlockchainLink: () => {
    return useMutation(BackOfficeAssetsApi.backOfficeLinkAssetToBlockchain, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PUBLIC_ASSET_KEY)
        queryClient.invalidateQueries(GET_PUBLIC_ASSETS_KEY)
        queryClient.invalidateQueries(GET_BACKOFFICE_ASSETS_KEY)
        queryClient.invalidateQueries(GET_BACKOFFICE_ASSET_KEY)
      },
    })
  },
  useDeleteAssetBlockchainLink: () => {
    return useMutation(BackOfficeAssetsApi.backOfficeDeleteBlockchainLink, {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PUBLIC_ASSET_KEY)
        queryClient.invalidateQueries(GET_PUBLIC_ASSETS_KEY)
        queryClient.invalidateQueries(GET_BACKOFFICE_ASSETS_KEY)
        queryClient.invalidateQueries(GET_BACKOFFICE_ASSET_KEY)
      },
    })
  },
}
