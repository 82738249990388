import React, { useEffect } from "react"

import LoadingSpinner from "components/misc/LoadingSpinner"
import api from "services/api"

const XeroSingleSignUpScene = (): JSX.Element => {
  const onExternalRegister = async () => {
    const url = await api.accounting.getRegisterOAuthRedirectUrl({ integration: "xero" })

    window.location.href = url.url
  }

  useEffect(() => {
    onExternalRegister()
  })

  return <LoadingSpinner />
}

export default XeroSingleSignUpScene
