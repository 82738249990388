import { Box, Divider, IconButton, makeStyles, Theme, Typography } from "@material-ui/core"
import { DeleteRounded, GetAppRounded, OpenInNewRounded } from "@material-ui/icons"
import React, { useCallback, useContext, useMemo, useState } from "react"
import { IDataTableColumn } from "react-data-table-component"
import { UncontrolledLoadingButton } from "components/LoadingButton"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import ServerSideTable from "components/Table/ServerSideTable"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { ButtonSize, Mode } from "CryptioUI/types"

import api from "services/api"
import { GetInvoicesSortByEnum, GetInvoicesSortDirectionEnum } from "services/api/openapi/apis"
import { GetInvoiceDto } from "services/api/openapi/models"
import { DEFAULT_ROWS_PER_PAGE } from "services/constants"
import { WorkspaceContext } from "services/context/workspaceContext"
import download from "services/utils/download"
import { openInNewTab } from "services/utils/openInNewTab"
import { pluralize } from "services/utils/textUtils"
import TooltipUI from "CryptioUI/Tooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  noInvoiceText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
}))

const InvoiceListModal = (): JSX.Element => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<{
    allSelected: boolean
    selectedCount: number
    selectedRows: GetInvoiceDto[]
  }>({ allSelected: false, selectedCount: 0, selectedRows: [] })
  const [limit, setLimit] = useState<number>(DEFAULT_ROWS_PER_PAGE)
  const [sorting] = useState<{
    sortBy: GetInvoicesSortByEnum
    sortDirection: GetInvoicesSortDirectionEnum
  }>({
    sortBy: "invoice_ref",
    sortDirection: "ascending",
  })
  const [page, setPage] = useState<number>(1)
  const invoices = api.invoices.useInvoices({
    page: page,
    limit: limit,
    sortBy: sorting.sortBy,
    sortDirection: sorting.sortDirection,
  })
  const basicDialog = useDialog()
  const toast = useToast()
  const workspaceCtx = useContext(WorkspaceContext)
  const { mutateAsync: deleteInvoiceMutation } = api.invoices.useDeleteInvoices()

  const handleDelete = useCallback(
    async (invoiceIds: string[]) => {
      basicDialog.showDialog({
        title: "Are you sure?",
        content: (
          <Typography variant="h5">
            You are about to delete {invoiceIds.length} {pluralize(invoiceIds.length > 1, "invoice")}. This action is
            irreversible.
          </Typography>
        ),
        yesText: "Yes",
        noText: "Cancel",
        onAccept: async () => {
          try {
            await deleteInvoiceMutation({ deleteInvoicesDto: { invoiceIds } })
            toast.open(`Successfully deleted ${invoiceIds.length} ${pluralize(invoiceIds.length > 1, "invoice")}`, {
              variant: "success",
            })
          } catch (e) {
            toastCatch(e, toast)
          }
        },
      })
    },
    [basicDialog, toast, deleteInvoiceMutation],
  )

  const handleDeleteSelectedInvoice = async () => {
    handleDelete(selectedRows.selectedRows.map((row) => row.id))
    // TODO: Not updated ??
    setSelectedRows({ allSelected: false, selectedCount: 0, selectedRows: [] })
  }

  const columns: IDataTableColumn<GetInvoiceDto>[] = useMemo(() => {
    const handleOpen = (invoice: GetInvoiceDto) => {
      if (invoice.source === "request_network" && invoice.filename) {
        openInNewTab(`https://invoicing.request.network/${invoice.filename}`, true)
      } else {
        api.invoices.getSignedUrl(workspaceCtx, invoice.id).then((res) => download(res.signedUrl))
      }
    }
    return [
      {
        name: "Reference",
        selector: "invoiceRef",
      },
      {
        name: "Source",
        width: "150px",
        selector: "source",
        format: (row) => {
          if (row.source === "internal_pdf") {
            return "Cryptio"
          } else if (row.source === "request_network") {
            return "Request Finance"
          }
          return "Unknown"
        },
      },
      {
        name: "Filename",
        selector: "filename",
        format: function FormatFilename(row) {
          if (!row.filename) return "-"
          return (
            <TooltipUI content={row.filename ?? ""}>
              <span>{row.filename}</span>
            </TooltipUI>
          )
        },
        width: "350px",
      },
      {
        name: "Actions",
        selector: "actions",
        format: function FormatActions(row) {
          return (
            <Box>
              <IconButton onClick={() => handleDelete([row.id])}>
                <DeleteRounded />
              </IconButton>
              {row.filename && (
                <IconButton onClick={() => handleOpen(row)}>
                  {row.source === "request_network" ? <OpenInNewRounded /> : <GetAppRounded />}
                </IconButton>
              )}
            </Box>
          )
        },
      },
    ]
  }, [handleDelete, workspaceCtx])

  let invoiceTable: JSX.Element
  if (invoices.isError) {
    invoiceTable = <NetworkErrorMessage additionalData={invoices} />
  } else if (invoices.isLoading || invoices.data === undefined) {
    invoiceTable = <LoadingSpinner />
  } else {
    invoiceTable = (
      <ServerSideTable<GetInvoiceDto, GetInvoicesSortByEnum>
        columns={columns}
        items={invoices.data.data}
        totalCount={invoices.data.totalCount}
        defaultParams={{
          ...sorting,
          page,
        }}
        limit={invoices.data.limit}
        pagination
        onPageChange={setPage}
        onLimitChange={setLimit}
        onSelectedChange={setSelectedRows}
      />
    )
  }

  return (
    <>
      <ButtonUI mode={Mode.CONTAINED} size={ButtonSize.LG} onClick={() => setIsModalOpen(true)}>
        Invoices
      </ButtonUI>
      <ModalUI
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="w-[1000px] h-auto"
        title="Invoice list"
      >
        {basicDialog.dialog}
        <Divider />
        {invoices.data && invoices.data.data.length > 0 ? (
          <>
            {invoiceTable}
            <div className="flex flex-row mt-2 space-x-4 justify-end">
              <ButtonUI mode={Mode.CONTAINED} onClick={() => setIsModalOpen(false)}>
                Close
              </ButtonUI>
              <UncontrolledLoadingButton
                disabled={selectedRows.selectedCount === 0}
                onClick={handleDeleteSelectedInvoice}
              >
                Delete selection
              </UncontrolledLoadingButton>
            </div>
          </>
        ) : (
          <Box className={classes.noInvoiceText}>
            <Typography variant="h4">You don&apos;t have any invoices.</Typography>
          </Box>
        )}
      </ModalUI>
    </>
  )
}
export default InvoiceListModal
