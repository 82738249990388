/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import { BackOfficeGetFullUserDto, BackOfficePaginatedUserResultDto, BackOfficeUpdateUserDto } from "../models"

export interface BackOfficeGetUserRequest {
  userId: string
}

export interface BackOfficeGetUsersRequest {
  page: number
  limit: number
  queryEmail?: string
  queryCompany?: string
  registerStartDate?: string
  registerEndDate?: string
  lastLoggedInStartDate?: string
  lastLoggedInEndDate?: string
  sortBy?: BackOfficeGetUsersSortByEnum
  emailConfirmed?: BackOfficeGetUsersEmailConfirmedEnum
  is2FaEnabled?: BackOfficeGetUsersIs2FaEnabledEnum
  hasAccountingIntegration?: BackOfficeGetUsersHasAccountingIntegrationEnum
  sortDirection?: BackOfficeGetUsersSortDirectionEnum
  minimumTransaction?: number
  minimumWallet?: number
  minimumLabel?: number
  userId?: string
  inWorkspaceId?: string
  excludedIds?: Array<string>
}

export interface BackOfficeUpdateUserRequest {
  userId: string
  backOfficeUpdateUserDto: BackOfficeUpdateUserDto
}

/**
 *
 */
export class BackOfficeUserApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static backOfficeGetUser(params: BackOfficeGetUserRequest) {
    return localBackOfficeUserApi.backOfficeGetUser(params)
  }

  /**
   */
  backOfficeGetUser({ userId }: BackOfficeGetUserRequest): Promise<BackOfficeGetFullUserDto> {
    return this.restInstance.get(`/back-office/user/${encodeURIComponent(userId)}`)
  }

  static backOfficeGetUsers(params: BackOfficeGetUsersRequest) {
    return localBackOfficeUserApi.backOfficeGetUsers(params)
  }

  /**
   * Get users
   */
  backOfficeGetUsers({ ...queryParameters }: BackOfficeGetUsersRequest): Promise<BackOfficePaginatedUserResultDto> {
    return this.restInstance.get(`/back-office/user?${this.restInstance.encodeObjectToUrl(queryParameters)}`)
  }

  static backOfficeToggleAdminFeatures() {
    return localBackOfficeUserApi.backOfficeToggleAdminFeatures()
  }

  /**
   * Toggle admin features
   */
  backOfficeToggleAdminFeatures(): Promise<void> {
    return this.restInstance.post(`/back-office/user/admin-features`, undefined, true)
  }

  static backOfficeUpdateUser(params: BackOfficeUpdateUserRequest) {
    return localBackOfficeUserApi.backOfficeUpdateUser(params)
  }

  /**
   */
  backOfficeUpdateUser({ backOfficeUpdateUserDto, userId }: BackOfficeUpdateUserRequest): Promise<void> {
    return this.restInstance.put(`/back-office/user/${encodeURIComponent(userId)}`, backOfficeUpdateUserDto, true)
  }
}

const localBackOfficeUserApi = new BackOfficeUserApi()

/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetUsersSortByEnum = "email" | "created_at"
/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetUsersEmailConfirmedEnum = "Confirmed" | "Not confirmed" | "Both"
/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetUsersIs2FaEnabledEnum = "Enabled" | "Disabled" | "Both"
/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetUsersHasAccountingIntegrationEnum =
  | "Has an accounting integration"
  | "Has no accounting integration"
  | "Both"
/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetUsersSortDirectionEnum = "ascending" | "descending"
