import React, { ForwardedRef } from "react"
import { twMerge } from "tailwind-merge"

import { modeStyle, sizeStyle } from "CryptioUI/Button"
import { InputButtonUIProps, Mode, ButtonSize, TypographyVariant, BoldWeight } from "CryptioUI/types"
import IconPositionWrapper from "CryptioUI/Utilities/IconPositionWrapper"
import TypographyUI from "CryptioUI/Typography"

const InputButtonUI = React.forwardRef(
  (props: InputButtonUIProps, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
    const {
      type = "button",
      size = ButtonSize.LG,
      Icon,
      onClick,
      children,
      disabled,
      className = "",
      iconPosition = "left",
      mode = Mode.DEFAULT,
      id = "",
      fullWidth = false,
      form,
      onChange,
      accept,
      name,
      ...rest
    } = props

    const widthStyle = fullWidth ? "w-full " : ""
    const commonStyle = "px-4 flex justify-center items-center h-10"
    const genericDisabledStyle = "disabled:bg-grey-200 disabled:border-none disabled:text-grey-300"
    const genericStyle = [commonStyle, modeStyle[mode], widthStyle, sizeStyle[size], className].join(" ")

    const finalStyle =
      mode !== Mode.TEXTONLY && mode !== Mode.SHADOWED
        ? twMerge([genericStyle + genericDisabledStyle].join(" "))
        : twMerge(genericStyle)
    return (
      <>
        <label form={form} className={finalStyle} role="button" htmlFor={name} {...rest}>
          <IconPositionWrapper
            disabled={disabled}
            Icon={Icon}
            iconOnly={!children}
            iconPosition={iconPosition}
            iconSpacing={8}
          >
            {children && (
              <TypographyUI variant={TypographyVariant.BUTTON} boldness={BoldWeight.MEDIUM}>
                <>{children}</>
              </TypographyUI>
            )}
          </IconPositionWrapper>
        </label>
        <input
          disabled={disabled}
          type={type}
          accept={accept}
          style={{ display: "none" }}
          ref={ref}
          id={id}
          onChange={onChange}
          onClick={onClick}
          name={name}
        ></input>
      </>
    )
  },
)

export default InputButtonUI
