import { Fade, makeStyles, Theme } from "@material-ui/core"
import PieChartIcon from "@material-ui/icons/PieChart"
import TableChartIcon from "@material-ui/icons/TableChart"
import CardUI from "CryptioUI/Card"
import TooltipUI from "CryptioUI/Tooltip"
import { TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import React, { useState } from "react"

import { OverviewBalanceDto } from "services/api/openapi"
import DashboardAllocation from "./DashboardAllocation"
import DashboardPie from "./DashboardPie"

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  icon: {
    cursor: "pointer",
  },
}))

interface Props {
  assets: OverviewBalanceDto[]
}

const DashboardAsset = ({ assets }: Props): JSX.Element => {
  const [showPie, setShowPie] = useState(true)
  const classes = useStyles()

  return (
    <CardUI data-test-id="unrealized-gains-losses" fullWidth={true} className="flex flex-row justify-start items-start">
      <div className="flex flex-col justify-start items-start w-full min-h-[440px]">
        <div className="flex flex-row w-full justify-between items-center p-8">
          <div className="w-fit">
            <TypographyUI variant={TypographyVariant.H4}>Top assets</TypographyUI>
          </div>
          <div className="flex justify-center items-center">
            <TooltipUI
              content={
                "The assets for which the live balance valuations are missing can be found in the Historical Balance Report or Today's Snapshot"
              }
            >
              <div className="flex justify-center">
                <TypographyUI className="pr-2.5" variant={TypographyVariant.CAPTION}>
                  Missing assets ?
                </TypographyUI>
              </div>
            </TooltipUI>
            {showPie ? (
              <TableChartIcon className={classes.icon} onClick={() => setShowPie(false)} />
            ) : (
              <PieChartIcon className={classes.icon} onClick={() => setShowPie(true)} />
            )}
          </div>
        </div>
        <div className="flex w-full">
          {showPie ? (
            <DashboardPie assets={assets} />
          ) : (
            <Fade in={!showPie}>
              <DashboardAllocation assets={assets} />
            </Fade>
          )}
        </div>
      </div>
    </CardUI>
  )
}

export default DashboardAsset
