// @ts-ignore
import mixpanel from "mixpanel-browser"

import { GetUserDto } from "./api/openapi"
import { isMixpanelEnabled } from "./variables"

// The Mixpanel project token is not a secret. It's fine to expose it on the
// internet. It can be compared to Google Analytics tracking identifier.
const MIXPANEL_PROJECT_TOKEN = "aad80b24418dfffe6246db1da06312eb"

if (isMixpanelEnabled) {
  mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
    batch_requests: false, // May help on low-end computers
    debug: false,
    secure_cookie: true, // Send Mixpanel cookie over HTTPS only
    ignore_dnt: false, // Do not ignore browers' Do Not Track feature
    opt_out_tracking_by_default: true,
  })
}

type TrackableEvent =
  | "Login"
  | "Logout"
  | "UpdateCostBasis"
  | "OpenPageTransactions"
  | "OpenPageLabels"
  | "OpenPageCOA"
  | "OpenPageDashboard"
  | "OpenPageContacts"
  | "TransactionFiltersWithNoLabelOnly"
  | "TransactionFiltersWithNoValuation"
  | "TransactionFiltersMissingFiatRate"
  | "TransactionFiltersMissingVolume"
  | "TransactionLabelAssigned"
  | "TransactionLabelRemoved"
  | "BatchTransactionEditLabel"
  | "TransactionNoteEdited"
  | "TransactionBulkNoteEdited"
  | "LabelFilterType"
  | "CreateLabel"
  | "EditLabel"
  | "LabelsInfo"
  | "CreateMapping"
  | "ViewDashboard"
  | "ReportCreated"
  | "ReportCreationCanceled"
  | "WalletCreated"
  | "ContactsInfo"
  | "ContactsFilters"
  | "ViewContacts"
  | "DatePickerFilterTransaction"
  | "ClickIncompleteCOAReview"
  | "ClickInternalTransferReview"
  | "ClickNeedReview"
  | "ClickMissingLabelReview"
  | "ClickTxToBeSynced"
  | "ClickReconcileLedgerEntries"
  | "CreateFilteredTransactions"
  | "ClickLabelsToMapCOA"
  | "ClickWalletsToMapCOA"
  | "ClickExchangesToMapCOA"

export const Mixpanel = {
  // The `identify` method must be called when the user logs-in. If the method
  // is not called, the user will be considered a "visitor". Mixpanel will
  // assign a random ID to him and we'll not be able to tie his events to his
  // "identity".
  identify: (user: GetUserDto) => {
    if (!isMixpanelEnabled) {
      return
    }

    // What really matters here is that we always pass the same ID for a given
    // user. As `user.id` comes straight from the database and is constant, we
    // are fine.
    mixpanel.identify(user.id)

    // These is also the mixpanel.people.set(any) method that allows to attach
    // more context to the user. But let's not give too much data to Mixpanel!
  },
  // The `track` method sends an event to Mixpanel, with optionnal additional
  // properties. If you want to track an event that is not listed in the
  // `TrackableEvent` type, please reach out to the tech/product team as the
  // event also needs to be added on Mixpanel's side (I mean, it does not
  // *have* to, but it's cleaner).
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track: (event: TrackableEvent, props?: any) => {
    if (!isMixpanelEnabled) {
      return
    }
    mixpanel.track(event, props)
  },

  optInTracking: (): void => isMixpanelEnabled && mixpanel.opt_in_tracking(),
  optOutTracking: (): void => isMixpanelEnabled && mixpanel.opt_out_tracking(),
}
