import React, { useMemo } from "react"
import GeneratedRows from "./GeneratedRows"
import { ColumnsName } from "./ContainerColumn"
import GeneratedRowHeader from "./GeneratedRowHeader"
import { SelectedRowsType } from "./useBulkSelectorTransactionPage"
import { DeveloppedRowsType } from "./useDeveloppedTransactionPage"
import { useConfigTable } from "./useConfigTable"
import { SelectTransactionPageContextValue } from "services/context/selectTransactionPageContext"
import ContainerRow from "./ContainerRow"
import { makeStyles, TablePagination } from "@material-ui/core"
import { PaginationParameters } from "services/urlParse"
import { GenericSorting, SortDirectionEnum } from "components/Table/interface"
import { TransactionParams } from ".."
import TablePaginationAction from "./Pagination"
import { NewInListTransactionDto } from "services/api/openapi"
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized"
import { GetTradeRequest } from "services/api/routes/transactions"
import { WithoutWorkspaceId } from "services/api/aliases"
import { CostBasisIds } from ".."
import { GetMovementDto } from "../../../services/api/routes/transactions"

interface GeneratedTableProps {
  openTradeDrawer: <PassedItemId extends WithoutWorkspaceId<GetTradeRequest> | null>(
    data: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  totalCount: number
  transactions: NewInListTransactionDto[]
  openWalletMovementDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  openMovementLabelDrawer: <PassedItemId extends GetMovementDto>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  setCostBasisDrawerTransactionId: (ids: CostBasisIds | null) => void
  openTransactionDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  openMovementDrawer: <PassedItemId extends GetMovementDto>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  selectedRows: SelectedRowsType | undefined
  setSelectedRows: (row: SelectedRowsType | undefined) => void
  developpedRows: DeveloppedRowsType | undefined
  setDeveloppedRows: (row: DeveloppedRowsType | undefined) => void
  onPageSelected: () => void
  isPageSelected: boolean
  filteredColumnsNameArray: ColumnsName[]
  openTransactionLabelDrawer: <PassedItemId extends string>(
    itemId: PassedItemId,
    formData?: (null extends PassedItemId ? undefined : undefined) | undefined,
  ) => void
  mvtSelected: SelectTransactionPageContextValue
  desabledColumnsNameArray: ColumnsName[]
  hiddenColumns: ColumnsName[]
  params: PaginationParameters & GenericSorting<"transaction_date"> & TransactionParams
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void
  handleChangeRowsPerPage: (event: {
    target: {
      value: string
    }
  }) => void
  setSorting: (sorting: GenericSorting<"transaction_date">) => void
  sortDirection: SortDirectionEnum
}

const useStyles = makeStyles(() => ({
  bottomRightInnerContainer: {
    "@global": {
      ".ReactVirtualized__Grid__innerScrollContainer": {
        overflowX: "visible !important",
        minWidth: "1225px",
        width: "100%",
      },
      ".ReactVirtualized__Table__row": {
        overflowX: "visible !important",
        minWidth: "1225px",
        width: "100%",
      },
      ".ReactVirtualized__Grid": {
        overflowX: "visible !important",
        minWidth: "1225px",
        width: "100%",
      },
      ".ReactVirtualized__List": {
        overflowX: "visible !important",
        minWidth: "1225px",
        width: "100%",
      },
    },
  },
  container: {
    width: "100%",
    overflowX: "auto",
    backgroundColor: "white",
  },
}))

const GeneratedTable = (props: GeneratedTableProps): JSX.Element => {
  const {
    totalCount,
    transactions,
    openWalletMovementDrawer,
    openMovementLabelDrawer,
    setCostBasisDrawerTransactionId,
    openTransactionDrawer,
    openTradeDrawer,
    openMovementDrawer,
    selectedRows,
    setSelectedRows,
    developpedRows,
    setDeveloppedRows,
    onPageSelected,
    isPageSelected,
    filteredColumnsNameArray,
    openTransactionLabelDrawer,
    mvtSelected,
    desabledColumnsNameArray,
    hiddenColumns,
    params,
    handleChangePage,
    handleChangeRowsPerPage,
    setSorting,
    sortDirection,
  } = props
  const classes = useStyles()

  const columnsConfig = useConfigTable()

  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 64,
      }),
    // eslint-disable-next-line
    [
      columnsConfig,
      filteredColumnsNameArray,
      transactions,
      openWalletMovementDrawer,
      openMovementLabelDrawer,
      setCostBasisDrawerTransactionId,
      openMovementDrawer,
      openTransactionDrawer,
      openTradeDrawer,
      selectedRows,
      setSelectedRows,
      developpedRows,
      setDeveloppedRows,
      openTransactionLabelDrawer,
      mvtSelected,
    ],
  )

  const rows = useMemo(() => {
    if (!transactions) {
      return <></>
    }

    const renderRow = ({ index, key, style, parent }: any) => {
      const newStyle = {
        ...style,
      }

      return (
        <CellMeasurer
          key={key}
          cache={cache}
          className={classes.bottomRightInnerContainer}
          parent={parent}
          columnIndex={0}
          rowIndex={index}
        >
          <div style={newStyle} className={classes.bottomRightInnerContainer} role="row">
            <GeneratedRows
              key={key}
              transaction={transactions[index]}
              isLastRow={index === transactions.length - 1}
              columnsNameArray={filteredColumnsNameArray}
              openWalletMovementDrawer={openWalletMovementDrawer}
              openMovementLabelDrawer={openMovementLabelDrawer}
              setCostBasisDrawerTransactionId={setCostBasisDrawerTransactionId}
              openTransactionDrawer={openTransactionDrawer}
              openTradeDrawer={openTradeDrawer}
              openMovementDrawer={openMovementDrawer}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              developpedRows={developpedRows}
              setDeveloppedRows={setDeveloppedRows}
              columnsConfig={columnsConfig}
              openTransactionLabelDrawer={openTransactionLabelDrawer}
              mvtSelected={mvtSelected}
            />
          </div>
        </CellMeasurer>
      )
    }

    return (
      <div
        style={{
          height: "calc(100vh - 335px)",
          minWidth: "1270px",
        }}
      >
        <AutoSizer className={classes.bottomRightInnerContainer}>
          {({ width, height }) => {
            return (
              <List
                width={width}
                height={height}
                className="min-w-[1225px] !overflow-x-visible"
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowRenderer={renderRow}
                rowCount={transactions.length}
                overscanRowCount={3}
              />
            )
          }}
        </AutoSizer>
      </div>
    )
  }, [
    classes.bottomRightInnerContainer,
    columnsConfig,
    filteredColumnsNameArray,
    transactions,
    openWalletMovementDrawer,
    openMovementLabelDrawer,
    setCostBasisDrawerTransactionId,
    openMovementDrawer,
    openTransactionDrawer,
    openTradeDrawer,
    selectedRows,
    setSelectedRows,
    developpedRows,
    setDeveloppedRows,
    openTransactionLabelDrawer,
    mvtSelected,
    cache,
  ])

  const rowsHeader = useMemo(() => {
    return (
      <div className="min-w-[1270px]">
        <GeneratedRowHeader
          setSelectedRows={setSelectedRows}
          filteredColumnsNameArray={filteredColumnsNameArray}
          onPageSelected={onPageSelected}
          isPageSelected={isPageSelected}
          columnsConfig={columnsConfig}
          mvtSelected={mvtSelected}
          setSorting={setSorting}
          sortDirection={sortDirection}
          selectedRows={selectedRows}
        />
      </div>
    )
  }, [
    selectedRows,
    setSelectedRows,
    filteredColumnsNameArray,
    onPageSelected,
    isPageSelected,
    columnsConfig,
    mvtSelected,
    setSorting,
    sortDirection,
  ])

  const pagination = useMemo(() => {
    return (
      // noBorderTop logic doesn't follow size of screen but i don't have better solution now, this should disappear with new design system
      <div className="overflow-x-hidden flex w-full">
        <ContainerRow height={"42px"} borderBottom nonClickable noBorderTop={transactions.length > 10}>
          <TablePagination
            className={classes.bottomRightInnerContainer}
            rowsPerPageOptions={[15, 25, 50, 100, 250, 500]}
            colSpan={desabledColumnsNameArray.length - hiddenColumns.length}
            count={totalCount}
            rowsPerPage={params.limit}
            page={params.page - 1}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationAction}
          />
        </ContainerRow>
      </div>
    )
  }, [
    classes.bottomRightInnerContainer,
    desabledColumnsNameArray,
    hiddenColumns,
    totalCount,
    params.limit,
    params.page,
    handleChangePage,
    handleChangeRowsPerPage,
    transactions.length,
  ])

  return (
    <>
      <div className={classes.container}>
        {rowsHeader}
        {rows}
      </div>
      {pagination}
    </>
  )
}

export default GeneratedTable
