import { encodeObjectToUrl } from "pure-shared"

import { BASE_API_URL } from "../variables"

/* eslint-disable @typescript-eslint/no-explicit-any */

const doFetch = async (
  endpoint: string,
  resolve: any,
  reject: any,
  { headers: additionalHeaders, ...options }: any,
  ignoreResult: boolean,
): Promise<void> => {
  try {
    const headers: Record<string, any> = {
      Accept: "application/json, text/plain, */*",
      ...additionalHeaders,
    }
    if (additionalHeaders === undefined && options.body !== undefined) {
      headers["Content-Type"] = "application/json"
    }

    const response = await fetch(`${BASE_API_URL}${endpoint}`, {
      ...options,
      headers,
      credentials: "include",
    })
    if (!response.ok) {
      //@ts-ignore
      reject(await response.json())
      return
    }
    if (ignoreResult) {
      resolve()
    } else {
      const data = await response.json()
      resolve(data)
    }
  } catch (ex) {
    reject(ex)
  }
}

const rest = {
  encodeObjectToUrl,

  get: <T>(endpoint: string): Promise<T> =>
    new Promise<T>((resolve, reject) => {
      return doFetch(
        endpoint,
        resolve,
        reject,
        {
          method: "GET",
        },
        false,
      )
    }),

  post: <TResult>(endpoint: string, data: any = {}, ignoreResult = false): Promise<TResult> =>
    new Promise<TResult>((resolve, reject) => {
      return doFetch(
        endpoint,
        resolve,
        reject,
        {
          method: "POST",
          body: JSON.stringify(data),
        },
        ignoreResult,
      )
    }),

  uploadFile: <TResult>(
    endpoint: string,
    method: "POST" | "PUT",
    data: any = {},
    ignoreResult = false,
  ): Promise<TResult> =>
    new Promise<TResult>((resolve, reject) => {
      return doFetch(
        endpoint,
        resolve,
        reject,
        {
          method,
          body: data,
          headers: {},
        },
        ignoreResult,
      )
    }),

  patch: <TResult>(endpoint: string, data: any = {}, ignoreResult = false): Promise<TResult> =>
    new Promise<TResult>((resolve, reject) => {
      return doFetch(
        endpoint,
        resolve,
        reject,
        {
          method: "PATCH",
          body: JSON.stringify(data),
        },
        ignoreResult,
      )
    }),

  put: <TResult>(endpoint: string, data: any = {}, ignoreResult = false): Promise<TResult> =>
    new Promise<TResult>((resolve, reject) => {
      return doFetch(
        endpoint,
        resolve,
        reject,
        {
          method: "PUT",
          body: JSON.stringify(data),
        },
        ignoreResult,
      )
    }),

  delete: (endpoint: string, data: any | undefined): Promise<void> =>
    new Promise((resolve, reject) => {
      if (data === undefined) {
        return doFetch(
          endpoint,
          resolve,
          reject,
          {
            method: "DELETE",
          },
          true,
        )
      } else {
        return doFetch(
          endpoint,
          resolve,
          reject,
          {
            method: "DELETE",
            body: JSON.stringify(data),
          },
          true,
        )
      }
    }),
  formData: <TResult>(endpoint: string, data: any = {}, method: string = "POST", ignoreResult = false) =>
    new Promise<TResult>((resolve, reject) => {
      return doFetch(
        endpoint,
        resolve,
        reject,
        {
          method,
          body: data,
          headers: {},
        },
        ignoreResult,
      )
    }),
}

export default rest
