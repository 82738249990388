import TooltipUI from "CryptioUI/Tooltip"
import React from "react"
import { BackOfficeGetAssetExchangeLinksDto } from "services/api/openapi"

export interface PermissionDisabledProps {
  children: JSX.Element
  placement?: "right" | "left" | "top" | "bottom"
  titlePermissionEnable?: string
  assetLinks: BackOfficeGetAssetExchangeLinksDto[]
}

const PermissionRemapAsset = (props: PermissionDisabledProps): JSX.Element => {
  if (props.assetLinks.some((x) => x.walletTypeId === "Fireblocks")) {
    return (
      // We do not allow to remap asset of fireblocks
      // Because it will distort fireblocks transactions since we use several wallet_source for this custody
      <TooltipUI
        side={props.placement}
        content={`Following the ledgers update, we do not allow to remap asset of fireblocks because it will distort fireblocks transactions. If you have any question, please reach out to the tech team.`}
      >
        <span>{React.cloneElement(props.children, { disabled: true })}</span>
      </TooltipUI>
    )
  }

  return <>{props.children}</>
}

export default PermissionRemapAsset
