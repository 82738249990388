import { Box, Typography } from "@material-ui/core"
import React, { useContext } from "react"
import { ReactComponent as Delete } from "CryptioUI/assets/icons/delete.svg"

import { DrawerCategory } from "components/Drawer/DrawerItems"
import useDialog from "components/misc/useDialog"
import { DrawerProp } from "components/misc/useDrawer"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { GetCOAMappingDto } from "services/api/aliases"
import { COAAccountElement, COAAssetElement, IndividualMappingTypeEnum } from "services/api/openapi"
import MappingSummary from "./MappingSummary"
import { iconStyleBlack } from "CryptioUI/Utilities/config"
import MappingFormEdition from "./MappingFormEdition"
import MappingFormCreation from "./MappingFormCreation"
import { AccountingFilterContext } from "services/context/accountingFilterContext"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

export interface IndividualMappingItem {
  asset: COAAssetElement
  account: COAAccountElement
  type: IndividualMappingTypeEnum
}

const MappingDrawer = (props: DrawerProp<GetCOAMappingDto, true>) => {
  const { item: mapping, onClose } = props

  const toast = useToast()
  const basicDialog = useDialog()
  const { isNeedReview } = useContext(AccountingFilterContext)

  const { mutateAsync: deleteMappingMutation } = api.chartAccount.useDeleteMapping()

  const doDeleteMapping = async () => {
    if (!mapping || mapping.type === "default_mapping") return
    try {
      await deleteMappingMutation({ mappingId: mapping.id })
      toast.open("Mapping deleted", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
    onClose()
  }

  const askDeleteMapping = () => {
    if (!mapping) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <Typography variant="h5">Do you really want to delete the mapping? This action is irreversible.</Typography>
      ),
      yesText: "Yes",
      noText: "Cancel",
      onAccept: doDeleteMapping,
    })
  }

  const isEditMode = !!mapping

  return (
    <Box mb={3}>
      {basicDialog.dialog}

      {mapping && <MappingSummary mapping={mapping} />}

      {isEditMode && !isNeedReview ? <MappingFormEdition {...props} /> : <MappingFormCreation {...props} />}

      {isEditMode && mapping?.type !== "default_mapping" && (
        <DrawerCategory title="Mapping deletion">
          <Box mt={3} />
          <Typography variant="body2">Click to delete this mapping. This action is irreversible.</Typography>
          <Box mt={2} />
          <ButtonUI Icon={<Delete className={iconStyleBlack} />} mode={Mode.CONTAINED} onClick={askDeleteMapping}>
            Delete
          </ButtonUI>
        </DrawerCategory>
      )}
    </Box>
  )
}

export default MappingDrawer
