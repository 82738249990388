import { Box } from "@material-ui/core"
import React from "react"

export function RequiredIndicator() {
  return (
    <Box component="span" color="red">
      *
    </Box>
  )
}
