import { Box, Typography } from "@material-ui/core"
import React, { useContext } from "react"
import { WorkspaceContext } from "services/context/workspaceContext"
import { BASE_FRONT_URL } from "services/variables"

import { DrawerCategory, DrawerSection } from "../Drawer/DrawerItems"
import BackOfficeView from "./BackOfficeView"
import CopyableText from "./copyableText"

export interface Information {
  id: string
  extended: string
  shareUrl?: string
  createdAt?: string
}

interface Props {
  infos: Information[]
}

const AdministratorInformation = (props: Props): JSX.Element => {
  const { infos } = props
  const { workspace } = useContext(WorkspaceContext)

  return (
    <BackOfficeView>
      <Box>
        <DrawerCategory title="Administrator information">
          {infos?.map((info) => {
            const fullShareUrl =
              info.shareUrl &&
              `${BASE_FRONT_URL}/redirect/workspace/${workspace.id}?workspaceRedirect=${encodeURI(info.shareUrl)}`
            return (
              <div key={info.id}>
                <DrawerSection name={`${info.extended} ID`}>
                  <Typography variant="body2" color="textSecondary">
                    <CopyableText>{info.id}</CopyableText>
                  </Typography>
                </DrawerSection>
                {fullShareUrl && (
                  <DrawerSection name={`Filtered view`}>
                    <Typography variant="body2" color="textSecondary">
                      <CopyableText text={fullShareUrl}>Share view</CopyableText>
                    </Typography>
                  </DrawerSection>
                )}
                {info.createdAt && (
                  <DrawerSection name="Created at">
                    <Typography variant="body2" color="textSecondary">
                      <CopyableText>{info.createdAt}</CopyableText>
                    </Typography>
                  </DrawerSection>
                )}
              </div>
            )
          })}
        </DrawerCategory>
      </Box>
    </BackOfficeView>
  )
}

export default AdministratorInformation
