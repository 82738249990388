import { Box, Switch, Typography } from "@material-ui/core"
import React, { useCallback, useContext, useMemo, useState } from "react"

import { UncontrolledLoadingButton } from "components/LoadingButton"
import DialogDataConfirmQuit from "components/misc/BasicDialogContext/ReusableContextData/ConfirmQuit"
import useDialog from "components/misc/useDialog"
import { ErrorModal, LoadingModal } from "components/Modal/MiscModal"
import DebouncedTextField from "components/selector/DebouncedTextField"
import BaseTable from "components/Table"
import { GenericSorting, IExtendedDataTableColumn } from "components/Table/interface"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import { GetChartAccountDto, GetChartAccountsRequest, GetChartAccountsSortByEnum } from "services/api/openapi"
import { ThemeContext } from "services/context/themeContext"
import { DataTableSelectedRowState } from "services/utils/types"
import { widgetTableStyle } from "scenes/Dashboard/widgets/widgetTableStyle"
import { usePaginatedParamsWithoutURL } from "services/misc/usePaginatedParamsWithoutUrl"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const columns: IExtendedDataTableColumn<GetChartAccountDto, GetChartAccountsSortByEnum>[] = [
  {
    name: "Code",
    selector: "code",
    sortName: "code",
    sortable: true,
  },
  {
    name: "Name",
    selector: "name",
    sortName: "name",
    sortable: true,
  },
  {
    name: "Type",
    selector: "type",
  },
  // {
  //   name: "Color",
  //   selector: "color",
  //   format: function formatColor(row) {
  //     return <ChartAccountCircleColor color={row.color} />
  //   },
  // },
]

const defaultChartAccountSorting: GenericSorting<GetChartAccountsSortByEnum> = {
  sortDirection: "descending",
  sortBy: "name",
}

const defaultChartAccountParams: WithoutWorkspaceId<GetChartAccountsRequest> = {
  types: undefined,
  excludedIds: undefined,
  page: 0,
  limit: 15,
  visibleState: "all",
}

const ChartAccountUpdateModal = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const muiThemeContext = useContext(ThemeContext)
  const basicDialog = useDialog()

  const [selectedRows, setSelectedRows] = useState<DataTableSelectedRowState<GetChartAccountDto>>({
    allSelected: false,
    selectedCount: 0,
    selectedRows: [],
  })

  const { params, setPage, setSorting, setParams } = usePaginatedParamsWithoutURL<
    Partial<WithoutWorkspaceId<GetChartAccountsRequest>>,
    GetChartAccountsSortByEnum
  >(defaultChartAccountParams, defaultChartAccountSorting)
  const setQuery = useCallback((query: string) => setParams({ query, page: 1 }), [setParams])
  const accounts = api.chartAccount.useChartAccounts(params)
  const { mutateAsync: updateChartAccountVisibleMutation } = api.chartAccount.useUpdateChartAccountVisible()
  const toast = useToast()

  const onClose = () => setIsOpen(false)

  const accountUpdate = useMemo(() => {
    if (!accounts.data) return { newVisibleAccounts: [], newHiddenAccount: [] }
    const newVisibleAccounts = accounts.data.data.filter(
      (acc) => !acc.visible && selectedRows.selectedRows.some((row) => row.id === acc.id),
    )
    const newHiddenAccount = accounts.data.data.filter(
      (acc) => acc.visible && !selectedRows.selectedRows.some((row) => row.id === acc.id),
    )

    return { newVisibleAccounts, newHiddenAccount }
  }, [accounts.data, selectedRows.selectedRows])

  if (accounts.isError) return <ErrorModal additionalData={accounts} isOpen={isOpen} onClose={onClose} />
  if (accounts.isLoading || accounts.data === undefined) return <LoadingModal isOpen={isOpen} onClose={onClose} />

  const isStateDirty = !!(accountUpdate.newHiddenAccount.length || accountUpdate.newVisibleAccounts.length)

  const saveChanges = async () => {
    try {
      await updateChartAccountVisibleMutation({
        updateChartAccountsVisibleDto: {
          hideAccountIds: accountUpdate.newHiddenAccount.map((x) => x.id),
          showAccountIds: accountUpdate.newVisibleAccounts.map((x) => x.id),
        },
      })
      toast.open("Accounts updated", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const onChangePage = (page: number) => {
    if (isStateDirty) {
      basicDialog.showDialog({
        title: "Drop current page changes?",
        content: <Typography variant="h5">Do you want to drop the current changes of the page?</Typography>,
        yesText: "Yes",
        noText: "Cancel",
        onAccept: () => setPage(page),
      })
    } else {
      setPage(page)
    }
  }

  const closeDrawer = () => {
    if (isStateDirty) {
      basicDialog.showDialog(DialogDataConfirmQuit(onClose))
    } else onClose()
  }

  return (
    <>
      {basicDialog.dialog}
      <ButtonUI onClick={() => setIsOpen(true)}>View imported COA</ButtonUI>
      <ModalUI isOpen={isOpen} onClose={closeDrawer} className="w-[1000px] h-auto" title="Import Chart of accounts">
        <Box mt={1} mb={1} mr={1} display="flex" justifyContent="flex-end">
          <DebouncedTextField
            placeholder="Search by code or name"
            value={params.query}
            setValue={setQuery}
            disabled={isStateDirty}
          />
        </Box>
        <BaseTable<GetChartAccountDto, GetChartAccountsSortByEnum>
          columns={columns}
          items={accounts.data.data}
          fixedScroll="400px"
          onSelectedChange={setSelectedRows}
          paginationRowsPerPageOptions={[10, 15, 30]}
          paginationTotalRows={accounts.data.totalCount}
          paginationPerPage={15}
          pagination
          paginationServer
          customStyle={widgetTableStyle(muiThemeContext.type)}
          selectableRowsComponent={Switch}
          onChangePage={onChangePage}
          onChangeRowsPerPage={(newLimit: number) => setParams({ limit: newLimit })}
          onSort={setSorting}
          selectableRowSelected={(row) => row.visible}
          selectableRowsNoSelectAll
        />
        <div className="flex flex-row gap-x-4 justify-end">
          <ButtonUI onClick={closeDrawer} mode={Mode.CONTAINED}>
            Close
          </ButtonUI>
          <UncontrolledLoadingButton disabled={!isStateDirty} onClick={saveChanges}>
            Save current page
          </UncontrolledLoadingButton>
        </div>
      </ModalUI>
    </>
  )
}

export default ChartAccountUpdateModal
