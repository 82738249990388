import { Box, Typography } from "@material-ui/core"
import { TransactionSynchronizationSubcomponentsProps } from "."
import ButtonUI from "../../../../CryptioUI/Button"
import api from "../../../../services/api"
import { ReactComponent as Warning } from "../../../../CryptioUI/assets/icons/warning.svg"
import TypographyUI from "../../../../CryptioUI/Typography"
import { BoldWeight, TypographyVariant } from "../../../../CryptioUI/types"
import { iconStyleWhite } from "../../../../CryptioUI/Utilities/config"
import TooltipUI from "CryptioUI/Tooltip"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

function ToggleManuallySyncedButton({
  transaction,
  isCustomIntegration,
}: TransactionSynchronizationSubcomponentsProps) {
  const toast = useToast()

  const isSynchronized = transaction.accountingJournal?.status === "synced"
  const { mutateAsync: bulkUpdateMovementMutation } = api.transaction.useBulkUpdateMovements()

  const toggleManuallySyncedTransaction = async () => {
    try {
      await bulkUpdateMovementMutation({
        movementBulkUpdateDto: {
          synchronizationAction: isSynchronized ? "markAsUnsynchronized" : "manuallyMarkAsSynchronized",
          individualTransactions: {
            transactionIds: [transaction.id],
          },
        },
      })

      toast.open(`Marking one transaction as ${isSynchronized ? "unsynced" : "synced"}...`, { variant: "default" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const button = (
    <ButtonUI
      onClick={() => toggleManuallySyncedTransaction()}
      Icon={!isCustomIntegration ? <Warning className={iconStyleWhite} /> : undefined}
    >
      <TypographyUI variant={TypographyVariant.BUTTON} boldness={BoldWeight.MEDIUM}>
        Mark transaction as {isSynchronized ? "unsynced" : "synced"}
      </TypographyUI>
    </ButtonUI>
  )

  return (
    <Box mt={2}>
      {isCustomIntegration ? (
        button
      ) : (
        <TooltipUI
          content={
            isSynchronized ? (
              <Typography>This transaction has been manually reconciled. Click to mark it as unreconciled. </Typography>
            ) : (
              <Typography>
                This transaction has not been synced by our software. Click to mark this transaction as reconciled.
              </Typography>
            )
          }
        >
          {button}
        </TooltipUI>
      )}
    </Box>
  )
}

export default ToggleManuallySyncedButton
