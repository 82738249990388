import JsonCrush from "jsoncrush"
import { useMemo } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { URLS } from "../../routes"

import { formatUrl, paramUrlName } from "services/urlParse"

interface ParamsReturnType<TParam> {
  params: TParam
  setParams: (newParams: TParam) => void
}

export const useParams = <TParam>(defaultValue?: TParam): ParamsReturnType<TParam> => {
  const { search, pathname } = useLocation()
  const history = useHistory()

  const parameters = useMemo(() => {
    const urlParamQuery = new URLSearchParams(search)
    const rawParameters = urlParamQuery.get(paramUrlName)

    try {
      return rawParameters ? JSON.parse(JsonCrush.uncrush(rawParameters)) : {}
    } catch (e) {
      console.error(e)
      history.push(URLS.Portfolio)
    }
  }, [search, history])

  const setters = useMemo<ParamsReturnType<TParam>>(() => {
    const params: TParam = {
      ...defaultValue,
      ...parameters,
    }

    const setValue = (setter: (oldValue: TParam) => TParam) => {
      const newValue = setter(params)
      const newUrl = formatUrl<TParam>(pathname, newValue, defaultValue)
      history.push(newUrl)
    }

    return {
      params,
      setParams: (params: TParam) =>
        setValue((p) => {
          return { ...p, ...params }
        }),
    }
  }, [history, pathname, parameters, defaultValue])

  return setters
}
