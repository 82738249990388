import React, { useMemo, useState } from "react"

import { DrawerCategory, DrawerFormSection } from "../../../components/Drawer/DrawerItems"
import useFilterDrawer from "../../../components/Hooks/useFilterDrawer"
import InputClearable from "../../../components/Input/InputClearable"
import DrawerUI from "../../../CryptioUI/Drawer"
import BackOfficeView from "../../../components/misc/BackOfficeView"
import { defaultLabelParams, labelHasAnyFilter, LabelParameters } from "../index"
import { REGEX_UUID_V4 } from "pure-shared"
import { GetLabelQueryLabelTypeEnum } from "../../../services/api/openapi"
import { FormControl, MenuItem, Select } from "@material-ui/core"

interface Props {
  filter: LabelParameters
  setFilter: (newValue: LabelParameters) => void
}

const LabelFilter = (props: Props): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const { OpenButton, SaveButton, tempFilter, updateFilter, setIsErrorValidation } = useFilterDrawer({
    ...props,
    defaultParams: defaultLabelParams,
    setIsDrawerOpen,
  })

  const filterCount = useMemo(() => {
    const filter = props.filter
    let res = 0
    if (filter.labelType !== "all_label") res++
    if (filter.individualId !== undefined) res++
    if (filter.query !== undefined) res++
    return res
  }, [props.filter])

  const canClear = useMemo(() => {
    return filterCount > 0 || labelHasAnyFilter(tempFilter)
  }, [filterCount, tempFilter])
  const isUuid4 = (uuid: string) => REGEX_UUID_V4.test(uuid)

  const labelTypes = [
    { type: "all_label", displayName: "All labels" },
    { type: "my_label", displayName: "My labels" },
    { type: "global_label", displayName: "Global labels" },
  ]

  useMemo(() => {
    setIsErrorValidation(tempFilter.individualId !== undefined && !isUuid4(tempFilter.individualId))
  }, [setIsErrorValidation, tempFilter])

  return (
    <>
      <OpenButton filterCount={filterCount} />

      <DrawerUI title="Label Filter" onClose={() => setIsDrawerOpen(false)} isOpen={isDrawerOpen}>
        <DrawerCategory title="Filter">
          <DrawerFormSection htmlFor="label-type-select" name="Filter by label type">
            <FormControl fullWidth>
              <Select
                labelId="label-type-select-label"
                id="label-type-select"
                value={tempFilter.labelType}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  updateFilter({ labelType: event.target.value as GetLabelQueryLabelTypeEnum })
                }}
              >
                {labelTypes.map((labelType, index) => (
                  <MenuItem key={index} value={labelType.type}>
                    {labelType.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DrawerFormSection>
          <DrawerFormSection htmlFor="label-name-textfield" name="Filter by label name">
            <InputClearable
              id="label-name-textfield"
              onChange={(newValue) => updateFilter({ query: newValue ?? undefined })}
              value={tempFilter.query}
              fullWidth
            />
          </DrawerFormSection>
          <BackOfficeView>
            <DrawerFormSection htmlFor="label-id-textfield" name="Filter by label ID">
              <InputClearable
                id="label-id-textfield"
                onChange={(newValue) => updateFilter({ individualId: newValue ?? undefined })}
                value={tempFilter.individualId}
                fullWidth
                error={tempFilter.individualId !== undefined && !isUuid4(tempFilter.individualId)}
              />
            </DrawerFormSection>
          </BackOfficeView>

          <SaveButton filterCount={filterCount} canClear={canClear} />
        </DrawerCategory>
      </DrawerUI>
    </>
  )
}

export default LabelFilter
