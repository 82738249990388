import { useTypedController } from "@hookform/strictly-typed"
import { Box, DialogActions, makeStyles, Theme, Typography } from "@material-ui/core"
import { prettyEmptyOrNull } from "pure-shared"
import React, { useMemo } from "react"
import { useForm } from "react-hook-form"
import WarningIcon from "@material-ui/icons/Warning"

import AssetOptionInAutocomplete from "components/AutoCompleteOptions/AssetOptionInAutocomplete"
import ChartAccountOptionInAutocomplete from "components/AutoCompleteOptions/ChartAccountOptionInAutocomplete"
import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import CustomSelector from "components/selector/CustomSelector"
import api from "services/api"
import { GetChartAccountDto, IndividualMappingTypeEnum } from "services/api/openapi"
import { IndividualMappingItem } from "./MappingDrawer"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import ModalUI from "CryptioUI/Modal"

interface Props {
  addMapping: (newMapping: IndividualMappingItem) => void
  actualMapping: IndividualMappingItem[]
  mappingType: IndividualMappingTypeEnum | null
  isOpen: boolean
  walletIds?: string[]
  onClose: () => void
}

interface IndividualMappingForm {
  account: IndividualMappingItem["account"] | null
  asset: IndividualMappingItem["asset"] | null
}

const useStyles = makeStyles((theme: Theme) => ({
  iconWarning: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.4),
  },
}))

const DialogCreateIndividualMapping = ({
  addMapping,
  onClose,
  isOpen,
  actualMapping,
  mappingType,
  walletIds,
}: Props) => {
  const { handleSubmit, control, formState } = useForm<IndividualMappingForm>({
    mode: "onChange",
  })
  const TypedController = useTypedController<IndividualMappingForm>({ control })
  const [CreateMappingButton, handleButtonCallback] = useLoadingButton()
  const classes = useStyles()

  const createMapping = handleSubmit(async (form) => {
    if (form.account && form.asset && mappingType) {
      // TODO: fix: is async => enqueue toast ?
      addMapping({
        account: form.account,
        asset: form.asset,
        type: mappingType,
      })

      onClose()
      return
    }
  })

  const createMappingFormId = "create-mapping-form"

  const modalTitle = useMemo<string>(() => {
    switch (mappingType) {
      case "gains_mapping":
        return "Create individual mapping for gains"
      case "losses_mapping":
        return "Create individual mapping for losses"
      default:
        return "Create individual mapping"
    }
  }, [mappingType])

  return (
    <ModalUI isOpen={isOpen} onClose={onClose} title={modalTitle}>
      {/* <DialogTitle>
        <Typography variant="h3">Create individual mapping</Typography>
      </DialogTitle>
      <Divider /> */}

      <Typography variant="body1">Use the selectors below to associate an account to an asset.</Typography>
      <Box mt={1}>
        <Typography variant="body1">
          <WarningIcon className={classes.iconWarning} /> Please note that not all your assets and accounts are
          displayed in the selectors. Type the name of your asset/account to find it.
        </Typography>
      </Box>
      <Box component="form" id={createMappingFormId} onSubmit={handleButtonCallback(createMapping)} mb={1}>
        <DrawerFormSection htmlFor="asset-select" name="Asset">
          <TypedController
            name="asset"
            rules={{ required: true }}
            defaultValue={null}
            render={({ ref, onChange, ...rest }) => (
              <CustomSelector
                id="asset-select"
                {...rest}
                inputRef={ref}
                filterOptions={(x) => x}
                maxAutocompletionResults={50}
                minInputLengthForScroll={2}
                onChange={(_, newValue) => onChange(newValue)}
                getOptionLabel={(option) => option.name || prettyEmptyOrNull(option.symbol)}
                defaultPaginatedQueryProps={{
                  // sortBy: "symbol",
                  type: "both",
                  excludedIds: actualMapping.filter((m) => m.type === mappingType).map((m) => m.asset.id),
                  walletIds,
                }}
                usePaginatedQuery={api.transaction.useAssets}
                size="small"
                placeholder="Type to search..."
                renderOption={(option) => <AssetOptionInAutocomplete asset={option} />}
                // renderInput={(params) => <TextField {...params} label="Asset" />}
              />
            )}
          />
        </DrawerFormSection>
        <DrawerFormSection htmlFor="account-select" name="Account">
          <TypedController
            name="account"
            rules={{ required: true }}
            defaultValue={null}
            render={({ ref: inputRef, onChange, ...propRest }) => (
              <CustomSelector
                id="account-select"
                {...propRest}
                inputRef={inputRef}
                maxAutocompletionResults={50}
                onChange={(_, newValue) => onChange(newValue)}
                getOptionLabel={(option) => option.name}
                defaultPaginatedQueryProps={{
                  sortBy: "name",
                  sortDirection: "ascending",
                  types: ["asset", "liability"],
                }}
                usePaginatedQuery={api.chartAccount.useChartAccounts}
                size="small"
                placeholder="Type to search..."
                filterSelectedOptions
                renderOption={(option) => <ChartAccountOptionInAutocomplete account={option as GetChartAccountDto} />}
              />
            )}
          />
        </DrawerFormSection>
      </Box>

      <DialogActions>
        <ButtonUI onClick={onClose} mode={Mode.OUTLINED}>
          Cancel
        </ButtonUI>
        <CreateMappingButton type="submit" form={createMappingFormId} disabled={!formState.isValid}>
          Create
        </CreateMappingButton>
      </DialogActions>
    </ModalUI>
  )
}

export default DialogCreateIndividualMapping
