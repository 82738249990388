import { getEnvValue } from "pure-shared"

export const BASE_API_URL: string = getEnvValue("REACT_APP_BASE_API_URL", "http://localhost:3030")
export const BASE_FRONT_URL: string = getEnvValue("REACT_APP_BASE_FRONT_URL", "http://localhost:3000")

const CRYPTIO_ENV: string = getEnvValue("REACT_APP_CRYPTIO_ENV", "local")
export const isProd = CRYPTIO_ENV === "prod"
// export const isLocal = CRYPTIO_ENV === "local"

// if the env is not provided, the default is true
export const isTestAccountEnabled: boolean = getEnvValue("REACT_APP_ENABLE_TEST_ACCOUNT", "1") === "1"
export const isIntercomEnabled = getEnvValue("REACT_APP_INTERCOM_ENABLED", "0") === "1"
export const QuickbooksEnv = getEnvValue("REACT_APP_QUICKBOOKS_ENV", "sandbox") as "sandbox" | "production"
export const isStripeEnabled = getEnvValue("REACT_APP_STRIPE_ENABLED", "0") === "1"
export const StripePublishableKey = getEnvValue("REACT_APP_STRIPE_PUBLISHABLE_KEY", "none")
export const stripeIsLiveEnvironment = StripePublishableKey.startsWith("pk_live")
export const BillingEnabled = getEnvValue("REACT_APP_BILLING_ENABLED", "0") === "1"
export const currentVersion = getEnvValue("REACT_APP_CURRENT_COMMIT", "")
export const isMixpanelEnabled = getEnvValue("REACT_APP_MIXPANEL_ENABLED", "0") === "1"
export const gtagEnabled = getEnvValue("REACT_APP_GTAG_ENABLED", "") === "1"
export const gtagId = getEnvValue("REACT_APP_GTAG_ID", "")
export const gadsSignupConversionLabel = getEnvValue("REACT_APP_GADS_SIGNUP_CONVERSION_LABEL", "")
export const isOnboardingEnabled: boolean = getEnvValue("REACT_APP_ONBOARDING_ENABLED", "0") === "1"
export const gdprConsentVersion = getEnvValue("GDPR_CONSENT_VERSION", "1")
export const gdprConsentLocalStorageKey = getEnvValue("GDPR_CONSENT_LOCAL_STORAGE_KEY", "cryptioGDPRConsent")
export const zendeskKey = getEnvValue("REACT_APP_ZENDESK_KEY", "none")
