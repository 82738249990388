import { IconButton, makeStyles, Theme } from "@material-ui/core"
import copy from "copy-to-clipboard"
import { useToast } from "CryptioUI/Toaster"
import TooltipUI from "CryptioUI/Tooltip"
import { TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import React from "react"

import TypographyAllowEvent from "../TypographyAllowEvent"
import CopyIcon from "./copyIcon"

interface Props {
  children: React.ReactNode
  text?: string
  spaceBetween?: boolean
  allowEvent?: boolean
  noTypography?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  rootSpaced: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "space-between",
  },
  copyButton: {
    marginLeft: theme.spacing(1),
    padding: 0,
  },
}))

/*
  use Children if it's a string, or text if provided
 */
const CopyableText = (props: Props): JSX.Element => {
  const classes = useStyles()
  const toast = useToast()

  const copyText = () => {
    if ((typeof props.children === "string" || props.children instanceof String) && !props.text)
      copy(props.children as string)
    else if (props.text) copy(props.text)
    toast.open("Copied to the clipboard", { variant: "default" })
  }

  const Typo = props.allowEvent ? TypographyAllowEvent : TypographyUI
  return (
    <span className={props.spaceBetween ? classes.rootSpaced : classes.root}>
      {props.noTypography ? (
        props.children
      ) : (
        <Typo variant={TypographyVariant.BODY} className="overflow-hidden whitespace-nowrap text-ellipsis">
          {props.children}
        </Typo>
      )}
      <TooltipUI content="Copy the text to the clipboard">
        <IconButton
          aria-label="copy"
          size="small"
          onClick={copyText}
          className={classes.copyButton}
          data-tag="allowRowEvents"
        >
          <CopyIcon />
        </IconButton>
      </TooltipUI>
    </span>
  )
}

export default CopyableText
