import { useTypedController } from "@hookform/strictly-typed"
import { Box, Checkbox, FormLabel, makeStyles } from "@material-ui/core"
import React, { useContext } from "react"
import { Control } from "react-hook-form"
import { SynchronizationBulkForm, TransactionBulkForm } from "./index"

import PermissionDisabled from "../../Permission/PermissionDisabled"
import { WorkspaceContext } from "services/context/workspaceContext"
import WarningTypography from "components/WarningTypography"
import { prettyAccountingIntegrationNameMap } from "pure-shared"
import BackOfficeView from "../../misc/BackOfficeView"

interface Props {
  control: Control<TransactionBulkForm>
}

const useStyles = makeStyles(() => ({
  checkbox: {
    paddingLeft: 0,
  },
}))

const BulkManuallySync = (props: Props) => {
  const { control } = props
  const classes = useStyles()
  const TypedController = useTypedController<TransactionBulkForm>({ control })
  const workspaceCtx = useContext(WorkspaceContext)

  const atMostOneOption = (form: SynchronizationBulkForm) => {
    const options = [form.syncToAccountingSystem, form.markAsUnsynchronized, form.markAsManuallySynchronized]
    const numberOfOptions = options.filter(Boolean).length

    return numberOfOptions <= 1
  }

  return (
    <Box mt={2}>
      <TypedController
        name="synchronization"
        defaultValue={{
          syncToAccountingSystem: false,
          markAsManuallySynchronized: false,
          markAsUnsynchronized: false,
        }}
        rules={{
          validate: atMostOneOption,
        }}
        render={({ onChange, value, ...rest }) =>
          workspaceCtx.workspace.accountingIntegration === null ? (
            <></>
          ) : (
            <>
              {workspaceCtx.workspace.accountingIntegration.name !== "custom" && (
                <>
                  <div className="mt-4 mb-4">
                    <BackOfficeView
                      fallback={
                        <WarningTypography>
                          Only the unbookable complex transactions can be manually marked as synchronized
                        </WarningTypography>
                      }
                    >
                      <WarningTypography>Back office users can mark any transaction as synchronized</WarningTypography>
                    </BackOfficeView>
                  </div>
                  <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
                    <Checkbox
                      id="sync-software-checkbox"
                      color="primary"
                      className={classes.checkbox}
                      onChange={() =>
                        onChange({
                          syncToAccountingSystem: !value.syncToAccountingSystem,
                          markAsManuallySynchronized: value.markAsManuallySynchronized,
                          markAsUnsynchronized: value.markAsUnsynchronized,
                        })
                      }
                      checked={value.syncToAccountingSystem}
                      disabled={value.markAsManuallySynchronized || value.markAsUnsynchronized}
                      {...rest}
                    />
                  </PermissionDisabled>
                  <FormLabel htmlFor="sync-software-checkbox">
                    {`Synchronize transactions with ${
                      prettyAccountingIntegrationNameMap[workspaceCtx.workspace.accountingIntegration.name]
                    }`}
                  </FormLabel>
                </>
              )}
              <Box>
                <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
                  <Checkbox
                    id="mark-synchronized-checkbox"
                    color="primary"
                    className={classes.checkbox}
                    onChange={() =>
                      onChange({
                        syncToAccountingSystem: value.syncToAccountingSystem,
                        markAsManuallySynchronized: !value.markAsManuallySynchronized,
                        markAsUnsynchronized: value.markAsUnsynchronized,
                      })
                    }
                    checked={value.markAsManuallySynchronized}
                    disabled={value.syncToAccountingSystem || value.markAsUnsynchronized}
                    {...rest}
                  />
                </PermissionDisabled>
                <FormLabel htmlFor="mark-synchronized-checkbox">Mark transactions as synchronized</FormLabel>
              </Box>

              <Box>
                <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
                  <Checkbox
                    id="mark-unsynchronized-checkbox"
                    color="primary"
                    className={classes.checkbox}
                    onChange={() =>
                      onChange({
                        syncToAccountingSystem: value.syncToAccountingSystem,
                        markAsManuallySynchronized: value.markAsManuallySynchronized,
                        markAsUnsynchronized: !value.markAsUnsynchronized,
                      })
                    }
                    checked={value.markAsUnsynchronized}
                    disabled={value.syncToAccountingSystem || value.markAsManuallySynchronized}
                    {...rest}
                  />
                </PermissionDisabled>
                <FormLabel htmlFor="mark-unsynchronized-checkbox">Unmark transactions as synchronized</FormLabel>
              </Box>
            </>
          )
        }
      />
    </Box>
  )
}

export default BulkManuallySync
