import { prettyEmptyOrNull } from "pure-shared"
import { FilterElement } from "../api/openapi"
import { Tabs } from "../context/filterContext"
import { TransactionParams } from "../../components/CoreTransaction"

type NullableFilterElement = { id: string; name: string | null }
export default function toFilterElement<T extends FilterElement | NullableFilterElement>(el: T): FilterElement {
  return {
    id: el.id,
    name: prettyEmptyOrNull(el.name),
  }
}

export const reviewFilters: Record<Tabs, TransactionParams> = {
  missingLabel: { filters: [{ type: "labels", value: null, isNot: true }] },
  internalTransfer: { filters: [{ type: "internal_transfer_status", value: "potential", isNot: false }] },
  incompleteChartOfAccounts: {
    filters: [
      { type: "accounting_mapping_status", value: "completeMapping", isNot: true },
      { type: "synchronization_status", value: "synced", isNot: true },
    ],
  },
  readyToBeSynchronized: {
    filters: [
      { type: "synchronization_status", value: "synced", isNot: true },
      { type: "cost_basis_error_statuses", value: null, isNot: true },
      { type: "accounting_mapping_status", value: "completeMapping", isNot: false },
    ],
  },
}
