import React from "react"

import { TextLinkWrapperProps } from "../types"

const TextLinkWrapper = (props: TextLinkWrapperProps): JSX.Element => {
  const { children, url, target } = props

  return (
    <>
      {url ? (
        <a href={url} target={target}>
          {children}
        </a>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default TextLinkWrapper
