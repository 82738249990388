import { Box, Link, makeStyles, Theme } from "@material-ui/core"
import { HelpRounded } from "@material-ui/icons"
import React from "react"

interface Props {
  helpUrl: string
}

const useStyles = makeStyles((theme: Theme) => ({
  linkNoDecoration: {
    "paddingTop": theme.spacing(0.75),
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}))

const FieldHelper = ({ helpUrl }: Props) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Link className={classes.linkNoDecoration} target="_blank" rel="noopener noreferrer" href={helpUrl}>
        <HelpRounded />
      </Link>
    </Box>
  )
}

export default FieldHelper
