import { Box, DialogActions, DialogTitle, Typography } from "@material-ui/core"
import React from "react"
import { useForm } from "react-hook-form"

import { useLoadingButton } from "components/LoadingButton"
import useDialog from "components/misc/useDialog"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import api from "services/api"
import { GetWalletDto } from "services/api/openapi"
import { AdditionalInfoBanners } from "../WalletDialog/AdditionalInfoBanners"
import BasicString from "../WalletDialog/Source/Fields/BasicString"
import HiddenString from "../WalletDialog/Source/Fields/HiddenString"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface Props {
  isOpen: boolean
  onClose: () => void
  wallet: GetWalletDto
}

type GenericFieldSourceForm = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

const ImportUpdateCredentialDialog = (props: Props): JSX.Element => {
  const { onClose, isOpen, wallet } = props
  const toast = useToast()
  const basicDialog = useDialog()
  const { mutateAsync: updateWalletCredentialsMutation } = api.wallet.useUpdateWalletCredentials()

  const { handleSubmit, control, formState } = useForm({ mode: "onChange" })

  const [ImportButton, handleButtonCallback] = useLoadingButton()

  const types = api.wallet.useTypes({ onlyInWorkspace: false })
  const fieldWallet = types?.data?.filter((source) => source.name === wallet.sourceName)

  const submitImport = async (form: GenericFieldSourceForm) => {
    try {
      await updateWalletCredentialsMutation({
        walletId: wallet.id,
        updateWalletCredentialDto: {
          credentials: form,
        },
      })
      toast.open(`${wallet.name} credentials updated`, {
        variant: "success",
      })
      props.onClose()
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const importFormId = "wallet-import-form"

  if (fieldWallet === undefined) return <></>
  const source = fieldWallet[0]

  if (!source?.credentialFields) return <></>
  const formFields = source.credentialFields.map((field) => {
    if (!field.isUpdatable) return <div key={field.name}></div>
    switch (field.type) {
      case "string":
        return <BasicString key={field.name} isRequired={field.isRequired} field={field} control={control} />
      case "hidden-string":
        return <HiddenString isRequired={field.isRequired} key={field.name} field={field} control={control} />
    }
  })

  return (
    <ModalUI isOpen={isOpen} onClose={onClose}>
      {basicDialog.dialog}
      <DialogTitle id="form-dialog-title" disableTypography>
        <Typography variant="h1" component="h2" style={{ textAlign: "center" }}>
          Update credentials
        </Typography>
      </DialogTitle>
      <Box mr={3} ml={3}>
        <form id={importFormId} onSubmit={handleButtonCallback(handleSubmit(submitImport))}>
          <Box mt={2}>
            <AdditionalInfoBanners sourceName={source.name} />
          </Box>
          <Box mt={4}>{formFields}</Box>
        </form>
      </Box>
      <Box mr={2} ml={2} mb={1} mt={3}>
        <DialogActions>
          <ButtonUI onClick={onClose}>Cancel</ButtonUI>
          <PermissionDisabled permission="can_modify_wallet" action="import sources">
            <ImportButton disabled={!formState.isValid} type="submit" form={importFormId}>
              Update credentials
            </ImportButton>
          </PermissionDisabled>
        </DialogActions>
      </Box>
    </ModalUI>
  )
}

export default ImportUpdateCredentialDialog
