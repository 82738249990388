import React from "react"

import { Box } from "@material-ui/core"

interface TabPanelProps {
  children: React.ReactNode
  tab: number
  index: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, tab, index } = props

  return (
    <div role="tabpanel" hidden={tab !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
      {tab === index && <Box>{children}</Box>}
    </div>
  )
}

export default TabPanel
