import { ReportColumnType } from "pure-shared"
import { GetReportTypeDto, ReportTypeFormatDto } from "services/api/openapi"

export type ReportExportFormatIdentifier = {
  [K in keyof ReportColumnType]: { type: K; exportFormatName?: keyof ReportColumnType[K] & string }
}[keyof ReportColumnType]

export const matchReportExportFormatIdentifier = (
  identifiers: ReportExportFormatIdentifier[],
  {
    reportType,
    reportFormat,
  }: {
    reportType: GetReportTypeDto
    reportFormat: ReportTypeFormatDto
  },
) => {
  return identifiers.some(
    (v) => v.type === reportType.name && (v.exportFormatName === undefined || v.exportFormatName === reportFormat.name),
  )
}
