import { Box, DialogActions, makeStyles, Theme, Typography } from "@material-ui/core"
import { VisibilityRounded } from "@material-ui/icons"
import BigNumber from "bignumber.js"
import numbro from "numbro"
import React, { useContext, useMemo, useState } from "react"

import { IExtendedDataTableColumn } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { GetOverviewBalancesSortByEnum, OverviewBalanceDto, OverviewWalletElement } from "services/api/openapi"
import { DEFAULT_ROWS_PER_PAGE } from "services/constants"
import { ThemeContext } from "services/context/themeContext"
import { WorkspaceContext } from "services/context/workspaceContext"
import { getCode, getSymbol } from "services/utils/AssetSymbols"
import { prettyNumber } from "services/utils/processNumber"
import { CurrencyLogo } from "./CurrencyLogo"
import { widgetTableStyle } from "./widgetTableStyle"
import { TypographyVariant } from "CryptioUI/types"
import TypographyUI from "CryptioUI/Typography"
import TooltipUI from "CryptioUI/Tooltip"

const useStyles = makeStyles((theme: Theme) => ({
  symbol: {
    paddingLeft: theme.spacing(2),
    color: "rgba(0, 0, 0, 0.54)",
  },
  action: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  namePadding: {
    paddingLeft: theme.spacing(2),
  },
  SourcesContainer: {
    overflowY: "auto",
  },
}))

interface Props {
  assets: OverviewBalanceDto[]
}

const DashboardAllocation = ({ assets }: Props): JSX.Element => {
  const muiThemeContext = useContext(ThemeContext)
  const { workspace } = useContext(WorkspaceContext)
  const userFiatSymbol = getSymbol(workspace.defaultCurrencySymbol)
  const userFiatCode = getCode(workspace.defaultCurrencySymbol)
  const classes = useStyles()
  const [subPage, setSubPage] = useState<number>(1)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [wallets, setWallets] = useState<OverviewWalletElement[]>([])
  const [rate, setRate] = useState<BigNumber | null>(null)

  const columns = useMemo<IExtendedDataTableColumn<OverviewBalanceDto, GetOverviewBalancesSortByEnum>[]>(
    () => [
      {
        name: "Asset",
        selector: "currency",
        minWidth: "35%",
        maxWidth: "250px",
        format: function AssetFormat(row) {
          return (
            <Box display="flex" alignItems="center">
              <CurrencyLogo asset={row} />
              {row.assetName && row.assetName.length > 0 ? (
                <Typography className={classes.namePadding} noWrap variant="h5">
                  {row.assetName}
                </Typography>
              ) : (
                <Typography className={classes.symbol} variant="h5">
                  No asset name
                </Typography>
              )}
              <Box display="flex" justifyContent="center">
                {row.assetSymbol && row.assetSymbol.length > 0 ? (
                  <Typography className={classes.symbol} variant="h5">
                    {row.assetSymbol}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          )
        },
      },
      {
        name: "Amount",
        selector: "amount",
        format: function AssetAmount(row) {
          const amount = new BigNumber(row.amount)
          return (
            <TooltipUI content={row.amount ?? ""}>
              <Box component="span">
                {numbro(amount.decimalPlaces(4, BigNumber.ROUND_UP)).format(prettyNumber(amount))}
              </Box>
            </TooltipUI>
          )
        },
      },
      {
        name: `${userFiatCode} Value`,
        selector: "amountInUsd",
        format: (row) => {
          // No amount in USD
          if (row.amountInUsd === null || row.amountInUsd?.length === 0) {
            return "-"
          }

          // No USD rate to multiply with the amount
          if (row.usdRateToDefaultCurrency === null || row.usdRateToDefaultCurrency.length === 0) {
            return "-"
          }

          const amount = new BigNumber(row.amountInUsd)
          return (
            numbro(amount.multipliedBy(row.usdRateToDefaultCurrency).decimalPlaces(2, BigNumber.ROUND_UP)).format(
              prettyNumber(amount),
            ) + ` ${userFiatSymbol}`
          )
        },
      },
      {
        name: "",
        selector: "action",
        format: function Actions(row) {
          return (
            <VisibilityRounded
              className={classes.action}
              onClick={() => {
                setIsOpen(true)
                setWallets(row.wallets)
                setRate(row.usdRateToDefaultCurrency ? new BigNumber(row.usdRateToDefaultCurrency) : null)
              }}
            />
          )
        },
      },
    ],
    [classes.action, classes.namePadding, classes.symbol, userFiatCode, userFiatSymbol],
  )

  const componentAsMemo = useMemo(() => {
    const ModalWallets = () => {
      const walletsColumns = useMemo<IExtendedDataTableColumn<OverviewWalletElement, GetOverviewBalancesSortByEnum>[]>(
        () => [
          {
            name: "Source",
            selector: "source",
            format: (row) => {
              return row.name
            },
          },
          {
            name: `Amount`,
            selector: "amount",
            format: function AssetAmount(row) {
              const amount = new BigNumber(row.amount)
              return (
                <TooltipUI content={row.amount ?? ""}>
                  <Box component="span">
                    {numbro(amount.decimalPlaces(4, BigNumber.ROUND_UP)).format(prettyNumber(amount))}
                  </Box>
                </TooltipUI>
              )
            },
          },
          {
            name: `${userFiatCode} Value`,
            selector: "value",
            sortable: true,
            sortName: "amount_in_usd",
            format: function AssetValue(row) {
              if (!row.amountInUsd || !rate) {
                return "-"
              }
              const amountInUsd = new BigNumber(row.amountInUsd)
              return (
                numbro(amountInUsd.multipliedBy(rate).decimalPlaces(2, BigNumber.ROUND_UP)).format(
                  prettyNumber(amountInUsd),
                ) + ` ${userFiatSymbol}`
              )
            },
          },
        ],
        [],
      )

      if (wallets === null) {
        return null
      }

      return (
        <ModalUI isOpen={isOpen} onClose={() => setIsOpen(false)} className="w-[700px] h-auto" title="Sources">
          <div className="w-full rounded-xl border-[1px] border-grey-200">
            <ServerSideTable<OverviewWalletElement, GetOverviewBalancesSortByEnum>
              defaultParams={{
                page: subPage,
              }}
              customStyle={widgetTableStyle(muiThemeContext.type)}
              columns={walletsColumns}
              pagination={wallets.length > DEFAULT_ROWS_PER_PAGE}
              items={wallets.slice(
                subPage * DEFAULT_ROWS_PER_PAGE - DEFAULT_ROWS_PER_PAGE,
                subPage * DEFAULT_ROWS_PER_PAGE - 1,
              )}
              limit={DEFAULT_ROWS_PER_PAGE}
              totalCount={wallets.length}
              onPageChange={(page) => setSubPage(page)}
            />
          </div>
          <Box>
            <DialogActions>
              <ButtonUI onClick={() => setIsOpen(false)}>Close</ButtonUI>
            </DialogActions>
          </Box>
        </ModalUI>
      )
    }
    if (!assets.length) {
      return (
        <div className="flex w-full justify-center items-center p-6">
          <TypographyUI variant={TypographyVariant.BODY}>Missing valuation.</TypographyUI>
        </div>
      )
    }
    return (
      <div className="w-full rounded-b-xl">
        <ModalWallets />
        <ServerSideTable<OverviewBalanceDto, GetOverviewBalancesSortByEnum>
          customStyle={widgetTableStyle(muiThemeContext.type)}
          pagination={false}
          columns={columns}
          items={assets}
          totalCount={assets.length}
        />
      </div>
    )
  }, [assets, columns, muiThemeContext.type, isOpen, rate, subPage, userFiatCode, userFiatSymbol, wallets])
  return componentAsMemo
}

export default DashboardAllocation
