import { Badge, Box, makeStyles } from "@material-ui/core"
import React, { useContext } from "react"
import { TransactionParams } from ".."
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import { GetTransactionNeedingReviewDto } from "services/api/openapi"
import { useHistory } from "react-router-dom"

import { FilterContext } from "services/context/filterContext"
import { WorkspaceContext } from "services/context/workspaceContext"
import { Mixpanel } from "services/mixpanel"
import { URLS } from "routes"
import { reviewFilters } from "../../../services/utils/toFilterElement"

const useStyles = makeStyles(() => ({
  tabOn: {
    opacity: 1,
  },
  tabOff: {
    opacity: 0.5,
  },
  buttonTab: {
    borderBottomWidth: "0px",
  },
}))

interface NeedReviewTabsParams {
  setParamsWithDialog: (newParams: TransactionParams) => void
  filter: TransactionParams
  labelMetrics: GetTransactionNeedingReviewDto | undefined
}

const NeedReviewTabs = (props: NeedReviewTabsParams): JSX.Element => {
  const { labelMetrics } = props
  const classes = useStyles()
  const { isNeedReview, isTab } = useContext(FilterContext)
  const { workspace } = useContext(WorkspaceContext)
  const history = useHistory()

  return (
    <>
      {isNeedReview ? (
        <Box display="flex" alignItems="flex-end" overflow-x="scroll">
          <Box mr={2} className={isTab("missingLabel") ? classes.tabOn : classes.tabOff}>
            <Badge badgeContent={labelMetrics?.noLabelCount} color="error">
              <ButtonUI
                className={classes.buttonTab}
                mode={isTab("missingLabel") ? Mode.DEFAULT : Mode.CONTAINED}
                onClick={() => {
                  Mixpanel.track("ClickMissingLabelReview")
                  history.push(URLS.TransactionsReviewMissingLabel.getUrl(reviewFilters.missingLabel))
                }}
              >
                Missing label
              </ButtonUI>
            </Badge>
          </Box>
          <Box mr={2} className={isTab("internalTransfer") ? classes.tabOn : classes.tabOff}>
            <Badge badgeContent={labelMetrics?.internalTransferNeedReviewCount} color="error">
              <ButtonUI
                className={classes.buttonTab}
                mode={isTab("internalTransfer") ? Mode.DEFAULT : Mode.CONTAINED}
                onClick={() => {
                  Mixpanel.track("ClickInternalTransferReview")
                  history.push(URLS.TransactionsReviewInternalTransfer.getUrl(reviewFilters.internalTransfer))
                }}
              >
                Internal transfer
              </ButtonUI>
            </Badge>
          </Box>
          {workspace.accountingIntegration !== null && (
            <Box mr={2} className={isTab("incompleteChartOfAccounts") ? classes.tabOn : classes.tabOff}>
              <Badge badgeContent={labelMetrics?.incompleteChartAccountCount} color="error">
                <ButtonUI
                  className={classes.buttonTab}
                  mode={isTab("incompleteChartOfAccounts") ? Mode.DEFAULT : Mode.CONTAINED}
                  onClick={() => {
                    Mixpanel.track("ClickIncompleteCOAReview")
                    history.push(URLS.TransactionsReviewIncompleteCOA.getUrl(reviewFilters.incompleteChartOfAccounts))
                  }}
                >
                  Incomplete COA
                </ButtonUI>
              </Badge>
            </Box>
          )}
          {workspace?.accountingIntegration && (
            <Box mr={2} className={isTab("readyToBeSynchronized") ? classes.tabOn : classes.tabOff}>
              <Badge badgeContent={labelMetrics?.readyToBeSynchronizedCount} color="error">
                <ButtonUI
                  className={classes.buttonTab}
                  mode={isTab("readyToBeSynchronized") ? Mode.DEFAULT : Mode.CONTAINED}
                  onClick={() => {
                    Mixpanel.track("ClickTxToBeSynced")
                    history.push(URLS.TransactionsReviewReadyToBeSynced.getUrl(reviewFilters.readyToBeSynchronized))
                  }}
                >
                  Ready to be synced
                </ButtonUI>
              </Badge>
            </Box>
          )}
        </Box>
      ) : (
        <Box></Box>
      )}
    </>
  )
}

export default NeedReviewTabs
