import React, { useContext } from "react"

import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import { useHistory } from "react-router"

import api from "services/api"

import { WorkspaceContext } from "services/context/workspaceContext"
import { ReactComponent as Help } from "CryptioUI/assets/icons/help-circle.svg"

import BigNumber from "bignumber.js"
import SumView from "components/SumView"
import numbro from "numbro"
import CardUI from "CryptioUI/Card"
import TypographyUI from "CryptioUI/Typography"
import { Mode, TypographyVariant } from "CryptioUI/types"
import ButtonUI from "CryptioUI/Button"
import TooltipUI from "CryptioUI/Tooltip"
import { iconStyleBlack } from "CryptioUI/Utilities/config"
import { URLS } from "routes"
import dayjs from "dayjs"
import { CircularProgress } from "@material-ui/core"

const DefiOverview = (): JSX.Element => {
  const workspaceCtx = useContext(WorkspaceContext)
  const defiPositions = api.workspace.useTotalDefiPositions(workspaceCtx.workspace.id)
  const history = useHistory()

  if (defiPositions.isError) return <NetworkErrorMessage additionalData={defiPositions} />
  if (defiPositions.isLoading || defiPositions.data === undefined) return <LoadingSpinner />

  const transformStringInBigNumberOrNull = (value: string | null) => {
    const transformedNumber = value ? new BigNumber(value).decimalPlaces(2, BigNumber.ROUND_UP) : null
    const tmp = numbro(transformedNumber?.toString()).format({ thousandSeparated: true })
    return transformedNumber ? tmp : transformedNumber
  }

  const totalDepositFiat = transformStringInBigNumberOrNull(defiPositions.data.totalDepositFiat)
  const totalClaimableFiat = transformStringInBigNumberOrNull(defiPositions.data.totalClaimableFiat)
  const totalValueFiat = transformStringInBigNumberOrNull(defiPositions.data.totalValueFiat)
  const totalDebts = transformStringInBigNumberOrNull(defiPositions.data.totalDebtFiat)
  const totalRealizedPAndL = transformStringInBigNumberOrNull(defiPositions.data.totalRealizedPnlFiat)
  const totalUnrealisedPAndL = transformStringInBigNumberOrNull(defiPositions.data.totalUnrealizedPnlFiat)
  const totalPAndL = transformStringInBigNumberOrNull(defiPositions.data.totalPnlFiat)

  return (
    <CardUI data-test-id="unrealized-gains-losses" fullWidth={true} className="flex flex-row justify-start items-start">
      <div className="flex flex-col justify-start items-start w-full p-8">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row justify-start items-center gap-2">
            <div className="w-fit">
              <TypographyUI variant={TypographyVariant.H4}>DeFi Overview</TypographyUI>
            </div>
            <TooltipUI content="Overview of DeFi positions on Ethereum, Polygon, Avalanche & BSC.">
              <Help className={iconStyleBlack} />
            </TooltipUI>
          </div>
          <div>
            <ButtonUI onClick={() => history.push(URLS.DeFi.getUrl())} mode={Mode.CONTAINED}>
              Browse all positions
            </ButtonUI>
          </div>
        </div>
        <div className="flex flex-row items-end w-full pt-8 gap-x-8">
          <SumView
            addends={[
              {
                addendsTitle: "Total Deposit",
                addendsValue: totalDepositFiat,
              },
              {
                addendsTitle: "Total Claimable",
                addendsValue: totalClaimableFiat,
                tooltipInfo: "Claimable interest and rewards from depositing liquidity into DeFi protocols",
              },
              {
                addendsTitle: "Total Debts",
                addendsValue: totalDebts,
                tooltipInfo: "Current USD value of assets borrowed from protocols",
                isNegative: true,
              },
            ]}
            sum={{
              sumTitle: "Total Value",
              sumValue: totalValueFiat,
            }}
          />
          <SumView
            addends={[
              {
                addendsTitle: "Realized P&L",
                addendsValue: totalRealizedPAndL,
              },
              {
                addendsTitle: "Unrealized P&L",
                addendsValue: totalUnrealisedPAndL,
              },
            ]}
            sum={{
              sumTitle: "Total P&L",
              sumValue: totalPAndL,
              tooltipInfo:
                "All-time P&L for the fully integrated protocols only (Aave, Compound, Lido & Uniswap). Fee included.",
            }}
          />
        </div>
        <div className="flex flex-row justify-end pt-6 w-full items-center gap-2">
          <TypographyUI className="text-grey-400" variant={TypographyVariant.BODY}>
            {`Last update: ${
              defiPositions.data.jobStatus.status === "pending"
                ? "Update in progress…"
                : dayjs(defiPositions.data.lastUpdate).format("LLL")
            }`}
          </TypographyUI>
          {defiPositions.data.jobStatus.status === "pending" && (
            <div className="flex justify-center items-center">
              <CircularProgress size={20} />
            </div>
          )}
        </div>
      </div>
    </CardUI>
  )
}

export default DefiOverview
