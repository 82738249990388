import { Box } from "@material-ui/core"
import React from "react"

import { DrawerCategory } from "components/Drawer/DrawerItems"
import ButtonUI from "CryptioUI/Button"
import api from "services/api"
import { GetWalletDto } from "services/api/openapi"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

export function ImportDrawerAutoFlagBorrowSection({ wallet }: { wallet: GetWalletDto }) {
  const toast = useToast()

  const { mutateAsync: toggleAutoFlagBorrowMutation } = api.wallet.useToggleAutoFlagBorrow()
  const { mutateAsync: updateAutoFlagBorrowMutation } = api.wallet.useUpdateAutoFlagBorrow()

  const toggleAutoFlagBorrow = async () => {
    try {
      await toggleAutoFlagBorrowMutation({
        walletId: wallet.id,
        walletToggleAutoFlagBorrow: {
          borrowAutoFlagEnabled: !wallet.autoFlagBorrowEnabled,
        },
      })
      toast.open("Auto flag borrow toggled", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const updateAutoFlagBorrow = async () => {
    try {
      await updateAutoFlagBorrowMutation({
        walletId: wallet.id,
      })
      toast.open("Auto flag borrow started...", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  if (!wallet.canEnableAutoFlagBorrow) {
    return <></>
  }

  return (
    <DrawerCategory title="Auto flag borrow">
      <Box mt={2}>
        <ButtonUI onClick={toggleAutoFlagBorrow}>
          {wallet.autoFlagBorrowEnabled ? "Disable" : "Enable"} auto flag borrow
        </ButtonUI>
      </Box>
      <Box mt={2}>
        <ButtonUI onClick={updateAutoFlagBorrow} disabled={!wallet.autoFlagBorrowEnabled}>
          Update auto borrow
        </ButtonUI>
      </Box>
    </DrawerCategory>
  )
}
