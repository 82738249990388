export enum TagSize {
  SM = "small",
  LG = "large",
}

export type TagColorType =
  | "indigo"
  | "red"
  | "turquoise"
  | "orange"
  | "brown"
  | "green"
  | "blue"
  | "grey"
  | "pink"
  | "cream"
  | "default"

// the duplicate colors are here to comply with both the old colors (and the old tags associated with them)
// as well as with the new colors of the design system
export const correspondanceHex: { [key: string]: TagColorType } = {
  "#94939F": "grey",
  "#EAAB00": "orange",
  "#4F57C8": "indigo",
  "#FA4739": "red",
  "#2FDC77": "green",
  "#2FAEDC": "turquoise",
  "#363650": "brown",
  "#FAFA39": "cream",
  "#93024D": "pink",
  "#9EA9BD": "grey",
  "#FFAB00": "orange",
  "#625DF5": "indigo",
  "#FF3366": "red",
  "#3DC45F": "green",
  "#27C9D6": "turquoise",
  "#A96D64": "brown",
  "#EDEBDD": "cream",
  "#FD80E9": "pink",
  "#03BEFC": "blue",
  "#69BBFF": "blue",
}

export const newColorHex: { [key: string]: string } = {
  grey: "#9EA9BD",
  orange: "#FFAB00",
  indigo: "#625DF5",
  red: "#FF3366",
  green: "#3DC45F",
  turquoise: "#27C9D6",
  brown: "#A96D64",
  cream: "#EDEBDD",
  pink: "#FD80E9",
  blue: "#69BBFF",
}

export const newColorNames: string[] = Object.keys(newColorHex)

export interface TagProps {
  onClick?: () => void
  label: string
  iconPosition?: "left" | "right"
  Icon?: JSX.Element
  disabled?: boolean
  className?: string
  color?: TagColorType
  size?: TagSize
  ellipsisPosition?: "end" | "middle"
  width?: string
}
