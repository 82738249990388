import { TextField } from "@material-ui/core"
import React, { useMemo, useState } from "react"

import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import useFilterDrawer from "components/Hooks/useFilterDrawer"
import InputClearable from "components/Input/InputClearable"
import DrawerUI from "CryptioUI/Drawer"
import { Mixpanel } from "services/mixpanel"
import { contactHasAnyFilter, ContactParameters, defaultContactParams } from "../index"

interface Props {
  filter: ContactParameters
  setFilter: (newValue: ContactParameters) => void
}

const ContactFilter = (props: Props): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const { OpenButton, SaveButton, tempFilter, updateFilter } = useFilterDrawer({
    ...props,
    defaultParams: defaultContactParams,
    setIsDrawerOpen,
  })

  const filterCount = useMemo(() => {
    const filter = props.filter
    let res = 0
    if (filter.query) res++
    if (filter.address) res++
    return res
  }, [props.filter])

  const canClear = filterCount > 0 || contactHasAnyFilter(tempFilter)

  return (
    <>
      <OpenButton filterCount={filterCount} />

      <DrawerUI title="Contact Filter" onClose={() => setIsDrawerOpen(false)} isOpen={isDrawerOpen}>
        <DrawerCategory title="Filter">
          <DrawerFormSection htmlFor="search-name-textfield" name="Search by name">
            <InputClearable
              id="search-name-textfield"
              value={tempFilter.query || ""}
              onChange={(value) =>
                updateFilter({
                  query: value,
                })
              }
              placeholder="Name"
              fullWidth
            />
          </DrawerFormSection>

          <DrawerFormSection htmlFor="address-select" name="Address">
            <TextField
              id="address-select"
              onChange={(v) =>
                updateFilter({
                  address: v.target.value,
                })
              }
              placeholder="Address"
              fullWidth
              value={tempFilter.address ?? null}
            />
          </DrawerFormSection>

          <SaveButton
            onSaveClick={() => Mixpanel.track("ContactsFilters")}
            filterCount={filterCount}
            canClear={canClear}
            canSave={true}
          />
        </DrawerCategory>
      </DrawerUI>
    </>
  )
}

export default ContactFilter
