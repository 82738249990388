/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import { GlobalBalanceDto, PaginatedOverviewBalanceResultDto, PaginatedUnrealizedGainBalanceResultDto } from "../models"

export interface GetGlobalBalancesRequest {
  workspaceId: string
}

export interface GetOverviewBalancesRequest {
  page: number
  limit: number
  sortBy: GetOverviewBalancesSortByEnum
  workspaceId: string
  sortDirection?: GetOverviewBalancesSortDirectionEnum
  excludedIds?: Array<string>
}

export interface GetUnrealizedGainBalancesRequest {
  page: number
  limit: number
  unrealizedType: GetUnrealizedGainBalancesUnrealizedTypeEnum
  workspaceId: string
  excludedIds?: Array<string>
}

/**
 *
 */
export class BalanceApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static getGlobalBalances(params: GetGlobalBalancesRequest) {
    return localBalanceApi.getGlobalBalances(params)
  }

  /**
   * Get user\'s global balance
   */
  getGlobalBalances({ workspaceId }: GetGlobalBalancesRequest): Promise<GlobalBalanceDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/balance/global`)
  }

  static getOverviewBalances(params: GetOverviewBalancesRequest) {
    return localBalanceApi.getOverviewBalances(params)
  }

  /**
   * Get user\'s overview balances
   */
  getOverviewBalances({
    workspaceId,
    ...queryParameters
  }: GetOverviewBalancesRequest): Promise<PaginatedOverviewBalanceResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/balance/overview?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }

  static getUnrealizedGainBalances(params: GetUnrealizedGainBalancesRequest) {
    return localBalanceApi.getUnrealizedGainBalances(params)
  }

  /**
   * Get user\'s unrealized gain balances
   */
  getUnrealizedGainBalances({
    workspaceId,
    ...queryParameters
  }: GetUnrealizedGainBalancesRequest): Promise<PaginatedUnrealizedGainBalanceResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/balance/unrealized?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }
}

const localBalanceApi = new BalanceApi()

/**
 * @export
 * @enum {string}
 */
export type GetOverviewBalancesSortByEnum = "amount" | "amount_in_usd" | "asset_symbol"
/**
 * @export
 * @enum {string}
 */
export type GetOverviewBalancesSortDirectionEnum = "ascending" | "descending"
/**
 * @export
 * @enum {string}
 */
export type GetUnrealizedGainBalancesUnrealizedTypeEnum = "Gains" | "Losses"
