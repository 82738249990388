import React from "react"
import SimpleAutocompleteFactory from "../../../../Autocomplete/SimpleAutocompleteFactory"

export type IsNotType = "normal" | "numeric" | "ranged"
type IsNotEnum = "is" | "isNot"
type IsNotAutocomplete = {
  is: string
  isNot: string
}

interface IsNotProps {
  type: IsNotType
  disabled?: boolean
  value: IsNotEnum
  onChange: (value: IsNotEnum) => void
}

const IsNot = (props: IsNotProps) => {
  const prettyIsNot: Record<IsNotType, IsNotAutocomplete> = {
    normal: {
      is: "Is",
      isNot: "Is not",
    },
    numeric: {
      is: "Is greater than",
      isNot: "Is less than",
    },
    ranged: {
      is: "Is between",
      isNot: "Is not between",
    },
  }

  const IsNotAutocomplete = SimpleAutocompleteFactory<IsNotEnum>(prettyIsNot[props.type])

  return (
    <IsNotAutocomplete
      disableClearable
      disabled={props.disabled}
      size="small"
      value={props.value}
      onChange={props.onChange}
    />
  )
}

export default IsNot
