import { useTypedController } from "@hookform/strictly-typed"
import { Box, makeStyles, TextField, Theme, Typography } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"

import { DrawerFormSection } from "components/Drawer/DrawerItems"
import { InputEmail } from "components/Input/InputEmail"
import { useLoadingButton } from "components/LoadingButton"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { ButtonSize } from "CryptioUI/types"
import api from "services/api"
import { BasicElement } from "services/api/aliases"
import { CreateWorkspaceInvitationDto } from "services/api/openapi/models"
import { WorkspaceContext } from "services/context/workspaceContext"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  disclaimer: {
    marginTop: theme.spacing(1),
  },
  autocompleteRole: {
    minWidth: theme.spacing(25),
  },
}))

interface Props {
  workspaceId: string
}

const WorkspaceInvitationModal = ({ workspaceId }: Props): JSX.Element => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [roleId, setRoleId] = useState<string | null>(null)

  const workspaceCtx = useContext(WorkspaceContext)
  const { control, handleSubmit, errors, watch, setError, setValue } = useForm<CreateWorkspaceInvitationDto>({
    mode: "onChange",
  })

  const watchAllFields = watch()

  const TypedController = useTypedController<CreateWorkspaceInvitationDto>({
    control,
  })

  const toast = useToast()

  const { mutateAsync: createWorkspaceInvitationMutation } = api.workspace.useCreateWorkspaceInvitation()

  const onClose = () => setIsOpen(false)

  const [SendInvitationButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = handleButtonCallback(
    handleSubmit(async ({ invitedEmail }) => {
      if (roleId === null) return

      try {
        await createWorkspaceInvitationMutation({ createWorkspaceInvitationDto: { invitedEmail, roleId }, workspaceId })
        toast.open("Invitation sent", { variant: "success" })
        onClose()
      } catch (e) {
        toastCatch(e, toast)
      }
    }),
  )

  const workspaceRoles = api.workspace.useWorkspaceRoles({
    workspaceId: workspaceCtx.workspace.id,
  })

  if (workspaceRoles.isError) return <NetworkErrorMessage additionalData={workspaceRoles} />

  if (workspaceRoles.isLoading || workspaceRoles.data === undefined) return <LoadingSpinner />
  return (
    <>
      <Box display="flex" alignItems="center">
        <ButtonUI onClick={() => setIsOpen(true)}>Invite a user</ButtonUI>
      </Box>
      <ModalUI isOpen={isOpen} onClose={onClose} className="w-[600px] h-[470px]" title="Invite a user">
        <Box component="form" onSubmit={onSubmit}>
          <Box flexDirection="column" display="flex">
            <DrawerFormSection htmlFor="email-textfield" name="Email">
              <TypedController
                name="invitedEmail"
                defaultValue={""}
                rules={{
                  required: true,
                }}
                render={(props) => (
                  <InputEmail
                    {...props}
                    fullWidth={true}
                    placeholder="Enter a valid email"
                    error={errors?.invitedEmail !== undefined}
                    onError={({ message, value }) => {
                      setError("invitedEmail", { message })
                      setValue("invitedEmail", value)
                    }}
                    helperText={errors?.invitedEmail?.message}
                  />
                )}
              />
            </DrawerFormSection>
            <DrawerFormSection htmlFor="role-textfield" name="User's role">
              <Autocomplete
                getOptionSelected={(option, selected) => option.id === selected.id}
                onChange={(_, role) => setRoleId(role.id)}
                getOptionLabel={(role) => role.name}
                options={(workspaceRoles.data as BasicElement[]) ?? []}
                disableClearable
                className={classes.autocompleteRole}
                renderInput={(params) => <TextField {...params} label="Role" />}
                size="small"
              />
            </DrawerFormSection>
          </Box>
          <Box mt={4}>
            <Typography className={classes.disclaimer}>
              When a user is invited to join a workspace, they receive an email with a link that will allow them to
              either log in, or sign up into Cryptio. Pending invitations can be managed in the{" "}
              <b>Manage invitations</b> section.
            </Typography>
          </Box>
          <Box mt={4} display="flex" justifyContent="center" alignItems="center">
            <SendInvitationButton
              disabled={watchAllFields?.invitedEmail === undefined || !!errors.invitedEmail || roleId === null}
              size={ButtonSize.LG}
              type="submit"
            >
              Send Invitation
            </SendInvitationButton>
          </Box>
        </Box>
      </ModalUI>
    </>
  )
}

export default WorkspaceInvitationModal
