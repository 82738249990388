import React from "react"
import { pluralize } from "services/utils/textUtils"
import { WarningAdminFeature } from "components/Feature/WarningAdminFeature"
import { BoldWeight, TypographyVariant } from "CryptioUI/types"
import CardUI from "CryptioUI/Card"
import TypographyUI from "CryptioUI/Typography"
import { css } from "@emotion/css"
import { ReactComponent as Calendar } from "CryptioUI/assets/icons/calendar.svg"
import { ReactComponent as BalanceSheet } from "CryptioUI/assets/icons/balance-sheet.svg"
import { ReactComponent as Synchronize } from "CryptioUI/assets/icons/synchronize.svg"
import { ReactComponent as Favorite } from "CryptioUI/assets/icons/favorite-off.svg"
import { ReactComponent as Config } from "CryptioUI/assets/icons/menu/configuration.svg"
import { GetReportModuleDto, GetReportModuleDtoNameEnum, GetReportTypeDto } from "services/api/openapi"

interface ModulesFormProps {
  moduleReportTypes: GetReportModuleDto[]
  savedModule: GetReportModuleDto | null
  setSavedModule: (value: React.SetStateAction<GetReportModuleDto | null>) => void
  setReportType: (value: React.SetStateAction<GetReportTypeDto | null>) => void
  setReturnAction: ((newAction: (() => void) | undefined) => void) | undefined
  setTitle?: (newTitle: string) => void
}

const ModulesForm = (props: ModulesFormProps) => {
  const { moduleReportTypes, savedModule, setSavedModule, setReportType, setReturnAction, setTitle } = props

  const iconStyle = css`
    height: 16px;
    width: 16px;
    filter: invert(100%) sepia(40%) saturate(0%) hue-rotate(267deg) brightness(102%) contrast(103%);
  `

  const modeStyle: Record<GetReportModuleDtoNameEnum, JSX.Element> = {
    ["accounting"]: (
      <div className="w-12 h-12 flex justify-center items-center mr-4 bg-label-green-200 rounded-full	">
        <Calendar className={iconStyle} />
      </div>
    ),
    ["financial"]: (
      <div className="w-12 h-12 flex justify-center items-center mr-4 bg-label-turquoise-200 rounded-full">
        <BalanceSheet className={iconStyle} />
      </div>
    ),
    ["audit"]: (
      <div className="w-12 h-12 flex justify-center items-center mr-4 bg-label-indigo-200 rounded-full">
        <Synchronize className={iconStyle} />
      </div>
    ),
    ["filecoin"]: (
      <div className="w-12 h-12 flex justify-center items-center mr-4 bg-[#0090ff] rounded-full	">
        <img src={"/filecoin.png"} alt="filcoin reporting module icon" className="w-8 h-8 " />
      </div>
    ),
    ["favorite"]: (
      <div className="w-12 h-12 flex justify-center items-center mr-4 bg-label-orange-200 rounded-full">
        <Favorite className={iconStyle} />
      </div>
    ),
    ["admin"]: (
      <div className="w-12 h-12 flex justify-center items-center mr-4 bg-label-blue-200 rounded-full">
        <Config className={iconStyle} />
      </div>
    ),
    // Won't ever be displayed to non admins
    ["consensys"]: (
      <div className="w-12 h-12 flex justify-center items-center mr-4 bg-label-indigo-200 rounded-full	">
        <Synchronize className={iconStyle} />
      </div>
    ),
  }

  const onModuleCardClick = (module: GetReportModuleDto) => {
    if (!module) return setSavedModule(null)
    setReportType(null)
    setSavedModule(module)
    if (setReturnAction !== undefined) {
      setReturnAction(() => () => setSavedModule(null))
    }
    if (setTitle !== undefined) {
      setTitle(module.displayName)
    }
  }

  return (
    <>
      {moduleReportTypes.length > 1 && !savedModule && (
        <div className="flex flex-col justify-center items-center gap-y-6 mt-6">
          {moduleReportTypes.map((module) => {
            return (
              <WarningAdminFeature isAdminFeature={module !== null && module.isFeatureEnabledOnlyForAdmin}>
                <CardUI
                  fullWidth={true}
                  className="flex flex-row justify-start items-start"
                  onClick={() => onModuleCardClick(module)}
                >
                  <div className="flex flex-row justify-start items-center w-full p-6">
                    {modeStyle[module.name]}
                    <div className="flex flex-col justify-start items-start">
                      <TypographyUI
                        variant={TypographyVariant.BODY}
                        boldness={BoldWeight.MEDIUM}
                        ellipsisPosition="end"
                      >
                        {module.displayName}
                      </TypographyUI>
                      <TypographyUI variant={TypographyVariant.CAPTION} boldness={BoldWeight.REGULAR}>
                        {module.types.length} {pluralize(module.types.length > 1, "report")}
                      </TypographyUI>
                    </div>
                  </div>
                </CardUI>
              </WarningAdminFeature>
            )
          })}
        </div>
      )}
    </>
  )
}

export default ModulesForm
