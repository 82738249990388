import { Box } from "@material-ui/core"
import { ReplayRounded } from "@material-ui/icons"
import React, { useCallback, useEffect, useState } from "react"
import ButtonUI from "CryptioUI/Button"
import { ButtonSize, Mode } from "CryptioUI/types"
import { iconStyleMaterialUI } from "CryptioUI/Utilities/config"

import { pluralize } from "services/utils/textUtils"
import ButtonGroupUI from "CryptioUI/ButtonGroup"

interface Props<TFilter> {
  defaultParams: TFilter
  filter: TFilter
  setFilter: (newValue: TFilter) => void
  setIsDrawerOpen: (isOpen: boolean) => void
  onSave?: () => void
}

interface OpenButtonProps {
  filterCount: number
}

interface SaveButtonProps {
  filterCount: number
  canClear?: boolean
  canSave?: boolean
  onSaveClick?: () => void
}

function useFilterDrawer<TFilter>(props: Props<TFilter>) {
  const { defaultParams, filter, setFilter, setIsDrawerOpen, onSave } = props
  const [tempFilter, setTempFilter] = useState<TFilter>(filter)
  const [isErrorValidation, setIsErrorValidation] = useState<boolean>(false)

  useEffect(() => {
    setTempFilter(filter)
  }, [filter])

  const updateFilter = useCallback(
    (newFilter: Partial<TFilter>) => {
      const tmp = {
        ...tempFilter,
        ...newFilter,
      }

      setTempFilter(tmp)
    },
    [tempFilter, setTempFilter],
  )

  const OpenButton = ({ filterCount }: OpenButtonProps): JSX.Element => {
    return filterCount === 0 ? (
      <ButtonUI onClick={() => setIsDrawerOpen(true)}>Filters</ButtonUI>
    ) : (
      <Box>
        <ButtonGroupUI mode={Mode.OUTLINED}>
          <ButtonUI mode={Mode.OUTLINEDHIGHLIGHT} size={ButtonSize.LG} onClick={() => setIsDrawerOpen(true)}>
            {filterCount} {pluralize(filterCount > 1, "filter")} activated
          </ButtonUI>
          <ButtonUI
            mode={Mode.CONTAINED}
            size={ButtonSize.LG}
            Icon={<ReplayRounded style={iconStyleMaterialUI} />}
            onClick={() => setFilter(defaultParams)}
          >
            Reset
          </ButtonUI>
        </ButtonGroupUI>
      </Box>
    )
  }

  const SaveButton = ({ filterCount, canClear = true, canSave = true, onSaveClick }: SaveButtonProps): JSX.Element => {
    const saveFilter = () => {
      setFilter(tempFilter)
      setIsDrawerOpen(false)
      if (onSave) {
        onSave()
      }
      if (onSaveClick) onSaveClick()
    }
    const resetFilter = () => {
      if (filterCount > 0) setFilter(defaultParams)
      setTempFilter(defaultParams)
    }

    return (
      <div className="mt-8 flex flex-row space-x-4">
        <ButtonUI mode={Mode.OUTLINED} onClick={resetFilter} disabled={canClear === false}>
          Clear
        </ButtonUI>
        <ButtonUI disabled={canSave === false || isErrorValidation} onClick={saveFilter}>
          Save
        </ButtonUI>
      </div>
    )
  }

  return {
    tempFilter,
    OpenButton,
    SaveButton,
    updateFilter,
    setIsErrorValidation,
  }
}

export default useFilterDrawer
