import { useTypedController } from "@hookform/strictly-typed"
import {
  Avatar,
  Box,
  Checkbox,
  DialogActions,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core"
import { checkIntegrationFormat } from "pure-shared"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useHistory, useLocation } from "react-router"

import FieldHelper from "components/FieldHelper"
import { LoadingButton, UncontrolledLoadingButton } from "components/LoadingButton"
import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import useDialog from "components/misc/useDialog"
import useDrawer from "components/misc/useDrawer"
import ButtonUI from "CryptioUI/Button"
import ModalUI from "CryptioUI/Modal"
import { Mode } from "CryptioUI/types"
import { URLS } from "../../../../routes"
import api from "services/api"
import { pluralize } from "services/utils/textUtils"
import { showConfirmation } from "../../utils"
import { ReactComponent as Reset } from "CryptioUI/assets/icons/reset.svg"
import { ReactComponent as Create } from "CryptioUI/assets/icons/create.svg"
import { iconStyleWhite } from "CryptioUI/Utilities/config"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

export interface IntegrationForm {
  apiKey: string
  apiSecret: string
  importHiddenVaults: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    marginRight: "16px",
  },
  marginLeft: {
    marginLeft: theme.spacing(1.5),
  },
  numberOfImport: {
    display: "inline-block",
  },
  textEmphasized: {
    fontWeight: "bold",
    marginRight: theme.spacing(0.5),
    fontSize: 16,
  },
  missingAddressesText: {
    "textDecoration": "underline",
    "cursor": "pointer",
    "&:hover": {
      color: "#1e70bf",
    },
  },
}))

interface ModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  connect: (form: IntegrationForm) => Promise<void>
  formId: string
}

interface FireblocksRouteState {
  openFireblocksDialog: boolean
}

export function FireblocksIntegration(): JSX.Element {
  const classes = useStyles()
  const toast = useToast()
  const history = useHistory()
  const state = useLocation<FireblocksRouteState | undefined>().state

  const { mutateAsync: createFireblocksConnectionMutation } = api.integrations.useConnectToFireblocks()
  const { mutateAsync: deleteFireblocksConnectionMutation } = api.integrations.useDisconnectFromFireblocks()
  const { mutateAsync: updateFireblocksMutation } = api.integrations.useUpdateFireblocks()

  const [isOpen, setIsOpen] = useState(state?.openFireblocksDialog ? true : false)
  const [intergationDrawer, openIntegrationDrawer] = useDrawer("integration-edition-fireblocks")
  const connection = api.integrations.useFireblocks()
  const dialog = useDialog()

  // const { mutateAsync: createReportMutation } = api.report.useCreateReports()
  const reportTypes = api.report.useReportTypes()
  // const toast = useToast()

  // const createMissingAddressesReport = async () => {
  //   const missingAddressesType = reportTypes.data?.find((t) => t.name === "fireblocks_missing_addresses")
  //   if (!missingAddressesType || missingAddressesType.formats.length < 1) return

  //   const [unidentifiedFormat] = missingAddressesType.formats
  //   await createReportMutation({
  //     createReportDto: {
  //       exportFormatId: unidentifiedFormat.id,
  //       startDate: null,
  //       endDate: null,
  //       assetIds: [],
  //       walletIds: [],
  //       allColumns: true,
  //       fileType: "csv",
  //     },
  //   })
  //   toast.open("Missing addresses report created", { variant: "success" })
  //   history.push(URLS.Reports.basePath)
  // }

  function Modal({ isOpen, setIsOpen, connect, formId }: ModalProps): JSX.Element {
    const { control, handleSubmit, formState } = useForm<IntegrationForm>({
      mode: "onChange",
    })
    const TypedController = useTypedController<IntegrationForm>({ control })

    return (
      <ModalUI isOpen={isOpen} onClose={() => setIsOpen(false)} className="w-[700px" title="Connect to Fireblocks">
        <Divider />
        <form id={formId} onSubmit={handleSubmit(connect)}>
          <Box mt={2} padding="8px 24px">
            <Grid container alignItems="center">
              <FormLabel htmlFor="apiKey-textfield">API key</FormLabel>
              <Box ml={1}>
                <FieldHelper helpUrl={"https://docs.fireblocks.com/api/#issuing-api-credentials"} />
              </Box>
            </Grid>
            <TypedController
              name="apiKey"
              defaultValue=""
              rules={{ required: true, minLength: 1 }}
              render={(props) => (
                <TextField {...props} id="apiKey-textfield" fullWidth variant="standard" margin="normal" />
              )}
            />
          </Box>
          <Box mt={2} padding="8px 24px">
            <Grid container alignItems="center">
              <FormLabel htmlFor="apiSecret-textfield">API secret (optional)</FormLabel>
            </Grid>
            <TypedController
              name="apiSecret"
              defaultValue=""
              rules={{ required: false }}
              render={(props) => (
                <TextField
                  {...props}
                  id="apiSecret-textfield"
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                />
              )}
            />

            <TypedController
              name="importHiddenVaults"
              defaultValue={false}
              render={({ onChange, value }) => (
                <FormControlLabel
                  control={<Checkbox color="primary" onChange={() => onChange(!value)} />}
                  label="Import hidden vaults"
                />
              )}
            />
          </Box>
          <DialogActions>
            <ButtonUI
              mode={Mode.CONTAINED}
              onClick={() => {
                if (state) history.replace(URLS.Business.Integrations)
                setIsOpen(false)
              }}
            >
              Cancel
            </ButtonUI>
            <ButtonUI disabled={!formState.isValid} id="fireblocks-form-submit" type="submit" form={formId}>
              Connect
            </ButtonUI>
          </DialogActions>
        </form>
      </ModalUI>
    )
  }

  async function submitImport({ apiKey, apiSecret, importHiddenVaults }: IntegrationForm) {
    if (state) history.replace(URLS.Business.Integrations)
    try {
      await createFireblocksConnectionMutation({
        fireblocksConnectionDto: {
          apiKey,
          apiSecret: apiSecret.length > 0 ? apiSecret : undefined,
          importHiddenVaults,
        },
      })
      await updateFireblocksMutation({})
      setIsOpen(false)
      toast.open("Integration with Fireblocks succeeded", {
        variant: "success",
      })
    } catch (e) {
      await deleteFireblocksConnectionMutation({}).catch(() => {})
      toastCatch(e, toast)
    }
  }

  async function successfullDisconnect() {
    try {
      await deleteFireblocksConnectionMutation({})
      toast.open("Successfully disconnected from Fireblocks", {
        variant: "success",
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  async function connect(form: IntegrationForm) {
    if (!checkIntegrationFormat("fireblocks", form.apiKey)) {
      showConfirmation(dialog, () => submitImport(form))
    } else {
      submitImport(form)
    }
  }

  async function update() {
    try {
      await updateFireblocksMutation({})
      toast.open("Updating Fireblocks...", {
        variant: "default",
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  if (reportTypes.isError) return <NetworkErrorMessage small={false} additionalData={reportTypes} />
  if (reportTypes.isLoading || reportTypes.data === undefined) return <LoadingSpinner />
  if (connection.isError) return <NetworkErrorMessage additionalData={connection} />
  if (connection.isLoading || connection.data === undefined) return <LoadingSpinner />

  return (
    <>
      {intergationDrawer}
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} connect={connect} formId="business-integrations-fireblocks-form" />
      {dialog.dialog}
      <Box display="flex" alignItems="center">
        <Avatar
          alt={"Fireblocks"}
          variant={"rounded"}
          src={"/images/integrations/fireblocks.jpeg"}
          className={classes.avatar}
        />
        <Typography variant="h2">Fireblocks</Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          Connecting a Fireblocks account allows to automatically import vaults as wallets and external addresses as
          contacts.
        </Typography>
      </Box>
      <Box mb={2} bottom="0" position="absolute">
        {connection.data.isConnected ? (
          <Grid container>
            <Grid xs={12}>
              <Box display="flex">
                <Typography className={classes.numberOfImport}>
                  <span data-test-id="fireblocks-contact-imported" className={classes.textEmphasized}>
                    {connection.data.contactNumber}
                  </span>
                  {pluralize(connection.data.contactNumber > 1, "contact")} imported.
                </Typography>
                {/*
                <Box mr={1} />
                <Typography
                  onClick={createMissingAddressesReport}
                  className={classes.missingAddressesText}
                  variant="body1"
                >
                  Fix conflicts in contacts
                </Typography>
                */}
              </Box>
              <Typography className={classes.numberOfImport}>
                <span className={classes.textEmphasized} data-test-id="fireblocks-vault-count">
                  {connection.data.vaults.length}
                </span>
                {pluralize(connection.data.vaults.length > 1, "vault")} found.
              </Typography>
            </Grid>
            <Grid xs={12}>
              <div className="flex flex-row mt-4">
                <LoadingButton
                  pending={connection.data.jobStatus !== "inactive"}
                  disabled={connection.data.jobStatus !== "inactive"}
                  Icon={<Reset className={iconStyleWhite} />}
                  onClick={update}
                >
                  Update
                </LoadingButton>
                <ButtonUI
                  onClick={() => openIntegrationDrawer(null)}
                  Icon={<Create className={iconStyleWhite} />}
                  className={classes.marginLeft}
                >
                  Modify
                </ButtonUI>
                <UncontrolledLoadingButton
                  id="fireblocks-disconnect"
                  mode={Mode.CONTAINED}
                  className={classes.marginLeft}
                  onClick={() => successfullDisconnect()}
                >
                  Disconnect
                </UncontrolledLoadingButton>
              </div>
            </Grid>
          </Grid>
        ) : (
          <UncontrolledLoadingButton
            id="fireblocks-connect"
            onClick={() => {
              setIsOpen(true)
            }}
          >
            Connect
          </UncontrolledLoadingButton>
        )}
      </Box>
    </>
  )
}
