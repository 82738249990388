import { RegisterOptions } from "react-hook-form"

export const passwordRules: RegisterOptions = {
  required: true,
  minLength: {
    value: 8,
    message: "Should be at least 8 characters long",
  },
  maxLength: {
    value: 256,
    message: "Should not exceed 256 characters",
  },
  pattern: {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])/,
    message: "Should contains at least 1 digit, 1 lowercase, 1 uppercase and 1 special character",
  },
}

export const code2faRules: RegisterOptions = {
  required: true,
  minLength: {
    value: 6,
    message: "Should be 6 characters long",
  },
  maxLength: {
    value: 6,
    message: "Should be 6 characters long",
  },
}
