import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import BigNumber from "bignumber.js"
import numbro from "numbro"
import React, { useContext, useMemo, useState } from "react"

import LoadingSpinner from "components/misc/LoadingSpinner"
import NetworkErrorMessage from "components/misc/NetworkErrorMessage"
import { IExtendedDataTableColumn } from "components/Table/interface"
import { tableColumnAlign } from "components/Table/misc"
import ServerSideTable from "components/Table/ServerSideTable"
import api from "services/api"
import { GetUnrealizedGainBalancesUnrealizedTypeEnum, UnrealizedGainBalanceDto } from "services/api/openapi"
import { ThemeContext } from "services/context/themeContext"
import { WorkspaceContext } from "services/context/workspaceContext"
import ChildrenProps from "services/misc/childrenProps"
import { getCode, getSymbol } from "services/utils/AssetSymbols"
import { prettyNumber } from "services/utils/processNumber"
import { CurrencyLogo } from "./CurrencyLogo"
import { widgetTableStyle } from "./widgetTableStyle"
import CardUI from "CryptioUI/Card"
import TypographyUI from "CryptioUI/Typography"
import { TypographyVariant } from "CryptioUI/types"
import TooltipUI from "CryptioUI/Tooltip"

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  symbol: {
    paddingLeft: theme.spacing(2),
    color: "rgba(0, 0, 0, 0.54)",
  },
  namePadding: {
    paddingLeft: theme.spacing(2),
  },
  noGains: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: "center",
  },
}))

interface Props {
  type: "Gains" | "Losses"
}

const TopGainLoss = ({ type }: Props): JSX.Element => {
  const muiThemeContext = useContext(ThemeContext)
  const { workspace } = useContext(WorkspaceContext)
  const userFiatSymbol = getSymbol(workspace.defaultCurrencySymbol)
  const userFiatCode = getCode(workspace.defaultCurrencySymbol)
  const classes = useStyles()
  const [page] = useState<number>(1)
  const displayedType = type.toLowerCase()

  const columns = useMemo<
    IExtendedDataTableColumn<UnrealizedGainBalanceDto, GetUnrealizedGainBalancesUnrealizedTypeEnum>[]
  >(
    () => [
      {
        name: "Asset",
        selector: "currency",
        minWidth: "35%",
        maxWidth: "250px",
        format: function AssetFormat(row) {
          return (
            <Box display="flex" alignItems="center">
              <CurrencyLogo asset={row} />
              {row.assetName.length > 0 ? (
                <Typography className={classes.namePadding} noWrap variant="h5">
                  {row.assetName}
                </Typography>
              ) : (
                <Typography className={classes.symbol} variant="h5">
                  No asset name
                </Typography>
              )}
              <Box display="flex" justifyContent="center">
                {row.assetSymbol.length > 0 ? (
                  <Typography className={classes.symbol} variant="h5">
                    {row.assetSymbol}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          )
        },
      },
      {
        name: "Amount",
        selector: "amount",
        format: function AssetAmount(row) {
          const amount = new BigNumber(row.amount)
          return (
            <TooltipUI content={row.amount ?? ""}>
              <Box component="span">
                {numbro(amount.decimalPlaces(4, BigNumber.ROUND_UP)).format(prettyNumber(amount))}
              </Box>
            </TooltipUI>
          )
        },
      },
      {
        name: tableColumnAlign(`${userFiatCode} Acq. Cost`, "right"),
        selector: "inventoryFiat",
        format: (row) => {
          // No amount in USD
          if (row.inventoryFiat === null || row.inventoryFiat.length === 0) {
            return "-"
          }

          // No USD rate to multiply with the amount
          if (row.usdRateToDefaultCurrency === null || row.usdRateToDefaultCurrency.length === 0) {
            return "-"
          }

          const amount = new BigNumber(row.inventoryFiat)
          return numbro(amount.decimalPlaces(2, BigNumber.ROUND_UP)).format(prettyNumber(amount)) + ` ${userFiatSymbol}`
        },
      },
      {
        name: tableColumnAlign(`Market value`, "right"),
        selector: "amountInUsd",
        format: (row) => {
          // No amount in USD
          if (row.amountInUsd === null || row.amountInUsd?.length === 0) {
            return "-"
          }

          // No USD rate to multiply with the amount
          if (row.usdRateToDefaultCurrency === null || row.usdRateToDefaultCurrency.length === 0) {
            return "-"
          }

          const amount = new BigNumber(row.amountInUsd).multipliedBy(row.usdRateToDefaultCurrency)
          return numbro(amount.decimalPlaces(2, BigNumber.ROUND_UP)).format(prettyNumber(amount)) + ` ${userFiatSymbol}`
        },
      },
      {
        name: tableColumnAlign(`Unrealized ${displayedType}`, "right"),
        selector: "amountInUsd",
        format: (row) => {
          // No amount in USD
          if (row.amountInUsd === null || row.amountInUsd?.length === 0) {
            return "-"
          }

          // No USD rate to multiply with the amount
          if (row.usdRateToDefaultCurrency === null || row.usdRateToDefaultCurrency.length === 0) {
            return "-"
          }

          const amount = new BigNumber(row.amountInUsd)
            .multipliedBy(row.usdRateToDefaultCurrency)
            .minus(row.inventoryFiat)
          return (
            numbro(amount.decimalPlaces(2, BigNumber.ROUND_UP)).format(prettyNumber(amount.absoluteValue())) +
            ` ${userFiatSymbol}`
          )
        },
      },
    ],
    [displayedType, classes, userFiatSymbol, userFiatCode],
  )

  const balances = api.balance.useUnrealizedBalances(
    {
      page,
      limit: 5,
      unrealizedType: type,
    },
    {
      keepPreviousData: true,
    },
  )

  if (balances.isError) return <NetworkErrorMessage additionalData={balances} />
  if (balances.isLoading || balances.data === undefined) return <LoadingSpinner />

  const ComponentLayout = ({ children }: ChildrenProps) => (
    <CardUI data-test-id="unrealized-gains-losses" fullWidth={true} className="flex flex-row justify-start items-start">
      <div className="flex flex-col justify-start items-start w-full">
        <div className="flex w-full justify-start items-start p-8">
          <div className="w-fit">
            <TypographyUI variant={TypographyVariant.H4}>Top unrealized {displayedType}</TypographyUI>
          </div>
        </div>
        <div className="rounded-b-xl w-full">{children}</div>
      </div>
    </CardUI>
  )

  if (workspace.isCostBasisDirty) {
    return (
      <ComponentLayout>
        <Box className={classes.noGains}>
          <Typography color="primary">
            Some modifications on pricing have been made. Please update the cost basis.
          </Typography>
        </Box>
      </ComponentLayout>
    )
  }

  if (balances.data.data.length === 0) {
    return (
      <ComponentLayout>
        <Box className={classes.noGains}>
          <Typography color="primary">Nothing to display.</Typography>
        </Box>
      </ComponentLayout>
    )
  }

  return (
    <ComponentLayout>
      <ServerSideTable<UnrealizedGainBalanceDto, GetUnrealizedGainBalancesUnrealizedTypeEnum>
        customStyle={widgetTableStyle(muiThemeContext.type)}
        pagination={false}
        columns={columns}
        items={balances.data?.data ?? []}
        limit={balances.data?.limit ?? 0}
        totalCount={balances.data?.totalCount ?? 0}
        onLimitChange={() => true}
        onPageChange={() => true}
      />
    </ComponentLayout>
  )
}

export default TopGainLoss
