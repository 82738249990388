import { Box, Button, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

import BaseContainer from "components/Container"
import { URLS } from "../../../routes"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: "0 auto",
    maxWidth: "60em",
  },
  submitButton: {
    "paddingLeft": theme.spacing(4),
    "paddingRight": theme.spacing(4),
    "marginTop": theme.spacing(1),
    "marginBottom": theme.spacing(1),
    "fontSize": "1.2em",
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}))

const BillingCheckoutCancelledScene = (): JSX.Element => {
  const classes = useStyles()

  return (
    <BaseContainer>
      <Box pt={20}>
        <Paper component="form" className={classes.container}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h1">The checkout was cancelled</Typography>
            <Typography className={classes.subtitle}>Please try again</Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitButton}
              component={RouterLink}
              to={URLS.Billing.Simulator}
            >
              Subscribe
            </Button>
          </Box>
        </Paper>
      </Box>
    </BaseContainer>
  )
}

export default BillingCheckoutCancelledScene
