import { Link, Typography } from "@material-ui/core"
import React, { useCallback, useContext, useEffect } from "react"

import useDialog from "components/misc/useDialog"
import { useLocalStorage } from "../misc/useLocalStorage"
import { Mixpanel } from "../mixpanel"
import { gdprConsentLocalStorageKey, gdprConsentVersion } from "../variables"

export type ConsentState = "accepted" | "rejected" | "unknown"

interface GDPRStoredConsent {
  state: ConsentState
  version: string
}

interface GDPRContextData {
  consentState: ConsentState
  openConsentDialog: () => void
  consentDialog: JSX.Element
}

const GDPRContext = React.createContext<GDPRContextData>({
  consentState: "unknown",
  openConsentDialog: () => {},
  consentDialog: <></>,
})

interface GDPRContextProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

function onConsentChanged(consentState: ConsentState) {
  if (consentState === "accepted") {
    Mixpanel.optInTracking()
  } else {
    Mixpanel.optOutTracking()
  }
}

export function GDPRContextProvider({ children }: GDPRContextProviderProps) {
  const [storedConsent, setStoredConsent] = useLocalStorage<GDPRStoredConsent>(gdprConsentLocalStorageKey, {
    state: "unknown",
    version: gdprConsentVersion,
  })
  const consentState = storedConsent.version === gdprConsentVersion ? storedConsent.state : "unknown"

  const { dialog, showDialog } = useDialog()

  const setConsentState = useCallback(
    (state: ConsentState) => {
      setStoredConsent({ state, version: gdprConsentVersion })
    },
    [setStoredConsent],
  )

  const openConsentDialog = useCallback(() => {
    showDialog({
      title: "Cookie policy",
      content: (
        <Typography variant="body1">
          Cryptio uses third-party cookies and trackers. See our{" "}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://cryptio.co/privacy"
            style={{ textDecoration: "underline" }}
          >
            Privacy Policy
          </Link>{" "}
          for a detailed overview.
        </Typography>
      ),
      yesText: "I accept all cookies and trackers",
      noText: "I reject non essential cookies and trackers",
      onAccept: () => setConsentState("accepted"),
      onRefuse: () => setConsentState("rejected"),
      explicit: consentState === "unknown",
      onCancel: () => {},
    })
  }, [setConsentState, showDialog, consentState])

  useEffect(() => {
    onConsentChanged(consentState)
    if (consentState === "unknown") openConsentDialog()
  }, [consentState, openConsentDialog])

  return (
    <GDPRContext.Provider value={{ consentState, openConsentDialog, consentDialog: dialog }}>
      {children}
    </GDPRContext.Provider>
  )
}

export const useGDPRConsent = () => useContext(GDPRContext)

export default useGDPRConsent
