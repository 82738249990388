import { useTypedController } from "@hookform/strictly-typed"
import { Box, Grid, TextField, Typography } from "@material-ui/core"

import { ReactComponent as Delete } from "CryptioUI/assets/icons/delete.svg"
import { createFilterOptions } from "@material-ui/lab"
import React, { useCallback, useContext, useEffect } from "react"
import { useForm } from "react-hook-form"

import AddressTypography from "components/AddressTypography"
import { DrawerCategory, DrawerFormSection } from "components/Drawer/DrawerItems"
import { useLoadingButton } from "components/LoadingButton"
import useDialog from "components/misc/useDialog"
import { DrawerProp } from "components/misc/useDrawer"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import CustomSelector from "components/selector/CustomSelector"
import WarningTypography from "components/WarningTypography"
import api from "services/api"
import { GetContactDto, GetUnknownContactDto, GetUnknownContactsRequest } from "services/api/openapi"
import { pluralize } from "services/utils/textUtils"
import ExternalLink from "components/ExternalLink"
import ButtonUI from "CryptioUI/Button"
import { Mode } from "CryptioUI/types"
import { iconStyleBlack } from "CryptioUI/Utilities/config"
import ConditionalTooltip from "components/ConditionalTooltip"
import { WorkspaceContext } from "services/context/workspaceContext"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

interface FormType {
  name: string
  unknownContacts: string[]
}

const ContactForm = ({ item: contact, isOpen, onClose, setFormDirty }: DrawerProp<GetContactDto, true>) => {
  const { handleSubmit, control, formState, reset, watch } = useForm<FormType>({
    mode: "onChange",
    defaultValues: {
      name: contact?.name ?? "",
      unknownContacts: contact?.addresses ?? [],
    },
  })
  const TypedController = useTypedController<FormType>({ control })
  const toast = useToast()
  const { workspace } = useContext(WorkspaceContext)

  const watchAllFields = watch()
  const isFormDirty = contact
    ? formState.isValid &&
      formState.isDirty &&
      (contact.name !== watchAllFields.name ||
        JSON.stringify(watchAllFields.unknownContacts.sort()) !== JSON.stringify(contact.addresses.sort()))
    : formState.isValid && formState.isDirty

  useEffect(() => setFormDirty(isFormDirty), [isFormDirty, setFormDirty])

  const isEditMode = !!contact
  const isCreateMode = contact === null

  const basicDialog = useDialog()

  useEffect(() => {
    if (isOpen) {
      reset({
        name: contact?.name ?? "",
        unknownContacts: contact?.addresses ?? [],
      })
    }
  }, [isOpen, contact, reset])

  const { mutateAsync: createContactMutation } = api.contact.useCreateContacts()
  const { mutateAsync: updateContactMutation } = api.contact.useUpdateContacts()
  const { mutateAsync: deleteContactMutation } = api.contact.useDeleteContacts()
  const [SaveContactButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = useCallback(
    async (form: FormType) => {
      if (!form.unknownContacts) {
        return
      }
      try {
        if (contact) {
          await updateContactMutation({
            updateContactDto: {
              id: contact.id,
              name: form.name,
              addresses: form.unknownContacts,
            },
          })
          toast.open("Contact updated", { variant: "success" })
        } else {
          await createContactMutation({
            createContactsDto: { contacts: [{ name: form.name, addresses: form.unknownContacts }] },
          })
          toast.open("Contact created", { variant: "success" })
        }

        onClose()
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [onClose, contact, updateContactMutation, createContactMutation, toast],
  )

  const doDeleteContact = async () => {
    if (!contact) return
    try {
      await deleteContactMutation({
        deleteContactsDto: {
          individuals: {
            contactIds: [contact.id],
          },
        },
      })
      toast.open("Contact deleted", { variant: "success" })
    } catch (e) {
      toastCatch(e, toast)
    }
    onClose()
  }

  const askDeleteContact = () => {
    if (!contact) return
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <>
          <Typography variant="h5" component="p">
            Do you really want to delete the contact <b>{contact.name}</b>? This action is irreversible.
          </Typography>
          {contact.labelRuleCount > 0 && (
            <Typography variant="h5" component="p">
              The contact <b>{contact.name}</b> is included in <strong>{contact.labelRuleCount}</strong>{" "}
              {pluralize(contact.labelRuleCount > 1, "label rule")}. If you delete it, the{" "}
              {pluralize(contact.labelRuleCount > 1, "rule")} will be deleted as well (but the labels already applied by
              the {pluralize(contact.labelRuleCount > 1, "rule")} will remain). Are you sure you want to delete the
              label the contact?
            </Typography>
          )}
        </>
      ),

      yesText: "Yes",
      noText: "Cancel",
      onAccept: doDeleteContact,
    })
  }

  const isDisplayLimitReached: boolean =
    contact && contact.addressCount && contact.addressDisplayCount
      ? contact.addressCount >= contact.addressDisplayCount
      : false

  const isLocking = workspace.lock.isEnabled && (contact ? contact.labelRuleCount > 0 : false)

  return (
    <DrawerCategory
      component="form"
      onSubmit={handleButtonCallback(handleSubmit(onSubmit))}
      title={contact ? "Contact Edition" : "New Contact"}
    >
      {basicDialog.dialog}

      <DrawerFormSection htmlFor="name-textfield" name="Name">
        <TypedController
          name="name"
          defaultValue={contact?.name || ""}
          rules={{ required: true, minLength: 1, maxLength: 256 }}
          render={(props) => (
            <PermissionDisabled action="modify contacts" permission="can_modify_contact">
              <TextField
                id="name-textfield"
                {...props}
                placeholder="Name"
                fullWidth={true}
                error={!!formState.errors.name}
              />
            </PermissionDisabled>
          )}
        />
      </DrawerFormSection>

      <DrawerFormSection htmlFor="address-select" name="Addresses">
        <TypedController
          name="unknownContacts"
          rules={{ required: true, validate: (value) => value.length > 0 }}
          defaultValue={contact?.addresses ?? []}
          render={({ ref, onChange, ...rest }) => (
            <ConditionalTooltip
              tooltipMessage="Please note that any contact updates won't be reflected on your locked transactions. If you want to update a contact and see the change in all your history, please unlock your period first."
              disabled={isLocking}
            >
              <div>
                <PermissionDisabled placement="left" action="modify contacts" permission="can_modify_contact">
                  <CustomSelector<
                    GetUnknownContactsRequest["getUnknownContactQuery"],
                    GetUnknownContactDto | string,
                    true,
                    false,
                    true
                  >
                    id="address-select"
                    // Remove placeholder if one or more addresses
                    placeholder={rest.value.length ? undefined : "Addresses"}
                    inputRef={ref}
                    {...rest}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") return option as string
                      return (option as GetUnknownContactDto).address
                    }}
                    defaultPaginatedQueryProps={{
                      excludedAddresses: rest.value,
                    }}
                    freeSolo
                    disableCloseOnSelect
                    error={!!formState.errors.unknownContacts}
                    onChange={(_, newValue) => {
                      const addresses = newValue.map((entry) => {
                        if (typeof entry === "string") return entry
                        else return entry.address
                      })
                      onChange([...new Set(addresses)])
                    }}
                    filterOptions={(options, params) => {
                      const filter = createFilterOptions<GetUnknownContactDto>()

                      const filtered = filter(options as GetUnknownContactDto[], params) as (
                        | GetUnknownContactDto
                        | string
                      )[]
                      if (params.inputValue && !rest.value.some((x) => params.inputValue === x)) {
                        filtered.push(params.inputValue)
                      }

                      return filtered
                    }}
                    // transformOption={(option) => option.map((c) => c.address).concat(contact?.addresses ?? [])}
                    usePaginatedQuery={api.contact.useUnknownContacts}
                    size="small"
                    multiple
                    filterSelectedOptions
                    renderOption={(option) => {
                      if (typeof option === "string") return `Add ${option}`
                      return (
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <AddressTypography component="span" variant="body1">
                              {option.address}
                            </AddressTypography>
                            <Typography variant="subtitle2" color="textSecondary">
                              {option.movementCount} {pluralize(option.movementCount > 1, "occurrence")}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    }}
                  />
                </PermissionDisabled>
              </div>
            </ConditionalTooltip>
          )}
        />
      </DrawerFormSection>

      {isDisplayLimitReached && (
        <WarningTypography mt={3}>
          The limit of {contact?.addressDisplayCount} addresses to display per contact is reached. Please note that you
          can add addresses to this contact only through a&nbsp;
          <ExternalLink href="https://support.cryptio.co/hc/en-gb/articles/7037996189969-Identifying-managing-and-creating-Contacts">
            csv import
          </ExternalLink>
          .
        </WarningTypography>
      )}

      <Box mt={3} display="flex" justifyContent="space-between">
        <SaveContactButton disabled={!isFormDirty} type="submit">
          {isEditMode && "Save"}
          {isCreateMode && "Create"}
        </SaveContactButton>
        {isEditMode && (
          <ConditionalTooltip
            tooltipMessage="Please note that any contact updates won't be reflected on your locked transactions. If you want to update a contact and see the change in all your history, please unlock your period first."
            disabled={isLocking}
          >
            <div>
              <PermissionDisabled permission="can_remove_contact" action="delete contacts">
                <ButtonUI
                  Icon={<Delete className={iconStyleBlack} />}
                  mode={Mode.CONTAINED}
                  onClick={askDeleteContact}
                  disabled={isLocking}
                >
                  Delete
                </ButtonUI>
              </PermissionDisabled>
            </div>
          </ConditionalTooltip>
        )}
      </Box>
    </DrawerCategory>
  )
}

export default ContactForm
