import { Box, Link, Paper } from "@material-ui/core"
import React, { useContext, useMemo } from "react"
import { WalletKindsExchangeMapping, WalletKindsWalletMapping } from "pure-shared"

import { GenericSorting, IExtendedDataTableColumn } from "components/Table/interface"
import ServerSideTable from "components/Table/ServerSideTable"
import api from "services/api"
import { WithoutWorkspaceId } from "services/api/aliases"
import { GetCOAMappingsRequest, GetWalletDto, GetWalletsRequest, GetWalletsSortByEnum } from "services/api/openapi"
import { usePaginatedParams } from "services/misc/usePaginatedParams"
import { URLS } from "routes"
import { Link as RouterLink } from "react-router-dom"
import toFilterElement from "services/utils/toFilterElement"
import { PaginationParameters } from "services/urlParse"
import CopyableText from "components/misc/copyableText"
import AddressTypography from "components/AddressTypography"
import { useLocalStorage } from "services/misc/useLocalStorage"
import { AccountingFilterContext } from "services/context/accountingFilterContext"

export const defaultImportSorting: GenericSorting<GetWalletsSortByEnum> = {
  sortDirection: "descending",
  sortBy: "created_at",
}

export type ImportParameters = Omit<Partial<WithoutWorkspaceId<GetWalletsRequest>>, "minimumTransactions"> & {
  minimumTransactions?: string
}

export const defaultImportParams: ImportParameters = {
  page: 0,
  limit: 10,
  query: undefined,
  typeNames: undefined,
  minimumTransactions: undefined,
  wallets: [],
}

export const paramsToFilter = (
  params: PaginationParameters & Partial<ImportParameters>,
): WithoutWorkspaceId<GetWalletsRequest> => {
  return {
    page: params.page,
    limit: params.limit,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
    minimumTransactions: params.minimumTransactions !== undefined ? Number(params.minimumTransactions) : undefined,
    query: params.query === undefined || params.query.length === 0 ? undefined : params.query,
    typeNames: params.typeNames,
    wallets: params.wallets,
  }
}

export const defaultMappingParams: WithoutWorkspaceId<GetCOAMappingsRequest> = {
  type: undefined,
  excludedIds: undefined,
  accountType: undefined,
  page: 0,
  limit: 10,
}

export const mappingHasAnyFilter = (filters: Partial<GetCOAMappingsRequest>) =>
  Boolean(filters.type || filters.accountType)

interface ExchangeWalletTabProps {
  openMappingDrawer: <PassedItemId extends string | null>(
    data: PassedItemId,
    formData?: (null extends PassedItemId ? Partial<Record<string, unknown>> | undefined : undefined) | undefined,
  ) => void
}

const ExchangeWalletTab = (props: ExchangeWalletTabProps): JSX.Element => {
  const { openMappingDrawer } = props
  const [hiddenColumns] = useLocalStorage<string[]>(`hidden_columns_imports`, [])

  const { isTab } = useContext(AccountingFilterContext)

  const { params, setLimit, setPage, setSorting } = usePaginatedParams<Partial<ImportParameters>, GetWalletsSortByEnum>(
    defaultImportParams,
    defaultImportSorting,
  )

  const wallets = api.wallet.useWallets(
    {
      ...paramsToFilter(params),
      walletTypeTypes: isTab("exchange_mapping") ? WalletKindsExchangeMapping : WalletKindsWalletMapping,
      excludeWithMapping: true,
    },
    {
      refetchInterval: 2000,
    },
  )

  const columns = useMemo<IExtendedDataTableColumn<GetWalletDto, GetWalletsSortByEnum>[]>(
    () => [
      {
        minWidth: "50px",
        width: "50px",
        maxWidth: "50px",
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        sortName: "name",
      },
      {
        name: "Type",
        selector: "sourceName",
        sortable: true,
        typeNames: "source_id",
      },
      {
        name: "Address",
        selector: "address",
        sortable: true,
        typeNames: "address",
        format: function formatAddress(row: GetWalletDto): JSX.Element | string {
          if (row.address === null) return "-"
          return (
            <CopyableText text={row.address}>
              <AddressTypography component="span">{row.address}</AddressTypography>
            </CopyableText>
          )
        },
        omit: isTab("exchange_mapping"),
      },
      {
        name: "Transactions",
        selector: "transactionCount",
        sortable: true,
        sortName: "transaction_count",
        format: function formatContactAddresses(row) {
          if (row.transactionCount === 0) return "0"
          return (
            <Box>
              <Link
                component={RouterLink}
                to={URLS.Transactions.getUrl({
                  filters: [{ type: "wallets", value: [toFilterElement(row)], isNot: false }],
                })}
                underline="always"
              >
                {row.transactionCount}
              </Link>
            </Box>
          )
        },
      },
      {
        minWidth: "50px",
        width: "50px",
        maxWidth: "50px",
      },
    ],
    [isTab],
  )

  const omittedColumns = useMemo(() => {
    return columns.map((c) => ({
      ...c,
      omit: c.omit || hiddenColumns.includes(c.name as string),
    }))
  }, [columns, hiddenColumns])

  return (
    <>
      {wallets && wallets.data && (
        <Paper>
          <ServerSideTable<GetWalletDto, GetWalletsSortByEnum>
            columns={omittedColumns}
            items={wallets.data.data}
            limit={wallets.data.limit}
            defaultParams={params}
            totalCount={wallets.data.totalCount}
            onPageChange={setPage}
            onLimitChange={setLimit}
            onSort={setSorting}
            onRowClicked={(row) => openMappingDrawer(null, { wallets: [row] })}
          />
        </Paper>
      )}
    </>
  )
}

export default ExchangeWalletTab
