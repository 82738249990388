import { useEffect, useMemo, useState } from "react"
import { UseQueryResult } from "react-query"

import { SelfPaginatedQuery } from "services/utils/types"

export type SelectedRowsType =
  | {
      type: "positiveAdd"
      itemIds: string[]
    }
  | {
      type: "negativeAdd"
      excludedIds: string[]
    }

function useBulkSelectorTransactionPage<T>(
  rows: UseQueryResult<SelfPaginatedQuery<T>, unknown>["data"] | undefined,
  nonMemoizedGetRowId: (row: T) => string,
) {
  const [selectedRows, setSelectedRows] = useState<SelectedRowsType | undefined>(undefined)

  // Without this Memo, the useBulkSelectorTransactionPage rerenders the component everytime,
  // as `getRowId` is usually specified as an arrow function which is
  // different with every call
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getRowId = useMemo(() => nonMemoizedGetRowId, [])

  const { isPageSelected, onPageSelected, selectedRowCount } = useMemo(() => {
    const isPageSelected = (() => {
      if (rows === undefined) return false
      if (selectedRows?.type === "positiveAdd") {
        // all transaction on this page are in the selected transaction list
        return rows.data.every((x) => selectedRows.itemIds.includes(getRowId(x)))
      } else if (selectedRows?.type === "negativeAdd") {
        // all transaction on this page are NOT in the excluded transaction list
        return rows.data.every((x) => !selectedRows.excludedIds.includes(getRowId(x)))
      }
      return false
    })()

    const selectedRowCount = (() => {
      if (rows === undefined) return 0
      if (selectedRows?.type === "positiveAdd") return selectedRows.itemIds.length
      else if (selectedRows?.type === "negativeAdd") return rows.totalCount - selectedRows.excludedIds.length

      return 0
    })()
    // rows, selectedRows
    const onPageSelected = () => {
      if (rows === undefined) return

      if (selectedRows?.type === "positiveAdd") {
        if (isPageSelected) {
          setSelectedRows({
            // remove all transaction ids from this page
            ...selectedRows,
            itemIds: selectedRows.itemIds.filter((x) => !rows.data.some((tx) => getRowId(tx) === x)),
          })
        } else {
          setSelectedRows({
            // add all transaction ids from this page
            ...selectedRows,
            itemIds: Array.from(new Set(selectedRows.itemIds.concat(rows.data.map((x) => getRowId(x))))),
          })
        }
      } else if (selectedRows?.type === "negativeAdd") {
        if (isPageSelected) {
          setSelectedRows({
            // add transaction to excluded list
            ...selectedRows,
            excludedIds: Array.from(new Set(selectedRows.excludedIds.concat(rows.data.map((x) => getRowId(x))))),
          })
        } else {
          setSelectedRows({
            // remove transaction from excluded list
            ...selectedRows,
            excludedIds: selectedRows.excludedIds.filter((x) => !rows.data.some((tx) => getRowId(tx) === x)),
          })
        }
      } else {
        setSelectedRows({
          // create a new selection list
          type: "positiveAdd",
          itemIds: rows.data.map((x) => getRowId(x)),
        })
      }
    }

    return {
      isPageSelected,
      selectedRowCount,
      onPageSelected,
    }
  }, [selectedRows, getRowId, rows])

  useEffect(() => {
    if (selectedRows === undefined || !rows) return
    if (selectedRows.type === "positiveAdd" && selectedRows.itemIds.length === 0) setSelectedRows(undefined) // if the user deselected all rows in individual mode, remove selection
    if (selectedRows.type === "negativeAdd" && selectedRows.excludedIds.length === rows.totalCount) {
      // if the user excluded all rows in filtered mode, remove selection
      setSelectedRows(undefined)
    }
    if (selectedRows.type === "positiveAdd" && selectedRows.itemIds.length === rows.totalCount) {
      // If the user manually selected all pages like a dumb ass, move to filtered
      setSelectedRows({
        type: "negativeAdd",
        excludedIds: [],
      })
    }
  }, [selectedRows, rows])

  return {
    selectedRows,
    setSelectedRows,
    isPageSelected,
    selectedRowCount,
    onPageSelected,
  }
}

export default useBulkSelectorTransactionPage
