import { useTypedController } from "@hookform/strictly-typed"
import { Box, TextField } from "@material-ui/core"
import PlainDatePicker from "components/DatePickers/DatePicker"
import { useToast } from "CryptioUI/Toaster"
import React, { useCallback, useContext, useEffect } from "react"
import { useForm } from "react-hook-form"

import { PlainDate } from "../../../../components/DateRangePicker"
import { DrawerCategory, DrawerFormSection } from "../../../../components/Drawer/DrawerItems"
import { useLoadingButton } from "../../../../components/LoadingButton"
import useDialog from "../../../../components/misc/useDialog"
import { DrawerProp } from "../../../../components/misc/useDrawer"
import PermissionDisabled from "../../../../components/Permission/PermissionDisabled"
import { toastCatch } from "../../../../components/ReactHookForm/utils"
import api from "../../../../services/api"
import { GetImpairmentDto } from "../../../../services/api/openapi"
import { WorkspaceContext } from "../../../../services/context/workspaceContext"

interface FormType {
  endDate: PlainDate | null
  description?: string
}

const ImpairmentForm = ({ isOpen, onClose, setFormDirty }: DrawerProp<GetImpairmentDto, true>) => {
  const { workspace } = useContext(WorkspaceContext)
  const { handleSubmit, control, formState, reset } = useForm<FormType>({
    mode: "onChange",
  })
  const TypedController = useTypedController<FormType>({ control })
  const toast = useToast()

  const isFormDirty = formState.isValid && formState.isDirty

  useEffect(() => setFormDirty(isFormDirty), [isFormDirty, setFormDirty])

  const basicDialog = useDialog()

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen, reset])

  const { mutateAsync: createImpairmentMutation } = api.impairment.useCreateImpairment()
  const [SaveImpairmentButton, handleButtonCallback] = useLoadingButton()
  const onSubmit = useCallback(
    async (form: FormType) => {
      if (!form.endDate) return
      try {
        await createImpairmentMutation({
          createImpairmentDto: {
            startDate: PlainDate.fromBackendDate("2008-01-01").toBackendDate(),
            endDate: form.endDate.toBackendDate(),
            description: form.description ?? null,
          },
        })
        toast.open("Impairment created", { variant: "success" })

        onClose()
      } catch (e) {
        toastCatch(e, toast)
      }
    },
    [onClose, createImpairmentMutation, toast],
  )

  return (
    <DrawerCategory component="form" onSubmit={handleButtonCallback(handleSubmit(onSubmit))} title="New Impairment">
      {basicDialog.dialog}

      <DrawerFormSection name="Impairment date">
        <TypedController
          name="endDate"
          defaultValue={null}
          rules={{
            required: true,
            validate: (endDate) =>
              endDate !== null && endDate.isValid && endDate.date.isBefore(workspace.lock.inclusivePlainEndDate),
          }}
          render={({ onChange, value }) => (
            <PlainDatePicker
              disableFuture={true}
              maxDateMessage="Impairment date cannot be after the workspace lock date"
              maxDate={workspace.lock.inclusivePlainEndDate ?? undefined}
              value={value ?? undefined}
              onChange={(endDate) => onChange(endDate)}
              fullWidth
            />
          )}
        />
      </DrawerFormSection>

      <DrawerFormSection htmlFor="description-textfield" name="Notes">
        <TypedController
          name="description"
          defaultValue=""
          render={({ ref: _, ...props }) => (
            <TextField id="description-textfield" {...props} multiline placeholder="Write anything..." fullWidth />
          )}
        />
      </DrawerFormSection>

      <Box mt={3} display="flex" justifyContent="space-between">
        <PermissionDisabled permission="can_create_impairment" action="create an impairment">
          <SaveImpairmentButton disabled={!workspace.lock.isEnabled || !formState.isValid} type="submit">
            Create
          </SaveImpairmentButton>
        </PermissionDisabled>
      </Box>
    </DrawerCategory>
  )
}

export default ImpairmentForm
