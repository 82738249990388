import React, { useContext } from "react"

import api from "services/api"
import { WorkspaceContext } from "services/context/workspaceContext"
import { Mixpanel } from "services/mixpanel"

import { ReactComponent as Reset } from "CryptioUI/assets/icons/reset.svg"
import { ReactComponent as Warning } from "CryptioUI/assets/icons/warning.svg"
import { ReactComponent as Dollar } from "CryptioUI/assets/icons/menu/billing.svg"

import { UserContext } from "services/context/userContext"
import { SelectedRowsType } from "../GeneratedTable/useBulkSelectorTransactionPage"
import { Badge, Box, makeStyles, Theme, Typography } from "@material-ui/core"
import MainTitleView from "../../MainTitleView"
import TotalCount from "../../filter/TotalCount"
import { pluralize } from "services/utils/textUtils"
import { prettyAccountingIntegrationNameMap } from "pure-shared"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import { LoadingButton } from "components/LoadingButton"
import { TransactionParams } from ".."
import { PaginationParameters } from "services/urlParse"
import TransactionFilter from "../filters/TransactionFilter"
import OmitColumnSelector from "../GeneratedTable/OmitColumnSelector"
import { ColumnsName } from "../GeneratedTable/ContainerColumn"
import InvoiceListModal from "../invoices/InvoiceListModal"
import { SelectTransactionPageContextValue } from "services/context/selectTransactionPageContext"
import ExportFilter from "./ExportFilter"
import NeedReviewTabs from "./NeedReviewTabs"
import { FilterContext } from "services/context/filterContext"
import { useHistory } from "react-router"
import { NewInListTransactionDto } from "services/api/openapi"
import { URLS } from "../../../routes"
import MarkLedgerEntries from "./MarkLedgerEntries"
import ButtonUI from "CryptioUI/Button"
import { ButtonSize, Mode } from "CryptioUI/types"
import { iconStyleBlack, iconStyleWhite } from "CryptioUI/Utilities/config"
import { reviewFilters } from "../../../services/utils/toFilterElement"
import TooltipUI from "CryptioUI/Tooltip"
import numbro from "numbro"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 400,
  },
  tooltipIcon: {
    marginTop: theme.spacing(0.1),
    marginBottom: theme.spacing(0.1),
  },
  helpButton: {
    "color": theme.palette.secondary.main,
    "minHeight": "0.83em",
    "minWidth": "0.83em",
    "height": "0.83em",
    "width": "0.83em",
    "padding": "0px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  linkNoDecoration: {
    "display": "flex",
    "justifyContent": "center",
    "color": theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  needReview: {
    paddingLeft: theme.spacing(3),
    minWidth: "8em",
  },
  warningBanner: {
    paddingLeft: theme.spacing(4),
  },
  containerCardWarningBanner: {
    overflow: "visible",
    maxWidth: "700px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
  },
  textWaringBanner: {
    paddingLeft: theme.spacing(2),
  },
}))
interface FilterControllerParams {
  maxTotalCount: number | null
  totalCount: number
  selectedTransactionCount: number
  selectedRows: SelectedRowsType | undefined
  setSelectedRows: React.Dispatch<React.SetStateAction<SelectedRowsType | undefined>>
  setParamsWithDialog: (newParams: TransactionParams) => void
  params: PaginationParameters & TransactionParams
  columns: ColumnsName[]
  hiddenColumns: ColumnsName[]
  setHiddenColumns: (columns: ColumnsName[]) => void
  setIsBulkEditDrawerOpen: (isOpen: boolean) => void
  mvtSelected: SelectTransactionPageContextValue
  isRefetching: boolean
  transactions: NewInListTransactionDto[]
}

const selectedRowsText = (selectedTransactionsCount: number, selectedMovementsCount: number) => {
  const selectedCount = (count: number, item: string) => (count ? `${count} ${pluralize(count > 1, item)}` : null)

  const transactionsText = selectedCount(selectedTransactionsCount, "transaction")
  const movementText = selectedCount(selectedMovementsCount, "movement")

  if (transactionsText && movementText) {
    return `${transactionsText} and ${movementText} selected`
  } else if (transactionsText) {
    return `${transactionsText} selected`
  } else if (movementText) {
    return `${movementText} selected`
  } else {
    return ""
  }
}

const FilterController = (props: FilterControllerParams): JSX.Element => {
  const {
    selectedTransactionCount,
    selectedRows,
    setSelectedRows,
    setParamsWithDialog,
    params,
    columns,
    hiddenColumns,
    setHiddenColumns,
    setIsBulkEditDrawerOpen,
    mvtSelected,
    maxTotalCount,
    totalCount,
    isRefetching,
    transactions,
  } = props
  const { isNeedReview, isTab } = useContext(FilterContext)
  const workspaceCtx = useContext(WorkspaceContext)
  const toast = useToast()
  const { mutateAsync: bulkUpdateMovementsMutation } = api.transaction.useBulkUpdateMovements()
  const { mutateAsync: updateCostBasisMutation } = api.transaction.useUpdateCostBasis()
  const labelMetrics = api.transaction.useReviewMetrics().data
  const { mutateAsync: updateWorkspaceValuationMutation } = api.wallet.useWorkspaceValuationUpdate()
  const { user } = useContext(UserContext)
  const classes = useStyles()
  const history = useHistory()

  const updateCostBasis = async () => {
    try {
      await updateCostBasisMutation({})
      toast.open("Updating cost basis...", { variant: "default" })
    } catch (e) {
      toastCatch(e, toast)
    }
    Mixpanel.track("UpdateCostBasis")
  }

  const syncTransactionsToAccountingSystem = async () => {
    try {
      await bulkUpdateMovementsMutation({
        movementBulkUpdateDto: {
          filteredTransactions:
            selectedRows?.type === "negativeAdd"
              ? {
                  excludedIds: selectedRows.excludedIds,
                  ...params,
                }
              : undefined,
          individualTransactions:
            selectedRows?.type === "positiveAdd"
              ? {
                  transactionIds: selectedRows.itemIds,
                }
              : undefined,
          synchronizationAction: "syncToAccountingSystem",
        },
      })
      toast.open("Synchronizing transactions", { variant: "default" })

      setSelectedRows(undefined)
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const countToReview = (labelMetrics?.internalTransferNeedReviewCount ?? 0) + (labelMetrics?.noLabelCount ?? 0)

  const valuateTransactions = async () => {
    try {
      await updateWorkspaceValuationMutation({})
      toast.open("Updating valorisation", { variant: "success" })
    } catch {
      toast.open("Could not valuate selected sources", { variant: "warning" })
    }
  }

  const canSeeBackOffice =
    user.role === "business_administrator" || user.role === "tech_administrator" || user.role === "reporter"
  const isInSyncTab = isNeedReview && isTab("readyToBeSynchronized")

  const rowsCount = selectedTransactionCount + mvtSelected.selectedRowCount

  return (
    <>
      <div
        className={`flex flex-col justify-between items-between mb-4 ${
          isNeedReview ? "min-h-[123px]" : "min-h-[92px]"
        }`}
      >
        <div>
          <div className="w-full flex flex-row justify-between">
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <MainTitleView
                title={"Transactions"}
                helpTooltipContent="Browse your transactions and adjust labels, USD rates, notes, invoices..."
              />
              <Box className={classes.needReview}>
                <Badge badgeContent={isNeedReview ? 0 : countToReview} color="error">
                  <ButtonUI
                    size={ButtonSize.MD}
                    mode={isNeedReview ? Mode.OUTLINEDHIGHLIGHT : Mode.DEFAULT}
                    onClick={() => {
                      Mixpanel.track("ClickNeedReview")
                      if (!isNeedReview) {
                        history.push(URLS.TransactionsReviewMissingLabel.getUrl(reviewFilters.missingLabel))
                      } else {
                        history.push(URLS.Transactions.getUrl())
                      }
                    }}
                  >
                    Needs review
                  </ButtonUI>
                </Badge>
              </Box>
            </Box>
            <Box display="flex" alignItems="flex-end" flexDirection="row" pr="2.9rem">
              {rowsCount ? (
                <>
                  <Box mr={2}>
                    <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
                      <ButtonUI onClick={() => setIsBulkEditDrawerOpen(true)}>
                        Edit {rowsCount} selected {pluralize(rowsCount > 1, "row")}
                      </ButtonUI>
                    </PermissionDisabled>
                  </Box>
                </>
              ) : (
                <></>
              )}
              {selectedTransactionCount || mvtSelected.selectedRowCount > 0 ? (
                <>
                  {/* TODO: limit max transactions ? */}
                  {workspaceCtx.workspace.accountingIntegration &&
                    workspaceCtx.workspace.accountingIntegration.name !== "custom" && (
                      <div className="mt-4 mr-4 flex flex-row space-x-0">
                        <>
                          <ButtonUI
                            disabled={selectedTransactionCount === 0 && mvtSelected.selectedRowCount > 0}
                            onClick={syncTransactionsToAccountingSystem}
                          >
                            Synchronize {selectedTransactionCount} {pluralize(rowsCount > 1, "transaction")} with{" "}
                            {prettyAccountingIntegrationNameMap[workspaceCtx.workspace.accountingIntegration.name]}
                          </ButtonUI>
                          {mvtSelected.selectedRowCount > 0 ? (
                            <ButtonUI>
                              <TooltipUI
                                classNameTrigger={classes.tooltipIcon}
                                content={
                                  <Typography>
                                    Your selection may contains <i>complex</i> transactions. These transactions can not
                                    be currently booked into an accounting system and will be ignored. Please reach out
                                    to us if you need to book these transactions.
                                  </Typography>
                                }
                              >
                                <a
                                  // onClick={() => onClick && onClick()}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={"https://support.cryptio.co/hc/en-gb"}
                                >
                                  <Warning className={iconStyleWhite} />
                                </a>
                              </TooltipUI>
                            </ButtonUI>
                          ) : (
                            <></>
                          )}
                        </>
                      </div>
                    )}
                </>
              ) : (
                <></>
              )}
              <Box>
                <ExportFilter filter={params} transactions={transactions} />
              </Box>
            </Box>
          </div>
          <Box>
            <Typography variant="body1">
              <TotalCount
                isRefetching={isRefetching}
                totalCount={totalCount}
                maxTotalCount={maxTotalCount}
                item="transaction"
              />
              {rowsCount > 0 && (
                <TooltipUI content={selectedRowsText(selectedTransactionCount, mvtSelected.selectedRowCount)}>
                  <span>
                    {" "}
                    - {numbro(rowsCount).format({ thousandSeparated: true })} {pluralize(rowsCount > 1, "row")} selected
                  </span>
                </TooltipUI>
              )}
            </Typography>
          </Box>
        </div>
        <div className="flex flex-row justify-between items-end">
          <div className="">
            <NeedReviewTabs setParamsWithDialog={setParamsWithDialog} filter={params} labelMetrics={labelMetrics} />
          </div>
          <div className="flex flex-wrap-reverse justify-end gap-y-4">
            <MarkLedgerEntries show={isInSyncTab && !isRefetching && transactions.length > 0} />
            {(canSeeBackOffice || workspaceCtx.workspace.features.includes("update_valuation")) && (
              <Box mr={2}>
                <ButtonUI
                  mode={Mode.CONTAINED}
                  size={ButtonSize.LG}
                  onClick={valuateTransactions}
                  Icon={<Dollar className={iconStyleBlack} />}
                >
                  Price transactions
                </ButtonUI>
              </Box>
            )}
            <Box mr={2}>
              <PermissionDisabled permission="can_update_cost_basis" action="update a cost basis">
                <LoadingButton
                  mode={Mode.CONTAINED}
                  pending={workspaceCtx.workspace.costBasisStatus.status !== "inactive"}
                  onClick={updateCostBasis}
                  disabled={workspaceCtx.workspace.costBasisStatus.status !== "inactive"}
                  Icon={<Reset className={iconStyleBlack} />}
                >
                  Update cost basis
                </LoadingButton>
              </PermissionDisabled>
            </Box>
            <Box mr={2}>
              <InvoiceListModal />
            </Box>
            <Box mr={2}>
              <TransactionFilter setFilter={setParamsWithDialog} filter={params} />
            </Box>
            <Box mr={1}>
              <OmitColumnSelector
                columns={columns.filter((column) => column !== ColumnsName.CHECKBOX)}
                hiddenColumns={hiddenColumns}
                setHiddenColumns={setHiddenColumns}
              />
            </Box>
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterController
