import FavoriteButton from "components/CoreTransaction/filters/TransactionFilter/FavoriteTab/FavoriteButton"
import FavoriteModale from "components/CoreTransaction/filters/TransactionFilter/FavoriteTab/FavoriteModal"
import IconButton from "components/CoreTransaction/filters/TransactionFilter/IconButton"
import { toastCatch } from "components/ReactHookForm/utils"
import CustomSelector from "components/selector/CustomSelector"
import Icon from "CryptioUI/Icon"
import ModalUI from "CryptioUI/Modal"
import { useToast } from "CryptioUI/Toaster"
import { useState } from "react"
import api from "services/api"
import { GetFavoriteReportFilterDto } from "services/api/openapi"
import { ReportFormComponentProps } from "."

export const ReportFavoriteFilters = ({ control }: ReportFormComponentProps) => {
  const { getValues, setValue } = control
  const toast = useToast()
  const [isCreateModalOpen, setCreateIsModalOpen] = useState<boolean>(false)
  const [isSelectModalOpen, setSelectIsModalOpen] = useState<boolean>(false)

  const [selectedFavorite, setSelectedFavorite] = useState<GetFavoriteReportFilterDto | null>()

  const { mutateAsync: addFavoriteReportFilter } = api.report.useAddFavoriteReportFilter()
  const { mutateAsync: deleteFavoriteReportFilter } = api.report.useDeleteFavoriteReportFilter()

  const wallets = getValues("wallets") ?? []
  const assets = getValues("assets") ?? []

  const createFavoriteFilter = async (name: string) => {
    try {
      await addFavoriteReportFilter({
        addFavoriteReportFilterDto: {
          name,
          walletIds: wallets?.map((w) => w.id),
          assetIds: assets?.map((w) => w.id),
        },
      })
      toast.open(`Favorite filter '${name}' created`, { variant: "default" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const deleteSavedFilter = async (id: string) => {
    try {
      await deleteFavoriteReportFilter({ id })
      toast.open(`Favorite filter have been deleted`, { variant: "default" })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <div className="pt-4">
          <IconButton
            onClick={() => setSelectIsModalOpen(true)}
            title="Use saved filters"
            icon={<Icon name="FloppyDisc" className="hover:cursor-pointer h-4 w-4" />}
          />
        </div>
        <FavoriteButton
          updateFavoriteFilter={createFavoriteFilter}
          setIsModalOpen={setCreateIsModalOpen}
          canSave={true}
        />
      </div>

      <ModalUI
        isOpen={isSelectModalOpen}
        onClose={() => setSelectIsModalOpen(false)}
        onCancel={() => {
          if (selectedFavorite) {
            deleteSavedFilter(selectedFavorite?.id)
            setSelectIsModalOpen(false)
            setSelectedFavorite(undefined)
          }
        }}
        className="w-[600px] h-auto"
        title="Select favorite filter"
        actionTitle="Apply"
        cancelTitle="Delete selected filter"
        disableCancel={!selectedFavorite}
        onAction={() => {
          setValue("assets", selectedFavorite?.assets ?? [])
          setValue("wallets", selectedFavorite?.wallets ?? [])
          setSelectIsModalOpen(false)
        }}
        // isLoadingButton
        // isLoadingButtonPending={assetsFetch.isLoading || walletsFetch.isLoading}
        disabled={false}
      >
        <CustomSelector
          id="favorite-filter-select"
          onChange={(_, newValue) => setSelectedFavorite(newValue)}
          value={selectedFavorite}
          getOptionLabel={(option) => option.name}
          usePaginatedQuery={api.report.useFavoriteReportFilters}
          defaultPaginatedQueryProps={{
            page: 1,
            limit: 5,
          }}
        />
      </ModalUI>
      <FavoriteModale
        isOpen={isCreateModalOpen}
        onClose={() => setCreateIsModalOpen(false)}
        onSave={(name: string) => {
          createFavoriteFilter(name)
          setCreateIsModalOpen(false)
        }}
      />
    </>
  )
}
