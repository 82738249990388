import { Box, DialogActions, makeStyles, Theme, Typography } from "@material-ui/core"
import { ExitToAppRounded } from "@material-ui/icons"
import React, { useState } from "react"

import ExternalLink from "components/ExternalLink"
import { UncontrolledLoadingButton } from "components/LoadingButton"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import ModalUI from "CryptioUI/Modal"
import { Mode } from "CryptioUI/types"
import api from "services/api"
import { GetWalletDto } from "services/api/openapi"
import InputButtonUI from "CryptioUI/InputButton"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  fileUploadField: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  attachCsvFileText: {
    marginTop: theme.spacing(2),
  },
  externalLink: {
    padding: 0,
    marginTop: theme.spacing(3),
    textAlign: "end",
  },
  csvExampleMenuItem: {
    padding: 0,
    marginTop: theme.spacing(3),
    textAlign: "end",
  },
  linkIcon: {
    display: "inline-flex",
    verticalAlign: "top",
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  wallet: GetWalletDto
  isOpen: boolean
  onClose: () => void
}

export default function UpdateCustomSourceModal({ wallet, isOpen, onClose }: Props) {
  const classes = useStyles()
  const [csvFile, setCsvFile] = useState<File | null>(null)
  const toast = useToast()
  const { mutateAsync: updateCustomWalletMutation } = api.wallet.useAddCsvToWallet()
  const { mutateAsync: replaceCSVWithError } = api.wallet.useReplaceCSVWithError()

  const submitImport = async () => {
    try {
      if (!csvFile) {
        return
      }
      if (wallet.hasError) {
        await replaceCSVWithError({
          walletId: wallet.id,
          file: csvFile,
        })
      } else {
        await updateCustomWalletMutation({
          walletId: wallet.id,
          file: csvFile,
        })
      }
      toast.open("Importing transactions...", { variant: "success" })
      onClose()
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFile = event.target.files.item(0) as File
      // 500mb
      if (newFile.size > 500e6) {
        toast.open("File exceeds 500MB", { variant: "danger" })
      } else {
        setCsvFile(newFile)
      }
    }
  }

  return (
    <ModalUI isOpen={isOpen} onClose={onClose} title="Update CSV source" className="w-[500px] h-auto">
      <Box width="100%" display="flex" justifyItems="flex-start" flexDirection="column">
        <Typography variant="subtitle1" className={classes.attachCsvFileText}>
          (format csv, 500MB max)
        </Typography>
        <InputButtonUI
          mode={Mode.CONTAINED}
          fullWidth={true}
          htmlFor="customCsv"
          type="file"
          accept=".csv"
          name="customCsv"
          id="customCsv"
          onChange={handleFileChange}
        >
          {csvFile ? `${csvFile.name} (${(csvFile.size / 1e6).toFixed(2)}MB)` : "Choose csv file"}
        </InputButtonUI>
        {(wallet.sourceName === "Custom" || !wallet.isCsvOnly) && (
          <Box display="flex" justifyContent="space-around">
            <ExternalLink
              className={classes.csvExampleMenuItem}
              href={"https://docs.google.com/spreadsheets/d/1ve3usOD7gpnF6Q4nm8_RWzTHlgpCKyURpkeNdxW_I6k"}
            >
              See CSV example
              <ExitToAppRounded className={classes.linkIcon} />
            </ExternalLink>

            <ExternalLink className={classes.externalLink} href={`/assets`}>
              See asset list
            </ExternalLink>
          </Box>
        )}
        <DialogActions>
          <PermissionDisabled permission="can_modify_wallet" action="update custom source">
            <UncontrolledLoadingButton disabled={csvFile === null} onClick={submitImport}>
              Update
            </UncontrolledLoadingButton>
          </PermissionDisabled>
        </DialogActions>
      </Box>
    </ModalUI>
  )
}
