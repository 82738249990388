import React from "react"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import TagUI from "CryptioUI/Tag"
import { ReactComponent as AddIcon } from "CryptioUI/assets/icons/add.svg"
import { TagSize } from "CryptioUI/types"

interface Props {
  onClick: () => void
}

export const AddLabelButton = ({ onClick }: Props) => {
  const StyledAddIcon = <AddIcon className="w-3 h-3" />

  return (
    <PermissionDisabled permission="can_modify_transaction" action="modify a transaction">
      <TagUI ellipsisPosition="end" label="Add label" Icon={StyledAddIcon} onClick={onClick} size={TagSize.SM} />
    </PermissionDisabled>
  )
}

export default AddLabelButton
