import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import React from "react"
import { LightGrey } from "../../../materialTheme"

const STOP_PROP_TAG = "allowRowEvents"
interface ContainerRowHeaderProps {
  children?: React.ReactNode
  noBorderTop?: boolean
  borderBottom?: boolean
  nonClickable?: boolean
  onClick?: () => void
  isMatchingFilter?: boolean
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    minWidth: "fit-content",
    maxHeight: "42px",
    height: "42px",
    paddingRight: "15px",
    minHeight: "42px",
    width: "100%",
    borderTopLeftRadius: "12px",
    overflow: "hidden",
    borderTopRightRadius: "12px",
    backgroundColor: "#F2F4F8",
    border: "none",
  },
  clickable: {
    "&:hover": {
      backgroundColor: "#F5F5F5",
      cursor: "pointer",
    },
  },
  borderTop: {
    borderTop: "solid",
    borderWidth: "1px",
    borderColor: LightGrey,
  },
  borderBottom: {
    borderBottom: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: LightGrey,
  },
  filtered: {
    opacity: 0.25,
  },
}))

const ContainerRowHeader = (props: ContainerRowHeaderProps) => {
  const { children, noBorderTop = false, borderBottom = false, onClick, nonClickable, isMatchingFilter = true } = props
  const classes = useStyles()

  const rowStyle = () => {
    return clsx(
      classes.container,
      !noBorderTop ? classes.borderTop : "",
      borderBottom ? classes.borderBottom : "",
      !nonClickable ? classes.clickable : "",
      !isMatchingFilter ? classes.filtered : "",
    )
  }

  const handleClickRow = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement
    if (target && target.getAttribute("data-tag") === STOP_PROP_TAG) {
      if (onClick) onClick()
    }
  }

  return (
    <div data-test-id="row" className={rowStyle()} onClick={handleClickRow}>
      {children}
    </div>
  )
}

export default ContainerRowHeader
