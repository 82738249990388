import { Avatar, Box, createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import dayjs from "dayjs"
import { prettyAccountingIntegrationNameMap } from "pure-shared"
import React, { useContext } from "react"

import { UncontrolledLoadingButton } from "components/LoadingButton"
import { Mode } from "CryptioUI/types"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import BackOfficeView from "../../../../components/misc/BackOfficeView"
import QuickBooksAutoIncrement from "../QuickBooksAutoIncrement"
import { IntegrationBlockProps } from "."
import api from "services/api"
import { WorkspaceContext } from "services/context/workspaceContext"
import { useToast } from "CryptioUI/Toaster"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      minHeight: "250px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    disabledRoot: {
      padding: theme.spacing(2),
      minHeight: "250px",
      backgroundColor: "#EDEDED",
      cursor: "not-allowed",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    containerText: {
      height: "100%",
    },
    avatar: {
      marginRight: "16px",
    },
    avatarCustom: {
      "marginRight": "16px",
      "& img": {
        border: "solid #fff",
      },
    },
  }),
)

// if connected, ignore disabled
// onSelect == connect if !connected | otherwise | onSelect == updateCOA
const OAuth2IntegrationBlock = ({
  onDisconnect,
  onUpdate,
  disabled,
  integration,
  workspaceIntegration,
  askConnect,
}: IntegrationBlockProps) => {
  const classes = useStyles()
  const prettyIntegrationName = prettyAccountingIntegrationNameMap[integration.name]
  const { workspace } = useContext(WorkspaceContext)
  const toast = useToast()

  const onConnect = async () => {
    try {
      const url = await api.accounting.getOAuthRedirectUrl({
        workspaceId: workspace.id,
        integrationName: integration.name,
      })

      window.location.href = url.url
    } catch (e) {
      toast.open(e, { variant: "danger" })
    }
  }

  return (
    <Paper className={disabled && !workspaceIntegration ? classes.disabledRoot : classes.root}>
      <Box display="flex" alignItems="center">
        <Avatar alt={prettyIntegrationName} src={integration.logo} className={classes.avatar} />
        <Typography variant="h2">
          {prettyIntegrationName}
          {workspaceIntegration && ` - ${workspaceIntegration.companyName}`}
        </Typography>
      </Box>
      <Box mt={2} display="flex" justifyContent="flex-start" flexDirection="column" className={classes.containerText}>
        <Typography variant="body1">
          Connecting a {prettyIntegrationName} account allows to pull the chart of accounts directly from{" "}
          {prettyIntegrationName}. Then it is possible to use automated chart of accounts mapping features, and
          synchronize transactions with {prettyIntegrationName}.
        </Typography>

        {workspaceIntegration && (
          <Box mt={1}>
            <Typography color={workspaceIntegration.connectionErrorAt !== null ? "error" : undefined}>
              You are {workspaceIntegration.connectionErrorAt !== null ? "disconnected from" : "connected to"}{" "}
              {prettyIntegrationName}
            </Typography>

            <Box mt={1} />

            <Typography>
              {workspaceIntegration.coaUpdatedAt
                ? `Last COA update was ${dayjs(workspaceIntegration.coaUpdatedAt).fromNow()}`
                : "Never updated COA"}
            </Typography>
          </Box>
        )}
      </Box>
      <div className="flex flex-row mt-4">
        {!workspaceIntegration ? (
          <PermissionDisabled
            permission="can_connect_to_accounting_integration"
            action="connect to an accounting integration"
          >
            <UncontrolledLoadingButton onClick={() => askConnect(onConnect)} disabled={disabled}>
              Connect
            </UncontrolledLoadingButton>
          </PermissionDisabled>
        ) : (
          <>
            <div className="flex flex-row space-x-4">
              <UncontrolledLoadingButton onClick={onDisconnect} mode={Mode.CONTAINED}>
                Disconnect
              </UncontrolledLoadingButton>
              <UncontrolledLoadingButton onClick={() => askConnect(onConnect)}>Reconnect</UncontrolledLoadingButton>
              <UncontrolledLoadingButton onClick={onUpdate}>Update chart of accounts</UncontrolledLoadingButton>
              {integration.name === "quickbooks" && (
                <BackOfficeView>
                  <QuickBooksAutoIncrement />
                </BackOfficeView>
              )}
            </div>
          </>
        )}
      </div>
    </Paper>
  )
}

export default OAuth2IntegrationBlock
