import { useMutation, useQuery } from "react-query"

import { queryClient } from "../../../.."
import { BackOfficeGetWorkspaceJobsQueueNameEnum, BackOfficeJobsApi } from "../../openapi"

const BACK_OFFICE_GET_QUEUE_NAMES_KEY = "backOfficeGetQueueNames"
const BACK_OFFICE_GET_WK_JOBS_KEY = "backOfficeGetWKJobs"
const BACK_OFFICE_GET_QUEUE_JOBS_KEY = "backOfficeGetQueueJobs"

export const backOfficeJobsActions = {
  useQueueNames: () => {
    return useQuery([BACK_OFFICE_GET_QUEUE_NAMES_KEY], () => BackOfficeJobsApi.backOfficeGetQueues())
  },
  useWorkspaceJobs: (workspaceId: string, queueName?: BackOfficeGetWorkspaceJobsQueueNameEnum) => {
    return useQuery(
      [BACK_OFFICE_GET_WK_JOBS_KEY, workspaceId, queueName],
      () => BackOfficeJobsApi.backOfficeGetWorkspaceJobs({ workspaceId: workspaceId, queueName: queueName }),
      {
        refetchInterval: 1000,
      },
    )
  },
  useQueueJobs: (queueName: string) => {
    return useQuery(
      [BACK_OFFICE_GET_QUEUE_JOBS_KEY, queueName],
      () => BackOfficeJobsApi.backOfficeGetQueueJobs({ queueName }),
      {
        refetchInterval: 1000,
      },
    )
  },
  useKillJob: () => {
    return useMutation(BackOfficeJobsApi.backOfficeKillJob, {
      onSuccess: () => {
        queryClient.invalidateQueries(BACK_OFFICE_GET_WK_JOBS_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_WK_JOBS_KEY)
      },
    })
  },
}
