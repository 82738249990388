import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import { VisibilityOff } from "@material-ui/icons"
import React from "react"

import BaseContainer from "../Container"
import MainTitleView from "../MainTitleView"

const useStyles = makeStyles((theme: Theme) => ({
  noMappingText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}))

const PermissionReadView = ({ viewTitle, itemName }: { viewTitle: string; itemName: string }) => {
  const classes = useStyles()

  return (
    <BaseContainer>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <MainTitleView title={viewTitle} />
      </Box>
      <Box height="inherit" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <VisibilityOff fontSize="large" />
        <Typography className={classes.noMappingText} variant="h4">
          Your current role does not allow to see {itemName}.
          <br />
          An administrator can change your role.
        </Typography>
      </Box>
    </BaseContainer>
  )
}

export default PermissionReadView
