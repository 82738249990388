import { Button, Divider, MenuItem, Popover, SvgIconTypeMap, Typography } from "@material-ui/core"
import { OverridableComponent } from "@material-ui/core/OverridableComponent"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { ArrowDropDownRounded } from "@material-ui/icons"
import { FeatureGate } from "components/Feature/FeatureGate"
import React from "react"
import { NavLink } from "react-router-dom"

import { LightGrey, White } from "../../materialTheme"
import { GetWorkspaceDtoFeaturesEnum } from "../../services/api/openapi"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeLink: {
      // remove focus
      color: `${theme.palette.action.selected} !important`,
      borderBottomWidth: "3px",
      marginTop: "3px", // Compensates for the border-bottom
      borderBottom: "solid",
    },
    inactiveLink: {
      "color": LightGrey,
      "&:hover": {
        color: White,
      },
    },
    linkTypography: {
      fontWeight: 800,
    },
    iconSpacer: {
      marginRight: theme.spacing(0.5),
    },
    activeSubLink: {
      color: `${theme.palette.action.selected} !important`,
    },
    inactiveSubLink: {
      "color": LightGrey,
      "&:hover": {
        color: White,
      },
    },
    dropDownIcon: {
      // Not pixel-perfect and kind of "magic" but it helps compensating for
      // the margins of the SVG icon in the navbar (otherwise it looks like
      // all the entries are not the same size)
      marginRight: "-0.5em",
    },
    menuPaper: {
      marginTop: theme.spacing(1),
      border: `1px solid #7676a3`,
      backgroundColor: theme.palette.primary.main,
      color: LightGrey,
    },
    dividerSubLink: {
      backgroundColor: "#555",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  }),
)

interface LinkIconProps {
  title: string
  url: string
  Icon?: OverridableComponent<SvgIconTypeMap>
  disabled?: boolean
  onClick?: () => void
}

export const LinkIcon = ({ title, url, Icon, disabled, onClick }: LinkIconProps) => {
  const classes = useStyles()

  return (
    <MenuItem
      onClick={onClick}
      component={NavLink}
      to={url}
      className={classes.inactiveLink}
      activeClassName={classes.activeLink}
      disabled={disabled}
    >
      {Icon && <Icon className={classes.iconSpacer} />}
      <Typography className={classes.linkTypography}>{title}</Typography>
    </MenuItem>
  )
}

// const ExternalLinkIcon = ({ title, url, Icon }: LinkIconProps) => {
//   const classes = useStyles()

//   return (
//     <MenuItem component="a" href={url} className={classes.inactiveLink} target="_blank" rel="noopener noreferrer">
//       {Icon && <Icon className={classes.iconSpacer} />}
//       <Typography className={classes.linkTypography}>{title}</Typography>
//     </MenuItem>
//   )
// }

export const MultiLinkIcon = ({
  title,
  options,
  Icon,
  active,
}: {
  title: string
  options: {
    url: string
    name: string
    feature?: GetWorkspaceDtoFeaturesEnum
    disabled?: boolean
    onClick?: () => void
  }[]
  Icon: OverridableComponent<SvgIconTypeMap>
  active: boolean
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const menuId = `${title}-menu`
  return (
    <>
      <Popover
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menuPaper }}
      >
        {options.map((option, index) => (
          <React.Fragment key={option.name}>
            <FeatureGate feature={option.feature}>
              {index !== 0 && <Divider className={classes.dividerSubLink} />}
              <MenuItem
                component={NavLink}
                to={option.url}
                className={classes.inactiveSubLink}
                activeClassName={classes.activeSubLink}
                disabled={option.disabled}
                onClick={() => {
                  handleClose()
                  option.onClick && option.onClick()
                }}
              >
                <Typography className={classes.linkTypography}>{option.name}</Typography>
              </MenuItem>
            </FeatureGate>
          </React.Fragment>
        ))}
      </Popover>
      <Button
        aria-haspopup="true"
        onClick={handleClickListItem}
        variant="text"
        color="inherit"
        style={{ padding: 0, alignItems: "normal" }}
        aria-controls={menuId}
      >
        <MenuItem className={active ? classes.activeLink : classes.inactiveLink}>
          <Icon className={classes.iconSpacer} />
          <Typography className={classes.linkTypography}>{title}</Typography>
          <ArrowDropDownRounded className={classes.dropDownIcon} />
        </MenuItem>
      </Button>
    </>
  )
}
