/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  BatchUpdateContactDto,
  CreateContactsDto,
  DeleteContactsDto,
  GetContactDto,
  GetUnknownContactQuery,
  PaginatedContactResultDto,
  PaginatedUnknownContactResultDto,
  UpdateContactDto,
} from "../models"

export interface BatchUpdateContactRequest {
  workspaceId: string
  batchUpdateContactDto: BatchUpdateContactDto
}

export interface CreateContactsRequest {
  workspaceId: string
  createContactsDto: CreateContactsDto
}

export interface DeleteContactsRequest {
  workspaceId: string
  deleteContactsDto: DeleteContactsDto
}

export interface GetContactRequest {
  workspaceId: string
  contactId: string
}

export interface GetContactsRequest {
  page: number
  limit: number
  workspaceId: string
  query?: string
  sortBy?: GetContactsSortByEnum
  sortDirection?: GetContactsSortDirectionEnum
  address?: string
  excludedIds?: Array<string>
}

export interface GetUnknownContactsRequest {
  workspaceId: string
  getUnknownContactQuery: GetUnknownContactQuery
}

export interface UpdateContactRequest {
  workspaceId: string
  updateContactDto: UpdateContactDto
}

/**
 *
 */
export class ContactsApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static batchUpdateContact(params: BatchUpdateContactRequest) {
    return localContactsApi.batchUpdateContact(params)
  }

  /**
   * Add addresses to a contact
   */
  batchUpdateContact({ batchUpdateContactDto, workspaceId }: BatchUpdateContactRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/contacts/batch`,
      batchUpdateContactDto,
      true,
    )
  }

  static createContacts(params: CreateContactsRequest) {
    return localContactsApi.createContacts(params)
  }

  /**
   * Create contacts
   */
  createContacts({ createContactsDto, workspaceId }: CreateContactsRequest): Promise<void> {
    return this.restInstance.post(`/workspaces/${encodeURIComponent(workspaceId)}/contacts`, createContactsDto, true)
  }

  static deleteContacts(params: DeleteContactsRequest) {
    return localContactsApi.deleteContacts(params)
  }

  /**
   * Delete some contacts
   */
  deleteContacts({ deleteContactsDto, workspaceId }: DeleteContactsRequest): Promise<void> {
    return this.restInstance.delete(`/workspaces/${encodeURIComponent(workspaceId)}/contacts`, deleteContactsDto)
  }

  static getContact(params: GetContactRequest) {
    return localContactsApi.getContact(params)
  }

  /**
   * Get one contact
   */
  getContact({ workspaceId, contactId }: GetContactRequest): Promise<GetContactDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/contacts/${encodeURIComponent(contactId)}`,
    )
  }

  static getContacts(params: GetContactsRequest) {
    return localContactsApi.getContacts(params)
  }

  /**
   * Get a user\'s contacts
   */
  getContacts({ workspaceId, ...queryParameters }: GetContactsRequest): Promise<PaginatedContactResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/contacts?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
    )
  }

  static getUnknownContacts(params: GetUnknownContactsRequest) {
    return localContactsApi.getUnknownContacts(params)
  }

  /**
   * Get a user\'s unknown contacts. We have to use a POST (hence a body) instead of a GET otherwise the url becomes too long with a large ammount of addresses.
   */
  getUnknownContacts({
    getUnknownContactQuery,
    workspaceId,
  }: GetUnknownContactsRequest): Promise<PaginatedUnknownContactResultDto> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/contacts/get/unknown`,
      getUnknownContactQuery,
    )
  }

  static updateContact(params: UpdateContactRequest) {
    return localContactsApi.updateContact(params)
  }

  /**
   * Update a contact
   */
  updateContact({ updateContactDto, workspaceId }: UpdateContactRequest): Promise<void> {
    return this.restInstance.put(`/workspaces/${encodeURIComponent(workspaceId)}/contacts`, updateContactDto, true)
  }
}

const localContactsApi = new ContactsApi()

/**
 * @export
 * @enum {string}
 */
export type GetContactsSortByEnum = "movement_count" | "name" | "created_at"
/**
 * @export
 * @enum {string}
 */
export type GetContactsSortDirectionEnum = "ascending" | "descending"
