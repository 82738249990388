/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import { BackOfficeQueueNamesDto, BackOfficeWorkspaceJobArray } from "../models"

export interface BackOfficeGetQueueJobsRequest {
  queueName: string
}

export interface BackOfficeGetWorkspaceJobsRequest {
  workspaceId: string
  queueName?: BackOfficeGetWorkspaceJobsQueueNameEnum
}

export interface BackOfficeKillJobRequest {
  jobId: string
}

/**
 *
 */
export class BackOfficeJobsApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static backOfficeGetQueueJobs(params: BackOfficeGetQueueJobsRequest) {
    return localBackOfficeJobsApi.backOfficeGetQueueJobs(params)
  }

  /**
   * Get Queue jobs
   */
  backOfficeGetQueueJobs({ queueName }: BackOfficeGetQueueJobsRequest): Promise<BackOfficeWorkspaceJobArray> {
    return this.restInstance.get(`/back-office/jobs/byQueue/${encodeURIComponent(queueName)}`)
  }

  static backOfficeGetQueues() {
    return localBackOfficeJobsApi.backOfficeGetQueues()
  }

  /**
   * Get Queue names
   */
  backOfficeGetQueues(): Promise<BackOfficeQueueNamesDto> {
    return this.restInstance.get(`/back-office/jobs/queueNames`)
  }

  static backOfficeGetWorkspaceJobs(params: BackOfficeGetWorkspaceJobsRequest) {
    return localBackOfficeJobsApi.backOfficeGetWorkspaceJobs(params)
  }

  /**
   * Get workspace jobs
   */
  backOfficeGetWorkspaceJobs({
    workspaceId,
    ...queryParameters
  }: BackOfficeGetWorkspaceJobsRequest): Promise<BackOfficeWorkspaceJobArray> {
    return this.restInstance.get(
      `/back-office/jobs/byWorkspace/${encodeURIComponent(workspaceId)}?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }

  static backOfficeKillJob(params: BackOfficeKillJobRequest) {
    return localBackOfficeJobsApi.backOfficeKillJob(params)
  }

  /**
   * Kill a job
   */
  backOfficeKillJob({ jobId }: BackOfficeKillJobRequest): Promise<void> {
    return this.restInstance.post(`/back-office/jobs/kill/${encodeURIComponent(jobId)}`, undefined, true)
  }
}

const localBackOfficeJobsApi = new BackOfficeJobsApi()

/**
 * @export
 * @enum {string}
 */
export type BackOfficeGetWorkspaceJobsQueueNameEnum =
  | "flag-margins-borrow"
  | "flag-internal-transfer"
  | "flag-transaction-types"
  | "flag-internal-transfer-review"
  | "cost-basis"
  | "cost-basis-impairment"
  | "impairment-valuation"
  | "impairment-applicator"
  | "lock"
  | "update-transaction-valuation"
  | "coa-automated-mapping"
  | "accounting-synchronisation"
  | "request-network-importer"
  | "fireblocks"
  | "wallet-deletion"
  | "wallet-importer-creator"
  | "label-applicator"
  | "label-per-wallet-applicator"
  | "label-deletion"
  | "contact-unknown-refresher"
  | "contact-movement-counter"
  | "bulk-movement-update"
  | "duplicate-workspace"
  | "delete-workspace"
  | "mark-ledger-entries-as-synchronized"
  | "defi-position-import"
  | "update-nft-valuation"
  | "postprocess-defi"
  | "wallet-importer:arbitrum"
  | "wallet-importer:avalanche"
  | "wallet-importer:binance-smart-chain-(legacy)"
  | "wallet-importer:binance-smart-chain"
  | "wallet-importer:bitcoin"
  | "wallet-importer:cardano"
  | "wallet-importer:ethereum"
  | "wallet-importer:fantom"
  | "wallet-importer:filecoin"
  | "wallet-importer:litecoin"
  | "wallet-importer:near"
  | "wallet-importer:optimism"
  | "wallet-importer:optimism-(legacy)"
  | "wallet-importer:polygon"
  | "wallet-importer:polygon-(legacy)"
  | "wallet-importer:tezos"
  | "wallet-importer:zilliqa"
  | "wallet-importer:linea"
  | "wallet-importer:zksync"
  | "wallet-importer:base"
  | "wallet-importer:anchorage"
  | "wallet-importer:binance"
  | "wallet-importer:binance-us"
  | "wallet-importer:binance-file"
  | "wallet-importer:bitgo"
  | "wallet-importer:bitcoin-suisse"
  | "wallet-importer:bitfinex"
  | "wallet-importer:bitstamp"
  | "wallet-importer:bittrex"
  | "wallet-importer:coinbase-commerce"
  | "wallet-importer:coinbase-prime"
  | "wallet-importer:coinbase-pro"
  | "wallet-importer:coinbase"
  | "wallet-importer:deribit"
  | "wallet-importer:ftx-(otc)"
  | "wallet-importer:ftx-us"
  | "wallet-importer:ftx"
  | "wallet-importer:gate.io"
  | "wallet-importer:gemini"
  | "wallet-importer:hitbtc"
  | "wallet-importer:hitbtc-(legacy)"
  | "wallet-importer:huobi"
  | "wallet-importer:kraken"
  | "wallet-importer:fireblocks"
  | "wallet-importer:nexo"
  | "wallet-importer:spot"
  | "wallet-importer:kucoin"
  | "wallet-importer:deribit"
  | "wallet-importer:custom"
  | "wallet-importer:custom-api"
  | "balance-importer:arbitrum"
  | "balance-importer:avalanche"
  | "balance-importer:binance-smart-chain-(legacy)"
  | "balance-importer:binance-smart-chain"
  | "balance-importer:bitcoin"
  | "balance-importer:cardano"
  | "balance-importer:ethereum"
  | "balance-importer:fantom"
  | "balance-importer:filecoin"
  | "balance-importer:litecoin"
  | "balance-importer:near"
  | "balance-importer:optimism"
  | "balance-importer:optimism-(legacy)"
  | "balance-importer:polygon"
  | "balance-importer:polygon-(legacy)"
  | "balance-importer:tezos"
  | "balance-importer:zilliqa"
  | "balance-importer:linea"
  | "balance-importer:zksync"
  | "balance-importer:base"
  | "balance-importer:anchorage"
  | "balance-importer:binance"
  | "balance-importer:binance-us"
  | "balance-importer:binance-file"
  | "balance-importer:bitgo"
  | "balance-importer:bitcoin-suisse"
  | "balance-importer:bitfinex"
  | "balance-importer:bitstamp"
  | "balance-importer:bittrex"
  | "balance-importer:coinbase-commerce"
  | "balance-importer:coinbase-prime"
  | "balance-importer:coinbase-pro"
  | "balance-importer:coinbase"
  | "balance-importer:deribit"
  | "balance-importer:ftx-(otc)"
  | "balance-importer:ftx-us"
  | "balance-importer:ftx"
  | "balance-importer:gate.io"
  | "balance-importer:gemini"
  | "balance-importer:hitbtc"
  | "balance-importer:hitbtc-(legacy)"
  | "balance-importer:huobi"
  | "balance-importer:kraken"
  | "balance-importer:fireblocks"
  | "balance-importer:nexo"
  | "balance-importer:spot"
  | "balance-importer:kucoin"
  | "balance-importer:deribit"
  | "balance-importer:custom"
  | "balance-importer:custom-api"
  | "report-exporter:total-per-day:cryptio"
  | "report-exporter:asset-breakdown:cryptio"
  | "report-exporter:wallet-breakdown:cryptio"
  | "report-exporter:per-day-breakdown:cryptio"
  | "report-exporter:portfolio-asset-breakdown:cryptio"
  | "report-exporter:portfolio-wallet-breakdown:cryptio"
  | "report-exporter:current-balances:cryptio"
  | "report-exporter:end-of-day-balances:cryptio"
  | "report-exporter:cost-basis:cryptio-universal"
  | "report-exporter:cost-basis:cryptio-per-wallet"
  | "report-exporter:cost-basis:us-gouv"
  | "report-exporter:ledger:cryptio"
  | "report-exporter:ledger:xero-us"
  | "report-exporter:ledger:xero-uk"
  | "report-exporter:ledger:xero-fr"
  | "report-exporter:ledger:quickbooks"
  | "report-exporter:summarized-ledger:cryptio"
  | "report-exporter:transactions:cryptio"
  | "report-exporter:new-transaction-history:cryptio"
  | "report-exporter:transactions-custom-export:cryptio"
  | "report-exporter:movements:cryptio"
  | "report-exporter:assets:cryptio"
  | "report-exporter:chainlink-node-operator:cryptio"
  | "report-exporter:bank-statement:cryptio"
  | "report-exporter:unidentified-addresses:cryptio"
  | "report-exporter:fireblocks-missing-addresses:cryptio"
  | "report-exporter:custom:fireblocks-month-end-invoicing"
  | "report-exporter:impairment:wac-universal-impairment"
  | "report-exporter:impairment:wac-per-wallet-impairment"
  | "report-exporter:impairment:recap"
  | "report-exporter:impairment-balance:cryptio-per-wallet"
  | "report-exporter:impairment-balance:cryptio-universal"
  | "report-exporter:impairment-transaction-detail:cryptio-per-wallet"
  | "report-exporter:impairment-transaction-detail:cryptio-universal"
  | "report-exporter:contacts:cryptio"
  | "report-exporter:asset-roll-forward:cryptio"
  | "report-exporter:detailed-asset-roll-forward:cryptio"
  | "report-exporter:fees-expenditures:cryptio"
  | "report-exporter:transfers-statement:cryptio"
  | "report-exporter:sources:cryptio"
  | "report-exporter:highest-missing-volume:cryptio"
  | "report-exporter:audit-trail:cryptio"
  | "report-exporter:journal-sequential-numbering:cryptio"
  | "report-exporter:administrator:sanity-check"
  | "report-exporter:trial-balances:cryptio"
  | "report-exporter:filecoin-reward-tracking:cryptio"
  | "report-exporter:consensys-transactions:cryptio"
  | "report-exporter:consensys-asset-roll-forward:consensys-asset-roll-forward"
  | "report-exporter:consensys-rgl-asset-roll-forward:consensys-rgl-asset-roll-forward"
  | "report-exporter:consensys-asset-roll-forward-tax:consensys-asset-roll-forward-tax"
  | "report-exporter:new-consensys-asset-roll-forward:new-consensys-asset-roll-forward"
  | "report-exporter:consensys-provider-fee-historical-balances:consensys-provider-fee-historical-balances"
  | "report-exporter:consensys-asset-breakdown-recap:consensys-asset-breakdown-recap"
  | "report-exporter:consensys-ledger-entries:consensys-ledger-entries"
