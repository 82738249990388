/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
  CreateInternalMovementLinkDto,
  DeleteTransactionCustomDto,
  FullMovementDto,
  GetTransactionNeedingReviewDto,
  MovementBulkUpdateDto,
  MovementCounterPartyDto,
  NewFullTransactionDto,
  NewPaginatedTransactionMovementResultDto,
  NewPaginatedTransactionResultDto,
  NewTransactionMovementPaginationQueryDto,
  NewTransactionPaginationQueryDto,
  PaginatedAssetResultDto,
  PaginatedMovementCostBasisTaxLine,
  UpdateSimpleTransactionJournalLinesDto,
  UpdateTransactionInvoiceDto,
} from "../models"

export interface DeleteTransactionCustomRequest {
  workspaceId: string
  deleteTransactionCustomDto: DeleteTransactionCustomDto
}

export interface GetNewTransactionMovementsRequest {
  workspaceId: string
  transactionId: string
  newTransactionMovementPaginationQueryDto: NewTransactionMovementPaginationQueryDto
}

export interface GetNewTransactionsRequest {
  workspaceId: string
  newTransactionPaginationQueryDto: NewTransactionPaginationQueryDto
}

export interface GetOneMovementRequest {
  workspaceId: string
  movementId: string
}

export interface GetOneNewTransactionRequest {
  workspaceId: string
  transactionId: string
}

export interface GetReviewMetricsRequest {
  workspaceId: string
}

export interface GetTransactionAssetsRequest {
  page: number
  limit: number
  workspaceId: string
  query?: string
  type?: GetTransactionAssetsTypeEnum
  walletIds?: Array<string>
  excludedIds?: Array<string>
}

export interface GetTransactionTaxLinesRequest {
  page: number
  limit: number
  workspaceId: string
  movementId: string
  excludedIds?: Array<string>
}

export interface GuessInternalTransferCounterPartiesRequest {
  workspaceId: string
  movementId: string
}

export interface InsertInternalTransferRequest {
  workspaceId: string
  createInternalMovementLinkDto: CreateInternalMovementLinkDto
}

export interface UpdateBulkMovementsRequest {
  workspaceId: string
  movementBulkUpdateDto: MovementBulkUpdateDto
}

export interface UpdateCostBasisRequest {
  workspaceId: string
}

export interface UpdateSimpleTransactionAccountingJournalLineRequest {
  workspaceId: string
  updateSimpleTransactionJournalLinesDto: UpdateSimpleTransactionJournalLinesDto
}

export interface UpdateTransactionInvoiceRequest {
  workspaceId: string
  updateTransactionInvoiceDto: UpdateTransactionInvoiceDto
}

/**
 *
 */
export class TransactionsApi /* extends runtime.BaseAPI */ {
  constructor(public readonly restInstance = rest) {}

  static deleteTransactionCustom(params: DeleteTransactionCustomRequest) {
    return localTransactionsApi.deleteTransactionCustom(params)
  }

  /**
   * delete some transactions
   */
  deleteTransactionCustom({ deleteTransactionCustomDto, workspaceId }: DeleteTransactionCustomRequest): Promise<void> {
    return this.restInstance.delete(
      `/workspaces/${encodeURIComponent(workspaceId)}/transactions/custom`,
      deleteTransactionCustomDto,
    )
  }

  static getNewTransactionMovements(params: GetNewTransactionMovementsRequest) {
    return localTransactionsApi.getNewTransactionMovements(params)
  }

  /**
   * Get user\'s transaction movements
   */
  getNewTransactionMovements({
    newTransactionMovementPaginationQueryDto,
    workspaceId,
    transactionId,
  }: GetNewTransactionMovementsRequest): Promise<NewPaginatedTransactionMovementResultDto> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/transactions/get-new-transactions/${encodeURIComponent(
        transactionId,
      )}/movements`,
      newTransactionMovementPaginationQueryDto,
    )
  }

  static getNewTransactions(params: GetNewTransactionsRequest) {
    return localTransactionsApi.getNewTransactions(params)
  }

  /**
   * Get user\'s transaction history
   */
  getNewTransactions({
    newTransactionPaginationQueryDto,
    workspaceId,
  }: GetNewTransactionsRequest): Promise<NewPaginatedTransactionResultDto> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/transactions/get-new-transactions`,
      newTransactionPaginationQueryDto,
    )
  }

  static getOneMovement(params: GetOneMovementRequest) {
    return localTransactionsApi.getOneMovement(params)
  }

  /**
   * Get a movement by id
   */
  getOneMovement({ workspaceId, movementId }: GetOneMovementRequest): Promise<FullMovementDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/transactions/transaction-movement/${encodeURIComponent(
        movementId,
      )}`,
    )
  }

  static getOneNewTransaction(params: GetOneNewTransactionRequest) {
    return localTransactionsApi.getOneNewTransaction(params)
  }

  /**
   * Get one transaction
   */
  getOneNewTransaction({ workspaceId, transactionId }: GetOneNewTransactionRequest): Promise<NewFullTransactionDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/transactions/get-new-transactions/${encodeURIComponent(
        transactionId,
      )}`,
    )
  }

  static getReviewMetrics(params: GetReviewMetricsRequest) {
    return localTransactionsApi.getReviewMetrics(params)
  }

  /**
   * Return count of transactions per label
   */
  getReviewMetrics({ workspaceId }: GetReviewMetricsRequest): Promise<GetTransactionNeedingReviewDto> {
    return this.restInstance.get(`/workspaces/${encodeURIComponent(workspaceId)}/transactions/review/label-metrics`)
  }

  static getTransactionAssets(params: GetTransactionAssetsRequest) {
    return localTransactionsApi.getTransactionAssets(params)
  }

  /**
   * Get a user\'s unique assets in his transaction history
   */
  getTransactionAssets({
    workspaceId,
    ...queryParameters
  }: GetTransactionAssetsRequest): Promise<PaginatedAssetResultDto> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/transactions/assets?${this.restInstance.encodeObjectToUrl(
        queryParameters,
      )}`,
    )
  }

  static getTransactionTaxLines(params: GetTransactionTaxLinesRequest) {
    return localTransactionsApi.getTransactionTaxLines(params)
  }

  /**
   * Get transaction tax lines
   */
  getTransactionTaxLines({
    workspaceId,
    movementId,
    ...queryParameters
  }: GetTransactionTaxLinesRequest): Promise<PaginatedMovementCostBasisTaxLine> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/transactions/transaction-tax-lines/${encodeURIComponent(
        movementId,
      )}?${this.restInstance.encodeObjectToUrl(queryParameters)}`,
    )
  }

  static guessInternalTransferCounterParties(params: GuessInternalTransferCounterPartiesRequest) {
    return localTransactionsApi.guessInternalTransferCounterParties(params)
  }

  /**
   * Guess internal transfer counter parties
   */
  guessInternalTransferCounterParties({
    workspaceId,
    movementId,
  }: GuessInternalTransferCounterPartiesRequest): Promise<Array<MovementCounterPartyDto>> {
    return this.restInstance.get(
      `/workspaces/${encodeURIComponent(workspaceId)}/transactions/${encodeURIComponent(movementId)}/guess-transfers`,
    )
  }

  static insertInternalTransfer(params: InsertInternalTransferRequest) {
    return localTransactionsApi.insertInternalTransfer(params)
  }

  /**
   * Guess internal transfer counter parties
   */
  insertInternalTransfer({ createInternalMovementLinkDto, workspaceId }: InsertInternalTransferRequest): Promise<void> {
    return this.restInstance.post(
      `/workspaces/${encodeURIComponent(workspaceId)}/transactions/transfers`,
      createInternalMovementLinkDto,
      true,
    )
  }

  static updateBulkMovements(params: UpdateBulkMovementsRequest) {
    return localTransactionsApi.updateBulkMovements(params)
  }

  /**
   * Update multiple movement
   */
  updateBulkMovements({ movementBulkUpdateDto, workspaceId }: UpdateBulkMovementsRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/transactions/bulk`,
      movementBulkUpdateDto,
      true,
    )
  }

  static updateCostBasis(params: UpdateCostBasisRequest) {
    return localTransactionsApi.updateCostBasis(params)
  }

  /**
   * Update cost basis
   */
  updateCostBasis({ workspaceId }: UpdateCostBasisRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/transactions/cost-basis`,
      undefined,
      true,
    )
  }

  static updateSimpleTransactionAccountingJournalLine(params: UpdateSimpleTransactionAccountingJournalLineRequest) {
    return localTransactionsApi.updateSimpleTransactionAccountingJournalLine(params)
  }

  /**
   * Update transaction journal lines
   */
  updateSimpleTransactionAccountingJournalLine({
    updateSimpleTransactionJournalLinesDto,
    workspaceId,
  }: UpdateSimpleTransactionAccountingJournalLineRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/transactions/journal-lines`,
      updateSimpleTransactionJournalLinesDto,
      true,
    )
  }

  static updateTransactionInvoice(params: UpdateTransactionInvoiceRequest) {
    return localTransactionsApi.updateTransactionInvoice(params)
  }

  /**
   * Update transaction invoice
   */
  updateTransactionInvoice({
    updateTransactionInvoiceDto,
    workspaceId,
  }: UpdateTransactionInvoiceRequest): Promise<void> {
    return this.restInstance.put(
      `/workspaces/${encodeURIComponent(workspaceId)}/transactions/invoice`,
      updateTransactionInvoiceDto,
      true,
    )
  }
}

const localTransactionsApi = new TransactionsApi()

/**
 * @export
 * @enum {string}
 */
export type GetTransactionAssetsTypeEnum = "incoming" | "outgoing" | "both"
