import { Box, DialogActions, makeStyles, Theme, Typography } from "@material-ui/core"
import { Promise as BBPromise } from "bluebird"
import Fuse from "fuse.js"
import _ from "lodash"
import React, { useContext, useMemo, useState } from "react"
import { useHistory } from "react-router"

import DateRangePicker, { DateRange, PlainDate } from "components/DateRangePicker"
import useBulkSelector from "components/Hooks/useBulkSelector"
import { useLoadingButton } from "components/LoadingButton"
import LoadingSpinner from "components/misc/LoadingSpinner"
import PermissionDisabled from "components/Permission/PermissionDisabled"
import DebouncedTextField from "components/selector/DebouncedTextField"
import BaseTable from "components/Table"
import { IExtendedDataTableColumn } from "components/Table/interface"
import TypographyAllowEvent from "components/TypographyAllowEvent"
import ButtonUI from "CryptioUI/Button"
import { Mode, TypographyVariant } from "CryptioUI/types"
import { URLS } from "../../../../routes"
import api from "services/api"
import { GetWalletTypeDto, Vault } from "services/api/openapi"
import { Mixpanel } from "services/mixpanel"
import { pluralize } from "services/utils/textUtils"
import dayjs from "dayjs"
import WarningTypography from "../../../../components/WarningTypography"
import { WorkspaceContext } from "../../../../services/context/workspaceContext"
import { useTypedController } from "@hookform/strictly-typed"
import { useForm } from "react-hook-form"
import { isLockedTransaction } from "../../../../services/utils/isLocked"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  noFireblocksText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}))

interface Props {
  setSelectedSource: (source: GetWalletTypeDto | undefined) => void
  onClose?: () => void
  selectedSource: GetWalletTypeDto
}

type FireblocksFieldSourceForm = {
  dateRange: DateRange
}

export default function FireblocksSource({ setSelectedSource, onClose, selectedSource }: Props) {
  const classes = useStyles()
  const fireblocks = api.integrations.useFireblocks()
  const history = useHistory()
  const workspaceCtx = useContext(WorkspaceContext)
  const [ImportButton, handleButtonCallback] = useLoadingButton()
  const [ImportAllButton, handleImportAllButtonCallback] = useLoadingButton()
  const toast = useToast()
  const { mutateAsync: createBatchWallets } = api.wallet.useCreateBatchWallets()
  const [search, setSearch] = useState<string>("")
  const { handleSubmit, control, formState, watch } = useForm({ mode: "onChange" })
  const TypedController = useTypedController<FireblocksFieldSourceForm>({ control })
  const watchAllFields = watch()

  const vaultsNotImported = useMemo(() => fireblocks.data?.vaults.filter((v) => !v.isImported), [fireblocks.data])
  const fuse = vaultsNotImported
    ? new Fuse(vaultsNotImported, {
        keys: ["name", "identifier"],
      })
    : undefined
  const vaultsSearched = search !== "" ? fuse?.search(search).map((r) => r.item) : vaultsNotImported

  const { selectedRows, bulkSelectorColumn } = useBulkSelector(
    vaultsNotImported
      ? {
          data: vaultsNotImported,
          limit: 0,
          page: 0,
          totalCount: vaultsNotImported.length,
        }
      : undefined,
    (vault) => vault.identifier,
  )

  const importFormId = "wallet-import-form"

  const importVaults = async (vaults: Vault[]) => {
    const startDate = watchAllFields.dateRange?.startDate?.toBackendDate() ?? null
    const endDate = watchAllFields.dateRange?.endDate?.toBackendDate() ?? null
    try {
      await BBPromise.map(_.chunk(vaults, 1000), async (chunk) => {
        await createBatchWallets({
          createBatchWalletsDto: {
            wallets: chunk.map((x) => {
              return {
                name: `Fireblocks - ${x.name} - ${x.identifier}`,
                typeId: selectedSource.id,
                credentials: {
                  vaultId: x.identifier,
                },
                startDate,
                endDate,
              }
            }),
          },
        })
      })
      toast.open(`${vaults.length} ${pluralize(vaults.length > 1, "vault")} imported`, {
        variant: "success",
      })
      if (onClose) onClose()
    } catch (e) {
      toastCatch(e, toast)
    }

    vaults.forEach(() =>
      Mixpanel.track("WalletCreated", {
        sourceType: selectedSource.type,
        sourceName: selectedSource.name,
      }),
    )
  }

  const onSubmit = async () => {
    if (!vaultsNotImported) return
    if (!selectedRows) return
    const vaultsList =
      selectedRows.type === "individuals"
        ? vaultsNotImported.filter((v) => selectedRows.itemIds.includes(v.identifier))
        : vaultsNotImported.filter((v) => !selectedRows.excludedIds.includes(v.identifier))
    await importVaults(vaultsList)
  }

  const onImportAll = async () => {
    if (!vaultsNotImported) return
    await importVaults(vaultsNotImported)
  }

  const isLockingConflicting = isLockedTransaction(
    workspaceCtx.workspace.lock.isEnabled,
    watchAllFields?.dateRange?.startDate?.toBackendDate().valueOf(),
    workspaceCtx.workspace.lock.exclusiveEndDate,
  )

  const columns = useMemo<IExtendedDataTableColumn<Vault>[]>(
    () => [
      bulkSelectorColumn,
      {
        name: "Name",
        selector: "name",
        format: function formatName(row) {
          return <TypographyAllowEvent variant={TypographyVariant.BODY}>{row.name}</TypographyAllowEvent>
        },
      },
      {
        name: "Identifier",
        selector: "identifier",
        format: function formatIdentifier(row) {
          return <TypographyAllowEvent variant={TypographyVariant.BODY}>{row.identifier}</TypographyAllowEvent>
        },
      },
    ],
    [bulkSelectorColumn],
  )

  const AutocompleteMemo = useMemo(() => {
    const maxDate = dayjs().subtract(1, "day").format("YYYY-MM-DD")
    const defaultValue = workspaceCtx.workspace.lock.isEnabled
      ? {
          startDate: PlainDate.fromBackendDate(workspaceCtx.workspace?.lock.exclusivePlainEndDate ?? ""),
          endDate: undefined,
        }
      : {}

    const dateRangeValidation = (dateRange: DateRange) =>
      (!dateRange.startDate || dateRange.startDate.isValid) &&
      (!dateRange.endDate || dateRange.endDate.isValid) &&
      !isLockingConflicting

    if (!vaultsSearched) {
      return <Box>Error while connecting to Fireblocks</Box>
    }
    return (
      <Box>
        <BaseTable<Vault>
          columns={columns}
          items={vaultsSearched}
          pagination={true}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
        <TypedController
          name="dateRange"
          defaultValue={defaultValue}
          rules={{
            required: false,
            validate: dateRangeValidation,
          }}
          render={({ value, onChange }) => (
            <DateRangePicker
              value={value}
              onChange={onChange}
              maxStartDate={maxDate}
              maxEndDate={maxDate}
              fullHistoryButtonText="Fetch complete history"
              disabledSwitch={
                !!(workspaceCtx.workspace.lock.isEnabled && workspaceCtx.workspace.lock.exclusivePlainEndDate)
              }
            />
          )}
        />
        {isLockingConflicting && (
          <div className="mt-4">
            <WarningTypography>
              Some transactions occurred during a period that is currently locked in your workspace. If you want to
              include them, please unlock your period first. Please note that this action will update your cost basis.
            </WarningTypography>
          </div>
        )}
      </Box>
    )
  }, [vaultsSearched, columns, TypedController, workspaceCtx.workspace, isLockingConflicting])

  if (fireblocks.isLoading) {
    return <LoadingSpinner />
  }

  if (!fireblocks.data) {
    return <Box>Error while connecting to Fireblocks</Box>
  }

  if (fireblocks.data.isConnected === false || fireblocks.data.vaults.length === 0) {
    return (
      <Box>
        <Typography className={classes.noFireblocksText} variant="h4">
          You are not connected to Fireblocks.
          <br />
          Please click the button below to connect.
        </Typography>
        <DialogActions>
          <ButtonUI mode={Mode.CONTAINED} onClick={() => setSelectedSource(undefined)}>
            Previous
          </ButtonUI>
          <ButtonUI
            onClick={() =>
              history.push({
                pathname: URLS.Business.Integrations,
                state: { openFireblocksDialog: true },
              })
            }
          >
            Connect to Fireblocks
          </ButtonUI>
        </DialogActions>
      </Box>
    )
  }

  if (vaultsNotImported?.length === 0) {
    return (
      <Box>
        <Typography className={classes.noFireblocksText} variant="h4">
          You already imported all your Fireblocks vaults.
          <br />
          If you have created new vaults, please go to the Integration page and refresh the Fireblocks integration.
        </Typography>
        <DialogActions>
          <ButtonUI mode={Mode.CONTAINED} onClick={() => setSelectedSource(undefined)}>
            Previous
          </ButtonUI>
          <ButtonUI onClick={() => history.push({ pathname: URLS.Business.Integrations })}>
            Go to the Integration page
          </ButtonUI>
        </DialogActions>
      </Box>
    )
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <PermissionDisabled permission="can_create_wallet" action="import sources">
          <ImportAllButton onClick={handleImportAllButtonCallback(onImportAll)}>Import All</ImportAllButton>
        </PermissionDisabled>

        <Box width="50%">
          <DebouncedTextField
            placeholder="Search a vault name or identifier"
            value={search}
            setValue={setSearch}
            fullWidth={true}
          />
        </Box>
      </Box>

      <form id={importFormId} onSubmit={handleButtonCallback(handleSubmit(onSubmit))}>
        {AutocompleteMemo}
      </form>

      <DialogActions>
        <ButtonUI mode={Mode.CONTAINED} onClick={() => setSelectedSource(undefined)}>
          Previous
        </ButtonUI>
        <PermissionDisabled permission="can_create_wallet" action="import sources">
          <ImportButton disabled={selectedRows === undefined || !formState.isValid} type="submit" form={importFormId}>
            Create
          </ImportButton>
        </PermissionDisabled>
      </DialogActions>
    </Box>
  )
}
