import { AppBar, Box, createStyles, makeStyles, Paper, Toolbar, Typography } from "@material-ui/core"
import BigNumber from "bignumber.js"
import React, { useEffect, useMemo, useState } from "react"
import ButtonUI from "CryptioUI/Button"

import api from "services/api"
import {
  GetFeatureAddonDto,
  GetFeatureAddonDtoFeatureEnum,
  GetLimitAddonDto,
  GetPackagesDto,
  GetPlanDto,
  GetWorkspaceUsageLimitDtoLimitTypeEnum,
  SubscriptionPayingDetailsPeriodTypeEnum,
} from "services/api/openapi"
import ChangePricingButton from "./ChangePricingButton"
import { limitTypes } from "./constants"
import DisplayPricing from "./DisplayPricing"
import LimitEditor from "./limitEditor"
import PeriodSelector from "./PeriodSelector"
import PlanWithAddonView from "./planWithAddonView"
import TooltipUI from "CryptioUI/Tooltip"

interface LimitCount {
  type: GetWorkspaceUsageLimitDtoLimitTypeEnum
  count: number
}

interface Props {
  packages: GetPackagesDto
  existingPeriod?: SubscriptionPayingDetailsPeriodTypeEnum
}

const useStyles = makeStyles(() =>
  createStyles({
    appBar: {
      top: "auto",
      bottom: 0,
      height: "62px",
    },
    grow: {
      flexGrow: 1,
    },
  }),
)

const PricingCalculator = ({ packages, existingPeriod }: Props): JSX.Element => {
  const classes = useStyles()

  const [additionals, setAdditionals] = useState<LimitCount[]>(limitTypes.map((lt) => ({ type: lt.type, count: 0 })))
  const [period, setPeriod] = useState<SubscriptionPayingDetailsPeriodTypeEnum>("yearly")

  /* const [neededFeatures,  setNeededFeatures] = useState<GetFeatureAddonDtoFeatureEnum[]>([]) */

  const subscription = api.billing.useFullSubscription()

  useEffect(() => {
    if (existingPeriod) setPeriod(existingPeriod)
  }, [existingPeriod])

  useEffect(() => {
    if (!subscription.data) return
    setAdditionals((addis) =>
      addis.map((x) => {
        const packageLimit = subscription.data.packageLimits.find((e) => e.limitType === x.type)
        const current = packages.currentWorkspaceUsage.find((e) => e.limitType === x.type)

        const toBe = packageLimit
          ? packageLimit.planMaximum + (packageLimit.addonAdditional || 0) - (current?.count || 0)
          : 0
        if (x.count === 0 && toBe > 0) {
          return {
            ...x,
            count: toBe,
          }
        } else {
          return {
            ...x,
            count: x.count,
          }
        }
      }),
    )
  }, [subscription.data, packages.currentWorkspaceUsage])

  const totals: LimitCount[] = useMemo(() => {
    return limitTypes.map((lt) => {
      const current = packages.currentWorkspaceUsage.find((x) => x.limitType === lt.type)
      const additional = additionals.find((x) => x.type === lt.type)
      if (!current || !additional) throw new Error("should not happend")

      return {
        type: lt.type,
        count: current.count + additional.count,
      }
    })
  }, [additionals, packages.currentWorkspaceUsage])

  const sufficientPlans = useMemo(() => {
    const arePlansLimitsRespected = (plan: GetPlanDto): boolean => {
      return plan.hardLimits.every((hardLimit) => {
        const limitCount = totals.find((l) => l.type === hardLimit.limitType)
        if (!limitCount) return false
        return limitCount.count <= hardLimit.maximum
      })
    }

    const arePlansFeaturesRespected = (_plan: GetPlanDto): boolean => {
      return true
      /* return neededFeatures.every((x) => plan.features.includes(x)) */
    }
    return packages.plans.filter((x) => arePlansLimitsRespected(x) && arePlansFeaturesRespected(x))
  }, [packages.plans, totals])

  const plansWithAddons = useMemo(() => {
    const plansThatWillBeAnalyzed = packages.plans.filter((x) => !sufficientPlans.some((sp) => sp.id === x.id))
    return findPlansWithAddons(plansThatWillBeAnalyzed, totals, [], packages.limitAddons, packages.featureAddons)
  }, [packages, sufficientPlans, totals])

  const cheapestSufficientPlan = useMemo(() => {
    return sufficientPlans.sort((a, b) => new BigNumber(a.usdPricePerMonth).comparedTo(b.usdPricePerMonth))[0]
  }, [sufficientPlans])

  const cheapestPlanWithAddons = useMemo(() => {
    return plansWithAddons.sort((a, b) => a.totalUsdPricePerMonth.comparedTo(b.totalUsdPricePerMonth))[0]
  }, [plansWithAddons])

  /* const toggleFeature = (featureType: GetFeatureAddonDtoFeatureEnum) => {
   *   if (neededFeatures.includes(featureType)) setNeededFeatures(neededFeatures.filter((x) => x !== featureType))
   *   else setNeededFeatures(neededFeatures.concat([featureType]))
   * } */

  let cheapestPlanOverral: GetPlanDto | PlanWithAddons
  if (cheapestPlanWithAddons === undefined) cheapestPlanOverral = cheapestSufficientPlan
  else if (cheapestSufficientPlan === undefined) cheapestPlanOverral = cheapestPlanWithAddons
  else
    cheapestPlanOverral = cheapestPlanWithAddons.totalUsdPricePerMonth.isLessThan(
      cheapestSufficientPlan.usdPricePerMonth,
    )
      ? cheapestPlanWithAddons
      : cheapestSufficientPlan

  const hasNegativeAdditional = additionals.some((add) => add.count < 0)

  return (
    <>
      <Box mb={2} p={2} component={Paper}>
        <Typography variant="h4" gutterBottom>
          Subscription details
        </Typography>
        <Typography variant="body1" gutterBottom>
          Input your needs and our calculator will automatically find the best subscription plan for you. A subscription
          is composed of a plan, and optionally one or multiple add-ons. Subscriptions can be paid monthly or yearly
          with a 12% discount.
        </Typography>
        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="justify-evenly">
          {limitTypes.map((lt) => {
            const current = packages.currentWorkspaceUsage.find((x) => x.limitType === lt.type)
            const additional = additionals.find((x) => x.type === lt.type)
            if (additional === undefined || current === undefined) return null
            return (
              <LimitEditor
                key={lt.type}
                limitType={lt}
                current={current.count}
                value={additional.count}
                onChange={(value) => {
                  setAdditionals((prev) => {
                    return prev.map((x) => {
                      if (x.type === lt.type) {
                        if (value + current.count > lt.softMax) value = lt.softMax - current.count
                        x.count = value
                      }
                      return x
                    })
                  })
                }}
              />
            )
          })}
        </Box>
      </Box>
      {/* CODE FOR THE 'FEATURES', not to be used
      <Box m={2} p={2} component={Paper}>
        <Typography variant="h4">Features</Typography>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          {featureTypes.map((ft) => {
            const isNeeded = neededFeatures.includes(ft.type)
            return (
              <Box key={ft.type} p={1}>
                <Chip
                  key={ft.type}
                  label={ft.name}
                  clickable
                  avatar={
                    isNeeded ? (
                      <Avatar>
                        <DoneIcon />
                      </Avatar>
                    ) : undefined
                  }
                  color={isNeeded ? "primary" : "default"}
                  onClick={() => toggleFeature(ft.type)}
                  variant="outlined"
                />
              </Box>
            )
          })}
        </Box>
      </Box>
       */}
      <Box p={2} component={Paper}>
        <Typography variant="h4" gutterBottom>
          Price detail
        </Typography>
        {cheapestPlanOverral === cheapestSufficientPlan && (
          <PlanWithAddonView
            planInAccordion
            plan={cheapestSufficientPlan}
            currentWorkspaceUsage={packages.currentWorkspaceUsage}
            showOnlyPeriodPrice={period}
          />
        )}
        {cheapestPlanOverral === cheapestPlanWithAddons && (
          <PlanWithAddonView
            planInAccordion
            plan={cheapestPlanWithAddons.plan}
            featureAddons={cheapestPlanWithAddons.featureAddons}
            limitAddons={cheapestPlanWithAddons.limitAddons}
            currentWorkspaceUsage={packages.currentWorkspaceUsage}
            showOnlyPeriodPrice={period}
          />
        )}
      </Box>

      <Box mt={2} mb={"62px"} p={2} component={Paper}>
        <Typography variant="h4" gutterBottom>
          Do you need help?
        </Typography>
        <Typography variant="body1">
          Do you want to pay your subscription in crypto?
          <br />
          Or you simply don&apos;t find something that suits your needs?
        </Typography>
        <Box mt={1}>
          <ButtonUI>Contact us on Zendesk</ButtonUI>
        </Box>
      </Box>

      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar>
          {cheapestPlanOverral === cheapestSufficientPlan && (
            <>
              <DisplayPricing
                totalUsdPricePerMonth={new BigNumber(cheapestSufficientPlan.usdPricePerMonth)}
                totalUsdPricePerYear={new BigNumber(cheapestSufficientPlan.usdPricePerYear)}
                period={period}
                planId={cheapestSufficientPlan.id}
                featureAddons={[]}
                limitAddons={[]}
                isError={hasNegativeAdditional}
              />
            </>
          )}
          {cheapestPlanOverral === cheapestPlanWithAddons && (
            <DisplayPricing
              totalUsdPricePerMonth={cheapestPlanWithAddons.totalUsdPricePerMonth}
              totalUsdPricePerYear={cheapestPlanWithAddons.totalUsdPricePerYear}
              period={period}
              planId={cheapestPlanWithAddons.plan.id}
              featureAddons={cheapestPlanWithAddons.featureAddons}
              limitAddons={cheapestPlanWithAddons.limitAddons}
              isError={hasNegativeAdditional}
            />
          )}
          {!existingPeriod && (
            <TooltipUI
              content={
                <Typography>
                  Save&nbsp;$
                  {cheapestPlanOverral === cheapestSufficientPlan && (
                    <>
                      {new BigNumber(cheapestSufficientPlan.usdPricePerMonth)
                        .multipliedBy(12)
                        .minus(new BigNumber(cheapestSufficientPlan.usdPricePerYear))
                        .toFixed(2)}
                    </>
                  )}
                  {cheapestPlanOverral === cheapestPlanWithAddons && (
                    <>
                      {cheapestPlanWithAddons.totalUsdPricePerMonth
                        .multipliedBy(12)
                        .minus(cheapestPlanWithAddons.totalUsdPricePerYear)
                        .toFixed(2)}
                    </>
                  )}
                  &nbsp; USD with a yearly payment
                </Typography>
              }
            >
              <Box>
                <PeriodSelector value={period} setValue={setPeriod} />
              </Box>
            </TooltipUI>
          )}
          <div className={classes.grow} />
          {cheapestPlanOverral === cheapestSufficientPlan && (
            <ChangePricingButton
              period={period}
              planId={cheapestSufficientPlan.id}
              featureAddons={[]}
              limitAddons={[]}
              isError={hasNegativeAdditional}
            />
          )}
          {cheapestPlanOverral === cheapestPlanWithAddons && (
            <ChangePricingButton
              period={period}
              planId={cheapestPlanWithAddons.plan.id}
              featureAddons={cheapestPlanWithAddons.featureAddons}
              limitAddons={cheapestPlanWithAddons.limitAddons}
              isError={hasNegativeAdditional}
            />
          )}
        </Toolbar>
      </AppBar>
    </>
  )
}

export type ExtendedLimitAddon = {
  additionalRequired: number
  usdPricePerMonth: BigNumber
  usdPricePerYear: BigNumber
} & GetLimitAddonDto

type PlanWithAddons = {
  plan: GetPlanDto
  limitAddons: ExtendedLimitAddon[]
  featureAddons: GetFeatureAddonDto[]
  totalUsdPricePerMonth: BigNumber
  totalUsdPricePerYear: BigNumber
}

const findPlansWithAddons = (
  plansThatWillBeAnalyzed: GetPlanDto[],
  totals: LimitCount[],
  neededFeatures: GetFeatureAddonDtoFeatureEnum[],
  limitAddons: GetLimitAddonDto[],
  featureAddons: GetFeatureAddonDto[],
) => {
  if (plansThatWillBeAnalyzed.length === 0) return []

  return plansThatWillBeAnalyzed.reduce((planList, plan) => {
    // For each hard limit that is not respected, try to find a matching addon
    const necessaryLimitAddons = plan.hardLimits.reduce((a, hardLimit) => {
      // find the matching workspace current limit values
      const totalCount = totals.find((l) => l.type === hardLimit.limitType)
      if (!totalCount) throw new Error("should not happen")
      if (totalCount.count > hardLimit.maximum) {
        // If we have more than the plan's limit, find a addon to fill that need

        // maybe here find all addons matching and find the cheapest
        const additionalRequired = totalCount.count - hardLimit.maximum
        const matchingAddons = limitAddons.filter((x) => x.limitType === hardLimit.limitType)
        const allLimitAddons = matchingAddons.map((matchingAddon) =>
          getExtendedLimitAddon(matchingAddon, additionalRequired),
        )
        const bestMatchingAddon = allLimitAddons.sort((a, b) =>
          new BigNumber(a.usdPricePerMonth).comparedTo(b.usdPricePerMonth),
        )[0]
        if (!bestMatchingAddon) throw new Error("should not happen")
        a.push(bestMatchingAddon)
      }
      return a
    }, new Array<ExtendedLimitAddon>())

    const necessaryFeatureAddons = neededFeatures.reduce((a, neededFeature) => {
      if (plan.features.includes(neededFeature)) return a

      const matchingAddon = featureAddons.find((x) => x.feature === neededFeature)
      if (!matchingAddon) throw new Error("should not happen")

      a.push(matchingAddon)
      return a
    }, new Array<GetFeatureAddonDto>())

    if (necessaryLimitAddons.length > 0 || necessaryFeatureAddons.length > 0) {
      planList.push(getPlanWithAddons(plan, necessaryLimitAddons, necessaryFeatureAddons))
    }

    return planList
  }, new Array<PlanWithAddons>())
}

interface PricePerMonthAndYear {
  usdPricePerMonth: string
  usdPricePerYear: string
}

export const getPlanWithAddons = (
  plan: GetPlanDto,
  limitAddons: ExtendedLimitAddon[],
  featureAddons: GetFeatureAddonDto[],
): PlanWithAddons => ({
  plan,
  limitAddons: limitAddons,
  featureAddons: featureAddons,
  totalUsdPricePerMonth: limitAddons
    .reduce((a, c) => a.plus(c.usdPricePerMonth), new BigNumber(plan.usdPricePerMonth))
    .plus(featureAddons.reduce((a, c) => a.plus(c.usdPricePerMonth), new BigNumber(0))),
  totalUsdPricePerYear: limitAddons
    .reduce((a, c) => a.plus(c.usdPricePerYear), new BigNumber(plan.usdPricePerYear))
    .plus(featureAddons.reduce((a, c) => a.plus(c.usdPricePerYear), new BigNumber(0))),
})

export const getExtendedLimitAddon = (addon: GetLimitAddonDto, additionalRequired: number): ExtendedLimitAddon => {
  if (addon.pricing.pricingType === "package") {
    const quantity = Math.max(Math.ceil(additionalRequired / addon.pricing.perUnit), 1)
    additionalRequired = quantity * addon.pricing.perUnit
  }
  return {
    ...addon,
    additionalRequired,
    usdPricePerMonth: computeLimitAddonPrice(addon, additionalRequired, (y) => y.usdPricePerMonth),
    usdPricePerYear: computeLimitAddonPrice(addon, additionalRequired, (y) => y.usdPricePerYear),
  }
}

const computeLimitAddonPrice = (
  addon: GetLimitAddonDto,
  additionalRequired: number,
  pricingSelector: (pmay: PricePerMonthAndYear) => string,
): BigNumber => {
  switch (addon.pricing.pricingType) {
    case "standard":
      return new BigNumber(pricingSelector(addon.pricing)).multipliedBy(additionalRequired)
    case "package":
      return new BigNumber(pricingSelector(addon.pricing)).multipliedBy(
        Math.max(Math.ceil(additionalRequired / addon.pricing.perUnit), 1),
      )
    case "volume": {
      // TODO: mayhaps not the right algo...
      const matching = addon.pricing.prices.find(
        (x) => x.min >= additionalRequired && (x.max === null || (x.max !== null && x.max <= additionalRequired)),
      )
      if (matching === undefined) throw new Error("should not happen")
      return new BigNumber(pricingSelector(matching)).multipliedBy(additionalRequired)
    }
    case "graduated":
      return addon.pricing.prices.reduce((a, c) => {
        const adjustment = c.min === 1 ? 1 : 0
        const additionalForThisStep = Math.min(
          additionalRequired - (c.min - adjustment),
          c.max ? c.max - (c.min - adjustment) : Infinity,
        )
        if (additionalRequired >= c.min)
          a = a.plus(new BigNumber(pricingSelector(c)).multipliedBy(additionalForThisStep))
        return a
      }, new BigNumber(0))
    default:
      throw new Error(`unkown pricing type ${addon.pricing.pricingType}`)
  }
}

export default PricingCalculator
