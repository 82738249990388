import { css } from "@emotion/css"

export const iconStyleWhite = css`
  height: 14px;
  width: 14px;
  filter: invert(100%) sepia(40%) saturate(0%) hue-rotate(267deg) brightness(102%) contrast(103%);
`

export const iconStyleGrey = css`
  height: 14px;
  width: 14px;
  filter: invert(40%) sepia(36%) saturate(240%) hue-rotate(190deg) brightness(89%) contrast(85%);
`

export const iconStyleBlack = css`
  height: 14px;
  width: 14px;
  filter: invert(0%) sepia(3%) saturate(0%) hue-rotate(231deg) brightness(101%) contrast(105%);
`

export const iconStylePrimary = css`
  height: 14px;
  width: 14px;
  filter: invert(12%) sepia(65%) saturate(498%) hue-rotate(138deg) brightness(96%) contrast(103%);
`

export const iconStyleGold = css`
  height: 14px;
  width: 14px;
  filter: invert(72%) sepia(82%) saturate(556%) hue-rotate(360deg) brightness(103%) contrast(103%);
`

export const iconStyleMaterialUI = { width: "16px", height: "16px" }
