import { Link, Typography } from "@material-ui/core"
import React from "react"

import { DrawerCategory, DrawerSection } from "components/Drawer/DrawerItems"
import CopyableText from "components/misc/copyableText"
import { URLS } from "../../../../routes"
import { BackOfficeGetFullPlanDto } from "services/api/openapi/models"

const BackOfficeBillingSummary = ({ plan }: { plan: BackOfficeGetFullPlanDto }) => {
  const checkoutLink = `${window.location.origin}${URLS.Billing.Checkout.Review.getUrl({ planId: plan.id })}`
  return (
    <DrawerCategory title="Plan summary">
      <DrawerSection name="Name">
        <Typography variant="body2" color="textSecondary">
          {plan.name}
        </Typography>
      </DrawerSection>

      <DrawerSection name="Description">
        <Typography variant="body2" color="textSecondary">
          {plan.description}
        </Typography>
      </DrawerSection>

      <DrawerSection name="Price per month">
        <Typography variant="body2" color="textSecondary">
          {plan.usdPricePerMonth} $ / month
        </Typography>
      </DrawerSection>

      <DrawerSection name="Price per year">
        <Typography variant="body2" color="textSecondary">
          {plan.usdPricePerYear} $ / year
        </Typography>
      </DrawerSection>

      <DrawerSection name="Plan ID">
        <Typography variant="body2" color="textSecondary">
          <CopyableText>{plan.id}</CopyableText>
        </Typography>
      </DrawerSection>

      <DrawerSection name="Cryptio checkout link">
        <Link href={checkoutLink} target="_blank" rel="noopener noreferrer">
          <Typography variant="body2">Click to preview</Typography>
        </Link>
      </DrawerSection>
    </DrawerCategory>
  )
}

export default BackOfficeBillingSummary
