import { pickTextColorBasedOnBgColorSimple } from "./textUtils"

export const getIconFilterFromContrast = (backgroundColor: string) => {
  const contrastTextColor = pickTextColorBasedOnBgColorSimple(backgroundColor, "#ffffff", "#000000")
  const colorStyle =
    contrastTextColor === "#ffffff"
      ? "filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);"
      : ""
  return colorStyle
}
