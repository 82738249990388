import { Box, Checkbox, Grid, LinearProgress, Popover, Tab, Tabs, Typography } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import Fab from "@material-ui/core/Fab"
import { LinearProgressProps } from "@material-ui/core/LinearProgress"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import CircleCheckedFilled from "@material-ui/icons/CheckCircle"
import CloseIcon from "@material-ui/icons/Close"
import HelpIcon from "@material-ui/icons/HelpOutline"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useHistory } from "react-router"

import FieldHelper from "components/FieldHelper"
import useDialog from "components/misc/useDialog"
import { GreenColor, White } from "../../materialTheme"
import { URLS } from "../../routes"
import api from "services/api"
import { GetWorkspaceMetricsDto } from "services/api/openapi/models"
import { WorkspaceContext } from "services/context/workspaceContext"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stopIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    popover: {
      marginLeft: "10px",
      marginTop: "-70px",
    },
    fab: {
      position: "fixed",
      margin: theme.spacing(1),
      bottom: theme.spacing(2),
      left: theme.spacing(2),
    },
    fabIcon: {
      marginRight: theme.spacing(1),
    },
    box: {
      height: "auto",
      width: "480px",
    },
    boxTitle: {
      textAlign: "center",
      backgroundColor: theme.palette.primary.main,
    },
    tabTitle: {
      color: "white",
      fontWeight: "bold",
    },
  }),
)

export const useGetWorkspaceOnboardingProgress = (metrics: GetWorkspaceMetricsDto | undefined) => {
  const workspaceCtx = useContext(WorkspaceContext)
  let steps = 0
  let stepCompleted = 0

  if (metrics === undefined) {
    return { sum: 0, finish: false }
  }

  const compute = (stepCheck: boolean): void => {
    steps++
    if (stepCheck) stepCompleted++
  }

  compute(workspaceCtx.workspace.accountingIntegration !== null)
  compute(metrics.hasLabel === true)
  compute(metrics.hasContact === true)
  compute(metrics.hasWallet === true)
  compute(metrics.hasCustomMapping === true && metrics.hasDefaultMapping === true)
  compute(metrics.hasInvitedSomeone === true)
  compute(metrics.hasSeenGlobalLabelsExplanation === true)
  compute(metrics.hasLabeledTransaction === true)
  compute(metrics.hasSynchronizedTransaction === true)

  const sum = steps === 0 ? 100 : (100 / steps) * stepCompleted
  return { sum, finish: sum >= 100 }
}

function BorderLinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  const percent = props.value > 100 ? 100 : props.value
  return (
    <Box display="flex" alignItems="center">
      <Box minWidth={35} ml={2} mr={2}>
        <Typography variant="body2" style={{ color: White }}>{`${Math.round(percent)}%`}</Typography>
      </Box>
      <Box width="80%">
        <LinearProgress variant="determinate" {...props} value={percent} />
      </Box>
    </Box>
  )
}

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: GreenColor,
    },
  }),
)(BorderLinearProgressWithLabel)

interface GettingStartedCategory {
  children: JSX.Element
  index: number
  value: number
}

function FabHelper() {
  const [tabIndex, setTabIndex] = useState(0)
  const classes = useStyles()
  const toast = useToast()
  const basicDialog = useDialog()
  const history = useHistory()
  const workspaceCtx = useContext(WorkspaceContext)
  const { mutateAsync: updateShowOnboarding } = api.workspace.useUpdateShowOnboarding()
  const metrics = api.workspace.useGetWorkspaceMetrics()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [clickedOnClose, setClickedOnClose] = useState<boolean>(false)
  const open = Boolean(anchorEl)
  const id = open ? "fab-helper" : undefined
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    metrics.refetch()
    setAnchorEl(event.currentTarget)
    // https://github.com/mui-org/material-ui/issues/9337
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent("resize"))
    }, 100)
  }

  const changeShowOnboarding = useCallback(
    (show: boolean) => {
      updateShowOnboarding({
        updateShowOnboardingDto: {
          showOnboarding: show,
        },
      })
    },
    [updateShowOnboarding],
  )

  const percent = useGetWorkspaceOnboardingProgress(metrics.data).sum
  useEffect(() => {
    if (percent >= 100) changeShowOnboarding(false)
  }, [percent, changeShowOnboarding])
  useEffect(() => {
    if (
      workspaceCtx.workspace.showOnboarding === true &&
      percent === 0 &&
      anchorEl === null &&
      clickedOnClose === false
    ) {
      setAnchorEl(document.getElementById("fab-button"))
    }
  }, [setAnchorEl, workspaceCtx.workspace.showOnboarding, percent, anchorEl, clickedOnClose])
  if (metrics.isError) return <></>
  if (metrics.isLoading || metrics.data === undefined) return <></>
  const pushAndClose = (route: string, state?: unknown) => {
    history.push(route, state)
    setAnchorEl(null)
  }

  const onSkipClick = () => {
    basicDialog.showDialog({
      title: "Are you sure?",
      content: (
        <>
          <Typography variant="h5">
            You are about to disable the onboarding progress tracker.
            <br />
            If you change your mind, you can re-enable it in the workspace settings.
          </Typography>
        </>
      ),
      yesText: "Yes",
      noText: "No",
      onAccept: async () => {
        try {
          changeShowOnboarding(false)
          toast.open("Changes saved", { variant: "success" })
        } catch (e) {
          toastCatch(e, toast)
        }
        setAnchorEl(null)
      },
    })
  }

  const TabCategory = ({ value, index, children }: GettingStartedCategory): JSX.Element => (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {children}
    </Box>
  )

  const OnboardingProgress = (
    <>
      {basicDialog.dialog}
      <Popover
        style={{ outline: "none" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        className={classes.popover}
        id={id}
        open={open}
        onClose={() => {
          setClickedOnClose(true)
          setAnchorEl(null)
        }}
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            border: "none",
          },
        }}
      >
        <Box className={classes.box}>
          <Grid container>
            <Grid className={classes.boxTitle} item xs={12}>
              <Grid container>
                <Grid item xs={10}>
                  <Box ml={12} mt={3}>
                    <Typography variant="h2" style={{ color: White }}>
                      Getting Started
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box mt={4}>
                    <HighlightOffIcon
                      data-test-id="skip-onboarding"
                      className={classes.stopIcon}
                      onClick={onSkipClick}
                      style={{ color: White }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box ml={2} mb={3} mt={3}>
                <BorderLinearProgress variant="determinate" value={percent} />
              </Box>
              <Tabs value={tabIndex} variant="fullWidth" onChange={(_, index) => setTabIndex(index)}>
                <Tab className={classes.tabTitle} label={"Step 1: Setup"} />
                <Tab className={classes.tabTitle} label={"Step 2: Classify"} />
                <Tab className={classes.tabTitle} label={"Step 3: Connect"} />
              </Tabs>
            </Grid>

            <Grid item xs={12}>
              <List disablePadding component="nav">
                <TabCategory value={tabIndex} index={0}>
                  <Box>
                    <ListItem
                      onClick={() =>
                        pushAndClose(URLS.Workspaces, {
                          openWorkspaceInvitationExplanation: true,
                        })
                      }
                      button
                    >
                      <Checkbox
                        color="primary"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={metrics.data.hasInvitedSomeone === true}
                        size="medium"
                      />

                      <ListItemText primary="Invite your team (team members, accountants, bookkeepers...)" />
                    </ListItem>
                    <ListItem onClick={() => pushAndClose(URLS.Import.basePath)} button>
                      <Checkbox
                        color="primary"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={metrics.data.hasWallet === true}
                        size="medium"
                      />
                      <Grid container alignItems="center">
                        <Typography>Import Data</Typography>
                        <Box ml={1}>
                          <FieldHelper
                            helpUrl={
                              "https://support.cryptio.co/hc/en-gb/articles/7375737834385-Quick-Start-Guide-for-End-Users"
                            }
                          />
                        </Box>
                      </Grid>
                    </ListItem>
                  </Box>
                </TabCategory>
                <TabCategory value={tabIndex} index={1}>
                  <Box>
                    <ListItem onClick={() => pushAndClose(URLS.Business.Contacts.basePath)} button>
                      <Checkbox
                        color="primary"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={metrics.data.hasContact === true}
                        size="medium"
                      />
                      <ListItemText primary="Identify contacts" />
                    </ListItem>
                    <Divider />

                    <ListItem
                      onClick={() =>
                        pushAndClose(URLS.Business.Labels.getUrl({ labelType: "global_label" }), {
                          openGlobalLabelsExplanation: true,
                        })
                      }
                      button
                    >
                      <Checkbox
                        color="primary"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={metrics.data.hasSeenGlobalLabelsExplanation === true}
                        size="medium"
                      />
                      <ListItemText primary="Check our list of Global labels" />
                    </ListItem>
                    <Divider />
                    <ListItem onClick={() => pushAndClose(URLS.Business.Labels.basePath)} button>
                      <Checkbox
                        color="primary"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={metrics.data.hasLabel === true}
                        size="medium"
                      />
                      <Typography>Create your own label</Typography>
                      <Box ml={1}>
                        <FieldHelper
                          helpUrl={"https://support.cryptio.co/hc/en-gb/articles/7049569936913-Label-Categorisation"}
                        />
                      </Box>
                    </ListItem>
                    <Divider />
                    <ListItem onClick={() => pushAndClose(URLS.Transactions.basePath)} button>
                      <Checkbox
                        color="primary"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={metrics.data.hasLabeledTransaction === true}
                        size="medium"
                      />
                      <ListItemText primary="Label transactions" />
                    </ListItem>
                  </Box>
                </TabCategory>
                <TabCategory value={tabIndex} index={2}>
                  <Box>
                    <ListItem onClick={() => pushAndClose(URLS.Accounting.Integrations)} button>
                      <Checkbox
                        color="primary"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={workspaceCtx.workspace.accountingIntegration !== null}
                        size="medium"
                      />
                      <Typography>Connect an accounting system</Typography>
                    </ListItem>
                    <Divider />
                    <ListItem onClick={() => pushAndClose(URLS.Accounting.AutomatedMapping)} button>
                      <Checkbox
                        color="primary"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={metrics.data.hasCustomMapping === true && metrics.data.hasDefaultMapping === true}
                        size="medium"
                      />
                      <Typography>Map chart of accounts to transactions</Typography>
                      <Box ml={1}>
                        <FieldHelper
                          helpUrl={
                            "https://support.cryptio.co/hc/en-gb/articles/9083538867089-Introduction-to-the-Chart-of-Accounts"
                          }
                        />
                      </Box>
                    </ListItem>
                    <Divider />
                    <ListItem onClick={() => pushAndClose(URLS.Transactions.basePath)} button>
                      <Checkbox
                        color="primary"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={metrics.data.hasSynchronizedTransaction}
                        size="medium"
                      />
                      <Typography>Synchronize transactions with an accounting system</Typography>
                      <Box ml={1}>
                        <FieldHelper
                          helpUrl={
                            "https://support.cryptio.co/hc/en-gb/articles/9086103218833-Introduction-to-Transaction-Reconciliation"
                          }
                        />
                      </Box>
                    </ListItem>
                  </Box>
                </TabCategory>
              </List>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  )
  return (
    <>
      {workspaceCtx.workspace.showOnboarding === true && percent < 100 && (
        <>
          <Fab
            variant="extended"
            size="small"
            color="primary"
            id={"fab-button"}
            aria-controls={id}
            onClick={handleClick}
            className={classes.fab}
          >
            {open ? <CloseIcon className={classes.fabIcon} /> : <HelpIcon className={classes.fabIcon} />}
            Getting Started
          </Fab>
          {OnboardingProgress}
        </>
      )}
    </>
  )
}
export default FabHelper
