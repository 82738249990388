import { useMutation, useQuery } from "react-query"

import { queryClient } from "../../../.."
import { BackOfficeGetUsersRequest, BackOfficeUserApi } from "../../openapi"
import { GET_USER_KEY } from "../user"
import { GET_TRANSACTION_KEY } from "../transactions"
import { GET_REPORT_TYPES_KEY } from "../report"
import { GET_WORKSPACE_KEY } from "../workspace"
import { GET_WALLETS_SOURCE_KEY } from "../wallet"

const BACK_OFFICE_GET_USER_KEY = "backOfficeGetUser"
const BACK_OFFICE_GET_USERS_KEY = "backOfficeGetUsers"

export const backOfficeUserActions = {
  useUser: (userId: string | null) => {
    return useQuery(
      [BACK_OFFICE_GET_USER_KEY, userId],
      () => BackOfficeUserApi.backOfficeGetUser({ userId: userId ?? "" }),
      {
        enabled: Boolean(userId),
      },
    )
  },
  useUsers: (variables: BackOfficeGetUsersRequest) => {
    return useQuery([BACK_OFFICE_GET_USERS_KEY, variables], () => BackOfficeUserApi.backOfficeGetUsers(variables), {
      keepPreviousData: true,
    })
  },
  useUpdateUser: () => {
    return useMutation(BackOfficeUserApi.backOfficeUpdateUser, {
      onSuccess: () => {
        // Update user
        queryClient.invalidateQueries(BACK_OFFICE_GET_USER_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_USERS_KEY)

        // If self
        queryClient.invalidateQueries(GET_USER_KEY)
      },
    })
  },
  useToggleAdminFeatures: () => {
    return useMutation(BackOfficeUserApi.backOfficeToggleAdminFeatures, {
      onSuccess: () => {
        queryClient.invalidateQueries(BACK_OFFICE_GET_USER_KEY)
        queryClient.invalidateQueries(BACK_OFFICE_GET_USERS_KEY)
        queryClient.invalidateQueries(GET_USER_KEY)
        queryClient.invalidateQueries(GET_TRANSACTION_KEY)
        queryClient.invalidateQueries(GET_REPORT_TYPES_KEY)
        queryClient.invalidateQueries(GET_WALLETS_SOURCE_KEY)
        queryClient.invalidateQueries(GET_WORKSPACE_KEY)
      },
    })
  },
}
