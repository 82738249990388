import { Box, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"

import LoadingSpinner from "components/misc/LoadingSpinner"
import ButtonUI from "CryptioUI/Button"
import { URLS } from "../../routes"
import api from "services/api"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

// type emailConfirmedState = null | "invalid_token" | "already_confirmed" | "confirmed"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: "0 auto",
    maxWidth: "60em",
  },
  submitButton: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "1.2em",
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}))

const ConfirmEmailScene = (): JSX.Element => {
  const classes = useStyles()
  const routeMatch = useRouteMatch<{ email: string; token: string }>()
  const toast = useToast()
  const history = useHistory()

  const { mutateAsync: confirmEmailMutation } = api.user.useConfirmEmail()
  const [confirmedEmailState, setConfirmedEmailState] = useState<boolean | null>(null)

  useEffect(() => {
    const confirmFunc = async () => {
      try {
        await confirmEmailMutation({
          confirmEmailDto: routeMatch.params,
        })

        setConfirmedEmailState(true)
      } catch (e) {
        // TODO: already confirmed or other error
        // if (e.message === "Invalid token") {
        //   setConfirmedEmailState("invalid_token")
        // } else {
        //   setConfirmedEmailState("already_confirmed")
        // }
        toastCatch(e, toast)
        history.push(URLS.Portfolio)
      }
    }

    confirmFunc()
  }, [confirmEmailMutation, toast, history, routeMatch.params])

  return (
    <Box pt={20}>
      <Paper component="form" className={classes.container}>
        {confirmedEmailState === null ? (
          <LoadingSpinner />
        ) : (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h1">Thank you for verifying your email!</Typography>
            <Typography className={classes.subtitle}>You have successfully verified your email</Typography>
            <ButtonUI onClick={() => history.push(URLS.Account.Login)}>Login</ButtonUI>
          </Box>
        )}
      </Paper>
    </Box>
  )
}

export default ConfirmEmailScene
