import { Box, CircularProgress } from "@material-ui/core"
import React from "react"

const LoadingSpinner = (): JSX.Element => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
    <CircularProgress />
  </Box>
)

export default LoadingSpinner
