import { Avatar } from "@material-ui/core"
import { ImageOutlined } from "@material-ui/icons"
import React from "react"

import { OverviewBalanceDto, UnrealizedGainBalanceDto } from "services/api/openapi"
import { getSymbol } from "services/utils/AssetSymbols"

export function CurrencyLogo({ asset }: { asset: OverviewBalanceDto | UnrealizedGainBalanceDto }) {
  const currencyName = asset.assetName ?? asset.assetSymbol ?? "Asset"
  if (asset.logoUrl) {
    return <Avatar alt={`${currencyName} logo`} src={asset.logoUrl} />
  }
  if (asset.assetType === "fiat" && asset.assetSymbol) {
    const symbol = getSymbol(asset.assetSymbol)
    return <Avatar alt={`${currencyName} symbol`}>{symbol}</Avatar>
  }

  return (
    <Avatar alt={currencyName}>
      <ImageOutlined />
    </Avatar>
  )
}
