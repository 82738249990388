import { Box, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import { isInteger } from "pure-shared"
import React from "react"

import SimpleAutocompleteFactory from "components/Autocomplete/SimpleAutocompleteFactory"
import InputNumber from "components/Input/InputNumber"
import { White } from "../../../materialTheme"
import ChildrenProps from "services/misc/childrenProps"
import isNumberStringValid from "services/utils/isNumberStringValid"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: "0 auto",
    outline: "none",
    border: "none",
  },
  inlineContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}))

type LimitationTableProps = ChildrenProps & {
  title: string
  filterName?: string

  maxRemainingUsage?: string
  setMaxRemainingUsage?: (newValue: string) => void
  usageType?: LimitationUsageType
  setUsageType?: (newValue: LimitationUsageType) => void
}

export type LimitationUsageType = "percent" | "count"
const UsageTypeAutocomplete = SimpleAutocompleteFactory<LimitationUsageType>({
  percent: "%",
  count: "count",
})

const LimitationTable = (props: LimitationTableProps) => {
  const { title, filterName, maxRemainingUsage, setMaxRemainingUsage, usageType = "count", setUsageType } = props
  const classes = useStyles()
  return (
    <Box component={Paper} className={classes.container} {...props}>
      <Box className={classes.header} display="flex" flexDirection="column">
        <Typography variant="h4">{title}</Typography>
        {filterName && maxRemainingUsage !== undefined && setMaxRemainingUsage && (
          <Box display="flex" alignItems="center">
            <Typography variant="body1">Filter by number of {filterName} left:</Typography>
            <Box mr={1} />
            <InputNumber
              value={maxRemainingUsage}
              onChange={(newValue) => setMaxRemainingUsage(newValue ?? "")}
              style={{ width: "100px", backgroundColor: White }}
              isValueValid={usageType === "count" ? isInteger : isNumberStringValid}
            />
            {setUsageType && (
              <>
                <Box mr={1} />
                <UsageTypeAutocomplete
                  id="limitation-type-select"
                  style={{ width: "70px", backgroundColor: White }}
                  value={usageType}
                  disableClearable
                  renderProps={{
                    variant: "standard",
                  }}
                  onChange={setUsageType}
                />
              </>
            )}
            <Box mr={1} />
            <Typography variant="body1">or less.</Typography>
          </Box>
        )}
      </Box>
      <Box className={classes.inlineContainer}>
        <Paper>{props.children}</Paper>
      </Box>
    </Box>
  )
}

export default LimitationTable
