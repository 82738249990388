import { useTypedController } from "@hookform/strictly-typed"
import dayjs from "dayjs"
import { PlainDateFormat } from "pure-shared"
import React from "react"
import { ReportFormComponentProps } from "."
import DateRangePicker from "components/DateRangePicker"
import { DrawerSection } from "components/Drawer/DrawerItems"
import { DrawerReportForm } from "../ReportForm"

const ReportDateRangePicker = ({ control }: ReportFormComponentProps) => {
  const TypedController = useTypedController<DrawerReportForm>({ control })

  return (
    <DrawerSection name="Date range" labelGap={0}>
      <TypedController
        name="date"
        defaultValue={{
          startDate: undefined,
          endDate: undefined,
        }}
        rules={{
          required: false,
          validate: (dateRange) =>
            (!dateRange.startDate || dateRange.startDate.isValid) && (!dateRange.endDate || dateRange.endDate.isValid),
        }}
        render={({ onChange, value }) => (
          <DateRangePicker
            value={value}
            onChange={onChange}
            fullHistoryButtonText="Include complete history"
            maxEndDate={dayjs.utc().subtract(1, "day").format(PlainDateFormat)}
          />
        )}
      />
    </DrawerSection>
  )
}

export default ReportDateRangePicker
