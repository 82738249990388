import { Grid, Typography } from "@material-ui/core"
import React from "react"

import { GetWalletDto } from "services/api/openapi"
import { pluralize } from "services/utils/textUtils"

interface Props {
  wallet: Pick<GetWalletDto, "name" | "sourceName" | "transactionCount">
}

const WalletOptionInAutocomplete = ({ wallet }: Props) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Typography variant="body1">{wallet.name}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {wallet.sourceName}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {wallet.transactionCount} {pluralize(wallet.transactionCount > 0, "transaction")}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default WalletOptionInAutocomplete
