import React, { useContext, useState } from "react"
import { IconButton, TextField, Typography } from "@material-ui/core"
import { RestoreRounded } from "@material-ui/icons"
import BigNumber from "bignumber.js"

import { DrawerFormSection } from "../../../components/Drawer/DrawerItems"
import ConditionalTooltip from "../../ConditionalTooltip"
import { WorkspaceContext } from "services/context/workspaceContext"
import TooltipUI from "CryptioUI/Tooltip"

export interface TradeRateValue {
  inRate?: string
  outRate?: string
  feeRate?: string
}

export interface TradeRateFieldProps {
  incoming?: {
    volume: string
    originalAssetToUsdRate: string | null
    usdToFiatRate: string | null
    assetToFiatRate: string | null
    originalAssetToFiatRate: string | null
  }
  outgoing?: {
    volume: string
    originalAssetToUsdRate: string | null
    usdToFiatRate: string | null
    assetToFiatRate: string | null
    originalAssetToFiatRate: string | null
  }
  feeMovement?: {
    volume: string
    originalAssetToUsdRate: string | null
    usdToFiatRate: string | null
    assetToFiatRate: string | null
    originalAssetToFiatRate: string | null
  }
  value: TradeRateValue
  onChange: (value: TradeRateValue) => void
  disabled?: boolean
}

export function TradeRateField({
  incoming,
  outgoing,
  feeMovement,
  value,
  onChange,
  disabled = false,
}: TradeRateFieldProps) {
  const workspaceCtx = useContext(WorkspaceContext)
  const [persistentValue, setPersistentValue] = useState(value)
  const computeOtherRate = (newRate: string, newVolume?: string, otherVolume?: string): string | undefined => {
    const newRateB = new BigNumber(newRate)
    if (newVolume && otherVolume && !newRateB.isNaN()) {
      //FIXME: Arbitrary precision, this should depend on the volume, decimals
      // Etc. but this should be good enough for now
      const minimalPrecision = 15
      const dp = Math.max(newRateB.decimalPlaces(), minimalPrecision)
      return new BigNumber(newVolume).dividedBy(otherVolume).multipliedBy(newRate).toFixed(dp)
    }
    return undefined
  }

  const onChangeIncomingRate = (newInRate: string) => {
    const otherRate = computeOtherRate(newInRate, incoming?.volume, outgoing?.volume)
    onChange({
      inRate: newInRate,
      outRate: otherRate,
    })
    setPersistentValue({ inRate: newInRate, outRate: otherRate, feeRate: persistentValue.feeRate })
  }

  const onChangeOutgoingRate = (newOutRate: string) => {
    const otherRate = computeOtherRate(newOutRate, outgoing?.volume, incoming?.volume)
    onChange({
      inRate: otherRate,
      outRate: newOutRate,
    })
    setPersistentValue({ inRate: otherRate, outRate: newOutRate, feeRate: persistentValue.feeRate })
  }

  const onChangeFeeRate = (newFeeMovement: string) => {
    onChange({
      feeRate: newFeeMovement,
    })
    setPersistentValue({ inRate: persistentValue.inRate, outRate: persistentValue.outRate, feeRate: newFeeMovement })
  }

  return (
    <>
      {incoming && (
        <DrawerFormSection
          htmlFor="volume-textfield"
          name={`Incoming ${workspaceCtx.workspace.defaultCurrencyName} rate`}
          mt={2}
        >
          <ConditionalTooltip tooltipMessage={"The rate of a locked transaction can't be edited"} disabled={disabled}>
            <TextField
              placeholder={value.inRate === "" ? "Missing rate" : undefined}
              error={!!value.inRate && isNaN(Number(value.inRate))}
              value={value.inRate}
              onChange={(x) => onChangeIncomingRate(x.target.value)}
              disabled={disabled}
              InputProps={{
                endAdornment: incoming.originalAssetToFiatRate &&
                  incoming.originalAssetToFiatRate !== persistentValue.inRate && (
                    <TooltipUI
                      content={
                        <Typography variant="body1">
                          Reset rate to {incoming.originalAssetToFiatRate || "missing rate"}
                        </Typography>
                      }
                    >
                      <IconButton
                        style={{ padding: 0 }}
                        aria-label="reset"
                        onClick={() => {
                          if (incoming.originalAssetToFiatRate) {
                            onChange({
                              inRate: incoming.originalAssetToFiatRate,
                              outRate: outgoing?.originalAssetToFiatRate ?? value.outRate,
                              feeRate: value.feeRate,
                            })
                            setPersistentValue({
                              inRate: incoming.originalAssetToFiatRate,
                              outRate: outgoing?.originalAssetToFiatRate ?? value.outRate,
                              feeRate: persistentValue.feeRate,
                            })
                          }
                        }}
                      >
                        <RestoreRounded />
                      </IconButton>
                    </TooltipUI>
                  ),
              }}
              fullWidth
            />
          </ConditionalTooltip>
        </DrawerFormSection>
      )}
      {outgoing && (
        <DrawerFormSection
          htmlFor="volume-textfield"
          name={`Outgoing ${workspaceCtx.workspace.defaultCurrencyName} rate`}
          mt={2}
        >
          <ConditionalTooltip tooltipMessage={"The rate of a locked transaction can't be edited"} disabled={disabled}>
            <TextField
              disabled={disabled}
              placeholder={value.outRate === "" ? "Missing rate" : undefined}
              error={!!value.outRate && isNaN(Number(value.outRate))}
              value={value.outRate}
              onChange={(x) => onChangeOutgoingRate(x.target.value)}
              InputProps={{
                endAdornment: outgoing.originalAssetToFiatRate &&
                  outgoing.originalAssetToFiatRate !== persistentValue.outRate && (
                    <TooltipUI
                      content={
                        <Typography variant="body1">
                          Reset rate to {outgoing.originalAssetToFiatRate || "missing rate"}
                        </Typography>
                      }
                    >
                      <IconButton
                        style={{ padding: 0 }}
                        aria-label="reset"
                        onClick={() => {
                          if (outgoing.originalAssetToFiatRate) {
                            onChange({
                              inRate: incoming?.originalAssetToFiatRate ?? value.inRate,
                              outRate: outgoing.originalAssetToFiatRate,
                              feeRate: value.feeRate,
                            })
                            setPersistentValue({
                              inRate: incoming?.originalAssetToFiatRate ?? value.inRate,
                              outRate: outgoing.originalAssetToFiatRate,
                              feeRate: persistentValue.feeRate,
                            })
                          }
                        }}
                      >
                        <RestoreRounded />
                      </IconButton>
                    </TooltipUI>
                  ),
              }}
              fullWidth
            />
          </ConditionalTooltip>
        </DrawerFormSection>
      )}
      {feeMovement && (
        <DrawerFormSection
          htmlFor="volume-textfield"
          name={`Fee Rate ${workspaceCtx.workspace.defaultCurrencyName} rate`}
          mt={2}
        >
          <ConditionalTooltip tooltipMessage={"The rate of a locked transaction can't be edited"} disabled={disabled}>
            <TextField
              placeholder={value.feeRate === "" ? "Missing rate" : undefined}
              error={!!value.feeRate && isNaN(Number(value.feeRate))}
              value={value.feeRate}
              onChange={(x) => onChangeFeeRate(x.target.value)}
              disabled={disabled}
              InputProps={{
                endAdornment: feeMovement.originalAssetToFiatRate &&
                  feeMovement.originalAssetToFiatRate !== persistentValue.feeRate && (
                    <TooltipUI
                      content={
                        <Typography variant="body1">
                          Reset rate to {feeMovement.originalAssetToFiatRate || "missing rate"}
                        </Typography>
                      }
                    >
                      <IconButton
                        style={{ padding: 0 }}
                        aria-label="reset"
                        onClick={() => {
                          if (feeMovement.originalAssetToFiatRate) {
                            onChange({
                              inRate: value.inRate,
                              outRate: value.outRate,
                              feeRate: feeMovement.originalAssetToFiatRate,
                            })
                            setPersistentValue({
                              inRate: persistentValue.inRate,
                              outRate: persistentValue.outRate,
                              feeRate: feeMovement.originalAssetToFiatRate,
                            })
                          }
                        }}
                      >
                        <RestoreRounded />
                      </IconButton>
                    </TooltipUI>
                  ),
              }}
              fullWidth
            />
          </ConditionalTooltip>
        </DrawerFormSection>
      )}
    </>
  )
}

export default TradeRateField
