import { makeStyles, Switch, Theme, Typography } from "@material-ui/core"
import React, { useContext, useState } from "react"

import { useLoadingButton } from "components/LoadingButton"
import api from "services/api"
import SettingsCategory from "./SettingsCategory"
import { UserContext } from "../../services/context/userContext"
import { useToast } from "CryptioUI/Toaster"
import { toastCatch } from "components/ReactHookForm/utils"

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontSize: "1.2em",
  },
}))

const AdminFeatures = (): JSX.Element => {
  const classes = useStyles()
  const toast = useToast()
  const userCtx = useContext(UserContext)
  const [SaveButton, handleButtonCallback] = useLoadingButton()

  const [showAdminFeatures, setShowAdminFeatures] = useState(!userCtx.user.hideAdminFeatures)

  const { mutateAsync: toggleAdminFeaturesMutation } = api.backOffice.user.useToggleAdminFeatures()

  const onSubmit = async () => {
    try {
      await toggleAdminFeaturesMutation()
      toast.open(`Administrator features are now ${showAdminFeatures ? "shown" : "hidden"}`, {
        variant: "success",
      })
    } catch (e) {
      toastCatch(e, toast)
    }
  }

  const isDirty = showAdminFeatures !== !userCtx.user.hideAdminFeatures

  return (
    <SettingsCategory title="Toggle admin features">
      <div className="flex flex-col justify-center items-start">
        <div className="flex justify-between items-center">
          <Switch checked={showAdminFeatures} onChange={() => setShowAdminFeatures(!showAdminFeatures)} />
          <Typography>Show administrator features</Typography>
        </div>
        <div className="mt-4">
          <SaveButton className={classes.submitButton} disabled={!isDirty} onClick={handleButtonCallback(onSubmit)}>
            Save
          </SaveButton>
        </div>
      </div>
    </SettingsCategory>
  )
}

export default AdminFeatures
